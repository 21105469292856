import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { TextDisplay } from '../../display';

import * as style from './style.css';

// the permitted values
const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class SkillLevelButtons extends Component {
  shouldComponentUpdate(nextProps) {
    // We don't want to re-render on every click (which updated the parent). only to update the external listener
    return nextProps.skill.skillType !== this.props.skill.skillType;
  }

  onClickHandler = (e) => {
    this.props.onClick(e.target.name, e.target.value);
  };

  render() {
    const { skill, labelsLookup } = this.props;
    const skillLevelParsed = parseInt(skill.skillLevel, 10) || 1;
    return (
      <div>
        <Grid container alignItems="center" justifyContent="center" style={{ marginBottom: 24, userSelect: 'none' }}>
          <Grid item xs={12} sm={2}>
            <TextDisplay>{labelsLookup[skill.skillType]}</TextDisplay>
          </Grid>
          {values.map((skillLevel) => (
            <Grid
              item
              xs={1}
              className={style['card-container']}
              key={`${skill.skillType}-radio-button-value-${skillLevel}`}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className={style['label-container']}>
                <input
                  value={skillLevel}
                  type="radio"
                  name={skill.skillType} // unique name for each skill
                  className={style['card-input-element']}
                  onClick={this.onClickHandler}
                  defaultChecked={skillLevel === skillLevelParsed} // initial state if the radio button
                />
                <div className={style['card-input']}>{skillLevel}</div>
              </label>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

SkillLevelButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  skill: PropTypes.object.isRequired,
  labelsLookup: PropTypes.object.isRequired,
};

export default SkillLevelButtons;
