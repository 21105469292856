import React from 'react';

import { NavigationButtonProps, WizardNavigationButtonsProps } from './types';
import { Button } from '../../inputs';

import useStyles from './style';

const WizardNavigationButtons: React.FC<WizardNavigationButtonsProps> = (props) => {
  const classes = useStyles(props);
  const { step, prevButton, nextButton, finishButton, skipButton } = props;

  const {
    label: prevButtonLabel = 'Previous',
    onClick: prevButtonOnClick,
    icon: prevButtonIcon,
    hide: prevButtonHide,
    ...prevButtonRest
  } = prevButton || ({} as NavigationButtonProps);

  const {
    label: nextButtonLabel,
    onClick: nextButtonOnClick,
    icon: nextButtonIcon,
    hide: nextButtonHide,
    ...nextButtonRest
  } = nextButton || ({} as NavigationButtonProps);

  const {
    label: skipButtonLabel,
    onClick: skipButtonOnClick,
    icon: skipButtonIcon,
    hide: skipButtonHide,
    ...skipButtonRest
  } = skipButton || ({} as NavigationButtonProps);

  const {
    label: finishButtonLabel,
    onClick: finishButtonOnClick,
    icon: finishButtonIcon,
    hide: finishButtonHide,
    ...finishButtonRest
  } = finishButton || ({} as NavigationButtonProps);

  return (
    <div className={classes.root}>
      {prevButton && !prevButtonHide && (
        <Button
          color="primary"
          label={prevButtonLabel}
          onClick={prevButtonOnClick || step.previousStep}
          startIcon={prevButtonIcon}
          {...prevButtonRest}
        />
      )}

      {skipButton && !skipButtonHide && (
        <Button
          color="primary"
          label={skipButtonLabel || 'Skip'}
          onClick={skipButtonOnClick || step.nextStep}
          startIcon={skipButtonIcon}
          {...skipButtonRest}
        />
      )}

      {nextButton && !nextButtonHide && (
        <Button
          color="primary"
          variant="contained"
          label={nextButtonLabel || 'Next'}
          onClick={nextButtonOnClick || step.nextStep}
          startIcon={nextButtonIcon}
          {...nextButtonRest}
        />
      )}

      {finishButton && !finishButtonHide && (
        <Button
          color="primary"
          variant="contained"
          label={finishButtonLabel || 'Finish'}
          onClick={finishButtonOnClick || step.onClose}
          startIcon={finishButtonIcon}
          {...finishButtonRest}
        />
      )}
    </div>
  );
};

export default WizardNavigationButtons;
