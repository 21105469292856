import { CronofyAvailabilityProfiles } from '@mayple/types';

import { UseAvailabilityViewerFilterOutput } from '../types';
import useManagedAvailability from './useManagedAvailability';
import useTimezonePicker from './useTimezonePicker';
import useAvailabilityProfiles from './useAvailabilityProfiles';
import useOnlyShowAvailabilityFilter from './useOnlyShowAvailabilityFilter';
import useAccountsSelectionFilter from '../components/AccountGroupList/useAccountsSelectionFilter';

const useAvailabilityViewerFilter = (): UseAvailabilityViewerFilterOutput => {
  const [availabilityManagementValue, setAvailabilityManagementValue] = useManagedAvailability();

  const [availabilityProfilesValue, setAvailabilityProfilesValue] = useAvailabilityProfiles([
    CronofyAvailabilityProfiles.work_hours,
  ]);

  const [timezone, onTimezoneChangeHandler] = useTimezonePicker();

  const [selectedAccountsFilterValue, onSelectedAccountsFilterChange] = useAccountsSelectionFilter();

  const [onlyShowAvailability, onOnlyShowAvailabilityChange] = useOnlyShowAvailabilityFilter();

  return {
    availabilityManagementValue,
    setAvailabilityManagementValue,
    availabilityProfilesValue,
    setAvailabilityProfilesValue,
    timezone,
    onTimezoneChangeHandler,
    selectedAccountsFilterValue,
    onSelectedAccountsFilterChange,
    onlyShowAvailability,
    onOnlyShowAvailabilityChange,
  };
};

export default useAvailabilityViewerFilter;
