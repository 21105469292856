import React from 'react';
import { Project } from '@mayple/types';

import { toNiceCountriesList, toNiceEnumsList } from '../../../../../../../fe_common/client/services/utils';
import { getIndustryLabel } from '../../../../../../../fe_common/client/logic/industries';
import {
  PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  REMOTE_WORK_LOOKUP,
} from '../../../../../../../fe_common/client/app/enums';
import { REMOTE_WORK } from '../../../../../../../fe_common/client/app/consts';
import { PropertiesMetaType } from '../../../../../../../fe_common/client/components/display/PropertiesList';

const PROPS_GROUPS = {
  TARGETING: 'Targeting',
  CUSTOMER_REQUESTS: 'Customer Requests',
  CONFIGURATION: 'Configuration',
};

const remoteWorkLookup = REMOTE_WORK_LOOKUP as Record<string, string>;

export const getMarketerLocationPreference = (project: Project | null | undefined): string => {
  const country = project?.company?.companyProfile?.contactDetails?.postalAddress?.country;
  const locations = project?.locations || [];
  // Requested by https://mayple.slack.com/archives/CJLP2PN9H/p1609408110048100
  if (locations.length === 1 && locations[0] === country) {
    return REMOTE_WORK.NA;
  }

  const { preferLocalMarketers, preferMarketerInTargettedLocations } = project || ({} as Project);

  if (preferLocalMarketers) {
    return remoteWorkLookup[REMOTE_WORK.DOMESTIC];
  }

  if (preferMarketerInTargettedLocations) {
    return remoteWorkLookup[REMOTE_WORK.ON_TARGET];
  }

  return remoteWorkLookup[REMOTE_WORK.NO_PREFERENCE];
};

export const getProjectBriefProperties = (): PropertiesMetaType[] => [
  {
    label: 'Industry',
    path: 'industry',
    formatter: (value) => getIndustryLabel(value, true),
    group: PROPS_GROUPS.TARGETING,
  },
  {
    label: 'Target Countries',
    path: 'locations',
    formatter: toNiceCountriesList,
    group: PROPS_GROUPS.TARGETING,
  },
  {
    label: 'Product Business Model',
    path: 'productBusinessModel',
    formatter: (value) => toNiceEnumsList(value, PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP),
    group: PROPS_GROUPS.TARGETING,
  },
  {
    label: 'Desired target KPI',
    path: 'projectDefinition.primaryObjectives.targetKPI',
    defaultValue: 'N/A',
    group: PROPS_GROUPS.TARGETING,
  },
  {
    label: 'Target URL',
    path: 'targetUrls[0]',
    // eslint-disable-next-line react/display-name
    formatter: (value) => (
      <a href={value} rel="noreferrer noopener" target="_blank">
        {value}
      </a>
    ),
    group: PROPS_GROUPS.TARGETING,
  },
];
