import React, { FC, useMemo } from 'react';
import { AdminParticipantsInsightsFeedStatisticsReportQuery } from 'growl-graphql/dist/queries/AdminParticipantsInsightsFeedStatisticsReportQuery';

import { InsightsTableProps } from './types';
import { initialFilter, initialSort } from './consts';
import { getColumns } from './logic';

import QueryTable from '../../../fe_common/client/components/molecules/QueryTable';
import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';
import useGrowthStrategists from '../../../fe_common/client/hooks/internalTeam/useGrowthStrategists';

const InsightsTable: FC<InsightsTableProps> = (props) => {
  const { topic } = props;

  const growthStrategists = useGrowthStrategists();
  const growthStrategistsLabelValue = useMemo(() => {
    if (!growthStrategists?.length) {
      return [];
    }

    return growthStrategists.map((gs) => ({
      label: gs.name,
      value: gs.id.toString(),
    }));
  }, [growthStrategists]);

  const columns = useMemo(() => getColumns(topic, growthStrategistsLabelValue), [topic, growthStrategistsLabelValue]);

  return (
    <Section title="Insights feed latest post date by topic" noBorder noTopMargin>
      <SectionContent>
        <QueryTable
          queryClass={AdminParticipantsInsightsFeedStatisticsReportQuery}
          columns={columns}
          initialFiltered={initialFilter}
          initialSorted={initialSort}
          entityKey="projectsPaged"
          options={{
            skip: !topic,
          }}
        />
      </SectionContent>
    </Section>
  );
};

export default InsightsTable;
