import React from 'react';
import PropTypes from 'prop-types';
import { ProjectLifeCycleStatus } from '@mayple/types';

function HideByProjectStatus(props) {
  const { status, hideOnDiscovery, hideOnOnBoarding, hideOnLive, hideOnFinished, hideOnAbandoned, children } = props;

  let shouldHide;

  switch (status) {
    case ProjectLifeCycleStatus.DISCOVERY:
      shouldHide = hideOnDiscovery;
      break;
    case ProjectLifeCycleStatus.ONBOARDING:
      shouldHide = hideOnOnBoarding;
      break;
    case ProjectLifeCycleStatus.LIVE:
      shouldHide = hideOnLive;
      break;
    case ProjectLifeCycleStatus.FINISHED:
      shouldHide = hideOnFinished;
      break;
    case ProjectLifeCycleStatus.ABANDONED:
      shouldHide = hideOnAbandoned;
      break;
    default:
      shouldHide = false;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return shouldHide ? null : <>{children}</>;
}

HideByProjectStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ProjectLifeCycleStatus)).isRequired,
  hideOnDiscovery: PropTypes.bool,
  hideOnOnBoarding: PropTypes.bool,
  hideOnLive: PropTypes.bool,
  hideOnFinished: PropTypes.bool,
  hideOnAbandoned: PropTypes.bool,
  children: PropTypes.node,
};

HideByProjectStatus.defaultProps = {
  hideOnDiscovery: false,
  hideOnOnBoarding: false,
  hideOnLive: false,
  hideOnFinished: false,
  hideOnAbandoned: false,
  children: null,
};

export default HideByProjectStatus;
