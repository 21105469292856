import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    propertyLabel: {},
    propertyValue: {},
    red: {
      color: 'red',
    },
    green: {
      color: 'green',
    },
  }),
);

export default useStyles;
