import React, { FC, useState } from 'react';
import { Company, Project } from '@mayple/types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';
import { logos } from '../../../app/logos';
import BriefReportAnswerSet from './BriefReportAnswerSet';
import BriefReportBasicDetails from './BriefReportBasicDetails';

import useStyles from './style';

type BriefReportProps = {
  project: Project | null | undefined;
  isPrintMode?: boolean;
  hideHeader?: boolean;
  allClosed?: boolean;
  classes?: Record<string, string>;
};

/**
 * @param project
 * @param classes
 * @param isPrintMode - To create a shadow DOM for the print to PDF feature, with some minor changes.
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
const BriefReport: FC<BriefReportProps> = React.forwardRef((props, ref: React.LegacyRef<HTMLDivElement | null>) => {
  const { project, isPrintMode = false, hideHeader = false, allClosed = false } = props;

  const classes = useStyles(props);

  const { deepBrief = [], company = {} as Company } = project || ({} as Project);

  const { name, displayImageUrl } = company;

  const [toExpand, setToExpand] = useState(() => {
    // open default basicBrief - only if allClosed === false
    const toExpandTemp: Record<string, boolean> = {
      basicBrief: !allClosed,
    };

    deepBrief.forEach(({ answerSetUuid }) => {
      toExpandTemp[answerSetUuid] = false;
    });

    return toExpandTemp;
  });

  /**
   * Changes the "expanded" state of each expansion panel by uuid.
   * @param key
   * @returns {function(...[*]=)}
   */
  const handlePanelChange = (key: string): (() => void) => {
    if (isPrintMode) {
      return () => {
        /* Do nothing */
      };
    }

    return () => {
      setToExpand((prevToExpand) => ({
        ...prevToExpand,
        [key]: !prevToExpand[key],
      }));
    };
  };

  return (
    <div className={classNames(classes.container, { [classes.printContainer]: isPrintMode })}>
      {/* @ts-ignore */}
      <div ref={ref} style={isPrintMode ? { padding: 12, background: colors.site_background_light } : {}}>
        {!hideHeader && (
          <Grid container alignItems="center" className={classes.company}>
            <Grid item>
              <Avatar src={displayImageUrl} className={classes.avatar} />
            </Grid>
            <Grid item>
              <TextDisplay variant="h4" bold>
                {name}
              </TextDisplay>
              <TextDisplay variant="h6" colorOverride={colors.black_light}>
                Business brief
              </TextDisplay>
            </Grid>
          </Grid>
        )}

        <BriefReportBasicDetails
          project={project}
          isPrintMode={isPrintMode}
          onPanelChange={handlePanelChange}
          closePanel={allClosed}
          classes={classes}
        />

        {deepBrief && deepBrief.length > 0 && (
          <BriefReportAnswerSet
            deepBrief={deepBrief}
            isPrintMode={isPrintMode}
            toExpand={toExpand}
            onPanelChange={handlePanelChange}
            classes={classes}
          />
        )}

        {isPrintMode && (
          <a href="https://www.mayple.com" target="_blank" rel="noreferrer noopener">
            <Grid container justifyContent="center" alignItems="center" style={{ color: colors.black }}>
              Made with <img style={{ height: 48 }} src={logos.logoDarkPadding} alt="" />
            </Grid>
          </a>
        )}
      </div>
    </div>
  );
});

export default BriefReport;
