import { useQuery } from '@apollo/react-hooks';
import { Project } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProjectAppliedPackagesQuery } from 'growl-graphql/dist/queries/project/ProjectAppliedPackagesQuery';

export interface ProjectAppliedPackages {
  project: Project | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: { projectId: number | null | undefined } | undefined) => Promise<ApolloQueryResult<any>>;
}
const useProjectAppliedPackages = (projectId: number | null | undefined): ProjectAppliedPackages => {
  const { data, loading, error, refetch } = useQuery(ProjectAppliedPackagesQuery.query, {
    variables: { projectId },
    skip: !projectId,
  });

  return {
    project: data?.project || ({} as Project),
    loading,
    error,
    refetch,
  };
};

export default useProjectAppliedPackages;
