import React, { useEffect, useMemo, useState } from 'react';
import { Package, PackageKey } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ProjectPackagesAutocompleteProps } from './types';
import getPackageKey from './logic';

import { LabelValueProps } from '../../../../../../../fe_common/client/app/types';
import useSearchForPackages from '../../../../../../../fe_common/client/hooks/packages/useSearchForPackages';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const ProjectPackagesAutocomplete: React.FC<ProjectPackagesAutocompleteProps> = (props) => {
  const classes = useStyles(props);
  const { searchForPackageKey, onSelectPackage } = props;

  const { packages, loading } = useSearchForPackages(searchForPackageKey);

  const [selectedPackage, setSelectedPackage] = useState<LabelValueProps | null | undefined>(null);
  const [selectedPackageKey, setSelectedPackageKey] = useState<PackageKey | null | undefined>(null);

  const allPackages = useMemo(
    () =>
      (packages || []).map((p: Package) => ({
        label: p.presentation?.name || p.title,
        value: p.uuid,
        isDeprecated: p.isDeprecated,
      })) as LabelValueProps[],
    [packages],
  );

  const onPackageChangeHandler = (_event: React.ChangeEvent, value: any): void => {
    // console.log('event', event);
    // console.log('value', value);
    // console.log('reason', reason);
    // console.log('details', details);

    const newPackage = packages.find((p) => p.uuid === value?.value);
    const newPackageKey = getPackageKey(newPackage);

    setSelectedPackageKey(newPackageKey);
    setSelectedPackage(value);
  };

  useEffect(() => {
    onSelectPackage?.(selectedPackage, selectedPackageKey);
  }, [onSelectPackage, selectedPackage, selectedPackageKey]);

  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label="Select a package" variant="outlined" />}
      options={allPackages}
      getOptionLabel={(option: LabelValueProps) => option.label}
      getOptionSelected={(option: LabelValueProps, selectedValue: LabelValueProps) =>
        option.value === selectedValue.value
      }
      value={selectedPackage}
      className={classes.root}
      loading={loading}
      // @ts-ignore
      onChange={onPackageChangeHandler}
    />
  );
};

export default ProjectPackagesAutocomplete;
