import React, { FC, useMemo } from 'react';
import { Field } from 'redux-form';

import CheckboxCard from '../../Registration/CheckboxCard';
import { validateMaximumSelectedValues, validateNonEmptyArray } from '../../../../services/validation';

interface WizardCheckboxCardGroupFieldProps {
  name: string;
  enums: Record<string, any>[];
  isRequired?: boolean;
  maximumSelectedValues?: number;
}

/**
 * will create a 3 in a row cards, that are multi choice checkboxes, add them them to redux form
 * @param props
 * @returns {*}
 * @constructor
 */
const WizardCheckboxCardGroupField: FC<WizardCheckboxCardGroupFieldProps> = (props) => {
  const { isRequired = false, maximumSelectedValues = -1, name, enums, ...rest } = props;

  const validator = useMemo(() => {
    const tempValidator = [];
    if (isRequired) {
      tempValidator.push(validateNonEmptyArray());
    }

    if (maximumSelectedValues > -1) {
      tempValidator.push((value: number) => validateMaximumSelectedValues(value, maximumSelectedValues));
    }

    return tempValidator;
  }, [isRequired, maximumSelectedValues]);

  return (
    <Field
      component={CheckboxCard}
      name={name}
      enums={enums}
      validate={validator}
      maximumSelectedValues={maximumSelectedValues}
      {...rest}
    />
  );
};

export default WizardCheckboxCardGroupField;
