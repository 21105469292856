import { Answers } from '../consts';
import { Question, Option } from '../types';

export const getYesOrNoRadioGroupOptions = ({ withMaybe }: { withMaybe: boolean } = { withMaybe: false }): Option[] => {
  const options = [
    { label: 'Yes', value: Answers.YES },
    { label: 'No', value: Answers.NO },
  ];

  if (withMaybe) {
    options.push({ label: "I don't know", value: Answers.I_DONT_KNOW });
  }

  return options;
};

export const getPartyNameRadioGroupOptions = (): Option[] => [
  { label: 'Mayple', value: Answers.MAYPLE },
  { label: 'Client', value: Answers.CLIENT },
  { label: 'Expert', value: Answers.EXPERT },
];

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const getQuestionList = (_isComponentCustomerFacing: boolean, marketerName: string): Question[] => {
  const marketerNameFormatted = marketerName?.replace(new RegExp(escapeRegExp('_'), 'g'), ' ');

  return [
    {
      name: 'isExpertBeingReplaced',
      title: `Is ${marketerNameFormatted} being replaced?`,
      tooltip: 'Let us know if this is an Expert replacement.',
      options: getYesOrNoRadioGroupOptions(),
    },
    {
      name: 'isClientHaveOtherProject',
      title: 'Does the client have another project? (or are they moving to another project)',
      tooltip: 'Do they have a different project with us or are they starting a different project with us?',
      options: getYesOrNoRadioGroupOptions(),
    },
    {
      name: 'stopProjectInitiator',
      title: 'Who decided to stop the project?',
      options: getPartyNameRadioGroupOptions(),
    },
    {
      name: 'isClientHappyWithExpert',
      title: 'Was the client happy with the expert?',
      options: getYesOrNoRadioGroupOptions({ withMaybe: true }),
    },
    {
      name: 'isClientHappyWithMayple',
      title: 'Was the client happy with Mayple?',
      options: getYesOrNoRadioGroupOptions({ withMaybe: true }),
    },
    {
      name: 'isPackageRecurring',
      title: 'Was this a one time package?',
      tooltip: 'For example; Marketing strategy or an audit.',
      options: getYesOrNoRadioGroupOptions({ withMaybe: true }),
    },
    {
      name: 'isProjectSeasonal',
      title: 'Was the project seasonal?',
      tooltip: 'Does this company only sell at a specific time of year and only need marketing then?',
      options: getYesOrNoRadioGroupOptions({ withMaybe: true }),
    },
    {
      name: 'isClientHavingBusinessIssues',
      title: 'Is the client having business issues?',
      tooltip: 'Stock issues, closing down, etc.',
      options: getYesOrNoRadioGroupOptions({ withMaybe: true }),
    },
  ];
};
