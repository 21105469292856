import React, { FC } from 'react';
import { AdminAccessibleMarketersPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleMarketersPagedQuery';

import { columns, initialSort } from './logic';

import QueryTable from '../../../../../../../fe_common/client/components/molecules/QueryTable';

const MarketersTable: FC = () => (
  <QueryTable
    queryClass={AdminAccessibleMarketersPagedQuery}
    columns={columns}
    entityKey="marketersPaged"
    initialSorted={initialSort}
  />
);

export default MarketersTable;
