import React, { Provider } from 'react';

// eslint-disable-next-line react/display-name, @typescript-eslint/explicit-module-boundary-types
export const withProvider = (ProviderComponent: Provider<any>) => (WrappedComponent: any) =>
  // eslint-disable-next-line react/display-name,func-names
  function (props: any) {
    return (
      <ProviderComponent value={{}}>
        <WrappedComponent {...props} />
      </ProviderComponent>
    );
  };

export default withProvider;
