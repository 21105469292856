import React from 'react';

import { useCronofyAuthenticationResult } from '../CronofyIntegrationManager/hooks';

const CronofyResultPage: React.FC = () => {
  useCronofyAuthenticationResult();

  return null;
};

export default CronofyResultPage;
