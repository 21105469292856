import isArray from 'lodash/isArray';

import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import isFloat from 'validator/lib/isFloat';
import isIn from 'validator/lib/isIn';
import isURL from 'validator/lib/isURL';
import matches from 'validator/lib/matches';

import { isValidPhoneNumber } from 'react-phone-number-input';

const isEmpty = (value) => value === undefined || value === null || value === '';

const join = (rules) => (value, data) => rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export const validateTrue = (value) => value !== true && 'Must be true';

export const validateEmail = (value) => !isEmpty(value) && !isEmail(value) && 'Invalid email address';

export const validateUrl = (value) =>
  !isEmpty(value) && !isURL(value, { require_tld: true }) && 'Invalid URL. Correct format: http://www.website.com';

export const validateLinkedInUrl = (value) =>
  !isEmpty(value) &&
  !(
    isURL(value, {
      protocols: ['http', 'https'],
      host_whitelist: [/^.*linkedin\.com$/i],
    }) &&
    (matches(value, /linkedin\.com\/in\/.+/i) || matches(value, /linkedin\.com\/company\/.+/i))
  ) &&
  'Invalid LinkedIn URL. Correct format: http://linkedin.com/in/YourName or http://linkedin.com/company/Name';
export const validateRequired = (value) => isEmpty(value) && 'Required';

export const validateNonEmptyArray = (array) => !(array && isArray(array) && array.length > 0) && 'Required';

export const validateMinLength = (min) => (value) =>
  !isEmpty(value) && value.length < min && `Must be at least ${min} characters`;

export const validateMaxLength = (max) => (value) =>
  !isEmpty(value) && value.length > max && `Must be no more than ${max} characters`;

export const validateInteger = (value) => !isInt(`${value}`) && 'Must be an integer';

export const validateIntegerBetween = (min, max) => (value) =>
  !(isInt(`${value}`) && value >= min && value <= max) && `Must be between ${min} and ${max}`;

export const validateFloatBetween = (min, max) => (value) =>
  !(isFloat(`${value}`) && value >= min && value <= max) && `Must be between ${min} and ${max}`;

export const validateIntegerGreaterThan = (min) => (value) =>
  !(isInt(`${value}`) && value > min) && `Must be greater than ${min}`;

export const validateIntegerLessThan = (max) => (value) =>
  !(isInt(`${value}`) && value < max) && `Must be less than ${max}`;

export const validateIntegerGreaterThanOrEqualTo = (min) => (value) =>
  !(isInt(`${value}`) && value >= min) && `Must be greater than or equal to ${min}`;

export const validateIntegerLessThanOrEqualTo = (max) => (value) =>
  !(isInt(`${value}`) && value <= max) && `Must be less than or equal to ${max}`;

export const validateFloat = (value) => !isFloat(`${value}`) && 'Must be a float';

export const validateOneOf = (values) => (value) => !isIn(value, values) && `Must be one of: ${values.join(', ')}`;

export const validateMatch = (field) => (value, data) => data && value !== data[field] && 'Must match';

export const createValidator =
  (rules) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };

export const validatePhoneNumber = (value) => (isValidPhoneNumber(value) ? undefined : 'Invalid phone number');

export const validateRegex =
  (regex, errorMessage = 'expression is invalid') =>
  (value) =>
    value && !regex.test(value) ? `Invalid: ${errorMessage}` : undefined;

export const validateAlphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const validateNumeric = (value) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);

export const validateNonEmptyObject = (obj) => {
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (obj[key] !== undefined) {
      return false;
    }
  }
  return true;
};

export const validateNonFalsyObject = (obj) => {
  // will return true if empty or all key values are falsy
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (obj[key]) {
      return false;
    }
  }
  return true;
};

export const validateRadioCardsWithSlider = (key) => (obj) => {
  const values = Object.values(obj);
  let invalid = true;
  for (const value of values) {
    if (value[key]) {
      invalid = false;
    }
  }

  return invalid ? 'Required' : false;
};
