import React from 'react';
import { TFunction } from 'i18next';
import { SharedFileCategory, SharedFileCreate } from '@mayple/types';

import { FileListFile } from '../../molecules/FilesList/types';
import { DialogProps } from '../../display/Dialog/types';

export enum FileUploadDialogTabs {
  GoogleSlides = 'GoogleSlides',
  FileStack = 'FileStack',
}

export type OnFileUpload = (f: SharedFileCreate) => void;

export type OnFileDelete = (f: FileListFile | null) => void;

export interface UseGoogleSlidesInputReturn {
  slideUrl: string;
  googleSlideInputError: string | null;
  onSlideUrlChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onUploadGoogleSlide: () => void;
}

export interface UseFileStackUploadReturn {
  filestackOptions: Record<string, any>;
  onFileUpload: (f: Record<string, any>) => void;
}

export interface FileUploadDialogProps extends DialogProps {
  onFileUpload: OnFileUpload;
  filename?: string;
  filestackApiKey: string;
  loading?: boolean;
  isMaypleFile?: boolean;
  category?: SharedFileCategory;
  tabs?: FileUploadDialogTabs[];
  defaultTab?: FileUploadDialogTabs;
  filestackOptions?: Record<string, any>;
  callout?: React.ReactNode;
  error?: string | null | undefined;
  clearError?: () => void;
}

export interface FileUploadData {
  t: TFunction;
  tab: string;
  setTab: (e: React.ChangeEvent<unknown>, t: string) => void;
  onFilestackFileUpload: (filestackResponse: Record<string, any>) => any;
  onSlideUrlChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onUploadGoogleSlide: () => void;
  slideUrl: string;
  googleSlideInputError: string | null;
  filestackOptions: Record<string, any>;
}
