import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { TextDisplay } from '../../../display';
import { stringHashCode } from '../../../../services/utils';
import { AnswerProps } from './types';

const splitLinesRegex = /\n/gim;

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    row: {
      wordBreak: 'break-word',
    },
  })
);

const StringAnswer: FC<AnswerProps> = (props) => {
  const { answer } = props;
  const classes = useStyles();
  const ans = answer?.values[0]?.value || '';

  const ansRows = ans.split(splitLinesRegex);

  return (
    <>
      {ansRows.map((row: string, index: number) => (
        <TextDisplay
          key={`StringAnswer-${stringHashCode(row + index.toString())}`}
          variant="subtitle1"
          classes={{ root: classes.row }}
        >
          {row}
        </TextDisplay>
      ))}
    </>
  );
};

export default StringAnswer;
