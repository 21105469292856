import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import MultiCheckbox, { MultiCheckboxProps } from '../../Registration/MultiCheckbox';

type MultiCheckboxInputProps = WrappedFieldProps & MultiCheckboxProps;

const MultiCheckboxInput: React.FC<MultiCheckboxInputProps> = (props) => {
  const { input, meta, helperText, value, ...rest } = props;
  const { error, touched, invalid } = meta;

  const hasError = touched && invalid;
  const errorMessage = hasError && error ? error : undefined;

  return (
    <MultiCheckbox
      name={input?.name}
      value={input?.value || value}
      onChange={input?.onChange}
      error={hasError}
      helperText={errorMessage || helperText}
      {...rest}
    />
  );
};

export default MultiCheckboxInput;
