import React from 'react';
import { User } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import AlternativeEmailAddressesEditor from '../../../../../../fe_common/client/components/editables/user/AlternativeEmailAddressesEditor';
import LoadingPlaceholder from '../../../../../../fe_common/client/components/atoms/LoadingPlaceholder';
import QueryErrorMessage from '../../../../../../fe_common/client/components/common/QueryErrorMessage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

type UserDetailsEditFormProps = {
  user: User | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  onSuccess?: () => Promise<void>;
};

const UserDetailsEditForm: React.FC<UserDetailsEditFormProps> = (props) => {
  const classes = useStyles();
  const { user, loading, error, onSuccess } = props;

  if (!user) {
    return null;
  }

  return (
    <div className={classes.root}>
      {!user && <CalloutMessage message="User date wasn't found..." type="error" margin />}
      {loading && !error && <LoadingPlaceholder />}
      {!loading && error && <QueryErrorMessage error={error} />}
      <AlternativeEmailAddressesEditor
        userId={user.id}
        alternativeEmailAddresses={user.alternativeEmailAddresses}
        onSuccess={onSuccess}
        enableEdit
      />
    </div>
  );
};

export default UserDetailsEditForm;
