import { TFunction } from 'i18next';
import {
  Participant,
  ProjectExistingMarketingEvaluationReportV1Details,
  ProjectExistingMarketingEvaluationReportV2Details,
  ProjectExistingMarketingEvaluationReportV3Details,
  SharedFile,
  SharedFileCategory,
} from '@mayple/types';

import { QuestionMeta } from './types';
import {
  periodInUnitsFormatter,
  numberOutOfNumberFormatter,
  percentageFormatter,
  currencyFormatter,
} from '../../../../../fe_common/client/services/ExportToCSV/formatters';

export const getScoreQuestions = (
  t: TFunction,
  marketerReport: ProjectExistingMarketingEvaluationReportV1Details | null | undefined,
): QuestionMeta[] => [
  { label: t('questions.duration'), value: marketerReport?.duration, formatter: periodInUnitsFormatter() },
  {
    label: t('questions.improvementPotential'),
    value: marketerReport?.improvementPotential,
    formatter: percentageFormatter(),
  },
  {
    label: t('questions.numberOfDaysToImplement'),
    value: marketerReport?.numberOfDaysToImplement,
    formatter: periodInUnitsFormatter('days'),
  },
  { label: t('questions.activeCampaigns'), value: marketerReport?.activeCampaigns },
  { label: t('questions.activeFlows'), value: marketerReport?.activeFlows },
  {
    label: t('questions.overallAccountHealthScore'),
    value: marketerReport?.overallAccountHealthScore,
    formatter: numberOutOfNumberFormatter(),
  },
  {
    label: t('questions.accountSetupScore'),
    value: marketerReport?.accountSetupScore,
    formatter: numberOutOfNumberFormatter(),
  },
  {
    label: t('questions.deliverabilityScore'),
    value: marketerReport?.deliverabilityScore,
    formatter: numberOutOfNumberFormatter(),
  },
  { label: t('questions.formsScore'), value: marketerReport?.formsScore, formatter: numberOutOfNumberFormatter() },
  {
    label: t('questions.integrationsScore'),
    value: marketerReport?.integrationsScore,
    formatter: numberOutOfNumberFormatter(),
  },
  { label: t('questions.listsScore'), value: marketerReport?.listsScore, formatter: numberOutOfNumberFormatter() },
  {
    label: t('questions.testingAndExperimentsScore'),
    value: marketerReport?.testingAndExperimentsScore,
    formatter: numberOutOfNumberFormatter(),
  },
];

export const getYesNoQuestions = (
  t: TFunction,
  marketerReport: ProjectExistingMarketingEvaluationReportV1Details | null | undefined,
): QuestionMeta[] => [
  {
    label: t('questions.isTrackingTransactionsIntegratedWithMailchimp'),
    value: marketerReport?.isTrackingTransactionsIntegratedWithMailchimp,
  },
  {
    label: t('questions.isTrackingRevenueIntegratedWithMailchimp'),
    value: marketerReport?.isTrackingRevenueIntegratedWithMailchimp,
  },
  { label: t('questions.isUsingTagsAndGroups'), value: marketerReport?.isUsingTagsAndGroups },
  {
    label: t('questions.isUsingMailchimpOfferedSegments'),
    value: marketerReport?.isUsingMailchimpOfferedSegments,
  },
  {
    label: t('questions.isUsingPredictiveSegments'),
    value: marketerReport?.isUsingPredictiveSegments,
  },
  { label: t('questions.isUsingEventsAPI'), value: marketerReport?.isUsingEventsAPI },
  {
    label: t('questions.isOptDownVsOnlyOptingOut'),
    value: marketerReport?.isOptDownVsOnlyOptingOut,
  },
  {
    label: t('questions.isAudiencePermissionReminderClear'),
    value: marketerReport?.isAudiencePermissionReminderClear,
  },
  {
    label: t('questions.isUsingCustomerJourneyBuilder'),
    value: marketerReport?.isUsingCustomerJourneyBuilder,
  },
  {
    label: t('questions.isEmailDomainAuthenticated'),
    value: marketerReport?.isEmailDomainAuthenticated,
  },
  { label: t('questions.hasABorMVTTest'), value: marketerReport?.hasABorMVTTest },
  { label: t('questions.hasAccessibleEmails'), value: marketerReport?.hasAccessibleEmails },
  { label: t('questions.isUsingTemplates'), value: marketerReport?.isUsingTemplates },
  {
    label: t('questions.isUsingCustomCodedTemplates'),
    value: marketerReport?.isUsingCustomCodedTemplates,
  },
  { label: t('questions.isUsingNewEmailEditor'), value: marketerReport?.isUsingNewEmailEditor },
  { label: t('questions.isUsingSMCBlocks'), value: marketerReport?.isUsingSMCBlocks },
  {
    label: t('questions.hasUsedContentOptimizer'),
    value: marketerReport?.hasUsedContentOptimizer,
  },
  {
    label: t('questions.isUsingBenchmarkingReports'),
    value: marketerReport?.isUsingBenchmarkingReports,
  },
  {
    label: t('questions.isUsingComparativeReports'),
    value: marketerReport?.isUsingComparativeReports,
  },
  {
    label: t('questions.isDownloadingOrExportingReports'),
    value: marketerReport?.isDownloadingOrExportingReports,
  },
];

export const getExistingMarketingEvaluationReportFile = (
  participant: Participant | null | undefined,
): SharedFile | undefined =>
  (participant?.sharedFiles || ([] as SharedFile[])).find(
    ({ category }) => category === SharedFileCategory.EXISTING_MARKETING_EVALUATION_REPORT_FILE,
  );

export const getScoreQuestionsHCV2 = (
  t: TFunction,
  reportDetails: ProjectExistingMarketingEvaluationReportV2Details | null | undefined,
): QuestionMeta[] => [
  { label: t('questions.duration'), value: reportDetails?.duration, formatter: periodInUnitsFormatter() },
  {
    label: t('questions.improvementPotential'),
    value: reportDetails?.improvementPotential,
    formatter: percentageFormatter(),
  },
  {
    label: t('questions.overallAccountHealthScore'),
    value: reportDetails?.overallAccountHealthScore,
    formatter: numberOutOfNumberFormatter(),
  },
];

export const getScoreQuestionsHCV3 = (
  t: TFunction,
  reportDetails: ProjectExistingMarketingEvaluationReportV3Details | null | undefined,
): QuestionMeta[] => [
  {
    label: t('questions.clientAvgSpendLastThreeMonths'),
    value: reportDetails?.clientAvgSpendLastThreeMonths,
    formatter: currencyFormatter(),
  },
  {
    label: t('questions.clientAvgCPCLastThreeMonths'),
    value: reportDetails?.clientAvgCPCLastThreeMonths,
    formatter: currencyFormatter(),
  },
  {
    label: t('questions.clientAvgCTRLastThreeMonths'),
    value: reportDetails?.clientAvgCTRLastThreeMonths,
    formatter: percentageFormatter(),
  },
  {
    label: t('questions.improvementPotential'),
    value: reportDetails?.improvementPotential,
    formatter: percentageFormatter(),
  },
];
