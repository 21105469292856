import { toNiceFloat } from '../../../../../services/utils';

const sortDebts = (a, b) => {
  if (a.repaid === b.repaid) {
    return 0;
  } else if (a.repaid) {
    return 1;
  }
  return -1;
};

const reverseFundAccountData = (arr) => {
  const finalArr = arr ? [...arr] : [];
  return finalArr.reverse();
};

export const getSortedDebts = (debts) => reverseFundAccountData(debts).sort(sortDebts);

export const mergeWithdrawalsAndDeposits = (withdrawals, deposits) => {
  const w = withdrawals || [];
  const d = deposits || [];
  return [...w, ...d].sort((a, b) => {
    const { created: createdA } = a;
    const { created: createdB } = b;
    const utcA = new Date(createdA).getTime();
    const utcB = new Date(createdB).getTime();
    return utcA > utcB ? 1 : -1;
  });
};

export const getFormattedAmount = (amount, depositSource, withdrawalTarget) => {
  if (depositSource) {
    return `+${toNiceFloat(amount)}`;
  } else if (withdrawalTarget) {
    return `${toNiceFloat(-amount)}`;
  }
  return null;
};
