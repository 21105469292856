import React from 'react';
import { AccountType } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import useMultipleMarketersAvailabilityData from './useMultipleMarketersAvailabilityData';

import Section, { SectionContent } from '../../../../../../../fe_common/client/components/atoms/Section';
import AvailabilityViewer from '../../../../../../../fe_common/client/components/integrations/calendars/AvailabilityViewerV2';
import LoadingPlaceholder from '../../../../../../../fe_common/client/components/atoms/LoadingPlaceholder';
import QueryErrorMessage from '../../../../../../../fe_common/client/components/common/QueryErrorMessage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {},
  }),
);

const MultipleMarketersAvailability: React.FC = () => {
  const classes = useStyles();

  const { mainAccountsGroup, marketersAccountsGroup, loading, error } = useMultipleMarketersAvailabilityData();

  return (
    <Section noBorder noTopMargin title="Multiple Marketers Availability" classes={{ root: classes.sectionRoot }}>
      <SectionContent>
        {loading && <LoadingPlaceholder />}
        {!loading && error && <QueryErrorMessage error={error} message="Error getting Internal Team data." />}
        {!loading && (
          <AvailabilityViewer
            mainAccountsGroup={{ accounts: mainAccountsGroup, accountsGroupSelectionType: AccountType.INTERNAL_TEAM }}
            defaultFilter={{ accounts: marketersAccountsGroup }}
          />
        )}
      </SectionContent>
    </Section>
  );
};

export default MultipleMarketersAvailability;
