import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
    },
    stepWizardRoot: {
      width: '100%',
    },
  }),
);

export const useStepStyle = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      minHeight: 400,
      backgroundColor: colors.white,
      padding: '4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
      boxShadow: `0px 2px 5px ${colors.blackOpacity(0.1)}`,
    },
    loadingContainer: {
      width: '100%',
    },
    loading: {
      display: 'block',
      width: '100%',
      height: '0.5rem',
    },
    restartButton: {
      alignSelf: 'flex-end',
      textAlign: 'right',
    },
  }),
);

export default useStyles;
