import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Button } from '../../inputs';
import { iconCodes } from '../../../app/icons';

class CopyToClipboardButton extends Component {
  state = {
    didCopy: false,
  };

  componentWillUnmount() {
    // Prevent the waiting setState from being executed after component un-mount
    if (this.timeOutId) {
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/clearTimeout
      clearTimeout(this.timeOutId);
    }
  }

  render() {
    const { didCopy } = this.state;
    const { text, label, onValueCopied, onAfterValueCopied } = this.props;

    return (
      <CopyToClipboard text={text}>
        <Button
          id="copy-to-clipboard-button"
          label={didCopy ? 'COPIED' : label}
          icon={didCopy ? iconCodes.accept_outline : iconCodes.copy}
          color="secondary"
          variant={didCopy ? 'contained' : 'text'}
          style={{ marginLeft: 8 }}
          onClick={() => {
            // prevent double clicking
            if (!didCopy) {
              this.setState({ didCopy: true });
              onValueCopied();
              // save the time out id
              this.timeOutId = setTimeout(() => {
                this.setState({ didCopy: false });
                onAfterValueCopied();
              }, 4000);
            }
          }}
        />
      </CopyToClipboard>
    );
  }
}

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  onValueCopied: PropTypes.func,
  onAfterValueCopied: PropTypes.func,
};

CopyToClipboardButton.defaultProps = {
  label: 'COPY',
  onValueCopied: () => {},
  onAfterValueCopied: () => {},
};

export default CopyToClipboardButton;
