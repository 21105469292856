import { Project } from '@mayple/types';

import { config } from '../../../../config';

const useHasAssignedMarketer = (project: Project): boolean => {
  const marketerId = project?.participants?.[0]?.marketerId;
  return marketerId != null && marketerId !== config.maamId;
};

export default useHasAssignedMarketer;
