import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionContent: {
      padding: 24,
    },
    listItemSecondaryProperty: {
      display: 'block',
    },
    formContainer: {
      marginTop: 24,
    },
    divider: {
      margin: '12px auto',
    },
  }),
);

export default useStyles;
