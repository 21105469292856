import React, { FC } from 'react';
import { ProjectCyclePauseDuration } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import PauseProjectButton from './PauseProjectButton';
import CyclePauseDurationsType from './consts';
import { getMutationClass, getMutationVars } from './logic';
import ProjectCyclePeriodViewer from '../../../../components/cpanel/components/molecules/ProjectCyclePeriods/ProjectCyclePeriodViewer';

import useMutation from '../../../../../fe_common/client/hooks/useMutation';
import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    root: { maxWidth: 800, margin: '32px auto' },
  }),
);

interface PauseDurationsProps {
  title: string;
  name: string;
  projectId: number;
  isServicePaused: boolean;
  durationType: CyclePauseDurationsType;
  durations?: Array<ProjectCyclePauseDuration>;
  showPauseResumeButton?: boolean;
  onSuccess?: () => void;
}

const PauseDurations: FC<PauseDurationsProps> = ({
  title,
  name,
  projectId,
  isServicePaused,
  durationType,
  durations = [],
  showPauseResumeButton = false,
  onSuccess = () => {
    /* Do Nothing */
  },
}) => {
  const classes = useStyles();

  const { mutate, loading } = useMutation(getMutationClass(isServicePaused, durationType));

  const handlePauseResumeOnClick = async () => {
    try {
      const vars = getMutationVars(projectId, isServicePaused);

      await mutate(vars);
      onSuccess();
    } catch (e) {
      clientLogger.error(e);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={8}>
        <TextDisplay variant="h6" bold>
          {title}
        </TextDisplay>
      </Grid>
      <Grid item xs={4}>
        {showPauseResumeButton && (
          <PauseProjectButton
            name={`pauseProjectButton-${name}`}
            isServicePaused={isServicePaused}
            onPauseResumeOnClick={handlePauseResumeOnClick}
            disabled={loading}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <ProjectCyclePeriodViewer name={name} durations={durations} />
      </Grid>
    </Grid>
  );
};

export default PauseDurations;
