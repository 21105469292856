import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import { StarsVoteProps } from '../../../inputs/StarsVote/types';
import StarsVote from '../../../inputs/StarsVote';

type WWizardStarsVoteInputProps = WrappedFieldProps & StarsVoteProps;

const WizardStarsVoteInput: React.FC<WWizardStarsVoteInputProps> = (props) => {
  const { input, name, ...rest } = props;
  // const { error, touched, invalid } = meta;

  // const hasError = touched && invalid;
  // const errorMessage = hasError && error ? error : undefined;

  return <StarsVote name={input?.name || name} value={input?.value} onChange={input?.onChange} {...rest} />;
};

export default WizardStarsVoteInput;
