import { Participant, ProjectMeetingType, SharedFile, SharedFileCategory } from '@mayple/types';

import { ProjectMarketingEvaluationImplementationDetailsData } from './types';
import { formatDate } from '../../../../app/utils';
import useUploadParticipantSharedFile from '../../../../hooks/useUploadParticipantSharedFile';

import useProjectScheduledMeetings from '../../../../../fe_common/client/hooks/project/useProjectScheduledMeetings';

const useProjectMarketingEvaluationImplementationDetailsData = (
  projectId: number,
  participant: Participant,
): ProjectMarketingEvaluationImplementationDetailsData => {
  const { meetings: mcPostImplementationMeetings, refetch: refetchMeetings } = useProjectScheduledMeetings(
    projectId,
    ProjectMeetingType.SCHEDULED_POST_IMPLEMENTATION_MEETING,
  );

  const mcPostImplementationMeeting = mcPostImplementationMeetings?.[0];
  const mcPostImplementationMeetingDate: string = mcPostImplementationMeeting
    ? `scheduled at ${formatDate(mcPostImplementationMeeting.start)} UTC`
    : `Meeting wasn't set`;

  const sharedFiles = participant?.sharedFiles || ([] as SharedFile[]);

  const summaryDeckFiles =
    sharedFiles.filter(
      ({ category }) => category === SharedFileCategory.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION_SUMMARY_DECK,
    ) || [];

  const { loading: savingFile, onFileUpload } = useUploadParticipantSharedFile({
    participant,
    sharedFileCategory: SharedFileCategory.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION_SUMMARY_DECK,
  });

  const refetch = () => {
    refetchMeetings();
  };

  return {
    postImplementationMeetingDate: mcPostImplementationMeetingDate,
    summaryDeckFiles,
    savingFile,
    onFileUpload,
    refetch,
  };
};

export default useProjectMarketingEvaluationImplementationDetailsData;
