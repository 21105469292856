import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { getGradientColor } from '../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '1rem',
    },
    timezoneSelectorRoot: {
      marginBottom: '2rem',
    },
    errorMessage: {
      marginTop: '1rem',
    },
    available: {
      backgroundColor: getGradientColor('green', 10),
    },
    unavailable: {
      backgroundSize: '400%',
      backgroundImage: 'repeating-linear-gradient(-45deg, #f4f0ec, #f4f0ec 2px, transparent 0px, transparent 10px)',
    },
    unavailable0: {
      backgroundPosition: '0px 0px',
    },
    unavailable15: {
      backgroundPosition: '-5px 0px',
    },
    unavailable30: {
      backgroundPosition: '-10px 0px',
    },
    unavailable45: {
      backgroundPosition: '-15px 0px',
    },
  })
);

export default useStyles;
