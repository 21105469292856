/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useState } from 'react';
import { ProjectBillingPointBehavior, ProjectUpdateInput } from '@mayple/types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';

import { ProjectBillingPointBehaviorEditorProps } from './types';

import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { Button } from '../../../../../fe_common/client/components/inputs';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import {
  getBillingBehaviorTextForAdmin,
  isProjectBillingPointBehaviorOptionAllowed,
} from '../../../../../fe_common/client/logic/billing';
import { PROJECT_BILLING_POINT_PAIRS, ProjectBillingPoint } from '../../../../../fe_common/client/logic/billing/types';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import useUpdateProject from '../../../../hooks/useUpdateProject';

const ProjectBillingPointBehaviorEditor: FC<ProjectBillingPointBehaviorEditorProps> = (props) => {
  const { project, onSuccess } = props;
  const [editable, setEditable] = useState(false);
  const { updateProject, saving } = useUpdateProject();

  const handleOnSelect = async (key: ProjectBillingPoint, value: ProjectBillingPointBehavior) => {
    if (!value || value === project[key]) {
      return;
    }

    const projectUpdate: ProjectUpdateInput = { [key]: value };

    clientLogger.debug(`You submitted:\n\n${JSON.stringify({ projectId: project?.id, projectUpdate }, null, 2)}`);

    await updateProject(project.id, projectUpdate, onSuccess);
  };

  return (
    <div>
      <Section
        title="Project Billing Point Behavior"
        subTitle="Configuration of client billing in different project stages"
        noBorder
        layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
      >
        <SectionContent>
          {ProjectBillingPointBehavior && (
            <>
              <Button
                variant="outlined"
                label={editable ? 'FINISHED' : 'EDIT'}
                onClick={() => setEditable(!editable)}
              />
              {editable && (
                <CalloutMessage
                  title="Attention!"
                  message="Before changing any of those values, make sure you know what you are doing."
                  type="warning"
                  margin
                />
              )}
              <div>
                {PROJECT_BILLING_POINT_PAIRS.map((option) => (
                  <div key={`${option}-ProjectBillingPointBehaviorEditor-item`}>
                    <FormControl style={{ minWidth: 300, width: '100%', margin: '16px 0' }}>
                      <InputLabel htmlFor="age-native-simple">{`${option.label} configuration`}</InputLabel>
                      <MuiSelect
                        value={project[option.value as ProjectBillingPoint]}
                        disabled={saving || !editable}
                        onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                          handleOnSelect(option.value, event.target.value as ProjectBillingPointBehavior)
                        }
                        native
                        fullWidth
                      >
                        <option value="" />
                        {Object.values(ProjectBillingPointBehavior).map((o) => (
                          <option
                            key={`ProjectBillingPointBehavior-item-${option}-${o}`}
                            value={o}
                            disabled={!isProjectBillingPointBehaviorOptionAllowed(option.value, o)}
                          >
                            {getBillingBehaviorTextForAdmin(o)}
                          </option>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </div>
                ))}
              </div>
            </>
          )}
        </SectionContent>
      </Section>
    </div>
  );
};

export default ProjectBillingPointBehaviorEditor;
