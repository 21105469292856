import React from 'react';
import { TaskStatus } from '@mayple/types';
import StepWizard from 'react-step-wizard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { AssessmentWizardProps } from './types';
import AssessmentThanksMessage from '../AssessmentThanksMessage';
import { AssessmentSubTask } from '../../types';
import getFormComponentBySubTaskType from '../../logic';
import useMarketerAssessmentWizardData from '../../hooks/useMarketerAssessmentWizardData';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const filterPendingTasks = ({ taskStatus }: { taskStatus: TaskStatus }) => taskStatus === TaskStatus.PENDING;

const AssessmentWizard: React.FC<AssessmentWizardProps> = (props) => {
  const classes = useStyles();
  const {
    subTasks,
    showThanksMessage = false,
    skipFormHandler,
    submitFormHandler,
    onAssessmentDoneClickHandler,
  } = props;

  const { setWizardFormContentContent } = useMarketerAssessmentWizardData();

  const stepWizardChildren = [
    ...(subTasks || []).filter(filterPendingTasks).map((subTask: AssessmentSubTask) => {
      // eslint-disable-next-line no-underscore-dangle
      const FormComponent = getFormComponentBySubTaskType(subTask.__typename);

      return (
        <FormComponent
          key={`marketerAssessmentForm-${subTask.projectId}-${subTask.uuid}`}
          name={`marketerAssessmentForm-${subTask.projectId}-${subTask.uuid}`}
          onSubmitForm={submitFormHandler}
          onSkipForm={skipFormHandler}
          subTask={subTask}
          setDialogContent={setWizardFormContentContent}
        />
      );
    }),
  ];

  if (showThanksMessage) {
    stepWizardChildren.push(
      <AssessmentThanksMessage
        key="AssessmentDialog-thanks"
        onClick={onAssessmentDoneClickHandler}
        setDialogContent={setWizardFormContentContent}
      />,
    );
  }

  return (
    <div className={classes.root}>
      <StepWizard>{stepWizardChildren}</StepWizard>
    </div>
  );
};

export default AssessmentWizard;
