// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.py5SyprMQqk5w5Z2zXLl {
  border: 1px solid #dee2e3;
}

.acqSST_53P6BC78Rx_1I {
  width: 100%;
}

.eaQzdup38U4lqPgfc0ZD {
  display: none;
}

.GKFr1RPm6tJDB33WXTko {
  padding: 8px 0;
  text-align: center;
  color: #887b77;
  box-sizing: border-box;
}

.GKFr1RPm6tJDB33WXTko:hover {
  cursor: pointer;
  background-color: rgb(225, 225, 225);
}

.eaQzdup38U4lqPgfc0ZD:checked + .GKFr1RPm6tJDB33WXTko {
  color: #fff;
  background-color: #534ff2;
}
`, "",{"version":3,"sources":["webpack://./src/fe_common/client/components/atoms/SkillLevelButtons/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".card-container {\n  border: 1px solid #dee2e3;\n}\n\n.label-container {\n  width: 100%;\n}\n\n.card-input-element {\n  display: none;\n}\n\n.card-input {\n  padding: 8px 0;\n  text-align: center;\n  color: #887b77;\n  box-sizing: border-box;\n}\n\n.card-input:hover {\n  cursor: pointer;\n  background-color: rgb(225, 225, 225);\n}\n\n.card-input-element:checked + .card-input {\n  color: #fff;\n  background-color: #534ff2;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `py5SyprMQqk5w5Z2zXLl`;
export { _1 as "card-container" };
var _2 = `acqSST_53P6BC78Rx_1I`;
export { _2 as "label-container" };
var _3 = `eaQzdup38U4lqPgfc0ZD`;
export { _3 as "card-input-element" };
var _4 = `GKFr1RPm6tJDB33WXTko`;
export { _4 as "card-input" };
export default ___CSS_LOADER_EXPORT___;
