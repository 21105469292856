import React, { useContext } from 'react';

import { IMarketingPlanContext, MarketingPlanProviderProps } from './types';
import { MarketingPlanDispatch } from './marketingPlanReducer';
import useInitMarketingPlanProviderData from './useInitMarketingPlanProviderData';

const MarketingPlanContext = React.createContext<IMarketingPlanContext | undefined>(undefined);
const MarketingPlanDispatchContext = React.createContext<MarketingPlanDispatch | undefined>(undefined);

const MarketingPlanProvider: React.FC<MarketingPlanProviderProps> = ({ initialValues, children }) => {
  const [state, dispatch] = useInitMarketingPlanProviderData(initialValues);

  return (
    <MarketingPlanContext.Provider value={state}>
      <MarketingPlanDispatchContext.Provider value={dispatch}>{children}</MarketingPlanDispatchContext.Provider>
    </MarketingPlanContext.Provider>
  );
};

function useMarketingPlanState(): IMarketingPlanContext {
  const context = useContext(MarketingPlanContext);
  if (context === undefined) {
    throw new Error('useMarketingPlanState must be used within a MarketingPlanProvider');
  }
  return context;
}

function useMarketingPlanDispatch(): MarketingPlanDispatch {
  const context = useContext(MarketingPlanDispatchContext);
  if (context === undefined) {
    throw new Error('useMarketingPlanDispatch must be used within a MarketingPlanProvider');
  }
  return context;
}

export { MarketingPlanProvider, useMarketingPlanState, useMarketingPlanDispatch };
