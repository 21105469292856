import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '100%',
      '& button': {
        marginLeft: 8,
        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
  })
);

export default useStyles;
