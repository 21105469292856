import React, { FC } from 'react';
import { ManualPaymentAgreement } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
}));

type ManualPaymentAgreementViewProps = {
  manualPaymentAgreement: ManualPaymentAgreement;
};

const ManualPaymentAgreementView: FC<ManualPaymentAgreementViewProps> = (props) => {
  const classes = useStyles();
  const { manualPaymentAgreement } = props;

  const { active, details, adminSigner, modified } = manualPaymentAgreement;

  if (!manualPaymentAgreement) {
    return <CalloutMessage message="No manual payment agreement for this company." />;
  }

  return (
    <div className={classes.root}>
      <TextDisplay className={`${active ? 'green' : 'red'}-text`}>
        Status: {active ? 'ACTIVE' : 'NOT ACTIVE'}
      </TextDisplay>
      <TextDisplay>Agreement Details:</TextDisplay>
      <p style={{ padding: 8, background: '#e8e8e8' }}>{details}</p>
      <TextDisplay>Edited by: {adminSigner}</TextDisplay>
      {modified && <TextDisplay>Last updated: {new Date(modified).toLocaleString()}</TextDisplay>}
    </div>
  );
};

export default ManualPaymentAgreementView;
