import React, { FC } from 'react';
import { Project, ProjectMarketerOpportunity } from '@mayple/types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Header from './Header';
import ConfirmDialog from './ConfirmDialog';
import useProjectParticipantsLogic from './logic';
import AppliedOpportunitiesMarketers from '../AppliedOpportunitiesMarketers';
import { ENTITY_TYPE } from '../../../../app/consts';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import EntitySearchAutoComplete from '../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';

import useStyles from './style';

interface ProjectParticipantsProps {
  project: Project | null | undefined;
  opportunities: ProjectMarketerOpportunity[];
  refetchProjectData: () => void;
  readOnly?: boolean;
}

const ProjectParticipants: FC<ProjectParticipantsProps> = (props) => {
  const { opportunities, project, refetchProjectData, readOnly = false } = props;
  const classes = useStyles(props);

  const {
    selectedMarketer,
    marketerMatches,
    refetchProjectMarketerMatches,
    confirmDialogOpen,
    closeConfirmDialog,
    handleChangeParticipant,
    handleSelectAppliedOpportunity,
    onSelectMarketerFromSearch,
    saving,
    queryFilterBy,
  } = useProjectParticipantsLogic(project, refetchProjectData);

  const skipOpportunityStage: boolean = !!project?.flowConfiguration?.skipOpportunityStage?.value || false;

  return (
    <div>
      <Header
        project={project}
        readOnly={readOnly}
        refetchProject={() => {
          refetchProjectData();
          refetchProjectMarketerMatches();
        }}
      />

      {!readOnly && (
        <div className={classes.selectNewMarketer}>
          <Card>
            <CardContent>
              <TextDisplay variant="h6" bold gutterBottom>
                Search for a marketer
              </TextDisplay>
              <EntitySearchAutoComplete
                entityType={ENTITY_TYPE.MARKETER}
                helperText="Search for a marketer you want to select for the client approval for this project"
                onSelect={onSelectMarketerFromSearch}
                queryFilterBy={queryFilterBy}
                filterBy={({ isVetted }) => isVetted}
              />
            </CardContent>
          </Card>
          {!skipOpportunityStage && (
            <Card>
              <CardContent>
                <AppliedOpportunitiesMarketers
                  opportunities={opportunities}
                  marketerMatches={marketerMatches}
                  onSelectOpportunity={handleSelectAppliedOpportunity}
                  project={project}
                />
              </CardContent>
            </Card>
          )}
        </div>
      )}

      {!readOnly && (
        <ConfirmDialog
          onConfirm={handleChangeParticipant}
          open={confirmDialogOpen}
          onClose={closeConfirmDialog}
          introduceYourself={selectedMarketer.introduceYourself}
          saving={saving}
        />
      )}
    </div>
  );
};

export default ProjectParticipants;
