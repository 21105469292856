import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 24,
    paddingBottom: 24,
    display: 'flex',
    gap: '1rem',
    width: '100%',
  },
  headerProjectDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.2rem',
    width: '100%',
  },
  headerProjectUtilities: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  headerProjectActions: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
  },
  projectLaunchDate: {},
  inlineItem: {
    paddingLeft: '0.4rem',
  },
  alignedInlineItem: {
    display: 'flex',
    alignItems: 'center',
  },
  projectDetailsHeader: {
    marginRight: '1rem',
  },
  headerDetailsContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  viewModeText: {
    fontSize: '2rem',
  },
}));

export default useStyles;
