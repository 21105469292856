import React, { useMemo } from 'react';

import getProjectDeepBriefPreloadedAnswersProperties from './logic';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';

import useStyles from './style';
import PropertiesList from '../../../../../fe_common/client/components/display/PropertiesList';

interface ProjectDeepBriefPreloadedAnswersProps {
  data: Record<string, any>;
}

const ProjectDeepBriefPreloadedAnswers: React.FC<ProjectDeepBriefPreloadedAnswersProps> = (props) => {
  const classes = useStyles();
  const { data } = props;

  const projectDeepBriefPreloadedAnswersProperties = useMemo(
    () => getProjectDeepBriefPreloadedAnswersProperties(classes),
    [classes],
  );

  return (
    <Section title="Project Deep Brief Preloaded Answers" noBorder noTopMargin classes={{ root: classes.root }}>
      <SectionContent>
        {data && (
          <PropertiesList
            id="projectDeepBriefPreloadedAnswers"
            data={data}
            propertiesMeta={projectDeepBriefPreloadedAnswersProperties}
            classes={{
              propertyLabel: classes.propertyLabel,
              propertyValue: classes.propertyValue,
            }}
          />
        )}
      </SectionContent>
    </Section>
  );
};

export default ProjectDeepBriefPreloadedAnswers;
