import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: '2rem',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: 1342,
    },
    availabilityViewer: {
      flexGrow: 1,
    },
    filterOptions: {
      minWidth: 350,
    },
    spinnerWrapper: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  })
);

export default useStyles;
