/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import getSmWidth from './logic';
import { LabelValueProps } from '../../../../app/types';

export interface MultiCheckboxProps {
  name?: string | undefined;
  value: Record<string, boolean> | undefined;
  enums: LabelValueProps[];
  onChange?: (newValue: Record<string, boolean> | undefined) => void;
  maxSelections?: number;
  columns?: number;
  helperText?: React.ReactNode;
  error?: boolean;
}
const MultiCheckbox: FC<MultiCheckboxProps> = (props) => {
  const { name, enums, value, onChange, columns = 2, helperText, error = false } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCheckboxCheck = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, isChecked: boolean, cb: LabelValueProps) => {
      const valuesObj = {
        ...value,
      };

      if (isChecked) {
        valuesObj[cb.value] = true;
        onChange?.(valuesObj);
      } else if (valuesObj[cb.value] === true) {
        valuesObj[cb.value] = false;
        onChange?.(valuesObj);
      }
    },
    [onChange, value],
  );

  const getOnChangeHandler = (item: LabelValueProps) => (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) =>
    onCheckboxCheck(e, isChecked, item);

  const finalEnums = useMemo(() => (enums || []).filter(({ isDeprecated = false }) => !isDeprecated), [enums]);

  const smWidth = useMemo(() => getSmWidth(finalEnums, columns), [columns, finalEnums]);

  return (
    <div role="button">
      <Grid container>
        {(finalEnums || []).map((item) => {
          const checked = !!value?.[item.value];
          return (
            <Grid item xs={12} sm={smWidth} key={`${name}-${item.value}`}>
              <FormControlLabel
                control={<Checkbox onChange={getOnChangeHandler(item)} checked={checked} />}
                // disabled={!checked && maxedOut}
                label={item.label}
                style={{ height: 40 }}
              />
            </Grid>
          );
        })}
      </Grid>
      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};

export default MultiCheckbox;
