import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { SharedEnumerationsQuery } from 'growl-graphql/dist/queries/shared/SharedEnumerationsQuery';

export enum SharedEnumerationsKeys {
  ppcBillingModelPlans = 'ppcBillingModelPlans',
}

interface Options {
  enumsKey?: string;
}

const useSharedEnumerationsQuery = (
  options?: Options
): {
  loading: boolean;
  enums: Record<string, any>;
  error: ApolloError | undefined;
} => {
  const { enumsKey } = options || {};
  const { loading, data, error } = useQuery(SharedEnumerationsQuery.query);
  let enums;

  if (typeof enumsKey === 'string' && !loading && data?.sharedEnumerations?.[enumsKey]) {
    enums = data.sharedEnumerations[enumsKey];
  } else if (!loading) {
    enums = data?.sharedEnumerations || {};
  }

  return { enums, loading, error };
};

export default useSharedEnumerationsQuery;
