import React, { FC, useEffect, useState } from 'react';
import { UserType, WireTransferReport } from '@mayple/types';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { useQuery } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IdentityDetailsQuery } from 'growl-graphql/dist/queries/IdentityDetailsQuery';
import { ReportWireTransferByCompanyMutation } from 'growl-graphql/dist/mutations/ReportWireTransferByCompanyMutation';

import { ReportWireTransferFormProps, WireTransferReportData } from './types';
import WizardSteps from './steps';

import useMutation from '../../../../../fe_common/client/hooks/useMutation';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const ReportWireTransferForm: FC<ReportWireTransferFormProps> = (props) => {
  const { company, onSuccess } = props;
  const classes = useStyles();
  const [userType, setUserType] = useState(UserType.NORMAL_USER);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { data, loading, error } = useQuery(IdentityDetailsQuery.query);
  const { mutate: reportWireTransferByCompany } = useMutation(ReportWireTransferByCompanyMutation);

  const wizardFormName = 'reportWireTransfer';

  const initialValues = {
    paymentDate: new Date().toISOString(),
  };

  const valuesToWatch = ['wireAmountPaid', 'paymentDate'];

  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  useEffect(() => {
    if (loading || error) {
      return;
    }

    const newUserType = data?.identityDetails?.userType || UserType.NORMAL_USER;
    setUserType(newUserType);
  }, [loading, error, data]);

  const validate = (values: WireTransferReportData) => {
    const errors = {};
    const {
      wireAmountPaid,
      verificationAmount,
      paymentConfirmationNumber,
      paymentSourceBankAccountNumber,
      paymentInvoiceID,
    } = values;

    if (JSON.stringify(wireAmountPaid) !== JSON.stringify(verificationAmount)) {
      // @ts-ignore
      errors.verificationAmount = 'Verification amount does not match reported amount';
    }

    if (!paymentConfirmationNumber && !paymentSourceBankAccountNumber && !paymentInvoiceID) {
      // @ts-ignore
      errors.paymentConfirmationNumber = 'Please provide information for at least one of fields';
      // @ts-ignore
      errors.paymentSourceBankAccountNumber = 'Please provide information for at least one of fields';
      // @ts-ignore
      errors.paymentInvoiceID = 'Please provide information for at least one of fields';
    }

    return errors;
  };

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleFormSubmit: FormSubmitHandler<WireTransferReportData> = async (formValues, _dispatch, formProps) => {
    const { wireAmountPaid, description, paymentSourceBankAccountNumber, paymentConfirmationNumber, paymentDate } =
      formValues;

    const hasCompany = !!company;
    const hasFundAccount = !!(company?.fundAccount || false);
    const isAdminUser = [UserType.ADMIN_USER].includes(userType);

    if (!hasCompany || !hasFundAccount || !isAdminUser || !wireAmountPaid?.value) {
      return;
    }

    const wireTransferReport: WireTransferReport = {
      amount: wireAmountPaid.value,
      currency: wireAmountPaid.currency,
      description: description || 'Wire transfer reported manually by admin user with no description.',
      paymentSourceBankAccountNumber,
      paymentConfirmationNumber,
      paymentDate,
    };

    const variables = {
      companyId: company.id,
      wireTransferReport,
    };

    await reportWireTransferByCompany({ variables });

    closeConfirmationDialog();
    formProps?.destroy?.();
    onSuccess();
  };

  return (
    <div className={classes.root}>
      <WizardForm
        form={wizardFormName}
        initialValues={initialValues}
        valuesToWatch={valuesToWatch}
        steps={WizardSteps}
        validate={validate}
        onShowConfirmation={openConfirmationDialog}
        showConfirmationDialog={showConfirmationDialog}
        closeConfirmationDialog={closeConfirmationDialog}
        company={company}
        currency={company?.fundAccount?.currency}
        // @ts-ignore
        onSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default ReportWireTransferForm;
