import React from 'react';
import { useTranslation } from 'react-i18next';

import WizardSteps from './steps';
import { FormValues } from './types';
import { AssessmentFormProps } from '../../types';
import useMailchimpAuditAssessmentFormData from './useMailchimpAuditAssessmentFormData';

import { useDeviceInfo } from '../../../../../hooks';
import { TextDisplay } from '../../../../display';
import CalloutMessage from '../../../../common/CalloutMessage';
import { WizardForm } from '../../../../organisms/WizardForm';
import EntityAvatar from '../../../../atoms/EntityAvatar';
import RemoteSubmitButton from '../../../../atoms/RemoteSubmitButton';
import { TRANSLATION_NS } from '../../../../../app/consts';

import useStyles from './styles';

const MailchimpAuditAssessmentForm: React.FC<AssessmentFormProps> = (props) => {
  const classes = useStyles(props);

  const { name: wizardFormName, subTask, subHeadline } = props;
  const { marketerId, projectId } = subTask;
  const { isMobile } = useDeviceInfo();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'AssessmentDialog.MarketerMailchimpAuditAssessmentForm',
  });

  const initialValues: Partial<FormValues> = {
    projectId,
    marketerId,
  };

  const { saving, displayImageUrl, marketerName, formSubmitHandler, errorMessage /* onSkipButtonClickHandler, */ } =
    useMailchimpAuditAssessmentFormData(props);

  return (
    <div className={classes.root}>
      <div className={classes.formHeader}>
        <EntityAvatar
          classes={{
            root: classes.entityAvatarRoot,
            avatar: classes.entityAvatarAvatar,
            primary: classes.entityAvatarPrimary,
          }}
          size={isMobile ? 'md' : 'xl'}
          avatarImageUrl={displayImageUrl}
          primaryTextType="overline"
          primary={marketerName}
        />
        <TextDisplay variant="h5" className={classes.headline}>
          {t('headline', { marketerName })}
        </TextDisplay>
        {!!subHeadline && (
          <TextDisplay variant="body2" className={classes.subHeadline}>
            {t('subHeadline')}
          </TextDisplay>
        )}
      </div>

      <WizardForm
        form={wizardFormName}
        // @ts-ignore
        steps={WizardSteps}
        // @ts-ignore
        onSubmit={formSubmitHandler}
        loading={saving}
        initialValues={initialValues}
        watchAllValues
      />

      {!!errorMessage && <CalloutMessage type="error" message={errorMessage} margin />}

      <div className={classes.formActions}>
        <RemoteSubmitButton
          form={wizardFormName}
          label={t('submitButton', 'submit')}
          color="primary"
          variant="contained"
          className={classes.nextButton}
          disabled={saving}
          loading={saving}
        />
      </div>
    </div>
  );
};

export default MailchimpAuditAssessmentForm;
