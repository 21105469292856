import {
  AD_SPEND_LABELS_LOOKUP,
  CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP,
  COUNTRIES_LABELS_LOOKUP,
  INDUSTRIES_LABELS_LOOKUP,
  LANGUAGES_LABELS_LOOKUP,
  MARKETER_TYPE_LABELS_LOOKUP,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  PRODUCT_COST_RANGE_LABELS_LOOKUP,
  PROJECT_SPECIAL_TYPE_LABELS_LOOKUP,
  TARGET_KPI_LABELS_LOOKUP,
  TARGETED_COUNTRIES_LABELS_LOOKUP,
} from '../../../../../fe_common/client/app/enums';
import { INDUSTRIES_CATEGORIES_LABELS } from '../../../../../fe_common/client/app/consts';

export const emptyResolver = (): null => null;

export const enumResolver = (value: string, lookup: Record<string, string>): string | null => {
  if (!lookup) {
    return null;
  }
  return lookup[value] || 'N/A';
};

// The arrow sign '->' indicates on a filterFieldName that points to a specific field inside a list.
const filterFieldsMapper = [
  {
    fieldName: 'workingGroup',
    filterFieldName: 'matchingSettings.matchableForProjectSpecialTypes',
    resolver: enumResolver,
    lookup: PROJECT_SPECIAL_TYPE_LABELS_LOOKUP,
  },
  {
    fieldName: 'marketingSkills',
    filterFieldName: 'marketerCapabilities.marketingSkills',
    resolver: enumResolver,
    lookup: MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  },
  {
    fieldName: 'marketerType',
    filterFieldName: 'marketerProfile.marketerType',
    resolver: enumResolver,
    lookup: MARKETER_TYPE_LABELS_LOOKUP,
  },
  {
    fieldName: 'productBusinessModelExpertise',
    filterFieldName: 'marketerCapabilities.productBusinessModelExpertise',
    resolver: enumResolver,
    lookup: PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  },
  {
    fieldName: 'targetKPIExpertise',
    filterFieldName: 'marketerCapabilities.targetKPIExpertise',
    resolver: enumResolver,
    lookup: TARGET_KPI_LABELS_LOOKUP,
  },
  {
    fieldName: 'languageExpertise',
    filterFieldName: 'marketerCapabilities.languageExpertise',
    resolver: enumResolver,
    lookup: LANGUAGES_LABELS_LOOKUP,
  },
  {
    fieldName: 'countryExpertise',
    filterFieldName: 'marketerCapabilities.countryExpertise',
    resolver: enumResolver,
    lookup: TARGETED_COUNTRIES_LABELS_LOOKUP,
  },
  {
    fieldName: 'marketerLocation',
    filterFieldName: 'marketerProfile.contactDetails.postalAddress.country',
    resolver: enumResolver,
    lookup: COUNTRIES_LABELS_LOOKUP,
  },
  {
    fieldName: 'monthlyBudgetRangeAmount',
    filterFieldName: 'marketerCapabilities.monthlyBudgetRangeAmount',
    resolver: enumResolver,
    lookup: AD_SPEND_LABELS_LOOKUP,
  },
  {
    fieldName: 'industryExpertiseCategory',
    filterFieldName: 'marketerCapabilities.industryExpertise->industryCategory',
    resolver: enumResolver,
    lookup: INDUSTRIES_CATEGORIES_LABELS,
  },
  {
    fieldName: 'industryExpertiseSubCategory',
    filterFieldName: 'marketerCapabilities.industryExpertise->industrySubCategory',
    resolver: enumResolver,
    lookup: INDUSTRIES_LABELS_LOOKUP,
  },
  {
    fieldName: 'minimumProjectFeeAmount',
    filterFieldName: 'matchingSettings.minimumProjectFeeAmount',
    resolver: emptyResolver,
  },
  {
    fieldName: 'successStoriesIndustryCategory',
    filterFieldName: 'marketerProfile.successStories->industry.industryCategory',
    resolver: enumResolver,
    lookup: INDUSTRIES_CATEGORIES_LABELS,
  },
  {
    fieldName: 'successStoriesIndustrySubCategory',
    filterFieldName: 'marketerProfile.successStories->industry.industrySubCategory',
    resolver: enumResolver,
    lookup: INDUSTRIES_LABELS_LOOKUP,
  },
  {
    fieldName: 'successStoriesSkillsUsed',
    filterFieldName: 'marketerProfile.successStories->skillsUsed',
    resolver: enumResolver,
    lookup: MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  },
  {
    fieldName: 'numbersOfTotalProjectsAssigned',
    filterFieldName: 'numbersOfTotalProjectsAssigned',
    resolver: emptyResolver,
  },
  {
    fieldName: 'numberOfLiveProjectsAssigned',
    filterFieldName: 'numberOfLiveProjectsAssigned',
    resolver: emptyResolver,
  },
  {
    fieldName: 'isVetted',
    filterFieldName: 'isVetted',
    resolver: emptyResolver,
  },
  {
    fieldName: 'availableForProjects',
    filterFieldName: 'matchingSettings.availableForProjects',
    resolver: emptyResolver,
  },
  {
    fieldName: 'enableMatching',
    filterFieldName: 'matchingSettings.enableMatching',
    resolver: emptyResolver,
  },
  {
    fieldName: 'matchableForProjectSpecialTypes',
    filterFieldName: 'matchingSettings.matchableForProjectSpecialTypes',
    resolver: emptyResolver,
    lookup: PROJECT_SPECIAL_TYPE_LABELS_LOOKUP,
  },
  {
    fieldName: 'productCostRange',
    filterFieldName: 'marketerCapabilities.productCostRange',
    resolver: enumResolver,
    lookup: PRODUCT_COST_RANGE_LABELS_LOOKUP,
  },
  {
    fieldName: 'clientStageExpertise',
    filterFieldName: 'marketerCapabilities.clientStageExpertise',
    resolver: enumResolver,
    lookup: CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP,
  },
];

export default filterFieldsMapper;
