import React, { FC } from 'react';
import { Marketer } from '@mayple/types';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';

import getMarketerDetails from './logic';

import { stringHashCode } from '../../../../../../../fe_common/client/services/utils';
import LabelValue from '../../../../../../../fe_common/client/components/display/LabelValue';

import useStyles from './styles';

interface MarketerResultCardProps {
  marketer: Marketer;
  classes?: Record<string, string>;
}

const MarketerResultCard: FC<MarketerResultCardProps> = (props) => {
  const { marketer } = props;
  const classes = useStyles(props);

  const { id, name, description, displayImageUrl } = marketer;

  const marketerDetails = getMarketerDetails(marketer);

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Link to={`/marketers/${id}`}>{`${name} (${id})`}</Link>}
        action={
          <Tooltip classes={{ tooltip: classes.toolTip }} title={description || ''} placement="left-start">
            <HelpIcon fontSize="large" color="primary" className={classes.toolTipPopper} />
          </Tooltip>
        }
      />
      <CardActionArea>
        <CardMedia className={classes.media} image={displayImageUrl} title={name} />
      </CardActionArea>
      <CardContent>
        {marketerDetails.map(({ label, value }) => (
          <LabelValue key={`marketer-details-${stringHashCode(label)}`} label={label} value={value} />
        ))}
      </CardContent>
    </Card>
  );
};

export default MarketerResultCard;
