import { ApolloError } from 'apollo-client';
import { ProjectUpdateInput } from '@mayple/types';
import { UpdateProjectWithOperationResultMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectWithOperationResultMutation';

import { useMutation } from '../../fe_common/client/hooks';
import { handleClientError } from '../../fe_common/client/services/logger';
import { EntityOperationResponseExecutionResult } from '../../fe_common/client/app/types';

type UpdateProjectData = {
  updateProject: (
    projectId: number,
    projectUpdate: ProjectUpdateInput,
    onSuccess?: () => Promise<any>,
  ) => Promise<void>;
  saving: boolean;
  error: ApolloError | undefined;
};

const useUpdateProject = (): UpdateProjectData => {
  const {
    mutate: updateProjectMutation,
    loading: saving,
    error,
  } = useMutation(UpdateProjectWithOperationResultMutation);

  const updateProject = async (
    projectId: number,
    projectUpdate: ProjectUpdateInput,
    onSuccess?: () => Promise<any>,
  ) => {
    try {
      const variables = {
        projectId,
        projectUpdate,
      };

      const result: EntityOperationResponseExecutionResult<'updateProjectWithOperationResult'> =
        await updateProjectMutation({
          variables,
        });

      if (result?.data?.updateProjectWithOperationResult?.success) {
        await onSuccess?.();
      }
    } catch (e) {
      handleClientError(e, 'Error updating project data.');
    }
  };

  return {
    updateProject,
    saving,
    error,
  };
};

export default useUpdateProject;
