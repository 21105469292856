import React, { FC, useMemo } from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ChipLabelPair, mapItemsToShow } from './logic';

import useStyles from './style';

interface ChipArrayProps {
  labels: ChipLabelPair[];
  defaultIcon?: React.ReactElement;
  variant?: ChipProps['variant'];
  size?: ChipProps['size'];
  maxChips?: number;
  showAvatar?: boolean;
}

const ChipArray: FC<ChipArrayProps> = (props) => {
  const classes = useStyles(props);
  const { labels, defaultIcon, variant = 'outlined', size = 'medium', maxChips = 0, showAvatar = true } = props;

  const [shownItems, hiddenItems] = useMemo(() => mapItemsToShow(labels, maxChips), [labels, maxChips]);

  const hiddenItemsTooltipTitle = useMemo(() => (hiddenItems.length > 0 ? hiddenItems.join(', ') : ''), [hiddenItems]);

  return (
    <div className={classes.root}>
      {shownItems &&
        shownItems.length > 0 &&
        shownItems.map(({ label, iconUrl }, index) => {
          const avatar = showAvatar && !!iconUrl ? <Avatar src={iconUrl} className={classes.avatar} /> : undefined;

          return (
            <Chip
              key={`${label}-${index}-ChipArray-item`}
              variant={variant}
              size={size}
              label={label}
              avatar={avatar}
              icon={!iconUrl ? defaultIcon : undefined}
              className={classes.chip}
            />
          );
        })}
      {maxChips > 0 && hiddenItems.length > 0 && (
        <Tooltip title={hiddenItemsTooltipTitle} placement="top">
          <Chip label={`+${hiddenItems.length}`} className={classes.chip} size={size} />
        </Tooltip>
      )}
      {(!shownItems || shownItems.length === 0) && (
        <Chip variant={variant} size={size} label="No info" className={classes.chip} icon={<InfoOutlinedIcon />} />
      )}
    </div>
  );
};

export default ChipArray;
