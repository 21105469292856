import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colorPalette } from '../../../app/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    avatar: {},
    chip: {
      color: colorPalette.gray[80],
      fontWeight: 500,
    },
  })
);

export default useStyles;
