import { ParticipantOnboardingStatus } from '@mayple/types';

export const isOnboardingStepDone = (stepToCheck, currentOnboardingStatus, inclusive = true) => {
  const orderedSteps = Object.values(ParticipantOnboardingStatus);

  const stepToCheckPosition = orderedSteps.findIndex((value) => stepToCheck === value);

  const currentStepPosition = orderedSteps.findIndex((value) => currentOnboardingStatus === value);

  return inclusive ? stepToCheckPosition <= currentStepPosition : stepToCheckPosition < currentStepPosition;
};
