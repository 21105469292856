import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import { config } from '../../../../config';
import { ExternalLink, TextDisplay } from '../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    inlineItem: {
      marginRight: '0.5rem',
    },
  }),
);

interface HubspotContactLinkProps {
  hubspotContactId: string | null | undefined;
  classes?: Record<string, string>;
}

// https://app.hubspot.com/contacts/4292856/record/0-1/87097551
const getHubspotContactLink = (hubspotPortalId: number | string, hubspotAccountId: number | string) =>
  `https://app.hubspot.com/contacts/${hubspotPortalId}/record/0-1/${hubspotAccountId}`;

const HubspotContactLink: React.FC<HubspotContactLinkProps> = (props) => {
  const classes = useStyles(props);

  const { hubspotContactId } = props;

  if (!hubspotContactId) {
    return null;
  }

  // const linkToHubspotContact = 'https://app.hubspot.com/contacts/4292856';
  const linkToHubspotContact = getHubspotContactLink(config.hubspotPortalId, hubspotContactId);

  return (
    <div className={classes.root}>
      <TextDisplay variant="subtitle1" inline className={classes.inlineItem}>
        {'Hubspot Contact: '}
      </TextDisplay>
      <ExternalLink href={linkToHubspotContact}>
        {linkToHubspotContact}
        <Tooltip title="Link to Hubspot deal" placement="top">
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </ExternalLink>
    </div>
  );
};

export default HubspotContactLink;
