import React, { useCallback, useState } from 'react';
import { ProjectSponsorPayment } from '@mayple/types';
import { StepWizardChildProps } from 'react-step-wizard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LoopIcon from '@material-ui/icons/Loop';

import { getIdListFromPayments } from '../logic';
import useMarkProjectSponsorPaymentsAsInvoicedMutation from '../useMarkProjectSponsorPaymentsAsInvoicedMutation';

import { Button } from '../../../../fe_common/client/components/inputs';

import { useStepStyle } from '../style';
import QueryErrorMessage from '../../../../fe_common/client/components/common/QueryErrorMessage';

interface PayInReportStep3Props extends Partial<StepWizardChildProps> {
  payments: ProjectSponsorPayment[];
  onRestart?: () => void;
  onSuccess?: () => void;
}

const PayInReportStep3: React.FC<PayInReportStep3Props> = (props) => {
  const classes = useStepStyle(props);
  const { payments, firstStep, onRestart, onSuccess } = props;

  const [approveFileDownload, setApproveFileDownload] = useState<boolean>(false);
  const onSuccessHandler = () => {
    onSuccess?.();
    firstStep?.();
  };

  const onRestartClickHandler = () => {
    onRestart?.();
    firstStep?.();
  };

  const { markProjectSponsorPaymentsAsInvoiced, saving, error } =
    useMarkProjectSponsorPaymentsAsInvoicedMutation(onSuccessHandler);

  const onMarkAllPaymentsAsInvoicedHandler = useCallback(async () => {
    const idList = getIdListFromPayments(payments);
    await markProjectSponsorPaymentsAsInvoiced(idList);
  }, [markProjectSponsorPaymentsAsInvoiced, payments]);

  return (
    <div id="step3" className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            style={{ maxWidth: 190 }}
            checked={approveFileDownload}
            onChange={() => {
              setApproveFileDownload(!approveFileDownload);
            }}
          />
        }
        label="I hearby approve I've D/L the CSV file and ready to mark all payments as invoiced."
      />
      <Button
        label="Mark all payments as Invoiced"
        onClick={onMarkAllPaymentsAsInvoicedHandler}
        color="primary"
        variant="contained"
        size="large"
        loading={saving}
        disabled={!approveFileDownload}
      />
      <div className={classes.restartButton}>
        <Button label="Restart" startIcon={<LoopIcon />} onClick={onRestartClickHandler} />
      </div>
      {error && (
        <QueryErrorMessage error={error} message="Error while trying to mark project sponsor payments as invoiced." />
      )}
    </div>
  );
};

export default PayInReportStep3;
