import React, { FC } from 'react';
import { Answer } from '@mayple/types';

import { AnswerProps } from './types';
import { TextDisplay } from '../../../display';
import { BRIEF_SUPPORTED_CURRENCIES } from '../../../../logic/deepBrief/consts';

const CurrencyAnswer: FC<AnswerProps> = (props) => {
  const { answer = {} as Answer } = props;

  const { values = [] } = answer;

  const [value = {}] = values;
  const { value: amount, currencyType: currency } = value;
  const { symbol = '' } = BRIEF_SUPPORTED_CURRENCIES.find((curr) => curr.value === currency) || {};
  return (
    <TextDisplay variant="subtitle1">
      {amount}
      {symbol}
    </TextDisplay>
  );
};

export default CurrencyAnswer;
