import React from 'react';
import PropTypes from 'prop-types';
import SwitchMUI from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

function Switch({ label, input, meta: { touched, invalid, error }, helperText, ...rest }) {
  return <FormControl>
    <FormControlLabel
      control={<SwitchMUI placeholder={label} checked={input.value} onChange={input.onChange} {...rest} />}
      label={label}
    />
    <FormHelperText error={touched && invalid}>{(touched && error) || helperText}</FormHelperText>
  </FormControl>
}

Switch.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default Switch;
