import { Package, PackageKeyInput } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { SearchForPackagesQuery } from 'growl-graphql/dist/queries/packages/SearchForPackagesQuery';

interface SearchForPackages {
  loading: boolean;
  error: ApolloError | undefined;
  packages: Package[];
  refetch: (variables?: { packageKey: PackageKeyInput } | undefined) => Promise<ApolloQueryResult<any>>;
}

const useSearchForPackages = (packageKey: PackageKeyInput): SearchForPackages => {
  const variables = {
    packageKey,
  };

  const { data, loading, error, refetch } = useQuery(SearchForPackagesQuery.query, { variables });

  return {
    loading,
    error,
    packages: data?.searchForPackages || ([] as Package[]),
    refetch,
  };
};

export default useSearchForPackages;
