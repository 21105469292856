import React, { FC } from 'react';
import { FeedComment, FeedLegend, FeedPost, Maybe } from '@mayple/types';
import Divider from '@material-ui/core/Divider';

import { useFeedState } from '../FeedContext';
import FeedPostComment from './FeedPostComment';
import FeedPostCommentEditor from '../FeedPostCommentEditor';

interface FeedPostCommentsProps {
  post: FeedPost;
  legend: FeedLegend;
  authorUuid: string;
  classes?: Record<string, string>;
}

const deletedCommentsFilter = (feedComment: Maybe<FeedComment>) => !(feedComment?.deleted || false);

const FeedPostComments: FC<FeedPostCommentsProps> = (props) => {
  const { post, legend, authorUuid } = props;
  const { uuid: postUuid } = post;
  const { newCommentPlaceholder, isEditable } = useFeedState();

  const comments = React.useMemo(
    () =>
      // Do not render "deleted" comments
      (post?.comments || []).filter(deletedCommentsFilter),
    [post]
  );

  // console.log('post', post);

  return (
    <div>
      <Divider />
      {isEditable && (
        <div>
          <FeedPostCommentEditor
            postUuid={postUuid}
            authorUuid={authorUuid}
            legend={legend}
            placeholder={newCommentPlaceholder}
          />
        </div>
      )}
      {isEditable && <Divider />}
      {comments.length > 0 &&
        comments.map((c: Maybe<FeedComment>) => (
          <FeedPostComment key={c?.uuid} legend={legend} comment={c as FeedComment} postUuid={postUuid} />
        ))}
    </div>
  );
};

export default FeedPostComments;
