import { Event } from 'react-big-calendar';

export interface AvailableMeetingSlotsSelectorProps {
  mode?: SelectionMode;
  requiredDurationInMinutes?: number;
  step?: number;
  minDate?: Date;
  maxDate?: Date;
  timezone?: string;
  showTimezoneSelector?: boolean;
  initialEvents?: AvailabilitySlot[];
  initialSelectionBoundaries?: AvailabilitySlot[];
  onEventsChange: (events: AvailabilitySlot[]) => void;
  onHasError?: (hasError: boolean) => void;
  classes?: Record<string, string>;
}

export interface AvailabilitySlot extends Event {
  start: Date;
  end: Date;
}

export enum SelectionMode {
  availability = 'availability',
  slots = 'slots',
  meeting = 'meeting',
}

export enum SelectionAction {
  SUBTRACT = 'SUBTRACT',
  ADD = 'ADD',
}

export type MeetingBoundaries = {
  timezone: string;
  initialSelectionBoundaries?: AvailabilitySlot[];
  minDate?: Date;
  maxDate?: Date;
};
