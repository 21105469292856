import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ReactJson from 'react-json-view';
import withStyles from '@material-ui/core/styles/withStyles';

import { getProjectProperties } from './logic';
import { getCrackleUrl } from '../../../../../../app/utils';
import ProjectBriefCloneAndConnect from '../../../molecules/ProjectBriefCloneAndConnect';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { PropertiesList, TextDisplay } from '../../../../../../../fe_common/client/components/display';

import { styles } from './style';

const ProjectInfo = ({ project = {}, classes }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const { id: projectId, companyId } = project;
    const linkToProject = `${getCrackleUrl()}/company/projects/${projectId}?actAsCompanyId=${companyId}`;

    // TODO: Show the id of the accounts
    const connectedFBHistory = get(project, 'marketingHistoryAccounts.facebookMarketingAdAccounts', []).length > 0;
    const connectedGAHistory = get(project, 'marketingHistoryAccounts.googleAdWordsClientAccounts', []).length > 0;

    setData({
      ...project,
      linkToProject,
      connectedFBHistory,
      connectedGAHistory,
    });
  }, [project, setData]);

  const projectProperties = getProjectProperties(classes);

  return (
    <>
      <Section
        title="General"
        subTitle="General information about the project"
        noBorder
        classes={{ root: classes.root }}
      >
        <SectionContent>
          {!project && <div> Could not get project data. </div>}
          {project && (
            <>
              <PropertiesList
                id="projectInfo"
                data={data}
                propertiesMeta={projectProperties}
                classes={{
                  propertyLabel: classes.propertyLabel,
                  propertyValue: classes.propertyValue,
                }}
              />
              <div>
                <TextDisplay>Full project data:</TextDisplay>
                <ReactJson src={project} collapsed name="Project Data" />
              </div>
            </>
          )}
        </SectionContent>
      </Section>
      <Section
        title="Deep Brief"
        subTitle="Additional information about the project"
        noBorder
        classes={{ root: classes.root }}
      >
        <SectionContent>
          <div>
            <ProjectBriefCloneAndConnect projectId={project.id} />
          </div>
        </SectionContent>
      </Section>
    </>
  );
};

ProjectInfo.propTypes = {
  project: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

ProjectInfo.defaultProps = {};

export default withStyles(styles)(ProjectInfo);
