import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '2rem',
    },
  }),
);

export const useFormStyles = makeStyles(() =>
  createStyles({
    root: {},
    formContainer: {
      maxWidth: 600,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    separator: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  }),
);
