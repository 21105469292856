import React, { FC } from 'react';
import { ProjectLifeCycleStatus } from '@mayple/types';

import LinkToProject from '../../../../../components/cpanel/components/atoms/Links/LinkToProject';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import { titleCase } from '../../../../../../fe_common/client/services/utils';

import useStyles from '../style';

interface ProjectIdProps {
  projectId: number;
  projectLifeCycleStatus: ProjectLifeCycleStatus;
}

const ProjectId: FC<ProjectIdProps> = (props) => {
  const classes = useStyles();

  const { projectId, projectLifeCycleStatus } = props;

  return (
    <TextDisplay variant="subtitle1" className={classes.inlineItem} inline>
      Project ID: <LinkToProject projectId={projectId}>({projectId})</LinkToProject>
      {' - '} <strong>{titleCase(projectLifeCycleStatus)}</strong>
    </TextDisplay>
  );
};

export default ProjectId;
