import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';

import ChipArray from '../../display/ChipArray';
import { mapEnumsToLabels } from '../../display/ChipArray/logic';

import useStyles from './style';

export interface ChipListProps {
  items: string[];
  labelsLookup: Record<string, any>;
  maxToShow?: number;
  size?: 'small' | 'medium';
  classes?: Record<string, string>;
}

const ChipList: FC<ChipListProps> = (props) => {
  const classes = useStyles(props);

  const { items, labelsLookup, maxToShow = 0, size = 'medium' } = props;

  if (!items || items.length === 0 || !labelsLookup) {
    return <Chip label="No info" size={size} classes={{ root: classes.chipStyle }} />;
  }
  const labels = mapEnumsToLabels(items, labelsLookup);

  return (
    <div className={classes.root}>
      <ChipArray variant="default" labels={labels} maxChips={maxToShow} size={size} />
    </div>
  );
};

export default ChipList;
