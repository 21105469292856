import React, { useState } from 'react';

export type ExpendingPanelData = {
  expandSearch: boolean;
  setExpandSearch: React.Dispatch<React.SetStateAction<boolean>>;
  handleExpandClick: () => void;
  collapseSearchForm: () => void;
  expandSearchForm: () => void;
};

const useExpendingPanel = (): ExpendingPanelData => {
  const [expandSearch, setExpandSearch] = useState(true);

  const collapseSearchForm = () => {
    setExpandSearch(false);
  };

  const expandSearchForm = () => {
    setExpandSearch(true);
  };

  const handleExpandClick = () => {
    setExpandSearch(!expandSearch);
  };

  return {
    expandSearch,
    setExpandSearch,
    handleExpandClick,
    collapseSearchForm,
    expandSearchForm,
  };
};

export default useExpendingPanel;
