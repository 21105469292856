import React, { FC, useState } from 'react';
import { Company, ManualPaymentAgreement } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { EditManualPaymentAgreementWizard } from './EditManualPaymentAgreementWizard';

import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
}));

type ManualPaymentAgreementEditorProps = {
  company: Company;
};

const ManualPaymentAgreementEditor: FC<ManualPaymentAgreementEditorProps> = (props) => {
  const classes = useStyles();
  const { company } = props;
  const manualPaymentAgreement = company?.fundAccount?.manualPaymentAgreement || ({} as ManualPaymentAgreement);

  const [enableEditManualPaymentAgreement, setEnableEditManualPaymentAgreement] = useState(false);
  const [editManualPaymentAgreementMode, setEditManualPaymentAgreementMode] = useState(false);

  const onSuccess = () => {
    setEnableEditManualPaymentAgreement(false);
    setEditManualPaymentAgreementMode(false);
  };

  if (!company || !company.fundAccount) {
    return <div>Error loading edit manual payment dialog. Contact dev team.</div>;
  }

  return (
    <div className={classes.root}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              style={{ maxWidth: 190 }}
              checked={enableEditManualPaymentAgreement}
              onChange={() => {
                setEnableEditManualPaymentAgreement(!enableEditManualPaymentAgreement);
              }}
            />
          }
          label="I want to edit this"
        />
        <Button
          variant="contained"
          color="primary"
          label="EDIT MANUAL PAYMENT AGREEMENT"
          onClick={() => {
            setEditManualPaymentAgreementMode(true);
          }}
          disabled={!enableEditManualPaymentAgreement}
        />
      </div>

      {editManualPaymentAgreementMode && enableEditManualPaymentAgreement && (
        <div style={{ background: '#fdffc6' }}>
          <TextDisplay bold variant="h5">
            Editing manual payment agreement for company {company.name} (id: {company.id})
          </TextDisplay>
          <EditManualPaymentAgreementWizard
            manualPaymentAgreement={manualPaymentAgreement}
            companyId={company.id}
            onSuccess={onSuccess}
            onCancel={onSuccess}
            showCancelButton
          />
        </div>
      )}
    </div>
  );
};

export default ManualPaymentAgreementEditor;
