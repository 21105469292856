import { CronofyAvailabilityProfiles } from '@mayple/types';

import { CronofyAvailabilityOptionsStyles, WeekDays } from '../types/cronofyTypes';
import { colorPalette, colors } from '../../../../app/theme';
import { LabelValueProps } from '../../../../app/types';
import { pairsToLabelLookup } from '../../../../app/enums';

export const Constants = {
  DEFAULT_ELEMENT_TARGET_ID: 'cronofy-availability-rules',
  DEFAULT_WORKING_DAY_START_TIME: '08:00',
  DEFAULT_WORKING_DAY_END_TIME: '20:00',
  DEFAULT_MEETING_DURATION: 60,
  DEFAULT_AVAILABILITY_RULE_ID: CronofyAvailabilityProfiles.work_hours, // 'work_hours',
  DEFAULT_KEYBOARD_SUPPORT_BASIC: 'basic',
  DEFAULT_CSS_CLASSNAME_PREFIX: 'availability-rules',
  DEFAULT_WEEK_START_DAY: WeekDays.monday,
};

export const Texts = {
  errorMessage: 'Error occurred while trying to load data...',
};

export const defaultStyles: CronofyAvailabilityOptionsStyles = {
  colors: {
    available: colorPalette.green['20'],
    unavailable: colors.white,
  },
  prefix: Constants.DEFAULT_CSS_CLASSNAME_PREFIX,
};

export const AVAILABILITY_RULES_PROFILES_LABELS: LabelValueProps[] = [
  { label: 'Work Hours', value: CronofyAvailabilityProfiles.work_hours },
  { label: 'Mailchimp', value: CronofyAvailabilityProfiles.mailchimp },
];
export const AVAILABILITY_RULES_PROFILES_LABELS_LOOKUP = pairsToLabelLookup(AVAILABILITY_RULES_PROFILES_LABELS);
