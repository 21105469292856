import { MarketerSearchDefinition } from '@mayple/types';
import Mark from 'mark.js';
import keyBy from 'lodash/keyBy';

import filterFieldsMapper, { emptyResolver } from '../../consts';

export type SearchContext = string | HTMLElement | ReadonlyArray<HTMLElement> | NodeList;

// Text Highlight functions

const filterFieldsMapperByFilterFieldName = keyBy(filterFieldsMapper, 'filterFieldName');

const escapeRegExp = (value: string): string =>
  value.replace(/[(]/g, '\\(').replace(/[)]/g, '\\)').replace(/[$]/g, '\\$');

export const getHighlightTerms = (filter: MarketerSearchDefinition): string[] => {
  const { filtersList } = filter;
  const highLightTerms: (string | null)[] = [];

  filtersList.forEach(({ fieldName, fieldFilterValues }) => {
    let fieldLabels;

    if (filterFieldsMapperByFilterFieldName[fieldName]) {
      const { resolver = emptyResolver, lookup } = filterFieldsMapperByFilterFieldName[fieldName];
      fieldLabels = fieldFilterValues.map((value) => resolver(value, lookup as Record<string, string>));
    } else {
      fieldLabels = fieldFilterValues.map(emptyResolver);
    }

    highLightTerms.push(...fieldLabels);
  });

  return highLightTerms.filter(Boolean) as string[];
};

export const highlightResults = (searchContext: SearchContext, terms: string[]): void => {
  const instance = new Mark(searchContext);
  const highlightTerms = terms.map(escapeRegExp);

  const regex = new RegExp(`(${highlightTerms.join('|')})`, 'ig');
  instance.markRegExp(regex);
};

export const removeHighlightFromResults = (searchContext: SearchContext): void => {
  const instance = new Mark(searchContext);
  instance.unmark();
};
