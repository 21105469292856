import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import WizardSteps from './steps';
import { createFilterFromFormValues } from './logic';
import useExpendingPanel from './hooks/useExpendingPanel';
import useAdvancedMarketerSearch from './hooks/useAdvancedMarketerSearch';
import MarketerSearchResults from './components/MarketerSearchResults';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import RemoteSubmitButton from '../../../../../fe_common/client/components/atoms/RemoteSubmitButton';
import RemoteResetButton from '../../../../../fe_common/client/components/atoms/RemoteResetButton';

import useStyles from './styles';

interface AdvancedMarketerSearchWizardProps {
  classes?: Record<string, string>;
}

const AdvancedMarketerSearchWizard: FC<AdvancedMarketerSearchWizardProps> = (props) => {
  const wizardFormName = 'advancedMarketerSearch';
  const classes = useStyles(props);

  const { initialValues, filterData, setFilterData, showResults, setShowResults } = useAdvancedMarketerSearch();

  const { expandSearch, handleExpandClick, collapseSearchForm, expandSearchForm } = useExpendingPanel();

  const handleFormSubmit = useCallback(
    async (formValues: Record<string, any>) => {
      const newFilterData = createFilterFromFormValues(formValues, ['filtersGlobalOperator', 'filterValuesOperator']);
      setShowResults(true);
      setFilterData(newFilterData);
    },
    [setFilterData, setShowResults],
  );

  const handleResetOnClick = () => {
    setShowResults(false);
    expandSearchForm();
  };

  return (
    <div>
      <Card className={classes.cardRoot}>
        <CardHeader
          title={<TextDisplay variant="h4">Advanced Marketer Search</TextDisplay>}
          action={
            <div className={classes.actionsContainer}>
              <RemoteSubmitButton
                form={wizardFormName}
                label="Search Marketers"
                variant="contained"
                color="primary"
                onClick={collapseSearchForm}
              />
              <RemoteResetButton form={wizardFormName} label="Reset Form" onClick={handleResetOnClick} />
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: expandSearch,
                })}
                onClick={handleExpandClick}
                aria-expanded={expandSearch}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          }
        />
        <Collapse in={expandSearch} timeout="auto">
          <CardContent>
            <WizardForm
              form={wizardFormName}
              initialValues={initialValues}
              // @ts-ignore
              steps={WizardSteps}
              onSubmit={handleFormSubmit}
              destroyOnUnmount={false}
              watchAllValues
              classes={classes}
            />
          </CardContent>
        </Collapse>
      </Card>

      {filterData && showResults && <MarketerSearchResults filter={filterData} />}
    </div>
  );
};

export default AdvancedMarketerSearchWizard;
