import { User } from '@mayple/types';
import { IFeedContext, SelectedFeedMember } from './FeedContext';

// eslint-disable-next-line no-shadow
export enum FeedActionType {
  OPEN_DELETE_DIALOG = 'OPEN_DELETE_DIALOG',
  CLOSE_DELETE_DIALOG = 'CLOSE_DELETE_DIALOG',
  SET_SELECTED_FEED_MEMBER = 'SET_SELECTED_FEED_MEMBER',
  SET_PUPPET_USERS = 'SET_PUPPET_USERS',
}

interface ReducerAction {
  type: string;
  payload?: any;
}

export type FeedAction =
  | { type: FeedActionType.OPEN_DELETE_DIALOG }
  | { type: FeedActionType.CLOSE_DELETE_DIALOG }
  | { type: FeedActionType.SET_SELECTED_FEED_MEMBER; payload: SelectedFeedMember | null }
  | { type: FeedActionType.SET_PUPPET_USERS; payload: User[] | null };

export type FeedDispatch = (action: FeedAction) => void;

export const feedReducer = (feedContextState: IFeedContext, action: ReducerAction): IFeedContext => {
  const { type, payload = null } = action;

  // console.log('type', type);
  // console.log('payload', payload);

  switch (type) {
    case FeedActionType.OPEN_DELETE_DIALOG:
      return {
        ...feedContextState,
        deleteDialogOpen: true,
      };
    case FeedActionType.CLOSE_DELETE_DIALOG:
      return {
        ...feedContextState,
        deleteDialogOpen: false,
      };
    case FeedActionType.SET_SELECTED_FEED_MEMBER:
      return {
        ...feedContextState,
        selectedFeedMember: {
          ...payload,
        },
      };
    case FeedActionType.SET_PUPPET_USERS:
      return {
        ...feedContextState,
        puppetUsers: payload,
      };

    default:
      return feedContextState;
  }
};
