import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { PROJECT_DETAILS_TABS, tabsDefinitions } from './consts';
import getDefaultTabByProjectLifeCycleStatusAndProjectStage from './logic';
import useProjectDetailsPageData from './useProjectDetailsPageData';
import ProjectDetailsContextProvider from './ProjectDetailsProvider';

import ProjectBasicDetailsTab from './tabs/ProjectBasicDetailsTab';
import ProjectMatchingAndHistoryTab from './tabs/ProjectMatchingAndHistoryTab';
import ProjectOnBoardingTab from './tabs/ProjectOnBoardingTab';
import ProjectInternalTeamsTab from './tabs/ProjectInternalTeamsTab';
import ProjectAccountsTab from './tabs/ProjectAccountsTab';
import ProjectServicesAndBillingTab from './tabs/ProjectServicesAndBillingTab';
import ProjectAuditTab from './tabs/ProjectAuditTab';
import ProjectDiscoveryTab from './tabs/ProjectDiscoveryTab';
import ProjectInsightsTab from './tabs/ProjectInsightsTab';
import ProjectOpportunitiesTab from './tabs/ProjectOpportunitiesTab';
import AssessmentTab from './tabs/AssessmentTab';

import ShowByProjectStatus from './components/ShowByProjectStatus';
import ProjectDetailsHeader from './components/ProjectDetailsHeader';
import HideByProjectStatus from './components/HideByProjectStatus';
import { ProjectIdParams } from '../../components/Routes/types';

import { useMuiTabState } from '../../../fe_common/client/hooks';
import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import TabContent from '../../../fe_common/client/components/atoms/TabContent';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import { tryParseInt } from '../../../fe_common/client/services/utils';
import { RefreshSpinner } from '../../../fe_common/client/components/atoms';

import useStyles from './style';
import MeetingsTab from './tabs/MeetingsTab';
import useSupervisorData from '../../hooks/useSupervisorData';

const ProjectDetailsPage: FC = () => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useMuiTabState(PROJECT_DETAILS_TABS.basicDetails, PROJECT_DETAILS_TABS);

  const { projectId: projectIdFromUrl = '' } = useParams<ProjectIdParams>();

  const projectId = tryParseInt(projectIdFromUrl, 0);

  const { loadingFullProject, loadingCycles, error, project, cycles, refetchData } =
    useProjectDetailsPageData(projectId);

  const {
    loading: loadingSupervisor,
    supervisor,
    supervisorType,
    allowedSupervisorTypes,
    refetch: refetchSupervisor,
  } = useSupervisorData(projectId);

  const { projectLifeCycleStatus } = project || {};

  useEffect(() => {
    setSelectedTab(
      {} as React.ChangeEvent<unknown>,
      getDefaultTabByProjectLifeCycleStatusAndProjectStage(project?.projectLifeCycleStatus, project?.stage),
    );
  }, [project?.projectLifeCycleStatus, project?.stage, setSelectedTab]);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<unknown>, newSelectedTab: string) => {
      window.location.hash = newSelectedTab;
      setSelectedTab(event, newSelectedTab);
    },
    [setSelectedTab],
  );

  const projectInternalTeamsTabOnChangeHandler = useCallback(async () => {
    await refetchData?.();
    await refetchSupervisor?.();
  }, [refetchData, refetchSupervisor]);

  if (loadingFullProject) {
    return <PageLoadingPlaceholder />;
  }

  if (!project?.id) {
    return <CalloutMessage message={`Could not find project ${projectId} data`} type="error" margin />;
  }

  return (
    <ProjectDetailsContextProvider
      state={{
        projectId,
        project,
        cycles,
        refetchData,
        loadingSupervisor,
        supervisor,
        supervisorType,
        allowedSupervisorTypes,
        refetchSupervisor,
      }}
    >
      <ProjectDetailsHeader classes={{ root: classes.projectDetailsHeader }} />

      {error && (
        <QueryErrorMessage error={error} message="Error getting project data, some of the data might be missing" />
      )}

      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {tabsDefinitions.map(({ label, value, hideOnStatus = [] }) => {
          if (!hideOnStatus.includes(projectLifeCycleStatus)) {
            return <Tab key={`ProjectDetailsPage-tabs-${label}`} label={label} value={value} />;
          }
          return null;
        })}
      </Tabs>
      {loadingCycles && (
        <div>
          <span>* loading previous cycles in the background</span>
          <RefreshSpinner linear />
        </div>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.basicDetails && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectBasicDetailsTab project={project} refetchProjectData={refetchData} />
        </TabContent>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.matchingAndOpportunities && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectMatchingAndHistoryTab />
          <ProjectOpportunitiesTab project={project} refetchProjectData={refetchData} />
        </TabContent>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.discovery && (
        <ShowByProjectStatus status={projectLifeCycleStatus} showOnDiscovery>
          <TabContent classes={{ root: classes.tabContentRoot }}>
            <ProjectDiscoveryTab cycles={cycles} project={project} />
          </TabContent>
        </ShowByProjectStatus>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.onboarding && (
        <HideByProjectStatus status={projectLifeCycleStatus} hideOnDiscovery hideOnAbandoned>
          <TabContent classes={{ root: classes.tabContentRoot }}>
            <ProjectOnBoardingTab project={project} />
          </TabContent>
        </HideByProjectStatus>
      )}

      {selectedTab === PROJECT_DETAILS_TABS.assessment && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <AssessmentTab project={project} />
        </TabContent>
      )}

      {selectedTab === PROJECT_DETAILS_TABS.meetings && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <MeetingsTab companyId={project?.companyId} projectId={projectId} />
        </TabContent>
      )}

      {selectedTab === PROJECT_DETAILS_TABS.accounts && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectAccountsTab project={project} />
        </TabContent>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.servicesAndBilling && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectServicesAndBillingTab
            cycles={cycles}
            project={project}
            refetchProjectData={refetchData}
            companyId={project.companyId}
          />
        </TabContent>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.internalTeams && (
        <ProjectInternalTeamsTab project={project} onUpdate={projectInternalTeamsTabOnChangeHandler} />
      )}
      {selectedTab === PROJECT_DETAILS_TABS.reports && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectAuditTab project={project} />
        </TabContent>
      )}
      {selectedTab === PROJECT_DETAILS_TABS.insights && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <ProjectInsightsTab project={project} />
        </TabContent>
      )}
    </ProjectDetailsContextProvider>
  );
};

export default ProjectDetailsPage;
