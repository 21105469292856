import React, { FC } from 'react';
import ReactTable, { Column, Filter, SortingRule, TableProps } from 'react-table-6';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';

import useQueryTable from './useQueryTable';
import { ContainsFilter } from './filters';
import CalloutMessage from '../../common/CalloutMessage';
import usePagedQuery from '../../../hooks/usePagedQuery';

export interface QueryTableProps {
  queryClass: any;
  entityKey: string;
  columns: Column[];
  initialFiltered?: Filter[];
  initialSorted?: SortingRule[];
  pageSize?: number;
  pageNumber?: number;
  options?: QueryHookOptions;
  reactTableProps?: Partial<TableProps>;
}

const QueryTable: FC<QueryTableProps> = (props) => {
  const { queryClass, entityKey, columns, options, reactTableProps } = props;

  const { variables, filtered, sorted, onFetchData } = useQueryTable(props);

  const { data, loading, error, numPages } = usePagedQuery(queryClass.query, entityKey, variables, options);

  if (error) {
    return <CalloutMessage type="error" message={error.message} />;
  }

  return (
    <ReactTable
      manual
      filterable
      columns={columns}
      loading={loading}
      data={data}
      defaultFiltered={filtered}
      defaultSorted={sorted}
      pages={numPages}
      pageSize={variables.pagination.pageSize}
      pageSizeOptions={[10, 20, 40, 60, 80, 100]} // server only supports multiples of 10
      FilterComponent={ContainsFilter({})} // default filter
      onFetchData={onFetchData}
      {...reactTableProps}
    />
  );
};

export default QueryTable;
