import React, { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Divider from '@material-ui/core/Divider';
import { useQuery } from '@apollo/react-hooks';
import { ProjectByIdDeepBriefQuery } from 'growl-graphql/dist/queries/ProjectByIdDeepBriefQuery';

import ProjectBriefCloneAndConnectButton from './ProjectBriefCloneAndConnectButton';
import ProjectBriefConnectedProjects from './ProjectBriefConnectedProjects';

import ProjectBriefPreviewButton from '../../../../../../fe_common/client/components/molecules/ProjectBriefPreviewButton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

interface CloneAndConnectDeepBriefProps {
  projectId: number;
}

const ProjectBriefCloneAndConnect: React.FC<CloneAndConnectDeepBriefProps> = (props) => {
  const classes = useStyles(props);
  const { projectId } = props;

  const { data, loading } = useQuery(ProjectByIdDeepBriefQuery.query, { variables: { projectId }, skip: !projectId });

  const projectWithBrief = useMemo(() => {
    if (!loading) {
      return data?.project || {};
    }
    return null;
  }, [data, loading]);

  return (
    <>
      <div className={classes.root}>
        <ProjectBriefPreviewButton project={projectWithBrief} disabled={loading} />
        {projectWithBrief && <ProjectBriefCloneAndConnectButton project={projectWithBrief} />}
      </div>
      <Divider />
      {projectWithBrief && <ProjectBriefConnectedProjects project={projectWithBrief} />}
    </>
  );
};

export default ProjectBriefCloneAndConnect;
