import React, { FC, useCallback, useMemo, useState } from 'react';
import { User } from '@mayple/types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { UpdateUserMutation } from 'growl-graphql/dist/mutations/UpdateUserMutation';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import SwitchField from '../../../../../fe_common/client/components/atoms/SwitchField';
import {
  RemoteControlActionType,
  RemoteControllerData,
} from '../../../../../fe_common/client/hooks/useRemoteControlHandler/logic';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { normalizeUrl } from '../../../../services/utils';

interface UserRemoteControllerProps {
  user: User;
  onChange?: () => void;
}

const UserRemoteController: FC<UserRemoteControllerProps> = (props) => {
  const { user, onChange } = props;
  const userName = user.displayName;
  const clientMetadata: Record<string, any> = useMemo(() => user?.clientMetadata || {}, [user]);
  const remoteController: RemoteControllerData = useMemo(
    () => clientMetadata?.remoteController || {},
    [clientMetadata],
  );
  const { REFRESH_BROWSER = false, REDIRECT_WINDOW = '' } = remoteController;
  const [redirectUrl, setRedirectUrl] = useState<string>(REDIRECT_WINDOW || '');
  const { mutate, loading } = useMutation(UpdateUserMutation);
  const updateRemoteControlMetaData = useCallback(
    async (key, value) => {
      const variables = {
        userId: user.id,
        userUpdate: {
          clientMetadata: {
            ...clientMetadata,
            remoteController: {
              ...remoteController,
              [key]: value,
            },
          },
        },
      };

      await mutate({ variables });

      if (typeof onChange === 'function') {
        onChange();
      }
    },
    [clientMetadata, mutate, onChange, remoteController, user.id],
  );

  const onRefreshChangeHandler = useCallback(async () => {
    updateRemoteControlMetaData(RemoteControlActionType.REFRESH_BROWSER, !REFRESH_BROWSER);
  }, [REFRESH_BROWSER, updateRemoteControlMetaData]);

  const onRedirectUserHandler = useCallback(async () => {
    if (redirectUrl) {
      updateRemoteControlMetaData(RemoteControlActionType.REDIRECT_WINDOW, normalizeUrl(redirectUrl));
    }
  }, [redirectUrl, updateRemoteControlMetaData]);

  return (
    <Section
      title="Remote control user"
      subTitle={`Remotely make action for ${userName} when he is logged in the main crackle app. This is a 1 time task only.`}
      noTopMargin
      noBorder
    >
      <SectionContent>
        <Grid container alignItems="center">
          <TextField
            variant="outlined"
            disabled={loading}
            label="Redirect the user to URL"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
          />
          <Button label="redirect" size="small" onClick={onRedirectUserHandler} />
          {REDIRECT_WINDOW && (
            <TextDisplay variant="caption">(Currently set to redirect to {REDIRECT_WINDOW})</TextDisplay>
          )}
        </Grid>
        <br />
        <hr />
        <br />
        <div>
          <SwitchField
            disabled={loading}
            checked={REFRESH_BROWSER}
            onChange={onRefreshChangeHandler}
            label={`Refresh ${userName} main app tab (if opened)`}
          />
        </div>
      </SectionContent>
    </Section>
  );
};

export default UserRemoteController;
