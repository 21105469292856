import { PostProjectMatchOfferMarketerAssessment, Task, TaskStatus, TaskType } from '@mayple/types';

import useCollectSubTasks from '../../../hooks/tasks/useCollectSubTasks';
import { CollectSubTasksData, TasksQueryOptions } from '../../../hooks/tasks/types';

const useCollectPostProjectMatchOfferMarketerAssessmentTasks = (
  options?: TasksQueryOptions
): CollectSubTasksData<PostProjectMatchOfferMarketerAssessment> => {
  const { initialized, taskStatus, tasks, subTasks, loading, error, refetch } =
    useCollectSubTasks<PostProjectMatchOfferMarketerAssessment>(
      TaskType.PostProjectMatchOfferMarketerAssessment,
      options
    );

  return {
    initialized,
    loading,
    error,
    taskStatus,
    tasks: (tasks || []).filter((task: Task) => (taskStatus || []).includes(task.taskStatus || TaskStatus.PENDING)),
    subTasks: (subTasks || ([] as PostProjectMatchOfferMarketerAssessment[])).filter((subTask) =>
      (taskStatus || []).includes(subTask.taskStatus)
    ) as PostProjectMatchOfferMarketerAssessment[],
    refetch,
  };
};

export default useCollectPostProjectMatchOfferMarketerAssessmentTasks;
