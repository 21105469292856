import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      paddingTop: 24,
      marginTop: 24,
    },
    tabContentRoot: {
      paddingTop: 48,
      paddingBottom: 48,
    },
    projectDetailsHeader: {
      width: '100%',

      '& .MuiGrid-root > div > p > span': {
        fontSize: '1.25rem',
      },

      '& input': {
        minWidth: '20rem',
      },
    },
  }),
);

export default useStyles;
