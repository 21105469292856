import React, { FC, useMemo, useState } from 'react';
import { Marketer, User } from '@mayple/types';
import { Redirect } from 'react-router';

import { UpdateMarketerWizardProps } from './types';
import WizardSteps from './steps';
import useUpdateMarketer from './useUpdateMarketer';

import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

const UpdateMarketerWizard: FC<UpdateMarketerWizardProps> = (props) => {
  const { marketer = {} as Marketer, viewer = {} as User } = props;
  const marketerId = marketer?.id;
  const viewerId = viewer?.id;
  const [redirectTo, setRedirectTo] = useState('');

  const wizardFormName = `UpdateMarketerWizard-${marketer?.uuid}`;
  const valuesToWatch = ['marketerType', 'numberOfEmployees', 'areYouSure', 'isVetted'];

  const { getInitialValues, handleFormSubmit } = useUpdateMarketer(marketerId, setRedirectTo);

  const initialValues = useMemo(() => {
    if (marketerId && viewerId) {
      return getInitialValues(marketer, viewer);
    }
    return null;
  }, [getInitialValues, marketer, marketerId, viewer, viewerId]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (!initialValues) {
    return <CalloutMessage type="error" message="Something went wrong loading marketer. Please contact dev team." />;
  }

  return (
    <div>
      <WizardForm
        form={wizardFormName}
        initialValues={initialValues}
        // @ts-ignore
        valuesToWatch={valuesToWatch}
        // @ts-ignore
        onSubmit={handleFormSubmit}
        marketer={marketer}
        steps={WizardSteps}
        touchOnChange
      />
    </div>
  );
};

export default UpdateMarketerWizard;
