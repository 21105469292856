import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { AvailabilityViewerButtonProps } from './types';
import useAvailabilityViewerButton from './logic';
import Button from '../../../../../inputs/Button';

import useStyles from './style';

const AvailabilityViewerButton: React.FC<AvailabilityViewerButtonProps> = (props) => {
  const { onClick } = props;

  const classes = useStyles(props);
  const { title, disabled } = useAvailabilityViewerButton(props);

  return (
    <Tooltip title={title}>
      <span>
        <Button
          label="Availability"
          onClick={onClick}
          startIcon={<VisibilityIcon />}
          size="medium"
          variant="outlined"
          color="primary"
          className={classes.root}
          disabled={disabled}
        />
      </span>
    </Tooltip>
  );
};

export default AvailabilityViewerButton;
