import { InternalTeam, SupervisorType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { SupervisorQuery } from 'growl-graphql/dist/queries/supervisor/SupervisorQuery';
import { GetSupervisorTypeQuery } from 'growl-graphql/dist/queries/supervisor/GetSupervisorTypeQuery';
import { GetAllowedSupervisorTypesQuery } from 'growl-graphql/dist/queries/supervisor/GetAllowedSupervisorTypesQuery';

export type SupervisorData = {
  loading: boolean;
  supervisor: InternalTeam;
  supervisorType: SupervisorType;
  allowedSupervisorTypes: Array<SupervisorType>;
  refetch: () => Promise<any>;
};

const useSupervisorData = (projectId: number | null | undefined): SupervisorData => {
  const variables = { projectId };

  const {
    data: supervisorData,
    loading: supervisorLoading,
    refetch: supervisorRefetch,
  } = useQuery(SupervisorQuery.query, {
    variables,
    skip: !projectId,
  });

  const {
    data: getSupervisorTypeData,
    loading: getSupervisorTypeDataLoading,
    refetch: getSupervisorTypeRefetch,
  } = useQuery(GetSupervisorTypeQuery.query, { variables, skip: !projectId });

  const { data: getAllowedSupervisorTypesData, loading: getAllowedSupervisorTypesLoading } = useQuery(
    GetAllowedSupervisorTypesQuery.query,
    { variables, skip: !projectId },
  );

  return {
    loading: supervisorLoading || getSupervisorTypeDataLoading || getAllowedSupervisorTypesLoading,
    supervisor: supervisorData?.supervisor,
    supervisorType: getSupervisorTypeData?.getSupervisorType,
    allowedSupervisorTypes: getAllowedSupervisorTypesData?.getAllowedSupervisorTypes,
    refetch: async () => {
      await supervisorRefetch();
      await getSupervisorTypeRefetch();
    },
  };
};

export default useSupervisorData;
