import React, { FC, useCallback } from 'react';
import { Project, ProjectUpdateInput } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import { TextDisplay } from '../../../display';
import { UseMutation } from '../../../../hooks/useMutation';
import EditableComponent from '../../../molecules/EditableComponent';
import { WizardFieldProps, WizardStepFieldTypes } from '../../../molecules/WizardStepField/types';

import useStyles from './style';

type ProjectNameEditorProps = {
  project: Project;
  mutation: UseMutation;
  enableEdit?: boolean;
  onSuccess?: () => Promise<void>;
  classes?: Record<string, string>;
};

type ProjectUpdateVariables = {
  projectId: number;
  projectUpdate: ProjectUpdateInput;
};

const ProjectNameEditor: FC<ProjectNameEditorProps> = (props) => {
  const { project, mutation, onSuccess, enableEdit = false } = props;
  const classes = useStyles(props);

  const { name, useOverriddenName, id: projectId } = project;

  const view = (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <TextDisplay classes={{ root: classes.viewModeText }}>{name}</TextDisplay>
        </Grid>
      </Grid>
    </div>
  );

  const fields: WizardFieldProps[] = [
    {
      name: 'name',
      type: WizardStepFieldTypes.TEXT,
      required: true,
      fieldProps: {
        label: 'Project Name',
        minLength: 2,
        maxLength: 100,
      },
    },
    {
      name: 'useOverriddenName',
      type: WizardStepFieldTypes.SWITCH,
      fieldProps: {
        label: 'Use Override Name',
      },
    },
  ];

  const handleValuesBeforeSave = useCallback(
    (values: Record<string, any>) => {
      const variables: ProjectUpdateVariables = {
        projectId: values?.projectId ?? projectId,
        projectUpdate: {
          useOverriddenName: values?.useOverriddenName ?? useOverriddenName,
        },
      };

      // only set the Overridden Name in case useOverriddenName is set as true
      if (values?.useOverriddenName) {
        variables.projectUpdate.overriddenName = values?.name;
      }

      return variables;
    },
    [projectId, useOverriddenName],
  );

  if (!project) {
    return <div className={classes.error}>Error getting project data, Please contact support.</div>;
  }

  return (
    <EditableComponent
      view={view}
      fields={fields}
      enableEdit={enableEdit}
      mutation={mutation}
      initialValues={{ name, useOverriddenName, projectId }}
      handleValuesBeforeSave={handleValuesBeforeSave}
      onSave={onSuccess}
    />
  );
};

export default ProjectNameEditor;
