import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'grid',
      width: '100%',
      maxWidth: 600,
      gap: '8px',
      padding: '8px 16px',
      gridTemplateColumns: '48px auto auto',
      gridTemplateRows: '48px auto auto',
      // prettier-ignore
      gridTemplateAreas: '"logo title ."' +
                         '". content replace"' +
                         '". action ."',
    },
    cronofyLogo: {
      gridArea: 'logo',
    },
    title: {
      gridArea: 'title',
      display: 'flex',
      alignItems: 'center',
    },
    listItemTextPrimary: {
      textTransform: 'capitalize',
    },
    listItemTextSecondary: {},
    replaceButton: {
      gridArea: 'replace',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      gridArea: 'content',
    },
    actionButton: {
      gridArea: 'action',
    },
  })
);

export default useStyles;
