import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SliderField from '../../SliderField';
import { TextDisplay } from '../../../display';

export function WizardSliderField(props) {
  const { name, helperText, ...rest } = props;
  // console.log('WizardSliderField props', props);

  return (
    <div>
      <Field component={SliderField} name={name} {...rest} />
      {helperText && (
        <TextDisplay variant="caption" colorOverride="#887B77" style={{ margin: '2px 0 -19px 8px' }}>
          {helperText}
        </TextDisplay>
      )}
    </div>
  );
}

WizardSliderField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

WizardSliderField.defaultProps = {
  isRequired: false,
  helperText: '',
};
