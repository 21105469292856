import React, { useCallback, useState } from 'react';
import { PackageKey, PackageKeyInput } from '@mayple/types';

import ProjectPackagesAutocomplete from '../ProjectPackagesAutocomplete';

import { Dialog } from '../../../../../../../fe_common/client/components/display';
import { DialogProps } from '../../../../../../../fe_common/client/components/display/Dialog/types';
import { LabelValueProps } from '../../../../../../../fe_common/client/app/types';

export interface ProjectPackagesSelectionDialogProps extends DialogProps {
  searchForPackageKey: PackageKeyInput;
  onAddPackage: (selectedPackageKey: PackageKey | null | undefined) => void;
}

const ProjectPackagesSelectionDialog: React.FC<ProjectPackagesSelectionDialogProps> = (props) => {
  const { searchForPackageKey, onAddPackage, onClose, ...rest } = props;

  const [, setSelectedPackage] = useState<string | null | undefined>(null);
  const [selectedPackageKey, setSelectedPackageKey] = useState<PackageKey | null | undefined>(null);

  const onSelectPackageHandler = useCallback(
    (newSelectedPackage: LabelValueProps | null | undefined, newSelectedPackageKey: PackageKey | null | undefined) => {
      setSelectedPackage(newSelectedPackage?.value || null);
      setSelectedPackageKey(newSelectedPackageKey || null);
    },
    [],
  );

  const onAddPackageHandler = useCallback(() => onAddPackage(selectedPackageKey), [onAddPackage, selectedPackageKey]);

  return (
    <Dialog
      title="Select a package"
      fullWidth
      containerMaxWidth="sm"
      actions={[
        {
          label: 'Add Package',
          color: 'primary',
          variant: 'contained',
          disabled: !selectedPackageKey,
          onClick: onAddPackageHandler,
        },
        {
          label: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: onClose,
        },
      ]}
      onClose={onClose}
      {...rest}
    >
      <ProjectPackagesAutocomplete searchForPackageKey={searchForPackageKey} onSelectPackage={onSelectPackageHandler} />
    </Dialog>
  );
};

export default ProjectPackagesSelectionDialog;
