import { toNumber } from 'lodash';
import toBoolean from 'validator/lib/toBoolean';

import {
  MailchimpAuditAssessmentByCompanyInput,
  MailchimpAuditAssessmentByCompanyParameter,
  MailchimpAuditAssessmentByCompanyParameterKey,
} from '@mayple/types';

import { FormValues } from './types';

type SetMailchimpAuditAssessmentByCompanyForProjectVariables = {
  projectId: number;
  mailchimpAuditAssessmentByCompanyInput: MailchimpAuditAssessmentByCompanyInput;
};

type ConversionMapper = (v: any) => any;

const defaultConversionMapper: ConversionMapper = (value) => value;
// We use this to convert numbers that are in the range (0, 5) to the range (0, 10), as per the SoT
const toNumber0to10: ConversionMapper = (value) => toNumber(value) * 2;

const toArrayFromHushOfBoolean: ConversionMapper = (value: any) => {
  if (!value) {
    return [];
  }

  const result: string[] = [];
  Object.entries(value).forEach(([key, boolValue]) => {
    if (boolValue) {
      result.push(key);
    }
  });
  return result;
};

type VariableValuesMapper = {
  mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey;
  conversionMapper?: (value: any) => any;
};

const variableValuesMapper: Record<keyof FormValues, VariableValuesMapper> = {
  interactionScore: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditInteractionRating,
    conversionMapper: toNumber0to10,
  },
  interactionReason: {
    mailchimpAssessmentKey:
      MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditInteractionRatingReasoning,
  },
  satisfactionScore: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditResultSatisfaction,
    conversionMapper: toNumber0to10,
  },
  improvementPrediction: {
    mailchimpAssessmentKey:
      MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditPerceptionOfHealthCheckImprovement,
    conversionMapper: toNumber0to10,
  },
  implementationAssistanceNeeded: {
    mailchimpAssessmentKey:
      MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditNeedForAdditionalAssistance,
    conversionMapper: toBoolean,
  },
  recommendToOtherBusinesses: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditRecommendationToOthers,
    conversionMapper: toBoolean,
  },
  howCanWeImprove: {
    mailchimpAssessmentKey:
      MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditDriversOfProgramImprovement,
    conversionMapper: toArrayFromHushOfBoolean,
  },
  howCanWeImproveOther: {
    mailchimpAssessmentKey:
      MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditDriversOfProgramImprovementOtherFeedback,
  },
  CSMHelpScore: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditCsmSatisfaction,
    conversionMapper: toNumber0to10,
  },
  navigationExperienceScore: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditEase,
    conversionMapper: toNumber0to10,
  },
  recommendMailchimpToFriendsScore: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditTnps,
    conversionMapper: toNumber,
  },
  workingWithMaypleExperience: {
    mailchimpAssessmentKey: MailchimpAuditAssessmentByCompanyParameterKey.general_mailchimpAuditGeneralFeedback,
  },
};

const getSetMailchimpAuditAssessmentByCompanyForProjectVariables = (
  formValues: FormValues,
): SetMailchimpAuditAssessmentByCompanyForProjectVariables => {
  const { projectId /* , marketerId */ } = formValues;

  if (!projectId) {
    throw new Error('Missing parameter projectId');
  }

  const marketerAssessmentParameters: MailchimpAuditAssessmentByCompanyParameter[] = Object.entries(formValues)
    .filter(([key]) => !['projectId', 'marketerId'].includes(key))
    .map(([key, value]) => {
      if (!variableValuesMapper[key]) {
        return undefined;
      }

      const { mailchimpAssessmentKey, conversionMapper = defaultConversionMapper } = variableValuesMapper[key];
      return {
        key: mailchimpAssessmentKey as MailchimpAuditAssessmentByCompanyParameterKey,
        value: conversionMapper(value),
      };
    })
    .filter((item) => item != null) as MailchimpAuditAssessmentByCompanyParameter[];

  return {
    projectId,
    mailchimpAuditAssessmentByCompanyInput: { marketerAssessmentParameters },
  };
};

export default getSetMailchimpAuditAssessmentByCompanyForProjectVariables;
