import React from 'react';
import { Integration, IntegrationType } from '@mayple/types';

import IntegrationItemDetailsCronofy from './IntegrationItemDetailsCronofy';
import IntegrationItemDetailsGeneral from './IntegrationItemDetailsGeneral';

import { svgs } from '../../../../fe_common/client/app/svg';

interface IntegrationItemDetails {
  avatar: string;
  component: React.ComponentClass<any> | React.FC<any>;
}

export const integrationMapper: Record<IntegrationType, IntegrationItemDetails> = {
  [IntegrationType.FACEBOOK]: {
    avatar: svgs.facebook,
    component: IntegrationItemDetailsGeneral,
  },
  [IntegrationType.FACEBOOK_MANUAL]: {
    avatar: svgs.facebook,
    component: IntegrationItemDetailsGeneral,
  },
  [IntegrationType.GOOGLE_ADWORDS]: {
    avatar: svgs.googleAds,
    component: IntegrationItemDetailsGeneral,
  },
  [IntegrationType.GOOGLE_ADWORDS_MANUAL]: {
    avatar: svgs.googleAds,
    component: IntegrationItemDetailsGeneral,
  },
  [IntegrationType.GOOGLE_ANALYTICS]: {
    avatar: svgs.googleAnalytics,
    component: IntegrationItemDetailsGeneral,
  },
  [IntegrationType.CRONOFY]: {
    avatar: svgs.cronofy,
    component: IntegrationItemDetailsCronofy,
  },
  [IntegrationType.MAILCHIMP]: {
    avatar: svgs.mailchimp,
    component: IntegrationItemDetailsCronofy,
  },
  [IntegrationType.MAILCHIMP_MANUAL]: {
    avatar: svgs.mailchimp,
    component: IntegrationItemDetailsCronofy,
  },
};

export const getIntegrationDetailsItem = (integration: Integration): IntegrationItemDetails | null => {
  if (!integration || !integration?.integrationType) {
    return null;
  }

  return integrationMapper[integration.integrationType];
};

const DEFAULT_MISSING_EMAIL_MESSAGE = 'Failed to get email';
const DEFAULT_MISSING_NAME_MESSAGE = 'Failed to get name';

export function getUserEmail(integrationItem: Integration): string {
  if (integrationItem.integrationType === IntegrationType.FACEBOOK) {
    return integrationItem?.facebookIntegrationUser?.email || DEFAULT_MISSING_EMAIL_MESSAGE;
  }

  if (integrationItem.integrationType === IntegrationType.GOOGLE_ADWORDS) {
    return integrationItem.googleAdWordsIntegrationUser?.email || DEFAULT_MISSING_EMAIL_MESSAGE;
  }

  if (integrationItem.integrationType === IntegrationType.GOOGLE_ANALYTICS) {
    return integrationItem.googleAnalyticsIntegrationUser?.email || DEFAULT_MISSING_EMAIL_MESSAGE;
  }

  return '';
}

export function getUserName(integrationItem: Integration): string {
  if (integrationItem.integrationType === IntegrationType.FACEBOOK) {
    return integrationItem?.facebookIntegrationUser?.name || DEFAULT_MISSING_NAME_MESSAGE;
  }

  if (integrationItem.integrationType === IntegrationType.GOOGLE_ADWORDS) {
    return integrationItem?.googleAdWordsIntegrationUser?.name || DEFAULT_MISSING_NAME_MESSAGE;
  }

  if (integrationItem.integrationType === IntegrationType.GOOGLE_ANALYTICS) {
    return integrationItem?.googleAnalyticsIntegrationUser?.name || DEFAULT_MISSING_NAME_MESSAGE;
  }

  if (integrationItem.integrationType === IntegrationType.FACEBOOK_MANUAL) {
    return 'Facebook manual integration';
  }

  if (integrationItem.integrationType === IntegrationType.GOOGLE_ADWORDS_MANUAL) {
    return 'Google Ads manual integration';
  }

  return 'Unknown integration type';
}
