import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { Button } from '../../../../../inputs';
import { ConnectCalendarExplanationDialogProps } from './types';
import ConnectCalendarExplanation from './ConnectCalendarExplanation';

const ConnectCalendarExplanationDialog: FC<ConnectCalendarExplanationDialogProps> = (props) => {
  const { onCalendarAuthorizationClick, onClose, open } = props;

  return (
    <Dialog open={open}>
      <DialogContent>
        <ConnectCalendarExplanation />
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" onClick={onClose} />
        <Button label="Connect calendar" onClick={onCalendarAuthorizationClick} color="primary" variant="contained" />
      </DialogActions>
    </Dialog>
  );
};

export default ConnectCalendarExplanationDialog;
