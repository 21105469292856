import React, { useRef } from 'react';
import { ProjectSponsorPayment } from '@mayple/types';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { StepWizardChildProps } from 'react-step-wizard';

import createCsvData from '../logic';

import { Button } from '../../../../fe_common/client/components/inputs';
import { RefreshSpinner } from '../../../../fe_common/client/components/atoms';
import { Transitions } from '../../../../fe_common/client/components/molecules/Transitions';

import { useStepStyle } from '../style';
import { TextDisplay } from '../../../../fe_common/client/components/display';

interface PayInReportStep2Props extends Partial<StepWizardChildProps> {
  loading: boolean;
  payments: ProjectSponsorPayment[];
}

const PayInReportStep2: React.FC<PayInReportStep2Props> = (props) => {
  const classes = useStepStyle(props);
  const { loading, payments, nextStep } = props;
  const generatingCSVFile = useRef<boolean>(false);

  const onDownloadFileClickHandler = () => {
    if (!loading && payments.length > 0 && !generatingCSVFile.current) {
      generatingCSVFile.current = true;
      // We are safe to generate CSV file with given data
      createCsvData(payments);
      generatingCSVFile.current = false;
      nextStep?.();
    }
  };

  return (
    <div id="step2" className={classes.root}>
      {loading && (
        <Transitions type={Fade}>
          <div className={classes.loadingContainer}>
            <TextDisplay align="center">Loading...</TextDisplay>
            <RefreshSpinner linear classes={{ root: classes.loading }} />
          </div>
        </Transitions>
      )}
      {!loading && payments && (
        <Slide in={!loading} appear={!loading} direction="up" mountOnEnter unmountOnExit>
          <div>
            <Button
              label="Download CSV file"
              onClick={onDownloadFileClickHandler}
              color="primary"
              variant="contained"
              size="large"
              disabled={!payments?.length}
            />
          </div>
        </Slide>
      )}
    </div>
  );
};

export default PayInReportStep2;
