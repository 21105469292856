import { ModifySupervisorInput } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import { ModifySupervisorMutation } from 'growl-graphql/dist/mutations/supervisor/ModifySupervisorMutation';

import { useMutation } from '../../../../../fe_common/client/hooks';
import { EntityOperationResponseExecutionResult } from '../../../../../fe_common/client/app/types';
import { handleClientError } from '../../../../../fe_common/client/services/logger';
import { setNotification } from '../../../../../fe_common/client/services/notification';

type SetSupervisorTypeData = {
  saving: boolean;
  error: ApolloError | undefined;
  saveSupervisor: (projectId: number | null | undefined, modifySupervisor: ModifySupervisorInput) => Promise<void>;
};

const useSetSupervisorType = (): SetSupervisorTypeData => {
  const { loading: saving, mutate, error } = useMutation(ModifySupervisorMutation);

  const saveSupervisor = async (
    projectId: number | null | undefined,
    modifySupervisor: ModifySupervisorInput,
  ): Promise<void> => {
    const { supervisorType } = modifySupervisor || {};

    try {
      if (!projectId) {
        throw new Error('Missing projectId type');
      }

      if (!supervisorType) {
        throw new Error('Missing supervisor type');
      }

      const variables = { projectId, modifySupervisorData: modifySupervisor };

      const result: EntityOperationResponseExecutionResult<'modifySupervisor'> = await mutate({
        variables,
      });

      if (!result?.data?.modifySupervisor?.success) {
        setNotification(result?.data?.modifySupervisor?.result, 'error');
      }
    } catch (err: any) {
      handleClientError(err);
      setNotification(err?.message || 'Failed updating supervisor information, please contact dev support.', 'error');
    }
  };

  return {
    saving,
    error,
    saveSupervisor,
  };
};

export default useSetSupervisorType;
