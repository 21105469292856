import {
  getBillingModelTerms,
  getBillingTypes,
  getCyclePhaseValue,
  getListOfServicesFromCycle,
  getServicesData,
} from './logic';

import {
  dateTimeFormatter,
  defaultStringFormatter,
  numericFormatter,
  yesNoCsvFormatter,
  yesNoFormatter,
} from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import { COUNTRIES_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';
import { CSVColumn } from '../../../../../fe_common/client/services/ExportToCSV/types';
import { getMarketerSubmittedMediaSpend } from '../../../../../fe_common/client/services/reactTableHelpers/filterMethods';

const PROJECT_BILLING_REVIEW_CSV_COLUMNS: CSVColumn[] = [
  {
    id: 'companyName',
    header: 'Company Name',
    accessor: 'company.name',
  },
  {
    id: 'companyId',
    header: 'Company Id',
    accessor: 'companyId',
    formatter: numericFormatter,
  },
  {
    id: 'companyBillingName',
    header: 'Company billing name',
    accessor: 'company.fundAccount.invoicingDetails.companyName',
  },
  {
    id: 'projectName',
    header: 'Project',
    accessor: 'project.name',
  },
  {
    id: 'projectId',
    header: 'Project Id',
    accessor: 'project.id',
    formatter: numericFormatter,
  },
  {
    id: 'projectSpecialType',
    header: 'Project Type',
    accessor: 'project.specialType',
  },
  {
    id: 'projectSpecialSubType',
    header: 'Project SubType',
    accessor: 'project.specialSubType',
  },
  {
    id: 'estimatedTotalCost',
    header: 'Estimated Total Cost',
    accessor: 'cycle.estimatedTotalCost',
    formatter: numericFormatter,
  },
  {
    id: 'actualTotalCost',
    header: 'Actual Total Cost',
    accessor: 'cycle.actualTotalCost',
    formatter: numericFormatter,
  },
  {
    id: 'nextMonthEstimatedTotalCost',
    header: 'Next Month Estimated Total Cost',
    accessor: 'nextCycle.estimatedTotalCost',
    formatter: numericFormatter,
  },
  {
    id: 'cycleBilled',
    header: 'Cycle Billed',
    accessor: 'cycle.phase',
    formatter: (value) => yesNoCsvFormatter(getCyclePhaseValue(value)),
  },
  {
    id: 'currency',
    header: 'Currency',
    accessor: 'project.fundAccount.currency',
  },
  // Debts
  {
    id: 'debts',
    header: 'Debts',
    accessor: 'company.fundAccount.totalDebt',
    formatter: numericFormatter,
  },
  // Project life cycle status
  {
    id: 'projectLifecycleStatus',
    header: 'Project Lifecycle Status',
    accessor: 'project.projectLifeCycleStatus',
  },
  {
    id: 'mainGrowthStrategist',
    header: 'Growth Strategist',
    accessor: 'project.mainGrowthStrategist',
    formatter: (value) => defaultStringFormatter(value?.name || ''),
  },
  // Automatic renewal enabled
  {
    id: 'automaticRenewalEnabled',
    header: 'Automatic Renewal Enabled',
    accessor: 'project.automaticRenewal',
    formatter: yesNoCsvFormatter,
  },
  {
    id: 'readyForFinalBilling',
    accessor: 'cycle.isReadyForFinalBilling',
    header: 'Ready for final billing',
    formatter: yesNoCsvFormatter,
  },
  // Manual / automatic billing
  {
    id: 'billingType',
    header: 'Manual / Automatic Billing',
    accessor: 'company.fundAccount',
    formatter: (value = {}) => defaultStringFormatter(getBillingTypes(value)),
  },
  // Problems reading ad accounts
  {
    id: 'problemsReadingAdSpend',
    header: 'Problems Reading Ad Accounts',
    accessor: 'problemsReadingAdSpend',
    formatter: yesNoCsvFormatter,
  },
  // Services
  {
    id: 'serviceType',
    header: 'Service Types',
    accessor: 'cycle',
    formatter: (cycle) => defaultStringFormatter(getServicesData(cycle).join(';')),
  },
  {
    id: 'services',
    header: 'Services',
    accessor: 'cycle',
    formatter: (cycle) => defaultStringFormatter(getListOfServicesFromCycle(cycle).join(';')),
  },
  // Discounts
  // PPC billing plan
  {
    id: 'billingPlan',
    header: 'PPC Billing Plan',
    accessor: 'cycle.billingConfiguration.planName',
  },
  // PPC billing plan details
  {
    id: 'billingModelTerms',
    header: 'PPC Billing Plan Details',
    accessor: 'cycle.billingConfiguration',
    formatter: (value) => defaultStringFormatter(getBillingModelTerms(value)),
  },
  // Estimated ppc media budget
  {
    id: 'estimatedMediaBudget',
    header: 'Estimated PPC Media Budget',
    accessor: 'cycle.estimatedMediaBudget',
    formatter: numericFormatter,
  },
  // Actual ppc media spend
  {
    id: 'actualMediaSpend',
    header: 'Actual PPC Media Spend',
    accessor: 'cycle.actualMediaSpend',
    formatter: numericFormatter,
  },
  // Project Cycle Balance
  {
    id: 'projectCycleBalance',
    header: 'Project Cycle Balance',
    accessor: 'cycle.fundAccount.balance',
    formatter: numericFormatter,
  },
  // Project Balance
  {
    id: 'projectBalance',
    header: 'Project Balance',
    accessor: 'project.fundAccount.balance',
    formatter: numericFormatter,
  },
  // Company Balance
  {
    id: 'companyBalance',
    header: 'Company Balance',
    accessor: 'company.fundAccount.balance',
    formatter: numericFormatter,
  },
  // Marketer
  {
    id: 'marketer',
    header: 'Marketer Name',
    accessor: 'participant.marketer.name',
  },
  // Marketer ID
  {
    id: 'marketer',
    header: 'Marketer Id',
    accessor: 'participant.marketer.id',
    formatter: numericFormatter,
  },
  // Marketer country
  {
    id: 'marketerCountry',
    header: 'Marketer Country',
    accessor: 'participant.marketer.marketerProfile.contactDetails.postalAddress.country',
    // @ts-ignore
    formatter: (value) => defaultStringFormatter(COUNTRIES_LABELS_LOOKUP[value] || value || ''),
  },
  // Actual Marketer cut (no tax)
  {
    id: 'actualMarketerFee',
    header: 'Actual Marketer Cut (no tax)',
    accessor: 'cycle.actualMarketerFee',
    formatter: numericFormatter,
  },
  // Actual Mayple cut
  {
    id: 'actualMaypleFee',
    header: "Actual Mayple's Cut",
    accessor: 'cycle.actualMaypleFee',
    formatter: numericFormatter,
  },
  {
    id: 'marketerMonthlyMediaSpend',
    header: 'Marketer submitted media spend report',
    accessor: 'marketerMonthlyMediaSpend',
    formatter: (value) => defaultStringFormatter(yesNoFormatter(getMarketerSubmittedMediaSpend(value))),
  },
  {
    id: 'totalMediaSpendReported',
    header: 'Total media spend reported by the expert',
    accessor: 'marketerMonthlyMediaSpend.totalMediaSpend',
    formatter: numericFormatter,
  },
  // Project Created date
  {
    id: 'projectCreatedDate',
    header: 'Project Created Date',
    accessor: 'project.created',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  // Project start date
  {
    id: 'projectStartDate',
    header: 'Project Start Date',
    accessor: 'project.fundedAt',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  // Cycle start date
  {
    id: 'cycleStartDate',
    header: 'Cycle Start Date',
    accessor: 'cycle.startDate',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  // Cycle end date
  {
    id: 'cycleEndDate',
    header: 'Cycle End Date',
    accessor: 'cycle.endDate',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },

  {
    id: 'marketerFirstSubmitAt',
    header: 'HC submitted date (by the expert)',
    accessor: 'project.existingMarketingEvaluation.marketerFirstSubmitAt',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  {
    id: 'meetingDueDate',
    header: 'HC Review meeting date',
    accessor: 'project.existingMarketingEvaluation.meetingDueDate',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  {
    id: 'approvedByGS',
    header: 'HC approved by GS',
    accessor: 'project.existingMarketingEvaluation.approvedByGS',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  {
    id: 'approvedByCompany',
    header: 'HC approved by client',
    accessor: 'project.existingMarketingEvaluation.approvedByCompany',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
  {
    id: 'companyWantsToContinueToAdditionalPackages',
    header: 'Continue to implementation',
    accessor: 'project.existingMarketingEvaluation.companyWantsToContinueToAdditionalPackages',
  },
  {
    id: 'finishedAt',
    header: 'Finish date',
    accessor: 'project.finishedAt',
    formatter: (value) => defaultStringFormatter(dateTimeFormatter(value)),
  },
];

export default PROJECT_BILLING_REVIEW_CSV_COLUMNS;
