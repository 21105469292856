import React, { useMemo, useState } from 'react';
import { OpportunityState } from '@mayple/types';

import { getProjectOpportunitiesTableActions, getProjectOpportunitiesTableColumns } from './logic';
import { ProjectOpportunitiesTableProps } from '../types';
import ProjectOpportunityConfirmation from '../ProjectOpportunityExtendConfirmation';
import useProjectOpportunityConfirmationDialog from '../ProjectOpportunityExtendConfirmation/useProjectOpportunityConfirmationDialog';
import useProjectOpportunityWithdrawDialog from '../ProjectOpportunityWithdrawConfirmation/useProjectOpportunityWithdrawDialog';
import ProjectOpportunityWithdrawConfirmation from '../ProjectOpportunityWithdrawConfirmation';

import useDataTableRef from '../../../../../../fe_common/client/components/molecules/DataTable/useDataTableRef';
import DataTable from '../../../../../../fe_common/client/components/molecules/DataTable';
import TextDisplay from '../../../../../../fe_common/client/components/display/TextDisplay';

import useStyles from './style';

const ProjectOpportunitiesTable: React.FC<ProjectOpportunitiesTableProps> = (props) => {
  const classes = useStyles(props);

  const {
    opportunities = [],
    onUpdateOpportunities = () => {
      /* do nothing */
    },
  } = props;

  const {
    openConfirmExtendOpportunityDialog,
    showConfirmExtendOpportunityDialog,
    closeConfirmExtendOpportunityDialog,
  } = useProjectOpportunityConfirmationDialog();

  const { showConfirmWithdrawDialog, openConfirmWithdrawDialog, closeConfirmWithdrawDialog } =
    useProjectOpportunityWithdrawDialog();

  const [selectedOpportunities, setSelectedOpportunities] = useState([]);
  const { tableRef, clearTableSelection } = useDataTableRef();

  const { hasOfferedOpportunities, hasAppliedOpportunities } = useMemo(() => {
    const hasOffered = opportunities.some(({ opportunityState }) => opportunityState === OpportunityState.OFFERED);
    const hasApplied = opportunities.some(({ opportunityState }) => opportunityState === OpportunityState.APPLIED);

    return {
      hasOfferedOpportunities: hasOffered,
      hasAppliedOpportunities: hasApplied,
    };
  }, [opportunities]);

  const tableData = useMemo(
    () =>
      opportunities.map((opportunity) => ({
        ...opportunity,
        hasOfferedOpportunities,
      })),
    [hasOfferedOpportunities, opportunities],
  );

  const projectOpportunitiesTableColumns = useMemo(() => getProjectOpportunitiesTableColumns(), []);

  /* Withdraw Opportunity functions */
  const handleWithdrawOpportunity = (_event: any, rowData: Record<string, any> | Record<string, any>[]): void => {
    // saving the selected opportunities uuids to the state
    const newSelectedOpportunities = rowData.map(({ uuid }: { uuid: string }) => uuid);
    setSelectedOpportunities(newSelectedOpportunities);

    // Open the confirmation dialog
    openConfirmWithdrawDialog();
  };

  /* Extend Opportunity functions */
  const handleExtendOpportunity = (_event: any, rowData: Record<string, any> | Record<string, any>[]): void => {
    // saving the selected opportunities uuids to the state
    const newSelectedOpportunities = rowData.map(({ uuid }: { uuid: string }) => uuid);
    setSelectedOpportunities(newSelectedOpportunities);

    // Open the confirmation dialog
    openConfirmExtendOpportunityDialog();
  };

  const projectOpportunitiesTableOptions = {
    selection: true,
    sorting: true,
    paging: true,
    pageSize: 10,
    emptyRowsWhenPaging: false,
    pageSizeOptions: [10, 20, 50],
  };

  const projectOpportunitiesTableActions = getProjectOpportunitiesTableActions(
    handleWithdrawOpportunity,
    handleExtendOpportunity,
  );

  const onUpdateOpportunitiesHandler = () => {
    closeConfirmExtendOpportunityDialog();
    clearTableSelection();
    setSelectedOpportunities([]);
    onUpdateOpportunities?.();
  };

  const onWithdrawOpportunityHandler = () => {
    closeConfirmWithdrawDialog();
    clearTableSelection();
    setSelectedOpportunities([]);
    onUpdateOpportunities?.();
  };

  return (
    <div className={classes.root}>
      <DataTable
        title={`${tableData.length} opportunities`}
        data={tableData}
        columns={projectOpportunitiesTableColumns}
        actions={projectOpportunitiesTableActions}
        options={projectOpportunitiesTableOptions}
        tableRef={tableRef}
        emptyStateProps={{
          primary: 'No opportunities here yet',
          secondary: '',
        }}
      />
      {hasAppliedOpportunities && (
        <TextDisplay color="error" variant="caption">
          * To select applied opportunities please look at section below
        </TextDisplay>
      )}

      <ProjectOpportunityConfirmation
        selectedOpportunities={selectedOpportunities}
        isOpen={showConfirmExtendOpportunityDialog}
        onCloseDialog={closeConfirmExtendOpportunityDialog}
        onUpdateOpportunities={onUpdateOpportunitiesHandler}
      />

      <ProjectOpportunityWithdrawConfirmation
        selectedOpportunities={selectedOpportunities}
        isOpen={showConfirmWithdrawDialog}
        onCloseDialog={closeConfirmWithdrawDialog}
        onWithdrawOpportunity={onWithdrawOpportunityHandler}
      />
    </div>
  );
};

export default ProjectOpportunitiesTable;
