import React, { useCallback } from 'react';
import { ExecutionResult } from 'graphql';
import { EntityOperationResponse } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { RejectProjectLeadMutation } from 'growl-graphql/dist/mutations/RejectProjectLeadMutation';

import { useMutation } from '../../../../fe_common/client/hooks';
import { Button } from '../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../fe_common/client/components/display';
import { clientLogger, handleClientError } from '../../../../fe_common/client/services/logger';
import { setEntityOperationResponseNotification } from '../../../../fe_common/client/services/notification';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface RejectProjectLeadCardProps {
  projectLeadId: number;
  onSuccess?: () => void;
}

const RejectProjectLeadCard: React.FC<RejectProjectLeadCardProps> = (props) => {
  const classes = useStyles(props);
  const { projectLeadId, onSuccess } = props;

  const { loading: savingRejectProjectLead, mutate: rejectProjectLead } = useMutation(RejectProjectLeadMutation);

  const handleRejectProjectLead = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    const ans = confirm(`Are you sure you want to reject this lead ID(${projectLeadId}) ?`);

    if (ans) {
      try {
        const variables = { projectLeadId };

        const response: ExecutionResult<{ rejectProjectLead: EntityOperationResponse }> = await rejectProjectLead({
          variables,
        });

        clientLogger.debug(`rejectProjectLeadMutation response:\n\n${JSON.stringify(response, null, 2)}`);
        setEntityOperationResponseNotification(response?.data?.rejectProjectLead);
        if (response?.data?.rejectProjectLead?.success) {
          onSuccess?.();
        } else {
          throw new Error(response?.data?.rejectProjectLead?.result);
        }
      } catch (e) {
        handleClientError(e, 'Unable to reject lead');
      }
    }
  }, [onSuccess, projectLeadId, rejectProjectLead]);

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <TextDisplay variant="h6">Reject lead</TextDisplay>
          <p>
            Rejecting a lead means that this lead is not suitable for Mayple. He will be removed from the lead list, and
            the token will be invalidated. This is un-reversible.
          </p>
          <div>
            <Button
              onClick={handleRejectProjectLead}
              label="REJECT"
              variant="contained"
              color="secondary"
              loading={savingRejectProjectLead}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RejectProjectLeadCard;
