import React, { FC } from 'react';
import { InternalTeam } from '@mayple/types';

import InternalTeamUsers from '../components/InternalTeamUsers';
import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';

interface InternalTeamUsersTabProps {
  internalTeam: InternalTeam;
  onChange: () => void;
}

const InternalTeamUsersTab: FC<InternalTeamUsersTabProps> = (props) => {
  const { internalTeam, onChange } = props;

  return (
    <Section title="Users" layout={SECTION_LAYOUT.ONE_COLUMN} noTopMargin noBorder>
      <SectionContent>
        <InternalTeamUsers internalTeam={internalTeam} onChange={onChange} showInviteUserLink />
      </SectionContent>
    </Section>
  );
};

export default InternalTeamUsersTab;
