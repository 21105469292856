import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import CycleDataTable from './CycleDataTable';
import { getTotalCost } from '../ProjectCycleDetailsNew/logic';
import ProjectCycleSnapshot from '../ProjectCycle/ProjectCycleSnapshot';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

const styles = {
  bold: {
    fontWeight: ['bold', '!important'],
  },
};

const ProjectCycleFunding = ({ cycle, currency, classes }) => {
  const { billingSnapshots } = cycle;

  const estimatedTotalCostRowData = getTotalCost(cycle, null, currency, classes, false);
  const actualTotalCostRowData = getTotalCost(cycle, null, currency, classes, true, true);

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={12}>
        <CycleDataTable cycleData={estimatedTotalCostRowData} />
        <CycleDataTable cycleData={actualTotalCostRowData} />
      </Grid>

      {billingSnapshots && billingSnapshots.length > 0 && (
        <Grid item xs={12}>
          <TextDisplay variant="h5" gutterBottom>
            Billing snapshot
          </TextDisplay>
          <ProjectCycleSnapshot cycle={cycle} currency={currency} />
        </Grid>
      )}
    </Grid>
  );
};

ProjectCycleFunding.propTypes = {
  cycle: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectCycleFunding);
