import { makeStyles } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

import { getGradientColor } from '../../../../app/theme';

const useStyles = makeStyles(
  createStyles({
    questionContainer: {
      marginBottom: '1.54rem',
      '& .Mui-checked': {
        color: getGradientColor('blue', 60),
      },
    },
    questionTitle: {
      fontWeight: 'bold',
    },
    questionTitleFlexContainer: {
      display: 'flex',
    },
    descriptionTextArea: {
      width: '100%',
      '& div': {
        minHeight: '7rem',
        borderRadius: '1rem',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1.6875rem',
      '& button': {
        marginLeft: '1.5rem',
      },
    },
  })
);

export default useStyles;
