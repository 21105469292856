import { useQuery } from '@apollo/react-hooks';
import { FullCompanyByIdQuery } from 'growl-graphql/dist/queries/FullCompanyById';
import { ApolloError } from 'apollo-client';
import { Company } from '@mayple/types';

export type CompanyData = {
  loading: boolean;
  error: ApolloError | undefined;
  company: Company | undefined | null;
  refetch: () => void;
};

const useCompanyData = (companyId: number | null | undefined): CompanyData => {
  const { loading, error, data, refetch } = useQuery(FullCompanyByIdQuery.query, {
    variables: { companyId },
    skip: !companyId,
  });

  return {
    loading,
    error,
    company: data?.company,
    refetch,
  };
};

export default useCompanyData;
