import React, { FC, useCallback, useMemo } from 'react';
import { OpportunityState } from '@mayple/types';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { UpdateProjectCycleWithOperationResultMutation } from 'growl-graphql/dist/mutations/UpdateProjectCycleWithOperationResultMutation';
import { UpdateProjectCycleAdditionalServicesMutation } from 'growl-graphql/dist/mutations/UpdateProjectCycleAdditionalServicesMutation';

import { ProjectBillingPageProps } from './types';
import projectBilling from './logic';
import ProjectBillingEditWizard from '../../containers/project/wizards/ProjectBillingEditWizard';
import { ProjectIdParams } from '../../components/Routes/types';
import useFullProjectData from '../../hooks/useFullProjectData';
import { tryParseInt } from '../../../fe_common/client/services/utils';
import useSearchParams from '../../../fe_common/client/hooks/useSearchParams';
import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import LoadingPlaceholder from '../../../fe_common/client/components/atoms/LoadingPlaceholder';
import useProjectOpportunities from '../ProjectDetailsPage/tabs/ProjectOpportunitiesTab/useProjectOpportunities';

const ProjectBillingPageWrapped: FC<ProjectBillingPageProps> = (props) => {
  const { updateProjectCycleWithOperationResultMutation, updateProjectCycleAdditionalServicesMutation } = props;

  const { projectId: projectIdFromUrl } = useParams<ProjectIdParams>();
  const projectId = tryParseInt(projectIdFromUrl, 0);
  const { loading: loadingProject, project, error, refetch } = useFullProjectData(projectId);
  const { opportunities, loading: loadingOpportunities } = useProjectOpportunities(projectId);

  const { hasOfferedOpportunities, hasAppliedOpportunities } = useMemo(() => {
    const hasOffered = opportunities.some(({ opportunityState }) => opportunityState === OpportunityState.OFFERED);
    const hasApplied = opportunities.some(({ opportunityState }) => opportunityState === OpportunityState.APPLIED);

    return {
      hasOfferedOpportunities: hasOffered,
      hasAppliedOpportunities: hasApplied,
    };
  }, [opportunities]);

  const loading = useMemo(() => loadingOpportunities || loadingProject, [loadingOpportunities, loadingProject]);

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const onSuccessHandler = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const { isDiscovery, isOnboarding, isLive } = useMemo(() => projectBilling.getProjectLifeCycles(project), [project]);

  return (
    <>
      {loading && !error && <LoadingPlaceholder />}
      {!loading && !isDiscovery && !isOnboarding && !isLive && (
        <CalloutMessage message={`Cannot update projects with status ${project?.projectLifeCycleStatus}`} />
      )}
      {!!error && !loading && (
        <QueryErrorMessage
          error={error}
          message="There was an error when trying to get project data. Some values may be missing."
        />
      )}
      {!loading && !error && project != null && (
        <ProjectBillingEditWizard
          project={project}
          hasOpenOpportunities={hasOfferedOpportunities || hasAppliedOpportunities}
          onSuccess={onSuccessHandler}
          updateProjectCycleWithOperationResultMutation={updateProjectCycleWithOperationResultMutation}
          updateProjectCycleAdditionalServicesMutation={updateProjectCycleAdditionalServicesMutation}
          returnToUrl={returnTo}
        />
      )}
    </>
  );
};
const ProjectBillingPage: FC<ProjectBillingPageProps> = compose(
  withMutation(UpdateProjectCycleWithOperationResultMutation),
  withMutation(UpdateProjectCycleAdditionalServicesMutation),
  // @ts-ignore
)(ProjectBillingPageWrapped) as FC<ProjectBillingPageProps>;

export default ProjectBillingPage;
