import React, { FC } from 'react';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import TableCell from './TableCell';
import LinkToProject from '../../../atoms/Links/LinkToProject';
import LinkToProjectCycle from '../../../atoms/Links/LinkToProjectCycle';

import { safeExtractCurrencyValue } from '../../../../../../../fe_common/client/services/utils';
import { dateTimeUTCFormatter } from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';

import { useRowStyles } from './styles';

export type MarketersPayoutReviewPartialRowProps = {
  dataRow: any;
  isSelected: boolean;
  onSelectRow: (marketerId: number, marketerPayoutUuid: string) => void;
  classes?: Record<string, string>;
};

const MarketersPayoutReviewPartialRow: FC<MarketersPayoutReviewPartialRowProps> = (props) => {
  const { dataRow, isSelected, onSelectRow } = props;
  const classes = useRowStyles(props);

  const {
    companyName,
    projectId,
    projectName,
    marketerId,
    cycleId,
    cycleDate,
    amount,
    currency,
    payoutWithdrawed,
    marketerPayoutUuid,
  } = dataRow;

  const formattedAmount = safeExtractCurrencyValue(amount, currency);
  const formattedCycleDate = dateTimeUTCFormatter(cycleDate, 'MM/YYYY');

  return (
    <TableRow role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected}>
      <TableCell padding="checkbox">
        <Checkbox
          disableRipple
          checked={isSelected}
          disabled={payoutWithdrawed}
          onClick={() => {
            onSelectRow(marketerId, marketerPayoutUuid);
          }}
        />
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>{companyName}</TableCell>
      <TableCell>{projectId}</TableCell>
      <TableCell>
        <LinkToProject projectId={projectId} openInNewTab>
          {projectName}
        </LinkToProject>
      </TableCell>
      <TableCell>
        <LinkToProjectCycle projectId={projectId} projectCycleId={cycleId} openInNewTab>
          {cycleId}
        </LinkToProjectCycle>
      </TableCell>
      <TableCell className={classes.alignRight}>{formattedCycleDate}</TableCell>
      <TableCell className={classes.alignRight}>{formattedAmount}</TableCell>
      <TableCell />
    </TableRow>
  );
};

export default MarketersPayoutReviewPartialRow;
