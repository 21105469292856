import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

import { StarsVoteProps } from './types';
import starsVoteEn from './translations/en/starsVote.json';
import { getRatingLabel, getTextVariant, TRANSLATION_NS } from './logic';
import { TextDisplay, TitleWithTooltip } from '../../display';
import useI18nLazyBundleLoader from '../../../hooks/useI18nLazyBundleLoader';

import useStyles from './styles';

const StarsVote: React.FC<StarsVoteProps> = (props) => {
  const classes = useStyles(props);

  const {
    title,
    value,
    onChange,
    showRatingLabel = false,
    size,
    precision = 1,
    tooltip,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    classes: classesOverride,
    ...rest
  } = props;

  useI18nLazyBundleLoader(TRANSLATION_NS, starsVoteEn);
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: `precision-${precision}` });

  const ratingLabel = getRatingLabel(value, t);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<any>, newValue: number | null) => {
      onChange?.(event, newValue);
    },
    [onChange],
  );

  const textVariant = getTextVariant(size);

  return (
    <div className={classes.root}>
      {!!title && <TitleWithTooltip title={title} tooltip={tooltip} />}

      <div className={classes.vote}>
        <Rating
          value={value}
          precision={precision}
          onChange={onChangeHandler}
          size={size}
          classes={{ root: classes.ratingRoot }}
          {...rest}
        />
        {showRatingLabel && (
          <TextDisplay variant={textVariant} className={classes.ratingLabel}>
            {ratingLabel}
          </TextDisplay>
        )}
      </div>
    </div>
  );
};

export default StarsVote;
