import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';

import DateRangePicker from '../../../../../../fe_common/client/components/atoms/Registration/DateRangePicker';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import { muiIcons } from '../../../../../../fe_common/client/app/icons';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() => ({
  rangeContainer: { margin: '24px 0' },
  container: { margin: '24px 0' },
}));

const MultiDateRangesPicker = (props) => {
  // console.log('MultiDateRangesPicker props', props);

  const classes = useStyles();
  const { title, meta, input, minDate, maxDate } = props;
  const { value, onChange } = input;
  const errorMsg = meta?.error;

  const handleAddNewEntry = useCallback(() => {
    const newValue = [...value];

    const lastDateUsed = value?.[value.length - 1]?.endDate
      ? moment(value?.[value.length - 1]?.endDate).add(1, 'day')
      : moment(minDate);

    newValue.push({
      comment: '',
      startDate: lastDateUsed,
      endDate: moment(maxDate).subtract(1, 'day'),
    });

    onChange(newValue);
  }, [value, onChange, minDate, maxDate]);

  return (
    <div className={classes.container}>
      <TextDisplay variant="h6" bold>
        {title}
      </TextDisplay>
      {value &&
        value.length > 0 &&
        value.map((duration, index) => {
          const { startDate, endDate, comment } = duration;

          const handleDateChange = (range) => {
            const newValue = [...value];
            newValue[index] = {
              ...newValue[index],
              ...range,
            };
            onChange(newValue);
          };

          const handleCommentChange = (e) => {
            const newValue = [...value];
            newValue[index] = {
              ...newValue[index],
              comment: e.target.value,
            };
            onChange(newValue);
          };

          const handleDeleteEntry = () => {
            const newValue = [...value];
            // Delete the item from array
            newValue.splice(index, 1);

            onChange(newValue);
          };

          return (
            <Grid
              alignItems="center"
              container
              key={`MultiDateRangesPicker-DateRangePicker-item-${startDate}`}
              className={classes.rangeContainer}
              spacing={2}
            >
              <Grid item>
                <DateRangePicker
                  fromLabel="Pause Start Date"
                  toLabel="Resume Date"
                  minStartDate={minDate}
                  maxStartDate={maxDate}
                  minEndDate={minDate}
                  maxEndDate={maxDate}
                  input={{
                    value: {
                      startDate,
                      endDate,
                    },
                    onChange: handleDateChange,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Comment"
                  variant="outlined"
                  value={comment}
                  onChange={handleCommentChange}
                />
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={handleDeleteEntry}>
                  {muiIcons.deleteForever}
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      {!!errorMsg && (
        <TextDisplay color="error" gutterBottom>
          {errorMsg}
        </TextDisplay>
      )}
      <Button
        color="primary"
        startIcon={muiIcons.add}
        variant="outlined"
        label="ADD PAUSE DURATION"
        onClick={handleAddNewEntry}
      />
    </div>
  );
};

MultiDateRangesPicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.object.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MultiDateRangesPicker;
