import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { compose } from 'redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  FullProjectByIdQuery,
  ShareMarketingHistoryAccountsMutation,
  UnshareMarketingHistoryAccountsMutation,
  withQuery,
  withMutation,
} from 'growl-graphql';

import { arrayOfObjectsToHashTable } from '../../../../../services/utils';

import { TextIcon } from '../../../../../../fe_common/client/components/atoms';
import { setNotification } from '../../../../../../fe_common/client/services/notification';
import { iconCodes } from '../../../../../../fe_common/client/app/icons';
import { Button } from '../../../../../../fe_common/client/components/inputs';

/**
 * Renders a button to share the project.marketingHistoryAccounts set by the company.
 * Should show additional information whether the operation succeeded.
 * For now, we only support facebook accounts sharing by API.
 * @param projectId
 * @param marketerId
 * @returns {*}
 */
class ShareHistoryButton extends React.Component {
  constructor(props) {
    super(props);

    const haveMarketingHistoryAccounts = this.getHaveMarketingHistoryAccounts(props);
    const sharedMarketingHistoryHash = this.getSharedMarketingHistoryHash(props);

    this.state = {
      haveMarketingHistoryAccounts,
      sharedMarketingHistoryHash,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fullProjectByIdQuery.loading !== this.props.fullProjectByIdQuery.loading) {
      const haveMarketingHistoryAccounts = this.getHaveMarketingHistoryAccounts(this.props);
      this.setHaveMarketingHistoryAccounts(haveMarketingHistoryAccounts);
    }

    const prevSharedMarketingHistoryAccounts = get(
      prevProps,
      'fullProjectByIdQuery.project.sharedMarketingHistoryAccounts',
      null,
    );
    const sharedMarketingHistoryAccounts = get(
      this.props,
      'fullProjectByIdQuery.project.sharedMarketingHistoryAccounts',
      null,
    );

    if (JSON.stringify(prevSharedMarketingHistoryAccounts) !== JSON.stringify(sharedMarketingHistoryAccounts)) {
      const sharedMarketingHistoryHash = this.getSharedMarketingHistoryHash(this.props);
      this.setSharedMarketingHistoryHash(sharedMarketingHistoryHash);
    }
  }

  getHaveMarketingHistoryAccounts = (props) => {
    const { fullProjectByIdQuery } = props;
    const { project = {} } = fullProjectByIdQuery;
    const { marketingHistoryAccounts: marketingHistory } = project;
    const sharedFbAccountId = get(marketingHistory, 'facebookMarketingAdAccounts[0].facebookAdAccountId');
    const sharedGaAccountId = get(marketingHistory, 'googleAdWordsClientAccounts[0].googleAdWordsClientAccountId');
    return !!sharedFbAccountId || !!sharedGaAccountId;
  };

  setHaveMarketingHistoryAccounts = (haveMarketingHistoryAccounts) => {
    this.setState({ haveMarketingHistoryAccounts });
  };

  getSharedMarketingHistoryHash = (props) => {
    const sharedMarketingHistoryAccounts = props?.fullProjectByIdQuery?.project?.sharedMarketingHistoryAccounts || [];

    return arrayOfObjectsToHashTable({
      array: sharedMarketingHistoryAccounts,
      key: 'marketerId',
    });
  };

  setSharedMarketingHistoryHash = (sharedMarketingHistoryHash) => {
    this.setState({ sharedMarketingHistoryHash });
  };

  /**
   * @returns {Promise<void>}
   */
  shareMarketingHistoryForMarketer = async () => {
    const { marketerId, projectId, shareMarketingHistoryAccountsMutation } = this.props;

    const confirmed = window.confirm(`
      Will share marketing history for marketer ${marketerId}.
      Currently this only works for Facebook. Google Ads assets are still required to be handled manually.
      Please confirm.
    `);

    if (confirmed) {
      const response = await shareMarketingHistoryAccountsMutation.mutate({ projectId, marketerId });
      const { shareMarketingHistoryAccounts = {} } = response;

      setNotification(
        shareMarketingHistoryAccounts.result,
        shareMarketingHistoryAccounts.success ? 'success' : 'error',
      );
    }
  };

  unshareMarketingHistoryForMarketer = async () => {
    const { marketerId, projectId, unshareMarketingHistoryAccountsMutation } = this.props;

    const confirmed = window.confirm(`
      Will unshare marketing history for marketer ${marketerId}.
      Currently this only works for Facebook. Google Ads assets are still required to be handled manually.
      Please confirm.
    `);

    if (confirmed) {
      const res = await unshareMarketingHistoryAccountsMutation.mutate({ projectId, marketerId });
      const { unshareMarketingHistoryAccounts = {} } = res;
      setNotification(
        unshareMarketingHistoryAccounts.result,
        unshareMarketingHistoryAccounts.success ? 'success' : 'error',
      );
    }
  };

  wrapWithTooltip = (content) => (
    <Tooltip placement="left" title="Share marketing history">
      <div>{content}</div>
    </Tooltip>
  );

  render() {
    const { marketerId, withIcon, withTooltip, withLabel } = this.props;

    const { haveMarketingHistoryAccounts, sharedMarketingHistoryHash } = this.state;

    const facebookSharingResult = get(
      sharedMarketingHistoryHash[marketerId],
      'facebookMarketingAdAccounts[0].sharingResult',
      null,
    );

    // eslint-disable-next-line react/no-unstable-nested-components
    const ShareButton = () => {
      if (!withLabel && withIcon) {
        return (
          // eslint-disable-next-line react/no-this-in-sfc
          <IconButton onClick={this.shareMarketingHistoryForMarketer} disabled={!haveMarketingHistoryAccounts}>
            <TextIcon icon={iconCodes.share} />
          </IconButton>
        );
      }

      return (
        <Button
          color="primary"
          variant="contained"
          label="Share marketing history"
          icon={withIcon ? iconCodes.share : null}
          // eslint-disable-next-line react/no-this-in-sfc
          onClick={this.shareMarketingHistoryForMarketer}
          disabled={!haveMarketingHistoryAccounts}
        />
      );
    };

    return (
      <React.Fragment>
        {withTooltip ? this.wrapWithTooltip(<ShareButton />) : <ShareButton />}
        {facebookSharingResult != null && (
          <React.Fragment>
            <br />
            <span className={facebookSharingResult.success ? 'green-test' : 'red-text'}>
              Sharing result: {facebookSharingResult.result}
            </span>
            <br />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <a role="button" style={{ cursor: 'pointer' }} onClick={this.unshareMarketingHistoryForMarketer}>
              Unshare accounts
            </a>
          </React.Fragment>
        )}
        <br />
      </React.Fragment>
    );
  }
}

ShareHistoryButton.propTypes = {
  projectId: PropTypes.number.isRequired,
  marketerId: PropTypes.number.isRequired,
  fullProjectByIdQuery: PropTypes.object.isRequired,
  shareMarketingHistoryAccountsMutation: PropTypes.object.isRequired,
  unshareMarketingHistoryAccountsMutation: PropTypes.object.isRequired,
  withIcon: PropTypes.bool,
  withTooltip: PropTypes.bool,
  withLabel: PropTypes.bool,
};
ShareHistoryButton.defaultProps = {
  withIcon: false,
  withTooltip: false,
  withLabel: true,
};

export default compose(
  withQuery(FullProjectByIdQuery, {
    options: ({ projectId }) => ({
      variables: { projectId },
      skip: !projectId,
    }),
  }),
  withMutation(ShareMarketingHistoryAccountsMutation),
  withMutation(UnshareMarketingHistoryAccountsMutation),
)(ShareHistoryButton);
