import { OperationVariables } from 'apollo-client';
import { ExecutionResult } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks/lib/types';
import { AddUserToCompanyMutation } from 'growl-graphql/dist/mutations/AddUserToCompanyMutation';
import { RemoveUserFromCompanyMutation } from 'growl-graphql/dist/mutations/RemoveUserFromCompanyMutation';
import { AddUserToMarketerMutation } from 'growl-graphql/dist/mutations/AddUserToMarketerMutation';
import { RemoveUserFromMarketerMutation } from 'growl-graphql/dist/mutations/RemoveUserFromMarketerMutation';
import { AddUserToInternalTeamMutation } from 'growl-graphql/dist/mutations/AddUserToInternalTeamMutation';
import { RemoveUserFromInternalTeamMutation } from 'growl-graphql/dist/mutations/RemoveUserFromInternalTeamMutation';

import { ENTITY_TYPE, EntityTypeMap } from '../app/consts';
import { useMutation } from '../../fe_common/client/hooks';

export interface EntityUsersOperations {
  entitiesKey: string;
  entityIdKey: string;
  addUserToEntity: (options?: MutationHookOptions<any, OperationVariables>) => Promise<ExecutionResult<any>>;
  removeUserFromEntity: (options?: MutationHookOptions<any, OperationVariables>) => Promise<ExecutionResult<any>>;
}

export type ValidOperationsEntities = ENTITY_TYPE.MARKETER | ENTITY_TYPE.COMPANY | ENTITY_TYPE.INTERNAL_TEAM;

const entityTypeOperationsMapper: Record<ValidOperationsEntities, EntityTypeMap> = {
  [ENTITY_TYPE.COMPANY]: {
    entitiesKey: 'companies',
    entityIdKey: 'companyId',
    addUserToEntityMutation: AddUserToCompanyMutation,
    removeUserFromEntityMutation: RemoveUserFromCompanyMutation,
  },
  [ENTITY_TYPE.MARKETER]: {
    entitiesKey: 'marketers',
    entityIdKey: 'marketerId',
    addUserToEntityMutation: AddUserToMarketerMutation,
    removeUserFromEntityMutation: RemoveUserFromMarketerMutation,
  },
  [ENTITY_TYPE.INTERNAL_TEAM]: {
    entitiesKey: 'internalTeams',
    entityIdKey: 'internalTeamId',
    addUserToEntityMutation: AddUserToInternalTeamMutation,
    removeUserFromEntityMutation: RemoveUserFromInternalTeamMutation,
  },
};

export const getEntityTypeProps = (entityType: ValidOperationsEntities): EntityTypeMap =>
  entityTypeOperationsMapper[entityType];

export const disconnectUserFromEntity = async (
  mutate: (options?: MutationHookOptions<any, OperationVariables>) => Promise<ExecutionResult<any>>,
  entityType: ValidOperationsEntities,
  entityId: number,
  userId: number,
): Promise<ExecutionResult<any>> => {
  const { entityIdKey } = getEntityTypeProps(entityType);
  const variables = {
    userId,
    [entityIdKey]: entityId,
  };

  return mutate({ variables });
};

export const connectUserToEntity = async (
  mutate: (options?: MutationHookOptions<any, OperationVariables>) => Promise<ExecutionResult<any>>,
  entityType: ValidOperationsEntities,
  entityId: number | string,
  userId: number,
): Promise<ExecutionResult<any>> => {
  const { entityIdKey } = getEntityTypeProps(entityType);

  const variables = {
    userId,
    [entityIdKey]: entityId,
  };

  return mutate({ variables });
};

const useEntityUsersOperations = (entityType: ValidOperationsEntities): EntityUsersOperations => {
  const { entitiesKey, entityIdKey, addUserToEntityMutation, removeUserFromEntityMutation } =
    getEntityTypeProps(entityType);

  const { mutate: addUserToEntity } = useMutation(addUserToEntityMutation);
  const { mutate: removeUserFromEntity } = useMutation(removeUserFromEntityMutation);

  return {
    entitiesKey,
    entityIdKey,
    addUserToEntity,
    removeUserFromEntity,
  };
};

export default useEntityUsersOperations;
