import { Project } from '@mayple/types';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { PublicProjectByIdDeepBriefQuery } from 'growl-graphql/dist/queries/marketer/PublicProjectByIdDeepBriefQuery';

export interface PublicProjectDeepBriefData {
  loading: boolean;
  error: ApolloError | undefined;
  project: Project | undefined | null;
  refetch: (
    variables?: { projectId: number | null | undefined; includeCostDetails: boolean } | undefined
  ) => Promise<ApolloQueryResult<Project>>;
}

const usePublicProjectDeepBriefData = (
  projectId: number | null | undefined,
  includeCostDetails = false,
  options: QueryHookOptions = { notifyOnNetworkStatusChange: true }
): PublicProjectDeepBriefData => {
  const { loading, error, data, refetch } = useQuery(PublicProjectByIdDeepBriefQuery.query, {
    variables: { projectId, includeCostDetails },
    skip: !projectId,
    ...options,
  });

  return {
    loading,
    error,
    project: data?.project,
    refetch,
  };
};

export default usePublicProjectDeepBriefData;
