import { Country, FundAccount, MarketerPayout, MarketerPayoutsReviewItem, PayoutType } from '@mayple/types';

import { MarketerPayoutsReviewQuery } from 'growl-graphql/dist/queries/MarketerPayoutsReviewQuery';
import { MarketersPayoutsReviewQuery } from 'growl-graphql/dist/queries/MarketersPayoutsReviewQuery';

import { DataProcessorHandler, MarketersPayoutReviewDataRow, RowDataType, SelectedPayoutData, TableCol } from './types';

import { COUNTRIES_LABELS_LOOKUP } from '../../../../../../../fe_common/client/app/enums';
import { CountriesLabelsLookup } from '../../../../../../../fe_common/client/app/types';
import { QueryClass } from '../../../../../../../fe_common/client/hooks/tasks/types';
import { PagingSortingVariables } from '../../../../../../../fe_common/client/hooks/usePagedQuery';

// export const DEFAULT_CLIENT_PAGE_SIZE = 5;

export type QueryData = {
  queryClass: QueryClass;
  variables: Record<string, any>;
  queryEntityKey: string;
  dataProcessor: DataProcessorHandler<any, any>;
};

export const marketersPayoutReviewTableCols: TableCol[] = [
  { id: 'marketerId', label: 'Marketer ID' },
  { id: 'marketerName', label: 'Marketer name' },
  { id: 'country', label: 'Country' },
  { id: 'preferredPayoutType', label: 'Preferred Payout Type' },
  { id: 'companyName', label: 'Company' },
  { id: 'projectId', label: 'Project ID' },
  { id: 'projectName', label: 'Project name' },
  { id: 'cycleId', label: 'Cycle ID' },
  { id: 'cycleDate', label: 'Cycle Date' },
  { id: 'amount', label: 'amount' },
  { id: 'totalMarketerFee', label: 'Total marketer fee' },
];

const getPreferredPayoutType = (fundAccount: FundAccount | null | undefined): PayoutType | string => {
  if (fundAccount?.preferredPayoutType === PayoutType.PAYPAL && !fundAccount?.payoutDetails?.paypal?.emailAddress) {
    return 'N/A';
  }

  return fundAccount?.preferredPayoutType || 'N/A';
};

const getLineDescription = (
  projectId: number | null | undefined,
  projectName = 'Missing project name',
  companyName = 'Missing company name',
): string => `${companyName} - ${projectName} - ${projectId ?? 'Missing project id'}`;

const removeWithdrawnPayoutsFilter = (row: MarketerPayout) => row.withdrawalType !== null;

const sortByProjectIdCycleId = (a: MarketerPayout, b: MarketerPayout) => {
  if (a.projectId === b.projectId && a.cycleId != null && b.cycleId != null) {
    return a.cycleId < b.cycleId ? -1 : 1;
  }

  if (a.projectId && b.projectId) {
    return a.projectId < b.projectId ? -1 : 1;
  }

  return 0;
};

// export const filterMarketersWithNoPayoutData = (m: MarketerPayoutsReviewItem): boolean =>
//   (m.payouts || []).filter(removeWithdrawnPayoutsFilter).length > 0;

// export const filterMarketersWithNoPayoutDataDataProcessor = (
//   data: MarketerPayoutsReviewItem[]
// ): MarketerPayoutsReviewItem[] => data.filter(filterMarketersWithNoPayoutData);

export const getTableData: DataProcessorHandler<MarketerPayoutsReviewItem, MarketersPayoutReviewDataRow> = async (
  data,
) => {
  // const { items = [] } = data || {};
  const items = data || ([] as MarketerPayoutsReviewItem[]);
  const marketerPayoutsReviewItems = items || [];
  // .filter(filterMarketersWithNoPayoutData);
  // .sort(sortByLabel('marketerId'));

  const tableData: MarketersPayoutReviewDataRow[] = [];
  let prevMarketerId = 0;
  let marketerRowIndex = -1;

  marketerPayoutsReviewItems.forEach((item) => {
    const { marketerId, marketer, payouts } = item;

    const payoutItemsLength = payouts.length + 1;

    const sortedPayoutItems = payouts.filter(removeWithdrawnPayoutsFilter).sort(sortByProjectIdCycleId);

    sortedPayoutItems.forEach(({ uuid, projectId, project, cycleId, cycle, amount }) => {
      const companyId = project?.companyId;
      const company = project?.company;

      if (prevMarketerId !== marketerId) {
        prevMarketerId = marketerId;
        marketerRowIndex += 1;

        tableData.push({
          rowIndex: marketerRowIndex,
          rowType: RowDataType.FULL,
          marketerPayoutUuid: null,
          projectPayoutItemsLength: payoutItemsLength,
          marketerId,
          marketerName: marketer.name,
          country: marketer.marketerProfile?.contactDetails?.postalAddress?.country,
          companyId: 0,
          companyName: company?.name,
          projectId: 0,
          projectName: project?.name,
          cycleId: null,
          cycleDate: null,
          amount: 0,
          balance: marketer.fundAccount?.balance,
          currency: marketer.fundAccount?.currency,
          preferredPayoutType: getPreferredPayoutType(marketer.fundAccount),
          lineDescription: getLineDescription(projectId, project?.name, company?.name),
        });
      }

      tableData.push({
        rowIndex: marketerRowIndex,
        rowType: RowDataType.PARTIAL,
        marketerPayoutUuid: uuid,
        projectPayoutItemsLength: payoutItemsLength,
        marketerId,
        marketerName: marketer.name,
        country: marketer.marketerProfile?.contactDetails?.postalAddress?.country,
        companyId,
        companyName: company?.name,
        projectId: projectId || project?.id,
        projectName: project?.name,
        cycleId,
        cycleDate: cycle?.endDate,
        amount,
        balance: marketer.fundAccount?.balance,
        currency: marketer.fundAccount?.currency,
        preferredPayoutType: getPreferredPayoutType(marketer.fundAccount),
        lineDescription: getLineDescription(projectId, project?.name, company?.name),
      });
    });
  });

  return tableData;
};

type MarketersPayoutsReviewQueryVariables = PagingSortingVariables & {
  marketerId?: number | null | undefined;
};

export const getQueryData = (marketerId: number | null | undefined): QueryData => {
  const queryClass = marketerId ? MarketerPayoutsReviewQuery : MarketersPayoutsReviewQuery;
  const variables: MarketersPayoutsReviewQueryVariables = {};

  if (marketerId) {
    variables.marketerId = marketerId;
  }

  return {
    queryClass,
    variables,
    queryEntityKey: 'marketerPayoutsReview.items',
    dataProcessor: getTableData,
  };
};

// export const getTableDataForSingleMarketer = async (marketer = {}) => getTableData([{ ...marketer }]);

export const smartSlice = (
  data: MarketersPayoutReviewDataRow[],
  page: number,
  marketersPerPage: number,
): MarketersPayoutReviewDataRow[] => {
  const startIndex = page * marketersPerPage;
  const endIndex = page * marketersPerPage + marketersPerPage;

  return data.filter(({ rowIndex }) => rowIndex >= startIndex && rowIndex < endIndex);
};

export const getFilteredMarketerPaymentData = (
  items: MarketersPayoutReviewDataRow[] = [],
  selectedPayoutData: SelectedPayoutData = {},
): MarketersPayoutReviewDataRow[] =>
  // first step - filter only selected payouts
  items.filter(
    (item) =>
      item.rowType === RowDataType.PARTIAL &&
      selectedPayoutData?.[item.marketerId] &&
      item.marketerPayoutUuid &&
      selectedPayoutData[item.marketerId].includes(item.marketerPayoutUuid),
  );

export const updateTotalBalance = (
  items: MarketersPayoutReviewDataRow[],
  selectedPayoutData: SelectedPayoutData,
): MarketersPayoutReviewDataRow[] => {
  const marketersIds = Object.keys(selectedPayoutData);
  const totalBalancePerMarketer: Record<string, number> = {};

  // init totalBalancePerMarketer
  marketersIds.forEach((marketerId) => {
    totalBalancePerMarketer[marketerId] = 0;
  });

  // calculate total balance per marketer
  items.forEach((item) => {
    const { marketerId } = item;
    totalBalancePerMarketer[marketerId] += item.amount;
  });

  // finally create an array with new total balance
  return items.map((item) => ({
    ...item,
    balance: totalBalancePerMarketer[item.marketerId],
  }));
};

/*
const mapMarketerProjectsData = (
  flattenMarketerData: Record<string, string>[],
  items: MarketersPayoutReviewDataRow[]
) => {
  // We need to reorganize the data, so it would fit our email report
  const aggregatedProjectsData = getAggregatedProjectsData(items);

  // And now we are adding the emailMessage field to the flattenMarketerData
  return addEmailMessageToMarketerData(flattenMarketerData, aggregatedProjectsData);
};
*/

/*
export const getUniqueMarketerPaymentData = (items: MarketersPayoutReviewDataRow[], columns: CSVColumn[]) => {
  // since the data rows are spread for each project and cycle, we need to re-map the data
  // in a way that would fit Tipalti CSV format.
  // As requested we get the marketerId, and the total payment as the main interest fields.
  const newItems: Record<string, string>[] = items.map((item) => {
    const newItem: Record<string, string> = {};
    columns.forEach(({ accessor }) => {
      if (accessor) {
        newItem[accessor] = get(item, accessor, '');
      }
    });
    return newItem;
  });

  // need to return unique objects
  // To understand better read here:
  // https://medium.com/coding-at-dawn/how-to-use-set-to-filter-unique-items-in-javascript-es6-196c55ce924b
  // https://gist.github.com/DoctorDerek/4fcdc502f0f37ec363782d5e9d0551ba
  const flattenMarketerData: Record<string, string>[] = [...new Set(newItems.map((o) => JSON.stringify(o)))].map(
    (string) => JSON.parse(string)
  );

  return mapMarketerProjectsData(flattenMarketerData, items);
};
*/

export const getCountryLabelFromCountryCode = (countryCode: Country, defaultValue = ''): string => {
  if (!countryCode) {
    return defaultValue;
  }

  return (COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[countryCode] || countryCode;
};

export const getReportMultipleMarketerPayoutAsWithdrawnManuallyVariables = (
  selectedPayoutData: SelectedPayoutData,
  description: string,
): {
  marketerPayoutUuidList: string[];
  description: string;
} => {
  let marketerPayoutUuidList = [] as string[];
  Object.values(selectedPayoutData).forEach((marketerPayoutUuids) => {
    marketerPayoutUuidList = [...marketerPayoutUuidList, ...marketerPayoutUuids];
  });

  return {
    marketerPayoutUuidList,
    description,
  };
};

/**
 * This function maps marketers objects into PayoutReviewItems response.
 * Keeping it here only for reference, as it is not in use since marketerPayoutReview was implemented in BE
 * @param marketers
 * @returns {Promise<{marketer: *, payoutItems: [], marketerId: *}[]>}
 */
/*
 const extractCycleIdRegexp = /Cycle #(\d*)/i;

 const mapMarketersToMarketerPayoutsReviewItems = async (marketers = []) => {

 return marketers.filter((marketer) => {

 const hasPositiveFundAccountBalance = marketer?.fundAccount?.balance > 0;

 const hasLiveOrFinishedProjects = marketer?.participants.filter((participant) => {
 return [ProjectLifeCycleStatus.LIVE, ProjectLifeCycleStatus.FINISHED]
 .includes(participant?.project?.projectLifeCycleStatus);
 }).length > 0;

 return hasPositiveFundAccountBalance && hasLiveOrFinishedProjects;
 }).map((marketer) => {

 // Getting the last PAYOUT date from withdrawals
 const withdrawals = marketer?.fundAccount?.withdrawals || [];
 const lastPayoutRecord = withdrawals.reverse().find(({ withdrawalTarget }) => {
 return withdrawalTarget === 'PAYOUT';
 });
 const lastPayoutDate = new Date(lastPayoutRecord?.created || '2000-01-01T00:00:00Z').getTime();

 // Filter all deposits from that lastPayoutDate until today to get the unpaid deposits
 const deposits = marketer?.fundAccount?.deposits || [];
 const unpaidDeposits = deposits.filter(({ created }) => {
 return new Date(created).getTime() > lastPayoutDate;
 });

 // Collect all cycleIds from unpaid deposits (can be for more than 1 project)
 const unpaidDepositsCycles = [];
 unpaidDeposits.forEach(({ description }) => {
 const cycle = description.match(extractCycleIdRegexp);
 if (cycle && cycle.length === 2) {
 unpaidDepositsCycles.push(parseInt(cycle[1], 10));
 }
 });

 // Now filtering only relevant participants with (LIVE + FINISHED) projects
 const participants = marketer?.participants.filter((participant) => {
 return [ProjectLifeCycleStatus.LIVE, ProjectLifeCycleStatus.FINISHED]
 .includes(participant?.project?.projectLifeCycleStatus);
 });

 const payoutDetails = [];

 // Get the projects relevant data
 participants.forEach(({ project, assignedMarketingSupervisorTempField: assignedMarketingSupervisor }) => {

 const { companyId, company } = project;

 // Filter over the project cycles include only cycles with unpaid deposits
 const cycles = project?.cycles.filter(({ id }) => {
 return unpaidDepositsCycles.includes(id);
 });

 // loop over the cycles and create MarketerPayoutItem
 cycles.forEach((cycle) => {
 const { id: cycleId, projectId, actualMarketerFee, endDate } = cycle;

 payoutDetails.push({
 projectId,
 project,
 companyId,
 company,
 cycleId,
 cycle,
 amount:      actualMarketerFee,
 currency:    marketer.fundAccount?.currency,
 earningDate: endDate,
 assignedMarketingSupervisor,
 });
 });
 });

 return {
 marketerId:         marketer.id,
 marketer,
 projectPayoutItems: payoutDetails,
 };
 });

 };
 */
