import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { TextDisplay } from '../../../display';
import SelectionCard from '../SelectionCard';

export class RadioCards extends React.Component {
  constructor(props) {
    super(props);

    this.renderCards = this.renderCards.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);

    this.state = {};
  }

  handleOnClick(item) {
    const {
      disabled,
      onCardClick,
      input: { onChange },
    } = this.props;
    // console.log('radio props', this.props);

    // TODO: add support for show glow selection outside of redux form
    // console.log(item);
    // this.setState({
    //   currentChannel: item,
    // });

    if (!item.disabled && !disabled) {
      onChange(item.value);
      onCardClick(item);
    }
  }

  handleOnMouseEnter(hoverText, key) {
    this.setState({ [key]: hoverText });
  }

  handleOnMouseLeave(key) {
    this.setState({ [key]: '' });
  }

  renderCards() {
    const {
      enums,
      input,
      currentSelected,
      cardsInRow,
      bigCard,
      recommendedEnums,
      showRecommendedEnums,
      disabled,
      ...rest
    } = this.props;

    const recommendedEnumsFinal = [];
    let enumsFinal = [...enums];
    // In case we want to show recommended options to select, we should pass array of strings, that are
    // a subset of the enums values.
    if (showRecommendedEnums && recommendedEnums && recommendedEnums.length > 0) {
      enumsFinal = [];
      // divide the enums for recommended / regular options
      enums.forEach((item) => {
        if (recommendedEnums.includes(item.value)) {
          recommendedEnumsFinal.push(item);
        } else {
          enumsFinal.push(item);
        }
      });
    }

    return (
      <React.Fragment>
        {showRecommendedEnums && recommendedEnumsFinal.length > 0 && (
          <React.Fragment>
            <Grid container alignItems="center" style={{ padding: 9 }}>
              <FavoriteIcon style={{ color: '#FD5444', marginRight: 8 }} />
              <TextDisplay variant="caption">{this.props.recommendedEnumsText}</TextDisplay>
            </Grid>
            <Grid container alignItems="stretch" justifyContent="space-around" spacing={2}>
              {recommendedEnumsFinal
                .filter(({ isDeprecated }) => !isDeprecated) // filter all deprecated items
                .map((item) => {
                  // set the number of cards in row for medium screens
                  let finalGrid = 4; // default 3
                  if (cardsInRow === 'four') {
                    finalGrid = 3;
                  } else if (cardsInRow === 'two') {
                    finalGrid = 6;
                  }

                  const { label, svgIcon, fontIcon, value, textOnHover, subtitle } = item;
                  const isChecked = value === input.value || value === currentSelected;

                  return (
                    <Grid item xs={12} md={finalGrid} key={`${input.name}-${value}`}>
                      <SelectionCard
                        textOnHover={textOnHover}
                        label={label}
                        icon={svgIcon || fontIcon}
                        selectionIndicatorType="showOnSelected"
                        cardType="radio"
                        disabled={disabled}
                        checked={isChecked}
                        onClick={() => {
                          this.handleOnClick(item);
                        }}
                        layout={bigCard ? 'vertical' : 'horizontal'}
                        subtitle={subtitle}
                        {...rest}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Divider style={{ margin: '32px 8px' }} />
          </React.Fragment>
        )}
        <Grid container alignItems="stretch" justifyContent="space-around" spacing={2}>
          {enumsFinal
            .filter(({ isDeprecated }) => !isDeprecated) // filter all deprecated items
            .map((item) => {
              // set the number of cards in row for medium screens
              let finalGrid = 4; // default 3
              if (cardsInRow === 'four') {
                finalGrid = 3;
              } else if (cardsInRow === 'two') {
                finalGrid = 6;
              }

              const { label, svgIcon, fontIcon, value, subtitle, textOnHover } = item;
              const isChecked = value === input.value || value === currentSelected;

              return (
                <Grid item xs={12} md={finalGrid} key={`${input.name}-${value}`}>
                  <SelectionCard
                    textOnHover={textOnHover}
                    label={label}
                    icon={svgIcon || fontIcon}
                    selectionIndicatorType="showOnSelected"
                    cardType="radio"
                    disabled={disabled}
                    checked={isChecked}
                    onClick={() => {
                      this.handleOnClick(item);
                    }}
                    layout={bigCard ? 'vertical' : 'horizontal'}
                    subtitle={subtitle}
                    {...rest}
                  />
                </Grid>
              );
            })}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return <div>{this.renderCards()}</div>;
  }
}

RadioCards.propTypes = {
  enums: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
  recommendedEnums: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.object, // inserted by redux form / other external wrapper
  currentSelected: PropTypes.string,
  recommendedEnumsText: PropTypes.string,
  cardsInRow: PropTypes.oneOf(['two', 'three', 'four']),
  onCardClick: PropTypes.func,
  bigCard: PropTypes.bool,
  disabled: PropTypes.bool,
  showRecommendedEnums: PropTypes.bool,
};

RadioCards.defaultProps = {
  cardsInRow: 'three',
  currentSelected: '',
  recommendedEnumsText: 'RECOMMENDED',
  bigCard: false,
  disabled: false,
  onCardClick: () => {},
  minHeight: 10,
  input: {
    onChange: () => {},
    value: null,
  },
  recommendedEnums: null,
  showRecommendedEnums: false,
};
