import React, { FC } from 'react';
import { Steps, Step } from 'react-albus';
import Grid from '@material-ui/core/Grid';

import { WizardStepField } from '../../../../../../../../fe_common/client/components/molecules/WizardStepField';
import {
  COUNTRIES_PAIRS,
  INDUSTRIES_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  TARGET_KPI_PAIRS,
} from '../../../../../../../../fe_common/client/app/enums';
import { Button } from '../../../../../../../../fe_common/client/components/inputs';

interface StepsProps {
  invalid: any;
  submit: any;
  submitting: any;
  onCancel: any;
  loading: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const WizardSteps: FC<StepsProps> = (props) => {
  const { invalid, submit, onCancel, loading } = props;

  return (
    <Steps>
      <Step
        id="marketerUpdateStep"
        render={() => (
          <div>
            <WizardStepField
              type={WizardStepField.types.AUTOCOMPLETE}
              required
              name="industry"
              // @ts-ignore
              label="Industry"
              // @ts-ignore
              enums={INDUSTRIES_PAIRS}
            />
            <WizardStepField
              type={WizardStepField.types.AUTOCOMPLETE}
              required
              name="locations"
              // @ts-ignore
              label="Target Countries"
              // @ts-ignore
              isMulti
              // @ts-ignore
              enums={COUNTRIES_PAIRS}
            />
            <WizardStepField
              type={WizardStepField.types.AUTOCOMPLETE}
              required
              name="productBusinessModel"
              // @ts-ignore
              label="Business model"
              // @ts-ignore
              isMulti
              // @ts-ignore
              enums={PRODUCT_BUSINESS_MODEL_PAIRS}
            />
            <WizardStepField
              type={WizardStepField.types.SELECT}
              required
              name="targetKPI"
              // @ts-ignore
              label="Target KPI"
              // @ts-ignore
              enums={TARGET_KPI_PAIRS}
            />

            <WizardStepField
              type={WizardStepField.types.URL_LIST}
              required
              name="targetUrls"
              // @ts-ignore
              label="Target URLs"
            />
            <Grid container justifyContent="flex-end">
              <Button label="cancel" onClick={onCancel} disabled={loading} />
              <Button
                color="primary"
                variant="contained"
                label="Submit"
                onClick={submit}
                disabled={loading || invalid}
                loading={loading}
              />
            </Grid>
          </div>
        )}
      />
    </Steps>
  );
};
