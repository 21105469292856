import { createTheme } from '@material-ui/core/styles';
import { colors, getGradientColor } from '../../../app/theme';

const fontFamily = ['"GT Walsheim Pro"', 'Roboto', 'sans-serif'].join(',');
const defaultMuiTheme = createTheme();

const materialTableTheme = createTheme({
  typography: {
    fontFamily,
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        [defaultMuiTheme.breakpoints.up('sm')]: {
          paddingLeft: 8,
        },
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 'bold',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 0,
      },
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          boxShadow: `0px 0px 6px 2px ${colors.blackOpacity(0.1)}`,
          backgroundColor: colors.white,
        },
      },
    },

    MuiTableCell: {
      head: {
        backgroundColor: `${getGradientColor('gray', 10)} !important`,
        color: `${getGradientColor('gray', 80)}`,
        fontWeight: 'bold',
      },
    },
  },
});

export default materialTableTheme;
