/* eslint-disable react/display-name */
import React from 'react';
import { ProjectCycle } from '@mayple/types';
import { Column } from 'material-table';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { ExtendedBillingBreakdownDocument } from './index';
import { dateTimeFormatter } from '../../../../../../fe_common/client/components/molecules/DataTable/logic';

export const getBillingBreakdownDocumentsTableColumns = (withCycleDate = false): Column<Record<string, unknown>>[] => [
  { title: 'documentUuid', field: 'uuid', hidden: true },
  {
    title: 'Public / Private',
    field: 'internalOnly',
    render: (rowData) =>
      rowData?.internalOnly ? (
        <Tooltip title="Internal Only">
          <span>
            <LockIcon />
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="Shared with customer too">
          <span>
            <PublicIcon />
          </span>
        </Tooltip>
      ),
    width: '10%',
  },
  // {
  //   title:  'Created',
  //   field:  'created',
  //   render: dateTimeFormatter('startDate', 'MMM YYYY'),
  //   width:  withCycleDate ? '15%' : 0, <--- ??
  // },
  { title: 'Document Id', field: 'documentId', width: '20%' },
  { title: 'Description', field: 'description', width: withCycleDate ? '45%' : '60%' },
  {
    hidden: !withCycleDate,
    title: 'Cycle Month',
    field: withCycleDate ? 'startDate' : undefined,
    render: withCycleDate ? dateTimeFormatter('startDate', 'MMM YYYY') : undefined,
    width: withCycleDate ? '15%' : 0,
  },
  {
    title: 'Download',
    field: 'fileUrl',
    render: (rowData) =>
      !!rowData?.fileUrl && (
        <Tooltip title="Click to download">
          <Link target="_blank" href={(rowData?.fileUrl as string) || '#'}>
            <InsertDriveFileIcon />
          </Link>
        </Tooltip>
      ),
    width: '10%',
  },
];

export const getProjectCyclesBillingBreakdownDocuments = (
  cycles: ProjectCycle[],
): ExtendedBillingBreakdownDocument[] => {
  let billingBreakdownDocuments = [] as ExtendedBillingBreakdownDocument[];

  cycles.forEach((cycle) => {
    const { startDate } = cycle;

    billingBreakdownDocuments = [
      ...billingBreakdownDocuments,
      ...cycle.billingBreakdownDocuments.map((billingBreakdownDocument) => ({
        ...billingBreakdownDocument,
        startDate,
      })),
    ];
  });

  return billingBreakdownDocuments;
};
