import React, { FC, useMemo } from 'react';
import { Field } from 'redux-form';

import ScoreButtonsInput from './ScoreButtonsInput';
import {
  validateIntegerGreaterThanOrEqualTo,
  validateIntegerLessThanOrEqualTo,
  validateRequired,
} from '../../../../services/validation';

interface WizardScoreButtonsFieldProps {
  name: string;
  isRequired?: boolean;
  minimumValue?: number;
  maximumValue?: number;
}

const WizardScoreButtonsField: FC<WizardScoreButtonsFieldProps> = (props) => {
  const { name, isRequired, minimumValue, maximumValue, ...rest } = props;

  const validator = useMemo(
    () =>
      [
        isRequired ? validateRequired : undefined,
        minimumValue ? validateIntegerGreaterThanOrEqualTo(minimumValue) : undefined,
        maximumValue ? validateIntegerLessThanOrEqualTo(maximumValue) : undefined,
      ].filter((validateFunction) => !!validateFunction),
    [minimumValue, maximumValue, isRequired],
  );

  return (
    <Field
      component={ScoreButtonsInput}
      name={name}
      validate={validator}
      {...{ minimumValue, maximumValue }}
      {...rest}
    />
  );
};

export default WizardScoreButtonsField;
