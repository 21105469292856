import React, { FC, useMemo } from 'react';
import { ProjectPackageDelayReason } from '@mayple/types';

import { WizardStepsProps } from './types';
import useProjectPackageDelayReasonTranslations from './useProjectPackageDelayReasonTranslations';

import { Button } from '../../inputs';
import { WizardStepField } from '../../molecules/WizardStepField';
import QueryErrorMessage from '../../common/QueryErrorMessage';
import { LabelValueProps } from '../../../app/types';

import useStyles from './style';

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const { overrideTranslations, error, submit, submitting, onClose } = props;

  const classes = useStyles();

  const { t, projectPackageDelayReasonPairs } = useProjectPackageDelayReasonTranslations(overrideTranslations);

  const projectPackageDelayReasonPairsFiltered: LabelValueProps<ProjectPackageDelayReason>[] = useMemo(
    () =>
      projectPackageDelayReasonPairs.filter(
        ({ value }) => value !== ProjectPackageDelayReason.DELAY_REASON_NOT_SPECIFIED
      ),
    [projectPackageDelayReasonPairs]
  );

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <WizardStepField
          type={WizardStepField.types.RADIO}
          name="delayReason"
          // @ts-ignore
          isRequired
          label="Delay Reason"
          color="primary"
          layout="vertical"
          enums={projectPackageDelayReasonPairsFiltered}
          autoFocus
        />
        <WizardStepField
          type={WizardStepField.types.TEXT}
          name="delayReasonMessage"
          // @ts-ignore
          label={t('delayReasonMessage')}
          isRequired
          isMultiLine
          minRows={3}
          fullWidth
        />
        {!!error && <QueryErrorMessage error={error} message={t('errorOnUpdate')} />}
      </div>
      <div className={classes.actionsWrapper}>
        <Button label="submit" variant="contained" color="primary" onClick={submit} loading={submitting} />
        <Button label="cancel" variant="outlined" color="primary" onClick={onClose} />
      </div>
    </div>
  );
};

export default WizardSteps;
