import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { DivestFundsOfMarketerMutation } from 'growl-graphql/dist/mutations/DivestFundsOfMarketerMutation';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';

import { Button } from '../../../../fe_common/client/components/inputs';

const styles = () => ({
  root: {},
});

const handleDivestMarketerFunds = async (marketer, divestFundsOfMarketerMutation) => {
  // TODO: Do this in a nice way... for now we're moving fast and this is only exposed to admins
  alert(`You are about to divest funds from marketer ${marketer.name} with ID: ${marketer.id}.\n
           You will be taking away REAL money that was paid by the marketer to be used in campaigns.\n
           Are you certain you want to do this? If not, please exit the following dialogs.`);
  const fundsToDivestStr = prompt('how much funds to divest? Enter a whole number in the account currency.');
  const fundsToDivest = parseInt(fundsToDivestStr, 10);
  const verificationAmount = prompt(
    `Enter the number ${fundsToDivestStr} again to divest ${fundsToDivest} ${marketer.fundAccount.currency} from the marketer.`,
  );
  const divestDesc = prompt('Please enter description for the reason divesting funds');

  if (String(fundsToDivest) !== verificationAmount) {
    alert('Failed divesting funds.');
    return;
  }

  if (!divestDesc || divestDesc.trim() === '') {
    alert('Failed granting funds. Please enter description.');
    return;
  }

  await divestFundsOfMarketerMutation.mutate({
    marketerId: marketer.id,
    withdrawalOperation: {
      amount: fundsToDivest,
      currency: marketer.fundAccount.currency,
      description: divestDesc || 'Funds divested manually by admin user with no description.',
    },
  });
};

const DivestMarketerFunds = ({ marketer, divestFundsOfMarketerMutation, classes }) => {
  const { fundAccount = {} } = marketer;
  const { id: fundAccountId, accountStatus, balance, currency, totalDebt } = fundAccount;

  return (
    <div className={classes.root}>
      <ul>
        <li>Status: {accountStatus} </li>
        <li>
          Balance: {balance} ({currency})
        </li>
        <li>
          Total debt: {totalDebt} ({currency})
        </li>
        <li>ID: {fundAccountId}</li>
      </ul>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          handleDivestMarketerFunds(marketer, divestFundsOfMarketerMutation);
        }}
        label="DIVEST FUNDS FROM MARKETER"
      />
    </div>
  );
};

DivestMarketerFunds.propTypes = {
  marketer: PropTypes.object.isRequired,
  divestFundsOfMarketerMutation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withMutation(DivestFundsOfMarketerMutation))(DivestMarketerFunds);
