import * as React from 'react';
import { ProjectLifeCycleStatus, ReactionType, UserType } from '@mayple/types';

export enum FEED_MEMBERS {
  POST = 'POST',
  COMMENT = 'COMMENT',
}

export type FeedMemberType = FEED_MEMBERS.POST | FEED_MEMBERS.COMMENT;

export type PostReactionIcon = {
  reactionType: ReactionType;
  label: string;
  icon: React.ComponentClass | React.FC;
  iconClass: string;
};

export type FeedEventTraits = {
  pageName: string;
  tabName?: string;
  projectName?: string;
  projectId?: number;
  projectLifeCycleStatus?: ProjectLifeCycleStatus;
  authorAccountName?: string | null;
  authorAccountId?: number | null;
  authorAccountType?: string | null;
  userType?: UserType;
  postUuid?: string;
  commentUuid?: string;
  topic?: string;
};
