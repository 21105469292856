import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';

import { getPackagePrice } from '../logic';
import { useOverviewStyles } from '../style';
import { TextDisplay } from '../../../display';
import { safeExtractCurrencyValue } from '../../../../services/utils';

interface PackagePriceProps {
  cost: number;
  discount: number;
  recurring: boolean;
  showOneTimeLabel?: boolean;
  currency: string;
}

const PackagePrice: FC<PackagePriceProps> = (props) => {
  const { cost, discount, recurring, currency, showOneTimeLabel = false } = props;
  const { costBeforeDiscount, costAfterDiscount } = getPackagePrice(cost, discount);
  const classes = useOverviewStyles(props);

  return (
    <div>
      {costBeforeDiscount !== costAfterDiscount && (
        <TextDisplay className={classes.costBeforeDiscount}>
          <span className={classes.costBeforeDiscountTxt}>
            {safeExtractCurrencyValue(costBeforeDiscount, currency, false)}
          </span>{' '}
          <Chip component="span" className={classes.discountChip} size="small" label={`${discount}% Off`} />
        </TextDisplay>
      )}
      <TextDisplay variant="h5" bold className={classes.costAfterDiscount} inline>
        {safeExtractCurrencyValue(costAfterDiscount, currency, false)}
      </TextDisplay>
      <TextDisplay className={classes.recurringText} inline variant="h6">
        {/* eslint-disable-next-line no-nested-ternary */}
        {recurring ? ' /mo' : showOneTimeLabel ? ' /one-time payment' : ''}
      </TextDisplay>
    </div>
  );
};

export default PackagePrice;
