import { IAvailabilityViewerContext } from './AvailabilityViewerContext';
import { AvailabilityViewerFilterData } from '../types';

export enum AvailabilityViewerActionType {
  UPDATE_FILTER = 'UPDATE_FILTER',
}

interface ReducerAction {
  type: string;
  payload?: any;
}

export interface AvailabilityViewerAction extends ReducerAction {
  type: AvailabilityViewerActionType;
  payload: AvailabilityViewerFilterData;
}

export type AvailabilityViewerDispatch = (action: ReducerAction) => void;

export const availabilityViewerReducer = (
  availabilityViewerContextState: IAvailabilityViewerContext,
  action: ReducerAction
): IAvailabilityViewerContext => {
  const { type, payload = null } = action;

  switch (type) {
    case AvailabilityViewerActionType.UPDATE_FILTER:
      return {
        ...availabilityViewerContextState,
        filterData: {
          ...availabilityViewerContextState.filterData,
          ...payload,
        },
      };
    default:
      return availabilityViewerContextState;
  }
};
