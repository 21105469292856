/* eslint-disable no-unused-vars */
// Redux-form supporting Range of Numbers inputs
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

import { SUPPORTED_CURRENCIES, CURRENCY_TYPE_PAIRS } from '../../../app/enums';
import NumericField from '../NumericField';

import { Select } from '../Registration/Select';

const styles = () => ({
  root: {
    width: '100%',
  },
  fromRoot: {
    width: '100%',
  },
  toRoot: {
    width: '100%',
  },
});

class NumberRangeInput extends React.Component {
  static currency = CURRENCY_TYPE_PAIRS;

  constructor(props) {
    super(props);

    const { input = {}, currency: defaultCurrency } = props;

    const { value = {} } = input;
    const { minimum = '', maximum = '', currency = undefined } = value;

    // console.log('NumberRangeInput input', input);

    let newState = {
      minimum,
      maximum,
    };

    if (currency || defaultCurrency) {
      newState = {
        ...newState,
        currency: currency || defaultCurrency,
      };
    }

    this.state = { ...newState };
  }

  handleOnChange = (value, fieldToUpdate) => {
    const { input } = this.props;

    const newValue = {
      ...this.state,
      [fieldToUpdate]: value,
    };

    this.setState(newValue);

    input.onChange(newValue);
  };

  render() {
    const {
      classes,
      fieldType,
      maximumValue,
      minimumValue,
      required,
      variant,
      meta,
      supportedCurrencies = SUPPORTED_CURRENCIES,
    } = this.props;

    const { minimum, maximum, currency } = this.state;
    const { error = '', pristine } = meta;

    let InputProps;

    if (currency) {
      const { symbol = '' } = supportedCurrencies.find((curr) => curr.value === currency);
      InputProps = { startAdornment: <InputAdornment position="start">{symbol}</InputAdornment> };
    }

    const withCurrency = fieldType === 'currency';
    const smWidth = withCurrency ? 5 : 6;
    return (
      <Grid
        container
        className={classes.root}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        {withCurrency && (
          <Grid item sm={2} xs={12}>
            <Select
              enums={supportedCurrencies}
              label="Currency"
              required={required}
              input={{
                value: currency,
                onChange: (value) => {
                  this.handleOnChange(value, 'currency');
                },
              }}
              onSelect={(value) => {
                this.setState({ currency: value });
              }}
            />
          </Grid>
        )}
        <Grid item sm={smWidth} xs={12}>
          <NumericField
            name="minimum"
            label="From"
            value={minimum}
            classes={{ root: classes.fromRoot }}
            variant={variant}
            fullWidth
            required={required}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            currency={currency}
            InputProps={InputProps}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(value) => {
              this.handleOnChange(value, 'minimum');
            }}
          />
        </Grid>
        <Grid item sm={smWidth} xs={12}>
          <NumericField
            name="maximum"
            label="To"
            value={maximum}
            classes={{ root: classes.toRoot }}
            variant={variant}
            fullWidth
            required={required}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            InputProps={InputProps}
            InputLabelProps={{
              shrink: true,
            }}
            currency={currency}
            onChange={(value) => {
              this.handleOnChange(value, 'maximum');
            }}
          />
        </Grid>
        {error && !pristine && (
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
        )}
      </Grid>
    );
  }
}

NumberRangeInput.propTypes = {
  fieldType: PropTypes.oneOf(['currency', 'number']),
  maximumValue: PropTypes.number,
  minimumValue: PropTypes.number,
  variant: PropTypes.string,
  required: PropTypes.bool,
  currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
  meta: PropTypes.object,
  input: PropTypes.shape({
    value: PropTypes.shape({
      minimum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      maximum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
    }),
    onChange: PropTypes.func,
  }),
  classes: PropTypes.object.isRequired,
  supportedCurrencies: PropTypes.arrayOf(PropTypes.object),
};

NumberRangeInput.defaultProps = {
  fieldType: 'number',
  maximumValue: 100,
  minimumValue: 0,
  required: true,
  variant: 'outlined',
  currency: '',
  meta: {},
  input: {
    value: {
      minimum: '',
      maximum: '',
      currency: '',
    },
    onChange: () => {},
  },
  supportedCurrencies: SUPPORTED_CURRENCIES,
};

export default withStyles(styles)(NumberRangeInput);
