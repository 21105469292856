import React, { FC } from 'react';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SuccessStoriesPanelsProps } from './types';
import ReferralContactsList from '../ReferralContactsList';

import { logos } from '../../../../../../fe_common/client/app/logos';
import { colors } from '../../../../../../fe_common/client/app/theme';
import ChipList from '../../../../../../fe_common/client/components/atoms/ChipList';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import { getIndustryLabel } from '../../../../../../fe_common/client/logic/industries';
import {
  AD_SPEND_LABELS_LOOKUP,
  COUNTRIES_LABELS_LOOKUP,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
} from '../../../../../../fe_common/client/app/enums';
import { formatKpiValuePercentageString } from '../../../../../../fe_common/client/services/utils';
import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';
import SkillsChipList from '../../../../../../fe_common/client/components/atoms/SkillsChipList';

import useStyles from './style';

/**
 * UI component for admins to easily view the success stories filled by marketers
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const SuccessStoriesPanels: FC<SuccessStoriesPanelsProps> = (props) => {
  const { successStories } = props;

  const classes = useStyles(props);

  if (!successStories || successStories.length === 0) {
    return <TextDisplay>No success stories information found.</TextDisplay>;
  }

  return (
    <div>
      {successStories.map((story, i) => {
        const {
          name,
          brandInfo,
          startDate,
          endDate,
          industry,
          websiteAddress,
          locations,
          skillsUsed,
          monthlyBudgetRangeAmount,
          targetKPI,
          targettedKPITypeImprovementPercentage,
          referralContacts,
          challenge,
          process,
          results,
        } = story;
        const iconUrl = brandInfo?.iconUrl || logos.logoSmall;
        const startDateFormatted = moment(startDate).format('MMMM YYYY');
        const endDateFormatted = endDate ? moment(endDate).format('MMMM YYYY') : 'Present';

        return (
          <Accordion key={`SuccessStoriesPanels-${i}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.content }}>
              <List>
                <ListItem>
                  <Avatar src={iconUrl} title="" />
                  <ListItemText primary={name} secondary={`${startDateFormatted} - ${endDateFormatted}`} />
                </ListItem>
              </List>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Industry
                </TextDisplay>
                <TextDisplay>
                  <strong>{getIndustryLabel(industry, true)}</strong>
                </TextDisplay>
                <Divider className={classes.divider} />
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Website
                </TextDisplay>
                <TextDisplay>
                  <a target="_blank" rel="noreferrer noopener" href={websiteAddress}>
                    {websiteAddress}
                  </a>
                </TextDisplay>
                <Divider className={classes.divider} />
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Skills
                </TextDisplay>
                <SkillsChipList marketingSkills={skillsUsed} labelsLookup={MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS} />
                <Divider className={classes.divider} />
                {!!targetKPI && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Target KPI
                    </TextDisplay>
                    <TextDisplay>
                      {targetKPI}{' '}
                      {formatKpiValuePercentageString(targetKPI, targettedKPITypeImprovementPercentage || undefined)}
                    </TextDisplay>
                    <Divider className={classes.divider} />
                  </>
                )}
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Budget managed
                </TextDisplay>
                {/* @ts-ignore */}
                <ChipList items={monthlyBudgetRangeAmount} labelsLookup={AD_SPEND_LABELS_LOOKUP} />
                <Divider className={classes.divider} />
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Locations targeted
                </TextDisplay>
                <ChipList items={locations} labelsLookup={COUNTRIES_LABELS_LOOKUP} />
                <Divider className={classes.divider} />
                {referralContacts && referralContacts.length > 0 && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Referral Contacts
                    </TextDisplay>
                    {/* @ts-ignore */}
                    <ReferralContactsList referralContacts={referralContacts} />
                  </>
                )}
                {!!challenge?.contents && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Challenge
                    </TextDisplay>
                    <RichTextViewer value={challenge} />
                    <Divider className={classes.divider} />
                  </>
                )}
                {!!process?.contents && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Process
                    </TextDisplay>
                    <RichTextViewer value={process} />
                    <Divider className={classes.divider} />
                  </>
                )}
                {!!results?.contents && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Results
                    </TextDisplay>
                    <RichTextViewer value={results} />
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default SuccessStoriesPanels;
