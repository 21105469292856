import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { User } from '@mayple/types';
import { PuppetUsersQuery } from 'growl-graphql/dist/queries/PuppetUsersQuery';
import { useFeedDispatch } from './FeedContext';
import { FeedActionType } from './feedReducer';

const usePuppetUsers = (canPostAsPuppet = false): void => {
  const dispatch = useFeedDispatch();

  const { loading: loadingPuppetUsers, data: puppetUsersData } = useQuery(PuppetUsersQuery.query, {
    skip: !canPostAsPuppet,
  });

  useEffect(() => {
    if (loadingPuppetUsers === true) {
      return;
    }

    const newPuppetUsers = (puppetUsersData?.puppetUsers || []).map((puppetUser: User) => ({
      label: puppetUser.displayName,
      value: puppetUser.personUuid,
    }));

    dispatch({
      type: FeedActionType.SET_PUPPET_USERS,
      payload: newPuppetUsers,
    });
  }, [dispatch, loadingPuppetUsers, puppetUsersData?.puppetUsers]);
};

export default usePuppetUsers;
