/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';
import { muiIcons } from '../../../../app/icons';
import { useDebouncedValue } from '../../../../hooks';

const styles = () => ({
  root: {},
  inputWrapper: {
    flexGrow: 1,
  },
  itemsListContainer: {
    marginBottom: 12,
  },
  itemContainer: {
    marginBottom: 8,
  },
  actionsContainer: {},
});

function TextList(props) {
  const {
    input,
    alwaysShowOneItem,
    maxItemsInList,
    label,
    addButtonLabel,
    addButtonIcon,
    placeholder,
    meta,
    required,
    noItemsMessage,
    listItemTextMaxLength,
    classes,
  } = props;

  // console.log('TextList props', props);

  const { invalid, touched, error } = meta;

  const { value, onChange = () => {} } = input;
  const items = value || [];

  if (alwaysShowOneItem && items.length === 0) {
    items.push('');
  }

  const debouncedItems = useDebouncedValue(items, 500);

  useEffect(() => {
    onChange(debouncedItems);
  }, [debouncedItems, onChange]);

  const handleOnChange = (newValue, changeIndex) => {
    const newItems = [...items];
    newItems[changeIndex] = newValue;
    onChange(newItems);
  };

  const handleOnAddNewItem = () => {
    // get out if there is a limit and items limit reached
    if (maxItemsInList && maxItemsInList > 0 && items.length >= maxItemsInList) {
      return;
    }

    const newItems = [...items, ''];
    onChange(newItems);
  };

  // remove url from list, and update redux form
  const handleOnDeleteItem = (deletedIndex) => {
    const newItems = [...items];
    newItems.splice(deletedIndex, 1);

    if (alwaysShowOneItem && newItems.length === 0) {
      newItems.push('');
    }

    onChange(newItems);
  };

  const handleOnEnterPress = (e) => {
    if (e.charCode === 13) {
      // enter key pressed
      e.preventDefault();
      handleOnAddNewItem();
    }
  };

  let extraProps = {};
  if (listItemTextMaxLength && listItemTextMaxLength > 0) {
    extraProps = {
      inputProps: {
        maxLength: listItemTextMaxLength,
      },
    };
  }

  return (
    <div className={classes.root}>
      <div className={classes.itemsListContainer}>
        {items.length === 0 && <TextDisplay variant="body1">{noItemsMessage}</TextDisplay>}
        {items.length > 0 &&
          items.map((item, index) => (
            <Grid container spacing={1} alignItems="center" key={`TextList-${index}`} className={classes.itemContainer}>
              <Grid item className={classes.inputWrapper}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={placeholder}
                  label={label}
                  onChange={(event) => {
                    const { value: targetValue } = event.target;
                    handleOnChange(targetValue, index);
                  }}
                  onKeyPress={handleOnEnterPress}
                  helperText={error}
                  error={error}
                  value={item}
                  size="small"
                  autoFocus
                  {...extraProps}
                />
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleOnDeleteItem(index);
                  }}
                  disabled={alwaysShowOneItem && items.length === 1}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </div>
      <div className={classes.actionsContainer}>
        <Button
          label={addButtonLabel}
          startIcon={addButtonIcon}
          onClick={handleOnAddNewItem}
          disabled={maxItemsInList && maxItemsInList > 0 && items.length >= maxItemsInList}
        />
      </div>
      {
        // this is to show a required error when user attempts to submit without adding at least 1 url
        required && invalid && touched && items.length < 1 && (
          <TextDisplay variant="caption" className={classes.error}>
            {error}
          </TextDisplay>
        )
      }
    </div>
  );
}

TextList.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  addButtonLabel: PropTypes.string,
  addButtonIcon: PropTypes.node,
  value: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  noItemsMessage: PropTypes.string,
  alwaysShowOneItem: PropTypes.bool,
  maxItemsInList: PropTypes.number,
  listItemTextMaxLength: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

TextList.defaultProps = {
  label: 'Item',
  placeholder: '',
  addButtonLabel: 'New Item',
  addButtonIcon: muiIcons.add,
  required: false,
  meta: {},
  value: [],
  input: {
    value: null,
    onChange: () => {},
  },
  noItemsMessage: 'No items added yet...',
  alwaysShowOneItem: true,
  maxItemsInList: null, // unlimited
  listItemTextMaxLength: null, // unlimited
};

export default withStyles(styles)(TextList);
