import { Answers } from '../consts';
import { ProjectStopReasonsFormValues } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getDefaultFormValues = (): ProjectStopReasonsFormValues => ({
  isExpertBeingReplaced: Answers.NO,
  isClientHaveOtherProject: Answers.NO,
  stopProjectInitiator: Answers.CLIENT,
  isClientHappyWithExpert: Answers.I_DONT_KNOW,
  isClientHappyWithMayple: Answers.I_DONT_KNOW,
  isPackageRecurring: Answers.I_DONT_KNOW,
  isProjectSeasonal: Answers.I_DONT_KNOW,
  isClientHavingBusinessIssues: Answers.I_DONT_KNOW,
  description: '',
});
