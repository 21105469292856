import React, { useCallback } from 'react';
import { Project } from '@mayple/types';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { submit } from 'redux-form';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import SendIcon from '@material-ui/icons/Send';
import { CreateProjectOpportunitiesMutation } from 'growl-graphql/dist/mutations/admin/CreateProjectOpportunitiesMutation';

import { OpportunitiesCandidatesForm } from '../OpportunitiesCandidates/types';
import { saveProjectOpportunities } from '../OpportunitiesCandidates/logic';
import SendProjectOpportunityWizardSteps from '../OpportunitiesCandidates/SendProjectOpportunityWizardSteps';
import useProjectFlowConfiguration from '../../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';

import EntityType from '../../../../../app/types';
import useEntityNoteData from '../../../../../components/cpanel/Notes/hooks/useEntityNoteData';

import { Button } from '../../../../../../fe_common/client/components/inputs';
import { useDialog, useMutation } from '../../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../../fe_common/client/services/logger';
import ConfirmDialog from '../../../../../../fe_common/client/components/dialogs/ConfirmDialog';
import { WizardForm } from '../../../../../../fe_common/client/components/organisms/WizardForm';

interface SendOpportunitiesButtonProps {
  projectId: number;
  project: Project | null | undefined;
  opportunitiesCandidates: string[] | number[];
  onUpdateOpportunities?: () => void;
  onSuccess?: () => void;
  classes?: Record<string, any>;
}

const SendOpportunitiesButton: React.FC<SendOpportunitiesButtonProps> = (props) => {
  const { projectId, project, opportunitiesCandidates, onUpdateOpportunities, onSuccess } = props;

  const wizardFormSendOpportunityName = 'create-opportunities';
  const dispatch = useDispatch();
  const dueDateTime = moment().add(24, 'hours');

  const {
    open: showConfirmSendOpportunityDialog,
    openDialog: openConfirmSendOpportunityDialog,
    closeDialog: closeConfirmSendOpportunityDialog,
  } = useDialog();

  const {
    flowConfiguration: initialFlowConfiguration,
    saveProjectFlowConfiguration,
    saving: savingFlowConfiguration,
    loading: loadingFlowConfiguration,
    refetch: refetchFlowConfiguration,
  } = useProjectFlowConfiguration(projectId);

  const { note: projectNote } = useEntityNoteData(projectId, EntityType.Project);
  const { note: companyNote } = useEntityNoteData(project?.companyId, EntityType.Company);

  const { mutate: createProjectOpportunitiesMutation, loading: savingProjectOpportunities } = useMutation(
    CreateProjectOpportunitiesMutation,
  );

  const handleSendOpportunities = () => {
    openConfirmSendOpportunityDialog();
  };

  const saveProjectConfiguration = useCallback(
    async (formValues: OpportunitiesCandidatesForm) => {
      const { includeCompanyNote, includeProjectNote } = formValues;
      await saveProjectFlowConfiguration('opportunityIncludeCompanyNote', includeCompanyNote ? 1 : 0);
      await saveProjectFlowConfiguration('opportunityIncludeProjectNote', includeProjectNote ? 1 : 0);
    },
    [saveProjectFlowConfiguration],
  );

  const handleOnConfirmSendOpportunity = async () => {
    await dispatch(submit(wizardFormSendOpportunityName));
  };

  const createProjectOpportunities = async (formValues: OpportunitiesCandidatesForm) => {
    const result = await saveProjectOpportunities(formValues, createProjectOpportunitiesMutation);
    const isSuccess = result?.data?.createProjectOpportunities?.success;
    if (isSuccess === true) {
      // clear selected marketers
    }
  };

  const handleOnSubmitSendOpportunity: FormSubmitHandler<OpportunitiesCandidatesForm> = async (formValues) => {
    try {
      await saveProjectConfiguration(formValues);
      await createProjectOpportunities(formValues);

      await refetchFlowConfiguration?.();
      onUpdateOpportunities?.();
      closeConfirmSendOpportunityDialog();
      // clear the selected marketers
      onSuccess?.();

      return true;
    } catch (e) {
      handleClientError(e, 'problem saving opportunities data');
      return false;
    }
  };

  return (
    <>
      <Button
        label="Send Opportunities"
        color="primary"
        variant="contained"
        startIcon={<SendIcon />}
        disabled={opportunitiesCandidates.length === 0}
        onClick={handleSendOpportunities}
      />
      {showConfirmSendOpportunityDialog && (
        <ConfirmDialog
          open={showConfirmSendOpportunityDialog}
          onCancel={closeConfirmSendOpportunityDialog}
          onConfirm={handleOnConfirmSendOpportunity}
          containerMaxWidth="lg"
          loading={savingFlowConfiguration || loadingFlowConfiguration || savingProjectOpportunities}
          fullWidth
          content={
            <WizardForm
              steps={SendProjectOpportunityWizardSteps}
              form={wizardFormSendOpportunityName}
              initialValues={{
                opportunitiesCandidates,
                dueDateTime,
                projectId,
                // flowConfiguration: initialFlowConfiguration,
                includeCompanyNote: initialFlowConfiguration?.opportunityIncludeCompanyNote?.value || false,
                includeProjectNote: initialFlowConfiguration?.opportunityIncludeProjectNote?.value || false,
                companyNote,
                projectNote,
              }}
              // @ts-ignore
              onSubmit={handleOnSubmitSendOpportunity}
              destroyOnUnmount
              watchAllValues
            />
          }
          title="Send Opportunities"
        />
      )}
    </>
  );
};

export default SendOpportunitiesButton;
