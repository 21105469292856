import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { QueryClass, UseSubTasksData } from './types';
import EmptyQuery from '../../query/EmptyQuery';

const useSubTasks = <T = any>(
  subTasksOfTaskQueryClass: QueryClass | null,
  taskUuid?: string | null,
  options?: QueryHookOptions<any>,
  projectId?: number | null,
): UseSubTasksData<T> => {
  const variables = {
    taskUuid,
  };

  const { data, loading, error, refetch } = useQuery(subTasksOfTaskQueryClass?.query || EmptyQuery, {
    skip: !taskUuid || !subTasksOfTaskQueryClass?.query,
    notifyOnNetworkStatusChange: true,
    variables,
    ...options,
  });

  const dataKey = (subTasksOfTaskQueryClass?.name || '').replace('Query', '');

  // console.log('dataKey', dataKey);
  // console.log('data', data);

  return {
    subTasks: (data?.[dataKey] || ([] as T[])).filter((subTask: T) =>
      // @ts-ignore
      projectId && subTask?.projectId ? subTask.projectId === projectId : true,
    ),
    refetch,
    loading,
    error,
  };
};

export default useSubTasks;
