import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TextField from '../../Registration/TextField';
import { validateLinkedInUrl, validateUrl, validateRequired } from '../../../../services/validation';
import { TextDisplay } from '../../../display';

export function WizardUrlField(props) {
  const {
    name,
    hintText,
    fieldType,
    floatingLabelText,
    label,
    isRequired,
    onBlur,
    isLinkedin,
    helperText,
    variant,
    onEnterPress,
    extraValidation,
  } = props;

  // field required?
  let validator = isRequired ? [validateRequired] : [];

  if (isLinkedin) {
    validator.push(validateLinkedInUrl);
  } else {
    validator.push(validateUrl);
  }

  if (extraValidation && extraValidation.length) {
    validator = [...validator, ...extraValidation];
  }

  return (
    <div>
      <Field
        component={TextField}
        type={fieldType}
        name={name}
        placeholder={hintText}
        fullWidth
        label={label || floatingLabelText}
        variant={variant}
        validate={validator}
        onBlur={onBlur}
        onKeyUp={(e) => {
          e.preventDefault();
          if (onEnterPress && (e.key === 'Enter' || e.keyCode === 13)) {
            onEnterPress(e);
          }
        }}
      />
      {helperText && (
        <TextDisplay variant="caption" colorOverride="#887B77" style={{ margin: '2px 0 -19px 12px' }}>
          {helperText}
        </TextDisplay>
      )}
    </div>
  );
}

WizardUrlField.propTypes = {
  name: PropTypes.string.isRequired,
  // form:       PropTypes.string.isRequired,
  // dispatch:   PropTypes.func.isRequired,
  hintText: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  helperText: PropTypes.string,
  fieldType: PropTypes.string,
  floatingLabelText: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isLinkedin: PropTypes.bool,
  onBlur: PropTypes.func,
  onEnterPress: PropTypes.func,
  extraValidation: PropTypes.arrayOf(PropTypes.func),
};

WizardUrlField.defaultProps = {
  hintText: '',
  helperText: '',
  floatingLabelText: '',
  label: '',
  variant: 'outlined',
  fieldType: 'text',
  isRequired: true,
  isLinkedin: false,
  onBlur: () => {},
  onEnterPress: () => {},
  extraValidation: [],
};
