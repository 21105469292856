import React from 'react';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';

import EmptyState, { EmptyStateProps } from '../../atoms/EmptyState';

import materialTableTheme from './style';

interface DataTableProps extends MaterialTableProps<any> {
  emptyStateProps?: EmptyStateProps;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxWidth: '100%',
  },
}));

const DataTable: React.FC<DataTableProps> = (props) => {
  const { data, columns, options = {}, emptyStateProps, loading = false, ...rest } = props;
  const classes = useStyles();
  const shouldRenderEmptyState = emptyStateProps != null && (!data || data.length === 0) && !loading;

  return (
    <>
      {shouldRenderEmptyState && emptyStateProps != null && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <EmptyState {...emptyStateProps} />
      )}
      {!shouldRenderEmptyState && (
        <MuiThemeProvider theme={materialTableTheme}>
          <TableContainer className={classes.tableContainer}>
            <MaterialTable
              isLoading={loading}
              columns={columns}
              data={data}
              options={{
                ...options,
              }}
              {...rest}
            />
          </TableContainer>
        </MuiThemeProvider>
      )}
    </>
  );
};

export default DataTable;
