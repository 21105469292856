import React, { FC, useCallback, useState } from 'react';
import { ExecutionResult } from 'graphql';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AddUserAlternativeEmailAddressMutation } from 'growl-graphql/dist/mutations/user/AddUserAlternativeEmailAddressMutation';
import { RemoveUserAlternativeEmailAddressMutation } from 'growl-graphql/dist/mutations/user/RemoveUserAlternativeEmailAddressMutation';

import {
  AddUserAlternativeEmailAddressExecutionResult,
  AlternativeEmailAddressesEditorProps,
  RemoveUserAlternativeEmailAddressExecutionResult,
  ViewMode,
} from './types';
import WizardSteps from './WizardSteps';
import CalloutMessage from '../../../common/CalloutMessage';
import { useMutation } from '../../../../hooks';
import { WizardForm } from '../../../organisms/WizardForm';
import { TextDisplay } from '../../../display';
import { Button } from '../../../inputs';
import { handleClientError } from '../../../../services/logger';

import useStyles from './style';

const AlternativeEmailAddressesEditor: FC<AlternativeEmailAddressesEditorProps> = (props) => {
  const { userId, alternativeEmailAddresses, onSuccess } = props;
  const classes = useStyles(props);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.readonly);

  const { mutate: addUserAlternativeEmailAddress } = useMutation(AddUserAlternativeEmailAddressMutation);
  const { mutate: removeUserAlternativeEmailAddress } = useMutation(RemoveUserAlternativeEmailAddressMutation);

  const formName = `alternativeEmailAddressesEditor-${userId}`;

  const hasAlternativeEmailAddresses = !!alternativeEmailAddresses?.length;

  const getVariables = useCallback(
    (values: any): Record<string, any> => ({
      userId,
      alternativeEmailAddress: values?.alternativeEmailAddress,
    }),
    [userId]
  );

  const onRemoveAlternativeEmailAddressClickHandler = useCallback(
    (value: string) => async () => {
      try {
        const variables = {
          userId,
          alternativeEmailAddress: value,
        };

        const result: ExecutionResult<RemoveUserAlternativeEmailAddressExecutionResult> =
          await removeUserAlternativeEmailAddress({ variables });

        if (result?.data?.removeUserAlternativeEmailAddress?.success) {
          await onSuccess?.();
        }
      } catch (e) {
        handleClientError(e, 'Error while trying to add alternative email address.');
      }
    },
    [onSuccess, removeUserAlternativeEmailAddress, userId]
  );

  const onSubmitHandler: FormSubmitHandler = useCallback(
    async (values, _dispatch, formProps) => {
      try {
        const variables = getVariables(values);
        const result: ExecutionResult<AddUserAlternativeEmailAddressExecutionResult> =
          await addUserAlternativeEmailAddress({ variables });

        if (result?.data?.addUserAlternativeEmailAddress?.success) {
          await onSuccess?.();
          formProps?.reset?.();
        }
      } catch (e) {
        handleClientError(e, 'Error while trying to add alternative email address.');
      }
    },
    [addUserAlternativeEmailAddress, getVariables, onSuccess]
  );

  if (!userId) {
    return <CalloutMessage type="error" title="Error" message="Missing user id." />;
  }

  return (
    <>
      <TextDisplay variant="subtitle1" bold gutterBottom>
        Alternative Email Addresses
      </TextDisplay>
      {viewMode === ViewMode.edit && (
        <div className={classes.rootEditMode}>
          <WizardForm
            form={formName}
            initialValues={{ userId, alternativeEmailAddress: '' }}
            onSubmit={onSubmitHandler}
            destroyOnUnmount
            // @ts-ignore
            steps={WizardSteps}
            watchAllValues
            alternativeEmailAddresses={alternativeEmailAddresses}
            onSave={() => onSuccess?.()}
            onCancel={() => setViewMode(ViewMode.readonly)}
          />
        </div>
      )}
      {!hasAlternativeEmailAddresses && <CalloutMessage message="No alternative email addresses were set." margin />}
      {hasAlternativeEmailAddresses && (
        <div className={classes.rootViewMode}>
          <List classes={{ root: classes.rootList }}>
            {(alternativeEmailAddresses || []).map((alternativeEmailAddress) => (
              <ListItem key={`user-alternativeEmailAddress-${alternativeEmailAddress.emailAddress}`}>
                <ListItemText>{alternativeEmailAddress.emailAddress}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={onRemoveAlternativeEmailAddressClickHandler(alternativeEmailAddress.emailAddress)}
                  >
                    {viewMode === ViewMode.edit && <DeleteForeverIcon color="secondary" />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {viewMode === ViewMode.readonly && (
        <div className={classes.actions}>
          <Button
            label="Edit"
            endIcon={<EditIcon />}
            color="primary"
            variant="outlined"
            onClick={() => setViewMode(ViewMode.edit)}
          />
        </div>
      )}
    </>
  );
};

export default AlternativeEmailAddressesEditor;
