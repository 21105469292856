import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import PackageImage from './PackageImage';
import { TextDisplay } from '../../../display';
import RichTextViewer from '../../../common/RichTextViewer';
import CalloutMessage from '../../../common/CalloutMessage';
import { colorPalette } from '../../../../app/theme';

interface PackageSectionProps {
  section: Record<string, any>;
  reverse?: boolean;
  classes?: Record<string, string>;
}

const useStyles = makeStyles(
  createStyles({
    root: {
      marginBottom: 72,
    },
    overline: {
      color: colorPalette.blue[80],
    },
    title: {
      marginBottom: 32,
    },
    callout: {
      marginTop: 32,
    },
  }),
  { index: 1 }
);

const PackageSection: FC<PackageSectionProps> = (props) => {
  const { section, reverse = false } = props;
  const classes = useStyles(props);
  const { image, overline, title, content, callout } = section;

  return (
    <Grid container direction={reverse ? 'row-reverse' : 'row'} spacing={4} className={classes.root}>
      {image && (
        <Grid item xs={12} md={6}>
          <PackageImage image={section.image} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {!!overline && (
          <TextDisplay variant="overline" className={classes.overline}>
            {overline}
          </TextDisplay>
        )}
        {!!title && (
          <TextDisplay variant="h5" className={classes.title}>
            {title}
          </TextDisplay>
        )}
        {/* @ts-ignore */}
        {!!content && <RichTextViewer value={content} />}
        {!!callout && (
          <CalloutMessage
            classes={{ root: classes.callout }}
            title={callout.title}
            message={callout.subtitle}
            type={callout.type}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PackageSection;

// const additionalServicesFlavors = [
//   {
//     serviceType: 'SEARCH_ENGINE_OPTIMIZATION',
//     flavors:     [
//       {
//         flavor:                   'Core',
//         variableFactorTitle:      'title',
//         variableFactorUnit:       'test',
//         variableFactorCount:      2,
//         description:              {
//           mediaType: 'TEXT_HTML',
//           contents:  '<p>content</p>',
//         },
//         cost:                     1000,
//         currency:                 'USD',
//         recurring:                true,
//         marketingServiceTypeInfo: {
//           name:               'SEO',
//           description:        {
//             contents:  '<p>Use SEO to rank higher on search engines for the search terms related to your niche, and
// improve the quality/quantity of organic traffic to your site.</p>', mediaType: 'TEXT_HTML', }, features:           [
// 'Industry & competitors research', 'Keyword research, relationship building for backlinks, and building/optimizing
// your "Google My Business" account', 'Hands-on SEO strategy by one of our vetted Mayple Experts', 'Continuous
// optimization to make sure you hit your goals', 'Content calendar recommending 1 article a month', ], badges:
//     [ { title:    'Advertising on 2 platforms  (Facebook and Instagram count as one)', iconCode: 'facebookAds', }, {
// title:    '$600/mo per additional platform', iconCode: 'taboola', }, ], faq:                [ { question: 'How long
// before I should expect to see results?', answer:   { contents:  '<p>SEO is a powerful tool but it does take time. In
// fact, it generally takes 4-6 months for an SEO strategy to start showing results.</p>', mediaType: 'TEXT_HTML', },
// }, { question: 'How long before I should expect to see results?', answer:   { contents:  '<p>SEO is a powerful tool
// but it does take time. In fact, it generally takes 4-6 months for an SEO strategy to start showing results.</p>',
// mediaType: 'TEXT_HTML', }, }, { question: 'How long before I should expect to see results?', answer:   { contents:
// '<p>SEO is a powerful tool but it does take time. In fact, it generally takes 4-6 months for an SEO strategy to
// start showing results.</p>', mediaType: 'TEXT_HTML', }, }, ], supportedPlatforms: [{ title: '', iconCode: '' }],
// examples:           { title:  'At Mayple you can rest easy with', images: [ { url:
// 'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    'title', subtitle: 'subtitle', }, { url:
// 'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    'title', subtitle: 'subtitle', }, { url:
// 'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    'title', subtitle: 'subtitle', }, { url:
// 'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    'title', subtitle: 'subtitle', }, { url:
// 'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    'title', subtitle: 'subtitle', }, ], badges: [ {
// title: 'Re-engaging with potential clients the right way.', iconCode: 'googleAds' }, { title: 'Re-engaging with
// potential clients the right way.', iconCode: 'google' }, ], }, about:              [ { title:   'Tab 1', content: [{
// image:    { url:      'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    '', subtitle: '', },
// overline: '#2 assets creation', title:    'Your marketing expert will create automated email templates for each
// flow', content:  { contents:  '<p>\n' + '<strong>SEO is a great option for eComm businesses</strong>because people
// find you in an organic way. Growing your organic search appearance will help you to establish your brand as an
// authority in its field. This means that these leads will be both higher intent as well as having more trust in your
// business. The other advantage is that unlike PPC advertising\n' + 'where you literally "pay-per-click", when you use
// SEO your clicks are free!</p>\n' + '<p>The world of SEO is about deeply understanding your industry, improving your
// content, getting promoted by other websites in your industry, and so much more...</p>', mediaType: 'TEXT_HTML', },
// callout:  { title:    'Important to know:', subtitle: 'This includes the design, copywriting, setup, and QA of your
// emails\n' + 'Your Marketing Expert offers up to 2 revisions per email.', type:     'info', }, }], }, { title:   'Tab
// 2', content: [{ image:    { url:      'https://cdn.filestackcontent.com/CWP5J4TYTPWtOftH6Z3A', title:    '',
// subtitle: '', }, overline: '#3 assets creation', title:    'Your marketing expert will create automated email
// templates for each flow', content:  { contents:  '<p>\n' + '<strong>SEO is a great option for eComm
// businesses</strong>because people find you in an organic way. Growing your organic search appearance will help you
// to establish your brand as an authority in its field. This means that these leads will be both higher intent as well
// as having more trust in your business. The other advantage is that unlike PPC advertising\n' + 'where you literally
// "pay-per-click", when you use SEO your clicks are free!</p>\n' + '<p>The world of SEO is about deeply understanding
// your industry, improving your content, getting promoted by other websites in your industry, and so much
// more...</p>', mediaType: 'TEXT_HTML', }, callout:  { title:    'Important to know:', subtitle: 'This includes the
// design, copywriting, setup, and QA of your emails\n' + 'Your Marketing Expert offers up to 2 revisions per email.',
// type:     'info', }, }], }, ], whatExpertsDo:      [ { image:    { url:
// 'https://cdn.filestackcontent.com/qcRPZgA1Rxi41cHd5Qa4', title:    '', subtitle: '', }, overline: '', title:
// 'Research and Analysis', content:  { contents:  '<p><strong>Audit:<strong> In the first month your SEO Expert will
// run an audit of your website including: </p>\n' + '<p>Determining your website speed (crawl), looking for broken
// links, checking your inbound links, assessing your image tags, site hierarchy, URL naming, meta data, and how you
// structure your data. </p>\n' + '<p>They will also analyze the market, start with keyword research, and run a Google
// Analytics audit to check that it\'s working, and if it isn\'t, offer guidance on implementation/configuration.
// </p>\n' + '<p>Once this is done, they will use use their results to improve their roadmap and strategy. </p>',
// mediaType: 'TEXT_HTML', }, callout:  { title:    'Important to know:', subtitle: 'This includes the design,
// copywriting, setup, and QA of your emails\n' + 'Your Marketing Expert offers up to 2 revisions per email.', type:
//  'info', }, }, ], }, }, { flavor:                   'Extra', variableFactorTitle:      'title extra',
// variableFactorUnit:       'test', variableFactorCount:      2, description:              { mediaType: 'TEXT_HTML',
// contents:  '<p>content</p>', }, cost:                     2000, currency:                 'USD', recurring:
//       true, marketingServiceTypeInfo: { name: 'SEO', // ... rest of Extra flavor marketingServiceTypeInfo fields },
// }, { flavor:                   'Audit', variableFactorTitle:      'title audit', variableFactorUnit:       'test',
// variableFactorCount:      2, description:              { mediaType: 'TEXT_HTML', contents:  '<p>content</p>', },
// cost:                     900, currency:                 'USD', recurring:                false,
// marketingServiceTypeInfo: { name: 'SEO', // ... rest of Audit flavor marketingServiceTypeInfo fields }, }, ], }, {
// serviceType: 'AFFILIATE_MARKETING', flavors:     [], // This is not a defined service, so no flavors exist }, ];
