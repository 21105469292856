import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { UrlListProps } from './types';

import { TextDisplay } from '../../../display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxHeight: 190,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    emptyList: {
      padding: '2px 0px 2px 0px',
    },
    item: {
      padding: '2px 0px 2px 0px',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

const UrlListViewMode: React.FC<UrlListProps> = (props) => {
  const classes = useStyles();

  const { items, alternate } = props;

  if (!items?.length) {
    return (
      <div className={classNames(classes.root, classes.emptyList)}>
        <TextDisplay variant="subtitle1" color={alternate ? 'textSecondary' : 'textPrimary'}>
          Empty URL list
        </TextDisplay>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        const text = items.length > 1 ? `${index + 1}) ` : null; // list the urls if more than 1

        return (
          <div
            key={`url-key-${window.btoa(decodeURIComponent(item.replace(/https?:\/\//, '')))}`}
            className={classes.item}
          >
            <a target="_blank" rel="noopener noreferrer" href={item} className={classes.link}>
              <TextDisplay variant="subtitle1" color={alternate ? 'textSecondary' : 'textPrimary'}>
                {text} <u>{item}</u>
              </TextDisplay>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default UrlListViewMode;
