import { Project, SharedFile } from '@mayple/types';

export const removeSharedFileHandler = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  file: any,
  projectId: number | undefined,
  removeProjectSharedFileMutation: (options?: any) => Promise<any>,
): Promise<void> => {
  const didConfirm = window.confirm(`Are you sure you want to delete the file ${file.name} from this project?`);

  if (!didConfirm) {
    return;
  }

  const variables = {
    projectId,
    sharedFileId: file.id,
  };

  await removeProjectSharedFileMutation({ variables });
};

export const onAuditFileAddedEventHandler = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadedFile: any,
  projectId: number | undefined,
  addMaypleSharedFileToProjectMutation: (options?: any) => Promise<any>,
): Promise<void> => {
  const { filename, mimetype, size, url } = uploadedFile;

  const variables = {
    projectId,
    sharedFileCreate: {
      name: filename,
      description: 'added file by mayple admin',
      mimetype,
      size,
      url,
      category: 'MARKETING_AUDIT',
      uploaderContext: 'admin',
      isMaypleFile: true,
    },
  };
  await addMaypleSharedFileToProjectMutation({ variables });
};

export const useAllProjectFiles = (project: Project | null | undefined): SharedFile[] => [
  ...(project?.sharedFiles || []),
  ...(project?.participants?.[0]?.sharedFiles || []),
];
