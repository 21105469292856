import { TaskType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';

import { Assessment, AssessmentData, ValidAssessmentTaskTypesKeys } from './types';
import { getAssessmentQueryByTaskType, getAssessments } from './logic';

const useAssessmentDataForProject = <T>(taskType: TaskType, projectId: number): AssessmentData => {
  const variables = { projectId };

  const { query, dataKey, mapper } = getAssessmentQueryByTaskType(taskType as ValidAssessmentTaskTypesKeys);

  const { data, loading, error, refetch } = useQuery(query, {
    variables,
    // skip: taskType === TaskType.PostProjectMatchOfferMarketerAssessment,
  });

  const assessments: Assessment[] = getAssessments<T>(data?.[dataKey], mapper);

  return {
    assessments,
    loading,
    error,
    refetch,
  };
};

export default useAssessmentDataForProject;
