import { svgs } from '../../app/svg';
import { SUPPORTED_CURRENCIES } from '../../app/enums';

export const BRIEF_SUPPORTED_CURRENCIES = [
  ...SUPPORTED_CURRENCIES,
  {
    label: 'AUD',
    value: 'AUD',
    name: 'Australian Dollar',
    symbol: '$',
  },
  {
    label: 'CAD',
    value: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$',
  },
];

export enum BRIEF_MOTIVATION_STATUS {
  BRIEF_START = 'BRIEF_START',
  BRIEF_25_PERCENT = 'BRIEF_25_PERCENT',
  BRIEF_70_PERCENT = 'BRIEF_70_PERCENT',
  BRIEF_95_PERCENT = 'BRIEF_95_PERCENT',
  BRIEF_COMPLETED = 'BRIEF_COMPLETED',
}

export const BRIEF_MOTIVATION_DATA = {
  [BRIEF_MOTIVATION_STATUS.BRIEF_START]: {
    title: 'Your journey has started! Keep the momentum going!',
    subTitle:
      'Answer some in-depth questions so we can create the best possible' +
      ' marketing strategy to meet your business goals',
    imageUrl: svgs.briefMotivationStart,
    minValue: 0,
    maxValue: 24,
  },
  [BRIEF_MOTIVATION_STATUS.BRIEF_25_PERCENT]: {
    title: 'You are off to a great start, keep it up!',
    subTitle: 'Answer a few follow-up questions to create an even more accurate marketing strategy',
    imageUrl: svgs.briefMotivation25Percent,
    minValue: 25,
    maxValue: 69,
  },
  [BRIEF_MOTIVATION_STATUS.BRIEF_70_PERCENT]: {
    title: "You've come a long way, don't turn back now",
    subTitle: "The more questions you answer the closer you'll get to business success",
    imageUrl: svgs.briefMotivation70Percent,
    minValue: 70,
    maxValue: 94,
  },
  [BRIEF_MOTIVATION_STATUS.BRIEF_95_PERCENT]: {
    title: 'Almost done. Time to push past the finish line',
    subTitle:
      'Did you know you can share your brief with anyone in the world? View your brief, ' +
      'download it, and shout it from the mountain tops!',
    imageUrl: svgs.briefMotivation95Percent,
    minValue: 95,
    maxValue: 99,
  },
  [BRIEF_MOTIVATION_STATUS.BRIEF_COMPLETED]: {
    title: 'Your brief is completed!',
    subTitle:
      'Did you know you can share your brief with anyone in the world? View your brief, ' +
      'download it, and shout it from the mountain tops!',
    imageUrl: svgs.briefMotivation95Percent,
    minValue: 100,
    maxValue: 100,
  },
};
