/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { UpdateProjectWithOperationResultMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectWithOperationResultMutation';

import {
  PROJECT_FUNDS_REMAINDER_BEHAVIOR,
  PROJECT_FUNDS_REMAINDER_BEHAVIOR_LABELS_LOOKUP,
} from '../../../../fe_common/client/app/enums';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';
import { clientLogger } from '../../../../fe_common/client/services/logger';
import useMutation from '../../../../fe_common/client/hooks/useMutation';
import { TextDisplay } from '../../../../fe_common/client/components/display';

const ProjectFundsRemainderEditor = ({ project }) => {
  const { mutate, loading } = useMutation(UpdateProjectWithOperationResultMutation);
  const { fundsRemainingBehavior } = project;

  const handleOnSelect = async (e) => {
    const fundsRemainingBehaviorSelected = e.target.value;

    if (!fundsRemainingBehaviorSelected || fundsRemainingBehavior === fundsRemainingBehaviorSelected) {
      return;
    }

    const variables = {
      fundsRemainingBehavior: fundsRemainingBehaviorSelected,
      projectId: project.id,
    };

    clientLogger.debug(`You submitted:\n\n${JSON.stringify(variables, null, 2)}`);

    mutate({ variables });
  };

  return (
    <div>
      <TextDisplay variant="h6">Handling of remaining funds on project stopping</TextDisplay>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <FormControl style={{ minWidth: 300, margin: '16px 0' }}>
            <InputLabel htmlFor="age-native-simple">Please select</InputLabel>
            <Select
              value={fundsRemainingBehavior}
              native
              disabled={loading}
              onChange={handleOnSelect}
              inputProps={{
                name: 'fundsRemainingBehavior',
                id: 'fundsRemainingBehavior-native-simple',
              }}
            >
              <option value="" />
              {PROJECT_FUNDS_REMAINDER_BEHAVIOR.map(({ label, value }) => (
                <option key={`funds-remaining-behavior-${label}`} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {!!PROJECT_FUNDS_REMAINDER_BEHAVIOR_LABELS_LOOKUP[fundsRemainingBehavior] && (
          <Tooltip
            placement="top"
            title={PROJECT_FUNDS_REMAINDER_BEHAVIOR_LABELS_LOOKUP[`${fundsRemainingBehavior}-tooltip`]}
          >
            <Grid item>
              <InfoIcon />
            </Grid>
          </Tooltip>
        )}
      </Grid>
      <CalloutMessage
        message="This will determine what to do with the funds remaining after a project stops -
        whether to transfer the remainder funds to the company as credit which can be used for future projects,
        or to pay the funds to Mayple as fees."
      />
    </div>
  );
};

ProjectFundsRemainderEditor.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectFundsRemainderEditor;
