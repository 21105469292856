import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    marketingPlanProgressRoot: {
      width: '100%',
      maxWidth: '900px',
    },
  })
);

export default useStyles;
