import { useMemo } from 'react';
import { InternalTeam, Project } from '@mayple/types';
import { getProjectMainGrowthStrategist } from './logic';

/**
 * Return the MainGrowthStrategist assigned to the project
 * @param project
 */
const useMainGrowthStrategist = (project: Partial<Project> | null | undefined): InternalTeam | null =>
  useMemo(() => getProjectMainGrowthStrategist(project), [project]);

export default useMainGrowthStrategist;
