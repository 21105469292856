import makeStyles from '@material-ui/core/styles/makeStyles';

import { SectionHeaderProps } from './types';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
  },
  listItemAvatar: {},
  avatar: {
    width: (props: SectionHeaderProps) => props.avatarSize || 90,
    height: (props: SectionHeaderProps) => props.avatarSize || 90,
    marginRight: 16,
  },
  primary: {},
  secondary: {},
}));

export default useStyles;
