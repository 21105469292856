import React from 'react';
import { Project, User } from '@mayple/types';
import { Link } from 'react-router-dom';

import { PropertyFormatterType } from '../../fe_common/client/components/display/PropertiesList';
import { ExternalLink } from '../../fe_common/client/components/display';

export const externalLinkFormatter: PropertyFormatterType = (value, label = '') => (
  <ExternalLink key={`externalLinkFormatter-${value}`} href={value}>
    {label || value}
  </ExternalLink>
);

export const emailLinkFormatter: PropertyFormatterType = (value) => {
  if (!value) {
    return null;
  }

  return (
    <a key={`emailLinkFormatter-${value}`} href={`mailto:${value}`} rel="noreferrer noopener" target="_blank">
      {value}
    </a>
  );
};

export const phoneLinkFormatter: PropertyFormatterType = (value) => {
  if (!value) {
    return null;
  }

  return (
    <a key={`phoneLinkFormatter-${value}`} href={`tel:${value}`} rel="noreferrer noopener" target="_blank">
      {value}
    </a>
  );
};

export const companyLinkFormatter: PropertyFormatterType = (company) => {
  const { id, name } = company;
  return (
    <Link to={`/companies/${id}`}>
      {name} ({id})
    </Link>
  );
};

export const internalTeamLinkFormatter: PropertyFormatterType = (internalTeam) => {
  const { id, name } = internalTeam;
  return (
    <Link to={`/internal-accounts/${id}`}>
      {name} ({id})
    </Link>
  );
};

export const projectLinkFormatter: PropertyFormatterType = (project: Project): React.ReactNode => (
  <Link to={`/projects/${project.id}`}>
    {project.name} ({project.id})
  </Link>
);

export const marketerLinkFormatter: PropertyFormatterType = (marketer, idNameKeysTuple = ['id', 'name']) => {
  const [idKey, nameKey] = idNameKeysTuple;

  return (
    <Link to={`/marketers/${marketer?.[idKey] || marketer?.id}`}>
      {marketer?.[nameKey]} ({marketer?.[idKey] || marketer?.id})
    </Link>
  );
};

export const userLinkFormatter: PropertyFormatterType = (user: User) => {
  const { id, displayName } = user;

  return (
    <Link to={`/users/${id}`}>
      {displayName} ({id})
    </Link>
  );
};
