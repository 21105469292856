import React from 'react';
import PropTypes from 'prop-types';

import { getFilterCount } from './logic';
import { TextDisplay } from '../../display';

function FeedFilterHeader({ filter }) {
  const filterCount = getFilterCount(filter);

  return (
    <div>
      <TextDisplay gutterBottom>Filtering on {filterCount} values</TextDisplay>
    </div>
  );
}

FeedFilterHeader.propTypes = {
  filter: PropTypes.object.isRequired,
};

export default FeedFilterHeader;
