import React, { useCallback, useMemo } from 'react';
import { Project } from '@mayple/types';

import useProjectOpportunities from './useProjectOpportunities';
import ProjectParticipants from '../../components/ProjectParticipants';
import HideByProjectStatus from '../../components/HideByProjectStatus';
import ProjectOpportunitiesTable from '../../components/ProjectOpportunities/ProjectOpportunitiesTable';
import OpportunitiesCandidates from '../../components/ProjectOpportunities/OpportunitiesCandidates';
import { config } from '../../../../config';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import useHasAssignedInternalTeams from '../../../../../fe_common/client/hooks/internalTeam/useHasAssignedInternalTeams';
import { SkeletonContainer } from '../../../../../fe_common/client/components/display';

import useStyles from './style';

interface ProjectOpportunitiesTabProps {
  project: Project | null | undefined;
  refetchProjectData?: () => void;
}

const ProjectOpportunitiesTab: React.FC<ProjectOpportunitiesTabProps> = (props) => {
  const classes = useStyles();

  const { project, refetchProjectData } = props;

  const { projectLifeCycleStatus, id: projectId, flowConfiguration } = project || ({} as Project);
  const skipOpportunityStage: boolean = !!flowConfiguration?.skipOpportunityStage?.value || false;

  const {
    opportunities,
    loading: loadingOpportunities,
    refetch: refetchOpportunities,
  } = useProjectOpportunities(projectId, { skip: !projectId || skipOpportunityStage });

  const { hasAssignedMarketer } = useMemo(() => {
    const [participant] = project?.participants || [];
    const assignedMarketer = participant?.marketerId;
    const status = participant?.participantOnboardingStatus;
    return {
      participantOnboardingStatus: status,
      hasAssignedMarketer:
        assignedMarketer !== null && assignedMarketer !== undefined && assignedMarketer !== config.maamId,
    };
  }, [project]);

  const { hasAssignedGS } = useHasAssignedInternalTeams(project);

  const refetch = useCallback(async () => {
    refetchProjectData?.();
    await refetchOpportunities();
  }, [refetchOpportunities, refetchProjectData]);

  return (
    <div className={classes.root}>
      {!skipOpportunityStage && (
        <Section title="Opportunities">
          <SkeletonContainer loading={loadingOpportunities} height={300}>
            <ProjectOpportunitiesTable
              project={project}
              opportunities={opportunities}
              onUpdateOpportunities={refetch}
            />
          </SkeletonContainer>
        </Section>
      )}

      {!hasAssignedMarketer && (
        <SkeletonContainer loading={loadingOpportunities} height={300}>
          <OpportunitiesCandidates
            projectId={projectId}
            project={project}
            opportunities={opportunities}
            onUpdateOpportunities={refetch}
          />
        </SkeletonContainer>
      )}

      {hasAssignedGS && (
        <HideByProjectStatus status={projectLifeCycleStatus} hideOnDiscovery>
          <Section title="Project Marketer" classes={{ root: classes.sectionRoot }}>
            <SectionContent>
              <ProjectParticipants opportunities={opportunities} refetchProjectData={refetch} project={project} />
            </SectionContent>
          </Section>
        </HideByProjectStatus>
      )}
    </div>
  );
};

export default ProjectOpportunitiesTab;
