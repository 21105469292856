const { useState } = require('react');

export function useFilterDialogControl() {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const showFilterDialog = () => setIsFilterDialogOpen(true);

  const hideFilterDialog = () => setIsFilterDialogOpen(false);

  return {
    isFilterDialogOpen,
    showFilterDialog,
    hideFilterDialog,
  };
}
