import React from 'react';
import { useTranslation } from 'react-i18next';
import { NonPpcPackage, ProjectExistingMarketingEvaluationReportV2Details } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { getScoreQuestionsHCV2 } from './logic';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import RichTextViewer from '../../../../../fe_common/client/components/common/RichTextViewer';
import { titleCase } from '../../../../../fe_common/client/services/utils';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

export interface ExistingMarketingEvaluationReportV2DetailsProps {
  reportDetails: ProjectExistingMarketingEvaluationReportV2Details;
}

const ExistingMarketingEvaluationReportV2Details: React.FC<ExistingMarketingEvaluationReportV2DetailsProps> = (
  props,
) => {
  const classes = useStyles();
  const { reportDetails } = props;
  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketerProjectMarketingEvaluationAuditReport.ExistingMarketingEvaluationReportV2Details',
  });

  const scoreQuestions = getScoreQuestionsHCV2(t, reportDetails);

  return (
    <div className={classes.root}>
      {!reportDetails && <div>The marketer has not yet submitted an evaluation form</div>}
      {!!reportDetails && (
        <div>
          <TextDisplay gutterBottom variant="h6" bold>
            Score questions
          </TextDisplay>
          <Divider />
          <div>
            {scoreQuestions.map(
              ({ label: scoreLabel, value: scoreValue, formatter }, index) =>
                typeof scoreValue === 'number' && (
                  <TextDisplay key={`reportDetails-score-questions-${index}`}>
                    <strong>{titleCase(scoreLabel)}?</strong>: {formatter ? formatter?.(scoreValue) : scoreValue}
                  </TextDisplay>
                ),
            )}
          </div>
          <TextDisplay gutterBottom bold>
            Biggest gap:
          </TextDisplay>
          {reportDetails.biggestGap && <RichTextViewer value={reportDetails.biggestGap} />}
          <TextDisplay gutterBottom>
            <strong>Chosen Spotlight chapter:</strong> {reportDetails.spotlightChapter}
          </TextDisplay>
          <TextDisplay bold gutterBottom>
            Recommended Packages
          </TextDisplay>
          {(reportDetails.upsellPotential?.additionalServices || []).map((p: NonPpcPackage) => (
            <TextDisplay key={p?.uuid}>
              {p?.serviceType} - {p?.flavor}
            </TextDisplay>
          ))}
          <Divider />
        </div>
      )}
    </div>
  );
};

export default ExistingMarketingEvaluationReportV2Details;
