import React, { useEffect, useState, useCallback, useRef, FC, useMemo } from 'react';
import { MediaDocument } from '@mayple/types';
import SafeSrcDocIframe from 'react-safe-src-doc-iframe';

import { useWindowDimensions } from '../../../hooks';
import { reviveImages, wrapContentWithHTML } from './logic';

import useStyles from './styles';
import ReadMoreLink from '../ReadMoreLink';

export interface RichTextViewerProps {
  value: MediaDocument | null | undefined;
  // This should be used in case the width of the iframe container should be set by the iframe content
  fitContent?: boolean;
  // use maxContentWidth to limit the max iframe width in cases you don't want the content to overflow
  maxContentWidth?: number;
  initialMaxHeight?: number;
  // in case the initialMaxHeight is in the top boundary of the threshold - don't display read more button
  initialMaxHeightThreshold?: number;
}

/**
 * Display HTML content inside a secure iframe
 */
const RichTextViewer: FC<RichTextViewerProps> = (props) => {
  // A hook that listen to window size changes
  const { width } = useWindowDimensions();
  const { initialMaxHeight = null, initialMaxHeightThreshold = 30, fitContent = false, maxContentWidth = 450 } = props;

  const classes = useStyles(props);
  const { value } = props;

  const cleanHtml = useMemo(() => {
    // Before rendering, sanitize the html and load to state.
    const contents = reviveImages(value?.contents || '');
    // return DOMPurify.sanitize(contents);
    return wrapContentWithHTML(contents, maxContentWidth);
  }, [value?.contents, maxContentWidth]);

  // Unique id for each viewer
  const [id] = useState(() => `RichTextViewer-${Math.random().toString(36).substr(2, 6)}`);

  // Use to determine if it's time to adjust iframe dimensions
  const [shouldAdjustDimensions, setShouldAdjustDimensions] = useState(false);

  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const [readMoreButtonVisible, setReadMoreButtonVisible] = useState(false);

  const [actualContentWidth, setActualContentWidth] = useState<number>();

  const frameRef = useRef<any>(null);

  const setIframeWidthFitContent = useCallback(() => {
    if (!fitContent) {
      return;
    }

    const frame = frameRef.current?.iframeElement;

    if (!frame || !frame.contentWindow?.document?.body) {
      return;
    }

    frame.style.position = 'absolute';
    frame.style.width = '0px';
    frame.contentWindow.document.body.className = 'before-calc';

    // get the actual content width
    const tempActualContentWidth = frame.contentWindow.document.body.scrollWidth;
    // storing actualContentWidth, no need to calculate it everytime
    setActualContentWidth(tempActualContentWidth);

    // now we can remove 'before-calc' class from the body
    frame.contentWindow.document.body.className = '';

    // if the width is larger than the maxWidth we got - we set it to the max width.
    // if it's smaller - we use the actual content width + 1 (it's fixing unwanted text wrapping)
    const finalWidth = tempActualContentWidth > maxContentWidth ? maxContentWidth : tempActualContentWidth + 1;
    frame.style.width = `${finalWidth}px`;
    frame.style.position = 'static';
  }, [maxContentWidth, fitContent, setActualContentWidth]);

  const setIFrameHeight = useCallback(() => {
    const frame = frameRef.current?.iframeElement;
    if (!frame) {
      return;
    }

    let finalHeight;

    // I think not all browser supports this
    if (frame.contentWindow?.document?.body?.offsetHeight) {
      finalHeight = frame.contentWindow.document.body.offsetHeight;
    } else if (frame.contentWindow?.document?.documentElement?.scrollHeight) {
      finalHeight = frame.contentWindow.document.documentElement.scrollHeight;
    }

    if (
      initialMaxHeight != null &&
      initialMaxHeight > 0 &&
      finalHeight > initialMaxHeight + initialMaxHeightThreshold &&
      !readMoreOpen
    ) {
      finalHeight = initialMaxHeight;
      setReadMoreButtonVisible(true);
    }

    frame.style.height = `${finalHeight}px`;
  }, [initialMaxHeight, initialMaxHeightThreshold, readMoreOpen]);

  useEffect(() => {
    if (!shouldAdjustDimensions) {
      return;
    }
    // we are setting the iframe width first
    setIframeWidthFitContent();

    // and we are free to set the content height
    setIFrameHeight();

    // reset this value - so we can refresh dimensions
    setShouldAdjustDimensions(false);
  }, [setIFrameHeight, setIframeWidthFitContent, shouldAdjustDimensions]);

  // Calculate iframe height on resize
  useEffect(() => {
    setIFrameHeight();
  }, [width, setIFrameHeight]);

  // Calculate iframe width on resize
  useEffect(() => {
    const frame = frameRef.current?.iframeElement;
    if (fitContent && actualContentWidth != null) {
      const finalWidth = actualContentWidth > maxContentWidth ? maxContentWidth : actualContentWidth + 1;
      frame.style.width = `${finalWidth}px`;
    } else {
      frame.style.width = '100%';
    }
  }, [fitContent, width, actualContentWidth, maxContentWidth]);

  // On mount, get the iframe and update its styles, set it's width and height
  useEffect(() => {
    const frame = frameRef.current?.iframeElement;

    if (frame) {
      frame.onload = () => {
        setShouldAdjustDimensions(true);
      };
    }
  }, []);

  // update the dimensions in case of change in the content
  useEffect(() => {
    setShouldAdjustDimensions(true);
  }, [value?.contents]);

  return (
    <div className={classes.root}>
      <SafeSrcDocIframe
        id={id}
        ref={frameRef}
        title="mayple-rich-text-viewer"
        srcDoc={cleanHtml}
        width="100%"
        style={{
          border: 'none',
          fontFamily: 'GT Walsheim Pro',
          width: '100%',
          transition: 'height 100ms ease-in-out',
        }}
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
      />
      {readMoreButtonVisible && <ReadMoreLink onChange={setReadMoreOpen} isOpen={false} />}
    </div>
  );
};

export default RichTextViewer;
