import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SetCompanyBillingMechanismMutation } from 'growl-graphql/dist/mutations/billing/SetCompanyBillingMechanismMutation';

import { useMutation } from '../../../../../fe_common/client/hooks';
import { SkeletonContainer } from '../../../../../fe_common/client/components/display';
import SwitchField from '../../../../../fe_common/client/components/atoms/SwitchField';

interface CompanyBillingMechanismProps {
  companyId: number;
  onChange: () => void;
  loading: boolean;
  useStripe: boolean;
}
/*
 * Controls the company useStripe flag.
 * */
const CompanyBillingMechanism: FC<CompanyBillingMechanismProps> = ({ companyId, loading, onChange, useStripe }) => {
  const { mutate: setCompanyBillingMechanism, loading: loadingMutation } = useMutation(
    SetCompanyBillingMechanismMutation,
  );
  const { t } = useTranslation('translation', { keyPrefix: 'CompanyDetailsPage.CompanyBillingMechanism' });

  const onChangeHandler = async () => {
    await setCompanyBillingMechanism({ variables: { companyId, useStripe: !useStripe } });
    onChange();
  };

  return (
    <div>
      <SkeletonContainer loading={loading} height={100}>
        <SwitchField
          disabled={true || loadingMutation}
          checked={useStripe}
          onChange={onChangeHandler}
          label={t('billingSystemInUseLabel', { type: useStripe ? t('new') : t('old') })}
        />
      </SkeletonContainer>
    </div>
  );
};

export default CompanyBillingMechanism;
