import React, { useCallback, useEffect, useState } from 'react';
import { FundAccount, Project, ProjectCycle, ProjectCycleOperationMode } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { ValuesToShow } from '../types';
import { getProjectBillingPagePath } from '../../../../consts';
import ProjectCycleDetailsNew from '../../../../../components/cpanel/components/organisms/Project/ProjectCycleDetailsNew';

import { Button } from '../../../../../../fe_common/client/components/inputs';
import Section from '../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';

import useCommonStyles from '../style';

interface MonthlyCyclesProps {
  project: Project;
  cycles: ProjectCycle[];
  isOnboarding: boolean;
  isLive: boolean;
  currentCycleFundAccount: FundAccount | undefined;
  setCurrentCycleFundAccount: React.Dispatch<React.SetStateAction<FundAccount | undefined>>;
}

const MonthlyCycles: React.FC<MonthlyCyclesProps> = (props) => {
  const classes = useCommonStyles();
  const { cycles, project, isOnboarding, isLive, currentCycleFundAccount, setCurrentCycleFundAccount } = props;

  const {
    id: projectId,
    currentCycle = {} as ProjectCycle,
    projectCycleOperationMode = ProjectCycleOperationMode.OPERATED_MANUALLY_BY_HUMANS,
  } = project;

  const isOperatedAutomaticallyByProjectPackages =
    projectCycleOperationMode === ProjectCycleOperationMode.OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES;

  const [showActual, setShowActual] = useState<ValuesToShow>(ValuesToShow.ESTIMATED);

  const shouldDisableViewSelection = isOnboarding;

  const setDefaultViewType = useCallback(
    (cycle) => {
      const isLiveAndHasBillingSnapshots =
        isLive && Array.isArray(cycle.billingSnapshots) && cycle.billingSnapshots.length > 0;

      if (isOnboarding) {
        setShowActual(ValuesToShow.ESTIMATED);
      } else if (isLiveAndHasBillingSnapshots) {
        setShowActual(ValuesToShow.ACTUAL);
      } else {
        setShowActual(ValuesToShow.ESTIMATED);
      }
    },
    [isLive, isOnboarding],
  );

  // Locating the current cycle in the cycles array (as fundAccount is missing on currentCycle)
  useEffect(() => {
    if (cycles && currentCycle) {
      const tempCurrCycle = cycles.find(({ id }) => id === currentCycle.id);
      if (tempCurrCycle) {
        const { fundAccount: tempCurrCycleFundAccount = {} as FundAccount } = tempCurrCycle;
        setCurrentCycleFundAccount(tempCurrCycleFundAccount || undefined);
        setDefaultViewType(tempCurrCycle);
      }
    }
  }, [currentCycle, cycles, setDefaultViewType, setCurrentCycleFundAccount]);

  const handleChangeShowActual = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value } = event.target;
    setShowActual(value as ValuesToShow);
  };

  const editButtonLabel = isOperatedAutomaticallyByProjectPackages
    ? 'Edit marketer cut configuration'
    : 'edit services and billing of current and next month';

  return (
    <Section title="Monthly Cycles" noBorder gutterTopSize={2}>
      <SectionContent>
        <Grid container>
          <Grid item xs={12} className={classes.centerContent}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel>Select values to show</InputLabel>
              <Select
                name="select-estimated-actual"
                value={showActual}
                onChange={handleChangeShowActual}
                disabled={shouldDisableViewSelection}
              >
                <MenuItem value="estimated">Show Estimated Values</MenuItem>
                <MenuItem value="actual">Show Actual Values</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <ProjectCycleDetailsNew
              cycles={cycles}
              project={project}
              fundAccount={currentCycleFundAccount}
              showActual={showActual === ValuesToShow.ACTUAL}
            />
          </Grid>

          {(isLive || isOnboarding) && (
            <Grid item xs={12}>
              {isOperatedAutomaticallyByProjectPackages && (
                <CalloutMessage
                  type="warning"
                  message="This project cycle is managed automatically, so you can't edit the services manually. please use the
                  packages tab to edit the project packages."
                />
              )}
              <div className={classes.actionsWrapper}>
                <Button
                  label={editButtonLabel}
                  link={getProjectBillingPagePath(projectId)}
                  color="primary"
                  variant="contained"
                  size="large"
                  // disabled={isOperatedAutomaticallyByProjectPackages}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </SectionContent>
    </Section>
  );
};

export default MonthlyCycles;
