import { useCallback } from 'react';
import { Participant } from '@mayple/types';
import { useTranslation } from 'react-i18next';
import { ApolloQueryResult } from 'apollo-client';

import { TRANSLATION_NS } from '../consts';
import { MarketingPlanSteps } from '../types';
import { MarketingPlanStatusExtended } from '../../MarketingPlanProvider/types';
import { MarketingPlanActionType } from '../../MarketingPlanProvider/marketingPlanReducer';
import { useMarketingPlanDispatch } from '../../MarketingPlanProvider';
import { getStateDataFromParticipant } from '../../MarketingPlanProvider/logic';

export const getActiveStepFromMarketingPlanStatus = (marketingPlanStatus?: MarketingPlanStatusExtended): number => {
  switch (marketingPlanStatus) {
    case MarketingPlanStatusExtended.MARKETING_PLAN_MEETING_NOT_SET:
    case MarketingPlanStatusExtended.MISSING_MARKETING_PLAN:
      return MarketingPlanSteps.createYourMarketingPlan;

    case MarketingPlanStatusExtended.WAITING_GS_REVIEW:
      return MarketingPlanSteps.reviewByGrowthStrategist;

    case MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW:
    case MarketingPlanStatusExtended.APPROVED:
      return MarketingPlanSteps.marketingPlanMeetingAndApproval;

    default:
      return MarketingPlanSteps.createYourMarketingPlan;
  }
};

export const checkIsStepDone = (
  marketingPlanStep: MarketingPlanSteps,
  marketingPlanStatus: MarketingPlanStatusExtended | undefined
): boolean => {
  switch (marketingPlanStep) {
    case MarketingPlanSteps.marketingPlanMeetingAndApproval:
      return marketingPlanStatus === MarketingPlanStatusExtended.APPROVED;
    case MarketingPlanSteps.reviewByGrowthStrategist:
      return (
        marketingPlanStatus === MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW ||
        marketingPlanStatus === MarketingPlanStatusExtended.APPROVED
      );
    case MarketingPlanSteps.createYourMarketingPlan:
    default:
      return (
        marketingPlanStatus === MarketingPlanStatusExtended.WAITING_GS_REVIEW ||
        marketingPlanStatus === MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW ||
        marketingPlanStatus === MarketingPlanStatusExtended.APPROVED
      );
  }
};

export const useStepperLogic = (): {
  onSuccessfulDataRefresh: (result: ApolloQueryResult<any>) => void;
} => {
  const dispatch = useMarketingPlanDispatch();
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'common' });

  const onSuccessfulDataRefresh = useCallback(
    (result: ApolloQueryResult<any>) => {
      // get the new state here
      const { participant = {} as Participant } = result.data;
      const newStateData = getStateDataFromParticipant(participant, t);

      // set the new state here
      dispatch({ type: MarketingPlanActionType.SET_VALUES, payload: newStateData });
    },
    [dispatch, t]
  );

  return {
    onSuccessfulDataRefresh,
  };
};
