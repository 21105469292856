import React, { FC } from 'react';
import { User } from '@mayple/types';

import { getUserDetailsProperties } from '../logic';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import PropertiesList from '../../../../fe_common/client/components/display/PropertiesList';

type UserBasicDetailsTabProps = {
  user: User;
};

const UserBasicDetailsTab: FC<UserBasicDetailsTabProps> = (props) => {
  const { user } = props;
  const userDetailsProperties = getUserDetailsProperties();

  return (
    <div>
      <Section title="Basic details" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
        <SectionContent>
          <PropertiesList id="userDetailsData" data={user} propertiesMeta={userDetailsProperties} />
        </SectionContent>
      </Section>
    </div>
  );
};

export default UserBasicDetailsTab;
