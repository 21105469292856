import React, { FC, KeyboardEventHandler, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { PromptDialogProps } from './types';
import { Dialog } from '../../display';

import useStyles from './style';

const PromptDialog: FC<PromptDialogProps> = (props) => {
  const {
    isOpen,
    title = '',
    defaultValue = '',
    message,
    onCancel,
    onConfirm,
    cancelButtonIcon,
    cancelButtonLabel = 'CANCEL',
    confirmButtonIcon,
    confirmButtonLabel = 'CONFIRM',
  } = props;

  const classes = useStyles(props);
  const [value, setValue] = useState<null | any>(() => defaultValue);

  const handleOnChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleOnConfirm = () => {
    onConfirm(value);
    setValue(null);
  };

  const handleOnCancel = () => {
    setValue(null);
    onCancel();
  };

  const handleOnKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      handleOnConfirm();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleOnCancel}
      title={title !== '' && title}
      actions={[
        {
          label: cancelButtonLabel,
          onClick: handleOnCancel,
          startIcon: cancelButtonIcon,
        },
        {
          color: 'primary',
          variant: 'contained',
          label: confirmButtonLabel,
          onClick: handleOnConfirm,
          startIcon: confirmButtonIcon,
        },
      ]}
      classes={{ dialogTitleRoot: classes.title, dialogContent: classes.message }}
    >
      <>
        {message}
        <TextField
          value={value}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          autoFocus
        />
      </>
    </Dialog>
  );
};

export default PromptDialog;
