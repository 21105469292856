import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { MatchingWeightsManagementPanelData, MatchingWeightsManagementPanelProps } from './types';
import { mapToWeightTableData } from './logic';
import { MATCHING_SIMULATOR_WEIGHTS_KEY } from './consts';
import { ProjectIdParams } from '../../../../../../components/Routes/types';

import {
  getFromLocalStorage,
  setToLocalStorage,
  tryParseInt,
} from '../../../../../../../fe_common/client/services/utils';
import { mapFormValuesToTestMarketerMatchesInput } from '../../logic';
import { setNotification } from '../../../../../../../fe_common/client/services/notification';
import { useDialog } from '../../../../../../../fe_common/client/hooks';

const useMatchingWeightsManagementPanelData = (
  props: MatchingWeightsManagementPanelProps,
): MatchingWeightsManagementPanelData => {
  const { formValues } = props;

  const { projectId: projectIdFromUrl } = useParams<ProjectIdParams>();

  const projectId: number = tryParseInt(projectIdFromUrl, 0);

  const [weightTableData, setWeightTableData] = useState(() =>
    mapToWeightTableData(getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY) || {}),
  );

  const {
    open: weightsManagementIsOpen,
    openDialog: openWeightsManagement,
    closeDialog: closeWeightsManagement,
  } = useDialog(false);

  const { open: savePromptIsOpen, openDialog: openSavePrompt, closeDialog: closeSavePrompt } = useDialog(false);

  const refetchData = () => {
    setWeightTableData(mapToWeightTableData(getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY) || {}));
  };

  const saveWeightsDataHandler: React.MouseEventHandler = () => {
    openSavePrompt();
  };

  const onConfirmSaveHandler = async (value: string) => {
    closeSavePrompt();

    const weightKey = value.toString();

    if (weightKey !== '') {
      const { testWeights } = mapFormValuesToTestMarketerMatchesInput(formValues);
      const matchingSimulatorWeights = getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY) || {};

      const newMatchingSimulatorWeightsData = {
        ...matchingSimulatorWeights,
        [weightKey]: {
          projectId,
          ...testWeights,
        },
      };

      setToLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY, newMatchingSimulatorWeightsData);
      refetchData();
    } else {
      setNotification(`Invalid name provided to save method`, 'warning');
    }
  };

  return {
    projectId,
    weightTableData,
    refetchData,
    saveWeightsDataHandler,
    openWeightsManagement,
    savePromptIsOpen,
    onConfirmSaveHandler,
    closeSavePrompt,
    weightsManagementIsOpen,
    closeWeightsManagement,
  };
};
export default useMatchingWeightsManagementPanelData;
