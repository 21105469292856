import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedAlt from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';

const MATCH_VOTE = [
  {
    label: 'Terrible',
    value: 'TERRIBLE',
    icon: <SentimentVeryDissatisfied />,
  },
  {
    label: 'Bad',
    value: 'BAD',
    icon: <SentimentDissatisfied />,
  },
  {
    label: 'Okay',
    value: 'OKAY',
    icon: <SentimentSatisfied />,
  },
  {
    label: 'good',
    value: 'GOOD',
    icon: <SentimentSatisfiedAlt />,
  },
  {
    label: 'Excellent',
    value: 'EXCELLENT',
    icon: <SentimentVerySatisfied />,
  },
];

export const MatchVotePanel = (props) => {
  // console.log('MatchVotePanel props', props);
  const [reasonMode, setReasonMode] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [vote, setVote] = React.useState(null);
  const { onVote, marketerId, projectId, loading } = props;

  if (loading) {
    return 'Loading, Please wait...';
  }

  if (reasonMode) {
    return (
      <React.Fragment>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          onChange={(e) => {
            setReason(e.target.value);
          }}
          value={reason}
          placeholder="Please enter a reason for your vote"
        />
        <Button
          disabled={reason.trim().length < 5 || loading}
          onClick={() => {
            onVote(marketerId, projectId, reason, vote);
            setReasonMode(false);
            setReason('');
            setVote(null);
          }}
        >
          Vote
        </Button>
        <Button
          onClick={() => {
            setReasonMode(false);
            setReason('');
            setVote(null);
          }}
        >
          Cancel
        </Button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {MATCH_VOTE.map(({ label, value, icon }) => (
          <Tooltip key={`match-vote-${value}`} title={label} placement="top">
            <IconButton
              disabled={loading}
              key={`match-vote-${value}`}
              onClick={() => {
                setReasonMode(true);
                setVote(value);
              }}
            >
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </React.Fragment>
    );
  }
};

MatchVotePanel.propTypes = {
  marketerId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  onVote: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

MatchVotePanel.defaultProps = {
  loading: false,
};
