import { ProjectMarketerOpportunity } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { ProjectOpportunitiesQuery } from 'growl-graphql/dist/queries/project/ProjectOpportunitiesQuery';

export type ProjectOpportunitiesData = {
  opportunities: ProjectMarketerOpportunity[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: { projectId: number | null | undefined } | undefined) => Promise<ApolloQueryResult<any>>;
};

const useProjectOpportunities = (
  projectId: number | null | undefined,
  options?: QueryHookOptions,
): ProjectOpportunitiesData => {
  const { data, loading, error, refetch } = useQuery(ProjectOpportunitiesQuery.query, {
    variables: { projectId },
    skip: !projectId || options?.skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  return {
    opportunities: data?.project?.opportunities || ([] as ProjectMarketerOpportunity[]),
    loading,
    error,
    refetch,
  };
};

export default useProjectOpportunities;
