import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';
import { ListItemTreeView } from '../../atoms/ListItemTreeNode';

/**
 * this function tries to get the relevant fields from the given Facebook Ads business manager object,
 * if they exists we add them to the childNodesList for the TreeView structure.
 * @param businessManager - the given BM object.
 * @returns Object
 */
const getFacebookBusinessManagerNode = (businessManager) => {
  const { ownedPages = [], ownedApps = [], ownedAdAccounts = [] } = businessManager;

  const bmChildNodes = [
    {
      node: {
        id: `${businessManager.id}-ownedPages`,
        label: 'Pages (owned)',
        data: ownedPages,
        showItemsCount: true,
      },
    },
    {
      node: {
        id: `${businessManager.id}-ownedApps`,
        label: 'Apps (owned)',
        data: ownedApps,
        showItemsCount: true,
      },
    },
    {
      node: {
        id: `${businessManager.id}-ownedAdAccounts`,
        label: 'Ad Accounts (owned)',
        data: ownedAdAccounts,
        showItemsCount: true,
      },
    },
  ];

  return {
    node: {
      id: `${businessManager.id}-self`,
      label: `${businessManager.name} (#${businessManager.id})`,
      data: [businessManager],
      showItemsCount: false,
    },
    childNodes: bmChildNodes,
  };
};

/**
 * this function is trying to get the relevant fields from the given Facebook Ads integration,
 * if they exists we add them to the childNodesList for the TreeView structure.
 * @param integration - the given integration object, facebookUser data object.
 * @returns array[Object]
 */
const getFacebookIntegrationNodes = (integration) => {
  if (!integration) {
    return [];
  }

  const { ownedAdAccounts = [], administratedPages = [], administratedBusinessManagers = [] } = integration;

  const childNodes = [
    {
      node: {
        id: `${integration.id}-ownedAdAccounts`,
        label: 'Ad Accounts (owned)',
        data: ownedAdAccounts,
        showItemsCount: true,
      },
    },
    {
      node: {
        id: `${integration.id}-administratedPages`,
        label: 'Pages (administrated)',
        data: administratedPages,
        showItemsCount: true,
      },
    },
  ];

  const bmChildNodes = [];
  if (administratedBusinessManagers && administratedBusinessManagers.length) {
    administratedBusinessManagers.forEach((currentBusinessManager) => {
      bmChildNodes.push(getFacebookBusinessManagerNode(currentBusinessManager));
    });
  }

  childNodes.push({
    node: {
      id: `${integration.id}-administratedBusinessManagers`,
      label: 'Business Managers (administrated)',
      data: [],
      showItemsCount: true,
    },
    childNodes: bmChildNodes,
  });

  return childNodes;
};

function FacebookIntegrationDetails({ integrationUser }) {
  const childNodes = useMemo(() => getFacebookIntegrationNodes(integrationUser), [integrationUser]);

  if (childNodes.length === 0) {
    return (
      <TextDisplay style={{ margin: '0 75px' }} colorOverride={colors.black_light}>
        Facebook Ads user has no ad accounts, pages or business managers.
      </TextDisplay>
    );
  }

  return childNodes.map((childNode) => (
    <ListItemTreeView
      key={`custom-tree-view-${childNode.node.id}`}
      node={childNode.node}
      childNodes={childNode?.childNodes}
    />
  ));
}

FacebookIntegrationDetails.propTypes = {
  integrationUser: PropTypes.object.isRequired,
};

export default FacebookIntegrationDetails;
