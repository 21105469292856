import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useFeedPostReactionsIconsStyles = makeStyles(() =>
  createStyles({
    reactionIcon: {
      width: 16,
      height: 16,
    },
    likeAvatar: {
      width: 24,
      height: 24,
      padding: 4,
      color: '#FFF',
      background: 'linear-gradient(180deg, #3E74A5 0%, #1C518B 94.07%)',
    },
  })
);

export default useFeedPostReactionsIconsStyles;
