import {
  localDateFormatter,
  stringArrayFormatter,
  yesNoFormatter,
} from '../../../fe_common/client/services/ExportToCSV/formatters';
import { externalLinkFormatter } from '../../logic/linkFormatters';

import { PropertiesMetaType } from '../../../fe_common/client/components/display/PropertiesList';

const PROPS_GROUPS = {
  BASIC_INFORMATION: 'Basic information',
};

export const getInternalTeamDetailsProperties = (): PropertiesMetaType[] => [
  {
    label: 'Type',
    path: 'internalTeamType',
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Joined',
    path: 'created',
    formatter: localDateFormatter,
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Last modified',
    path: 'modified',
    formatter: localDateFormatter,
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Meeting Link',
    path: 'meetYouExpertCalendlyLink',
    formatter: externalLinkFormatter,
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Slack Member ID',
    path: 'maypleSlackMemberId',
    defaultValue: 'Not set yet',
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Allow tag in Slack',
    path: 'maypleSlackAllowTag',
    formatter: yesNoFormatter,
    group: PROPS_GROUPS.BASIC_INFORMATION,
  },
  {
    label: 'Working Group On Project Types',
    path: 'projectAutomaticAssignmentSettings.workingGroupOnProjectTypes',
    group: PROPS_GROUPS.BASIC_INFORMATION,
    formatter: stringArrayFormatter,
    defaultValue: 'N/A',
  },
];

export const INTERNAL_TEAM_DETAILS_TABS = {
  basicDetails: 'basicDetails',
  users: 'users',
  projects: 'projects',
};

export const internalTeamDetailsTabs = [
  {
    value: INTERNAL_TEAM_DETAILS_TABS.basicDetails,
    label: 'Basic Details',
  },
  {
    value: INTERNAL_TEAM_DETAILS_TABS.users,
    label: 'Users',
  },
  {
    value: INTERNAL_TEAM_DETAILS_TABS.projects,
    label: 'Projects',
  },
];
