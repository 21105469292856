import { ProjectFinishReason } from '@mayple/types';

import { PageLabels } from './types';

import { PROJECT_FINISH_REASON_LABELS_LOOKUP } from '../../../../../../fe_common/client/app/enums';

export const projectFinishReasonLabelsLookup = PROJECT_FINISH_REASON_LABELS_LOOKUP as Record<
  ProjectFinishReason,
  string
>;

const getLabels = (automaticRenewal: boolean): PageLabels => {
  let title = 'Resume at end of month';
  let btnLabel = 'do not stop at the end of month';
  let calloutMsg = 'Project will stop at end of the month.';

  if (automaticRenewal) {
    title = 'Stop at end of month';
    btnLabel = 'stop at the end of month';
    calloutMsg = 'Project will continue at end of the month.';
  }

  return {
    title,
    btnLabel,
    calloutMsg,
  };
};

export default getLabels;
