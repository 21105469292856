import React from 'react';
import { FundAccount, Project, ProjectCycle } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { CompanyByIdFundAccountQuery } from 'growl-graphql/dist/queries/CompanyByIdFundAccountQuery';

import { getCycleHeader } from '../../../../../components/cpanel/components/organisms/Project/ProjectCycleDetailsNew/logic';
import FundAccountDetails from '../../../../../components/cpanel/components/molecules/FundAccountDetails';

import Section from '../../../../../../fe_common/client/components/atoms/Section';
import QueryErrorMessage from '../../../../../../fe_common/client/components/common/QueryErrorMessage';

interface FundAccountsSectionProps {
  project: Project;
  companyId: number;
  showMonthlyCycles: boolean;
  currentCycleFundAccount: FundAccount | undefined;
}

const FundAccountsSection: React.FC<FundAccountsSectionProps> = (props) => {
  const { project, companyId, currentCycleFundAccount, showMonthlyCycles } = props;

  const { currentCycle = {} as ProjectCycle, fundAccount: projectFundAccount = {} as FundAccount } = project;

  const currentCycleFundAccountTitle = getCycleHeader(currentCycle);

  const { data: companyQueryResponse = {}, error } = useQuery(CompanyByIdFundAccountQuery.query, {
    variables: { companyId },
  });

  const { company = {} } = companyQueryResponse;
  const { fundAccount: companyFundAccount } = company;
  return (
    <Section title="Fund Accounts" noBorder={!showMonthlyCycles} noTopMargin={!showMonthlyCycles}>
      {currentCycleFundAccount && (
        <FundAccountDetails
          fundAccount={currentCycleFundAccount}
          title="Cycle Fund Account"
          subTitle={currentCycleFundAccountTitle}
          showDebt={false}
        />
      )}

      <FundAccountDetails fundAccount={projectFundAccount} title="Project Fund Account" showDebt={false} />

      {!!companyFundAccount && (
        <FundAccountDetails
          key={`company-${companyId}-fund-account`}
          fundAccount={companyFundAccount}
          title="Company Fund Account"
          showDebt
        />
      )}
      {error && <QueryErrorMessage error={error} message="Error loading company fund account." />}
    </Section>
  );
};

export default FundAccountsSection;
