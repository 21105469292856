import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  chipRoot: {
    backgroundColor: '#FCFEFE',
    border: '1px solid #C2C2CB',
    margin: 4,
  },
  chipLabel: {
    textAlign: 'center',
  },
}));

export default useStyles;
