import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WrappedFieldMetaProps } from 'redux-form';

import { CardsInRow, CheckboxCardData, CheckboxCardProps, EnumGroup } from './types';
import { getCardLayout } from './logic';
import SelectionCard from '../SelectionCard';
import CalloutMessage from '../../../common/CalloutMessage';
import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';

import useStyles from './style';

const CheckboxCard: FC<CheckboxCardProps> = (props) => {
  const classes = useStyles(props);

  const {
    enums,
    input = {
      name: '',
      value: null,
      onChange: () => {
        /* do nothing */
      },
    },
    meta = {} as WrappedFieldMetaProps,
    cardsInRow = CardsInRow.three,
    allowSelectAll = false,
    splitEnumsByGroup = false,
    bigCard = false,
    showError = false,
    leftCard = false,
    layout = 'vertical',
    selectionIndicatorType = 'showOnSelected',
    maximumSelectedValues = -1,
    hideMaximumSelectedValuesWarning = false,
    groups = [] as EnumGroup[],
  } = props;

  const { invalid, pristine, error } = meta;

  const [checked, setChecked] = useState<string[]>(() => props?.input?.value || []);

  useEffect(() => {
    if (props?.input) {
      setChecked(props?.input?.value);
    }
  }, [props?.input, props?.input?.value]);

  const handleOnClick = useCallback(
    (card: CheckboxCardData) => {
      const { onChange, value } = input;

      const values = [...value];

      const index = values.indexOf(card.value);

      if (index === -1) {
        if (maximumSelectedValues > 0 && values.length + 1 > maximumSelectedValues) {
          return;
        }
        values.push(card.value);
      } else {
        values.splice(index, 1);
      }

      onChange(values);
      setChecked(values);
    },
    [input, maximumSelectedValues]
  );

  const handleSelectAll = useCallback(() => {
    const allValues = enums
      .filter(
        (item) =>
          // remove deprecated items;
          !item.isDeprecated
      )
      .map(
        (item) =>
          // send only values
          item.value
      );
    input.onChange(allValues);
    setChecked(allValues);
  }, [enums, input]);

  let previousGroup: string | null | undefined = '';

  const cardLayout = getCardLayout(layout, bigCard, leftCard);

  const selectionCardClasses = useMemo(
    () => ({
      root: classes.selectionCardRoot,
      active: classes.selectionCardActive,
      hovering: classes.selectionCardHovering,
      hoveringWithBackSide: classes.selectionCardHoveringWithBackSide,
      flipCardBack: classes.selectionCardFlipCardBack,
      flipCardSide: classes.selectionCardFlipCardSide,
      flipCardFront: classes.selectionCardFlipCardFront,
      disabled: classes.selectionCardDisabled,
      vertical: classes.selectionCardVertical,
      horizontal: classes.selectionCardHorizontal,
      withSelectionIndicator: classes.selectionCardWithSelectionIndicator,
      withCardIcon: classes.selectionCardWithCardIcon,
      withSubtitle: classes.selectionCardWithSubtitle,
      withExtraDetails: classes.selectionCardWithExtraDetails,
      checked: classes.selectionCardChecked,
      cardIconWrapper: classes.selectionCardCardIconWrapper,
      cardIcon: classes.selectionCardCardIcon,
      label: classes.selectionCardLabel,
      subtitle: classes.selectionCardSubtitle,
      selectionIndicator: classes.selectionCardSelectionIndicator,
      selectionIndicatorChecked: classes.selectionCardSelectionIndicatorChecked,
      extraDetails: classes.selectionCardExtraDetails,
      extraDetailsText: classes.selectionCardExtraDetailsText,
    }),
    [classes]
  );

  return (
    <>
      {allowSelectAll && (
        <div style={{ textAlign: 'end' }}>
          <Button variant="outlined" label="Select all" onClick={handleSelectAll} className={classes.selectAllButton} />
        </div>
      )}
      <div
        className={classNames(classes.root, {
          [classes.oneColumn]: cardsInRow === CardsInRow.one,
          [classes.twoColumns]: cardsInRow === CardsInRow.two,
          [classes.threeColumns]: cardsInRow === CardsInRow.three,
          [classes.fourColumns]: cardsInRow === CardsInRow.four,
          [classes.userDefined]: cardsInRow === CardsInRow.userDefined,
        })}
      >
        {(enums || {}).map((card: CheckboxCardData) => {
          const { isDeprecated, value, label, svgIcon, fontIcon, icon, group, subtitle } = card;

          const showGroupSplitter = splitEnumsByGroup && group !== previousGroup && previousGroup !== '';

          const showGroupTitle =
            // @ts-ignore
            splitEnumsByGroup && group !== previousGroup && groups && group != null && groups[group]?.label;

          previousGroup = group;

          if (isDeprecated) {
            return null;
          }

          const isChecked = checked.includes(value);
          const isDisabled = maximumSelectedValues > -1 && !isChecked && checked.length >= maximumSelectedValues;

          return (
            <React.Fragment key={`CheckboxCard-${input.name}-${value}`}>
              {showGroupTitle && (
                <TextDisplay key={`group-title-${group}`} variant="subtitle1" className={classes.groupTitle}>
                  {showGroupTitle}
                </TextDisplay>
              )}
              {showGroupSplitter && (
                <div key={`divider-${group}`} className={classes.dividerWrapper}>
                  <Divider className={classes.divider} />
                </div>
              )}
              <div className={classes.item} key={`${input.name}-${value}`}>
                <SelectionCard
                  label={label}
                  icon={svgIcon || fontIcon || icon}
                  checked={isChecked}
                  disabled={isDisabled}
                  onClick={() => {
                    handleOnClick(card);
                  }}
                  subtitle={subtitle}
                  layout={cardLayout}
                  cardType="checkbox"
                  selectionIndicatorType={selectionIndicatorType}
                  classes={selectionCardClasses}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {maximumSelectedValues > 0 && checked.length === maximumSelectedValues && !hideMaximumSelectedValuesWarning && (
        <CalloutMessage type="warning" message={`You can't select more than ${maximumSelectedValues} items`} margin />
      )}
      {showError && !pristine && error && (
        <FormHelperText error={invalid} variant="standard">
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default CheckboxCard;
