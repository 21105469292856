import React, { Component } from 'react';
import { CurrencyType } from '@mayple/types';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  MARKETING_CHANNELS_PAIRS_WITH_ICONS,
  MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS,
} from '../../../../../fe_common/client/app/enums';
import { colors } from '../../../../../fe_common/client/app/theme';
import { handleClientError } from '../../../../../fe_common/client/services/logger';
import { Select } from '../../../../../fe_common/client/components/atoms/Registration/Select';
import { Transitions } from '../../../../../fe_common/client/components/molecules/Transitions';
import { safeExtractCurrencyValue } from '../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';

const BUDGET_MIN_VALUE = 0;
const BUDGET_MAX_VALUE = 1000000;

const channelBudgetStyle = { marginTop: 0 };
const divisionStyle = {
  background: colors.blackOpacity(0.02),
  padding: 24,
  borderRadius: 24,
  marginBottom: 12,
  paddingRight: 0,
};

/**
 *
 * @param mediaBudgetDivision
 * @param amountKey
 * @returns {any}
 */
function calcTotalDivisionBudget(mediaBudgetDivision, amountKey) {
  try {
    return Object.values(mediaBudgetDivision).reduce(
      (total, channel) =>
        // Convert to int. then add to sum.
        total + (+channel[amountKey] || 0),
      0,
    );
  } catch (e) {
    handleClientError(e);
    return 0;
  }
}

//
export class MediaBudgetDivisionField extends Component {
  updateReduxForm = (mediaBudget) => {
    try {
      const onChange = this.props.input?.onChange;
      // We clone deep, because sometimes we only change 1 of the childes. we want redux-form to run validation for
      // all the value regardless of the changes.

      onChange?.(mediaBudget);
    } catch (e) {
      handleClientError(e);
    }
  };

  handleNumericFieldChange = (e) => {
    const { value } = e.target;
    let newValue = Number.parseInt(value, 10);

    if ((!value && value !== 0) || value === '') {
      return '';
    }

    if (!Number.isFinite(newValue)) {
      return null;
    }

    if (newValue < BUDGET_MIN_VALUE) {
      newValue = BUDGET_MIN_VALUE;
    }

    if (newValue > BUDGET_MAX_VALUE) {
      newValue = BUDGET_MAX_VALUE;
    }

    return newValue;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleTotalBudgetChange = (newValue) => {
    const { input } = this.props;
    const { value } = input;
    const newMediaBudget = {
      ...value,
      totalMediaBudget: newValue, // e.target.value,
    };

    this.updateReduxForm(newMediaBudget);
  };

  /**
   *
   */
  handleAddMediaChannel = () => {
    const { amountKey, input } = this.props;
    const { value } = input;
    const { mediaBudgetDivision } = value;

    const newMediaBudgetDivision = cloneDeep(mediaBudgetDivision);

    for (const pair of MARKETING_CHANNELS_PAIRS_WITH_ICONS) {
      const { value: skillType } = pair;
      // look for non-existing channels, and add it
      if (!newMediaBudgetDivision[skillType]) {
        newMediaBudgetDivision[skillType] = {
          skillType,
          [amountKey]: '',
        };
        break;
      }
    }

    const newTotalMediaBudget = calcTotalDivisionBudget(newMediaBudgetDivision, amountKey);

    const newMediaBudget = {
      totalMediaBudget: newTotalMediaBudget,
      mediaBudgetDivision: newMediaBudgetDivision,
    };

    this.updateReduxForm(newMediaBudget);
  };

  /**
   *
   * @param skillType
   */
  handleDeleteChannel = (skillType) => {
    const { amountKey, input } = this.props;
    const { value } = input;
    const { mediaBudgetDivision } = value;

    const newMediaBudgetDivision = cloneDeep(mediaBudgetDivision);

    if (newMediaBudgetDivision[skillType]) {
      delete newMediaBudgetDivision[skillType];
    }

    const newTotalMediaBudget = calcTotalDivisionBudget(newMediaBudgetDivision, amountKey);
    const newMediaBudget = { totalMediaBudget: newTotalMediaBudget, mediaBudgetDivision: newMediaBudgetDivision };

    this.updateReduxForm(newMediaBudget);
  };

  /**
   *
   * @param skillType
   * @param previousSkillType
   */
  handleChannelChange = (skillType, previousSkillType) => {
    const { input } = this.props;
    const { value } = input;
    const { amountKey } = this.props;
    const { mediaBudgetDivision } = value;

    // In case that channel already exists, do nothing.
    if (mediaBudgetDivision[skillType]) {
      return;
    }

    const newMediaBudgetDivision = cloneDeep(mediaBudgetDivision);

    // In case of we want to switch channel and keep the budget.
    if (newMediaBudgetDivision[previousSkillType]) {
      newMediaBudgetDivision[skillType] = {
        skillType,
        [amountKey]: newMediaBudgetDivision[previousSkillType][amountKey],
      };
      // Delete old channel
      delete newMediaBudgetDivision[previousSkillType];

      const newTotalMediaBudget = calcTotalDivisionBudget(newMediaBudgetDivision, amountKey);

      const newMediaBudget = {
        totalMediaBudget: newTotalMediaBudget,
        mediaBudgetDivision: newMediaBudgetDivision,
      };

      this.updateReduxForm(newMediaBudget);
    }
  };

  /**
   *
   * @param amount
   * @param skillType
   */
  handleMediaChannelBudgetChange = (amount, skillType) => {
    const { amountKey, input } = this.props;
    const { value } = input;
    const { mediaBudgetDivision } = value;

    const newMediaBudgetDivision = cloneDeep(mediaBudgetDivision);

    newMediaBudgetDivision[skillType][amountKey] = amount;

    const newTotalMediaBudget = calcTotalDivisionBudget(newMediaBudgetDivision, amountKey);

    const newMediaBudget = {
      mediaBudgetDivision: newMediaBudgetDivision,
      totalMediaBudget: newTotalMediaBudget,
    };

    this.updateReduxForm(newMediaBudget);
  };

  renderMediaChannels = () => {
    const { amountKey, project, label, input } = this.props;
    const { value } = input;
    const { mediaBudgetDivision, totalMediaBudget } = value;
    const { currency = CurrencyType.USD } = project;

    if (!mediaBudgetDivision || Object.values(mediaBudgetDivision).length === 0) {
      return <div style={divisionStyle}>No channels selected</div>;
    }

    // const filteredEnums = MARKETING_CHANNELS_PAIRS_WITH_ICONS.filter((channel) => !mediaBudgetDivision[channel.value]);

    return (
      <div style={divisionStyle}>
        {Object.values(mediaBudgetDivision).map((channel) => {
          const selectInput = {
            value: channel.skillType,
            onChange: this.handleChannelChange,
          };
          return (
            <Transitions type={Fade} duration={400} key={`mediaBudgetDivision-input-key-${channel.skillType}`}>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={9} sm={11}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Select enums={MARKETING_CHANNELS_PAIRS_WITH_ICONS} input={selectInput} label="Channel type" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={label}
                        value={channel[amountKey]}
                        onChange={(e) => {
                          const budget = this.handleNumericFieldChange(e);
                          if (budget == null) {
                            return;
                          }
                          this.handleMediaChannelBudgetChange(budget, channel.skillType);
                        }}
                        type="number"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          inputProps: { min: BUDGET_MIN_VALUE, max: BUDGET_MAX_VALUE },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        style={channelBudgetStyle}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <IconButton
                    onClick={() => {
                      this.handleDeleteChannel(channel.skillType);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Transitions>
          );
        })}
        {!!totalMediaBudget && (
          <TextDisplay color="primary" variant="overline">
            Total Media {label} of {safeExtractCurrencyValue(totalMediaBudget, currency)}
          </TextDisplay>
        )}
      </div>
    );
  };

  render() {
    // console.log('MediaBudgetDivisionField props', this.props);
    const { getTitle, meta } = this.props;

    return (
      <div>
        {getTitle(
          'Media channels',
          'How exactly will the marketing budget be distributed across different channels?',
          true,
        )}
        {this.renderMediaChannels()}
        {meta.error && (
          <TextDisplay variant="caption" colorOverride={colors.red}>
            {meta.error}
          </TextDisplay>
        )}

        <Button label="ADD PPC SERVICE" variant="outlined" color="primary" onClick={this.handleAddMediaChannel} />
      </div>
    );
  }
}

MediaBudgetDivisionField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.object.isRequired,
  // yes it is used..
  // eslint-disable-next-line react/no-unused-prop-types,
  project: PropTypes.object.isRequired,
  getTitle: PropTypes.func.isRequired,
  amountKey: PropTypes.oneOf(['budgetForSkillType', 'spendForSkillType']),
  label: PropTypes.oneOf(['Budget', 'Spend']),
};

MediaBudgetDivisionField.defaultProps = {
  amountKey: 'budgetForSkillType',
  label: 'Budget',
};

/**
 *
 * @param value
 // * @param formValues
 * @returns {string|boolean}
 */
export function validateMediaBudgetDivision(value /* , formValues */) {
  if (value === null || value === undefined) {
    return '';
  }

  const { mediaBudgetDivision } = value;

  // if ((!mediaBudgetDivision || Object.values(mediaBudgetDivision).length === 0)) {
  //   return 'Please add at least 1 channel';
  // }

  for (const channel of Object.values(mediaBudgetDivision)) {
    const { skillType, budgetForSkillType, spendForSkillType } = channel;

    if (!skillType) {
      return 'Please select a media channel';
    } else if (budgetForSkillType != null && (budgetForSkillType < 0 || budgetForSkillType === '')) {
      return `Invalid budget for ${MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS[skillType]}`;
    } else if (spendForSkillType != null && (spendForSkillType < 0 || spendForSkillType === '')) {
      return `Invalid budget for ${MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS[skillType]}`;
    }
  }

  return false;
}
