/* eslint-disable class-methods-use-this */
import { SetPostProjectMatchOfferMarketerAssessmentForProjectMutation } from 'growl-graphql/dist/mutations/tasks/PostProjectMatchOfferMarketerAssessmentForProject/SetPostProjectMatchOfferMarketerAssessmentForProjectMutation';
import { SkipPostProjectMatchOfferMarketerAssessmentForProjectMutation } from 'growl-graphql/dist/mutations/tasks/PostProjectMatchOfferMarketerAssessmentForProject/SkipPostProjectMatchOfferMarketerAssessmentForProjectMutation';
import {
  PostProjectMatchOfferMarketerAssessment,
  PostProjectMatchOfferMarketerAssessmentInput,
  PostProjectMatchOfferMarketerAssessmentParameterKey,
} from '@mayple/types';

import IAssessment from './IAssessment';
import { FormValues } from '../types';
import { postProjectMatchOfferMarketerAssessmentParameterKeys } from '../consts';
import { AssessmentSubTask } from '../../../types';

interface SetProjectMatchOfferMarketerAssessmentVariables extends Record<string, any> {
  projectId: number;
  marketerId: number;
  postProjectMatchOfferMarketerAssessmentInput: PostProjectMatchOfferMarketerAssessmentInput;
}

class PostProjectMatchOfferMarketerAssessmentConcrete implements IAssessment {
  skipMutation = SkipPostProjectMatchOfferMarketerAssessmentForProjectMutation;

  skipMutationResponseKey = 'skipPostProjectMatchOfferMarketerAssessmentForProject';

  saveMutation = SetPostProjectMatchOfferMarketerAssessmentForProjectMutation;

  saveMutationResponseKey = 'setPostProjectMatchOfferMarketerAssessmentForProject';

  getAssessmentVotes(): string[] {
    return postProjectMatchOfferMarketerAssessmentParameterKeys;
  }

  getFormValuesFromSubTask(
    subTask: AssessmentSubTask,
    votes: Record<string, any>,
    assessmentComment: string | null | undefined
  ): FormValues {
    const postProjectMatchOfferMarketerAssessment = subTask as unknown as PostProjectMatchOfferMarketerAssessment;
    return {
      projectId: postProjectMatchOfferMarketerAssessment?.projectId,
      marketerId: postProjectMatchOfferMarketerAssessment?.marketerId,
      votes,
      assessmentComment,
    };
  }

  getSubmitFormVariables(formValues: FormValues): SetProjectMatchOfferMarketerAssessmentVariables {
    const marketerAssessmentParameters: Array<{
      key: PostProjectMatchOfferMarketerAssessmentParameterKey;
      value: number;
    }> = Object.entries(formValues?.votes || ({} as PostProjectMatchOfferMarketerAssessmentParameterKey))
      .map(([key, value]) => ({
        key: key as PostProjectMatchOfferMarketerAssessmentParameterKey,
        value: (value as number) || 0,
      }))
      .filter((item) => item.value);

    return {
      projectId: formValues?.projectId || 0,
      marketerId: formValues?.marketerId || 0,
      postProjectMatchOfferMarketerAssessmentInput: {
        marketerAssessmentParameters,
        marketerAssessmentComment: formValues?.assessmentComment,
      },
    };
  }

  getSkipFormVariables(formValues: FormValues): Record<string, any> {
    return {
      projectId: formValues?.projectId || 0,
      marketerId: formValues?.marketerId || 0,
    };
  }
}

export default PostProjectMatchOfferMarketerAssessmentConcrete;
