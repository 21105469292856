import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'calc(100% - 2rem)',
      borderTop: `1px solid ${colors.borderLight}`,
      borderBottom: `1px solid ${colors.borderLight}`,
      padding: '2rem',
      margin: '2rem auto',
    },
  }),
);
export default useStyles;
