import React, { FC, useCallback, useMemo } from 'react';
import { Project, ProjectLifeCycleStatus, ProjectPackageState, ProjectSpecialSubType } from '@mayple/types';
import { StopProjectMutation } from 'growl-graphql/dist/mutations/StopProjectMutation';

import ProjectHealthCheckStopReasonDialog from '../ProjectHealthCheckStopReasonDialog';
import useProjectHealthCheckStopReasonDialog from '../ProjectHealthCheckStopReasonDialog/useProjectHealthCheckStopReasonDialog';

import { setNotification } from '../../../../../fe_common/client/services/notification';
import ProjectStopReasonsDialog from '../../../../../fe_common/client/components/editables/ProjectStopReasonsDialog';
import { handleClientError } from '../../../../../fe_common/client/services/logger';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { useDialog, useMutation } from '../../../../../fe_common/client/hooks';
import useProjectAppliedPackages from '../../../../../fe_common/client/hooks/project/useProjectAppliedPackages';

export interface StopLiveProjectProps {
  project: Project;
  onSuccess: () => void;
}

const StopLiveProject: FC<StopLiveProjectProps> = (props) => {
  const { project, onSuccess } = props;

  const { open: isModalOpen, openDialog, closeDialog } = useDialog(false);

  const {
    isProjectHealthCheckStopReasonDialogOpen,
    openProjectHealthCheckStopReasonDialog,
    closeProjectHealthCheckStopReasonDialog,
  } = useProjectHealthCheckStopReasonDialog();

  const { id: projectId, fundsRemainingBehavior } = project;

  const { mutate: stopProjectMutation } = useMutation(StopProjectMutation);

  const { project: projectWithAppliedPackages, refetch: refetchProjectAppliedPackages } =
    useProjectAppliedPackages(projectId);

  const isHealthCheckProject =
    projectWithAppliedPackages?.specialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK ||
    projectWithAppliedPackages?.specialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2;

  const hasProjectPackagesNotCanceledOrCompleted = useMemo(
    () =>
      (projectWithAppliedPackages?.projectPackages || []).length > 0 &&
      projectWithAppliedPackages?.projectPackages?.some(
        ({ state }) =>
          state !== ProjectPackageState.PACKAGE_COMPLETED && state !== ProjectPackageState.PACKAGE_CANCELED,
      ),
    [projectWithAppliedPackages?.projectPackages],
  );

  const handleCancel = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleStopProject = useCallback(
    async (formValues) => {
      const ans = window.confirm(`Confirm stopping of project ID ${projectId}`);

      if (!ans) {
        return;
      }

      try {
        const result = await stopProjectMutation({ variables: formValues });
        if (result?.data?.stopProject?.success) {
          onSuccess?.();
          closeDialog();
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed stopping project ID ${projectId} :( Please contact developers.`);
      }
    },
    [projectId, stopProjectMutation, onSuccess, closeDialog],
  );

  const handleOnStopClick = () => {
    if (hasProjectPackagesNotCanceledOrCompleted) {
      if (isHealthCheckProject) {
        // For HC project we have a prior dialog
        openProjectHealthCheckStopReasonDialog();
        // we bail out here
        return;
      }

      alert('All project packages must be completed or canceled prior to stopping a project.');
      document.getElementById('projectPackages')?.scrollIntoView();
      return;
    }

    if (!fundsRemainingBehavior) {
      alert('Please set the Funds Remainder Behavior before stopping the project.');
      return;
    }

    openDialog();
  };

  const onSuccessHandler = async () => {
    await refetchProjectAppliedPackages();
    openDialog();
  };

  return (
    <div>
      <TextDisplay variant="h6" gutterBottom>
        Stop immediately
      </TextDisplay>

      <TextDisplay gutterBottom>
        Stopping a project will end the current monthly cycle immediately, and no next monthly cycle will take place.
        The current monthly cycle then proceeds to the final billing review, when billing details must be confirmed
        prior to actually performing the final billing.
      </TextDisplay>

      <Button
        color="secondary"
        variant="contained"
        label="STOP PROJECT"
        onClick={handleOnStopClick}
        disabled={project?.projectLifeCycleStatus === ProjectLifeCycleStatus.FINISHED}
      />

      {isModalOpen && (
        <ProjectStopReasonsDialog
          handleStopProject={handleStopProject}
          handleCancel={handleCancel}
          isComponentCustomerFacing={false}
          isStopProjectImmediately
          project={project}
          isOpen={isModalOpen}
          onClose={closeDialog}
        />
      )}

      {isProjectHealthCheckStopReasonDialogOpen && (
        <ProjectHealthCheckStopReasonDialog
          projectId={projectId}
          isOpen={isProjectHealthCheckStopReasonDialogOpen}
          onClose={closeProjectHealthCheckStopReasonDialog}
          onSuccess={onSuccessHandler}
        />
      )}
    </div>
  );
};

export default StopLiveProject;
