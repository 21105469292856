import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { collectDataFromProjectLead, getProjectLeadProperties } from './logic';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { TextDisplay, PropertiesList } from '../../../../../../../fe_common/client/components/display';

import { styles } from './style';

function ProjectLeadDetails({ project, classes }) {
  const { projectLead } = project;
  const data = collectDataFromProjectLead(projectLead);
  const projectLeadProperties = getProjectLeadProperties(classes);

  return (
    <Section title="Project Lead details">
      <SectionContent>
        {!data && (
          <TextDisplay className={classes.red}>
            This project was created after the user signed up, so it does not have a Project Lead.
          </TextDisplay>
        )}
        {data && (
          <PropertiesList
            id="projectLeadDetails"
            data={data}
            propertiesMeta={projectLeadProperties}
            classes={{
              propertyLabel: classes.propertyLabel,
              propertyValue: classes.propertyValue,
            }}
          />
        )}
      </SectionContent>
    </Section>
  );
}

ProjectLeadDetails.propTypes = {
  project: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectLeadDetails);
