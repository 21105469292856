import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    integrationDetailsListRoot: {
      width: '100%',
    },
    avatar: {
      borderRadius: 0,
    },
    accordionDetailsRoot: {
      paddingLeft: '4rem',
    },
  }),
);

export default useStyles;
