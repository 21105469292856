/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { EntityOperationResponse, Marketer, TaskType } from '@mayple/types';
import { useTranslation } from 'react-i18next';

import { AssessmentFormData, FormValues } from './types';
import getAssessmentVotesInitialValue from './logic';
import assessmentFactory from './AssessmentClasses/AssessmentFactory';
import { AssessmentFormProps, AssessmentWizardFormContent } from '../../types';

import { handleClientError } from '../../../../../services/logger';
import { TRANSLATION_NS } from '../../../../../app/consts';
import { useMutation } from '../../../../../hooks';

const useAssessmentFormData = (props: AssessmentFormProps): AssessmentFormData => {
  const { subTask, onSubmitForm, onSkipForm, currentStep = 1, totalSteps = 0, setDialogContent } = props;
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'AssessmentDialog.MarketerAssessmentForm' });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const marketerId = subTask?.marketerId || subTask?.project?.participants?.[0]?.marketerId;
  const marketer = subTask?.marketer || subTask?.project?.participants?.[0]?.marketerMatch;
  const { name: marketerName = '', displayImageUrl = '' } = marketer || ({} as Marketer);

  const taskType = subTask?.__typename as TaskType;

  const assessment = assessmentFactory.createAssessment(taskType);

  const assessmentVotes: string[] = assessment?.getAssessmentVotes();

  const [votes, setVotes] = useState<Record<string, string | number | undefined | null>>(() =>
    getAssessmentVotesInitialValue(assessmentVotes),
  );

  const [assessmentComment, setAssessmentComment] = useState('');

  const { mutate: setAssessmentForProject } = useMutation(assessment.saveMutation, {
    mutationResponseKey: assessment.saveMutationResponseKey,
  });

  const { mutate: skipAssessmentForProject } = useMutation(assessment.skipMutation, {
    mutationResponseKey: assessment.skipMutationResponseKey,
  });

  const getOnChangeStarVoteHandler = useCallback(
    (key: string) => (_event: React.ChangeEvent<any>, newValue: number | null) => {
      setVotes((prevVotes) => ({
        ...prevVotes,
        [key]: newValue,
      }));
    },
    [],
  );

  const getOnChangeScoreVoteHandler = useCallback(
    (key: string) => (newValue: number | string | undefined) => {
      setVotes((prevVotes) => ({
        ...prevVotes,
        [key]: newValue,
      }));
    },
    [],
  );

  const getOnChangeTextVoteHandler: (key: string) => React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
    (key) => (event) => {
      const newValue = event.target.value;

      setVotes((prevVotes) => ({
        ...prevVotes,
        [key]: newValue,
      }));
    };

  const onAssessmentCommentChangeHandler = useCallback((event: React.ChangeEvent<any>) => {
    const newValue = event.target?.value;
    setAssessmentComment(newValue);
  }, []);

  const submitFormHandler = useCallback(
    async (formValues: FormValues): Promise<EntityOperationResponse> => {
      const variables = assessment.getSubmitFormVariables(formValues);
      const result = await setAssessmentForProject({ variables });
      return result?.data?.[assessment.saveMutationResponseKey];
    },
    [assessment, setAssessmentForProject],
  );

  const onSubmitButtonClickHandler = useCallback(async () => {
    if (!subTask) {
      return;
    }

    const formValues = assessment.getFormValuesFromSubTask(subTask, votes, assessmentComment);

    try {
      const result = await submitFormHandler(formValues);

      if (!result?.success) {
        handleClientError(null, t('submitFormErrorMessage'));
        setErrorMessage(t('submitFormErrorMessage', 'There was a problem submitting your assessment data.'));
        return;
      }

      onSubmitForm?.();
    } catch (e) {
      handleClientError(null, t('submitFormErrorMessage'));
      setErrorMessage(t('submitFormErrorMessage', 'There was a problem submitting your assessment data.'));
    }
  }, [assessment, assessmentComment, onSubmitForm, subTask, submitFormHandler, t, votes]);

  const skipFormHandler = useCallback(
    async (formValues: FormValues): Promise<EntityOperationResponse> => {
      const variables = assessment.getSkipFormVariables(formValues);
      const result = await skipAssessmentForProject({ variables });
      return result?.data?.[assessment.skipMutationResponseKey];
    },
    [assessment, skipAssessmentForProject],
  );

  const onSkipButtonClickHandler = useCallback(async () => {
    if (!subTask) {
      return;
    }

    const formValues = assessment?.getFormValuesFromSubTask(subTask, votes, assessmentComment);

    try {
      const result = await skipFormHandler(formValues);

      if (!result?.success) {
        handleClientError(null, t('skipFormErrorMessage'));
        setErrorMessage(t('submitFormErrorMessage', 'There was a problem skipping your assessment data.'));
        return;
      }

      onSkipForm?.();
    } catch (e) {
      handleClientError(null, t('skipFormErrorMessage'));
      setErrorMessage(t('submitFormErrorMessage', 'There was a problem skipping your assessment data.'));
    }
  }, [subTask, assessment, votes, assessmentComment, skipFormHandler, onSkipForm, t]);

  useEffect(() => {
    if (currentStep < totalSteps) {
      setDialogContent(AssessmentWizardFormContent.SHOW_FORM);
    }
  }, [currentStep, setDialogContent, totalSteps]);

  return {
    marketerId,
    marketerName,
    displayImageUrl,
    onSubmitButtonClickHandler,
    onSkipButtonClickHandler,
    getOnChangeScoreVoteHandler,
    getOnChangeStarVoteHandler,
    getOnChangeTextVoteHandler,
    assessmentVotes,
    votes,
    assessmentComment,
    onAssessmentCommentChangeHandler,
    errorMessage,
  };
};

export default useAssessmentFormData;
