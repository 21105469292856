import React, { useEffect } from 'react';
import { TFunction } from 'i18next';

import { MarketingPlanSteps, StepProps } from '../types';
import { MarketingPlanStatusExtended } from '../../MarketingPlanProvider/types';
import { checkIsStepDone, getActiveStepFromMarketingPlanStatus } from './logic';
import CreateYourMarketingPlanStep from './steps/CreateYourMarketingPlanStep';
import ReviewByGrowthStrategistStep from './steps/ReviewByGrowthStrategistStep';
import MarketingPlanMeetingAndApprovalStep from './steps/MarketingPlanMeetingAndApprovalStep';

export const getSteps = (t: TFunction, marketingPlanStatus: MarketingPlanStatusExtended | undefined): StepProps[] => [
  {
    id: MarketingPlanSteps.createYourMarketingPlan,
    label: t('createYourMarketingPlanStepHeader'),
    component: CreateYourMarketingPlanStep,
    completed: checkIsStepDone(MarketingPlanSteps.createYourMarketingPlan, marketingPlanStatus),
  },
  {
    id: MarketingPlanSteps.reviewByGrowthStrategist,
    label: t('reviewByGrowthStrategistStepHeader'),
    component: ReviewByGrowthStrategistStep,
    completed: checkIsStepDone(MarketingPlanSteps.reviewByGrowthStrategist, marketingPlanStatus),
  },
  {
    id: MarketingPlanSteps.marketingPlanMeetingAndApproval,
    label: t('marketingPlanMeetingAndApprovalStepHeader'),
    component: MarketingPlanMeetingAndApprovalStep,
    completed: checkIsStepDone(MarketingPlanSteps.marketingPlanMeetingAndApproval, marketingPlanStatus),
  },
];

export const useMarketingPlanStepper = (
  marketingPlanStatus: MarketingPlanStatusExtended | undefined
): {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleNext: () => void;
  handleBack: () => void;
} => {
  const [activeStep, setActiveStep] = React.useState(() => getActiveStepFromMarketingPlanStatus(marketingPlanStatus));

  useEffect(() => {
    setActiveStep(getActiveStepFromMarketingPlanStatus(marketingPlanStatus));
  }, [marketingPlanStatus]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
  };
};
