import React, { FC, useCallback, useEffect, useState } from 'react';
import { MarketerPayoutsReviewItem } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import keyBy from 'lodash/keyBy';

import { getQueryData } from './logic';
import { MarketersPayoutReviewDataRow, MarketersPayoutReviewProps } from './types';
import MarketersPayoutReviewTable from './MarketersPayoutReviewTable';

import { RefreshSpinner } from '../../../../../../../fe_common/client/components/atoms';
import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import CalloutMessage from '../../../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

import { useMarketersPayoutReviewStyles } from './styles';

const DEFAULT_SERVER_PAGE_SIZE = 2000;

const MarketersPayoutReview: FC<MarketersPayoutReviewProps> = (props) => {
  const { marketerId, getAllMarketers = false } = props;

  const classes = useMarketersPayoutReviewStyles();

  const [initialized, setInitialized] = useState(false);
  const [finalData, setFinalData] = useState<MarketersPayoutReviewDataRow[] | null>(null);
  const [totalMarketers, setTotalMarketers] = useState(0);

  const { queryClass, variables, dataProcessor } = getQueryData(marketerId);

  const { loading, error, data, refetch } = useQuery(queryClass.query, {
    variables: {
      ...variables,
      pagination: {
        pageSize: DEFAULT_SERVER_PAGE_SIZE,
        by: [{ desc: true, key: 'marketerId' }],
      },
    },
    skip: !getAllMarketers && !marketerId,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (loading) {
      setInitialized(false);
    }
  }, [loading]);

  const onPayoutReportSuccessHandler = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    async function processData(result: MarketerPayoutsReviewItem[]): Promise<boolean> {
      const newData: MarketersPayoutReviewDataRow[] = await dataProcessor(result);
      setFinalData(newData);
      return true;
    }

    if (!loading && !initialized) {
      processData(data?.marketerPayoutsReview?.items || []).then(() => {
        setInitialized(true);
      });
    }
  }, [data?.marketerPayoutsReview?.items, dataProcessor, finalData, initialized, loading]);

  useEffect(() => {
    if (initialized && finalData) {
      const newTotalMarketers = new Set(Object.keys(keyBy(finalData, 'marketerId'))).size;
      setTotalMarketers(newTotalMarketers);
    }
  }, [initialized, finalData]);

  return (
    <Section
      noBorder
      title={marketerId ? 'Marketer Payout Review' : 'Marketers Payout Review'}
      classes={{ root: classes.sectionRoot }}
    >
      <SectionContent>
        {error && (
          <CalloutMessage
            message="Could not load Marketers Payout Review data. Try to refresh the page."
            type="error"
          />
        )}
        {(loading || !initialized) && (
          <div className={classes.loadingContainer}>
            <TextDisplay>Loading data... (This may take a while)</TextDisplay>
            <RefreshSpinner linear thickness={2} />
          </div>
        )}
        {!loading && initialized && !error && finalData && finalData?.length === 0 && (
          <TextDisplay variant="body1">No marketers payout data was found.</TextDisplay>
        )}
        <MarketersPayoutReviewTable
          data={finalData || []}
          loading={loading}
          totalRows={totalMarketers}
          onPayoutReportSuccess={onPayoutReportSuccessHandler}
          // onPageNumberChange={(newPageNumber) => setClientPageNumber(newPageNumber)}
          // onPageSizeChange={(newPageSize) => setClientPageSize(newPageSize)}
        />
      </SectionContent>
    </Section>
  );
};

export default MarketersPayoutReview;
