import isPlainObject from 'lodash/isPlainObject';
import { DefaultFilterFunction, Filter } from 'react-table-6';
import { PaypalOpenPaymentAgreement, CreditCardPaymentAgreement, TaskStatus } from '@mayple/types';

/**
 * this function filters the react-table-6 with case insensitivity
 */
export const filterCaseInsensitiveForReactTable: DefaultFilterFunction = (filter, row) => {
  const id = filter?.id || filter?.pivotId;

  // @ts-ignore
  let filterValue: any = id != null && ['number', 'string', 'symbol'].includes(typeof id) ? row[id] : '';

  if (isPlainObject(filterValue) && id === 'company') {
    filterValue = filterValue.name;
  }

  if (typeof filterValue === 'number') {
    filterValue = filterValue.toString();
  }

  if (typeof filterValue === 'boolean') {
    filterValue = filterValue ? 'Yes' : 'No';
  }

  return filterValue != null ? String(filterValue).toLowerCase().includes(filter.value.toLowerCase()) : true;
};

export const filterByAnotherField =
  (fieldName: string): DefaultFilterFunction =>
  (filter, row) => {
    const filterValue = row[fieldName];
    if (filterValue !== undefined) {
      return String(filterValue).toLowerCase().includes(filter.value.toLowerCase());
    }
    return true;
  };

export const billingAgreementFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  const { creditCardPaymentAgreements, manualPaymentAgreement, paypalOpenPaymentAgreements } = row[filter.id];
  const hasManualPaymentAgreement = manualPaymentAgreement?.active;

  const hasCreditCardPaymentAgreements =
    creditCardPaymentAgreements.length > 0 &&
    (creditCardPaymentAgreements as CreditCardPaymentAgreement[]).some(({ valid, active }) => valid || active);

  const hasPaypalOpenPaymentAgreements =
    paypalOpenPaymentAgreements.length > 0 &&
    (paypalOpenPaymentAgreements as PaypalOpenPaymentAgreement[]).some(({ valid, active }) => valid || active);

  if (filter.value === 'manual') {
    return hasManualPaymentAgreement;
  }
  if (filter.value === 'creditCard') {
    return hasCreditCardPaymentAgreements;
  }
  if (filter.value === 'payPal') {
    return hasPaypalOpenPaymentAgreements;
  }

  if (filter.value === 'none') {
    return !hasCreditCardPaymentAgreements && !hasManualPaymentAgreement && !hasPaypalOpenPaymentAgreements;
  }

  return true;
};

export const hasDebtsFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  if (filter.value === 'true') {
    return row[filter.id] > 0;
  }
  return row[filter.id] === 0;
};

export const yesNoFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  if (filter.value === 'true') {
    return row[filter.id] === true;
  }
  return row[filter.id] === false;
};

export const yesNoUndefinedFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  if (filter.value === 'true') {
    return row[filter.id] === true;
  }

  return !row[filter.id];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMarketerSubmittedMediaSpend = (value: any): boolean =>
  value?.taskStatus === TaskStatus.DONE || value?.taskStatus === TaskStatus.CLOSED;

export const marketerMonthlyMediaSpendFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  if (filter.value === 'true') {
    return getMarketerSubmittedMediaSpend(row[filter.id]);
  }

  return !getMarketerSubmittedMediaSpend(row[filter.id]);
};

export const companyBilledFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'true') {
    return row[filter.id] === 'FINALIZED';
  }
  if (filter.value === 'false') {
    return row[filter.id] !== 'FINALIZED';
  }

  return true;
};

export const emptyValueFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'empty') {
    return !row[filter.id];
  }
  return true;
};

export const projectLifeCycleFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  return filter.value === row[filter.id];
};

export const projectSpecialTypeFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  return filter.value === row[filter.id];
};

export const projectSpecialSubTypeFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  return filter.value === row[filter.id];
};

export const assignedGrowthStrategistFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  return filter.value === row[filter.id]?.id?.toString();
};

export const defaultEnumSingleValueFilterMethod: DefaultFilterFunction = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }
  return filter.value === row[filter.id];
};

export const defaultEnumArrayValueFilterMethod: DefaultFilterFunction = (filter: Filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  return (row?.[filter.id] || []).includes(filter.value);
};
