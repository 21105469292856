import { useEffect, useState } from 'react';
import { AccountType, CronofyElementToken, CronofyMembersEventQuery, Integration } from '@mayple/types';
import { DocumentNode } from 'graphql';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { CronofyAvailabilityViewerElementTokenQuery } from 'growl-graphql/dist/queries/CronofyAvailabilityViewerElementTokenQuery';
import { MarketerByIdIntegrationsQuery } from 'growl-graphql/dist/queries/MarketerByIdIntegrationsQuery';
import { InternalTeamByIdIntegrationsQuery } from 'growl-graphql/dist/queries/InternalTeamByIdIntegrationsQuery';

import { getCronofyIntegration } from '../../CronofyIntegrationManager/hooks';
import { useAvailabilityViewerState } from '../AvailabilityViewerProvider/AvailabilityViewerContext';
import { Account } from '../../types';

interface CronofyIntegrationReturnType {
  elementToken: CronofyElementToken;
  members: CronofyMembersEventQuery[];
  isLoading: boolean;
  error: ApolloError | undefined;
}

const getAccountIntegrationsQuery = (
  accountType: AccountType
): null | {
  query: DocumentNode;
  accountIdKey: string;
  resultKey: string;
} => {
  switch (accountType) {
    case AccountType.MARKETER:
      return {
        query: MarketerByIdIntegrationsQuery.query,
        accountIdKey: 'marketerId',
        resultKey: 'marketer',
      };
    case AccountType.INTERNAL_TEAM:
      return {
        query: InternalTeamByIdIntegrationsQuery.query,
        accountIdKey: 'internalTeamId',
        resultKey: 'internalTeam',
      };
    default:
      return null;
  }
};

interface UseAccountIntegrationsOutput {
  loading: boolean;
  error: ApolloError | undefined;
  integrations: Integration[] | null;
}

export const useAccountIntegrations = (
  accountId: number | null,
  accountType: AccountType
): UseAccountIntegrationsOutput => {
  const { query, accountIdKey = '', resultKey = '' } = getAccountIntegrationsQuery(accountType) || {};

  const variables = {
    [accountIdKey]: accountId,
  };

  const { loading, data, error } = useQuery(query as DocumentNode, {
    variables,
    skip: !query || !accountType || !accountId,
  });

  return {
    loading,
    error,
    integrations: data?.[resultKey]?.integrations,
  };
};

const useCronofyElementToken = (): CronofyIntegrationReturnType => {
  const [integrationIds, setIntegrationIds] = useState<Array<number | undefined>>([]);

  const { filterData, mainAccount } = useAvailabilityViewerState();

  const secondaryAccount: Account = filterData?.enableEntitySearch
    ? filterData?.account || ({} as Account)
    : ({} as Account);

  const accounts = [mainAccount, secondaryAccount];

  const marketerAccount = accounts.find(({ accountType }) => accountType === AccountType.MARKETER);
  const internalTeamAccount = accounts.find(({ accountType }) => accountType === AccountType.INTERNAL_TEAM);

  const marketerId = marketerAccount?.id || null;
  const internalTeamId = internalTeamAccount?.id || null;

  const {
    integrations: marketerIntegrations,
    loading: loadingMarketerIntegrations,
    error: errorMarketerIntegrations,
  } = useAccountIntegrations(marketerId, AccountType.MARKETER);

  const {
    integrations: internalTeamIntegrations,
    loading: loadingInternalTeamIntegrations,
    error: errorInternalTeamIntegrations,
  } = useAccountIntegrations(internalTeamId, AccountType.INTERNAL_TEAM);

  useEffect(() => {
    if (loadingMarketerIntegrations || loadingInternalTeamIntegrations) {
      return;
    }

    const marketerCronofyIntegration = getCronofyIntegration(marketerIntegrations);
    const internalTeamCronofyIntegration = getCronofyIntegration(internalTeamIntegrations);

    const newIntegrationIds = [] as number[];

    if (marketerCronofyIntegration?.id) {
      newIntegrationIds.push(marketerCronofyIntegration.id);
    }

    if (internalTeamCronofyIntegration?.id) {
      newIntegrationIds.push(internalTeamCronofyIntegration.id);
    }

    setIntegrationIds(newIntegrationIds);
  }, [loadingMarketerIntegrations, marketerIntegrations, loadingInternalTeamIntegrations, internalTeamIntegrations]);

  const { origin } = window.location;
  const variables = { integrationIds, origin };

  const {
    data: elementTokenQueryData,
    loading: isElementTokenQueryLoading,
    error,
  } = useQuery(CronofyAvailabilityViewerElementTokenQuery.query, {
    variables,
    skip: !integrationIds || integrationIds.length === 0,
  });

  const cronofyAvailabilityViewerElementToken = elementTokenQueryData?.cronofyAvailabilityViewerElementToken;

  return {
    elementToken: cronofyAvailabilityViewerElementToken?.elementToken,
    members: cronofyAvailabilityViewerElementToken?.members,
    isLoading: isElementTokenQueryLoading || loadingMarketerIntegrations,
    error: errorMarketerIntegrations || errorInternalTeamIntegrations || error,
  };
};

export default useCronofyElementToken;
