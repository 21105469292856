import { useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { i18n as i18nType } from 'i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useI18nLazyBundleLoader = (ns: string, resources: any, lng = 'en'): { i18n: i18nType; t: TFunction } => {
  const { i18n, t } = useTranslation(ns);

  useEffect(() => {
    i18n.addResourceBundle(lng, ns, resources, true, true);
  }, [i18n, lng, ns, resources]);

  return {
    t,
    i18n,
  };
};

export default useI18nLazyBundleLoader;
