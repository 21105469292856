import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      maxWidth: '100%',
      display: 'block',
      overflow: 'auto',
      paddingBottom: '2rem',
    },
    cardRoot: {
      borderRadius: '0 0 16px 16px',
    },
    actions: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    autoCompleteValueContainer: {
      padding: '0 8px',
    },
  }),
);

export default useStyles;
