import React, { useState } from 'react';
import { CurrencyType, MarketerSearchDefinition, MarketingServiceType, QueryOperatorType } from '@mayple/types';

import { MARKETING_SKILLS_PAIRS_WITH_ICONS } from '../../../../../../fe_common/client/app/enums';

interface AdvancedMarketerSearchHooks {
  initialValues: Record<string, any>;
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  filterData: MarketerSearchDefinition | undefined;
  setFilterData: React.Dispatch<React.SetStateAction<MarketerSearchDefinition | undefined>>;
}

/*
interface AdvancedMarketerSearchFormData {
  filtersGlobalOperator: 'AND' | 'OR';
  filterValuesOperator: 'AND' | 'OR';
  marketingSkills: MarketingSkills[];
  productBusinessModelExpertise: ProductBusinessModel[];
  locations: Location[];
  targetKPIExpertise: KpiType[];
  industryExpertiseCategory: [];
  industryExpertiseSubCategory: [];
  languageExpertise: [];
  countryExpertise: [];
  marketerLocation: [];
  monthlyBudgetRangeAmount: [];
  successStoriesIndustryCategory: [];
  successStoriesIndustrySubCategory: [];
  successStoriesSkillsUsed: [];
  minimumProjectFeeAmount: { value: ''; currency: 'USD' };
  numbersOfTotalProjectsAssigned: number | string;
  numberOfLiveProjectsAssigned: number | string;
  isVetted: boolean;
  availableForProjects: boolean;
  enableMatching: boolean;
  matchableForProjectSpecialTypes: ProjectSpecialType[];
}
*/

const initialValues = {
  filtersGlobalOperator: QueryOperatorType.AND,
  filterValuesOperator: QueryOperatorType.AND,
  marketingSkills: [
    MARKETING_SKILLS_PAIRS_WITH_ICONS.find(({ value }) => value === MarketingServiceType.FACEBOOK_ADS),
    MARKETING_SKILLS_PAIRS_WITH_ICONS.find(({ value }) => value === MarketingServiceType.GOOGLE_ADS),
  ],
  productBusinessModelExpertise: [],
  locations: [],
  targetKPIExpertise: [],
  industryExpertiseCategory: [],
  industryExpertiseSubCategory: [],
  languageExpertise: [],
  countryExpertise: [],
  marketerLocation: [],
  monthlyBudgetRangeAmount: [],
  successStoriesIndustryCategory: [],
  successStoriesIndustrySubCategory: [],
  successStoriesSkillsUsed: [],
  minimumProjectFeeAmount: { value: '', currency: CurrencyType.USD },
  numbersOfTotalProjectsAssigned: '',
  numberOfLiveProjectsAssigned: '',
  isVetted: true,
  availableForProjects: true,
  enableMatching: true,
  matchableForProjectSpecialTypes: [],
};

const useAdvancedMarketerSearch = (): AdvancedMarketerSearchHooks => {
  const [showResults, setShowResults] = useState(false);
  const [filterData, setFilterData] = useState<MarketerSearchDefinition>();

  return {
    initialValues,
    filterData,
    setFilterData,
    showResults,
    setShowResults,
  };
};

export default useAdvancedMarketerSearch;
