import moment from 'moment';
import { CurrencyType } from '@mayple/types';
import { formatCurrencyString } from '../utils';

export const escapeCsvValue = (value) => (value ?? '').toString().replace('"', '\\"');

export const defaultStringFormatter = (value) => `"${escapeCsvValue(value)}"`;

export const yesNoFormatter = (value) => (value ? 'Yes' : 'No');

export const localDateFormatter = (value) => moment(value).format('LL');

export const dateTimeFormatter = (value, format = 'YYYY-MM-DD') => {
  if (!value) {
    return null;
  }
  return moment(value).format(format);
};

export const dateTimeUTCFormatter = (value, format = 'YYYY-MM-DD', defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }
  return moment.utc(value).format(format);
};

export const numericFormatter = (value) => `${escapeCsvValue(value)}`;
export const currencyFormatter =
  (currency = CurrencyType.USD) =>
  (value) =>
    `${escapeCsvValue(formatCurrencyString(value, currency))}`;

export const yesNoCsvFormatter = (value) => defaultStringFormatter(yesNoFormatter(value));

export const stringArrayFormatter = (value) => {
  if (!value || !Array.isArray(value)) {
    return value;
  }
  return value.join(' / ');
};

export const periodInUnitsFormatter =
  (unitOfTime = 'hours') =>
  (value) =>
    !value ? value : `${value} ${unitOfTime}`;

export const numberOutOfNumberFormatter =
  (maxRange = 5) =>
  (value) =>
    value != null ? `${value} out of ${maxRange}` : value;

export const percentageFormatter =
  (precision = 0) =>
  (value) =>
    value ? `${value.toFixed(precision)}%` : value;
