import { IndustryCategory, IndustrySubCategory } from '@mayple/types';
import { AutoSuggestSkills } from './types';

export const STATIC_ASSETS_CDN_URL = 'https://static.cdn.mayple.com';

export const SHARED_FILE_MIME_TYPE = {
  TEXT: 'text/plain',
  PDF: 'application/pdf',
  MS_WORD: 'application/msword',
  MS_POWER_POINT: 'application/vnd.ms-powerpoint',
  MS_VISIO: 'application/vnd.visio',
  MS_EXCEL: 'application/vnd.ms-excel',
  ICON: 'image/vnd.microsoft.icon',
  CALENDAR: 'text/calendar',
  JPEG: 'image/jpeg',
  JSON: 'application/json',
  MP3: 'audio/mpeg',
  MPEG: 'audio/mpeg',
  RAR: 'application/x-rar-compressed',
  ZIP: 'application/zip',
  SEVEN_ZIP: 'application/x-7z-compressed',
  XML: 'text/xml',
  GOOGLE_SLIDES: 'application/vnd.google-apps.presentation',
};

export const BRIEF_CATEGORY_ESSENTIAL = 'essential';

// Cookies constants
export const APP_COOKIES = {
  //
  WEBSITE_PRE_FILLED_VALUES: '_mayple_pre_filled',
  //
  TEST_COOKIE: '_mayple_test_c',
  //
  LEAD_TOKEN_COOKIE: '_mayple_d_plt',
  // Mayple ding project create cookie
  PROJECT_CREATE: '_mayple_d_pc',
  // Mayple ding project create local cookie
  PROJECT_CREATE_LOCAL: '_mayple_d_pcl',
  // Mayple ding project mock local cookie
  PROJECT_MOCK_COOKIE: '_mayple_d_pml',
  // hasOffersTransactionId cookie, set via the has offers link
  HAS_OFFERS_ID: '_mayple_utm_h_o_id',
  // lead event reported
  LEAD_REPORTED: '_mayple_lead',
  // Unkapped cookie
  UNKAPPED_UKID: '_uk_ukid',
  // funnel a/b test version
  FUNNEL_VERSION: '_mayple_funnel_version',
  // Is Qualified Lead (Qualification score >= 0)
  IS_QUALIFIED_LEAD: '_mayple_q_lead',
};

export const URL_PARAMS = {
  MAYPLE_UNIVERSAL_TRACKING_ID: 'mut_id',
  PROJECT_LEAD_CREATE_TOKEN: 'token',
  EMAIL: 'email',
};

export const MAYPLE_ANALYTICS_TIME_TO_WAIT = 3;

export const HUBSPOT_CONTACT_LIFE_CYCLE = {
  // Contact with basic information that did not show high intent
  SUBSCRIBER: 'subscriber',
  // Contact with basic information that did show high intent of using mayple
  LEAD: 'lead',
  // Contact that finished basic brief, but did not sign up
  MQL: 'marketingqualifiedlead',
  // Contact that signed up
  SQL: 'salesqualifiedlead',
  // Contact received package for a project
  OPPORTUNITY: 'opportunity',
  // Contact accepted package after payment
  CUSTOMER: 'customer',
  // Customer that recommends mayple services to others
  EVANGELIST: 'evangelist',
  //
  OTHER: 'other',
};

// TODO: remove the following - deprecated
export const INDUSTRIES = {
  ART_AND_ENTERTAINMENT: 'ART_AND_ENTERTAINMENT',
  AUTOMOTIVE: 'AUTOMOTIVE',
  CAREERS: 'CAREERS',
  EDUCATION: 'EDUCATION',
  FOOD_AND_DRINK: 'FOOD_AND_DRINK',
  FINANCE: 'FINANCE',
  HEALTH_AND_FITNESS: 'HEALTH_AND_FITNESS',
  HOME_AND_GARDEN: 'HOME_AND_GARDEN',
  HOSPITALITY: 'HOSPITALITY',
  LAW_AND_POLITICS: 'LAW_AND_POLITICS',
  MEDIA: 'MEDIA',
  REAL_ESTATE: 'REAL_ESTATE',
  SOCIETY: 'SOCIETY',
  STYLE_AND_FASHION: 'STYLE_AND_FASHION',
  SHOPPING: 'SHOPPING',
  TECHNOLOGY: 'TECHNOLOGY',
  TRAVEL: 'TRAVEL',
  OTHER: 'OTHER',
};

export const INDUSTRIES_CATEGORIES = {
  ART_AND_ENTERTAINMENT: 'ART_AND_ENTERTAINMENT',
  AUTOMOTIVE: 'AUTOMOTIVE',
  BABY: 'BABY',
  BEAUTY: 'BEAUTY',
  ELECTRONICS: 'ELECTRONICS',
  EDUCATION: 'EDUCATION',
  FINANCE: 'FINANCE',
  FOOD_AND_DRINK: 'FOOD_AND_DRINK',
  GAMING: 'GAMING',
  HEALTH_AND_WELLNESS: 'HEALTH_AND_WELLNESS',
  HOME_AND_GARDEN: 'HOME_AND_GARDEN',
  NONPROFIT_AND_GOVERNMENT: 'NONPROFIT_AND_GOVERNMENT',
  PETS: 'PETS',
  PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
  REAL_ESTATE: 'REAL_ESTATE',
  SOCIAL_AND_COMMUNITY: 'SOCIAL_AND_COMMUNITY',
  SPORTS_OUTDOORS_AND_FITNESS: 'SPORTS_OUTDOORS_AND_FITNESS',
  STYLE_AND_FASHION: 'STYLE_AND_FASHION',
  TECHNOLOGY: 'TECHNOLOGY',
  TRAVEL: 'TRAVEL',
};

export const INDUSTRIES_CATEGORIES_LABELS = {
  [IndustryCategory.ART_AND_ENTERTAINMENT]: 'Art & Entertainment',
  [IndustryCategory.AUTOMOTIVE]: 'Automotive',
  [IndustryCategory.BABY]: 'Baby',
  [IndustryCategory.BEAUTY]: 'Beauty',
  [IndustryCategory.ELECTRONICS]: 'Electronics',
  [IndustryCategory.EDUCATION]: 'Education',
  [IndustryCategory.FINANCE]: 'Finance',
  [IndustryCategory.FOOD_AND_DRINK]: 'Food & Drink',
  [IndustryCategory.GAMING]: 'Gaming',
  [IndustryCategory.HEALTH_AND_WELLNESS]: 'Health & Wellness',
  [IndustryCategory.HOME_AND_GARDEN]: 'Home & Garden',
  [IndustryCategory.NONPROFIT_AND_GOVERNMENT]: 'Nonprofit & Government',
  [IndustryCategory.PETS]: 'Pets',
  [IndustryCategory.PROFESSIONAL_SERVICES]: 'Professional Services',
  [IndustryCategory.REAL_ESTATE]: 'Real Estate',
  [IndustryCategory.SOCIAL_AND_COMMUNITY]: 'Social & Community',
  [IndustryCategory.SPORTS_OUTDOORS_AND_FITNESS]: 'Sports, Outdoors & Fitness',
  [IndustryCategory.STYLE_AND_FASHION]: 'Style & Fashion',
  [IndustryCategory.TECHNOLOGY]: 'Technology',
  [IndustryCategory.TRAVEL]: 'Travel',
};

export const DEPRECATED_INDUSTRIES_SUBCATEGORIES = new Set([IndustrySubCategory.CBD_PRODUCTS]);

export const INDUSTRIES_SUBCATEGORIES = {
  [IndustryCategory.ART_AND_ENTERTAINMENT]: {
    [IndustrySubCategory.ART]: 'Art',
    [IndustrySubCategory.BOOKS_AND_LITERATURE]: 'Books & Literature',
    [IndustrySubCategory.DIGITAL_CONTENT]: 'Digital content',
    [IndustrySubCategory.GIFTS]: 'Gifts',
    [IndustrySubCategory.EVENTS_AND_ATTRACTIONS]: 'Events & Attractions',
    [IndustrySubCategory.MUSIC]: 'Music',
    [IndustrySubCategory.PHOTOGRAPHY]: 'Photography',
  },
  [IndustryCategory.AUTOMOTIVE]: {
    [IndustrySubCategory.AUTO_BUYING_AND_SELLING]: 'Auto buying and selling',
    [IndustrySubCategory.AUTO_PARTS_AND_TIRES]: 'Auto parts & Tires',
    [IndustrySubCategory.AUTO_SERVICES]: 'Auto services',
  },
  [IndustryCategory.BABY]: {
    [IndustrySubCategory.BABY_CLOTHING]: 'Baby clothing',
    [IndustrySubCategory.BABY_PRODUCTS]: 'Baby products',
    [IndustrySubCategory.SAFETY_AND_HEALTH]: 'Safety & Health',
  },
  [IndustryCategory.BEAUTY]: {
    [IndustrySubCategory.BATH_AND_BODY]: 'Bath & Body',
    [IndustrySubCategory.HAIR_BEARD_AND_NAIL_CARE]: 'Hair, beard & nail care',
    [IndustrySubCategory.MAKEUP_COSMETICS_AND_PERFUMES]: 'Makeup, cosmetics and perfumes',
  },
  [IndustryCategory.ELECTRONICS]: {
    [IndustrySubCategory.COMPUTERS_LAPTOPS_AND_ACCESSORIES]: 'Computers, laptops and accessories',
    [IndustrySubCategory.MAINTENANCE_AND_REPAIR_SERVICES]: 'Maintenance and repair services',
    [IndustrySubCategory.SMARTPHONES_SMARTWATCHES_AND_ACCESSORIES]: 'Smartphones, smartwatches and accessories',
    [IndustrySubCategory.SOUND_SYSTEMS]: 'Sound systems',
  },
  [IndustryCategory.EDUCATION]: {
    [IndustrySubCategory.COLLEGE_AND_UNIVERSITIES]: 'College and Universities',
    [IndustrySubCategory.EARLY_PRIMARY_AND_SECONDARY_EDUCATION]: 'Early, primary and secondary education',
    [IndustrySubCategory.ONLINE_COURSES]: 'Online courses',
    [IndustrySubCategory.STUDIOS_AND_VOCATIONAL_SCHOOLS]: 'Studios and vocational schools',
  },
  [IndustryCategory.FINANCE]: {
    [IndustrySubCategory.BANKING_AND_CREDIT_CARDS]: 'Banking & Credit Cards',
    [IndustrySubCategory.CRYPTO]: 'Crypto',
    [IndustrySubCategory.INSURANCE]: 'Insurance',
    [IndustrySubCategory.INVESTMENT]: 'Investment',
    [IndustrySubCategory.MORTGAGES_AND_LOANS]: 'Mortgages & Loans',
  },
  [IndustryCategory.FOOD_AND_DRINK]: {
    [IndustrySubCategory.ALCOHOLIC_BEVERAGES]: 'Alcoholic beverages',
    [IndustrySubCategory.COFFEE_TEA_CHOCOLATE_AND_GOURMET_FOOD]: 'Coffee, tea, chocolate and gourmet food',
    [IndustrySubCategory.GROCERY_STORES]: 'Grocery stores',
    [IndustrySubCategory.NON_ALCOHOLIC_BEVERAGES]: 'Non-alcoholic beverages',
    [IndustrySubCategory.RESTAURANTS_AND_BARS]: 'Restaurants & Bars',
    [IndustrySubCategory.VEGETARIAN_VEGAN_AND_HEALTHY_EATING]: 'Vegetarian, vegan and healthy eating',
  },
  [IndustryCategory.GAMING]: {
    [IndustrySubCategory.CONSOLE_AND_PC_GAMES]: 'Console & PC games',
    [IndustrySubCategory.EDUCATIONAL_VIDEO_GAMES]: 'Educational video games',
    [IndustrySubCategory.ESPORTS]: 'eSports',
    [IndustrySubCategory.GAMBLING]: 'Gambling',
    [IndustrySubCategory.ONLINE_GAMES]: 'Online games',
    [IndustrySubCategory.TOYS_AND_GAMES]: 'Toys & Games',
  },
  [IndustryCategory.HEALTH_AND_WELLNESS]: {
    [IndustrySubCategory.ALTERNATIVE_MEDICINE]: 'Alternative medicine',
    [IndustrySubCategory.CBD_PRODUCTS]: 'CBD products',
    [IndustrySubCategory.CHILDRENS_HEALTH]: "Children's health",
    [IndustrySubCategory.DENTAL_CARE]: 'Dental care',
    [IndustrySubCategory.HEALTH_CARE_FACILITIES]: 'Health care facilities',
    [IndustrySubCategory.MEDICAL_CARE]: 'Medical care',
    [IndustrySubCategory.MEDICAL_EQUIPMENT_AND_SUPPLIES]: 'Medical equipment and supplies',
    [IndustrySubCategory.NUTRITION_AND_WEIGHT_LOSS]: 'Nutrition & Weight Loss',
    [IndustrySubCategory.SENIOR_HEALTH]: 'Senior Health',
    [IndustrySubCategory.VISION_CARE]: 'Vision care',
    [IndustrySubCategory.VITAMINS_AND_SUPPLEMENTS]: 'Vitamins & supplements',
    [IndustrySubCategory.WELLNESS_AND_HEALTHY_LIVING]: 'Wellness & healthy living',
  },
  [IndustryCategory.HOME_AND_GARDEN]: {
    [IndustrySubCategory.CLEANING_SERVICES]: 'Cleaning services',
    [IndustrySubCategory.ENERGY_EFFICIENCY]: 'Energy efficiency',
    [IndustrySubCategory.FURNITURE]: 'Furniture',
    [IndustrySubCategory.GARDENING_AND_SUPPLIES]: 'Gardening and supplies',
    [IndustrySubCategory.HOME_DECOR]: 'Home decor',
    [IndustrySubCategory.HOME_ELECTRONICS]: 'Home electronics',
    [IndustrySubCategory.HOME_IMPROVEMENT]: 'Home improvement',
    [IndustrySubCategory.HOME_SECURITY]: 'Home security',
    [IndustrySubCategory.LOCKSMITH]: 'Locksmith',
    [IndustrySubCategory.MOVING]: 'Moving',
  },
  [IndustryCategory.NONPROFIT_AND_GOVERNMENT]: {
    [IndustrySubCategory.CHARITY]: 'Charity',
    [IndustrySubCategory.GOVERNMENT_SERVICES]: 'Government services',
    [IndustrySubCategory.POLITICAL_OR_RELIGIOUS_NONPROFIT]: 'Political or religious nonprofit',
  },
  [IndustryCategory.PETS]: {
    [IndustrySubCategory.MEDICATION_AND_SUPPLEMENTS]: 'Medication and supplements',
    [IndustrySubCategory.PET_SHOPS_FOOD_AND_SUPPLIES]: 'Pet shops, food, and supplies',
    [IndustrySubCategory.VETERINARY_SERVICES]: 'Veterinary services',
  },
  [IndustryCategory.PROFESSIONAL_SERVICES]: {
    [IndustrySubCategory.ATTORNEYS_AND_LAWYERS]: 'Attorneys & lawyers',
    [IndustrySubCategory.CAREERS]: 'Careers',
    [IndustrySubCategory.CONSULTING_SERVICES]: 'Consulting Services',
    [IndustrySubCategory.EVENTS_PLANNING]: 'Events planning',
    [IndustrySubCategory.MARKETING_AGENCIES]: 'Marketing agencies',
    [IndustrySubCategory.TAX_AND_ACCOUNTING]: 'Tax and accounting',
    [IndustrySubCategory.WEB_DEVELOPMENT_AND_DESIGN]: 'Web development & design',
  },
  [IndustryCategory.REAL_ESTATE]: {
    [IndustrySubCategory.COWORKING_SPACES]: 'Co-working spaces',
    [IndustrySubCategory.LUXURIOUS_PROPERTIES]: 'Luxurious properties',
    [IndustrySubCategory.OFFICE_PROPERTY]: 'Office property',
    [IndustrySubCategory.PROPERTY_MANAGEMENT]: 'Property management',
    [IndustrySubCategory.REAL_ESTATE_BUYING_AND_SELLING]: 'Real Estate buying and selling',
    [IndustrySubCategory.REAL_ESTATE_RENTING_AND_LEASING]: 'Real Estate renting and leasing',
  },
  [IndustryCategory.SOCIAL_AND_COMMUNITY]: {
    [IndustrySubCategory.COMMUNITY_ORGANIZATIONS]: 'Community organizations',
    [IndustrySubCategory.DATING]: 'Dating',
    [IndustrySubCategory.NETWORKING]: 'Networking',
    [IndustrySubCategory.PARENTING]: 'Parenting',
  },
  [IndustryCategory.SPORTS_OUTDOORS_AND_FITNESS]: {
    [IndustrySubCategory.CAMPING_AND_OUTDOORS]: 'Camping and outdoors',
    [IndustrySubCategory.EXERCISE_AND_FITNESS]: 'Exercise and fitness',
    [IndustrySubCategory.SPORTS_GAMES_AND_TOYS]: 'Sports games and toys',
    [IndustrySubCategory.SPORTING_EQUIPMENT]: 'Sporting equipment',
  },
  [IndustryCategory.STYLE_AND_FASHION]: {
    [IndustrySubCategory.ACCESSORIES]: 'Accessories',
    [IndustrySubCategory.CLOTHING_AND_SHOES]: 'Clothing and shoes',
    [IndustrySubCategory.FASHION_JEWELRY]: 'Fashion Jewelry',
    [IndustrySubCategory.FINE_JEWELRY]: 'Fine Jewelry',
    [IndustrySubCategory.HANDBAGS_WALLETS_HATS_AND_SCARVES]: 'Handbags, wallets, hats and scarves',
    [IndustrySubCategory.WATCHES_AND_SUNGLASSES]: 'Watches & Sunglasses',
  },
  [IndustryCategory.TECHNOLOGY]: {
    [IndustrySubCategory.AUTOMOTIVE]: 'Automotive',
    [IndustrySubCategory.BIOTECH]: 'Biotech',
    [IndustrySubCategory.CLEANTECH]: 'CleanTech',
    [IndustrySubCategory.CRM]: 'CRM',
    [IndustrySubCategory.CYBERSECURITY]: 'Cybersecurity',
    [IndustrySubCategory.ECOMMERCE_SERVICES]: 'Ecommerce services',
    [IndustrySubCategory.EDTECH]: 'Edtech',
    [IndustrySubCategory.FINTECH]: 'Fintech',
    [IndustrySubCategory.HEALTHTECH]: 'Healthtech',
    [IndustrySubCategory.HRTECH]: 'HRtech',
    [IndustrySubCategory.LEGALTECH]: 'Legaltech',
    [IndustrySubCategory.MARKETPLACE]: 'Marketplace',
    [IndustrySubCategory.MARTECH]: 'Martech',
    [IndustrySubCategory.PAYTECH]: 'Paytech',
    [IndustrySubCategory.REGTECH]: 'Regtech',
    [IndustrySubCategory.REMOTE_WORK]: 'Remote work',
    [IndustrySubCategory.SAAS]: 'SAAS',
    [IndustrySubCategory.TELECOM]: 'Telecom',
    [IndustrySubCategory.PROPTECH]: 'Proptech',
  },
  [IndustryCategory.TRAVEL]: {
    [IndustrySubCategory.AUTO_RENTALS]: 'Auto rentals',
    [IndustrySubCategory.CRUISES]: 'Cruises',
    [IndustrySubCategory.FLIGHTS]: 'Flights',
    [IndustrySubCategory.HOTELS_AND_ACCOMMODATIONS]: 'Hotels and accommodations',
    [IndustrySubCategory.TRANSPORTATION_SERVICES]: 'Transportation services',
    [IndustrySubCategory.TRAVEL_AGENCY_AND_TOURS]: 'Travel agency & Tours',
  },
};

export const FILE_STACK_DEFAULT_SOURCES = [
  'local_file_system',
  'facebook',
  'googledrive',
  'imagesearch',
  'instagram',
  'box',
  'github',
  'gmail',
  'picasa',
  'onedrive',
  'webcam',
];

export const AUTO_SUGGEST_SKILLS = {
  [AutoSuggestSkills.AUTO]: 'AUTO',
  [AutoSuggestSkills.MANUAL]: 'MANUAL',
};

export const REMOTE_WORK = {
  DOMESTIC: 'DOMESTIC',
  ON_TARGET: 'ON_TARGET',
  NO_PREFERENCE: 'NO_PREFERENCE',
  NA: 'N/A',
};

/**
 * Enum representing possible answer types
 * @type {{ENUM: string, STRING: string, CURRENCY_RANGE: string}}
 */
export const ANSWER_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  NUMERIC_RANGE: 'NUMERIC_RANGE',
  CURRENCY: 'CURRENCY',
  CURRENCY_RANGE: 'CURRENCY_RANGE',
  ENUM: 'ENUM',
  LOCATION_NAME: 'LOCATION_NAME',
  URL: 'URL',
  EMAIL: 'EMAIL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  FILE: 'FILE',
  IMAGE: 'IMAGE',
  DATE: 'DATE',
};

// PAYMENT_METHODS
export const PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  PAYPAL: 'PAYPAL',
};

export const TRANSLATION_NS = 'translation';

export const PPC_SERVICE_TYPE = 'PAID_MEDIA';
