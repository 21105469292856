import { useMemo } from 'react';
import moment from 'moment-timezone';

import { TimezoneOption } from './types';

const useAllTimezones = (showTimezoneOffset: boolean): TimezoneOption[] => {
  const timeZones = moment.tz.names();

  const offsetTmz: TimezoneOption[] = useMemo(
    () =>
      timeZones.map((tz) => {
        const tzOffset = moment.tz(tz).format('Z');
        const value: string = parseInt(
          tzOffset.replace(':00', '.00').replace(':15', '.25').replace(':30', '.50').replace(':45', '.75'),
          10
        ).toFixed(2);

        return {
          label: showTimezoneOffset ? `${tz} (GMT${tzOffset})` : tz,
          value: tz,
          tzOffset: value,
        };
      }),
    [showTimezoneOffset, timeZones]
  );

  return offsetTmz;
};

export default useAllTimezones;
