import React, { FC } from 'react';
import { Field } from 'redux-form';

import { WizardRadioButtonGroupProps } from './types';
import RadioGroupField from './RadioGroupField';
import { validateRequired } from '../../../../services/validation';

const WizardRadioButtonGroup: FC<WizardRadioButtonGroupProps> = (props) => {
  const { enums = [], name, label: fieldLabel = '', isRequired = false, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  return (
    <div>
      <Field component={RadioGroupField} name={name} label={fieldLabel} validate={validator} enums={enums} {...rest} />
    </div>
  );
};

export default WizardRadioButtonGroup;
