import { DeepBriefQuestionnaire, Project, ProjectLifeCycleStatus } from '@mayple/types';

import { SelectOptions } from '../../../../../../fe_common/client/components/atoms/Registration/Select';
import { sortByLabel } from '../../../../../../fe_common/client/app/enums';

/**
 * Return a list of { label, value } out of projects[] excluding the supplied excludedProjectIds
 * @param projects
 * @param excludedProjectIds
 */
export const getCompanyProjectsList = (projects: Project[] = [], excludedProjectIds: number[] = []): SelectOptions[] =>
  projects
    .filter(
      ({ id, projectLifeCycleStatus }) =>
        !excludedProjectIds.includes(id) &&
        [ProjectLifeCycleStatus.ONBOARDING, ProjectLifeCycleStatus.LIVE, ProjectLifeCycleStatus.DISCOVERY].includes(
          projectLifeCycleStatus,
        ),
    )
    .sort((a: Project, b: Project) => (a.id < b.id ? 1 : -1))
    .map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} (ID: ${id})`,
    }));

/**
 * combines the list of answerSetUuid in project brief into 1 long string
 * @param projectBrief
 */
const getDeepBriefAnswerSetHash = (projectBrief: DeepBriefQuestionnaire[]): string =>
  (projectBrief || [])
    .sort(sortByLabel('answerSetUuid'))
    .map(({ answerSetUuid }) => answerSetUuid)
    .join();

/**
 * Gets two projects deep brief hashes and compare their deep brief answer sets to see if matched
 */
const isDeepBriefMatchBetweenProjects = (
  sourceProjectDeepBriefHash: string,
  targetProjectDeepBriefHash: string,
): boolean => sourceProjectDeepBriefHash === targetProjectDeepBriefHash;

/**
 * Return a list of projects (excluding source project) that has matching deep brief answerSets
 * @param companyProjects
 * @param sourceProject
 */
export const getBriefConnectedProjects = (sourceProject: Project, companyProjects: Project[] = []): Project[] => {
  const { deepBrief: sourceProjectBrief, id: sourceProjectId } = sourceProject;
  const sourceProjectBriefHash = getDeepBriefAnswerSetHash(sourceProjectBrief);

  return companyProjects.filter(({ id: targetProjectId, deepBrief }) => {
    const targetProjectDeepBriefHash = getDeepBriefAnswerSetHash(deepBrief);
    return (
      targetProjectId !== sourceProjectId &&
      isDeepBriefMatchBetweenProjects(sourceProjectBriefHash, targetProjectDeepBriefHash)
    );
  });
};
