import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { ReportProjectPackageAsCompletedMutation } from 'growl-graphql/dist/mutations/project/projectPackage/ReportProjectPackageAsCompletedMutation';

import { MarkAsCompletedReasonDialogProps, MarkAsCompletedReasonFormData } from './types';
import WizardSteps from './steps';
import MarkAsCompletedReasonDialogEn from './translations/en/MarkAsCompletedReasonDialog.json';

import { Dialog } from '../../display';
import { WizardForm } from '../../organisms/WizardForm';
import { handleClientError } from '../../../services/logger';
import { useMutation } from '../../../hooks';
import useI18nLazyBundleLoader from '../../../hooks/useI18nLazyBundleLoader';

const TRANSLATION_NS = 'MarkAsCompletedReasonDialog';
const FORM_NAME = 'formReportProjectPackageAsCompletedReason';

const ReportProjectPackageAsCompletedReasonDialog: React.FC<MarkAsCompletedReasonDialogProps> = (props) => {
  const { isOpen, onClose, projectPackageUuid, onSuccessfulSave } = props;

  useI18nLazyBundleLoader(TRANSLATION_NS, MarkAsCompletedReasonDialogEn);
  const { t } = useTranslation(TRANSLATION_NS);

  const {
    mutate: reportProjectPackageAsCompleted,
    loading: saving,
    error,
  } = useMutation(ReportProjectPackageAsCompletedMutation);

  const handleFormSubmit: FormSubmitHandler<MarkAsCompletedReasonFormData> = useCallback(
    async (formValuesSubmitted, _dispatch, formProps) => {
      const { completionMessage = '' } = formValuesSubmitted;

      try {
        const variables = {
          projectPackageUuid,
          completionMessage,
        };

        await reportProjectPackageAsCompleted({ variables });
        await onSuccessfulSave?.();
        formProps?.destroy?.();
        onClose?.();
      } catch (e) {
        handleClientError(e, t('errorOnUpdate'));
      }
    },
    [onClose, onSuccessfulSave, projectPackageUuid, reportProjectPackageAsCompleted, t]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      title={t('dialogTitle')}
      subtitle={t('dialogSubtitle')}
      isOpen={isOpen}
      onClose={onClose}
      containerMaxWidth="sm"
      fullWidth
    >
      <WizardForm
        initialValues={{
          projectPackageUuid,
          completionMessage: '',
        }}
        form={`${FORM_NAME}-${projectPackageUuid}`}
        // @ts-ignore
        onSubmit={handleFormSubmit}
        onClose={onClose}
        steps={WizardSteps}
        loading={saving}
        error={error}
        t={t}
      />
    </Dialog>
  );
};

export default ReportProjectPackageAsCompletedReasonDialog;
