import React, { FC } from 'react';
import classNames from 'classnames';

import Texts from './consts';
import usePackageContactUsButtonProps from './hooks/usePackageContactUsButtonProps';

import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';

import { useCommonStyles, useContactUsStyles } from '../style';

const PackageContactUs: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useContactUsStyles();
  const itemsData = usePackageContactUsButtonProps();

  return (
    <div className={classNames(classes.root, commonClasses.lightDiv)}>
      <div className={commonClasses.center}>
        <TextDisplay variant="h5" bold className={classes.title}>
          {Texts.TITLE}
        </TextDisplay>
      </div>

      <div className={classes.actionsContainer}>
        {itemsData.map((itemProps) => {
          const key = `container--${itemProps.label.replace(' ', '-')}`;

          return (
            <div className={commonClasses.center} key={key}>
              <Button
                {...itemProps}
                className={classes.btn}
                classes={{ startIcon: classes.btnStartIcon }}
                variant="outlined"
                color="primary"
              />
            </div>
          );
        })}
      </div>

      <div className={classNames(classes.caption, commonClasses.center)}>
        <TextDisplay variant="caption" className={classNames(classes.caption, commonClasses.center)}>
          {Texts.WORKING_HOURS}
        </TextDisplay>
      </div>
    </div>
  );
};

export default PackageContactUs;
