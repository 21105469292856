import React, { FC } from 'react';

import Constants from './constants';
import useCronofyAvailabilityViewerConfiguration from './hooks/useCronofyAvailabilityViewerConfiguration';
import { CronofyAvailabilityViewerOptions } from '../types/cronofyTypes';

export interface CronofyAvailabilityViewerProps {
  options: CronofyAvailabilityViewerOptions;
}

const CronofyAvailabilityViewer: FC<CronofyAvailabilityViewerProps> = ({ options }) => {
  const targetId = options?.target_id || Constants.WIDGET_TARGET_ID;

  useCronofyAvailabilityViewerConfiguration(options);

  return targetId ? <div id={targetId} /> : null;
};

export default CronofyAvailabilityViewer;
