import React, { FC } from 'react';
import { Field } from 'redux-form';

import WizardStarsVoteInput from './WizardStarsVoteInput';
import { validateRequired } from '../../../../services/validation';

interface WizardStarsVoteFieldProps {
  name: string;
  isRequired?: boolean;
  // validationErrorMessage?: string;
}

const WizardStarsVoteField: FC<WizardStarsVoteFieldProps> = (props) => {
  const { name, isRequired /* validationErrorMessage = 'Required' */, ...rest } = props;

  const validator = isRequired ? [validateRequired] : [];

  return <Field name={name} component={WizardStarsVoteInput} validate={validator} {...rest} />;
};

export default WizardStarsVoteField;
