import React from 'react';
import { AccountType } from '@mayple/types';

import { TRANSLATION_NS } from './constants';
import { AvailabilityViewerFilterData, AvailabilityViewerOptions } from './types';
import { Account } from '../types';
import AvailabilityViewerInitializer from './AvailabilityViewerInitializer';
import AvailabilityViewerFilter from './AvailabilityViewerFilter';
import AvailabilityViewerContextProvider from './AvailabilityViewerProvider/AvailabilityViewerContext';
import availabilityViewerEn from './translations/en/availabilityViewer.json';
import useI18nLazyBundleLoader from '../../../../hooks/useI18nLazyBundleLoader';

import useStyles from './style';

export interface AvailabilityViewerProps {
  mainAccount: Account;
  selectionAccountType: AccountType;
  initOptions?: AvailabilityViewerOptions;
  defaultFilter?: AvailabilityViewerFilterData;
  showFilter?: boolean;
}

const AvailabilityViewer: React.FC<AvailabilityViewerProps> = (props) => {
  const classes = useStyles();

  useI18nLazyBundleLoader(TRANSLATION_NS, availabilityViewerEn);

  const {
    mainAccount,
    initOptions = {} as AvailabilityViewerOptions,
    defaultFilter,
    showFilter = true,
    selectionAccountType = AccountType.INTERNAL_TEAM,
  } = props;

  const showAvailabilityProfiles = mainAccount?.accountType === AccountType.INTERNAL_TEAM;

  return (
    <AvailabilityViewerContextProvider
      value={{ mainAccount, filterData: defaultFilter, initOptions, selectionAccountType }}
    >
      <div className={classes.container}>
        <div className={classes.availabilityViewer}>
          <AvailabilityViewerInitializer />
        </div>
        {showFilter && (
          <div className={classes.filterOptions}>
            <AvailabilityViewerFilter showAvailabilityProfiles={showAvailabilityProfiles} />
          </div>
        )}
      </div>
    </AvailabilityViewerContextProvider>
  );
};

export default AvailabilityViewer;
