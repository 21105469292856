import { Project, ProjectStage } from '@mayple/types';

import { stageTitle } from '../../../../../../../fe_common/client/logic/projectStage';

interface ProjectAction {
  onClick: () => any;
  label: string;
}

interface ProjectActionDetails {
  title: string;
  subtitle: string;
  action?: ProjectAction;
}

const getCurrentProjectStageAction = (project: Project): ProjectActionDetails => {
  const { stage } = project;

  if (!stage) {
    return { title: 'ERROR! Missing project stage', subtitle: 'Please contact QA team.' };
  }

  const map: Record<ProjectStage, ProjectActionDetails> = {
    [ProjectStage.WAITING_FOR_PACKAGE]: {
      title: stageTitle[ProjectStage.WAITING_FOR_PACKAGE],
      subtitle:
        'Sales should select a package which suits the client. Until this step is done, the client cannot add a payment method/move to onboarding.',
    },
    [ProjectStage.PACKAGE_UNDER_CLIENT_REVIEW]: {
      title: stageTitle[ProjectStage.PACKAGE_UNDER_CLIENT_REVIEW],
      subtitle:
        'The Package is being reviewed by the client. To move to the next stage they should book their package and add their credit card details',
    },
    [ProjectStage.DATA_ENRICHMENT]: {
      title: stageTitle[ProjectStage.DATA_ENRICHMENT],
      subtitle: 'The client should be filling in their deep brief and sharing their marketing accounts.',
    },
    [ProjectStage.OPPORTUNITIES]: {
      title: stageTitle[ProjectStage.OPPORTUNITIES],
      subtitle:
        'The Expert Success team needs to send opportunities and choose one of the applicants (they still need to be approved by the client)',
    },
    [ProjectStage.MARKETER_APPROVAL]: {
      title: stageTitle[ProjectStage.MARKETER_APPROVAL],
      subtitle: 'The client is meeting the Expert and then Approving/Declining them as their mayple expert',
    },
    [ProjectStage.EXISTING_MARKETING_EVALUATION]: {
      title: stageTitle[ProjectStage.EXISTING_MARKETING_EVALUATION],
      subtitle: 'Special stage for audit project',
    },
    [ProjectStage.MARKETING_PLAN]: {
      title: stageTitle[ProjectStage.MARKETING_PLAN],
      subtitle: 'The expert has to Upload the marketing plan for GS approval. The client should be scheduling the MPM',
    },
    [ProjectStage.LAUNCH]: {
      title: stageTitle[ProjectStage.LAUNCH],
      subtitle: 'The client has approved the Marketing plan (if needed), and is now waiting to launch the project',
    },
    [ProjectStage.SETUP]: {
      title: stageTitle[ProjectStage.SETUP],
      subtitle: 'The client has launched the project, and is now sharing their advertising accounts',
    },
    [ProjectStage.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION]: {
      title: stageTitle[ProjectStage.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION],
      subtitle: `The expert should work on the project's deliverables`,
    },
    [ProjectStage.MONITORING]: {
      title: stageTitle[ProjectStage.MONITORING],
      subtitle: 'The project is live :)',
    },
    [ProjectStage.STOPPED]: {
      title: stageTitle[ProjectStage.STOPPED],
      subtitle: 'The project is no longer running and cannot be resumed.',
    },
  };

  if (!map[stage]) {
    return { title: `ERROR! unrecognized project stage: ${stage}`, subtitle: 'Please contact QA team.' };
  }

  return map[stage];
};

export default getCurrentProjectStageAction;
