import { useState } from 'react';
import { FundAccount, ProjectCycle, ProjectCyclePhase, ProjectLifeCycleStatus } from '@mayple/types';

import { ProjectServicesAndBillingTabProps, UseProjectServicesAndBillingTabOutput } from './types';

const useProjectServicesAndBillingTab = (
  props: ProjectServicesAndBillingTabProps,
): UseProjectServicesAndBillingTabOutput => {
  const { project, cycles } = props;
  const [currentCycleFundAccount, setCurrentCycleFundAccount] = useState<FundAccount | undefined>();
  const { projectLifeCycleStatus, currentCycle = {} as ProjectCycle } = project;

  const isLive = projectLifeCycleStatus === ProjectLifeCycleStatus.LIVE;
  const isOnboarding = projectLifeCycleStatus === ProjectLifeCycleStatus.ONBOARDING;
  const elapsedCycles = cycles.filter(({ phase }) => phase === ProjectCyclePhase.ELAPSED);
  const showMonthlyCycles = Boolean(
    currentCycle &&
      currentCycle?.phase !== ProjectCyclePhase.CANCELED &&
      currentCycle?.phase !== ProjectCyclePhase.ELAPSED &&
      currentCycle?.phase !== ProjectCyclePhase.FINALIZED,
  );

  return {
    cycles,
    isLive,
    isOnboarding,
    showMonthlyCycles,
    elapsedCycles,
    currentCycleFundAccount,
    setCurrentCycleFundAccount,
  };
};

export default useProjectServicesAndBillingTab;
