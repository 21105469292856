import { useQuery } from '@apollo/react-hooks';
import { TestMarketerMatchesQuery } from 'growl-graphql/dist/queries/TestMarketerMatchesQuery';

import { MarketerMatchesProps, MatchedMarketersResultsData } from '../../types';

const useMatchedMarketersResults = (props: MarketerMatchesProps): MatchedMarketersResultsData => {
  const { testMarketerMatchesInput } = props;

  const {
    loading,
    error,
    data: dataTestMarketerMatches,
  } = useQuery(TestMarketerMatchesQuery.query, {
    variables: {
      testMarketerMatchesInput,
    },
    fetchPolicy: 'network-only',
  });

  const testMarketerMatches = dataTestMarketerMatches?.testMarketerMatches;

  return {
    testMarketerMatches,
    loading,
    error,
  };
};

export default useMatchedMarketersResults;
