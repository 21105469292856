import {
  Country,
  Marketer,
  MarketerCapabilities,
  MarketerProfile,
  MarketerType,
  MatchingSettings,
  ProjectLifeCycleStatus,
} from '@mayple/types';

import {
  countMarketerAssignedProjects,
  getMergedIndustryValuesFromArray,
  getSkillsUsedListFromSuccessStories,
} from '../../logic';

import {
  AD_SPEND_LABELS_LOOKUP,
  CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP,
  COUNTRIES_LABELS_LOOKUP,
  CURRENT_WORK_STATUS_LABELS_LOOKUP,
  INDUSTRIES_LABELS_LOOKUP,
  LANGUAGES_LABELS_LOOKUP,
  MARKETER_TYPE_LABELS_LOOKUP,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  PRODUCT_COST_RANGE_LABELS_LOOKUP,
  PROJECT_SPECIAL_TYPE_LABELS_LOOKUP,
  TARGET_KPI_LABELS_LOOKUP,
} from '../../../../../../../fe_common/client/app/enums';
import {
  formatFloatCurrency,
  toNiceCountriesList,
  toNiceEnumsList,
} from '../../../../../../../fe_common/client/services/utils';
import {
  CountriesLabelsLookup,
  LabelValueProps,
  MarketerTypeLabelsLookup,
} from '../../../../../../../fe_common/client/app/types';

const getMarketerDetails = (marketer: Marketer): Array<LabelValueProps> => {
  const { isVetted, finishedInitialization, marketerCapabilities, marketerProfile, matchingSettings, participants } =
    marketer;

  const {
    countryExpertise,
    industryExpertise,
    languageExpertise,
    marketingSkills,
    monthlyBudgetRangeAmount,
    productBusinessModelExpertise,
    targetKPIExpertise,
    clientStageExpertise,
    productCostRange,
  } = (marketerCapabilities || {}) as MarketerCapabilities;

  const industryExpertiseMerged = getMergedIndustryValuesFromArray(industryExpertise);

  const {
    marketerType,
    successStories,
    numberOfEmployees,
    currentWorkStatus: currentWorkStatusOrig,
  } = (marketerProfile || {}) as MarketerProfile;

  const successStoriesIndustries = successStories.map((successStory) => successStory.industry);
  const successStoriesIndustriesMerged = getMergedIndustryValuesFromArray(successStoriesIndustries);
  const successStoriesSkillsUsed = getSkillsUsedListFromSuccessStories(successStories);

  const totalProjectsAssignedCount = countMarketerAssignedProjects(participants || []);
  const liveProjectsAssignedCount = countMarketerAssignedProjects(participants || [], ProjectLifeCycleStatus.LIVE);

  const currentWorkStatus = currentWorkStatusOrig != null ? [currentWorkStatusOrig] : [];

  const { availableForProjects, minimumProjectFeeAmount, enableMatching, matchableForProjectSpecialTypes } =
    (matchingSettings || {}) as MatchingSettings;

  const marketingSkillsValues = marketingSkills.map(({ skillType }) => skillType);

  const marketerLocation: Country = marketerProfile?.contactDetails?.postalAddress?.country as Country;

  const marketerDetails = [
    { label: 'Marketer Type', value: (MARKETER_TYPE_LABELS_LOOKUP as MarketerTypeLabelsLookup)[marketerType] || 'N/A' },
    { label: 'Is Vetted', value: isVetted },
    { label: 'Finished Initialization', value: finishedInitialization },
    { label: 'Available For Projects', value: availableForProjects },
    { label: 'Matching enabled', value: enableMatching },
    {
      label: 'Project Type',
      value: toNiceEnumsList(matchableForProjectSpecialTypes, PROJECT_SPECIAL_TYPE_LABELS_LOOKUP),
    },
    {
      label: 'Business Model Expertise',
      value: toNiceEnumsList(productBusinessModelExpertise, PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP),
    },
    {
      label: 'Marketer Location',
      value: (COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[marketerLocation] || 'N/A',
    },
    { label: 'Country Expertise', value: toNiceCountriesList(countryExpertise) },
    { label: 'Industry Expertise', value: toNiceEnumsList(industryExpertiseMerged, INDUSTRIES_LABELS_LOOKUP) },
    { label: 'Language Expertise', value: toNiceEnumsList(languageExpertise, LANGUAGES_LABELS_LOOKUP) },
    {
      label: 'Marketing Expertise',
      value: toNiceEnumsList(marketingSkillsValues, MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS),
    },
    { label: 'Target KPI Expertise', value: toNiceEnumsList(targetKPIExpertise, TARGET_KPI_LABELS_LOOKUP) },
    { label: 'Monthly Budget Range Amount', value: toNiceEnumsList(monthlyBudgetRangeAmount, AD_SPEND_LABELS_LOOKUP) },
    { label: 'Minimum Project Fee Amount', value: formatFloatCurrency(minimumProjectFeeAmount) },
    {
      label: 'Success stories industries ',
      value: toNiceEnumsList(successStoriesIndustriesMerged, INDUSTRIES_LABELS_LOOKUP),
    },
    {
      label: 'Success stories skills used',
      value: toNiceEnumsList(successStoriesSkillsUsed, MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS),
    },
    {
      label: 'Product costs range',
      value: toNiceEnumsList(productCostRange, PRODUCT_COST_RANGE_LABELS_LOOKUP),
    },
    {
      label: 'Client stage expertise',
      value: toNiceEnumsList(clientStageExpertise, CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP),
    },
    { label: 'Number of Total projects assigned', value: totalProjectsAssignedCount },
    { label: 'Number of LIVE projects assigned', value: liveProjectsAssignedCount },
  ];

  if (marketerType === MarketerType.FREELANCER) {
    marketerDetails.push({
      label: "What's your current status?",
      value: toNiceEnumsList(currentWorkStatus, CURRENT_WORK_STATUS_LABELS_LOOKUP),
    });
  }

  if (marketerType === MarketerType.DIGITAL_AGENCY) {
    marketerDetails.push({
      label: 'How many people are you?',
      value: numberOfEmployees || 'N/A',
    });
  }

  return marketerDetails;
};

export default getMarketerDetails;
