import React, { useMemo } from 'react';
import moment from 'moment';
import keyBy from 'lodash/keyBy';
import { AssessmentResponse, AssessmentResponseState, AssessmentTerm } from '@mayple/types';
import { useTranslation } from 'react-i18next';

import { Card, TextDisplay } from '../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';

import useStyles from './style';

type AssessmentCardProps = {
  assessmentResponse: AssessmentResponse;
};

type QuestionWithAnswer = AssessmentTerm & { value: any };

const AssessmentCard: React.FC<AssessmentCardProps> = (props) => {
  const classes = useStyles(props);

  const { assessmentResponse } = props;

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'ProjectDetailsPage.AssessmentCard' });

  const { id, name, created, answers, state, survey } = assessmentResponse;

  const key = `${name}:${id}}`;
  const questions = survey?.questions;
  const answersHash = useMemo(() => keyBy(answers, 'termUniqueCode'), [answers]);

  const questionsWithAnswers: QuestionWithAnswer[] = useMemo(
    () =>
      questions?.map((question: AssessmentTerm) => ({
        ...question,
        value: answersHash?.[question.termUniqueCode]?.value,
      })),
    [answersHash, questions],
  );

  return (
    <Card key={`marketer-assessment-${survey?.surveyUniqueCode}`} elevation={0} square classes={{ root: classes.root }}>
      <Card.Header title={t(survey?.surveyUniqueCode)} />
      <Card.Content classes={{ root: classes.cardContent }}>
        <div>
          <TextDisplay variant="subtitle1" className={classes.assessmentMonth}>
            {moment(created).format('MMMM YYYY')}
          </TextDisplay>
          <TextDisplay className={classes.assessmentDate}>{moment(created).calendar()}</TextDisplay>
        </div>
        <div className={classes.assessmentVotes}>
          {state !== AssessmentResponseState.ASSESSMENT_ANSWERED && (
            <CalloutMessage type="warning" message={t(state)} margin />
          )}
          {state === AssessmentResponseState.ASSESSMENT_ANSWERED &&
            !!questionsWithAnswers.length &&
            questionsWithAnswers.map(({ termUniqueCode, standardLabel, value }) => (
              <div key={`vote-${key}-${termUniqueCode}`} className={classes.vote}>
                <TextDisplay>{standardLabel}</TextDisplay>
                <TextDisplay bold>{`${value}`}</TextDisplay>
              </div>
            ))}
        </div>
      </Card.Content>
    </Card>
  );
};

export default AssessmentCard;
