import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

import { CheckboxProps } from './types';

import useStyles from './style';

const Checkbox: FC<CheckboxProps> = (props) => {
  const classes = useStyles(props);

  const { name, checked = false, onChange, error, inputLabel = '', helperText, ...rest } = props;

  const onChangeHandler = (_event: ChangeEvent<HTMLInputElement>, newChecked: boolean): void => {
    onChange?.(newChecked);
  };

  return (
    <FormControl classes={{ root: classes.root }}>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            checked={checked}
            onChange={onChangeHandler}
            className={classNames({ [classes.error]: error })}
            {...rest}
          />
        }
        label={inputLabel}
      />
      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Checkbox;
