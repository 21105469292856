import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 0,
      paddingTop: 0,
      marginTop: 0,
    },
    deleteIcon: {
      color: colors.red,
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      fontSize: 32,
    },
  }),
);
export default useStyles;
