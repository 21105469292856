import React from 'react';
import { RefreshSpinner } from '../../../../../../fe_common/client/components/atoms';
import useStyles from './style';

interface LoadingSpinnerProps {
  loading: boolean;
}

const LoadingSpinnerNonBlocking: React.FC<LoadingSpinnerProps> = (props) => {
  const classes = useStyles();
  const { loading } = props;
  if (!loading) {
    return null;
  }
  return (
    <div className={classes.nonBlockingSpinner}>
      <RefreshSpinner />
    </div>
  );
};

export default LoadingSpinnerNonBlocking;
