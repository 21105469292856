import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { colors } from '../../../app/theme';
import { TextDisplay } from '../../display';
import { Button } from '../../inputs';
import { ButtonProps } from '../../inputs/Button';

const useStyles = makeStyles({
  root: {
    minWidth: 120,
    transition: 'all 400ms ease-out',
  },
  copied: {
    borderRadius: 2,
    background: `${colors.primary} !important`,
    color: colors.white,
    transition: 'all 400ms ease-out',
    cursor: 'default',
  },
  error: {
    color: colors.red,
  },
});

export interface CopyHtmlElementToClipboardButtonProps extends ButtonProps {
  querySelector: string;
}

const CopyHtmlElementToClipboardButton: FC<CopyHtmlElementToClipboardButtonProps> = (props) => {
  const { label = 'Copy', querySelector, ...rest } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState('');

  const classes = useStyles();
  const copyToClipboard = useCallback(() => {
    if (isCopied) {
      return;
    }

    const copyElement = window.document.querySelector(querySelector);

    if (copyElement) {
      try {
        const range = new Range();
        range.selectNode(copyElement);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);
        window.document.execCommand('copy');
        window.getSelection()?.removeAllRanges();
        setIsCopied(true);
      } catch (e) {
        setIsCopied(false);
        setError('Could not copy data.');
      }
    } else {
      setError('Could not find data to copy.');
    }
  }, [querySelector, isCopied, setIsCopied]);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setIsCopied(false);
      window.clearTimeout(timeoutId);
    }, 3000);

    return () => {
      // Clean up the subscription
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [isCopied, setIsCopied]);

  return (
    <div>
      <Button
        label={isCopied ? 'Copied' : label}
        onClick={copyToClipboard}
        variant="outlined"
        color={!isCopied ? 'primary' : 'default'}
        disableRipple={isCopied}
        classes={{ root: classNames(classes.root, { [classes.copied]: isCopied }) }}
        size="small"
        {...rest}
      />
      {error !== '' && (
        <TextDisplay inline variant="caption" className={classes.error}>
          {error}
        </TextDisplay>
      )}
    </div>
  );
};

export default CopyHtmlElementToClipboardButton;
