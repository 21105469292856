import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { AddUserToMarketerMutation } from 'growl-graphql/dist/mutations/AddUserToMarketerMutation';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketerUsersList from './MarketerUsersList';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { setEntityOperationResponseNotification } from '../../../../../fe_common/client/services/notification';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { AddUserDialog } from '../../../../components/cpanel/components/atoms/AddUserDialog';

const styles = () => ({
  root: {},
});

const MarketerUsers = (props) => {
  const { marketer, addUserToMarketerMutation, readOnly, classes } = props;
  const { id: marketerId, name: marketerName, users } = marketer;
  const hasUsers = (users ?? []).length > 0;

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState(0);

  const openAddUsersDialog = () => {
    setShowAddUserDialog(true);
  };

  const closeAddUsersDialog = () => {
    setShowAddUserDialog(false);
  };

  const handleAddUser = async () => {
    try {
      const addVars = {
        marketerId,
        userId,
      };
      const result = await addUserToMarketerMutation.mutate(addVars);
      setEntityOperationResponseNotification(result);
      setUserEmail('');
      setUserId(0);
      closeAddUsersDialog();
    } catch (err) {
      alert(`Error has encountered in addUserToMarketerMutation - ${err.msg}`);
    }
  };

  const onRemoveUser = () => {
    setUserId(0);
    setUserEmail('');
  };

  return (
    <div className={classes.root}>
      {!hasUsers && <CalloutMessage type="info" message="No users listed." margin />}

      {hasUsers && (
        <MarketerUsersList users={users} marketerId={marketerId} readOnly={readOnly} onRemoveUser={onRemoveUser} />
      )}

      {!readOnly && (
        <Button label="Add User to Account" onClick={openAddUsersDialog} color="primary" variant="outlined" />
      )}

      {!readOnly && showAddUserDialog && (
        <AddUserDialog
          userName={marketerName}
          open={showAddUserDialog}
          onChange={(event) => {
            setUserEmail(event.target.value);
          }}
          emailAddress={userEmail}
          userIdSetter={setUserId}
          handleAddUser={handleAddUser}
          handleClose={closeAddUsersDialog}
        />
      )}
    </div>
  );
};

MarketerUsers.propTypes = {
  marketer: PropTypes.object.isRequired,
  addUserToMarketerMutation: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

MarketerUsers.defaultProps = {
  readOnly: false,
};

export default compose(withStyles(styles), withMutation(AddUserToMarketerMutation))(MarketerUsers);
