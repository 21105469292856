import React, { FC } from 'react';
import classNames from 'classnames';

import { TextDisplay } from '../../index';

import useStyles from './style';

interface FilterSectionProps {
  title: string;
  className?: string;
  classes?: Record<string, string>;
}

const FilterSection: FC<FilterSectionProps> = (props) => {
  const classes = useStyles(props);
  const { title, className, children } = props;

  return (
    <div className={classNames(className, classes.root)}>
      <TextDisplay variant="overline" className={classes.title}>
        {title}
      </TextDisplay>
      {children}
    </div>
  );
};

export default FilterSection;
