import React, { useCallback } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { Deliverable, DeliverablesFulfillmentBehavior, MarketingServiceType, MediaType } from '@mayple/types';
import cloneDeep from 'lodash/cloneDeep';

import { generateUuid, tryParseInt } from '../../../../../fe_common/client/services/utils';

const UNIT_COST_DEFAULT_VALUE = 1000;
const UNIT_COST_MIN_VALUE = 0;
const UNIT_COST_MAX_VALUE = 1000000;
const UNIT_AMOUNT_DEFAULT_VALUE = 1;
const UNIT_AMOUNT_MIN_VALUE = 0;
const UNIT_AMOUNT_MAX_VALUE = 1000;

const useDeliverables = (input: WrappedFieldInputProps): Record<string, any> => {
  const onCreateDeliverable = useCallback(() => {
    const { value: deliverables, onChange } = input;
    const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);

    // console.log('useDeliverables(deliverables=', deliverables, ')');

    const newUuid = generateUuid();
    newDeliverables[newUuid] = {
      uuid: newUuid,
      serviceType: MarketingServiceType.AFFILIATE_MARKETING,
      unitType: '',
      unitDescription: {
        contents: '',
        mediaType: MediaType.TEXT_HTML,
      },
      unitCost: UNIT_COST_DEFAULT_VALUE,
      unitAmount: UNIT_AMOUNT_DEFAULT_VALUE,
      discountPercentage: 0,
      fulfillmentBehavior: DeliverablesFulfillmentBehavior.MOVE_REMAINING_TO_NEXT_CYCLE,
    };

    onChange(newDeliverables);
  }, [input]);

  const onDeliverableServiceTypeChange = useCallback(
    (newValue: string, uuid: string) => {
      // console.log('onDeliverableServiceTypeChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const value = newValue as MarketingServiceType;

      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);
      newDeliverables[uuid].serviceType = value;
      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableUnitTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
      // console.log('onDeliverableUnitTypeChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const { value } = e.target;

      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);
      newDeliverables[uuid].unitType = value;
      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableUnitDescriptionChange = useCallback(
    (newValue: string, uuid: string) => {
      // console.log('onDeliverableUnitDescriptionChange(newValue=', newValue, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;

      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);
      newDeliverables[uuid].unitDescription.contents = newValue;
      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableUnitCostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
      // console.log('onDeliverableUnitCostChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const { value: rawValue } = e.target;
      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);

      const value = tryParseInt(rawValue, UNIT_COST_DEFAULT_VALUE);
      if (value > UNIT_COST_MAX_VALUE) {
        newDeliverables[uuid].unitCost = UNIT_COST_MAX_VALUE;
      } else if (value < UNIT_COST_MIN_VALUE) {
        newDeliverables[uuid].unitCost = UNIT_COST_MIN_VALUE;
      } else {
        newDeliverables[uuid].unitCost = value;
      }

      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableUnitAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
      // console.log('onDeliverableUnitAmountChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const { value: rawValue } = e.target;
      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);

      const value = tryParseInt(rawValue, UNIT_AMOUNT_DEFAULT_VALUE);
      if (value > UNIT_AMOUNT_MAX_VALUE) {
        newDeliverables[uuid].unitAmount = UNIT_AMOUNT_MAX_VALUE;
      } else if (value < UNIT_AMOUNT_MIN_VALUE) {
        newDeliverables[uuid].unitAmount = UNIT_AMOUNT_MIN_VALUE;
      } else {
        newDeliverables[uuid].unitAmount = value;
      }

      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableDiscountPercentageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
      // console.log('onDeliverableDiscountPercentageChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const { value: rawValue } = e.target;
      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);

      const value = tryParseInt(rawValue, 0);
      if (value > 100) {
        newDeliverables[uuid].discountPercentage = 100;
      } else if (value < 0) {
        newDeliverables[uuid].discountPercentage = 0;
      } else {
        newDeliverables[uuid].discountPercentage = value;
      }

      onChange(newDeliverables);
    },
    [input],
  );

  const onDeliverableFulfillmentBehaviorChange = useCallback(
    (newValue: string, uuid: string) => {
      // console.log('onDeliverableFulfillmentBehaviorChange(e=', e, ', uuid=', uuid, ')');

      const { value: deliverables, onChange } = input;
      const value = newValue as DeliverablesFulfillmentBehavior;

      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);
      newDeliverables[uuid].fulfillmentBehavior = value;
      onChange(newDeliverables);
    },
    [input],
  );

  const onDeleteDeliverable = useCallback(
    (uuid: string) => {
      const { value: deliverables, onChange } = input;
      const newDeliverables: Record<string, Deliverable> = cloneDeep(deliverables);

      delete newDeliverables[uuid];

      onChange(newDeliverables);
    },
    [input],
  );

  return {
    onCreateDeliverable,
    onDeliverableServiceTypeChange,
    onDeliverableUnitTypeChange,
    onDeliverableUnitDescriptionChange,
    onDeliverableUnitCostChange,
    onDeliverableUnitAmountChange,
    onDeliverableDiscountPercentageChange,
    onDeliverableFulfillmentBehaviorChange,
    onDeleteDeliverable,
  };
};

/**
 *
 * @param deliverable
 // * @param formValues
 * @returns {string|boolean}
 */
export function validateDeliverable(deliverable: Deliverable): string | boolean {
  // console.log('validateDeliverable(deliverable=', deliverable, ')');

  if (deliverable === null || deliverable === undefined) {
    return '';
  }

  // eslint-disable-next-line
  const { serviceType, unitType, unitDescription, unitCost, unitAmount, discountPercentage, fulfillmentBehavior } =
    deliverable;

  if (!serviceType) {
    return 'Please select a deliverable service type';
  }

  if (!unitType || unitType.trim() === '') {
    return 'Please input a deliverable unit type';
  }
  if (!unitDescription.contents || unitDescription.contents === '') {
    // Do nothing... never mind.
    // return 'Please input a deliverable unit description';
  }

  if (unitCost !== null && (unitCost < UNIT_COST_MIN_VALUE || unitCost > UNIT_COST_MAX_VALUE)) {
    return 'Invalid deliverable unit cost.';
  }

  if (unitAmount !== null && (unitAmount < UNIT_AMOUNT_MIN_VALUE || unitAmount > UNIT_AMOUNT_MAX_VALUE)) {
    return 'Invalid deliverable unit amount.';
  }

  if (discountPercentage !== null && (discountPercentage < 0 || discountPercentage > 100)) {
    return 'Invalid deliverable discount percentage.';
  }

  // @ts-ignore
  if (!fulfillmentBehavior || fulfillmentBehavior === '') {
    return 'Please select a deliverable fulfillment behavior.';
  }

  return false;
}

/**
 * Validate the entire input
 * @param value
 * @returns {boolean}
 */
export const validateDeliverables = (value: Record<string, Deliverable>): Record<string, string> | boolean => {
  // console.log('validateDeliverables(value=', value, ')');

  const errors: Record<string, string> = {};

  if (!value || Object.values(value).length === 0) {
    return false;
  }

  Object.values(value).forEach((deliverable) => {
    const currentError = validateDeliverable(deliverable);

    // Not an error?
    if (typeof currentError === 'string') {
      errors[deliverable.uuid] = currentError;
    }
  });

  return Object.keys(errors).length === 0 ? false : errors;
};

export default useDeliverables;
