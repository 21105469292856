import isArray from 'lodash/isArray';

import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import isFloat from 'validator/lib/isFloat';
import isURL from 'validator/lib/isURL';
import matches from 'validator/lib/matches';
import equals from 'validator/lib/equals';

import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';

const isEmpty = (value) => value === undefined || value === null || value === '';

const join = (rules) => (value, data) => rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export const validateTrue =
  (message = 'Required') =>
  (value) =>
    value !== true ? message : undefined;

export const validateEmail = (value) => !isEmpty(value) && !isEmail(value) && 'Invalid email address';

export const validateUrl = (value) =>
  !isEmpty(value) && !isURL(value, { require_tld: true }) && 'Invalid URL. Correct format: https://www.website.com';

export const validateNoMayple = (value, errorMessage = 'Value cannot contain Mayple') => {
  const regex = /mayple/i;
  const containsMayple = regex.test(value);
  return !isEmpty(value) && containsMayple && errorMessage;
};

export const validateLinkedInUrl = (value) =>
  !isEmpty(value) &&
  !(
    isURL(value, { protocols: ['http', 'https'], host_whitelist: [/^.*linkedin\.com$/i] }) &&
    (matches(value, /linkedin\.com\/in\/.+/i) || matches(value, /linkedin\.com\/company\/.+/i))
  ) &&
  'Invalid LinkedIn URL. It should look like this - https://linkedin.com/in/YourName';

export const validateRequired = (value) => {
  if (isEmpty(value)) {
    return 'Required';
  }

  return undefined;
};

export const validateRequiredWithMessage =
  (customMsg = 'Required') =>
  (value) => {
    if (isEmpty(value)) {
      return customMsg;
    }

    return undefined;
  };

export const validateNonEmptyArray = (customMsg) => (array) =>
  !(array && isArray(array) && array.length > 0) && (customMsg || 'Required');

export const validateMinLength = (min) => (value) => {
  if (!isEmpty(value) && value.length < min) {
    return `Min ${min} characters required`;
  }

  return undefined;
};

export const validateMaxLength = (max) => (value) =>
  !isEmpty(value) && value.length > max && `Must be no more than ${max} characters`;

export const validateInteger = (value) => !isInt(`${value}`) && 'Must be an integer';

export const validateIntegerBetween = (min, max) => (value) =>
  !(isInt(`${value}`) && value >= min && value <= max) && `Must be between ${min} and ${max}`;

export const validateIntegerGreaterThan = (min) => (value) =>
  !(isInt(`${value}`) && value > min) && `Must be greater than ${min}`;

export const validateIntegerLessThan = (max) => (value) =>
  !(isInt(`${value}`) && value < max) && `Must be less than ${max}`;

export const validateIntegerGreaterThanOrEqualTo = (min) => (value) =>
  !((isInt(`${value}`) || isFloat(`${value}`)) && value >= min) && `Must be greater than or equal to ${min}`;

export const validateIntegerLessThanOrEqualTo = (max) => (value) =>
  !((isInt(`${value}`) || isFloat(`${value}`)) && value <= max) && `Must be less than or equal to ${max}`;

export const validateFloatBetween =
  (min = 0, max = 1000000) =>
  (value) => {
    if (isEmpty(value)) {
      return false;
    }
    return !(isFloat(`${value}`) && value >= min && value <= max) && `Must be between ${min} and ${max}`;
  };

// export const validateFloat = (value) => {
//   return !isFloat(`${value}`) &&
//          'Must be a float';
// };

// export const validateOneOf = (values) => {
//   return (value) => {
//     return !isIn(value, values) &&
//            `Must be one of: ${values.join(', ')}`;
//   };
// };

// export const validateMatch = (field) => {
//   return (value, data) => {
//     return data && value !== data[field] &&
//            'Must match';
//   };
// };

export const createValidator =
  (rules) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };

export const validatePhoneNumber = (value) => {
  if (!value) {
    return false;
  }
  return isValidPhoneNumber(value) ? undefined : 'Invalid phone number';
};

export const validateRegex =
  (regex, errorMessage = 'expression is invalid') =>
  (value) =>
    value && !regex.test(value) ? `Invalid: ${errorMessage}` : undefined;

export const validateAlphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const validateNumeric = (value) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);

export const validateNonEmptyObject = (obj) => {
  if (!obj) {
    return 'Required';
  }

  return !Object.keys(obj).length ? 'Required' : undefined;
};

// will return true if empty or all key values are falsy
export const validateNonFalsyObject = (obj) => {
  if (!obj) {
    return 'Required';
  }

  return (Object.values(obj) || []).some((value) => value) ? undefined : 'Required';
};

export const validateMaxedOutSelection = (maxSelections) => (obj) => {
  const optionsSelected = (Object.values(obj || {}) || []).filter((val) => !!val).length;
  const maxedOut = !!maxSelections && optionsSelected > maxSelections;
  return maxedOut ? `Can't select more than ${maxSelections} options` : undefined;
};

export const validateRadioCardsWithSlider = (key) => (obj) => {
  const values = Object.values(obj);
  const invalid = true;
  for (const value of values) {
    if (value[key]) {
      return false;
    }
  }
  // console.log('invalid?', invalid);
  return invalid ? 'Required' : false;
};

export const validateEquality = (value, comparison) =>
  !isEmpty(value) && !isEmpty(comparison) && equals(value, comparison)
    ? undefined
    : 'Google ADS manager accounts ID do not match';

export const validateNumericRangeRequired = (value) => {
  if (isEmpty(value)) {
    return 'Required';
  }
  if (
    typeof value === 'object' &&
    (Object.prototype.hasOwnProperty.call(value, 'minimum') || Object.prototype.hasOwnProperty.call(value, 'maximum'))
  ) {
    if (isEmpty(value.minimum)) {
      return 'Minimum value is required.';
    }

    if (isEmpty(value.maximum)) {
      return 'Maximum value is required.';
    }
  }

  return undefined;
};

export const validateCurrencyRequired = (value) => {
  if (isEmpty(value)) {
    return 'Please select currency';
  }

  if (typeof value === 'object') {
    if (Object.prototype.hasOwnProperty.call(value, 'currency')) {
      return isEmpty(value.currency) ? 'Please select currency' : undefined;
    } else {
      return 'Please select currency';
    }
  }
  return undefined;
};

export const validateAmountRequired = (value) => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (typeof value === 'object') {
    if (Object.prototype.hasOwnProperty.call(value, 'value')) {
      return isEmpty(value.value) ? 'Required' : undefined;
    } else {
      return 'Required';
    }
  }
  return undefined;
};

export const validateNumericRangeMinimumMaximumValues = (minimumValue, maximumValue) => (value) => {
  if (isEmpty(value)) {
    return undefined;
  }

  let minimum;
  let maximum;

  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'minimum') && !isEmpty(value.minimum)) {
    ({ minimum } = value);
    if (minimum < +minimumValue) {
      return `Amount can't be smaller than ${minimumValue}`;
    }
  }

  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'maximum') && !isEmpty(value.maximum)) {
    ({ maximum } = value);
    if (maximum > +maximumValue) {
      return `Amount can't be greater than ${maximumValue}`;
    }
  }

  const hasMinimum = typeof minimum === 'number';
  const hasMaximum = typeof minimum === 'number';

  if (hasMinimum && hasMaximum) {
    return +minimum > +maximum ? "Minimum value can't be greater than maximum value" : undefined;
  }
  return undefined;
};

export const validateCurrencyFieldMaximumValue = (maximumValue) => (value) => {
  if (isEmpty(value)) {
    return undefined;
  }
  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'value') && !isEmpty(value.value)) {
    const { value: amount } = value;
    return +amount > +maximumValue ? `Amount can't be greater than ${maximumValue}` : undefined;
  }
  return undefined;
};

export const validateCurrencyFieldMinimumValue = (minimumValue) => (value) => {
  if (isEmpty(value)) {
    return undefined;
  }
  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'value') && !isEmpty(value.value)) {
    const { value: amount } = value;
    return +amount < +minimumValue ? `Amount can't be smaller than ${minimumValue}` : undefined;
  }
  return undefined;
};

export const validateMinimumSelectedValues = (values, minimumSelectedValues) => {
  if (Array.isArray(values) && values.length < minimumSelectedValues) {
    return `Cannot select less than ${minimumSelectedValues} options.`;
  }
  return undefined;
};

export const validateMaximumSelectedValues = (values, maximumSelectedValues) => {
  if (Array.isArray(values) && values.length > maximumSelectedValues) {
    return `Cannot select more than ${maximumSelectedValues} options.`;
  }
  return undefined;
};

export const validateMinimumSelectedValuesWithMessage = (minimumSelectedValues, message) => (values) => {
  if (Array.isArray(values) && values.length < minimumSelectedValues) {
    return message || `Cannot select less than ${minimumSelectedValues} options.`;
  }
  return undefined;
};

export const validateMaximumSelectedValuesWithMessage = (maximumSelectedValues, message) => (values) => {
  if (Array.isArray(values) && values.length > maximumSelectedValues) {
    return message || `Cannot select more than ${maximumSelectedValues} options.`;
  }
  return undefined;
};

export const validateNumberIsGreaterThanOrEqualTo = (minimumValue) => (value) => {
  if (!value && value !== 0) {
    return undefined;
  }

  return +value < +minimumValue ? `Must be greater than or equal to  ${minimumValue}` : undefined;
};

export const validateNumberIsLessThanOrEqualTo = (maximumValue) => (value) => {
  if (!value && value !== 0) {
    return undefined;
  }
  return +value > +maximumValue ? `Must be less than or equal to  ${maximumValue}` : undefined;
};

export const validateMinDate =
  (minDate, errorMessage = `Date should be later than ${minDate}`) =>
  (value) => {
    if (moment(minDate).diff(moment(value), 'minutes') > 0) {
      return errorMessage;
    }

    return undefined;
  };

export const validateMaxDate =
  (maxDate, errorMessage = `Date should be earlier than ${maxDate}`) =>
  (value) => {
    if (moment(maxDate).diff(moment(value), 'minutes') < 0) {
      return errorMessage;
    }

    return undefined;
  };

export const validateRequiredDurationInMinutes = (requiredDurationInMinutes) => (value) => {
  if (
    value &&
    Array.isArray(value) &&
    value?.length > 0 &&
    value.some(({ end, start }) => moment.utc(end).diff(moment.utc(start), 'minutes') < requiredDurationInMinutes)
  ) {
    return `Minimum required meeting duration is ${requiredDurationInMinutes} minutes`;
  }
  return undefined;
};

export const validateUniqueStringInArray = (arr) => (value) =>
  value && (arr ?? []).includes(value) ? `${value} already exist on the list` : undefined;
