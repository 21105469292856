import React, { FC, useMemo } from 'react';
import { Company } from '@mayple/types';

import { getCompanyProfile, getCompanyProfileProperties } from './logic';

import PropertiesList from '../../../../../fe_common/client/components/display/PropertiesList';

import useStyles from './style';

interface CompanyProfileDetailsProps {
  company: Company;
}

const CompanyProfileDetails: FC<CompanyProfileDetailsProps> = (props) => {
  const { company } = props;
  const classes = useStyles(props);

  const companyProfile = useMemo(() => getCompanyProfile(company), [company]);

  const companyProfileProperties = useMemo(() => getCompanyProfileProperties(classes), [classes]);

  return (
    <PropertiesList
      id="companyProfileDetails"
      data={companyProfile}
      propertiesMeta={companyProfileProperties}
      classes={{
        propertyLabel: classes.propertyLabel,
        propertyValue: classes.propertyValue,
      }}
    />
  );
};

export default CompanyProfileDetails;
