import React, { FC } from 'react';
import { Participant, Project, ProjectStage } from '@mayple/types';
import List from '@material-ui/core/List';

import MarketerListItem from '../MarketerListItem';
import { config } from '../../../../config';
import ShareHistoryButton from '../../../../components/cpanel/components/molecules/ShareHistoryButton';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

import useStyles from './style';
import SelectedMatches from './SelectedMatches';

interface HeaderProps {
  project: Project | null | undefined;
  readOnly?: boolean;
  refetchProject: () => void;
}

const Header: FC<HeaderProps> = (props) => {
  const classes = useStyles(props);
  const { project, readOnly, refetchProject } = props;
  const projectId = project?.id;
  const stage = project?.stage;
  const participants = project?.participants || [];
  const participant = participants[0] || ({} as Participant);
  const { marketerMatch, marketerId: participantMarketerId } = participant;
  const isMarketerAssigned = config.maamId !== participantMarketerId;
  const isMatchWaitingForCompanyApproval = stage === ProjectStage.MARKETER_APPROVAL;

  return (
    <div>
      {!isMarketerAssigned && !isMatchWaitingForCompanyApproval && (
        <TextDisplay variant="subtitle1" gutterBottom color="error">
          No expert assigned yet.
        </TextDisplay>
      )}
      {isMarketerAssigned && (
        <div>
          <TextDisplay variant="subtitle1" gutterBottom color="primary">
            Current marketer that is assigned to this project.
          </TextDisplay>
          <div className={classes.activeMarketer}>
            {participants.length === 0 && 'No Participant-Marketers are assigned to this project.'}
            {participant !== null && (
              <div key={`marketer-for-project-${participantMarketerId}`}>
                <List>
                  <MarketerListItem className="collection-item" marketer={marketerMatch} />
                  {!readOnly && <ShareHistoryButton projectId={projectId} marketerId={participantMarketerId} />}
                </List>
              </div>
            )}
          </div>
        </div>
      )}
      {isMatchWaitingForCompanyApproval && <SelectedMatches project={project} refetchProject={refetchProject} />}
    </div>
  );
};

export default Header;
