import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { AvailabilityDialogProps } from './types';
import AvailabilityRulesInitializer from '../../../AvailabilityRulesInitializer';

import { TextDisplay } from '../../../../../display';
import { useDeviceInfo } from '../../../../../../hooks';
import CalloutMessage from '../../../../../common/CalloutMessage';

import useStyles from './style';

const AvailabilityDialog: React.FC<AvailabilityDialogProps> = (props) => {
  const classes = useStyles(props);

  const { onCloseClick, isOpen, availabilityRulesProfiles, showImpersonationWarning = false } = props;
  const { isTablet, isMobile } = useDeviceInfo();

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth fullScreen={isMobile || isTablet}>
      <DialogTitle disableTypography>
        <TextDisplay variant="h5">What are your work hours?</TextDisplay>
        <TextDisplay variant="subtitle2" className={classes.subTitle}>
          Select when your clients can book meetings with you.
        </TextDisplay>
        <IconButton aria-label="close" onClick={onCloseClick} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        {showImpersonationWarning && (
          <CalloutMessage
            type="warning"
            title="You are in impersonation mode."
            message="Verify the timezone is set to the impersonated accout timezone."
            margin
          />
        )}
        <AvailabilityRulesInitializer
          availabilityRulesProfiles={availabilityRulesProfiles}
          onAvailabilityRuleSaved={onCloseClick}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AvailabilityDialog;
