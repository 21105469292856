import React, { FC } from 'react';
import classNames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getGradientColor } from '../../../../app/theme';

interface SelectionIndicatorProps {
  type: 'radio' | 'checkbox';
  checked: boolean;
  disabled?: boolean;
  color?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary';
  htmlColor?: string;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  disabled: {
    color: getGradientColor('gray', 20),
  },
}));

const SelectionIndicator: FC<SelectionIndicatorProps> = (props) => {
  const classes = useStyles(props);

  const { type, checked, disabled, color = 'inherit', htmlColor, className } = props;

  return (
    <SvgIcon
      color={color}
      htmlColor={htmlColor}
      className={classNames(className, {
        [classes.disabled]: disabled,
      })}
    >
      {type === 'radio' && checked && <RadioButtonCheckedIcon />}
      {type === 'radio' && !checked && <RadioButtonUncheckedIcon />}
      {type === 'checkbox' && checked && <CheckBoxIcon />}
      {type === 'checkbox' && !checked && <CheckBoxOutlineBlankIcon />}
    </SvgIcon>
  );
};

export default SelectionIndicator;
