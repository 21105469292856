import { getCrackleUrl } from '../app/utils';

import { URL_PARAMS } from '../../fe_common/client/app/consts';

const getAppLoginUrl = (emailAddress: string): string => {
  const crackleUrl = getCrackleUrl();
  const tokenUrl = new URL('/login', crackleUrl);
  tokenUrl.searchParams.append(URL_PARAMS.EMAIL, emailAddress);
  return tokenUrl.href;
};

export default getAppLoginUrl;
