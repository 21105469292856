/* eslint-disable class-methods-use-this */
import {
  MarketerFeePlanName,
  ProjectCyclePhase,
  PpcBillingModelPlans,
  PpcTieredPlanTier,
  ProjectCycle,
  Project,
  Company,
  PpcMeteredPlanMultiplier,
} from '@mayple/types';
import React from 'react';
import { Link } from 'react-router-dom';
import diff from 'variable-diff';
import moment from 'moment';
import startCase from 'lodash/startCase';

import { createPpcMeteredMultipliersLabels, createPpcTierLabels } from '../../../fe_common/client/logic/projectCycle';
import { PPCBillingPlanType } from '../../../fe_common/client/types/billingConfiguration';

export type BillingPlanPairs = {
  value: string;
  label: string;
  type: PPCBillingPlanType;
  tiers?: PpcTieredPlanTier[];
  multiplier?: number;
  multipliers?: Array<PpcMeteredPlanMultiplier>;
};

class ProjectBillingHelpers {
  createBillingPlanPairsFromSharedEnums(enums: PpcBillingModelPlans): BillingPlanPairs[] {
    try {
      if (!enums) {
        return [];
      }

      const pairs = [];

      const { fixedPlan, meteredPlans, tieredPlans } = enums;

      if (tieredPlans) {
        tieredPlans.reverse().forEach((tieredPlan) => {
          const { planName, tiers } = tieredPlan;
          pairs.push({ value: planName, label: startCase(planName), type: PPCBillingPlanType.TIERED, tiers });
        });
      }

      if (meteredPlans) {
        meteredPlans.reverse().forEach((meteredPlan) => {
          const { planName, multipliers } = meteredPlan;

          if (multipliers.length === 1) {
            multipliers.forEach((m) => {
              const { multiplier } = m;
              pairs.push({ value: planName, label: startCase(planName), type: PPCBillingPlanType.METERED, multiplier });
            });
          } else if (multipliers.length > 1) {
            pairs.push({ value: planName, label: startCase(planName), type: PPCBillingPlanType.METERED, multipliers });
          }
        });
      }

      if (fixedPlan) {
        pairs.push({ value: 'fixedPlan', label: 'Fixed plan', type: PPCBillingPlanType.FIXED });
      }

      return pairs;
    } catch (e) {
      return [];
    }
  }

  getBillingPlanHint(billingConfigurationPlanName: string, billingPlanPairs: BillingPlanPairs[]) {
    const selectedPlan = (billingPlanPairs || []).find(({ value }) => value === billingConfigurationPlanName);

    const planType = selectedPlan?.type;

    if (planType === PPCBillingPlanType.TIERED) {
      const tiers = selectedPlan?.tiers || [];
      const labels = createPpcTierLabels(tiers);

      return (
        <p>
          Setting {selectedPlan?.label} is as follows: <br />
          <ul>
            {labels.map(({ label, price }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`getBillingPlanHint-${price}-${index}`}>
                For {label} spend, the price is: {price}
              </li>
            ))}
          </ul>
        </p>
      );
    }

    if (planType === PPCBillingPlanType.FIXED) {
      return (
        <p>
          The {selectedPlan?.label} is as follows: <br />
          The PPC fee is a fixed value (&quot;Fixed PPC fee&quot;) that ranges from 0 to 1000000. At least the Minimum
          PPC fee is paid.
        </p>
      );
    }

    if (planType === PPCBillingPlanType.METERED && selectedPlan?.multiplier) {
      const multiplier = (selectedPlan?.multiplier || 0) * 100 || 'N/A';

      return (
        <p>
          The {selectedPlan?.label} is as follows: <br />
          The PPC fee is {multiplier}% of media spend (percentage can be overridden, &quot;Metered PPC Fee
          Multiplier&quot;), with a minimum of $750. <br />
          At least the Minimum PPC fee is paid.
        </p>
      );
    }

    if (planType === PPCBillingPlanType.METERED && selectedPlan?.multipliers) {
      const multipliers = selectedPlan?.multipliers || [];
      const labels = createPpcMeteredMultipliersLabels(multipliers);

      return (
        <p>
          The {selectedPlan?.label} is as follows: <br />
          <ul>
            {labels.map(({ label, multiplier }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`getBillingPlanHint-${multiplier}-${index}`}>
                For {label} spend, the percentage is: {multiplier}%
              </li>
            ))}
          </ul>
        </p>
      );
    }
    return <p>Please select a plan</p>;
  }

  getMarketerFeePlanHint(
    marketerFeeConfigurationPlanName: string,
    feePartPercentage: number,
    _minimumFeePart: number,
    marketerFixedFee: number,
  ): JSX.Element {
    switch (marketerFeeConfigurationPlanName) {
      case MarketerFeePlanName.percentage:
        return (
          <div>
            The <b>Percentage</b> Plan is as follows: <br />
            Marketer cut = {feePartPercentage || 0}% of cycle total cost. <br />
            At least the &quot;Minimum marketer cut&quot; will be paid to the marketer, and up to the &quot;Maximum
            marketer cut&quot;. <br />
            If the &quot;Fixed cut&quot; is set, then it is used instead of the percentage.
          </div>
        );
      case MarketerFeePlanName.fixedToMarketer:
        return (
          <div>
            Setting <b>Fixed to Marketer</b> Plan is as follows: <br />
            The Marketer will receive up to ${marketerFixedFee || 0} (&quot;Fixed cut&quot;) and the rest will go to
            Mayple.
          </div>
        );
      case MarketerFeePlanName.fixedToMayple:
        return (
          <div>
            Setting <b>Fixed to Mayple</b> Plan is as follows: <br />
            Mayple will receive up to ${marketerFixedFee || 0} (&quot;Fixed cut&quot;) and the rest will go to the
            marketer.
          </div>
        );
      default:
        return <div>Please select a plan</div>;
    }
  }

  // FOR TESTING ATM - DO NOT DELETE
  getBillingEditorPreview(formValues: Record<string, any>): JSX.Element {
    const iv = formValues.initialValues;
    const nv = JSON.parse(JSON.stringify(formValues));
    delete nv.initialValues;

    return <p>{JSON.stringify(diff(iv, nv).text, null, 2)}</p>;
  }

  getCycleGlossary(cycle: ProjectCycle | null | undefined, project: Project) {
    const { phase, id: cycleId, startDate } = cycle || ({} as ProjectCycle);
    const { id: projectId, name: projectName, company, companyId } = project || ({} as Project);
    const { name: companyName } = company || ({} as Company);

    const date = moment(startDate || new Date(), 'YYYY/MM/DD');
    const month = date.format('MMMM');
    const year = date.format('YYYY');

    const common = {
      header: `Cycle ${month} ${year} Configuration (ID: ${cycleId})`,
      context: (
        <span>
          <Link to={`/projects/${projectId}`}>Project {projectName}</Link> owned by{' '}
          <Link to={`/companies/${companyId}`}>{companyName}</Link>
        </span>
      ),
    };

    if (phase === ProjectCyclePhase.ELAPSED) {
      return {
        ...common,
        header: `${common.header} - Final billing review`,
        ppcDivisionHeader: 'PPC media spend details:',
        mediaDivisionLabel: 'Spend',
      };
    }

    return {
      ...common,
      ppcDivisionHeader: 'PPC services',
      mediaDivisionLabel: 'Budget',
    };
  }
}

const projectBillingHelpers = new ProjectBillingHelpers();

export default projectBillingHelpers;
