import React from 'react';
import { Project } from '@mayple/types';

import MarketerAssessmentDetails from '../../components/MarketerAssessmentDetails';
import ProjectAssessmentDetails from '../../components/ProjectAssessmentDetails';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

interface AssessmentTabProps {
  project: Project;
}

const AssessmentTab: React.FC<AssessmentTabProps> = (props) => {
  const { project } = props;
  const { id: projectId = 0 } = project || ({} as Project);
  const { enableNewAssessment } = useFeatureFlags();
  return (
    <div>
      {enableNewAssessment && <ProjectAssessmentDetails projectId={projectId} />}
      {!enableNewAssessment && <MarketerAssessmentDetails projectId={projectId} />}
    </div>
  );
};

export default AssessmentTab;
