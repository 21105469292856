import React, { FC, useContext } from 'react';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import { PackageContext } from './logic';
import PackageBadges from './common/PackageBadges';
import PackageFAQ from './PackageFAQ';
import PackagePlatforms from './PackagePlatforms';
import PackagePrice from './common/PackagePrice';
import { Button } from '../../inputs';
import { TextDisplay } from '../../display';
import RichTextViewer from '../../common/RichTextViewer';
import chatApi from '../../../logic/chat';

import { useOverviewStyles } from './style';

interface PackageContentProps {
  onBookPackageClick?: () => void;
  noCTA?: boolean;
}

const PackageOverview: FC<PackageContentProps> = (props) => {
  const { onBookPackageClick, noCTA = false } = props;
  const classes = useOverviewStyles(props);
  const { data, showChatLink } = useContext(PackageContext);
  const marketingServiceTypeInfo = data?.marketingServiceTypeInfo || {};
  const {
    variableFactorTitle,
    variableFactorUnit,
    variableFactorCount,
    cost,
    discount,
    recurring,
    currency,
    description,
  } = data;

  return (
    <>
      <TextDisplay variant="h4" bold gutterBottom>
        {marketingServiceTypeInfo.name || data.flavor}
      </TextDisplay>
      {/* @ts-ignore */}
      <RichTextViewer value={description} />

      {!noCTA && (
        <div className={classes.priceCtn}>
          <PackagePrice cost={cost} discount={discount} recurring={recurring} currency={currency} showOneTimeLabel />
        </div>
      )}

      {!!variableFactorTitle && (
        <div>
          <ListItem role={undefined} button={false} className={classes.listItem}>
            <ListItemIcon>
              <RadioButtonCheckedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={`Includes ${variableFactorCount || ''} ${variableFactorTitle} ${variableFactorUnit}`}
              secondary={
                variableFactorCount && showChatLink ? (
                  <span>
                    Need more?{' '}
                    <ButtonBase
                      // type="button"
                      onClick={() => chatApi.openChat()}
                      className={classes.contactUsBtn}
                    >
                      Talk to one of our Marketing Consultants
                    </ButtonBase>
                  </span>
                ) : null
              }
            />
          </ListItem>
        </div>
      )}
      {!noCTA && (
        <div className={classes.linkBtnCtn}>
          <div className={classes.linkBtnSubCtn}>
            <Button
              className={classes.linkBtn}
              label="book your package"
              color="secondary"
              variant="contained"
              onClick={onBookPackageClick}
            />
            <TextDisplay variant="caption" className={classes.linkBtnHelperText}>
              You won’t be charged yet
            </TextDisplay>
          </div>
        </div>
      )}
      <Divider className={classes.divider} />
      <div>
        <TextDisplay variant="h6" className={classes.whatsIncludedTitle}>
          What’s included?
        </TextDisplay>
        <ul className={classes.whatsIncludedList}>
          {(marketingServiceTypeInfo?.features || []).map((f: string) => (
            <li key={`PackageOverview-features-${f}`}>{f}</li>
          ))}
        </ul>
      </div>
      <PackageBadges badges={marketingServiceTypeInfo.badges} textType="body2" />
      <PackageFAQ />
      <PackagePlatforms />
    </>
  );
};

export default PackageOverview;
