import React from 'react';
import { Participant, Project } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { config } from '../../../../../config';
import LinkToProject from '../../../../../components/cpanel/components/atoms/Links/LinkToProject';
import LinkToCompany from '../../../../../components/cpanel/components/atoms/Links/LinkToCompany';
import LinkToMarketer from '../../../../../components/cpanel/components/atoms/Links/LinkToMarketer';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    copyDetailsContainer: {
      position: 'absolute',
      display: 'block',
      left: -999,
      width: 1,
      height: 1,
      overflow: 'hidden',
    },
  }),
);

interface ProjectDetailsForSlackProps {
  project: Project;
}

const ProjectDetailsForSlack: React.FC<ProjectDetailsForSlackProps> = (props) => {
  const classes = useStyles();

  const { project = {} as Project } = props;
  const { company, companyId, id: projectId, name: projectName = '', participants = [] } = project;
  const [participant] = participants || ([] as Participant[]);
  const assignedMarketer = participant && participant?.marketerId !== config.maamId ? participant : null;

  return (
    <div role="banner" id="project-header" className={classes.copyDetailsContainer}>
      <div>
        <span>Project: </span>
        <LinkToProject projectId={projectId}>
          {projectName} ({projectId})
        </LinkToProject>
      </div>
      <div>
        <span>Company: </span>
        <LinkToCompany companyId={companyId}>
          {company?.name} ({companyId})
        </LinkToCompany>
      </div>
      {assignedMarketer && (
        <div>
          <span>Assigned Expert: </span>
          <LinkToMarketer marketerId={assignedMarketer?.marketerId}>
            {assignedMarketer?.marketerMatch?.name} ({assignedMarketer?.marketerId})
          </LinkToMarketer>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailsForSlack;
