import React from 'react';

import { ProjectStopReasonsDialogProps } from './types';
import StopReasonsForm from './StopReasonsForm';

import { Dialog } from '../../display';

import useStyles from './style';

const ProjectStopReasonsDialog: React.FC<ProjectStopReasonsDialogProps> = (props) => {
  const classes = useStyles();

  const {
    project,
    handleStopProject,
    isComponentCustomerFacing,
    isStopProjectImmediately,
    handleCancel,
    isOpen,
    onClose,
  } = props;

  return (
    <Dialog
      title="Stop the project"
      subtitle="Your answers should reflect the client's perspective"
      isOpen={isOpen}
      onClose={onClose}
      containerMaxWidth="md"
      fullWidth
      classes={{
        dialogPaper: classes.modalContainer,
        dialogTitleRoot: classes.dialogTitleContainer,
        dialogActions: classes.dialogFooter,
      }}
    >
      <StopReasonsForm
        {...{
          project,
          handleStopProject,
          isComponentCustomerFacing,
          isStopProjectImmediately,
          handleCancel,
        }}
      />
    </Dialog>
  );
};

export default React.memo(ProjectStopReasonsDialog);
