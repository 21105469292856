import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((/* theme */) => ({
  root: {
    width: 56,
    height: 56,
  },
  small: {
    width: 40,
    height: 40,
  },
  large: {
    width: 72,
    height: 72,
  },
}));

function FeedAuthorAvatar({ author, size, className }) {
  const { photoUrl, name } = author;
  const classes = useStyles();

  return (
    <Avatar
      alt={name}
      src={photoUrl}
      className={classNames(classes.root, className, {
        [classes.small]: size === 'small',
        [classes.large]: size === 'large',
      })}
    />
  );
}

FeedAuthorAvatar.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  className: PropTypes.string,
};

FeedAuthorAvatar.defaultProps = {
  size: 'normal',
  className: '',
};

export default FeedAuthorAvatar;
