import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';

import { defaultFormatter } from './logic';

import { rowStyles } from './style';

function Row({ tableName, columns, row, classes }) {
  return <TableRow classes={{ root: classes.tableRow }}>
    {columns.map(({ field, formatter = defaultFormatter, className }) => {
      let extraClassNames = className;
      if (typeof className === 'function') {
        extraClassNames = className(row[field], row);
      }

      return (
        <TableCell
          key={`SimpleDataGrid-${tableName}-cell-${row.id}-${field}`}
          className={extraClassNames}
          classes={{ root: classes.tableCell }}
        >
          {formatter(row[field], row)}
        </TableCell>
      );
    })}
  </TableRow>
}

Row.propTypes = {
  tableName: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(rowStyles)(Row);
