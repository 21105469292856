import React, { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { DialogProps } from '../../../../../../fe_common/client/components/display/Dialog/types';
import { Dialog } from '../../../../../../fe_common/client/components/display';
import useUserData from '../../../useUserData';
import UserDetailsEditForm from '../UserDetailsEditForm';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface EditUserDetailsDialogProps extends DialogProps {
  userId: number;
  classes?: Record<string, string>;
}

const EditUserDetailsDialog: React.FC<EditUserDetailsDialogProps> = (props) => {
  const classes = useStyles(props);
  const { userId, ...rest } = props;

  const { user, loading, error, refetch } = useUserData(userId);

  const dialogTitle = useMemo(() => `Edit User ${user?.displayName} (${userId}) Details`, [user?.displayName, userId]);

  return (
    <Dialog title={dialogTitle} fullScreen fullWidth containerMaxWidth="sm" {...rest} classes={classes}>
      <UserDetailsEditForm loading={loading} error={error} user={user} onSuccess={refetch} />
    </Dialog>
  );
};

export default EditUserDetailsDialog;
