import React from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import { MatchingWeightsManagementPanelProps } from './types';
import { columns, MATCHING_SIMULATOR_WEIGHTS_KEY } from './consts';
import useMatchingWeightsManagementPanelData from './useMatchingWeightsManagementPanelData';
import { handleExportToCSVOnClick, mapToMatchingSimulatorWeights } from './logic';
import { wizardFormName } from '../../consts';
import { MatchingSimulatorForm } from '../../types';

import { Button } from '../../../../../../../fe_common/client/components/inputs';
import { getFromLocalStorage, setToLocalStorage } from '../../../../../../../fe_common/client/services/utils';
import PromptDialog from '../../../../../../../fe_common/client/components/molecules/PromptDialog';
import { Dialog, TextDisplay } from '../../../../../../../fe_common/client/components/display';
import DataTable from '../../../../../../../fe_common/client/components/molecules/DataTable';

import useStyles from './style';

const MatchingWeightsManagementPanelWrapped: React.FC<MatchingWeightsManagementPanelProps> = (props) => {
  const classes = useStyles();
  const { onLoad } = props;
  const {
    projectId,
    weightTableData,
    refetchData,
    saveWeightsDataHandler,
    openWeightsManagement,
    savePromptIsOpen,
    onConfirmSaveHandler,
    closeSavePrompt,
    weightsManagementIsOpen,
    closeWeightsManagement,
  } = useMatchingWeightsManagementPanelData(props);

  if (!projectId) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button onClick={saveWeightsDataHandler} label="save weights" variant="outlined" color="primary" size="small" />
      <Button onClick={openWeightsManagement} label="Manage weights" variant="outlined" color="primary" size="small" />

      <PromptDialog
        isOpen={savePromptIsOpen}
        title="Save matching simulator weights"
        message="Please provide a name to save the weight selection"
        onConfirm={onConfirmSaveHandler}
        onCancel={closeSavePrompt}
      />

      {weightsManagementIsOpen && (
        <Dialog
          isOpen={weightsManagementIsOpen}
          title={<TextDisplay variant="h5">Weights Management</TextDisplay>}
          subtitle={<TextDisplay variant="subtitle1">rename, delete or export the saved weights data</TextDisplay>}
          onClose={closeWeightsManagement}
          containerMaxWidth="md"
          fullWidth
        >
          <Button
            label="Export to CSV"
            onClick={handleExportToCSVOnClick}
            color="primary"
            variant="contained"
            disabled={!weightTableData?.length}
          />
          <DataTable
            columns={columns}
            data={weightTableData || []}
            options={{
              showTitle: false,
              selection: true,
              search: false,
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                tooltip: 'Remove All Selected Users',
                icon: 'delete',
                onClick: (_evt, data) => {
                  const newData = getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY);
                  // @ts-ignore
                  data.forEach(({ fileName }) => {
                    delete newData[fileName];
                  });
                  setToLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY, newData);
                  refetchData();
                },
              },
              (rowsData) => ({
                icon: OpenInBrowserIcon,
                isFreeAction: true,
                tooltip: 'Load saved weights',
                onClick: (_event, [rowData]) => {
                  const storedMatchingSimulatorWeights = getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY);
                  const selectedMatchingSimulatorWeights = storedMatchingSimulatorWeights[rowData.fileName];

                  if (!selectedMatchingSimulatorWeights) {
                    alert(`Loading ${rowData.fileName} weights data failed.`);
                  }

                  const matchingSimulatorWeights = mapToMatchingSimulatorWeights(selectedMatchingSimulatorWeights);

                  onLoad(matchingSimulatorWeights);
                  closeWeightsManagement();
                },
                disabled: rowsData.length > 1,
              }),
            ]}
          />
        </Dialog>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    formValues: getFormValues(wizardFormName)(state) as MatchingSimulatorForm,
  };
}

const MatchingWeightsManagementPanel = connect(mapStateToProps)(MatchingWeightsManagementPanelWrapped);
export default MatchingWeightsManagementPanel;
