import React, { FC } from 'react';
import Link from '@material-ui/core/Link';

import { LinkToProjectCycleProps } from './types';

const LinkToProjectCycle: FC<LinkToProjectCycleProps> = (props) => {
  const { projectId, projectCycleId, openInNewTab = false, children } = props;

  const target = openInNewTab ? '_blank' : '_self';
  return (
    <Link href={`/projects/${projectId}/cycles/${projectCycleId}`} target={target}>
      {children}
    </Link>
  );
};

export default LinkToProjectCycle;
