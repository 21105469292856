import React, { useMemo } from 'react';
import { Marketer } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import getEntityExtraDetailsProperties from './logic';
import { getDingUrl } from '../../../../app/utils';

import PropertiesList from '../../../../../fe_common/client/components/display/PropertiesList';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    propertyValue: {
      fontWeight: 'normal',
    },
  }),
);

interface MarketerExtraDetailsProps {
  marketer: Marketer;
}

const MarketerExtraDetails: React.FC<MarketerExtraDetailsProps> = (props) => {
  const classes = useStyles();
  const { marketer } = props;
  const marketerType = marketer?.marketerProfile?.marketerType;
  const entityExtraDetailsProperties = useMemo(() => getEntityExtraDetailsProperties(marketerType), [marketerType]);
  const externalProfileLink = useMemo(() => `${getDingUrl()}/expert/${marketer?.uuid}`, [marketer?.uuid]);
  const propertiesListData = { ...marketer, externalProfileLink };

  return (
    <PropertiesList
      id="entityExtraDetails"
      data={propertiesListData || {}}
      propertiesMeta={entityExtraDetailsProperties}
      classes={{
        propertyValue: classes.propertyValue,
      }}
    />
  );
};

export default MarketerExtraDetails;
