import { useEffect, useState } from 'react';
import { ProjectSpecialType } from '@mayple/types';
import { useParams } from 'react-router-dom';

import useProjectData from './useProjectData';
import { UseMatchingSimulatorDataOutput } from './types';
import { ProjectIdParams } from '../../components/Routes/types';
import { MatchingSimulatorData } from '../../containers/project/wizards/ProjectMatchingSimulatorWizard/types';

import { tryParseInt, valuesOnly } from '../../../fe_common/client/services/utils';

const useMatchingSimulatorData = (): UseMatchingSimulatorDataOutput => {
  const [initialized, setInitialized] = useState(false);
  const [initialValues, setInitialValues] = useState<null | Partial<MatchingSimulatorData>>(null);

  const { projectId: projectIdFromUrl } = useParams<ProjectIdParams>();
  const projectId = tryParseInt(projectIdFromUrl, 0);

  const { project, loading } = useProjectData(projectId);

  useEffect(() => {
    if (!projectId) {
      setInitialized(true);
      setInitialValues(null);
    }

    if (projectId && !loading && initialValues !== null) {
      setInitialized(true);
    }
  }, [projectId, loading, initialValues]);

  useEffect(() => {
    if (loading || !project) {
      return;
    }

    const targetKPI = project?.projectDefinition?.primaryObjectives?.targetKPI ?? undefined;

    setInitialValues({
      estimatedMediaBudget: project?.requestedMediaBudget,
      targetKPI,
      industry: project?.industry ?? undefined,
      locations: project?.locations,
      productBusinessModel: project?.productBusinessModel,
      testCompanyCountry: project?.company?.companyProfile?.contactDetails?.postalAddress?.country ?? undefined,
      skills: valuesOnly(project?.requestedServices, 'serviceType'),
      languages: valuesOnly(project?.languages),
      preferLocalMarketers: project?.preferLocalMarketers,
      requestedProjectSpecialType:
        (project?.requestedProjectSpecialType as ProjectSpecialType) ||
        project?.specialType ||
        ProjectSpecialType.NO_SPECIAL_TYPE,
    });
  }, [loading, project]);

  return {
    initialized,
    initialValues,
  };
};

export default useMatchingSimulatorData;
