import React, { FC, useState } from 'react';
import { Field } from 'redux-form';
import moment from 'moment';

import DateTimePicker from '../../Registration/DateTimePicker';
import { validateMaxDate, validateMinDate, validateRequired } from '../../../../services/validation';

interface WizardDateTimePickerFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  isRequired?: boolean;
  minDate?: typeof moment | Date;
  maxDate?: typeof moment | Date;
}

const WizardDateTimePickerField: FC<WizardDateTimePickerFieldProps> = (props) => {
  const { name, label = '', disabled = false, isRequired = false, minDate, maxDate, ...rest } = props;

  const [validator] = useState(() => {
    const tempValidator = [];
    if (isRequired) {
      tempValidator.push(validateRequired);
    }

    if (minDate) {
      tempValidator.push(validateMinDate(minDate));
    }
    if (maxDate) {
      tempValidator.push(validateMaxDate(maxDate));
    }

    return tempValidator;
  });

  return (
    <div>
      <Field
        component={DateTimePicker}
        name={name}
        label={label}
        disabled={disabled}
        validate={validator}
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
    </div>
  );
};

export default WizardDateTimePickerField;
