import { useQuery } from '@apollo/react-hooks';
import { ProjectNotesQuery } from 'growl-graphql/dist/queries/project/ProjectNotesQuery';
import { CompanyNotesQuery } from 'growl-graphql/dist/queries/company/CompanyNotesQuery';

import { EntityNotesData, NoteQueries, NotesEntityType } from '../types';
import EntityType from '../../../../app/types';

const getNotesQueries = (entityId: number | null | undefined, entityType: NotesEntityType): NoteQueries | null => {
  if (!entityId || !entityType) {
    return null;
  }

  // @ts-ignore
  const notesQueries: Record<Partial<EntityType>, NoteQueries> = {
    [EntityType.Project]: {
      queryClass: ProjectNotesQuery,
      // mutationClass: UpdateProjectMutation,
      dataKey: 'project',
      variables: {
        projectId: entityId,
      },
    },
    [EntityType.Company]: {
      queryClass: CompanyNotesQuery,
      // mutationClass: UpdateCompanyMutation,
      dataKey: 'company',
      variables: {
        companyId: entityId,
      },
    },
  };

  // @ts-ignore
  return notesQueries[entityType] ?? null;
};

const useEntityNoteData = (
  entityId: number | null | undefined,
  entityType: EntityType.Project | EntityType.Company,
): EntityNotesData => {
  const notesQueries = getNotesQueries(entityId, entityType);
  const { queryClass, variables = {}, dataKey = '' } = notesQueries || {};

  const { data, loading, error, refetch } = useQuery(queryClass?.query, {
    variables,
    skip: !queryClass || !entityId,
  });

  return {
    note: data?.[dataKey]?.note,
    entityName: data?.[dataKey]?.name,
    loading,
    error,
    refetch,
  };
};

export default useEntityNoteData;
