import React from 'react';
import { InternalTeamType } from '@mayple/types';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { Step, Steps } from 'react-albus';
import Grid from '@material-ui/core/Grid';

import { config } from '../../../../config';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { PROJECT_SPECIAL_TYPE } from '../../../../../fe_common/client/app/enums';

interface WizardStepsProps extends DecoratedFormProps {
  formValues: Record<string, any>;
}

const InternalTeamTypePairs = Object.entries(InternalTeamType).map(([key, value]) => ({
  label: value,
  value: key,
}));

const WizardSteps: React.FC<WizardStepsProps> = () => (
  <Steps>
    <Step
      id="setInvoiceDetails"
      render={() => (
        <Grid container style={{ width: '100%' }}>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.TEXT}
              // @ts-ignore
              wizardFieldLabel="Name"
              vertical={false}
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.SELECT}
              wizardFieldLabel="Type"
              vertical={false}
              name="internalTeamType"
              required
              // @ts-ignore
              enums={InternalTeamTypePairs}
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.TEXT}
              wizardFieldLabel="Description"
              vertical={false}
              name="description"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.URL}
              name="meetYouExpertCalendlyLink"
              wizardFieldLabel="Meet your expert Meeting link"
              vertical={false}
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.TEXT}
              name="maypleSlackMemberId"
              // @ts-ignore
              label="e.g. U020X7V8AAZ"
              helperText="Go to Profile -> More -> Copy member ID"
              wizardFieldLabel="Slack member ID"
              vertical={false}
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.SWITCH}
              name="maypleSlackAllowTag"
              wizardFieldLabel="Allow to tag in Slack"
              vertical={false}
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.AUTOCOMPLETE}
              name="workingGroupOnProjectTypes"
              wizardFieldLabel="Working Group On Project Types"
              vertical={false}
              // @ts-ignore
              isMulti
              enums={PROJECT_SPECIAL_TYPE}
            />
          </Grid>
          <Grid item xs={12}>
            <WizardStepField
              type={WizardStepField.types.IMAGE_PICKER_2}
              name="displayImageUrl"
              wizardFieldLabel="Profile photo"
              vertical={false}
              required
              // @ts-ignore
              apikey={config.filestackApiKey}
            />
          </Grid>
        </Grid>
      )}
    />
  </Steps>
);

export default WizardSteps;
