import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      flex: '1 1 auto',
    },
    pageTitle: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
    },
    pageActions: {},
  }),
);

export default useStyles;
