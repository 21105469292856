import React, { useCallback, useEffect, useState } from 'react';
import { MarketerMatch, ProjectLifeCycleStatus, ProjectMarketerOpportunity } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import { getMarketerIdsWithOpenOpportunities } from './logic';
import { OpportunitiesCandidatesProps } from '../types';
import SendOpportunitiesButton from '../SendOpportunitiesButton';
import { ENTITY_TYPE } from '../../../../../app/consts';
import EntityListItem from '../../../../../components/cpanel/components/molecules/EntityListItem';

import Section, { SECTION_LAYOUT } from '../../../../../../fe_common/client/components/atoms/Section';
import LoadingPlaceholder from '../../../../../../fe_common/client/components/atoms/LoadingPlaceholder';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import useProjectMarketerMatches from '../../../../../../fe_common/client/hooks/project/useProjectMarketerMatches';

import useStyles from './style';

const OpportunitiesCandidates: React.FC<OpportunitiesCandidatesProps> = (props) => {
  const classes = useStyles(props);
  const { projectId, opportunities, onUpdateOpportunities, project } = props;

  const { marketerMatches, loading } = useProjectMarketerMatches(projectId);

  const [existingOpportunities, setExistingOpportunities] = useState(() =>
    getMarketerIdsWithOpenOpportunities((opportunities || []) as ProjectMarketerOpportunity[]),
  );

  const [opportunitiesCandidates, setOpportunitiesCandidates] = useState([] as Array<number>);

  const filterOutOpportunities = useCallback(
    (marketer: MarketerMatch) => !(existingOpportunities || []).includes(marketer.marketerId),
    [existingOpportunities],
  );

  useEffect(() => {
    const newOpportunities = getMarketerIdsWithOpenOpportunities((opportunities || []) as ProjectMarketerOpportunity[]);
    setExistingOpportunities(newOpportunities);
  }, [opportunities]);

  const handleOnMarketerSelected = useCallback(
    (marketer: MarketerMatch): void => {
      const newOpportunitiesCandidates = new Set(opportunitiesCandidates);
      const { marketerId } = marketer;

      if (marketerId && !newOpportunitiesCandidates.has(marketerId)) {
        newOpportunitiesCandidates.add(marketerId);
      } else if (marketerId && newOpportunitiesCandidates.has(marketerId)) {
        newOpportunitiesCandidates.delete(marketerId);
      }
      setOpportunitiesCandidates(Array.from(newOpportunitiesCandidates));
    },
    [opportunitiesCandidates],
  );

  return (
    <Section
      title="Opportunities candidates"
      subTitle="The list of marketers is based on Matched marketers for this project"
      layout={SECTION_LAYOUT.TWO_COLUMNS}
      classes={{
        root: classes.root,
      }}
    >
      {loading && <LoadingPlaceholder />}
      {!loading && project?.projectLifeCycleStatus !== ProjectLifeCycleStatus.ONBOARDING && (
        <CalloutMessage type="info" message="Opportunities can only be sent for project that are on-boarding" />
      )}
      {!loading && marketerMatches.length === 0 && (
        <CalloutMessage type="info" message="No marketer matches for this project" />
      )}
      <Grid container>
        <Grid item xs={8}>
          <TextDisplay>Selected Candidates: {opportunitiesCandidates.join(', ')}</TextDisplay>
        </Grid>
        <Grid item xs={4}>
          <SendOpportunitiesButton
            projectId={projectId}
            project={project}
            opportunitiesCandidates={opportunitiesCandidates}
            onUpdateOpportunities={onUpdateOpportunities}
            onSuccess={() => {
              setOpportunitiesCandidates([]);
            }}
          />
        </Grid>
      </Grid>
      {opportunitiesCandidates.length > 3 && (
        <CalloutMessage type="warning" message="Suggested number of opportunities should be no more than 3" />
      )}
      <Divider />

      {!!marketerMatches?.length && (
        <List>
          {(marketerMatches || []).filter(filterOutOpportunities).map((marketer: MarketerMatch) => (
            <EntityListItem
              key={`entity-list-item-${marketer.marketerId}`}
              entityType={ENTITY_TYPE.MARKETER}
              entity={marketer}
              withSelection
              isChecked={opportunitiesCandidates.includes(marketer.marketerId)}
              handleToggleSelection={handleOnMarketerSelected}
            />
          ))}
        </List>
      )}
    </Section>
  );
};

export default OpportunitiesCandidates;
