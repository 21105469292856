import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

import { PackageContext } from './logic';
import PackageSection from './common/PackageSection';
import { TextDisplay } from '../../display';

import { useCommonStyles, useWhatExpertsDoStyles } from './style';

const PackageWhyChooseThisPackage: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useWhatExpertsDoStyles();
  const { data } = useContext(PackageContext);
  const sections = data?.marketingServiceTypeInfo?.whyChooseThis || [];
  const lastIndex = sections.length - 1;

  if (sections.length === 0) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <TextDisplay className={commonClasses.sectionTitle} variant="h4" bold>
        Why Choose This Package?
      </TextDisplay>
      {/* @ts-ignore */}
      {(sections || []).map((section, i: number) => (
        <PackageSection
          key={`PackageWhyChooseThisPackage-PackageSection-${i}`}
          section={section}
          reverse={Boolean(i % 2)}
          classes={{
            root: classNames({ [commonClasses.noBottomMargin]: i === lastIndex }),
          }}
        />
      ))}
    </Paper>
  );
};

export default PackageWhyChooseThisPackage;
