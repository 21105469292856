import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/es/styles/withStyles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { formatDate } from '../../../../../app/utils';

import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const styles = () => ({
  root: {},
});

const FundAccountDebts = (props) => {
  const { debts, classes } = props;
  const [showRepaidDebts, onChangeShowRepaidDebts] = useState(false);

  if (!debts || !debts.length) {
    return <CalloutMessage message="No debts found" />;
  }

  return (
    <div className={classes.root}>
      <TextDisplay variant="h5">Debts</TextDisplay>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={showRepaidDebts}
              onChange={() => {
                onChangeShowRepaidDebts(!showRepaidDebts);
              }}
            />
          }
          label="Show repaid debts"
        />
      </FormGroup>
      {debts &&
        debts.length > 0 &&
        debts.map((debt) => {
          const { uuid, id, amount, currency, name, description, created, repaid, repaymentDate } = debt;
          if ((showRepaidDebts && repaid) || !repaid) {
            return (
              <Accordion key={`fund-account-debt-${uuid}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <span className={repaid ? 'grey-text' : 'red-text'}>
                    {formatDate(created, false)} - Amount: {amount} ({currency}) {repaid ? '(Repaid)' : ''}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {name}
                    <br />
                    {description}
                    <br />
                    (Operation ID: {id})
                    {repaid ? (
                      <span className="green-text"> Repaid on {formatDate(repaymentDate)}</span>
                    ) : (
                      <span className="red-text"> Not repaid</span>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

FundAccountDebts.propTypes = {
  debts: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
};

FundAccountDebts.defaultProps = {
  debts: [],
};

export default withStyles(styles)(FundAccountDebts);
