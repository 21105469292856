import { PpcBillingModelPlanName } from '@mayple/types';

export const checkIsMeteredPlan = (billingConfigurationPlanName: string): boolean =>
  ([PpcBillingModelPlanName.meteredPlan20240608, PpcBillingModelPlanName.meteredPlan20240608] as string[]).includes(
    billingConfigurationPlanName,
  );

export const checkIsFixedPlan = (billingConfigurationPlanName: string): boolean =>
  ([PpcBillingModelPlanName.fixedPlan] as string[]).includes(billingConfigurationPlanName);

export const checkIsTieredPlan = (billingConfigurationPlanName: string): boolean =>
  (
    [
      PpcBillingModelPlanName.tieredPlan20201101,
      PpcBillingModelPlanName.tieredPlan20190101,
      PpcBillingModelPlanName.tieredPlan20200701,
      PpcBillingModelPlanName.tieredPlan20201101,
      PpcBillingModelPlanName.tieredPlan20210401,
      PpcBillingModelPlanName.tieredPlan20211201,
      PpcBillingModelPlanName.tieredPlan20220518,
    ] as string[]
  ).includes(billingConfigurationPlanName);
