import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/styles/withStyles';

import CycleDataRow from '../ProjectCycleDetailsNew/CycleDataRow';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

const styles = {
  root: {},
};

const CycleDataTable = ({ cycleData, classes }) => {
  if (!cycleData || cycleData.length === 0) {
    return <TextDisplay>No Info</TextDisplay>;
  }

  return (
    <Table className={classes.root} padding="checkbox">
      <TableBody>
        {cycleData.map((rowData) => {
          const { label } = rowData;

          return <CycleDataRow key={`table-row-cycle-data-${label}`} hasNextCycle={false} rowData={rowData} />;
        })}
      </TableBody>
    </Table>
  );
};

CycleDataTable.propTypes = {
  cycleData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      currCycleValue: PropTypes.string,
      currCycleExtraLabel: PropTypes.string,
      currCycleValueTooltip: PropTypes.string,
      extendedData: PropTypes.arrayOf(PropTypes.object),
      customClasses: PropTypes.object,
    }),
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CycleDataTable);
