import { LabelValueProps } from '../../app/types';

export enum ProjectBillingPoint {
  acceptProjectBillingPointBehavior = 'acceptProjectBillingPointBehavior',
  scheduleProjectLaunchBillingPointBehavior = 'scheduleProjectLaunchBillingPointBehavior',
  approveMarketerBillingPointBehavior = 'approveMarketerBillingPointBehavior',
  startProjectBillingPointBehavior = 'startProjectBillingPointBehavior',
}

export const PROJECT_BILLING_POINT_PAIRS: LabelValueProps[] = [
  { label: 'Book package', value: ProjectBillingPoint.acceptProjectBillingPointBehavior },
  { label: 'Schedule project delayed launch', value: ProjectBillingPoint.scheduleProjectLaunchBillingPointBehavior },
  { label: 'Approve expert', value: ProjectBillingPoint.approveMarketerBillingPointBehavior },
  { label: 'Project Start', value: ProjectBillingPoint.startProjectBillingPointBehavior },
];
