import React, { FC } from 'react';
import { MarketerMatch } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import useMatchedMarketersResults from './useMatchedMarketersResults';
import MarketerMatchCard from '../MarketerMatchCard';
import { MarketerMatchesProps } from '../../types';

import { RefreshSpinner } from '../../../../../../../fe_common/client/components/atoms';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../../../fe_common/client/components/common/CalloutMessage';
import QueryErrorMessage from '../../../../../../../fe_common/client/components/common/QueryErrorMessage';

const MatchedMarketersResults: FC<MarketerMatchesProps> = (props) => {
  const { loading, error, testMarketerMatches } = useMatchedMarketersResults(props);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        <Grid item>
          <RefreshSpinner />
        </Grid>
        <Grid item>
          <TextDisplay variant="body2" italic>
            This may take several seconds...
          </TextDisplay>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return <QueryErrorMessage error={error} />;
  }

  if (!testMarketerMatches?.length) {
    return <CalloutMessage type="info" margin message="No matches found!" />;
  }

  return (
    <div>
      <Grid container spacing={2}>
        {testMarketerMatches.map((marketerMatch: MarketerMatch) => (
          <MarketerMatchCard marketerMatch={marketerMatch} key={`match-result-${marketerMatch.marketerId}-key`} />
        ))}
      </Grid>
    </div>
  );
};

export default MatchedMarketersResults;
