import React, { useCallback } from 'react';
import {
  ProjectExistingMarketingOptimizationImplementationState,
  ProjectStage,
  SharedFileCategory,
} from '@mayple/types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { ApproveExistingMarketingOptimizationImplementationByCompanyMutation } from 'growl-graphql/dist/mutations/project/ExistingMarketingOptimizationImplementation/ApproveExistingMarketingOptimizationImplementationByCompanyMutation';

import { ProjectMarketingEvaluationImplementationDetailsProps } from './types';
import useImplementationDeckApproval from './useImplementationDeckApproval';
import useProjectMarketingEvaluationImplementationDetailsData from './useProjectMarketingEvaluationImplementationDetailsData';
import useProjectExistingMarketingOptimizationImplementation from './useProjectExistingMarketingOptimizationImplementation';
import { config } from '../../../../config';

import Section, { SectionContent } from '../../../../../fe_common/client/components/atoms/Section';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import FilesList from '../../../../../fe_common/client/components/molecules/FilesList';
import { FileListFile } from '../../../../../fe_common/client/components/molecules/FilesList/types';
import FileUploadButton from '../../../../../fe_common/client/components/inputs/FileUploadButton';
import { FileUploadDialogTabs } from '../../../../../fe_common/client/components/inputs/FileUploadDialog/types';
import { Button } from '../../../../../fe_common/client/components/inputs';
import LabelValue from '../../../../../fe_common/client/components/display/LabelValue';
import useProjectFlowConfiguration from '../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../fe_common/client/services/logger';
import getTranslationForProjectSpecialSubType from '../../../../../fe_common/client/logic/translation/getTranslationForProjectSpecialSubType';

import useStyles from './style';

const ProjectMarketingEvaluationImplementationDetails: React.FC<
  ProjectMarketingEvaluationImplementationDetailsProps
> = (props) => {
  const classes = useStyles();
  const { projectId, participant, ...sectionProps } = props;

  const { postImplementationMeetingDate, summaryDeckFiles, savingFile, onFileUpload } =
    useProjectMarketingEvaluationImplementationDetailsData(projectId, participant);

  const { getProjectFlowConfigurationByKey } = useProjectFlowConfiguration(projectId);
  const skipUploadProcessAtImplementationStage = !!getProjectFlowConfigurationByKey(
    'skipUploadProcessAtImplementationStage',
  )?.value;

  const { t } = useTranslation('translation', { keyPrefix: 'ProjectMarketingEvaluationImplementationDetails' });

  const {
    savingGSComments,
    setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler,
    savingGSApprove,
    approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler,
  } = useImplementationDeckApproval(projectId);

  const {
    project,
    existingMarketingOptimizationImplementation,
    loading: loadingExistingMarketingOptimizationImplementation,
    // error: errorExistingMarketingOptimizationImplementation,
    refetch: refetchExistingMarketingOptimizationImplementation,
  } = useProjectExistingMarketingOptimizationImplementation(projectId);

  const { mutate: approveExistingMarketingOptimizationImplementationByCompany, loading: savingCompanyApproval } =
    useMutation(ApproveExistingMarketingOptimizationImplementationByCompanyMutation);

  const refetch = useCallback(async () => {
    await refetchExistingMarketingOptimizationImplementation();
  }, [refetchExistingMarketingOptimizationImplementation]);

  const onSendingBackToTheExpertForFixesClickHandler = useCallback(async () => {
    await setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler(refetch);
  }, [refetch, setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler]);

  const onApproveClickHandler = useCallback(async () => {
    await approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler(refetch);
  }, [approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler, refetch]);

  const isWaitingForGSApproval =
    existingMarketingOptimizationImplementation?.state ===
      ProjectExistingMarketingOptimizationImplementationState.SUMMARY_DECK_UPLOADED ||
    existingMarketingOptimizationImplementation?.state ===
      ProjectExistingMarketingOptimizationImplementationState.SUMMARY_DECK_REVIEWED_BY_MARKETER;

  const onApproveImplementationIsDoneClickHandler = useCallback(async () => {
    try {
      const variables = {
        projectId,
      };
      const result = await approveExistingMarketingOptimizationImplementationByCompany({ variables });

      if (result.data?.approveExistingMarketingOptimizationImplementationByCompany.success) {
        await refetch?.();
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to approve implementation on behalf of the company');
    }
  }, [approveExistingMarketingOptimizationImplementationByCompany, projectId, refetch]);

  return (
    <Section
      title={getTranslationForProjectSpecialSubType(t, 'title', project?.specialSubType)}
      classes={{ root: classes.root }}
      {...sectionProps}
    >
      <SectionContent>
        <div>
          <LabelValue
            label={t('implementationSummaryState', 'Implementation summary state')}
            value={
              skipUploadProcessAtImplementationStage
                ? 'File upload is not needed'
                : existingMarketingOptimizationImplementation?.state
            }
          />
        </div>
        <div>
          <LabelValue
            label={t('implementationSummaryMeeting', 'Implementation summary meeting')}
            value={postImplementationMeetingDate}
          />
        </div>
        <Divider />

        {!skipUploadProcessAtImplementationStage && (
          <div>
            {!(summaryDeckFiles || []).length && (
              <TextDisplay gutterBottom>Summary deck file wasn't uploaded yet.</TextDisplay>
            )}
            {(summaryDeckFiles || []).length > 0 && (
              <div className={classes.summaryDeckFileContainer}>
                <TextDisplay gutterBottom>Summary deck file uploaded by marketer:</TextDisplay>
                <div className={classes.fileListContainer}>
                  <FilesList
                    files={summaryDeckFiles as FileListFile[]}
                    allowDownload
                    showFileDate
                    showFileSize
                    classes={{ root: classes.fileListRoot }}
                  />
                </div>
              </div>
            )}
            <div>
              <FileUploadButton
                label="Upload / replace Google slide"
                variant="outlined"
                filestackApiKey={config.filestackApiKey}
                tabs={[FileUploadDialogTabs.GoogleSlides]}
                filename="Mailchimp implementation summary deck"
                category={SharedFileCategory.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION_SUMMARY_DECK}
                loading={savingFile}
                onFileUpload={onFileUpload}
              />
            </div>
            <Divider />
            <div className={classes.actionsContainer}>
              <Button
                label="Sending back to the expert for fixes"
                variant="outlined"
                color="primary"
                loading={savingGSComments}
                disabled={
                  loadingExistingMarketingOptimizationImplementation || savingGSComments || !isWaitingForGSApproval
                }
                onClick={onSendingBackToTheExpertForFixesClickHandler}
              />
              <Button
                label="Approve Implementation Summary Deck"
                color="primary"
                variant="contained"
                loading={savingGSApprove}
                disabled={
                  loadingExistingMarketingOptimizationImplementation || savingGSApprove || !isWaitingForGSApproval
                }
                onClick={onApproveClickHandler}
              />
            </div>
          </div>
        )}
        <div>
          <Button
            label="Approve Implementation is done on behalf of the company"
            color="primary"
            variant="contained"
            loading={savingCompanyApproval}
            disabled={
              savingCompanyApproval || project?.stage !== ProjectStage.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION
            }
            onClick={onApproveImplementationIsDoneClickHandler}
          />
        </div>
      </SectionContent>
    </Section>
  );
};

export default ProjectMarketingEvaluationImplementationDetails;
