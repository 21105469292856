import { useEffect, useState } from 'react';
import { Integration } from '@mayple/types';

import { AvailabilityViewerButtonProps, UseAvailabilityViewerButtonOutput } from './types';
import { useAccountIntegrations } from '../../hooks/useCronofyElementToken';
import { getCronofyIntegration } from '../../../CronofyIntegrationManager/hooks';

const useAvailabilityViewerButton = (props: AvailabilityViewerButtonProps): UseAvailabilityViewerButtonOutput => {
  const { accountId, accountType } = props;

  const [cronofyIntegration, setCronofyIntegration] = useState<Integration | null>();

  const { integrations, loading, error } = useAccountIntegrations(accountId, accountType);

  useEffect(() => {
    if (!loading && integrations) {
      setCronofyIntegration(getCronofyIntegration(integrations));
    }
  }, [loading, integrations]);

  const disabled = loading || !cronofyIntegration || !!error;

  const title = disabled ? 'This expert has no Cronofy integration' : `View expert's availability`;

  return {
    title,
    disabled,
  };
};

export default useAvailabilityViewerButton;
