import React, { FC, useMemo } from 'react';
import { Field } from 'redux-form';

import UrlList from '../../Registration/UrlList/UrlList';
import { validateNonEmptyArray } from '../../../../services/validation';

export interface WizardUrlListFieldProps {
  name: string;
  isMobile?: boolean;
  isRequired?: boolean;
}

export const WizardUrlListField: FC<WizardUrlListFieldProps> = (props) => {
  const { name, isRequired = false, isMobile = false } = props;
  const validator = useMemo(() => (isRequired ? [validateNonEmptyArray()] : []), [isRequired]);

  return (
    <div>
      <Field component={UrlList} name={name} validate={validator} required={isRequired} isMobile={isMobile} />
    </div>
  );
};
