import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Marketer, MarketerSearchDefinition } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Highlight from '@material-ui/icons/Highlight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SearchForMarketersQuery } from 'growl-graphql/dist/queries/SearchForMarketersQuery';

import Pager from '../Pager';
import GridLayoutSelector from '../GridLayoutSelector';
import MarketerResultCard from '../MarketerResultCard';
import { getHighlightTerms, highlightResults, removeHighlightFromResults, SearchContext } from './logic';

import { clientLogger } from '../../../../../../../fe_common/client/services/logger';
import { isEmptyObject } from '../../../../../../../fe_common/client/services/utils';
import { RefreshSpinner } from '../../../../../../../fe_common/client/components/atoms';
import CalloutMessage from '../../../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './styles';

interface MarketerSearchResultsProps {
  filter: MarketerSearchDefinition;
}

const MarketerSearchResults: FC<MarketerSearchResultsProps> = (props) => {
  const classes = useStyles();

  const { filter = {} as MarketerSearchDefinition } = props;

  const resultsWrapper = useRef<HTMLDivElement>(null);

  const [initialized, setInitialized] = useState(false);
  const [gridCols, setGridCols] = useState('cols');
  const [highlightText, setHighlightText] = useState(false);
  const [pageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchResults, setSearchResults] = useState([]);

  const queryOptions = { variables: { filter } };

  const { data, loading, error } = useQuery(SearchForMarketersQuery.query, queryOptions);

  useEffect(() => {
    if (highlightText) {
      highlightResults(resultsWrapper.current as SearchContext, getHighlightTerms(filter));
    } else {
      removeHighlightFromResults(resultsWrapper.current as SearchContext);
    }
  }, [highlightText, filter]);

  const handleHighlightTextChange = (_event: ChangeEvent<unknown>, checked: boolean) => {
    setHighlightText(checked);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'KeyH' && event.altKey) {
      event.stopPropagation();
      event.preventDefault();
      setHighlightText((prevValue) => !prevValue);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      const { searchForMarketers = [] } = data || {};
      setSearchResults(searchForMarketers);
      setTotalItems(searchForMarketers.length);
      setHighlightText(false);
      setCurrPage(1);
      setInitialized(true);
    }
  }, [loading, data]);

  const handleGridColsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGridCols(event.target.value);
  };

  const handleNextClick = () => {
    const totalPages = Math.ceil(totalItems / pageSize);

    if (totalPages > currPage) {
      setCurrPage(currPage + 1);
      setHighlightText(false);
    }
  };

  const handlePrevClick = () => {
    if (currPage > 1) {
      setCurrPage(currPage - 1);
      setHighlightText(false);
    }
  };

  if (isEmptyObject(filter)) {
    return null;
  }

  if (error) {
    clientLogger.error(error);
  }

  const start = (currPage - 1) * pageSize;
  const end = currPage * pageSize;
  const marketers = searchResults.slice(start, end);

  return (
    <div className={classes.root}>
      {(loading || !initialized) && (
        <div className={classes.loadingWrapper}>
          <RefreshSpinner />
        </div>
      )}
      {initialized && error && (
        <CalloutMessage type="error" message="Error fetching data... Results might be lacking" />
      )}
      {initialized && !error && totalItems === 0 && <CalloutMessage type="info" message="No matching experts found" />}
      {initialized && totalItems > 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Pager
                totalItems={totalItems}
                page={currPage}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                pageSize={pageSize}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormControlLabel
                    label="Highlight results"
                    onChange={handleHighlightTextChange}
                    title="(ALT+H)"
                    control={
                      <Checkbox
                        checked={highlightText}
                        icon={<Highlight htmlColor="#aaa" />}
                        checkedIcon={<Highlight color="primary" />}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <GridLayoutSelector gridCols={gridCols} onChange={handleGridColsChange} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div ref={resultsWrapper}>
            <Grid container spacing={2} className={classes.results}>
              {marketers.map((marketer: Marketer) => (
                <Grid item xs={12} md={gridCols === 'cols' ? 6 : 12} key={`marketer-result-${marketer.id}-key`}>
                  <MarketerResultCard marketer={marketer} />
                </Grid>
              ))}
            </Grid>
          </div>
          <Grid container className={classes.bottomPager}>
            <Grid item xs={12}>
              <Pager
                totalItems={totalItems}
                page={currPage}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                pageSize={pageSize}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default MarketerSearchResults;
