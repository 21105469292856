import { makeStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {},
    sectionRootNoPaddingNoMargin: {
      paddingTop: 0,
      marginTop: 0,
    },
  }),
);

export default useStyles;
