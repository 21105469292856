import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: 24,
    position: 'relative',
  },
  actions: {
    display: 'flex',
    flexShrink: 1,
    gap: '1rem',
  },
  copyToSlackButton: {},
  heroHeader: {
    display: 'flex',
    flexGrow: 1,
    gap: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  heroImageContainer: {
    maxWidth: 256,
    maxHeight: 256,
    [theme.breakpoints.down('sm')]: {
      minWidth: 116,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: 'auto',
    },
  },
  heroImage: {
    display: 'block',
    width: '100%',
    paddingTop: '100%',
    minWidth: 192,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    boxShadow: '0px 2px 16px rgba(36, 24, 21, 0.19)',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      width: '100%',
      maxHeight: 384,
      maxWidth: 384,
      minWidth: 'unset',
    },
  },
  userName: {
    marginTop: 16,
  },
  heroDetailsContainer: {
    flexGrow: 1,
  },
}));

export default useStyles;
