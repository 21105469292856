import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Company, InternalTeam, Marketer, User } from '@mayple/types';
import List from '@material-ui/core/List';
import { SvgIconTypeMap } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import EntityListItem from '../EntityListItem';
import { ENTITY_TYPE } from '../../../../../app/consts';

const useStyles = makeStyles(() => ({
  root: {},
}));

type Entity = Company | User | InternalTeam | Marketer;

interface EntityListProps {
  entities: Entity[];
  entityType: ENTITY_TYPE;
  action?: {
    icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
    label?: string;
    handler: (entity: ENTITY_TYPE) => void;
  };
}

const EntityList: React.FC<EntityListProps> = (props) => {
  const classes = useStyles();
  const { entities, entityType, action } = props;
  return (
    <List className={classes.root}>
      {entities.map((entity) => (
        <EntityListItem key={`entity-list-item-${entity.id}`} entityType={entityType} entity={entity} action={action} />
      ))}
    </List>
  );
};

export default EntityList;
