import React from 'react';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { NotesDialogProps } from '../types';
import NotesEditor from '../NotesEditor';

import { Dialog } from '../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    dialogContent: {
      paddingTop: '2rem',
      height: '100%',
      maxHeight: 'calc(100vh - 64px)',
      display: 'flex',
    },
  }),
);

const NotesDialog: React.FC<NotesDialogProps> = (props) => {
  const classes = useStyles();
  const { open, onClose, entityId, entityType } = props;

  const formName = `${entityType}-note-editor`;
  const dispatch = useDispatch();

  const onSaveHandler = async () => {
    await dispatch(submit(formName));
  };

  const onSuccessfulSaveHandler = () => {
    onClose?.();
  };

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      fullScreen
      containerMaxWidth="md"
      title={`Update ${entityType} notes`}
      actions={[
        {
          label: 'Save & Exit',
          color: 'primary',
          variant: 'contained',
          onClick: onSaveHandler,
        },
      ]}
      classes={{ dialogContent: classes.dialogContent }}
    >
      <NotesEditor
        entityId={entityId}
        entityType={entityType}
        formName={formName}
        onSuccessfulSave={onSuccessfulSaveHandler}
      />
    </Dialog>
  );
};

export default NotesDialog;
