const mergeByProperty = <T extends Record<string, any>>(target: Array<T>, source: Array<T>, prop: string): T[] => {
  const targetCopy: T[] = target.map((v) => v);

  source.forEach((sourceElement) => {
    // @ts-ignore
    const targetElement = targetCopy.find((te) => sourceElement[prop] === te[prop]);

    // eslint-disable-next-line no-unused-expressions
    targetElement ? Object.assign(sourceElement, targetElement) : targetCopy.push(sourceElement);
  });

  return targetCopy;
};
export default mergeByProperty;
