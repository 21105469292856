import React, { FC, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Project } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { ProjectMarketerMatchQuery } from 'growl-graphql/dist/queries/ProjectMarketerMatchQuery';

import MarketerListItem from './MarketerListItem';
import getMerchantInitiatedIrregularChargeReasoningFromUser from '../../../logic/getMerchantInitiatedIrregularChargeReasoningFromUser';

import { TextDisplay } from '../../../../fe_common/client/components/display';
import { Button } from '../../../../fe_common/client/components/inputs';
import { RefreshSpinner } from '../../../../fe_common/client/components/atoms';
import useExpertMatchingData from '../../../../fe_common/client/logic/project/stage/onboarding/useExpertMatchingData';
import { ExpertMatchingStageData } from '../../../../fe_common/client/logic/project/stage/onboarding/useExpertMatchingData/types';

interface DeclineMatchedExpertButtonProps {
  project: Project | null | undefined;
  matchedExpert: ExpertMatchingStageData;
  onDecline?: () => void;
  onApprove?: () => void;
}

const useStyles = makeStyles(
  createStyles({
    root: {},
    actionsContainer: {
      display: 'flex',
      gap: '2rem',
    },
  }),
);

const DeclineOrApproveMatchedExpertButton: FC<DeclineMatchedExpertButtonProps> = (props) => {
  const { project, matchedExpert, onDecline, onApprove } = props;
  const classes = useStyles();

  const { marketerIntro, marketerId } = matchedExpert;

  const { loading, declineSelectedMatchedMarketer, approveSelectedMatchedMarketer, loadingMutation } =
    useExpertMatchingData(project);

  const { data: marketerMatchData } = useQuery(ProjectMarketerMatchQuery.query, {
    variables: { projectId: project?.id, marketerId },
    skip: !project?.id,
  });

  const marketerMatch = marketerMatchData?.projectMarketerMatch || null;

  const onClickDeclineExpert = async () => {
    // eslint-disable-next-line no-alert
    const result = window.confirm('Are you sure you want to decline the expert?');
    if (!result) {
      return;
    }
    if (marketerId) {
      await declineSelectedMatchedMarketer(marketerId);
      onDecline?.();
    }
  };

  const onClickApproveExpert = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const result = window.confirm('Are you sure you want to approve the expert?');
    if (!result) {
      return;
    }

    const merchantInitiatedIrregularChargeReasoning = getMerchantInitiatedIrregularChargeReasoningFromUser(project);

    if (marketerId && merchantInitiatedIrregularChargeReasoning !== null) {
      const approveSelectedMatchedMarketerResult = await approveSelectedMatchedMarketer(
        marketerId,
        merchantInitiatedIrregularChargeReasoning,
      );

      if (approveSelectedMatchedMarketerResult) {
        onApprove?.();
      }
    }
  }, [approveSelectedMatchedMarketer, marketerId, onApprove, project]);

  return (
    <div>
      {marketerMatch && (
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <MarketerListItem marketer={marketerMatch} />
          </Grid>
          <Grid item>
            <div className={classes.actionsContainer}>
              <Tooltip placement="top-start" title="Accept the expert instead of the client.">
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    label="Accept For Client"
                    disabled={loading || loadingMutation}
                    loading={loadingMutation}
                    onClick={onClickApproveExpert}
                  />
                </div>
              </Tooltip>
              <Tooltip
                placement="top-start"
                title="After declining the Expert he will not be visible anymore to the client. You will have to select a new
              Expert for company approval."
              >
                <div>
                  <Button
                    color="secondary"
                    variant="contained"
                    label="Remove"
                    disabled={loading || loadingMutation}
                    loading={loadingMutation}
                    onClick={onClickDeclineExpert}
                  />
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      )}
      {loading && (
        <div>
          Loading marketer {marketerId} <RefreshSpinner size={24} />
        </div>
      )}
      <TextDisplay color="textSecondary" variant="body2" gutterBottom>
        <strong>Expert intro:</strong> {marketerIntro}
      </TextDisplay>
      <br />

      <Divider />
    </div>
  );
};

export default DeclineOrApproveMatchedExpertButton;
