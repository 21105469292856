import React, { FC, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { MarketerPageTabs, MarketersPageProps } from './types';
import MarketersTable from '../../components/cpanel/components/organisms/Marketer/MarketersTable';
import MarketersPayoutReview from '../../components/cpanel/components/organisms/Marketer/MarketersPayoutReview';
import MultipleMarketersAvailability from '../../components/cpanel/components/organisms/Marketer/MultipleMarketersAvailability';
import AdvancedMarketerSearchWizard from '../../containers/marketer/wizards/AdvancedMarketerSearchWizard';

import TabContent from '../../../fe_common/client/components/atoms/TabContent';
import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';

import useStyles from './styles';

const MarketersPage: FC<MarketersPageProps> = (props) => {
  const classes = useStyles(props);
  const [selectedTab, setSelectedTab] = useState(MarketerPageTabs.advancedMarketerSearch);

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleTabChange = (event: React.ChangeEvent<unknown>, selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  return (
    <div className={classes.root}>
      <Tabs value={selectedTab} onChange={handleTabChange} classes={{ root: classes.tabsRoot }}>
        <Tab label="Marketers Table" />
        <Tab label="Advanced Marketer Search" />
        <Tab label="Multiple Marketers Availability" />
        <Tab label="Marketers Payout Review" />
      </Tabs>
      {selectedTab === MarketerPageTabs.marketersTable && (
        <TabContent>
          <Section title="Marketers" noBorder gutterTopSize={1}>
            <SectionContent>
              <MarketersTable />
            </SectionContent>
          </Section>
        </TabContent>
      )}
      {selectedTab === MarketerPageTabs.advancedMarketerSearch && (
        <TabContent>
          <AdvancedMarketerSearchWizard />
        </TabContent>
      )}
      {selectedTab === MarketerPageTabs.multipleMarketersAvailability && (
        <TabContent>
          <MultipleMarketersAvailability />
        </TabContent>
      )}
      {selectedTab === MarketerPageTabs.marketersPayoutReview && (
        <TabContent>
          <MarketersPayoutReview getAllMarketers />
        </TabContent>
      )}
    </div>
  );
};

export default MarketersPage;
