import React, { FC, /* lazy, */ Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES_REDIRECT } from '../../app/consts';
import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';

import HomePage from '../../pages/HomePage';
import Projects from '../../pages/ProjectsPage';
import ProjectDetailsPage from '../../pages/ProjectDetailsPage';
import ProjectLeadsPage from '../../pages/ProjectLeadsPage';
import ProjectLeadDetailsPage from '../../pages/ProjectLeadDetailsPage';
import MarketersPage from '../../pages/MarketersPage';
import MarketerDetailsPage from '../../pages/MarketerDetailsPage';
import UpdateMarketerDetailsPage from '../../pages/UpdateMarketerDetailsPage';
import CompaniesPage from '../../pages/CompaniesPage';
import CompanyDetailsPage from '../../pages/CompanyDetailsPage';
import InternalTeamsPage from '../../pages/InternalTeamsPage';
import InternalTeamsDetailsPage from '../../pages/InternalTeamsDetailsPage';
import UsersPage from '../../pages/UsersPage';
import UserDetailsPage from '../../pages/UserDetailsPage';
import MatchStatisticsReport from '../../pages/MatchStatisticsReport';
import PayInReport from '../../pages/PayInReport';
import InsightsFeedReport from '../../pages/InsightsFeedReport';
import ProjectCycleFinalizationPage from '../../pages/ProjectCycleFinalizationPage';
import ProjectCycleDetailsPage from '../../pages/ProjectCycleDetailsPage';
import ProjectBillingPage from '../../pages/ProjectBillingPage';
import ProjectBillingReviewPage from '../../pages/ProjectBillingReviewPage';
import MatchingSimulatorPage from '../../pages/MatchingSimulatorPage';
import AdministrationStatusReport from '../../pages/AdministrationStatusReportPage';
import Page404 from '../../pages/Page404';
import SearchResultsPage from '../../pages/SearchResultsPage';

import CronofyResultPage from '../../../fe_common/client/components/integrations/calendars/CronofyResultPage';
// const HomePage = lazy(() => import('../pages/HomePage'));
// const Projects = lazy(() => import('../pages/ProjectsPage'));
// const ProjectDetailsPage = lazy(() => import('../pages/ProjectDetailsPage'));
// const ProjectLeadsPage = lazy(() => import('../pages/ProjectLeadsPage'));
// const ProjectLeadDetailsPage = lazy(() => import('../pages/ProjectLeadDetailsPage'));
// const MarketersPage = lazy(() => import('../pages/MarketersPage'));
// const MarketerDetailsPage = lazy(() => import('../pages/MarketerDetailsPage'));
// const UpdateMarketerDetailsPage = lazy(() => import('../pages/UpdateMarketerDetailsPage'));
// const CompaniesPage = lazy(() => import('../pages/CompaniesPage'));
// const CompanyDetailsPage = lazy(() => import('../pages/CompanyDetailsPage'));
// const InternalTeamsPage = lazy(() => import('../pages/InternalTeamsPage'));
// const InternalTeamsDetailsPage = lazy(() => import('../pages/InternalTeamsDetailsPage'));
// const UsersPage = lazy(() => import('../pages/UsersPage'));
// const UserDetailsPage = lazy(() => import('../pages/UserDetailsPage'));
// const MatchStatisticsReport = lazy(() => import('../pages/MatchStatisticsReport'));
// const InsightsFeedReport = lazy(() => import('../pages/InsightsFeedReport'));
// const ProjectCycleFinalizationPage = lazy(() => import('../pages/ProjectCycleFinalizationPage'));
// const ProjectCycleDetailsPage = lazy(() => import('../pages/ProjectCycleDetailsPage'));
// const ProjectBillingPage = lazy(() => import('../pages/ProjectBillingPage'));
// const ProjectBillingReviewPage = lazy(() => import('../pages/ProjectBillingReviewPage'));
// const MatchingSimulator = lazy(() => import('../pages/MatchingSimulator'));
// const AdministrationStatusReport = lazy(() => import('../pages/AdministrationStatusReportPage'));
// const Page404 = lazy(() => import('../pages/Page404'));
// const CronofyResultPage = lazy(() =>
//   import('../../fe_common/client/components/integrations/calendars/CronofyResultPage')
// );

const Routes: FC = () => (
  <Suspense fallback={<PageLoadingPlaceholder />}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/search-results/:searchTerm" component={SearchResultsPage} />
      <Route exact path={ROUTES_REDIRECT.CRONOFY_AUTH} component={CronofyResultPage} />
      <Route exact path="/marketers" component={MarketersPage} />
      <Route exact path="/marketers/:marketerId" component={MarketerDetailsPage} />
      <Route exact path="/marketers/:marketerId/update" component={UpdateMarketerDetailsPage} />
      <Route exact path="/companies" component={CompaniesPage} />
      <Route exact path="/companies/:companyId" component={CompanyDetailsPage} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/project_leads" component={ProjectLeadsPage} />
      <Route exact path="/project_leads/:projectLeadId" component={ProjectLeadDetailsPage} />
      <Route exact path="/projects/simulator" component={MatchingSimulatorPage} />
      <Route exact path="/projects/:projectId" component={ProjectDetailsPage} />
      <Route exact path="/projects/:projectId/billing" component={ProjectBillingPage} />
      <Route exact path="/projects/:projectId/simulator" component={MatchingSimulatorPage} />
      <Route exact path="/projects/:projectId/billing/:cycleId" component={ProjectCycleFinalizationPage} />
      <Route exact path="/projects/:projectId/cycles/:cycleId" component={ProjectCycleDetailsPage} />
      <Route exact path="/internal-accounts" component={InternalTeamsPage} />
      <Route exact path="/internal-accounts/:internalTeamId" component={InternalTeamsDetailsPage} />
      <Route exact path="/users" component={UsersPage} />
      <Route exact path="/users/:userId" component={UserDetailsPage} />
      <Route exact path="/billings/" component={ProjectBillingReviewPage} />
      <Route exact path="/billings/overview" component={AdministrationStatusReport} />
      <Route exact path="/reports/pay-in" component={PayInReport} />
      <Route exact path="/reports/matching" component={MatchStatisticsReport} />
      <Route exact path="/reports/insights" component={InsightsFeedReport} />
      <Route path="*" component={Page404} />
    </Switch>
  </Suspense>
);

export default Routes;
