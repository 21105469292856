/* eslint-disable no-unused-vars */
// Redux-form supporting currency input
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';

import { Select } from '../Registration/Select';
import NumericField from '../NumericField';

import { SUPPORTED_CURRENCIES, CURRENCY_TYPE_PAIRS } from '../../../app/enums';
import { clientLogger } from '../../../services/logger';

const styles = () => ({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  currencyValueRoot: {
    width: '100%',
  },
});

class CurrencyAmountField extends React.Component {
  // I think this should be removed
  static currency = CURRENCY_TYPE_PAIRS;

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);

    const { input, currency: defaultCurrency, hideCurrencySelect } = props;
    const { value = {} } = input;
    const { value: initialValue = '', currency = '' } = value;

    if (hideCurrencySelect && !(currency || defaultCurrency)) {
      clientLogger.error('Missing default currency. Please supply currency value when hideCurrencySelect=true');
    }

    this.state = {
      value: initialValue,
      currency: currency || defaultCurrency,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.input?.value) !== JSON.stringify(this.props.input?.value)) {
      this.setCurrencyValue(this.props.input?.value);
    }
  }

  handleCurrencyOnChange = (value) => {
    this.handleOnChange(value, 'currency');
  };

  handleCurrencyOnSelect = (value) => {
    this.setState({ currency: value });
  };

  handleAmountOnChange = (value) => {
    const newValue = value !== '' && !Number.isNaN(value) ? +value : '';
    this.handleOnChange(newValue, 'value');
  };

  handleOnChange = (newValue, fieldToUpdate) => {
    const { input } = this.props;
    const { onChange } = input;

    const newState = {
      ...this.state,
      [fieldToUpdate]: newValue,
    };

    this.setState(newState);

    onChange(newState);
  };

  setCurrencyValue = (newValue) => {
    let value = newValue?.value || '';
    if (newValue?.value === 0) {
      value = 0;
    }
    const currency = newValue?.currency || this.props.currency || 'USD';

    this.setState({
      value,
      currency,
    });
  };

  render() {
    // console.log('CurrencyField props', this.props);
    const {
      classes,
      maximumValue,
      minimumValue,
      required,
      variant,
      label,
      meta,
      hideCurrencySelect,
      onEnterPress,
      supportedCurrencies = SUPPORTED_CURRENCIES,
      precision,
      placeholder,
    } = this.props;

    const { error, invalid, pristine, submitFailed } = meta;
    const { value, currency } = this.state;
    const hasError = (submitFailed || !pristine) && invalid && error !== '';

    let InputProps;

    if (currency) {
      const { symbol = '' } = supportedCurrencies.find((curr) => curr.value === currency);
      InputProps = { startAdornment: <InputAdornment position="start">{symbol}</InputAdornment> };
    }

    return (
      <Grid
        container
        className={classes.root}
        alignItems="center"
        justifyContent="space-between"
        spacing={hideCurrencySelect ? 0 : 2}
      >
        {!hideCurrencySelect && (
          <Grid item sm={3} xs={12}>
            <Select
              enums={supportedCurrencies}
              label="Currency"
              required={required}
              input={{
                value: currency,
                onChange: this.handleCurrencyOnChange,
              }}
              onSelect={this.handleCurrencyOnSelect}
            />
          </Grid>
        )}
        <Grid item sm={hideCurrencySelect ? 12 : 9} xs={12}>
          <NumericField
            name="currencyValue"
            label={label}
            value={value}
            classes={{ root: classes.currencyValueRoot }}
            variant={variant}
            fullWidth
            required={required}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            placeholder={placeholder}
            currency={currency}
            InputProps={InputProps}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleAmountOnChange}
            onEnterPress={onEnterPress}
            error={hasError}
            helperText={hideCurrencySelect && hasError ? error : ''}
            precision={precision}
            meta={meta}
          />
        </Grid>
        {hasError && !hideCurrencySelect && !pristine && (
          <Grid item xs={12} style={{ paddingTop: 0, marginLeft: 14, marginRight: 14 }}>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
        )}
      </Grid>
    );
  }
}

CurrencyAmountField.propTypes = {
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  maximumValue: PropTypes.number,
  minimumValue: PropTypes.number,
  currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
  hideCurrencySelect: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
      maximum: PropTypes.number,
      minimum: PropTypes.number,
    }),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.object,
  supportedCurrencies: PropTypes.arrayOf(PropTypes.object),
  precision: PropTypes.number,
  placeholder: PropTypes.string,
};

CurrencyAmountField.defaultProps = {
  minimumValue: Number.MIN_SAFE_INTEGER,
  maximumValue: Number.MAX_SAFE_INTEGER,
  variant: 'outlined',
  required: false,
  label: '',
  currency: '',
  hideCurrencySelect: false,
  input: {
    value: {
      value: '',
      currency: '',
      minimum: Number.MIN_SAFE_INTEGER,
      maximum: Number.MAX_SAFE_INTEGER,
    },
    onChange: () => {},
  },
  meta: {},
  supportedCurrencies: SUPPORTED_CURRENCIES,
  precision: 0,
  placeholder: '',
};

export default withStyles(styles)(CurrencyAmountField);
