/* eslint-disable class-methods-use-this */
import { MonthlyMarketerAssessmentByCompany } from '@mayple/types';
import { SetMonthlyMarketerAssessmentByCompanyForCycleMutation } from 'growl-graphql/dist/mutations/tasks/MonthlyMarketerAssessmentByCompany/SetMonthlyMarketerAssessmentByCompanyForCycleMutation';
import { SkipMonthlyMarketerAssessmentByCompanyForCycleMutation } from 'growl-graphql/dist/mutations/tasks/MonthlyMarketerAssessmentByCompany/SkipMonthlyMarketerAssessmentByCompanyForCycleMutation';

import { FormValues } from '../types';
import IAssessment from './IAssessment';
import { AssessmentSubTask } from '../../../types';
import { marketerAssessmentByCompanyParameterKeys } from '../consts';

class MonthlyMarketerAssessmentByCompanyConcrete implements IAssessment {
  skipMutation = SkipMonthlyMarketerAssessmentByCompanyForCycleMutation;

  skipMutationResponseKey = 'skipMonthlyMarketerAssessmentByCompanyForCycle';

  saveMutation = SetMonthlyMarketerAssessmentByCompanyForCycleMutation;

  saveMutationResponseKey = 'setMonthlyMarketerAssessmentByCompanyForCycle';

  getAssessmentVotes(): string[] {
    return marketerAssessmentByCompanyParameterKeys;
  }

  getFormValuesFromSubTask(
    subTask: AssessmentSubTask,
    votes: Record<string, any>,
    assessmentComment: string | null | undefined
  ): FormValues {
    const monthlyMarketerAssessmentByCompany = subTask as MonthlyMarketerAssessmentByCompany;
    return {
      cycleId: monthlyMarketerAssessmentByCompany?.cycleId,
      projectId: monthlyMarketerAssessmentByCompany?.projectId,
      votes,
      assessmentComment,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSubmitFormVariables(_formValues: FormValues): Record<string, any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSkipFormVariables(_formValues: FormValues): Record<string, any> {
    throw new Error('Method not implemented.');
  }
}

export default MonthlyMarketerAssessmentByCompanyConcrete;
