import { emailLinkFormatter, externalLinkFormatter, phoneLinkFormatter } from '../../../../../logic/linkFormatters';

import { STATES_LABELS_LOOKUP } from '../../../../../../fe_common/client/app/enums';
import { toNiceCountriesList, toNiceEnumsList } from '../../../../../../fe_common/client/services/utils';
import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';

const PROPS_GROUPS: Record<string, string> = {
  CONTACT_DETAILS: 'Contact details',
  POSTAL_ADDRESS: 'Postal Address',
};

const getEntityContactDetailsProperties = (): PropertiesMetaType[] => [
  {
    label: 'Email',
    path: 'emailAddress',
    formatter: emailLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Alt Email',
    path: 'alternativeEmailAddress',
    formatter: emailLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Website',
    path: 'websiteAddress',
    formatter: externalLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Tel',
    path: 'phoneNumber',
    formatter: phoneLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Alt Tel',
    path: 'alternativePhoneNumber',
    formatter: phoneLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Linkedin',
    path: 'linkedinProfileAddress',
    formatter: externalLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },

  {
    label: 'Street 1',
    path: 'postalAddress.street1',
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
  {
    label: 'Street 2',
    path: 'postalAddress.street2',
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
  {
    label: 'City',
    path: 'postalAddress.city',
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
  {
    label: 'State',
    path: 'postalAddress.state',
    formatter: (value) => toNiceEnumsList([value], STATES_LABELS_LOOKUP),
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
  {
    label: 'Country',
    path: 'postalAddress.country',
    formatter: (value) => toNiceCountriesList([value]),
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
  {
    label: 'Postal Code',
    path: 'postalAddress.postCode',
    group: PROPS_GROUPS.POSTAL_ADDRESS,
  },
];

export default getEntityContactDetailsProperties;
