import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colorPalette } from '../../../../../fe_common/client/app/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '3rem',
    },
    cardContent: {
      display: 'flex',
      gap: '4rem',
      justifyContent: 'stretch',
    },
    assessmentMonth: {
      fontWeight: 'bold',
    },
    assessmentDate: {
      color: colorPalette.gray['80'],
    },
    assessmentVotes: {
      flexGrow: 1,
    },
    vote: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colorPalette.gray['10']}`,
      gap: '2rem',
      padding: '0.5rem 0',
      width: '100%',
    },
  }),
);

export default useStyles;
