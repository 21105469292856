import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../../../../../fe_common/client/app/theme';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {},
  tableRoot: {},
  tableHead: {
    backgroundColor: colors.white_darkest,
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  number: {
    textAlign: 'right',
  },
  total: {
    fontWeight: 'bold',
  },
  positive: {
    color: 'green',
  },
  negative: {
    color: 'red',
  },
}));
