import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { NotesButtonProps } from '../types';
import NotesDialog from '../NotesDialog';

import { Button } from '../../../../../fe_common/client/components/inputs';
import { useDialog } from '../../../../../fe_common/client/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const NotesButton: React.FC<NotesButtonProps> = (props) => {
  const classes = useStyles(props);
  const { entityId, entityType, onClick } = props;
  const { open, openDialog, closeDialog } = useDialog();

  const onClickHandler = () => {
    onClick?.();
    openDialog();
  };

  return (
    <div className={classes.root}>
      <Button
        label={`Edit ${entityType} Notes`}
        onClick={onClickHandler}
        color="primary"
        variant="outlined"
        size="small"
      />
      {open && <NotesDialog entityType={entityType} entityId={entityId} open={open} onClose={closeDialog} />}
    </div>
  );
};

export default NotesButton;
