import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import ProjectBillingConfigurationForm from './ProjectBillingConfigurationForm';

import Section, { SECTION_LAYOUT, SectionContent } from '../../../../../fe_common/client/components/atoms/Section';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

type ProjectBillingConfigurationProps = {
  projectId: number;
  classes?: Record<string, string>;
};

const ProjectBillingConfiguration: React.FC<ProjectBillingConfigurationProps> = (props) => {
  const classes = useStyles(props);
  const { projectId } = props;

  return (
    <div className={classes.root}>
      <Section
        title="Project Billing Configuration"
        subTitle="Client Billing Configuration for special cases"
        noBorder
        layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
      >
        <SectionContent>
          <ProjectBillingConfigurationForm projectId={projectId} />
        </SectionContent>
      </Section>
    </div>
  );
};

export default ProjectBillingConfiguration;
