import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import {
  validateCurrencyRequired,
  validateNumericRangeMinimumMaximumValues,
  validateNumericRangeRequired,
} from '../../../../services/validation';
import NumberRangeInput from '../../NumberRangeInput';
import { SUPPORTED_CURRENCIES } from '../../../../app/enums';

export class WizardNumericRangeField extends React.Component {
  constructor(props) {
    super(props);
    // console.log('WizardNumericRangeField', props);

    const { fieldType, isRequired, minimumValue, maximumValue } = props;

    const validator = isRequired ? [validateNumericRangeRequired] : [];

    if (isRequired && fieldType === 'currency') {
      validator.push(validateCurrencyRequired);
    }

    validator.push(validateNumericRangeMinimumMaximumValues(minimumValue, maximumValue));

    this.state = {
      validator,
    };
  }

  render() {
    const {
      name,
      fieldType,
      minimumValue,
      maximumValue,
      onBlur,
      parse,
      fullWidth,
      autoFocus,
      helperText,
      variant,
      currency,
      onEnterPress,
      supportedCurrencies,
    } = this.props;

    const { validator } = this.state;

    return (
      <div>
        <Field
          component={NumberRangeInput}
          fieldType={fieldType}
          name={name}
          fullWidth={fullWidth}
          validate={validator}
          onBlur={onBlur}
          parse={parse}
          autoFocus={autoFocus} // only 1 input should get this prop per step
          helperText={helperText}
          variant={variant}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          currency={currency}
          supportedCurrencies={supportedCurrencies}
          onKeyUp={(e) => {
            e.preventDefault();
            if (onEnterPress && (e.key === 'Enter' || e.keyCode === 13)) {
              onEnterPress(e);
            }
          }}
        />
      </div>
    );
  }
}

WizardNumericRangeField.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  fieldType: PropTypes.oneOf(['currency', 'number']),
  currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  isRequired: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onEnterPress: PropTypes.func,
  parse: PropTypes.any,
  helperText: PropTypes.any,
};

WizardNumericRangeField.defaultProps = {
  floatingLabelText: '',
  hintText: '',
  label: '',
  regexErrorMessage: '',
  inputAdornment: '',
  fieldType: 'number',
  variant: 'outlined',
  autoFocus: false,
  isRequired: true,
  fullWidth: true,
  helperText: null,
  minimumValue: 0,
  maximumValue: 0,
  onBlur: () => {},
  onEnterPress: () => {},
  parse: undefined,
  currency: '',
  input: {
    value: {
      minimum: '',
      maximum: '',
      currency: '',
    },
  },
};
