import { TaskType } from '@mayple/types';
import { GetSubTasksOfPostProjectMatchOfferMarketerAssessmentTaskQuery } from 'growl-graphql/dist/queries/tasks/PostProjectMatchOfferMarketerAssessmentForProject/GetSubTasksOfPostProjectMatchOfferMarketerAssessmentTaskQuery';
import { GetSubTasksOfMonthlyMarketerAssessmentByGrowthStrategistTaskQuery } from 'growl-graphql/dist/queries/tasks/MonthlyMarketerAssessmentByGrowthStrategist/GetSubTasksOfMonthlyMarketerAssessmentByGrowthStrategistTaskQuery';
import { GetSubTasksOfMailchimpAuditAssessmentByCompanyByTaskQuery } from 'growl-graphql/dist/queries/tasks/Mailchimp/GetSubTasksOfMailchimpAuditAssessmentByCompanyByTaskQuery';
import { GetSubTasksOfOutbrainAuditAssessmentByCompanyByTaskQuery } from 'growl-graphql/dist/queries/tasks/Outbrain/GetSubTasksOfOutbrainAuditAssessmentByCompanyByTaskQuery';
import { GetSubTasksOfMonthlyMarketerAssessmentByCompanyTaskQuery } from 'growl-graphql/dist/queries/tasks/MonthlyMarketerAssessmentByCompany/GetSubTasksOfMonthlyMarketerAssessmentByCompanyTaskQuery';

import { QueryClass } from './types';

const getSubTasksQueryByTaskType = (taskType: TaskType | null): QueryClass | null => {
  switch (taskType) {
    case TaskType.PostProjectMatchOfferMarketerAssessment:
      return GetSubTasksOfPostProjectMatchOfferMarketerAssessmentTaskQuery;
    case TaskType.MonthlyMarketerAssessmentByGrowthStrategist:
      return GetSubTasksOfMonthlyMarketerAssessmentByGrowthStrategistTaskQuery;
    case TaskType.MonthlyMarketerAssessmentByCompany:
      return GetSubTasksOfMonthlyMarketerAssessmentByCompanyTaskQuery;
    case TaskType.MailchimpAuditAssessmentByCompany:
      return GetSubTasksOfMailchimpAuditAssessmentByCompanyByTaskQuery;
    case TaskType.OutbrainAuditAssessmentByCompany:
      return GetSubTasksOfOutbrainAuditAssessmentByCompanyByTaskQuery;
    default:
      return null;
    // throw new Error(`Unknown or missing task type ${taskType}`);
  }
};

export default getSubTasksQueryByTaskType;
