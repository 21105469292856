import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InfoIcon from '@material-ui/icons/HelpOutline';

import { CycleDataRowProps } from './types';
import { TextDisplay } from '../../../../../../../../fe_common/client/components/display';

import useStyles, { getDefaultClasses } from '../style';

const withToolTip = (label: string, tooltip: string, classes: Record<string, string>, reverse = false) => {
  if (!tooltip) {
    return label;
  }

  return (
    <span className={classNames(classes.labelTooltip, { [classes.labelTooltipReverse]: reverse })}>
      {label}
      <Tooltip title={tooltip}>
        <InfoIcon fontSize="small" className={classes.labelTooltipIcon} />
      </Tooltip>
    </span>
  );
};

/**
 * This component renders each row of data in CyclesSideBySide component
 * This component is recursive and can render other CycleDataRow, given the rowData.extendedData
 *
 * @returns {null|*}
 * @param props
 */
const CycleDataRow: FC<CycleDataRowProps> = (props) => {
  const { rowData, show = true, hasNextCycle } = props;

  const classes = useStyles(props);

  const defaultClasses = getDefaultClasses(classes);

  const {
    label, // string to be used in the first column to describe the row data
    tooltip, // extra string data displayed when hovering the label
    currCycleValue, // string value displaying the data from the currentCycle
    currCycleValueTooltip, // extra string data displayed when hovering the curr value
    currCycleExtraLabel, // string value used to elaborate on the value of currentCycle
    nextCycleValue, // string value displaying the data from the nextCycle
    nextCycleValueTooltip, // extra string data displayed when hovering the curr value
    nextCycleExtraLabel, // string value used to elaborate on the value of nextCycle
    customClasses = defaultClasses, // Set of classes with a predefined structure to decorate the row and labels
    extendedData = [], // hold same structure of rowData, representing lines that their display depends on parent line
  } = rowData;

  const [isExpandingRow] = useState(() => extendedData && extendedData.length > 0);
  const [showExtraData, setShowExtraData] = useState<boolean>(false);

  const onShowExtraDataClickHandler = (prevState: boolean) => {
    setShowExtraData(!prevState);
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <TableRow key={`table-row-cycle-side-by-side-${label}`} className={customClasses.row}>
        <TableCell className={classes.cellExpand}>
          {isExpandingRow && (
            <IconButton
              onClick={() => {
                onShowExtraDataClickHandler(showExtraData);
              }}
            >
              {showExtraData ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell className={classes.cellLabel}>
          <TextDisplay variant="body1" className={customClasses.cellLabel}>
            {withToolTip(label, tooltip, classes)}
          </TextDisplay>
        </TableCell>
        <TableCell className={classes.cellCurrExtra}>
          <TextDisplay variant="body1" className={customClasses.cellCurrExtra}>
            {currCycleExtraLabel}
          </TextDisplay>
        </TableCell>
        <TableCell className={classes.cellCurrValue}>
          <TextDisplay variant="body1" className={customClasses.cellCurrValue}>
            {withToolTip(currCycleValue, currCycleValueTooltip, classes, true)}
          </TextDisplay>
        </TableCell>
        {hasNextCycle && (
          <>
            <TableCell className={classes.cellNextValue}>
              <TextDisplay variant="body1" className={customClasses.cellNextValue}>
                {withToolTip(nextCycleValue, nextCycleValueTooltip, classes, true)}
              </TextDisplay>
            </TableCell>
            <TableCell className={classes.cellNextExtra}>
              <TextDisplay variant="body1" className={customClasses.cellNextExtra}>
                {nextCycleExtraLabel}
              </TextDisplay>
            </TableCell>
          </>
        )}
      </TableRow>

      {extendedData &&
        extendedData.length > 0 &&
        extendedData.map((extendedRowData) => {
          const { label: extendedLabel } = extendedRowData;
          return (
            <CycleDataRow
              key={`table-row-extended-${extendedLabel}`}
              rowData={extendedRowData}
              show={showExtraData}
              classes={classes}
              hasNextCycle={hasNextCycle}
            />
          );
        })}
    </>
  );
};

export default CycleDataRow;
