import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { CronofyAvailabilityRulesElementTokenQuery } from 'growl-graphql/dist/queries/CronofyAvailabilityRulesElementTokenQuery';
import { CronofyElementToken } from '@mayple/types';
import { useCronofyIntegration } from '../../CronofyIntegrationManager/hooks';

interface CronofyIntegrationReturnType {
  elementToken: CronofyElementToken;
  isLoading: boolean;
  error: ApolloError | undefined;
}

const useCronofyElementToken = (): CronofyIntegrationReturnType => {
  const { cronofyIntegration } = useCronofyIntegration();
  const { id: integrationId } = cronofyIntegration || {};
  const { origin } = window.location;

  const variables = { integrationId, origin };

  const {
    data: elementTokenQueryData,
    loading: isElementTokenQueryLoading,
    error,
  } = useQuery(CronofyAvailabilityRulesElementTokenQuery.query, { variables, skip: !integrationId });

  const cronofyAvailabilityRulesElementToken = elementTokenQueryData?.cronofyAvailabilityRulesElementToken;

  return {
    elementToken: cronofyAvailabilityRulesElementToken,
    isLoading: isElementTokenQueryLoading,
    error,
  };
};

export default useCronofyElementToken;
