import { ProjectLifeCycleStatus, ProjectStage } from '@mayple/types';

import { PROJECT_DETAILS_TABS } from './consts';

const getDefaultTabByProjectLifeCycleStatusAndProjectStage = (
  projectLifeCycleStatus: ProjectLifeCycleStatus,
  projectStage: ProjectStage,
): PROJECT_DETAILS_TABS => {
  if (window.location.hash) {
    return window.location.hash.replace('#', '') as PROJECT_DETAILS_TABS;
  }

  switch (projectStage) {
    case ProjectStage.DATA_ENRICHMENT:
      return PROJECT_DETAILS_TABS.basicDetails;
    case ProjectStage.MARKETING_PLAN:
      return PROJECT_DETAILS_TABS.onboarding;
    case ProjectStage.MARKETER_APPROVAL:
      return PROJECT_DETAILS_TABS.matchingAndOpportunities;
    case ProjectStage.OPPORTUNITIES:
      return PROJECT_DETAILS_TABS.matchingAndOpportunities;
    case ProjectStage.EXISTING_MARKETING_EVALUATION:
      return PROJECT_DETAILS_TABS.onboarding;
    default:
      break;
  }

  switch (projectLifeCycleStatus) {
    case ProjectLifeCycleStatus.LIVE:
      return PROJECT_DETAILS_TABS.servicesAndBilling;
    case ProjectLifeCycleStatus.ONBOARDING:
      return PROJECT_DETAILS_TABS.onboarding;
    case ProjectLifeCycleStatus.FINISHED:
      return PROJECT_DETAILS_TABS.servicesAndBilling;
    default:
      return PROJECT_DETAILS_TABS.basicDetails;
  }
};

export default getDefaultTabByProjectLifeCycleStatusAndProjectStage;
