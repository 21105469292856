import { ProjectBillingPointBehavior } from '@mayple/types';

import { ProjectBillingPoint } from './types';

export const getBillingBehaviorTextForAdmin = (type: ProjectBillingPointBehavior | null | undefined): string => {
  switch (type) {
    case ProjectBillingPointBehavior.AUTHORIZE_CHARGE_ON_PAYMENT_AGREEMENT:
      return 'Authorize only. No charge.';
    case ProjectBillingPointBehavior.CAPTURE_CHARGE_ON_PAYMENT_AGREEMENT:
      return 'Charge payment agreement.';
    case ProjectBillingPointBehavior.DO_NOTHING:
      return 'Do nothing.';
    default:
      return 'N/A';
  }
};

export const isProjectBillingPointBehaviorOptionAllowed = (
  _projectBillingPoint: ProjectBillingPoint,
  _option: ProjectBillingPointBehavior | null | undefined,
  createdByMarketerInvitation = false
): boolean => !createdByMarketerInvitation;
