import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    boxShadow: '0 1px 4px rgba(51, 53, 82, 0.12), 0 1px 6px rgba(51, 53, 82, 0.12)',
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },
  avatarContainer: {},
  avatarContainerOnActiveMode: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  postCommentEditorContainer: {
    width: '100%',
    flexWrap: 'nowrap',
  },
  postCommentEditorContainerActive: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  postCommentEditorContainerIdle: {},
  formContainer: {
    flexGrow: 1,
  },
  avatar: {
    marginRight: 16,
  },
  input: {
    borderRadius: 8,
    border: '1px solid rgba(36, 24, 21, 0.1)',
    padding: '8px 24px',
    margin: '2px auto',
    maxHeight: 40,
  },
  inputIdle: {
    background: 'rgba(36, 24, 21, 0.05)',
    transition: 'background 100ms ease-in',
    '&:hover': {
      transition: 'background 0.1s ease-in',
      background: 'rgba(36, 24, 21, 0.1)',
    },
  },
  inputIdleClicked: {
    transition: 'background 50ms ease-in',
    background: 'rgba(36, 24, 21, 0.25)',
  },
  cancelButton: {
    marginRight: 8,
  },
}));

const wizardFormStyles = () => ({
  root: {},
  actionsContainer: {},
  fileUploadButton: {
    minWidth: 'unset',
    margin: 0,
    padding: 10,
  },
  fileUploadButtonStartIcon: {
    margin: 0,
  },
  topicSelect: {
    lineHeight: '1.4em',
    minHeight: '1.4em',
    textTransform: 'capitalize',
    paddingTop: 16,
    paddingBottom: 16,
    '& > option': {
      textTransform: 'capitalize',
    },
  },
  postContent: {},
});
export const useWizardFormStyles = makeStyles(wizardFormStyles);
