import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { getGradientColor } from '../../../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: '1.5rem',
    },
    mobileStepper: {
      background: 'transparent',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {},
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    headline: {
      fontWeight: 'bold',
      textAlign: 'center',
    },
    subHeadline: {
      textAlign: 'center',
      color: getGradientColor('gray', 60),
    },
    formBody: {
      paddingTop: '2rem',
      paddingBottom: '1rem',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    question: {
      marginBottom: '3rem',
    },
    assessmentComment: {
      flexGrow: 1,
      width: '100%',
    },
    entityAvatarRoot: {
      flexDirection: 'column',
    },
    entityAvatarAvatar: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    entityAvatarPrimary: {
      color: getGradientColor('gray', 80),
    },
    vote: {
      marginBottom: '1rem',
    },
    formActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    nextButton: {
      paddingRight: '3rem',
      paddingLeft: '3rem',
    },
    skipButton: {},
  })
);
export default useStyles;
