import React from 'react';
import { InternalTeam, SupervisorType } from '@mayple/types';

import useProjectSupervisorValidation from './useProjectSupervisorValidation';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { Button } from '../../../../../fe_common/client/components/inputs';

type ProjectSupervisorCheckerProps = {
  projectId: number | null | undefined;
  supervisor: InternalTeam | null | undefined;
  supervisorType: SupervisorType;
  allowedSupervisorTypes: SupervisorType[];
};

const ProjectSupervisorChecker: React.FC<ProjectSupervisorCheckerProps> = (props) => {
  const { projectId, supervisor, supervisorType, allowedSupervisorTypes } = props;

  const { missingProjectSupervisor, missingMC, missingGS } = useProjectSupervisorValidation(
    supervisor,
    supervisorType,
    allowedSupervisorTypes,
  );

  let message = '';

  if (missingGS) {
    message = 'Please assign a main GS.';
  }

  if (missingMC) {
    message = 'Please assign a main Marketing Consultant.';
  }

  if (!missingProjectSupervisor) {
    return null;
  }

  return (
    <CalloutMessage
      margin
      type="error"
      message={message}
      alternativeActionButton={
        <Button
          label="set main GS / MC"
          href={`/projects/${projectId}#internalTeams`}
          variant="contained"
          color="primary"
        />
      }
    />
  );
};

export default ProjectSupervisorChecker;
