import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

import { FullScreenDialogActions, FullScreenDialogProps } from './types';
import { Button } from '../../inputs';
import TextDisplay from '../../display/TextDisplay';
import DialogCloseButton from '../../display/Dialog/components/DialogCloseButton';

import useStyles from './style';

const FullScreenDialog: FC<FullScreenDialogProps> = (props) => {
  const classes = useStyles(props);

  const { open, onClose, title, loading = false, actions, children, ...rest } = props;
  const { primaryAction, secondaryAction } = actions || ({} as FullScreenDialogActions);

  return (
    <Dialog fullScreen open={open} {...rest}>
      <AppBar color="transparent" position="fixed">
        <Toolbar className={classes.toolbarRoot}>
          <DialogCloseButton onClose={onClose} />
          <div className={classes.titlesWrapper}>
            {!!title && (
              <div className={classes.title}>
                <TextDisplay variant="h5"> {title}</TextDisplay>
              </div>
            )}
          </div>
          <div className={classes.dialogActions}>
            {secondaryAction && <Button variant="text" color="primary" {...secondaryAction} />}
            {primaryAction && <Button variant="contained" color="primary" {...primaryAction} />}
          </div>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <DialogContent className={classes.content}>{children}</DialogContent>
    </Dialog>
  );
};

export default FullScreenDialog;
