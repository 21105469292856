import { useCallback, useEffect, useState } from 'react';
import { CronofyAuthorizationRequestInfo, CronofyIntegrationSettings, Integration } from '@mayple/types';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/react-hooks';
import { CronofyAuthorizationRequestInfoQuery } from 'growl-graphql/dist/queries/CronofyAuthorizationRequestInfoQuery';
import { AddCronofyIntegrationMutation } from 'growl-graphql/dist/mutations/AddCronofyIntegrationMutation';

import { CronofyIntegrationResult } from '../types';
import popupCenter from '../popupCenter';
import { useDialog, useMutation } from '../../../../../hooks';
import { CRONOFY_AUTH_PATH, CRONOFY_BASE_URL, CRONOFY_EVENT_SOURCE_NAME } from '../consts';
import { useCalendarIntegrationText } from '../texts';

const getRedirectUriOrigin = (uri: string) => {
  const regEx = /^(https?:\/\/[^/]*)/i;
  const match = uri.match(regEx);
  if (match?.length && match?.length > 0) {
    return match[0];
  }
  return uri;
};

type UseCronofyIntegrationManagerReturnValue = {
  onConnectIntegrationClick: () => void;
  isExplanationDialogOpen: boolean;
  onExplanationDialogClose: () => void;
  isAvailabilityDialogOpen: boolean;
  onAvailabilityDialogClose: () => void;
  onEditAvailabilityClick: () => void;
  onCalendarAuthorizationClick: () => void;
  clearErrorMessage: () => void;
  disabled: boolean;
  error: string;
  isLoading: boolean;
};

export type CronofyIntegrationManagerInput = {
  redirectUri: string;
  cronofyIntegration: Integration | null;
  refetchCronofyIntegration: () => Promise<any>;
  onSuccess?: () => void;
  state?: Record<string, any>;
  onError?: (err: any) => void;
};

/**
 * This holds all the logic to be executed in CronofyIntegrationManager Component
 */
export const useCronofyIntegrationManager = (
  cronofyIntegrationManagerInput: CronofyIntegrationManagerInput,
): UseCronofyIntegrationManagerReturnValue => {
  const { redirectUri, cronofyIntegration, onSuccess, refetchCronofyIntegration, onError, state } =
    cronofyIntegrationManagerInput;

  const {
    open: isExplanationDialogOpen,
    openDialog: openExplanationDialog,
    closeDialog: closeExplanationDialog,
  } = useDialog(false);

  const {
    open: isAvailabilityDialogOpen,
    openDialog: openAvailabilityDialog,
    closeDialog: closeAvailabilityDialog,
  } = useDialog(false);

  const {
    data: dataCronofyAuthorizationRequest,
    loading: loadingCronofyAuthorizationRequest,
    error: errorCronofyAuthorizationRequest,
  } = useQuery(CronofyAuthorizationRequestInfoQuery.query);

  const {
    mutate: addIntegration,
    error: errorAddIntegration,
    loading: loadingAddIntegration,
  } = useMutation(AddCronofyIntegrationMutation);

  const { addIntegrationErrorMessage } = useCalendarIntegrationText(cronofyIntegration);

  const [error, setError] = useState('');

  const [clientId, setClientId] = useState('');
  const [scope, setScope] = useState('');
  const [avoidLinking, setAvoidLinking] = useState(false);

  const redirectUriOrigin = getRedirectUriOrigin(redirectUri);

  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  const isLoading = loadingCronofyAuthorizationRequest || loadingAddIntegration;

  useEffect(() => {
    if (errorCronofyAuthorizationRequest?.message) {
      setError(errorCronofyAuthorizationRequest.message);
    }
    if (errorAddIntegration?.message) {
      setError(addIntegrationErrorMessage);
    }
  }, [errorCronofyAuthorizationRequest, addIntegrationErrorMessage, errorAddIntegration]);

  const onSuccessfulIntegrationCallback = useCallback(
    (result: CronofyIntegrationResult): void => {
      const { code } = result;

      const cronofyIntegrationSettings: CronofyIntegrationSettings = {
        code,
        redirectUri,
      };

      if (timeZone) {
        cronofyIntegrationSettings.browserInfo = {
          tzid: timeZone,
        };
      }

      const variables = {
        cronofyIntegrationSettings,
      };

      Sentry.captureMessage(JSON.stringify(variables), 'info');

      const saveData = async () => {
        try {
          const executionResult = await addIntegration({ variables });

          if (executionResult?.data?.addCronofyIntegration?.success === false) {
            Sentry.captureException(executionResult?.data?.addCronofyIntegration?.result);

            // report generic error to the user
            setError(addIntegrationErrorMessage);

            // call external onError with the result
            if (onError) {
              onError(executionResult);
            }
            return false;
          }

          // call external onSuccess
          onSuccess?.();

          await refetchCronofyIntegration();

          return true;
        } catch (err) {
          Sentry.captureException(err);
          if (onError) {
            onError(err);
          }
          return false;
        }
      };

      saveData().then((saveDataSuccess) => {
        if (saveDataSuccess) {
          openAvailabilityDialog();
        }
      });
    },
    [
      addIntegration,
      addIntegrationErrorMessage,
      onError,
      onSuccess,
      openAvailabilityDialog,
      redirectUri,
      refetchCronofyIntegration,
      timeZone,
    ],
  );

  useEffect(() => {
    const messageEventListener = (event: MessageEvent) => {
      // TODO: Implement what to do with the returned result failed
      if (event.origin !== redirectUriOrigin) {
        return;
      }

      if (event.data?.source !== CRONOFY_EVENT_SOURCE_NAME) {
        return;
      }

      if (event.data) {
        const { source, payload } = event.data;

        if (source === CRONOFY_EVENT_SOURCE_NAME) {
          // console.log('event.data', event.data);
          // Basically we will either call the onSuccess function or the onError function
          // This way we can do what ever we want with the auth code without being bounded to a specific set of actions
          onSuccessfulIntegrationCallback(payload);
        }
      }
    };

    // register a message listener that will be notified when the code was received
    window.addEventListener('message', messageEventListener, false);
    return () => {
      // remove the message listener when component unloads
      window.removeEventListener('message', messageEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingCronofyAuthorizationRequest && !errorCronofyAuthorizationRequest) {
      const { cronofyAuthorizationRequestInfo = {} as CronofyAuthorizationRequestInfo } =
        dataCronofyAuthorizationRequest;
      const { clientId: newClientId, scope: newScope, avoidLinking: newAvoidLinking } = cronofyAuthorizationRequestInfo;

      setClientId(newClientId);
      setScope(newScope);
      setAvoidLinking(newAvoidLinking);
    }
  }, [dataCronofyAuthorizationRequest, errorCronofyAuthorizationRequest, loadingCronofyAuthorizationRequest]);

  const onConnectIntegrationClick = () => {
    openExplanationDialog();
  };

  const clearErrorMessage = () => {
    setError('');
  };

  const onCalendarAuthorizationClick = useCallback(() => {
    closeExplanationDialog();

    let authUrl =
      `${CRONOFY_BASE_URL + CRONOFY_AUTH_PATH}` +
      `&client_id=${encodeURIComponent(clientId)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&scope=${encodeURIComponent(scope)}` +
      `&avoid_linking=${encodeURIComponent(avoidLinking)}`;

    const extendedState = {
      ...state,
    };

    const stateParam = JSON.stringify(extendedState);
    authUrl += `&state=${stateParam}`;

    popupCenter(authUrl, 'cronofy-auth', 520, 680);
  }, [clientId, redirectUri, scope, avoidLinking, state, closeExplanationDialog]);

  const onEditAvailabilityClick = () => {
    openAvailabilityDialog();
  };

  return {
    onConnectIntegrationClick,
    isExplanationDialogOpen,
    onExplanationDialogClose: closeExplanationDialog,
    isAvailabilityDialogOpen,
    onAvailabilityDialogClose: closeAvailabilityDialog,
    onCalendarAuthorizationClick,
    onEditAvailabilityClick,
    clearErrorMessage,
    disabled: isLoading || error !== '',
    isLoading,
    error,
  };
};

export default useCronofyIntegrationManager;
