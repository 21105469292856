import React, { FC, useCallback, useMemo } from 'react';
import ReactTable from 'react-table-6';
import AddIcon from '@material-ui/icons/Add';

import { InternalTeamsTableProps } from './types';
import InternalTeamEditorDialog from '../InternalTeamEditorDialog';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { Button } from '../../../../../fe_common/client/components/inputs';
import useDialog from '../../../../../fe_common/client/hooks/useDialog';
import { filterCaseInsensitiveForReactTable } from '../../../../../fe_common/client/services/reactTableHelpers/filterMethods';

import useStyles from '../../style';

const InternalTeamsTable: FC<InternalTeamsTableProps> = (props) => {
  const classes = useStyles(props);
  const { loading, data, refetch, columns } = props;

  const { open, openDialog, closeDialog } = useDialog();

  const headerAction = useMemo(
    () => (
      <Button
        label="Create Account"
        size="medium"
        color="primary"
        variant="outlined"
        className={classes.addIcon}
        onClick={openDialog}
        startIcon={<AddIcon />}
      />
    ),
    [classes.addIcon, openDialog],
  );

  const handleOnSuccess = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <>
      <Section
        title="Internal Accounts"
        noBorder
        classes={{
          root: classes.sectionRoot,
          sectionHeader: classes.sectionHeader,
        }}
        headerAction={headerAction}
      >
        <SectionContent>
          <ReactTable
            loading={loading}
            data={data}
            columns={columns}
            defaultPageSize={10}
            filterable
            defaultFilterMethod={filterCaseInsensitiveForReactTable}
          />
        </SectionContent>
      </Section>
      {open && <InternalTeamEditorDialog isOpen={open} onClose={closeDialog} onSuccess={handleOnSuccess} />}
    </>
  );
};

export default InternalTeamsTable;
