import React, { FC, useMemo } from 'react';
import { FeedAttachedFile } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilesList from '../../molecules/FilesList';

import { useFeedPostFilesStyles } from './styles';
import { FileListFile, FilesListLayout } from '../../molecules/FilesList/types';

interface FeedPostFilesProps {
  files?: Array<FeedAttachedFile> | null;
  classes: Record<string, string>;
}

const FeedPostFiles: FC<FeedPostFilesProps> = (props) => {
  const classes = useFeedPostFilesStyles();

  const files = useMemo(() => props.files || [], [props.files]); // eslint-disable-line react/destructuring-assignment

  // Separate the files to images and other types
  const [filesFiltered, imageFiles] = React.useMemo(() => {
    if (!Array.isArray(files)) {
      return [[], []];
    }

    const images = files.filter(({ mimetype }) => /image\/\w+/gi.test(mimetype));

    const rest = files.filter(({ mimetype }) => !/image\/\w+/gi.test(mimetype));

    return [rest, images];
  }, [files]);

  if (filesFiltered.length === 0 && imageFiles.length === 0) {
    return null;
  }

  return (
    <div className={classes.filesContainer}>
      <Grid container spacing={2} justifyContent="center" style={{ marginBottom: 16 }}>
        {imageFiles.map(({ url }, index) => {
          // @ts-ignore
          const uuid = (url || '').match(/\w*$/)[0] || index;

          const style = {
            backgroundImage: `url(${url})`,
          };

          return (
            <Grid key={`FeedPostFiles-${uuid}`} item xs={12} sm={6}>
              <div className={classes.postFilesImage} style={style}>
                <Link href={url} target="_blank" rel="noopener noreferrer" color="inherit">
                  <Avatar className={classes.fileImageAvatar}>
                    <GetAppIcon />
                  </Avatar>
                </Link>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <FilesList
        files={filesFiltered as FileListFile[]}
        showFileSize
        allowDownload
        layout={FilesListLayout.ONE_COLUMN}
        classes={{
          fileListItem: classes.fileItem,
        }}
      />
    </div>
  );
};

export default FeedPostFiles;
