import { Company, CompanyProfile } from '@mayple/types';
import { yesNoFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import { PropertiesMetaType } from '../../../../../fe_common/client/components/display/PropertiesList';

const PROPS_GROUPS = {
  BUSINESS_PROFILE: 'Business Profile',
};

export const getCompanyProfile = (company: Company): CompanyProfile => company.companyProfile || {};

export const getCompanyProfileProperties = (classes: Record<string, string>): PropertiesMetaType[] => {
  const yesNoToRedGreen = (value: any) => (value ? classes.green : classes.red);

  return [
    {
      label: 'Employee Count',
      path: 'employeeCount',
      group: PROPS_GROUPS.BUSINESS_PROFILE,
    },
    {
      label: 'Experienced Managing Digital Campaigns',
      path: 'hasExperienceManagingDigitalCampaigns',
      formatter: yesNoFormatter,
      customClass: yesNoToRedGreen,
      group: PROPS_GROUPS.BUSINESS_PROFILE,
    },
  ];
};
