import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 36,
    },
    divider: {
      marginTop: 32,
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
    tabContentRoot: {
      paddingTop: 48,
      paddingBottom: 48,
    },
  }),
);

export default useStyles;
