/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { quickSearchOptions } from './consts';

import useQuickNavigate from './useQuickNavigate';
import useStyles from './style';

const QuickNavigate: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    onChangeHandler,
    handleClickClearValue,
    handleClickClearValueMouseDown,
    handleOnEnterPress,
    handleClickListItem,
    handleMenuItemClick,
    handleClose,
    setTextInputRef,
    anchorEl,
    error,
    selectedIndex,
    value,
  } = useQuickNavigate(quickSearchOptions);

  return (
    <div className={classes.root}>
      <List className={classes.listRoot} component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
          color="primary"
          classes={{ root: classes.listItemRoot }}
        >
          <ListItemText
            classes={{ root: classes.listItemTextRoot }}
            primary={quickSearchOptions[selectedIndex].label}
            secondary={t('homePage.quickNavigationTo')}
          />
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {quickSearchOptions.map(({ label: optionLabel, value: optionValue }, index) => (
          <MenuItem
            key={`quick-search-option-${optionValue}`}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {optionLabel}
          </MenuItem>
        ))}
      </Menu>
      <TextField
        fullWidth
        focused
        inputRef={setTextInputRef}
        variant="outlined"
        type="number"
        helperText={error}
        error={!!error}
        value={value}
        onChange={onChangeHandler}
        onKeyUp={handleOnEnterPress}
        color="primary"
        autoFocus
        classes={{
          root: classes.textFieldRoot,
        }}
        inputProps={{
          min: 1,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear the text"
                onClick={handleClickClearValue}
                onMouseDown={handleClickClearValueMouseDown}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default QuickNavigate;
