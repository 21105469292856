import React from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';
import * as style from './style.css';

import { TextDisplay } from '../../../display';
import { colors } from '../../../../app/theme';
import { geoIpLookup } from '../../../../services/utils';

export class PhoneNumberInput2 extends React.Component {
  constructor(props) {
    super(props);

    const { input } = props;
    const { onChange, onBlur, onFocus, value, name } = input;

    this.onChange = onChange || (() => {});
    this.onBlur = onBlur || (() => {});
    this.onFocus = onFocus || (() => {});

    const phone = value || '';

    this.refComponent = React.createRef();

    this.state = {
      shouldUseGeoIp: !value,
      phone, // initialize value
      name,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getRenderedComponent() {
    return this.refComponent;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOnChange = (valid, rawValue, countryData, parsedNumber) => {
    // Update external listeners
    this.onChange(rawValue);
    this.setState({ phone: rawValue });
  };

  handleOnFocus = () => {
    this.onFocus();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOnBlur = (valid, rawValue, countryData, parsedNumber) => {
    this.onChange(parsedNumber);
    this.setState({ phone: parsedNumber });

    this.onBlur();
  };

  render() {
    const { phone, name, shouldUseGeoIp } = this.state;
    const { placeholder, meta } = this.props;
    const { valid, touched, error } = meta;
    const showInvalidWarn = touched && !valid;
    const inputClassNames = [style['form-control']];

    if (showInvalidWarn) {
      inputClassNames.push(style['form-control__error']);
    }

    // Set country based on ip address, send default country
    return (
      <div style={{ width: '100%' }}>
        <IntlTelInput
          fieldId={name}
          fieldName={name}
          value={phone}
          telInputProps={{ ref: this.refComponent }}
          onPhoneNumberChange={this.handleOnChange}
          onPhoneNumberBlur={this.handleOnBlur}
          onPhoneNumberFocus={this.handleOnFocus}
          inputClassName={inputClassNames.join(' ')}
          style={{ width: '100%' }}
          placeholder={placeholder}
          defaultCountry="auto"
          geoIpLookup={shouldUseGeoIp ? geoIpLookup : null}
          autoComplete="phone"
          nationalMode
          formatOnInit
          format={false}
        />
        {showInvalidWarn && (
          <TextDisplay style={{ margin: '8px 12px 0' }} colorOverride={colors.cherry} variant="caption">
            {error}
          </TextDisplay>
        )}
      </div>
    );
  }
}

PhoneNumberInput2.propTypes = {
  input: PropTypes.object.isRequired, // used in constructor
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  country: PropTypes.string,
};

PhoneNumberInput2.defaultProps = {
  placeholder: '',
  country: 'auto',
};
