import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

interface EmptyCellPlaceholderProps {
  name: string;
  numberOfEmptyCells: number;
  classes?: Record<string, string>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

const EmptyCellPlaceholder: React.FC<EmptyCellPlaceholderProps> = (props) => {
  const { name, numberOfEmptyCells } = props;
  const classes = useStyles(props);
  return (
    <>
      {Array.from(Array(numberOfEmptyCells).keys()).map((_item, index) => (
        <div key={`empty-cell-${name}-${index}`} className={classes.root} />
      ))}
    </>
  );
};

export default EmptyCellPlaceholder;
