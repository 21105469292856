import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketerProjects from '../components/MarketerProjects';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';

const styles = () => ({
  root: {},
});

const MarketerProjectsTab = ({ marketer, classes }) => {
  const {
    // id:   marketerId,
    // name: marketerName,
    participants,
  } = marketer;

  return (
    <div className={classes.root}>
      <Section
        title="Projects"
        subTitle="Projects assigned to the expert"
        layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
        noBorder
        noTopMargin
      >
        <SectionContent>
          <MarketerProjects projects={participants} />
        </SectionContent>
      </Section>
    </div>
  );
};

MarketerProjectsTab.propTypes = {
  marketer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(MarketerProjectsTab);
