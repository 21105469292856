import { Answer } from '@mayple/types';

// eslint-disable-next-line import/prefer-default-export
export const getSingleValueFromAnswer = (answer: Answer): any => {
  const { values = [] } = answer;
  const [data] = values;
  const { value } = data;

  return value;
};
