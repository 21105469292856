import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DateTimePicker as Picker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { colors } from '../../../../app/theme';
import { TextDisplay } from '../../../display';

interface DateTimePickerProps {
  input?: {
    value: Date | Moment | undefined;
    onChange: (value?: any) => void;
  };
  meta?: any;
  views?: ('date' | 'month' | 'year' | 'hours' | 'minutes')[] | undefined;
  openTo?: 'date' | 'month' | 'year' | 'hours' | 'minutes' | undefined;
  label?: string;
  helperText?: string;
  dateFormat?: string;
  disableFuture?: boolean;
  minDate?: Date | Moment;
  maxDate?: Date | Moment;
}

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const {
    input = {
      value: null,
      onChange: () => {
        /* Do nothing */
      },
    },
    openTo = 'date',
    views = ['date', 'hours', 'minutes'],
    label = 'Pick a date',
    helperText = '',
    disableFuture = false,
    meta = {},
    minDate,
    maxDate,
    dateFormat,
    ...rest
  } = props;

  const [date, setDate] = useState(() => {
    const initial = input?.value || minDate || new Date();

    return moment(initial);
  });

  useEffect(() => {
    const newValue = input?.value;

    if (moment(newValue).toISOString() !== moment(date).toISOString()) {
      setDate(moment(newValue));
    }
  }, [input?.value, date]);

  const handleOnChange = (newDate: MaterialUiPickersDate) => {
    const value = moment(newDate, true).toDate();
    if (input) {
      input.onChange(value);
    }
    setDate(moment(newDate));
  };

  const showError = !!meta.error && meta.dirty;

  return (
    <>
      <Picker
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        inputVariant="outlined"
        views={views}
        label={label}
        format={dateFormat}
        value={date}
        error={!!meta.error}
        helperText={helperText}
        onChange={handleOnChange}
        disableFuture={disableFuture}
        openTo={openTo}
        {...rest}
      />
      {showError && (
        <TextDisplay variant="caption" colorOverride={colors.error_text}>
          {meta.error}
        </TextDisplay>
      )}
    </>
  );
};

export default DateTimePicker;
