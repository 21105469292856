import { MarketerMatch } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ProjectMarketerMatchesQuery } from 'growl-graphql/dist/queries/ProjectMarketerMatchesQuery';
import { ApolloError } from 'apollo-client';

interface ReturnType {
  marketerMatches: MarketerMatch[];
  loading: boolean;
  refetch: () => void;
  error?: ApolloError;
}

/**
 * Return the Marketer matches of the project
 */
const useProjectMarketerMatches = (projectId: number | null | undefined): ReturnType => {
  const { data, loading, refetch, error } = useQuery(ProjectMarketerMatchesQuery.query, {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    marketerMatches: data?.project?.marketerMatches || [],
    loading,
    refetch,
    error,
  };
};

export default useProjectMarketerMatches;
