import React, { FC } from 'react';
import { CronofyAccount } from '@mayple/types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { IntegrationItemDetailsProps } from './types';

import { getProviderIconFromProviderService } from '../../../../fe_common/client/components/integrations/calendars/CronofyIntegrationManager/texts';
import { svgs } from '../../../../fe_common/client/app/svg';

import useStyles from './style';

const IntegrationItemDetailsCronofy: FC<IntegrationItemDetailsProps> = (props) => {
  const classes = useStyles(props);

  const { integration, avatar } = props;
  const { integrationType, integrationStatus, cronofyAccount } = integration;
  const { profileName = '', providerName = '', providerService = '' } = cronofyAccount || ({} as CronofyAccount);

  const providerIcon = getProviderIconFromProviderService(providerService as string);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <List className={classes.integrationDetailsListRoot}>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={avatar} title={integrationType} classes={{ root: classes.avatar }} />
            </ListItemAvatar>
            <ListItemText primary="Calendar" secondary={integrationStatus} />
          </ListItem>
        </List>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={svgs[providerIcon]} title={integrationType} classes={{ root: classes.avatar }} />
            </ListItemAvatar>
            <ListItemText primary={providerName} secondary={profileName} />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default IntegrationItemDetailsCronofy;
