import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import Button, { ButtonProps } from '../../inputs/Button';

interface RemoteResetButtonProps extends ButtonProps {
  form: string;
  onClick?: () => void;
}

const defaultOnClick = () => {
  /* Do nothing */
};

/**
 * A very useful button that can be used to submit (and destroy if needed) a redux form
 * This is for buttons that are not inside the form itself and need to reference it from
 * another place in the page
 *
 * @param props - Simple button props
 * @returns {*}
 * @constructor
 */
const RemoteResetButton: FC<RemoteResetButtonProps> = (props) => {
  const { form, onClick = defaultOnClick, ...buttonProps } = props;

  const dispatch = useDispatch();

  return (
    <Button
      {...buttonProps}
      onClick={() => {
        dispatch(reset(form));
        onClick();
      }}
    />
  );
};

export default RemoteResetButton;
