import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import SwitchField from '../../../../../fe_common/client/components/atoms/SwitchField';
import useProjectData from '../../../../../fe_common/client/hooks/project/useProjectData';
import useUpdateProject from '../../../../hooks/useUpdateProject';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface EnableProjectCycleInitialBillingSwitchProps {
  projectId: number;

  classes?: Record<string, string>;
}

const EnableProjectCycleInitialBillingSwitch: React.FC<EnableProjectCycleInitialBillingSwitchProps> = (props) => {
  const classes = useStyles();
  const { projectId } = props;
  const { project, refetch } = useProjectData(projectId);

  const checked = !!project?.enableProjectCycleInitialBilling;

  const { updateProject } = useUpdateProject();

  const onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => Promise<void> = async (
    _event,
    newValue,
  ) => {
    await updateProject(projectId, { enableProjectCycleInitialBilling: newValue }, refetch);
  };

  return (
    <div className={classes.root}>
      <SwitchField
        name="enableProjectCycleInitialBilling"
        label="Enable Project Cycle Initial Billing"
        helperText="Whether to perform an upfront billing based on the estimated cycle values at the beginning of the month or not."
        checked={checked}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default EnableProjectCycleInitialBillingSwitch;
