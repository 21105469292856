import { AccountType, InternalTeam, MarketingPlanStatus, Project, SharedFile } from '@mayple/types';
import { ApolloQueryResult } from 'apollo-client';
import { MarketingPlanDispatch } from './marketingPlanReducer';

// This is an additional status to MarketingPlanStatus
enum MarketingPlanStatusAdvanced {
  MARKETING_PLAN_MEETING_NOT_SET = 'MARKETING_PLAN_MEETING_NOT_SET',
}

// This is some workaround to extend enums in Typescript - apparently there is no native solution to that yet.
// See: https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
export type MarketingPlanStatusExtended = MarketingPlanStatus | MarketingPlanStatusAdvanced;
export const MarketingPlanStatusExtended = { ...MarketingPlanStatus, ...MarketingPlanStatusAdvanced };

type RefetchParticipantQuery = (
  variables?: { projectId: number; marketerId: number } | undefined
) => Promise<ApolloQueryResult<any>>;

export type MarketingPlanEventHandlers = {
  onError: (errorMessage: string) => void;
  onUploadMarketingPlan: RefetchParticipantQuery;
  onDeleteMarketingPlan: RefetchParticipantQuery;
  onApproveMarketingPlanByGS: RefetchParticipantQuery;
};

export interface IMarketingPlanContext {
  projectId: number;
  marketerId: number;
  filestackApiKey: string;
  isLoading?: boolean;
  isInitialized?: boolean;
  errorMessages?: string[];
  clearErrorMessages?: () => void;
  accountType?: AccountType;
  templateLink?: string;
  project?: Project | null;
  mainGrowthStrategist?: InternalTeam | null;
  participantId?: number;
  marketingPlanStatus?: MarketingPlanStatusExtended;
  marketingPlanDueDate?: Date;
  marketingPlanDueDateFormatted?: string;
  timeLeftForMarketingPlanDueDate?: string;
  marketingPlanMeetingDate?: Date;
  marketingPlanMeetingDateFormatted?: string;
  marketingPlanFile?: SharedFile[];
  uploadMarketingPlanDialogIsOpen?: boolean;
  companyName?: string;
  eventHandlers: MarketingPlanEventHandlers;
}

export type ParticipantStateData = Pick<
  IMarketingPlanContext,
  | 'participantId'
  | 'marketingPlanStatus'
  | 'marketingPlanDueDate'
  | 'marketingPlanDueDateFormatted'
  | 'timeLeftForMarketingPlanDueDate'
  | 'marketingPlanMeetingDateFormatted'
  | 'marketingPlanMeetingDate'
  | 'marketingPlanFile'
>;

export type MarketingPlanProviderInitialValues = Pick<
  IMarketingPlanContext,
  'filestackApiKey' | 'projectId' | 'marketerId'
>;

export type MarketingPlanProviderProps = {
  initialValues: MarketingPlanProviderInitialValues;
};

export type UseInitMarketingPlanProviderDataReturnValue = [IMarketingPlanContext, MarketingPlanDispatch];
