import React, { FC, useMemo } from 'react';
import { Project, ProjectStage as ProjectStageEnum } from '@mayple/types';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import useProjectStageData from './useProjectStageData';
import getCurrentProjectStageAction from './getCurrentProjectStageAction';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import { Button } from '../../../../../../../fe_common/client/components/inputs';
import CalloutMessage from '../../../../../../../fe_common/client/components/common/CalloutMessage';
import { stageTitle } from '../../../../../../../fe_common/client/logic/projectStage';

import useStyles from './style';

interface ProjectStageProps {
  project: Project;
  onSuccess: () => Promise<void>;
}

/**
 * This component should suggest to the admin what the next action should be taken for this project.
 * For example, project should be assigned an expert.
 * @param props
 * @constructor
 */
const ProjectStage: FC<ProjectStageProps> = (props) => {
  const { project, onSuccess } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { isCurrentProjectStageCanBeSkipped, skipToNextProjectStageHandler } = useProjectStageData(project?.id);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const action = useMemo(() => getCurrentProjectStageAction(project), [project]);

  const onSkipToNextStageClickHandler = async () => {
    await skipToNextProjectStageHandler(onSuccess);
  };

  return (
    <>
      <div className={classes.root}>
        <TextDisplay variant="subtitle1" className={classes.inlineItem}>
          Current project stage: <strong>{action.title}</strong>{' '}
        </TextDisplay>

        <Button label="show stages" size="small" variant="outlined" onClick={handleClick} />
      </div>

      <CalloutMessage
        margin
        message={action.subtitle || ''}
        actionLabel="Skip to next stage"
        onClick={onSkipToNextStageClickHandler}
        actionProps={{
          size: 'small',
        }}
        actionDisabled={!isCurrentProjectStageCanBeSkipped}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          <CardContent>
            <ol>
              {Object.values(ProjectStageEnum).map((s) => {
                const current = s === project.stage;
                return (
                  <li key={`${s}-ProjectStage-TimelineItem`}>
                    <TextDisplay bold={current} color={current ? 'primary' : 'textSecondary'}>
                      {stageTitle[s]} {current && ' 👈'}
                    </TextDisplay>
                  </li>
                );
              })}
            </ol>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default ProjectStage;
