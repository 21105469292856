import React from 'react';
import { TableCellRenderer } from 'react-table-6';

import ChipList from '../../../fe_common/client/components/atoms/ChipList';

const ChipListCell: TableCellRenderer = (cellInfo) => {
  const { value } = cellInfo;
  return <ChipList items={value} labelsLookup={{}} maxToShow={3} size="small" />;
};

export default ChipListCell;
