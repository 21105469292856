import { useCallback, useMemo } from 'react';
import { CronofyAvailabilityProfiles, CronofyElementToken, CronofyMembersEventQuery } from '@mayple/types';
import moment from 'moment';

import { useAvailabilityViewerState } from '../AvailabilityViewerProvider/AvailabilityViewerContext';
import { Constants, defaultStyles } from '../constants';
import { AVAILABILITY_MANAGEMENT } from '../types';
import {
  AvailabilityRulesHandler,
  CallbackEventType,
  CallbackEventTypes,
  CronofyAvailabilityQuery,
  CronofyAvailabilityViewerOptions,
  CronofyMeetingDuration,
  CronofyMember,
  AvailabilityViewerCallbackObject,
  ValidTime,
} from '../../types/cronofyTypes';

const getAvailabilityQueryPeriods = (startDate: Date, endDate: Date) => [
  {
    start: moment.utc(startDate).toISOString(),
    end: moment.utc(endDate).toISOString(),
  },
];

const getAvailabilityRuleIds = (
  availabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined,
  selectedAvailabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined,
): string[] | null | undefined => {
  // console.log('availabilityProfiles', availabilityProfiles);
  // console.log('selectedAvailabilityProfiles', selectedAvailabilityProfiles);

  if (!availabilityProfiles) {
    return null;
  }

  if (!selectedAvailabilityProfiles) {
    return [];
  }

  const validSelectedAvailabilityProfiles: CronofyAvailabilityProfiles[] = [];
  selectedAvailabilityProfiles.forEach((selectedProfile) => {
    if ((availabilityProfiles || []).includes(selectedProfile)) {
      validSelectedAvailabilityProfiles.push(selectedProfile);
    }
  });

  return validSelectedAvailabilityProfiles;
};

const availabilityQuery = (
  startDate: Date,
  endDate: Date,
  members: CronofyMembersEventQuery[],
  managedAvailability = false,
  selectedAvailabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined = undefined,
  onlyShowAvailability = false,
): CronofyAvailabilityQuery => {
  const queryPeriods = getAvailabilityQueryPeriods(startDate, endDate);

  const queryMembers: CronofyMember[] = members.map(({ sub, availabilityProfiles }) => {
    const cronofyMember: CronofyMember = {
      managed_availability: managedAvailability,
      sub,
    };

    if (managedAvailability) {
      cronofyMember.availability_rule_ids = getAvailabilityRuleIds(availabilityProfiles, selectedAvailabilityProfiles);
    }

    if (onlyShowAvailability) {
      // using an empty array here will ignore all the calendars that are set on the availability rule
      // This will show the user availability rules as they were set, ignoring all the meetings
      cronofyMember.calendar_ids = [];
    }

    return cronofyMember;
  });

  return {
    participants: [
      {
        required: 'all',
        members: queryMembers,
      },
    ],
    required_duration: { minutes: 30 },
    query_periods: queryPeriods,
  };
};

const useAvailabilityViewerConfiguration = (
  elementToken: CronofyElementToken,
  members: CronofyMembersEventQuery[],
  onAvailabilityRuleNotFound?: AvailabilityRulesHandler,
): CronofyAvailabilityViewerOptions | undefined => {
  const { initOptions: options, filterData } = useAvailabilityViewerState();

  const defaultCallback = useCallback(
    (callbackObject: AvailabilityViewerCallbackObject) => {
      const actionType: CallbackEventType = callbackObject.notification.type;

      switch (actionType) {
        case CallbackEventTypes.availabilityRuleNotFound:
          if (onAvailabilityRuleNotFound) {
            onAvailabilityRuleNotFound(callbackObject);
          }
          break;
        default:
          break;
      }
    },
    [onAvailabilityRuleNotFound],
  );

  const cronofyAvailabilityViewerOptions: CronofyAvailabilityViewerOptions | undefined = useMemo(() => {
    const token = elementToken?.token || '';
    const isDemo = options?.demo === true;

    // at this point we need to validate we have a valid token and that we are not in demo mode
    if (!token && !isDemo) {
      return undefined;
    }

    const elementTargetId = options?.elementTargetId || Constants.DEFAULT_ELEMENT_TARGET_ID;
    const styles = options?.styles || defaultStyles;
    const callback = options?.callback || defaultCallback;

    const startTime = (options?.config?.startTime || Constants.DEFAULT_WORKING_DAY_START_TIME) as ValidTime;
    const endTime = (options?.config?.endTime || Constants.DEFAULT_WORKING_DAY_END_TIME) as ValidTime;
    const weekStartDay = options?.config?.weekStartDay || Constants.DEFAULT_WEEK_START_DAY;
    const mode = options?.config?.mode || Constants.DEFAULT_MODE;
    const interval = (options?.config?.interval || Constants.DEFAULT_INTERVAL) as CronofyMeetingDuration;
    const maxSelectionCount = options?.config?.maxSelectionCount || Constants.DEFAULT_MAX_SELECTION_COUNT;
    const boundsControl = options?.config?.boundsControl || Constants.DEFAULT_BOUNDS_CONTROL;
    const allowExpansion = options?.config?.allowExpansion || Constants.DEFAULT_ALLOW_EXPANSION;
    const slotSelection = options?.config?.slotSelection || Constants.DEFAULT_SLOT_SELECTION;

    const managedAvailability =
      (filterData?.availabilityManagement
        ? filterData?.availabilityManagement === AVAILABILITY_MANAGEMENT.USE_MANAGED_AVAILABILITY
        : undefined) ??
      options?.config?.managedAvailability ??
      Constants.DEFAULT_MANAGED_AVAILABILITY;

    const timeZone =
      filterData?.timezone || options?.config?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    const selectedAvailabilityProfiles = filterData?.availabilityProfiles || [CronofyAvailabilityProfiles.work_hours];

    const onlyShowAvailability = filterData?.onlyShowAvailability || false;

    return {
      element_token: token,
      availability_query: availabilityQuery(
        moment.utc().add(1, 'hour').toDate(),
        moment.utc().add(1, 'month').toDate(),
        members,
        managedAvailability,
        selectedAvailabilityProfiles,
        onlyShowAvailability,
      ),
      target_id: elementTargetId,
      tzid: timeZone,
      config: {
        week_start_day: weekStartDay,
        start_time: startTime,
        end_time: endTime,
        mode,
        interval,
        max_selection_count: maxSelectionCount,
        bounds_control: boundsControl,
        allow_expansion: allowExpansion,
        slot_selection: slotSelection,
      },
      styles,
      demo: isDemo,
      callback,
    };
  }, [
    elementToken?.token,
    options?.demo,
    options?.elementTargetId,
    options?.styles,
    options?.callback,
    options?.config?.startTime,
    options?.config?.endTime,
    options?.config?.weekStartDay,
    options?.config?.mode,
    options?.config?.interval,
    options?.config?.maxSelectionCount,
    options?.config?.boundsControl,
    options?.config?.allowExpansion,
    options?.config?.slotSelection,
    options?.config?.managedAvailability,
    options?.config?.timeZone,
    defaultCallback,
    filterData?.availabilityManagement,
    filterData?.timezone,
    filterData?.availabilityProfiles,
    filterData?.onlyShowAvailability,
    members,
  ]);

  return cronofyAvailabilityViewerOptions;
};

export default useAvailabilityViewerConfiguration;
