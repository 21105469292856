import React, { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useQuery } from '@apollo/react-hooks';
import { FullMarketerByIdQuery } from 'growl-graphql/dist/queries/FullMarketerById';

import { MarketerDetailsTabs, marketerTabs } from './consts';
import MarketerDetailsHeader from './components/MarketerDetailsHeader';
import MarketerBasicDetailsTab from './tabs/MarketerBasicDetailsTab';
import MarketerIntegrationsTab from './tabs/MarketerIntegrationsTab';
import MarketerUsersTab from './tabs/MarketerUsersTab';
import MarketerFundsTab from './tabs/MarketerFundsTab';
import MarketerProjectsTab from './tabs/MarketerProjectsTab';
import { MarketerIdParams } from '../../components/Routes/types';

import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import TabContent from '../../../fe_common/client/components/atoms/TabContent';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import { tryParseInt } from '../../../fe_common/client/services/utils';

import useStyles from './style';

export interface MarketerDetailsPageProps {
  classes?: Record<string, string>;
}

const MarketerDetailsPage: FC<MarketerDetailsPageProps> = (props) => {
  const classes = useStyles(props);

  const { marketerId: marketerIdFromUrl } = useParams<MarketerIdParams>();
  const marketerId: number | null = tryParseInt(marketerIdFromUrl, null);

  const {
    data = {},
    loading,
    error,
  } = useQuery(FullMarketerByIdQuery.query, {
    variables: { marketerId },
    skip: !marketerId,
    errorPolicy: 'all',
  });

  const { marketer } = data;

  const [selectedTab, setSelectedTab] = useState<MarketerDetailsTabs>(() => {
    let defaultTab = MarketerDetailsTabs.basicDetails;
    const hash = window.location.hash.replace('#', '');

    if (Object.keys(MarketerDetailsTabs).includes(hash)) {
      defaultTab = hash as MarketerDetailsTabs;
    }

    return defaultTab;
  });

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleTabChange = (event: ChangeEvent<unknown>, newSelectedTab: MarketerDetailsTabs) => {
    window.location.hash = newSelectedTab;
    setSelectedTab(newSelectedTab);
  };

  return (
    <div className={classes.root}>
      {loading && <PageLoadingPlaceholder />}

      {!!error && (
        <CalloutMessage type="error" message="Error getting marketer data, some of the data may be incomplete" />
      )}

      {!marketer && !loading && (
        <CalloutMessage type="error" message={`Marketer with id: ${marketerIdFromUrl} not found.`} margin />
      )}

      {marketer && !loading && !error && (
        <>
          <MarketerDetailsHeader marketer={marketer} />
          <Divider className={classes.divider} />

          <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            {marketerTabs.map(({ label, value }) => (
              <Tab key={`MarketerDetailsPage-tabs-${label}`} label={label} value={value} />
            ))}
          </Tabs>

          {selectedTab === MarketerDetailsTabs.basicDetails && (
            <TabContent classes={{ root: classes.tabContentRoot }}>
              <MarketerBasicDetailsTab marketer={marketer} />
            </TabContent>
          )}

          {selectedTab === MarketerDetailsTabs.projects && (
            <TabContent classes={{ root: classes.tabContentRoot }}>
              <MarketerProjectsTab marketer={marketer} />
            </TabContent>
          )}

          {selectedTab === MarketerDetailsTabs.funds && (
            <TabContent classes={{ root: classes.tabContentRoot }}>
              <MarketerFundsTab marketer={marketer} />
            </TabContent>
          )}

          {selectedTab === MarketerDetailsTabs.integrations && (
            <TabContent classes={{ root: classes.tabContentRoot }}>
              <MarketerIntegrationsTab marketer={marketer} />
            </TabContent>
          )}

          {selectedTab === MarketerDetailsTabs.users && (
            <TabContent classes={{ root: classes.tabContentRoot }}>
              <MarketerUsersTab marketer={marketer} />
            </TabContent>
          )}
        </>
      )}
    </div>
  );
};

export default MarketerDetailsPage;
