/* eslint-disable import/prefer-default-export */
import React from 'react';
import moment from 'moment';

import { formatDateString } from '../../../services/utils';

export function dateTimeFormatter(fieldName: string, format = '') {
  // eslint-disable-next-line react/display-name, func-names
  return function (rowData: Record<string, any>): React.ReactElement {
    if (!rowData[fieldName]) {
      return <span>N/A</span>;
    }
    if (format !== '') {
      return <span>{moment(rowData[fieldName]).format(format)}</span>;
    }
    return <span>{formatDateString(rowData[fieldName])}</span>;
  };
}
