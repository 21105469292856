import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { validateRequired } from '../../../../services/validation';
import Switch from '../../Registration/Switch';

/**
 * MUI toggle field for wizard form
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardSwitchField(props) {
  const { name, label, disabled, isRequired, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  return (
    <div>
      <Field
        {...rest}
        validate={validator}
        component={Switch}
        name={name}
        label={label}
        disabled={disabled}
        labelPosition="right"
      />
    </div>
  );
}

WizardSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

WizardSwitchField.defaultProps = {
  label: '',
  disabled: false,
};
