import React from 'react';
import { FilterRender } from 'react-table-6';
import { InternalTeamType } from '@mayple/types';

export const InternalTeamTypeFilter: FilterRender = (props) => {
  const { filter, onChange } = props;

  const internalTeamType = Object.entries(InternalTeamType);

  return (
    <select
      onChange={(event) => {
        // @ts-ignore
        onChange(event.target.value);
      }}
      value={filter?.value ?? 'all'}
    >
      <option value="all">Show All</option>
      {internalTeamType.map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default InternalTeamTypeFilter;
