import PropTypes from 'prop-types';
import { ProjectLifeCycleStatus } from '@mayple/types';

function ShowByProjectStatus(props) {
  const { status, showOnDiscovery, showOnOnBoarding, showOnLive, showOnFinished, showOnAbandoned, children } = props;

  let shouldShow;

  switch (status) {
    case ProjectLifeCycleStatus.DISCOVERY:
      shouldShow = showOnDiscovery;
      break;
    case ProjectLifeCycleStatus.ONBOARDING:
      shouldShow = showOnOnBoarding;
      break;
    case ProjectLifeCycleStatus.LIVE:
      shouldShow = showOnLive;
      break;
    case ProjectLifeCycleStatus.FINISHED:
      shouldShow = showOnFinished;
      break;
    case ProjectLifeCycleStatus.ABANDONED:
      shouldShow = showOnAbandoned;
      break;
    default:
      shouldShow = false;
  }

  if (shouldShow) {
    return children;
  }

  return null;
}

ShowByProjectStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ProjectLifeCycleStatus)).isRequired,
  showOnDiscovery: PropTypes.bool,
  showOnOnBoarding: PropTypes.bool,
  showOnLive: PropTypes.bool,
  showOnFinished: PropTypes.bool,
  showOnAbandoned: PropTypes.bool,
  children: PropTypes.node,
};

ShowByProjectStatus.defaultProps = {
  showOnDiscovery: false,
  showOnOnBoarding: false,
  showOnLive: false,
  showOnFinished: false,
  showOnAbandoned: false,
  children: null,
};

export default ShowByProjectStatus;
