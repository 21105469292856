import { useQuery } from '@apollo/react-hooks';
import { Project, ProjectExistingMarketingOptimizationImplementation } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProjectExistingMarketingOptimizationImplementationQuery } from 'growl-graphql/dist/queries/project/ProjectExistingMarketingOptimizationImplementationQuery';

export type ProjectExistingMarketingOptimizationImplementationData = {
  loading: boolean;
  project: Project | null | undefined;
  existingMarketingOptimizationImplementation: ProjectExistingMarketingOptimizationImplementation | null | undefined;
  error: ApolloError | undefined;
  refetch: (variables?: { projectId: number | undefined } | undefined) => Promise<ApolloQueryResult<any>>;
};

const useProjectExistingMarketingOptimizationImplementation = (
  projectId: number | undefined,
  shouldSkip = false,
): ProjectExistingMarketingOptimizationImplementationData => {
  const { data, loading, error, refetch } = useQuery(ProjectExistingMarketingOptimizationImplementationQuery.query, {
    variables: { projectId },
    skip: !projectId || shouldSkip,
  });

  return {
    project: data?.project,
    existingMarketingOptimizationImplementation: data?.project?.existingMarketingOptimizationImplementation,
    loading,
    error,
    refetch,
  };
};

export default useProjectExistingMarketingOptimizationImplementation;
