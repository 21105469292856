import React, { useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import EntityAvatar, { EntityAvatarProps } from '../EntityAvatar';
import SelectionIndicator from '../Registration/SelectionIndicator';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface EntityAvatarCheckboxProps extends EntityAvatarProps {
  onSelectItem?: () => void;
  isSelected?: boolean;
}

const EntityAvatarCheckbox: React.FC<EntityAvatarCheckboxProps> = (props) => {
  const classes = useStyles();

  const { onSelectItem, isSelected = false, ...rest } = props;
  const onSelectItemHandler = useCallback(() => {
    if (typeof onSelectItem === 'function') {
      onSelectItem();
    }
  }, [onSelectItem]);

  return (
    <ListItem className={classes.root}>
      <ListItemText>
        <EntityAvatar {...rest} />
      </ListItemText>
      {typeof onSelectItem === 'function' && (
        <ListItemSecondaryAction onClick={onSelectItemHandler} aria-label="Select">
          <SelectionIndicator type="checkbox" checked={isSelected} color="primary" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default EntityAvatarCheckbox;
