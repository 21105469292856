import { useState } from 'react';
import { Filter, SortingRule } from 'react-table-6';

import { QueryTableProps } from './QueryTable';

const DEFAULT_PAGE_SIZE = 20;

type UseQueryTableOutput = {
  variables: Record<string, any>;
  filtered: Filter[];
  sorted: SortingRule[];
  onFetchData: (state: any, instance: any) => void;
};

type onFetchData = (state: any, instance: any) => void;

const getFilterBy = (filtered: Filter[] = []) =>
  filtered
    ?.filter(({ value }) => value?.key != null && value?.value != null)
    .map(({ id, value }) => ({ ...value, key: value.key || id }));

const useQueryTable = (props: QueryTableProps): UseQueryTableOutput => {
  const {
    initialFiltered,
    initialSorted,
    pageSize: initialPageSize = DEFAULT_PAGE_SIZE,
    pageNumber: initialPageNumber = 0,
  } = props;

  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [filtered, setFiltered] = useState<Filter[]>(initialFiltered || []);
  const [sorted, setSorted] = useState<SortingRule[]>(initialSorted || []);

  const variables = {
    pagination: {
      pageNumber,
      pageSize,
      by: sorted?.map(({ id, desc }) => ({ key: id, desc })),
    },
    filter: {
      by: getFilterBy(filtered),
    },
  };

  const onFetchData: onFetchData = ({
    page: newPage,
    pageSize: newPageSize,
    filtered: newFiltered,
    sorted: newSorted,
  }) => {
    setPageNumber(newPage);
    setPageSize(newPageSize);
    setSorted(newSorted);
    setFiltered(newFiltered);
  };

  return {
    variables,
    filtered,
    sorted,
    onFetchData,
  };
};

export default useQueryTable;
