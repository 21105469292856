import { TaskType } from '@mayple/types';
import IAssessment from './IAssessment';
import PostProjectMatchOfferMarketerAssessmentConcrete from './PostProjectMatchOfferMarketerAssessmentConcrete';
import OutbrainAuditAssessmentByCompanyAssessmentConcrete from './OutbrainAuditAssessmentByCompanyAssessmentConcrete';
import MonthlyMarketerAssessmentByCompanyConcrete from './MonthlyMarketerAssessmentByCompanyConcrete';

class AssessmentFactory {
  // eslint-disable-next-line class-methods-use-this
  createAssessment(taskType: TaskType): IAssessment {
    switch (taskType) {
      case TaskType.PostProjectMatchOfferMarketerAssessment:
        return new PostProjectMatchOfferMarketerAssessmentConcrete();
      case TaskType.OutbrainAuditAssessmentByCompany:
        return new OutbrainAuditAssessmentByCompanyAssessmentConcrete();
      case TaskType.MonthlyMarketerAssessmentByCompany:
        return new MonthlyMarketerAssessmentByCompanyConcrete();
      default:
        throw new Error(`Missing implementation for taskType: ${taskType}`);
    }
  }
}

const assessmentFactory = new AssessmentFactory();
export default assessmentFactory;
