import React, { useCallback } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';

import { AccountWithCronofyIntegration } from '../../../../types';
import { colorPalette } from '../../../../../../../app/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      listStyleType: 'none',
    },
    highlight: {
      background: colorPalette.green['20'],
    },
    container: {
      listStyleType: 'none',
    },
    entityProperty: {
      display: 'block',
    },
    entityPropertyLabel: {},
    entityPropertyValue: {
      fontWeight: 'bold',
    },
    secondaryActionRoot: {},
  })
);

type AccountListItemChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

interface AccountListItemProps {
  account: AccountWithCronofyIntegration;
  withSelection?: boolean;
  isChecked?: boolean;
  isHighlighted?: boolean;
  onChange?: (value: string, isChecked: boolean) => void | undefined;
  classes?: Record<string, string>;
}

const AccountListItem: React.FC<AccountListItemProps> = (props) => {
  const classes = useStyles(props);
  const { account, withSelection = false, isChecked, isHighlighted = false, onChange } = props;
  const { displayImageUrl = '', name = '', id, accountType, sub, hasActiveIntegration } = account;
  const primary = `${name} (${id})`;
  const secondary = !hasActiveIntegration ? `${accountType} - missing integration` : `${accountType}`;

  const onChangeEventHandler: AccountListItemChangeEventHandler = useCallback(
    (event) => {
      if (sub) {
        onChange?.(sub, event.target.checked);
      }
    },
    [sub, onChange]
  );

  return (
    <ListItem
      classes={{
        root: classNames(classes.root, {
          [classes.highlight]: isHighlighted,
        }),
        container: classes.container,
      }}
    >
      {withSelection && (
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            disabled={!hasActiveIntegration}
            onChange={onChangeEventHandler}
            value={sub}
          />
        </ListItemIcon>
      )}
      {displayImageUrl && (
        <ListItemAvatar>
          <Avatar src={displayImageUrl} />
        </ListItemAvatar>
      )}
      <ListItemText primary={primary} secondary={secondary} />

      {/*
      {customActionButton !== null && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          {customActionButton}
        </ListItemSecondaryAction>
      )}
      {hasAction && !customActionButton && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          <Tooltip title={label} placement="left">
            <IconButton
              onClick={() => {
                handler(entity);
              }}
            >
              <Icon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
      */}
    </ListItem>
  );
};

export default AccountListItem;
