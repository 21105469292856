import { useEffect, useMemo, useState } from 'react';
import {
  AccountType,
  CronofyElementToken,
  CronofyMembersEventQuery,
  Integration,
  InternalTeam,
  Marketer,
} from '@mayple/types';
import { DocumentNode } from 'graphql';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { CronofyAvailabilityViewerElementTokenQuery } from 'growl-graphql/dist/queries/CronofyAvailabilityViewerElementTokenQuery';
import { MarketerByIdIntegrationsQuery } from 'growl-graphql/dist/queries/MarketerByIdIntegrationsQuery';
import { InternalTeamByIdIntegrationsQuery } from 'growl-graphql/dist/queries/InternalTeamByIdIntegrationsQuery';

import { Account, AccountWithCronofyIntegration, AccountWithIntegration } from '../../types';
import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../AvailabilityViewerProvider/AvailabilityViewerContext';
import useAdminAccessibleMarketersData from './useAdminAccessibleMarketersData';
import { getCronofyIntegration } from '../../CronofyIntegrationManager/hooks';
import useAdminAccessibleInternalTeams from '../../../../../hooks/internalTeam/useAdminAccessibleInternalTeams';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../AvailabilityViewerProvider/availabilityViewerReducer';

interface CronofyIntegrationReturnType {
  elementToken: CronofyElementToken;
  members: CronofyMembersEventQuery[];
  isLoading: boolean;
  error: ApolloError | undefined;
}

const getAccountIntegrationsQuery = (
  accountType: AccountType
): null | {
  query: DocumentNode;
  accountIdKey: string;
  resultKey: string;
} => {
  switch (accountType) {
    case AccountType.MARKETER:
      return {
        query: MarketerByIdIntegrationsQuery.query,
        accountIdKey: 'marketerId',
        resultKey: 'marketer',
      };
    case AccountType.INTERNAL_TEAM:
      return {
        query: InternalTeamByIdIntegrationsQuery.query,
        accountIdKey: 'internalTeamId',
        resultKey: 'internalTeam',
      };
    default:
      return null;
  }
};

const getCronofyIntegrationIds = <T = Marketer | InternalTeam>(entities: T[] | null | undefined): number[] => {
  const cronofyIntegrationIds = (entities || []).map((entity: T) => {
    // @ts-ignore
    const cronofyIntegration = getCronofyIntegration(entity?.integrations);

    if (cronofyIntegration) {
      return cronofyIntegration.id;
    }
    return null;
  });

  // @ts-ignore
  return (cronofyIntegrationIds || []).filter((id: number | null): boolean => id !== null);
};

interface UseAccountIntegrationsOutput {
  loading: boolean;
  error: ApolloError | undefined;
  integrations: Integration[] | null;
}

export const useAccountIntegrations = (
  accountId: number | null,
  accountType: AccountType
): UseAccountIntegrationsOutput => {
  const { query, accountIdKey = '', resultKey = '' } = getAccountIntegrationsQuery(accountType) || {};

  const variables = {
    [accountIdKey]: accountId,
  };

  const { loading, data, error } = useQuery(query as DocumentNode, {
    variables,
    skip: !query || !accountType || !accountId,
  });

  return {
    loading,
    error,
    integrations: data?.[resultKey]?.integrations,
  };
};

const entityToAccountWithIntegrationMapper = (
  entity: Marketer | InternalTeam,
  accountType: AccountType
): AccountWithCronofyIntegration => {
  const { id, uuid, name, description, displayImageUrl, integrations } = entity;
  const cronofyIntegration = getCronofyIntegration(integrations);

  return {
    id,
    uuid,
    accountType,
    name,
    displayImageUrl,
    description,
    hasActiveIntegration: !!cronofyIntegration?.id,
    integrationId: cronofyIntegration?.id,
    integrationUuid: cronofyIntegration?.uuid,
    integrationStatus: cronofyIntegration?.integrationStatus,
    integrationType: cronofyIntegration?.integrationType,
    externalEntityId: cronofyIntegration?.externalEntityId,
  };
};

const marketerToAccountWithIntegrationMapper = (marketer: Marketer): AccountWithIntegration =>
  entityToAccountWithIntegrationMapper(marketer, AccountType.MARKETER);

const internalTeamToAccountWithIntegrationMapper = (internalTeam: InternalTeam): AccountWithIntegration =>
  entityToAccountWithIntegrationMapper(internalTeam, AccountType.INTERNAL_TEAM);

const useCronofyElementToken = (): CronofyIntegrationReturnType => {
  const [integrationIds, setIntegrationIds] = useState<Array<number | undefined>>([]);

  const { filterData, mainAccountsGroup } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const secondaryAccounts: Account[] = filterData?.accounts || ([] as Account[]);

  const accounts = Array.from(new Set([...(mainAccountsGroup.accounts || []), ...secondaryAccounts]));

  // console.log('accounts', accounts);

  const marketerAccounts = accounts.filter(({ accountType }) => accountType === AccountType.MARKETER);
  const internalTeamAccounts = accounts.filter(({ accountType }) => accountType === AccountType.INTERNAL_TEAM);

  // console.log('internalTeamAccounts', internalTeamAccounts);
  // console.log('marketerAccounts', marketerAccounts);

  const marketersIds: number[] = (marketerAccounts || []).map(({ id }) => id);
  const internalTeamIds: number[] = (internalTeamAccounts || []).map(({ id }) => id);

  const {
    marketers,
    loading: loadingMarketers,
    error: errorMarketers,
  } = useAdminAccessibleMarketersData(marketersIds, true);

  const {
    internalTeams,
    loading: loadingInternalTeams,
    error: errorInternalTeams,
  } = useAdminAccessibleInternalTeams({
    internalTeamIds,
    withIntegrations: true,
    queryOptions: { skip: !internalTeamIds?.length },
  });

  // console.log('loadingMarketers', loadingMarketers);
  // console.log('marketers', marketers);

  const marketersCronofyIntegrationIds = useMemo(() => getCronofyIntegrationIds<Marketer>(marketers), [marketers]);
  const internalTeamsCronofyIntegrationIds = useMemo(
    () => getCronofyIntegrationIds<InternalTeam>(internalTeams),
    [internalTeams]
  );

  // console.log('marketersCronofyIntegrationIds', marketersCronofyIntegrationIds);
  // console.log('internalTeamsCronofyIntegrationIds', internalTeamsCronofyIntegrationIds);

  useEffect(() => {
    if (loadingMarketers || loadingInternalTeams) {
      return;
    }

    const accountsWithIntegration: Array<AccountWithCronofyIntegration> = [];

    (internalTeams || []).forEach((internalTeam) => {
      if (internalTeam && internalTeam?.id) {
        accountsWithIntegration.push(internalTeamToAccountWithIntegrationMapper(internalTeam));
      }
    });

    (marketers || []).forEach((marketer) => {
      if (marketer && marketer?.id) {
        accountsWithIntegration.push(marketerToAccountWithIntegrationMapper(marketer));
      }
    });

    const action: AvailabilityViewerAction = {
      type: AvailabilityViewerActionType.UPDATE_ACCOUNTS_INTEGRATIONS,
      payload: accountsWithIntegration,
    };

    dispatch(action);
  }, [dispatch, internalTeams, loadingInternalTeams, loadingMarketers, marketers]);

  useEffect(() => {
    if (loadingMarketers || loadingInternalTeams) {
      return;
    }

    let newIntegrationIds = [] as number[];

    if (marketersCronofyIntegrationIds?.length) {
      newIntegrationIds = [...newIntegrationIds, ...marketersCronofyIntegrationIds];
    }

    if (internalTeamsCronofyIntegrationIds?.length) {
      newIntegrationIds = [...newIntegrationIds, ...internalTeamsCronofyIntegrationIds];
    }

    setIntegrationIds(Array.from(new Set(newIntegrationIds)));
  }, [internalTeamsCronofyIntegrationIds, loadingInternalTeams, loadingMarketers, marketersCronofyIntegrationIds]);

  // @ts-ignore
  const { origin } = window.location;
  const variables = { integrationIds, origin };

  const {
    data: elementTokenQueryData,
    loading: isElementTokenQueryLoading,
    error,
  } = useQuery(CronofyAvailabilityViewerElementTokenQuery.query, {
    variables,
    skip: !integrationIds || integrationIds.length === 0,
  });

  const cronofyAvailabilityViewerElementToken = elementTokenQueryData?.cronofyAvailabilityViewerElementToken;

  return {
    elementToken: cronofyAvailabilityViewerElementToken?.elementToken,
    members: cronofyAvailabilityViewerElementToken?.members,
    isLoading: isElementTokenQueryLoading || loadingMarketers || loadingInternalTeams,
    error: error || errorInternalTeams || errorMarketers,
  };
};

export default useCronofyElementToken;
