import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useDialogStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

export const useFormStyles = makeStyles(() =>
  createStyles({
    root: {},
    formContainer: {
      padding: '2rem 0',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1.6875rem',
      '& button': {
        marginLeft: '1.5rem',
      },
    },
  }),
);
