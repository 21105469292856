import { ApolloError, OperationVariables } from 'apollo-client';
import { MutationFunctionOptions } from '@apollo/react-common/lib/types/types';
import { ExecutionResult } from '@apollo/react-common';

import { SetMailchimpAuditAssessmentByCompanyForProjectMutation } from 'growl-graphql/dist/mutations/tasks/Mailchimp/SetMailchimpAuditAssessmentByCompanyForProjectMutation';
import { SkipMailchimpAuditAssessmentByCompanyForProjectMutation } from 'growl-graphql/dist/mutations/tasks/Mailchimp/SkipMailchimpAuditAssessmentByCompanyForProjectMutation';

import { useMutation } from '../../../../../hooks';

export type MailchimpAuditAssessmentMutations = {
  setMailchimpAuditAssessmentByCompanyForProject: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<ExecutionResult<any>>;
  savingAssessment: boolean;
  errorSavingAssessment: ApolloError | undefined;

  skipMailchimpAuditAssessmentByCompanyForProject: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<ExecutionResult<any>>;
  skippingAssessment: boolean;
  errorSkippingAssessment: ApolloError | undefined;
};

const useMailchimpAuditAssessmentMutations = (): MailchimpAuditAssessmentMutations => {
  const {
    mutate: setMailchimpAuditAssessmentByCompanyForProject,
    loading: savingAssessment,
    error: errorSavingAssessment,
  } = useMutation(SetMailchimpAuditAssessmentByCompanyForProjectMutation, {
    mutationResponseKey: 'setMailchimpAuditAssessmentByCompanyForProject',
  });

  const {
    mutate: skipMailchimpAuditAssessmentByCompanyForProject,
    loading: skippingAssessment,
    error: errorSkippingAssessment,
  } = useMutation(SkipMailchimpAuditAssessmentByCompanyForProjectMutation, {
    mutationResponseKey: 'skipMailchimpAuditAssessmentByCompanyForProject',
  });

  return {
    savingAssessment,
    setMailchimpAuditAssessmentByCompanyForProject,
    errorSavingAssessment,
    skippingAssessment,
    errorSkippingAssessment,
    skipMailchimpAuditAssessmentByCompanyForProject,
  };
};
export default useMailchimpAuditAssessmentMutations;
