import makeStyles from '@material-ui/core/styles/makeStyles';
import { InternalTeamDetailsHeaderProps } from './index';

const useDetailsHeaderStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    position: 'relative',
  },
  heroHeader: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  heroImageContainer: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 116,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: 'auto',
    },
  },
  heroImage: (props: InternalTeamDetailsHeaderProps) => {
    const displayImageUrl = props.internalTeam?.displayImageUrl || '';

    return {
      display: 'block',
      width: '100%',
      paddingTop: '100%',
      minWidth: 192,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url('${displayImageUrl}')`,
      boxShadow: '0px 2px 16px rgba(36, 24, 21, 0.19)',
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        width: '100%',
        maxHeight: 384,
        maxWidth: 384,
        minWidth: 'unset',
      },
    };
  },
  userName: {
    marginTop: 16,
  },
  heroDetailsContainer: {
    flexGrow: 1,
  },
  headerActions: {
    position: 'absolute',
    top: 15,
    right: 0,
  },
  copyToSlackButton: {},
  description: {},
  buttonSpacer: {
    marginRight: 8,
  },
}));

export default useDetailsHeaderStyles;
