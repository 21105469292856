import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { RadioCardsWithSlider } from '../../Registration/RadioCardsWithSlider';
import { validateRadioCardsWithSlider } from '../../../../services/validation';

/**
 * will create a 3 in a row cards, that are multichoice checkboxes, add them them to redux form
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardRadioCardWithSliderGroupField(props) {
  const { name, enums, isRequired } = props;
  const validator = isRequired ? [validateRadioCardsWithSlider('selected')] : [];

  return <Field component={RadioCardsWithSlider} name={name} validate={validator} enums={enums} />;
}

WizardRadioCardWithSliderGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  enums: PropTypes.array.isRequired,
  // disabled:   PropTypes.bool,
  isRequired: PropTypes.bool,
  // value:        PropTypes.number,
};

WizardRadioCardWithSliderGroupField.defaultProps = {
  // label:      '',
  // disabled:   false,
  isRequired: false,
  // value:        0,
};
