import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../fe_common/client/app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {},
    tabsRoot: {
      backgroundColor: '#fff',
      borderBottom: '0 none',
      boxShadow:
        `0px 1px 3px 0px ${colors.blackOpacity(0.2)}, ` +
        `0px 1px 1px 0px ${colors.blackOpacity(0.14)}` +
        ` 0px 2px 1px -1px ${colors.blackOpacity(0.12)}`,
    },
  }),
);

export default useStyles;
