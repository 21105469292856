import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { validateRequired } from '../../../../services/validation';
import FilesUpload from '../../Registration/FilesUpload';

export function WizardFilesUploaderField(props) {
  // console.log('WizardFileUploaderField props', props);

  const { name, label, disabled, isRequired, ...rest } = props;

  const validator = isRequired ? [validateRequired] : [];

  return (
    <div>
      <Field component={FilesUpload} name={name} label={label} disabled={disabled} validate={validator} {...rest} />
    </div>
  );
}

WizardFilesUploaderField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

WizardFilesUploaderField.defaultProps = {
  label: '',
  disabled: false,
  isRequired: false,
};
