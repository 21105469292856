import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';

import FeedFilter from '../FeedFilter';
import { Button } from '../../inputs';
import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';

const useStyles = makeStyles(() => ({
  root: {},
  dialogTitle: {
    padding: 0,
    border: '0 none',
  },
  dialogTitleBar: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  titleBarFiller: {
    flexGrow: 1,
    textAlign: 'left',
  },
  titleBarTitle: {
    flexGrow: 2,
    textAlign: 'center',
  },
  titleBarAction: {
    flexGrow: 0,
    textAlign: 'right',
  },
  titleBarClearButton: {
    color: colors.black,
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    justifyContent: 'center',
    padding: 24,
  },
  dialogActionButtonClose: {
    width: '90%',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function FeedFilterDialog(props) {
  const { isOpen, onClose, filter, feed, legend, onSetFilter } = props;

  const classes = useStyles();

  const handleClearFilter = () => {
    onSetFilter({});
  };

  const handleOnClose = () => {
    // close the dialog
    onClose();
  };

  return (
    <Dialog open={isOpen} fullScreen keepMounted TransitionComponent={Transition}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <AppBar position="sticky" className={classes.dialogTitleBar}>
          <div className={classes.titleBarFiller}>&nbsp;</div>
          <TextDisplay className={classes.titleBarTitle}>Filters</TextDisplay>
          <div className={classes.titleBarAction}>
            <Button label="clear" onClick={handleClearFilter} className={classes.titleBarClearButton} />
          </div>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FeedFilter filter={filter} feed={feed} legend={legend} onSetFilter={onSetFilter} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label="close"
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleOnClose}
          className={classes.dialogActionButtonClose}
        />
      </DialogActions>
    </Dialog>
  );
}

FeedFilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  feed: PropTypes.object.isRequired,
  legend: PropTypes.object.isRequired,
  onSetFilter: PropTypes.func.isRequired,
  // onApplyFilter: PropTypes.func.isRequired,
};

export default FeedFilterDialog;
