import { Filter, SortingRule } from 'react-table-6';

export const initialSort: SortingRule[] = [
  {
    id: 'id',
    desc: true,
  },
];

export const initialFilter: Filter[] = [
  {
    id: 'projectLifeCycleStatus',
    value: {
      key: 'projectLifeCycleStatus',
      value: 'LIVE',
      operator: 'EQUALS',
    },
  },
];
