import { ChangeEvent, useState } from 'react';

import useQueryString from './useQueryString';
import { reportTabClick } from '../logic/events';

// eslint-disable-next-line arrow-body-style
export const getInitialTab = (defaultTab: string, tabQueryParam?: unknown): string => {
  // Not sure about this. thinking about updating the query string on each click. instead of removing the qs.
  // Removed this piece of code as it was causing the page to rerender and change the tab back to default
  // if (tabQueryParam) {
  //   window.history.replaceState(null, window.document.title, window.location.href.split('?')[0]);
  // }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <string>tabQueryParam || (defaultTab as string); // initial index of tabs to show
};

interface Options {
  shouldReportEvent?: boolean;
  pageName?: string;
  traits?: Record<string, unknown>;
}

export const useMuiTabState = <T extends { [name: string]: string }>(
  defaultTab: string,
  possibleTabs: T,
  options: Options = {}
): [string, (e: ChangeEvent<unknown>, t: string) => void] => {
  const { tab: tabQueryParam } = useQueryString();

  const [tab, setTabState] = useState<string>(() => {
    let tabToSet = getInitialTab(defaultTab, tabQueryParam);

    if (!possibleTabs[tabToSet]) {
      tabToSet = defaultTab || '';
    }

    return tabToSet;
  });

  const setTab = (_e: ChangeEvent<unknown>, t: string) => {
    if (t !== tab) {
      setTabState(t);

      const { shouldReportEvent = false, pageName = '', traits = {} } = options;

      if (shouldReportEvent && pageName) {
        reportTabClick(pageName, t, traits);
      }
    }
  };

  return [tab, setTab];
};
