import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import withStyles from '@material-ui/core/styles/withStyles';

import Section from '../../../../fe_common/client/components/atoms/Section';
import { PARTICIPANT_ONBOARDING_STATUS_PAIRS } from '../../../../fe_common/client/app/enums';

const styles = {
  root: {
    marginTop: 0,
    paddingTop: 24,
  },
};

const ProjectOnboardingStatus = ({ status, classes }) => {
  const [activeStep] = useState(() => PARTICIPANT_ONBOARDING_STATUS_PAIRS.findIndex(({ value }) => value === status));

  return (
    <Section
      title="Onboarding status"
      noBorder
      classes={{
        root: classes.root,
      }}
    >
      <Stepper activeStep={activeStep}>
        {PARTICIPANT_ONBOARDING_STATUS_PAIRS.map(({ label, value }) => (
          <Step key={value} id={value}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Section>
  );
};

ProjectOnboardingStatus.propTypes = {
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectOnboardingStatus);
