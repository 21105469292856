import React, { FC } from 'react';
import { Project } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { UpdateProjectWithOperationResultMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectWithOperationResultMutation';

import useMutation from '../../../../../../fe_common/client/hooks/useMutation';
import { ProjectNameEditor } from '../../../../../../fe_common/client/components/editables/project';
import { getProjectLifeCycleStatus } from '../../../../../../fe_common/client/logic/project';
import { useProjectDetailsState } from '../../../ProjectDetailsProvider';

interface EditProjectNameHeaderProps {
  project: Project;
  classes?: Record<string, string>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    viewModeText: {},
  }),
);

const EditProjectNameHeader: FC<EditProjectNameHeaderProps> = (props) => {
  const classes = useStyles(props);

  const { project, refetchData } = useProjectDetailsState();

  const {
    isDiscovery: enableInDiscovery,
    isOnboarding: enableInOnboarding,
    isLive: enableInLive,
  } = getProjectLifeCycleStatus(project);
  const enableInAll = enableInDiscovery || enableInOnboarding || enableInLive;
  const updateProjectMutation = useMutation(UpdateProjectWithOperationResultMutation);

  return (
    <div className={classes.root}>
      <ProjectNameEditor
        project={project}
        mutation={updateProjectMutation}
        enableEdit={enableInAll}
        onSuccess={refetchData}
        classes={{ viewModeText: classes.viewModeText }}
      />
    </div>
  );
};

export default EditProjectNameHeader;
