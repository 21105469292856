import React from 'react';
import { MediaSpendDivision } from '@mayple/types';
import { Column } from 'material-table';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import SimpleIcon from '../../../../../fe_common/client/components/atoms/SimpleIcon';
import { MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS } from '../../../../../fe_common/client/app/enums';

export const getColumns = (classes: Record<string, string>): Column<Record<string, unknown>>[] => {
  const columns: Column<Record<string, unknown>>[] = [
    {
      field: 'projectId',
      hidden: true,
    },
    {
      field: 'cycleId',
      hidden: true,
    },
    {
      title: 'Channel',
      field: 'skillType',
      width: '40%',
      render: function MediaChannelCell(data) {
        const lookup = MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS as Record<string, any>;
        return (
          <ListItem classes={{ root: classes.listItemRoot }}>
            <ListItemAvatar>
              <SimpleIcon icon={lookup[`${data.skillType as string}-svgIcon`]} />
            </ListItemAvatar>
            <ListItemText>{lookup[data.skillType as string]}</ListItemText>
          </ListItem>
        );
      },
    },
    {
      title: 'Ad Spend',
      field: 'spendForSkillType',
      type: 'currency',
      align: 'right',
      currencySetting: {
        currencyCode: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
      width: '30%',
    },
  ];

  return columns;
};

export const getTotalRowColumns = (): Column<Record<string, unknown>>[] => {
  const columns: Column<Record<string, unknown>>[] = [
    {
      field: 'summaryTitle',
      width: '40%',
      cellStyle: {
        fontWeight: 'bold',
      },
    },
    {
      field: 'totalAdSpend',
      type: 'currency',
      align: 'right',
      currencySetting: {
        currencyCode: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
      width: '30%',
      cellStyle: {
        fontWeight: 'bold',
      },
    },
  ];

  return columns;
};

export const getTotalRowData = (
  mediaSpendDivision: MediaSpendDivision[],
): Array<{ summaryTitle: string; totalAdSpend: number }> => {
  const totalAdSpend = (mediaSpendDivision || []).reduce((acc, { spendForSkillType }) => acc + spendForSkillType, 0);

  return [
    {
      summaryTitle: 'Total',
      totalAdSpend,
    },
  ];
};
