import { formatFloatCurrency } from '../../../../../../services/utils';

import { toNiceEnumsList } from '../../../../../../../fe_common/client/services/utils';
import {
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  REMOTE_WORK_LOOKUP,
} from '../../../../../../../fe_common/client/app/enums';
import { REMOTE_WORK } from '../../../../../../../fe_common/client/app/consts';
import { yesNoFormatter } from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';

const PROPS_GROUPS = {
  CUSTOMER_REQUESTS: 'Customer Requests',
};

export const getMarketerLocationPreference = (project) => {
  const country = project?.company?.companyProfile?.contactDetails?.postalAddress?.country;
  const locations = project?.locations || [];
  // Requested by https://mayple.slack.com/archives/CJLP2PN9H/p1609408110048100
  if (locations.length === 1 && locations[0] === country) {
    return REMOTE_WORK.NA;
  }

  const { preferLocalMarketers, preferMarketerInTargettedLocations } = project;
  if (preferLocalMarketers) {
    return REMOTE_WORK_LOOKUP[REMOTE_WORK.DOMESTIC];
  }

  if (preferMarketerInTargettedLocations) {
    return REMOTE_WORK_LOOKUP[REMOTE_WORK.ON_TARGET];
  }

  return REMOTE_WORK_LOOKUP[REMOTE_WORK.NO_PREFERENCE];
};

export const getProjectBriefProperties = (isProjectLead, classes) => {
  const yesNoToRedGreen = (value) => (value ? classes.green : classes.red);

  return [
    {
      label: 'Media budget',
      path: isProjectLead ? 'estimatedMediaBudget' : 'requestedMediaBudget',
      formatter: (value, defaultValue, data) => {
        const { currency = 'USD' } = data;
        return formatFloatCurrency(value, currency) || defaultValue;
      },
      group: PROPS_GROUPS.CUSTOMER_REQUESTS,
    },
    {
      label: 'Requested Services',
      path: 'requestedServices',
      formatter: (value) => {
        const requestedServices = (value || []).map(({ serviceType }) => serviceType);
        return toNiceEnumsList(requestedServices, MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS);
      },
      group: PROPS_GROUPS.CUSTOMER_REQUESTS,
    },
    {
      label: 'Marketer location preference',
      path: 'marketerLocationPreference',
      group: PROPS_GROUPS.CUSTOMER_REQUESTS,
    },
    {
      label: 'Requested marketing audit?',
      path: 'marketingAuditRequested',
      formatter: yesNoFormatter,
      customClass: yesNoToRedGreen,
      group: PROPS_GROUPS.CUSTOMER_REQUESTS,
    },
  ];
};
