import React, { FC } from 'react';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import Paper from '@material-ui/core/Paper';

import useAdditionalServices from './logic';
import PackageSelector from './PackageSelector';
import ServicesEditor from './ServicesEditor';
import { Button } from '../../../../../fe_common/client/components/inputs';

interface AdditionalServicesFieldProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

const AdditionalServicesField: FC<AdditionalServicesFieldProps> = ({ input, meta }) => {
  const {
    packages,
    selectorDialogOpen,
    closeDialog,
    onCreateNewService,
    onPackageSelect,
    onServiceNameChange,
    onServiceCostChange,
    onServiceRecurringChange,
    onGeneralTextChange,
    onDescriptionChange,
    onDeleteService,
  } = useAdditionalServices(input);

  // console.log('packages', packages);

  const { value: services } = input;

  return (
    <Paper style={{ padding: 16 }}>
      <ServicesEditor
        meta={meta}
        services={services}
        packages={packages}
        onServiceNameChange={onServiceNameChange}
        onServiceCostChange={onServiceCostChange}
        onServiceRecurringChange={onServiceRecurringChange}
        onGeneralTextChange={onGeneralTextChange}
        onDeleteService={onDeleteService}
        onDescriptionChange={onDescriptionChange}
      />

      <PackageSelector open={selectorDialogOpen} onSelect={onPackageSelect} onClose={closeDialog} packages={packages} />

      <Button label="Create a new non-ppc service" variant="outlined" color="primary" onClick={onCreateNewService} />
    </Paper>
  );
};

export default AdditionalServicesField;

export { validateAdditionalServices } from './logic';
