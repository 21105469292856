import { useMemo } from 'react';
import { Project, ProjectStage } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { SelectedMatchedMarketersQuery } from 'growl-graphql/dist/queries/project/SelectedMatchedMarketersQuery';
import { DeclineSelectedMatchedMarketerMutation } from 'growl-graphql/dist/mutations/project/DeclineSelectedMatchedMarketerMutation';
import { ApproveSelectedMatchedMarketerMutation } from 'growl-graphql/dist/mutations/project/ApproveSelectedMatchedMarketerMutation';

import { ExpertMatchingData } from './types';
import { getMatchedExpertData } from './logic';
import { useMutation } from '../../../../../hooks';
import { clientLogger } from '../../../../../services/logger';

/**
 * Hook to receive a project selected experts for company approval, and the ability to decline them.
 * @param project
 */
const useExpertMatchingData = (project: Project | null | undefined): ExpertMatchingData => {
  const { stage, id: projectId } = project || ({} as Project);

  const hasExpertSelectedForApproval = [ProjectStage.MARKETER_APPROVAL, ProjectStage.OPPORTUNITIES].includes(stage);

  const { mutate: declineMatchedSelectedMarketer, loading: savingDeclineSelectedMatchedMarketer } = useMutation(
    DeclineSelectedMatchedMarketerMutation
  );

  const { mutate: approveSelectedMatchedMarketer, loading: savingApprovedSelectedMatchedMarketer } = useMutation(
    ApproveSelectedMatchedMarketerMutation
  );

  const { data, loading, error } = useQuery(SelectedMatchedMarketersQuery.query, {
    variables: { projectId },
    skip: !hasExpertSelectedForApproval,
    fetchPolicy: 'network-only',
  });

  const selectedMatches = useMemo(
    () => getMatchedExpertData(data?.selectedMatchedMarketers || []),
    [data?.selectedMatchedMarketers]
  );

  const declineSelectedMatchedMarketerHandler = async (marketerIdToDecline: number) => {
    const variables = { projectId, marketerId: marketerIdToDecline };
    const response = await declineMatchedSelectedMarketer({ variables });

    clientLogger.debug(`declineMatchedMarketer response:\n\n${JSON.stringify(response, null, 2)}`);
  };

  const approveSelectedMatchedMarketerHandler = async (
    marketerIdToApprove: number,
    merchantInitiatedIrregularChargeReasoning?: string
  ): Promise<boolean> => {
    try {
      const variables = { projectId, marketerId: marketerIdToApprove, merchantInitiatedIrregularChargeReasoning };
      const response = await approveSelectedMatchedMarketer({ variables });

      clientLogger.debug(`approveSelectedMatchedMarketer response:\n\n${JSON.stringify(response, null, 2)}`);

      return response?.data?.approveSelectedMatchedMarketer?.success;
    } catch (e) {
      return false;
    }
  };

  return {
    selectedMatches,
    error,
    loading,
    loadingMutation: savingDeclineSelectedMatchedMarketer || savingApprovedSelectedMatchedMarketer,
    declineSelectedMatchedMarketer: declineSelectedMatchedMarketerHandler,
    approveSelectedMatchedMarketer: approveSelectedMatchedMarketerHandler,
  };
};

export default useExpertMatchingData;
