import { useQuery } from '@apollo/react-hooks';
import { InternalTeam } from '@mayple/types';
import { GetInternalTeamsByWorkingGroupQuery } from 'growl-graphql/dist/queries/GetInternalTeamsByWorkingGroupQuery';

import { ApolloError } from 'apollo-client';

export type InternalTeamsByWorkingGroupData = {
  data: InternalTeam[] | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const useInternalTeamsByWorkingGroupData = (workingGroup: string): InternalTeamsByWorkingGroupData => {
  const { data, loading, error } = useQuery(GetInternalTeamsByWorkingGroupQuery.query, {
    variables: {
      workingGroup,
    },
  });

  return {
    data: data?.getInternalTeamsByWorkingGroup?.items,
    loading,
    error,
  };
};

export default useInternalTeamsByWorkingGroupData;
