import { TaskType } from '@mayple/types';

import { TaskListItemData } from './types';
import { AssessmentSubTask } from '../AssessmentDialog/types';

const getTaskListItemData = (subTask: AssessmentSubTask): TaskListItemData => {
  // eslint-disable-next-line no-underscore-dangle
  if (subTask?.__typename === TaskType.MonthlyMarketerAssessmentByGrowthStrategist) {
    const marketer = subTask.project?.participants?.[0].marketerMatch;
    const primaryText = `Expert ${marketer?.name} (${marketer?.marketerId}) Monthly Marketer Assessment`;
    const secondaryText =
      `Company: ${subTask.project.company.name} (${subTask.project.company.id})\n` +
      `Project: ${subTask.project.name} (${subTask.project.id})`;

    return {
      primaryText,
      secondaryText,
      marketer,
    };
  }

  // eslint-disable-next-line no-underscore-dangle
  if (subTask?.__typename === TaskType.PostProjectMatchOfferMarketerAssessment) {
    const { marketer, marketerId } = subTask;
    const primaryText = `Assessment after matching expert ${marketer?.name} (${marketerId})`;
    const secondaryText =
      `Company: ${subTask.project.company.name} (${subTask.project.company.id})\n` +
      `Project: ${subTask.project.name} (${subTask.project.id})`;

    return {
      primaryText,
      secondaryText,
      marketer,
    };
  }

  return {
    // eslint-disable-next-line no-underscore-dangle
    primaryText: `Missing implementation for ${subTask.__typename}`,
  };
};

export default getTaskListItemData;
