import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { ProjectBillingEditWizardProps } from './types';
import WizardSteps from './steps';
import projectBilling from '../../../../pages/ProjectBillingPage/logic';

import { Button } from '../../../../../fe_common/client/components/inputs';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

const ProjectBillingEditWizard: FC<ProjectBillingEditWizardProps> = (props) => {
  const history = useHistory();

  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const [enableNextCycleEdit] = useState(() => {
    const { isLive } = projectBilling.getProjectLifeCycles(props.project);
    return isLive;
  });

  const {
    project,
    onSuccess,
    hasOpenOpportunities,
    updateProjectCycleWithOperationResultMutation,
    updateProjectCycleAdditionalServicesMutation,
    returnToUrl,
  } = props;

  const initialValues = useMemo(() => projectBilling.getInitialValuesForOngoingProject(project), [project]);

  const projectId = project?.id;
  const company = project?.company;
  const errors: string[] = [];

  // Gather graphql errors if exists, and pass it to steps render for additional helpful info.
  [updateProjectCycleWithOperationResultMutation, updateProjectCycleAdditionalServicesMutation].forEach(({ error }) => {
    if (error?.graphQLErrors?.length) {
      error?.graphQLErrors.forEach((e: string) => errors.push(e));
    }
  });

  const handleFormSubmit = useCallback(
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    async (formValuesSubmitted: any, _dispatch: any, formProps: any): Promise<any> => {
      const success = await projectBilling.onSubmitForOngoingProject(
        formValuesSubmitted,
        project,
        hasOpenOpportunities,
        updateProjectCycleWithOperationResultMutation,
        updateProjectCycleAdditionalServicesMutation,
      );

      /* KEEP THIS - WE WANT TO TRY IN THE FUTURE SUBMIT VALIDATION INSTEAD OF SYNC VALIDATION */
      //  https://redux-form.com/7.4.2/examples/submitvalidation/
      //  For some reason the submit validation doesn't update the field errors, thus not showing the invalid errors.
      //  So for now we use sync validation.
      //  import SubmissionError from 'redux-form/lib/SubmissionError';
      //
      //  const errors = validateBillingConfig(formValuesSubmitted);
      //
      //  console.log('errors', errors);
      //
      //  const toError = {};
      //  for (const [key, value] of Object.entries(errors)) {
      //
      //  if (value) {
      //  toError[key] = value;
      //  }
      //
      //  }
      //
      //  console.log('toError', toError);
      //
      //  if (Object.values(toError).length > 0) {
      //
      //  toError._error = 'Invalid values';
      //
      //  this.setState({ dialogOpen: false });
      //  throw new SubmissionError(toError);

      if (success) {
        formProps?.destroy?.();
        await onSuccess?.();
        setRedirectTo(returnToUrl ?? `/projects/${projectId}`);
      }
    },
    [
      project,
      hasOpenOpportunities,
      updateProjectCycleWithOperationResultMutation,
      updateProjectCycleAdditionalServicesMutation,
      onSuccess,
      returnToUrl,
      projectId,
    ],
  );

  if (redirectTo) {
    history.push(redirectTo);
  }

  if (!project) {
    return <p>Error. No project data supplied.</p>;
  }

  return (
    <div>
      <TextDisplay variant="h4" gutterBottom>
        Editing project {projectId} owned by {company?.name}
      </TextDisplay>
      <Link to={`/projects/${projectId}`}>
        <Button label="BACK TO PROJECT PAGE" color="primary" />
      </Link>
      {enableNextCycleEdit && (
        <a href="#next-cycle-container">
          <Button label="JUMP TO NEXT CYCLE" color="secondary" />
        </a>
      )}
      <WizardForm
        form={`projectBillingWizard${projectId}`}
        initialValues={initialValues}
        // @ts-ignore
        steps={WizardSteps}
        onSubmit={handleFormSubmit}
        project={project}
        watchAllValues
        destroyOnUnmount
        validate={projectBilling.validateBillingConfig}
        enableNextCycleEdit={enableNextCycleEdit}
        errors={errors}
      />
    </div>
  );
};

export default ProjectBillingEditWizard;
