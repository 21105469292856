import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      listStyleType: 'none',
    },
    container: {
      listStyleType: 'none',
    },
    entityProperty: {
      display: 'block',
    },
    entityPropertyLabel: {},
    entityPropertyValue: {
      fontWeight: 'bold',
    },
    secondaryActionRoot: {},
  }),
);

export default useStyles;
