import React, { FC, useMemo } from 'react';
import { Company, ContactDetails, Project, ProjectSpecialSubType, ProjectSpecialType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DisableCompanyMutation } from 'growl-graphql/dist/mutations/DisableCompanyMutation';
import { CompanyProjectsQuery } from 'growl-graphql/dist/queries/company/CompanyProjectsQuery';
import { useHistory } from 'react-router';

import { getProjectBillingPagePath, getProjectPagePath } from '../../consts';
import CompanyProjects from '../components/CompanyProjects/index';
import ProjectCreationDialog from '../components/ProjectCreationDialog';
import CompanyProfileDetails from '../components/CompanyProfileDetails';
import EntityContactDetails from '../../../components/cpanel/components/molecules/EntityContactDetails';
import DisableEntityControl from '../../../containers/shared/DisableEntityControl';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import { SkeletonContainer } from '../../../../fe_common/client/components/display';
import { useDialog, useMutation } from '../../../../fe_common/client/hooks';
import { Button } from '../../../../fe_common/client/components/inputs';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';

interface CompanyBasicDetailsTabProps {
  company: Company;
}

const CompanyBasicDetailsTab: FC<CompanyBasicDetailsTabProps> = (props) => {
  const { company } = props;
  const { id: companyId } = company;
  const history = useHistory();

  const {
    data,
    loading,
    error,
    refetch: refetchCompanyProjects,
  } = useQuery(CompanyProjectsQuery.query, {
    variables: { companyId },
    notifyOnNetworkStatusChange: false,
  });

  const { mutate: disableCompanyMutation } = useMutation(DisableCompanyMutation);
  const { open, openDialog, closeDialog } = useDialog();
  const projects = useMemo(() => (data?.company?.projects || []).reverse(), [data?.company?.projects]);
  const onCreateProjectSuccess = (createdProject: Project) => {
    const { id: projectId, specialType, specialSubType } = createdProject;
    if (
      specialType === ProjectSpecialType.MAILCHIMP_AUDIT_TYPE &&
      specialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2
    ) {
      history.push(getProjectPagePath(projectId));
    } else {
      history.push(getProjectBillingPagePath(projectId));
    }
  };

  return (
    <div>
      <Section
        title="Projects"
        subTitle="Projects created by the company"
        layout={SECTION_LAYOUT.ONE_COLUMN}
        headerAction={
          <Button
            label="create new project"
            color="primary"
            type="button"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={openDialog}
          />
        }
        noBorder
        noTopMargin
      >
        <SectionContent>
          <SkeletonContainer loading={loading} height={300}>
            {error && <CalloutMessage message={error.message} type="error" />}
            <CompanyProjects projects={projects} refetch={refetchCompanyProjects} />
          </SkeletonContainer>
        </SectionContent>
      </Section>

      <Section title="Business details" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}>
        <SectionContent>
          <EntityContactDetails contactDetails={company?.companyProfile?.contactDetails || ({} as ContactDetails)} />
          <CompanyProfileDetails company={company} />
        </SectionContent>
      </Section>

      <Section>
        <DisableEntityControl
          entity={company}
          mutation={disableCompanyMutation}
          variables={{ companyId: company.id }}
          title="Disable Company"
          buttonLabel="Disable Company"
          warningText={`You are about to disable company ${company.name} with ID: ${company.id}`}
        />
      </Section>

      <ProjectCreationDialog
        companyId={companyId}
        open={open}
        onClose={closeDialog}
        onSuccess={onCreateProjectSuccess}
      />
    </div>
  );
};

export default CompanyBasicDetailsTab;
