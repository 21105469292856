import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

import { TextDisplay } from '../../../fe_common/client/components/display';

const styles = {
  avatar: {
    marginTop: 10,
    width: 75,
    height: 75,
    boxShadow: '1px 1px 18px #888888',
  },
  progress: {
    height: 12,
  },
  card: {
    height: '100%',
  },
};

const ResultItem = ({ marketerMatchStatistic, classes, topCount, index }) => {
  const { marketerId, count, marketer } = marketerMatchStatistic;

  const { displayImageUrl, name } = marketer;

  const percent = Math.ceil((count / topCount) * 100);

  return (
    <Card className={classes.card}>
      <CardContent>
        <TextDisplay variant="caption" gutterBottom>
          Count: {count} - Rank: {index + 1}
        </TextDisplay>
        <div>
          <LinearProgress className={classes.progress} variant="determinate" value={percent} />
          <TextDisplay variant="caption" gutterBottom>
            {percent}% from top shown
          </TextDisplay>
        </div>
        <div>
          <Avatar className={classes.avatar} src={displayImageUrl} />
        </div>
        <Link to={`/marketers/${marketerId}`}>
          <h6>
            <u>{name}</u>
          </h6>
        </Link>
      </CardContent>
    </Card>
  );
};

ResultItem.propTypes = {
  marketerMatchStatistic: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  topCount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(ResultItem);
