import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 0,
    },
    sectionRoot: {
      marginTop: 72,
      paddingTop: 72,
    },
    sectionHeader: {
      marginBottom: 0,
    },
    field: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      marginTop: 16,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      '& > *': {
        width: '100%',
      },
    },
    label: {
      width: 'auto',
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
