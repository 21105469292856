import { ExecutionResult } from 'graphql';
import { EntityOperationResponse } from '@mayple/types';
import { SetCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistMutation } from 'growl-graphql/dist/mutations/project/ExistingMarketingOptimizationImplementation/SetCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistMutation';
import { ApproveExistingMarketingOptimizationImplementationReportByGrowthStrategistMutation } from 'growl-graphql/dist/mutations/project/ExistingMarketingOptimizationImplementation/ApproveExistingMarketingOptimizationImplementationReportByGrowthStrategistMutation';

import { useMutation } from '../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../fe_common/client/services/logger';

export type ImplementationDeckApprovalData = {
  savingGSComments: boolean;
  setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler: (
    onSuccess?: () => Promise<void>,
  ) => Promise<void>;

  savingGSApprove: boolean;
  approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler: (
    onSuccess?: () => Promise<void>,
  ) => Promise<void>;
};

const useImplementationDeckApproval = (projectId: number): ImplementationDeckApprovalData => {
  const {
    mutate: setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategist,
    loading: savingGSComments,
  } = useMutation(SetCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistMutation);

  const {
    mutate: approveExistingMarketingOptimizationImplementationReportByGrowthStrategist,
    loading: savingGSApprove,
  } = useMutation(ApproveExistingMarketingOptimizationImplementationReportByGrowthStrategistMutation);

  const setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler = async (
    onSuccess?: () => Promise<void>,
  ) => {
    if (!projectId) {
      throw Error('Missing project id');
    }

    try {
      const variables = {
        projectId,
      };

      const result: ExecutionResult<{
        setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategist: EntityOperationResponse;
      }> = await setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategist({ variables });

      if (result.data?.setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategist.success) {
        await onSuccess?.();
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to report GS comments');
    }
  };

  const approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler = async (
    onSuccess?: () => Promise<void>,
  ) => {
    if (!projectId) {
      throw Error('Missing project id');
    }

    try {
      const variables = {
        projectId,
      };

      const result: ExecutionResult<{
        approveExistingMarketingOptimizationImplementationReportByGrowthStrategist: EntityOperationResponse;
      }> = await approveExistingMarketingOptimizationImplementationReportByGrowthStrategist({ variables });

      if (result.data?.approveExistingMarketingOptimizationImplementationReportByGrowthStrategist.success) {
        await onSuccess?.();
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to report GS comments');
    }
  };

  return {
    savingGSComments,
    setCommentsOnExistingMarketingOptimizationImplementationByGrowthStrategistHandler,
    savingGSApprove,
    approveExistingMarketingOptimizationImplementationReportByGrowthStrategistHandler,
  };
};
export default useImplementationDeckApproval;
