export enum Answers {
  YES = 'YES',
  NO = 'NO',
  I_DONT_KNOW = 'I_DONT_KNOW',
  MAYPLE = 'MAYPLE',
  CLIENT = 'CLIENT',
  EXPERT = 'EXPERT',
}

export enum ProjectStopReasonsQuestionIds {
  isExpertBeingReplaced = 'IS_EXPERT_REPLACED',
  isClientHaveOtherProject = 'DOES_THE_CLIENT_HAS_ANOTHER_PROJECT',
  stopProjectInitiator = 'WHO_DECIDES_TO_STOP_THE_PROJECT',
  isClientHappyWithExpert = 'WAS_THE_CLIENT_HAPPY_WITH_EXPERT',
  isClientHappyWithMayple = 'WAS_THE_CLIENT_HAPPY_WITH_MAYPLE',
  isPackageRecurring = 'WAS_THIS_ONE_TIME_PACKAGE',
  isProjectSeasonal = 'WAS_THE_PROJECT_SEASONAL',
  isClientHavingBusinessIssues = 'IS_THE_CLIENT_HAVE_BUSINESS_ISSUES',
}
