import React from 'react';
import { Project } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ProjectAutomaticConditionalStopping from './ProjectAutomaticConditionalStopping';
import ProjectFundsRemainderEditor from '../../../components/ProjectFundsRemainderEditor';
import ShowByProjectStatus from '../../../components/ShowByProjectStatus';
import StopLiveProject from '../../../components/StopLiveProject';
import UpdateProjectAutomaticRenewal from '../../../../../components/cpanel/components/molecules/UpdateProjectAutomaticRenewal';

import SectionContent from '../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import Section from '../../../../../../fe_common/client/components/atoms/Section';

import useCommonStyles from '../style';

interface ProjectStopSectionProps {
  project: Project;
  isOnboarding: boolean;
  refetchProjectData: () => void;
}

const ProjectStopSection: React.FC<ProjectStopSectionProps> = (props) => {
  const { project, isOnboarding, refetchProjectData } = props;

  const { automaticRenewal, projectLifeCycleStatus } = project;

  const classes = useCommonStyles();

  let stopProjectTitle = 'Stopping a Project';

  if (isOnboarding) {
    stopProjectTitle = 'Cancelling a Project';
  }

  return (
    <Section
      title={stopProjectTitle}
      subTitle="Stopping a project is final - a stopped project cannot be restarted. Instead, a new project
              should be created."
      noBorder
    >
      <SectionContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ProjectFundsRemainderEditor project={project} />
          </Grid>

          <ProjectAutomaticConditionalStopping projectId={project?.id} />

          <Divider className={classes.divider} />

          <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive showOnOnBoarding>
            <Grid item xs={12} md={6}>
              <StopLiveProject project={project} onSuccess={refetchProjectData} />
            </Grid>
          </ShowByProjectStatus>

          <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive>
            <Grid item xs={12} md={6}>
              <UpdateProjectAutomaticRenewal
                project={project}
                automaticRenewal={automaticRenewal}
                onSuccess={refetchProjectData}
              />
            </Grid>
          </ShowByProjectStatus>
        </Grid>
      </SectionContent>
    </Section>
  );
};

export default ProjectStopSection;
