import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { TextDisplayProps } from './index';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    bold: {
      fontWeight: 'bold',
    },
    italic: {
      fontStyle: 'italic',
    },
    inline: {
      display: 'inline-block',
    },
    underline: {
      textDecoration: 'underline',
    },
    color: ({ colorOverride }: Partial<TextDisplayProps>) => ({
      color: colorOverride || undefined,
    }),
  })
);

export default useStyles;
