import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import BILLING_CYCLE_TABLE_COLUMNS from './consts';
import PROJECT_BILLING_REVIEW_CSV_COLUMNS from './exprotToCSV';

import { useReactTableDataFilterSorter } from '../../../../../fe_common/client/hooks';
import { exportDataToCsv, getFilteredSortedData } from '../../../../../fe_common/client/services/ExportToCSV';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    button: {
      marginBottom: 12,
      marginRight: 12,
    },
    leftIcon: {
      marginRight: 12,
    },
  }),
);

const handleExportToCSV = (items: any, columns: any, dataTableFilter: any, dataTableSort: any) => {
  const data = getFilteredSortedData(items, columns, dataTableFilter, dataTableSort);
  exportDataToCsv(PROJECT_BILLING_REVIEW_CSV_COLUMNS, data);
};

interface ExportToCSVButtonProps {
  items: Array<any> | null | undefined;
  loading?: boolean;
}

const ExportToCSVButton: React.FC<ExportToCSVButtonProps> = (props) => {
  const classes = useStyles();
  const { items, loading = false } = props;

  const { dataTableFilter, dataTableSort } = useReactTableDataFilterSorter();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        handleExportToCSV(items, BILLING_CYCLE_TABLE_COLUMNS, dataTableFilter, dataTableSort);
      }}
      aria-label="Save as CSV"
      className={classes.button}
      disabled={loading || !items?.length}
      startIcon={<SaveAltIcon className={classes.leftIcon} />}
    >
      Save as CSV
    </Button>
  );
};

export default ExportToCSVButton;
