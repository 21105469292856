/* eslint-disable func-names */
import { useMemo } from 'react';
import { Project } from '@mayple/types';
import { checkHasAssignedGS, checkHasAssignedMC } from './logic';

/**
 * Hook to check if GS (Growth Strategist) is assigned to the project
 *
 * @param project
 */
const useHasAssignedInternalTeams = (project: Partial<Project> | null | undefined): Record<string, any> =>
  useMemo(
    () => ({
      hasAssignedGS: checkHasAssignedGS(project),
      hasAssignedMC: checkHasAssignedMC(project),
    }),
    [project]
  );

export default useHasAssignedInternalTeams;
