import { Column } from 'material-table';

export const MATCHING_SIMULATOR_WEIGHTS_KEY = `matchingSimulatorWeights`;

export const columns: Column<Record<string, unknown>>[] = [
  {
    field: 'fileName',
    title: 'Name',
  },
  {
    field: 'projectId',
    title: 'Project Id',
  },
];
