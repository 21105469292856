import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/es/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';

import { getCrackleUrl } from '../../../app/utils';
import ExternalLink from '../../../../fe_common/client/components/display/ExternalLink';

const styles = () => ({
  root: {
    listStyleType: 'none',
  },
  container: {
    listStyleType: 'none',
  },
  marketerProperty: {
    display: 'block',
  },
  marketerPropertyLabel: {},
  marketerPropertyValue: {
    fontWeight: 'bold',
  },
  secondaryActionRoot: {},
});

const defaultFormatter = (value) => value;

const getMarketerExtraData = (marketer, marketerProperties, classes) => {
  if (!marketerProperties.length) {
    return null;
  }

  const { marketerId } = marketer;

  return marketerProperties.map(({ marketerProperty, propertyLabel, propertyFormatter = defaultFormatter }) => {
    const value = get(marketer, marketerProperty, '');
    const formattedValue = propertyFormatter(value);

    return (
      <span className={classes.marketerProperty} key={`marketer-${marketerId}-prop-${marketerProperty}-key`}>
        {propertyLabel && <span className={classes.marketerPropertyLabel}>{propertyLabel}&nbsp;</span>}
        <span className={classes.marketerPropertyValue}>{formattedValue}</span>
      </span>
    );
  });
};

const MarketerListItem = (props) => {
  const { marketer, marketerProperties, secondary, action, customActionButton, classes } = props;
  const hasAction = action !== null; // here we check if action was passed
  const { icon: Icon = AddCircleIcon, label = '', handler = () => {}, disabled = false } = action || {}; // default action values
  const { name, displayImageUrl } = marketer;
  const marketerId = marketer.id || marketer.marketerId;

  return (
    <ListItem classes={{ root: classes.root, container: classes.container }}>
      <ListItemAvatar>
        <Tooltip title="Impersonate 👥" placement="top">
          <div>
            <ExternalLink href={`${getCrackleUrl()}?actAsMarketerId=${marketerId}`}>
              <Avatar src={displayImageUrl} />
            </ExternalLink>
          </div>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Link to={`/marketers/${marketerId}`} target="_blank">
            {name} (ID: {marketerId})
          </Link>
        }
        secondary={
          <React.Fragment>
            {getMarketerExtraData(marketer, marketerProperties, classes)}
            {secondary}
          </React.Fragment>
        }
      />
      {customActionButton !== null && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          {customActionButton}
        </ListItemSecondaryAction>
      )}
      {hasAction && !customActionButton && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          <Tooltip title={label} placement="left">
            <div>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  handler(marketer);
                }}
              >
                <Icon />
              </IconButton>
            </div>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

MarketerListItem.propTypes = {
  marketer: PropTypes.object.isRequired,
  marketerProperties: PropTypes.arrayOf(
    PropTypes.shape({
      marketerProperty: PropTypes.string,
      propertyLabel: PropTypes.string,
      propertyFormatter: PropTypes.func,
    }),
  ),
  secondary: PropTypes.any,
  customActionButton: PropTypes.node,
  action: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    label: PropTypes.string,
    handler: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  classes: PropTypes.object.isRequired,
};

MarketerListItem.defaultProps = {
  marketerProperties: [],
  action: null,
  customActionButton: null,
  secondary: null,
};

export default withStyles(styles)(MarketerListItem);
