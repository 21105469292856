import DOMPurify from 'dompurify';
import { colors } from '../../../app/theme';

// 1st Capturing Group (&lt;img)
//    &lt;img matches the characters &lt;img literally (case insensitive)
// 2nd Capturing Group (.[^<>]*)
//   . matches any character (except for line terminators)
//   Match a single character not present in the list below [^<>]*
//     * Quantifier — Matches between zero and unlimited times, as many times as possible, giving back as needed
// (greedy) <> matches a single character in the list <> (case insensitive) 3rd Capturing Group (&gt;) &gt; matches the
// characters &gt; literally (case insensitive)

export const sanitizedImageRegex = /(&lt;img )(.[^<>]*)(&gt;)/gim;

export const myReplaceAll = (str: string, regExp: RegExp, replaceValue: string): string => {
  let tempStr = str;
  while (regExp.test(tempStr)) {
    tempStr = tempStr.replace(regExp, replaceValue);
  }
  return tempStr;
};

export const reviveImages = (orgContents: string): string => {
  // This is temporary fix until we will fix BE sanitize for images
  if (orgContents.replaceAll && typeof orgContents.replaceAll === 'function') {
    return orgContents.replaceAll(sanitizedImageRegex, '<img $2>');
  }
  if (orgContents.replace && typeof orgContents.replace === 'function') {
    return myReplaceAll(orgContents, sanitizedImageRegex, '<img $2>');
  }
  return orgContents;
};

export const getIframeCss = (maxContentWidth: number): string => `
    html { margin: 0; padding: 0 }
    body { overflow: hidden; font-family: "GT Walsheim Pro", "Roboto", sans-serif; margin: 0; padding: 0;
      box-sizing: border-box; font-size: 1rem; line-height: 1.5rem; color: ${colors.black}; }
    p { margin-block-start: 0px!important; margin-block-end: 0px!important; margin: 0; 
      font-size: 1rem; line-height: 1.5rem; box-sizing: border-box; padding: 0.5rem 0; color: ${colors.black}; }
    span{ color: ${colors.black}; }
    h1,h2,h3,h4,h5,h6 { font-family: "GT Walsheim Pro", "Roboto", sans-serif; margin: 0; padding: 0; }
    h1 { font-size: 3.5rem; line-height: 5rem; }
    h2 { font-size: 3rem; line-height: 4rem; }
    h3 { font-size: 2.5rem; line-height: 3.5rem; }
    h4 { font-size: 2rem; line-height: 2.5rem; }
    h5 { font-size: 1.5rem; line-height: 2rem; }
    h6 { font-size: 1.25rem; line-height: 1.625rem; },
    img { max-width: 100%; height: auto; }
    ul { margin-block-start: 0; margin-block-end: 0; padding-block-start: 1rem; padding-block-end: 1rem; }
    li { font-size: 1rem; line-height: 1.5rem; }
    .before-calc { white-space: nowrap; }
    .before-calc img { width: 100%; min-width: ${maxContentWidth}px; }`;

export const wrapContentWithHTML = (content: string, maxContentWidth: number): string =>
  // wrap the content with HTML + CSS
  `<html><head><style>${getIframeCss(maxContentWidth)}</style>` +
  `<link rel="stylesheet" href="https://static.cdn.mayple.com/fonts/fonts.css"></head>` +
  `<body>${DOMPurify.sanitize(content)}<base target="_blank"></body></html>`;
