import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';
import { FullMarketerByIdQuery } from 'growl-graphql/dist/queries/FullMarketerById';

import MarketerDetailsHeader from '../MarketerDetailsPage/components/MarketerDetailsHeader';
import { MarketerIdParams } from '../../components/Routes/types';
import UpdateMarketerWizard from '../../containers/marketer/wizards/UpdateMarketerWizard';

import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import { Button } from '../../../fe_common/client/components/inputs';

import useStyles from './styles';

interface UpdateMarketerDetailsPageProps {
  classes?: Record<string, string>;
}

const UpdateMarketerDetailsPage: FC<UpdateMarketerDetailsPageProps> = (props) => {
  const classes = useStyles(props);

  const { marketerId } = useParams<MarketerIdParams>();
  const { data: viewerData, loading: loadingViewer, error: errorViewer } = useQuery(ViewerUserQuery.query);
  const {
    data: marketerData,
    loading: loadingMarketer,
    error: errorMarketer,
  } = useQuery(FullMarketerByIdQuery.query, { variables: { marketerId }, skip: !marketerId });

  const viewer = viewerData?.viewer || null;
  const marketer = marketerData?.marketer || null;

  const hasError = useMemo(() => !!errorViewer || !!errorMarketer, [errorMarketer, errorViewer]);
  const isLoading = useMemo(() => loadingMarketer || loadingViewer, [loadingMarketer, loadingViewer]);

  return (
    <div className={classes.root}>
      <Button link={`/marketers/${marketerId}`} color="primary" label="< GO BACK" />

      {isLoading && <PageLoadingPlaceholder />}

      {!isLoading && hasError && <CalloutMessage message="Error loading marketer data" type="error" />}

      {!hasError && !loadingMarketer && !marketer && (
        <CalloutMessage message={`Marketer with id ${marketerId} not found`} />
      )}

      {!hasError && !isLoading && (
        <>
          <MarketerDetailsHeader marketer={marketer} titlePrefix="Edit vetting data for " readOnly />
          <UpdateMarketerWizard marketer={marketer} viewer={viewer} />
        </>
      )}
    </div>
  );
};

export default UpdateMarketerDetailsPage;
