import { MarketingPlanProgressHeaderProps } from './types';
import { MarketingPlanStatusExtended } from '../MarketingPlanProvider/types';
import { svgs } from '../../../app/svg';

export const replaceValues = (value: string, params: Record<string, any>, texts?: Record<string, string>): string => {
  const paramTemplateRegExp = /{{([^}]*)}}/; // Basically looking for something like that {{xxx}} in a string

  let newValue = value;

  while (paramTemplateRegExp.test(newValue)) {
    const matchArray = newValue.match(paramTemplateRegExp);

    if (!matchArray) {
      return value;
    }

    const [, key] = matchArray;
    const valueFromParams = params[key];
    const valueFromTexts = texts ? texts[key] : undefined;

    newValue = newValue.replace(paramTemplateRegExp, valueFromParams || valueFromTexts || key);
  }

  return newValue;
};

export const marketingPlanProgressHeaders: Record<MarketingPlanStatusExtended, MarketingPlanProgressHeaderProps> = {
  [MarketingPlanStatusExtended.MARKETING_PLAN_MEETING_NOT_SET]: {
    title: 'At least {{timeLeftForMarketingPlanDueDate}} left',
    subtitle:
      'We’ll send you an email as soon as {{companyName}} schedules the meeting. ' +
      'Your deadline is 24hrs before the meeting',
    avatarUrl: svgs.noMeetingWasSetIndicator,
    value: MarketingPlanStatusExtended.MARKETING_PLAN_MEETING_NOT_SET,
  },
  [MarketingPlanStatusExtended.MISSING_MARKETING_PLAN]: {
    title: '{{timeLeftForMarketingPlanDueDate}} left',
    subtitle: 'Submit your plan for review before {{marketingPlanDueDateFormatted}}',
    avatarUrl: svgs.timeLeftIndicator,
    value: MarketingPlanStatusExtended.MISSING_MARKETING_PLAN,
  },
  [MarketingPlanStatusExtended.WAITING_GS_REVIEW]: {
    title: 'In review',
    subtitle: 'We’re reviewing the plan before we send it to {{companyName}}',
    avatarUrl: svgs.inReviewIndicator,
    value: MarketingPlanStatusExtended.WAITING_GS_REVIEW,
  },
  [MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW]: {
    title: 'Client approval',
    subtitle: 'Clients usually approve right after the Marketing Plan Meeting',
    avatarUrl: svgs.clientApprovalIndicator,
    value: MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW,
  },
  [MarketingPlanStatusExtended.APPROVED]: {
    title: 'Plan approved!',
    subtitle:
      'It’s time to start implementing your plan! ' +
      'The project will go live and the billing cycle will start as soon as ' +
      '{{companyName}} connects their accounts',
    avatarUrl: svgs.approvedIndicator,
    value: MarketingPlanStatusExtended.APPROVED,
  },
};

const applyDataToHeader = (
  marketingPlanProgressHeader: MarketingPlanProgressHeaderProps,
  extraData: Record<string, any>
): MarketingPlanProgressHeaderProps => {
  const { title, subtitle } = marketingPlanProgressHeader;

  const newTitle = replaceValues(title, extraData);
  const newSubTitle = replaceValues(subtitle, extraData);

  return {
    ...marketingPlanProgressHeader,
    title: newTitle,
    subtitle: newSubTitle,
  };
};

export const getMarketingPlanProgressHeaders = (
  marketingPlanStatus: MarketingPlanStatusExtended | undefined,
  extraData: Record<string, any>
): MarketingPlanProgressHeaderProps => {
  if (!marketingPlanStatus) {
    return {} as MarketingPlanProgressHeaderProps;
  }

  const marketingPlanProgressHeader = marketingPlanProgressHeaders[marketingPlanStatus];
  return applyDataToHeader(marketingPlanProgressHeader, extraData);
};

export const checkMarketingPlanIsApprovedByGS = (
  marketingPlanStatus: MarketingPlanStatusExtended | undefined
): boolean => {
  const approvedValues: MarketingPlanStatusExtended[] = [
    MarketingPlanStatusExtended.WAITING_COMPANY_REVIEW,
    MarketingPlanStatusExtended.APPROVED,
  ];

  if (!marketingPlanStatus) {
    return false;
  }

  return approvedValues.includes(marketingPlanStatus);
};

export const checkMarketingPlanIsApprovedByCompany = (
  marketingPlanStatus: MarketingPlanStatusExtended | undefined
): boolean => MarketingPlanStatusExtended.APPROVED === marketingPlanStatus;
