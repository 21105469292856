import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FeedFilter from '../FeedFilter';
import FeedFilterDialog from '../FeedFilterDialog';

function FeedFilterManager({
  legend,
  feed,
  onSetFilter,
  useFilterDialog,
  isFilterDialogOpen,
  hideFilterDialogHandler,
}) {
  const [filter, setFilter] = useState({});

  const handleOnSetFilter = (newFilter) => {
    setFilter(newFilter);
    onSetFilter(newFilter);
  };

  return (
    <>
      {!useFilterDialog && <FeedFilter legend={legend} feed={feed} filter={filter} onSetFilter={handleOnSetFilter} />}
      {useFilterDialog && (
        <FeedFilterDialog
          isOpen={isFilterDialogOpen}
          onClose={hideFilterDialogHandler}
          filter={filter}
          feed={feed}
          legend={legend}
          onSetFilter={handleOnSetFilter}
        />
      )}
    </>
  );
}

FeedFilterManager.propTypes = {
  legend: PropTypes.object,
  feed: PropTypes.object,
  onSetFilter: PropTypes.func,
  useFilterDialog: PropTypes.bool,
  isFilterDialogOpen: PropTypes.bool,
  hideFilterDialogHandler: PropTypes.func,
};

FeedFilterManager.defaultProps = {
  legend: {},
  feed: {},
  onSetFilter: () => {},
  useFilterDialog: false,
  isFilterDialogOpen: false,
  hideFilterDialogHandler: () => {},
};

export default FeedFilterManager;
