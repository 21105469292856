import React, { FC, useCallback, useMemo, useState } from 'react';
import { InternalTeamType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import Tooltip from '@material-ui/core/Tooltip';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';
import { SetProjectMainInternalTeamMutation } from 'growl-graphql/dist/mutations/admin/SetProjectMainInternalTeamMutation';
import { UnsetProjectMainInternalTeamMutation } from 'growl-graphql/dist/mutations/admin/UnsetProjectMainInternalTeamMutation';

import { getMainInternalTeamId, getVariables } from './logic';
import EntitySearchAutoComplete, {
  AutoCompleteOptionType,
} from '../../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';
import { ENTITY_TYPE } from '../../../../../app/consts';
import { getGrowthStrategistFilter } from '../../../../../pages/ProjectDetailsPage/components/ProjectConnectedInternalTeams/logic';

import { handleClientError } from '../../../../../../fe_common/client/services/logger';
import { setNotification } from '../../../../../../fe_common/client/services/notification';
import { useMutation } from '../../../../../../fe_common/client/hooks';

interface UpdateProjectMainInternalTeamSelectProps {
  projectId: number;
  internalTeamType: InternalTeamType;
  disabled?: boolean;
  label: string;
  tooltip?: string;
  onUpdate?: () => Promise<void>;
  error?: boolean;
  helperText?: string | undefined;
}

const UpdateProjectMainInternalTeamSelect: FC<UpdateProjectMainInternalTeamSelectProps> = (props) => {
  const {
    projectId,
    internalTeamType,
    label,
    tooltip = 'Select to update',
    disabled = false,
    onUpdate,
    error = false,
    helperText,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const {
    data,
    loading: loadingProject,
    refetch,
  } = useQuery(FullProjectByIdQuery.query, {
    variables: { projectId },
    skip: !projectId,
  });

  const { mutate: setProjectMainInternalTeamMutation, loading: settingTeam } = useMutation(
    SetProjectMainInternalTeamMutation,
  );
  const { mutate: unsetProjectMainInternalTeamMutation, loading: unsettingTeam } = useMutation(
    UnsetProjectMainInternalTeamMutation,
  );

  const project = useMemo(() => data?.project, [data?.project]);

  // Getting the current Growth Strategist assigned to project
  const internalTeamId = useMemo(() => {
    if (loadingProject || !project) {
      return null;
    }

    return getMainInternalTeamId(project, internalTeamType);
  }, [internalTeamType, loadingProject, project]);

  const updateGrowthStrategistInternalTeamId = useCallback(
    async (newInternalTeamId) => {
      if (!newInternalTeamId || internalTeamId === newInternalTeamId) {
        return;
      }

      try {
        const variables = getVariables(projectId, newInternalTeamId, internalTeamType);

        await setProjectMainInternalTeamMutation({ variables });
        await Promise.all([refetch(), onUpdate?.()]);
      } catch (err) {
        handleClientError(err);
        setNotification('Failed updating internal team, please contact dev support.', 'error');
      }
    },
    [internalTeamId, internalTeamType, onUpdate, projectId, refetch, setProjectMainInternalTeamMutation],
  );

  const onSelectInternalTeamHandler = (newSelected: AutoCompleteOptionType | null) => {
    if (!newSelected) {
      setInputValue('');
    } else if (newSelected.value) {
      updateGrowthStrategistInternalTeamId(newSelected.value as number);
    }
  };

  const onRemoveClickHandler = useCallback(async () => {
    await unsetProjectMainInternalTeamMutation({ variables: { projectId, internalTeamType } });
    await Promise.all([refetch(), onUpdate?.()]);
  }, [internalTeamType, onUpdate, projectId, refetch, unsetProjectMainInternalTeamMutation]);

  const onInputChangeHandler = (_event: Record<string, any>, newInputValue: string, reason: string) => {
    setInputValue(newInputValue);

    if (reason === 'clear') {
      onRemoveClickHandler();
    }
  };

  const growthStrategistFilter = useMemo(() => getGrowthStrategistFilter([internalTeamType]), [internalTeamType]);

  const loading = loadingProject || settingTeam || unsettingTeam;

  return (
    <div>
      <Tooltip title={disabled ? 'Disabled' : tooltip} placement="top">
        <span>
          <EntitySearchAutoComplete
            entityType={ENTITY_TYPE.INTERNAL_TEAM}
            onSelect={onSelectInternalTeamHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            filterBy={growthStrategistFilter}
            disabled={disabled || loading}
            value={internalTeamId || null}
            label={label}
            hasError={error}
            helperText={helperText}
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default UpdateProjectMainInternalTeamSelect;
