export type SearchOption = {
  value: string;
  label: string;
};

export const quickSearchOptions: SearchOption[] = [
  {
    value: 'projects',
    label: 'Project',
  },
  {
    value: 'marketers',
    label: 'Marketer',
  },
  {
    value: 'companies',
    label: 'Company',
  },
  {
    value: 'users',
    label: 'User',
  },
  {
    value: 'internal-teams',
    label: 'Internal Account',
  },
];
