import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  FilterBy,
  FilterByOperator,
  MarketerAssignedForApproval,
  Project,
  ProjectLifeCycleStatus,
  ProjectMarketerOpportunity,
} from '@mayple/types';
import { SelectMatchedMarketerForCompanyApprovalMutation } from 'growl-graphql/dist/mutations/admin/SelectMatchedMarketerForCompanyApprovalMutation';
import { ProjectMarketerMatchesQuery } from 'growl-graphql/dist/queries/ProjectMarketerMatchesQuery';

import { ProjectParticipantsLogicReturnType } from './types';
import { withAssignedGSVerification } from '../../../../logic/growthStrategist';
import useAdminAccessibleMarketerData from '../../../../hooks/useAdminAccessibleMarketerData';

import { useDialog, useMutation } from '../../../../../fe_common/client/hooks';
import { setNotification } from '../../../../../fe_common/client/services/notification';

const useProjectParticipantsLogic = (
  project: Project | null | undefined,
  refetchProject: () => void,
): ProjectParticipantsLogicReturnType => {
  const { id: projectId, projectLifeCycleStatus } = project || ({} as Project);

  const { open: confirmDialogOpen, openDialog: openConfirmDialog, closeDialog: closeConfirmDialog } = useDialog(false);

  const { mutate: selectOpportunityForCompanyApproval, loading: saving } = useMutation(
    SelectMatchedMarketerForCompanyApprovalMutation,
  );

  const {
    data: projectMarketerMatchesQuery,
    loading: loadingProjectMarketerMatchesQuery,
    refetch: refetchProjectMarketerMatches,
  } = useQuery(ProjectMarketerMatchesQuery.query, {
    variables: { projectId },
    skip: !projectId,
  });

  const settingNewMarketerInProgress = useRef<boolean>(false);

  const [selectedMarketer, setSelectedMarketer] = useState<MarketerAssignedForApproval>({
    marketerId: 0,
    introduceYourself: '',
  });

  const { marketer, loading } = useAdminAccessibleMarketerData(selectedMarketer?.marketerId);

  const closeConfirmDialogHandler = useCallback(() => {
    settingNewMarketerInProgress.current = false;
    setSelectedMarketer({
      marketerId: 0,
      introduceYourself: '',
    });
    closeConfirmDialog();
  }, [closeConfirmDialog]);

  const defaultIntro = useMemo(
    () =>
      `Hi ${
        project?.company?.name || ''
      }! I would love to work on your project! After reviewing your brief I've come up with several ideas on how to grow your business and would like to share them with you!`,
    [project?.company?.name],
  );

  const queryFilterBy: FilterBy[] = [
    {
      key: 'isVetted',
      value: '1',
      operator: FilterByOperator.EQUALS,
    },
  ];

  const handleChangeParticipantUnwrapped = useCallback(
    async (intro: string) => {
      if (intro && projectId && selectedMarketer.marketerId) {
        if (projectLifeCycleStatus !== ProjectLifeCycleStatus.ONBOARDING) {
          window.alert('Cannot select a marketer to a project that is not in On-Boarding Status.');
          return;
        }

        try {
          const variables = {
            projectId,
            marketerId: selectedMarketer.marketerId,
            introduceYourself: intro,
          };

          await selectOpportunityForCompanyApproval({ variables });
        } catch (err: any) {
          setNotification(err.message, 'error');
        }
      } else {
        window.alert('Operation failed, Aborting.');
      }

      closeConfirmDialogHandler();

      await refetchProjectMarketerMatches();
      await refetchProject();
    },
    [
      closeConfirmDialogHandler,
      projectId,
      projectLifeCycleStatus,
      refetchProjectMarketerMatches,
      refetchProject,
      selectOpportunityForCompanyApproval,
      selectedMarketer.marketerId,
    ],
  );

  const handleChangeParticipant = withAssignedGSVerification(project, handleChangeParticipantUnwrapped);

  const handleSelectAppliedOpportunity = (opportunity: ProjectMarketerOpportunity) => {
    setSelectedMarketer({
      marketerId: opportunity.marketerId,
      introduceYourself: opportunity.introduceYourself || defaultIntro,
    });
  };

  const onSelectMarketerFromSearch = useCallback(
    (newSelectedMarketer) => {
      if (newSelectedMarketer?.value) {
        setSelectedMarketer({
          marketerId: +newSelectedMarketer.value,
          introduceYourself: defaultIntro,
        });

        settingNewMarketerInProgress.current = true;
      } else {
        setSelectedMarketer({
          marketerId: 0,
          introduceYourself: '',
        });

        settingNewMarketerInProgress.current = false;
      }
    },
    [defaultIntro],
  );

  const marketerMatches = projectMarketerMatchesQuery?.project?.marketerMatches || [];

  useEffect(() => {
    if (!loading && marketer && settingNewMarketerInProgress.current && !confirmDialogOpen) {
      if (!marketer?.matchingSettings?.enableMatching) {
        setNotification(`Marketer is disabled for matching. You can't select this marketer.`);
      } else if (!marketer?.matchingSettings?.availableForProjects) {
        setNotification(`Marketer is not available for projects. You can't select this marketer.`);
      } else {
        openConfirmDialog();
      }
    }
  });

  return {
    selectedMarketer,
    marketerMatches,
    loadingProjectMarketerMatchesQuery,
    refetchProjectMarketerMatches,
    confirmDialogOpen,
    closeConfirmDialog: closeConfirmDialogHandler,
    handleChangeParticipant,
    handleSelectAppliedOpportunity,
    onSelectMarketerFromSearch,
    saving,
    queryFilterBy,
  };
};

export default useProjectParticipantsLogic;
