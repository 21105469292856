import React, { useCallback, useMemo } from 'react';
import { ProjectPackage } from '@mayple/types';

import { ProjectPackagesListProps } from './types';
import { getDeliverablePackages } from './logic';
import ProjectPackageCard from '../ProjectPackageCard';

import CalloutMessage from '../../common/CalloutMessage';
import useProjectAppliedPackages from '../../../hooks/project/useProjectAppliedPackages';

import useStyles from './style';

const ProjectPackagesList: React.FC<ProjectPackagesListProps> = (props) => {
  const classes = useStyles(props);

  const { projectId, showPrices = false, showDescription = false, enableEdit = false, onSuccessfulSave } = props;

  const { project, loading, refetch } = useProjectAppliedPackages(projectId);
  const projectPackages = project?.projectPackages;

  const deliverablePackages: ProjectPackage[] = useMemo(
    () => getDeliverablePackages(projectPackages),
    [projectPackages]
  );

  const onSuccessfulSaveHandler = useCallback(async () => {
    await refetch();
    await onSuccessfulSave?.();
  }, [onSuccessfulSave, refetch]);

  return (
    <div className={classes.root}>
      {!loading && !deliverablePackages?.length && (
        <CalloutMessage message="No project packages configured" type="info" />
      )}
      {deliverablePackages?.map((deliverablePackage) => (
        <ProjectPackageCard
          key={`ProjectPackageCard-${deliverablePackage.id}`}
          projectPackage={deliverablePackage}
          projectLifeCycleStatus={project?.projectLifeCycleStatus}
          showPrices={showPrices}
          showDescription={showDescription}
          enableEdit={enableEdit}
          onSuccessfulSave={onSuccessfulSaveHandler}
          loading={loading}
        />
      ))}
    </div>
  );
};

export default ProjectPackagesList;
