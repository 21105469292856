import React, { useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SECTION_LAYOUT, SectionProps } from './types';
import SectionTitle from './SectionTitle';
import SectionSubtitle from './SectionSubtitle';
import { getColumnsLayout, TwoColumnsKeys, twoColumnsLayouts } from './logic';

import useStyles from './style';

const Section: React.FC<SectionProps> = (props) => {
  const classes = useStyles(props);

  const {
    id,
    title = null,
    subTitle = null,
    headerAction = null,
    noBorder = false,
    noTopMargin = false,
    children = null,
    layout = SECTION_LAYOUT.ONE_COLUMN,
    collapsable = false,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(!collapsable);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const hasSectionHeader = title != null || subTitle != null || collapsable;

  const sectionRootClasses = classNames(classes.root, {
    [classes.noBorder]: noBorder,
    [classes.noTopMargin]: noTopMargin,
  });

  if (Object.keys(twoColumnsLayouts).includes(layout)) {
    const [firstColumn, secondColumn] = getColumnsLayout(layout as TwoColumnsKeys);

    return (
      <section id={id} className={sectionRootClasses}>
        <Grid container>
          <Grid item xs={12} md={firstColumn} className={classes.firstColumn}>
            {hasSectionHeader && (
              <Grid container className={classes.sectionHeader}>
                <Grid item xs={12} className={classes.sectionHeaderTitles}>
                  {!!title && <SectionTitle title={title} classes={{ root: classes.sectionTitle }} />}
                  {!!subTitle && <SectionSubtitle subtitle={subTitle} classes={{ root: classes.sectionSubTitle }} />}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={secondColumn} className={classes.secondColumn}>
            <Grid container>
              {(headerAction || collapsable) && (
                <Grid item className={classes.sectionHeaderActions}>
                  {headerAction}
                  {collapsable && (
                    <IconButton
                      className={classNames(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {children}
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }

  return (
    <section id={id} className={sectionRootClasses}>
      {hasSectionHeader && (
        <Grid container justifyContent="space-between" className={classes.sectionHeader}>
          <Grid item className={classes.sectionHeaderTitles}>
            {title && <SectionTitle title={title} classes={{ root: classes.sectionTitle }} />}
            {subTitle && <SectionSubtitle subtitle={subTitle} classes={{ root: classes.sectionSubTitle }} />}
          </Grid>
          {(headerAction || collapsable) && (
            <Grid item className={classes.sectionHeaderActions}>
              {headerAction}
              {collapsable && (
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>
      )}

      {collapsable && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
      {!collapsable && children}
    </section>
  );
};

export default Section;
