import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CompanyIntegrationsQuery } from 'growl-graphql/dist/queries/company/CompanyIntegrationsQuery';

import IntegrationsDetails from '../../../components/cpanel/IntegrationsDetails';

import { SkeletonContainer } from '../../../../fe_common/client/components/display';
import Section from '../../../../fe_common/client/components/atoms/Section';

export interface CompanyIntegrationsTabProps {
  companyId: number | null | undefined;
}

const CompanyIntegrationsTab: FC<CompanyIntegrationsTabProps> = (props) => {
  const { companyId } = props;

  const { data, loading } = useQuery(CompanyIntegrationsQuery.query, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    skip: !companyId,
  });

  const integrations = data?.company?.integrations || [];

  return (
    <div>
      <Section title="Integrations" noBorder noTopMargin>
        <SkeletonContainer loading={loading} height={300}>
          <IntegrationsDetails integrations={integrations} />
        </SkeletonContainer>
      </Section>
    </div>
  );
};

export default CompanyIntegrationsTab;
