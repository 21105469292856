import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactQuill, { Quill } from 'react-quill';
import { client } from 'filestack-react';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import 'react-quill/dist/quill.snow.css';

import CalloutMessage from '../CalloutMessage';
import CalloutMessageCollapse from '../CalloutMessageCollapse';
import { clientLogger } from '../../../services/logger';

import { styles } from './style';

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    const { formats: defaultFormats, modules: defaultModules, enableImageDropAndPastePlugin, fileStackApiKey } = props;

    this.quill = Quill; // Quill object use to register the plugins
    this.quill.debug('error'); // remove Quill override warning in console
    this.quillRef = React.createRef(); // Reference to the Quill editor (for low level actions)

    if (enableImageDropAndPastePlugin) {
      this.quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

      if (fileStackApiKey) {
        this.filestackClient = client.init(props.fileStackApiKey);
      } else {
        clientLogger.error('fileStackApiKey is required when enableImageDropAndPastePlugin is true');
      }
    }

    const formats = this.getFormats(defaultFormats, enableImageDropAndPastePlugin);
    const modules = this.getModules(defaultModules, enableImageDropAndPastePlugin);

    this.state = {
      splashError: '',
      modules,
      formats,
      uploadProgress: 0,
      uploading: false,
    };
  }

  setUploading = (uploading) => {
    this.setState({ uploading });
  };

  setUploadProgress = (uploadProgress) => {
    this.setState({ uploadProgress });
  };

  setSplashError = (splashError) => {
    this.setState({ splashError });
  };

  getModules = (defaultModules, enableImageDropAndPastePlugin) => {
    if (enableImageDropAndPastePlugin) {
      const toolbar = defaultModules?.toolbar || [];

      return {
        ...defaultModules,
        toolbar,
        imageDropAndPaste: {
          handler: this.imageHandler.bind(this),
        },
      };
    }
    return defaultModules;
  };

  getFormats = (defaultFormats, enableImageDropAndPastePlugin) => {
    if (enableImageDropAndPastePlugin) {
      return [...defaultFormats, 'image'];
    }
    return defaultFormats;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  imageHandler = async (dataUrl, type, imageData) => {
    // console.log('dataUrl:', dataUrl);
    // console.log('type:', type);
    // console.log('imageData:', imageData);
    // console.log('this.quill', this.quill);

    const pasteDateTime = new Date()
      .toISOString()
      .replaceAll(/[:]/gi, '-')
      .replace(/(\..*)$/, '');

    const filename = `pastedImage-${pasteDateTime}.png`;
    // const blob = imageData.toBlob();
    const file = imageData.toFile(filename);

    // here we can resize the image if we like using the imageData object

    try {
      // At this point we are using the filestack picker to store the uploaded image
      const options = {
        onProgress: this.onProgress,
        progressInterval: 50,
      };

      const response = await this.uploadPastedImageFile(file, options);
      if (response) {
        // we gain access to the quill editor through the reference
        const quillEditor = this.quillRef.current.getEditor();

        // Add the file to the end
        const index = (quillEditor.getSelection() || {}).index || quillEditor.getLength();
        quillEditor.insertEmbed(index, 'image', response?.url, 'user');
      }
    } catch (e) {
      clientLogger.error(e, 'Failed to do something with that image...');
    }
  };

  onProgress = (evt) => {
    const { totalPercent = 0 } = evt;
    this.setUploadProgress(totalPercent);
  };

  uploadPastedImageFile = async (file, options) => {
    this.setUploading(true);

    return this.filestackClient
      .upload(file, options)
      .then((res) => {
        this.setUploading(false);
        this.setUploadProgress(0);
        return res;
      })
      .catch((err) => {
        clientLogger.error(err, 'Error uploading file to filestack');
        this.setSplashError('Error uploading file to filestack');
        this.setUploading(false);
        this.setUploadProgress(0);
        throw err;
      });
  };

  render() {
    const { placeholder, input, meta, classes } = this.props;
    const { modules, formats, uploading, uploadProgress, splashError } = this.state;

    const error = meta?.error;
    const touched = meta?.touched;

    return (
      <div className={classes.root}>
        {uploading && (
          <div className={classes.uploadOverlay}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              color="primary"
              classes={{ root: classes.linearProgressRoot }}
            />
          </div>
        )}
        <ReactQuill
          theme="snow"
          value={input.value}
          onChange={input.onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          className={classes.richTextRoot}
          ref={this.quillRef}
        />
        {!!error && touched && <CalloutMessage type="error" message={error} />}
        <CalloutMessageCollapse
          type="error"
          message={splashError}
          onMessageDisappear={() => {
            this.setSplashError('');
          }}
        />
      </div>
    );
  }
}

RichTextEditor.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.object,
  modules: PropTypes.object,
  formats: PropTypes.array,
  placeholder: PropTypes.string,
  enableImageDropAndPastePlugin: PropTypes.bool,
  fileStackApiKey: PropTypes.string,
  rows: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

RichTextEditor.defaultProps = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  },
  formats: ['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link'],
  placeholder: 'Please provide a description',
  meta: {},
  enableImageDropAndPastePlugin: false,
  fileStackApiKey: '',
  rows: 20,
};

export default withStyles(styles)(RichTextEditor);
