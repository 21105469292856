import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2rem 1.5rem',
    },
    toolbarRoot: {
      paddingRight: 0,
      paddingLeft: 0,
      backgroundColor: colors.white,
    },
    titlesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: '1rem',
    },
    title: {
      width: '100%',
    },
    subtitle: {
      width: '100%',
    },
    dialogActions: {},
  })
);

export default useStyles;
