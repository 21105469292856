import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { PROJECT_SPECIAL_SUBTYPE_PAIRS, PROJECT_SPECIAL_TYPE } from '../../../../../fe_common/client/app/enums';

const BillingCycleForm: FC = () => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <WizardStepField
          wizardFieldLabel="Billing Cycle Date"
          name="billingCycleDate"
          type={WizardStepField.types.DATE_PICKER}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <WizardStepField
          wizardFieldLabel="Project Type"
          name="projectSpecialTypes"
          type={WizardStepField.types.AUTOCOMPLETE}
          // @ts-ignore
          enums={PROJECT_SPECIAL_TYPE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <WizardStepField
          wizardFieldLabel="Project Subtype"
          name="projectSpecialSubTypes"
          type={WizardStepField.types.AUTOCOMPLETE}
          // @ts-ignore
          enums={PROJECT_SPECIAL_SUBTYPE_PAIRS}
          isMulti
        />
      </Grid>
    </Grid>
  </div>
);

export default BillingCycleForm;
