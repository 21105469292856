export interface ChipLabelPair {
  label: string;
  iconUrl: string;
}

export const mapItemsToShow = (items: ChipLabelPair[], maxToShow: number): [ChipLabelPair[], string[]] => {
  try {
    if (maxToShow === 0) {
      return [items, []];
    }

    const shownItems = (items || []).slice(0, maxToShow);
    const hiddenItems = (items || []).slice(maxToShow).map(({ label }) => label);

    return [shownItems, hiddenItems];
  } catch (e) {
    return [items, []];
  }
};

interface EnumsToLabelsOptions {
  defaultLabel?: string;
  suffix?: string;
}

export const mapEnumsToLabels = (
  enums: string[],
  labelsLookup: Record<string, string>,
  options: EnumsToLabelsOptions = {}
): ChipLabelPair[] => {
  try {
    const { suffix = '', defaultLabel = '' } = options;

    return enums.map((item) => ({
      label: `${labelsLookup[item] || defaultLabel || item}${suffix ? ` ${suffix}` : ''}`,
      iconUrl: labelsLookup[`${item}-svgIcon`] || '',
    }));
  } catch (e) {
    return [];
  }
};

interface IMultipleEnum {
  enums: string[];
  labelsLookup: Record<string, string>;
  suffix?: string;
}

/**
 * Should be ordered by the importance. Highest importance in first index.
 */
export const mapMultipleEnumTypesToLabels = (enumList: IMultipleEnum[]): ChipLabelPair[] => {
  try {
    let labels: ChipLabelPair[] = [];

    enumList.forEach(({ enums, labelsLookup, suffix }) => {
      labels = labels.concat(mapEnumsToLabels(enums, labelsLookup, { suffix }));
    });

    return labels;
  } catch (e) {
    return [];
  }
};
