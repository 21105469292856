import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingBottom: 80,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionHeader: {
      marginTop: 32,
    },
    sectionContent: {
      padding: 0,
    },
    // twoSectionsSideBySide: {
    //   display: 'flex',
    //   width: '100%',
    //   flex: '1 1 auto',
    //   gap: '2rem',
    //   '& > section': {
    //     width: '100%',
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     flexDirection: 'column',
    //   },
    // },
  }),
);

export default useStyles;
