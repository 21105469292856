import { YES_OR_NO_PAIRS } from '../../../../../app/enums';
import { WizardStepField } from '../../../../molecules/WizardStepField';

type AssessmentQuestionDefinition = {
  component: string;
  dataType: 'string' | 'number';
  props?: Record<string, any> | undefined;
};

const defaultMultiLineTextProps = {
  margin: 'normal',
  variant: 'outlined',
  type: 'text',
  multiline: true,
  minRows: 3,
};

const assessmentQuestionsDefinitions: Record<string, AssessmentQuestionDefinition> = {
  general_healthCheckResultSatisfaction: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckPerceptionOfImprovement: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckInteractionRating: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckNeedForAdditionalAssistance: {
    component: WizardStepField.types.RADIO,
    dataType: 'string',
    props: {
      options: YES_OR_NO_PAIRS,
    },
  },
  general_healthCheckLikelidhoodOfEnhancement: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckRecommendationToOthers: {
    component: WizardStepField.types.RADIO,
    dataType: 'string',
    props: {
      options: YES_OR_NO_PAIRS,
    },
  },
  general_healthCheckTnps: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckEaseOfNavigation: {
    component: WizardStepField.types.SCORE_BUTTONS,
    dataType: 'number',
  },
  general_healthCheckDriversOfProgramImprovement: {
    component: WizardStepField.types.TEXT,
    dataType: 'string',
    props: defaultMultiLineTextProps,
  },
  general_healthCheckGeneralFeedback: {
    component: WizardStepField.types.TEXT,
    dataType: 'string',
    props: defaultMultiLineTextProps,
  },
};

export default assessmentQuestionsDefinitions;
