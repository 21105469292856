import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialogSubtitle: {
    marginBottom: 32,
  },
  ul: {
    marginTop: 8,
    marginBottom: 40,
  },
}));

export default useStyles;
