import React, { FC } from 'react';

import { AnswerProps } from './types';
import { TextDisplay } from '../../../display';
import { BRIEF_SUPPORTED_CURRENCIES } from '../../../../logic/deepBrief/consts';

const CurrencyRangeAnswer: FC<AnswerProps> = (props) => {
  const { answer } = props;

  const min = answer.values[0].minimum;
  const max = answer.values[0].maximum;
  const currency = answer.values[0].currencyType;
  const { symbol = '' } = BRIEF_SUPPORTED_CURRENCIES.find((curr) => curr.value === currency) || {};

  return (
    <TextDisplay variant="subtitle1">
      {min} - {max} {symbol}
    </TextDisplay>
  );
};

export default CurrencyRangeAnswer;
