import useDialog from '../../../../../../fe_common/client/hooks/useDialog';

const useProjectOpportunityConfirmationDialog = (): {
  showConfirmExtendOpportunityDialog: boolean;
  openConfirmExtendOpportunityDialog: () => void;
  closeConfirmExtendOpportunityDialog: () => void;
} => {
  const {
    open: showConfirmExtendOpportunityDialog,
    openDialog: openConfirmExtendOpportunityDialog,
    closeDialog: closeConfirmExtendOpportunityDialog,
  } = useDialog();

  return {
    showConfirmExtendOpportunityDialog,
    openConfirmExtendOpportunityDialog,
    closeConfirmExtendOpportunityDialog,
  };
};

export default useProjectOpportunityConfirmationDialog;
