import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import BriefAnswerRenderer from './BriefAnswerRenderer';
import { arrayOfObjectsToHashTable } from '../../../services/utils';

/**
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function BriefCategoryAnswers(props) {
  const { category, classes, expanded, onChange, isPrintMode } = props;

  // Create initial questions hash table
  const [questionsTable] = React.useState(() => {
    const questions = get(category, 'answerSet.questionSet.questions');
    return arrayOfObjectsToHashTable({ array: questions, key: 'uuid' });
  });

  const answers = get(category, 'answerSet.answers') || [];

  if (!answers || answers.length === 0) {
    return null;
  }

  const categoryTitle = get(category, 'answerSet.questionSet.title') || 'Brief';
  const categoryIcon = get(category, 'answerSet.questionSet.iconUrl');
  const categorySubtitle = get(category, 'answerSet.questionSet.headline') || '';
  const numberOfAnswers = answers.length;

  return (
    <Accordion className={classes.panelRoot} expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={isPrintMode ? null : <ExpandMoreIcon />} className={classes.panelSummary}>
        <ListItem style={{ padding: 0 }}>
          <Avatar src={categoryIcon} style={{ borderRadius: 0 }} />
          <ListItemText primary={categoryTitle} secondary={categorySubtitle} />
        </ListItem>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container>
          {answers.map((answer, i) => (
            <Grid
              key={`BriefAnswerRenderer-${answer.questionUuid}`}
              item
              xs={12}
              className={classes.briefAnswerWrapper}
            >
              <BriefAnswerRenderer answer={answer} question={questionsTable[answer.questionUuid]} />
              {
                // Add bottom divider except the last answer
                i !== numberOfAnswers - 1 && <Divider className={classes.divider} />
              }
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

BriefCategoryAnswers.propTypes = {
  category: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  isPrintMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BriefCategoryAnswers;
