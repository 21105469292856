import React, { useCallback, useEffect, useState } from 'react';
import { ProjectCreationReason } from '@mayple/types';
import { CreateProjectFromOtherProjectMutation } from 'growl-graphql/dist/mutations/admin/CreateProjectFromOtherProjectMutation';

import { DuplicateProjectDialogData, DuplicateProjectDialogProps } from './types';

import useMutation from '../../../../../../fe_common/client/hooks/useMutation';
import { setNotification } from '../../../../../../fe_common/client/services/notification';

const projectNameIsValid = (name: string): boolean =>
  name === '' || name.length === 0 || (name.length >= 4 && name.length < 100);

const useDuplicateProjectDialogData = (props: DuplicateProjectDialogProps): DuplicateProjectDialogData => {
  const { projectId, onSuccess, onFailure } = props;
  const [reason, setReason] = useState<ProjectCreationReason>(ProjectCreationReason.NEW_CLIENT_WITH_MULTIPLE_PROJECTS);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [duplicateInProgress, setDuplicateInProgress] = useState<boolean>(false);

  const {
    mutate,
    loading: savingProjectFromAnotherProject,
    error,
  } = useMutation(CreateProjectFromOtherProjectMutation);

  const onDuplicateProjectHandler = useCallback(async () => {
    const trimmedName = (name || '').trim();

    if (!projectNameIsValid(trimmedName)) {
      setNameError('If set, project name must be between 4 and 100 chars');
      return;
    }

    try {
      setDuplicateInProgress(true);

      const variables = {
        projectId,
        duplicateProjectSettings: {
          projectCreationReason: reason,
          name: trimmedName.length === 0 ? undefined : trimmedName,
        },
      };

      const response = await mutate({ variables });

      const newProjectId = response?.data?.createProjectFromOtherProject?.id;

      if (newProjectId) {
        await onSuccess?.(newProjectId);
      } else {
        setNotification('Failed duplication', 'error');
        onFailure?.();
      }
    } catch (e) {
      setNotification('Failed duplication', 'error');
      onFailure?.();
    } finally {
      setDuplicateInProgress(false);
    }
  }, [mutate, name, onFailure, onSuccess, projectId, reason]);

  const onSelectProjectCreationReason = (r: ProjectCreationReason) => {
    setReason(r);
  };

  const onTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  useEffect(() => {
    if (nameError && projectNameIsValid(name)) {
      setNameError('');
    }
  }, [name, nameError]);

  return {
    name,
    error,
    reason,
    saving: savingProjectFromAnotherProject || duplicateInProgress,
    onDuplicateProjectHandler,
    onTextChangeHandler,
    nameError,
    onSelectProjectCreationReason,
  };
};

export default useDuplicateProjectDialogData;
