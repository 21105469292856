import isArray from 'lodash/isArray';
import normalizeUrlFunc from 'normalize-url';

export function toNiceFloat(number) {
  return parseFloat(parseFloat(number).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export function toNiceInt(number) {
  return parseInt(number, 10).toLocaleString();
}

export function randomChoice(choices) {
  const index = Math.floor(Math.random() * choices.length);
  return choices[index];
}

export function formatFloatCurrency(number = 0, currencyType = '$') {
  if (number === null || number === undefined) {
    return 'N/A';
  }
  return `${currencyType} ${number.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  // return `${currencyType} ${toNiceFloat(number)}`; // previous version
}

export function formatPercentageString(percentageFraction) {
  return `${(percentageFraction * 100.0).toFixed(2)}%`;
}

export function formatBooleanString(boolean) {
  return boolean ? 'Yes' : 'No';
}

// ucs-2 string to base64 encoded ascii
export function utoa(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

// base64 encoded ascii to ucs-2 string
export function atou(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

const _urlNormalizingOptions = { stripWWW: false };

export function normalizeUrl(url) {
  if (!url) {
    return '';
  }
  return normalizeUrlFunc(url, _urlNormalizingOptions);
}

// export function getRandomLogoImageUrl() {
//   const logoImageUrls = [
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo1.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo2.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo3.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo4.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo5.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo6.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo7.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo8.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo9.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo10.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo11.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo12.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo13.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo14.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo15.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo16.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo17.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo18.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo19.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo20.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo21.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo22.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo23.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo24.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo25.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo26.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo27.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo28.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo29.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo30.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo31.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo32.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo33.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo34.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo35.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo36.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo37.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo38.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo39.gif',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo40.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo41.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo42.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo43.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo44.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo45.png',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo46.jpg',
//     'https://s3.amazonaws.com/perfpie-static-assets/images/logos/logo47.jpg',
//   ];
//
//   return randomChoice(logoImageUrls);
// }

/**
 * will reduce an array of objects to object with keys based on selected shared key from the objects in the array
 * @param array
 * @param keyField should be a key in the objects with a STRING value!
 */
export const arrayOfObjectsToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item[keyField]] = item;
    return obj;
  }, {});

/**
 * will reduce an array of objects to object with keys based on selected shared key from the objects in the array
 * @param array {Array} - list of objects
 * @param key {String} - should be a key in the objects with a STRING value!
 * @param toBoolean {Boolean} - To return boolean values in hash table - better performance if not using the values.
 */
export const arrayOfObjectsToHashTable = ({ array, key = 'value', toBoolean = false }) => {
  // Verify parameters
  if (!isArray(array) || typeof key !== 'string') {
    return {};
  }
  // Reduce the array to an object
  return array.reduce((obj, item) => {
    // Disabling the rule here, it does not make sense to create a new object each iteration.
    // eslint-disable-next-line no-param-reassign
    obj[item[key]] = toBoolean ? true : item;
    return obj;
  }, {});
};

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 * @param str
 * @returns {number}
 */
export const stringHashCode = (str) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) + hash + char;
    /* hash * 33 + c */
  }
  return hash;
};

/**
 * returns the correct performance indicator based on the KPI type
 * @param targetKPI
 * @param performance
 * @returns {*}
 */
export const getTargetKPIPerformance = ({ targetKPI, performance }) => {
  const { aggregated } = performance;

  if (['Conversions'].includes(targetKPI)) {
    return aggregated.sum_conversions;
  }
  if (['Clicks'].includes(targetKPI)) {
    return aggregated.sum_clicks;
  }

  return aggregated[targetKPI];
};

/**
 * calculated days difference between 2 dates sent.
 * can send only 1 date do calc the diff between today
 * @param date1
 * @param date2
 * @returns {number}
 */
export const daysBetweenTwoDates = (date1 = new Date(), date2 = new Date()) => {
  const date1InMS = new Date(date1).getTime();
  const date2InMS = new Date(date2).getTime();

  return Math.abs(Math.round((date2InMS - date1InMS) / 1000 / 60 / 60 / 24));
};
