/* eslint-disable import/prefer-default-export */
import {
  MarketingServiceType,
  AdditionalService,
  MarketingSkills,
  DeliverablesFulfillmentBehavior,
} from '@mayple/types';

import {
  DELIVERABLES_FULFILLMENT_BEHAVIOR_LABELS_LOOKUP,
  MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
} from '../../app/enums';

export const marketingServicesLookup = MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP as Record<
  MarketingServiceType,
  string
>;

export const marketingSkillsLookup = MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS as Record<MarketingServiceType, string>;

export const getAdditionalServiceName = (service: AdditionalService): string =>
  service?.alternativeName || marketingServicesLookup[service?.serviceType] || service?.serviceType;

export const skillsUsedMapper = (skill: MarketingSkills): MarketingServiceType => skill.skillType;

export const deliverablesFulfillmentBehaviorLookup = DELIVERABLES_FULFILLMENT_BEHAVIOR_LABELS_LOOKUP as Record<
  DeliverablesFulfillmentBehavior,
  string
>;
