import React, { FC } from 'react';
import Toolbar from '@material-ui/core/Toolbar';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

import { useToolbarStyles } from './styles';

export type TableToolbarProps = {
  selectedMarketers: number[];
  totalRows: number;
  actions: any;
};
const TableToolbar: FC<TableToolbarProps> = (props) => {
  const { selectedMarketers, totalRows, actions = null } = props;
  const classes = useToolbarStyles(props);

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <TextDisplay variant="body1">
          Selected {selectedMarketers.length} marketers out of {totalRows}.
        </TextDisplay>
        <TextDisplay variant="body1">Selected marketers: {selectedMarketers.join(', ')}</TextDisplay>
      </div>
      <div className={classes.actions}>{actions}</div>
    </Toolbar>
  );
};

export default TableToolbar;
