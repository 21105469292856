import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';

import { MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP } from '../../../../../../../fe_common/client/app/enums';
import { costReducer, formatFloatCurrency } from '../../../../../../../fe_common/client/services/utils';

export const getAdditionalServicesCostBeforeDiscount = (values) => values.reduce(costReducer, 0);

export const renderAdditionalServicesInfo = (additionalServices, classes) => {
  // No Non-PPC Services - no need to render
  if (additionalServices.length === 0) {
    return null;
  }

  const totalCost = getAdditionalServicesCostBeforeDiscount(additionalServices);

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      classes={{
        tooltip: classes.htmlTooltip,
      }}
      title={
        <React.Fragment>
          <h6 color="inherit">
            <strong>Non-PPC Services Details (Before discounts)</strong>
          </h6>
          <Grid container>
            {
              // TODO: fix the description.content use here,
              //  currently it is wrapped with mark-ups and is unable to read.
              // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
              additionalServices.map(({ serviceType, description, cost }) => {
                // const descriptionContent = description.contents;
                const marketingServiceDescription = MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP[serviceType];
                const marketingServiceImage =
                  MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP[`${serviceType}-svgIcon`];

                return (
                  <React.Fragment key={`additional-services-tooltip-${marketingServiceDescription}-${serviceType}`}>
                    <Grid item xs={1}>
                      <img height={16} src={marketingServiceImage} alt={marketingServiceDescription} />
                    </Grid>
                    <Grid item xs={6}>
                      {marketingServiceDescription}
                    </Grid>
                    {/* <Grid item xs={4}>{descriptionContent}</Grid> */}
                    <Grid item xs={5} align="right">
                      {formatFloatCurrency(cost)}
                    </Grid>
                  </React.Fragment>
                );
              })
            }
            <Grid item xs={12}>
              <hr />
            </Grid>
            <React.Fragment key="additional-services-total">
              <Grid item xs={1} />
              <Grid item xs={4}>
                <strong>Total:</strong>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={3} align="right">
                <strong>{formatFloatCurrency(totalCost)}</strong>
              </Grid>
            </React.Fragment>
          </Grid>
        </React.Fragment>
      }
    >
      <InfoIcon color="primary" className={classes.infoIcon} />
    </Tooltip>
  );
};

export const renderPriceBeforeDiscount = (value) => `Price Before Discount: ${value}`;

export const renderValueWithTooltip = (value, tooltip, classes) => (
  <Tooltip
    disableFocusListener
    disableTouchListener
    classes={{
      tooltip: classes.htmlTooltip,
    }}
    title={tooltip}
  >
    <span className={classes.toolTipTrigger}>{value}</span>
  </Tooltip>
);
