import React, { FC, useState } from 'react';
import { Company, InternalTeam, Marketer, User } from '@mayple/types';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import EntityListItem from '../../../../components/cpanel/components/molecules/EntityListItem';
import useEntityUsersOperations, {
  connectUserToEntity,
  disconnectUserFromEntity,
  ValidOperationsEntities,
} from '../../../../hooks/useEntityUsersOperation';

import EntitySearchAutoComplete, {
  AutoCompleteOptionType,
} from '../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import PromptDialog from '../../../../../fe_common/client/components/molecules/PromptDialog';
import { setNotification } from '../../../../../fe_common/client/services/notification';
import { Button } from '../../../../../fe_common/client/components/inputs';

import useStyles from './style';

export type UserConnectedEntityProps = {
  user: User;
  entityType: ValidOperationsEntities;
  onChange?: () => void;
  showConnectUserToEntity?: boolean;
  showNoConnectedEntitiesMessage?: boolean;
};

type SelectableEntity = Marketer | Company | InternalTeam;

const UserConnectedEntity: FC<UserConnectedEntityProps> = (props) => {
  const classes = useStyles();
  const { user, entityType, onChange, showConnectUserToEntity, showNoConnectedEntitiesMessage } = props;

  const { addUserToEntity, removeUserFromEntity, entitiesKey } = useEntityUsersOperations(entityType);
  const entities = ((user as Record<string, any>)?.[entitiesKey] as SelectableEntity[]) ?? [];
  const hasEntities = entities.length > 0;

  const [selectedEntity, setSelectedEntity] = useState<AutoCompleteOptionType | null>(null);
  const [disconnectEntity, setDisconnectEntity] = useState<SelectableEntity | null>(null);
  const [showAuthorizeUserPrompt, setShowAuthorizeUserPrompt] = useState(false);
  const [showDisconnectUserPrompt, setShowDisconnectUserPrompt] = useState(false);

  const onSelectEntityHandler: (newValue: AutoCompleteOptionType | null) => void = (newSelectedEntity) => {
    if (newSelectedEntity && newSelectedEntity.value) {
      setSelectedEntity(newSelectedEntity);
    } else {
      setSelectedEntity(null);
    }
  };

  /* Connect User To Entity handlers: Start */

  const onConnectUserToEntityClick = () => {
    setShowAuthorizeUserPrompt(true);
  };

  const onConfirmConnectUserToEntity = async (value: number) => {
    setShowAuthorizeUserPrompt(false);

    if (value.toString() === selectedEntity?.value?.toString()) {
      await connectUserToEntity(addUserToEntity, entityType, selectedEntity?.value, user.id);
      onChange?.();
    } else {
      setNotification(`Value entered does not match ${entityType} ID`, 'warning');
    }
  };

  const onCancelConnectUserToEntity = () => {
    setShowAuthorizeUserPrompt(false);
  };

  /* Connect User To Entity handlers: End */

  /* ******************************************* */

  /* Disconnect User From Entity handlers: Start */

  const disconnectUserFromEntityHandler = (entity: SelectableEntity) => {
    setDisconnectEntity(entity);
    setShowDisconnectUserPrompt(true);
  };

  const onConfirmDisconnectUserFromEntity = async (value: SelectableEntity) => {
    setShowDisconnectUserPrompt(false);

    if (value.toString() === disconnectEntity?.id?.toString()) {
      await disconnectUserFromEntity(removeUserFromEntity, entityType, disconnectEntity?.id, user.id);
      onChange?.();
    } else {
      setNotification(`Value entered does not match ${entityType} ID`, 'warning');
    }
    setDisconnectEntity(null);
  };

  const onCancelDisconnectUserFromEntity = () => {
    setShowDisconnectUserPrompt(false);
    setDisconnectEntity(null);
  };
  /* Disconnect User From Entity handlers: End */

  return (
    <div>
      {!hasEntities && showNoConnectedEntitiesMessage && (
        <CalloutMessage type="info" message={`This user is not connected to any ${entityType.toLowerCase()}.`} />
      )}
      {hasEntities && (
        <List>
          {entities.map((entity) => (
            <EntityListItem
              key={`entity-list-item-${entity.id}`}
              entityType={entityType}
              entity={entity}
              action={{
                icon: HighlightOffOutlinedIcon,
                handler: disconnectUserFromEntityHandler,
              }}
            />
          ))}
        </List>
      )}
      {showConnectUserToEntity && (
        <>
          {hasEntities && <Divider />}
          <EntitySearchAutoComplete
            entityType={entityType}
            // buttonAltTitle={`Connect ${entityType}`}
            helperText={`Search for a ${entityType} you want to this user to be connected to`}
            onSelect={onSelectEntityHandler}
          />
          <Button
            label={`Connect user to ${entityType}`}
            variant="outlined"
            color="primary"
            disabled={!selectedEntity}
            className={classes.actionButton}
            onClick={onConnectUserToEntityClick}
          />
        </>
      )}
      {showAuthorizeUserPrompt && (
        <PromptDialog
          isOpen={showAuthorizeUserPrompt}
          title={`Connect user ${user.emailAddress} to ${entityType} ${selectedEntity?.value}`}
          message={`Are you sure you want to continue?
            Please confirm by entering the ${entityType} id in the text box below and clicking confirm`}
          onConfirm={onConfirmConnectUserToEntity}
          onCancel={onCancelConnectUserToEntity}
        />
      )}
      {showDisconnectUserPrompt && (
        <PromptDialog
          isOpen={showDisconnectUserPrompt}
          title={`Disconnect user ${user.emailAddress} From ${entityType} ${disconnectEntity?.id}`}
          message={`Are you sure you want to continue?
            Please confirm by entering the ${entityType} id in the text box below and clicking confirm`}
          onConfirm={onConfirmDisconnectUserFromEntity}
          onCancel={onCancelDisconnectUserFromEntity}
        />
      )}
    </div>
  );
};

export default UserConnectedEntity;
