import React from 'react';
import { FeedAttachedFile, SharedFile } from '@mayple/types';

export enum FilesListLayout {
  ONE_COLUMN = 'one',
  TWO_COLUMNS = 'two',
}

export enum FileStatus {
  DELETED = 'DELETED',
}

export type FileStackFile = {
  id: string | undefined;
  uploadId: string | undefined;
  name: string | undefined;
  filename: string | undefined;
  size: number;
  created: Date;
  url: string;
  status: FileStatus;
};

export type OnClickFileHandler = (file: FileListFile) => void;
export type OnDeleteFileHandler = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  file: FileListFile,
  files: FileListFile[]
) => void;

export type FileListAction = {
  id: string;
  label: string;
  onClick: (file: FileListFile | null) => void;
};

export interface FilesListProps {
  files: Array<FileListFile> | undefined;
  fileOnClick?: OnClickFileHandler;
  fileOnDelete?: OnDeleteFileHandler;
  allowDownload?: boolean;
  showFileDate?: boolean;
  showFileSize?: boolean;
  showEmptyState?: boolean;
  emptyStatePrimaryText?: string;
  emptyStateSecondaryText?: string;
  layout?: FilesListLayout;
  actions?: FileListAction[];
  classes?: Record<string, string>;
}

// Create a unified type, so we can work with it
export type FileListFile = FileStackFile & SharedFile & FeedAttachedFile;
