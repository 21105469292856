import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvailabilityViewerFilterProps } from './types';
import useAvailabilityViewerFilter from './hooks/useAvailabilityViewerFilter';
import AccountGroupList from './components/AccountGroupList';
import { availabilityManagementOptions, TRANSLATION_NS } from '../constants';
import { AVAILABILITY_RULES_PROFILES_LABELS } from '../../AvailabilityRulesInitializer/constants';

import RadioGroup from '../../../../inputs/RadioGroup';
import TimezoneSelect from '../../../../inputs/TimezoneSelect';
import FilterPanel from '../../../../display/Filter/FilterPanel';
import FilterSection from '../../../../display/Filter/FilterSection';
import Checkbox from '../../../../atoms/Registration/Checkbox';
import MultiCheckbox from '../../../../atoms/Registration/MultiCheckbox';

import useStyles from './style';

export const arrayOfStringsToHashTable = (array: string[] | null | undefined): Record<string, boolean> => {
  // Verify parameters
  if (!Array.isArray(array)) {
    return {};
  }
  // Reduce the array to an object
  return array.reduce((obj, item) => {
    // Disabling the rule here, it does not make sense to create a new object each iteration.
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    obj[item] = true;
    return obj;
  }, {});
};

const AvailabilityViewerFilter: React.FC<AvailabilityViewerFilterProps> = (props) => {
  const classes = useStyles(props);
  const { showAvailabilityProfiles = false } = props;
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'AvailabilityViewerFilter' });

  const {
    availabilityManagementValue,
    setAvailabilityManagementValue,
    timezone,
    onTimezoneChangeHandler,
    availabilityProfilesValue,
    setAvailabilityProfilesValue,
    onlyShowAvailability,
    onOnlyShowAvailabilityChange,
  } = useAvailabilityViewerFilter();

  return (
    <FilterPanel title={t('filterOptions')} className={classes.root}>
      <FilterSection title={t('accountSelection')}>
        <AccountGroupList />
      </FilterSection>

      <FilterSection title="Managed Availability">
        <RadioGroup
          name="managedAvailability"
          options={availabilityManagementOptions}
          value={availabilityManagementValue.toString()}
          onChange={setAvailabilityManagementValue}
        />
      </FilterSection>

      {showAvailabilityProfiles && (
        <FilterSection title="Internal Account Availability Profile">
          <MultiCheckbox
            name="availabilityProfile"
            enums={AVAILABILITY_RULES_PROFILES_LABELS}
            value={arrayOfStringsToHashTable(availabilityProfilesValue)}
            onChange={setAvailabilityProfilesValue}
          />
        </FilterSection>
      )}

      <FilterSection title="Timezone">
        <TimezoneSelect
          label="Timezone"
          helperText="Please select a timezone from the list"
          onChange={onTimezoneChangeHandler}
          defaultTimezone={timezone}
        />
      </FilterSection>
      <FilterSection title="Only show availability">
        <Checkbox
          inputLabel={t('onlyShowAvailability', 'Only show availability rules')}
          checked={onlyShowAvailability}
          onChange={onOnlyShowAvailabilityChange}
          name="onlyShowAvailability"
        />
      </FilterSection>
    </FilterPanel>
  );
};

export default AvailabilityViewerFilter;
