import React, { FC, useMemo } from 'react';
import { ProjectSpecialSubType, ProjectSpecialType } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import FlagIcon from '@material-ui/icons/Flag';

import { WizardStepsProps } from './types';
import { getProjectName } from './logic';
import { hotLocationsEnums, PROJECT_CREATION_REASONS_PAIRS } from './consts';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import {
  INDUSTRIES_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  PROJECT_SPECIAL_SUBTYPE_PAIRS,
  TARGET_KPI_WITH_ICONS_PAIRS,
  TARGETED_COUNTRIES_PAIRS,
} from '../../../../../fe_common/client/app/enums';
import { LabelValueProps } from '../../../../../fe_common/client/app/types';

import { useFormStyles } from './style';

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const classes = useFormStyles(props);

  const { formValues, change } = props;

  const isNormalProject = formValues.requestedProjectSpecialType === ProjectSpecialType.NO_SPECIAL_TYPE;

  const { enableHealthCheckV2 } = useFeatureFlags();

  const onRequestedProjectSpecialSubTypeChangeHandler = (value: ProjectSpecialSubType) => {
    const newValue: ProjectSpecialType =
      value === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK ||
      value === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2
        ? ProjectSpecialType.MAILCHIMP_AUDIT_TYPE
        : ProjectSpecialType.NO_SPECIAL_TYPE;

    change?.('requestedProjectSpecialType', newValue);
    change?.('projectName', getProjectName(value, formValues?.companyName));
  };

  const options: LabelValueProps[] = useMemo(
    () =>
      PROJECT_SPECIAL_SUBTYPE_PAIRS.filter(({ value }) =>
        enableHealthCheckV2
          ? [
              ProjectSpecialSubType.NO_SPECIAL_SUB_TYPE,
              ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK,
              ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2,
            ].includes(value)
          : [ProjectSpecialSubType.NO_SPECIAL_SUB_TYPE, ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK].includes(value),
      ).map((item) => ({
        ...item,
        isDeprecated: item.value === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK,
      })),
    [enableHealthCheckV2],
  );

  return (
    <div className={classes.formContainer}>
      <WizardStepField
        name="requestedProjectSpecialSubType"
        wizardFieldLabel="Project Type"
        type={WizardStepField.types.RADIO_CARDS}
        // @ts-ignore
        onChange={onRequestedProjectSpecialSubTypeChangeHandler}
        cardType="radio"
        layout="vertical"
        selectionIndicatorType="hide"
        enums={options}
        required
      />
      <WizardStepField
        name="projectName"
        type={WizardStepField.types.TEXT}
        // @ts-ignore
        label="Project name (optional)"
        helperText="If no name will be set, project will be created with default name"
        autoFocus
      />
      <WizardStepField
        name="locations"
        type={WizardStepField.types.AUTOCOMPLETE}
        // @ts-ignore
        label="Target Countries"
        enums={TARGETED_COUNTRIES_PAIRS}
        required
        isMulti
        placeholder="Start typing to select target location"
        hotEnums={hotLocationsEnums}
        hotEnumsIcon={<FlagIcon />} // custom hot enum icon
      />
      <WizardStepField
        name="industry"
        type={WizardStepField.types.AUTOCOMPLETE}
        // @ts-ignore
        label="Industry"
        enums={INDUSTRIES_PAIRS}
        required
        placeholder="Try Entertainment, Clothing, etc."
      />
      <WizardStepField
        name="productBusinessModel"
        type={WizardStepField.types.AUTOCOMPLETE}
        // @ts-ignore
        label="Business model"
        enums={PRODUCT_BUSINESS_MODEL_PAIRS}
        isMulti
        required
      />
      {isNormalProject && (
        <>
          <WizardStepField
            name="targetKPI"
            type={WizardStepField.types.AUTOCOMPLETE}
            // @ts-ignore
            label="Target KPI"
            enums={TARGET_KPI_WITH_ICONS_PAIRS}
            required
          />
          <Divider className={classes.separator} />
          <WizardStepField
            name="projectCreationReason"
            type={WizardStepField.types.AUTOCOMPLETE}
            // @ts-ignore
            label="Reason for opening a project"
            enums={PROJECT_CREATION_REASONS_PAIRS}
            required
          />
        </>
      )}
    </div>
  );
};

export default WizardSteps;
