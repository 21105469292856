import { AccountType } from '@mayple/types';
import { ENTITY_TYPE } from '../app/types';

const getEntityTypeFromAccountType = (accountType: AccountType): ENTITY_TYPE => {
  switch (accountType) {
    case AccountType.COMPANY:
      return ENTITY_TYPE.COMPANY;
    case AccountType.INTERNAL_TEAM:
      return ENTITY_TYPE.INTERNAL_TEAM;
    case AccountType.MARKETER:
      return ENTITY_TYPE.MARKETER;
    default:
      return ENTITY_TYPE.USER;
  }
};

export default getEntityTypeFromAccountType;
