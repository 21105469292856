/* eslint-disable react/jsx-no-duplicate-props,react/no-unused-prop-types,no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { colors } from '../../../app/theme';
import { TextDisplay } from '../../display';

const isValidNumberRegex = /^[-+]$|^[-+]?\d+[,|.]?\d*$/;

/**
 * Wrapper to MUI TextField to support Numeric values
 * supports minimumValue, maximumValue, precision
 */
export default class NumericField extends React.Component {
  constructor(props) {
    super(props);

    // console.log('NumericField constructor props', props);
    const { input = {}, value: controlledValue } = props;
    const { value } = input;

    this.state = {
      value: this.getValidValue(value ?? controlledValue, props), // was || operator. when we sent 0, it took the initial value instead!
    };

    this.getValidValue = this.getValidValue.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { precision } = this.props;

    if (JSON.stringify(prevProps.input?.value) !== JSON.stringify(this.props.input?.value)) {
      this.setValue(this.getValidValue(this.props.input?.value, { precision }));
    } else if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      this.setValue(this.getValidValue(this.props.value, { precision }));
    }
  }

  setValue = (newValue) => {
    this.setState({ value: newValue });
  };

  getValidValue = (value, { precision }) => {
    let newValue;

    if (value === undefined || value === null || value === '') {
      return '';
    }

    // Checking this if this is a number
    if (isValidNumberRegex.test(value) === false) {
      return '';
    }

    // only real numbers
    if (precision === 0) {
      newValue = Number.parseInt(value, 10);
      if (!Number.isFinite(newValue)) {
        return null;
      }
    } else {
      [newValue] = Number.parseFloat(value)
        .toString()
        .match(new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`));
    }

    return parseFloat(newValue);
  };

  handleOnChange = (event) => {
    const { value } = event.currentTarget;
    const { input, onChange } = this.props;
    const { onChange: dispatchOnChange } = input;

    const valid = this.getValidValue(value, this.props);

    this.setValue(valid);

    onChange(valid);

    if (dispatchOnChange) {
      dispatchOnChange(valid);
    }
  };

  render() {
    const {
      minimumValue,
      maximumValue,
      required,
      variant,
      label,
      fullWidth,
      InputProps,
      meta,
      inputAdornment,
      adornmentPosition,
      onEnterPress,
      placeholder,
      helperText,
      disabled,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange, // we need to extract onChange here, so it won't override the onChange in ...rest
    } = this.props;

    const { error, invalid, pristine, submitFailed } = meta;
    const hasError = (submitFailed || !pristine) && invalid && error !== '';

    const { value } = this.state;
    let adornment = null;
    let adornmentInputProps = {};
    if (inputAdornment) {
      adornment = (
        <InputAdornment style={{ color: colors.black }} position={adornmentPosition}>
          <TextDisplay color="inherit">{inputAdornment}</TextDisplay>
        </InputAdornment>
      );

      adornmentInputProps = adornmentPosition === 'start' ? { startAdornment: adornment } : { endAdornment: adornment };
    }

    return (
      <TextField
        type="number"
        value={value}
        label={label}
        required={required}
        className={className}
        inputProps={{
          min: minimumValue,
          max: maximumValue,
        }}
        InputProps={{
          ...InputProps,
          ...adornmentInputProps,
        }}
        onChange={this.handleOnChange}
        variant={variant}
        fullWidth={fullWidth}
        error={hasError}
        placeholder={placeholder}
        helperText={(helperText ?? hasError) ? error : ''}
        disabled={disabled}
        onKeyUp={(e) => {
          e.preventDefault();
          if (onEnterPress && (e.key === 'Enter' || e.keyCode === 13)) {
            onEnterPress(e);
          }
        }}
      />
    );
  }
}

NumericField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.object,
  onChange: PropTypes.func,
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  precision: PropTypes.number,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  InputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputAdornment: PropTypes.string,
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

NumericField.defaultProps = {
  input: {},
  meta: {},
  minimumValue: Number.MIN_SAFE_INTEGER,
  maximumValue: Number.MAX_SAFE_INTEGER,
  precision: 0,
  required: false,
  variant: 'outlined',
  error: false,
  label: undefined,
  value: '',
  fullWidth: false,
  InputProps: {},
  onChange: () => {},
  inputAdornment: '',
  adornmentPosition: 'start',
  placeholder: '',
  disabled: false,
  className: '',
};
