import React, { FC, useMemo } from 'react';
import { ProjectCycleOperationMode } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';

import { WizardStepsProps } from './types';
import CycleStep from './CycleStep';
import projectBillingHelpers from '../../../../pages/ProjectBillingPage/helpers';
import projectBilling from '../../../../pages/ProjectBillingPage/logic';
import { hasActiveOpportunities } from '../../../../pages/ProjectDetailsPage/components/ProjectOpportunities/OpportunitiesCandidates/logic';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Button from '../../../../../fe_common/client/components/inputs/Button';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingBottom: '3rem',
    },
    actionsWrapper: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  }),
);

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const classes = useStyles();
  const [showEditorPreview, setShowEditorPreview] = React.useState(false);

  const { invalid, submit, submitting, errors, formValues, project, enableNextCycleEdit } = props;
  const { currentCycle, nextCycle } = project;

  const haveActiveOppts = useMemo(() => hasActiveOpportunities(project?.opportunities || []), [project]);
  const { isDiscovery, isOnboarding, isLive } = projectBilling.getProjectLifeCycles(project);
  const enablePpcBillingPlanEditCurrent = isDiscovery || isOnboarding || isLive;
  const enablePpcBillingPlanEditNext = isLive;
  const isOperatedAutomaticallyByProjectPackages =
    project?.projectCycleOperationMode === ProjectCycleOperationMode.OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES;

  return (
    <div className={classes.root}>
      <CycleStep
        {...props}
        cycle={currentCycle}
        isNextCycle={false}
        suffix=""
        allowDiscountEdit={!isLive && !isOperatedAutomaticallyByProjectPackages} //
        enablePpcBillingPlanEdit={enablePpcBillingPlanEditCurrent && !isOperatedAutomaticallyByProjectPackages}
        enableNonPpcServicesEdit={!isOperatedAutomaticallyByProjectPackages}
        enableDeliverablesEdit={!isOperatedAutomaticallyByProjectPackages}
        enableSetupServiceEdit={!isOperatedAutomaticallyByProjectPackages}
        mediaBudgetDivisionFieldAmountKey="budgetForSkillType"
      />

      {enableNextCycleEdit && (
        <CycleStep
          {...props}
          cycle={nextCycle}
          isNextCycle
          suffix="NextCycle"
          allowDiscountEdit={!isOperatedAutomaticallyByProjectPackages}
          enablePpcBillingPlanEdit={enablePpcBillingPlanEditNext && !isOperatedAutomaticallyByProjectPackages}
          enableNonPpcServicesEdit={!isOperatedAutomaticallyByProjectPackages}
          enableDeliverablesEdit={!isOperatedAutomaticallyByProjectPackages}
          enableSetupServiceEdit={!isOperatedAutomaticallyByProjectPackages}
          mediaBudgetDivisionFieldAmountKey="budgetForSkillType"
        />
      )}

      {/* TODO: need to ask business side what do they want to see here in a future task */}
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              disabled
              checked={showEditorPreview}
              onChange={(event) => {
                setShowEditorPreview(event.target.checked);
              }}
            />
          }
          label="Show update preview (Coming soon)"
        />
      </FormGroup>

      {showEditorPreview && <div>{projectBillingHelpers.getBillingEditorPreview(formValues)}</div>}

      {errors && errors.length > 0 && (
        <List>
          {errors.map((errMsg) => (
            <ListItem key={errMsg.replace(/\s/g, '')} disableGutters>
              <TextDisplay colorOverride="red">{errMsg}</TextDisplay>
            </ListItem>
          ))}
        </List>
      )}

      {haveActiveOppts && (
        <CalloutMessage
          margin
          message="This project have open opportunities that experts did not yet respond to. This means
                  you cannot update the project just yet. you can withdraw the open opportunities in the project page
                  or wait for the experts to respond to them first."
          type="warning"
        />
      )}

      <div className={classes.actionsWrapper}>
        <Button
          label="Update configuration"
          disabled={invalid || submitting || haveActiveOppts}
          onClick={submit}
          color="primary"
          variant="contained"
        />
      </div>
    </div>
  );
};

export default WizardSteps;
