import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

import { images } from '../../../app/images';

const styles = () => ({
  root: {
    marginLeft: 55,
  },
});

/**
 * ListItemNode is a ListItemTreeView tree-node.
 * @property data - consists from label and data props, label is the level headline description,
 * while data consists from id, name, displayImageUrl and linkUrl.
 * @property isParent - points if this node is a parent node or is a leaf node.
 * @property collapsed - boolean that indicates if this node branch is in collapsed mode or not.
 * @property handleClick - an on-click handler from the ListItemNode component that expends this node
 * along with the entire tree level.
 * @returns ListItemNode rendering component.
 */
function ListItemNode({ data, classes }) {
  if (!data) {
    return null;
  }

  const { id, name, displayImageUrl = images.noImageAvailable, linkUrl } = data;

  return (
    <ListItem classes={{ root: classes.root }}>
      <ListItemAvatar>
        <Avatar alt={name} src={displayImageUrl} style={{ width: 50, height: 50 }} />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <span>
            {`ID: ${id} `}
            <CopyToClipboard text={id}>
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                copy
              </Link>
            </CopyToClipboard>
            <br />
            {linkUrl && (
              <Link href={linkUrl} rel="noopener noreferrer" target="_blank">
                {linkUrl}
              </Link>
            )}
          </span>
        }
      />
    </ListItem>
  );
}

ListItemNode.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    displayImageUrl: PropTypes.string,
    linkUrl: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListItemNode);
