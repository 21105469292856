import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  validateRequired,
  validateNumberIsGreaterThanOrEqualTo,
  validateNumberIsLessThanOrEqualTo,
} from '../../../../services/validation';
import NumericField from '../../NumericField';

export class WizardNumberField extends React.Component {
  constructor(props) {
    super(props);

    const { isRequired, maximumValue, minimumValue } = props;

    // console.log('number field', props);

    // field required?
    const validator = isRequired ? [validateRequired] : [];

    if (typeof minimumValue === 'number') {
      validator.push(validateNumberIsGreaterThanOrEqualTo(minimumValue));
    }

    if (typeof maximumValue === 'number') {
      validator.push(validateNumberIsLessThanOrEqualTo(maximumValue));
    }

    this.state = {
      validator,
    };
  }

  render() {
    const { name, hintText, fieldType, formatValue, fullWidth, label, maximumValue, minimumValue, ...rest } =
      this.props;

    const { validator } = this.state;

    return (
      <div>
        <Field
          component={NumericField}
          type={fieldType}
          label={label}
          name={name}
          hintText={hintText}
          fullWidth={fullWidth}
          validate={validator}
          format={formatValue}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          {...rest}
        />
      </div>
    );
  }
}

WizardNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  fieldType: PropTypes.string,
  isRequired: PropTypes.bool,
  fullWidth: PropTypes.bool,
  formatValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  label: PropTypes.string,
};

WizardNumberField.defaultProps = {
  label: '',
  hintText: '',
  fieldType: 'number',
  isRequired: true,
  fullWidth: true,
  formatValue: null,
  minimumValue: Number.MIN_SAFE_INTEGER,
  maximumValue: Number.MAX_SAFE_INTEGER,
};
