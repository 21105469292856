import { useCallback, useMemo } from 'react';
import { IndustryInput, Project, ProjectUpdateInput } from '@mayple/types';
import { UpdateProjectWithOperationResultMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectWithOperationResultMutation';

import {
  INDUSTRIES_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  TARGETED_COUNTRIES_PAIRS,
} from '../../../../../../../../fe_common/client/app/enums';
import getEnumsInitialValues from '../../../../../../../../fe_common/client/components/molecules/EditableComponent/logic';
import { valuesOnly } from '../../../../../../../../fe_common/client/services/utils';
import useMutation from '../../../../../../../../fe_common/client/hooks/useMutation';
import { setNotification } from '../../../../../../../../fe_common/client/services/notification';
import { EntityOperationResponseExecutionResult } from '../../../../../../../../fe_common/client/app/types';

export const getFormInitialValues = (project: Project): Record<string, any> => {
  const {
    industry: industryOrig,
    locations: locationsOrig,
    projectDefinition,
    targetUrls,
    productBusinessModel: productBusinessModelOrig,
  } = project;

  const industry = INDUSTRIES_PAIRS.find(({ value }) => value === industryOrig?.[0]?.industrySubCategory);

  const locations = getEnumsInitialValues(locationsOrig, TARGETED_COUNTRIES_PAIRS);

  const productBusinessModel = getEnumsInitialValues(productBusinessModelOrig, PRODUCT_BUSINESS_MODEL_PAIRS);

  const { targetKPI } = projectDefinition.primaryObjectives;

  return {
    industry,
    locations,
    targetKPI,
    targetUrls,
    productBusinessModel,
  };
};

export const getParamsFromValues = (values: Record<string, any>): ProjectUpdateInput => {
  try {
    const industry: IndustryInput[] = [
      {
        industryCategory: values.industry.category,
        industrySubCategory: values.industry.value,
      },
    ];

    const locations = valuesOnly(values.locations);

    const productBusinessModel = valuesOnly(values.productBusinessModel);

    const { targetKPI, targetUrls } = values;

    return {
      industry,
      locations,
      projectDefinition: {
        primaryObjectives: {
          targetKPI,
        },
      },
      targetUrls,
      productBusinessModel,
    };
  } catch (e) {
    return {};
  }
};

export const useFormSubmit = (project: Project): Record<string, any> => {
  const initialValues = useMemo(() => getFormInitialValues(project), [project]);

  const { mutate: updateProject, loading, error } = useMutation(UpdateProjectWithOperationResultMutation);

  const onSubmit = useCallback(
    async (values: Record<string, any>): Promise<boolean> => {
      try {
        const projectUpdate = getParamsFromValues(values);

        const { id: projectId } = project;

        const variables = { projectId, projectUpdate };

        const response: EntityOperationResponseExecutionResult<'updateProjectWithOperationResult'> =
          await updateProject({ variables });

        return !!response?.data?.updateProjectWithOperationResult?.success;
      } catch (e) {
        setNotification(`Encountered error when trying to update project. ${JSON.stringify(e)}`, 'error');

        return false;
      }
    },
    [project, updateProject],
  );

  return { initialValues, onSubmit, loading, error };
};
