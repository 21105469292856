import { config } from '../config';

import { Environment } from '../../fe_common/client/app/types';

interface FeatureFlagsData extends Record<string, boolean> {
  enableHealthCheckV2: boolean;
  enableNewAssessment: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const featureFlags: FeatureFlagsData = {
  enableHealthCheckV2: true,
  enableNewAssessment: true,
};

export const getFeatureFlags = (): FeatureFlagsData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isProduction = config.env === Environment.PORKY;

  return {
    ...featureFlags,
  };
};

const useFeatureFlags = (): FeatureFlagsData => getFeatureFlags();

export default useFeatureFlags;
