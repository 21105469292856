import { useEffect, useMemo, useState } from 'react';
import { Task, TaskStatus, TaskType } from '@mayple/types';

import { CollectSubTasksData, TasksQueryOptions } from './types';
import getSubTasksQueryByTaskType from './logic';
import useTasks from './useTasks';
import useSubTasks from './useSubTasks';

const defaultOptions: TasksQueryOptions = {
  skipTasks: false,
  taskStatus: [TaskStatus.PENDING],
};

const useCollectSubTasks = <T>(taskType: TaskType | null, options?: TasksQueryOptions): CollectSubTasksData<T> => {
  const { skipTasks, taskStatus, projectId } = { ...defaultOptions, ...options };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [tasksUuids, setTasksUuids] = useState<string[] | undefined>();

  const [collectedSubTasks, setCollectedSubTasks] = useState<T[]>([]);

  const [currentTaskUuid, setCurrentTaskUuid] = useState<string | undefined>(() =>
    tasksUuids?.length ? tasksUuids[currentIndex] : undefined,
  );

  const queryClass = getSubTasksQueryByTaskType(taskType);

  useEffect(() => {
    if (!tasksUuids) {
      setCurrentIndex(0);
      setLoadingData(true);
      setInitialized(false);
      setCollectedSubTasks([]);
      setCurrentTaskUuid(undefined);
    }
  }, [tasksUuids]);

  const taskTypes: TaskType[] | null = taskType ? [taskType] : null;

  const {
    tasks,
    loading: loadingTasks,
    error: errorTasks,
    refetch: refetchTasks,
  } = useTasks(taskTypes, taskStatus, { skip: skipTasks || !taskTypes });

  const newTaskUuids: string[] | undefined = useMemo(
    () => (Array.isArray(tasks) ? tasks.map(({ uuid }) => uuid) : undefined),
    [tasks],
  );

  /**
   * save the tasksUuids into array
   */
  useEffect(() => {
    if (!initialized && !loadingTasks && Array.isArray(newTaskUuids)) {
      setTasksUuids((prevState) => {
        if (JSON.stringify(prevState) !== JSON.stringify(newTaskUuids)) {
          setTasksUuids(newTaskUuids);
        }
        return prevState;
      });
    }
  }, [initialized, loadingTasks, newTaskUuids, tasksUuids]);

  // And this one gets the subTasks, only if we have currentTaskUuid
  const {
    subTasks,
    loading: loadingSubTasks,
    error: errorSubTasks,
    // refetch: refetchSubTasks,
  } = useSubTasks<T>(
    queryClass,
    currentTaskUuid,
    { skip: !queryClass || !currentTaskUuid, fetchPolicy: 'network-only' },
    projectId,
  );

  useEffect(() => {
    if (loadingTasks) {
      setInitialized(false);
      setTasksUuids(undefined);
    }
  }, [loadingSubTasks, loadingTasks]);

  useEffect(() => {
    if (initialized || !loadingData) {
      return;
    }

    if (!tasksUuids?.length) {
      return;
    }

    if (loadingSubTasks) {
      return;
    }

    setCollectedSubTasks((prevCollectedSubTasks) => {
      // if we didn't get any subTasks - just return the previous state
      if (subTasks.length === 0 || loadingSubTasks) {
        return prevCollectedSubTasks;
      }

      // if the prev state was [] - add up the new subTasks
      if (prevCollectedSubTasks.length === 0) {
        return [...subTasks];
      }

      // before adding more subtasks - we need to make sure that the last one in the array is actually different
      if (
        prevCollectedSubTasks.length &&
        subTasks.length &&
        // @ts-ignore
        prevCollectedSubTasks[prevCollectedSubTasks.length - 1]?.uuid !== subTasks[subTasks.length - 1]?.uuid
      ) {
        return [...prevCollectedSubTasks, ...subTasks];
      }

      // by default return the existing state value
      return prevCollectedSubTasks;
    });

    if (currentIndex >= tasksUuids.length && !loadingSubTasks) {
      setLoadingData(false);
      setInitialized(true);
      return;
    }

    setCurrentIndex((prevIndex) => {
      if (loadingSubTasks) {
        return prevIndex;
      }
      const newIndex = tasksUuids.findIndex((item) => item === currentTaskUuid);
      return newIndex + 1;
    });

    setCurrentTaskUuid((prevTaskUuid) => {
      if (currentIndex < tasksUuids.length) {
        return tasksUuids[currentIndex];
      }
      return prevTaskUuid;
    });
  }, [
    currentIndex,
    currentTaskUuid,
    initialized,
    loadingData,
    loadingSubTasks,
    subTasks,
    tasksUuids,
    tasksUuids?.length,
  ]);

  return {
    initialized,
    taskStatus,
    loading: loadingTasks || loadingSubTasks,
    error: errorTasks || errorSubTasks,
    tasks: tasks || ([] as Task[]),
    subTasks: collectedSubTasks || ([] as T[]),
    refetch: refetchTasks,
  };
};
export default useCollectSubTasks;
