import { PackageExample } from '@mayple/types';

import { images } from '../../../app/images';

const defaultHeroImage = images.marketersCallSchedulingImage;
// eslint-disable-next-line import/prefer-default-export
export const getHeroImageUrl = (packageExample: PackageExample | null | undefined): string => {
  if (!packageExample) {
    return defaultHeroImage;
  }

  return packageExample?.images?.[0]?.image?.url ?? defaultHeroImage;
};
