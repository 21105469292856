import React, { FC } from 'react';
import { InternalTeam } from '@mayple/types';
import { useTranslation } from 'react-i18next';
import ReactFilestack from 'filestack-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useFileStackUpload, useGoogleSlidesInput } from './logic';
import { UploadMarketingPlanDialogTabs } from './consts';
import { OnMarketingPlanUpload } from './types';

import { TRANSLATION_NS } from '../consts';
import { replaceValues } from '../logic';
import { useMarketingPlanState } from '../../MarketingPlanProvider';
import { Button } from '../../../inputs';
import CalloutMessage from '../../../common/CalloutMessage';
import { useMuiTabState } from '../../../../hooks';
import TabContent from '../../../atoms/TabContent';
import { handleClientError } from '../../../../services/logger';
import { RefreshSpinner } from '../../../atoms';
import { TextDisplay } from '../../../display';

import useStyles from './style';

interface UploadMarketingPlanDialogProps {
  open: boolean;
  loading: boolean;
  filestackApiKey: string;
  onClose: () => void;
  onUpload: OnMarketingPlanUpload;
}

/**
 * This dialog will call onUpload with a SharedFileCreate as a parameter for the callback.
 * You can use this to update the participant/project sharedFiles property as an admin or marketer.
 *
 * @param props
 * @constructor
 */
const UploadMarketingPlanDialog: FC<UploadMarketingPlanDialogProps> = (props) => {
  const { onClose, onUpload, filestackApiKey, open, loading } = props;

  const classes = useStyles(props);

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'MarketingPlanProgress.UploadMarketingPlanDialog' });

  const [tab, setTab] = useMuiTabState<typeof UploadMarketingPlanDialogTabs>(
    UploadMarketingPlanDialogTabs.GoogleSlides,
    UploadMarketingPlanDialogTabs,
    {
      shouldReportEvent: false,
    }
  );

  const { mainGrowthStrategist } = useMarketingPlanState();
  const { name: mainGrowthStrategistName = '' } = (mainGrowthStrategist || {}) as InternalTeam;

  const { slideUrl, googleSlideInputError, showGoogleSlideInputError, onSlideUrlChange, onUploadGoogleSlide } =
    useGoogleSlidesInput(onUpload, t);

  const { onFileUpload, fileStackOptions } = useFileStackUpload(onUpload);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }} disableTypography>
        <TextDisplay variant="h6">Add marketing plan</TextDisplay>
        <IconButton aria-label="delete" onClick={onClose} disabled={loading}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs value={tab} onChange={setTab} variant="scrollable" disabled={loading}>
          <Tab disabled={loading} value={UploadMarketingPlanDialogTabs.GoogleSlides} label={t('GoogleSlidesTab')} />
          <Tab disabled={loading} value={UploadMarketingPlanDialogTabs.FileStack} label={t('FileStackTab')} />
        </Tabs>

        {tab === UploadMarketingPlanDialogTabs.GoogleSlides && (
          <TabContent>
            <TextField
              label={t('GoogleSlidesInputLabel')}
              variant="outlined"
              fullWidth
              error={showGoogleSlideInputError}
              helperText={showGoogleSlideInputError ? googleSlideInputError : ''}
              value={slideUrl}
              onChange={onSlideUrlChange}
              disabled={loading}
            />

            <CalloutMessage
              type="warning"
              message={replaceValues(t('GoogleSlidesCalloutMessage'), { mainGrowthStrategistName })}
              title={t('GoogleSlidesCalloutTitle')}
              margin
            />
            <Button
              startIcon={loading && <RefreshSpinner size={24} />}
              label={t('GoogleSlidesButton')}
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading || (!!googleSlideInputError && tab === UploadMarketingPlanDialogTabs.GoogleSlides)}
              onClick={onUploadGoogleSlide}
            />
          </TabContent>
        )}

        {tab === UploadMarketingPlanDialogTabs.FileStack && (
          <TabContent>
            <ReactFilestack
              apikey={filestackApiKey}
              options={fileStackOptions}
              onSuccess={onFileUpload}
              onError={(err: Error) => {
                handleClientError(err, 'Failed uploading image to Filestack.');
              }}
              render={({ onPick }: Record<string, any>) => (
                <Button
                  startIcon={loading ? <RefreshSpinner size={24} /> : <CloudUploadIcon />}
                  label={t('FileStackButton')}
                  onClick={onPick}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                />
              )}
            />
          </TabContent>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadMarketingPlanDialog;
