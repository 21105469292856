import React, { FC } from 'react';
import { Project } from '@mayple/types';
import { RemoveProjectSharedFileMutation } from 'growl-graphql/dist/mutations/RemoveProjectSharedFileMutation';
import { AddMaypleSharedFileToProjectMutation } from 'growl-graphql/dist/mutations/AddMaypleSharedFileToProjectMutation';

import { onAuditFileAddedEventHandler, removeSharedFileHandler, useAllProjectFiles } from './logic';
import { config } from '../../../../config';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import FilesList from '../../../../../fe_common/client/components/molecules/FilesList';
import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { WizardFileUploaderInput } from '../../../../../fe_common/client/components/atoms/WizardFields';

import useStyles from './style';

export interface ProjectFilesProps {
  project: Project | null | undefined;
  enableFileUpload: boolean;
  classes?: Record<string, string>;
}

const ProjectFiles: FC<ProjectFilesProps> = (props) => {
  const { project, enableFileUpload } = props;
  const { id: projectId } = project || {};

  const classes = useStyles(props);

  const { mutate: removeProjectSharedFileMutation } = useMutation(RemoveProjectSharedFileMutation);
  const { mutate: addMaypleSharedFileToProjectMutation } = useMutation(AddMaypleSharedFileToProjectMutation);

  const sharedFiles = useAllProjectFiles(project);

  return (
    <Section title="Project Files" noBorder classes={{ root: classes.root }}>
      <SectionContent>
        {sharedFiles.length === 0 && <TextDisplay variant="subtitle2">No files exist.</TextDisplay>}
        {sharedFiles.length > 0 && (
          <FilesList
            // @ts-ignore
            files={sharedFiles}
            allowDownload
            showFileDate
            showFileSize
            fileOnDelete={(event, file) => {
              event.stopPropagation();
              return removeSharedFileHandler(file, projectId, removeProjectSharedFileMutation);
            }}
          />
        )}

        {enableFileUpload && (
          <WizardFileUploaderInput
            apikey={config.filestackApiKey}
            onSuccess={(file: any) =>
              onAuditFileAddedEventHandler(file, projectId, addMaypleSharedFileToProjectMutation)
            }
            accept={['pdf']}
            optionalText="Add File"
            maxSize={1024 * 1024 * 20}
          />
        )}
      </SectionContent>
    </Section>
  );
};

export default ProjectFiles;
