/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { TextDisplay } from '../../components/display';

export const equalityComparison = (a: any, b: any) => a === b;

export const notEqualComparison = (a: any, b: any) => a !== b;

export const emphasizeWithColor = (
  value: any,
  emphasizedValue: any,
  comparisonMethod = equalityComparison,
  color = 'lightPink'
) =>
  comparisonMethod(value, emphasizedValue) ? (
    <TextDisplay style={{ backgroundColor: color }}>{value}</TextDisplay>
  ) : (
    value
  );
