import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 8px',
  },
  title: {
    color: colors.black_light,
    fontWeight: 'bold',
  },
}));

function FeedSingleFilter({ title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextDisplay variant="overline" className={classes.title}>
        {title}
      </TextDisplay>
      {children}
    </div>
  );
}

FeedSingleFilter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

FeedSingleFilter.defaultProps = {
  onChange: () => {},
  children: null,
};
export default FeedSingleFilter;
