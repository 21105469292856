import React, { FC } from 'react';
import { EmploymentHistory } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ReferralContactsList from '../ReferralContactsList';

import { logos } from '../../../../../../fe_common/client/app/logos';
import { colors } from '../../../../../../fe_common/client/app/theme';
import { ExternalLink, TextDisplay } from '../../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    content: { margin: 0 },
    divider: { margin: '12px 0' },
  }),
);

type EmploymentHistoryPanelsProps = {
  employmentHistory: EmploymentHistory[] | null | undefined;
  classes?: Record<string, string>;
};

/**
 * UI component for admins to easily view the employment history filled by marketers
 */
const EmploymentHistoryPanels: FC<EmploymentHistoryPanelsProps> = (props) => {
  const { employmentHistory } = props;
  const classes = useStyles(props);

  if (!employmentHistory || employmentHistory.length === 0) {
    return <TextDisplay>No employment history information found.</TextDisplay>;
  }

  return (
    <div>
      {employmentHistory?.map((item, i) => {
        const { brandInfo, endDate, jobTitle, name, referralContacts, startDate, websiteAddress } = item;
        const iconUrl = brandInfo?.iconUrl || logos.logoSmall;
        const startDateFormatted = moment(startDate).format('MMMM YYYY');
        const endDateFormatted = endDate ? moment(endDate).format('MMMM YYYY') : 'Present';

        return (
          <Accordion key={`EmploymentHistoryPanels-${i}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.content }}>
              <List>
                <ListItem>
                  <Avatar src={iconUrl} title="" />
                  <ListItemText primary={name} secondary={`${startDateFormatted} - ${endDateFormatted}`} />
                </ListItem>
              </List>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Job title
                </TextDisplay>
                <TextDisplay>{jobTitle}</TextDisplay>
                <Divider className={classes.divider} />
                <TextDisplay colorOverride={colors.black_light} variant="overline">
                  Website
                </TextDisplay>
                <TextDisplay>
                  <ExternalLink href={websiteAddress}>{websiteAddress}</ExternalLink>
                </TextDisplay>
                <Divider className={classes.divider} />
                {referralContacts && referralContacts.length > 0 && (
                  <>
                    <TextDisplay colorOverride={colors.black_light} variant="overline">
                      Referral Contacts
                    </TextDisplay>
                    <ReferralContactsList referralContacts={referralContacts} />
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default EmploymentHistoryPanels;
