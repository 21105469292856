import { MarketerAssignedForApproval } from '@mayple/types';
import { ExpertMatchingStageData } from './types';

export const defaultData = [];
/**
 * Extracts relevant data from selectedMatchedMarketersQuery
 * @param selectedMatchedMarketers
 */
export const getMatchedExpertData = (
  selectedMatchedMarketers: MarketerAssignedForApproval[]
): ExpertMatchingStageData[] => {
  // const validStages = [ProjectStage.MARKETER_APPROVAL, ProjectStage.OPPORTUNITIES];
  // if (!validStages.includes(stage)) {
  //   throw new Error(`Project stageDetails must be in one of ${JSON.stringify(validStages)} stages.`);
  // }

  try {
    if (!selectedMatchedMarketers) {
      return defaultData;
    }

    return selectedMatchedMarketers.map(({ marketerId, introduceYourself }) => ({
      marketerId,
      marketerIntro: introduceYourself || '',
    }));
  } catch (e) {
    return defaultData;
  }
};
