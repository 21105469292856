import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    padding: '1rem',
  },
  divider: {
    margin: '1rem',
  },
}));
