/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Task, TaskStatus, TaskType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { TasksQuery } from 'growl-graphql/dist/queries/tasks/TasksQuery';

import { TasksData } from './types';

const useTasks = (taskTypes?: TaskType[] | null, taskStatus?: TaskStatus[], options?: QueryHookOptions): TasksData => {
  const { data, loading, error, refetch } = useQuery(TasksQuery.query, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  const filteredTasks: Task[] = (data?.tasks || ([] as Task[]))
    .filter((task: Task) => (taskTypes && taskTypes.length ? taskTypes.includes(task.taskType!) : true))
    .filter((task: Task) => (taskStatus && taskStatus.length ? taskStatus.includes(task.taskStatus!) : true));

  return {
    tasks: filteredTasks,
    loading,
    error,
    refetch,
  };
};

export default useTasks;
