import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Select } from '../../Registration/Select';

import { validateRequired } from '../../../../services/validation';
import { TextDisplay } from '../../../display';

export function WizardSelectField(props) {
  const { name, isRequired, helperText, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  // console.log('WizardSelectField props', props);

  return (
    <div>
      <Field component={Select} name={name} validate={validator} {...rest} />
      {helperText && (
        <TextDisplay variant="caption" colorOverride="#887B77" style={{ margin: '2px 0 -19px 8px' }}>
          {helperText}
        </TextDisplay>
      )}
    </div>
  );
}

WizardSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
};

WizardSelectField.defaultProps = {
  isRequired: true,
  helperText: '',
  onSelect: () => {},
};

/*
 * import React from 'react';
 import PropTypes from 'prop-types';
 import { Field } from 'redux-form';
 import MenuItem from '@material-ui/core/MenuItem';
 import FormControl from '@material-ui/core/FormControl';
 import FormHelperText from '@material-ui/core/FormHelperText';
 import InputLabel from '@material-ui/core/InputLabel';
 import { Select } from 'redux-form-material-ui';

 import { validateRequired } from '../../../../services/validation';

 export function WizardSelectField(props) {
 const { enums, name, label: fieldLabel, isRequired, hintText } = props;
 const validator = isRequired ? [validateRequired] : [];

 // console.log('WizardSelectField props', props);
 const style = {};
 if (!props.fullWidth) {
 style.width = fieldLabel.length > 27 ? (fieldLabel.length * 8.2) : 180;
 }

 return (
 <div>
 <FormControl variant="outlined">
 <InputLabel style={{ zIndex: 0 }} htmlFor="select-field-placeholder">{fieldLabel}</InputLabel>
 <Field
 component={Select}
 name={name}
 // label={fieldLabel}
 validate={validator}
 // placeholder={fieldLabel}
 inputProps={{
 id: 'select-field-placeholder',
 }}
 style={style}
 >
 {
 enums.map(({ label, value, isDeprecated }) => {
 if (!isDeprecated) {
 return (<MenuItem key={`${value}-field`} value={value}>{label}</MenuItem>);
 } else {
 return null;
 }
 })
 }
 </Field>
 <FormHelperText style={{ margin: '8px 0 0' }}>{hintText}</FormHelperText>
 </FormControl>
 </div>
 );

 }

 WizardSelectField.propTypes = {
 enums:      PropTypes.arrayOf(PropTypes.object),
 name:       PropTypes.string.isRequired,
 label:      PropTypes.string,
 hintText:   PropTypes.string,
 isRequired: PropTypes.bool,
 fullWidth:  PropTypes.bool,
 };

 WizardSelectField.defaultProps = {
 enums:      [],
 label:      '',
 hintText:   'Please Select',
 isRequired: true,
 fullWidth:  false,
 };
 */
