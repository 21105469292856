import { SHARED_FILE_MIME_TYPE } from '../../../../app/consts';

export enum UploadMarketingPlanDialogTabs {
  GoogleSlides = 'GoogleSlides',
  FileStack = 'FileStack',
}

export const googleSlideUrlRegex = /^https?:\/\/docs\.google\.com\/presentation\/d\/\S+$/;

export const googleSlideUrlMimeType = SHARED_FILE_MIME_TYPE.GOOGLE_SLIDES; // 'application/vnd.google-apps.presentation';
