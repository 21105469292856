import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { DatePicker as Picker } from '@material-ui/pickers';

import { colors } from '../../../../app/theme';
import { TextDisplay } from '../../../display';

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    const initial = props.input?.value || props.defaultDate || props.minDate || new Date();
    const date = moment(initial);

    this.state = {
      date,
    };
  }

  componentDidUpdate(prevProps) {
    const prevValue = get(prevProps, 'input.value');
    const currValue = get(this.props, 'input.value');

    if (moment(currValue).format('YYYY-MM-DD') !== moment(prevValue).format('YYYY-MM-DD')) {
      this.setDate(currValue);
    }
  }

  setDate = (date) => {
    this.setState({ date: moment(date) });
  };

  handleOnChange = (date) => {
    // convert back to date object
    const value = moment(date, true).toDate();
    if (this.props.input) {
      this.props.input.onChange(value);
    }
    this.setDate(date);
  };

  render() {
    // console.log('date picker props', this.props);
    // console.log('date picker state', this.state);

    const { openTo, views, label, helperText, disableFuture, minDate, maxDate, dateFormat, meta, ...rest } = this.props;
    const showError = !!meta.error && meta.touched;

    return (
      <React.Fragment>
        <Picker
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          inputVariant="outlined"
          views={views}
          label={label}
          format={dateFormat}
          value={this.state.date}
          helperText={helperText}
          onChange={this.handleOnChange}
          disableFuture={disableFuture}
          openTo={openTo}
          {...rest}
        />
        {showError && (
          <TextDisplay variant="caption" colorOverride={colors.error}>
            {meta.error}
          </TextDisplay>
        )}
      </React.Fragment>
    );
  }
}

DatePicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.object,
  views: PropTypes.arrayOf(PropTypes.oneOf(['date', 'month', 'year'])),
  openTo: PropTypes.oneOf(['date', 'month', 'year']),
  label: PropTypes.string,
  helperText: PropTypes.string,
  dateFormat: PropTypes.string,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  defaultDate: PropTypes.any,
};

DatePicker.defaultProps = {
  label: 'Pick a date',
  helperText: '',
  views: ['year', 'month'],
  openTo: 'year',
  required: false,
  disableFuture: false,
  minDate: null,
  maxDate: null,
  meta: {},
  dateFormat: null,
  defaultDate: null,
  input: {
    value: null,
    onChange: () => {},
  },
};
