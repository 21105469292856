import React, { FC, useMemo } from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import { AdditionalService, AdditionalServicePackagesProfile, NonPpcMarketingServiceType } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';

import { getServiceMetadata } from './logic';

import { MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS } from '../../../../../fe_common/client/app/enums';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import RichTextEditor from '../../../../../fe_common/client/components/common/RichTextEditor';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';

interface ServicesEditorProps {
  meta: WrappedFieldMetaProps;
  services: AdditionalService[];
  packages: AdditionalServicePackagesProfile[];
  onServiceNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onServiceCostChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onServiceRecurringChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGeneralTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (s: string, u: string) => void;
  onDeleteService: (s: string) => void;
}

const ServicesEditor: FC<ServicesEditorProps> = ({
  meta,
  services,
  packages,
  onServiceNameChange,
  onServiceCostChange,
  onServiceRecurringChange,
  onGeneralTextChange,
  onDescriptionChange,
  onDeleteService,
}) => {
  //
  const servicesArr = useMemo(() => Object.values(services) || [], [services]);

  // console.log('servicesArr', servicesArr);

  return (
    <div>
      {servicesArr.length === 0 && <CalloutMessage message="No packages added yet" type="warning" margin />}

      {servicesArr.length > 0 && (
        <div>
          {servicesArr.map((service) => {
            const {
              uuid,
              alternativeName,
              serviceType,
              flavor,
              description,
              variableFactorTitle,
              variableFactorUnit,
              variableFactorCount,
              cost,
              currency,
              recurring,
            } = service;

            // console.log('service: ', service);

            const {
              isDefined: allowVFEdit,
              packageName,
              isDeprecated,
            } = getServiceMetadata(serviceType, flavor, packages);

            return (
              <div key={`AdditionalServicesField-${uuid}`}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {meta.error && meta.error[uuid] && <CalloutMessage message={meta.error[uuid]} type="error" />}
                    <TextDisplay variant="h5" bold>
                      {packageName || flavor}
                    </TextDisplay>
                    <TextDisplay variant="body1" gutterBottom>
                      <strong>{'Service: '}</strong>
                      {/* @ts-ignore */}
                      {MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS[serviceType] || serviceType}
                    </TextDisplay>
                  </Grid>
                  <Grid item>
                    {allowVFEdit && (
                      <Button label="package features (SOON)" color="primary" variant="outlined" disabled />
                    )}
                    {!allowVFEdit && <TextDisplay color="error">No package defined</TextDisplay>}
                  </Grid>
                </Grid>

                {isDeprecated && (
                  <div>
                    <CalloutMessage
                      message={`This service uses a deprecated flavor/package. This means that the customer will not
                          be able to see a full features description in the app package page. consider removing this
                          service and add a new ${serviceType} service with a defined package`}
                      type="warning"
                    />
                  </div>
                )}

                <Grid container alignItems="center" spacing={2}>
                  {serviceType === NonPpcMarketingServiceType.CUSTOM && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={uuid}
                        label="Name"
                        value={alternativeName}
                        onChange={onServiceNameChange}
                        type="text"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextField
                      name={uuid}
                      label="Cost"
                      value={cost}
                      onChange={onServiceCostChange}
                      type="number"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Switch checked={recurring} onChange={onServiceRecurringChange} name={uuid} color="primary" />
                      }
                      label="Recurring"
                    />
                  </Grid>
                </Grid>

                {allowVFEdit && (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      <TextDisplay bold style={{ marginBottom: -16 }}>
                        Variable factor
                      </TextDisplay>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={`${uuid}|variableFactorTitle`}
                        label="Title"
                        value={variableFactorTitle}
                        onChange={onGeneralTextChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={`${uuid}|variableFactorCount`}
                        label="Count"
                        value={variableFactorCount}
                        onChange={onGeneralTextChange}
                        type="number"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={`${uuid}|variableFactorUnit`}
                        label="Unit"
                        value={variableFactorUnit}
                        onChange={onGeneralTextChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextDisplay gutterBottom bold>
                    Description
                  </TextDisplay>
                  <RichTextEditor
                    input={{
                      value: description,
                      onChange: (val: any) => {
                        onDescriptionChange(val, uuid);
                      },
                    }}
                  />
                </Grid>

                {!allowVFEdit && (
                  <TextDisplay color="error">
                    No need to edit variable factors and description for non defined services
                  </TextDisplay>
                )}

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      label="Remove package"
                      onClick={() => {
                        onDeleteService(uuid);
                      }}
                      startIcon={<DeleteIcon />}
                      color="secondary"
                    />
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: 64, marginBottom: 64 }} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ServicesEditor;
