import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() => ({
  root: {},
  dialogTitle: {
    borderBottom: `1px solid ${colors.gray}`,
    boxShadow: `0px 2px 5px ${colors.blackOpacity(0.1)}`,
  },
  dialogContent: {
    paddingTop: '2rem',
  },
  dialogActions: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
}));

export default useStyles;
