import {
  InternalTeam,
  InternalTeamCreateInput,
  InternalTeamType,
  InternalTeamUpdateInput,
  ProjectSpecialType,
} from '@mayple/types';
import { LabelValueProps } from '../../../../../fe_common/client/app/types';

// eslint-disable-next-line no-shadow
export enum InternalTeamEditorModes {
  Edit = 'Edit',
  Create = 'Create',
}

export type InternalTeamEditorMode = InternalTeamEditorModes.Edit | InternalTeamEditorModes.Create;

export interface InternalTeamEditorDialogProps {
  isOpen?: boolean;
  internalTeam?: InternalTeam;
  onClose: () => void;
  onSuccess?: () => void;
}

export type InternalTeamInputs = Partial<
  (InternalTeamCreateInput | InternalTeamUpdateInput) & { internalTeamId?: number | null }
>;

export interface InternalTeamFormData {
  internalTeamId: number | null;
  name: string | undefined;
  description: string | undefined;
  internalTeamType: InternalTeamType | undefined;
  displayImageUrl: string | undefined;
  meetYouExpertCalendlyLink: string | undefined;
  maypleSlackMemberId: string | undefined;
  maypleSlackAllowTag: boolean;
  workingGroupOnProjectTypes: LabelValueProps<ProjectSpecialType>[];
}
