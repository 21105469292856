import {
  AdditionalPackageProfile,
  AdditionalServicePackagesProfile,
  MarketingServiceType,
  NonPpcMarketingServiceType,
  PpcMarketingServiceType,
} from '@mayple/types';

import { ExtendedAdditionalPackageProfile, PackagesFilter, SelectedPackagesKey, SelectionRulesOverride } from './types';

export const getFilteredPackages = (
  packages: AdditionalServicePackagesProfile[],
  filters: PackagesFilter = {}
): AdditionalServicePackagesProfile[] =>
  (packages || []).filter((obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in filters) {
      // @ts-ignore
      if (filters[key].length > 0 && !filters[key].includes(obj[key])) {
        return false;
      }
    }
    return true;
  });

export const getFilteredPackagesByServiceType = (
  packages: AdditionalServicePackagesProfile[],
  filters: PackagesFilter = {}
): AdditionalServicePackagesProfile[] =>
  (packages || []).filter(
    (obj) =>
      !(
        filters.serviceType &&
        filters.serviceType.length > 0 &&
        !filters.serviceType.includes(obj.serviceType as PpcMarketingServiceType | NonPpcMarketingServiceType)
      )
  );

const getOfferingFromName = (name: string | undefined): string => {
  if (!name) {
    return '';
  }

  if (/ - /g.test(name)) {
    return name?.split(' - ')?.[0];
  }
  if (/: /g.test(name)) {
    return name?.split(': ')?.[0];
  }
  return name;
};

const offeringWeights: Record<string, number> = {
  'Audience Management': 10,
  'Automation & Journeys': 20,
  'Email Best Practices': 30,
  Reporting: 40,
  Integrations: 50,
};

export const getExtendedAdditionalPackageProfiles = (
  additionalServicePackagesProfiles: AdditionalServicePackagesProfile[],
  filters: PackagesFilter = {}
): ExtendedAdditionalPackageProfile[] => {
  let extendedAdditionalPackageProfiles: ExtendedAdditionalPackageProfile[] = [];

  additionalServicePackagesProfiles.forEach((additionalServicePackagesProfile) => {
    if (!additionalServicePackagesProfile?.packages) {
      return;
    }

    const additionalPackageProfilesPerService: AdditionalPackageProfile[] =
      (additionalServicePackagesProfile?.packages as AdditionalPackageProfile[]) || ([] as AdditionalPackageProfile[]);

    const extendedAdditionalPackageProfilesPerService = additionalPackageProfilesPerService
      .map((additionalPackageProfile) => {
        const offering: string =
          getOfferingFromName(additionalPackageProfile?.packageKey?.offering) ||
          getOfferingFromName(additionalPackageProfile.name || '');

        const offeringWeight = offeringWeights?.[offering] ?? 100;

        const extendedAdditionalPackageProfile: ExtendedAdditionalPackageProfile = {
          category: additionalPackageProfile?.packageKey?.category || '',
          chapter: additionalPackageProfile?.packageKey?.chapter || '',
          offering: offering || '',
          variant: additionalPackageProfile?.packageKey?.variant || '',
          version: additionalPackageProfile?.packageKey?.version || '',
          ...additionalPackageProfile,
          serviceType:
            (additionalServicePackagesProfile.serviceType as MarketingServiceType) || MarketingServiceType.OTHER,
          offeringWeight,
        };

        return extendedAdditionalPackageProfile;
      })
      .filter(
        (extendedAdditionalPackagesProfile: ExtendedAdditionalPackageProfile) =>
          extendedAdditionalPackagesProfile != null
      )
      .filter((extendedAdditionalPackagesProfile: ExtendedAdditionalPackageProfile) => {
        if (filters.flavor && filters.flavor.length > 0) {
          if (
            filters.flavor &&
            extendedAdditionalPackagesProfile?.flavor &&
            !filters.flavor.includes(extendedAdditionalPackagesProfile.flavor as string)
          ) {
            return false;
          }
        }

        return true;
      });

    extendedAdditionalPackageProfiles = [
      ...extendedAdditionalPackageProfiles,
      ...extendedAdditionalPackageProfilesPerService,
    ];
  });

  return extendedAdditionalPackageProfiles;
};

export const sortByChapterAndPrice = (
  a: ExtendedAdditionalPackageProfile,
  b: ExtendedAdditionalPackageProfile
): number => {
  if (a.offeringWeight === b.offeringWeight) {
    // Price is only important when chapters are the same
    return (a.cost || 0) >= (b.cost || 0) ? 1 : -1;
  }

  return (a.offeringWeight || a.offering || '') > (b.offeringWeight || b.offering || '') ? 1 : -1;
};

export const checkIsSelected = (
  selectedPackages: SelectedPackagesKey[],
  needlePackage: SelectedPackagesKey
): boolean => {
  const foundItem = (selectedPackages || []).find(
    (currPackage) =>
      needlePackage.flavor === currPackage.flavor &&
      needlePackage.serviceType === currPackage.serviceType &&
      (currPackage?.offering ? needlePackage.offering === currPackage.offering : true)
  );

  return !!foundItem;
};

export const selectedByOffering = (
  selectedPackages: SelectedPackagesKey[],
  needlePackage: SelectedPackagesKey
): number => {
  const packagesFromOffering = (selectedPackages || []).filter(
    (item) => item.serviceType === needlePackage.serviceType && item.offering === needlePackage.offering
  );

  return (packagesFromOffering || []).length;
};

export const getMaxSelectionRulesOverridePerChapter = (
  maxSelectionRulesOverride: SelectionRulesOverride[] | undefined,
  extendedPackage: ExtendedAdditionalPackageProfile,
  defaultValue = -1
): number => {
  if (!maxSelectionRulesOverride || !maxSelectionRulesOverride?.length) {
    return defaultValue;
  }

  const selectionRuleOverride = maxSelectionRulesOverride.find(
    ({ offering }) => offering === extendedPackage?.offering
  );
  return selectionRuleOverride?.maxSelectedPackagesFromChapterOverride ?? defaultValue;
};

export const getOfferingClassname = (classes: Record<string, string>, offeringWeight = 100): string => {
  const value = (offeringWeight / 10) % 5;
  const offeringClassNames = [
    classes.offering1,
    classes.offering2,
    classes.offering3,
    classes.offering4,
    classes.offering5,
  ];

  return offeringClassNames?.[value] ?? '';
};
