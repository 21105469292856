import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { colors } from '../../../../app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      flex: '0 1 auto',
    },
    smallAvatar: {
      width: 64,
      height: 64,
    },
    picker: {
      border: `1px dashed ${colors.primary}`,
      borderRadius: 8,
      width: 300,
      height: 300,
      marginBottom: 16,
      position: 'relative',
      textAlign: 'center',
      backgroundPosition: 'center',
    },
    pickerButton: {
      bottom: -278,
    },
  })
);

export default useStyles;
