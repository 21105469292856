import { useCallback, useEffect, useState } from 'react';
import { AccountType, ProjectStage } from '@mayple/types';
import { useTranslation } from 'react-i18next';
import { ApproveMarketingPlanByGrowthStrategistMutation } from 'growl-graphql/dist/mutations/ApproveMarketingPlanByGrowthStrategistMutation';

import { useStepperLogic } from '../../logic';
import { useMarketingPlanState } from '../../../../MarketingPlanProvider';
import { useMutation } from '../../../../../../hooks';
import { checkMarketingPlanIsApprovedByGS } from '../../../logic';
import { TRANSLATION_NS } from '../../../consts';

type UseReviewByGrowthStrategistStepReturnValues = {
  accountType: AccountType | undefined;
  marketingPlanApproved: boolean;
  marketingPlanUploaded: boolean;
  mainGrowthStrategistName: string;
  projectInMarketingPlanStage: boolean;
  onApproveMarketingPlanClickHandler: () => void;
};

const useReviewByGrowthStrategistStep = (): UseReviewByGrowthStrategistStepReturnValues => {
  const { accountType, project, mainGrowthStrategist, eventHandlers, marketingPlanFile, marketingPlanStatus } =
    useMarketingPlanState();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper.ErrorMessages',
  });

  const { onSuccessfulDataRefresh } = useStepperLogic();

  const projectId = project?.id;
  const projectInMarketingPlanStage = project?.stage === ProjectStage.MARKETING_PLAN;
  const { name: mainGrowthStrategistName = '' } = mainGrowthStrategist || {};
  const { onError, onApproveMarketingPlanByGS } = eventHandlers;

  const { mutate: approveMarketingPlanByGrowthStrategist } = useMutation(
    ApproveMarketingPlanByGrowthStrategistMutation
  );

  const marketingPlanUploaded = (marketingPlanFile || []).length > 0;

  const [marketingPlanApproved, setMarketingPlanApproved] = useState<boolean>(() =>
    checkMarketingPlanIsApprovedByGS(marketingPlanStatus)
  );

  const onErrorHandler = useCallback(
    (errorMessage: string): void => {
      if (onError && typeof onError === 'function') {
        onError(errorMessage);
      }
    },
    [onError]
  );

  useEffect(() => {
    setMarketingPlanApproved(checkMarketingPlanIsApprovedByGS(marketingPlanStatus));
  }, [marketingPlanStatus]);

  const approveMarketingPlan = useCallback(async () => {
    try {
      const variables = {
        projectId,
      };

      const result = await approveMarketingPlanByGrowthStrategist({ variables });
      if (result?.data?.approveMarketingPlanByGrowthStrategist?.success === false) {
        onErrorHandler(result?.data?.approveMarketingPlanByGrowthStrategist?.result);
      }

      if (onApproveMarketingPlanByGS) {
        onApproveMarketingPlanByGS().then(onSuccessfulDataRefresh);
      }
    } catch (e) {
      onErrorHandler(t('errorMessageApproveMarketingPlan'));
    }
  }, [
    approveMarketingPlanByGrowthStrategist,
    onApproveMarketingPlanByGS,
    onErrorHandler,
    onSuccessfulDataRefresh,
    projectId,
    t,
  ]);

  const onApproveMarketingPlanClickHandler = (): void => {
    approveMarketingPlan();
  };

  return {
    accountType,
    marketingPlanApproved,
    marketingPlanUploaded,
    mainGrowthStrategistName,
    projectInMarketingPlanStage,
    onApproveMarketingPlanClickHandler,
  };
};

export default useReviewByGrowthStrategistStep;
