import React, { useState } from 'react';

const useControlledAccordion = (
  onSuccess?: () => void,
): {
  expandAccordion: boolean;
  setExpandAccordion: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  accordionOnChangeHandler: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onSuccessHandler: () => void;
} => {
  const [expandAccordion, setExpandAccordion] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-unused-vars
  const accordionOnChangeHandler = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandAccordion(isExpanded);
  };

  const onSuccessHandler = () => {
    setExpandAccordion(false);
    onSuccess?.();
  };

  return {
    expandAccordion,
    setExpandAccordion,
    accordionOnChangeHandler,
    onSuccessHandler,
  };
};

export default useControlledAccordion;
