import { useDialog } from '../../../hooks';
import { DialogState } from '../../../hooks/useDialog';

const useProjectPackageOverrideDialog = (): DialogState => {
  const { open, openDialog, closeDialog } = useDialog();

  return {
    open,
    openDialog,
    closeDialog,
  };
};

export default useProjectPackageOverrideDialog;
