import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      userSelect: 'none',
      width: '100%',
    },
    captions: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '0.5rem',
      '& > div': {
        display: 'block',
        width: '100%',
        flexGrow: 1,
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      width: '100%',
    },
    cardContainer: {
      border: '1px solid #dee2e3',
      flexGrow: 1,
      width: '100%',
    },
    labelContainer: {
      width: '100%',
    },
    cardInput: {
      padding: '0.5rem 0',
      textAlign: 'center',
      color: colors.black_lighter,
      boxSizing: 'border-box',
      transition: 'color 200ms ease-in-out, background-color 200ms ease-in-out',

      '&:hover': {
        cursor: 'pointer',
        color: colors.black,
        backgroundColor: getGradientColor('gray', 30),
        transition: 'color 200ms ease-in-out, background-color 200ms ease-in-out',
      },
    },
    cardInputElement: {
      display: 'none',
      '&:checked ~ $cardInput': {
        color: '#fff',
        backgroundColor: '#534ff2',
        transition: 'color 200ms ease-in-out, background-color 200ms ease-in-out',
      },
    },
  }),
);

export default useStyles;
