import React, { FC } from 'react';
import Link from '@material-ui/core/Link';

import { LinkToProjectLeadProps } from './types';
import { getProjectLeadPagePath } from '../../../../../pages/consts';

const LinkToProjectLead: FC<LinkToProjectLeadProps> = (props) => {
  const { projectLeadId, openInNewTab = false, children } = props;
  const target = openInNewTab ? '_blank' : '_self';

  return (
    <Link href={getProjectLeadPagePath(projectLeadId)} target={target}>
      {children}
    </Link>
  );
};

export default LinkToProjectLead;
