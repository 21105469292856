import { useCallback, useMemo } from 'react';
import { CronofyAvailabilityProfiles, CronofyElementToken } from '@mayple/types';
import moment from 'moment';

import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../AvailabilityViewerProvider/AvailabilityViewerContext';
import { Constants, defaultStyles } from '../constants';
import { AVAILABILITY_MANAGEMENT } from '../types';
import { Account, AccountWithCronofyIntegration } from '../../types';
import {
  AvailabilityRulesHandler,
  CallbackEventType,
  CallbackEventTypes,
  CronofyAvailabilityQuery,
  CronofyAvailabilityViewerOptions,
  CronofyMeetingDuration,
  CronofyMember,
  CronofyParticipant,
  AvailabilityViewerCallbackObject,
  ValidTime,
} from '../../types/cronofyTypes';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../AvailabilityViewerProvider/availabilityViewerReducer';

const MAX_NUMBER_OF_MEMBERS = 10;

const getAvailabilityRuleIds = (
  availabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined,
  selectedAvailabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined,
): string[] | null | undefined => {
  // console.log('availabilityProfiles', availabilityProfiles);
  // console.log('selectedAvailabilityProfiles', selectedAvailabilityProfiles);

  if (!availabilityProfiles) {
    return null;
  }

  if (!selectedAvailabilityProfiles) {
    return [];
  }

  const validSelectedAvailabilityProfiles: CronofyAvailabilityProfiles[] = [];
  selectedAvailabilityProfiles.forEach((selectedProfile) => {
    if ((availabilityProfiles || []).includes(selectedProfile)) {
      validSelectedAvailabilityProfiles.push(selectedProfile);
    }
  });

  return validSelectedAvailabilityProfiles;
};

const getParticipantMembers = (
  accounts: Account[] | null | undefined,
  accountsWithIntegration: AccountWithCronofyIntegration[] | undefined,
  selectedAccounts: string[],
  managedAvailability: boolean | undefined,
  selectedAvailabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined,
  onlyShowAvailability: boolean,
): CronofyMember[] => {
  const mainParticipantMembers: CronofyMember[] = [];

  (accounts || ([] as Account[])).forEach((account) => {
    const { id, accountType } = account;
    const accountWithIntegration = (accountsWithIntegration || []).find(
      (awi) => awi.id === id && awi.accountType === accountType,
    );

    if (!accountWithIntegration || !accountWithIntegration?.sub) {
      return;
    }

    if (!selectedAccounts.includes(accountWithIntegration.sub)) {
      return;
    }

    const cronofyMember: CronofyMember = {
      sub: accountWithIntegration.sub,
      managed_availability: accountWithIntegration?.managedAvailability,
    };

    if (managedAvailability) {
      cronofyMember.availability_rule_ids = getAvailabilityRuleIds(
        accountWithIntegration?.availabilityProfiles,
        selectedAvailabilityProfiles,
      );
    }

    if (onlyShowAvailability) {
      // using an empty array here will ignore all the calendars that are set on the availability rule
      // This will show the user availability rules as they were set, ignoring all the meetings
      cronofyMember.calendar_ids = [];
    }

    mainParticipantMembers.push(cronofyMember);
  });

  return mainParticipantMembers;
};

const getAvailabilityQueryPeriods = (startDate: Date, endDate: Date) => [
  {
    start: moment.utc(startDate).toISOString(),
    end: moment.utc(endDate).toISOString(),
  },
];

const getAvailabilityQuery = (
  startDate: Date,
  endDate: Date,
  mainAccounts: Account[] | undefined,
  secondaryAccounts: Account[] | null | undefined,
  accountsWithIntegration: AccountWithCronofyIntegration[] | undefined,
  selectedAccounts: string[],
  managedAvailability = false,
  selectedAvailabilityProfiles: CronofyAvailabilityProfiles[] | null | undefined = undefined,
  onlyShowAvailability = false,
): CronofyAvailabilityQuery => {
  const queryPeriods = getAvailabilityQueryPeriods(startDate, endDate);

  const participants: CronofyParticipant[] = [];

  const mainParticipant: CronofyParticipant = {
    required: 1,
    members: getParticipantMembers(
      mainAccounts,
      accountsWithIntegration,
      selectedAccounts,
      managedAvailability,
      selectedAvailabilityProfiles,
      onlyShowAvailability,
    ),
  };

  const secondaryParticipant: CronofyParticipant = {
    required: 1,
    members: getParticipantMembers(
      secondaryAccounts,
      accountsWithIntegration,
      selectedAccounts,
      managedAvailability,
      selectedAvailabilityProfiles,
      onlyShowAvailability,
    ),
  };

  if (mainParticipant?.members?.length > 0) {
    participants.push(mainParticipant);
  }

  if (secondaryParticipant?.members?.length > 0) {
    participants.push(secondaryParticipant);
  }

  return {
    participants,
    required_duration: { minutes: 30 },
    query_periods: queryPeriods,
    response_format: 'overlapping_slots',
    start_interval: {
      minutes: 30,
    },
  };
};

const useAvailabilityViewerConfiguration = (
  elementToken: CronofyElementToken,
  onAvailabilityRuleNotFound?: AvailabilityRulesHandler,
  onAvailabilityViewerSlotSelected?: AvailabilityRulesHandler,
): CronofyAvailabilityViewerOptions | undefined => {
  const { initOptions: options, filterData, mainAccountsGroup, accountsWithIntegration } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  // console.log('membersById', membersById);
  // console.log('accountsWithIntegration', accountsWithIntegration);

  const defaultCallback = useCallback(
    (callbackObject: AvailabilityViewerCallbackObject) => {
      const actionType: CallbackEventType = callbackObject.notification.type;

      switch (actionType) {
        case CallbackEventTypes.availabilityRuleNotFound:
          onAvailabilityRuleNotFound?.(callbackObject);
          break;
        case CallbackEventTypes.slotSelected:
          {
            const action: AvailabilityViewerAction = {
              type: AvailabilityViewerActionType.SLOT_SELECTED,
              payload: callbackObject,
            };

            dispatch(action);
            onAvailabilityViewerSlotSelected?.(callbackObject);
          }
          break;
        default:
          // console.log('callbackObject', callbackObject);
          break;
      }
    },
    [dispatch, onAvailabilityRuleNotFound, onAvailabilityViewerSlotSelected],
  );

  const cronofyAvailabilityViewerOptions: CronofyAvailabilityViewerOptions | undefined = useMemo(() => {
    const token = elementToken?.token || '';
    const isDemo = options?.demo === true;
    const hasIntegrations =
      (accountsWithIntegration?.length || false) &&
      (accountsWithIntegration || [])?.some(({ hasActiveIntegration }) => hasActiveIntegration);

    // at this point we need to validate we have a valid token and that we are not in demo mode, and that there are accounts
    if ((!token && !isDemo) || !hasIntegrations) {
      return undefined;
    }

    const elementTargetId = options?.elementTargetId || Constants.DEFAULT_ELEMENT_TARGET_ID;
    const styles = options?.styles || defaultStyles;
    const callback = options?.callback || defaultCallback;

    const startTime = (options?.config?.startTime || Constants.DEFAULT_WORKING_DAY_START_TIME) as ValidTime;
    const endTime = (options?.config?.endTime || Constants.DEFAULT_WORKING_DAY_END_TIME) as ValidTime;
    const weekStartDay = options?.config?.weekStartDay || Constants.DEFAULT_WEEK_START_DAY;
    const mode = options?.config?.mode || Constants.DEFAULT_MODE;
    const interval = (options?.config?.interval || Constants.DEFAULT_INTERVAL) as CronofyMeetingDuration;
    const maxSelectionCount = options?.config?.maxSelectionCount || Constants.DEFAULT_MAX_SELECTION_COUNT;
    const boundsControl = options?.config?.boundsControl || Constants.DEFAULT_BOUNDS_CONTROL;
    const allowExpansion = options?.config?.allowExpansion || Constants.DEFAULT_ALLOW_EXPANSION;
    const slotSelection = options?.config?.slotSelection || Constants.DEFAULT_SLOT_SELECTION;

    const managedAvailability =
      (filterData?.availabilityManagement
        ? filterData?.availabilityManagement === AVAILABILITY_MANAGEMENT.USE_MANAGED_AVAILABILITY
        : undefined) ??
      options?.config?.managedAvailability ??
      Constants.DEFAULT_MANAGED_AVAILABILITY;

    const selectedAccounts = filterData?.selectedAccounts || [];

    const timeZone =
      filterData?.timezone || options?.config?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    const selectedAvailabilityProfiles = filterData?.availabilityProfiles || [CronofyAvailabilityProfiles.work_hours];

    const onlyShowAvailability = filterData?.onlyShowAvailability || false;

    return {
      element_token: token,
      availability_query: getAvailabilityQuery(
        moment.utc().add(1, 'hour').toDate(),
        moment.utc().add(1, 'month').toDate(),
        mainAccountsGroup.accounts,
        filterData?.accounts,
        accountsWithIntegration,
        selectedAccounts,
        managedAvailability,
        selectedAvailabilityProfiles,
        onlyShowAvailability,
      ),
      target_id: elementTargetId,
      tzid: timeZone,
      config: {
        week_start_day: weekStartDay,
        start_time: startTime,
        end_time: endTime,
        mode,
        interval,
        max_selection_count: maxSelectionCount,
        bounds_control: boundsControl,
        allow_expansion: allowExpansion,
        slot_selection: slotSelection,
      },
      styles,
      demo: isDemo,
      callback,
    };
  }, [
    elementToken?.token,
    options?.demo,
    options?.elementTargetId,
    options?.styles,
    options?.callback,
    options?.config?.startTime,
    options?.config?.endTime,
    options?.config?.weekStartDay,
    options?.config?.mode,
    options?.config?.interval,
    options?.config?.maxSelectionCount,
    options?.config?.boundsControl,
    options?.config?.allowExpansion,
    options?.config?.slotSelection,
    options?.config?.managedAvailability,
    options?.config?.timeZone,
    accountsWithIntegration,
    defaultCallback,
    filterData?.availabilityManagement,
    filterData?.selectedAccounts,
    filterData?.timezone,
    filterData?.availabilityProfiles,
    filterData?.onlyShowAvailability,
    filterData?.accounts,
    mainAccountsGroup.accounts,
  ]);

  return cronofyAvailabilityViewerOptions;
};

export enum CronofyAvailabilityViewerOptionsValidationError {
  ELEMENT_TOKEN_IS_REQUIRED = 1000,
  NUMBER_OF_PARTICIPANTS_MUST_BE_LARGER_THAN_ZERO,
  NUMBER_OF_PARTICIPANTS_MUST_BE_LESS_THAN_OR_EQUAL_TO_TEN,
}

export const validateCronofyAvailabilityViewerOptions = (
  cronofyAvailabilityViewerOptions: CronofyAvailabilityViewerOptions | undefined,
): boolean | CronofyAvailabilityViewerOptionsValidationError => {
  if (!cronofyAvailabilityViewerOptions?.demo && !cronofyAvailabilityViewerOptions?.element_token) {
    return CronofyAvailabilityViewerOptionsValidationError.ELEMENT_TOKEN_IS_REQUIRED;
  }

  if (cronofyAvailabilityViewerOptions?.availability_query?.participants?.length === 0) {
    return CronofyAvailabilityViewerOptionsValidationError.NUMBER_OF_PARTICIPANTS_MUST_BE_LARGER_THAN_ZERO;
  }

  if (
    !!cronofyAvailabilityViewerOptions?.availability_query?.participants?.length &&
    cronofyAvailabilityViewerOptions?.availability_query?.participants?.length > 0
  ) {
    let numOfMembers = 0;
    cronofyAvailabilityViewerOptions?.availability_query?.participants.forEach((participant) => {
      numOfMembers += participant.members?.length ?? 0;
    });

    if (numOfMembers > MAX_NUMBER_OF_MEMBERS) {
      return CronofyAvailabilityViewerOptionsValidationError.NUMBER_OF_PARTICIPANTS_MUST_BE_LESS_THAN_OR_EQUAL_TO_TEN;
    }
  }

  return true;
};

export const getValidationErrorMessage = (validationError: CronofyAvailabilityViewerOptionsValidationError): string => {
  switch (validationError) {
    case CronofyAvailabilityViewerOptionsValidationError.NUMBER_OF_PARTICIPANTS_MUST_BE_LESS_THAN_OR_EQUAL_TO_TEN:
      return 'Number of participants must be less than or equal to ten';
    case CronofyAvailabilityViewerOptionsValidationError.NUMBER_OF_PARTICIPANTS_MUST_BE_LARGER_THAN_ZERO:
      return 'Please select at least 1 account from the list.';
    case CronofyAvailabilityViewerOptionsValidationError.ELEMENT_TOKEN_IS_REQUIRED:
      return 'Missing element token.';
    default:
      return 'Unknown error';
  }
};

export default useAvailabilityViewerConfiguration;
