import React, { FC, useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { GenerateInviteUserTokenProps } from './types';
import { getCreateEntityUserInvitationTokenParams } from './logic';
import getUserActionTokenUrl from '../../../../../logic/getUserActionTokenUrl';

import useMutation from '../../../../../../fe_common/client/hooks/useMutation';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import CopyToClipboardButton from '../../../../../../fe_common/client/components/atoms/CopyToClipboardButton';

import useStyles from './style';

const GenerateInviteUserToken: FC<GenerateInviteUserTokenProps> = (props) => {
  const classes = useStyles(props);
  const { entityType, entityId } = props;
  const [invitationLink, setInvitationLink] = useState<string | null>(null);
  const [showRegenerateLinkToNewUser, setShowRegenerateLinkToNewUser] = useState(false);

  const { query, entityIdKey, asEntityParam } = getCreateEntityUserInvitationTokenParams(entityType);
  const { mutate } = useMutation(query, { successMessage: '' });

  const handleGenerateLinkOnClick = useCallback(async () => {
    const vars = {
      variables: {
        [entityIdKey]: entityId,
      },
    };

    const result = await mutate(vars);

    const entityInvitationToken = result?.data[query.name];

    const tokenUrl = getUserActionTokenUrl(entityInvitationToken, { register: true, asEntity: asEntityParam });
    setInvitationLink(tokenUrl);
  }, [asEntityParam, entityId, entityIdKey, mutate, query.name]);

  const handleOnAfterValueCopied = () => {
    setInvitationLink(null);
    setShowRegenerateLinkToNewUser(true);
  };

  return (
    <div className={classes.root}>
      <Button
        label={showRegenerateLinkToNewUser ? 'Generate another link to a different user' : 'Generate link'}
        onClick={handleGenerateLinkOnClick}
        color="primary"
        variant="outlined"
        disabled={invitationLink !== null}
        className={classes.generateLinkButton}
      />
      {invitationLink && (
        <TextField
          variant="outlined"
          value={invitationLink}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboardButton text={invitationLink} onAfterValueCopied={handleOnAfterValueCopied} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

export default GenerateInviteUserToken;
