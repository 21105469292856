import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { DeactivateCreditCardPaymentAgreementOfCompanyMutation } from 'growl-graphql/dist/mutations/DeactivateCreditCardPaymentAgreementOfCompanyMutation';
import { DeactivatePaypalOpenPaymentAgreementOfCompanyMutation } from 'growl-graphql/dist/mutations/DeactivatePaypalOpenPaymentAgreementOfCompanyMutation';

import PayPalPaymentAgreement from './PayPalPaymentAgreement';
import CreditCardPaymentAgreement from './CreditCardPaymentAgreement';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { clientLogger } from '../../../../../fe_common/client/services/logger';

const ActivePaymentAgreement = (props) => {
  const {
    companyId,
    refetch,
    fundAccount,
    deactivateCreditCardPaymentAgreementOfCompanyMutation,
    deactivatePaypalOpenPaymentAgreementOfCompanyMutation,
  } = props;

  const creditCardPaymentAgreements = fundAccount?.creditCardPaymentAgreements || [];
  const paypalOpenPaymentAgreements = fundAccount?.paypalOpenPaymentAgreements || [];

  const deactivatePaypalAgreement = async (paypalOpenPaymentAgreementId) => {
    const didConfirm = window.confirm(
      "By deactivating this payment agreement, you'll make it unavailable to any future automatic payments. " +
        'To reactivate it, you will need to ask the Company to add the credit card/paypal to the its fund account ' +
        'again. In any case, please make sure to either set a manual payment agreement or have the Company add a ' +
        'credit card payment agreement to the fund account before the next project cycle starts.',
    );

    if (!didConfirm) {
      return;
    }

    try {
      const paymentAgreementIdAns = parseInt(prompt('Please verify the payment agreement id'), 10);

      if (paymentAgreementIdAns !== paypalOpenPaymentAgreementId) {
        window.alert('input was incorrect. Aborting.');
        return;
      }

      const vars = {
        companyId,
        paypalOpenPaymentAgreementId,
      };

      const res = await deactivatePaypalOpenPaymentAgreementOfCompanyMutation.mutate(vars);
      window.alert(res.deactivatePaypalOpenPaymentAgreementOfCompany.result);
    } catch (e) {
      clientLogger.error(e);
      window.alert(e);
    }

    refetch();
  };

  const deactivateBluesnapAgreement = async (creditCardPaymentAgreementId) => {
    const didConfirm = window.confirm(
      "By deactivating this payment agreement, you'll make it unavailable to any future automatic payments. " +
        'To reactivate it, you will need to ask the Company to add the credit card/paypal to the its fund account ' +
        'again. In any case, please make sure to either set a manual payment agreement or have the Company add a ' +
        'credit card payment agreement to the fund account before the next project cycle starts.',
    );

    if (!didConfirm) {
      return;
    }

    try {
      const paymentAgreementIdAns = parseInt(prompt('Please verify the payment agreement id'), 10);

      if (paymentAgreementIdAns !== creditCardPaymentAgreementId) {
        window.alert('input was incorrect. Aborting.');
        return;
      }

      const vars = {
        companyId,
        creditCardPaymentAgreementId,
      };

      const res = await deactivateCreditCardPaymentAgreementOfCompanyMutation.mutate(vars);
      window.alert(res.deactivateCreditCardPaymentAgreementOfCompany.result);
    } catch (e) {
      clientLogger.error(e);
      window.alert(e);
    }

    refetch();
  };

  if (creditCardPaymentAgreements.length === 0 && paypalOpenPaymentAgreements.length === 0) {
    return <CalloutMessage type="info" message="No active Bluesnap/Paypal payment agreement found" />;
  }

  return (
    <div>
      {creditCardPaymentAgreements.length > 0 && (
        <div>
          <div>Credit Card Payment Agreements</div>

          {creditCardPaymentAgreements.map((creditCardPaymentAgreement, index) => {
            if (!creditCardPaymentAgreement.active) {
              return null;
            }

            return (
              <CreditCardPaymentAgreement
                key={`ActivePaymentAgreement-creditCardPaymentAgreement-${index}`}
                currentActiveAgreement={creditCardPaymentAgreement}
                onDeactivatePaymentAgreement={() => deactivateBluesnapAgreement(creditCardPaymentAgreement.id)}
                isLoading={deactivateCreditCardPaymentAgreementOfCompanyMutation.loading}
              />
            );
          })}
        </div>
      )}

      {paypalOpenPaymentAgreements.length > 0 && (
        <div>
          <div>Paypal Open Payment Agreements</div>

          {paypalOpenPaymentAgreements.map((paypalOpenPaymentAgreement, index) => {
            if (!paypalOpenPaymentAgreement.active) {
              return null;
            }

            return (
              <PayPalPaymentAgreement
                key={`ActivePaymentAgreement-paypalOpenPaymentAgreement-${index}`}
                currentActiveAgreement={paypalOpenPaymentAgreement}
                onDeactivatePaymentAgreement={() => deactivatePaypalAgreement(paypalOpenPaymentAgreement.id)}
                isLoading={deactivatePaypalOpenPaymentAgreementOfCompanyMutation.loading}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

ActivePaymentAgreement.propTypes = {
  companyId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  fundAccount: PropTypes.object.isRequired,
  deactivateCreditCardPaymentAgreementOfCompanyMutation: PropTypes.object.isRequired,
  deactivatePaypalOpenPaymentAgreementOfCompanyMutation: PropTypes.object.isRequired,
};

export default compose(
  withMutation(DeactivateCreditCardPaymentAgreementOfCompanyMutation),
  withMutation(DeactivatePaypalOpenPaymentAgreementOfCompanyMutation),
)(ActivePaymentAgreement);
