import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { RepayDebtOfCompanyWithCreditCardChargeMutation } from 'growl-graphql/dist/mutations/RepayDebtOfCompanyWithCreditCardChargeMutation';
import { RepayDebtOfCompanyWithPaypalChargeMutation } from 'growl-graphql/dist/mutations/RepayDebtOfCompanyWithPaypalChargeMutation';

import { getCurrentPaymentMethod } from '../../logic';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { getActivePaymentAgreement } from '../../../../../fe_common/client/services/utils';
import { setEntityOperationResponseNotification } from '../../../../../fe_common/client/services/notification';
import { PAYMENT_METHODS } from '../../../../../fe_common/client/app/consts';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  actions: {
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

/**
 * This will attempt to repay debts from existing balance in the fund account
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const CompanyDebtRepayFromPaymentAgreement = (props) => {
  const classes = useStyles();

  const {
    companyId,
    fundAccount,
    onSuccess,
    repayDebtOfCompanyWithPaypalChargeMutation,
    repayDebtOfCompanyWithCreditCardChargeMutation,
  } = props;

  const { totalDebt, currency, balance } = fundAccount;

  const currentActiveAgreement = getActivePaymentAgreement(fundAccount);

  const [repayDebtFromPaymentAgreementResponse, setRepayDebtFromPaymentAgreementResponse] = useState({});

  // if (!currentActiveAgreement || balance >= totalDebt) {
  //   return null;
  // }

  const handleRepayDebtFromPaymentAgreement = async () => {
    const currentPaymentMethod = getCurrentPaymentMethod(currentActiveAgreement);

    const ans = window.confirm(`You are about to attempt charging of ${totalDebt}${currency} 
              from the company's balance (${balance})
              and payment agreement (${totalDebt - balance}${currency}) 
              to cover all of the total debt.`);

    if (!ans) {
      return;
    }

    if (currentPaymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
      const vars = {
        companyId,
        creditCardPaymentAgreementId: currentActiveAgreement?.id,
      };
      const res = await repayDebtOfCompanyWithCreditCardChargeMutation.mutate(vars);
      setEntityOperationResponseNotification(res?.repayDebtOfCompanyWithCreditCardCharge);
      setRepayDebtFromPaymentAgreementResponse(res?.repayDebtOfCompanyWithCreditCardCharge);

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } else if (currentPaymentMethod === PAYMENT_METHODS.PAYPAL) {
      const vars = {
        companyId,
        paypalOpenPaymentAgreementId: currentActiveAgreement?.id,
      };
      const res = await repayDebtOfCompanyWithPaypalChargeMutation.mutate(vars);
      setEntityOperationResponseNotification(res?.repayDebtOfCompanyWithPaypalCharge);
      setRepayDebtFromPaymentAgreementResponse(res?.repayDebtOfCompanyWithCreditCardCharge);

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  };

  return (
    <div className={classes.root}>
      <TextDisplay>
        This button is enabled only if debts exist and a valid Bluesnap or PayPal payment agreement exists. On clicking
        this, all existing debts are paid in a FIFO order (older first). Success means that all of the debts were paid.
        The debts will be first be paid from the balance, if it is not enough to cover the debt then the CC/PayPal will
        be charged.
      </TextDisplay>
      <p>
        Total debt of {totalDebt} {currency} found.
      </p>
      {repayDebtFromPaymentAgreementResponse?.result && (
        <p className={repayDebtFromPaymentAgreementResponse.success ? 'green-text' : 'red-text'}>
          {repayDebtFromPaymentAgreementResponse?.result}
        </p>
      )}
      <div className={classes.actions}>
        <Button
          color="secondary"
          variant="contained"
          disabled={
            repayDebtOfCompanyWithPaypalChargeMutation.loading ||
            repayDebtOfCompanyWithCreditCardChargeMutation.loading ||
            !(totalDebt > 0 && currentActiveAgreement) ||
            !currentActiveAgreement ||
            balance >= totalDebt ||
            totalDebt === 0
          }
          label={`Repay ${totalDebt} ${currency} (USING PAYMENT AGREEMENT AND BALANCE)`}
          onClick={handleRepayDebtFromPaymentAgreement}
        />
      </div>
    </div>
  );
};

CompanyDebtRepayFromPaymentAgreement.propTypes = {
  companyId: PropTypes.number.isRequired,
  fundAccount: PropTypes.object.isRequired,
  repayDebtOfCompanyWithPaypalChargeMutation: PropTypes.object.isRequired,
  repayDebtOfCompanyWithCreditCardChargeMutation: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

CompanyDebtRepayFromPaymentAgreement.defaultProps = {
  onSuccess: () => {},
};

export default compose(
  withMutation(RepayDebtOfCompanyWithPaypalChargeMutation),
  withMutation(RepayDebtOfCompanyWithCreditCardChargeMutation),
)(CompanyDebtRepayFromPaymentAgreement);
