import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  generateLinkButton: {
    marginBottom: 12,
  },
}));

export default useStyles;
