import React, { useRef } from 'react';

interface DialogState {
  tableRef: React.MutableRefObject<undefined>;
  clearTableSelection: () => void;
}

const useDataTableRef = (): DialogState => {
  const tableRef = useRef();

  const clearTableSelection = () => {
    if (tableRef.current !== undefined) {
      // @ts-ignore
      tableRef.current.onAllSelected(false);
    }
  };

  return {
    tableRef,
    clearTableSelection,
  };
};

export default useDataTableRef;
