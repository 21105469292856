import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconHelp from '@material-ui/icons/Help';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';
import { styleConstants } from '../../../app/style';

import {
  WizardCheckboxField,
  WizardTextField,
  WizardSelectField,
  WizardRadioButtonGroup,
  WizardSwitchField,
  WizardPhoneNumberInput,
  WizardPhoneNumberInput2,
  WizardUrlField,
  WizardImagePickerInput,
  WizardFileUploaderInput,
  WizardDatePickerField,
  WizardRadioCardWithSliderGroupField,
  WizardSliderField,
  WizardMultiCheckboxField,
  WizardRadioCardGroupField,
  WizardUrlListField,
  WizardMultiAutoCompleteRadioButtonsField,
  WizardNumericRangeField,
  WizardCurrencyField,
  WizardCategoriesField,
  WizardRichTextField,
  WizardAutocompleteField,
  WizardTextListField,
} from '../../atoms/WizardFields';
import { WizardNumberField } from '../../atoms/WizardFields/WizardNumberField';
import { WizardFilesUploaderField } from '../../atoms/WizardFields/WizardFilesUploaderField';
import WizardDateTimePickerField from '../../atoms/WizardFields/WizardDateTimePickerField';
import WizardEntityAvatarCheckboxField from '../../atoms/WizardFields/WizardEntityAvatarCheckboxField';
import WizardCheckboxCardGroupField from '../../atoms/WizardFields/WizardCheckboxCardGroupField';
import WizardImagePickerField from '../../atoms/WizardFields/WizardImagePickerField';
import WizardAvailabilitySelectionField from '../../atoms/WizardFields/WizardAvailabilitySelectionField';
import WizardScoreButtonsField from '../../atoms/WizardFields/WizardScoreButtonsField';
import WizardStarsVoteField from '../../atoms/WizardFields/WizardStarsVoteField';
import MultiplePackagesSelectorField from '../package/MultiplePackagesSelectorField';

const useStyles = makeStyles(() => ({
  rowStyle: {
    padding: 0,
    marginBottom: 12,
  },
  headerStyle: {
    padding: 0,
    marginBottom: 12,
  },
  label: {},
  required: {
    color: colors.error_text,
  },
  tooltipIcon: {
    color: colors.black_lighter,
    verticalAlign: 'sub',
    cursor: 'pointer',
    marginLeft: 4,
  },
}));

// component types enums for switch case -
export const _wizardStepFieldTypes = {
  AUTOCOMPLETE: 'autoComplete',
  AUTOCOMPLETE_RADIO_BUTTONS: 'autoCompleteRadioButtons',
  AVAILABILITY_SELECTION: 'availabilitySelection',
  CATEGORIES_INPUT: 'categoriesInput',
  CHECKBOX: 'checkbox',
  CHECKBOX_CARD: 'checkboxCard',
  CURRENCY: 'currency',
  CURRENCY_RANGE: 'currencyRange',
  CUSTOM: 'custom',
  DATE_PICKER: 'datePicker',
  DATE_TIME_PICKER: 'dateTimePicker',
  EMAIL: 'email',
  ENTITY_AVATAR_CHECKBOX: 'entityAvatarCheckbox',
  FILE_UPLOAD: 'fileUpload',
  FILE_UPLOAD_2: 'fileUpload2',
  IMAGE_PICKER: 'imagePicker',
  IMAGE_PICKER_2: 'imagePicker2',
  MULTI_AUTOCOMPLETE: 'multiAutoComplete',
  MULTI_CHECKBOX: 'multiCheckbox',
  MULTI_SELECT: 'multiSelect',
  MULTI_SELECT_AUTOCOMPLETE: 'multiSelectAutoComplete',
  NUMBER: 'number',
  NUMERIC_RANGE: 'numericRange',
  PASSWORD: 'password',
  PHONE2: 'phone2',
  PHONE: 'phone',
  RADIO: 'radio',
  RADIO_CARDS: 'radioCards',
  RADIO_CARDS_WITH_SLIDER: 'radioCardsWithSlider',
  RICH_TEXT: 'richText',
  SCORE_BUTTONS: 'scoreButtons',
  SELECT: 'select',
  SLIDER: 'slider',
  STARS_VOTE: 'starsVote',
  SWITCH: 'switch',
  TEXT: 'text',
  TEXT_LIST: 'textList',
  URL: 'url',
  URL_LIST: 'urlList',
  MULTIPLE_PACKAGES_SELECTOR: 'multiplePackagesSelector',
};

export function WizardStepField(props) {
  const {
    vertical,
    style,
    labelProps,
    valueProps,
    wizardFieldLabel,
    wizardFieldTooltip,
    required,
    classesOverride,
    customComponent,
    type,
    ...rest
  } = props;

  // Allow to override WizardStepField classes
  const ownClasses = useStyles({
    classes: { ...classesOverride },
  });

  const renderLabel = () => {
    if (!wizardFieldLabel) {
      return null;
    }

    // input label, add a red astrix for required fields
    return (
      <>
        <TextDisplay variant="subtitle1" align="left" inline={wizardFieldTooltip !== ''} className={ownClasses.label}>
          {wizardFieldLabel}
          {required && <span className={ownClasses.required}> *</span>}
        </TextDisplay>
        {wizardFieldTooltip !== '' && (
          <Tooltip title={wizardFieldTooltip}>
            <IconHelp className={ownClasses.tooltipIcon} fontSize="small" />
          </Tooltip>
        )}
      </>
    );
  };

  const renderInputComponent = () => {
    // console.log('stepField props', props);
    /*
     * all fields should receive:
     * type.enum
     * name.string
     * required.bool (default true)
     * label.string (optional)
     *
     * */

    switch (type) {
      case _wizardStepFieldTypes.CUSTOM: {
        /*
         * should receive:
         * customComponent
         *
         * */
        return customComponent;
      }

      case _wizardStepFieldTypes.SELECT: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardSelectField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.CATEGORIES_INPUT: {
        /*
         * should receive:
         * items.array
         *
         * */
        return <WizardCategoriesField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.MULTI_AUTOCOMPLETE: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardAutocompleteField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.AUTOCOMPLETE: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardAutocompleteField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.AUTOCOMPLETE_RADIO_BUTTONS: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardMultiAutoCompleteRadioButtonsField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.RADIO: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardRadioButtonGroup isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.RADIO_CARDS_WITH_SLIDER: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardRadioCardWithSliderGroupField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.SLIDER: {
        /*
         *
         * */
        return <WizardSliderField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.STARS_VOTE:
        return <WizardStarsVoteField isRequired={required} {...rest} />;

      case _wizardStepFieldTypes.RADIO_CARDS: {
        /*
         * should receive:
         * enums.array
         *
         * */
        return <WizardRadioCardGroupField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.CHECKBOX: {
        /*
         * should receive:
         * label.string
         *
         * */
        return <WizardCheckboxField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.MULTI_CHECKBOX: {
        /*
         * should receive:
         * label.string
         *
         * */
        return <WizardMultiCheckboxField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.CHECKBOX_CARD: {
        /*
         * should receive:
         * label.string
         *
         * */
        return <WizardCheckboxCardGroupField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.TEXT: {
        /*
         * should receive:
         * fieldType.string
         * minLength.number
         *
         * */
        return <WizardTextField fieldType="text" isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.TEXT_LIST: {
        return <WizardTextListField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.RICH_TEXT: {
        /*
         * should receive:
         * fieldType.string
         * minLength.number
         *
         * */
        return <WizardRichTextField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.EMAIL: {
        /*
         * should receive:
         * fieldType.string
         *
         * */
        return <WizardTextField fieldType="text" isEmail isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.URL: {
        /*
         * should receive:
         * fieldType.string
         * isLinkedin.bool (default false)
         *
         * */
        return <WizardUrlField fieldType="text" isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.URL_LIST: {
        /*
         * should receive:
         * fieldType.string
         * isLinkedin.bool (default false)
         *
         * */
        return <WizardUrlListField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.SWITCH: {
        /*
         * should receive:
         *
         * */
        return <WizardSwitchField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.PASSWORD: {
        /*
         * should receive:
         * fieldType.string
         * minLength.number
         *
         * */
        return <WizardTextField fieldType="password" isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.NUMBER: {
        /*
         * should receive:
         * fieldType.string
         * minLength.number
         *
         * */
        return <WizardNumberField fieldType="number" isRequired={required} {...rest} />;
        // return (
        //   <WizardTextField fieldType="number" isRequired={required} {...rest} />
        // );
      }

      case _wizardStepFieldTypes.PHONE: {
        /*
         * This one should be deprecated
         * */
        return <WizardPhoneNumberInput isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.PHONE2: {
        /*
         * should receive:
         * */
        return <WizardPhoneNumberInput2 isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.IMAGE_PICKER: {
        /*
         * This one should be deprecated
         * */
        return <WizardImagePickerInput isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.IMAGE_PICKER_2:
        return <WizardImagePickerField isRequired={required} {...rest} />;

      case _wizardStepFieldTypes.FILE_UPLOAD: {
        /* This one should be deprecated
         * should receive:
         * name
         * form
         * dispatch
         * icon
         * tooltipText
         * accept - type of files to accept
         * */
        return <WizardFileUploaderInput isRequired={required} {...rest} />;
      }
      case _wizardStepFieldTypes.FILE_UPLOAD_2:
        /*
         * should receive:
         * icon
         * tooltipText
         * accept - type of files to accept
         * onSuccess
         * onDelete
         * */
        return <WizardFilesUploaderField isRequired={required} {...rest} />;

      case _wizardStepFieldTypes.DATE_PICKER: {
        /*
         * should receive:
         * views
         * openTo
         * label
         * */
        return <WizardDatePickerField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.DATE_TIME_PICKER: {
        /*
         * should receive:
         * views
         * openTo
         * label
         * */
        return <WizardDateTimePickerField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.NUMERIC_RANGE: {
        /*
         * should receive:
         *
         * minLength.number
         *
         * */
        return <WizardNumericRangeField isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.CURRENCY_RANGE: {
        /*
         * should receive:
         *
         * minLength.number
         *
         * */
        return <WizardNumericRangeField fieldType="currency" isRequired={required} {...rest} />;
      }

      case _wizardStepFieldTypes.CURRENCY: {
        /*
         * should receive:
         *
         *
         * */
        return <WizardCurrencyField isRequired={required} {...rest} />;
      }
      case _wizardStepFieldTypes.SCORE_BUTTONS: {
        return <WizardScoreButtonsField isRequired={required} {...rest} />;
      }
      case _wizardStepFieldTypes.ENTITY_AVATAR_CHECKBOX: {
        return <WizardEntityAvatarCheckboxField isRequired={required} {...rest} />;
      }
      case _wizardStepFieldTypes.AVAILABILITY_SELECTION: {
        return <WizardAvailabilitySelectionField isRequired={required} {...rest} />;
      }
      case _wizardStepFieldTypes.MULTIPLE_PACKAGES_SELECTOR: {
        return <MultiplePackagesSelectorField isRequired={required} {...rest} />;
      }
      default: {
        return <WizardTextField fieldType="text" isRequired={required} {...rest} />;
      }
    }
  };

  // Draw the field vertically?
  if (vertical) {
    return (
      <Grid container className={ownClasses.rowStyle} style={style}>
        {wizardFieldLabel && (
          <Grid item xs={12} className={ownClasses.headerStyle}>
            {renderLabel()}
          </Grid>
        )}
        <Grid item xs={12}>
          {renderInputComponent()}
        </Grid>
      </Grid>
    );
  } else {
    // Draw the field horizontally?
    return (
      <Grid container className={ownClasses.rowStyle} style={style}>
        <Grid item xs={3} className={ownClasses.headerStyle} {...labelProps}>
          {renderLabel()}
        </Grid>
        <Grid item xs={9} {...valueProps}>
          {renderInputComponent()}
        </Grid>
      </Grid>
    );
  }
}

WizardStepField.propTypes = {
  customComponent: PropTypes.node,
  wizardFieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  wizardFieldTooltip: PropTypes.string,
  type: PropTypes.oneOf(Object.values(_wizardStepFieldTypes)).isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  styleConstants: PropTypes.object,
  required: PropTypes.bool,
  vertical: PropTypes.bool,
  labelProps: PropTypes.object,
  valueProps: PropTypes.object,
  classesOverride: PropTypes.object,
};

WizardStepField.defaultProps = {
  styleConstants,
  required: false,
  vertical: true,
  customComponent: null,
  wizardFieldLabel: '',
  wizardFieldTooltip: '',
  style: {},
  labelProps: {},
  valueProps: {},
  classesOverride: {},
};

WizardStepField.types = _wizardStepFieldTypes;
