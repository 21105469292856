import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    dialogTitle: {},
    dialogContent: {},
    dialogActions: {},
  })
);

export default useStyles;
