import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  iconButton: {},
  iconButtonResume: {
    backgroundColor: 'rgba(0, 255, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 255, 0, 0.6)',
    },
  },
  iconButtonPaused: {
    backgroundColor: 'rgba(255, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.6)',
    },
  },
  icon: {
    color: 'white',
  },
}));

function PauseProjectButton({ name, isServicePaused, disabled, onPauseResumeOnClick }) {
  const tooltipTitle = isServicePaused ? 'Click to resume service' : 'Click to pause service';
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        classes={{
          root: classNames(classes.iconButton, {
            [classes.iconButtonResume]: isServicePaused,
            [classes.iconButtonPaused]: !isServicePaused,
          }),
        }}
        name={name}
        onClick={onPauseResumeOnClick}
        disabled={disabled}
      >
        {isServicePaused && <ResumeIcon className={classes.icon} />}
        {!isServicePaused && <PauseIcon className={classes.icon} />}
      </IconButton>
    </Tooltip>
  );
}

PauseProjectButton.propTypes = {
  name: PropTypes.string.isRequired,
  isServicePaused: PropTypes.bool.isRequired,
  onPauseResumeOnClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PauseProjectButton;
