import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';

import { TextDisplay } from '../../display';

import { useFeedPostActionItemsStyles } from './styles';

interface FeedPostActionItemsProps {
  actionItems: string[] | null | undefined;
}

const FeedPostActionItems: FC<FeedPostActionItemsProps> = (props) => {
  const { actionItems = [] } = props;
  const classes = useFeedPostActionItemsStyles(props);

  if (!actionItems || actionItems.length === 0) {
    return null;
  }

  return (
    <Paper className={classes.actionItemsContainer}>
      <TextDisplay className={classes.actionItemsTitle} variant="overline">
        Action items
      </TextDisplay>
      <ul className={classes.actionItemList}>
        {actionItems &&
          actionItems.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`FeedPostActionItems-${i}-item`} className={classes.actionItemListItem}>
              {item}
            </li>
          ))}
        {actionItems && actionItems.length === 0 && <li>Hooray. No action items here</li>}
      </ul>
    </Paper>
  );
};

export default FeedPostActionItems;
