import React, { useMemo } from 'react';
import { Project } from '@mayple/types';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import WarningIcon from '@material-ui/icons/Warning';

import ProjectBriefCloneAndConnectDialog from './ProjectBriefCloneAndConnectDialog';

import { Button } from '../../../../../../fe_common/client/components/inputs';
import useDialog from '../../../../../../fe_common/client/hooks/useDialog';
import { checkIsFirstTime } from '../../../../../../fe_common/client/logic/deepBrief';
import { colors } from '../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
    },
  }),
);

interface ProjectBriefCloneAndConnectButtonProps {
  project: Project;
}

const ProjectBriefCloneAndConnectButton: React.FC<ProjectBriefCloneAndConnectButtonProps> = (props) => {
  const classes = useStyles();
  const { project } = props;
  const { open, openDialog, closeDialog } = useDialog();

  const isEmptyBrief = useMemo(() => checkIsFirstTime(project?.deepBrief), [project]);

  return (
    <>
      <div className={classes.root}>
        <Button
          label="Clone & Connect"
          color="primary"
          variant="outlined"
          startIcon={<FileCopyIcon />}
          onClick={openDialog}
          disabled={isEmptyBrief}
        />
        {isEmptyBrief && (
          <Tooltip title="You can’t clone an empty brief">
            <span>
              <WarningIcon htmlColor={colors.red} />
            </span>
          </Tooltip>
        )}
      </div>
      {open && <ProjectBriefCloneAndConnectDialog open={open} onClose={closeDialog} project={project} />}
    </>
  );
};

export default ProjectBriefCloneAndConnectButton;
