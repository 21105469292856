import get from 'lodash/get';
import { INDUSTRIES_LABELS_LOOKUP } from '../app/enums';
import { INDUSTRIES_CATEGORIES } from '../app/consts';

const getSubCategoryFromIndustry = (industry) => {
  const path = Array.isArray(industry) ? '[0].industrySubCategory' : 'industrySubCategory';
  const industrySubCategory = get(industry, path);

  return industrySubCategory && INDUSTRIES_LABELS_LOOKUP[industrySubCategory]
    ? INDUSTRIES_LABELS_LOOKUP[industrySubCategory]
    : null;
};

const getCategoryFromIndustry = (industry) => {
  const categoryPath = Array.isArray(industry) ? '[0].industryCategory' : 'industryCategory';
  const industryCategory = get(industry, categoryPath);

  return INDUSTRIES_LABELS_LOOKUP[industryCategory || INDUSTRIES_CATEGORIES.SERVICES_OTHER_1020];
};

export function getIndustryLabel(industry, showFullLabel = false) {
  if (typeof industry === 'string') {
    return INDUSTRIES_LABELS_LOOKUP[industry];
  }

  const industrySubCategory = getSubCategoryFromIndustry(industry);
  const industryCategory = getCategoryFromIndustry(industry);

  if (showFullLabel) {
    return [industryCategory, industrySubCategory].filter(Boolean).join(' / ');
  }
  return industrySubCategory || industryCategory;
}
