import { TFunction } from 'react-i18next';
import { ProjectSpecialSubType } from '@mayple/types';

const getTranslationForProjectSpecialSubType = (
  t: TFunction,
  text: string,
  projectSpecialSubType?: ProjectSpecialSubType | null,
  defaultValue?: string
): string => t(`${text}_${projectSpecialSubType}`, t(text, defaultValue));

export default getTranslationForProjectSpecialSubType;
