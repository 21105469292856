import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { HoverAnimation, SelectionCardProps } from './types';
import SelectionIndicator from '../SelectionIndicator';
import SimpleIcon from '../../SimpleIcon';
import { TextDisplay } from '../../../display';

import useStyles from './style';

const SelectionCard: FC<SelectionCardProps> = (props) => {
  const classes = useStyles(props);

  const {
    label,
    subtitle,
    icon,
    textOnHover,
    checked = false,
    disabled = false,
    cardType = '',
    selectionIndicatorType = 'hide',
    layout = 'vertical',
    onClick,
    animationForTextOnHover = HoverAnimation.none,
  } = props;

  const [isHorizontal] = useState(layout === 'horizontal');
  const [withBackSide] = useState(!isHorizontal && textOnHover != null && textOnHover !== '');

  return (
    <div
      className={classNames(classes.root, {
        [classes.horizontal]: isHorizontal,
        [classes.vertical]: !isHorizontal,
        [classes.hovering]: !disabled,
        [classes.hoveringWithBackSide]: !disabled && withBackSide,
        [classes.disabled]: disabled,
        [classes.active]: !disabled,
        [classes.flipAnimation]: animationForTextOnHover === HoverAnimation.flip,
        [classes.fadeInAnimation]: animationForTextOnHover === HoverAnimation.fadeIn,
      })}
      role="button"
      onClick={disabled ? () => null : onClick}
      onKeyUp={disabled ? () => null : onClick}
      tabIndex={disabled ? -1 : 0}
    >
      <div className={classNames(classes.flipCardInner, {})}>
        <div
          className={classNames(classes.flipCardSide, classes.flipCardFront, {
            [classes.checked]: checked,
            [classes.withSelectionIndicator]: selectionIndicatorType !== 'hide',
            [classes.withCardIcon]: !!icon,
            [classes.withSubtitle]: subtitle && subtitle !== '',
            [classes.withExtraDetails]: textOnHover && textOnHover !== '',
          })}
        >
          {icon !== null && icon !== undefined && icon !== '' && (
            <div
              className={classNames({
                [classes.cardIconWrapper]: isHorizontal,
              })}
            >
              <SimpleIcon icon={icon} className={classes.cardIcon} />
            </div>
          )}

          <TextDisplay variant="body1" className={classes.label}>
            {label}
          </TextDisplay>

          {subtitle != null && subtitle !== '' && (
            <TextDisplay variant="body2" className={classes.subtitle}>
              {subtitle}
            </TextDisplay>
          )}

          {isHorizontal && textOnHover != null && textOnHover !== '' && (
            <div className={classes.extraDetails}>
              <TextDisplay variant="caption" className={classes.extraDetailsText}>
                {textOnHover}
              </TextDisplay>
            </div>
          )}

          {((selectionIndicatorType === 'show' && cardType !== '') ||
            (selectionIndicatorType === 'showOnSelected' && checked && cardType !== '')) && (
            <SelectionIndicator
              type={cardType}
              checked={checked}
              disabled={disabled}
              className={classNames(classes.selectionIndicator, {
                [classes.selectionIndicatorChecked]: checked,
              })}
            />
          )}
        </div>
        {withBackSide && !disabled && (
          <div
            className={classNames(classes.flipCardSide, classes.flipCardBack, {
              [classes.checked]: checked,
            })}
          >
            <TextDisplay variant="body1" className={classes.label}>
              {label}
            </TextDisplay>

            <div className={classes.extraDetails}>
              <TextDisplay variant="caption" className={classes.extraDetailsText}>
                {textOnHover}
              </TextDisplay>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionCard;
