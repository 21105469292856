import { DocumentNode } from 'graphql';
import { MarketerAssessmentByCompanyParameter, MonthlyMarketerAssessmentByCompany, TaskType } from '@mayple/types';
import { GetMonthlyMarketerAssessmentByCompanyForProjectQuery } from 'growl-graphql/dist/queries/tasks/MonthlyMarketerAssessmentByCompany/GetMonthlyMarketerAssessmentByCompanyForProjectQuery';
import { GetPostProjectMatchOfferMarketerAssessmentForProjectQuery } from 'growl-graphql/dist/queries/tasks/PostProjectMatchOfferMarketerAssessmentForProject/GetPostProjectMatchOfferMarketerAssessmentForProjectQuery';

import { Assessment, ValidAssessmentTaskTypesKeys } from './types';

const MARKETER_ASSESSMENT_VOTE_MIN_VALUE = 0;
const MARKETER_ASSESSMENT_VOTE_MAX_VALUE = 5;

const marketerAssessmentParametersToVotesMapper = (
  marketerAssessmentParameter: MarketerAssessmentByCompanyParameter,
) => ({
  category: marketerAssessmentParameter.key,
  value: marketerAssessmentParameter.value,
  maxValue: MARKETER_ASSESSMENT_VOTE_MAX_VALUE,
  minValue: MARKETER_ASSESSMENT_VOTE_MIN_VALUE,
});

export const monthlyMarketerAssessmentByCompanyToAssessmentMapper = (
  monthlyMarketerAssessment: MonthlyMarketerAssessmentByCompany,
): Assessment => ({
  marketerId: monthlyMarketerAssessment.marketerId,
  projectId: monthlyMarketerAssessment.projectId,
  created: monthlyMarketerAssessment.cycle.startDate,
  votes: monthlyMarketerAssessment.marketerAssessmentParameters.map(marketerAssessmentParametersToVotesMapper),
});

const assessmentQueryByTaskTypeMapper: Record<
  ValidAssessmentTaskTypesKeys,
  {
    query: DocumentNode;
    dataKey: string;
    mapper: <T>(o: T) => Assessment;
  }
> = {
  [TaskType.MonthlyMarketerAssessmentByCompany]: {
    query: GetMonthlyMarketerAssessmentByCompanyForProjectQuery.query,
    dataKey: 'getMonthlyMarketerAssessmentByCompanyForProject',
    // @ts-ignore
    mapper: monthlyMarketerAssessmentByCompanyToAssessmentMapper,
  },
  [TaskType.PostProjectMatchOfferMarketerAssessment]: {
    query: GetPostProjectMatchOfferMarketerAssessmentForProjectQuery.query,
    dataKey: 'getPostProjectMatchOfferMarketerAssessmentForProject',
    // @ts-ignore
    mapper: monthlyMarketerAssessmentByCompanyToAssessmentMapper,
  },
};

export const getAssessmentQueryByTaskType = (
  taskType: ValidAssessmentTaskTypesKeys,
): {
  query: DocumentNode;
  dataKey: string;
  mapper: <T>(o: T) => Assessment;
} => {
  if (!assessmentQueryByTaskTypeMapper?.[taskType]) {
    throw new Error(`Task ${taskType} definitions are missing`);
  }

  return assessmentQueryByTaskTypeMapper?.[taskType];
};

export const getAssessments = <T>(assessments: Array<T>, mapper: (assessment: T) => Assessment): Assessment[] => {
  if (!assessments || !Array.isArray(assessments) || !assessments?.length) {
    return [] as Assessment[];
  }

  return (assessments || ([] as T[])).map(mapper);
};
