import { ApolloError } from 'apollo-client';
import { FilterByOperator, Marketer } from '@mayple/types';
import { AdminAccessibleMarketersPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleMarketersPagedQuery';

import usePagedQuery from '../../fe_common/client/hooks/usePagedQuery';

export interface AdminAccessibleMarketerData {
  marketer: Marketer | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useAdminAccessibleMarketerData = (marketerId?: number): AdminAccessibleMarketerData => {
  const { data, loading, error } = usePagedQuery(
    AdminAccessibleMarketersPagedQuery.query,
    'marketersPaged',
    {
      filter: {
        by: [
          {
            key: 'id',
            value: String(marketerId),
            operator: FilterByOperator.EQUALS,
          },
        ],
      },
      pagination: {
        pageSize: 10,
      },
    },
    {
      skip: !marketerId,
    },
  );

  return {
    marketer: data?.[0],
    loading,
    error,
  };
};

export default useAdminAccessibleMarketerData;
