import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: 24,
    paddingBottom: 32,
  },
}));

interface TabContentProps {
  children?: React.ReactElement | React.ReactNode | HTMLElement | Element;
  classes?: Record<string, string>;
}

const TabContent: React.FC<TabContentProps> = (props) => {
  const classes = useStyles(props);
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};

export default TabContent;
