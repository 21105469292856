import React, { FC, useState } from 'react';
import { Project, ProjectLifeCycleStatus } from '@mayple/types';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { Redirect } from 'react-router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import DuplicateProjectDialog from './DuplicateProjectDialog';
import LinkToProject from '../../../../components/cpanel/components/atoms/Links/LinkToProject';

import { stageTitle } from '../../../../../fe_common/client/logic/projectStage';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';

interface CompanyProjectsProps {
  projects: Project[];
  classes?: Record<string, string>;
  refetch: () => Promise<any>;
}

const CompanyProjects: FC<CompanyProjectsProps> = (props) => {
  const classes = useStyles();
  const [projectIdToDuplicate, setProjectIdToDuplicate] = useState<number | null>(null);
  const [redirectTo, setRedirectTo] = useState('');

  const { projects = [], refetch } = props;

  const onClose = () => {
    setProjectIdToDuplicate(null);
  };

  const onDuplicateClick = (id: number) => {
    setProjectIdToDuplicate(id);
  };

  const onDuplicateSuccess = async (projectId: number) => {
    await refetch();
    setRedirectTo(`/projects/${projectId}`);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={classes.root}>
      {projects.length === 0 && <CalloutMessage message="No Projects found." />}
      {projects.length > 0 && (
        <List>
          {projects
            .filter((project) => project.projectLifeCycleStatus !== ProjectLifeCycleStatus.ABANDONED)
            .map((project) => (
              <React.Fragment key={`company-project-list-${project.id}`}>
                <ListItem>
                  <ListItemIcon>
                    <WorkOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LinkToProject projectId={project.id}>
                        <TextDisplay inline className={classes.link}>
                          {project.name} (ID: {project.id})
                        </TextDisplay>
                      </LinkToProject>
                    }
                    secondary={`Status: ${project.projectLifeCycleStatus} - Stage: ${stageTitle[project.stage]}`}
                  />

                  <ListItemSecondaryAction>
                    <Tooltip title="Duplicate" placement="left">
                      <IconButton onClick={() => onDuplicateClick(project.id)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className={classes.divider} />
              </React.Fragment>
            ))}
        </List>
      )}

      {!!projectIdToDuplicate && (
        <DuplicateProjectDialog
          projectId={projectIdToDuplicate}
          open={!!projectIdToDuplicate}
          onClose={onClose}
          onSuccess={onDuplicateSuccess}
        />
      )}
    </div>
  );
};

export default CompanyProjects;
