import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IdentityDetailsQuery } from 'growl-graphql/dist/queries/IdentityDetailsQuery';
import { DivestFundsOfCompanyMutation } from 'growl-graphql/dist/mutations/DivestFundsOfCompanyMutation';

import { WizardSteps } from './steps';
import useMutation from '../../../../../fe_common/client/hooks/useMutation';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function DivestFundsForm(props) {
  const { company, onSuccess } = props;
  const classes = useStyles();
  const [userType, setUserType] = useState('NORMAL_USER');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { data, loading, error } = useQuery(IdentityDetailsQuery.query);
  const { mutate: divestFundsToCompany } = useMutation(DivestFundsOfCompanyMutation);

  const wizardFormName = 'divestFunds';
  const confirmMessage = (
    <TextDisplay>
      You are about to divest funds from company {company.name} with ID: {company.id}.<br />
      You will be taking away REAL money that was paid by the company to be used in campaigns.
      <br />
      Are you certain you want to do this?
    </TextDisplay>
  );

  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  useEffect(() => {
    if (loading || error) {
      return;
    }

    const newUserType = data?.identityDetails?.userType || 'NORMAL_USER';
    setUserType(newUserType);
  }, [loading, error, data]);

  const validate = (values) => {
    const errors = {};
    const { fundsToDivest, verificationAmount } = values;

    if (JSON.stringify(fundsToDivest) !== JSON.stringify(verificationAmount)) {
      errors.verificationAmount = 'Verification amount does not match reported amount';
    }

    return errors;
  };

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleFormSubmit = async (formValues, dispatch, formProps) => {
    const { fundsToDivest, description } = formValues;

    const hasCompany = !!company;
    const hasFundAccount = !!(company?.fundAccount || false);
    const isAdminUser = ['ADMIN_USER'].includes(userType);

    if (!hasCompany || !hasFundAccount || !isAdminUser) {
      return;
    }

    const variables = {
      companyId: company.id,
      amount: fundsToDivest?.value,
      currency: fundsToDivest?.currency,
      description: description || 'Funds divested manually by admin user with no description.',
    };

    await divestFundsToCompany({ variables });

    closeConfirmationDialog();
    formProps.destroy();
    onSuccess();
  };

  return (
    <div className={classes.root}>
      <WizardForm
        form={wizardFormName}
        steps={WizardSteps}
        validate={validate}
        onSubmit={handleFormSubmit}
        onShowConfirmation={openConfirmationDialog}
        showConfirmationDialog={showConfirmationDialog}
        closeConfirmationDialog={closeConfirmationDialog}
        confirmMessage={confirmMessage}
        currency={company?.fundAccount?.currency}
      />
    </div>
  );
}

DivestFundsForm.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

DivestFundsForm.defaultProps = {
  userType: 'NORMAL_USER',
};

export default DivestFundsForm;
