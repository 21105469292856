/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { PhoneNumberInput } from '../../Registration/PhoneNumberInput';

import { validateRequired, validatePhoneNumber } from '../../../../services/validation';

export function WizardPhoneNumberInput(props) {
  const { name, placeholder, isRequired, country } = props;

  // the validator is commented out because the invalid error is not shown to user. need to fix this before
  // we can use the validate
  const validator = isRequired ? [validateRequired] : [];
  validator.push(validatePhoneNumber);

  return (
    <div style={{ marginTop: 34 }}>
      <Field
        name={name}
        component={PhoneNumberInput}
        // this will prevent the formatted phone to be overwritten by the user input on blur event!
        onBlur={(event) => {
          event.preventDefault();
        }}
        isRequired={isRequired}
        validate={validator}
        placeholder={placeholder}
        country={country}
      />
    </div>
  );
}

WizardPhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  // form:        PropTypes.string.isRequired,
  // dispatch:    PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  country: PropTypes.string,
  isRequired: PropTypes.bool,
};

WizardPhoneNumberInput.defaultProps = {
  isRequired: false,
  country: 'GB',
  placeholder: '',
};
