import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 24,
      boxShadow: '0 1px 4px rgba(51, 53, 82, 0.12), 0 1px 6px rgba(51, 53, 82, 0.12)',
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
    avatarContainer: {},
    avatarContainerOnActiveMode: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
      },
    },
    postEditorContainer: {
      width: '100%',
      flexWrap: 'nowrap',
    },
    postEditorContainerActive: {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    postEditorContainerIdle: {},
    formContainer: {
      flexGrow: 1,
      position: 'relative',
    },
    avatar: {
      marginRight: 24,
    },
    input: {
      borderRadius: 16,
      border: '1px solid rgba(36, 24, 21, 0.1)',
      padding: '8px 24px',
      margin: '2px auto',
    },
    inputIdle: {
      background: 'rgba(36, 24, 21, 0.05)',
      transition: 'background 100ms ease-in',
      '&:hover': {
        transition: 'background 0.1s ease-in',
        background: 'rgba(36, 24, 21, 0.1)',
      },
    },
    inputIdleClicked: {
      transition: 'background 50ms ease-in',
      background: 'rgba(36, 24, 21, 0.25)',
    },
  })
);

export const useWizardFormStyles = makeStyles(() =>
  createStyles({
    root: {},
    actionsDivider: {
      marginBottom: 4,
    },
    actionsContainer: {
      alignItems: 'center',
    },
    fileUploadButton: {
      minWidth: 'unset',
      margin: 0,
      padding: 11,
    },
    fileUploadButtonStartIcon: {
      margin: 0,
    },
    puppetUserCheckbox: {},
    topicSelect: {
      lineHeight: '1.4em',
      minHeight: '1.4em',
      textTransform: 'capitalize',
      paddingTop: 16,
      paddingBottom: 16,
      '& > option': {
        textTransform: 'capitalize',
      },
    },
    postContent: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& .ql-container': {
        minHeight: '10em',
      },
      '& .ql-toolbar': {
        borderTop: '0 none',
      },
      '& .ql-editor': {
        borderTop: '1px solid #ccc',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  })
);
