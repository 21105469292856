import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'grid',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      gap: '1rem',
      width: '100%',
    },
    item: {},
    oneColumn: { gridTemplateColumns: '1fr' },
    twoColumns: { gridTemplateColumns: 'repeat(2, 1fr)' },
    threeColumns: { gridTemplateColumns: 'repeat(3, 1fr)' },
    fourColumns: { gridTemplateColumns: 'repeat(4, 1fr)' },
    userDefined: {},
    groupTitle: {
      width: '100%',
      display: 'block',
      margin: '2rem 0 0 0',
      padding: '0 0.5rem',
      fontWeight: 500,
      gridColumn: '1 / -1',
    },
    dividerWrapper: {
      width: '100%',
      padding: '1.25rem 0',
      boxShadow: 'none',
      background: 'transparent',
    },
    divider: {
      width: '20%',
      margin: 'auto',
      height: 2,
      backgroundColor: '#B5D6ED',
    },
    selectAllButton: {
      margin: 0,
      marginBottom: 4,
    },

    selectionCardRoot: {},
    selectionCardActive: {},
    selectionCardHovering: {},
    selectionCardHoveringWithBackSide: {},
    selectionCardFlipCardBack: {},
    selectionCardFlipCardSide: {},
    selectionCardFlipCardFront: {},
    selectionCardDisabled: {},
    selectionCardVertical: {},
    selectionCardHorizontal: {},
    selectionCardWithSelectionIndicator: {},
    selectionCardWithCardIcon: {},
    selectionCardWithSubtitle: {},
    selectionCardWithExtraDetails: {},
    selectionCardChecked: {},
    selectionCardCardIconWrapper: {},
    selectionCardCardIcon: {},
    selectionCardLabel: {},
    selectionCardSubtitle: {},
    selectionCardSelectionIndicator: {},
    selectionCardSelectionIndicatorChecked: {},
    selectionCardExtraDetails: {},
    selectionCardExtraDetailsText: {},
  })
);

export default useStyles;
