import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TextList from '../../Registration/TextList';
import { validateNonEmptyArray } from '../../../../services/validation';

export function WizardTextListField(props) {
  // console.log('WizardTextListField props', props);

  const { name, isRequired, label, placeholder, noItemsMessage, listItemTextMaxLength, maxItemsInList } = props;

  // field required?
  const validator = isRequired ? [validateNonEmptyArray()] : [];

  return (
    <div>
      <Field
        name={name}
        component={TextList}
        validate={validator}
        label={label}
        placeholder={placeholder}
        noItemsMessage={noItemsMessage}
        maxItemsInList={maxItemsInList}
        listItemTextMaxLength={listItemTextMaxLength}
      />
    </div>
  );
}

WizardTextListField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  noItemsMessage: PropTypes.string,
  listItemTextMaxLength: PropTypes.number,
  maxItemsInList: PropTypes.number,
};

WizardTextListField.defaultProps = {
  label: '',
  placeholder: '',
  isRequired: true,
  noItemsMessage: '',
  listItemTextMaxLength: null,
  maxItemsInList: null,
};
