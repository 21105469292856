import { InternalTeam, Project, ProjectCycle, SupervisorType } from '@mayple/types';
import React, { useContext } from 'react';
import { ApolloQueryResult } from 'apollo-client';

export interface IProjectDetailsContext {
  projectId: number;
  project: Project;
  cycles: ProjectCycle[];
  refetchData: () => Promise<any>;
  loadingSupervisor: boolean;
  supervisor: InternalTeam;
  supervisorType: SupervisorType;
  allowedSupervisorTypes: Array<SupervisorType>;
  refetchSupervisor: (
    variables?: { projectId: number | null | undefined } | undefined,
  ) => Promise<ApolloQueryResult<any>>;
}

const ProjectDetailsContext = React.createContext<IProjectDetailsContext | undefined>(undefined);

type ProjectDetailsContextProviderProps = { state: IProjectDetailsContext; children: React.ReactNode };

const ProjectDetailsContextProvider: React.FC<ProjectDetailsContextProviderProps> = ({ state, children }) => (
  <ProjectDetailsContext.Provider value={state}>{children}</ProjectDetailsContext.Provider>
);

function useProjectDetailsState(): IProjectDetailsContext {
  const context = useContext(ProjectDetailsContext);
  if (context === undefined) {
    throw new Error('useProjectDetailsState must be used within a ProjectDetailsProvider');
  }
  return context;
}

export { useProjectDetailsState };
export default ProjectDetailsContextProvider;
