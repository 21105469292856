import React, { FC } from 'react';

import { UrlListProps } from './types';
import UrlListViewMode from './UrlListViewMode';
import UrlListEditMode from './UrlListEditMode';

const UrlList: FC<UrlListProps> = (props) => {
  const { viewMode = false } = props;

  return (
    <>
      {viewMode && <UrlListViewMode {...props} />}
      {!viewMode && <UrlListEditMode {...props} />}
    </>
  );
};

export default UrlList;
