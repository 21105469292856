import { AlternativeEmailAddress, EntityOperationResponse } from '@mayple/types';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';

export enum ViewMode {
  readonly = 'readonly',
  edit = 'edit',
}

export type AlternativeEmailAddressesEditorProps = {
  userId: number;
  enableEdit: boolean;
  alternativeEmailAddresses: AlternativeEmailAddress[] | null | undefined;
  onSuccess?: () => Promise<void>;
  classes?: Record<string, string>;
};

export type RemoveUserAlternativeEmailAddressExecutionResult = {
  removeUserAlternativeEmailAddress: EntityOperationResponse;
};

export type AddUserAlternativeEmailAddressExecutionResult = { addUserAlternativeEmailAddress: EntityOperationResponse };

export interface WizardStepsProps extends DecoratedFormProps {
  formValues: Record<string, any>;
  alternativeEmailAddresses: AlternativeEmailAddress[];
  onCancel?: () => void;
  classes?: Record<string, string>;
}
