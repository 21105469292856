import useDialog from '../../../../../../fe_common/client/hooks/useDialog';

const useProjectOpportunityWithdrawDialog = (): {
  showConfirmWithdrawDialog: boolean;
  openConfirmWithdrawDialog: () => void;
  closeConfirmWithdrawDialog: () => void;
} => {
  const {
    open: showConfirmWithdrawDialog,
    openDialog: openConfirmWithdrawDialog,
    closeDialog: closeConfirmWithdrawDialog,
  } = useDialog();

  return {
    showConfirmWithdrawDialog,
    openConfirmWithdrawDialog,
    closeConfirmWithdrawDialog,
  };
};

export default useProjectOpportunityWithdrawDialog;
