import React from 'react';
import { Project, ProjectStage } from '@mayple/types';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';

import useCreateYourMarketingPlanStep from './logic';
import { TRANSLATION_NS } from '../../../consts';
import ExternalLink from '../../../../../display/ExternalLink';
import UploadMarketingPlanDialog from '../../../UploadMarketingPlanDialog';
import { Button } from '../../../../../inputs';
import FilesList from '../../../../../molecules/FilesList';
import BriefReportDialog from '../../../../../molecules/BriefReportDialog';
import { FileListFile } from '../../../../../molecules/FilesList/types';
import { TextDisplay } from '../../../../../display';

import useStyles from './style';

const CreateYourMarketingPlanStep: React.FC = () => {
  const classes = useStyles();

  const {
    isLoading,
    filestackApiKey,
    project,
    templateLink,
    uploadMarketingPlanDialogIsOpen,
    openUploadMarketingPlanDialog,
    marketingPlanFile,
    mainGrowthStrategistName,
    openBriefReportDialog,
    hasMarketingPlan,
    briefReportDialogIsOpen,
    closeBriefReportDialog,
    closeUploadMarketingPlanDialog,
    onUploadHandler,
    filesListActions,
    copyTemplateLinkHandler,
  } = useCreateYourMarketingPlanStep();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper.CreateYourMarketingPlanStep',
  });

  return (
    <>
      <TextDisplay variant="body1">
        <Trans t={t} i18nKey="stepContent">
          After you submit your marketing plan It will be reviewed by {{ mainGrowthStrategistName }}. You’ll find all
          the client’s information in
          <Link href="#" onClick={openBriefReportDialog}>
            the brief
          </Link>
          - Feel free to use
          <ExternalLink href={templateLink as string} onClick={copyTemplateLinkHandler}>
            this template
          </ExternalLink>
          to build your marketing plan.
        </Trans>
      </TextDisplay>
      {!hasMarketingPlan && (
        <Button
          label={t('uploadMarketingPlanButton')}
          onClick={openUploadMarketingPlanDialog}
          variant="contained"
          color="secondary"
          disabled={project?.stage !== ProjectStage.SETUP && project?.stage !== ProjectStage.MARKETING_PLAN}
          className={classes.uploadMarketingPlanButton}
        />
      )}
      {hasMarketingPlan && (
        <div>
          <FilesList
            files={marketingPlanFile as FileListFile[]}
            showFileSize
            allowDownload={false}
            actions={filesListActions}
            classes={{ root: classes.fileListRoot }}
          />
        </div>
      )}
      <BriefReportDialog open={briefReportDialogIsOpen} project={project as Project} onClose={closeBriefReportDialog} />
      <UploadMarketingPlanDialog
        loading={isLoading}
        open={uploadMarketingPlanDialogIsOpen}
        filestackApiKey={filestackApiKey}
        onClose={closeUploadMarketingPlanDialog}
        onUpload={onUploadHandler}
      />
    </>
  );
};

export default CreateYourMarketingPlanStep;
