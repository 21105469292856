import React, { FC } from 'react';

import UsersTable from './UsersTable';

import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';

import useStyles from './style';

const UsersPage: FC = () => {
  const classes = useStyles();
  return (
    <Section title="Users" noBorder classes={{ root: classes.sectionRoot }}>
      <SectionContent>
        <UsersTable />
      </SectionContent>
    </Section>
  );
};

export default UsersPage;
