const getEnumsInitialValues = (data, labels) => {
  try {
    if (Array.isArray(data)) {
      return data.map((item) => labels.find(({ value }) => value === item));
    }

    if (data != null) {
      return labels.find(({ value }) => value === data) || null;
    }

    return [];
  } catch (e) {
    if (Array.isArray(data)) {
      return [];
    }

    return null;
  }
};

export default getEnumsInitialValues;
