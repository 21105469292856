import React, { FC } from 'react';
import { ContactDetails } from '@mayple/types';

import getEntityContactDetailsProperties from './logic';

import PropertiesList from '../../../../../../fe_common/client/components/display/PropertiesList';

import useStyles from './style';

export type EntityContactDetailsProps = {
  contactDetails: ContactDetails | null | undefined;
  classes?: Record<string, string>;
};

const EntityContactDetails: FC<EntityContactDetailsProps> = (props) => {
  const { contactDetails = {} } = props;
  const classes = useStyles(props);

  const entityContactDetailsProperties = getEntityContactDetailsProperties();

  return (
    <PropertiesList
      id="entityContactDetails"
      data={contactDetails}
      propertiesMeta={entityContactDetailsProperties}
      classes={{
        propertyLabel: classes.propertyLabel,
        propertyValue: classes.propertyValue,
      }}
    />
  );
};

export default EntityContactDetails;
