import React from 'react';

const validLayout = ['horizontal', 'vertical'] as const;
export type Layout = (typeof validLayout)[number];

const validCardType = ['radio', 'checkbox'] as const;
export type CardType = (typeof validCardType)[number];

const validSelectionIndicatorType = ['show', 'hide', 'showOnSelected'] as const;
export type SelectionIndicatorType = (typeof validSelectionIndicatorType)[number];

// eslint-disable-next-line no-shadow
export enum HoverAnimation {
  none = 'none',
  flip = 'flip',
  fadeIn = 'fadeIn',
}

export type SelectionCardProps = {
  label: string;
  subtitle?: string;
  icon?: React.ReactNode | React.FC | string | undefined;
  textOnHover?: string | undefined;
  checked?: boolean;
  disabled?: boolean;
  layout?: Layout;
  cardType?: CardType;
  selectionIndicatorType?: SelectionIndicatorType;
  onClick?: () => void;
  animationForTextOnHover?: HoverAnimation;
  classes?: Record<string, string>;
};
