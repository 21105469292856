import React from 'react';
import PropTypes from 'prop-types';
import { Step, Steps } from 'react-albus';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { useFeedState } from '../FeedContext';
import { Button } from '../../inputs';
import { WizardStepField } from '../../molecules/WizardStepField';
import FilesList from '../../molecules/FilesList';

import { useWizardFormStyles } from './style';
import { FilesListLayout } from '../../molecules/FilesList/types';

function WizardSteps(props) {
  const { dispatch, change, submit, invalid, formValues, topics, placeholder, loading } = props;

  const classes = useWizardFormStyles();
  const { filestackApiKey, canPostAsPuppet, puppetUsers } = useFeedState();
  const { uploadFiles, showPuppetUserSelection } = formValues;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fileOnDeleteHandler = (event, file, files) => {
    const { uploadId } = file;
    const newFiles = [...files].filter((f) => f.uploadId !== uploadId);

    dispatch(change('uploadFiles', newFiles));
  };

  return (
    <Steps>
      <Step
        id="addSuccessStories"
        render={() => (
          <>
            {canPostAsPuppet && (
              <>
                <WizardStepField
                  type={WizardStepField.types.CHECKBOX}
                  name="showPuppetUserSelection"
                  inputLabel="Post as Puppet User"
                  className={classes.puppetUserCheckbox}
                />
                {showPuppetUserSelection && (
                  <WizardStepField
                    type={WizardStepField.types.SELECT}
                    name="puppetPersonUuid"
                    enums={puppetUsers}
                    label="Select Puppet User*"
                    required={showPuppetUserSelection}
                    className={classes.puppetUserCheckbox}
                  />
                )}
              </>
            )}
            <WizardStepField
              type={WizardStepField.types.SELECT}
              name="postTopic"
              label="Topic*"
              enums={topics}
              className={classes.topicSelect}
              showError={false}
              required
            />
            <WizardStepField
              type={WizardStepField.types.RICH_TEXT}
              name="postContent"
              placeholder={placeholder}
              classes={{ richTextRoot: classes.postContent }}
              fileStackApiKey={filestackApiKey}
              enableImageDropAndPastePlugin
              required
            />
            <WizardStepField
              type={WizardStepField.types.TEXT_LIST}
              name="postActionItems"
              wizardFieldLabel="Action items"
              wizardFieldTooltip="Your plans and main takeaways from this insight"
              label="Action item"
              maxItemsInList={10}
              listItemTextMaxLength={400}
            />
            <FilesList
              files={uploadFiles}
              showFileSize
              fileOnDelete={fileOnDeleteHandler}
              allowDownload={false}
              layout={FilesListLayout.TWO_COLUMNS}
            />
            <Divider className={classes.actionsDivider} />

            <Grid container justifyContent="space-between" className={classes.actionsContainer}>
              <Grid item>
                <WizardStepField
                  type={WizardStepField.types.FILE_UPLOAD_2}
                  name="uploadFiles"
                  apikey={filestackApiKey}
                  maxSize={10 * 1024 * 1024}
                  hideMaxFileSize
                  classes={{ fileUploadButton: classes.fileUploadButton }}
                  buttonProps={{
                    size: 'large',
                    classes: {
                      startIcon: classes.fileUploadButtonStartIcon,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={submit}
                  label="Publish update"
                  disabled={invalid || loading}
                />
              </Grid>
            </Grid>
          </>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  submit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
  topics: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

WizardSteps.defaultProps = {
  topics: [],
  formValues: {},
};

export default WizardSteps;
