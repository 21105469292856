import React, { FC, useCallback } from 'react';
import {
  CompanyCreate,
  CompanyProfile,
  ContactDetails,
  Country,
  ProjectLead,
  UserActionToken,
  UserUpdate,
} from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { Link, useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { FullProjectLeadByIdQuery } from 'growl-graphql/dist/queries/FullProjectLeadByIdQuery';

import { ProjectLeadDetailsPageParams } from './types';
import RejectProjectLeadCard from './components/RejectProjectLeadCard';
import ApproveProjectLeadCard from './components/ApproveProjectLeadCard';
import getUserActionTokenUrl from '../../logic/getUserActionTokenUrl';
import getAppLoginUrl from '../../logic/getAppLoginUrl';
import ProjectBriefTargeting, {
  getBooleanAnswer,
} from '../../components/cpanel/components/organisms/Project/ProjectBriefTargeting';
import LinkToProject from '../../components/cpanel/components/atoms/Links/LinkToProject';

import { COUNTRIES_LABELS_LOOKUP } from '../../../fe_common/client/app/enums';
import { RefreshSpinner } from '../../../fe_common/client/components/atoms';
import { muiIcons } from '../../../fe_common/client/app/icons';
import { CountriesLabelsLookup } from '../../../fe_common/client/app/types';
import { Button } from '../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../fe_common/client/components/display';
import CopyHtmlElementToClipboardButton from '../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';

import useStyles from './style';

const ProjectLeadDetailsPage: FC = () => {
  const classes = useStyles();
  const { projectLeadId: projectLeadIdFromUrl } = useParams<ProjectLeadDetailsPageParams>();

  const [redirectTo, setRedirectTo] = React.useState('');

  const {
    loading,
    data: fullProjectLeadByIdQueryData,
    refetch,
  } = useQuery(FullProjectLeadByIdQuery.query, {
    variables: {
      projectLeadId: projectLeadIdFromUrl,
    },
    skip: !projectLeadIdFromUrl,
  });

  const projectLead = fullProjectLeadByIdQueryData?.projectLead || ({} as ProjectLead);
  const projectLeadId = projectLead?.id;

  const { isRejected, isProjectCreated, isUserUpdated, projectCreatedBy, userActionToken, projectId } =
    projectLead || ({} as ProjectLead);

  const { name, companyProfile /* , clientMetadata */ } = projectLead?.companyCreate || ({} as CompanyCreate);

  const { maypleUniversalTrackingId } = projectLead.userUpdate || ({} as UserUpdate);
  const { hasExperienceManagingDigitalCampaigns, contactDetails } = companyProfile || ({} as CompanyProfile);
  const { websiteAddress, emailAddress, phoneNumber, postalAddress } = contactDetails || ({} as ContactDetails);
  const { token, state } = userActionToken || ({} as UserActionToken);

  const tokenUrl = getUserActionTokenUrl(token, { emailAddress, maypleUniversalTrackingId });
  const appLoginUrl = getAppLoginUrl(projectCreatedBy);

  const copyTokenLinkToClipboard = useCallback(() => {
    /* Select the text field */

    if (!navigator.clipboard) {
      /* Get the text field */
      const copyText: HTMLElement | null = document.getElementById('linkToCopy');

      if (copyText instanceof HTMLInputElement) {
        /* Copy the text inside the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand('copy');
      }
    } else if (isUserUpdated) {
      navigator.clipboard.writeText(appLoginUrl);
    } else {
      navigator.clipboard.writeText(tokenUrl);
    }
  }, [appLoginUrl, isUserUpdated, tokenUrl]);

  const onApproveProjectLeadSuccessHandler = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const onRejectProjectLeadSuccessHandler = useCallback(async () => {
    await refetch();
    setRedirectTo('/project_leads');
  }, [refetch]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (loading) {
    return <RefreshSpinner />;
  }

  return (
    <div className="m-b-16" style={{ position: 'relative' }}>
      <CopyHtmlElementToClipboardButton
        style={{ position: 'absolute', right: 0, top: 5 }}
        querySelector="#project-lead"
        label="Copy to Slack"
        size="small"
      />
      <div id="project-lead" className={classes.hidden}>
        <div>
          <Link to={`/project_leads/${projectLeadId}`}>Project Lead: {name}</Link>
        </div>
        {isProjectCreated && !!projectId && (
          <div>
            <Link to={`/projects/${projectId}`}>project details page</Link>
          </div>
        )}
      </div>
      <Link to="/project_leads">
        <Button label="Back To Project Leads" color="primary" />
      </Link>
      <TextDisplay variant="h5" bold>
        Project Lead Details Page
        {isRejected && <span className="red-text">{' (Rejected!)'}</span>}
        {isProjectCreated && <span className="green-text">{' (Approved!)'}</span>}
      </TextDisplay>
      <TextDisplay>
        Token state: <strong>{state}</strong>
      </TextDisplay>
      {isProjectCreated && !!projectId && <LinkToProject projectId={projectId}>project details page</LinkToProject>}
      {state === 'READY' && !isUserUpdated && (
        <>
          <TextDisplay>
            You can send this link with token attached, to let the client continue from the place they left. For
            example, the user did create a project lead but did not sign up. with this link, he will continue from the
            sign up step, automatically connecting him to this project and company.
          </TextDisplay>
          <div className={classes.textAndCopyButtonWrapper}>
            <TextField fullWidth type="text" value={tokenUrl} id="linkToCopy" />
            <div>
              <Button
                startIcon={muiIcons.copyFile}
                label="COPY TOKEN LINK TO CLIPBOARD"
                onClick={copyTokenLinkToClipboard}
                size="small"
                color="primary"
                variant="outlined"
              />
            </div>
          </div>
        </>
      )}
      {isUserUpdated && (
        <>
          <TextDisplay>
            The user did sign up. You can send this link with email prefilled, to let the client continue from the place
            they left.
          </TextDisplay>
          <div className={classes.textAndCopyButtonWrapper}>
            <TextField variant="outlined" fullWidth type="text" value={appLoginUrl} id="linkToCopy" inputMode="none" />
            <Button
              startIcon={muiIcons.copyFile}
              label="COPY LOGIN LINK TO CLIPBOARD"
              onClick={copyTokenLinkToClipboard}
              size="small"
              color="primary"
              variant="outlined"
            />
          </div>
        </>
      )}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <ProjectBriefTargeting project={projectLead.projectCreate} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <TextDisplay variant="h6">Company</TextDisplay>
              <ul>
                <li>
                  Name: <b>{name}</b>
                </li>
                <li>
                  Email: <b>{emailAddress}</b>
                </li>
                <li>
                  Phone: <b>{phoneNumber || 'N/A'}</b>
                </li>
                <li>
                  Website: <b>{websiteAddress}</b>
                </li>
                <li>
                  Company origin:{' '}
                  <strong>
                    {(COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[postalAddress.country as Country]}
                  </strong>
                </li>
                <li>Has marketing experience: {getBooleanAnswer(hasExperienceManagingDigitalCampaigns)}</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        {!isRejected && !isProjectCreated && (
          <>
            <Grid item xs={12} md={6}>
              <ApproveProjectLeadCard projectLeadId={projectLeadId} onSuccess={onApproveProjectLeadSuccessHandler} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RejectProjectLeadCard projectLeadId={projectLeadId} onSuccess={onRejectProjectLeadSuccessHandler} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ProjectLeadDetailsPage;
