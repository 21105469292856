import { useQuery } from '@apollo/react-hooks';
import { Marketer } from '@mayple/types';
import { GetMarketersByWorkingGroupQuery } from 'growl-graphql/dist/queries/GetMarketersByWorkingGroupQuery';

import { ApolloError } from 'apollo-client';

export type MarketersByWorkingGroupData = {
  data: Marketer[] | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const useMarketersByWorkingGroupData = (workingGroup: string[]): MarketersByWorkingGroupData => {
  const { data, loading, error } = useQuery(GetMarketersByWorkingGroupQuery.query, {
    variables: { workingGroup },
    skip: !workingGroup || !workingGroup?.length,
  });

  return {
    data: data?.searchForMarketers,
    loading,
    error,
  };
};

export default useMarketersByWorkingGroupData;
