import React, { useCallback } from 'react';
import { Project } from '@mayple/types';
import Tooltip from '@material-ui/core/Tooltip';

import BriefReportDialog from '../BriefReportDialog';
import { Button } from '../../inputs';
import { muiIcons } from '../../../app/icons';
import useDialog from '../../../hooks/useDialog';

interface ProjectBriefPreviewButtonProps {
  project: Project | undefined;
  disabled?: boolean;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: (label: string) => void;
  openDeepBrief?: boolean;
  setDeepBrief?: any;
}

const DEFAULT_LABEL = 'VIEW YOUR BRIEF';

const ProjectBriefPreviewButton: React.FC<ProjectBriefPreviewButtonProps> = (props) => {
  const {
    project,
    disabled = false,
    size = 'medium',
    onClick,
    openDeepBrief = false,
    setDeepBrief = null,
    label = DEFAULT_LABEL,
  } = props;
  // const label = 'VIEW YOUR BRIEF';

  const { open: briefReportDialogOpen, openDialog, closeDialog } = useDialog(!disabled && openDeepBrief);

  const handleCloseDialog = () => {
    if (openDeepBrief && typeof setDeepBrief === 'function') {
      setDeepBrief(false);
    }
    closeDialog();
  };

  const onClickHandler = useCallback(() => {
    openDialog();
    if (onClick && typeof onClick === 'function') {
      onClick(label);
    }
  }, [label, onClick, openDialog]);

  if (!project || !project.deepBrief) {
    return null;
  }

  const extraProps: Record<string, any> = {
    style: { margin: 0 },
  };

  if (!disabled) {
    extraProps.id = 'openBriefReportButton';
  }

  return (
    <div>
      <Tooltip
        placement="top"
        title={disabled ? 'Brief was not filled yet.' : 'Additional information about the project'}
      >
        <span>
          <Button
            label={label}
            variant="contained"
            color="primary"
            disabled={disabled}
            startIcon={muiIcons.eye}
            size={size}
            onClick={onClickHandler}
            {...extraProps}
          />
        </span>
      </Tooltip>
      <BriefReportDialog open={briefReportDialogOpen} project={project} onClose={handleCloseDialog} />
    </div>
  );
};

export default ProjectBriefPreviewButton;
