import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import useStyles from './style';

interface MoreInfoTooltipProps {
  text: string;
}

const HelpTooltip: React.FC<MoreInfoTooltipProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Tooltip placement="right" title={text} className={classes.tooltipContainer}>
      <span>
        <InfoOutlinedIcon className={classes.tooltipIcon} />
      </span>
    </Tooltip>
  );
};

export default HelpTooltip;
