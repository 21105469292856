import { TFunction } from 'i18next';
import { Variant } from '@material-ui/core/styles/createTypography';

export const TRANSLATION_NS = 'starsVote';

export const getRatingLabel = (value: number | null | undefined, t: TFunction): string => {
  if (!value) {
    return '';
  }

  return t(value.toString(), '');
};

export const getTextVariant = (size: 'small' | 'medium' | 'large' | undefined): Variant => {
  if (size === 'small') {
    return 'caption';
  }
  if (size === 'medium') {
    return 'body2';
  }
  return 'body1';
};
