import React from 'react';

import { MarketerSelectionConfigurationProps } from './types';
import useMarketerSelectionConfiguration from './useMarketerSelectionConfiguration';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SwitchField from '../../../../../fe_common/client/components/atoms/SwitchField';
import NumericField from '../../../../../fe_common/client/components/atoms/NumericField';
import { Button } from '../../../../../fe_common/client/components/inputs';
import CalloutMessageCollapse from '../../../../../fe_common/client/components/common/CalloutMessageCollapse';

import useStyles from './style';

const MarketerSelectionConfiguration: React.FC<MarketerSelectionConfigurationProps> = (props) => {
  const classes = useStyles();
  const { projectId } = props;

  const {
    editable,
    checked,
    toggleChecked,
    numOfExperts,
    numOfExpertsChangeHandler,
    errorMessage,
    saving,
    onSaveHandler,
    message,
    messageType,
    clearMessage,
  } = useMarketerSelectionConfiguration(projectId);

  return (
    <Section noBorder noTopMargin title="Automatic Expert Selection Configuration">
      <div className={classes.root}>
        <SwitchField
          name="enableAutomaticExpertSelection"
          checked={checked}
          onChange={toggleChecked}
          label="Enable Automatic Expert Selection"
          disabled={!editable}
        />
        <NumericField
          fullWidth
          disabled={!checked || !editable}
          name="numOfClients"
          label="Number of Experts the client should meet"
          value={numOfExperts}
          minimumValue={0}
          maximumValue={9}
          error={!!errorMessage}
          meta={{ error: errorMessage, submitFailed: !!errorMessage, invalid: !!errorMessage }}
          placeholder="Number of Experts the client should meet"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={numOfExpertsChangeHandler}
          className={classes.numOfClients}
        />
        <div>
          <Button
            label="Save"
            loading={saving}
            onClick={onSaveHandler}
            variant="contained"
            color="primary"
            disabled={!editable}
          />
        </div>
        {!!message && (
          <div>
            <CalloutMessageCollapse message={message} type={messageType} onMessageDisappear={clearMessage} margin />
          </div>
        )}
      </div>
    </Section>
  );
};

export default MarketerSelectionConfiguration;
