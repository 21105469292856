import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import { TextDisplay } from '../../../display';
import SkillLevelButtons from '../../SkillLevelButtons';
import { pairsToLabelLookup } from '../../../../app/enums';
import AutocompleteField, { AUTO_COMPLETE_ACTIONS } from '../AutocompleteField';

function AutoCompleteRadioButtons(props) {
  const { input, enums, hotEnums, floatingLabelText } = props;

  const labels = useMemo(() => pairsToLabelLookup(enums), [enums]);

  const value = useMemo(() => input.value || [], [input]);

  const handleOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (selected, actionMeta) => {
      // console.log('selected', selected);
      // console.log('actionMeta', actionMeta);
      // console.log('value', value);

      if (actionMeta) {
        let toUpdate = [...value];

        try {
          if (actionMeta.action === AUTO_COMPLETE_ACTIONS.VALUE_ADDED) {
            toUpdate.push({
              skillType: actionMeta.data.value,
              yearsOfExperience: 0,
              skillLevel: 1,
            });
          } else if (actionMeta.action === AUTO_COMPLETE_ACTIONS.VALUE_DELETED) {
            toUpdate = toUpdate.filter(({ skillType }) => skillType !== actionMeta.data.value);
          } else if (actionMeta.action === AUTO_COMPLETE_ACTIONS.VALUES_CLEARED) {
            toUpdate = [];
          }
        } catch (e) {
          toUpdate = [...value];
        }

        input.onChange(toUpdate);
      }
    },
    [input, value],
  );

  const handleOnSkillClick = useCallback(
    (skillType, skillLevel) => {
      const index = value.findIndex((item) => item.skillType === skillType);

      if (index > -1) {
        const toUpdate = [...value];
        // update the skill level
        toUpdate[index].skillLevel = parseInt(skillLevel, 10);
        // update external listener
        input.onChange(toUpdate);
      }
    },
    [input, value],
  );

  const autoCompleteValue = useMemo(
    () => enums.filter((enumItem) => value.find((item) => enumItem.value === item.skillType)),
    [enums, value],
  );

  return (
    <div>
      <AutocompleteField
        meta={{}}
        input={{
          value: autoCompleteValue,
          onChange: handleOnChange,
        }}
        enums={enums}
        hotEnums={hotEnums}
        isMulti
        addActionMeta
        label={floatingLabelText}
      />
      {value && value.length > 0 && (
        <div style={{ marginTop: 32 }}>
          <TextDisplay bold variant="overline">
            RATE YOUR SKILL LEVEL
          </TextDisplay>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="center" style={{ marginBottom: 4 }}>
                <Grid item xs={12} sm={2} />
                <Grid item xs={12} sm={10}>
                  <Grid container justifyContent="space-between">
                    <TextDisplay variant="caption">Just learning</TextDisplay>
                    <TextDisplay variant="caption">Pro</TextDisplay>
                  </Grid>
                </Grid>
              </Grid>
              {value.map((skill) => (
                <Slide direction="up" in mountOnEnter unmountOnExit key={`${skill.skillType}-skill-radio-button`}>
                  <SkillLevelButtons onClick={handleOnSkillClick} skill={skill} labelsLookup={labels} />
                </Slide>
              ))}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

AutoCompleteRadioButtons.propTypes = {
  enums: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })).isRequired,
  hotEnums: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  input: PropTypes.shape({
    // can be passed by other component, not only redux form
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  floatingLabelText: PropTypes.string,
};

AutoCompleteRadioButtons.defaultProps = {
  hotEnums: [],
  floatingLabelText: '',
  input: {
    onChange: () => {},
    value: [],
  },
};

export default AutoCompleteRadioButtons;
