import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';

import { getFilterCount } from '../FeedFilter/logic';

function FilterTriggerButton({ onClick, filter }) {
  const filterCount = getFilterCount(filter);

  return (
    <IconButton onClick={onClick}>
      <Badge badgeContent={filterCount} color="secondary">
        <FilterListIcon />
      </Badge>
    </IconButton>
  );
}

FilterTriggerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

export default FilterTriggerButton;
