import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

export const styles = (theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    richTextRoot: {
      '& .ql-container': {
        // minHeight: '20rem',
        minHeight: (props) => `calc(${props?.rows || 1} * '1.5em')`,
      },
      '& .ql-editor': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1.fontSize,
      },
    },
    uploadOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: colors.whiteOpacity(0.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      zIndex: 100,
    },
    linearProgressRoot: {
      width: '100%',
      height: 5,
      borderRadius: 5,
    },
  });
