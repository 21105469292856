import { useDialog } from '../../../../../fe_common/client/hooks';

const useProjectHealthCheckStopReasonDialog = (
  isOpen = false,
): {
  isProjectHealthCheckStopReasonDialogOpen: boolean;
  openProjectHealthCheckStopReasonDialog: () => void;
  closeProjectHealthCheckStopReasonDialog: () => void;
} => {
  const { open, openDialog, closeDialog } = useDialog(isOpen);

  return {
    isProjectHealthCheckStopReasonDialogOpen: open,
    openProjectHealthCheckStopReasonDialog: openDialog,
    closeProjectHealthCheckStopReasonDialog: closeDialog,
  };
};
export default useProjectHealthCheckStopReasonDialog;
