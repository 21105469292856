import React, { FC } from 'react';

import { TextDisplay } from '../../display';
import useStyles from './style';

export type LoadingOverlayProps = {
  message?: string | null | undefined;
  icon?: JSX.Element | null | undefined;
  classes?: Record<string, string>;
};

const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  const { message = 'loading...', icon = null } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {icon}
      <TextDisplay className={classes.message} variant="caption">
        {message}
      </TextDisplay>
    </div>
  );
};

export default LoadingOverlay;
