import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      paddingTop: 0,
    },
    marketerSearchRoot: {
      maxWidth: 600,
    },
    unAssignWrapper: {
      alignSelf: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    activeMarketer: {
      display: 'flex',
      borderBottom: `1px solid ${colors.gray}`,
      paddingBottom: 24,
      marginBottom: 24,
    },
    selectNewMarketer: {
      display: 'flex',
      flex: '1 1 100%',
      width: '100%',
      gap: '16px',
      '& > div': {
        width: '100%',
      },
    },
  }),
);

export default useStyles;
