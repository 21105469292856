/**
 * Calculate the price after discount
 * @param cost
 * @param discount
 */
// eslint-disable-next-line import/prefer-default-export
export const getDiscountedCost = (cost: number, discount: number): number => {
  if (!cost) {
    return 0;
  }

  if (!discount || discount <= 0 || discount > 100) {
    return cost;
  }

  return cost * ((100 - discount) / 100);
};
