import React, { FC } from 'react';
import { FundAccount, ProjectCycle, ProjectLifeCycleStatus } from '@mayple/types';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import {
  getCutsSplit,
  getPpcServicesFee,
  getSetupFee,
  getTotalCost,
  getNonPPCServicesCost,
  getCycleHeader,
  getCycleStatus,
  getFundAccountBalance,
  getCurrCycleHeaderPrefix,
  getDeliverablesDetails,
} from './logic';
import CycleDataRow from './CycleDataRow';
import { RowData } from './CycleDataRow/types';
import HideByProjectStatus from '../../../../../../pages/ProjectDetailsPage/components/HideByProjectStatus';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import { Button } from '../../../../../../../fe_common/client/components/inputs';

import useStyles from './style';

interface CyclesSideBySideProps {
  projectId: number;
  currentCycle: ProjectCycle;
  nextCycle?: ProjectCycle | null;
  currency: string;
  showActual: boolean;
  projectLifeCycleStatus: ProjectLifeCycleStatus;
  fundAccount?: FundAccount;
  classes?: Record<string, string>;
}

const CyclesSideBySide: FC<CyclesSideBySideProps> = (props) => {
  const {
    projectId,
    currentCycle,
    nextCycle = null,
    currency,
    showActual,
    projectLifeCycleStatus,
    fundAccount = {},
  } = props;

  const classes = useStyles(props);

  // console.log('currentCycle', currentCycle);
  // console.log('nextCycle', nextCycle);

  const hasNextCycle = nextCycle !== null;
  const currCycleHeaderPrefix = getCurrCycleHeaderPrefix(currentCycle, nextCycle);
  const currCycleHeader = getCycleHeader(currentCycle, currCycleHeaderPrefix);
  const nextCycleHeader = getCycleHeader(nextCycle, 'Next Cycle | ');

  const setupFee = getSetupFee(currentCycle, nextCycle, classes);
  const nonPPCServicesCost = getNonPPCServicesCost(currentCycle, nextCycle, currency, classes);
  const deliverablesDetails = getDeliverablesDetails(currentCycle, nextCycle, currency, classes, showActual);
  const ppcServicesFee = getPpcServicesFee(currentCycle, nextCycle, currency, classes, showActual);
  const totalCost = getTotalCost(currentCycle, nextCycle, currency, classes, showActual);
  const cutsSplit = getCutsSplit(currentCycle, nextCycle, currency, classes, showActual);
  const cycleStatus = getCycleStatus(currentCycle, nextCycle, classes);
  const alreadyPaid = getFundAccountBalance(fundAccount, classes);

  const values = [
    ...setupFee,
    ...nonPPCServicesCost,
    ...deliverablesDetails,
    ...ppcServicesFee,
    ...totalCost,
    ...alreadyPaid,
    ...cutsSplit,
    ...cycleStatus,
  ];

  return (
    <Table className={classNames(classes.root, { [classes.cycleRootNoNextCycle]: !hasNextCycle })}>
      <TableHead>
        <TableRow>
          <TableCell width="48px" padding="checkbox">
            &nbsp;
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell
            colSpan={2}
            className={classNames(classes.cycleHeader, { [classes.cycleHeaderNoNextCycle]: !hasNextCycle })}
          >
            <TextDisplay variant="h5">{currCycleHeader}</TextDisplay>
          </TableCell>
          {nextCycle && (
            <TableCell colSpan={2} className={classes.cycleHeader}>
              <TextDisplay variant="h5">{nextCycleHeader}</TextDisplay>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {(values as RowData[]).map((rowData) => (
          <CycleDataRow
            key={`table-row-cycle-side-by-side-${rowData?.label}`}
            hasNextCycle={hasNextCycle}
            rowData={rowData}
          />
        ))}
        <TableRow>
          <TableCell width="48px">&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell colSpan={2} className={classes.alignRight}>
            <HideByProjectStatus status={projectLifeCycleStatus} hideOnDiscovery>
              <Button
                label="View Current Cycle Info..."
                link={`/projects/${projectId}/cycles/${currentCycle?.id}`}
                style={{ margin: 0 }}
                color="primary"
                variant="outlined"
              />
            </HideByProjectStatus>
          </TableCell>
          {nextCycle && (
            <TableCell colSpan={2} className={classes.alignRight}>
              <Button
                label="View Next Cycle Info..."
                link={`/projects/${projectId}/cycles/${nextCycle?.id}`}
                style={{ margin: 0 }}
                color="primary"
                variant="outlined"
              />
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CyclesSideBySide;
