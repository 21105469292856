import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';
import LinkToProject from '../../../components/cpanel/components/atoms/Links/LinkToProject';

function MarketerProjects({ projects = [] }) {
  return (
    <div>
      {(projects || [])?.length === 0 && <CalloutMessage message="No Projects found." />}
      {projects?.length > 0 &&
        projects.map(({ project }) => (
          <div key={project.id}>
            <LinkToProject projectId={project.id}>{project.name}</LinkToProject>
            <span> - {project.projectLifeCycleStatus}</span>
            <span>
              {' '}
              - <Link to={`/companies/${project.companyId}`}>(Company: {project.company.name})</Link>
            </span>
            <hr />
          </div>
        ))}
    </div>
  );
}

MarketerProjects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MarketerProjects;
