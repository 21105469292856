import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Layout, SelectionIndicatorType } from '../SelectionCard/types';

export type EnumGroup = {
  label: string;
  value: string;
  priority: number;
};

export enum CardsInRow {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  all = 'all',
  userDefined = 'userDefined',
}

export type CheckboxCardData = {
  value: string;
  label: string;
  svgIcon?: string;
  fontIcon?: string;
  icon?: React.ReactNode | React.FC | string | undefined;
  group?: string;
  subtitle?: string;
  isDeprecated?: boolean;
  onClick?: () => void;
};

export interface CheckboxCardProps {
  enums: CheckboxCardData[];
  cardsInRow?: CardsInRow;
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  allowSelectAll?: boolean;
  splitEnumsByGroup?: boolean;
  bigCard?: boolean;
  showError?: boolean;
  leftCard?: boolean;
  layout?: Layout;
  maximumSelectedValues?: number;
  hideMaximumSelectedValuesWarning?: boolean;
  selectionIndicatorType?: SelectionIndicatorType;
  groups?: EnumGroup[];
  classes?: Record<string, string>;
}
