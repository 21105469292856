import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import { TextDisplay } from '../../../../fe_common/client/components/display';
import { Button } from '../../../../fe_common/client/components/inputs';
import { useStepStyle } from '../style';

interface PayInReportStep1Props extends Partial<StepWizardChildProps> {
  onStart: () => void;
}

const PayInReportStep1: React.FC<PayInReportStep1Props> = (props) => {
  const classes = useStepStyle(props);
  const { onStart, nextStep } = props;
  const onClickNextHandler = () => {
    onStart();
    nextStep?.();
  };

  return (
    <div id="step1" className={classes.root}>
      <TextDisplay align="center">Click "Next" to generate Pay-In report for Mailchimp</TextDisplay>
      <Button label="Next" onClick={onClickNextHandler} color="primary" variant="contained" size="large" />
    </div>
  );
};

export default PayInReportStep1;
