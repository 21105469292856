import React, { FC, useEffect, useMemo, useState } from 'react';
import { LatestFeedPostByTopic } from '@mayple/types';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import InsightsTable from './InsightsTable';
import useInsightsFeedReportData from './useInsightsFeedReportData';

import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import { RefreshSpinner } from '../../../fe_common/client/components/atoms';
import { TextDisplay } from '../../../fe_common/client/components/display';
import { titleCase } from '../../../fe_common/client/services/utils';

const InsightsFeedReport: FC = () => {
  const [topic, setTopic] = useState<string>('');

  const { data, error, loading } = useInsightsFeedReportData();

  const topics = useMemo(() => {
    if (data.length > 0) {
      const [firstRow] = data;
      return (firstRow?.participants?.[0]?.feedStatistics?.feedLatestPostDateByTopic || []).map(
        (latestFeedPostByTopic: LatestFeedPostByTopic | null | undefined) => {
          const { topic: t } = latestFeedPostByTopic || ({} as LatestFeedPostByTopic);
          return t;
        },
      );
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (topics && topics.includes('MONTHLY_UPDATE')) {
      setTopic('MONTHLY_UPDATE');
    }
  }, [topics]);

  return (
    <div>
      {loading && (
        <div>
          <TextDisplay variant="h5" gutterBottom>
            Generating report, give it a moment...
          </TextDisplay>
          <RefreshSpinner linear />
        </div>
      )}
      {!!error && !loading && <QueryErrorMessage error={error} message="Error loading insights feed report" />}
      <FormControl
        style={{
          minWidth: 300,
          margin: '16px 0',
        }}
      >
        <InputLabel shrink>Select topic to filter</InputLabel>
        <Select
          value={topic}
          native
          onChange={(e) => {
            if (e.target.value) {
              setTopic(e.target.value as string);
            }
          }}
          disabled={topics.length === 0}
        >
          <option label="Please select" value="" />
          {topics.map((t: string) => (
            <option key={`feedLatestPostDateByTopic-select-${t}`} value={t}>
              {titleCase(t)}
            </option>
          ))}
        </Select>
      </FormControl>
      <InsightsTable topic={topic} />
    </div>
  );
};

export default InsightsFeedReport;
