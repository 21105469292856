import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from '../../../app/theme';

const styles = {
  itemContainer: {
    borderRadius: 8,
    padding: 24,
    height: '100%',
    background: colors.whiteOpacity(0.6),
  },
};

function ExpertProfileInfoCard({ children, classes }) {
  return <div className={classes.itemContainer}>{children}</div>
}

ExpertProfileInfoCard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

ExpertProfileInfoCard.defaultProps = {
  children: null,
};

export default withStyles(styles)(ExpertProfileInfoCard);
