import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/es/styles/withStyles';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { TriggerProjectIntermediateBillingMutation } from 'growl-graphql/dist/mutations/TriggerProjectIntermediateBillingMutation';

import {
  setEntityOperationResponseNotification,
  setNotification,
} from '../../../../fe_common/client/services/notification';
import { formatFloatCurrency } from '../../../../fe_common/client/services/utils';
import Section from '../../../../fe_common/client/components/atoms/Section';
import { Button } from '../../../../fe_common/client/components/inputs';

const styles = () => ({
  root: {},
  sectionContent: {
    padding: 12,
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
});

function TriggerProjectIntermediateBilling(props) {
  const { project = {}, triggerProjectIntermediateBillingMutation, classes, cycles } = props;
  const { fundAccount: projectFundAccount, id: projectId, currentCycle } = project;
  const { id: currentCycleId } = currentCycle;

  const currentCycleFundAccount = cycles.find(({ id }) => id === currentCycleId)?.fundAccount;
  const projectBalance = projectFundAccount?.balance || 0;
  const currentCycleBalance = currentCycleFundAccount?.balance || null;
  const currentCycleTotalCost = project?.currentCycle?.estimatedTotalCost || 0;
  // First try to show the cycle balance if exists.
  const balanceToUse = currentCycleBalance != null ? currentCycleBalance : projectBalance;
  const balanceToUseTitle = currentCycleBalance != null ? 'cycle' : 'project';
  const isBalanceBiggerThanCost = currentCycleTotalCost <= balanceToUse;
  const toCharge = Math.max(currentCycleTotalCost - balanceToUse, 0);

  const handleOnTriggerBilling = async () => {
    const ans = window.confirm(`Confirm billing action for project ${projectId}`);

    if (ans) {
      try {
        const response = await triggerProjectIntermediateBillingMutation.mutate({ projectId });
        if (response) {
          setEntityOperationResponseNotification(response.triggerProjectIntermediateBilling);
        } else {
          setNotification('Error. No response from server', 'error');
        }
      } catch (e) {
        setNotification('Triggering Intermediate Billing Failed', 'error');
      }
    }
  };

  return (
    <Section title="Trigger Intermediate Billing">
      <Paper square elevation={1} classes={{ root: classes.sectionContent }}>
        <p>
          This will execute a charge of <span className={classes.green}>{formatFloatCurrency(toCharge)}</span> to
          complete the estimated total cost of{' '}
          <span className={classes.green}>{formatFloatCurrency(currentCycleTotalCost)}</span> of the project (Existing{' '}
          {balanceToUseTitle} funds balance of {formatFloatCurrency(balanceToUse)}). <br />
          NOTE: Make sure you refresh the page to see the latest up to date values before using this!
        </p>
        {isBalanceBiggerThanCost && (
          <p>
            NOTE: This will not cause any charge as the funds of the project are sufficient to cover the estimated total
            cost.
          </p>
        )}
        <Button
          label="Trigger billing"
          color="secondary"
          variant="contained"
          disabled={isBalanceBiggerThanCost || triggerProjectIntermediateBillingMutation.loading}
          onClick={handleOnTriggerBilling}
        />
      </Paper>
    </Section>
  );
}

TriggerProjectIntermediateBilling.propTypes = {
  project: PropTypes.object.isRequired,
  cycles: PropTypes.array.isRequired,
  triggerProjectIntermediateBillingMutation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withMutation(TriggerProjectIntermediateBillingMutation),
)(TriggerProjectIntermediateBilling);
