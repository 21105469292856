import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { WizardSteps } from './steps';
import projectBilling from '../../../../pages/ProjectBillingPage/logic';

import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';

const ProjectCycleFinalizationEditWizard = (props) => {
  const {
    cycleId,
    project,
    cycles,
    updateProjectCycleMutation,
    updateProjectCycleAdditionalServicesMutation,
    updateProjectCyclePauseDurationsMutation,
    onSuccessfulUpdate,
  } = props;

  const formName = `ProjectCycleFinalizationEditWizard${cycleId}`;

  const { id: projectId } = project;
  const initialValues = useMemo(
    () => projectBilling.getInitialValuesForElapsedCycle(cycles, cycleId),
    [cycleId, cycles],
  );
  // console.log('ProjectCycleFinalizationEditWizard.initialValues=', initialValues);
  const { cycle, firstCycleId } = useMemo(() => {
    const c = cycles.find(({ id }) => id === cycleId);
    return {
      cycle: c,
      firstCycleId: cycles[0].id,
    };
  }, [cycles, cycleId]);
  // console.log('ProjectCycleFinalizationEditWizard.cycle=', cycle);
  const [redirectTo, setRedirectTo] = useState(null);
  const isFirstCycle = cycleId === firstCycleId;

  const [updateProjectCycleAdditionalServices, updateProjectCycleAdditionalServicesContext] =
    updateProjectCycleAdditionalServicesMutation;
  const [updateProjectCycle, updateProjectCycleContext] = updateProjectCycleMutation;
  const [updateProjectCyclePauseDurations, updateProjectCyclePauseDurationsMutationContext] =
    updateProjectCyclePauseDurationsMutation;
  const errors = [];

  // Gather graphql errors if exists, and pass it to steps render for additional helpful info.
  [
    updateProjectCycleAdditionalServicesContext,
    updateProjectCycleContext,
    updateProjectCyclePauseDurationsMutationContext,
  ].forEach(({ error }) => {
    if (error && error.graphQLErrors) {
      for (const e of error.graphQLErrors) {
        errors.push(e);
      }
    }
  });

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  async function handleFormSubmit(formValuesSubmitted, dispatch, formProps) {
    // console.log(
    //   'ProjectCycleFinalizationEditWizard.formValuesSubmitted(formValuesSubmitted=',
    //   formValuesSubmitted,
    //   ')'
    // );

    const isBillingUpdateSucceeded = await projectBilling.onSubmitForElapsedCycle(
      formValuesSubmitted,
      project,
      cycles,
      cycleId,
      updateProjectCycle,
      updateProjectCycleAdditionalServices,
      updateProjectCyclePauseDurations,
      isFirstCycle,
    );

    if (isBillingUpdateSucceeded) {
      await onSuccessfulUpdate();
      formProps.destroy();
      setRedirectTo(`/projects/${projectId}${window.location.hash || ''}`);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <WizardForm
      form={formName}
      initialValues={initialValues}
      steps={WizardSteps}
      onSubmit={handleFormSubmit}
      project={project}
      cycleId={cycleId}
      isFirstCycle={isFirstCycle}
      cycle={cycle}
      watchAllValues
      destroyOnUnmount
      validate={projectBilling.validateBillingConfig}
      errors={errors}
    />
  );
};

ProjectCycleFinalizationEditWizard.propTypes = {
  project: PropTypes.object.isRequired,
  cycleId: PropTypes.number.isRequired,
  updateProjectCyclePauseDurationsMutation: PropTypes.array.isRequired,
  updateProjectCycleAdditionalServicesMutation: PropTypes.array.isRequired,
  updateProjectCycleMutation: PropTypes.array.isRequired,
};

export default ProjectCycleFinalizationEditWizard;
