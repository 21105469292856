import React from 'react';

import { FilterRender } from 'react-table-6';

export const BillingAgreementFilter: FilterRender = ({ filter, onChange }) => (
  <select
    onChange={(event) => {
      // @ts-ignore
      onChange(event.target.value);
    }}
    value={filter ? filter.value : 'all'}
  >
    <option value="all">Show All</option>
    <option value="manual">Manual</option>
    <option value="creditCard">Credit Card</option>
    <option value="payPal">PayPal</option>
    <option value="none">None</option>
  </select>
);

export default BillingAgreementFilter;
