import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';

import { getFormattedAmount } from './logic';
import { formatDate } from '../../../../../app/utils';
import { toNiceFloat } from '../../../../../services/utils';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';

import { useStyles } from './style';

const AccountMovementsTable = (props) => {
  const classes = useStyles(props);

  const { title, withdrawalsAndDeposits, movementsOrder } = props;
  const [formattedAccountMovements, setFormattedAccountMovements] = useState([]);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    let balance = 0;
    const tempAccountMovements = withdrawalsAndDeposits.map((item) => {
      const { withdrawalTarget, depositSource, amount, __typename } = item;

      if (__typename === 'Withdrawal') {
        balance -= amount;
      } else if (__typename === 'Deposit') {
        balance += amount;
      }

      const formattedAmount = getFormattedAmount(amount, depositSource, withdrawalTarget);

      return {
        ...item,
        formattedAmount,
        balance,
      };
    });

    setFormattedAccountMovements(movementsOrder === 'desc' ? tempAccountMovements.reverse() : tempAccountMovements);
  }, [withdrawalsAndDeposits, setFormattedAccountMovements, movementsOrder]);

  const handleOnAccordionClick = (e, newExpanded) => {
    e.preventDefault();
    setExpanded(newExpanded);
  };

  return (
    <Accordion className={classes.root} expanded={expanded} onChange={handleOnAccordionClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title !== '' && <h5 className={classes.title}>{title}</h5>}
        <TextDisplay variant="h6">Movements</TextDisplay>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Table className={classes.tableRoot}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>Action Date</TableCell>
                  <TableCell className={classes.tableHeadCell}>Description</TableCell>
                  <TableCell className={classes.tableHeadCell}>Deposit/Withdrawal</TableCell>
                  <TableCell className={classes.tableHeadCell}>Fund Account Balance (USD)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedAccountMovements.map((item) => {
                  const {
                    uuid,
                    created,
                    withdrawalTarget,
                    depositSource,
                    name,
                    description,
                    formattedAmount,
                    balance,
                  } = item;

                  return (
                    <Tooltip key={uuid} title={description} placement="top" interactive>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {formatDate(created)}
                        </TableCell>
                        <TableCell>
                          <span>{name}</span>
                        </TableCell>
                        <TableCell
                          className={classNames(classes.number, {
                            [classes.positive]: depositSource,
                            [classes.negative]: withdrawalTarget,
                          })}
                        >
                          {formattedAmount}
                        </TableCell>
                        <TableCell className={classNames(classes.number, classes.total)}>
                          {toNiceFloat(balance)}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

AccountMovementsTable.propTypes = {
  withdrawalsAndDeposits: PropTypes.arrayOf(PropTypes.object),
  movementsOrder: PropTypes.oneOf(['asc', 'desc']),
  title: PropTypes.string,
};

AccountMovementsTable.defaultProps = {
  movementsOrder: 'desc',
  withdrawalsAndDeposits: [],
  title: '',
};
export default AccountMovementsTable;
