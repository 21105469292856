import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import TablePagination from '@material-ui/core/TablePagination';

import Row from './Row';
import CollapsibleRow from './CollapsibleRow';

import { styles } from './style';

function SimpleDataGrid(props) {
  const {
    name,
    rows,
    columns,
    collapsedColumns,
    noDataMessage,
    fixedHeader,
    pageSize,
    rowsPerPageOptions,
    withPaging,
    classes,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowsWithPaging = () => (withPaging ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows);

  return (
    <div>
      <TableContainer className={classes.container}>
        <Table stickyHeader={fixedHeader} classes={{ root: classes.root }}>
          <TableHead classes={{ root: classes.tableHead }}>
            <TableRow classes={{ root: classes.tableHeadRow }}>
              {collapsedColumns && <TableCell classes={{ root: classes.tableHeadCell }} />}
              {columns.map(({ headerName, field, width }) => (
                <TableCell
                  key={`SimpleDataGrid-${name}-TableHead-column-${field}`}
                  width={width}
                  classes={{ root: classes.tableHeadCell }}
                >
                  {headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.tableBody }}>
            {rows.length === 0 && (
              <TableRow classes={{ root: classNames(classes.tableRow, classes.tableEmptyRow) }}>
                <TableCell
                  colSpan={collapsedColumns ? columns.length + 1 : columns.length}
                  classes={{ root: classes.tableCell }}
                >
                  {noDataMessage}
                </TableCell>
              </TableRow>
            )}
            {rows.length > 0 &&
              rowsWithPaging().map((row) => {
                if (collapsedColumns) {
                  return (
                    <CollapsibleRow
                      key={`SimpleDataGrid-${name}-collapsible-row-${row.id}`}
                      tableName={name}
                      row={row}
                      columns={columns}
                      collapsedColumns={collapsedColumns}
                      classes={{
                        tableRow: classes.tableRow,
                        tableCell: classes.tableCell,
                        collapsibleTableRoot: classes.collapsibleTableRoot,
                        collapsibleTableContainer: classes.collapsibleTableContainer,
                        collapsibleTableHead: classes.collapsibleTableHead,
                        collapsibleTableHeadRow: classes.collapsibleTableHeadRow,
                        collapsibleTableHeadCell: classes.collapsibleTableHeadCell,
                        collapsibleTableBody: classes.collapsibleTableBody,
                        collapsibleTableRow: classes.collapsibleTableRow,
                        collapsibleTableEmptyRow: classes.collapsibleTableEmptyRow,
                        collapsibleTableCell: classes.collapsibleTableCell,
                      }}
                    />
                  );
                } else {
                  return (
                    <Row
                      key={`SimpleDataGrid-${name}-row-${row.id}`}
                      tableName={name}
                      row={row}
                      columns={columns}
                      classes={{
                        tableRow: classes.tableRow,
                        tableCell: classes.tableCell,
                      }}
                    />
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {withPaging && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

SimpleDataGrid.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      formatter: PropTypes.func,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
  ).isRequired,
  collapsedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      formatter: PropTypes.func,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
  ),
  rows: PropTypes.arrayOf(PropTypes.object),
  withPaging: PropTypes.bool,
  pageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  fixedHeader: PropTypes.bool,
  noDataMessage: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

SimpleDataGrid.defaultProps = {
  rows: [],
  noDataMessage: 'No data.',
  pageSize: 10,
  rowsPerPageOptions: [5, 10, 25, 50],
  fixedHeader: false,
  withPaging: false,
  collapsedColumns: null,
};

export default withStyles(styles)(SimpleDataGrid);
