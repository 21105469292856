import { InternalTeam, SupervisorType } from '@mayple/types';

const useProjectSupervisorValidation = (
  supervisor: InternalTeam | null | undefined,
  supervisorType: SupervisorType,
  allowedSupervisorTypes: SupervisorType[],
) => {
  const missingProjectSupervisor = !supervisor;

  const missingGS =
    missingProjectSupervisor &&
    (allowedSupervisorTypes || []).includes(SupervisorType.GROWTH_STRATEGIST) &&
    supervisorType === SupervisorType.GROWTH_STRATEGIST;

  const missingMC =
    missingProjectSupervisor &&
    (allowedSupervisorTypes || []).includes(SupervisorType.MARKETING_CONSULTANT) &&
    supervisorType === SupervisorType.MARKETING_CONSULTANT;

  return {
    missingProjectSupervisor,
    missingGS,
    missingMC,
  };
};

export default useProjectSupervisorValidation;
