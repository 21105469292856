import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import TableCell from './TableCell';
import { getCountryLabelFromCountryCode } from './logic';
import LinkToMarketer from '../../../atoms/Links/LinkToMarketer';

import { safeExtractCurrencyValue } from '../../../../../../../fe_common/client/services/utils';

import { useRowStyles } from './styles';

export type MarketersPayoutReviewFullRowProp = {
  dataRow: Record<string, any>;
  onSelectRow: (marketerId: number) => void;
  isSelected: boolean;
  totalNumOfCycles: number;
  numOfSelectedCycles: number;
  classes?: Record<string, string>;
};

const MarketersPayoutReviewFullRow: FC<MarketersPayoutReviewFullRowProp> = (props) => {
  const { dataRow, isSelected, onSelectRow, numOfSelectedCycles, totalNumOfCycles } = props;
  const classes = useRowStyles(props);
  const { projectPayoutItemsLength, marketerId, marketerName, country, balance, currency, preferredPayoutType } =
    dataRow;

  const formattedBalance = safeExtractCurrencyValue(balance, currency);
  const addVerticalAlignTop = projectPayoutItemsLength > 1;
  const formattedCountry = getCountryLabelFromCountryCode(country, 'N/A');
  const onFullRowClickHandler = useCallback(() => {
    onSelectRow(marketerId);
  }, [marketerId, onSelectRow]);

  return (
    <TableRow role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected}>
      <TableCell padding="checkbox" className={classNames({ [classes.fullRowCheckBoxCell]: addVerticalAlignTop })}>
        <Checkbox
          disableRipple
          checked={isSelected}
          onClick={onFullRowClickHandler}
          indeterminate={numOfSelectedCycles > 0 && numOfSelectedCycles < totalNumOfCycles}
        />
      </TableCell>
      <TableCell className={classNames({ [classes.fullRowCell]: addVerticalAlignTop })}>{marketerId}</TableCell>
      <TableCell className={classNames({ [classes.fullRowCell]: addVerticalAlignTop })}>
        <LinkToMarketer marketerId={marketerId} openInNewTab>
          {marketerName}
        </LinkToMarketer>
      </TableCell>
      <TableCell className={classNames({ [classes.fullRowCell]: addVerticalAlignTop })}>{formattedCountry}</TableCell>
      <TableCell className={classNames({ [classes.fullRowCell]: addVerticalAlignTop })}>
        {preferredPayoutType}
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell className={classNames(classes.alignRight, { [classes.fullRowCell]: addVerticalAlignTop })}>
        {formattedBalance}
      </TableCell>
    </TableRow>
  );
};

export default MarketersPayoutReviewFullRow;
