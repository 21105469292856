import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  title: {},
  message: {},
  dialogActions: {},
  confirmButton: {},
  cancelButton: {},
}));

export default useStyles;
