import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    paginatedListHeader: {
      width: '100%',
      display: 'flex',
      flex: '1 1 auto',
      padding: '0.5rem 1rem 0 1rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    secondary: {
      whiteSpace: 'pre',
    },
  })
);

export default useStyles;
