import { LabelValueProps } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getTranslatedLabelValuePairs = <T extends string>(
  labelValuePairs: LabelValueProps<T>[],
  translations: Record<T, string>
): LabelValueProps<T>[] =>
  (labelValuePairs || []).map((labelValuePair) => ({
    ...labelValuePair,
    label: translations?.[labelValuePair?.value] || labelValuePair?.value,
  }));
