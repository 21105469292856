import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(
  createStyles({
    hidden: {
      position: 'absolute',
      top: '-200px',
      left: '-1000px',
    },
    textAndCopyButtonWrapper: {
      display: 'flex',
      gap: '3rem',
      width: '100%',
      marginTop: '1rem',
      marginBottom: '2rem',
      justifyContent: 'space-between',
      '& .MuiTextField-root': {
        width: '100%',
        flexGrow: 1,
        flexShrink: 1,
      },
      '& .MuiButtonBase-root': {
        whiteSpace: 'nowrap',
        flexGrow: 1,
        flexShrink: 1,
      },
    },
  }),
);

export default useStyles;
