import React from 'react';
import { FilterRender } from 'react-table-6';

import useGrowthStrategists from '../../../hooks/internalTeam/useGrowthStrategists';

const AssignedGrowthStrategistFilter: FilterRender = (props) => {
  const { filter, onChange } = props;

  const growthStrategists = useGrowthStrategists();

  return (
    <select
      onChange={(event) => {
        // @ts-ignore
        onChange(event.target.value);
      }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      {growthStrategists.map(({ id, name }) => (
        <option key={`growth-strategist-${id}`} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default AssignedGrowthStrategistFilter;
