import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: '16px',
      background: colors.whiteOpacity(0.6),
      padding: '15px',
      boxShadow: 'none',
      margin: '15px',
      '&:before': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5px',
        margin: '15px 0',
      },
    },
    accordionSummary: {
      width: '100%',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
    },
    answerExpended: {
      color: getGradientColor('sherry', 60),
    },
    accordionDetails: {
      flexDirection: 'column',
    },
    addIcon: {},
    removeIcon: {
      color: colors.cherry,
    },
  })
);

export default useStyles;
