import React, { FC } from 'react';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import Paper from '@material-ui/core/Paper';

import useDeliverables from './logic';
import DeliverablesEditor from './DeliverablesEditor';
import { Button } from '../../../../../fe_common/client/components/inputs';

interface DeliverablesFieldProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

const DeliverablesField: FC<DeliverablesFieldProps> = ({ input, meta }) => {
  const {
    onCreateDeliverable,
    onDeliverableServiceTypeChange,
    onDeliverableUnitTypeChange,
    onDeliverableUnitDescriptionChange,
    onDeliverableUnitCostChange,
    onDeliverableUnitAmountChange,
    onDeliverableDiscountPercentageChange,
    onDeliverableFulfillmentBehaviorChange,
    onDeleteDeliverable,
  } = useDeliverables(input);

  // console.log('packages', packages);

  const { value: deliverables } = input;

  // console.log('DeliverablesField(deliverables=', deliverables, ')');

  return (
    <Paper style={{ padding: 16 }}>
      <DeliverablesEditor
        meta={meta}
        deliverables={deliverables}
        onDeliverableServiceTypeChange={onDeliverableServiceTypeChange}
        onDeliverableUnitTypeChange={onDeliverableUnitTypeChange}
        onDeliverableUnitDescriptionChange={onDeliverableUnitDescriptionChange}
        onDeliverableUnitCostChange={onDeliverableUnitCostChange}
        onDeliverableUnitAmountChange={onDeliverableUnitAmountChange}
        onDeliverableDiscountPercentageChange={onDeliverableDiscountPercentageChange}
        onDeliverableFulfillmentBehaviorChange={onDeliverableFulfillmentBehaviorChange}
        onDeleteDeliverable={onDeleteDeliverable}
      />

      <Button label="Create a Deliverable" variant="outlined" color="primary" onClick={onCreateDeliverable} />
    </Paper>
  );
};

export default DeliverablesField;

export { validateDeliverables } from './logic';
