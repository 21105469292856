/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { ProjectBillingReviewItem, ProjectCyclePhase } from '@mayple/types';
import { compose } from 'redux';
import { ApolloError } from 'apollo-client';
import ReactTable, {
  Column,
  DerivedDataObject,
  FilteredChangeFunction,
  Instance,
  RowInfo,
  SortedChangeFunction,
} from 'react-table-6';
// @ts-ignore
import checkboxHOC from 'react-table-6/lib/hoc/selectTable';
import withStyles from '@material-ui/core/styles/withStyles';

import { BillingCycleFormData } from './types';
import BILLING_CYCLE_TABLE_COLUMNS from './consts';
import { getYearMonthFromDate } from './useProjectBillingReviewQuery';

import { isEmptyObject } from '../../../../../fe_common/client/services/utils';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import SelectInputComponent from '../../../../../fe_common/client/services/reactTableHelpers/SelectInputComponent';
import { filterCaseInsensitiveForReactTable } from '../../../../../fe_common/client/services/reactTableHelpers/filterMethods';

import './rt-override.css';

const CheckboxTable = checkboxHOC(ReactTable);

const styles = () => ({
  root: {
    marginTop: 24,
  },
});

type BillingDataTableProps = {
  keyField: string;
  onFilteredChange: FilteredChangeFunction;
  onSortedChange: SortedChangeFunction;
  items?: ProjectBillingReviewItem[];
  filter?: BillingCycleFormData;
  loading?: boolean;
  error?: ApolloError | undefined;
  onSelectionChange?: (newSelection: string[] | undefined) => void;
  classes?: Record<string, string>;
};

type BillingDataTableState = {
  selectAll: boolean;
  selection: string[];
};

class BillingDataTable extends Component<BillingDataTableProps, BillingDataTableState> {
  checkboxTable: any = null;

  constructor(props: BillingDataTableProps) {
    super(props);

    this.state = {
      selectAll: false,
      selection: [],
    };
  }

  /**
   * Toggle a single checkbox for select table
   */
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  toggleSelection = (key: string, shift: boolean, row: RowInfo) => {
    const { onSelectionChange } = this.props;
    // start off with the existing state
    // eslint-disable-next-line react/no-access-state-in-setstate
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
    onSelectionChange?.(selection);
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField, onSelectionChange } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const selectAll = !this.state.selectAll;
    const selection: string[] = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable?.getWrappedInstance?.();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item: DerivedDataObject) => {
        if (
          item._original?.cycle?.phase === ProjectCyclePhase.ELAPSED &&
          !item._original?.cycle?.isReadyForFinalBilling
        ) {
          selection.push(`select-${item._original[keyField]}`);
        }
      });
    }
    this.setState({ selectAll, selection });
    onSelectionChange?.(selection);
  };

  /**
   * Whether a row is selected for select table
   */
  isSelected = (key: string): boolean => this.state.selection.includes(`select-${key}`);

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  rowFn = (state: any, rowInfo: RowInfo, column: Column, instance: any) => {
    const { selection } = this.state;

    return {
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      onClick: (e: any, handleOriginal: () => void) => {
        // console.log('It was in this row:', rowInfo);

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default, a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      className: rowInfo && selection.includes(`select-${rowInfo.original.cycleId}`) && 'row-selected',
    };
  };

  render() {
    const { items = [], filter, loading, error } = this.props;

    if (!filter || isEmptyObject(filter)) {
      return null;
    }

    const { billingCycleDate } = filter;
    const { year, month } = getYearMonthFromDate(billingCycleDate ?? new Date());

    if (error) {
      return <CalloutMessage type="error" margin message={`Error getting Cycle ${year}-${month} Data`} />;
    }

    return (
      <CheckboxTable
        {...this.props}
        ref={(r: Instance) => {
          this.checkboxTable = r;
        }}
        data={items} // show new projects first
        columns={BILLING_CYCLE_TABLE_COLUMNS}
        defaultPageSize={10}
        loading={loading}
        filterable
        sortable
        className="-striped -highlight"
        defaultSorted={[{ id: 'projectId', desc: true }]}
        defaultFilterMethod={filterCaseInsensitiveForReactTable}
        onFilteredChange={this.props.onFilteredChange}
        onSortedChange={this.props.onSortedChange}
        selectType="checkbox"
        toggleSelection={this.toggleSelection}
        selectAll={this.state.selectAll}
        toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        SelectInputComponent={SelectInputComponent}
      />
    );
  }
}

export default compose(withStyles(styles))(BillingDataTable);
