import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      gap: '2rem',
      flex: '1 1 0',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    numOfClients: {
      maxWidth: 400,
    },
  }),
);

export default useStyles;
