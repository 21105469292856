import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import CurrencyAmountField from '../../CurrencyAmountField';

import {
  validateAmountRequired,
  validateCurrencyRequired,
  validateCurrencyFieldMaximumValue,
  validateCurrencyFieldMinimumValue,
} from '../../../../services/validation';
import { SUPPORTED_CURRENCIES } from '../../../../app/enums';

export class WizardCurrencyField extends React.Component {
  constructor(props) {
    super(props);

    const { isRequired, minimumValue, maximumValue } = props;

    // field required?
    const validator = isRequired ? [validateAmountRequired] : [];

    if (isRequired) {
      validator.push(validateCurrencyRequired);
    }

    if (typeof maximumValue === 'number') {
      validator.push(validateCurrencyFieldMaximumValue(maximumValue));
    }

    if (typeof minimumValue === 'number') {
      validator.push(validateCurrencyFieldMinimumValue(minimumValue));
    }

    this.state = {
      validator,
    };
  }

  render() {
    const {
      name,
      hintText,
      formatValue,
      fullWidth,
      label,
      variant,
      minimumValue,
      maximumValue,
      currency,
      hideCurrencySelect,
      isRequired,
      onEnterPress,
      supportedCurrencies = SUPPORTED_CURRENCIES,
      precision,
      placeholder,
    } = this.props;

    const { validator } = this.state;

    return (
      <div>
        <Field
          component={CurrencyAmountField}
          type="number"
          name={name}
          hintText={hintText}
          fullWidth={fullWidth}
          label={label}
          validate={validator}
          format={formatValue}
          variant={variant}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          currency={currency}
          hideCurrencySelect={hideCurrencySelect}
          required={isRequired}
          onEnterPress={onEnterPress}
          supportedCurrencies={supportedCurrencies}
          precision={precision}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

WizardCurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fullWidth: PropTypes.bool,
  formatValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  currency: PropTypes.oneOf([...SUPPORTED_CURRENCIES.map(({ value }) => value), '']),
  hideCurrencySelect: PropTypes.bool,
  supportedCurrencies: PropTypes.arrayOf(PropTypes.object),
  precision: PropTypes.number,
  placeholder: PropTypes.string,
};

WizardCurrencyField.defaultProps = {
  label: '',
  hintText: '',
  variant: 'outlined',
  isRequired: true,
  fullWidth: true,
  minimumValue: Number.MIN_SAFE_INTEGER,
  maximumValue: Number.MAX_SAFE_INTEGER,
  formatValue: null,
  currency: '',
  hideCurrencySelect: false,
  supportedCurrencies: SUPPORTED_CURRENCIES,
  precision: 0,
  placeholder: '',
};
