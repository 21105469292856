import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getPreviousMonthDate } from './logic';
import BillingDataTable from './BillingDataTable';
import BillingCycleForm from './BillingCycleForm';
import ExportToCSVButton from './ExportToCSVButton';
import useProjectBillingReviewQuery from './useProjectBillingReviewQuery';
import { BillingCycleFormData, BillingCycleWizardProps } from './types';
import MarkProjectCyclesAsReadyForFinalBillingButton from './MarkProjectCyclesAsReadyForFinalBillingButton';

import { useReactTableDataFilterSorter } from '../../../../../fe_common/client/hooks';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import RemoteResetButton from '../../../../../fe_common/client/components/atoms/RemoteResetButton';
import RemoteSubmitButton from '../../../../../fe_common/client/components/atoms/RemoteSubmitButton';

import useStyles from './style';

const BillingCycleWizard: FC<BillingCycleWizardProps> = (props) => {
  const wizardFormName = 'billingCycleFilter';
  const classes = useStyles(props);

  const [expandSearch, setExpandSearch] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [filterData, setFilterData] = useState<BillingCycleFormData | null>(null);
  const [cycleIds, setCycleIds] = useState<string[] | undefined>();

  const { onFilteredChangeHandler, onSortedChangeHandler } = useReactTableDataFilterSorter();

  const initialValues = useMemo(
    () => ({
      billingCycleDate: getPreviousMonthDate(),
    }),
    [],
  );

  const { items, loading, error, refetch } = useProjectBillingReviewQuery(filterData, showResults);

  const collapseSearch = () => {
    setExpandSearch(false);
  };

  const openSearch = () => {
    setExpandSearch(true);
  };

  const handleExpandClick = () => {
    setExpandSearch(!expandSearch);
  };

  const handleFormSubmit: FormSubmitHandler<BillingCycleFormData> = async (formValues) => {
    const newFilterData = formValues;

    setShowResults(true);
    setFilterData(newFilterData);
  };

  const handleResetOnClick = () => {
    setShowResults(false);
    openSearch();
  };

  const onSelectionChangeHandler = (newSelection: string[] | undefined) => {
    setCycleIds(newSelection);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.cardRoot}>
        <CardHeader
          title={<TextDisplay variant="h4">Projects Billing Review</TextDisplay>}
          action={
            <div className={classes.actions}>
              <span>
                <RemoteSubmitButton
                  form={wizardFormName}
                  label="Show Billing data"
                  variant="contained"
                  color="primary"
                  onClick={collapseSearch}
                />
              </span>
              <span>
                <RemoteResetButton form={wizardFormName} label="Reset Form" onClick={handleResetOnClick} />
              </span>
              <span>
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: expandSearch,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expandSearch}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </span>
            </div>
          }
        />
        <Collapse in={expandSearch} timeout="auto">
          <CardContent>
            <WizardForm
              form={wizardFormName}
              initialValues={initialValues}
              destroyOnUnmount={false}
              // @ts-ignore
              onSubmit={handleFormSubmit}
              steps={BillingCycleForm}
              watchAllValues
              classes={classes}
            />
          </CardContent>
        </Collapse>
      </Card>

      {filterData && showResults && (
        <>
          <div className={classes.actions}>
            <ExportToCSVButton items={items} />
            <MarkProjectCyclesAsReadyForFinalBillingButton
              disabled={loading || !cycleIds?.length}
              cycleIds={cycleIds}
              onSuccess={refetch}
            />
          </div>
          <BillingDataTable
            items={items}
            filter={filterData}
            keyField="cycleId"
            loading={loading}
            error={error}
            onFilteredChange={onFilteredChangeHandler}
            onSortedChange={onSortedChangeHandler}
            onSelectionChange={onSelectionChangeHandler}
          />
        </>
      )}
    </div>
  );
};

export default BillingCycleWizard;
