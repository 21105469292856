import { useCallback } from 'react';
import { PackageKeyInput } from '@mayple/types';
import { CreateProjectPackageMutation } from 'growl-graphql/dist/mutations/project/projectPackage/CreateProjectPackageMutation';

import { useDialog, useMutation } from '../../../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../../../fe_common/client/services/logger';

export type ProjectPackageSelectionDialogData = {
  addPackageDialogIsOpen: boolean;
  openAddPackageDialog: () => void;
  closeAddPackageDialog: () => void;
  onAddPackageHandler: (packageKey: PackageKeyInput | null | undefined) => Promise<void>;
  saving: boolean;
};

const useProjectPackageSelectionDialog = (
  projectId: number | null | undefined,
  onSuccessfulSave?: () => void,
): ProjectPackageSelectionDialogData => {
  const {
    open: addPackageDialogIsOpen,
    openDialog: openAddPackageDialog,
    closeDialog: closeAddPackageDialog,
  } = useDialog();

  const { loading: saving, mutate: createProjectPackage } = useMutation(CreateProjectPackageMutation);

  const createProjectPackageHandler = useCallback(
    async (packageKey: PackageKeyInput | null | undefined): Promise<void> => {
      try {
        if (!projectId) {
          throw new Error(`Missing projectId, can't be null or undefined.`);
        }

        if (!packageKey) {
          throw new Error(`Missing packageKey, can't be null or undefined.`);
        }

        const variables = {
          projectId,
          packageKey,
        };

        await createProjectPackage({ variables });
        await onSuccessfulSave?.();
      } catch (e) {
        handleClientError(e, 'Error while trying to activate package.');
      }
    },
    [createProjectPackage, onSuccessfulSave, projectId],
  );

  const onAddPackageHandler = useCallback(
    async (packageKey: PackageKeyInput | null | undefined) => {
      // console.log('packageKey', packageKey);
      await createProjectPackageHandler(packageKey);
      closeAddPackageDialog();
    },
    [closeAddPackageDialog, createProjectPackageHandler],
  );

  return {
    openAddPackageDialog,
    addPackageDialogIsOpen,
    closeAddPackageDialog,
    onAddPackageHandler,
    saving,
  };
};

export default useProjectPackageSelectionDialog;
