import { ChangeEventHandler, useCallback, useMemo } from 'react';

import { ScoreButtonsData, ScoreButtonsProps } from './types';

const getScores = (minValue: number, maxValue: number): string[] =>
  Array.from(Array(maxValue + 1 - minValue), (_e, i) => (i + minValue).toString());

const useScoreButtonsData = (props: ScoreButtonsProps): ScoreButtonsData => {
  const { onChange, minimumValue = 1, maximumValue = 10, minimumValueCaption = '', maximumValueCaption = '' } = props;

  const scores: string[] = useMemo(() => getScores(minimumValue, maximumValue), [minimumValue, maximumValue]);

  const showCaptions = (minimumValueCaption + maximumValueCaption)?.length > 0;

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newValue = event?.target?.value;
      onChange?.(newValue);
    },
    [onChange],
  );

  return {
    scores,
    showCaptions,
    onChangeHandler,
  };
};

export default useScoreButtonsData;
