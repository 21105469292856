import React, { FC, useMemo } from 'react';
import { Company } from '@mayple/types';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { getCrackleUrl } from '../../../../../app/utils';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import ExternalLink from '../../../../../../fe_common/client/components/display/ExternalLink';

import useStyles from '../style';

interface OwningCompanyProps {
  companyId: number;
  company: Company;
  projectId?: number;
}

const OwningCompany: FC<OwningCompanyProps> = (props) => {
  const classes = useStyles(props);

  const { company, companyId, projectId } = props;
  const { name } = company || {};

  const impersonateUrl = useMemo(
    () =>
      projectId
        ? `${getCrackleUrl()}/company/projects/${projectId}/?actAsCompanyId=${companyId}`
        : `${getCrackleUrl()}/?actAsCompanyId=${companyId}`,
    [companyId, projectId],
  );

  return (
    <span>
      <TextDisplay variant="subtitle1" className={classes.inlineItem}>
        Owning Company:{' '}
        <RouterLink to={`/companies/${companyId}`}>
          {name} (ID: {companyId})
        </RouterLink>
        <ExternalLink href={impersonateUrl}>
          <Tooltip title="Impersonate company" placement="top">
            <IconButton>
              <SupervisorAccountIcon />
            </IconButton>
          </Tooltip>
        </ExternalLink>
      </TextDisplay>
    </span>
  );
};

export default OwningCompany;
