import React, { useState } from 'react';

import SearchInput from './SearchInput';
import ResultsContainer from './ResultsContainer';

const initialValues = {
  days: '45',
  positions: '3',
  marketersPerPosition: '10',
  includeMatchesOverrides: false,
};

const MatchStatisticsReport = () => {
  const [queryVars, setQueryVars] = useState(initialValues);
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <h2>Match statistics report</h2>
      <p>
        Each project created receives marketer matches sorted by match %. Top marketers will be viewed first to the
        clients.
        <br />
        Purpose of this report is to show the most shown marketers to projects created in last X days and in which
        position.
        <br />
        For example, for projects created in the last 45 days, show the first 3 positions (which are usually the most
        interesting) and for each position show 10 marketers.
        <br />
      </p>
      <SearchInput loading={loading} initialValues={initialValues} onChange={setQueryVars} />
      <ResultsContainer marketerMatchesStatisticsInput={queryVars} setLoading={setLoading} />
    </div>
  );
};

export default MatchStatisticsReport;
