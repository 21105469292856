import { MarketerMatchOverride } from '@mayple/types';

import { tryParseInt } from '../../../../../fe_common/client/services/utils';

export const getMarketerMatchOverrideCopy = (
  marketerMatchesOverrideOriginal: MarketerMatchOverride[],
): MarketerMatchOverride[] => {
  // Deep copy the array
  const marketerMatchesOverride: MarketerMatchOverride[] = JSON.parse(JSON.stringify(marketerMatchesOverrideOriginal));
  marketerMatchesOverride.forEach((o: MarketerMatchOverride) => {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    delete o.__typename;
  }); // Delete the __typename key for growl

  return marketerMatchesOverride;
};

export const getUserConfirmationToOverrideMarketer = (
  projectId: number,
  overrideMarketerId: number | string,
  overridePosition: number | string,
): boolean =>
  window.confirm(`
    You are about to override marketer ${overrideMarketerId?.toString() || ''}
    to position ${overridePosition?.toString() || ''}
    in project ${projectId}
    `);

export const validateMarketerMatchesOverride = (
  marketerMatchesOverride: MarketerMatchOverride[],
  overrideMarketerId: number | string,
  overridePosition: number | string,
): boolean => {
  if (marketerMatchesOverride.find(({ marketerId }) => marketerId === tryParseInt(overrideMarketerId, 0))) {
    alert(`Error! Marketer ${overrideMarketerId} already exists!`);
    return false;
  }

  if (marketerMatchesOverride.find(({ position }) => position === tryParseInt(overridePosition, 0))) {
    alert(`Error! Position ${overridePosition} already taken!`);
    return false;
  }

  return true;
};
