import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

import { DuplicateProjectDialogProps } from './types';
import { getOptions, optionLabels, OptionsType } from '../logic';
import useDuplicateProjectDialogData from './useDuplicateProjectDialogData';
import { PROJECT_CREATION_REASONS_PAIRS } from '../../../../../containers/project/wizards/ProjectCreationWizard/consts';

import { Dialog } from '../../../../../../fe_common/client/components/display';
import { ButtonProps } from '../../../../../../fe_common/client/components/inputs/Button';
import Select from '../../../../../../fe_common/client/components/atoms/Registration/Select';
import QueryErrorMessage from '../../../../../../fe_common/client/components/common/QueryErrorMessage';

import useStyles from '../style';

const DuplicateProjectDialog: FC<DuplicateProjectDialogProps> = (props) => {
  const classes = useStyles(props);

  const { projectId, open, onClose } = props;

  const {
    name,
    error,
    reason,
    saving,
    onTextChangeHandler,
    onDuplicateProjectHandler,
    onSelectProjectCreationReason,
    nameError,
  } = useDuplicateProjectDialogData(props);

  const actions: ButtonProps[] = [
    {
      disabled: !reason || saving,
      loading: saving,
      variant: 'contained',
      color: 'primary',
      key: 'DuplicateProjectDialogCta',
      label: 'Duplicate',
      onClick: onDuplicateProjectHandler,
    },
  ];

  return (
    <Dialog
      fullWidth
      containerMaxWidth="sm"
      title={`Duplicate project ${projectId}`}
      isOpen={open}
      onClose={onClose}
      actions={actions}
    >
      <TextField
        value={name}
        onChange={onTextChangeHandler}
        label="Project name (optional)"
        fullWidth
        variant="outlined"
        className={classes.nameField}
        error={!!nameError}
        helperText={nameError}
      />
      <Select
        enums={PROJECT_CREATION_REASONS_PAIRS}
        onSelect={onSelectProjectCreationReason}
        value={reason}
        label="Reason for duplication"
      />
      <List>
        {reason &&
          Object.entries(getOptions(reason)).map((value, index) => {
            const [key, included] = value as [keyof OptionsType, boolean];

            return (
              <ListItem key={`Object.entries(getOptions(reason))-item-${index}`}>
                <ListItemIcon>
                  {included ? (
                    <CheckCircleOutlineIcon className={classes.green} />
                  ) : (
                    <CancelIcon className={classes.yellow} />
                  )}
                </ListItemIcon>
                <ListItemText primary={optionLabels[key]} />
              </ListItem>
            );
          })}
        {!reason && (
          <ListItem>
            <ListItemIcon>
              <CancelIcon className={classes.red} />
            </ListItemIcon>
            <ListItemText primary="Select reason" />
          </ListItem>
        )}
        {error && <QueryErrorMessage error={error} message="Failed duplication" />}
      </List>
    </Dialog>
  );
};

export default DuplicateProjectDialog;
