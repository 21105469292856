import React from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { UrlListProps } from './types';
import useUrlListData from './useUrlListData';
import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';

import useStyles from './style';
import SimpleIcon from '../../SimpleIcon';

const UrlListEditMode: React.FC<UrlListProps> = (props) => {
  const classes = useStyles();

  const {
    icon = 'delete',
    label = 'Enter URL',
    placeholder = 'www.example.com',
    buttonLabel = 'ADD',
    required = false,
    isMobile = false,
    meta = {} as WrappedFieldMetaProps,
  } = props;

  const finalButtonLabel = isMobile ? '+' : buttonLabel;

  const { items, url, showError, onEnterPress, handleOnUrlAdd, handleOnDeleteUrl, onChangeHandler } =
    useUrlListData(props);

  const { invalid, touched } = meta as WrappedFieldMetaProps;

  return (
    <div className={classes.root}>
      <div>
        <div style={{ padding: '10px 0px' }}>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={placeholder}
                label={label}
                onChange={onChangeHandler}
                value={url}
                helperText={showError && 'Invalid URL'}
                error={showError}
                onKeyDown={onEnterPress}
                size="small"
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                label={finalButtonLabel}
                disabled={showError}
                onClick={handleOnUrlAdd}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.itemsListContainer}>
          {items.map((item: string) => (
            <Grid
              container
              key={`url-key-${item}`}
              wrap="nowrap"
              justifyContent="space-between"
              style={{ padding: '10px 0px 10px 0px' }}
            >
              <Grid item className={classes.viewerUrlContainer}>
                <TextDisplay style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%' }}>
                  {item}
                </TextDisplay>
              </Grid>
              <Grid item>
                <Button
                  label=""
                  startIcon={<SimpleIcon icon={icon} style={{ color: 'primary' }} />}
                  onClick={(e) => {
                    e.preventDefault(); // this cause a submit sometimes for some reason..
                    handleOnDeleteUrl(item);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
      {
        // this is to show a required error when user attempts to submit without adding at least 1 url
        required && invalid && touched && items.length < 1 && (
          <div>
            <span style={{ color: '#ff0000', fontSize: 12 }}>Please add at least 1 url</span>
          </div>
        )
      }
    </div>
  );
};

export default UrlListEditMode;
