import { InternalTeamType, Project } from '@mayple/types';

export const getVariables = (
  projectId: number,
  internalTeamId: number,
  type: InternalTeamType,
): Record<string, any> => ({
  projectId,
  internalTeamId,
  internalTeamType: type,
});

export const getMainInternalTeamId = (project: Project, type: InternalTeamType): number | undefined => {
  switch (type) {
    case InternalTeamType.GROWTH_STRATEGIST:
      return project?.mainGrowthStrategistId || undefined;
    case InternalTeamType.MARKETING_CONSULTANT:
      return project?.mainMarketingConsultantId || undefined;

    default:
      return undefined;
  }
};
