/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import classNames from 'classnames';

import { useBadgesStyles, useCommonStyles } from '../style';
import { TextDisplay } from '../../../display';
import SimpleIcon from '../../../atoms/SimpleIcon';

interface PackageBadgesProps {
  badges: Record<string, any>[];
  divider?: boolean;
  textType: 'body1' | 'body2';
}

const PackageBadges: FC<PackageBadgesProps> = (props) => {
  const { badges, divider = false, textType } = props;
  const commonClasses = useCommonStyles();
  const classes = useBadgesStyles();

  if (!badges?.length) {
    return null;
  }

  return (
    <div className={classNames(classes.root, commonClasses.lightDiv, commonClasses.center)}>
      {badges.map(
        // @ts-ignore
        ({ title, icon }, i: number) => (
          <div
            key={`PackageExamples-badge-${title}-${i}`}
            className={classNames(classes.badge, { [classes.divider]: divider })}
          >
            <SimpleIcon icon={icon?.url || title} />
            <TextDisplay
              className={classNames(commonClasses.lightText, classes.badgeText)}
              variant={textType}
              align="center"
            >
              {title}
            </TextDisplay>
          </div>
        )
      )}
    </div>
  );
};

export default PackageBadges;
