import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import AvailableMeetingSlotsSelector from '../../integrations/calendars/AvailableMeetingSlotsSelector';
import {
  AvailabilitySlot,
  AvailableMeetingSlotsSelectorProps,
} from '../../integrations/calendars/AvailableMeetingSlotsSelector/types';

export type AvailableMeetingSlotsSelectorFieldProps = WrappedFieldProps & AvailableMeetingSlotsSelectorProps;

const AvailableMeetingSlotsSelectorField: React.FC<AvailableMeetingSlotsSelectorFieldProps> = (props) => {
  const { input, onEventsChange, ...rest } = props;
  const { onChange, value } = input;

  const onChangeHandler = (events: AvailabilitySlot[]) => {
    onEventsChange?.(events);
    onChange?.(events);
  };

  return <AvailableMeetingSlotsSelector onEventsChange={onChangeHandler} initialEvents={value} {...rest} />;
};

export default AvailableMeetingSlotsSelectorField;
