import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withQuery, UserByEmailAddressQuery } from 'growl-graphql';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';

class GetUserByEmailWrapped extends React.Component {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userByEmailAddress: prevUserByEmailAddress } = prevProps;
    const { userByEmailAddress, onValidStateChange, userIdSetter } = this.props;

    // setting the user id in order to get it in the outer component
    if (userByEmailAddress !== prevUserByEmailAddress) {
      onValidStateChange(userByEmailAddress != null);

      if (userByEmailAddress) {
        userIdSetter(userByEmailAddress.id);
      }
    }
  }

  render() {
    const { loading, userByEmailAddress } = this.props;
    const message = userByEmailAddress ? `User Name: ${userByEmailAddress.displayName}` : 'User Not Found';

    if (loading) {
      return 'Searching for user...';
    }

    return (
      <TextDisplay bold variant="body2">
        {message}
      </TextDisplay>
    );
  }
}

GetUserByEmailWrapped.propTypes = {
  loading: PropTypes.bool.isRequired,
  userIdSetter: PropTypes.func,
  userByEmailAddress: PropTypes.any,
  onValidStateChange: PropTypes.func.isRequired,
};

GetUserByEmailWrapped.defaultProps = {
  userByEmailAddress: false,
  userIdSetter: () => {},
};

function mapApolloContextToProps(apolloContext) {
  return {
    ...apolloContext.userByEmailAddressQuery,
  };
}

export const GetUserByEmail = compose(
  withQuery(UserByEmailAddressQuery, {
    options: ({ emailAddress }) => ({
      variables: {
        emailAddress,
      },
      notifyOnNetworkStatusChange: true,
    }),
    props: mapApolloContextToProps,
  }),
)(GetUserByEmailWrapped);

// userIdSetter is a setState function that updates the id for the outer component to get
GetUserByEmail.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  userIdSetter: PropTypes.func.isRequired,
  onValidStateChange: PropTypes.func,
};
GetUserByEmail.defaultProps = {
  onValidStateChange: () => {},
};
