import { Integration } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import { RevokeIntegrationMutation } from 'growl-graphql/dist/mutations/RevokeIntegrationMutation';

import { useDialog, useMutation } from '../../../../../hooks';

type UseCronofyIntegrationRevokeReturnValue = {
  isRevokeIntegrationConfirmDialogOpen: boolean;
  onRevokeIntegrationClick: () => void;
  onRevokeIntegrationConfirm: () => void;
  onRevokeIntegrationConfirmDialogClose: () => void;
  revokeIntegrationError: ApolloError | undefined;
};

const defaultOnSuccess = () => {
  /* Do nothing */
};
const defaultOnError = (err: Error) => {
  throw err;
};

const useCronofyIntegrationRevoke = (
  calendarIntegration: Integration | null,
  refetchCronofyIntegration: () => Promise<any>,
  onSuccess: () => void = defaultOnSuccess,
  onError: (err: Error) => void = defaultOnError,
): UseCronofyIntegrationRevokeReturnValue => {
  const { id: integrationId } = calendarIntegration || ({} as Integration);

  const {
    open: isRevokeIntegrationConfirmDialogOpen,
    openDialog: openRevokeIntegrationConfirmDialog,
    closeDialog: closeRevokeIntegrationConfirmDialog,
  } = useDialog(false);

  const { mutate: revokeIntegration, error: revokeIntegrationError } = useMutation(RevokeIntegrationMutation);

  const onRevokeIntegrationClick = () => {
    openRevokeIntegrationConfirmDialog();
  };

  const onRevokeIntegrationConfirm = async () => {
    const variables = {
      integrationId,
    };

    try {
      closeRevokeIntegrationConfirmDialog();
      const executionResult = await revokeIntegration({ variables });

      if (executionResult?.data?.revokeIntegration?.success === false) {
        // call external onError
        onError(new Error(executionResult?.data?.revokeIntegration?.result));
        return false;
      }

      await refetchCronofyIntegration();
      onSuccess();
      return true;
    } catch (err: any) {
      onError(err);
      return false;
    }
  };

  return {
    onRevokeIntegrationClick,
    isRevokeIntegrationConfirmDialogOpen,
    onRevokeIntegrationConfirm,
    onRevokeIntegrationConfirmDialogClose: closeRevokeIntegrationConfirmDialog,
    revokeIntegrationError,
  };
};

export default useCronofyIntegrationRevoke;
