import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';

import { USER_DETAILS_TABS, userDetailsTabs } from './logic';
import UserBasicDetailsTab from './tabs/UserBasicDetailsTab';
import UserEntitiesTab from './tabs/UserEntitiesTab';
import UserRemoteControllerTab from './tabs/UserRemoteControllerTab';
import UserDetailsHeader from './components/UserDetailsHeader';
import { UserIdParams } from '../../components/Routes/types';

import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import TabContent from '../../../fe_common/client/components/atoms/TabContent';

import useStyles from './styles';
import useUserData from './useUserData';
import { tryParseInt } from '../../../fe_common/client/services/utils';

// shows full details of single user
const UserDetailsPage: FC = () => {
  const classes = useStyles();
  const { userId: userIdFromUrl = 0 } = useParams<UserIdParams>();
  const userId = tryParseInt(userIdFromUrl, 0);

  const [selectedTab, setSelectedTab] = useState(USER_DETAILS_TABS.basicDetails);

  const { user, loading, error, refetch } = useUserData(userId);

  // if the data still not returned show loading symbol
  if (loading) {
    // loading symbol
    return <PageLoadingPlaceholder />;
  }

  if (error) {
    return <CalloutMessage type="error" message={`Error getting user data. ${error}`} />;
  }

  // if no data gets returned from the DB
  if (!user) {
    return <CalloutMessage type="info" message={`User with id ${userId} not found`} />;
  }

  const handleTabChange: (event: React.ChangeEvent<unknown>, value: any) => void = (_event, newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  };

  return (
    <div className={classes.root}>
      <UserDetailsHeader user={user} />

      <Divider className={classes.divider} />

      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {userDetailsTabs.map(({ label, value }) => (
          <Tab key={`UserDetailsPage-tabs-${label}`} label={label} value={value} />
        ))}
      </Tabs>

      {selectedTab === USER_DETAILS_TABS.basicDetails && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <UserBasicDetailsTab user={user} />
        </TabContent>
      )}

      {selectedTab === USER_DETAILS_TABS.connectedEntities && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <UserEntitiesTab user={user} onChange={refetch} />
        </TabContent>
      )}

      {selectedTab === USER_DETAILS_TABS.remoteController && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <UserRemoteControllerTab user={user} onChange={refetch} />
        </TabContent>
      )}
    </div>
  );
};

export default UserDetailsPage;
