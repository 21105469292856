import gql from 'graphql-tag';

const EmptyQuery = gql`
  query {
    __type(name: "null") {
      name
    }
  }
`;

export default EmptyQuery;
