import { GridSize } from '@material-ui/core/Grid/Grid';

import { LabelValueProps } from '../../../../app/types';

const getSmWidth = (finalEnums: LabelValueProps[], columns: number | null | undefined): GridSize => {
  if (columns != null && columns > 0 && columns <= 12 && 12 % columns === 0) {
    return parseInt((12 / columns).toFixed(0), 10) as GridSize;
  }
  return finalEnums.length % 2 === 0 ? 6 : 12;
};

export default getSmWidth;
