import React, { FC } from 'react';
import { AdditionalService } from '@mayple/types';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';

import PackageContent from './PackageContent';
import { Button } from '../../inputs';

import useStyles from './style';

interface PackageMarketingServiceTypeInfoDialogProps {
  service: AdditionalService | null | undefined;
  open: boolean;
  onClose: () => void;
  onBookPackageClick?: () => void;
  noCTA?: boolean;
}

export interface PackageData extends AdditionalService {
  discount: number;
}

const PackageMarketingServiceTypeInfoDialog: FC<PackageMarketingServiceTypeInfoDialogProps> = (props) => {
  const { service, open, onClose, onBookPackageClick, noCTA = false } = props;

  const classes = useStyles(props);

  if (!service) {
    return null;
  }

  // console.log('context', context);

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <AppBar color="default" position="fixed" className={classes.appbar}>
          <Toolbar>
            <Button label="close" color="primary" variant="contained" onClick={onClose} />
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <PackageContent service={service} onBookPackageClick={onBookPackageClick} noCTA={noCTA} />
      </DialogContent>
    </Dialog>
  );
};

export default PackageMarketingServiceTypeInfoDialog;
