import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  actionButton: {
    marginLeft: 0,
    marginTop: 24,
  },
}));

export default useStyles;
