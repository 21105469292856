import React, { FC, useCallback, useMemo } from 'react';
import { FeedPost as FeedPostType, FeedLegend } from '@mayple/types';
import Paper from '@material-ui/core/Paper';

import FeedPostHeader from './FeedPostHeader';
import FeedPostActionItems from './FeedPostActionItems';
import { getAuthorDetails } from '../logic';
import RichTextViewer from '../../common/RichTextViewer';
import FeedPostFiles from './FeedPostFiles';
import FeedPostReactions from './FeedPostReactions';
import FeedPostComments from './FeedPostComments';
import { useDelayedEventTriggerForElementInView, useFeedEvents } from '../events';

import { useFeedPostStyles } from './styles';

interface FeedPostProps {
  post: FeedPostType;
  legend: FeedLegend;
  viewerPersonUuid: string;
  enableComments?: boolean;
  enableReactions?: boolean;
}

const FeedPost: FC<FeedPostProps> = (props) => {
  const { post, legend, enableComments = true, enableReactions = true, viewerPersonUuid } = props;

  // console.log('FeedPost post', post);
  // console.log('FeedPost legend', legend);

  const { uuid, actionItems, content, authorUuid, attachedFiles } = post;

  const classes = useFeedPostStyles();
  const maxPostHeight = 395;
  const { onPostViewed } = useFeedEvents();

  const onPostViewedCallBack = useCallback(() => {
    onPostViewed({ postUuid: uuid });
  }, [onPostViewed, uuid]);

  const { ref } = useDelayedEventTriggerForElementInView(onPostViewedCallBack, 5000, 0.5, true);

  const author = useMemo(() => getAuthorDetails(authorUuid, legend), [authorUuid, legend]);

  // const tempContent = {
  //   mediaType: 'TEXT_HTML',
  //   contents:  '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto inventore laboriosam non
  // placeat quod sed similique voluptate? Ad animi corporis deserunt dicta doloribus dolorum eaque explicabo fuga
  // illum itaque labore laboriosam, libero minima neque obcaecati officia omnis perferendis perspiciatis quam quas
  // quibusdam ratione reiciendis repellendus tenetur ullam vitae voluptatum? Voluptate.</p>', };

  // const tempFiles = [
  //   {
  //     uuid:           'b89d5c9c3e144b68a5282f77d83af6fc',
  //     id:             804,
  //     creatingUserId: 5,
  //     companyId:      null,
  //     marketerId:     312,
  //     participantId:  402,
  //     projectId:      null,
  //     name:           'pdf.pdf',
  //     description:    '',
  //     mimetype:       'application/pdf',
  //     size:           433994,
  //     url:            'https://cdn.filestackcontent.com/zwxBKRgPQPyKYbU5w1al',
  //     category:       'NONE',
  //     isMaypleFile:   false,
  //     created:        '2020-12-02T14:21:04',
  //     __typename:     'SharedFile',
  //   },
  //   {
  //     uuid:           'd1d02e6b99264c4cb6b97d64e732a568',
  //     id:             805,
  //     creatingUserId: 5,
  //     companyId:      null,
  //     marketerId:     312,
  //     participantId:  402,
  //     projectId:      null,
  //     name:           'doge-wallpaper-preview.jpg',
  //     description:    '',
  //     mimetype:       'image/jpeg',
  //     size:           12118,
  //     url:            'https://cdn.filestackcontent.com/S48jjLeVSyP8Q3viSY0O',
  //     category:       'NONE',
  //     isMaypleFile:   false,
  //     created:        '2020-12-02T14:21:21',
  //     __typename:     'SharedFile',
  //   },
  //   {
  //     uuid:           '73012f963479426fa40370ae75fc8b92',
  //     id:             806,
  //     creatingUserId: 5,
  //     companyId:      null,
  //     marketerId:     312,
  //     participantId:  402,
  //     projectId:      null,
  //     name:           'DSC_4473.JPG',
  //     description:    '',
  //     mimetype:       'image/jpeg',
  //     size:           3764182,
  //     url:            'https://cdn.filestackcontent.com/DfzeawAkSMC8SKfNgbbW',
  //     category:       'NONE',
  //     isMaypleFile:   false,
  //     created:        '2020-12-02T14:21:43',
  //     __typename:     'SharedFile',
  //   },
  // ];

  return (
    <Paper id={uuid} ref={ref} className={classes.root}>
      <FeedPostHeader post={post} author={author} />
      <div className={classes.contentsContainer}>
        <RichTextViewer initialMaxHeight={maxPostHeight} value={content} />
      </div>
      <FeedPostActionItems actionItems={actionItems} />
      <FeedPostFiles classes={classes} files={attachedFiles} />

      {enableReactions && <FeedPostReactions viewerPersonUuid={viewerPersonUuid} post={post} />}

      {enableComments && (
        <FeedPostComments post={post} legend={legend} authorUuid={viewerPersonUuid} classes={classes} />
      )}
    </Paper>
  );
};

export default FeedPost;
