import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  MailchimpAuditAssessmentByCompany,
  MonthlyMarketerAssessmentByCompany,
  MonthlyMarketerAssessmentByGrowthStrategist,
  OutbrainAuditAssessmentByCompany,
  PostProjectMatchOfferMarketerAssessment,
} from '@mayple/types';

import { DialogProps } from '../../display/Dialog/types';

export type AssessmentSubTask =
  | PostProjectMatchOfferMarketerAssessment
  | MonthlyMarketerAssessmentByGrowthStrategist
  | MonthlyMarketerAssessmentByCompany
  | MailchimpAuditAssessmentByCompany
  | OutbrainAuditAssessmentByCompany;

export interface AssessmentDialogProps extends DialogProps {
  subTasks: AssessmentSubTask[] | undefined;
  showThanksMessage?: boolean;
}

export interface MarketerAssessmentDialogData {
  // will be executed on successful save
  submitFormHandler: () => void;
  // will be executed on successful skip
  skipFormHandler: () => void;
  onCloseDialogHandler: () => void;
  onAssessmentDoneClickHandler: () => void;
}

export enum AssessmentWizardFormContent {
  SHOW_FORM = 'SHOW_FORM',
  SHOW_THANKS_MESSAGE = 'SHOW_THANKS_MESSAGE',
}

export interface AssessmentThanksMessageProps extends Partial<StepWizardChildProps> {
  onClick: () => void;
  setDialogContent: React.Dispatch<React.SetStateAction<AssessmentWizardFormContent>>;
}
export interface AssessmentFormProps extends Partial<StepWizardChildProps> {
  subTask: AssessmentSubTask;
  name: string;
  onSubmitForm?: () => void;
  onSkipForm: () => void;
  setDialogContent: React.Dispatch<React.SetStateAction<AssessmentWizardFormContent>>;
  subHeadline?: string;
  hideAssessmentComment?: boolean;
}
