import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { FilesListProps, FilesListLayout, FileStatus, FileListFile, FileListAction } from './types';
import { renderSecondary } from './logic';
import { fileOnDownloadHandler } from '../../../services/utils';
import { TextIcon } from '../../atoms';
import { iconCodes } from '../../../app/icons';
import EmptyState from '../../atoms/EmptyState';

import useStyles from './style';

const FilesList: FC<FilesListProps> = (props) => {
  const { files = [] as Array<FileListFile> } = props;

  const {
    fileOnClick = () => {
      /* do nothing */
    },
    fileOnDelete = null,
    allowDownload = true,
    showFileDate = false,
    showFileSize = false,
    showEmptyState = false,
    emptyStatePrimaryText = 'The folder is empty',
    emptyStateSecondaryText = 'Upload new files above',
    layout = FilesListLayout.ONE_COLUMN,
    actions = [] as FileListAction[],
  } = props;

  const classes = useStyles(props);

  const [fileInContext, setFileInContext] = useState<FileListFile | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = useCallback(
    (onClick: (file: FileListFile | null) => void) => {
      onClick(fileInContext);
    },
    [fileInContext]
  );

  const fileOnClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: FileListFile): void => {
      event.stopPropagation();
      window.open(file.url, '_blank');
      fileOnClick(file);
    },
    [fileOnClick]
  );

  const hasFiles = files && Array.isArray(files) && files.length > 0;

  if (!hasFiles) {
    if (showEmptyState) {
      return <EmptyState primary={emptyStatePrimaryText} secondary={emptyStateSecondaryText} />;
    }

    return null;
  }

  const hasActions = actions != null && actions.length > 0;

  return (
    <>
      <List
        className={classNames(classes.root, {
          [classes.twoColumnsLayout]: layout === FilesListLayout.TWO_COLUMNS,
        })}
      >
        {files.map((file: FileListFile) => {
          if (file.status && file.status === FileStatus.DELETED) {
            return null;
          }

          return (
            <ListItem
              button
              key={file.uuid || file.id || file.uploadId}
              onClick={(event) => {
                fileOnClickHandler(event, file);
              }}
              className={classes.fileListItem}
            >
              <ListItemIcon>
                <TextIcon icon={iconCodes.file} className={classes.fileIcon} />
              </ListItemIcon>
              <Tooltip title="Click to preview file" enterDelay={500} leaveDelay={200}>
                <ListItemText
                  primary={file.filename || file.name}
                  secondary={renderSecondary(file, showFileDate, showFileSize)}
                  secondaryTypographyProps={{ variant: 'caption' }}
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                />
              </Tooltip>

              {fileOnDelete && !hasActions && (
                <Tooltip title="Delete">
                  <ListItemSecondaryAction
                    onClick={(event) => {
                      event.stopPropagation();
                      fileOnDelete(event, file, files);
                    }}
                    className={classes.pointer}
                  >
                    <TextIcon icon={iconCodes.delete} className={classes.deleteIcon} />
                  </ListItemSecondaryAction>
                </Tooltip>
              )}

              {allowDownload && !hasActions && (
                <Tooltip title="Download file">
                  <ListItemSecondaryAction
                    onClick={async (event) => {
                      await fileOnDownloadHandler(event, file);
                    }}
                    className={classNames(classes.pointer, { [classes.shiftLeft]: fileOnDelete !== null })}
                  >
                    <TextIcon icon={iconCodes.download} className={classes.downloadIcon} />
                  </ListItemSecondaryAction>
                </Tooltip>
              )}

              {hasActions && (
                <Tooltip title="More actions...">
                    <ListItemSecondaryAction className={classes.pointer}>
                      <IconButton
                        className={classes.moreIcon}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          setAnchorEl(event.currentTarget);
                          setFileInContext(file);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Tooltip>
              )}
            </ListItem>
          );
        })}
      </List>
      <Menu id="file-list-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {actions.map((action: FileListAction) => {
          const { id, label, onClick } = action;
          return (
            <MenuItem
              key={`file-list-menu-item-${id}`}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
                event.stopPropagation();
                handleMenuClose();
                onMenuItemClick(onClick);
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default FilesList;
