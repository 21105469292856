import { InternalTeam, InternalTeamType } from '@mayple/types';

import { FilterType } from '../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';

// eslint-disable-next-line import/prefer-default-export
export const getGrowthStrategistFilter =
  (internalTeamTypes: InternalTeamType[]): FilterType<InternalTeam> =>
  (entity: InternalTeam): boolean => {
    const internalTeamType = entity?.internalTeamType || '';
    if (Array.isArray(internalTeamTypes) && internalTeamTypes.length > 0) {
      return internalTeamTypes.includes(internalTeamType);
    }
    return true;
  };
