import React from 'react';
import moment from 'moment';

import useCompanyData from '../../../../CompanyDetailsPage/useCompanyData';

import { localDateFormatter } from '../../../../../../fe_common/client/services/ExportToCSV/formatters';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import Section from '../../../../../../fe_common/client/components/atoms/Section';
import AvailableMeetingSlotsSelector from '../../../../../../fe_common/client/components/integrations/calendars/AvailableMeetingSlotsSelector';
import {
  AvailabilitySlot,
  SelectionMode,
} from '../../../../../../fe_common/client/components/integrations/calendars/AvailableMeetingSlotsSelector/types';
import LoadingPlaceholder from '../../../../../../fe_common/client/components/atoms/LoadingPlaceholder';

type CompanyOpenSlotsProps = {
  companyId: number | null | undefined;
};

const CompanyOpenSlots: React.FC<CompanyOpenSlotsProps> = (props) => {
  const { companyId } = props;
  const { company, loading } = useCompanyData(companyId);

  const freeSlots = company?.freeSlots;

  const selectedSlots: AvailabilitySlot[] = (freeSlots?.slots ?? []).map((slot) => ({
    start: new Date(slot.startDate),
    end: new Date(slot.endDate),
  }));

  return (
    <>
      {loading && <LoadingPlaceholder />}
      {!loading && (
        <Section noBorder noTopMargin title="Company Open Slots" subTitle="Times displayed in user local time">
          {!freeSlots?.slots?.length && <CalloutMessage message="No free slots were specified" />}
          {(freeSlots?.slots || []).map((slot) => (
            <TextDisplay key={`CompanyTimeSlot-${new Date(slot?.startDate).getTime()}`} gutterBottom>
              {`${localDateFormatter(slot?.startDate)}, ${moment(slot?.startDate).format('HH:mm')} - ${moment(
                slot?.endDate,
              ).format('HH:mm')}`}
            </TextDisplay>
          ))}
          {!!selectedSlots && !loading && (
            <AvailableMeetingSlotsSelector
              initialEvents={selectedSlots}
              initialSelectionBoundaries={selectedSlots}
              mode={SelectionMode.slots}
              showTimezoneSelector
              step={30}
            />
          )}
        </Section>
      )}
    </>
  );
};

export default CompanyOpenSlots;
