import { ReactNode } from 'react';

export enum SECTION_LAYOUT {
  ONE_COLUMN = 'one',
  TWO_COLUMNS = 'two',
  ONE_THIRD_TWO_THIRDS = 'one_third_two_thirds',
  TWO_THIRDS_ONE_THIRD = 'two_thirds_one_third',
}

export interface SectionProps {
  id?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  headerAction?: ReactNode;
  noBorder?: boolean;
  noTopMargin?: boolean;
  layout?: SECTION_LAYOUT;
  gutterTopSize?: number;
  collapsable?: boolean;
  classes?: Record<string, string>;
}
