import React, { FC } from 'react';

import { WizardStepsProps } from './types';

import { Button } from '../../inputs';
import { WizardStepField } from '../../molecules/WizardStepField';
import QueryErrorMessage from '../../common/QueryErrorMessage';

import useStyles from './style';

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const { t, error, submit, submitting, onClose } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <WizardStepField
          type={WizardStepField.types.TEXT}
          name="completionMessage"
          // @ts-ignore
          label={t('completionMessage', 'Completion Message')}
          isMultiLine
          minRows={3}
          fullWidth
          autoFocus
        />
        {error && <QueryErrorMessage error={error} message={t('errorOnUpdate')} />}
      </div>
      <div className={classes.actionsWrapper}>
        <Button label={t('submit')} variant="contained" color="primary" onClick={submit} loading={submitting} />
        <Button label={t('cancel')} variant="outlined" color="primary" onClick={onClose} />
      </div>
    </div>
  );
};
export default WizardSteps;
