import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    nonBlockingSpinner: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      transition: 'all .3s ease',
      top: '50%',
      pointerEvents: 'none',
    },
  }),
);

export default useStyles;
