import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles';

import CycleCostsTable from './CycleCostsTable';
import FundAccountDetails from '../../../molecules/FundAccountDetails';
import { formatDate } from '../../../../../../app/utils';
import { formatFloatCurrency } from '../../../../../../../fe_common/client/services/utils';
import { Button } from '../../../../../../../fe_common/client/components/inputs';

const styles = () => ({
  root: {},
  AccordionSummaryContent: {
    justifyContent: 'space-between',
  },
  cycleCostsTableRoot: {
    marginBottom: 24,
  },
});

const ProjectCycleSnapshot = ({ cycle, currency, projectId, showLinkToCyclePage, classes }) => {
  const { id, phase, startDate, endDate, billingSnapshots, fundAccount } = cycle;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.AccordionSummaryContent }}>
        <div>
          <b>{formatDate(startDate, false)}</b>- <b>{formatDate(endDate, false)}</b> <br />
          Cycle (ID: {id}) <b>{phase}</b> <br />
        </div>
        {showLinkToCyclePage && projectId && (
          <div>
            <Button
              color="primary"
              variant="contained"
              label="View Cycle page..."
              link={`/projects/${projectId}/cycles/${cycle.id}`}
            />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <CycleCostsTable cycle={cycle} currency={currency} classes={{ root: classes.cycleCostsTableRoot }} />
          </Grid>
          <Grid item xs={12}>
            <FundAccountDetails fundAccount={fundAccount} showDebt={false} title="Cycle Fund Account" />
          </Grid>
          <Grid item xs={12}>
            {billingSnapshots &&
              billingSnapshots.length > 0 &&
              billingSnapshots.reverse().map((snap) => {
                const {
                  datetime,
                  actualTotalCost: actualTotalCostSnap,
                  estimatedTotalCost: estimatedTotalCostSnap,
                  fundsChargedDuringProcess,
                  projectFundAccountFundsCharged,
                  projectFundAccountBalanceBeforeFundsCharged,
                  projectCycleFundAccountBalanceBeforeFundsCharged,
                } = snap;
                // First try to show the cycle funds.
                const balanceBeforeToUse =
                  projectCycleFundAccountBalanceBeforeFundsCharged || projectFundAccountBalanceBeforeFundsCharged;

                const charged =
                  projectFundAccountFundsCharged != null ? projectFundAccountFundsCharged : fundsChargedDuringProcess;

                return (
                  <div key={`${datetime}-cycle-snapshot`}>
                    <p>
                      <b>{formatDate(new Date(datetime).toISOString(), false)}</b> | Balance Before: $
                      {balanceBeforeToUse}, Charged: <b>{formatFloatCurrency(charged)}</b> | Total Cost, Estimated:{' '}
                      {formatFloatCurrency(estimatedTotalCostSnap)}, Ongoing: {formatFloatCurrency(actualTotalCostSnap)}
                    </p>
                    <ReactJson src={snap} collapsed name="Billing snapshot" />
                    <Divider />
                  </div>
                );
              })}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ProjectCycleSnapshot.propTypes = {
  cycle: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  showLinkToCyclePage: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

ProjectCycleSnapshot.defaultProps = {
  projectId: null,
  showLinkToCyclePage: false,
};

export default withStyles(styles)(ProjectCycleSnapshot);
