import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/es/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import { GetUserByEmail } from '../GetUserByEmail';
import { Button } from '../../../../../../fe_common/client/components/inputs';

export const AddUserDialog = (props) => {
  const { userName, open, onChange, userIdSetter, emailAddress, handleAddUser, handleClose } = props;
  const [isValidUser, setIsValidUser] = useState(false);

  const handleAddUserOnClick = () => {
    handleAddUser();
    handleClose();
  };

  return (
    <Dialog title={`Add a new user to ${userName}`} open={open}>
      <DialogContent>
        <DialogContentText>Search and add a new user by the user email:</DialogContentText>
        <TextField
          id="user-email"
          label="User email"
          helperText="Enter a user email-address here"
          onChange={onChange}
          fullWidth
          autoFocus
          margin="normal"
        />

        {emailAddress !== '' && (
          <GetUserByEmail emailAddress={emailAddress} userIdSetter={userIdSetter} onValidStateChange={setIsValidUser} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          label="Add this user"
          disabled={!isValidUser}
          color="primary"
          variant="contained"
          onClick={handleAddUserOnClick}
        />
        <Button label="Cancel" onClick={handleClose} color="secondary" variant="contained" />
      </DialogActions>
    </Dialog>
  );
};

AddUserDialog.propTypes = {
  userName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  userIdSetter: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  handleAddUser: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
