import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ConfirmDialogProps } from './types';
import { Button } from '../../inputs';

import useStyles from './styles';
import { TextDisplay } from '../../display';

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const classes = useStyles(props);

  const {
    open,
    title,
    content,
    loading = false,
    onCancel,
    onConfirm,
    cancelButtonIcon,
    cancelButtonLabel = 'CANCEL',
    confirmButtonIcon,
    confirmButtonLabel = 'CONFIRM',
    confirmButtonDisabled = false,
    ...rest
  } = props;

  return (
    <Dialog open={open} classes={{ root: classes.root }} {...rest}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <TextDisplay variant="h5" color="primary">
          {title}
        </TextDisplay>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>{content}</DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button label={cancelButtonLabel} onClick={onCancel} startIcon={cancelButtonIcon} />
        <Button
          variant="contained"
          color="primary"
          label={confirmButtonLabel}
          onClick={onConfirm}
          startIcon={confirmButtonIcon}
          disabled={confirmButtonDisabled || loading}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
