import { ProjectMeetingType, ProjectSpecialSubType } from '@mayple/types';

export enum HealthCheckMeetingType {
  kickOffMeetingType = 'kickOffMeetingType',
  reviewMeetingType = 'reviewMeetingType',
}

const meetingsMapper: Record<
  ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK | ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2,
  Record<HealthCheckMeetingType, ProjectMeetingType>
> = {
  [ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK]: {
    [HealthCheckMeetingType.kickOffMeetingType]:
      ProjectMeetingType.SCHEDULED_EXISTING_MARKETING_EVALUATION_KICK_OFF_MEETING_WITHOUT_MAYPLE,
    [HealthCheckMeetingType.reviewMeetingType]:
      ProjectMeetingType.SCHEDULED_EXISTING_MARKETING_EVALUATION_REVIEW_MEETING_WITHOUT_MAYPLE,
  },
  [ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2]: {
    [HealthCheckMeetingType.kickOffMeetingType]:
      ProjectMeetingType.SCHEDULED_EXISTING_MARKETING_EVALUATION_KICK_OFF_MEETING,
    [HealthCheckMeetingType.reviewMeetingType]:
      ProjectMeetingType.SCHEDULED_EXISTING_MARKETING_EVALUATION_REVIEW_MEETING,
  },
};

export const getMeetingType = (
  projectSpecialSubType: Partial<ProjectSpecialSubType> | undefined,
  healthCheckMeetingType: HealthCheckMeetingType
): ProjectMeetingType => {
  if (projectSpecialSubType && Object.prototype.hasOwnProperty.call(meetingsMapper, projectSpecialSubType)) {
    // @ts-ignore
    return meetingsMapper[projectSpecialSubType][healthCheckMeetingType];
  }

  return meetingsMapper[ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2][healthCheckMeetingType];
};

export const useHealthCheckMeetingType = (
  projectSpecialSubType: Partial<ProjectSpecialSubType> | undefined
): {
  kickOffMeetingType: ProjectMeetingType;
  reviewMeetingType: ProjectMeetingType;
} => {
  const kickOffMeetingType = getMeetingType(projectSpecialSubType, HealthCheckMeetingType.kickOffMeetingType);
  const reviewMeetingType = getMeetingType(projectSpecialSubType, HealthCheckMeetingType.reviewMeetingType);

  return {
    kickOffMeetingType,
    reviewMeetingType,
  };
};
