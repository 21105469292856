import React from 'react';
import PropTypes from 'prop-types';

import EditableComponent from '../../../molecules/EditableComponent';
import { TextDisplay } from '../../../display';
import { formatFloatCurrency } from '../../../../services/utils';
import { colors } from '../../../../app/theme';
import { WizardStepField } from '../../../molecules/WizardStepField';

const handleValuesBeforeSave = (values) => {
  const { projectId, estimatedMediaBudget } = values;

  return {
    projectId,
    projectCycleUpdate: {
      estimatedMediaBudget,
    },
  };
};

function ProjectBudgetEditor(props) {
  // console.log('ProjectBudgetEditor props', this.props);
  const { project, mutation, enableEdit } = props;

  if (!project) {
    return <div>Error getting project data, Please contact support.</div>;
  }
  const { currentCycle, id: projectId } = project;

  const view = (
    <div>
      <div>
        <TextDisplay variant="caption" colorOverride={colors.black_lighter}>
          MONTHLY MARKETING BUDGET
        </TextDisplay>
        <TextDisplay>{formatFloatCurrency(currentCycle.estimatedMediaBudget)}</TextDisplay>
      </div>
    </div>
  );

  const { estimatedMediaBudget } = currentCycle;
  const fields = [
    {
      name: 'estimatedMediaBudget',
      type: WizardStepField.types.NUMBER,
      required: true,
      fieldProps: {
        minimumValue: 500,
        maximumValue: 1000000,
        floatingLabelText: 'Minimum amount of 500',
      },
    },
  ];
  return (
    <EditableComponent
      fields={fields}
      view={view}
      enableEdit={enableEdit}
      mutation={mutation}
      formName="estimatedMediaBudgetProjectSettings"
      initialValues={{
        estimatedMediaBudget,
        projectId,
      }}
      handleValuesBeforeSave={handleValuesBeforeSave}
    />
  );
}

ProjectBudgetEditor.propTypes = {
  project: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  enableEdit: PropTypes.bool,
};

ProjectBudgetEditor.defaultProps = {
  enableEdit: false,
};

export default ProjectBudgetEditor;
