import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';
import { ListItemTreeView } from '../../atoms/ListItemTreeNode';

/**
 * this function is trying to get the relevant fields from the given integration,
 * if they exists we add them to the childNodesList for the TreeView structure.
 * @param integration - the given integration object, f.e facebookUser or googleAdsUser data object.
 * @returns array[Object]
 */
const getGoogleIntegrationNodes = (integration) => {
  const { administratedManagerAccounts = [], administratedClientAccounts = [] } = integration;

  return [
    {
      node: {
        id: `${integration.id}-administratedManagerAccounts`,
        label: 'Manager Accounts (administrated)',
        data: administratedManagerAccounts,
        showItemsCount: true,
      },
    },
    {
      node: {
        id: `${integration.id}-administratedClientAccounts`,
        label: 'Client Accounts (administrated)',
        data: administratedClientAccounts,
        showItemsCount: true,
      },
    },
  ];
};

function GoogleIntegrationDetails({ integrationUser }) {
  const childNodes = useMemo(() => getGoogleIntegrationNodes(integrationUser), [integrationUser]);

  if (childNodes.length === 0) {
    return (
      <TextDisplay style={{ margin: '0 75px' }} colorOverride={colors.black_light}>
        User has no manager or client accounts.
      </TextDisplay>
    );
  }

  return childNodes.map((childNode) => (
    <ListItemTreeView
      key={`custom-tree-view-child-${childNode.node.id}`}
      node={childNode.node}
      childNodes={childNode?.childNodes}
    />
  ));
}

GoogleIntegrationDetails.propTypes = {
  integrationUser: PropTypes.object.isRequired,
};

export default GoogleIntegrationDetails;
