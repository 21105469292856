import React, { useState } from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FeedProvider } from './FeedContext';
import FeedViewer from './FeedViewer';
import FeedFilterManager from './FeedFilterManager';
import { Container } from '../utility/Container';
import FilterTriggerButton from './FeedFilterManager/FilterTriggerButton';
import { useFilterDialogControl } from './FeedFilterManager/hooks';
import PageHeader from '../molecules/PageHeader';
import CalloutMessage from '../common/CalloutMessage';

import useStyles from './style';

function FeedContainer(props) {
  const {
    projectId,
    marketerId,
    feedUuid,
    title,
    feed,
    legend,
    filestackApiKey,
    onCreatePost,
    onCreatePostComment,
    onDeletePost,
    onDeleteComment,
    onPostReaction,
    isEditable, // temporary prop for setting feed permission
    newPostPlaceholder,
    newCommentPlaceholder,
    error,
    enableComments,
    canPostAsPuppet,
    onError,
    basicTraits,
    disableAnalyticsEvents,
  } = props;

  const classes = useStyles(props);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState({});

  const { isFilterDialogOpen, showFilterDialog, hideFilterDialog } = useFilterDialogControl();

  const hasError = error && error !== '';
  const errorMessage =
    "We are sorry but something went wrong... Try refreshing the page, if that doesn't help " +
    'contact Mayple for assistance.';

  // console.log('feedUuid', feedUuid);
  // console.log('feed', feed);
  // console.log('legend', legend);
  // console.log('filter', filter);

  const { topics } = feed;

  const eventHandlers = {
    onCreatePost,
    onCreatePostComment,
    onDeletePost,
    onDeleteComment,
    onPostReaction,
    onError,
  };

  const feedDefaultValues = {
    filestackApiKey,
    newPostPlaceholder,
    newCommentPlaceholder,
    topics: keyBy(topics, 'title'),
    enableComments,
    deleteDialogOpen: false,
    selectedFeedMember: null,
    editPostDialogOpen: false,
    projectId,
    marketerId,
    isEditable,
    canPostAsPuppet,
    puppetUsers: [],
    eventHandlers,
    traits: basicTraits,
    legend,
    disableAnalyticsEvents,
  };

  return (
    <FeedProvider value={feedDefaultValues}>
      <Container classes={{ container: classes.container }}>
        <PageHeader
          title={title}
          isMobile={isMobile}
          classes={{
            pageHeaderRoot: classes.pageHeaderRoot,
            pageHeaderLeftSide: classes.pageHeaderLeftSide,
            pageHeaderRightSide: classes.pageHeaderRightSide,
          }}
        >
          {isMobile && <FilterTriggerButton onClick={showFilterDialog} filter={filter} />}
        </PageHeader>

        {hasError && <CalloutMessage type="error" message={errorMessage} />}

        {!hasError && feed != null && legend != null && (
          <>
            <Grid container spacing={isMobile ? 0 : 3} className={classes.feedContainer}>
              <Grid item className={classes.feedViewerContainer}>
                <FeedViewer feedUuid={feedUuid} feed={feed} legend={legend} filter={filter} />
              </Grid>
              <Grid item className={classes.feedFilterManagerContainer}>
                <FeedFilterManager
                  feed={feed}
                  legend={legend}
                  onSetFilter={setFilter}
                  useFilterDialog={isMobile}
                  isFilterDialogOpen={isFilterDialogOpen}
                  showFilterDialogHandler={showFilterDialog}
                  hideFilterDialogHandler={hideFilterDialog}
                />
              </Grid>
            </Grid>

            {/* <EditPostDialog
             feedUuid={feedUuid}
             feed={feed}
             legend={legend}
             open={editPostDialogOpen}
             onClose={handleCloseEditPostDialog}
             onEditPost={onCreatePost}
             /> */}
          </>
        )}
      </Container>
    </FeedProvider>
  );
}

FeedContainer.propTypes = {
  title: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  marketerId: PropTypes.number.isRequired,
  feedUuid: PropTypes.string,
  feed: PropTypes.object,
  legend: PropTypes.object,
  filestackApiKey: PropTypes.string.isRequired,
  error: PropTypes.string,
  onCreatePost: PropTypes.func,
  onCreatePostComment: PropTypes.func,
  onPostReaction: PropTypes.func,
  onDeletePost: PropTypes.func,
  onDeleteComment: PropTypes.func,
  newPostPlaceholder: PropTypes.string,
  newCommentPlaceholder: PropTypes.string,
  isEditable: PropTypes.bool,
  enableComments: PropTypes.bool,
  canPostAsPuppet: PropTypes.bool,
  disableAnalyticsEvents: PropTypes.bool,
  basicTraits: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

FeedContainer.defaultProps = {
  feedUuid: '',
  feed: {},
  legend: {},
  error: '',
  onCreatePost: () => {},
  onCreatePostComment: () => {},
  onDeletePost: () => {},
  onDeleteComment: () => {},
  onPostReaction: () => {},
  isEditable: true,
  newPostPlaceholder: 'Add new insight',
  newCommentPlaceholder: 'Write a comment...',
  enableComments: true,
  canPostAsPuppet: false,
  disableAnalyticsEvents: false,
  onError: (errorMessage) => {
    // eslint-disable-next-line no-alert
    window.alert(errorMessage);
  },
  classes: {},
};

export default FeedContainer;
