import { AlternativeEmailAddress } from '@mayple/types';

import { USER_TYPE_LABELS } from '../UsersPage/consts';
import { companyLinkFormatter, emailLinkFormatter, marketerLinkFormatter } from '../../logic/linkFormatters';

import { localDateFormatter } from '../../../fe_common/client/services/ExportToCSV/formatters';
import { LabelValueProps } from '../../../fe_common/client/app/types';
import {
  formatListOfEntities,
  PropertiesMetaType,
  wrapWithSpan,
} from '../../../fe_common/client/components/display/PropertiesList';

const PROPS_GROUPS = {
  CONTACT_DETAILS: 'Contact details',
  CONNECTED_ENTITIES: 'Connected Entities',
  OTHER: 'Other',
};

const spanProps = { style: { display: 'block' } };

const listOfCompanies = (value: Record<string, any>) =>
  formatListOfEntities(
    value,
    wrapWithSpan(companyLinkFormatter, spanProps),
    'This user is not connected to any company.',
  );

const listOfMarketers = (value: Record<string, any>) =>
  formatListOfEntities(
    value,
    wrapWithSpan(marketerLinkFormatter, spanProps),
    'This user is not connected to any marketer.',
  );

const listOfAlternativeEmailAddress = (values: AlternativeEmailAddress[]) => {
  if (!values || !Array.isArray(values) || values.length === 0) {
    return 'No alternative emails';
  }

  return values.map((item) => emailLinkFormatter(item?.emailAddress));
};

export const getUserDetailsProperties = (): PropertiesMetaType[] => [
  {
    label: 'First Name',
    path: 'firstName',
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Last Name',
    path: 'lastName',
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Email',
    path: 'emailAddress',
    formatter: emailLinkFormatter,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Alternative Emails',
    path: 'alternativeEmailAddresses',
    formatter: listOfAlternativeEmailAddress,
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },
  {
    label: 'Tel',
    path: 'phoneNumber',
    group: PROPS_GROUPS.CONTACT_DETAILS,
  },

  {
    label: 'User companies',
    path: 'companies',
    formatter: listOfCompanies,
    group: PROPS_GROUPS.CONNECTED_ENTITIES,
  },
  {
    label: 'Marketers connected to this user',
    path: 'marketers',
    formatter: listOfMarketers,
    group: PROPS_GROUPS.CONNECTED_ENTITIES,
  },
  {
    label: 'User type',
    path: 'userType',
    formatter: (value: LabelValueProps) => USER_TYPE_LABELS.find((v) => v.value === value)?.label,
    group: PROPS_GROUPS.OTHER,
  },
  {
    label: 'Joined',
    path: 'created',
    formatter: localDateFormatter,
    group: PROPS_GROUPS.OTHER,
  },
  {
    label: 'Timezone',
    path: 'timezone',
    group: PROPS_GROUPS.OTHER,
  },
  {
    label: 'User Login using',
    path: 'auth0LoginType',
    group: PROPS_GROUPS.OTHER,
  },
];

export const getUserConnectedEntitiesProperties = (): PropertiesMetaType[] => [
  {
    label: 'User companies',
    path: 'companies',
    formatter: listOfCompanies,
    group: PROPS_GROUPS.CONNECTED_ENTITIES,
  },
  {
    label: 'Marketers connected to this user',
    path: 'marketers',
    formatter: listOfMarketers,
    group: PROPS_GROUPS.CONNECTED_ENTITIES,
  },
];

export enum USER_DETAILS_TABS {
  basicDetails = 'basicDetails',
  connectedEntities = 'connectedEntities',
  remoteController = 'remoteController',
}

export const userDetailsTabs: LabelValueProps[] = [
  {
    value: USER_DETAILS_TABS.basicDetails,
    label: 'Basic Details',
  },
  {
    value: USER_DETAILS_TABS.connectedEntities,
    label: 'Accounts',
  },
  {
    value: USER_DETAILS_TABS.remoteController,
    label: 'Remote Control',
  },
];
