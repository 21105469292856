import React, { FC } from 'react';
import Divider from '@material-ui/core/Divider';

import useMarketerMatchesOverride from './useMarketerMatchesOverride';
import MarketerMatchesOverrideList from './MarketerMatchesOverrideList';
import AddMarketerMatchOverrideForm from './AddMarketerMatchOverrideForm';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';

import useStyles from './style';

interface MarketerMatchesOverrideProps {
  projectId: number;
  classes?: Record<string, string>;
}

const MarketerMatchesOverride: FC<MarketerMatchesOverrideProps> = (props) => {
  const classes = useStyles(props);

  const { projectId } = props;

  const { loading, project, viewer, marketerMatches, updateProjectMutate, refetchData } =
    useMarketerMatchesOverride(projectId);

  return (
    <Section title="Override Marketer Matches">
      <SectionContent classes={{ root: classes.sectionContent }}>
        <MarketerMatchesOverrideList
          project={project}
          marketerMatches={marketerMatches}
          updateProjectMutate={updateProjectMutate}
          onDeleteMatchOverride={refetchData}
        />
        <Divider className={classes.divider} />
        <AddMarketerMatchOverrideForm
          project={project}
          viewer={viewer}
          loading={loading}
          updateProjectMutate={updateProjectMutate}
          onAddMarketerMatchOverride={refetchData}
        />
      </SectionContent>
    </Section>
  );
};

export default MarketerMatchesOverride;
