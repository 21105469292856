import React from 'react';
import { FilterRender } from 'react-table-6';

const BillingPlanFilter: FilterRender = ({ filter, onChange }) => (
  <select
    onChange={(event) => {
      onChange(event.target.value);
    }}
    value={filter ? filter.value : 'all'}
  >
    <option value="all">Show All</option>
    <option value="empty">No Billing Plan</option>
  </select>
);

export default BillingPlanFilter;
