import React, { useCallback } from 'react';
import { ExecutionResult } from 'graphql';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { EntityOperationResponse, ProjectStage } from '@mayple/types';
import { LaunchProjectImmediatelyMutation } from 'growl-graphql/dist/mutations/project/LaunchProjectImmediatelyMutation';

import getMerchantInitiatedIrregularChargeReasoningFromUser from '../../../../../../logic/getMerchantInitiatedIrregularChargeReasoningFromUser';

import { Button } from '../../../../../../../fe_common/client/components/inputs';
import useProjectFlowConfiguration, {
  FlowConfigurationKey,
} from '../../../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';
import useMutation from '../../../../../../../fe_common/client/hooks/useMutation';
import { handleClientError } from '../../../../../../../fe_common/client/services/logger';
import useProjectData from '../../../../../../../fe_common/client/hooks/project/useProjectData';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface ManualProjectLaunchButtonProps {
  projectId: number | null | undefined;
  onSuccess?: () => void;
  classes?: Record<string, string>;
}

const ManualProjectLaunchButton: React.FC<ManualProjectLaunchButtonProps> = (props) => {
  const classes = useStyles(props);
  const { projectId, onSuccess } = props;

  const { loading: saving, mutate: launchProjectImmediately } = useMutation(LaunchProjectImmediatelyMutation);
  const { project } = useProjectData(projectId);

  const onLaunchProjectImmediatelyClickHandler = useCallback(async () => {
    const merchantInitiatedIrregularChargeReasoning = getMerchantInitiatedIrregularChargeReasoningFromUser(project);

    const variables = { projectId, merchantInitiatedIrregularChargeReasoning };
    try {
      const result: ExecutionResult<{ launchProjectImmediately: EntityOperationResponse }> =
        await launchProjectImmediately({
          variables,
        });

      if (result.data?.launchProjectImmediately?.success) {
        onSuccess?.();
      } else {
        handleClientError(
          !result.data?.launchProjectImmediately?.result,
          'Error while trying to launch project manually.',
        );
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to launch project manually.');
    }
  }, [launchProjectImmediately, onSuccess, project, projectId]);

  const { stage, getProjectFlowConfigurationByKey } = useProjectFlowConfiguration(projectId);

  const allowDelayedProjectLaunch = getProjectFlowConfigurationByKey(
    'allowDelayedProjectLaunch' as FlowConfigurationKey,
  );

  return (
    <div className={classes.root}>
      <Button
        label="Manually Launch Project"
        variant="outlined"
        color="primary"
        size="small"
        loading={saving}
        onClick={onLaunchProjectImmediatelyClickHandler}
        title="By Clicking here the project will be launched immidietly and move to LIVE. The client will be chanrged (is he wasn't chanrged before that)."
        disabled={stage !== ProjectStage.LAUNCH || !allowDelayedProjectLaunch.value || saving}
      />
    </div>
  );
};

export default ManualProjectLaunchButton;
