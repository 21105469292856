import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardRoot: {
      borderRadius: '0 0 16px 16px',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& button': {
        marginRight: 12,
      },
      '& button:last-child': {
        marginRight: 0,
      },
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    autoCompleteValueContainer: {
      padding: '0 8px',
    },
  }),
);

export default useStyles;
