import React, { FC } from 'react';
import { Project, ProjectLifeCycleStatus } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import ProjectFiles from '../../components/ProjectFiles';

interface ProjectAuditTabProps {
  project: Project | undefined | null;
  classes?: Record<string, string>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionDivider: {
      margin: '24px auto',
    },
  }),
);

const fileUploadEnabledStatuses = [
  ProjectLifeCycleStatus.DISCOVERY,
  ProjectLifeCycleStatus.ONBOARDING,
  ProjectLifeCycleStatus.LIVE,
];

const ProjectAuditTab: FC<ProjectAuditTabProps> = (props) => {
  const classes = useStyles();

  const { project } = props;
  const { projectLifeCycleStatus } = project || ({} as Project);
  const enableFileUpload = fileUploadEnabledStatuses.includes(projectLifeCycleStatus);

  return (
    <div className={classes.root}>
      <ProjectFiles project={project} enableFileUpload={enableFileUpload} />
    </div>
  );
};

export default ProjectAuditTab;
