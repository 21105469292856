import React, { useCallback } from 'react';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { WithdrawProjectOpportunityMutation } from 'growl-graphql/dist/mutations/admin/WithdrawProjectOpportunityMutation';

import WithdrawProjectOpportunityWizardSteps from './WithdrawProjectOpportunityWizardSteps';

import { WizardForm } from '../../../../../../fe_common/client/components/organisms/WizardForm';
import ConfirmDialog from '../../../../../../fe_common/client/components/dialogs/ConfirmDialog';
import { useMutation } from '../../../../../../fe_common/client/hooks';

export const wizardFormWithdrawOpportunityName = 'withdraw-opportunities';

interface ProjectOpportunityWithdrawConfirmationProps {
  selectedOpportunities: string[];
  isOpen: boolean;
  onCloseDialog: () => void;
  onWithdrawOpportunity: () => void;
}

const ProjectOpportunityWithdrawConfirmation: React.FC<ProjectOpportunityWithdrawConfirmationProps> = (props) => {
  const { isOpen, onCloseDialog, selectedOpportunities, onWithdrawOpportunity } = props;

  const initialValues = {
    opportunitiesUuid: selectedOpportunities,
  };

  const { mutate: withdrawOpportunity } = useMutation(WithdrawProjectOpportunityMutation);

  const dispatch = useDispatch();

  const saveWithdrawnOpportunities = useCallback(
    async (formValues: Record<string, any>): Promise<any> => {
      const { opportunitiesUuid, resolutionReason } = formValues;

      const variables = {
        opportunitiesUuid,
        resolutionReason,
      };

      return withdrawOpportunity({ variables });
    },
    [withdrawOpportunity],
  );

  const handleOnConfirmWithdrawOpportunity = async () => {
    await dispatch(submit(wizardFormWithdrawOpportunityName));
  };

  const handleOnSubmitWithdrawOpportunity = async (formValues: Record<string, any>) => {
    await saveWithdrawnOpportunities(formValues);
    onWithdrawOpportunity?.();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isOpen && (
        <ConfirmDialog
          open={isOpen}
          onCancel={onCloseDialog}
          onConfirm={handleOnConfirmWithdrawOpportunity}
          content={
            <WizardForm
              // @ts-ignore
              steps={WithdrawProjectOpportunityWizardSteps}
              form={wizardFormWithdrawOpportunityName}
              initialValues={initialValues}
              onSubmit={handleOnSubmitWithdrawOpportunity}
              destroyOnUnmount
              watchAllValues
            />
          }
          title="Withdraw Opportunities"
        />
      )}
    </>
  );
};

export default ProjectOpportunityWithdrawConfirmation;
