import React, { FC } from 'react';
import { Company } from '@mayple/types';

import CompanyUsers from '../components/CompanyUsers';
import { ENTITY_TYPE } from '../../../app/consts';
import GenerateInviteUserToken from '../../../components/cpanel/components/molecules/GenerateInviteUserToken';

import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';

export interface CompanyUsersTabProps {
  company: Company;
}

const CompanyUsersTab: FC<CompanyUsersTabProps> = (props) => {
  const { company } = props;

  return (
    <div>
      <Section title="Users" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
        <SectionContent>
          <CompanyUsers company={company} />
        </SectionContent>
      </Section>
      <Section
        title="Invite user"
        subTitle={
          'Generate a unique link to invite a new user to join this company account. After signing up ' +
          'with this link, the user will get full access to the company and its projects. If the user ' +
          'has already signed up, but is not connected to any other company or marketer account, the ' +
          'link can still be used to allow him to join the company. However, if the user is already ' +
          'connected to another company or marketer, then the link will not work.'
        }
        layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
      >
        <SectionContent>
          <GenerateInviteUserToken entityType={ENTITY_TYPE.COMPANY} entityId={company.id} />
        </SectionContent>
      </Section>
    </div>
  );
};

export default CompanyUsersTab;
