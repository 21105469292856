import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { ProjectExistingMarketingEvaluationReportV1Details } from '@mayple/types';

import { QuestionMeta } from './types';
import { getScoreQuestions, getYesNoQuestions } from './logic';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { titleCase } from '../../../../../fe_common/client/services/utils';
import { yesNoFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import RichTextViewer from '../../../../../fe_common/client/components/common/RichTextViewer';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

export interface ExistingMarketingEvaluationReportV1DetailsProps {
  reportDetails: ProjectExistingMarketingEvaluationReportV1Details;
  scoreQuestions: QuestionMeta[];
  yesNoQuestions: QuestionMeta[];
}

const ExistingMarketingEvaluationReportV1Details: React.FC<ExistingMarketingEvaluationReportV1DetailsProps> = (
  props,
) => {
  const classes = useStyles();
  const { reportDetails } = props;

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketerProjectMarketingEvaluationAuditReport.ExistingMarketingEvaluationReportV1Details',
  });

  const scoreQuestions = useMemo(() => getScoreQuestions(t, reportDetails), [reportDetails, t]);

  const yesNoQuestions = useMemo(() => getYesNoQuestions(t, reportDetails), [reportDetails, t]);

  return (
    <div className={classes.root}>
      {!reportDetails && <div>The marketer has not yet submitted an evaluation form</div>}
      {reportDetails && (
        <div>
          <TextDisplay gutterBottom variant="h6" bold>
            Score questions
          </TextDisplay>
          <div>
            {scoreQuestions.map(
              ({ label: scoreLabel, value: scoreValue, formatter }, index) =>
                typeof scoreValue === 'number' && (
                  <TextDisplay key={`reportDetails-score-questions-${index}`}>
                    <strong>{titleCase(scoreLabel)}?</strong>: {formatter ? formatter?.(scoreValue) : scoreValue}
                  </TextDisplay>
                ),
            )}
          </div>

          <Divider />

          <TextDisplay gutterBottom variant="h6" bold>
            Yes No questions
          </TextDisplay>
          <div>
            {yesNoQuestions.map(
              ({ value: boolValue, label: boolLabel }, index) =>
                boolValue != null && (
                  <TextDisplay key={`reportDetails-boolean-questions-${index}`}>
                    <strong>{titleCase(boolLabel)}?:</strong> {yesNoFormatter(boolValue)}
                  </TextDisplay>
                ),
            )}
          </div>
          <Divider />
          <TextDisplay gutterBottom bold>
            Biggest gap:
          </TextDisplay>
          {reportDetails.biggestGap && <RichTextViewer value={reportDetails.biggestGap} />}
          <Divider />
          <TextDisplay gutterBottom bold>
            What other tools are they using to manage their business?
          </TextDisplay>
          {reportDetails?.otherBusinessManagementTools && (
            <RichTextViewer value={reportDetails.otherBusinessManagementTools} />
          )}
          <Divider />
          <TextDisplay gutterBottom bold>
            Is the customer using multiple audiences with tags, groups, and segments they’ve developed or are they using
            multiple lists with limited tags, groups, and segments? :
          </TextDisplay>
          {reportDetails.multipleAudiencesVsMultipleLists && (
            <RichTextViewer value={reportDetails.multipleAudiencesVsMultipleLists} />
          )}
          <Divider />
        </div>
      )}
    </div>
  );
};

export default ExistingMarketingEvaluationReportV1Details;
