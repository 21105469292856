import { FeedMaterializedView, FeedLegend } from '@mayple/types';

export enum FilterType {
  TEAM_MEMBERS = 'teamMembers',
  TOPICS = 'topics',
}

export interface FeedFilterProps {
  feed: FeedMaterializedView;
  filter?: FeedFilterType;
  legend?: FeedLegend;
  onSetFilter: (filter: Record<string, any>) => void;
  showFilterHeader?: boolean;
}

export type FeedFilterType = {
  [FilterType.TEAM_MEMBERS]?: string[];
  [FilterType.TOPICS]?: string[];
};
