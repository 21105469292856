import moment from 'moment';
import { OpportunityState, PreferredMarketerInput, ProjectMarketerOpportunity } from '@mayple/types';

import { datesDiffInDays } from '../../../../../../fe_common/client/logic/dateUtils';
import { CreateProjectOpportunitiesVariables, OpportunitiesCandidatesForm } from './types';

const openOpportunitiesStatuses = [OpportunityState.OFFERED, OpportunityState.EXPIRED, OpportunityState.APPLIED];

export const getMarketerIdsWithOpenOpportunities = (opportunities: ProjectMarketerOpportunity[]): number[] =>
  opportunities
    .filter((opportunity) => openOpportunitiesStatuses.includes(opportunity?.opportunityState))
    .map((opportunity) => opportunity?.marketerId);

const mapToPreferredMarketers = (opportunitiesCandidates: number[] = []): PreferredMarketerInput[] =>
  opportunitiesCandidates.map((marketerId: number, index: number) => ({
    marketerId,
    marketerPreferredIndex: index + 1,
  }));

export const saveProjectOpportunities = (
  formValues: OpportunitiesCandidatesForm,
  createProjectOpportunitiesMutation: (options?: any) => Promise<any>,
): Promise<any> => {
  const { projectId, opportunitiesCandidates, dueDateTime } = formValues;

  const diffInDays = datesDiffInDays(new Date(), moment(dueDateTime).toISOString());
  const preferredMarketers = mapToPreferredMarketers(opportunitiesCandidates);

  const mutationVariables: CreateProjectOpportunitiesVariables = {
    projectId,
    preferredMarketers,
    dueDateTime,
  };

  // if the diff is default 24hrs, we don't include the dueDateTime, but let the server set it by default
  // As the time from the client may be less than the minimum time in the server
  if (diffInDays > 1) {
    mutationVariables.dueDateTime = moment(dueDateTime).toDate();
  }

  return createProjectOpportunitiesMutation({ variables: mutationVariables });
};

const activeOpportunitiesStatuses = new Set([OpportunityState.OFFERED, OpportunityState.APPLIED]);

export const hasActiveOpportunities = (opportunities: ProjectMarketerOpportunity[]): boolean =>
  (opportunities || []).some(({ opportunityState }) => activeOpportunitiesStatuses.has(opportunityState));
