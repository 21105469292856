import React, { useState } from 'react';
import { InternalTeam } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import { AddProjectToInternalTeamMutation } from 'growl-graphql/dist/mutations/AddProjectToInternalTeamMutation';

import ProjectSearch from '../ProjectSearch';
import ProjectsTable from '../../../ProjectsPage/ProjectsTable';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { useMutation } from '../../../../../fe_common/client/hooks';

import useStyles from './style';

interface InternalTeamProjectsProps {
  internalTeam: InternalTeam;
  onChange: () => void;
}

const InternalTeamProjects: React.FC<InternalTeamProjectsProps> = (props) => {
  const classes = useStyles();
  const { internalTeam, onChange } = props;
  const projects = internalTeam.projects || [];

  const [projectId, setProjectId] = useState<string>('');

  const [disableConnectProject, setDisableConnectProject] = useState(true);

  const { mutate, loading } = useMutation(AddProjectToInternalTeamMutation);

  const projectIdOnChangeHandler = (newProjectId: string) => {
    setProjectId(newProjectId);
  };

  const projectIdOnSelectHandler = (newProjectId: string | null) => {
    const shouldDisable = newProjectId === null;
    setDisableConnectProject(shouldDisable);
  };

  const onConnectProjectClick = async () => {
    const variables = {
      internalTeamId: internalTeam.id,
      projectId,
    };
    await mutate({ variables });
    onChange();
    setProjectId('');
  };

  return (
    <div className={classes.root}>
      <div className={classes.selectProjectContainer}>
        <ProjectSearch
          name="projectId"
          value={projectId}
          onChange={projectIdOnChangeHandler}
          onSelect={projectIdOnSelectHandler}
          label="Project ID to add"
          helperText="Please enter the project id you want to connect to the Internal account"
        />
        <Button
          label="Connect Project"
          disabled={disableConnectProject || loading}
          onClick={onConnectProjectClick}
          className={classes.connectProjectButton}
          color="primary"
          variant="contained"
        />
      </div>
      <Divider className={classes.divider} />
      <ProjectsTable data={projects} loading={false} />
    </div>
  );
};

export default InternalTeamProjects;
