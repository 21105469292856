import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';

import usePayInReportData from './usePayInReportData';
import PayInReportStep1 from './steps/PayInReportStep1';
import PayInReportStep2 from './steps/PayInReportStep2';
import PayInReportStep3 from './steps/PayInReportStep3';

import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';

const PayInReport: React.FC = () => {
  const classes = useStyles();
  const [getReportData, setGetReportData] = useState<boolean>(false);
  const { payments, loading, error, refetch } = usePayInReportData(getReportData);

  const onGeneratePayInReportHandler = () => {
    setGetReportData(true);
  };

  const onRestartHandler = () => {
    setGetReportData(false);
  };

  const onSuccessHandler = async () => {
    setGetReportData(false);
    await refetch();
  };

  return (
    <div className={classes.root}>
      {error && <QueryErrorMessage error={error} message="Error while trying to generate pay-in report" />}
      {!loading && payments.length === 0 && getReportData && (
        <CalloutMessage type="info" message="No data was found." />
      )}
      <StepWizard className={classes.stepWizardRoot}>
        <PayInReportStep1 stepName="step1" onStart={onGeneratePayInReportHandler} />
        <PayInReportStep2 stepName="step2" loading={loading} payments={payments} />
        <PayInReportStep3
          stepName="step3"
          payments={payments}
          onRestart={onRestartHandler}
          onSuccess={onSuccessHandler}
        />
      </StepWizard>
    </div>
  );
};

export default PayInReport;
