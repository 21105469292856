import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { TextDisplay } from '../../display';

function SliderField({
  label,
  meta: { touched, invalid, error },
  minSliderValue,
  maxSliderValue,
  sliderStep,
  marks,
  input: { name, onChange, value },
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div>
      <TextDisplay variant="caption" bold>
        {label}
      </TextDisplay>
      <Slider
        name={name}
        invalid={invalid}
        min={minSliderValue}
        max={maxSliderValue}
        step={sliderStep}
        onChange={handleChange}
        value={value}
        style={{ width: '96%' }}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="auto"
        marks={marks}
      />
      {touched && !!error && (
        <TextDisplay variant="caption" color="error">
          {error}
        </TextDisplay>
      )}
    </div>
  );
}
SliderField.propTypes = {
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  minSliderValue: PropTypes.number,
  maxSliderValue: PropTypes.number,
  sliderStep: PropTypes.number,
  marks: PropTypes.arrayOf(PropTypes.object),
};

SliderField.defaultProps = {
  minSliderValue: 0,
  maxSliderValue: 100,
  sliderStep: 1,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 75,
      label: '75',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  value: 0,
};

export default SliderField;
