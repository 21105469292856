import { FilterByOperator, Project, ProjectLifeCycleStatus } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { AdminParticipantsInsightsFeedStatisticsReportQuery } from 'growl-graphql/dist/queries/AdminParticipantsInsightsFeedStatisticsReportQuery';

import { InsightsFeedReportData } from './types';

const useInsightsFeedReportData = (): InsightsFeedReportData => {
  const variables = {
    filter: {
      by: [
        {
          key: 'projectLifeCycleStatus',
          value: ProjectLifeCycleStatus.LIVE,
          operator: FilterByOperator.EQUALS,
        },
      ],
    },
    pagination: {
      pageSize: 10,
      pageNumber: 0,
      by: {
        key: 'id',
        desc: true,
      },
    },
  };

  const { loading, data, error } = useQuery(AdminParticipantsInsightsFeedStatisticsReportQuery.query, { variables });

  return {
    loading,
    error,
    data: data?.projectsPaged?.items || ([] as Project[]),
  };
};

export default useInsightsFeedReportData;
