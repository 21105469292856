import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  actions: {
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
  },
  columnContainer: {
    display: 'flex',
  },
  leftColumn: {
    marginRight: '5rem',
  },
  rightColumn: {
    flex: 1,
  },
  rightColumnTitle: {
    marginTop: 0,
    marginBottom: '0.5rem',
  },
  billingInfoItem: {
    textTransform: 'capitalize',
  },
}));
