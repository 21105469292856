import React, { FC } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { useProjectDetailsState } from '../ProjectDetailsProvider';
import MarketerMatchesOverride from '../components/MarketerMatchesOverride';
import ProjectMatches from '../components/ProjectMatches';
import ShowByProjectStatus from '../components/ShowByProjectStatus';
import MarketerSelectionConfiguration from '../components/MarketerSelectionConfiguration';

import useNotesData from '../../../../fe_common/client/logic/notes';
import Section from '../../../../fe_common/client/components/atoms/Section';
import RichTextViewer from '../../../../fe_common/client/components/common/RichTextViewer';
// import ProjectMarketingAccountDetails from '../../../components/cpanel/components/organisms/Project/ProjectMarketingAccountsDetails';
// import ExpertsMatchesManager from '../../../components/cpanel/ExpertsMatchesManager';
// import Section from '../../../../fe_common/client/components/atoms/Section';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    flex: {
      display: 'flex',
      gap: '2rem',
      flex: '1 1 auto',
      flexWrap: 'nowrap',
      '& > div': {
        width: '100%',
      },
    },
  }),
);

const ProjectMatchingAndHistoryTab: FC = () => {
  const classes = useStyles();
  const { project, projectId } = useProjectDetailsState();
  const { projectLifeCycleStatus, marketerMatchesOverride, flowConfiguration } = project;

  const { notes } = useNotesData(projectId);
  const withNotes = notes?.contents?.length > 0;
  const skipOpportunityStage: boolean = !!flowConfiguration?.skipOpportunityStage?.value || false;

  return (
    <div>
      {!skipOpportunityStage && <MarketerSelectionConfiguration projectId={projectId} />}
      {withNotes && (
        <Section title="Notes from Mayple">
          <RichTextViewer value={notes} />
        </Section>
      )}
      {/*
      <Section title="Matched Experts">
        <ExpertsMatchesManager projectId={projectId} />
      </Section>
      */}
      <div className={classNames(classes.flex)}>
        <div>
          <ProjectMatches />
        </div>
        <div>
          <ShowByProjectStatus
            status={projectLifeCycleStatus}
            showOnDiscovery
            showOnOnBoarding
            showOnAbandoned
            showOnLive={(marketerMatchesOverride || []).length > 0}
          >
            <MarketerMatchesOverride projectId={projectId} />
          </ShowByProjectStatus>
        </div>
      </div>

      {/* <ProjectMarketingAccountDetails project={project} showHistoryAccounts noBorder noTopMargin /> */}
    </div>
  );
};

export default ProjectMatchingAndHistoryTab;
