import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  getAdditionalServicesCostBeforeDiscount,
  renderAdditionalServicesInfo,
  renderPriceBeforeDiscount,
  renderValueWithTooltip,
} from './logic';
import { getLabelByCycleStatus } from '../ProjectCycleDetailsNew/logic';

import { safeExtractCurrencyValue } from '../../../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

const styles = {
  root: {},
};

function CycleCostsTable({ cycle, currency, classes }) {
  const {
    estimatedFee: estimatedPPCFee,
    estimatedFeeBeforeDiscount: estimatedPPCFeeBeforeDiscount,
    actualFee: actualPPCFee,
    estimatedMarketerFee,
    actualMarketerFee,
    estimatedMaypleFee,
    actualMaypleFee,
    billingConfiguration,
    setupService,
    setupServiceCost,
    additionalServices,
    additionalServicesCost,
    estimatedTotalCost,
    actualTotalCost,
    phase,
  } = cycle;

  const { cost: setupServiceCostBeforeDiscount } = setupService;

  const { PPCDiscount, nonPPCDiscount, setupServiceDiscount } = billingConfiguration;

  const additionalServicesCostBeforeDiscount = getAdditionalServicesCostBeforeDiscount(additionalServices);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <TextDisplay variant="h5" gutterBottom>
          Cycle Costs
        </TextDisplay>
      </Grid>

      <Grid item xs={4}>
        <strong>Description</strong>
      </Grid>
      <Grid item xs={3} align="right">
        <strong>{getLabelByCycleStatus('', false, phase, false)}</strong>
      </Grid>
      <Grid item xs={3} align="right">
        <strong>{getLabelByCycleStatus('', true, phase, false)}</strong>
      </Grid>
      <Grid item xs={2} align="right">
        <strong>Discount</strong>
      </Grid>

      <Grid item xs={12}>
        <hr />
      </Grid>

      <Grid item xs={4}>
        PPC Services Fee
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(estimatedPPCFee, currency)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(actualPPCFee, currency)}
      </Grid>
      <Grid item xs={2} align="right">
        {renderValueWithTooltip(
          `${PPCDiscount}%`,
          renderPriceBeforeDiscount(safeExtractCurrencyValue(estimatedPPCFeeBeforeDiscount)),
          classes,
        )}
      </Grid>
      <Grid item xs={4}>
        Non-PPC Services Fee {renderAdditionalServicesInfo(additionalServices, classes)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(additionalServicesCost, currency)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(additionalServicesCost, currency)}
      </Grid>
      <Grid item xs={2} align="right">
        {renderValueWithTooltip(
          `${nonPPCDiscount}%`,
          renderPriceBeforeDiscount(safeExtractCurrencyValue(additionalServicesCostBeforeDiscount, currency)),
          classes,
        )}
      </Grid>

      <Grid item xs={4}>
        One-Time/Setup Simple Service Fee
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(setupServiceCost, currency)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(setupServiceCost, currency)}
      </Grid>
      <Grid item xs={2} align="right">
        {renderValueWithTooltip(
          `${setupServiceDiscount}%`,
          renderPriceBeforeDiscount(safeExtractCurrencyValue(setupServiceCostBeforeDiscount, currency)),
          classes,
        )}
      </Grid>

      <Grid item xs={12}>
        <hr />
      </Grid>

      <Grid item xs={4}>
        <TextDisplay variant="h6" bold>
          Total Cost
        </TextDisplay>
      </Grid>
      <Grid item xs={3} align="right">
        <TextDisplay variant="h6" bold>
          {safeExtractCurrencyValue(estimatedTotalCost, currency)}
        </TextDisplay>
      </Grid>
      <Grid item xs={3} align="right">
        <TextDisplay variant="h6" bold>
          {safeExtractCurrencyValue(actualTotalCost, currency)}
        </TextDisplay>
      </Grid>
      <Grid item xs={2} />

      <Grid item xs={4}>
        <strong>Marketer&apos;s Cut</strong>
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(estimatedMarketerFee, currency)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(actualMarketerFee, currency)}
      </Grid>
      <Grid item xs={2} />

      <Grid item xs={4}>
        <strong>Mayple&apos;s Cut</strong>
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(estimatedMaypleFee, currency)}
      </Grid>
      <Grid item xs={3} align="right">
        {safeExtractCurrencyValue(actualMaypleFee, currency)}
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

CycleCostsTable.propTypes = {
  cycle: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CycleCostsTable);
