import React from 'react';
import { ProjectCycleOperationMode } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import LabelValue from '../../../../../../../fe_common/client/components/display/LabelValue';
import useProjectData from '../../../../../../../fe_common/client/hooks/project/useProjectData';
import { SkeletonContainer } from '../../../../../../../fe_common/client/components/display';
import { PROJECT_CYCLE_OPERATION_MODE_LABELS_LOOKUP } from '../../../../../../../fe_common/client/app/enums';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: '2rem',
    },
  }),
);

interface ProjectCycleOperationModeProps {
  projectId: number;
}

const projectCycleOperationModeLabelsLookup = PROJECT_CYCLE_OPERATION_MODE_LABELS_LOOKUP as Record<
  ProjectCycleOperationMode,
  string
>;

const ProjectCycleOperationModeView: React.FC<ProjectCycleOperationModeProps> = (props) => {
  const classes = useStyles(props);
  const { projectId } = props;
  const { project, loading } = useProjectData(projectId);
  const projectCycleOperationMode: ProjectCycleOperationMode =
    project?.projectCycleOperationMode || ProjectCycleOperationMode.OPERATED_MANUALLY_BY_HUMANS;

  return (
    <SkeletonContainer loading={loading} variant="text">
      <div className={classes.root}>
        <LabelValue
          label="Project Packages Mode"
          value={projectCycleOperationModeLabelsLookup[projectCycleOperationMode]}
        />
      </div>
    </SkeletonContainer>
  );
};

export default ProjectCycleOperationModeView;
