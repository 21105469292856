import React, { FC, useRef } from 'react';

import TextField from '@material-ui/core/TextField';

import { Dialog } from '../../../../../fe_common/client/components/display';

interface ConfirmDialogProps {
  onConfirm: (i: string) => void;
  onClose: () => void;
  open: boolean;
  introduceYourself: string;
  saving: boolean;
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { onConfirm, onClose, open, introduceYourself, saving } = props;

  const textRef = useRef<HTMLInputElement>(null);

  const handleConfirm = () => {
    const intro = textRef?.current?.value;

    if (!intro) {
      alert('Please enter intro to the client');
      return;
    }

    onConfirm(intro);
  };

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      fullWidth
      containerMaxWidth="md"
      title="Introduction for the client"
      actions={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          color: 'primary',
          variant: 'contained',
          label: 'Select',
          disabled: saving,
          loading: saving,
          onClick: handleConfirm,
        },
      ]}
    >
      <TextField
        inputRef={textRef}
        fullWidth
        label="Edit intro"
        multiline
        maxRows={10}
        defaultValue={introduceYourself}
        variant="outlined"
      />
    </Dialog>
  );
};

export default ConfirmDialog;
