import React, { FC } from 'react';
import { FeedPost, Organization } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Tooltip from '@material-ui/core/Tooltip';

import FeedContextMenuButton from './FeedContextMenuButton';
import { FEED_MEMBERS } from '../types';
import { useFeedState } from '../FeedContext';
import FeedAuthorAvatar from '../FeedAuthorAvatar';
import FeedPostTopic from '../FeedPostTopic';
import { formatDateString } from '../../../services/utils';

import { useFeedPostHeaderStyles } from './styles';

interface FeedPostHeaderProps {
  post: FeedPost;
  author: {
    name: string;
    organization: Organization;
  };
}

const FeedPostHeader: FC<FeedPostHeaderProps> = ({ post, author }) => {
  const classes = useFeedPostHeaderStyles();

  const { isEditable } = useFeedState();

  const { uuid: postUuid, updated, topic, permissions } = post;
  const { name = '' } = author;
  const orgName = author?.organization?.name || name;

  return (
    <Grid container wrap="nowrap" justifyContent="space-between" className={classes.headerContainer}>
      <Grid item>
        <Tooltip title={orgName} placement="right">
          <ListItem className={classes.author}>
            <ListItemAvatar>
              <FeedAuthorAvatar author={author} className={classes.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={formatDateString(updated)}
              classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
            />
          </ListItem>
        </Tooltip>
      </Grid>
      <Grid item>
        <FeedPostTopic topic={topic} className={classes.topic} />
        {isEditable && (
          <FeedContextMenuButton
            permissions={permissions}
            feedMemberUuid={postUuid}
            feedMemberType={FEED_MEMBERS.POST}
            classes={{ root: classes.contextMenuButton }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FeedPostHeader;
