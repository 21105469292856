import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import MultiplePackagesSelector from '../MultiplePackagesSelector';
import { MultiplePackagesSelectorProps } from '../MultiplePackagesSelector/types';

type MultiplePackagesSelectorInputProps = WrappedFieldProps & MultiplePackagesSelectorProps;

const MultiplePackagesSelectorInput: React.FC<MultiplePackagesSelectorInputProps> = (props) => {
  const { input, meta, ...rest } = props;
  const { error, touched, invalid } = meta;

  const hasError = touched && invalid;
  const errorMessage = hasError && error ? error : undefined;

  return (
    <MultiplePackagesSelector value={input?.value} onChange={input?.onChange} errorMessage={errorMessage} {...rest} />
  );
};

export default MultiplePackagesSelectorInput;
