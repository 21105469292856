import React from 'react';
import { MarketerMatch, MarketingServiceType } from '@mayple/types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import {
  AD_SPEND_LABELS_LOOKUP,
  COUNTRIES_LABELS_LOOKUP,
  INDUSTRIES_LABELS_LOOKUP,
  LANGUAGES_LABELS_LOOKUP,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
  TARGET_KPI_LABELS_LOOKUP,
} from '../../../../../../../fe_common/client/app/enums';
import { Card, TextDisplay } from '../../../../../../../fe_common/client/components/display';
import { colorPalette } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    noMatchStyle: {
      color: colorPalette.gray['40'],
    },
  }),
);

interface MarketerMatchCardProps {
  marketerMatch: MarketerMatch;
}

const marketingSkillsLabelsLookup = MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS as Record<MarketingServiceType, string>;

const MarketerMatchCard: React.FC<MarketerMatchCardProps> = (props) => {
  const classes = useStyles();

  const { marketerMatch } = props;

  const {
    name,
    displayImageUrl,
    marketerId,
    monthlyBudgetRangeAmount,
    matchedMonthlyBudgetRangeAmount,
    matchedLanguages,
    matchedCountries,
    countryExpertise,
    matchedIndustries,
    matchedMarketingSkills,
    matchedProductBusinessModelExpertise,
    matchedTargetKPIExpertise,
    levelOfService,
    marketerHistoricalPerformance,
  } = marketerMatch;

  const isMatchedMarketingSkills = matchedMarketingSkills && matchedMarketingSkills.length > 0;

  // console.log('match', match);

  const matchedData = [
    {
      data: matchedMonthlyBudgetRangeAmount,
      label: 'Matched ad spend',
      labelsLookup: AD_SPEND_LABELS_LOOKUP,
    },
    {
      data: monthlyBudgetRangeAmount,
      label: 'Ad spend managed',
      labelsLookup: AD_SPEND_LABELS_LOOKUP,
    },
    {
      data: matchedLanguages,
      label: 'Matched languages',
      labelsLookup: LANGUAGES_LABELS_LOOKUP,
    },
    {
      data: matchedCountries,
      label: 'Matched countries',
      labelsLookup: COUNTRIES_LABELS_LOOKUP,
    },
    {
      data: [countryExpertise.length],
      label: 'Number of countries experienced in',
    },
    {
      data: matchedIndustries,
      label: 'Matched industries',
      labelsLookup: INDUSTRIES_LABELS_LOOKUP,
    },
    // {
    //   data: matchedMarketingSkills,
    //   label: 'Matched marketing skills',
    //   labelsLookup: MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
    // },
    {
      data: matchedProductBusinessModelExpertise,
      label: 'Matched Product Business Models',
    },
    {
      data: matchedTargetKPIExpertise,
      label: 'Matched KPI expertise',
      labelsLookup: TARGET_KPI_LABELS_LOOKUP,
    },
  ];

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <Card.Header
          avatar={<Avatar style={{ height: 80, width: 80 }} src={displayImageUrl} />}
          // @ts-ignore
          title={
            <Link to={`/marketers/${marketerId}/update`}>
              {name} ID: {marketerId}
            </Link>
          }
          // subheader={`Score: ${Math.round(score * 10000) / 100}%`}
        />
        <TextDisplay bold variant="h6" gutterBottom style={{ color: 'gray' }}>
          Matching data (by Mayple):
        </TextDisplay>
        {matchedData.map((matchItem) => {
          const { data = [], label = '', labelsLookup = {} } = matchItem;

          const key = `${label.replace(/\s/g, '')}-key-${marketerId}-card`;

          if (data && data.length > 0) {
            return (
              <React.Fragment key={key}>
                <TextDisplay bold variant="subtitle2">
                  {label}:
                </TextDisplay>

                {(data as unknown[]).map((item) => {
                  if (typeof item !== 'string') {
                    // @ts-ignore
                    if (item && item?.industrySubCategory) {
                      return (
                        <TextDisplay
                          key={`${label.replace(/\s/g, '')}-key-${marketerId}-${item}-text`}
                          colorOverride="#19881f"
                        >
                          {/* @ts-ignore */}
                          {labelsLookup[item.industrySubCategory] || item.industrySubCategory}
                        </TextDisplay>
                      );
                    }
                  }

                  return (
                    <TextDisplay
                      key={`${label.replace(/\s/g, '')}-key-${marketerId}-${item}-text`}
                      colorOverride="#19881f"
                    >
                      {/* @ts-ignore */}
                      {labelsLookup[item] || item}
                    </TextDisplay>
                  );
                })}

                <Divider />
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={key}>
              <div className={classes.noMatchStyle}>No match in {label}</div>
              <Divider />
            </React.Fragment>
          );
        })}
        <Divider />
        <TextDisplay bold variant="subtitle2" style={{ color: 'gray' }}>
          Marketer data (by marketer):
        </TextDisplay>
        <div>
          <TextDisplay bold>Matched marketing skills:</TextDisplay>
          {isMatchedMarketingSkills &&
            matchedMarketingSkills.map(({ skillType, skillLevel }) => (
              <TextDisplay
                key={`MARKETING_SKILLS-key-${marketerId}-${skillType}-${skillLevel}`}
                colorOverride="#0056ff"
              >
                {marketingSkillsLabelsLookup[skillType as MarketingServiceType]} (Level: {skillLevel}),
              </TextDisplay>
            ))}
          {!isMatchedMarketingSkills && <div className={classes.noMatchStyle}>No match in requested skills</div>}
        </div>

        <Divider />
        <TextDisplay>Level of service: {levelOfService}</TextDisplay>
        <TextDisplay>Historical performance: {marketerHistoricalPerformance}</TextDisplay>
      </Card>
    </Grid>
  );
};

export default MarketerMatchCard;
