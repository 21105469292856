import React, { FC } from 'react';

import { MarketingPlanProgressProps } from './types';
import { TRANSLATION_NS } from './consts';
import onboardingEn from '../translations/en/onboarding.json';
import MarketingPlanStepper from './MarketingPlanStepper';
import MarketingPlanProgressHeader from './MarketingPlanProgressHeader';
import { useMarketingPlanState } from '../MarketingPlanProvider';

import LoadingPlaceholder from '../../atoms/LoadingPlaceholder';
import CalloutMessageCollapse from '../../common/CalloutMessageCollapse';
import useI18nLazyBundleLoader from '../../../hooks/useI18nLazyBundleLoader';

import useStyles from './style';

const MarketingPlanProgress: FC<MarketingPlanProgressProps> = (props) => {
  const classes = useStyles(props);

  useI18nLazyBundleLoader(TRANSLATION_NS, onboardingEn);

  const { isLoading, isInitialized, errorMessages, clearErrorMessages } = useMarketingPlanState();

  return (
    <div className={classes.root}>
      {isLoading && <LoadingPlaceholder />}
      {errorMessages && errorMessages.length > 0 && !isLoading && (
        <CalloutMessageCollapse
          message={errorMessages[0]}
          type="error"
          onMessageDisappear={clearErrorMessages}
          timeToShow={5}
        />
      )}
      {!isLoading && isInitialized && (
        <div className={classes.marketingPlanProgressRoot}>
          <MarketingPlanProgressHeader />
          <MarketingPlanStepper />
        </div>
      )}
    </div>
  );
};

export default MarketingPlanProgress;
