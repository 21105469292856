import { GlobalEntitySearchResult } from '@mayple/types';
import truncate from 'lodash/truncate';

export const formatResult = (searchResult: GlobalEntitySearchResult): string => {
  const { entityType, entityId, name } = searchResult;
  return `${entityType} - ${name} - ${entityId}`;
};

export const escapeRegExp = (text: string): string => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const sortByEntityType = (a: GlobalEntitySearchResult, b: GlobalEntitySearchResult): number =>
  a.entityType <= b.entityType ? -1 : 1;

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const getDescriptionOutOfContext = (context: any, searchTerm: string): string => {
  const contextStr = JSON.stringify(context);
  const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gim');
  const index = contextStr.search(regex);

  if (index < 0) {
    return '';
  }

  return truncate(contextStr.slice(index), { length: 120, separator: ' ' }).replace(regex, '<em>$1</em>');
};
