import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import SwitchField from '../../../../../fe_common/client/components/atoms/SwitchField';
import useProjectData from '../../../../../fe_common/client/hooks/project/useProjectData';
import useUpdateProject from '../../../../hooks/useUpdateProject';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface AllowManualPaymentAgreementChargingSwitchProps {
  projectId: number;
  classes?: Record<string, string>;
}

const AllowManualPaymentAgreementChargingSwitch: React.FC<AllowManualPaymentAgreementChargingSwitchProps> = (props) => {
  const classes = useStyles();
  const { projectId } = props;
  const { project, refetch } = useProjectData(projectId);

  const checked = !!project?.allowManualPaymentAgreementCharging;

  const { updateProject } = useUpdateProject();

  const onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => Promise<void> = async (
    _event,
    newValue,
  ) => {
    await updateProject(projectId, { allowManualPaymentAgreementCharging: newValue }, refetch);
  };

  return (
    <div className={classes.root}>
      <SwitchField
        name="allowManualPaymentAgreementCharging"
        label="Allow Manual Payment Agreement Charging"
        helperText="Whether to allow charging a company's manual payment agreement (if it exists) or not for this project. If charging of the manual payment agreement is not allowed, then the credit card or PayPal payment agreements will be used instead, or alternatively debt will be created."
        checked={checked}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default AllowManualPaymentAgreementChargingSwitch;
