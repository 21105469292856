import React, { useCallback, useMemo } from 'react';
import { SupervisorType } from '@mayple/types';

import useSetSupervisorType from './logic';
import { useProjectDetailsState } from '../../ProjectDetailsProvider';

import { LabelValueProps } from '../../../../../fe_common/client/app/types';
import Select from '../../../../../fe_common/client/components/atoms/Registration/Select';
import EntityAvatar from '../../../../../fe_common/client/components/atoms/EntityAvatar';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

type ProjectSupervisorProps = {
  projectId: number | null | undefined;
  classes?: Record<string, string>;
};

const ProjectSupervisor: React.FC<ProjectSupervisorProps> = (props) => {
  const { projectId } = props;

  const { loadingSupervisor, supervisor, supervisorType, allowedSupervisorTypes, refetchSupervisor, refetchData } =
    useProjectDetailsState();

  const { saveSupervisor, error } = useSetSupervisorType();

  const onSelectHandler = useCallback(
    async (newSupervisorType: SupervisorType) => {
      await saveSupervisor(projectId, { supervisorType: newSupervisorType });
      Promise.all([refetchData?.(), refetchSupervisor?.()]);
    },
    [projectId, refetchData, refetchSupervisor, saveSupervisor],
  );

  const allowedSupervisorTypesEnum: LabelValueProps[] = useMemo(
    () =>
      (allowedSupervisorTypes || []).map((allowedSupervisorType) => ({
        label: allowedSupervisorType,
        value: allowedSupervisorType,
      })),
    [allowedSupervisorTypes],
  );

  const shouldDisableSelection = allowedSupervisorTypes?.length === 1;

  return (
    <Section gutterTopSize={2} noBorder title="Project Supervisor" layout={SECTION_LAYOUT.ONE_COLUMN}>
      <Select
        label="Current Supervisor type"
        enums={allowedSupervisorTypesEnum}
        onSelect={onSelectHandler}
        value={supervisorType ?? ''}
        showError={!!error}
        disabled={shouldDisableSelection}
      />

      <EntityAvatar
        loading={loadingSupervisor}
        primary={supervisor ? 'Current Supervisor' : 'Missing Supervisor'}
        secondary={supervisor ? supervisor?.name : 'Please select main MC or GS representative'}
        avatarImageUrl={supervisor?.displayImageUrl}
      />
    </Section>
  );
};

export default ProjectSupervisor;
