import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import {
  INDUSTRIES_PAIRS,
  LANGUAGES_PAIRS,
  MARKETING_SKILLS_PAIRS_WITH_ICONS,
  TARGET_KPI_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  AD_SPEND_PAIRS,
  YES_OR_NO_PAIRS,
  TARGETED_COUNTRIES_PAIRS,
  COUNTRIES_PAIRS,
  INDUSTRIES_GROUPS,
  PRODUCT_COST_RANGE,
  CLIENT_STAGE_EXPERTISE,
  MARKETER_TYPE_PAIRS,
  PROJECT_SPECIAL_TYPE,
} from '../../../../../fe_common/client/app/enums';

import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';

const AND_OR_PAIRS = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' },
];

interface WizardStepsProps {
  classes: Record<string, string>;
}

const WizardSteps: FC<WizardStepsProps> = () => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Working Group"
          name="workingGroup"
          enums={PROJECT_SPECIAL_TYPE}
          type={WizardStepField.types.AUTOCOMPLETE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Marketing skills"
          name="marketingSkills"
          enums={MARKETING_SKILLS_PAIRS_WITH_ICONS}
          type={WizardStepField.types.AUTOCOMPLETE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Marketer Type"
          enums={MARKETER_TYPE_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="marketerType"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Business model"
          enums={PRODUCT_BUSINESS_MODEL_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="productBusinessModelExpertise"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Target KPI Expertise"
          enums={TARGET_KPI_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="targetKPIExpertise"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Industry Category"
          enums={INDUSTRIES_GROUPS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="industryExpertiseCategory"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Industry Sub Category"
          enums={INDUSTRIES_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="industryExpertiseSubCategory"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Target languages"
          name="languageExpertise"
          enums={LANGUAGES_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Target locations"
          name="countryExpertise"
          type={WizardStepField.types.AUTOCOMPLETE}
          enums={TARGETED_COUNTRIES_PAIRS}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Marketer Location"
          name="marketerLocation"
          enums={COUNTRIES_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Budgets"
          name="monthlyBudgetRangeAmount"
          type={WizardStepField.types.AUTOCOMPLETE}
          enums={AD_SPEND_PAIRS}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Marketer project fee amount less than (USD)"
          type={WizardStepField.types.CURRENCY}
          currency="USD"
          name="minimumProjectFeeAmount"
          minimumValue={0}
          maximumValue={1000000}
          precision={0}
          hideCurrencySelect
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Success stories industry category"
          enums={INDUSTRIES_GROUPS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="successStoriesIndustryCategory"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Success stories industry subcategory"
          enums={INDUSTRIES_PAIRS}
          type={WizardStepField.types.AUTOCOMPLETE}
          name="successStoriesIndustrySubCategory"
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Success stories skills used"
          name="successStoriesSkillsUsed"
          enums={MARKETING_SKILLS_PAIRS_WITH_ICONS}
          type={WizardStepField.types.AUTOCOMPLETE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Min number of total projects assigned"
          type={WizardStepField.types.NUMBER}
          name="numbersOfTotalProjectsAssigned"
          minimumValue={0}
          maximumValue={1000000}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Min number of live projects assigned"
          type={WizardStepField.types.NUMBER}
          name="numberOfLiveProjectsAssigned"
          minimumValue={0}
          maximumValue={1000000}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Are Vetted"
          type={WizardStepField.types.SELECT}
          enums={YES_OR_NO_PAIRS}
          name="isVetted"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Are Available for work"
          type={WizardStepField.types.SELECT}
          enums={YES_OR_NO_PAIRS}
          name="availableForProjects"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Matching enabled by admin"
          type={WizardStepField.types.SELECT}
          enums={YES_OR_NO_PAIRS}
          name="enableMatching"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Project Type"
          type={WizardStepField.types.AUTOCOMPLETE}
          enums={PROJECT_SPECIAL_TYPE}
          name="matchableForProjectSpecialTypes"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Product costs range"
          type={WizardStepField.types.AUTOCOMPLETE}
          name="productCostRange"
          enums={PRODUCT_COST_RANGE}
          isMulti
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WizardStepField
          // @ts-ignore
          label="Client stage expertise"
          type={WizardStepField.types.AUTOCOMPLETE}
          name="clientStageExpertise"
          enums={CLIENT_STAGE_EXPERTISE}
          isMulti
        />
      </Grid>

      <Grid item xs={12}>
        <WizardStepField
          wizardFieldLabel="Require match on all marketer properties"
          name="filtersGlobalOperator"
          // @ts-ignore
          enums={AND_OR_PAIRS}
          type={WizardStepField.types.RADIO}
          vertical={false}
          style={{ alignItems: 'center', flexWrap: 'no-wrap' }}
          labelProps={{ xs: 6 }}
          valueProps={{ xs: 6 }}
        />
      </Grid>
      <Grid item xs={12}>
        <WizardStepField
          wizardFieldLabel="Require match for all values inside a marketer property"
          name="filterValuesOperator"
          // @ts-ignore
          enums={AND_OR_PAIRS}
          type={WizardStepField.types.RADIO}
          vertical={false}
          style={{ alignItems: 'center', flexWrap: 'no-wrap' }}
          labelProps={{ xs: 6 }}
          valueProps={{ xs: 6 }}
        />
      </Grid>
    </Grid>
  </div>
);

export default WizardSteps;
