import React, { FC, useCallback, useMemo, useState } from 'react';
import { ExecutionResult } from 'graphql';
import { EntityOperationResponse } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { ReportMultipleMarketerPayoutAsWithdrawnManuallyMutation } from 'growl-graphql/dist/mutations/billing/marketerPayout/ReportMultipleMarketerPayoutAsWithdrawnManuallyMutation';

import {
  MarketersPayoutReviewDataRow,
  MarketersPayoutReviewTableProps,
  RowDataType,
  SelectedPayoutData,
} from './types';
import TableToolbar from './TableToolbar';
import MarketersPayoutReviewTableHead from './MarketersPayoutReviewTableHead';
import MarketersPayoutReviewFullRow from './MarketersPayoutReviewFullRow';
import MarketersPayoutReviewPartialRow from './MarketersPayoutReviewPartialRow';
import {
  getFilteredMarketerPaymentData,
  getReportMultipleMarketerPayoutAsWithdrawnManuallyVariables,
  marketersPayoutReviewTableCols,
  smartSlice,
  updateTotalBalance,
} from './logic';
import {
  MARKETERS_PAYOUT_REVIEW_CSV_COLUMNS,
  MARKETERS_PAYOUT_REVIEW_TIPALTI_CSV_COLUMNS,
  referenceCodeFormatter,
} from './exprotToCSV';

import { exportDataToCsv } from '../../../../../../../fe_common/client/services/ExportToCSV';
import useMutation from '../../../../../../../fe_common/client/hooks/useMutation';
import { tryParseInt } from '../../../../../../../fe_common/client/services/utils';
import { Button } from '../../../../../../../fe_common/client/components/inputs';
import { handleClientError } from '../../../../../../../fe_common/client/services/logger';

import { useTableStyles } from './styles';

const DEFAULT_ROWS_PER_PAGE = 5;

export enum SelectionActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

const MarketersPayoutReviewTable: FC<MarketersPayoutReviewTableProps> = (props) => {
  const { data, totalRows, loading = false, onPayoutReportSuccess, onPageNumberChange, onPageSizeChange } = props;
  const classes = useTableStyles(props);
  const [selectedMarketers, setSelectedMarketers] = useState<number[]>([]);
  const [selectedMarketerPayoutIds, setSelectedMarketerPayoutIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [selectedPayoutData, setSelectedPayoutData] = useState<SelectedPayoutData>({});

  const numOfCyclesPerMarketer: Record<number, number> = useMemo(() => {
    const groupedPayoutData: SelectedPayoutData = {};
    data.forEach((rowData) => {
      if (!groupedPayoutData[rowData.marketerId]) {
        groupedPayoutData[rowData.marketerId] = [];
      }

      if (rowData.marketerPayoutUuid) {
        groupedPayoutData[rowData.marketerId].push(rowData.marketerPayoutUuid);
      }
    });

    const newNumOfCyclesPerMarketer: Record<number, number> = {};
    Object.entries(groupedPayoutData).forEach(([key, value]) => {
      newNumOfCyclesPerMarketer[parseInt(key, 10)] = (value || []).length;
    });

    return newNumOfCyclesPerMarketer;
  }, [data]);

  const {
    mutate: reportMultipleMarketerPayoutAsWithdrawnManually,
    loading: loadingReportPartialPayoutForMultipleMarketers,
  } = useMutation(ReportMultipleMarketerPayoutAsWithdrawnManuallyMutation);

  const updateSelectedMarketersAndMarketerPayoutIds = (newSelectedPayoutData: SelectedPayoutData): void => {
    setSelectedMarketers(Object.keys(newSelectedPayoutData).map((value) => parseInt(value, 10)));
    setSelectedMarketerPayoutIds(
      Object.values(newSelectedPayoutData).reduce(
        (previousValue, currentValue) => [...previousValue, ...currentValue],
        [],
      ),
    );
  };

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const actionType: SelectionActionType = checked ? SelectionActionType.ADD : SelectionActionType.REMOVE;

      if (actionType === SelectionActionType.ADD) {
        const newSelectedPayoutData: SelectedPayoutData = {};
        data.forEach((rowData) => {
          if (!newSelectedPayoutData[rowData.marketerId]) {
            newSelectedPayoutData[rowData.marketerId] = [];
          }

          if (rowData.marketerPayoutUuid && !rowData.payoutWithdrawed) {
            newSelectedPayoutData[rowData.marketerId].push(rowData.marketerPayoutUuid);
          }
        });

        setSelectedPayoutData(newSelectedPayoutData);
        updateSelectedMarketersAndMarketerPayoutIds(newSelectedPayoutData);
      }

      if (actionType === SelectionActionType.REMOVE) {
        setSelectedPayoutData({});
        setSelectedMarketers([]);
        setSelectedMarketerPayoutIds([]);
      }
    },
    [data],
  );

  const onFullRowClickHandler = useCallback(
    (newMarketerId: number) => {
      const actionType: SelectionActionType = selectedPayoutData?.[newMarketerId]
        ? SelectionActionType.REMOVE
        : SelectionActionType.ADD;

      // Get the relevant cycle Id's (belong to the expert)
      const relevantCycles: string[] = Array.from(
        new Set(
          data
            .filter(
              (row) => row.marketerId === newMarketerId && row.marketerPayoutUuid != null && !row.payoutWithdrawed,
            )
            .map((row) => row.marketerPayoutUuid as string),
        ),
      );

      const newSelectedPayoutData = { ...selectedPayoutData };

      if (actionType === SelectionActionType.REMOVE) {
        delete newSelectedPayoutData[newMarketerId];
      }

      if (actionType === SelectionActionType.ADD) {
        newSelectedPayoutData[newMarketerId] = [...relevantCycles];
      }

      setSelectedPayoutData(newSelectedPayoutData);
      updateSelectedMarketersAndMarketerPayoutIds(newSelectedPayoutData);
    },
    [data, selectedPayoutData],
  );

  const onPartialRowClickHandler = useCallback(
    (newMarketerId: number, newMarketerPayoutUuid: string) => {
      const actionType: SelectionActionType = (selectedPayoutData?.[newMarketerId] || []).includes(
        newMarketerPayoutUuid,
      )
        ? SelectionActionType.REMOVE
        : SelectionActionType.ADD;

      const newSelectedPayoutData = { ...selectedPayoutData };
      let newMarketerPayoutUuidArray: string[] = Array.from(
        new Set<string>([...(newSelectedPayoutData?.[newMarketerId] || [])]),
      );

      if (actionType === SelectionActionType.REMOVE) {
        newMarketerPayoutUuidArray = newMarketerPayoutUuidArray.filter(
          (prevMarketerPayoutUuid) => prevMarketerPayoutUuid !== newMarketerPayoutUuid,
        );
      } else {
        newMarketerPayoutUuidArray.push(newMarketerPayoutUuid);
      }

      if (!newMarketerPayoutUuidArray.length) {
        delete newSelectedPayoutData[newMarketerId];
      }

      newSelectedPayoutData[newMarketerId] = newMarketerPayoutUuidArray;

      setSelectedPayoutData(newSelectedPayoutData);
      updateSelectedMarketersAndMarketerPayoutIds(newSelectedPayoutData);
    },
    [selectedPayoutData],
  );

  const handleChangePage = useCallback(
    (_event, newPage) => {
      setPage(newPage);
      onPageNumberChange?.(newPage);
    },
    [onPageNumberChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newPageSize = tryParseInt(value, DEFAULT_ROWS_PER_PAGE);
      setRowsPerPage(newPageSize);
      setPage(0);
      onPageSizeChange?.(newPageSize);
      onPageNumberChange?.(0);
    },
    [onPageNumberChange, onPageSizeChange],
  );

  const handleMarketersPayoutOnClick = async () => {
    if (selectedMarketers.length === 0) {
      return;
    }

    const ans = window.confirm(
      `Please make sure that you have exported all selected data to Tipalti CSV before proceeding.\n` +
        `Are you sure you want to report payout for marketers: ${selectedMarketers.join(', ')}`,
    );

    if (!ans) {
      return;
    }

    const description = window.prompt('Please write down a reason for this payout', 'Monthly payout');

    if (!description) {
      window.alert('Description is required.');
      return;
    }

    try {
      const variables = getReportMultipleMarketerPayoutAsWithdrawnManuallyVariables(selectedPayoutData, description);

      const result: ExecutionResult<{ reportMultipleMarketerPayoutAsWithdrawnManually: EntityOperationResponse }> =
        await reportMultipleMarketerPayoutAsWithdrawnManually({
          variables,
        });

      if (!result?.data?.reportMultipleMarketerPayoutAsWithdrawnManually?.success) {
        throw new Error('could not update payout data');
      }

      // clear selections
      setSelectedPayoutData({});
      setSelectedMarketers([]);
      setSelectedMarketerPayoutIds([]);

      // refetch data
      onPayoutReportSuccess?.();
    } catch (e) {
      handleClientError(e, 'could not update payout data');
    }
  };

  const handleExportToCSVOnClick = (items: any) => {
    exportDataToCsv(MARKETERS_PAYOUT_REVIEW_CSV_COLUMNS, items);
  };

  const handleExportToTipaltiCSVOnClick = useCallback(
    (items: MarketersPayoutReviewDataRow[]) => {
      const filteredItems = getFilteredMarketerPaymentData(items, selectedPayoutData);
      const tipaltiItems = updateTotalBalance(filteredItems, selectedPayoutData);
      exportDataToCsv(MARKETERS_PAYOUT_REVIEW_TIPALTI_CSV_COLUMNS, tipaltiItems, referenceCodeFormatter('Tipalti'));
      // exportDataToCsv(MARKETERS_PAYOUT_REVIEW_TIPALTI_CSV_COLUMNS, items, referenceCodeFormatter('Tipalti'));
    },
    [selectedPayoutData],
  );

  const checkIsSelectedMarketer = (id = 0) => selectedMarketers.includes(id);
  const checkIsSelectedMarketerPayoutId = (marketerPayoutUuid: string | null | undefined) =>
    marketerPayoutUuid ? selectedMarketerPayoutIds.includes(marketerPayoutUuid) : false;

  const numSelected = selectedMarketers.length;

  const haveSelectedPayoutData = Object.keys(selectedPayoutData).length > 0;

  const tablePagedData = useMemo(() => smartSlice(data, page, rowsPerPage), [data, page, rowsPerPage]);

  return (
    <>
      <TableToolbar
        selectedMarketers={selectedMarketers}
        totalRows={totalRows}
        actions={
          <>
            <Button
              variant="outlined"
              color="primary"
              label="Export to CSV"
              onClick={() => {
                handleExportToCSVOnClick(data);
              }}
              disabled={loading || loadingReportPartialPayoutForMultipleMarketers}
              className={classes.exportToCSVButton}
            />
            <Button
              variant="outlined"
              color="primary"
              label="Export to Tipalti"
              onClick={() => {
                handleExportToTipaltiCSVOnClick(data);
              }}
              disabled={loading || loadingReportPartialPayoutForMultipleMarketers || !haveSelectedPayoutData}
              className={classes.exportToCSVButton}
            />
            <Button
              variant="contained"
              color="primary"
              label="Payout marketers"
              onClick={handleMarketersPayoutOnClick}
              loading={loadingReportPartialPayoutForMultipleMarketers}
              disabled={loading || loadingReportPartialPayoutForMultipleMarketers || !haveSelectedPayoutData}
            />
          </>
        }
      />
      <Divider />
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Divider />

      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <MarketersPayoutReviewTableHead
            cols={marketersPayoutReviewTableCols}
            numSelected={numSelected}
            rowCount={totalRows}
            handleSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {tablePagedData.map((dataRow: MarketersPayoutReviewDataRow) => {
              const { rowType, marketerId, projectId, marketerPayoutUuid } = dataRow;
              const isSelectedMarketer = checkIsSelectedMarketer(marketerId);
              const isSelectedMarketerPayoutId = checkIsSelectedMarketerPayoutId(marketerPayoutUuid);
              const key = `${marketerId}-${projectId}-${marketerPayoutUuid}`;

              if (rowType === RowDataType.FULL) {
                return (
                  <MarketersPayoutReviewFullRow
                    key={`marketers-payout-review-table-full-row-${key}`}
                    dataRow={dataRow}
                    isSelected={isSelectedMarketer}
                    numOfSelectedCycles={selectedPayoutData?.[marketerId]?.length || 0}
                    totalNumOfCycles={numOfCyclesPerMarketer[marketerId]}
                    onSelectRow={onFullRowClickHandler}
                  />
                );
              }

              return (
                <MarketersPayoutReviewPartialRow
                  key={`marketers-payout-review-table-partial-row-${key}`}
                  dataRow={dataRow}
                  isSelected={isSelectedMarketerPayoutId}
                  onSelectRow={onPartialRowClickHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default MarketersPayoutReviewTable;
