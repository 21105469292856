import React from 'react';
import moment from 'moment/moment';
import { TaskType } from '@mayple/types';
import { useTranslation } from 'react-i18next';

import useAssessmentDataForProject from './useAssessmentDataForProject';

import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';
import { Card, TextDisplay } from '../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';
import { Assessment } from './types';

interface MarketerAssessmentByCompanyDetailsProps {
  projectId: number;
}

const MarketerAssessmentByCompanyDetails: React.FC<MarketerAssessmentByCompanyDetailsProps> = (props) => {
  const classes = useStyles();
  const { projectId } = props;

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'ProjectDetailsPage.MarketerAssessmentDetails' });
  const { assessments } = useAssessmentDataForProject(TaskType.MonthlyMarketerAssessmentByCompany, projectId);

  return (
    <div className={classes.root}>
      <TextDisplay variant="h5" gutterBottom>
        {t('marketerAssessmentByCompanyTitle', 'Expert Assessment By Company')}
      </TextDisplay>
      {(assessments || ([] as Assessment[])).map(({ created, votes }) => {
        const key = moment(created).format(`YYYY-MM`);
        return (
          <Card key={`marketer-assessment-${key}`} elevation={0} square classes={{ root: classes.assessmentsCard }}>
            <Card.Content classes={{ root: classes.cardContent }}>
              <div>
                <TextDisplay variant="subtitle1" className={classes.assessmentMonth}>
                  {moment(created).format('MMMM YYYY')}
                </TextDisplay>
                <TextDisplay className={classes.assessmentDate}>{moment(created).calendar()}</TextDisplay>
              </div>
              <div className={classes.assessmentVotes}>
                {!votes.length && (
                  <CalloutMessage
                    type="warning"
                    message={t('missingAssessmentData', `Assessment wasn't filled`)}
                    margin
                  />
                )}
                {!!votes.length &&
                  votes.map(({ category, value, maxValue }) => (
                    <div key={`vote-${key}-${category}`} className={classes.vote}>
                      <TextDisplay>{t(category)}</TextDisplay>
                      <TextDisplay bold>{`${value}/${maxValue}`}</TextDisplay>
                    </div>
                  ))}
              </div>
            </Card.Content>
          </Card>
        );
      })}
    </div>
  );
};

export default MarketerAssessmentByCompanyDetails;
