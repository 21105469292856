import React from 'react';
import omit from 'lodash/omit';
import { ProjectLifeCycleStatus } from '@mayple/types';

import { FilterRenderProps } from '../types';

export interface ProjectLifeCycleFilterProps extends FilterRenderProps {
  excludedValues?: ProjectLifeCycleStatus[];
}

export type ProjectLifeCycleFilterRender = (params: ProjectLifeCycleFilterProps) => React.ReactElement;

const ProjectLifeCycleFilter: ProjectLifeCycleFilterRender = (props) => {
  const { filter, onChange, excludedValues = [] } = props;

  const filteredProjectLifeCycleStatus = omit({ ...ProjectLifeCycleStatus }, excludedValues);
  const projectLifeCycleStatuses = Object.entries(filteredProjectLifeCycleStatus);

  return (
    <select
      onChange={(event) => {
        // @ts-ignore
        onChange(event.target.value);
      }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      {projectLifeCycleStatuses.map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default ProjectLifeCycleFilter;
