import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { Button } from '../../inputs';
import { muiIcons } from '../../../app/icons';

// decides what buttons to show for wizardForm navigation
export function WizardNavigationButtons(props) {
  // console.log('WizardNavigationButtons props', props);
  const {
    nextButtonLabel,
    finishButtonLabel,
    backButtonLabel,
    skipButtonLabel,
    showSkipButton,
    onNextButtonClick,
    onSkipButtonClick,
    onFinishButtonClick,
    disableNext,
    hideNext,
    hideBack,
    floatRight,
    step,
    finishButtonId,
    submit,
    invalid,
    verticalMode,
    buttonsSize,
    marginTop,
    submitting,
    containerStyle,
    ...rest
  } = props;

  const buttonsContainerStyle = {
    marginTop,
    paddingBottom: 8,
    overflow: 'hidden',
    ...containerStyle,
  };

  // this will check if the form is valid.
  // if not, it will trigger a submit, forcing the errors to show to the user.
  const handleNext = () => {
    if (invalid) {
      submit();
    } else {
      onNextButtonClick();
      step?.next?.();
    }
  };

  const currentStep = step?.step || {};
  const allSteps = step?.steps || [currentStep];
  const isLastStep = currentStep?.id === allSteps[allSteps.length - 1]?.id;

  const finishButtonExtraProps = {};

  if (finishButtonId) {
    finishButtonExtraProps.id = finishButtonId;
  }

  if (!verticalMode) {
    const nextButtonStyle = floatRight ? { float: 'right' } : { float: 'left' };
    const skipButtonStyle = {
      float: 'right',
      marginRight: 12,
    };

    const nextButton = (
      <Button
        disabled={submitting || disableNext}
        variant="contained"
        color="primary"
        label={nextButtonLabel}
        onClick={handleNext}
        style={nextButtonStyle}
        size={buttonsSize}
        {...rest}
      />
    );

    const finishButton = (
      <Button
        disabled={submitting || disableNext}
        variant="contained"
        color="primary"
        type="submit"
        label={finishButtonLabel}
        onClick={() => {
          onFinishButtonClick();
          submit();
        }}
        style={nextButtonStyle}
        size={buttonsSize}
        {...rest}
        {...finishButtonExtraProps}
      />
    );

    const backButton = (
      <Button
        disabled={submitting}
        label={backButtonLabel}
        onClick={step.previous}
        size={buttonsSize}
        startIcon={muiIcons.arrowLeft}
        {...rest}
      />
    );

    const handleDefaultSkipClick = () => (isLastStep ? submit() : step?.next?.());

    const onSkipClick = onSkipButtonClick || handleDefaultSkipClick;

    const skipButton = (
      <Button size="large" label={skipButtonLabel} onClick={onSkipClick} style={skipButtonStyle} {...rest} />
    );

    // if only 1 step in form
    if (allSteps.length === 1) {
      return <div style={buttonsContainerStyle}>{finishButton}</div>;
      // if first step
    } else if (currentStep?.id === allSteps?.[0]?.id) {
      return (
        <div>
          {
            // optional to hide next button, must use a click handler to go to next step
            !hideNext && (
              <div style={buttonsContainerStyle}>
                {nextButton}
                {showSkipButton && skipButton}
              </div>
            )
          }
          {hideNext && (
            <div style={{ height: 35 }} /> // this is for making the registration cards look good
          )}
          {hideNext && // this is only for showing the skip button on the first step
            showSkipButton &&
            skipButton}
        </div>
      );
      // if last step
    } else if (isLastStep) {
      return (
        <div style={buttonsContainerStyle}>
          {!hideBack && backButton}
          {!hideNext && finishButton}
          {showSkipButton && skipButton}
        </div>
      );
      // if middle step
    } else {
      return (
        <div style={buttonsContainerStyle}>
          {!hideBack && backButton}
          {!hideNext && nextButton}
          {showSkipButton && skipButton}
        </div>
      );
    }
  }

  const nextButton = (
    <Button
      disabled={submitting || disableNext}
      variant="contained"
      color="primary"
      label={nextButtonLabel}
      onClick={handleNext}
      // style={nextButtonStyle}
      size={buttonsSize}
      {...rest}
    />
  );

  const finishButton = (
    <Button
      disabled={submitting || disableNext}
      variant="contained"
      color="primary"
      type="submit"
      label={finishButtonLabel}
      onClick={() => {
        onFinishButtonClick();
        submit();
      }}
      // style={nextButtonStyle}
      size={buttonsSize}
      {...rest}
      {...finishButtonExtraProps}
    />
  );

  const backButton = (
    <Button
      disabled={submitting}
      label={backButtonLabel}
      onClick={step.previous}
      size={buttonsSize}
      // icon={iconCodes.goBack}
      {...rest}
    />
  );

  const onSkipClick = onSkipButtonClick || step.next;

  const skipButton = <Button size="large" label={skipButtonLabel} onClick={onSkipClick} {...rest} />;

  let gridProps = {
    justifyContent: 'space-between',
    container: true,
    style: buttonsContainerStyle,
  };

  if (verticalMode) {
    gridProps = {
      ...gridProps,
      direction: 'column',
      alignItems: 'flex-start',
      style: { ...buttonsContainerStyle, minHeight: 140 },
    };
  }

  // if only 1 step in form
  if (allSteps.length === 1) {
    return (
      <Grid {...gridProps} justifyContent="space-between">
        {finishButton}
      </Grid>
    );
    // if first step
  } else if (currentStep.id === allSteps[0].id) {
    return (
      <Grid {...gridProps} justifyContent="space-between">
        {
          // optional to hide next button, must use a click handler to go to next step
          !hideNext && (
            <div>
              {nextButton}
              {showSkipButton && skipButton}
            </div>
          )
        }
        {
          // this is for making the registration cards look good
          hideNext && <div style={{ height: 35 }} />
        }
      </Grid>
    );
    // if last step
    // eslint-disable-next-line no-unsafe-optional-chaining
  } else if (currentStep.id === allSteps?.[allSteps?.length - 1]?.id) {
    return (
      <Grid {...gridProps} justifyContent="space-between">
        <React.Fragment>
          {!hideNext && finishButton}
          {!hideBack && backButton}
        </React.Fragment>
      </Grid>
    );
    // if middle step
  } else {
    return (
      <Grid {...gridProps} justifyContent="space-between">
        <React.Fragment>
          {!hideNext && nextButton}
          {showSkipButton && skipButton}
          {!hideBack && backButton}
        </React.Fragment>
      </Grid>
    );
  }
}

WizardNavigationButtons.propTypes = {
  step: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  nextButtonLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  finishButtonLabel: PropTypes.string,
  skipButtonLabel: PropTypes.string,
  finishButtonId: PropTypes.string,
  // loadingMutation:           PropTypes.bool,
  disableNext: PropTypes.bool,
  verticalMode: PropTypes.bool,
  hideNext: PropTypes.bool,
  hideBack: PropTypes.bool,
  floatRight: PropTypes.bool,
  showSkipButton: PropTypes.bool,
  onNextButtonClick: PropTypes.func,
  onSkipButtonClick: PropTypes.func,
  onFinishButtonClick: PropTypes.func,
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  buttonsSize: PropTypes.oneOf(['small', 'medium', 'large']),
  containerStyle: PropTypes.object,
};

WizardNavigationButtons.defaultProps = {
  nextButtonLabel: 'Continue',
  buttonsSize: 'large',
  backButtonLabel: 'Back',
  finishButtonLabel: 'Finish',
  skipButtonLabel: 'SKIP',
  finishButtonId: 'SKIP',
  marginTop: '4vh',
  disableNext: false,
  verticalMode: false,
  floatRight: true,
  hideNext: false,
  hideBack: false,
  showSkipButton: false,
  onNextButtonClick: () => {},
  onSkipButtonClick: null,
  onFinishButtonClick: () => {},
  containerStyle: {},
};
