import React, { FC } from 'react';
import moment from 'moment';

import { AnswerProps } from './types';
import { getSingleValueFromAnswer } from './logic';
import { TextDisplay } from '../../../display';

const DateAnswer: FC<AnswerProps> = (props) => {
  const { answer, question } = props;
  const value = getSingleValueFromAnswer(answer);
  const dateFormat = question?.renderingInfo?.componentProps?.dateFormat || 'LL';

  return <TextDisplay variant="subtitle1">{moment(value, 'YYYY-MM-DD').format(dateFormat)}</TextDisplay>;
};

export default DateAnswer;
