import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AdministrationStatusReportQuery } from 'growl-graphql/dist/queries/AdministrationStatusReportQuery';
import { withQuery } from 'growl-graphql/dist/hoc/withQuery';

import { RefreshSpinner } from '../../../fe_common/client/components/atoms/RefreshSpinner';
import { formatDate } from '../../app/utils';

// const sortByDate = (arr, dateKey) => {
//   return [...arr].sort((a, b) => {
//     // Turn your strings into dates, and then subtract them
//     // to get a value that is either negative, positive, or zero.
//     return new Date(a[dateKey]) - new Date(b[dateKey]);
//   });
// };

const AdministrationStatusReportDisconnected = (props) => {
  const { administrationStatusReportQuery } = props;
  const { loading, administrationStatusReport } = administrationStatusReportQuery;
  if (loading) {
    return (
      <div>
        <RefreshSpinner />
        <h4>This might take a while to load..</h4>
      </div>
    );
  }
  // console.log('administrationStatusReportQuery', administrationStatusReportQuery);

  if (!administrationStatusReport) {
    return <div>Could not get Administration Status Report. Please contact dev team.</div>;
  }

  const {
    stuckInOnboardingProjectsDetails,
    problemsWithAdLedgerProjectsDetails,
    problemsInPaymentAgreementsProjectsDetails,
    companiesWithDebtDetails,
    overSpendingProjectsDetails,
  } = administrationStatusReport;

  return (
    <div className="container">
      <h1>Administration Status Report</h1>
      <p>
        The following data is to be displayed by date:
        <br />
        <span style={{ color: 'red' }}>Note: data shown here might be delayed up to 15 minutes since last update</span>
        <br />
        <br />
        <HashLink to="#problemsWithAdLedgerProjectsDetails">
          1. Problems With AdLedger Projects Details - Trouble connecting to an ad or client account and/or reading its
          data
        </HashLink>
        <br />
        <HashLink to="#problemsInPaymentAgreementsProjectsDetails">
          2. Problems In Payment Agreements Projects Details - LIVE projects that we need to collect from without a
          valid payment agreement.
        </HashLink>
        <br />
        <HashLink to="#companiesWithDebtDetails">3. Companies with debt to Mayple</HashLink>
        <br />
        <HashLink to="#stuckInOnboardingProjectsDetails">
          4. Projects that are stuck in ONBOARDING - were modified more than 7 days ago.
        </HashLink>
        <br />
        <HashLink to="#overSpendingProjectsDetails">5. Projects that their actual spend is over the budget.</HashLink>
        <br />
        <br />
        Clicking a Project gets you to the relevant Project page in the CPanel, to quickly view it.
        <br />
      </p>

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <div className="p-8 m-b-16" id="problemsWithAdLedgerProjectsDetails">
              <ul>
                <li>
                  <h2>1. Problems reading ad spend - Next cycle begin at:</h2>
                </li>
                {problemsWithAdLedgerProjectsDetails.map((data) => {
                  const { projectId, nextCycleDate, project } = data;
                  return (
                    <li key={`problemsWithAdLedgerProjectsDetails-key-${projectId}`}>
                      <span>{formatDate(nextCycleDate)}: </span>
                      <Link to={`/projects/${projectId}`}>
                        {project.name} (ID: {projectId}) will end the cycle, but ad spend cannot be read
                      </Link>
                      <span>
                        {' Owner: '}
                        <Link to={`/companies/${project.company.id}`}>
                          {project.company.name} (ID: {project.company.id})
                        </Link>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <div className="p-8 m-b-16" id="problemsInPaymentAgreementsProjectsDetails">
              <ul>
                <li>
                  <h3>
                    2. Projects that we need to collect from, at the current or next project cycles, without a valid
                    payment agreement.
                  </h3>
                </li>
                {problemsInPaymentAgreementsProjectsDetails.map((data) => {
                  const { projectId, nextCycleDate, project } = data;
                  return (
                    <li key={`problemsInPaymentAgreementsProjectsDetailskey${projectId}`}>
                      <span>{formatDate(nextCycleDate)}: </span>
                      <Link to={`/projects/${projectId}`}>
                        {project.name} (ID: {projectId})
                      </Link>
                      <span>
                        {' Owner: '}
                        <Link to={`/companies/${project.company.id}`}>
                          {project.company.name} (ID: {project.company.id})
                        </Link>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <div className="p-8 m-b-16" id="companiesWithDebtDetails">
              <ul>
                <li>
                  <h3>
                    3. Companies with Debt to Mayple. The amount displayed is the total debt and not just the new cycle
                    debt.
                  </h3>
                </li>
                {companiesWithDebtDetails.map((data) => {
                  const { companyId, company, debtTotal, debtCurrency } = data;
                  return (
                    <li key={`companiesWithDebtDetails-key-${companyId}`}>
                      <Link to={`/companies/${companyId}`}>
                        {company.name} (ID: {companyId}) - With debt:
                      </Link>
                      <span>
                        {debtTotal} {debtCurrency}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <div className="p-8 m-b-16" id="stuckInOnboardingProjectsDetails">
              <ul>
                <li>
                  <h3>4. Stuck in on boarding projects - Funded at:</h3>
                </li>
                {stuckInOnboardingProjectsDetails.map((data) => {
                  const { projectId, lastModified, project } = data;
                  return (
                    <li key={`stuckInOnboardingProjectsDetails-key-${projectId}`}>
                      <span>{formatDate(lastModified)}: </span>
                      <Link to={`/projects/${projectId}`}>
                        {project.name} (ID: {projectId}) in ONBOARDING was last updated
                      </Link>
                      <span>
                        {' Owner: '}
                        <Link to={`/companies/${project.company.id}`}>
                          {project.company.name} (ID: {project.company.id})
                        </Link>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <div className="p-8 m-b-16" id="overSpendingProjectsDetails">
              <ul>
                <li>
                  <h3>
                    5. Projects that their actual ad-spend is over the budget, in total or for a specific channel:
                  </h3>
                </li>
                {overSpendingProjectsDetails.map((overSpendingObject) => {
                  const { project } = overSpendingObject;
                  const { id: projectId, name: projectName, currentCycle } = project;
                  const { actualMediaSpend, estimatedMediaBudget } = currentCycle;
                  return (
                    <li key={`overSpendingProjectsDetails-key-${project.id}`}>
                      <span>Project:</span>
                      <Link to={`/projects/${projectId}`}>
                        {projectName} (ID: {projectId}):
                      </Link>
                      <div>Estimated PPC Media budget: {estimatedMediaBudget}</div>
                      <div>Actual PPC Media spend: {actualMediaSpend}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

AdministrationStatusReportDisconnected.propTypes = {
  administrationStatusReportQuery: PropTypes.object.isRequired,
};

const enhance = compose(withQuery(AdministrationStatusReportQuery));
const AdministrationStatusReport = enhance(AdministrationStatusReportDisconnected);
export default AdministrationStatusReport;
