import { MouseEventHandler } from 'react';
import { DeliverableBlueprint, ProjectLifeCycleStatus, ProjectPackage, ProjectPackageState } from '@mayple/types';

import { PROJECT_PACKAGE_STATE_LABELS_LOOKUP } from '../../../app/enums';
import { MenuItemWithLabelProps } from '../../inputs/MultipleActionsButton/types';

export interface ProjectPackageCardProps {
  projectPackage: ProjectPackage;
  projectLifeCycleStatus: ProjectLifeCycleStatus | null | undefined;
  loading?: boolean;
  enableEdit?: boolean | undefined;
  showPrices?: boolean | undefined;
  showDescription?: boolean | undefined;
  onCardClick?: MouseEventHandler | undefined;
  onSuccessfulSave?: (() => Promise<any>) | undefined;
}

export const projectPackageStateLabelsLookup = PROJECT_PACKAGE_STATE_LABELS_LOOKUP as Record<
  ProjectPackageState,
  string
>;

export enum ProjectPackageAction {
  ProjectPackageActionDisable = 'project-package-action-disable',
}

export interface ProjectPackageCardData {
  actions: MenuItemWithLabelProps[];
  finalDeliverablesBlueprint: DeliverableBlueprint;
  isMarkAsCompletedDialogOpen: boolean;
  closeMarkAsCompletedDialog: () => void;
  isReportPackageDelayDialogOpen: boolean;
  closeReportPackageDelayDialog: () => void;
}
