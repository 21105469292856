import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';

import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { TextDisplay } from '../../../display';
import { colors } from '../../../../app/theme';
import { AnswerProps } from './types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    chip: {
      margin: 4,
      background: colors.white_darker,
    },
  })
);

const EnumAnswer: FC<AnswerProps> = (props) => {
  const { answer } = props;
  const classes = useStyles();

  const enums = answer.values;
  const desc = answer.comment;

  return (
    <div>
      {enums.map(({ value }) => (
        <Chip key={`renderEnumAnswer-${answer.questionUuid}-${value}`} label={value} classes={{ root: classes.chip }} />
      ))}

      {!!desc && (
        <TextDisplay variant="subtitle1" style={{ marginTop: 12 }}>
          {desc}
        </TextDisplay>
      )}
    </div>
  );
};

export default EnumAnswer;
