import React from 'react';
import PropTypes from 'prop-types';
import { Step, Steps } from 'react-albus';
import Grid from '@material-ui/core/Grid';

import { useFeedState } from '../FeedContext';
import { Button } from '../../inputs';
import FilesList from '../../molecules/FilesList';
import { WizardStepField } from '../../molecules/WizardStepField';

import { useWizardFormStyles } from './style';

function WizardSteps(props) {
  const { dispatch, change, submit, invalid, formValues, reset, onCancel, placeholder } = props;

  const classes = useWizardFormStyles();

  const { filestackApiKey, canPostAsPuppet, puppetUsers } = useFeedState();
  const { uploadFiles, showPuppetUserSelection } = formValues;

  const onCancelHandler = () => {
    reset();
    onCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fileOnDeleteHandler = (event, file, files) => {
    const { uploadId } = file;
    const newFiles = [...files].filter((f) => f.uploadId !== uploadId);

    dispatch(change('uploadFiles', newFiles));
  };

  return (
    <Steps>
      <Step
        id="addSuccessStories"
        render={() => (
          <>
            {canPostAsPuppet && (
              <>
                <WizardStepField
                  type={WizardStepField.types.CHECKBOX}
                  name="showPuppetUserSelection"
                  inputLabel="Post as Puppet User"
                  className={classes.puppetUserCheckbox}
                />
                {showPuppetUserSelection && (
                  <WizardStepField
                    type={WizardStepField.types.SELECT}
                    name="puppetPersonUuid"
                    enums={puppetUsers}
                    label="Select Puppet User*"
                    required={showPuppetUserSelection}
                    className={classes.puppetUserCheckbox}
                  />
                )}
              </>
            )}
            <WizardStepField
              type={WizardStepField.types.RICH_TEXT}
              name="commentContent"
              placeholder={placeholder}
              classes={{ richTextRoot: classes.postContent }}
              fileStackApiKey={filestackApiKey}
              enableImageDropAndPastePlugin
              required
            />
            <FilesList files={uploadFiles} showFileSize fileOnDelete={fileOnDeleteHandler} allowDownload={false} />
            <Grid container justifyContent="space-between" className={classes.actionsContainer}>
              <Grid item>
                <WizardStepField
                  type={WizardStepField.types.FILE_UPLOAD_2}
                  name="uploadFiles"
                  apikey={filestackApiKey}
                  maxSize={10 * 1024 * 1024}
                  hideMaxFileSize
                  buttonProps={{
                    classes: {
                      root: classes.fileUploadButton,
                      startIcon: classes.fileUploadButtonStartIcon,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Button label="Cancel" onClick={onCancelHandler} className={classes.cancelButton} />
                <Button label="Send" onClick={submit} disabled={invalid} color="secondary" variant="contained" />
              </Grid>
            </Grid>
          </>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  submit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
};

WizardSteps.defaultProps = {
  topics: [],
  formValues: {},
  initialValues: {},
};

export default WizardSteps;
