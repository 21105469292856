import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StepWizardChildProps } from 'react-step-wizard';

import { FormValues } from '../types';

import { TRANSLATION_NS } from '../../../../../../app/consts';
import { YES_OR_NO_PAIRS } from '../../../../../../app/enums';
import { WizardStepField } from '../../../../../molecules/WizardStepField';

import useStyles from './style';

interface Step1Props extends Partial<StepWizardChildProps> {
  formValues: FormValues;
}

const Step1: React.FC<Step1Props> = (props) => {
  const classes = useStyles();

  const { stepName, isActive } = props;

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'AssessmentDialog.MarketerMailchimpAuditAssessmentForm',
  });

  // const howCanWeImproveEnums = useHowCanWeImproveAnswers();
  // const { howCanWeImprove /* ,implementationAssistanceNeeded */ } = formValues;
  // const showHowCanWeImproveOther = howCanWeImprove?.[MailchimpAuditDriversOfProgramImprovement.OTHER];
  const showHowCanWeImproveOther = true;

  const showInterestedInFollowUp = false; // implementationAssistanceNeeded === 'false';

  if (!isActive) {
    return null;
  }

  return (
    <div id={stepName} className={classes.formWrapper}>
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="satisfactionScore"
        required
        wizardFieldLabel={t('questions.satisfactionScore')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={5}
        minimumValueCaption={t('notSatisfied')}
        maximumValueCaption={t('verySatisfied')}
      />
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="improvementPrediction"
        required
        wizardFieldLabel={t('questions.improvementPrediction')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={5}
        minimumValueCaption={t('noImprovement')}
        maximumValueCaption={t('lotsOfImprovement')}
      />
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="interactionScore"
        required
        wizardFieldLabel={t('questions.interactionScore')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={5}
        minimumValueCaption={t('notSatisfied', 'Not satisfied')}
        maximumValueCaption={t('verySatisfied', 'Very satisfied')}
        autoFocus
      />
      <WizardStepField
        type={WizardStepField.types.RICH_TEXT}
        name="interactionReason"
        required
        // @ts-ignore
        classes={{
          richTextRoot: classes.richTextRoot,
        }}
        modules={{
          toolbar: false,
        }}
        rows={3}
        maxLength={1000}
        wizardFieldLabel={t('questions.interactionReason')}
        placeholder={t('questions.interactionReason')}
      />
      <WizardStepField
        type={WizardStepField.types.RADIO}
        name="implementationAssistanceNeeded"
        wizardFieldLabel={t('questions.implementationAssistanceNeeded')}
        required
        // @ts-ignore
        enums={YES_OR_NO_PAIRS}
        color="primary"
      />
      <WizardStepField
        type={WizardStepField.types.RADIO}
        name="recommendToOtherBusinesses"
        wizardFieldLabel={t('questions.recommendToOtherBusinesses')}
        required
        // @ts-ignore
        enums={YES_OR_NO_PAIRS}
        color="primary"
      />
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="recommendMailchimpToFriendsScore"
        required
        wizardFieldLabel={t('questions.recommendMailchimpToFriendsScore')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={10}
        minimumValueCaption={t('notAtAllLikely')}
        maximumValueCaption={t('extremelyLikely')}
      />
      {/*
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="CSMHelpScore"
        required
        wizardFieldLabel={t('questions.CSMHelpScore')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={5}
        minimumValueCaption={t('notSatisfied')}
        maximumValueCaption={t('verySatisfied')}
      />
      */}
      <WizardStepField
        type={WizardStepField.types.SCORE_BUTTONS}
        name="navigationExperienceScore"
        required
        wizardFieldLabel={t('questions.navigationExperienceScore')}
        // @ts-ignore
        minimumValue={1}
        maximumValue={5}
        minimumValueCaption={t('notVeryEasy')}
        maximumValueCaption={t('easy')}
      />
      <div>
        {/*
        <WizardStepField
          type={WizardStepField.types.MULTI_CHECKBOX}
          name="howCanWeImprove"
          wizardFieldLabel={t('questions.howCanWeImprove')}
          required
          // @ts-ignore
          enums={howCanWeImproveEnums}
          color="primary"
          columns={1}
        />
        */}
        {showHowCanWeImproveOther && (
          <WizardStepField
            type={WizardStepField.types.RICH_TEXT}
            name="howCanWeImproveOther"
            wizardFieldLabel={t('questions.howCanWeImprove')}
            required
            // @ts-ignore
            rows={3}
            modules={{
              toolbar: false,
            }}
            classes={{
              richTextRoot: classes.richTextRoot,
            }}
            maxLength={1000}
            // placeholder={t('questions.howCanWeImproveOther')}
          />
        )}
      </div>
      <WizardStepField
        type={WizardStepField.types.RICH_TEXT}
        name="workingWithMaypleExperience"
        required
        // @ts-ignore
        classes={{
          richTextRoot: classes.richTextRoot,
        }}
        modules={{
          toolbar: false,
        }}
        rows={3}
        maxLength={1000}
        wizardFieldLabel={t('questions.workingWithMaypleExperience')}
        placeholder={t('questions.workingWithMaypleExperience')}
      />

      {showInterestedInFollowUp && (
        <WizardStepField
          type={WizardStepField.types.RADIO}
          name="interestedInFollowUp"
          // @ts-ignore
          wizardFieldLabel={
            <Trans t={t} i18nKey="questions.interestedInFollowUp">
              Would you be interested in following up with us further to complete a brief 60-minute guided interview?
              You will receive <strong>$100</strong> for your time.
            </Trans>
          }
          classesOverride={{ label: classes.preserveLineBreak }}
          required={showInterestedInFollowUp}
          // @ts-ignore
          enums={YES_OR_NO_PAIRS}
          color="primary"
        />
      )}
    </div>
  );
};

export default Step1;
