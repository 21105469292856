import React, { FC } from 'react';
import { MonthlyMediaSpend, TaskStatus } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { GetMarketerMonthlyMediaSpendQuery } from 'growl-graphql/dist/queries/tasks/MarketerMonthlyMediaSpend/GetMarketerMonthlyMediaSpendQuery';

import { getColumns, getTotalRowColumns, getTotalRowData } from './logic';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import DataTable from '../../../../../fe_common/client/components/molecules/DataTable';

import useStyles from './style';

interface MarketerMonthlyMediaSpendProps {
  cycleId: number;
  onCopySpend: (x: Record<string, any>[]) => void;
}

const MarketerMonthlyMediaSpend: FC<MarketerMonthlyMediaSpendProps> = (props) => {
  const { cycleId, onCopySpend } = props;

  const classes = useStyles();

  const columns = getColumns(classes);
  const totalRowColumns = getTotalRowColumns();

  const { data, loading } = useQuery(GetMarketerMonthlyMediaSpendQuery.query, {
    variables: { cycleId },
    notifyOnNetworkStatusChange: true,
  });

  const marketerMonthlyMediaSpend: MonthlyMediaSpend = data?.getMarketerMonthlyMediaSpend;
  const totalRowData = getTotalRowData(marketerMonthlyMediaSpend?.mediaSpendDivision);

  if (loading) {
    return <div>Loading Marketer Monthly Media Spend...</div>;
  }

  if ((!marketerMonthlyMediaSpend && !loading) || marketerMonthlyMediaSpend?.taskStatus === TaskStatus.PENDING) {
    return (
      <div>
        <CalloutMessage margin message="The expert did not submit his media spend for this cycle yet." type="warning" />
      </div>
    );
  }

  if (!loading && cycleId !== marketerMonthlyMediaSpend?.cycleId) {
    return (
      <CalloutMessage
        margin
        message="The expert submitted the spend but there is a mismatch in the data. Please contact QA team."
        type="error"
      />
    );
  }

  return (
    <div className={classes.root} style={{ marginTop: 24, marginBottom: 24 }}>
      <CalloutMessage
        margin
        message={`Expert submitted the following media spend for cycle ${cycleId}`}
        type="success"
      />

      <DataTable
        columns={columns}
        data={marketerMonthlyMediaSpend?.mediaSpendDivision}
        options={{
          filtering: false,
          search: false,
          sorting: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          tableLayout: 'fixed',
          emptyRowsWhenPaging: false,
          actionsColumnIndex: 4,
        }}
        actions={[
          {
            icon: FileCopyOutlinedIcon,
            tooltip: `Copy Ad Spend`,
            onClick: (_event, rowData) => {
              const { skillType, spendForSkillType } = rowData;
              onCopySpend([
                {
                  skillType,
                  spendForSkillType,
                },
              ]);
            },
          },
        ]}
      />

      <DataTable
        columns={totalRowColumns}
        data={totalRowData}
        options={{
          header: false,
          filtering: false,
          search: false,
          sorting: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          tableLayout: 'fixed',
          emptyRowsWhenPaging: false,
          actionsColumnIndex: 3,
        }}
        actions={[
          {
            icon: FileCopyIcon,
            tooltip: 'Copy All Ad Spend',
            onClick: () => {
              onCopySpend(marketerMonthlyMediaSpend?.mediaSpendDivision || []);
            },
          },
        ]}
      />
    </div>
  );
};

export default MarketerMonthlyMediaSpend;
