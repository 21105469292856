import React, { FC } from 'react';

import { WizardStepsProps } from './types';
import { WizardStepField } from '../WizardStepField';
import { RefreshSpinner } from '../../atoms';
import { Button } from '../../inputs';

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const { form, formFields, saving, onCancelClickHandler, invalid = true, submit, dispatch, change } = props;

  return (
    <div>
      {formFields.map((field) => {
        // for available type reference, look at WizardStepField.types
        const {
          type,
          name,
          required = true,
          fieldProps = {},
          shouldRenderFunc = () => true, // can be used for conditional rendering
        } = field;

        /**
         * shouldRenderFunc can be used to dynamically add fields or any custom components based on
         * user input.
         */
        if (shouldRenderFunc(props)) {
          return (
            <WizardStepField
              key={`editable-field-${name}-${type}`}
              type={type}
              name={name}
              required={required}
              // @ts-ignore
              form={form}
              dispatch={dispatch}
              change={change}
              {...fieldProps}
            />
          );
        }

        return null;
      })}
      <div style={{ textAlign: 'center' }}>
        <Button color="primary" disabled={invalid || saving} label="Save" onClick={submit} />
        {/* eslint-disable-next-line react/no-this-in-sfc */}
        <Button label="Cancel" disabled={saving} onClick={onCancelClickHandler} />
        {saving && (
          <div>
            Saving... <RefreshSpinner size={32} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WizardSteps;
