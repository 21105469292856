import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { ReportWireTransferProps } from './types';
import ReportWireTransferForm from './ReportWireTransferForm';
import useControlledAccordion from '../../../../hooks/useControlledAccordion';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

const ReportWireTransfer: React.FC<ReportWireTransferProps> = ({ company, onSuccess }) => {
  const { expandAccordion, accordionOnChangeHandler, onSuccessHandler } = useControlledAccordion(onSuccess);

  return (
    <Section
      title="Report Wire Transfer"
      subTitle={
        'Report a payment that company has made to Mayple, which was paid with a wire transfer ' +
        '(aka bank account transfer). ' +
        'This payment will then be useable as credit for new and existing projects.'
      }
      layout={SECTION_LAYOUT.TWO_COLUMNS}
    >
      <Accordion expanded={expandAccordion} onChange={accordionOnChangeHandler}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay>Click here to report wire transfer</TextDisplay>
        </AccordionSummary>
        <AccordionDetails>
          <ReportWireTransferForm company={company} onSuccess={onSuccessHandler} />
        </AccordionDetails>
      </Accordion>
    </Section>
  );
};

export default ReportWireTransfer;
