import React, { FC, useMemo } from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import { CurrencyType, Deliverable } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  DELIVERABLES_FULFILLMENT_BEHAVIOR,
  MARKETING_SERVICE_TYPES_WITH_ICONS,
} from '../../../../../fe_common/client/app/enums';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import RichTextEditor from '../../../../../fe_common/client/components/common/RichTextEditor';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { Select } from '../../../../../fe_common/client/components/atoms/Registration/Select';

// type Deliverable {
//   uuid: String!
//   serviceType: MarketingServiceType!
//   unitType: String!
//   unitDescription: MediaDocument!
//   unitCost: Float!
//   unitAmount: Int!
//   discountPercentage: Float!
//   fulfillmentBehavior: DeliverablesFulfillmentBehavior!
// }

interface DeliverablesEditorProps {
  meta: WrappedFieldMetaProps;
  deliverables: Deliverable[];
  onDeliverableServiceTypeChange: (newValue: string, uuid: string) => void;
  onDeliverableUnitTypeChange: (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => void;
  onDeliverableUnitDescriptionChange: (newValue: string, uuid: string) => void;
  onDeliverableUnitCostChange: (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => void;
  onDeliverableUnitAmountChange: (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => void;
  onDeliverableDiscountPercentageChange: (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => void;
  onDeliverableFulfillmentBehaviorChange: (newValue: string, uuid: string) => void;
  onDeleteDeliverable: (s: string) => void;
}

const DeliverablesEditor: FC<DeliverablesEditorProps> = ({
  meta,
  deliverables,
  onDeliverableServiceTypeChange,
  onDeliverableUnitTypeChange,
  onDeliverableUnitDescriptionChange,
  onDeliverableUnitCostChange,
  onDeliverableUnitAmountChange,
  onDeliverableDiscountPercentageChange,
  onDeliverableFulfillmentBehaviorChange,
  onDeleteDeliverable,
}) => {
  const deliverablesArr = useMemo(() => Object.values(deliverables) || [], [deliverables]);

  // console.log('DeliverablesEditor(deliverablesArr=', deliverablesArr, ')');

  return (
    <div>
      {deliverablesArr.length === 0 && <CalloutMessage message="No deliverables added yet" type="warning" margin />}

      {deliverablesArr.length > 0 && (
        <div>
          {deliverablesArr.map((deliverable, index) => {
            const {
              uuid,
              serviceType,
              unitType,
              unitDescription,
              unitCost,
              unitAmount,
              discountPercentage,
              fulfillmentBehavior,
            } = deliverable;
            const currency = CurrencyType.USD;

            // console.log('deliverable: ', deliverable);

            return (
              <div key={`DeliverablesEditor-${uuid}`}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {meta.error && meta.error[uuid] && <CalloutMessage message={meta.error[uuid]} type="error" />}
                    <TextDisplay variant="h5" bold>
                      {`Deliverable #${(index + 1).toString()}`}
                    </TextDisplay>
                  </Grid>
                  <Grid item>
                    <TextDisplay variant="h6">{`UUID: ${uuid}`}</TextDisplay>
                  </Grid>
                </Grid>

                <Grid item>
                  <Select
                    // name={`${uuid}|serviceType`}
                    enums={MARKETING_SERVICE_TYPES_WITH_ICONS}
                    onSelect={(val: string) => {
                      onDeliverableServiceTypeChange(val, uuid);
                    }}
                    value={serviceType}
                    label="Marketing Service Type"
                  />
                </Grid>

                <Grid item>
                  <TextField
                    name={`${uuid}|unitType`}
                    label="Unit Type"
                    value={unitType}
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                      onDeliverableUnitTypeChange(val, uuid);
                    }}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextDisplay gutterBottom bold>
                    Description
                  </TextDisplay>
                  <RichTextEditor
                    input={{
                      value: unitDescription.contents,
                      onChange: (val: string) => {
                        onDeliverableUnitDescriptionChange(val, uuid);
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    name={`${uuid}|unitCost`}
                    label="Unit Cost"
                    value={unitCost}
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                      onDeliverableUnitCostChange(val, uuid);
                    }}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                    }}
                    focused={unitCost === 0}
                    color={unitCost === 0 ? 'primary' : 'secondary'}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    name={`${uuid}|unitAmount`}
                    label="Unit Amount"
                    value={unitAmount}
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                      onDeliverableUnitAmountChange(val, uuid);
                    }}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    focused={unitAmount === 0}
                    color={unitAmount === 0 ? 'primary' : 'secondary'}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    name={`${uuid}|discountPercentage`}
                    label="Discount Percentage"
                    value={discountPercentage}
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                      onDeliverableDiscountPercentageChange(val, uuid);
                    }}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item>
                  <Select
                    // name={`${uuid}|fulfillmentBehavior`}
                    enums={DELIVERABLES_FULFILLMENT_BEHAVIOR}
                    onSelect={(val: string) => {
                      onDeliverableFulfillmentBehaviorChange(val, uuid);
                    }}
                    value={fulfillmentBehavior}
                    label="Fulfillment Behavior"
                  />
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      label="Remove deliverable"
                      onClick={() => {
                        onDeleteDeliverable(uuid);
                      }}
                      startIcon={<DeleteIcon />}
                      color="secondary"
                    />
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: 64, marginBottom: 64 }} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DeliverablesEditor;
