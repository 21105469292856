import React, { FC } from 'react';
import { ProjectCyclePauseDuration } from '@mayple/types';

import SimpleDataGrid from '../../../../../../fe_common/client/components/atoms/SimpleDataGrid';

const columns = [
  { field: 'startDate', headerName: 'Start date', width: 130 },
  { field: 'endDate', headerName: 'End date', width: 130 },
  { field: 'comment', headerName: 'Comment', width: 300 },
];

interface ProjectCyclePeriodViewerProps {
  name: string;
  durations: Array<ProjectCyclePauseDuration>;
}

const ProjectCyclePeriodViewer: FC<ProjectCyclePeriodViewerProps> = (props) => {
  const { name, durations = [] } = props;

  return <SimpleDataGrid name={name} columns={columns} rows={durations} noDataMessage="No pause durations." />;
};

export default ProjectCyclePeriodViewer;
