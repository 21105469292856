import React, { FC, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import { TextDisplay } from '../../../display';
import { colors } from '../../../../app/theme';
import { RefreshSpinner } from '../../../atoms';
import EditableComponent from '../../../molecules/EditableComponent';
import { WizardStepFieldTypes } from '../../../molecules/WizardStepField/types';
import { UseMutation } from '../../../../hooks/useMutation';
import { EditableComponentField } from '../../../molecules/EditableComponent/types';

type ProjectAdditionalServiceDescriptionEditorProps = {
  description: string;
  name: string;
  mutation: UseMutation;
  enableEdit?: boolean;
  loading?: boolean;
};

const ProjectAdditionalServiceDescriptionEditor: FC<ProjectAdditionalServiceDescriptionEditorProps> = (props) => {
  // console.log('ProjectAdditionalServiceDescriptionEditor props', props);
  const { name, description, mutation, enableEdit = false, loading = false } = props;

  const viewComponent = useMemo(
    () => (
      <>
        {loading && <RefreshSpinner />}
        {!loading && (
          <Grid container>
            <Grid item xs={12}>
              <TextDisplay colorOverride={colors.black_lighter} variant="caption">
                DESCRIPTION
              </TextDisplay>
            </Grid>
            <Grid item xs={12} style={{ minHeight: 36 }}>
              {description || 'No description has been provided yet.'}
            </Grid>
          </Grid>
        )}
      </>
    ),
    [description, loading],
  );

  const fields: EditableComponentField[] = [
    {
      name,
      type: WizardStepFieldTypes.TEXT,
      required: true,
      fieldProps: {
        isMultiLine: true,
      },
    },
  ];

  const initialValues: Record<string, any> = {
    name: description,
  };

  return (
    <EditableComponent
      fields={fields}
      view={viewComponent}
      enableEdit={enableEdit}
      mutation={mutation}
      initialValues={initialValues}
    />
  );
};

export default ProjectAdditionalServiceDescriptionEditor;
