import { useEffect, useRef } from 'react';
import * as CronofyElements from 'cronofy-elements';

import { CronofyAvailabilityRulesOptions } from '../../types/cronofyTypes';

const useCronofyAvailabilityRulesConfiguration = (options: CronofyAvailabilityRulesOptions): void => {
  const element = useRef(null);

  useEffect(() => {
    if (!element.current) {
      element.current = CronofyElements.AvailabilityRules(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (element && element.current) {
      // @ts-ignore
      element.current.update(options);
    }
  }, [options]);
};

export default useCronofyAvailabilityRulesConfiguration;
