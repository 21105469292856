import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    dialogTitleRoot: {},
    dialogContent: {
      padding: 32,
      paddingRight: 0,
      paddingLeft: 0,
      [theme.breakpoints.up('md')]: {
        padding: '0 32px',
        overflow: 'visible',
      },
    },
    dialogActions: {
      paddingBottom: '1rem',
    },
    content: {
      overflow: 'hidden',
    },
    withSlides: {},
    slide: {},
  })
);
export default useStyles;
