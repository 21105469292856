import get from 'lodash/get';

export const MARKETING_ACCOUNT_TYPE = {
  HISTORY: 'HISTORY',
  LIVE: 'LIVE',
};

const getMarketingHistoryAccounts = (project) => {
  const discoveryFbAccountId = get(
    project,
    'marketingHistoryAccounts.facebookMarketingAdAccounts[0].facebookAdAccountId',
  );
  const discoveryGaAccountId = get(
    project,
    'marketingHistoryAccounts.googleAdWordsClientAccounts[0].googleAdWordsClientAccountId',
  );

  return {
    FbAccountId: discoveryFbAccountId,
    GaAccountId: discoveryGaAccountId,
  };
};

const getMarketingLiveAccounts = (project) => {
  let FbAccountAccessSettings;
  let GaAccountAccessSettings;
  let liveFbAccountId;
  let liveGaAccountId;
  const channelAccounts = get(project, 'participants[0].channelAccounts') || [];

  for (const channel of channelAccounts) {
    if (channel.channelType === 'FACEBOOK_MARKETING') {
      FbAccountAccessSettings = channel.accessSettings;
      liveFbAccountId = get(channel, 'accessSettings.adAccountId', null);
    } else if (channel.channelType === 'GOOGLE_ADWORDS') {
      GaAccountAccessSettings = channel.accessSettings;
      liveGaAccountId = get(channel, 'accessSettings.adAccountId', null);
    }
  }

  return {
    FbAccountId: liveFbAccountId,
    FbAccountAccessSettings,
    GaAccountId: liveGaAccountId,
    GaAccountAccessSettings,
  };
};

const marketingAccountsMapper = {
  [MARKETING_ACCOUNT_TYPE.HISTORY]: {
    getMarketingAccounts: getMarketingHistoryAccounts,
    title: 'Marketing history accounts',
    noAccountsText: 'No marketing history accounts set.',
  },
  [MARKETING_ACCOUNT_TYPE.LIVE]: {
    getMarketingAccounts: getMarketingLiveAccounts,
    title: 'Live channel accounts',
    noAccountsText: 'No available live channel accounts.',
  },
};

export const getMarketingAccountsByType = (marketingAccountType, project) =>
  marketingAccountsMapper[marketingAccountType].getMarketingAccounts(project);

export const getTitleByType = (marketingAccountType) => marketingAccountsMapper[marketingAccountType].title;

export const getNoAccountsTextByType = (marketingAccountType) =>
  marketingAccountsMapper[marketingAccountType].noAccountsText;
