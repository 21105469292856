import React, { FC } from 'react';
import { Project } from '@mayple/types';

import ProjectMarketingAccountDetails from '../../../../components/cpanel/components/organisms/Project/ProjectMarketingAccountsDetails';
import ProjectSharedGoogleAnalyticsViews from '../../../../components/cpanel/components/organisms/Project/ProjectSharedGoogleAnalyticsViews';

import useStyles from './style';

export type ProjectAccountsTabProps = {
  project: Project;
  classes?: Record<string, string>;
};

const ProjectAccountsTab: FC<ProjectAccountsTabProps> = (props) => {
  const classes = useStyles(props);
  const { project } = props;

  return (
    <div className={classes.root}>
      <ProjectMarketingAccountDetails project={project} showLiveAccounts showHistoryAccounts noBorder noTopMargin />
      <ProjectSharedGoogleAnalyticsViews project={project} />
    </div>
  );
};

export default ProjectAccountsTab;
