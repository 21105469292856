import { CronofyAvailabilityProfiles } from '@mayple/types';

import { UseAvailabilityViewerFilterOutput } from '../types';
import useManagedAvailability from './useManagedAvailability';
import useTimezonePicker from './useTimezonePicker';
import useEntitySelectionFilter from './useEntitySelectionFilter';
import useAvailabilityProfiles from './useAvailabilityProfiles';
import useOnlyShowAvailabilityFilter from './useOnlyShowAvailabilityFilter';
import { useAvailabilityViewerState } from '../../AvailabilityViewerProvider/AvailabilityViewerContext';

const useAvailabilityViewerFilter = (): UseAvailabilityViewerFilterOutput => {
  const { selectionAccountType } = useAvailabilityViewerState();

  const [availabilityManagementValue, setAvailabilityManagementValue] = useManagedAvailability();

  const [availabilityProfilesValue, setAvailabilityProfilesValue] = useAvailabilityProfiles([
    CronofyAvailabilityProfiles.work_hours,
  ]);

  const [timezone, onTimezoneChangeHandler] = useTimezonePicker();

  const [entitySelectionFilterValue, onEntitySelectionFilterChange] = useEntitySelectionFilter();

  const [onlyShowAvailability, onOnlyShowAvailabilityChange] = useOnlyShowAvailabilityFilter();

  return {
    selectionAccountType,
    availabilityManagementValue,
    setAvailabilityManagementValue,
    availabilityProfilesValue,
    setAvailabilityProfilesValue,
    timezone,
    onTimezoneChangeHandler,
    entitySelectionFilterValue,
    onEntitySelectionFilterChange,
    onlyShowAvailability,
    onOnlyShowAvailabilityChange,
  };
};

export default useAvailabilityViewerFilter;
