import React, { useMemo, useState } from 'react';
import { MediaType } from '@mayple/types';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import InputBase from '@material-ui/core/InputBase';
import { CreateFeedPostCommentMutation } from 'growl-graphql/dist/mutations/CreateFeedPostCommentMutation';

import { useFeedState } from '../FeedContext';
import { COMMENT_EDITOR_MODES, getMutationOptions } from './logic';
import WizardSteps from './steps';
import FeedAuthorAvatar from '../FeedAuthorAvatar';
import { WizardForm } from '../../organisms/WizardForm';
import useMutation from '../../../hooks/useMutation';

import { getAuthorDetails, mapAttachedFiles } from '../logic';
import { clientLogger } from '../../../services/logger';

import { useStyles } from './style';

function FeedPostCommentEditor(props) {
  const classes = useStyles();
  const { postUuid, legend, authorUuid, placeholder } = props;

  const { projectId, marketerId, eventHandlers } = useFeedState();
  const { onCreatePostComment } = eventHandlers;
  const [editorMode, setEditorMode] = useState(COMMENT_EDITOR_MODES.IDLE);
  const wizardFormName = `insightsFeedComment-${postUuid}`;
  const { mutate: createFeedPostCommentMutation } = useMutation(CreateFeedPostCommentMutation, {
    mutationOptions: getMutationOptions(postUuid, projectId, marketerId),
  });

  const initialValues = {
    uploadFiles: [],
    puppetPersonUuid: null,
  };

  const author = useMemo(() => getAuthorDetails(authorUuid, legend), [authorUuid, legend]);

  const onInputClickHandler = () => {
    setEditorMode(COMMENT_EDITOR_MODES.IDLE_CLICKED);
    setTimeout(() => {
      setEditorMode(COMMENT_EDITOR_MODES.ACTIVE);
    }, 100);
  };

  const handleOnCancel = () => {
    setEditorMode(COMMENT_EDITOR_MODES.IDLE);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnSubmit = async (formValues, dispatch, formProps) => {
    // console.log('formValues', formValues);
    // console.log('formProps', formProps);

    const { commentContent, uploadFiles, puppetPersonUuid, showPuppetUserSelection } = formValues;

    const variables = {
      postUuid,
      feedCommentCreate: {
        content: {
          mediaType: MediaType.TEXT_HTML,
          contents: commentContent,
        },
        attachedFiles: mapAttachedFiles(uploadFiles) || [],
        puppetPersonUuid: showPuppetUserSelection === true ? puppetPersonUuid : null,
      },
    };

    try {
      const result = await createFeedPostCommentMutation({ variables });

      if (result?.data?.createFeedPostComment) {
        // call parent scope onCreatePostComment
        onCreatePostComment();

        // close the editor and return to idle
        setEditorMode(COMMENT_EDITOR_MODES.IDLE);

        // clear redux form values
        formProps.reset();
      }
    } catch (e) {
      clientLogger(e);
    }
  };

  const editorModeIsActive = editorMode === COMMENT_EDITOR_MODES.ACTIVE;

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classNames(classes.postCommentEditorContainer, {
          [classes.postCommentEditorContainerActive]: editorModeIsActive,
          [classes.postCommentEditorContainerIdle]: !editorModeIsActive,
        })}
      >
        <Grid
          item
          className={classNames(classes.avatarContainer, {
            [classes.avatarContainerOnActiveMode]: editorModeIsActive,
          })}
        >
          <FeedAuthorAvatar size="small" className={classes.avatar} author={author} />
        </Grid>
        <Grid item className={classes.formContainer}>
          <Collapse in={!editorModeIsActive}>
            <InputBase
              placeholder={placeholder}
              className={classNames(classes.input, {
                [classes.inputIdle]: editorMode === COMMENT_EDITOR_MODES.IDLE,
                [classes.inputIdleClicked]: editorMode === COMMENT_EDITOR_MODES.IDLE_CLICKED,
              })}
              fullWidth
              readOnly
              onClick={onInputClickHandler}
            />
          </Collapse>
          <Collapse in={editorMode === COMMENT_EDITOR_MODES.ACTIVE}>
            <div>
              <WizardForm
                form={wizardFormName}
                steps={WizardSteps}
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                onCancel={handleOnCancel}
                destroyOnUnmount
                placeholder={placeholder}
                watchAllValues
              />
            </div>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
}

FeedPostCommentEditor.propTypes = {
  legend: PropTypes.object.isRequired,
  authorUuid: PropTypes.string.isRequired,
  postUuid: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default FeedPostCommentEditor;
