import { useQuery } from '@apollo/react-hooks';
import { User } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import { FullUserByIdQuery } from 'growl-graphql/dist/queries/FullUserById';

export type UserData = {
  user: User | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: any;
};

const useUserData = (userId: number | null | undefined): UserData => {
  const { data, loading, error, refetch } = useQuery(FullUserByIdQuery.query, {
    variables: {
      userId,
    },
    skip: !userId,
  });

  return {
    user: data?.users?.[0],
    loading,
    error,
    refetch,
  };
};

export default useUserData;
