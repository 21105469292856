import pickBy from 'lodash/pickBy';

import { valuesOnly } from '../../../../../fe_common/client/services/utils';

import { InternalTeamEditorMode, InternalTeamEditorModes, InternalTeamInputs } from './types';

const getMutationVariables = (mode: InternalTeamEditorMode, formValues: Record<string, any>): InternalTeamInputs => {
  const variableName = mode === InternalTeamEditorModes.Edit ? 'internalTeamUpdate' : 'internalTeamCreate';
  const { internalTeamId, workingGroupOnProjectTypes, ...rest } = formValues;
  const variables = {
    [variableName]: {
      ...rest,
    },
  };

  if (mode === InternalTeamEditorModes.Edit) {
    variables.internalTeamId = internalTeamId;
    variables[variableName].projectAutomaticAssignmentSettings = {
      workingGroupOnProjectTypes: workingGroupOnProjectTypes?.length ? valuesOnly(workingGroupOnProjectTypes) : [],
    };
  }

  return pickBy(variables, (value: any) => value != null);
};

export default getMutationVariables;
