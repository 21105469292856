import React from 'react';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { ProjectPackageOverrideDialogProps } from './types';
import { formName } from '../ProjectPackageBillablesOverridesForm/logic';
import ProjectPackageBillablesOverridesForm from '../ProjectPackageBillablesOverridesForm';
import { Dialog } from '../../display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

const ProjectPackageOverrideDialog: React.FC<ProjectPackageOverrideDialogProps> = (props) => {
  const classes = useStyles(props);
  const { projectPackage, onSuccessfulSave, onClose, ...dialogProps } = props;

  const dispatch = useDispatch();

  // console.log('projectPackage', projectPackage);

  const packageName = projectPackage?.package?.presentation?.name;
  const dialogTitle = packageName ? `Override ${packageName} values` : 'Override Package values';

  const submitForm = () => dispatch(submit(formName));
  const onSuccessfulSaveHandler = () => {
    onSuccessfulSave?.();
    onClose?.();
  };

  return (
    <Dialog
      onClose={onClose}
      {...dialogProps}
      classes={classes}
      title={dialogTitle}
      fullWidth
      containerMaxWidth="sm"
      actions={[
        {
          label: 'save',
          color: 'primary',
          variant: 'contained',
          onClick: submitForm,
        },
        {
          label: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: onClose,
        },
      ]}
    >
      <ProjectPackageBillablesOverridesForm
        projectPackage={projectPackage}
        onSuccessfulSave={onSuccessfulSaveHandler}
      />
    </Dialog>
  );
};

export default ProjectPackageOverrideDialog;
