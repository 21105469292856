import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import ProjectCycleDetailsHeader from './ProjectCycleDetailsHeader';
import CycleDataTable from './CycleDataTable';
import ProjectCycleFunding from './ProjectCycleFunding';
import {
  getCutsSplit,
  getDeliverablesDetails,
  getNonPPCServicesCost,
  getPpcServicesFee,
  getSetupFee,
} from '../ProjectCycleDetailsNew/logic';
import PauseDurations from '../../../../../../pages/ProjectDetailsPage/components/PauseDurations';
import CyclePauseDurationsType from '../../../../../../pages/ProjectDetailsPage/components/PauseDurations/consts';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { toIsoString } from '../../../../../../../fe_common/client/services/utils';

import { styles } from './style';
import FundAccountDetails from '../../../molecules/FundAccountDetails';

const ProjectCycleDetails = ({ project, cycle, classes }) => {
  const { startDate, endDate } = cycle;
  const { currency, id: projectId } = project;
  const serviceSetup = getSetupFee(cycle, null, classes);
  const estimatedCostPPCServices = getPpcServicesFee(cycle, null, currency, classes, false);
  const actualCostPPCServices = getPpcServicesFee(cycle, null, currency, classes, true, true);
  const estimatedDeliverablesDetails = getDeliverablesDetails(cycle, null, currency, classes, false, false);
  const actualDeliverablesDetails = getDeliverablesDetails(cycle, null, currency, classes, true, true);
  const nonPPCServices = getNonPPCServicesCost(cycle, null, currency, classes);
  const cutsSplit = getCutsSplit(cycle, null, currency, classes, true);

  return (
    <div className={classes.root}>
      <ProjectCycleDetailsHeader project={project} cycle={cycle} />
      <Section title="Funding">
        <SectionContent>
          <ProjectCycleFunding cycle={cycle} currency={currency} />
        </SectionContent>
      </Section>

      <Section title="One-Time/Setup Simple Service">
        <SectionContent>
          <CycleDataTable cycleData={serviceSetup} />
        </SectionContent>
      </Section>

      <Section title="PPC Services">
        <SectionContent>
          <CycleDataTable cycleData={estimatedCostPPCServices} />
          <CycleDataTable cycleData={actualCostPPCServices} />
        </SectionContent>
      </Section>

      <Section title="Deliverables">
        <SectionContent>
          <CycleDataTable cycleData={estimatedDeliverablesDetails} />
          <CycleDataTable cycleData={actualDeliverablesDetails} />
        </SectionContent>
      </Section>

      <Section title="Non-PPC Services">
        <SectionContent>
          <CycleDataTable cycleData={nonPPCServices} />
        </SectionContent>
      </Section>

      <Section
        title="Cycle dates"
        subTitle={`Start date: ${toIsoString(startDate, true)} - End date: ${toIsoString(endDate, true)}`}
      >
        <SectionContent>
          <PauseDurations
            name="ppcDurations"
            title="PPC pause durations"
            durations={cycle.ppcPauseDurations}
            durationType={CyclePauseDurationsType.PPC_DURATIONS}
            isServicePaused={cycle.isPPCPaused}
            projectId={projectId}
          />
          <PauseDurations
            name="nonPpcDurations"
            title="Non-PPC pause durations"
            durations={cycle.nonPPCPauseDurations}
            durationType={CyclePauseDurationsType.NON_PPC_DURATIONS}
            isServicePaused={cycle.isNonPPCPaused}
            projectId={projectId}
          />
        </SectionContent>
      </Section>

      <Section title="Cuts">
        <SectionContent>
          <CycleDataTable cycleData={cutsSplit} />
        </SectionContent>
      </Section>

      <Section title="Fund Accounts">
        <SectionContent>
          <FundAccountDetails fundAccount={cycle.fundAccount} title="Cycle Fund Account" showDebt={false} />
        </SectionContent>
      </Section>
    </div>
  );
};

ProjectCycleDetails.propTypes = {
  project: PropTypes.object.isRequired,
  cycle: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

ProjectCycleDetails.defaultProps = {};

export default withStyles(styles)(ProjectCycleDetails);
