import React from 'react';
import { Project } from '@mayple/types';

import LabelValue from '../../../../../../fe_common/client/components/display/LabelValue';

interface ProjectCreationReasonProps {
  project: Project | null | undefined;
}

const ProjectCreationReason: React.FC<ProjectCreationReasonProps> = (props) => {
  const { project } = props;
  const creationReason = project?.creationReason;

  return <LabelValue label="Project Creation Reason" value={creationReason ?? 'N/A'} />;
};

export default ProjectCreationReason;
