import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import columns from './logic';
import InternalTeamsTable from './components/InternalTeamsTable';

import useAdminAccessibleInternalTeams from '../../../fe_common/client/hooks/internalTeam/useAdminAccessibleInternalTeams';

const useStyles = makeStyles(() => ({
  root: {},
}));

const InternalTeamsPage: React.FC = () => {
  const classes = useStyles();

  const { internalTeams, loading, refetch } = useAdminAccessibleInternalTeams({
    withWorkingGroups: true,
    queryOptions: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  });

  return (
    <div className={classes.root}>
      <InternalTeamsTable loading={loading} data={internalTeams} columns={columns} refetch={refetch} />
    </div>
  );
};

export default InternalTeamsPage;
