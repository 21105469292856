import { useCallback, useEffect, useMemo, useState } from 'react';
import { Color } from '@material-ui/lab/Alert';

import { MarketerSelectionConfigurationData } from './types';
import checkIfConfigurationEditableInCurrentStage from './logic';

import { tryParseInt } from '../../../../../fe_common/client/services/utils';
import useProjectFlowConfiguration, {
  FlowConfigurationInputKey,
} from '../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';

const useMarketerSelectionConfiguration = (projectId: number): MarketerSelectionConfigurationData => {
  const { flowConfiguration, stage, saveProjectFlowConfiguration, loading, saving } =
    useProjectFlowConfiguration(projectId);

  const [checked, setChecked] = useState(!!flowConfiguration?.numberOfOfferedMarketersForMarketerApproval?.value);
  const [numOfExperts, setNumOfExperts] = useState<number | string>(
    flowConfiguration?.numberOfOfferedMarketersForMarketerApproval?.value || '',
  );

  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<Color>('info');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const clearMessage = () => {
    setMessage('');
  };

  const numberOfOfferedMarketersForMarketerApproval = useMemo(
    () => flowConfiguration?.numberOfOfferedMarketersForMarketerApproval?.value,
    [flowConfiguration?.numberOfOfferedMarketersForMarketerApproval?.value],
  );

  const editable = useMemo(
    () =>
      checkIfConfigurationEditableInCurrentStage(flowConfiguration?.numberOfOfferedMarketersForMarketerApproval, stage),
    [flowConfiguration?.numberOfOfferedMarketersForMarketerApproval, stage],
  );

  useEffect(() => {
    if (loading || typeof numberOfOfferedMarketersForMarketerApproval === 'undefined') {
      return;
    }

    setChecked(!!numberOfOfferedMarketersForMarketerApproval);
    setNumOfExperts(numberOfOfferedMarketersForMarketerApproval);
  }, [loading, numberOfOfferedMarketersForMarketerApproval]);

  const numOfExpertsChangeHandler = (newNumOfExperts: number | string) => {
    setErrorMessage(null);
    setNumOfExperts(newNumOfExperts);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const validateConfiguration = useCallback(() => {
    if (!checked) {
      setNumOfExperts(0);
      return true;
    }

    if (
      checked &&
      (numOfExperts === '' || parseInt(<string>numOfExperts, 10) < 0 || parseInt(<string>numOfExperts, 10) > 9)
    ) {
      setErrorMessage('Number of experts should be a value between [0 - 9]');
      return false;
    }

    return true;
  }, [checked, numOfExperts]);

  const onSaveHandler = useCallback(async (): Promise<void> => {
    if (!validateConfiguration()) {
      return;
    }

    try {
      await saveProjectFlowConfiguration(
        'numberOfOfferedMarketersForMarketerApproval' as FlowConfigurationInputKey,
        checked ? tryParseInt(numOfExperts, 0) : 0,
      );
      setMessage('Configuration saved.');
      setMessageType('success');
    } catch (err) {
      setMessage('Error while trying to save Configuration.');
      setMessageType('error');
    }
  }, [checked, numOfExperts, saveProjectFlowConfiguration, validateConfiguration]);

  return {
    editable,
    checked,
    toggleChecked,
    numOfExperts,
    numOfExpertsChangeHandler,
    errorMessage,
    saving,
    onSaveHandler,
    message,
    messageType,
    clearMessage,
  };
};
export default useMarketerSelectionConfiguration;
