import React from 'react';
import { CurrencyType } from '@mayple/types';

import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { WizardStepsProps } from './types';
import LabelValue from '../../display/LabelValue';
import { WizardStepField } from '../../molecules/WizardStepField';
// import { DELIVERABLES_FULFILLMENT_BEHAVIOR } from '../../../app/enums';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
  })
);

const WizardSteps: React.FC<WizardStepsProps> = (props) => {
  const classes = useStyles();

  const { serviceType } = props?.formValues || {};

  // unitCost,
  // unitAmount,
  // discountPercentage,
  // serviceType,
  // unitType,
  // unitDescription,
  // fulfillmentBehavior,

  const currency = CurrencyType.USD;

  return (
    <div className={classes.root}>
      <LabelValue label="Service Type" value={serviceType} />

      <WizardStepField
        type={WizardStepField.types.CURRENCY}
        name="unitCost"
        // @ts-ignore
        label="Unit Cost"
        helperText="Enter a whole number in the account currency"
        minimumValue={0}
        maximumValue={100000}
        hideCurrencySelect={!!currency}
        currency={currency}
      />

      <WizardStepField
        type={WizardStepField.types.NUMBER}
        name="unitAmount"
        // @ts-ignore
        label="Unit Amount"
        helperText="Enter a whole number"
        minimumValue={0}
        maximumValue={100000}
      />

      <WizardStepField
        type={WizardStepField.types.NUMBER}
        name="discountPercentage"
        // @ts-ignore
        label="Discount Percentage"
        helperText="value between 0-100"
        minimumValue={0}
        maximumValue={100}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      {/* Hiding this at the moment as it has no effect */}
      {/*
      <WizardStepField
        type={WizardStepField.types.SELECT}
        name="fulfillmentBehavior"
        // @ts-ignore
        label="Fulfillment Behavior"
        enums={DELIVERABLES_FULFILLMENT_BEHAVIOR}
      />
      */}
    </div>
  );
};

export default WizardSteps;
