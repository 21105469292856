import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    tabsRoot: {
      marginBottom: '1rem',
    },
    spinnerWrapper: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  })
);

export default useStyles;
