import React, { FC } from 'react';
import { Answer } from '@mayple/types';

import { AnswerProps } from './types';
import { TextDisplay } from '../../../display';

const UrlAnswer: FC<AnswerProps> = (props) => {
  const { answer = {} as Answer } = props;
  const { values = [], questionUuid } = answer;

  return (
    <>
      {values.map((value) => {
        const { value: url } = value;
        const key = url.replace(/\W/gi, '');

        return (
          <TextDisplay key={`url-${questionUuid}-${key}`} variant="subtitle1">
            <a target="_blank" rel="noopener noreferrer" href={url}>
              {url}
            </a>
          </TextDisplay>
        );
      })}
    </>
  );
};

export default UrlAnswer;
