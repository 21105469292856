import { Project, ProjectBillingPointBehavior } from '@mayple/types';

const getMerchantInitiatedIrregularChargeReasoningFromUser = (
  project: Project | null | undefined,
): string | null | undefined => {
  let merchantInitiatedIrregularChargeReasoning;

  if (
    project?.approveMarketerBillingPointBehavior !== ProjectBillingPointBehavior.DO_NOTHING ||
    project?.startProjectBillingPointBehavior !== ProjectBillingPointBehavior.DO_NOTHING
  ) {
    merchantInitiatedIrregularChargeReasoning = prompt(
      'This will trigger a payment to the project. Please provide a reason for doing this action on behalf of the client:',
    );
  }

  return merchantInitiatedIrregularChargeReasoning;
};

export default getMerchantInitiatedIrregularChargeReasoningFromUser;
