import React, { FC, useMemo } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

import { muiIcons } from '../../../../../fe_common/client/app/icons';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { ConfirmDialog } from '../../../../../fe_common/client/components/dialogs/ConfirmDialog';
import { safeExtractCurrencyValue } from '../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { WizardStepsProps } from './types';

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const {
    invalid,
    submit,
    submitting,
    userInput,
    currency,
    company,
    onShowConfirmation,
    showConfirmationDialog,
    closeConfirmationDialog,
  } = props;

  const { wireAmountPaid, paymentDate } = userInput;

  const confirmMessage = useMemo(() => {
    const wireAmountPaidFormattedValue = safeExtractCurrencyValue(wireAmountPaid?.value, wireAmountPaid?.currency);

    return (
      <TextDisplay>
        You are about to report a wire transfer of {wireAmountPaidFormattedValue}
        <br />
        made by company {company.name} with ID: {company.id}.<br />
        on {moment(paymentDate).format('LLL')} <br />
        This is REAL money that can later be used in campaigns.
        <br />
        Are you sure you want continue?
      </TextDisplay>
    );
  }, [company, paymentDate, wireAmountPaid]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WizardStepField
            type={WizardStepField.types.CURRENCY}
            name="wireAmountPaid"
            wizardFieldLabel="Amount that was wired "
            required
            // @ts-ignore
            helperText={`Enter a whole number in the account currency. (${currency})`}
            minimumValue={0}
            maximumValue={100000}
            hideCurrencySelect={!!currency}
            currency={currency}
          />
        </Grid>

        <Grid item xs={12}>
          <WizardStepField
            type={WizardStepField.types.CURRENCY}
            name="verificationAmount"
            wizardFieldLabel="Enter wired amount again to verify"
            required
            // @ts-ignore
            minimumValue={0}
            maximumValue={100000}
            hideCurrencySelect={!!currency}
            currency={currency}
          />
        </Grid>

        <Grid item xs={12}>
          <fieldset style={{ borderRadius: 8 }}>
            <legend>Fill at least one of the options below</legend>
            <div>
              <WizardStepField
                type={WizardStepField.types.TEXT}
                name="paymentSourceBankAccountNumber"
                wizardFieldLabel="Payer Bank Account number"
                // @ts-ignore
                helperText="Example: DE89-3704-0044-0532-0130-00 for IBAN, Leumi 999-3124512 for Israel account number"
              />
            </div>
            <div>
              <WizardStepField
                type={WizardStepField.types.TEXT}
                name="paymentConfirmationNumber"
                wizardFieldLabel="Payment confirmation number"
                // @ts-ignore
                helperText="This might be a SWIFT ack# or a bank confirmation#"
              />
            </div>
            <div>
              <WizardStepField
                type={WizardStepField.types.TEXT}
                name="paymentInvoiceID"
                wizardFieldLabel="Payment Invoice ID"
                // @ts-ignore
                helperText="The ID of the invoice related to this wire transfer, if available."
              />
            </div>
          </fieldset>
        </Grid>

        <Grid item xs={12}>
          <WizardStepField
            type={WizardStepField.types.DATE_PICKER}
            name="paymentDate"
            wizardFieldLabel="When was the payment received in our bank account?"
            // @ts-ignore
            helperText="Include time if possible. Example: Jan 25th, 2018 at 13:00, 2017-12-11 09:30am, etc"
            views={['date', 'month', 'year']}
            openTo="date"
            dateFormat="LLL"
            required
          />
        </Grid>

        <Grid item xs={12}>
          <WizardStepField
            type={WizardStepField.types.TEXT}
            name="description"
            wizardFieldLabel="Please enter description for the wire transfer reason"
            required
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            label="Report Wire Transfer"
            type="submit"
            startIcon={muiIcons.bank}
            color="secondary"
            variant="contained"
            onClick={onShowConfirmation}
            disabled={invalid || submitting}
            style={{ float: 'right' }}
          />
        </Grid>
      </Grid>
      {showConfirmationDialog && (
        <ConfirmDialog
          open={showConfirmationDialog}
          onCancel={closeConfirmationDialog}
          onConfirm={submit}
          content={confirmMessage}
          title="Report Wire Transfer"
        />
      )}
    </>
  );
};

export default WizardSteps;
