import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { getGradientColor } from '../../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: getGradientColor('gray', 10),
      position: 'sticky',
      top: 0,
    },
  })
);

export default useStyles;
