import React, { useReducer, useContext } from 'react';
import { AccountType } from '@mayple/types';

import { AvailabilityViewerDispatch, availabilityViewerReducer } from './availabilityViewerReducer';
import { AvailabilityViewerFilterData, AvailabilityViewerOptions } from '../types';
import { Account } from '../../types';

export interface IAvailabilityViewerContext {
  mainAccount: Account;
  selectionAccountType: AccountType;
  filterData?: AvailabilityViewerFilterData;
  initOptions?: AvailabilityViewerOptions;
}

const AvailabilityViewerContext = React.createContext<IAvailabilityViewerContext | undefined>(undefined);
const AvailabilityViewerDispatchContext = React.createContext<AvailabilityViewerDispatch | undefined>(undefined);

type AvailabilityViewerContextProviderProps = { value: IAvailabilityViewerContext; children: React.ReactNode };

const AvailabilityViewerContextProvider: React.FC<AvailabilityViewerContextProviderProps> = ({ value, children }) => {
  const [state, dispatch] = useReducer(availabilityViewerReducer, value);

  return (
    <AvailabilityViewerContext.Provider value={state}>
      <AvailabilityViewerDispatchContext.Provider value={dispatch}>
        {children}
      </AvailabilityViewerDispatchContext.Provider>
    </AvailabilityViewerContext.Provider>
  );
};

function useAvailabilityViewerState(): IAvailabilityViewerContext {
  const context = useContext(AvailabilityViewerContext);
  if (context === undefined) {
    throw new Error('useAvailabilityViewerState must be used within a AvailabilityViewerProvider');
  }
  return context;
}

function useAvailabilityViewerDispatch(): AvailabilityViewerDispatch {
  const context = useContext(AvailabilityViewerDispatchContext);
  if (context === undefined) {
    throw new Error('useAvailabilityViewerDispatch must be used within a AvailabilityViewerProvider');
  }
  return context;
}

export { useAvailabilityViewerState, useAvailabilityViewerDispatch };
export default AvailabilityViewerContextProvider;
