import React, { FC } from 'react';
import { FilterRender } from 'react-table-6';
import TextField from '@material-ui/core/TextField';
import { FilterBy, FilterByOperator } from '@mayple/types';

import { useAsyncDebounce } from '../../../hooks/useDebounce';
import { LabelValueProps } from '../../../app/types';

interface DebouncedInputProps {
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => (value: string) => void;
  placeholder?: string;
}

const DebouncedInput: FC<DebouncedInputProps> = ({ value, onChange, ...inputProps }) => {
  const onChangeDebounce = useAsyncDebounce(onChange, 800);
  return (
    <TextField
      fullWidth
      defaultValue={value}
      variant="outlined"
      onChange={(e) => onChangeDebounce(e.target.value)}
      {...inputProps}
    />
  );
  // return <input defaultValue={value} onChange={(e) => onChangeDebounce(e.target.value)} />;
};

type TextFilter = (filterBy: FilterBy, inputProps?: any) => FilterRender;

export const TextFilter: TextFilter = ({ key, operator }, inputProps?: any) =>
  function TextFilterWithDebouncedInput({ filter, onChange }) {
    const { value } = filter?.value || {};
    return (
      <DebouncedInput
        value={value}
        // @ts-ignore
        onChange={(newValue) => onChange(newValue ? { key, value: newValue, operator } : '')}
        {...inputProps}
      />
    );
  };

export const EqualsFilter: TextFilter = ({ key }: FilterBy = {}, inputProps: Record<string, any> = {}) =>
  TextFilter({ key, operator: FilterByOperator.EQUALS }, inputProps);

export const ContainsFilter: TextFilter = ({ key }: FilterBy = {}, inputProps: Record<string, any> = {}) =>
  TextFilter({ key, operator: FilterByOperator.CONTAINS }, inputProps);

export const EnumFilter = ({ items, key }: { items: LabelValueProps[]; key: string }): FilterRender =>
  function EnumFilterWithSelct({ filter, onChange }) {
    const { value, operator } = filter?.value || {};

    return (
      <select
        defaultValue={operator === FilterByOperator.NOT_IN ? 'unknown' : value}
        onChange={(e) => {
          const eventValue = e.target.value;

          const enumValues = items.map((item) => item.value);

          if (eventValue === 'unknown') {
            onChange({ key, value: enumValues.join(','), operator: FilterByOperator.NOT_IN });
          } else if (enumValues.includes(eventValue)) {
            onChange({ key, value: eventValue, operator: FilterByOperator.EQUALS });
          } else {
            onChange({ key: null });
          }
        }}
      >
        <option value="">Show All</option>
        {items.map(({ label, value: itemValue }: LabelValueProps) => (
          <option key={itemValue} value={itemValue}>
            {label}
          </option>
        ))}
        <option value="unknown">Unknown</option>
      </select>
    );
  };

export const BoolFilter = ({ key }: { key: string }): FilterRender =>
  EnumFilter({
    key,
    items: [
      { value: '0', label: 'No' },
      { value: '1', label: 'Yes' },
    ],
  });
