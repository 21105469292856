import { Company, InternalTeam, Marketer, MarketerMatch, User } from '@mayple/types';

import { Mutation } from '../../fe_common/client/hooks/useMutation';

export type EntityTypeMap = {
  entitiesKey: string;
  entityIdKey: string;
  addUserToEntityMutation: Mutation;
  removeUserFromEntityMutation: Mutation;
};

export const ROUTES_REDIRECT = {
  CRONOFY_AUTH: '/auth/cronofy-result',
};

export enum ENTITY_TYPE {
  COMPANY = 'Company',
  MARKETER = 'Marketer',
  USER = 'User',
  INTERNAL_TEAM = 'Internal Account',
}

export type ApplicationEntity = Company | Marketer | InternalTeam | User | MarketerMatch;

export const ENTITY_TYPES = Object.values(ENTITY_TYPE);
