import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import AssessmentCard from '../AssessmentCard';

import useProjectAssessmentResponse from '../../../../../fe_common/client/hooks/assessment/useProjectAssessmentResponse';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

type ProjectAssessmentDetailsProps = {
  projectId: number | null | undefined;
};

const ProjectAssessmentDetails: React.FC<ProjectAssessmentDetailsProps> = (props) => {
  const { projectId } = props;
  const classes = useStyles(props);

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'ProjectDetailsPage.MarketerAssessmentDetails' });

  const { projectAssessments /* , loading, error */ } = useProjectAssessmentResponse({
    projectId,
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <TextDisplay variant="h5" gutterBottom>
          {t('projectAssessmentsTitle', 'Project Assessments')}
        </TextDisplay>
        {projectAssessments?.map((assessmentResponse) => (
          <AssessmentCard key={`AssessmentCard-${assessmentResponse?.uuid}`} assessmentResponse={assessmentResponse} />
        ))}
      </Container>
    </div>
  );
};

export default ProjectAssessmentDetails;
