import React from 'react';
import { Project } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import ProjectConnectedInternalTeams from '../../components/ProjectConnectedInternalTeams';
import ProjectSupervisor from '../../components/ProjectSupervisor';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface ProjectInternalTeamsTabProps {
  project: Project;
  onUpdate: () => Promise<void>;
}

const ProjectInternalTeamsTab: React.FC<ProjectInternalTeamsTabProps> = (props) => {
  const classes = useStyles();
  const { project, onUpdate } = props;

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <ProjectSupervisor projectId={project.id} />
        <ProjectConnectedInternalTeams title="InternalTeams" project={project} onUpdate={onUpdate} />
      </Container>
    </div>
  );
};

export default ProjectInternalTeamsTab;
