import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import CompanyOpenSlots from './components/CompanyOpenSlots';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

type MeetingsTabProps = {
  companyId: number | null | undefined;
  projectId?: number | null | undefined;
};

const MeetingsTab: React.FC<MeetingsTabProps> = (props) => {
  const classes = useStyles();
  const { companyId } = props;

  return (
    <div className={classes.root}>
      <CompanyOpenSlots companyId={companyId} />
    </div>
  );
};

export default MeetingsTab;
