import React from 'react';
import get from 'lodash/get';
import { ApplicationEntity, ENTITY_TYPE } from '../../../../../app/consts';
import {
  companyLinkFormatter,
  internalTeamLinkFormatter,
  marketerLinkFormatter,
  userLinkFormatter,
} from '../../../../../logic/linkFormatters';

import { EntityProperty, EntityTypeProps } from './types';

const entityTypePropsMap: Record<ENTITY_TYPE, EntityTypeProps> = {
  [ENTITY_TYPE.MARKETER]: {
    entityIdKey: 'marketerId',
    linkFormatter: marketerLinkFormatter,
  },
  [ENTITY_TYPE.COMPANY]: {
    entityIdKey: 'companyId',
    linkFormatter: companyLinkFormatter,
  },
  [ENTITY_TYPE.INTERNAL_TEAM]: {
    entityIdKey: 'internalTeamId',
    linkFormatter: internalTeamLinkFormatter,
  },
  [ENTITY_TYPE.USER]: {
    entityIdKey: 'userId',
    linkFormatter: userLinkFormatter,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const defaultFormatter = (value: any): any => value;

export const getEntityTypeProps = (entityType: ENTITY_TYPE): EntityTypeProps => entityTypePropsMap[entityType];

export const getEntityExtraData = (
  entity: ApplicationEntity,
  entityProperties: EntityProperty[] | null | undefined,
  entityIdKey: string,
  classes: Record<string, string>,
): React.ReactNode => {
  if (!entityProperties?.length) {
    return null;
  }

  const entityId: number | undefined = get(entity, entityIdKey);

  return entityProperties.map(({ entityProperty, propertyLabel, propertyFormatter = defaultFormatter }) => {
    const value = get(entity, entityProperty, '');
    const formattedValue = propertyFormatter(value);

    return (
      <span className={classes.entityProperty} key={`entity-${entityId}-prop-${entityProperty}-key`}>
        {propertyLabel && <span className={classes.entityPropertyLabel}>{propertyLabel}&nbsp;</span>}
        <span className={classes.entityPropertyValue}>{formattedValue}</span>
      </span>
    );
  });
};
