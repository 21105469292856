import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    content: { margin: 0 },
    divider: { margin: '12px 0' },
  }),
);

export default useStyles;
