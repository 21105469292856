import { useState } from 'react';
import { TaskType } from '@mayple/types';

import { TasksListData } from './types';
import { AssessmentSubTask } from '../AssessmentDialog/types';
import useCollectPostProjectMatchOfferMarketerAssessmentTasks from '../hooks/useCollectPostProjectMatchOfferMarketerAssessmentTasks';

const useTasksListData = (taskTypes: TaskType[]): TasksListData => {
  // const {
  //   subTasks: monthlyMarketerAssessmentByGrowthStrategistSubTasks,
  //   initialized: monthlyMarketerAssessmentByGrowthStrategistInitialized,
  //   loading: monthlyMarketerAssessmentByGrowthStrategistLoading,
  //   error: monthlyMarketerAssessmentByGrowthStrategistError,
  // } = useCollectMonthlyMarketerAssessmentByGrowthStrategistTasks({
  //   skipTasks: !taskTypes.includes(TaskType.MonthlyMarketerAssessmentByGrowthStrategist),
  // });

  const {
    subTasks: postProjectMatchOfferMarketerAssessmentSubTasks,
    // initialized: postProjectMatchOfferMarketerAssessmentInitialized,
    loading: postProjectMatchOfferMarketerAssessmentLoading,
    error: postProjectMatchOfferMarketerAssessmentError,
    refetch: postProjectMatchOfferMarketerAssessmentRefetch,
  } = useCollectPostProjectMatchOfferMarketerAssessmentTasks({
    skipTasks: !taskTypes.includes(TaskType.PostProjectMatchOfferMarketerAssessment),
  });

  const [selectedSubTask, setSelectedSubTask] = useState<AssessmentSubTask | null>(null);

  // const [subTasks, setSubTasks] = useState<AssessmentSubTask[]>([] as AssessmentSubTask[]);postProjectMatchOfferMarketerAssessmentSubTasks
  /*
  useEffect(() => {
    if (postProjectMatchOfferMarketerAssessmentLoading) {
      return;
    }

    const newSubTasks = [
      // ...(monthlyMarketerAssessmentByGrowthStrategistSubTasks ?? []),
      ...(postProjectMatchOfferMarketerAssessmentSubTasks ?? []).filter((subTask) =>
        taskStatus.includes(subTask.taskStatus)
      ),
    ];

    setSubTasks(newSubTasks);
  }, [postProjectMatchOfferMarketerAssessmentLoading, postProjectMatchOfferMarketerAssessmentSubTasks, taskStatus]);
  */

  const loading = postProjectMatchOfferMarketerAssessmentLoading; // || monthlyMarketerAssessmentByGrowthStrategistLoading;
  const error = postProjectMatchOfferMarketerAssessmentError; // || monthlyMarketerAssessmentByGrowthStrategistError;
  const subTasks = postProjectMatchOfferMarketerAssessmentSubTasks;

  const refetch = async () => {
    await postProjectMatchOfferMarketerAssessmentRefetch();
  };

  return {
    loading,
    error,
    refetch,
    subTasks,
    selectedSubTask,
    setSelectedSubTask,
  };
};

export default useTasksListData;
