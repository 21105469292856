import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      paddingBottom: '2rem',
    },
    sectionContentRoot: {},
    reactTable: {
      maxWidth: 'calc(100vw - 2rem)',
    },
  }),
);

export default useStyles;
