import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../../../app/theme';

export default makeStyles(() => ({
  tooltipContainer: {
    display: 'inline-flex',
    marginTop: '-1px',
  },
  tooltipIcon: {
    color: colors.blackOpacity(0.54),
    fill: colors.blackOpacity(0.54),
    width: '1.75rem',
    paddingLeft: '0.5rem',
  },
}));
