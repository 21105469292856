import { MediaType } from '@mayple/types';
import { change } from 'redux-form';

import { mapAttachedFiles } from '../logic';
import { stripHtmlTags } from '../../../services/utils';

export const POST_EDITOR_MODES = {
  IDLE: 'IDLE',
  IDLE_CLICKED: 'IDLE_CLICKED',
  ACTIVE: 'ACTIVE',
};

export const handleOnSuccess = () => {};
export const handleOnFailure = () => {};

export const removeEmptyLines = (lines) => lines.filter((line) => line.trim() !== '');

export const getInitialValues = (feedUuid, postData) => {
  // console.log('postData', postData);

  if (postData === null) {
    return {
      feedUuid,
      postTopic: null,
      uploadFiles: [],
      puppetPersonUuid: null,
    };
  }

  return {
    postUuid: postData.uuid,
    postTopic: postData.topic,
    uploadFiles: postData.attachedFiles,
    postActionItems: postData.actionItems,
    postContent: postData.content?.contents,
  };
};

export const isValidPost = (formValues, dispatch, wizardFormName) => {
  const postContent = stripHtmlTags(formValues.postContent);

  if (postContent.length === 0) {
    dispatch(change(wizardFormName, 'postContent', ''));
    return false;
  }
  return true;
};

const filterActionItems = (actionItems) => actionItems.filter((actionItem) => actionItem.toString().trim().length);

export const getMutationVariable = (formValues) => {
  const {
    feedUuid,
    postUuid,
    postActionItems,
    postContent,
    postTopic,
    uploadFiles,
    puppetPersonUuid,
    showPuppetUserSelection,
  } = formValues;

  const filteredActionItems = filterActionItems(postActionItems);

  // Update post variables
  if (postUuid) {
    return {
      postUuid,
      feedPostUpdate: {
        content: {
          mediaType: MediaType.TEXT_HTML,
          contents: postContent,
        },
        actionItems: filteredActionItems,
        topic: postTopic,
        attachedFiles: mapAttachedFiles(uploadFiles),
      },
    };
  }

  // Create post variables
  return {
    feedUuid,
    feedPostCreate: {
      content: {
        mediaType: MediaType.TEXT_HTML,
        contents: postContent,
      },
      actionItems: filteredActionItems,
      topic: postTopic,
      attachedFiles: mapAttachedFiles(uploadFiles),
      puppetPersonUuid: showPuppetUserSelection === true ? puppetPersonUuid : null,
    },
  };
};
