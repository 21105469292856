import React from 'react';
import PropTypes from 'prop-types';

import { formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';

// TODO: Consider a way to debounce this - it is real problematic in the case of sliders, for example
// https://gist.github.com/brunolemos/df7621394abbfabfffc23f2b455e960d

export class ReduxFormValueDisconnected extends React.PureComponent {
  render() {
    const { value, isAvatar, avatarSize } = this.props;

    if (!value) {
      return <span />;
    } else if (isAvatar) {
      return <Avatar src={value} size={avatarSize} />;
    } else {
      return <span>{value}</span>;
    }
  }
}

ReduxFormValueDisconnected.propTypes = {
  value: PropTypes.string,
  isAvatar: PropTypes.bool,
  avatarSize: PropTypes.number,
};

ReduxFormValueDisconnected.defaultProps = {
  value: '',
  isAvatar: false,
  avatarSize: 32,
};

const enhance = compose(
  connect((storeState, ownProps) => {
    // Create a form value selector for the current dialog
    const formStateSelector = formValueSelector(ownProps.form);

    const value = `${formStateSelector(storeState, ownProps.valueName)}`;

    return {
      value,
    };
  })
);

export const ReduxFormValue = enhance(ReduxFormValueDisconnected);

ReduxFormValue.propTypes = {
  form: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
  isAvatar: PropTypes.bool,
  avatarSize: PropTypes.number,
};

ReduxFormValue.defaultProps = {
  isAvatar: false,
  avatarSize: 32,
};
