export const getProjectPagePath = (projectId: number, hash = ''): string =>
  `/projects/${encodeURIComponent(projectId)}${hash ? `#${encodeURIComponent(hash)}` : ''}`;

export const getProjectLeadPagePath = (projectLeadId: number, hash = ''): string =>
  `/project_leads/${encodeURIComponent(projectLeadId)}${hash ? `#${hash}` : ''}`;

export const getProjectBillingPagePath = (projectId: number, returnTo?: string | undefined): string => {
  const returnToPath = returnTo ? `/?returnTo=${encodeURIComponent(returnTo)}` : '';
  return `/projects/${projectId}/billing${returnToPath}`;
};

export const getMatchingSimulationPagePath = (projectId?: number): string =>
  projectId ? `/projects/${encodeURIComponent(projectId)}/simulator` : '/projects/simulator';
