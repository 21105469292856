import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { SectionProps } from './types';

import { colorPalette, colors } from '../../../app/theme';

const defaultGutterTopSize = 3.5;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 0,
      marginTop: (props: SectionProps) => `${props.gutterTopSize || defaultGutterTopSize}rem`,
      paddingTop: (props: SectionProps) => `${props.gutterTopSize || defaultGutterTopSize}rem`,
      borderTop: `1px solid ${colorPalette.gray[30]}`,
      [theme.breakpoints.down('sm')]: {
        marginTop: 48,
        paddingTop: 48,
      },
    },
    noTopMargin: {
      marginTop: '0px !important',
      paddingTop: '0px !important',
    },
    noBorder: {
      borderTop: '0 none !important',
    },
    firstColumn: {
      paddingRight: 16,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    secondColumn: {
      paddingLeft: 16,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    sectionHeader: {
      width: '100%',
      marginBottom: 32,
    },
    sectionHeaderTitles: {
      flexGrow: 1,
    },
    sectionHeaderActions: {},
    sectionTitle: {
      marginBottom: 8,
      color: colors.black,
      fontWeight: 'bold',
    },
    sectionSubTitle: {
      color: colors.black_light,
      maxWidth: 608,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
);

export default useStyles;
