import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    dialogContent: {
      paddingTop: '2rem',
    },
  })
);
export default useStyles;
