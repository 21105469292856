import React from 'react';
import PropTypes from 'prop-types';

import BriefCategoryAnswers from './BriefCategoryAnswers';

function BriefReportAnswerSet({ deepBrief, isPrintMode, onPanelChange, toExpand, classes }) {
  return deepBrief.map((category) => {
    const { answerSetUuid } = category;

    return (
      <BriefCategoryAnswers
        expanded={isPrintMode ? true : toExpand[answerSetUuid]}
        onChange={onPanelChange(answerSetUuid)}
        classes={classes}
        category={category}
        key={answerSetUuid}
        isPrintMode={isPrintMode}
      />
    );
  })
}

BriefReportAnswerSet.propTypes = {
  deepBrief: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPrintMode: PropTypes.bool.isRequired,
  onPanelChange: PropTypes.func.isRequired,
  toExpand: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default BriefReportAnswerSet;
