import React, { FC, useMemo } from 'react';
import { Field } from 'redux-form';

import MultiplePackagesSelectorInput from './MultiplePackagesSelectorInput';

import {
  validateMinimumSelectedValuesWithMessage,
  validateMaximumSelectedValuesWithMessage,
} from '../../../../services/validation';
import { MultiplePackagesSelectorProps } from '../MultiplePackagesSelector/types';

interface MultiplePackagesSelectorFieldProps extends Omit<MultiplePackagesSelectorProps, 'onChange'> {
  name: string;
  isRequired?: boolean;
  validationErrorMessage?: string;
}

const MultiplePackagesSelectorField: FC<MultiplePackagesSelectorFieldProps> = (props) => {
  const { name, isRequired, minSelectedPackages, maxSelectedPackages, validationErrorMessage, ...rest } = props;

  const validator = useMemo(() => {
    const tempValidator = [];
    if (isRequired) {
      tempValidator.push(validateMinimumSelectedValuesWithMessage(1, validationErrorMessage || 'Required'));
    }
    if (minSelectedPackages) {
      tempValidator.push(validateMinimumSelectedValuesWithMessage(minSelectedPackages, validationErrorMessage));
    }
    if (maxSelectedPackages) {
      tempValidator.push(validateMaximumSelectedValuesWithMessage(maxSelectedPackages, validationErrorMessage));
    }
    return tempValidator;
  }, [isRequired, minSelectedPackages, maxSelectedPackages, validationErrorMessage]);

  return <Field name={name} component={MultiplePackagesSelectorInput} validate={validator} {...rest} />;
};

export default MultiplePackagesSelectorField;
