import React, { FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ScoreButtonsProps } from './types';
import useScoreButtonsData from './logic';
import { TextDisplay } from '../../display';

import useStyles from './style';

const ScoreButtons: FC<ScoreButtonsProps> = (props) => {
  const classes = useStyles(props);
  const { name, value, minimumValueCaption, maximumValueCaption, error, helperText } = props;
  const { scores, showCaptions, onChangeHandler } = useScoreButtonsData(props);

  return (
    <FormControl className={classes.root}>
      {showCaptions && (
        <div className={classes.captions}>
          {minimumValueCaption && <TextDisplay variant="caption">{minimumValueCaption}</TextDisplay>}
          {maximumValueCaption && <TextDisplay variant="caption">{maximumValueCaption}</TextDisplay>}
        </div>
      )}
      <div className={classes.buttons}>
        {scores.map((score) => {
          // const isChecked = score === value?.toString();
          const id = `${name}-radio-button-value-${score}`;

          return (
            <div className={classes.cardContainer} key={id}>
              <label htmlFor={id} className={classes.labelContainer}>
                <input
                  id={id}
                  value={score}
                  type="radio"
                  name={name}
                  checked={score.toString() === value?.toString()}
                  onChange={onChangeHandler}
                  className={classes.cardInputElement}
                />
                <div className={classes.cardInput}>{score}</div>
              </label>
            </div>
          );
        })}
      </div>
      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ScoreButtons;
