import React from 'react';
import { Project, ProjectCyclePauseDuration } from '@mayple/types';

import PauseDurations from '../../../components/PauseDurations';
import CyclePauseDurationsType from '../../../components/PauseDurations/consts';

import Section, { SECTION_LAYOUT } from '../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';

interface ProjectPausingProps {
  project: Project;
  isLive: boolean;
  refetchProjectData: () => void;
}

const ProjectPausingSection: React.FC<ProjectPausingProps> = (props) => {
  const { project, refetchProjectData, isLive } = props;
  const { id: projectId } = project;
  const havePPC = (project?.currentCycle?.estimatedMediaBudgetDivision || []).length > 0;
  const haveNonPPC = (project?.currentCycle?.additionalServices || []).length > 0;

  return (
    <Section
      title="Pausing a project"
      noBorder
      layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
      subTitle="Durations when a project is paused are durations in which no work is taking place - hence the
              services do not incur any fees. Naturally, project that was fully paused from start to end will have a
              cost of $0. The pause durations can be confirmed and edited during the final billing review, when
              billing details must be confirmed prior to actually performing the final billing."
    >
      <SectionContent>
        {!havePPC && !haveNonPPC && <CalloutMessage message="Nothing to pause" />}
        {havePPC && (
          <PauseDurations
            onSuccess={refetchProjectData}
            name="ppcDurations"
            title="Pausing PPC services"
            durations={project?.currentCycle?.ppcPauseDurations}
            durationType={CyclePauseDurationsType.PPC_DURATIONS}
            isServicePaused={project?.currentCycle?.isPPCPaused === true}
            projectId={projectId}
            showPauseResumeButton={isLive}
          />
        )}
        {haveNonPPC && (
          <PauseDurations
            onSuccess={refetchProjectData}
            name="nonPpcDurations"
            title="Pausing non-PPC services"
            durations={project?.currentCycle?.nonPPCPauseDurations || ([] as ProjectCyclePauseDuration[])}
            durationType={CyclePauseDurationsType.NON_PPC_DURATIONS}
            isServicePaused={project?.currentCycle?.isNonPPCPaused === true}
            projectId={projectId}
            showPauseResumeButton={isLive}
          />
        )}
      </SectionContent>
    </Section>
  );
};

export default ProjectPausingSection;
