import React from 'react';
import { FundAccount, Project, ProjectCycle } from '@mayple/types';

export interface ProjectServicesAndBillingTabProps {
  project: Project;
  cycles: ProjectCycle[];
  companyId: number;
  refetchProjectData: () => Promise<void>;
}

export enum ValuesToShow {
  ESTIMATED = 'estimated',
  ACTUAL = 'actual',
}

export type UseProjectServicesAndBillingTabOutput = {
  cycles: ProjectCycle[];
  isLive: boolean;
  isOnboarding: boolean;
  showMonthlyCycles: boolean;
  elapsedCycles: ProjectCycle[];
  currentCycleFundAccount: FundAccount | undefined;
  setCurrentCycleFundAccount: React.Dispatch<React.SetStateAction<FundAccount | undefined>>;
};
