import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import { validateNonEmptyArray, validateRequiredDurationInMinutes } from '../../../../services/validation';
import AvailableMeetingSlotsSelectorField from '../../AvailableMeetingSlotsSelectorField';

interface WizardAvailabilitySelectionFieldProps {
  name: string;
  data: any;
  isRequired: boolean;
  emptyArrayMessage: string;
  requiredDurationInMinutes?: number;
}

const WizardAvailabilitySelectionField: React.FC<WizardAvailabilitySelectionFieldProps> = (props) => {
  const { name, data, isRequired = false, emptyArrayMessage, requiredDurationInMinutes, ...rest } = props;

  const validator = useMemo(() => {
    const validations = [];
    if (isRequired) {
      validations.push(validateNonEmptyArray(emptyArrayMessage));
    }

    if (requiredDurationInMinutes) {
      validations.push(validateRequiredDurationInMinutes(requiredDurationInMinutes));
    }

    return validations;
  }, [isRequired, requiredDurationInMinutes, emptyArrayMessage]);

  return (
    <Field component={AvailableMeetingSlotsSelectorField} name={name} data={data} validate={validator} {...rest} />
  );
};

export default WizardAvailabilitySelectionField;
