import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    divider: {
      margin: '36px auto',
    },
    sectionRoot: {
      marginBottom: '3rem',
    },
    sectionRootNoPaddingNoMargin: {
      paddingTop: 0,
      marginTop: 0,
    },
    centerContent: {},
    formControl: {
      maxWidth: 300,
      margin: '12px auto',
      display: 'block',
    },
    actionsWrapper: {
      paddingTop: 16,
      paddingBottom: 16,
      textAlign: 'center',
    },
  }),
);
export default useStyles;
