import { useEffect, useState } from 'react';
import { FeedMaterializedView } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { ParticipantInsightsFeedQuery } from 'growl-graphql/dist/queries/ParticipantInsightsFeedQuery';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';

import { FeedEventTraits } from './types';
import { useFeedEvents } from './events';
import { clientLogger } from '../../services/logger';

interface IInsightsFeed {
  isLoading: boolean;
  insightsFeedLegend: any;
  insightsFeed: FeedMaterializedView | null | undefined;
  initialized: boolean;
  feedUuid: string | null | undefined;
  onCreatePostHandler: () => void;
  onCreatePostCommentHandler: () => void;
  onDeletePostHandler: () => void;
  onDeletePostCommentHandler: () => void;
  onPostReactionHandler: (traits: Record<string, any>) => void;
  error: ApolloError | undefined;
  basicTraits: Partial<FeedEventTraits>;
}

const useInsightsFeed = (
  projectId: number | undefined,
  marketerId: number | undefined,
  pageName: string,
  tabName?: string
): IInsightsFeed => {
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { onPostReactionHandler } = useFeedEvents();

  const { data: viewerData, loading: viewerLoading } = useQuery(ViewerUserQuery.query);
  const { data, loading, error, refetch } = useQuery(ParticipantInsightsFeedQuery.query, {
    variables: {
      projectId,
      marketerId,
    },
    skip: !projectId || !marketerId,
  });

  // console.log('ParticipantInsightsFeedQuery data', data);
  const feedUuid = data?.participant?.insightsFeedUuid;
  const insightsFeed = data?.participant?.insightsFeedMaterializedView;
  const insightsFeedLegend = data?.participant?.insightsFeedLegend;

  const basicTraits: Partial<FeedEventTraits> = {
    pageName,
    tabName,
    projectId,
    projectName: data?.participant?.project?.name,
    projectLifeCycleStatus: data?.participant?.project?.projectLifeCycleStatus,
    userType: viewerData?.viewer?.type,
  };

  useEffect(() => {
    if (!loading && !viewerLoading) {
      setInitialized(true);
    }
  }, [loading, viewerLoading]);

  const refetchPosts = () => {
    setIsLoading(true);
    refetch()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e: Error) => {
        clientLogger.error(e, 'Failed to refresh feed data');
        setIsLoading(false);
      });
  };

  const onCreatePostHandler = () => {
    refetchPosts();
  };

  const onCreatePostCommentHandler = () => {
    refetchPosts();
  };

  const onDeletePostHandler = () => {
    refetchPosts();
  };

  const onDeletePostCommentHandler = () => {
    refetchPosts();
  };

  return {
    initialized,
    isLoading,
    error,
    feedUuid,
    insightsFeed,
    insightsFeedLegend,
    onCreatePostHandler,
    onCreatePostCommentHandler,
    onDeletePostHandler,
    onDeletePostCommentHandler,
    onPostReactionHandler,
    basicTraits,
  };
};

export default useInsightsFeed;
