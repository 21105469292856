import React from 'react';
import PropTypes from 'prop-types';
import { withMutation, UpdateCompanyFundAccountMutation } from 'growl-graphql';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';

import { WizardSteps } from './steps';
import { RefreshSpinner } from '../../../../../../fe_common/client/components/atoms';
import { WizardForm } from '../../../../../../fe_common/client/components/organisms/WizardForm';

class EditManualPaymentAgreementWizardDisconnected extends React.Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  async handleFormSubmit(formValuesSubmitted, dispatch, props) {
    const { updateCompanyFundAccountMutation, companyId, onSuccess } = this.props;

    // const test{ accountChoice, displayImageUrl, firstName, lastName, phoneNumber } = formValuesSubmitted;

    const queryVariables = {
      manualPaymentAgreement: {
        ...formValuesSubmitted,
      },
      companyId,
    };

    try {
      // Allow throwing of errors out of the mutate
      await updateCompanyFundAccountMutation.mutate(queryVariables, true);
      props.destroy();
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
      // throw new Error(err);
    }
  }

  render() {
    const { loading: loadingMutation } = this.props.updateCompanyFundAccountMutation;

    if (loadingMutation) {
      return <RefreshSpinner />;
    }

    // console.log('EditManualPaymentAgreementWizard props', this.props);

    const { manualPaymentAgreement, companyId, showCancelButton, onCancel } = this.props;
    if (!manualPaymentAgreement || !companyId) {
      return <div>Something went wrong. No payment agreement \ company ID found</div>;
    } else {
      const wizardFormName = `EditManualPaymentAgreementWizard-${companyId}`;
      // ! don't send text fields name to formValueSelector, it will cause re-render on every key stroke!
      // console.log('marketerProfile', marketerProfile);

      const { active, details } = manualPaymentAgreement;

      const initialValues = {
        active,
        details,
      };

      return (
        <Grid container>
          <Grid item xs={12}>
            <WizardForm
              form={wizardFormName}
              watchAllValues
              initialValues={initialValues}
              steps={WizardSteps}
              onSubmit={this.handleFormSubmit}
              showCancelButton={showCancelButton}
              onCancel={onCancel}
            />
          </Grid>
        </Grid>
      );
    }
  }
}

EditManualPaymentAgreementWizardDisconnected.propTypes = {
  updateCompanyFundAccountMutation: PropTypes.object.isRequired,
  manualPaymentAgreement: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

EditManualPaymentAgreementWizardDisconnected.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
  showCancelButton: false,
};

const enhance = compose(withMutation(UpdateCompanyFundAccountMutation));

export const EditManualPaymentAgreementWizard = enhance(EditManualPaymentAgreementWizardDisconnected);
