import { Project } from '@mayple/types';
import { checkHasAssignedGS } from '../../../fe_common/client/hooks/internalTeam/logic';

type CBF = (...rest: any) => void;
/**
 * Wrapper that checks if there is assigned GS, and returns one of the following:
 * No GS - a function alerting the user to assign GS first
 * Has GS - the call back function passed as parameter
 *
 * @param project
 * @param cb
 */
// eslint-disable-next-line import/prefer-default-export
export const withAssignedGSVerification = (project: Partial<Project> | null | undefined, cb: CBF): CBF => {
  if (!checkHasAssignedGS(project)) {
    return function a() {
      // eslint-disable-next-line no-alert
      window.alert('You must assign a Growth Strategist before selecting an Expert');
    };
  }
  return function b(...rest: any) {
    cb(...rest);
  };
};
