import React, { useState } from 'react';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Project } from '@mayple/types';

import FullScreenDialog from '../../../../../fe_common/client/components/dialogs/FullScreenDialog';
import ProjectCreationWizard from '../../../../containers/project/wizards/ProjectCreationWizard';

interface ProjectCreationDialogProps {
  companyId: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: (p: Project) => void;
}

const DEFAULT_ON_SUCCESS_HANDLER = () => {
  /* Do Nothing */
};

const ProjectCreationDialog: React.FC<ProjectCreationDialogProps> = (props) => {
  const { companyId, open, onClose, onSuccess = DEFAULT_ON_SUCCESS_HANDLER } = props;
  const formName = `projectCreationWizard-${companyId}`;
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSaveStatusChangeHandler = (newSaveStatus: boolean) => setIsSaving(newSaveStatus);

  const primaryActionHandler = () => {
    setIsSaving(true);
    dispatch(submit(formName));
  };

  const onErrorHandler = () => {
    setIsSaving(false);
  };

  const onSuccessHandler = (createdProject: Project) => {
    onSuccess?.(createdProject);
    setIsSaving(false);
    onClose();
  };

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      title="New project"
      actions={{
        primaryAction: {
          label: 'create project',
          onClick: primaryActionHandler,
          loading: isSaving,
          disabled: isSaving,
        },
        secondaryAction: { label: 'cancel', onClick: onClose },
      }}
    >
      <ProjectCreationWizard
        companyId={companyId}
        formName={formName}
        onSuccess={onSuccessHandler}
        onError={onErrorHandler}
        onSaveStatusChange={onSaveStatusChangeHandler}
      />
    </FullScreenDialog>
  );
};

export default ProjectCreationDialog;
