import { GridSize } from '@material-ui/core/Grid/Grid';

import { SECTION_LAYOUT } from './types';

const twoColumnsKeys = [
  SECTION_LAYOUT.TWO_COLUMNS,
  SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS,
  SECTION_LAYOUT.TWO_THIRDS_ONE_THIRD,
] as const;
export type TwoColumnsKeys = typeof twoColumnsKeys[number];

export const twoColumnsLayouts: Record<TwoColumnsKeys, [boolean | GridSize, boolean | GridSize]> = {
  [SECTION_LAYOUT.TWO_COLUMNS]: [6, 6],
  [SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS]: [4, 8],
  [SECTION_LAYOUT.TWO_THIRDS_ONE_THIRD]: [8, 4],
};
export const getColumnsLayout = (layout: TwoColumnsKeys): [boolean | GridSize, boolean | GridSize] =>
  twoColumnsLayouts[layout] || twoColumnsLayouts[SECTION_LAYOUT.TWO_COLUMNS];
