import { MarketerMatch, Project, User } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';
import { ProjectMarketerMatchesQuery } from 'growl-graphql/dist/queries/ProjectMarketerMatchesQuery';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';
import { UpdateProjectWithOperationResultMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectWithOperationResultMutation';

import { UseMarketerMatchesOverrideData } from './types';

import { useMutation } from '../../../../../fe_common/client/hooks';

const useMarketerMatchesOverride = (projectId?: number): UseMarketerMatchesOverrideData => {
  const { data: dataViewer, loading: loadingViewer } = useQuery(ViewerUserQuery.query);

  const {
    data: fullProjectByIdData,
    loading: fullProjectByIdLoading,
    refetch: fullProjectByIdRefetch,
  } = useQuery(FullProjectByIdQuery.query, {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: projectMarketerMatchesData,
    loading: projectMarketerMatchesLoading,
    refetch: projectMarketerMatchesRefetch,
  } = useQuery(ProjectMarketerMatchesQuery.query, {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
  });

  const { mutate: updateProjectMutate, loading: updateProjectLoading } = useMutation(
    UpdateProjectWithOperationResultMutation,
  );

  const refetchData = async () => {
    fullProjectByIdRefetch();
    projectMarketerMatchesRefetch();
  };

  return {
    viewer: dataViewer?.viewer || ({} as User),
    project: fullProjectByIdData?.project || ({} as Project),
    marketerMatches: projectMarketerMatchesData?.project?.marketerMatches || ([] as MarketerMatch[]),
    fullProjectByIdRefetch,
    projectMarketerMatchesRefetch,
    updateProjectMutate,
    loading: loadingViewer || updateProjectLoading || projectMarketerMatchesLoading || fullProjectByIdLoading,
    refetchData,
  };
};

export default useMarketerMatchesOverride;
