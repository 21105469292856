// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GrAK_FwEUBN2MyFh1f5G {
  border: 1px solid #818293;
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  transition: all 0.2s;
  width: 100%;
}

.GrAK_FwEUBN2MyFh1f5G:hover {
  border: 1px solid #103f60;
}

.GrAK_FwEUBN2MyFh1f5G:focus {
  border: 2px solid #534ff2;
  outline: 0;
}

.tDPBs7E5NO8eZKF5HoV2 {
  border: 1px solid #fd5444;
}
`, "",{"version":3,"sources":["webpack://./src/fe_common/client/components/atoms/Registration/PhoneNumberInput2/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".form-control {\n  border: 1px solid #818293;\n  box-sizing: border-box;\n  border-radius: 4px;\n  height: 56px;\n  transition: all 0.2s;\n  width: 100%;\n}\n\n.form-control:hover {\n  border: 1px solid #103f60;\n}\n\n.form-control:focus {\n  border: 2px solid #534ff2;\n  outline: 0;\n}\n\n.form-control__error {\n  border: 1px solid #fd5444;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `GrAK_FwEUBN2MyFh1f5G`;
export { _1 as "form-control" };
var _2 = `tDPBs7E5NO8eZKF5HoV2`;
export { _2 as "form-control__error" };
export default ___CSS_LOADER_EXPORT___;
