import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { RadioCards } from '../../Registration/RadioCards';
import { validateRequired } from '../../../../services/validation';

/**
 * will create a 3 in a row cards, that are multichoice checkboxes, add them them to redux form
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardRadioCardGroupField(props) {
  const { name, enums, isRequired, currentSelected, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  return (
    <Field
      component={RadioCards}
      name={name}
      validate={validator}
      enums={enums}
      currentSelected={currentSelected}
      {...rest}
    />
  );
}

WizardRadioCardGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  enums: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  currentSelected: PropTypes.string,
};

WizardRadioCardGroupField.defaultProps = {
  currentSelected: '',
  isRequired: false,
};
