/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReportRefundToCompanyMutation } from 'growl-graphql/dist/mutations/ReportRefundToCompanyMutation';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import useMutation from '../../../../../fe_common/client/hooks/useMutation';
import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { formatCurrencyString } from '../../../../../fe_common/client/services/utils';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { DEPOSIT_SOURCE } from '../../../../../fe_common/client/app/enums';

const useStyles = makeStyles(() => ({
  root: {},
  actionsWrapper: {
    textAlign: 'right',
    '& button': {
      marginRight: 12,
    },
    '& button:last-child': {
      marginRight: 0,
    },
  },
}));

const ReportRefundToCompany = ({ company, onSuccess }) => {
  const classes = useStyles();
  const { fundAccount, id: companyId } = company;
  const { mutate, loading } = useMutation(ReportRefundToCompanyMutation);
  const [showFlow, setShowFlow] = useState(false);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [depositSource, setDepositSource] = useState('');
  const descriptionLabel = useMemo(() => {
    let label = 'Please enter any additional information / reference number';

    if (depositSource === 'PAYPAL') {
      label = 'Paypal payment reference ID';
    }
    if (depositSource === 'CREDIT_CARD') {
      label = 'Bluesnap payment reference ID';
    }
    if (depositSource === 'WIRE_TRANSFER') {
      label = 'Bank transfer reference ID';
    }

    return label;
  }, [depositSource]);

  if (!fundAccount) {
    return <CalloutMessage type="error" message="No fund account data" />;
  }

  const { balance } = fundAccount;

  const handleOnSelect = (e) => {
    setDepositSource(e.target.value);
  };
  const handleOnType = (e) => {
    setDescription(e.target.value);
  };
  const handleOnTypeAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleOnRefund = async () => {
    if (balance < amount) {
      alert(`Cannot report amount of ${amount}, while balance only have ${balance}`);
      return;
    }
    if (amount <= 0) {
      alert(`Cannot report amount of ${amount}. Please use positive amounts.`);
      return;
    }

    const confirmed = window.confirm(`
      Will refund ${amount} $ for Company ${companyId}.
      Please confirm.
    `);

    if (confirmed) {
      let finalDesc = description;

      if (depositSource !== 'CASH') {
        finalDesc = `${descriptionLabel}: ${description}`;
      }

      const variables = {
        companyId,
        depositSource,
        withdrawalOperation: {
          amount,
          description: finalDesc,
          currency: 'USD',
        },
      };

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(variables, null, 2)}`);

      await mutate({ variables });

      setShowFlow(false);
      setDescription('');
      setAmount('');
      onSuccess();
    }
  };

  return (
    <div className={classes.root}>
      <TextDisplay>Balance available for refund: {formatCurrencyString(balance)}</TextDisplay>
      {!showFlow && (
        <div className={classes.actionsWrapper}>
          <Button
            color="secondary"
            variant="contained"
            label="REPORT REFUND"
            disabled={balance === 0 || loading}
            onClick={() => {
              setShowFlow(true);
            }}
          />
        </div>
      )}

      {showFlow && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="age-native-simple">Refund target</InputLabel>
              <Select
                value={depositSource}
                native
                onChange={handleOnSelect}
                inputProps={{
                  name: 'ReportRefundToCompany',
                  id: 'projectFinishReason-native-simple',
                }}
                disabled={loading}
              >
                <option value="" />
                {DEPOSIT_SOURCE.map(({ label, value }) => (
                  <option key={`marketing-supervisor-${label}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!!depositSource && (
            <>
              <Grid item xs={12}>
                <TextField
                  label={descriptionLabel}
                  variant="outlined"
                  onChange={handleOnType}
                  value={description}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount (USD)"
                  variant="outlined"
                  onChange={handleOnTypeAmount}
                  value={amount}
                  type="number"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.actionsWrapper}>
            <Button
              label="CANCEL"
              disabled={loading}
              onClick={() => {
                setShowFlow(false);
              }}
            />
            <Button
              color="primary"
              variant="contained"
              label="REFUND"
              disabled={loading || !description || !depositSource}
              onClick={handleOnRefund}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

ReportRefundToCompany.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ReportRefundToCompany;
