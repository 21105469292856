import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormSubmitHandler, SubmissionError } from 'redux-form';
import isEqual from 'lodash/isEqual';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import WizardSteps from './WizardSteps';
import { EditableComponentProps } from './types';

import chatApi from '../../../logic/chat';
import { WizardForm } from '../../organisms/WizardForm';
import { RefreshSpinner } from '../../atoms';
import { colors } from '../../../app/theme';
import { TextDisplay } from '../../display';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import { useDialog } from '../../../hooks';
import { clientLogger } from '../../../services/logger';

import useStyles from './style';

const defaultHandleValuesBeforeSave = (values: Record<string, any>) => values;

/**
 * Editable component has two modes: View and Edit (and we should probably use an enum to describe them)
 * When in view mode we return the values wrapped with a container that has an edit button on hover.
 * Once the button is clicked we switch the view into Edit mode,
 * allowing the user to edit the value and either save it or cancel the changes.
 *
 * @param props
 * @constructor
 */
const EditableComponent: FC<EditableComponentProps> = (props) => {
  const {
    view,
    enableEdit,
    mutation,
    fields,
    initialValues = {},
    showConfirmDialog = false,
    watchAllValues = false,
    dialogTitle = 'Confirm Action',
    dialogContent = '',
    // companyId = 0,
    // projectId = 0,
    valuesToWatch = [],
    handleValuesBeforeSave = defaultHandleValuesBeforeSave,
    formName = '',
    // onEdit,
    onSave,
    onCancel,
  } = props;

  const classes = useStyles(props);

  const [onEditMode, setOnEditMode] = useState(false);

  const { open: confirmDialogOpen, closeDialog: closeConfirmDialog, openDialog: openConfirmDialog } = useDialog();

  const alternativeFormName = useRef<string | undefined>();

  const onConfirmDialogApprove = () => {};

  const { loading } = mutation;

  // should be set only once on load
  useEffect(() => {
    alternativeFormName.current = `${Math.random().toString(36).substring(2, 5)}-EditForm`;
  }, []);

  const handleEdit = () => {
    setOnEditMode(true);
  };

  const handleSave: FormSubmitHandler = useCallback(
    async (values, _dispatch, _props) => {
      // Compare initial values to sent values from redux form
      const valuesChanged = !isEqual(initialValues, values);

      const newValues = handleValuesBeforeSave(values);

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(newValues, null, 2)}`);

      // If the user clicks save without changing anything, no need to run the mutation.
      if (valuesChanged) {
        try {
          await mutation.mutate({ variables: newValues });
        } catch (err: any) {
          throw new SubmissionError(err);
        }
      }

      onSave?.();
      closeConfirmDialog?.();
      setOnEditMode(false);
    },
    [closeConfirmDialog, handleValuesBeforeSave, initialValues, mutation, onSave],
  );

  const onConfirmDialogApproveHandler: FormSubmitHandler = (values, dispatch, formProps) => () =>
    handleSave(values, dispatch, formProps);

  const onConfirmDialogCancel = () => {
    // props.destroy(); // delete the form from redux
    setOnEditMode(false);
    closeConfirmDialog();
  };

  // Can be used to checks before saving data.
  const handlePreSave: FormSubmitHandler = (values, dispatch, formProps) => {
    // optional confirmation dialog to show before saving changed data
    if (showConfirmDialog) {
      openConfirmDialog();
      onConfirmDialogApproveHandler(values, dispatch, formProps);
    } else {
      handleSave(values, dispatch, formProps);
    }
  };

  const onCancelClickHandler = () => {
    setOnEditMode(false);
    onCancel?.();
  };

  return (
    <>
      {loading && enableEdit && (
        <div style={{ textAlign: 'center' }}>
          Saving... <RefreshSpinner size={32} />
        </div>
      )}

      {onEditMode && enableEdit && !loading && (
        <div>
          <WizardForm
            form={formName ? `${formName}-EditForm` : alternativeFormName.current}
            initialValues={initialValues}
            onSubmit={handlePreSave}
            destroyOnUnmount
            // @ts-ignore
            onCancelClickHandler={onCancelClickHandler}
            valuesToWatch={valuesToWatch}
            watchAllValues={watchAllValues}
            steps={WizardSteps}
            formFields={fields}
          />
          {confirmDialogOpen && (
            <ConfirmDialog
              open={confirmDialogOpen}
              title={dialogTitle}
              content={dialogContent}
              onConfirm={onConfirmDialogApprove}
              onCancel={onConfirmDialogCancel}
            />
          )}
        </div>
      )}

      {!onEditMode && (
        <div className={classes.viewContainer} onDoubleClick={handleEdit}>
          <div>
            {enableEdit && (
              <IconButton onClick={handleEdit} className={classes.editButton}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {view}

          {!enableEdit && (
            <TextDisplay variant="caption" style={{ marginTop: 4 }} colorOverride={colors.black_lighter}>
              To edit this field, please{' '}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  chatApi.openChat('I need help with editing my information');
                }}
              >
                contact
              </a>{' '}
              our customer success team.
            </TextDisplay>
          )}
        </div>
      )}
    </>
  );
};

export default EditableComponent;
