import React from 'react';
import moment from 'moment';
import { Steps, Step } from 'react-albus';

import { WizardStepField } from '../../../../../../fe_common/client/components/molecules/WizardStepField';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const ExtendProjectOpportunityWizardSteps: React.FC = () => {
  const minDate = moment().add(4, 'hours');
  const maxDate = moment().add(1, 'month');

  return (
    <Steps>
      <Step
        id="extendOpportunitiesConfirmation"
        render={() => (
          <div>
            <TextDisplay gutterBottom>
              Please select the expiration date of the selected opportunities
              <br />
              (default is 24 hours)
            </TextDisplay>
            <WizardStepField
              type={WizardStepField.types.DATE_TIME_PICKER}
              // @ts-ignore
              label="Date to expire"
              name="dueDateTime"
              required
              minDate={minDate}
              maxDate={maxDate}
              views={['date']}
            />
          </div>
        )}
      />
    </Steps>
  );
};

export default ExtendProjectOpportunityWizardSteps;
