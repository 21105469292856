import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    healthCheckFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '2rem',
    },
    fileListContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
      gap: '1rem',
    },
    fileListRoot: {
      flexGrow: 1,
      width: '100%',
    },
    moveToMarketerFixesButton: {
      flexGrow: 1,
      width: '100%',
    },
  }),
);

export default useStyles;
