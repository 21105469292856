import React from 'react';
import PropTypes from 'prop-types';

import FacebookIntegrationDetails from './FacebookIntegrationDetails';
import GoogleIntegrationDetails from './GoogleIntegrationDetails';
import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';

export function IntegrationsTreeView(props) {
  const { integration } = props;

  const { facebookIntegrationUser, googleAdWordsIntegrationUser } = integration;

  // console.log('facebookIntegrationUser - ', facebookIntegrationUser);
  // console.log('googleAdWordsIntegrationUser - ', googleAdWordsIntegrationUser);

  if (!facebookIntegrationUser && !googleAdWordsIntegrationUser) {
    return (
      <TextDisplay style={{ margin: '0 75px' }} colorOverride={colors.black_light}>
        No integrations to display.
      </TextDisplay>
    );
  }

  return (
    <div>
      {facebookIntegrationUser && <FacebookIntegrationDetails integrationUser={facebookIntegrationUser} />}

      {googleAdWordsIntegrationUser && <GoogleIntegrationDetails integrationUser={googleAdWordsIntegrationUser} />}
    </div>
  );
}

IntegrationsTreeView.propTypes = {
  integration: PropTypes.object.isRequired,
};
