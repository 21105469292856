import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    sectionRoot: {
      paddingTop: 0,
      paddingBottom: 36,
      marginTop: 36,
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    addIcon: {
      cursor: 'pointer',
    },
  }),
);

export default useStyles;
