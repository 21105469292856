import React, { FC } from 'react';
import Link from '@material-ui/core/Link';

import { LinkToCompanyProps } from './types';

const LinkToCompany: FC<LinkToCompanyProps> = (props) => {
  const { companyId, openInNewTab = false, children } = props;
  const target = openInNewTab ? '_blank' : '_self';

  return (
    <Link href={`/companies/${companyId}`} target={target}>
      {children}
    </Link>
  );
};

export default LinkToCompany;
