import React, { FC } from 'react';
import { MarketerMatch, Participant } from '@mayple/types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { getCrackleUrl } from '../../../../../app/utils';
import LinkToMarketer from '../../../../../components/cpanel/components/atoms/Links/LinkToMarketer';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import ExternalLink from '../../../../../../fe_common/client/components/display/ExternalLink';

import useStyles from '../style';

interface AssignedMarketerProps {
  assignedMarketer: Participant;
}

const AssignedMarketer: FC<AssignedMarketerProps> = (props) => {
  const { assignedMarketer } = props;
  const classes = useStyles();

  const { marketerId = 0, marketerMatch = {} as MarketerMatch, projectId } = assignedMarketer || ({} as Participant);

  const impersonateUrl = projectId
    ? `${getCrackleUrl()}/marketer/projects/${projectId}/?actAsMarketerId=${marketerId}`
    : `${getCrackleUrl()}?actAsMarketerId=${marketerId}`;

  return (
    <TextDisplay variant="subtitle1" className={classes.inlineItem}>
      {'Assigned Marketer: '}
      <LinkToMarketer marketerId={marketerId}>
        {marketerMatch?.name} (ID: {marketerId})
      </LinkToMarketer>
      <ExternalLink href={impersonateUrl}>
        <Tooltip title="Impersonate marketer" placement="top">
          <IconButton>
            <SupervisorAccountIcon />
          </IconButton>
        </Tooltip>
      </ExternalLink>
    </TextDisplay>
  );
};

export default AssignedMarketer;
