import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    maxWidth: 1280,
    paddingRight: 0,
    paddingLeft: 0,
  },
  pageHeaderRoot: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 12,
      paddingLeft: 24,
    },
  },
  pageHeaderLeftSide: {
    flexGrow: 1,
    flexBasis: 'auto',
    maxWidth: 'none',
  },
  pageHeaderRightSide: {
    flexGrow: 0,
    alignSelf: 'flex-end',
    flexBasis: 'auto',
  },
  feedContainer: {
    flexWrap: 'nowrap',
  },
  feedViewerContainer: {
    flexGrow: 1,
  },
  feedFilterManagerContainer: {
    flexBasis: '320px',
    minWidth: 320,
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
      minWidth: 'auto',
    },
  },
}));

export default useStyles;
