import React from 'react';
import { Project } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import EditProjectLaunchDate from './EditProjectLaunchDate';
import ManualProjectLaunchButton from './ManualProjectLaunchButton';
import CalloutMessage from '../../../../../../../fe_common/client/components/common/CalloutMessage';
import useProjectFlowConfiguration, {
  FlowConfigurationKey,
} from '../../../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';
import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import getShouldShowDelayedProjectLaunch from '../../../../../../../fe_common/client/logic/project/stage/launch/logic';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
    },
    delayedStart: {
      display: 'flex',
      gap: '2rem',
      justifyContent: 'space-between',
    },
    editProjectLaunchDateRoot: {
      flexGrow: 1,
    },
    manualProjectLaunchButtonRoot: {
      flexGrow: 0,
    },
  }),
);

interface ProjectLaunchProps {
  project: Project | undefined;
  onUpdateSuccess?: () => void;
  classes?: Record<string, string>;
}

const ProjectLaunch: React.FC<ProjectLaunchProps> = (props) => {
  const classes = useStyles();
  const { project = {} as Project, onUpdateSuccess } = props;

  const { getProjectFlowConfigurationByKey } = useProjectFlowConfiguration(project?.id);
  const allowDelayedProjectLaunch = getProjectFlowConfigurationByKey(
    'allowDelayedProjectLaunch' as FlowConfigurationKey,
  );

  const messageStartAfterApprove = 'This project will start right after the client will approve the expert.';
  const messageStartDelayed = 'This project will automatically start at the defined launch date.';
  const message = !allowDelayedProjectLaunch.value ? messageStartAfterApprove : messageStartDelayed;

  const shouldShowProjectLaunch = getShouldShowDelayedProjectLaunch(project);

  if (!shouldShowProjectLaunch) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Section
        noBorder
        noTopMargin
        title="Delayed Project Start"
        subTitle="You can set a delayed project start to this project"
      >
        <div className={classes.delayedStart}>
          <EditProjectLaunchDate
            project={project}
            onUpdateSuccess={onUpdateSuccess}
            classes={{ root: classes.editProjectLaunchDateRoot }}
          />
          <ManualProjectLaunchButton
            projectId={project?.id}
            onSuccess={onUpdateSuccess}
            classes={{ root: classes.manualProjectLaunchButtonRoot }}
          />
        </div>
        <CalloutMessage message={message} type="info" margin />
      </Section>
    </div>
  );
};

export default ProjectLaunch;
