import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ReportCashPaymentForm from './ReportCashPaymentForm';
import useControlledAccordion from '../../../../hooks/useControlledAccordion';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

function ReportCashPayment({ company, onSuccess }) {
  const { expandAccordion, accordionOnChangeHandler, onSuccessHandler } = useControlledAccordion(onSuccess);

  return (
    <Section
      title="Report Cash Payment"
      subTitle={`Report a payment that company has made to Mayple, which was paid with cash. 
                   This payment will then be useable as credit for new and existing projects.`}
      layout={SECTION_LAYOUT.TWO_COLUMNS}
    >
      <Accordion expanded={expandAccordion} onChange={accordionOnChangeHandler}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay>Click here to report cash payment</TextDisplay>
        </AccordionSummary>
        <AccordionDetails>
          <ReportCashPaymentForm company={company} onSuccess={onSuccessHandler} />
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}

ReportCashPayment.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

ReportCashPayment.defaultProps = {
  userType: 'NORMAL_USER',
};

export default ReportCashPayment;
