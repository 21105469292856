import React from 'react';
import { ProjectStopCondition } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { colors } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    met: {
      color: colors.green_dark,
      fontSize: '2rem',
    },
    unMet: {
      color: colors.cherry,
      fontSize: '2rem',
    },
  }),
);

interface ProjectStopConditionsProps {
  stopConditions: ProjectStopCondition[] | null | undefined;
}

const ProjectStopConditions: React.FC<ProjectStopConditionsProps> = (props) => {
  const classes = useStyles();
  const { stopConditions } = props;

  const allConditionsMet = stopConditions && stopConditions.every((condition) => condition.status);

  return (
    <List className={classes.root}>
      {(stopConditions || []).map(({ condition, status, statusReason }, index) => (
        <ListItem key={`ProjectStopCondition-${index}`}>
          <ListItemIcon>
            {status && <CheckIcon className={classes.met} />}
            {!status && <CloseIcon className={classes.unMet} />}
          </ListItemIcon>
          <ListItemText primary={condition} secondary={statusReason} />
        </ListItem>
      ))}
      <ListItem>{allConditionsMet ? 'All stopping conditions met.' : 'Not all stopping conditions met.'}</ListItem>
    </List>
  );
};

export default ProjectStopConditions;
