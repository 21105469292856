import React, { FC } from 'react';
import { Project } from '@mayple/types';

import { config } from '../../../../config';

import PageLoadingPlaceholder from '../../../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import FeedContainer from '../../../../../fe_common/client/components/feed';
import useInsightsFeed from '../../../../../fe_common/client/components/feed/hooks';
import { setNotification } from '../../../../../fe_common/client/services/notification';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';

export type ProjectInsightsTabProps = {
  project: Project;
  classes?: Record<string, string>;
};

const onErrorHandler = (errorMessage: string): void => {
  setNotification(errorMessage, 'warning');
};

const ProjectInsightsTab: FC<ProjectInsightsTabProps> = (props) => {
  const { project } = props;
  const classes = useStyles();

  const projectId = project?.id;
  const marketerId = project?.participants?.[0]?.marketerId;

  const {
    initialized,
    error,
    feedUuid,
    insightsFeed,
    onCreatePostHandler,
    insightsFeedLegend,
    onCreatePostCommentHandler,
    onDeletePostHandler,
    onDeletePostCommentHandler,
    onPostReactionHandler,
    basicTraits,
  } = useInsightsFeed(projectId, marketerId, 'InternalTeam.Project', 'Insights');

  if (!initialized) {
    return <PageLoadingPlaceholder />;
  }

  if (!projectId || !marketerId) {
    return (
      <CalloutMessage message="Could not load project insights. No project/marketer ID was provided!" type="warning" />
    );
  }

  return (
    <FeedContainer
      enableComments
      canPostAsPuppet
      feedUuid={feedUuid}
      title="Project Insights"
      feed={insightsFeed}
      legend={insightsFeedLegend}
      filestackApiKey={config.filestackApiKey}
      error={error?.graphQLErrors}
      onCreatePost={onCreatePostHandler}
      onCreatePostComment={onCreatePostCommentHandler}
      onDeletePost={onDeletePostHandler}
      onDeleteComment={onDeletePostCommentHandler}
      onPostReaction={onPostReactionHandler}
      projectId={projectId}
      marketerId={marketerId}
      onError={onErrorHandler}
      basicTraits={basicTraits}
      classes={{
        pageHeaderRoot: classes.pageHeaderRoot,
        container: classes.container,
      }}
    />
  );
};

export default ProjectInsightsTab;
