import { ProjectCyclePhase } from '@mayple/types';
import keyBy from 'lodash/keyBy';
import classNames from 'classnames';

import { getBillingModelTerms } from '../../../../../../containers/marketer/wizards/BillingCycleWizard/logic';

import { MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS } from '../../../../../../../fe_common/client/app/enums';
import {
  formatPercentageString,
  resolveCurrencySymbol,
  safeExtractCurrencyValue,
} from '../../../../../../../fe_common/client/services/utils';
import {
  deliverablesFulfillmentBehaviorLookup,
  getAdditionalServiceName,
} from '../../../../../../../fe_common/client/logic/services';

/* //////////////////////// */
/* General Helper functions */
/* //////////////////////// */

export const getLabelByCycleStatus = (label, isActual, cyclePhase = '', ignorePhase = true) => {
  let prefix = 'Estimated';

  if (isActual) {
    if (ignorePhase) {
      prefix = 'Actual';
    } else if (cyclePhase === ProjectCyclePhase.FINALIZED) {
      prefix = 'Final';
    } else if ([ProjectCyclePhase.ONGOING, ProjectCyclePhase.ELAPSED].includes(cyclePhase)) {
      prefix = 'Ongoing';
    } else {
      prefix = 'Actual';
    }
  }

  return `${prefix} ${label}`;
};

export const getCycleDates = (cycle) => {
  if (!cycle) {
    return '';
  }
  const { startDate, endDate } = cycle;
  const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
  const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getCurrCycleHeaderPrefix = (currentCycle, nextCycle) => {
  const defaultHeaderPrefix = 'Current Cycle | ';
  if (!currentCycle) {
    return '';
  }

  if (nextCycle) {
    return defaultHeaderPrefix;
  }

  const { phase } = currentCycle;
  return phase !== ProjectCyclePhase.ONGOING ? 'Monthly Cycle | ' : defaultHeaderPrefix;
};

export const getCycleHeader = (cycle, prefix = '') => {
  if (!cycle) {
    return '';
  }
  const cycleDates = getCycleDates(cycle);
  return prefix ? `${prefix} ${cycleDates}` : `${cycleDates}`;
};

const isRecurring = (recurring) => (recurring ? 'recurring' : 'non-recurring');

const getClassByCyclePhase = (phase, classes) => {
  const mapper = {
    PENDING: classes.pending,
    ONGOING: classes.ongoing,
    ELAPSED: classes.elapsed,
    FINALIZED: classes.finalized,
    CANCELED: classes.canceled,
  };

  return mapper[phase] || '';
};

// -------------
// Setup Service
// -------------

export const getSetupFeeDiscount = (currentCycle, nextCycle, classes = {}) => {
  const currBillingConfiguration = currentCycle?.billingConfiguration;
  const nextBillingConfiguration = nextCycle?.billingConfiguration;

  const currSetupServiceDiscount = (currBillingConfiguration?.setupServiceDiscount || 0) / 100;
  const nextSetupServiceDiscount = (nextBillingConfiguration?.setupServiceDiscount || 0) / 100;
  return [
    {
      label: 'One-Time/Setup Simple Service Discount',
      currCycleValue: formatPercentageString(currSetupServiceDiscount),
      currCycleExtraLabel: isRecurring(currBillingConfiguration?.setupServiceDiscountRecurring),
      nextCycleValue: nextCycle ? formatPercentageString(nextSetupServiceDiscount) : '',
      nextCycleExtraLabel: nextCycle ? isRecurring(nextBillingConfiguration?.setupServiceDiscountRecurring) : '',
      customClasses: {
        row: classes.extendedRow,
      },
    },
  ];
};

export const getSetupFee = (currentCycle, nextCycle, classes) => {
  const currSetupFee = currentCycle?.setupServiceCost;
  const nextSetupFee = nextCycle?.setupServiceCost;

  const currCurrency = currentCycle?.setupService?.currency;
  const nextCurrency = nextCycle?.setupService?.currency;
  const setupFeeDiscount = getSetupFeeDiscount(currentCycle, nextCycle, classes);

  return [
    {
      label: 'One-Time/Setup Simple Service Fee',
      currCycleValue: safeExtractCurrencyValue(currSetupFee, currCurrency),
      currCycleExtraLabel: '',
      nextCycleValue: safeExtractCurrencyValue(nextSetupFee, nextCurrency),
      nextCycleExtraLabel: '',
      extendedData: setupFeeDiscount,
    },
  ];
};

// ------------
// PPC Services
// ------------

export const getMediaBudgetAndSpend = (
  currentCycle,
  nextCycle,
  currency,
  classes,
  showActual = false,
  withCyclePhase = false,
) => {
  const currMediaBudget = showActual ? currentCycle?.actualMediaSpend : currentCycle?.estimatedMediaBudget;
  const nextMediaBudget = showActual ? nextCycle?.actualMediaSpend : nextCycle?.estimatedMediaBudget;

  const cyclePhase = withCyclePhase ? currentCycle?.phase : '';
  const mediaBudgetLabel = showActual ? 'PPC Media Spend' : 'PPC Media Budget';

  const mediaBudget = [
    {
      label: getLabelByCycleStatus(mediaBudgetLabel, showActual, cyclePhase, true),
      currCycleValue: safeExtractCurrencyValue(currMediaBudget, currency),
      currCycleExtraLabel: '',
      nextCycleValue: safeExtractCurrencyValue(nextMediaBudget, currency),
      nextCycleExtraLabel: '',
      customClasses: {
        row: classes.extendedRow,
      },
    },
  ];

  const currMediaBudgetDivision = showActual
    ? currentCycle?.actualMediaSpendDivision
    : currentCycle?.estimatedMediaBudgetDivision;
  const currMediaBudgetDivisionHash = keyBy(currMediaBudgetDivision, 'skillType');

  const nextMediaBudgetDivision = showActual
    ? nextCycle?.actualMediaSpendDivision
    : nextCycle?.estimatedMediaBudgetDivision;
  const nextMediaBudgetDivisionHash = keyBy(nextMediaBudgetDivision, 'skillType');

  const jointMediaTypes = Array.from(
    new Set([...Object.keys(currMediaBudgetDivisionHash), ...Object.keys(nextMediaBudgetDivisionHash)]),
  );

  const mediaSpend = jointMediaTypes.map((skillType) => {
    const label = MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS[skillType] || skillType;
    const currValue = showActual
      ? currMediaBudgetDivisionHash[skillType]?.spendForSkillType
      : currMediaBudgetDivisionHash[skillType]?.budgetForSkillType;
    const nextValue = showActual
      ? nextMediaBudgetDivisionHash[skillType]?.spendForSkillType
      : nextMediaBudgetDivisionHash[skillType]?.budgetForSkillType;

    return {
      label,
      currCycleValue: safeExtractCurrencyValue(currValue, currency),
      currCycleExtraLabel: '',
      nextCycleValue: safeExtractCurrencyValue(nextValue, currency),
      nextCycleExtraLabel: '',
      customClasses: {
        cellLabel: classNames(classes.showBullet, classes.smallerFont),
        cellCurrExtra: classes.smallerFont,
        cellCurrValue: classes.smallerFont,
        cellNextValue: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
        customClasses: {
          row: classes.extendedRow,
        },
      },
    };
  });

  return [...mediaBudget, ...mediaSpend];
};

export const getPPCDiscount = (currentCycle, nextCycle, classes) => {
  const currBillingConfiguration = currentCycle?.billingConfiguration;
  const nextBillingConfiguration = nextCycle?.billingConfiguration;

  const currPPCDiscount = (currBillingConfiguration?.PPCDiscount || 0) / 100;
  const nextPPCDiscount = (nextBillingConfiguration?.PPCDiscount || 0) / 100;

  return [
    {
      label: 'PPC Discount',
      currCycleValue: formatPercentageString(currPPCDiscount),
      currCycleExtraLabel: isRecurring(currBillingConfiguration?.PPCDiscountRecurring),
      nextCycleValue: nextCycle ? formatPercentageString(nextPPCDiscount) : '',
      nextCycleExtraLabel: nextCycle ? isRecurring(nextBillingConfiguration?.PPCDiscountRecurring) : '',
      customClasses: {
        cellCurrExtra: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
        row: classes.lastRowInGroup,
      },
    },
  ];
};

export const getPpcBillingPlan = (currentCycle, nextCycle, currency, classes) => {
  const currBillingConfiguration = currentCycle?.billingConfiguration;
  const nextBillingConfiguration = nextCycle?.billingConfiguration;
  const currPlanName = currBillingConfiguration?.planName || 'N/A';
  const nextPlanName = nextBillingConfiguration?.planName || 'N/A';
  const currBillingConfig = getBillingModelTerms(currBillingConfiguration, resolveCurrencySymbol(currency), true);
  const nextBillingConfig = getBillingModelTerms(nextBillingConfiguration, resolveCurrencySymbol(currency), true);

  return [
    {
      label: 'PPC Billing model',
      currCycleValue: currPlanName,
      currCycleExtraLabel: '',
      nextCycleValue: nextCycle ? nextPlanName : '',
      nextCycleExtraLabel: '',
      customClasses: {
        row: classes.extendedRow,
      },
    },
    {
      label: 'PPC Billing config',
      currCycleValue: currBillingConfig?.billingModelValue,
      currCycleExtraLabel: currBillingConfig?.billingModelName,
      nextCycleValue: nextCycle ? nextBillingConfig?.billingModelValue : '',
      nextCycleExtraLabel: nextCycle ? nextBillingConfig?.billingModelName : '',
      customClasses: {
        cellCurrExtra: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
        row: classes.extendedRow,
      },
    },
  ];
};

export const getPpcServicesFee = (
  currentCycle,
  nextCycle,
  currency,
  classes,
  showActual = false,
  withCyclePhase = false,
) => {
  const currPpcServicesFee = showActual ? currentCycle?.actualFee : currentCycle?.estimatedFee;
  const nextPpcServicesFee = showActual ? nextCycle?.actualFee : nextCycle?.estimatedFee;

  const cyclePhase = withCyclePhase ? currentCycle?.phase : '';
  const mediaBudgetAndSpend = getMediaBudgetAndSpend(
    currentCycle,
    nextCycle,
    currency,
    classes,
    showActual,
    withCyclePhase,
  );

  const ppcDiscounts = getPPCDiscount(currentCycle, nextCycle, classes);
  const ppcBillingPlan = getPpcBillingPlan(currentCycle, nextCycle, currency, classes);

  return [
    {
      label: getLabelByCycleStatus('PPC Services fee', showActual, cyclePhase, true),
      currCycleValue: safeExtractCurrencyValue(currPpcServicesFee, currency),
      currCycleExtraLabel: isRecurring(true),
      nextCycleValue: safeExtractCurrencyValue(nextPpcServicesFee, currency),
      nextCycleExtraLabel: nextCycle ? isRecurring(true) : '',
      extendedData: [...mediaBudgetAndSpend, ...ppcDiscounts, ...ppcBillingPlan],
    },
  ];
};

// ------------
// Deliverables
// ------------

export const getDeliverableName = (deliverable) => deliverable.unitType;
export const getDeliverableTooltip = (deliverable) => deliverable.unitDescription.contents;
// eslint-disable-next-line arrow-body-style
export const getDeliverableCost = (deliverable) => {
  return Number(
    deliverable.unitCost *
      deliverable.unitAmount *
      Number(((100.0 - deliverable.discountPercentage) / 100.0).toFixed(4)),
  ).toFixed(2);
};
export const getDeliverableFulfillmentBehaviorLabel = (deliverable) => {
  let label = deliverablesFulfillmentBehaviorLookup[deliverable.fulfillmentBehavior];
  if (!label) {
    // Reasonable fallback
    label = deliverable.fulfillmentBehavior.toString();
  }
  return label;
};
// eslint-disable-next-line arrow-body-style
export const getDeliverableCostExplanationTooltip = (deliverable, currency) => {
  return (
    `${deliverable.unitAmount} units, ${safeExtractCurrencyValue(deliverable.unitCost, currency)} per unit, ` +
    `%${deliverable.discountPercentage} discount`
  );
};

export const getDeliverableDetails = (cycle, isCurrentCycle, currency, classes, showActual = false) => {
  let cycleDeliverables;

  if (!showActual) {
    cycleDeliverables = cycle?.estimatedDeliverables;
  } else {
    cycleDeliverables = cycle?.actualDeliverables;
  }

  if (!cycleDeliverables) {
    return [];
  }

  let deliverablesData;
  if (isCurrentCycle) {
    deliverablesData = cycleDeliverables.map((currentDeliverable) => ({
      label: getDeliverableName(currentDeliverable),
      tooltip: getDeliverableTooltip(currentDeliverable),
      currCycleValue: safeExtractCurrencyValue(getDeliverableCost(currentDeliverable), currency),
      currCycleValueTooltip: getDeliverableCostExplanationTooltip(currentDeliverable, currency),
      currCycleExtraLabel: getDeliverableFulfillmentBehaviorLabel(currentDeliverable),
      customClasses: {
        row: classes.extendedRow,
        cellLabel: classNames(classes.showBullet, classes.smallerFont),
        cellCurrExtra: classes.smallerFont,
        cellCurrValue: classes.smallerFont,
        cellNextValue: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
      },
    }));
  } else {
    // Ugh this is ugly code repetition - improve it when you have more time
    deliverablesData = cycleDeliverables.map((currentDeliverable) => ({
      label: getDeliverableName(currentDeliverable),
      tooltip: getDeliverableTooltip(currentDeliverable),
      nextCycleValue: safeExtractCurrencyValue(getDeliverableCost(currentDeliverable), currency),
      nextCycleValueTooltip: getDeliverableCostExplanationTooltip(currentDeliverable, currency),
      nextCycleExtraLabel: getDeliverableFulfillmentBehaviorLabel(currentDeliverable),
      customClasses: {
        row: classes.extendedRow,
        cellLabel: classNames(classes.showBullet, classes.smallerFont),
        cellCurrExtra: classes.smallerFont,
        cellCurrValue: classes.smallerFont,
        cellNextValue: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
      },
    }));
  }

  return deliverablesData || [];
};

export const getDeliverablesDetails = (
  currentCycle,
  nextCycle,
  currency,
  classes,
  showActual = false,
  withCyclePhase = false,
) => {
  const currDeliverablesFee = showActual
    ? currentCycle?.actualDeliverablesCost
    : currentCycle?.estimatedDeliverablesCost;
  const nextDeliverablesFee = showActual ? nextCycle?.actualDeliverablesCost : nextCycle?.estimatedDeliverablesCost;

  const cyclePhase = withCyclePhase ? currentCycle?.phase : '';
  const currentCycleDeliverableDetails = getDeliverableDetails(currentCycle, true, currency, classes, showActual);
  const nextCycleDeliverableDetails = getDeliverableDetails(nextCycle, false, currency, classes, showActual);

  //     label, // string to be used in the first column to describe the row data
  //     tooltip, // extra string data displayed when hovering the label
  //     currCycleValue, // string value displaying the data from the currentCycle
  //     currCycleValueTooltip, // extra string data displayed when hovering the curr value
  //     currCycleExtraLabel, // string value used to elaborate on the value of currentCycle
  //     nextCycleValue, // string value displaying the data from the nextCycle
  //     nextCycleValueTooltip, // extra string data displayed when hovering the curr value
  //     nextCycleExtraLabel, // string value used to elaborate on the value of nextCycle
  //     customClasses = defaultClasses, // Set of classes with a predefined structure to decorate the row and labels
  //     extendedData = [], // hold same structure of rowData, representing lines that their display depends on parent line
  return [
    {
      label: getLabelByCycleStatus('Deliverables fee', showActual, cyclePhase, true),
      tooltip:
        'Deliverables are handled separately on each cycle. If a certain amount of deliverables are not ' +
        'delivered, and based on the fulfillment behavior, then when the cycle ends - some deliverables may ' +
        'pass to the next cycle. However, they will not appear here right now, only when the cycle ends. Any ' +
        'deliverables that appear in the next cycle - will only start in the next cycle.',
      currCycleValue: safeExtractCurrencyValue(currDeliverablesFee, currency),
      nextCycleValue: safeExtractCurrencyValue(nextDeliverablesFee, currency),
      extendedData: [...currentCycleDeliverableDetails, ...nextCycleDeliverableDetails],
    },
  ];
};

// -------------------
// Additional Services
// -------------------

export const getAdditionalServicesData = (currentCycle, nextCycle, classes) => {
  const currAdditionalServices = currentCycle?.additionalServices;
  const nextAdditionalServices = nextCycle?.additionalServices;

  const currAdditionalServicesHash = keyBy(currAdditionalServices, 'uuid');
  const nextAdditionalServicesHash = keyBy(nextAdditionalServices, 'uuid');

  const jointHashesKeys = Array.from(
    new Set([...Object.keys(currAdditionalServicesHash), ...Object.keys(nextAdditionalServicesHash)]),
  );

  const additionalServicesData = jointHashesKeys.map((uuid) => {
    const currCycleService = currAdditionalServicesHash[uuid];
    const nextCycleService = nextAdditionalServicesHash[uuid];

    const label = getAdditionalServiceName(currCycleService || nextCycleService);

    const tooltip = 'Price before discount and pro-ration';
    return {
      label,
      currCycleValue: safeExtractCurrencyValue(currCycleService?.cost, currCycleService?.currency),
      currCycleValueTooltip: currCycleService?.cost ? tooltip : '',
      currCycleExtraLabel: isRecurring(currCycleService?.recurring),
      nextCycleValue: safeExtractCurrencyValue(nextCycleService?.cost, nextCycleService?.currency),
      nextCycleValueTooltip: nextCycleService?.cost ? tooltip : '',
      nextCycleExtraLabel: nextCycle ? isRecurring(nextCycleService?.recurring) : '',
      customClasses: {
        row: classes.extendedRow,
        cellLabel: classNames(classes.showBullet, classes.smallerFont),
        cellCurrExtra: classes.smallerFont,
        cellCurrValue: classes.smallerFont,
        cellNextValue: classes.smallerFont,
        cellNextExtra: classes.smallerFont,
      },
    };
  });

  return additionalServicesData || [];
};

export const getNonPPCDiscounts = (currentCycle, nextCycle, classes) => {
  const currBillingConfiguration = currentCycle?.billingConfiguration;
  const nextBillingConfiguration = nextCycle?.billingConfiguration;

  const currNonPPCDiscount = (currBillingConfiguration?.nonPPCDiscount || 0) / 100;
  const nextNonPPCDiscount = (nextBillingConfiguration?.nonPPCDiscount || 0) / 100;

  return [
    {
      label: 'Non-PPC Discount',
      currCycleValue: formatPercentageString(currNonPPCDiscount),
      currCycleExtraLabel: isRecurring(currBillingConfiguration?.nonPPCDiscountRecurring),
      nextCycleValue: nextCycle ? formatPercentageString(nextNonPPCDiscount) : '',
      nextCycleExtraLabel: nextCycle ? isRecurring(nextBillingConfiguration?.nonPPCDiscountRecurring) : '',
      customClasses: {
        row: classes.extendedRow,
      },
    },
  ];
};

export const getNonPPCServicesCost = (currentCycle, nextCycle, currency, classes) => {
  const currAdditionalServicesCost = currentCycle?.additionalServicesCost;
  const nextAdditionalServicesCost = nextCycle?.additionalServicesCost;

  const additionalServicesData = getAdditionalServicesData(currentCycle, nextCycle, classes);
  const nonPPCDiscounts = getNonPPCDiscounts(currentCycle, nextCycle, classes);

  return [
    {
      label: 'Non-PPC Services fee',
      currCycleValue: safeExtractCurrencyValue(currAdditionalServicesCost, currency),
      currCycleExtraLabel: '',
      nextCycleValue: safeExtractCurrencyValue(nextAdditionalServicesCost, currency),
      nextCycleExtraLabel: '',
      extendedData: [...additionalServicesData, ...nonPPCDiscounts],
    },
  ];
};

export const getCutsSplit = (currentCycle, nextCycle, currency, classes, showActual = false) => {
  const currMarketerFeeConfiguration = currentCycle?.marketerFeeConfiguration;
  const nextMarketerFeeConfiguration = nextCycle?.marketerFeeConfiguration;

  const currMarketerFee = showActual ? currentCycle?.actualMarketerFee : currentCycle?.estimatedMarketerFee;
  const nextMarketerFee = showActual ? nextCycle?.actualMarketerFee : nextCycle?.estimatedMarketerFee;

  const currMaypleFee = showActual ? currentCycle?.actualMaypleFee : currentCycle?.estimatedMaypleFee;
  const nextMaypleFee = showActual ? nextCycle?.actualMaypleFee : nextCycle?.estimatedMaypleFee;

  return [
    {
      label: 'Marketer fee cut %',
      currCycleValue: formatPercentageString(currMarketerFeeConfiguration?.feePartPercentage),
      currCycleExtraLabel: '',
      nextCycleValue: nextCycle ? formatPercentageString(nextMarketerFeeConfiguration?.feePartPercentage) : '',
      nextCycleExtraLabel: '',
    },
    {
      label: "Mayple's fee cut %",
      currCycleValue: currMarketerFeeConfiguration?.feePartPercentage
        ? formatPercentageString(1 - currMarketerFeeConfiguration.feePartPercentage)
        : '',
      currCycleExtraLabel: '',
      nextCycleValue:
        nextCycle && nextMarketerFeeConfiguration?.feePartPercentage
          ? formatPercentageString(1 - nextMarketerFeeConfiguration.feePartPercentage)
          : '',
      nextCycleExtraLabel: '',
    },
    {
      label: getLabelByCycleStatus("Marketer's cut", showActual, '', true),
      currCycleValue: safeExtractCurrencyValue(currMarketerFee, currency),
      currCycleExtraLabel: '',
      nextCycleValue: nextCycle ? safeExtractCurrencyValue(nextMarketerFee, currency) : '',
      nextCycleExtraLabel: '',
    },
    {
      label: getLabelByCycleStatus("Mayple's cut", showActual, '', true),
      currCycleValue: safeExtractCurrencyValue(currMaypleFee, currency),
      currCycleExtraLabel: '',
      nextCycleValue: nextCycle ? safeExtractCurrencyValue(nextMaypleFee, currency) : '',
      nextCycleExtraLabel: '',
      customClasses: {
        row: classes.lastRowInGroup,
      },
    },
  ];
};

/**
 * Returns data for Cycle Status row
 * @param currentCycle
 * @param nextCycle
 * @param classes
 * @returns {[{nextCycleValue: *, customClasses: {cellNextValue: *, cellCurrValue: *}, nextCycleExtraLabel: string,
 *   label: string, currCycleExtraLabel: string, currCycleValue: (string)}]}
 */
export const getCycleStatus = (currentCycle, nextCycle, classes) => [
  {
    label: 'Cycle Status',
    currCycleValue: currentCycle?.phase,
    currCycleExtraLabel: '',
    nextCycleValue: nextCycle ? nextCycle?.phase : '',
    nextCycleExtraLabel: '',
    customClasses: {
      cellCurrValue: getClassByCyclePhase(currentCycle?.phase, classes),
      cellNextValue: getClassByCyclePhase(nextCycle?.phase, classes),
    },
  },
];

/**
 * Returns data for Total cost row
 *
 * @param currentCycle
 * @param nextCycle
 * @param currency
 * @param showActual
 * @param classes
 * @param withCyclePhase
 * @returns {[{nextCycleValue: string, customClasses: {cellNextExtra: *, cellLabel: *, row: string, cellNextValue: *,
 *   cellCurrValue: *}, nextCycleExtraLabel: string, label: string, currCycleExtraLabel: string, currCycleValue:
 *   string}]}
 */
export const getTotalCost = (
  currentCycle,
  nextCycle,
  currency,
  classes,
  showActual = false,
  withCyclePhase = false,
) => {
  const currTotalCost = showActual ? currentCycle?.actualTotalCost : currentCycle?.estimatedTotalCost;
  const nextTotalCost = showActual ? nextCycle?.actualTotalCost : nextCycle?.estimatedTotalCost;

  const cyclePhase = withCyclePhase ? currentCycle?.phase : '';

  const label = getLabelByCycleStatus('Total Cost', showActual, cyclePhase, true);

  return [
    {
      label,
      currCycleValue: safeExtractCurrencyValue(currTotalCost, currency),
      currCycleExtraLabel: '',
      nextCycleValue: safeExtractCurrencyValue(nextTotalCost, currency),
      nextCycleExtraLabel: '',
      customClasses: {
        cellLabel: classes.bold,
        cellCurrValue: classes.bold,
        cellNextValue: classes.bold,
        cellNextExtra: classes.bold,
        row: classNames(classes.totalRow, classes.lastRowInGroup),
      },
    },
  ];
};

export const getFundAccountBalance = (fundAccount = {}, classes = {}) => {
  const { balance = 0, currency = 'USD' } = fundAccount || {};

  return [
    {
      label: 'Already Paid',
      currCycleValue: safeExtractCurrencyValue(balance, currency),
      currCycleExtraLabel: '',
      nextCycleValue: '--',
      nextCycleExtraLabel: '',
      customClasses: {
        row: classNames(classes.lastRowInGroup),
      },
    },
  ];
};
