import { CurrencyType, KpiType, ProjectCreateInput, ProjectSpecialSubType, ProjectSpecialType } from '@mayple/types';

import { ProjectCreateFormValues } from './types';
import { DEFAULT_HEALTH_CHECK_REQUESTED_SERVICES, DEFAULT_MEDIA_BUDGET, DEFAULT_REQUESTED_SERVICES } from './consts';

const healthCheckProjectDefaults = {
  primaryObjectives: {
    targetKPI: KpiType.ROAS,
  },
};

export const getProjectName = (requestedProjectSpecialSubType: ProjectSpecialSubType, companyName?: string): string => {
  if (requestedProjectSpecialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK) {
    return 'Mailchimp Health Check';
  }
  if (requestedProjectSpecialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2) {
    return 'New Mailchimp Health Check';
  }
  return `${companyName || 'New'} Project`;
};

const mapProjectCreateFormValuesToProjectCreateInput = (formValues: ProjectCreateFormValues): ProjectCreateInput => {
  // console.log('formValues', formValues);
  const {
    projectName,
    locations,
    industry,
    productBusinessModel,
    targetKPI,
    requestedProjectSpecialType,
    requestedProjectSpecialSubType,
  } = formValues;

  const projectCreateInput: ProjectCreateInput = {
    targetUrls: [],
    locations: locations.map(({ value }) => value),
    industry: {
      industryCategory: industry?.industry,
      industrySubCategory: industry?.value,
    },
    projectDefinition: {
      primaryObjectives: {
        targetKPI: targetKPI?.value,
      },
    },
    languages: [],
    currency: CurrencyType.USD,
    estimatedMediaBudget: DEFAULT_MEDIA_BUDGET,
    worldwideAudienceTargetting: false,
    productBusinessModel: productBusinessModel.map(({ value }) => value),
    preferLocalMarketers: true,
    preferMarketerInTargettedLocations: false,
    requestedServices:
      requestedProjectSpecialType === ProjectSpecialType.MAILCHIMP_AUDIT_TYPE ||
      requestedProjectSpecialType === ProjectSpecialType.OUTBRAIN_AUDIT_TYPE
        ? DEFAULT_HEALTH_CHECK_REQUESTED_SERVICES
        : DEFAULT_REQUESTED_SERVICES,
    requestedProjectSpecialType,
    requestedProjectSpecialSubType,
  } as unknown as ProjectCreateInput;

  if (projectName && typeof projectName === 'string' && projectName.trim().length > 0) {
    projectCreateInput.overriddenName = projectName.trim();
    projectCreateInput.useOverriddenName = true;
  }

  if (
    requestedProjectSpecialType === ProjectSpecialType.MAILCHIMP_AUDIT_TYPE ||
    requestedProjectSpecialType === ProjectSpecialType.OUTBRAIN_AUDIT_TYPE
  ) {
    projectCreateInput.projectDefinition = healthCheckProjectDefaults;
    projectCreateInput.estimatedMediaBudget = 0;
  }

  return projectCreateInput;
};

export default mapProjectCreateFormValuesToProjectCreateInput;
