import { useCallback } from 'react';
import { ApolloError } from 'apollo-client';
import { MarkProjectSponsorPaymentsAsInvoicedMutation } from 'growl-graphql/dist/mutations/sponsor/MarkProjectSponsorPaymentsAsInvoicedMutation';

import { useMutation } from '../../../fe_common/client/hooks';
import { handleClientError } from '../../../fe_common/client/services/logger';

export type MarkProjectSponsorPaymentsAsInvoicedMutationExecuter = {
  markProjectSponsorPaymentsAsInvoiced: (idList: number[]) => Promise<void>;
  saving: boolean;
  error: ApolloError | undefined;
};

const useMarkProjectSponsorPaymentsAsInvoicedMutation = (
  onSuccess?: () => void,
): MarkProjectSponsorPaymentsAsInvoicedMutationExecuter => {
  const { mutate, loading, error } = useMutation(MarkProjectSponsorPaymentsAsInvoicedMutation);

  const markProjectSponsorPaymentsAsInvoiced = useCallback(
    async (idList: number[]) => {
      try {
        const variables = { idList };
        const result = await mutate({ variables });

        if (result?.data?.markProjectSponsorPaymentsAsInvoiced?.success) {
          onSuccess?.();
        }
      } catch (e) {
        handleClientError(e, 'Error while trying to mark project sponsor payments as invoiced.');
      }
    },
    [mutate, onSuccess],
  );

  return {
    markProjectSponsorPaymentsAsInvoiced,
    saving: loading,
    error,
  };
};

export default useMarkProjectSponsorPaymentsAsInvoicedMutation;
