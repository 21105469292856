/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import classNames from 'classnames/bind';
import Grid from '@material-ui/core/Grid';
import Check from '@material-ui/icons/CheckCircle';

import { TextDisplay } from '../../../display';
import { TextIcon } from '../../TextIcon';

import * as style from './style.css';
import { colors } from '../../../../app/theme';

const cx = classNames.bind(style);

const mobileIconStyle = {
  width: 32,
  height: 32,
  paddingTop: 4,
  marginRight: 16,
};
const iconStyleDefault = {
  width: '6vh',
  minWidth: 48,
  marginBottom: 20,
};
const upperIconStyle = {
  color: colors.primary,
  position: 'absolute',
  top: '8px',
  right: '8px',
};

const createSVGComponent = (Icon, isMobile, svgIconStyle) => {
  if (!Icon) {
    return null;
  }

  const finalStyle = isMobile ? mobileIconStyle : svgIconStyle;

  if (isFunction(Icon)) {
    return <Icon style={finalStyle} />;
  } else if (isString(Icon)) {
    return <img src={Icon} style={finalStyle} alt="" />;
  } else {
    return null;
  }
};

//* *CardWithIcon Explained:**
/* PROPS::
 *  label - card label.
 *  textOnHover - does text should appear over the card on Hover.
 *  svgIcon - adding an svg icon.
 *  fontIcon - adding a font icon.
 *  onClick - on click function.
 *  checked - does the card need to contain a 'checkmark' icon after 'seleced'.
 *  bigCard - makes the card bigger (with padding).
 *  darkBorder - add a dark border to the card.
 *  fontIconColor - sets the font icon color.
 *  minHeight - min height for the card.
 *  height - card const height.
 *  md - how many columns should the card take on an md window state.
 *  xs - how many columns should the card take on an xs window state.
 *  onMouseEnter - onMouseEnter function event.
 *  onMouseLeave - onMouseLeave function event.
 *  disabled - disables the card functionality.
 *  addIconPadding - add padding arround the icon.
 *  isMobile - isMobile boolean state indicator.
 *  textAddition - text addition of the card, gets 'node' as a type.
 *  floatLeft - icon on top, text on the bottom, icon floating left.
 *  svgIconStyle - sets the style for the svg icon.
 *  boldLabel - makes the label bold.
 *  leftCard - icon and text on the same line, icon on left and text on the right.
 *  cardPadding - the padding of the card.
 *  setLabelType - type of the card label (h1, h2, caption, etc...).
 * */
export function CardWithIcon(props) {
  const {
    label,
    textOnHover,
    svgIcon,
    fontIcon,
    onClick,
    checked,
    bigCard,
    darkBorder,
    fontIconColor,
    minHeight,
    height,
    md,
    xs,
    onMouseEnter,
    onMouseLeave,
    disabled,
    addIconPadding,
    isMobile,
    textAddition,
    floatLeft,
    svgIconStyle,
    boldLabel,
    leftCard,
    cardPadding,
    setLabelType,
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  // console.log('CardWithIcon props', props);
  const floatLeftPadding = floatLeft || leftCard ? '5%' : '';
  const containerClass = cx({
    checked,
    'card-container': true,
    'card-container-pointer': !disabled,
    'card-container-hover': !disabled,
    'card-container-active': !disabled,
    'card-container-disabled': disabled,
    'align-center': !textOnHover,
    'light-border': !darkBorder && !leftCard,
    'dark-border': darkBorder && !leftCard,
    'left-card': leftCard,
  });

  const iconExists = !!svgIcon || !!fontIcon;
  const isBigCard = !iconExists && bigCard;
  const shouldAddIconOnCheck = iconExists || bigCard;

  const cardIcon = cx({
    // 'card-icon':         iconExists, // add icon margin only if exists
    // 'card-icon-checked': checked, // add icon margin only if exists
    'card-icon-padding': addIconPadding, // add icon margin only if exists
    'card-icon-hidden': textOnHover && isHovering,
  });

  const cardContent = cx({
    // 'card-content':          true, // add icon margin only if exists - no need
    'card-content-hovering': isHovering, // add icon margin only if exists - no need
    'card-content-padding': isBigCard, // add icon margin only if exists
  });

  let labelType = isBigCard ? 'subtitle1' : 'body1';
  labelType = setLabelType !== null ? setLabelType : labelType;

  // in case Text Addition is true this style will take affect
  const textAdditionGridStyle = textAddition !== null ? { display: 'inline' } : {};

  let labelColor = colors.black_light;
  if (disabled) {
    labelColor = colors.black_lighter;
  } else if (isHovering && !checked) {
    labelColor = colors.primary;
  }

  if (isMobile) {
    return (
      <Grid
        item
        xs={12}
        role="button"
        onClick={disabled ? () => {} : onClick}
        tabIndex={disabled ? -1 : 0}
        style={{ padding: cardPadding }}
      >
        <div className={containerClass}>
          <Grid container justifyContent={iconExists ? 'flex-start' : 'center'}>
            {checked && textOnHover && <Check style={upperIconStyle} />}
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                {iconExists && (
                  <Grid item>
                    {svgIcon && createSVGComponent(svgIcon, isMobile, svgIconStyle)}
                    {fontIcon && !svgIcon && (
                      <TextIcon size={iconStyleDefault.minWidth} color={fontIconColor} icon={fontIcon} />
                    )}
                  </Grid>
                )}
                <Grid item style={{ marginTop: textOnHover ? -4 : 0 }}>
                  <TextDisplay variant="subtitle1" colorOverride={labelColor}>
                    {label}
                  </TextDisplay>
                </Grid>
              </Grid>
            </Grid>
            {textOnHover && (
              <Grid item style={{ paddingLeft: 48, marginTop: -4 }}>
                <TextDisplay variant="caption" colorOverride={colors.black_light}>
                  {textOnHover}
                </TextDisplay>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
  }

  return (
    <Grid item xs={xs} md={md} style={textAdditionGridStyle}>
      <div
        className={containerClass}
        style={{ minHeight, height, padding: cardPadding }}
        role="button"
        onClick={disabled ? () => {} : onClick}
        onKeyUp={disabled ? () => {} : onClick}
        tabIndex={disabled ? -1 : 0}
        onMouseEnter={() => {
          setIsHovering(true);
          onMouseEnter();
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          onMouseLeave();
        }}
      >
        <Grid container direction="row">
          {leftCard && (
            <Grid item className={cardIcon} xs={3}>
              {svgIcon && !textOnHover && createSVGComponent(svgIcon, false, svgIconStyle)}
              {fontIcon && !svgIcon && (
                <TextIcon size={iconStyleDefault.minWidth} color={fontIconColor} icon={fontIcon} />
              )}
            </Grid>
          )}
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            alignItems={floatLeft || leftCard ? 'flex-start' : 'center'}
            style={{ paddingLeft: floatLeftPadding }}
            xs={leftCard ? 9 : null}
          >
            {!leftCard && (
              <Grid item className={cardIcon}>
                {checked && shouldAddIconOnCheck && <Check style={upperIconStyle} />}
                {
                  /* (svgIcon && !textOnHover) && createSVGComponent(svgIcon, false, svgIconStyle) */
                  svgIcon && createSVGComponent(svgIcon, false, svgIconStyle)
                }
                {fontIcon && !svgIcon && (
                  <TextIcon size={iconStyleDefault.minWidth} color={fontIconColor} icon={fontIcon} />
                )}
              </Grid>
            )}
            <Grid item className={cardContent}>
              <TextDisplay variant={labelType} colorOverride={labelColor} bold={boldLabel}>
                {label}
              </TextDisplay>
              {textOnHover && (
                <TextDisplay variant="caption" style={{ display: isHovering ? 'block' : 'none' }}>
                  {textOnHover}
                </TextDisplay>
              )}
            </Grid>
            {textAddition && (
              <Grid item style={{ marginTop: '2%' }}>
                {textAddition}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

CardWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  xs: PropTypes.number,
  fontIcon: PropTypes.string,
  fontIconColor: PropTypes.string,
  textOnHover: PropTypes.string,
  svgIconStyle: PropTypes.object,
  minHeight: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  checked: PropTypes.bool,
  bigCard: PropTypes.bool,
  darkBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  addIconPadding: PropTypes.bool,
  isMobile: PropTypes.bool,
  svgIcon: PropTypes.any,
  textAddition: PropTypes.node,
  floatLeft: PropTypes.bool,
  boldLabel: PropTypes.bool,
  leftCard: PropTypes.bool,
  cardPadding: PropTypes.string,
  setLabelType: PropTypes.string,
};

CardWithIcon.defaultProps = {
  md: undefined,
  xs: 6,
  checked: false,
  bigCard: false,
  darkBorder: false,
  disabled: false,
  addIconPadding: false,
  isMobile: false,
  svgIcon: null,
  minHeight: 0,
  height: undefined,
  fontIcon: '',
  fontIconColor: colors.cherry,
  textOnHover: '',
  textAddition: null,
  floatLeft: false,
  boldLabel: false,
  leftCard: false,
  cardPadding: '',
  setLabelType: null,
  svgIconStyle: iconStyleDefault,
  onClick: () => {},
  onMouseEnter: () => {}, // Should this be null as default ?
  onMouseLeave: () => {},
};
