import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';

import { getSteps, useMarketingPlanStepper } from './useMarketingPlanStepper';
import { useMarketingPlanState } from '../../MarketingPlanProvider';
import { TRANSLATION_NS } from '../consts';
import { Button } from '../../../inputs';

import useStyles from './style';

const MarketingPlanStepper: React.FC = () => {
  const classes = useStyles();

  const { marketingPlanStatus } = useMarketingPlanState();
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper' });
  const steps = useMemo(() => getSteps(t, marketingPlanStatus), [t, marketingPlanStatus]);

  const { activeStep, handleNext, handleBack } = useMarketingPlanStepper(marketingPlanStatus);

  return (
    <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
      {steps.map(({ label, component: Component, completed }) => (
        <Step key={label} completed={completed}>
          <StepLabel>{label}</StepLabel>
          <StepContent>
            <div>
              <Component />
            </div>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                  color="default"
                  label={t('back')}
                  variant="text"
                />
                {activeStep < steps.length - 1 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    label={activeStep === steps.length - 1 ? t('finish') : t('next')}
                  />
                )}
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default MarketingPlanStepper;
