import React, { FC } from 'react';
import { User } from '@mayple/types';
import UserConnectedEntities from '../components/UserConnectedEntities';

interface UserEntitiesTabProps {
  user: User;
  onChange?: () => void;
}

const UserEntitiesTab: FC<UserEntitiesTabProps> = (props) => {
  const {
    user,
    onChange = () => {
      /* Do nothing */
    },
  } = props;
  return <UserConnectedEntities user={user} onChange={onChange} />;
};

export default UserEntitiesTab;
