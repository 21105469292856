import React, { FC } from 'react';
import { ProjectStage } from '@mayple/types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';

import { ProjectFlowConfigurationProps } from './types';
import useFlowConfigurationData from './logic';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { RefreshSpinner } from '../../../../../fe_common/client/components/atoms';
import { asBool } from '../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { stageTitle } from '../../../../../fe_common/client/logic/projectStage';

import useStyles from './style';

const ProjectFlowConfiguration: FC<ProjectFlowConfigurationProps> = (props) => {
  const { project } = props;
  const { switches, onSwitchChange, loading } = useFlowConfigurationData(project);
  const classes = useStyles();
  let lastCategory = '';
  let showCategoryTitle = false;

  return (
    <div>
      <Section
        title="Project Flow Configuration"
        subTitle="Configure various flows behaviours in one place"
        noBorder
        noTopMargin
      >
        <SectionContent>
          <div className={classes.root}>
            <Backdrop open={loading} className={classes.backdrop}>
              <RefreshSpinner size={32} />
            </Backdrop>
            <FormControl component="fieldset">
              <FormGroup>
                {switches.map((currentSwitch, index) => {
                  const { category, value, label, key, editable, tooltip, hidden } = currentSwitch;
                  if (lastCategory !== category) {
                    lastCategory = category;
                    showCategoryTitle = true;
                  } else {
                    showCategoryTitle = false;
                  }

                  if (hidden) {
                    return null;
                  }

                  return (
                    <React.Fragment key={`ProjectFlowConfiguration-switch-${index}`}>
                      {showCategoryTitle && (
                        <TextDisplay className={classes.category}>{stageTitle[category as ProjectStage]}</TextDisplay>
                      )}
                      <Tooltip title={tooltip} placement="top-end">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={asBool(value)}
                              onChange={onSwitchChange}
                              name={key}
                              disabled={!editable}
                              color="primary"
                            />
                          }
                          // labelPlacement="start"
                          label={label}
                        />
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
              </FormGroup>
              <FormHelperText>Each option can be changed only in the relevant stage.</FormHelperText>
            </FormControl>
          </div>
        </SectionContent>
      </Section>
    </div>
  );
};

export default ProjectFlowConfiguration;
