import React from 'react';
import { AdminAccessibleProjectLeadsQuery } from 'growl-graphql/dist/queries/AdminAccessibleProjectLeadsQuery';

import ProjectLeadsTable from './ProjectLeadsTable';
import useQueryPagination from '../../../fe_common/client/hooks/useQueryPagination';

const ProjectLeadsPage = () => {
  const { data, loading, refetch } = useQueryPagination(AdminAccessibleProjectLeadsQuery, 'projectLeads', {
    pageSize: 1500,
    maxResults: 3000,
    uniqueKey: 'id',
    filter: { by: [{ key: 'isRejected', value: 'false' }] },
  });

  return <ProjectLeadsTable data={data} loading={loading} refetch={refetch} />;
};

export default ProjectLeadsPage;
