import React, { FC } from 'react';
import { change } from 'redux-form';
import ReactFilestack from 'filestack-react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';
import { FILE_STACK_DEFAULT_SOURCES } from '../../../../app/consts';
import { clientLogger, handleClientError } from '../../../../services/logger';
import SimpleIcon from '../../SimpleIcon';

import useStyles from './style';
import { WizardFileUploaderInputProps } from './types';

const defaultAcceptedMimeTypes: string[] = [
  'image/*',
  '.pdf',
  '.docx',
  'video/*',
  'text/plain',
  'text/csv',
  'application/vnd.ms*',
  'application/msword',
  'application/vnd.openxmlformats*',
  'application/vnd.oasis.opendocument*',
  'application/postscript',
  'application/rtf',
  'application/zip',
  'application/x-rar-compressed',
];

const DEFAULT_MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const WizardFileUploaderInput: FC<WizardFileUploaderInputProps> = (props) => {
  // console.log('WizardFileUploaderInput props', props);
  const classes = useStyles(props);

  const {
    apikey,
    name = '',
    form = '',
    icon = null,
    dispatch,
    accept = defaultAcceptedMimeTypes,
    tooltipText = 'Upload File / Select File from Cloud',
    optionalText = '',
    maxSize = DEFAULT_MAX_FILE_SIZE,
    buttonProps = {},
    onSuccess,
    hideMaxFileSize = false,
  } = props;

  return (
    <div className={classes.root}>
      <ReactFilestack
        apikey={apikey}
        options={{
          accept,
          fromSources: FILE_STACK_DEFAULT_SOURCES,
          lang: 'en',
          maxFiles: 1,
          minFiles: 1,
          maxSize,
        }}
        onSuccess={(result: any) => {
          clientLogger.debug(`Filepicker upload response:\n\n${JSON.stringify(result, null, 2)}`);
          if (form && dispatch && name) {
            change(form, name, result.filesUploaded[0]);
          }
          onSuccess?.(result.filesUploaded[0]);
        }}
        onError={(err: any) => {
          handleClientError(err, 'Failed uploading file to Filestack.');
        }}
        render={({ onPick }: { onPick: () => void }) => (
          <div>
            <Tooltip title={tooltipText} placement="top">
              <span>
                <Button
                  startIcon={<SimpleIcon icon={icon} />}
                  label={optionalText}
                  onClick={onPick}
                  className={classes.fileUploadButton}
                  {...buttonProps}
                />
              </span>
            </Tooltip>
            {!hideMaxFileSize && (
              <TextDisplay variant="caption" className={classes.maxFileSizeLabel}>
                Max {Math.round(maxSize / 1000000)} MB.
              </TextDisplay>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default WizardFileUploaderInput;
