/* eslint-disable function-paren-newline */
import keyBy from 'lodash/keyBy';
import {
  Industry,
  IndustryCategory,
  IndustrySubCategory,
  MarketerFieldFilter,
  MarketerSearchDefinition,
  MarketingSkills,
  Participant,
  ProjectLifeCycleStatus,
  SuccessStory,
} from '@mayple/types';

import filterFieldsMapper from './consts';

const filterFieldsMapperByFieldName = keyBy(filterFieldsMapper, 'fieldName');

// TODO: Currently any, until I'll figure this one out
const getFilterValuesFromArray = (values: any) => {
  if (!values.length) {
    return null;
  }

  if (values.industry) {
    return values.map(({ industry, value }: { industry: IndustryCategory; value: IndustrySubCategory }) => ({
      industryCategory: industry,
      industrySubCategory: value,
    }));
  }

  return values.map(({ value }: { value: string }) => value);
};

function getFieldFilterValues(values: any) {
  // Array holding fieldFilters
  let fieldFilterValues;

  if (Array.isArray(values)) {
    // convert array values to fieldFilter
    fieldFilterValues = getFilterValuesFromArray(values);
  } else if (typeof values === 'object') {
    // Handling Currency fields
    if (values.value) {
      fieldFilterValues = [values.value.toString()];
    }
  } else if (values != null && values !== '') {
    // Handle all other primitive values
    fieldFilterValues = [values.toString()];
  }
  return fieldFilterValues;
}

const getFilterFieldNameFromKey = (key: string) => {
  if (typeof filterFieldsMapperByFieldName[key] !== 'undefined') {
    return filterFieldsMapperByFieldName[key]?.filterFieldName;
  }
  return key;
};

/**
 * Convert form values submitted to filter object used in query
 * @param formValues
 * @param excludedKeys
 * @returns {{filterValuesOperator: *, filtersList: []}}
 */
export const createFilterFromFormValues = (
  formValues: Record<string, any>,
  excludedKeys: string[] = [],
): MarketerSearchDefinition => {
  const { filtersGlobalOperator, filterValuesOperator } = formValues;
  const entries = Object.entries(formValues);

  const filtersList: MarketerFieldFilter[] = [];
  entries.forEach(([key, values]) => {
    // ignore excluded fields from formValues
    if (excludedKeys.includes(key)) {
      return;
    }

    const fieldName = getFilterFieldNameFromKey(key);
    const fieldFilterValues = getFieldFilterValues(values);

    // only push valid values to the array
    if (fieldFilterValues) {
      filtersList.push({
        fieldName,
        fieldFilterValues,
        filterValuesOperator,
      });
    }
  });

  const filterData = {
    filtersGlobalOperator,
    filtersList,
  };

  return filterData;
};

export const getMergedIndustryValuesFromArray = (
  industriesArray: Industry[],
): (IndustryCategory | IndustrySubCategory | null | undefined)[] => {
  const industryCategories = industriesArray.map(({ industryCategory }) => industryCategory);
  const industrySubCategories = industriesArray.map(({ industrySubCategory }) => industrySubCategory);
  const industriesArrayMerged = [
    ...new Set([...industryCategories.filter(Boolean), ...industrySubCategories.filter(Boolean)]),
  ];

  return industriesArrayMerged;
};

export const getSkillsUsedListFromSuccessStories = (
  successStories: SuccessStory[],
): Array<Array<MarketingSkills> | null | undefined> => {
  const successStoriesSkillsUsedMultipleLists = successStories.map((successStory) => successStory.skillsUsed);

  return [...new Set([...successStoriesSkillsUsedMultipleLists])];
};

/**
 *
 * @param participants
 * @param projectLifeCycleStatus - optional parameter.
 * @returns {*}
 */
export const countMarketerAssignedProjects = (
  participants: Participant[],
  projectLifeCycleStatus?: ProjectLifeCycleStatus,
): number => {
  const filteredParticipants = participants.filter((participant) => {
    // if there isn't a projectLifeCycleStatus filter, then just return the total number of all projects.
    if (!projectLifeCycleStatus) {
      return true;
      // Else if we filter by a specific life cycle status then check equality.
    }

    return participant.project.projectLifeCycleStatus === projectLifeCycleStatus;
  });

  return filteredParticipants.length;
};
