import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'relative',
    },
    backdrop: {
      position: 'absolute',
      zIndex: 111,
      background: colors.whiteOpacity(0.7),
    },
    category: {
      color: colors.black_light,
      marginTop: '1rem',
    },
  }),
);

export default useStyles;
