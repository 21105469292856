import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import { ButtonProps } from '../../../../../../fe_common/client/components/inputs/Button';
import { useDialog } from '../../../../../../fe_common/client/hooks';
import EditUserDetailsDialog from '../EditUserDetailsDialog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface EditUserDetailsButtonProps extends Omit<ButtonProps, 'label' | 'onClick' | 'color' | 'size' | 'variant'> {
  userId: number | null | undefined;
}

const EditUserDetailsButton: React.FC<EditUserDetailsButtonProps> = (props) => {
  const classes = useStyles();
  const { userId, ...rest } = props;
  const { open, openDialog, closeDialog } = useDialog(false);

  if (!userId) {
    return null;
  }

  const onEditUserDetailsClickHandler = () => {
    openDialog();
  };

  return (
    <div className={classes.root}>
      <Button
        label="Edit User Details"
        onClick={onEditUserDetailsClickHandler}
        color="primary"
        size="small"
        variant="outlined"
        {...rest}
      />

      <EditUserDetailsDialog isOpen={open} userId={userId} onClose={closeDialog} />
    </div>
  );
};

export default EditUserDetailsButton;
