import React, { FC } from 'react';
import { User } from '@mayple/types';

import UserRemoteController from '../components/UserRemoteController';

interface UserRemoteControllerTabProps {
  user: User;
  onChange?: () => void;
}

const UserRemoteControllerTab: FC<UserRemoteControllerTabProps> = (props) => {
  const { user, onChange } = props;

  return <UserRemoteController user={user} onChange={onChange} />;
};

export default UserRemoteControllerTab;
