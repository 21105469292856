import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { RadioGroupFieldProps } from './types';
import { RadioGroup } from '../../../inputs';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

const RadioGroupField: React.FC<RadioGroupFieldProps> = (props) => {
  const classes = useStyles(props);
  const { input, enums, meta, helperText, options, ...rest } = props;
  const { error, touched, invalid } = meta;

  const hasError = touched && invalid;
  const errorMessage = hasError && error ? error : undefined;

  return (
    <RadioGroup
      value={input?.value}
      onChange={input?.onChange}
      options={enums || options}
      error={hasError}
      helperText={errorMessage || helperText}
      classes={classes}
      {...rest}
    />
  );
};

export default RadioGroupField;
