import React from 'react';
import { FeedReaction } from '@mayple/types';

import { FeedPostReaction } from './types';
import { POST_REACTIONS } from '../../consts';

export const mapFeedReactionToFeedPostReaction = (
  reactions: FeedReaction[] | null,
  viewerPersonUuid: string
): FeedPostReaction[] => {
  if (!reactions || !Array.isArray(reactions)) {
    return [];
  }

  return reactions.reduce((acc: FeedPostReaction[], { reactionType, personUuid }) => {
    const index = acc.findIndex((item) => item.reactionType === reactionType);

    // First check if exists in array
    if (index > -1 && acc?.[index]) {
      acc[index].count = (acc[index].count || 1) + 1;
    } else {
      // If not, then create an entry
      const reaction = POST_REACTIONS.find((item) => item.reactionType === reactionType);

      acc.push({
        ...reaction,
        count: 1,
        didReact: personUuid === viewerPersonUuid,
      });
    }
    return acc;
  }, []);
};

const usePostReactions = (reactions: FeedReaction[], viewerPersonUuid: string): FeedPostReaction[] =>
  React.useMemo(() => {
    try {
      return mapFeedReactionToFeedPostReaction(reactions, viewerPersonUuid);
    } catch (e) {
      return [];
    }
  }, [reactions, viewerPersonUuid]);

export default usePostReactions;
