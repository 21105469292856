import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: { display: 'flex', flexWrap: 'wrap' },
    chipStyle: {
      margin: 4,
      background: getGradientColor('gray', 20),
      color: colors.black,
    },
    iconStyle: { marginLeft: 4, color: '#524a48' },
    avatarStyle: { padding: 4 },
  })
);

export default useStyles;
