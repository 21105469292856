const popupCenter = (url: string, target: string, w: number, h: number): void => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  // eslint-disable-next-line no-nested-ternary
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;

  // eslint-disable-next-line no-nested-ternary
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const features = `scrollbars=yes,location=off,width=${w / systemZoom},height=${
    h / systemZoom
  },top=${top},left=${left}`;
  const newWindow = window.open(url, target, features);

  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }
};

export default popupCenter;
