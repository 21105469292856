import React from 'react';

import { AssessmentDialogProps } from './types';
import AssessmentWizard from './components/AssessmentWizard';
import useMarketerAssessmentDialogData from './hooks/useMarketerAssessmentDialogData';

import { Dialog } from '../../display';
import { useDeviceInfo } from '../../../hooks';

import useStyles from './style';

const AssessmentDialog: React.FC<AssessmentDialogProps> = (props) => {
  const classes = useStyles(props);
  const { isOpen, subTasks, showThanksMessage = false, ...rest } = props;
  const { isMobile } = useDeviceInfo();

  const { onCloseDialogHandler, skipFormHandler, submitFormHandler, onAssessmentDoneClickHandler } =
    useMarketerAssessmentDialogData(props);

  return (
    <Dialog
      isOpen={isOpen}
      containerMaxWidth="xl"
      onClose={onCloseDialogHandler}
      fullScreen={isMobile}
      classes={{
        root: classes.root,
        dialogTitleRoot: classes.dialogTitleRoot,
        dialogContent: classes.dialogContent,
        dialogActions: classes.dialogActions,
      }}
      {...rest}
    >
      <AssessmentWizard
        {...{ subTasks, showThanksMessage, skipFormHandler, submitFormHandler, onAssessmentDoneClickHandler }}
      />
    </Dialog>
  );
};

export default AssessmentDialog;
