import React, { FC, useCallback } from 'react';
import { ProjectLifeCycleStatus } from '@mayple/types';
import AddIcon from '@material-ui/icons/Add';

import { ProjectServicesAndBillingTabProps } from './types';
import useProjectPackagesData from './useProjectPackagesData';
import useProjectServicesAndBillingTab from './useProjectServicesAndBillingTab';
import MonthlyCycles from './components/MonthlyCycles';
import ProjectPausingSection from './components/ProjectPausingSection';
import FundAccountsSection from './components/FundAccountsSection';
import FinalizedCyclesSection from './components/FinalizedCyclesSection';
import BillingBreakdownDocumentsSection from './components/BillingBreakdownDocumentsSection';
import ProjectStopSection from './components/ProjectStopSection';
import ProjectPackagesSelectionDialog from './components/ProjectPackagesSelectionDialog';
import ProjectElapsedCycleReview from '../../components/ProjectElapsedCycleReview';
import ShowByProjectStatus from '../../components/ShowByProjectStatus';
import ProjectCycleOperationMode from './components/ProjectCycleOperationMode';
import TriggerProjectIntermediateBilling from '../../components/TriggerProjectIntermediateBilling';
import ProjectBillingPointBehaviorEditor from '../../components/ProjectBillingPointBehaviorEditor';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import ProjectPackagesList from '../../../../../fe_common/client/components/package/ProjectPackagesList';
import { Button } from '../../../../../fe_common/client/components/inputs';

import useStyles from './style';
import ProjectBillingConfiguration from '../../components/ProjectBillingConfiguration';

const ProjectServicesAndBillingTab: FC<ProjectServicesAndBillingTabProps> = (props) => {
  const classes = useStyles();

  const { refetchProjectData, project, companyId } = props;

  const { id: projectId, projectLifeCycleStatus, currency } = project;

  const { addPackageDialogIsOpen, openAddPackageDialog, closeAddPackageDialog, onAddPackageHandler, packageKey } =
    useProjectPackagesData(projectId);

  const {
    cycles,
    isLive,
    isOnboarding,
    showMonthlyCycles,
    elapsedCycles,
    currentCycleFundAccount,
    setCurrentCycleFundAccount,
  } = useProjectServicesAndBillingTab(props);

  const onSuccessfulSaveHandler = useCallback(async () => refetchProjectData(), [refetchProjectData]);

  if (!cycles) {
    return <CalloutMessage type="error" message="Error getting project cycles data" />;
  }

  return (
    <div className={classes.root}>
      <ProjectCycleOperationMode projectId={projectId} />

      <Section
        id="projectPackages"
        title="Project Packages"
        noBorder
        gutterTopSize={1}
        headerAction={
          <Button
            color="primary"
            label="Add package"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={openAddPackageDialog}
            disabled={project?.projectLifeCycleStatus === ProjectLifeCycleStatus.FINISHED}
          />
        }
        classes={{ root: classes.sectionRoot }}
      >
        <ProjectPackagesList projectId={projectId} enableEdit showPrices onSuccessfulSave={onSuccessfulSaveHandler} />
      </Section>

      {addPackageDialogIsOpen && (
        <ProjectPackagesSelectionDialog
          isOpen={addPackageDialogIsOpen}
          onClose={closeAddPackageDialog}
          searchForPackageKey={packageKey}
          onAddPackage={onAddPackageHandler}
        />
      )}

      {showMonthlyCycles && (
        <MonthlyCycles
          cycles={cycles}
          project={project}
          isOnboarding={isOnboarding}
          isLive={isLive}
          currentCycleFundAccount={currentCycleFundAccount}
          setCurrentCycleFundAccount={setCurrentCycleFundAccount}
        />
      )}

      <FundAccountsSection
        project={project}
        currentCycleFundAccount={currentCycleFundAccount}
        showMonthlyCycles={showMonthlyCycles}
        companyId={companyId}
      />

      <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive showOnFinished showOnAbandoned>
        <Section title="Elapsed Cycles (waiting for final billing)" noBorder>
          <ProjectElapsedCycleReview
            project={project}
            currency={currency}
            projectLifeCycleStatus={projectLifeCycleStatus}
            elapsedCycles={elapsedCycles}
            refetchProjectData={refetchProjectData}
          />
        </Section>
      </ShowByProjectStatus>

      <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive showOnFinished showOnAbandoned>
        <FinalizedCyclesSection projectId={projectId} cycles={cycles} currency={currency} />
      </ShowByProjectStatus>

      <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive>
        <TriggerProjectIntermediateBilling project={project} cycles={cycles} />
      </ShowByProjectStatus>

      <ShowByProjectStatus status={projectLifeCycleStatus} showOnLive>
        <ProjectPausingSection project={project} isLive={isLive} refetchProjectData={refetchProjectData} />
      </ShowByProjectStatus>

      <ShowByProjectStatus
        status={projectLifeCycleStatus}
        showOnLive
        showOnOnBoarding
        showOnFinished={elapsedCycles.length > 0}
      >
        <ProjectStopSection project={project} isOnboarding={isOnboarding} refetchProjectData={refetchProjectData} />
      </ShowByProjectStatus>

      <ProjectBillingConfiguration projectId={projectId} />

      <ProjectBillingPointBehaviorEditor project={project} onSuccess={refetchProjectData} />

      <BillingBreakdownDocumentsSection cycles={cycles} />
    </div>
  );
};

export default ProjectServicesAndBillingTab;
