import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { MarketerMatch, MarketerMatchOverride, ProjectUpdateInput } from '@mayple/types';
import DeleteIcon from '@material-ui/icons/Delete';

import { MarketerMatchesOverrideListProps } from '../types';
import { getMarketerMatchOverrideCopy } from '../logic';
import MarketerListItem from '../../MarketerListItem';

import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import { colors, getGradientColor } from '../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    notAvailable: {
      backgroundColor: getGradientColor('sherry', 20),
    },
  }),
);

const MarketerMatchesOverrideList: React.FC<MarketerMatchesOverrideListProps> = (props) => {
  const { project, marketerMatches, updateProjectMutate, onDeleteMatchOverride } = props;
  const classes = useStyles(props);
  const { id: projectId } = project;

  if (!project?.marketerMatchesOverride?.length) {
    return <CalloutMessage margin type="info" message="No overrides found." />;
  }

  const mergedMarketerMatchesOverride = (project?.marketerMatchesOverride || ([] as MarketerMatchOverride[])).map(
    (marketerMatchOverride: MarketerMatchOverride) => {
      const tempMarketer = marketerMatches.find(
        (m: MarketerMatch) => m.marketerId === marketerMatchOverride.marketerId,
      );
      return {
        ...tempMarketer,
        ...marketerMatchOverride,
      };
    },
  );

  const deleteMarketerMatchOverride = async (marketer: MarketerMatchOverride) => {
    const marketerId = marketer?.marketerId;

    const marketerMatchesOverrideOriginal = project?.marketerMatchesOverride || [];

    const ans = window.confirm(`
    You are about to delete marketer ${marketerId}
    from the marketer matches override list
    for project ${projectId}, are you sure?
    `);

    if (!ans) {
      return;
    }

    // get a clean copy of marketerMatchesOverride
    let marketerMatchesOverride: MarketerMatchOverride[] = getMarketerMatchOverrideCopy(
      marketerMatchesOverrideOriginal,
    );

    if (projectId && marketerId) {
      // filter the desired marketer out.
      marketerMatchesOverride = marketerMatchesOverride.filter((override) => override.marketerId !== marketerId);

      const variables: { projectId: number; projectUpdate: ProjectUpdateInput } = {
        projectId,
        projectUpdate: {
          marketerMatchesOverride,
        },
      };

      await updateProjectMutate({ variables });

      onDeleteMatchOverride();
    }
  };

  return (
    <>
      {mergedMarketerMatchesOverride.map((marketer) => {
        const { marketerId, position, availableForProjects } = marketer;

        return (
          <MarketerListItem
            key={`mergedMarketerMatchesOverride-${marketerId}-${position}`}
            marketer={marketer}
            classes={{
              container: classNames({
                [classes.notAvailable]: !availableForProjects,
              }),
            }}
            marketerProperties={[
              {
                marketerProperty: 'position',
                propertyLabel: 'Position: ',
              },
              {
                marketerProperty: 'overriddenBy',
                propertyLabel: 'Done by: ',
              },
              {
                marketerProperty: 'overrideReason',
                propertyLabel: 'Description: ',
              },
              {
                marketerProperty: 'availableForProjects',
                propertyLabel: 'Is available: ',
                // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
                propertyFormatter: (value: boolean) =>
                  value ? (
                    <TextDisplay inline bold colorOverride={colors.primary}>
                      Yes
                    </TextDisplay>
                  ) : (
                    <TextDisplay inline bold colorOverride={colors.secondary}>
                      NO
                    </TextDisplay>
                  ),
              },
            ]}
            action={{
              icon: DeleteIcon,
              label: 'Delete Marketer Override',
              handler: deleteMarketerMatchOverride,
            }}
          />
        );
      })}
    </>
  );
};

export default MarketerMatchesOverrideList;
