import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    flex: '0 1 auto',
    textAlign: 'initial',
  },
  fileUploadButton: {
    margin: 0,
    padding: '8px 16px',
  },
  maxFileSizeLabel: {
    marginLeft: 18,
  },
}));

export default useStyles;
