import React, { FC } from 'react';
import { ProjectCyclePhase } from '@mayple/types';

export type SelectInputComponentProps = {
  id: string;
  selectType?: 'checkbox' | 'radio';
  checked?: boolean;
  onClick: (id: string, shiftKey: boolean, row: any) => void;
  row?: any;
};

const SelectInputComponent: FC<SelectInputComponentProps> = (props) => (
  <input
    type={props.selectType || 'checkbox'}
    aria-label={`${props.checked ? 'Un-select' : 'Select'} row with id:${props.id}`}
    checked={props.checked}
    id={props.id}
    onClick={(e) => {
      const { shiftKey } = e;
      e.stopPropagation();
      props.onClick(props.id, shiftKey, props.row);
    }}
    onChange={() => {
      /* Do nothing */
    }}
    disabled={!(!props.row?.cycle?.isReadyForFinalBilling && props.row?.cycle?.phase === ProjectCyclePhase.ELAPSED)}
  />
);

export default SelectInputComponent;
