import React from 'react';

import { MarketingPlanStatusExtended } from '../MarketingPlanProvider/types';

export interface MarketingPlanProgressProps {
  classes?: Record<string, string>;
}

export enum MarketingPlanSteps {
  createYourMarketingPlan,
  reviewByGrowthStrategist,
  marketingPlanMeetingAndApproval,
}

export type StepProps = {
  id: MarketingPlanSteps;
  label: string;
  component: React.FC;
  completed: boolean;
};

export type MarketingPlanProgressHeaderProps = {
  title: string;
  subtitle: string;
  avatarUrl: string;
  value: MarketingPlanStatusExtended;
};
