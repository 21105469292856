import React, { useEffect, useMemo } from 'react';
import {
  Project,
  ProjectCreateInput,
  ProjectCreationReason,
  ProjectSpecialSubType,
  ProjectSpecialType,
} from '@mayple/types';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { CreateProjectMutation } from 'growl-graphql/dist/mutations/CreateProjectMutation';

import { ProjectCreateFormValues, ProjectCreationWizardProps } from './types';
import mapProjectCreateFormValuesToProjectCreateInput, { getProjectName } from './logic';
import WizardSteps from './steps';
import useCompanyData from '../../../../pages/CompanyDetailsPage/useCompanyData';

import { useMutation } from '../../../../../fe_common/client/hooks';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { handleClientError } from '../../../../../fe_common/client/services/logger';

import { useStyles } from './style';

const ProjectCreationWizard: React.FC<ProjectCreationWizardProps> = (props) => {
  const classes = useStyles();
  const { companyId, formName, onSuccess, onError, onSaveStatusChange } = props;

  const { company } = useCompanyData(companyId);

  const { mutate: projectCreate, loading: saving } = useMutation(CreateProjectMutation);

  useEffect(() => onSaveStatusChange?.(saving), [onSaveStatusChange, saving]);

  const initialValues = useMemo(
    () => ({
      requestedProjectSpecialType: ProjectSpecialType.NO_SPECIAL_TYPE,
      requestedProjectSpecialSubType: ProjectSpecialSubType.NO_SPECIAL_SUB_TYPE,
      companyName: company?.name,
      projectName: getProjectName(ProjectSpecialSubType.NO_SPECIAL_SUB_TYPE, company?.name),
      locations: [],
      targetKPI: null,
    }),
    [company?.name],
  );

  const handleFormSubmit: FormSubmitHandler<ProjectCreateFormValues> = async (
    formValuesSubmitted,
    _dispatch,
    formProps,
  ) => {
    try {
      const projectCreateInput: ProjectCreateInput = mapProjectCreateFormValuesToProjectCreateInput(
        formValuesSubmitted as ProjectCreateFormValues,
      );

      const { projectCreationReason } = formValuesSubmitted;

      const variables = {
        companyId,
        projectCreate: projectCreateInput,
        creationReason: projectCreationReason?.value || ProjectCreationReason.REGULAR,
      };
      const result = await projectCreate({ variables });
      const project: Project = result?.data?.createProject;

      if (project) {
        formProps?.destroy?.();
        onSuccess?.(project);
      }
    } catch (e: any) {
      onError?.(e);
      handleClientError(e, 'Error when trying to create new project.');
    }
  };

  return (
    <div className={classes.root}>
      <WizardForm
        form={formName}
        initialValues={initialValues}
        // @ts-ignore
        steps={WizardSteps}
        // @ts-ignore
        onSubmit={handleFormSubmit}
        watchAllValues
        destroyOnUnmount
      />
    </div>
  );
};

export default ProjectCreationWizard;
