import { useQuery } from '@apollo/react-hooks';
import { ProjectCyclesQuery } from 'growl-graphql/dist/queries/project/ProjectCyclesQuery';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import { withQuery } from 'growl-graphql/dist/hoc/withQuery';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';

import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import ProjectCycleDetails from '../../components/cpanel/components/organisms/Project/ProjectCycleDetails';
import { TextDisplay } from '../../../fe_common/client/components/display';
import { tryParseInt } from '../../../fe_common/client/services/utils';

const styles = {
  root: {},
};

const ProjectCycleDetailsPage = ({ match, fullProjectByIdQuery }) => {
  const [cycleId] = useState(() => tryParseInt(match?.params?.cycleId, 0));
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [project, setProject] = useState();
  const [cycle, setCycle] = useState();
  const projectId = fullProjectByIdQuery?.project?.id;
  const { data: projectCyclesData, loading: projectCyclesLoading } = useQuery(ProjectCyclesQuery.query, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  useEffect(() => {
    const { loading } = fullProjectByIdQuery;
    if (isLoading !== loading && loading === false) {
      setIsLoading(false);
    }
  }, [isLoading, setIsLoading, isInitialized, fullProjectByIdQuery]);

  useEffect(() => {
    const { project: fullProject = {} } = fullProjectByIdQuery;
    const cycles = projectCyclesData?.project?.cycles || [];

    if (!isLoading && !isInitialized && cycles.length > 0) {
      const selectedCycle = cycles.find((temp) => temp.id === cycleId);

      setProject(fullProject);
      setCycle(selectedCycle);
      setIsInitialized(true);
    }
  }, [cycleId, fullProjectByIdQuery, isLoading, isInitialized, setCycle, setIsInitialized, projectCyclesData]);

  return (
    <React.Fragment>
      {(projectCyclesLoading || isLoading) && <PageLoadingPlaceholder />}
      {!isInitialized && <TextDisplay>Initializing data...</TextDisplay>}
      {!isInitialized && !isLoading && !cycle && <TextDisplay>Error getting project cycle data...</TextDisplay>}
      {isInitialized && cycle && <ProjectCycleDetails project={project} cycle={cycle} />}
    </React.Fragment>
  );
};

ProjectCycleDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  fullProjectByIdQuery: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withQuery(FullProjectByIdQuery, {
    options: ({ match }) => ({
      variables: { projectId: match?.params?.projectId },
      skip: !match?.params?.projectId,
    }),
  }),
)(ProjectCycleDetailsPage);
