import { useQuery } from '@apollo/react-hooks';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';

import { ProjectData } from './types';

const useProjectData = (projectId: number): ProjectData => {
  const { loading, error, data, refetch } = useQuery(FullProjectByIdQuery.query, {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error,
    project: data?.project,
    refetch,
  };
};

export default useProjectData;
