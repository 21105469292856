// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactTable input,
.ReactTable select {
    display: inline-block;
    height: auto;
    width: auto;
}

.ReactTable .rt-th select {
    height: 100%;
    width: 100%;
}

.ReactTable.-striped .rt-tr.row-selected {
    background-color: rgba(83 79 242 / 10%) !important;
}
`, "",{"version":3,"sources":["webpack://./src/client/containers/marketer/wizards/BillingCycleWizard/rt-override.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kDAAkD;AACtD","sourcesContent":[":global(.ReactTable input),\n:global(.ReactTable select) {\n    display: inline-block;\n    height: auto;\n    width: auto;\n}\n\n:global(.ReactTable .rt-th select) {\n    height: 100%;\n    width: 100%;\n}\n\n:global(.ReactTable.-striped .rt-tr.row-selected) {\n    background-color: rgba(83 79 242 / 10%) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
