import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: 32,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 32,
    },
  },
  heroImageContainer: {
    minWidth: 213,
    [theme.breakpoints.down('sm')]: {
      minWidth: 116,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: 'auto',
    },
  },
  heroDetailsContainer: {
    flexGrow: 1,
  },
  heroImage: {
    display: 'block',
    width: '100%',
    paddingTop: '100%',
    minWidth: 192,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    boxShadow: '0px 2px 16px rgba(36, 24, 21, 0.19)',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      width: '100%',
      maxHeight: 384,
      maxWidth: 384,
      minWidth: 'unset',
    },
  },
  companyName: {
    marginTop: 16,
  },
  heroHeader: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  digitalAgencyHeader: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
  },
  freelancerHeader: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {},
  },
  spacer: { flexGrow: 1 },
  description: { marginTop: 40 },
  vettedIcon: { marginRight: 12, color: colors.green_darker },
  nameStatusContainer: { width: '100%' },
  headerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',
  },
  copyButtonRoot: {},
}));

export default useStyles;
