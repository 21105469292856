import { ApolloError } from 'apollo-client';
import { AccountType, InternalTeam, Marketer, ProjectSpecialType } from '@mayple/types';

import useMarketersByWorkingGroupData from './useMarketersByWorkingGroupData';
import useInternalTeamsByWorkingGroupData from './useInternalTeamsByWorkingGroupData';

import { Account } from '../../../../../../../fe_common/client/components/integrations/calendars/types';

const internalTeamsToAccountsMapper = (internalTeam: InternalTeam): Account => {
  const { id, uuid } = internalTeam;
  return {
    id,
    uuid,
    accountType: AccountType.INTERNAL_TEAM,
  };
};

const marketersToAccountsMapper = (marketer: Marketer): Account => {
  const { id, uuid } = marketer;

  return {
    id,
    uuid,
    accountType: AccountType.MARKETER,
  };
};

type MultipleMarketersAvailabilityData = {
  mainAccountsGroup: Account[];
  marketersAccountsGroup: Account[];
  loading: boolean;
  error: ApolloError | undefined;
};

const useMultipleMarketersAvailabilityData = (): MultipleMarketersAvailabilityData => {
  const {
    data: dataInternalTeams,
    loading: loadingInternalTeams,
    error: errorInternalTeams,
  } = useInternalTeamsByWorkingGroupData(ProjectSpecialType.MAILCHIMP_LIVE_TYPE.toString());

  const {
    data: dataMarketers,
    loading: loadingMarketers,
    error: errorMarketers,
  } = useMarketersByWorkingGroupData([ProjectSpecialType.MAILCHIMP_LIVE_TYPE.toString()]);

  const mainAccountsGroup: Account[] = ((dataInternalTeams || []) as InternalTeam[]).map(internalTeamsToAccountsMapper);
  const marketersAccountsGroup: Account[] = ((dataMarketers || []) as Marketer[]).map(marketersToAccountsMapper);

  return {
    mainAccountsGroup,
    marketersAccountsGroup,
    loading: loadingInternalTeams || loadingMarketers,
    error: errorInternalTeams || errorMarketers,
  };
};

export default useMultipleMarketersAvailabilityData;
