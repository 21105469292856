import { clientLogger } from '../../services/logger';

class ChatAPI {
  constructor() {
    this._hidden = false;
    this.initialized = false;
    this._debug = false;
    this._delayedActions = [];
  }

  initializeChat(isDebug = false, loadImmediately = true) {
    this._debug = isDebug;
    if (this.initialized) {
      clientLogger.error('Chat API already initialized! Please use refresh() if you need to reload the api.');
      return;
    }

    if (!loadImmediately) {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
    }

    if (window.HubSpotConversations) {
      this.onConversationsAPIReady();
    } else {
      clientLogger.debug('Chat API not ready yet.');
      /*
       Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
       These callbacks will be called once the external API has been initialized.
       */
      window.hsConversationsOnReady = [
        () => {
          this.onConversationsAPIReady();
        },
      ];
    }
  }

  onConversationsAPIReady() {
    setTimeout(this.refreshChatForTheFirstTime.bind(this), 2000);
    clientLogger.debug('Chat API now ready.');
  }

  refreshChatForTheFirstTime() {
    this.initialized = true;
    this.refreshChat();
    if (this._delayedActions.length > 0) {
      const chatInstance = this;
      this._delayedActions.forEach((action) => {
        const execute = () => {
          // eslint-disable-next-line no-eval
          eval(`this.${action}();`);
        };
        execute.apply(chatInstance);
      });
      this._delayedActions = [];
    }
  }

  handleActionRejection(action = 'The') {
    if (!this.initialized) {
      clientLogger.info('Chat API not initialized yet, saving the action for later to be executed.');
      this._delayedActions.push(action);
    } else {
      clientLogger.error(`Could not perform ${action} action.`);
    }
  }

  openChat(prefilledMessage = '') {
    if (this._debug) {
      clientLogger.debug(`called HubSpotConversations.widget.open(${prefilledMessage})`);
    } else if (this.initialized) {
      clientLogger.debug('called HubSpotConversations.widget.open()');
      if (this._hidden) {
        this.showChat();
      }
      window.HubSpotConversations.widget.open(prefilledMessage);
    } else {
      this.handleActionRejection('openChat');
    }
  }

  closeChat() {
    if (this._debug) {
      clientLogger.debug('called HubSpotConversations.widget.close()');
    } else if (this.initialized) {
      clientLogger.debug('called HubSpotConversations.widget.close()');
      window.HubSpotConversations.widget.close();
    } else {
      this.handleActionRejection('closeChat');
    }
  }

  hideChat() {
    if (this.initialized && !this._hidden) {
      clientLogger.debug('called HubSpotConversations.widget.remove()');
      window.HubSpotConversations.widget.remove();
      this._hidden = true;
    } else {
      this.handleActionRejection('hideChat');
    }
  }

  showChat() {
    if (this._debug) {
      clientLogger.debug('called HubSpotConversations.widget.load()');
    } else if (this.initialized && this._hidden) {
      clientLogger.debug('called HubSpotConversations.widget.load()');
      window.HubSpotConversations.widget.load();
      this._hidden = false;
    } else {
      this.handleActionRejection('showChat');
    }
  }

  refreshChat() {
    if (this._debug) {
      clientLogger.debug('called HubSpotConversations.widget.refresh()');
    } else if (this.initialized && this._hidden) {
      clientLogger.debug('called HubSpotConversations.widget.refresh()');
      window.HubSpotConversations.widget.refresh();
      this._hidden = false;
    } else {
      this.handleActionRejection('refreshChat');
    }
  }
}

const chatApi = new ChatAPI();

export default chatApi;
