import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import AutocompleteField from '../../Registration/AutocompleteField';
import {
  validateRequired,
  validateNonEmptyArray,
  validateMaximumSelectedValues,
} from '../../../../services/validation';

export function WizardAutocompleteField(props) {
  const { isRequired, isMulti, enums, name, label, placeholder, autoFocus, maximumSelectedValues, ...rest } = props;

  const [validator] = useState(() => {
    const _validator = isRequired ? [validateRequired] : [];

    if (isMulti && isRequired) {
      _validator.push(validateNonEmptyArray());
    }

    if (isMulti && maximumSelectedValues > -1) {
      _validator.push((value) => validateMaximumSelectedValues(value, maximumSelectedValues));
    }

    return _validator;
  });

  return (
    <div>
      <Field
        component={AutocompleteField}
        name={name}
        enums={enums}
        isMulti={isMulti}
        placeholder={placeholder}
        label={label}
        validate={validator}
        autoFocus={autoFocus}
        // filterOption={filterOption}
        {...rest}
      />
    </div>
  );
}

WizardAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  enums: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maximumSelectedValues: PropTypes.number,
};

WizardAutocompleteField.defaultProps = {
  label: '',
  placeholder: 'Please select',
  isRequired: false,
  isMulti: false,
  autoFocus: false,
  maximumSelectedValues: -1,
};
