import React, { FC } from 'react';

import { PageHeaderProps } from './types';

import { TextDisplay } from '../../display';
import { useDeviceInfo } from '../../../hooks';

import useStyles from './style';

/**
 * PageHeader component should be used in all of our Main pages.
 * This will create a unified header look
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const PageHeader: FC<PageHeaderProps> = (props) => {
  const classes = useStyles(props);
  const { isMobile } = useDeviceInfo();

  const { title, subTitle, children } = props;

  return (
    <div className={classes.pageHeaderRoot}>
      <div className={classes.pageHeaderLeftSide}>
        <TextDisplay variant={isMobile ? 'h5' : 'h4'} bold={isMobile} className={classes.title}>
          {title}
        </TextDisplay>
        <TextDisplay variant={isMobile ? 'subtitle2' : 'subtitle1'} className={classes.subtitle}>
          {subTitle}
        </TextDisplay>
      </div>
      <div className={classes.pageHeaderRightSide}>{children}</div>
    </div>
  );
};

export default PageHeader;
