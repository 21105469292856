import React, { useEffect, useMemo, useState } from 'react';
import { WrappedFieldProps, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';

import EntityAvatarCheckbox from './EntityAvatarCheckbox';
import { EntityAvatarProps } from '../EntityAvatar';
import CalloutMessage from '../../common/CalloutMessage';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  entityAvatar: {
    flex: '1 1 auto',
  },
}));

interface EntityAvatarCheckboxesProps extends Partial<WrappedFieldProps> {
  data: Record<string, any>[];
  dataToEntityMapper: (dataRow: unknown) => Partial<EntityAvatarProps>;
  showError: boolean;
}

const EntityAvatarCheckboxes: React.FC<EntityAvatarCheckboxesProps> = (props) => {
  const classes = useStyles();
  const {
    data = [],
    input = {} as WrappedFieldInputProps,
    meta = {} as WrappedFieldMetaProps,
    dataToEntityMapper,
    showError = false,
  } = props;

  const { value, onChange } = input;
  const { error = '' } = meta;

  // entities are used in EntityAvatar
  const entities = useMemo(() => data.map(dataToEntityMapper), [data, dataToEntityMapper]);

  // initialize selected values from redux-form
  const [selectedEntities, setSelectedEntities] = useState<number[]>(() =>
    (value || []).map(({ id }: { id: number }) => id)
  );

  // update redux form with array of selected data
  useEffect(() => {
    const newSelectedData = selectedEntities.map((selectedEntityIndex: number) => data[selectedEntityIndex]);

    onChange(newSelectedData);
  }, [data, selectedEntities, onChange]);

  // event handler for selection
  const onSelectEntityHandler = (index: number) => () => {
    setSelectedEntities((prev: number[]) => {
      if (prev.includes(index)) {
        return prev.filter((elem: number) => elem !== index);
      }
      return [...prev, index];
    });
  };

  return (
    <div className={classes.root}>
      {showError && error !== '' && <CalloutMessage type="error" message={error} />}
      <List>
        {entities.map((entity, entityIndex) => {
          const { avatarImageUrl = '', primary = '', secondary = '' } = entity;
          const key = `entity-avatar-checkbox-${entityIndex}`;
          const isSelected = selectedEntities.includes(entityIndex);

          return (
            <EntityAvatarCheckbox
              key={key}
              avatarImageUrl={avatarImageUrl}
              primary={primary}
              secondary={secondary}
              onSelectItem={onSelectEntityHandler(entityIndex)}
              isSelected={isSelected}
              classes={{ root: classes.entityAvatar }}
            />
          );
        })}
      </List>
    </div>
  );
};

export default EntityAvatarCheckboxes;
