import React, { FC, useCallback } from 'react';
import { IntegrationStatus } from '@mayple/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { RefreshIntegrationMutation } from 'growl-graphql/dist/mutations/RefreshIntegrationMutation';

import { getUserEmail, getUserName } from './logic';
import { IntegrationItemDetailsProps } from './types';

import useMutation from '../../../../fe_common/client/hooks/useMutation';
import { Button } from '../../../../fe_common/client/components/inputs';
import { IntegrationsTreeView } from '../../../../fe_common/client/components/organisms/IntegrationsTreeView';

import useStyles from './style';

const IntegrationItemDetailsGeneral: FC<IntegrationItemDetailsProps> = (props) => {
  const classes = useStyles(props);

  const { integration, avatar } = props;

  const { selectable, integrationType, integrationStatus, externalEntityId } = integration;

  const { mutate: refreshIntegrationMutation } = useMutation(RefreshIntegrationMutation);

  const getHandlerRefreshIntegration = useCallback(
    (integrationId: number) =>
      async function handleRefreshIntegration(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const variables = { integrationId };
        await refreshIntegrationMutation({ variables });
      },
    [refreshIntegrationMutation],
  );

  const userEmail = getUserEmail(integration);
  const userName = getUserName(integration);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <List className={classes.integrationDetailsListRoot}>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={avatar} title={integrationType} classes={{ root: classes.avatar }} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <span>
                  {`${userName} (${userEmail} `}
                  <CopyToClipboard text={userEmail}>
                    <Link
                      href=""
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      copy
                    </Link>
                  </CopyToClipboard>
                  {`) (Status: ${integrationStatus})`}
                </span>
              }
              secondary={
                <span>
                  ID: {externalEntityId}-{' '}
                  <CopyToClipboard text={externalEntityId}>
                    <Link
                      href=""
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      copy
                    </Link>
                  </CopyToClipboard>{' '}
                  {selectable ? (
                    <span className="green-text">Selectable</span>
                  ) : (
                    <span className="red-text">Not selectable</span>
                  )}
                </span>
              }
            />
          </ListItem>
          <ListItemSecondaryAction>
            <Button
              label="Refresh Integration"
              onClick={getHandlerRefreshIntegration(integration.id)}
              variant="outlined"
              color="primary"
              disabled={integrationStatus === IntegrationStatus.INACTIVE}
            />
          </ListItemSecondaryAction>
        </List>
      </AccordionSummary>
      <AccordionDetails>
        <IntegrationsTreeView integration={integration} />
      </AccordionDetails>
    </Accordion>
  );
};

export default IntegrationItemDetailsGeneral;
