import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      height: '100%',
    },
    media: {
      height: 140,
      background: colors.site_background_main,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
    toolTip: {
      fontSize: '1rem',
      backgroundColor: colors.yellow_lighter,
      color: colors.black,
    },
    toolTipPopper: {
      cursor: 'help',
    },
  }),
);

export default useStyles;
