/**
 * Will return a list of errors to display the admin, prior for marking the cycle "ready for billing"
 * This will reduce errors when BE job tries to bill the cycle.
 * @param project
 * @param cycle
 * @returns {[]|*[]}
 */
export function getElapsedCycleWarningsBeforeBilling(project, cycle) {
  try {
    const warnings = [];
    const participant = project?.participants?.[0] || {};
    const channelAccounts = participant?.channelAccounts || [];

    // Verify the channel accounts has no issues with reading ad spend
    channelAccounts.forEach(({ accessSettings, channelType }) => {
      if (accessSettings.lifecycleStatus !== 'SYNCED') {
        warnings.push(
          `The channel ${channelType} has issues with reading the ad spend! 
          please manually verify the spend is correct using the ${channelType} platform.`,
        );
      }
    });

    // Verify the marketer minimum fee does not surpass the actual marketer fee
    const actualMarketerFee = cycle?.actualMarketerFee || 0;
    const marketerMinimumFee = cycle?.marketerFeeConfiguration?.minimumFeePart || 0;

    if (actualMarketerFee < marketerMinimumFee) {
      warnings.push(
        `The actual marketer fee (${actualMarketerFee}) is lower then the minimum fee (${marketerMinimumFee})
         set for the marketer`,
      );
    }

    // Verify the mayple minimum fee does not surpass the actual mayple fee
    const actualMaypleFee = cycle?.actualMaypleFee || 0;
    const maypleMinimumFee = cycle?.billingConfiguration?.minimumFee || 0;

    if (actualMaypleFee < maypleMinimumFee) {
      warnings.push(
        `The actual mayple fee (${actualMaypleFee}) is lower then the minimum fee (${maypleMinimumFee})
         set for mayple`,
      );
    }

    return warnings;
  } catch (e) {
    return [];
  }
}
