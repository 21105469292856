import { Industry } from '@mayple/types';

import { INDUSTRIES_PAIRS } from '../app/enums';
import { IndustryLabelValueProps, LabelValueProps } from '../app/types';

export const convertToLabelValue = (value: string, labelValuePairs: LabelValueProps[]): LabelValueProps | undefined => {
  if (!value) {
    return undefined;
  }
  return (labelValuePairs || []).find((pair) => value === pair.value);
};

export const fromArrayToLabelValueArray = (
  values: any | null | undefined,
  labelValuePairs: LabelValueProps[]
): LabelValueProps[] => {
  if (!values || !Array.isArray(values)) {
    return [] as LabelValueProps[];
  }

  const result: LabelValueProps[] = [];

  (values || []).forEach((value: string) => {
    const labelValuePair = convertToLabelValue(value, labelValuePairs);
    if (labelValuePair) {
      result.push(labelValuePair);
    }
  });

  return result;
};

export const getIndustryExpertise = (industries?: Industry[] | null): IndustryLabelValueProps | undefined => {
  if (!industries) {
    return undefined;
  }

  const [industry] = industries;
  if (!industry) {
    return undefined;
  }

  return convertToLabelValue(
    industry.industrySubCategory as string,
    INDUSTRIES_PAIRS as LabelValueProps[]
  ) as IndustryLabelValueProps;
};
