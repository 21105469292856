import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ProjectByIdQuery } from 'growl-graphql/dist/queries/ProjectByIdQuery';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface ProjectSearchProps {
  name: string;
  value?: string;
  label?: string;
  helperText?: string;
  onChange?: (projectId: string) => void;
  onSelect?: (projectId: string | null) => void;
}

const ProjectSearch: React.FC<ProjectSearchProps> = (props) => {
  const classes = useStyles();
  const { name, value, label, helperText, onChange, onSelect } = props;

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const { data, refetch } = useQuery(ProjectByIdQuery.query, { skip: !value });

  let extraProps = {};
  if (value !== undefined) {
    extraProps = {
      ...extraProps,
      value,
    };
  }

  const projectIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value || '';

    // controlled element
    if (onChange && typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (value && value !== '' && value !== selectedProjectId) {
      const variables = {
        projectId: value,
      };

      refetch(variables);
    } else {
      setSelectedProjectId(null);
    }
  }, [value, refetch, selectedProjectId]);

  useEffect(() => {
    if (data?.project) {
      setSelectedProjectId(data?.project?.id);
    } else {
      setSelectedProjectId(null);
    }
  }, [data]);

  useEffect(() => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(selectedProjectId);
    }
  }, [onSelect, selectedProjectId]);

  return (
    <div className={classes.root}>
      <TextField name={name} label={label} helperText={helperText} onChange={projectIdOnChange} {...extraProps} />
    </div>
  );
};

export default ProjectSearch;
