import React, { FC } from 'react';
import MUITableCell, { TableCellProps } from '@material-ui/core/TableCell';

import { useTableCellStyles } from './styles';

const TableCell: FC<TableCellProps> = (props) => {
  const classes = useTableCellStyles(props);
  const { children, ...rest } = props;

  return (
    <MUITableCell classes={{ root: classes.tableCellRoot }} {...rest}>
      {children}
    </MUITableCell>
  );
};

export default TableCell;
