import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountManager, ProjectExistingMarketingEvaluationReportV3Details } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { getScoreQuestionsHCV3 } from './logic';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import RichTextViewer from '../../../../../fe_common/client/components/common/RichTextViewer';
import { titleCase } from '../../../../../fe_common/client/services/utils';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';
import { ACCOUNT_MANAGER_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    scoreQuestions: {
      marginBottom: '2rem',
    },
  }),
);

export interface ExistingMarketingEvaluationReportV3DetailsProps {
  reportDetails: ProjectExistingMarketingEvaluationReportV3Details;
}

const ExistingMarketingEvaluationReportV3Details: React.FC<ExistingMarketingEvaluationReportV3DetailsProps> = (
  props,
) => {
  const classes = useStyles();
  const { reportDetails } = props;
  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketerProjectMarketingEvaluationAuditReport.ExistingMarketingEvaluationReportV3Details',
  });

  const scoreQuestions = getScoreQuestionsHCV3(t, reportDetails);

  return (
    <div className={classes.root}>
      {!reportDetails && <div>The marketer has not yet submitted an evaluation form</div>}
      {!!reportDetails && (
        <div>
          <TextDisplay gutterBottom variant="h6" bold>
            Score questions
          </TextDisplay>
          <Divider />
          <div className={classes.scoreQuestions}>
            {scoreQuestions.map(
              ({ label: scoreLabel, value: scoreValue, formatter }, index) =>
                typeof scoreValue === 'number' && (
                  <TextDisplay key={`reportDetails-score-questions-${index}`} gutterBottom>
                    <strong>{titleCase(scoreLabel)}?</strong>: {formatter ? formatter?.(scoreValue) : scoreValue}
                  </TextDisplay>
                ),
            )}
          </div>
          <TextDisplay gutterBottom variant="h6" bold>
            Open questions
          </TextDisplay>
          <Divider />
          <TextDisplay gutterBottom bold>
            Main challenges in this account
          </TextDisplay>
          {reportDetails.mainChallenges && <RichTextViewer value={reportDetails.mainChallenges} />}
          <TextDisplay gutterBottom bold>
            What KPI's have you set up with the client?
          </TextDisplay>
          {reportDetails.kpiSetWithClient && <RichTextViewer value={reportDetails.kpiSetWithClient} />}
          <TextDisplay gutterBottom bold>
            What implementation is needed in this account?
          </TextDisplay>
          {reportDetails.implementationNeeded && <RichTextViewer value={reportDetails.implementationNeeded} />}
          <TextDisplay gutterBottom bold inline>
            Who is managing the Outbrain account?
          </TextDisplay>{' '}
          {reportDetails.currentAccountManager && (
            <TextDisplay gutterBottom inline>
              {(ACCOUNT_MANAGER_LABELS_LOOKUP as Record<AccountManager, string>)[reportDetails.currentAccountManager]}
            </TextDisplay>
          )}
          <TextDisplay gutterBottom bold>
            Does the client have any issues related to Outbrain product/service?
          </TextDisplay>
          {reportDetails.issuesWithProductOrService && (
            <RichTextViewer value={reportDetails.issuesWithProductOrService} />
          )}
          <Divider />
        </div>
      )}
    </div>
  );
};

export default ExistingMarketingEvaluationReportV3Details;
