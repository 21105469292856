import React, { FC } from 'react';
import { InternalTeam } from '@mayple/types';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { DisableInternalTeamMutation } from 'growl-graphql/dist/mutations/DisableInternalTeamMutation';

import { getInternalTeamDetailsProperties } from '../logic';
import DisableEntityControl from '../../../containers/shared/DisableEntityControl';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import PropertiesList from '../../../../fe_common/client/components/display/PropertiesList';

interface InternalTeamBasicDetailsTabProps {
  internalTeam: InternalTeam;
  disableInternalTeamMutation: any;
}

const InternalTeamBasicDetailsTab: FC<InternalTeamBasicDetailsTabProps> = ({
  internalTeam,
  disableInternalTeamMutation,
}) => {
  const internalTeamDetailsProperties = getInternalTeamDetailsProperties();
  const redirectTo = '/internal-teams';

  return (
    <div>
      <Section title="Basic details" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
        <SectionContent>
          <PropertiesList
            id="internalTeamDetailsData"
            data={internalTeam}
            propertiesMeta={internalTeamDetailsProperties}
          />
        </SectionContent>
      </Section>

      <Section>
        <DisableEntityControl
          entity={internalTeam}
          mutation={disableInternalTeamMutation}
          variables={{ internalTeamId: internalTeam.id }}
          title="Disable Account"
          buttonLabel="Disable Account"
          warningText={`You are about to disable internal account ${internalTeam.name} with ID: ${internalTeam.id}`}
          redirectTo={redirectTo}
        />
      </Section>
    </div>
  );
};

export default withMutation(DisableInternalTeamMutation)(InternalTeamBasicDetailsTab);
