import React, { useCallback } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { ProjectPackage, ProjectPackageBillablesOverridesInput } from '@mayple/types';
import { SetProjectPackageBillablesOverridesMutation } from 'growl-graphql/dist/mutations/project/projectPackage/SetProjectPackageBillablesOverridesMutation';

import { ProjectPackageBillablesOverridesFormData, ProjectPackageBillablesOverridesFormProps } from './types';
import WizardSteps from './WizardSteps';
import {
  formName,
  getProjectPackageBillablesOverridesInitialValues,
  mapProjectPackageBillablesOverridesFormDataToDeliverableBlueprint,
} from './logic';
import { WizardForm } from '../../organisms/WizardForm';
import { useMutation } from '../../../hooks';
import { handleClientError } from '../../../services/logger';

const ProjectPackageBillablesOverridesForm: React.FC<ProjectPackageBillablesOverridesFormProps> = (props) => {
  const { projectPackage, onSuccessfulSave } = props;
  const { uuid: projectPackageUuid } = projectPackage || ({} as ProjectPackage);

  const initialValues = getProjectPackageBillablesOverridesInitialValues(projectPackage);

  const { mutate: setProjectPackageBillablesOverrides } = useMutation(SetProjectPackageBillablesOverridesMutation);

  const handleFormSubmit: FormSubmitHandler<ProjectPackageBillablesOverridesFormData> = useCallback(
    async (formValuesSubmitted, _dispatch, formProps) => {
      // console.log('formValuesSubmitted', formValuesSubmitted);

      const deliverablesBlueprintsOverride =
        mapProjectPackageBillablesOverridesFormDataToDeliverableBlueprint(formValuesSubmitted);

      try {
        const variables: {
          projectPackageUuid: string;
          billablesOverrides: ProjectPackageBillablesOverridesInput;
        } = {
          projectPackageUuid,
          billablesOverrides: {
            // @ts-ignore
            deliverablesBlueprintsOverride: [deliverablesBlueprintsOverride],
          },
        };

        await setProjectPackageBillablesOverrides({ variables });
        onSuccessfulSave?.();
        formProps?.destroy?.();
      } catch (e) {
        handleClientError(e, `Error while trying to start a package ${projectPackageUuid}.`);
      }
    },
    [onSuccessfulSave, projectPackageUuid, setProjectPackageBillablesOverrides]
  );

  return (
    <WizardForm
      form={formName}
      initialValues={initialValues}
      destroyOnUnmount
      // @ts-ignore
      onSubmit={handleFormSubmit}
      watchAllValues
      steps={WizardSteps}
    />
  );
};

export default ProjectPackageBillablesOverridesForm;
