import { Country, CurrencyType, PayoutType } from '@mayple/types';

export type MarketersPayoutReviewProps = {
  getAllMarketers?: boolean;
  marketerId?: number | null | undefined;
};

// export type DataProcessorHandler<T, Q> = (data: { items: T[] }) => Promise<Q[]>;
export type DataProcessorHandler<T, Q> = (data: T[]) => Promise<Q[]>;

export type TableCol = {
  id: string;
  label: string;
};

export enum RowDataType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

/*
export type AggregatedProjectRowData = {
  marketerId: number;
  companyId: number;
  companyName: string;
  projectId: number;
  projectName: string;
  currency: CurrencyType;
  totalAmount: number;
};
*/

export type MarketersPayoutReviewDataRow = {
  rowIndex: number;
  rowType: RowDataType;
  projectPayoutItemsLength: number;
  marketerId: number;
  marketerName: string;
  marketerPayoutUuid: string | null | undefined;
  country: Country | null | undefined;
  companyId: number | null | undefined;
  companyName: string | null | undefined;
  projectId: number | null | undefined;
  projectName: string | null | undefined;
  cycleId: number | null | undefined;
  cycleDate: Date | null | undefined;
  amount: number;
  balance: number | null | undefined;
  currency: CurrencyType | null | undefined; // oneOf(['USD', 'GBP', 'EUR', 'ILS'])
  preferredPayoutType: PayoutType | string;
  lineDescription: string;
  payoutWithdrawed?: boolean;
  // assignedMarketingSupervisor?: string;
};

export type MarketersPayoutReviewTableProps = {
  data: MarketersPayoutReviewDataRow[];
  totalRows: number;
  loading: boolean;
  onPayoutReportSuccess?: () => void;
  onPageNumberChange?: (newPageNumber: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  classes?: Record<string, string>;
};

export type SelectedPayoutData = Record<number, string[]>;
