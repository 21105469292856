import { ProjectCreationReason, MarketingServiceType, MediaType, RequestedServiceInput, Country } from '@mayple/types';
import { v4 as uuidV4 } from 'uuid';

import { CountriesLabelsLookup, LabelValueProps } from '../../../../../fe_common/client/app/types';
import { COUNTRIES_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';

export const DEFAULT_MEDIA_BUDGET = 500;
export const DEFAULT_REQUESTED_SERVICES: RequestedServiceInput[] = [
  {
    uuid: uuidV4().replace(/-/g, ''),
    serviceType: MarketingServiceType.OTHER,
    description: {
      mediaType: MediaType.TEXT_PLAIN,
      contents: 'No service was selected',
    },
  },
];

export const DEFAULT_HEALTH_CHECK_REQUESTED_SERVICES: RequestedServiceInput[] = [
  {
    uuid: uuidV4().replace(/-/g, ''),
    serviceType: MarketingServiceType.EMAIL_MARKETING,
    description: {
      mediaType: MediaType.TEXT_PLAIN,
      contents: '',
    },
  },
];

export const PROJECT_CREATION_REASONS_PAIRS: LabelValueProps[] = [
  { label: 'Upselling', value: ProjectCreationReason.UP_SALE },
  { label: 'Expert replacement', value: ProjectCreationReason.EXPERT_REPLACEMENT },
  { label: 'New client with multiple projects', value: ProjectCreationReason.NEW_CLIENT_WITH_MULTIPLE_PROJECTS },
  { label: 'Other', value: ProjectCreationReason.OTHER },
];

export const hotLocationsEnums = [
  {
    chipLabel: 'UK',
    label: (COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[Country.GB] as string,
    value: Country.GB,
  },
  {
    chipLabel: 'USA',
    label: (COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[Country.US] as string,
    // label: 'United States',
    value: Country.US,
  },
];
