import {
  MonthlyMarketerAssessmentByGrowthStrategist,
  OutbrainAuditAssessmentByCompany,
  PostProjectMatchOfferMarketerAssessment,
  TaskType,
} from '@mayple/types';
import {
  marketerAssessmentByCompanyParameterKeys,
  marketerAssessmentByGrowthStrategistParameterKeys,
  outbrainAuditAssessmentByCompanyParameterKeys,
  postProjectMatchOfferMarketerAssessmentParameterKeys,
} from './consts';
import { FormValues } from './types';
import { AssessmentSubTask } from '../../types';
import assessmentQuestionsDefinitions from './assessmentQuestionsDefinitions';

export const getAssessmentVotes = (taskType: TaskType | undefined): string[] => {
  switch (taskType) {
    case TaskType.MonthlyMarketerAssessmentByCompany:
      return marketerAssessmentByCompanyParameterKeys;
    case TaskType.MonthlyMarketerAssessmentByGrowthStrategist:
      return marketerAssessmentByGrowthStrategistParameterKeys;
    case TaskType.PostProjectMatchOfferMarketerAssessment:
      return postProjectMatchOfferMarketerAssessmentParameterKeys;
    case TaskType.OutbrainAuditAssessmentByCompany:
      return outbrainAuditAssessmentByCompanyParameterKeys;
    default:
      return [];
  }
};

const getAssessmentVotesInitialValue = (assessmentVotes: string[]): Record<string, any> => {
  const assessmentVotesInitialValue: Record<string, any> = {};

  assessmentVotes.forEach((key) => {
    const { dataType } = assessmentQuestionsDefinitions[key] || {};

    if (dataType === 'number') {
      assessmentVotesInitialValue[key] = 0;
    } else {
      assessmentVotesInitialValue[key] = '';
    }
  });

  return assessmentVotesInitialValue;
};

export const getFormValuesFromSubTask = (
  subTask: AssessmentSubTask,
  votes: Record<string, any>,
  assessmentComment: string | null | undefined
): FormValues => {
  // eslint-disable-next-line no-underscore-dangle
  switch (subTask?.__typename as TaskType) {
    case TaskType.MonthlyMarketerAssessmentByCompany: {
      const monthlyMarketerAssessmentByGrowthStrategist = subTask as MonthlyMarketerAssessmentByGrowthStrategist;
      return {
        cycleId: monthlyMarketerAssessmentByGrowthStrategist?.cycleId,
        projectId: monthlyMarketerAssessmentByGrowthStrategist?.projectId,
        votes,
        assessmentComment,
      };
    }
    case TaskType.MonthlyMarketerAssessmentByGrowthStrategist:
      return {
        votes,
        assessmentComment,
      };
    case TaskType.PostProjectMatchOfferMarketerAssessment: {
      const postProjectMatchOfferMarketerAssessment = subTask as PostProjectMatchOfferMarketerAssessment;
      return {
        projectId: postProjectMatchOfferMarketerAssessment?.projectId,
        marketerId: postProjectMatchOfferMarketerAssessment?.marketerId,
        votes,
        assessmentComment,
      };
    }
    case TaskType.OutbrainAuditAssessmentByCompany: {
      const outbrainAuditAssessmentByCompany = subTask as OutbrainAuditAssessmentByCompany;
      return {
        projectId: outbrainAuditAssessmentByCompany?.projectId,
        votes,
        assessmentComment,
      };
    }
    default:
      return {
        votes,
        assessmentComment,
      };
  }
};

/*
interface MutationMapperData {
  save: {
    mutationClass: MutationClass;
    dataKey: string;
    extraVariables: string[];
  };
  skip: {
    mutationClass: MutationClass;
    dataKey: string;
    extraVariables: string[];
  };
}

const mutationsMapper: Record<Partial<TaskType>, MutationMapperData> = {
  [TaskType.PostProjectMatchOfferMarketerAssessment]: {
    save: {
      mutationClass: SetPostProjectMatchOfferMarketerAssessmentForProjectMutation,
      dataKey: 'setPostProjectMatchOfferMarketerAssessmentForProject',
      extraVariables: ['projectId', 'marketerId'],
    },
    skip: {
      mutationClass: SkipPostProjectMatchOfferMarketerAssessmentForProjectMutation,
      dataKey: 'skipPostProjectMatchOfferMarketerAssessmentForProject',
      extraVariables: ['projectId', 'marketerId'],
    },
  },
};
*/

export default getAssessmentVotesInitialValue;
