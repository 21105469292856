import { handleClientError } from '../../../services/logger';
import { sortByLabel } from '../../../app/enums';
import { arrayOfObjectsToHashTable } from '../../../services/utils';

// TODO: add some basic tests
class CategoriesLogic {
  groupCategories(items, categoryName) {
    if (!items) {
      return [];
    }

    try {
      const map = {};

      for (const item of items) {
        const { category, value, label } = item;

        if (!map[category]) {
          map[category] = {
            category,
            value: [{ value, label, category, [categoryName]: category }],
          };
        } else {
          map[category].value.push({ value, label, category, [categoryName]: category });
        }
      }

      return (Object.values(map) || []).sort(sortByLabel('category'));
    } catch (e) {
      handleClientError(e);
      return [];
    }
  }

  createLabelFromGrouped(value) {
    try {
      if (!value || value.length === 0) {
        return '';
      }

      return value.map(({ label }) => label).join(' • ');
    } catch (e) {
      handleClientError(e);
      return '';
    }
  }

  onSave(items, selectMultiSubCategories, categoryToEdit, selectedSubCategory, input) {
    let itemsHash = arrayOfObjectsToHashTable({ array: items, key: 'value', toBoolean: true });

    if (selectMultiSubCategories) {
      const categoryToEditHash = arrayOfObjectsToHashTable({ array: categoryToEdit, key: 'value', toBoolean: true });
      const itemsToUpdateAfterFilter = items.filter((item) => !categoryToEditHash[item.value]);

      itemsHash = arrayOfObjectsToHashTable({ array: itemsToUpdateAfterFilter, key: 'value', toBoolean: true });

      // Filter out duplicates
      const toUpdate = selectedSubCategory.filter((item) => !itemsHash[item.value]);

      input.onChange([...itemsToUpdateAfterFilter, ...toUpdate]);
    } else {
      const exists = itemsHash[selectedSubCategory.value];
      // Skip update if selecting the same values
      if (!exists) {
        return;
      }

      if (categoryToEdit) {
        const indexOfItemToUpdate = items.findIndex((item) => item.value === categoryToEdit.value);

        // eslint-disable-next-line no-param-reassign
        items[indexOfItemToUpdate] = selectedSubCategory; // anyway im sending a new array for update!

        input.onChange([...items]);
      } else {
        input.onChange([...items, selectedSubCategory]);
      }
    }
  }

  onEdit(subCategoriesSelected, category, selectMultiSubCategories, items, enums, labels) {
    let subCategoryToEdit;
    let categoryToEditTemp;
    let categoryToEditItem;

    if (selectMultiSubCategories) {
      categoryToEditTemp = { label: labels[category], value: category };
      categoryToEditItem = enums.find((item) => item.category === category);
      subCategoryToEdit = subCategoriesSelected;
    } else {
      subCategoryToEdit = items.find((item) => item.value === subCategoriesSelected[0].value);
      categoryToEditTemp = { label: labels[subCategoryToEdit.category], value: subCategoryToEdit.category };
      categoryToEditItem = enums.find((item) => categoryToEditTemp.value === item.category);
    }

    return {
      subCategoryToEdit,
      categoryToEditItem,
    };
  }

  onDelete(toDelete, items, input) {
    const categoryToDelete = Array.isArray(toDelete) ? toDelete[0].category : toDelete.category;

    const newItems = items.filter((item) => item.category !== categoryToDelete);

    input.onChange(newItems);
  }
}

const categoriesEditor = new CategoriesLogic();

export default categoriesEditor;
