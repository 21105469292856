import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { RepayEligibleDebtOfCompanyMutation } from 'growl-graphql/dist/mutations/RepayEligibleDebtOfCompanyMutation';

import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import useMutation from '../../../../../fe_common/client/hooks/useMutation';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  actions: {
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));
/**
 * Attempt to repay debts from existing balance in the fund account
 * @returns {JSX.Element}
 */
const CompanyDebtRepayFromBalance = (props) => {
  const classes = useStyles();

  const { companyId, fundAccount, onSuccess } = props;

  const { totalDebt, balance, currency } = fundAccount;

  const [repayDebtFromBalanceResponse, setRepayDebtFromBalanceResponse] = useState();

  const { loading, mutate: repayEligibleDebtOfCompanyMutation } = useMutation(RepayEligibleDebtOfCompanyMutation);

  const repayDebtHandler = async () => {
    const ans = window.confirm(`You are about to attempt charging of ${balance}${currency} from the company's 
              fund account to cover all of the total debt or part of it.`);
    if (!ans) {
      return;
    }

    const res = await repayEligibleDebtOfCompanyMutation({ variables: { companyId } });
    setRepayDebtFromBalanceResponse(res?.repayEligibleDebtOfCompany);

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  return (
    <div className={classes.root}>
      <TextDisplay gutterBottom>
        This button is enabled only if debts exist. On clicking this, existing debts are paid in a FIFO order (older
        first). Success means that one or more of the debts were paid.
      </TextDisplay>
      <TextDisplay gutterBottom>
        Total debt of {totalDebt} {currency} found.
      </TextDisplay>
      {repayDebtFromBalanceResponse?.result && (
        <p className={repayDebtFromBalanceResponse?.success ? 'green-text' : 'red-text'}>
          {repayDebtFromBalanceResponse?.result}
        </p>
      )}
      <div className={classes.actions}>
        <Button
          color="secondary"
          variant="contained"
          disabled={loading || !(totalDebt > 0 && balance > 0)}
          label={`Repay ${totalDebt} ${currency} (USING ONLY BALANCE)`}
          onClick={repayDebtHandler}
        />
      </div>
    </div>
  );
};

CompanyDebtRepayFromBalance.propTypes = {
  companyId: PropTypes.number.isRequired,
  fundAccount: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};
CompanyDebtRepayFromBalance.defaultProps = {
  onSuccess: () => {},
};

export default CompanyDebtRepayFromBalance;
