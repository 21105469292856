import React from 'react';
import { Field } from 'redux-form';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ImagePicker from '../../Registration/ImagePicker';
import { validateRequired } from '../../../../services/validation';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface WizardImagePickerFieldProps {
  name: string;
  isRequired: boolean;
  disabled: boolean;
}

const WizardImagePickerField: React.FC<WizardImagePickerFieldProps> = (props) => {
  const classes = useStyles();
  const { name, isRequired = false, disabled = false, ...rest } = props;

  const validator = isRequired ? [validateRequired] : [];

  return (
    <div className={classes.root}>
      <Field component={ImagePicker} name={name} disabled={disabled} validate={validator} {...rest} />
    </div>
  );
};

export default WizardImagePickerField;
