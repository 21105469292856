import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterBy, FilterByOperator } from '@mayple/types';
import keyBy from 'lodash/keyBy';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getEntitySearchParams, getFilteredResults, getOptionLabel, SearchParam } from './logic';

import { sortByLabel } from '../../../app/enums';
import { ENTITY_TYPE, ENTITY_TYPES } from '../../../app/types';
import { useQueryPagination } from '../../../hooks';
import usePagedQuery, { PagingSortingVariables } from '../../../hooks/usePagedQuery';
import { useAsyncDebounce } from '../../../hooks/useDebounce';

import useStyles from './style';

export type AutoCompleteOptionType = {
  value: number | string;
  label: string;
  icon?: string;
};

export type FilterType<T> = (entity: T) => boolean;

interface EntitySearchAutoCompleteProps {
  entityType: ENTITY_TYPE;
  onSelect?: (newValue: AutoCompleteOptionType | null) => void;
  filterBy?: FilterType<any>;
  queryFilterBy?: FilterBy[];
  inputValue?: string;
  helperText?: string;
  label?: string;
  onInputChange?: (event: Record<string, any>, value: string, reason: string) => void;
  onClose?: (event: Record<string, any>, reason: string) => void;
  disabled?: boolean;
  value?: any;
  hasError?: boolean;
  classes?: Record<string, string>;
}

const EntitySearchAutoComplete: FC<EntitySearchAutoCompleteProps> = (props) => {
  const classes = useStyles(props);

  const {
    entityType,
    onSelect,
    inputValue: inputValueProp,
    onInputChange: onInputChangeProp,
    onClose = () => {
      /* Do Nothing */
    },
    filterBy,
    queryFilterBy = [] as FilterBy[],
    helperText = '',
    disabled = false,
    value,
    label = '',
    hasError = false,
  } = props;

  if (!ENTITY_TYPES.includes(entityType)) {
    throw new Error(`Unknown entity type was used ${entityType}`);
  }

  const [inputValue, setInputValue] = useState<string | null>('');
  const onInputChange = useAsyncDebounce((_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  }, 500);

  let extraProps = {};
  if (typeof inputValueProp === 'string') {
    extraProps = {
      inputValue: inputValueProp,
    };
  }

  // List of all entities to search in
  const [entities, setEntities] = useState<AutoCompleteOptionType[]>([]);
  const [entitiesHash, setEntitiesHash] = useState<Record<string | number, AutoCompleteOptionType>>({});
  // Current Selected Entity
  const [selectedEntity, setSelectedEntity] = React.useState<AutoCompleteOptionType | null>(null);

  const {
    query: entitySearchQuery,
    dataKey,
    getAllEntities,
  } = useMemo(() => getEntitySearchParams(entityType) || ({} as SearchParam), [entityType]);

  const {
    data: allEntitiesData,
    loading: allEntitiesLoading,
    error: allEntitiesError,
  } = useQueryPagination(entitySearchQuery, dataKey, {
    pageSize: 1500,
    uniqueKey: 'uuid',
    skip: !getAllEntities,
  });

  const variables: PagingSortingVariables = useMemo(
    () => ({
      pagination: {
        pageSize: 20,
        pageNumber: 0,
      },
      filter: {
        by: [
          ...queryFilterBy,
          {
            // eslint-disable-next-line no-restricted-globals
            key: !isNaN(Number(inputValue)) ? 'id' : 'name',
            value: inputValue,
            operator: FilterByOperator.CONTAINS,
          },
        ],
      },
    }),
    [inputValue, queryFilterBy],
  );

  const {
    data: pagedData,
    loading: pagedLoading,
    error: pagedError,
  } = usePagedQuery(entitySearchQuery.query, dataKey, variables, {
    skip: getAllEntities,
  });

  const data = useMemo(
    () => (getAllEntities ? allEntitiesData : pagedData),
    [allEntitiesData, getAllEntities, pagedData],
  );
  const loading = useMemo(
    () => (getAllEntities ? allEntitiesLoading : pagedLoading),
    [allEntitiesLoading, getAllEntities, pagedLoading],
  );
  const error = useMemo(
    () => (getAllEntities ? allEntitiesError : pagedError),
    [allEntitiesError, getAllEntities, pagedError],
  );

  const mapResultsToEntities = useCallback((): AutoCompleteOptionType[] => {
    if (loading || error) {
      return [];
    }

    const results = data || [];

    return getFilteredResults(results, filterBy)
      .map((entity) => ({
        label: `${entity.name} (${entity.id})`,
        value: entity.id,
        icon: entity.displayImageUrl,
      }))
      .sort(sortByLabel());
  }, [loading, error, data, filterBy]);

  useEffect(() => {
    const newEntities = mapResultsToEntities();
    setEntities(newEntities);
    setEntitiesHash(keyBy(newEntities, 'value'));
  }, [mapResultsToEntities]);

  useEffect(() => {
    if (value && entitiesHash) {
      setSelectedEntity(entitiesHash[value]);
    }
  }, [value, entitiesHash]);

  const onChangeHandler = useCallback(
    (_event: any, newValue: AutoCompleteOptionType | null) => {
      setSelectedEntity(newValue);
      if (typeof onSelect === 'function') {
        onSelect(newValue);
      }
    },
    [onSelect],
  );

  return (
    <div className={classes.root}>
      <Autocomplete
        value={selectedEntity}
        onChange={onChangeHandler}
        onInputChange={onInputChangeProp ?? onInputChange}
        id="controllable-states-demo"
        options={entities}
        getOptionLabel={getOptionLabel}
        {...extraProps}
        disabled={disabled}
        onClose={onClose}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || `Search for ${entityType} by ID or Name`}
            variant="outlined"
            helperText={helperText}
            disabled={disabled}
            error={hasError}
          />
        )}
        renderOption={(option: AutoCompleteOptionType) => {
          const { label: optionLabel, icon } = option;
          return (
            <>
              <Avatar src={icon} alt={optionLabel} style={{ width: '1.2rem', height: '1.2rem' }} />
              <span>{optionLabel}</span>
            </>
          );
        }}
      />
    </div>
  );
};

export default EntitySearchAutoComplete;
