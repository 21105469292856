import { useEffect, useRef } from 'react';
import * as CronofyElements from 'cronofy-elements';

import { CronofyAvailabilityViewer, CronofyAvailabilityViewerOptions } from '../../types/cronofyTypes';

const useCronofyAvailabilityViewerConfiguration = (options: CronofyAvailabilityViewerOptions): void => {
  const element = useRef<CronofyAvailabilityViewer | null>(null);

  // Onload
  useEffect(() => {
    if (!element.current) {
      element.current = CronofyElements.AvailabilityViewer(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update
  useEffect(() => {
    if (element?.current) {
      element.current.update(options);
    }
  }, [options]);
};

export default useCronofyAvailabilityViewerConfiguration;
