import React, { FC } from 'react';
import { ProjectFinishAnswer } from '@mayple/types';
import { UpdateProjectAutomaticRenewalMutation } from 'growl-graphql/dist/mutations/UpdateProjectAutomaticRenewalMutation';

import { UpdateProjectAutomaticRenewalProps } from './types';
import getLabels from './logic';

import useMutation from '../../../../../../fe_common/client/hooks/useMutation';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { clientLogger } from '../../../../../../fe_common/client/services/logger';
import LabelValue from '../../../../../../fe_common/client/components/display/LabelValue';
import ProjectStopReasonsDialog from '../../../../../../fe_common/client/components/editables/ProjectStopReasonsDialog';
import { useDialog } from '../../../../../../fe_common/client/hooks';

const UpdateProjectAutomaticRenewal: FC<UpdateProjectAutomaticRenewalProps> = (props) => {
  const { automaticRenewal, project, onSuccess } = props;

  const { fundsRemainingBehavior, id: projectId, projectFinishAnswers, projectFinishReasonDescription } = project;

  const { open: isModalOpen, openDialog, closeDialog } = useDialog(false);
  const { mutate: updateProjectAutomaticRenewalMutation } = useMutation(UpdateProjectAutomaticRenewalMutation);

  const { title, btnLabel, calloutMsg } = getLabels(automaticRenewal);

  const startDisableFlow = () => {
    if (!fundsRemainingBehavior) {
      alert('Please set the Funds Remainder Behavior before stopping the project.');
    } else {
      openDialog();
    }
  };

  const handleEnableAutoRenewal = async () => {
    const question = `Are you sure you want to enable project ${projectId} automatic renewal?`;
    const answer = window.confirm(question);
    if (!answer) {
      return;
    }

    await updateProjectAutomaticRenewalMutation({
      variables: {
        projectId,
        automaticRenewal: true,
      },
    });
    //
    onSuccess();
  };

  const handleDisableAutoRenewal = async (formValues: any) => {
    clientLogger.debug(`You submitted:\n\n${JSON.stringify(formValues, null, 2)}`);
    await updateProjectAutomaticRenewalMutation({ variables: formValues });

    onSuccess();
  };

  const onChangeHandler = () => {
    if (automaticRenewal) {
      startDisableFlow();
    } else {
      handleEnableAutoRenewal();
    }
  };

  return (
    <div>
      <TextDisplay variant="h6" gutterBottom>
        {title}
      </TextDisplay>
      <TextDisplay gutterBottom>
        The project will stop at the end of the monthly cycle, and no next monthly cycle will take place. The last
        monthly cycle then proceeds to the final billing review, when billing details must be confirmed prior to
        actually performing the final billing.
      </TextDisplay>

      <Button color="secondary" variant="contained" label={btnLabel} onClick={onChangeHandler} />

      <CalloutMessage message={calloutMsg} type={automaticRenewal ? 'success' : 'warning'} margin />

      {!!projectFinishAnswers?.length && (
        <>
          {(projectFinishAnswers || []).map((answer: ProjectFinishAnswer) => {
            const [answerValue] = answer.values || [];

            return (
              <LabelValue
                key={`projectFinishReason-${answer.questionId}`}
                label={answer.questionId}
                value={answerValue?.value}
              />
            );
          })}
        </>
      )}

      {!!projectFinishReasonDescription && (
        <LabelValue label="Additional description" value={projectFinishReasonDescription} />
      )}

      <ProjectStopReasonsDialog
        handleStopProject={handleDisableAutoRenewal}
        project={project}
        isComponentCustomerFacing={false}
        isStopProjectImmediately={false}
        handleCancel={closeDialog}
        isOpen={isModalOpen}
      />
    </div>
  );
};

export default UpdateProjectAutomaticRenewal;
