import { getCrackleUrl } from '../app/utils';

import { URL_PARAMS } from '../../fe_common/client/app/consts';
import { handleClientError } from '../../fe_common/client/services/logger';

export type AdditionalUrlParameters = {
  emailAddress?: string;
  asEntity?: 'asCompany' | 'asMarketer' | 'asInternalTeam';
  maypleUniversalTrackingId?: string;
  register?: boolean;
};

export const getUserActionTokenUrl = (token: string, additionalUrlParameters: AdditionalUrlParameters): string => {
  const crackleUrl = getCrackleUrl();

  if (!token) {
    handleClientError(null, 'Missing token value');
    return '';
  }

  const tokenUrl = new URL('/login', crackleUrl);

  tokenUrl.searchParams.append(URL_PARAMS.PROJECT_LEAD_CREATE_TOKEN, token);

  const { emailAddress, maypleUniversalTrackingId, asEntity, register } =
    additionalUrlParameters || ({} as AdditionalUrlParameters);

  if (emailAddress) {
    tokenUrl.searchParams.append(URL_PARAMS.EMAIL, emailAddress);
  }

  if (maypleUniversalTrackingId) {
    tokenUrl.searchParams.append(URL_PARAMS.MAYPLE_UNIVERSAL_TRACKING_ID, maypleUniversalTrackingId);
  }

  if (asEntity) {
    tokenUrl.searchParams.append(asEntity, '1');
  }

  if (register) {
    tokenUrl.searchParams.append('register', '1');
  }

  return tokenUrl.href;
};

export default getUserActionTokenUrl;
