import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { FilterType } from './types';
import { handleFilterToggle } from './logic';
import FeedAuthorAvatar from '../FeedAuthorAvatar';

const useStyles = makeStyles((/* theme */) => ({
  root: {},
  authorName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function TeamMembersFilter({ teamMembers, selectedMembers, onFilterChange }) {
  const classes = useStyles();

  const handleToggle = (value) => handleFilterToggle(value, selectedMembers, onFilterChange, FilterType.TEAM_MEMBERS);

  return (
    <List className={classes.root}>
      {(teamMembers || []).map((author) => {
        const key = `TeamMemberFilter-${author.uuid}`;
        return (
          <ListItem key={key}>
            <ListItemAvatar>
              <FeedAuthorAvatar author={author} size="small" />
            </ListItemAvatar>
            <ListItemText
              title={author.name}
              primary={author.name}
              classes={{
                primary: classes.authorName,
              }}
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(author.uuid)}
                checked={selectedMembers.indexOf(author.uuid) !== -1}
                inputProps={{ 'aria-labelledby': key }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

TeamMembersFilter.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.object),
  selectedMembers: PropTypes.arrayOf(PropTypes.string),
  onFilterChange: PropTypes.func,
};

TeamMembersFilter.defaultProps = {
  teamMembers: [],
  selectedMembers: [],
  onFilterChange: () => {},
};
export default TeamMembersFilter;
