import { CronofyAccount, Integration } from '@mayple/types';
import get from 'lodash/get';

enum Texts {
  title = 'Calendar',
  connectCalendar = 'Connect My Calendar',
  providerServiceTemplate = '{providerService} calendar',
  editAvailability = 'edit availability',
  confirmDialogTitleDisconnectMessageTemplate = 'Disconnect {profileName}?',
  confirmDialogContent = 'Your clients won’t be able to schedule new meetings with you using this calendar.',
  addIntegrationErrorMessage = 'There was a problem while saving calendar integration.',
}

/**
 * This is a mapper between providerService to our icons
 * possible values: cronofy, exchange, google, gsuite, icloud, office365, outlook_com
 *
 * @param providerService
 */
export const getProviderIconFromProviderService = (providerService: string): string => {
  const mapper = {
    exchange: 'exchange',
    google: 'google',
    gsuite: 'google',
    icloud: 'apple',
    office365: 'office365',
    outlook_com: 'outlook',
  };

  return get(mapper, providerService, '');
};

export const useCalendarIntegrationText = (calendarIntegration: Integration | null): Record<string, any> => {
  const { providerServiceTemplate, confirmDialogTitleDisconnectMessageTemplate } = Texts;

  let primary = '';
  let secondary = '';
  let providerName;
  let providerService;
  let profileName;
  let providerIcon;
  let confirmDialogTitleDisconnectMessage;

  if (calendarIntegration != null) {
    const { cronofyAccount = {} as CronofyAccount } = calendarIntegration;
    profileName = cronofyAccount?.profileName || '';
    providerName = cronofyAccount?.providerName || '';
    providerService = cronofyAccount?.providerService || '';
    providerIcon = getProviderIconFromProviderService(providerService);
    confirmDialogTitleDisconnectMessage = confirmDialogTitleDisconnectMessageTemplate.replace(
      /{profileName}/g,
      profileName
    );
    primary = providerServiceTemplate.replace(/\{providerService\}/g, providerService);
    secondary = profileName;
  }

  return {
    ...Texts,
    primary,
    secondary,
    providerName,
    providerService,
    providerIcon,
    profileName,
    confirmDialogTitleDisconnectMessage,
  };
};

export default Texts;
