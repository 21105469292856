import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Project } from '@mayple/types';
import { ScheduleProjectLaunchMutation } from 'growl-graphql/dist/mutations/project/ScheduleProjectLaunchMutation';

import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import getMerchantInitiatedIrregularChargeReasoningFromUser from '../../../../../../logic/getMerchantInitiatedIrregularChargeReasoningFromUser';

import useMutation from '../../../../../../../fe_common/client/hooks/useMutation';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import { formatDateString } from '../../../../../../../fe_common/client/services/utils';
import { getProjectLifeCycleStatus } from '../../../../../../../fe_common/client/logic/project';
import EditableComponent from '../../../../../../../fe_common/client/components/molecules/EditableComponent';
import { WizardStepField } from '../../../../../../../fe_common/client/components/molecules/WizardStepField';

const useStyles = makeStyles(() =>
  createStyles({
    root: { display: 'flex', gap: '2rem', width: '100%', justifyContent: 'space-between', alignItems: 'center' },
    label: {},
    value: {
      fontWeight: 'bold',
    },
  }),
);

interface EditProjectLaunchDateProps {
  project: Project;
  onUpdateSuccess?: () => void;
  classes?: Record<string, string>;
}

const EditProjectLaunchDate: React.FC<EditProjectLaunchDateProps> = (props) => {
  const classes = useStyles(props);
  const { project = {} as Project, onUpdateSuccess } = props;

  const mutation = useMutation(ScheduleProjectLaunchMutation);

  const launchDate = project?.projectLaunchParameters?.launchDate;
  const projectId = project?.id;

  const view = (
    <div className={classes.root}>
      <TextDisplay className={classes.label}>Project Launch Date</TextDisplay>
      <TextDisplay classes={{ root: classes.value }}>
        {launchDate ? formatDateString(launchDate) : 'Not set'}
      </TextDisplay>
    </div>
  );

  const fields = useMemo(
    () => [
      {
        name: 'launchDate',
        type: WizardStepField.types.DATE_PICKER,
        label: 'Project Launch Date',
        fieldProps: {
          minDate: moment.utc().add(1, 'd').hour(0).minutes(0).millisecond(0).toDate(),
          views: ['date'],
          openTo: 'date',
          dateFormat: 'LL',
        },
      },
    ],
    [],
  );

  const { isDiscovery: enableInDiscovery, isOnboarding: enableInOnboarding } = getProjectLifeCycleStatus(project);
  const enableEdit = !!enableInDiscovery || !!enableInOnboarding;

  const handleValuesBeforeSave = useCallback(
    (values: Record<string, any>) => {
      const merchantInitiatedIrregularChargeReasoning = getMerchantInitiatedIrregularChargeReasoningFromUser(project);

      return {
        projectId,
        projectLaunchParameters: {
          ...values,
        },
        merchantInitiatedIrregularChargeReasoning,
      };
    },
    [project, projectId],
  );

  return (
    <EditableComponent
      view={view}
      fields={fields}
      enableEdit={enableEdit}
      mutation={mutation}
      initialValues={{ launchDate }}
      handleValuesBeforeSave={handleValuesBeforeSave}
      onSave={onUpdateSuccess}
    />
  );
};

export default EditProjectLaunchDate;
