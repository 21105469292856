import React from 'react';
import { ProjectLifeCycleStatus } from '@mayple/types';
import { CellInfo, Column } from 'react-table-6';

import {
  getBillingConfigurationDiscounts,
  getBillingModelTerms,
  getBillingPlan,
  getBillingTypes,
  getCyclePhaseValue,
  getListOfServicesFromCycle,
  getWrappedServicesData,
} from './logic';

import { colors } from '../../../../../fe_common/client/app/theme';
import { COUNTRIES_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';
import { formatFloatCurrency } from '../../../../../fe_common/client/services/utils';
import { companyLinkFormatter, marketerLinkFormatter, projectLinkFormatter } from '../../../../logic/linkFormatters';
import { emphasizeWithColor, notEqualComparison } from '../../../../../fe_common/client/services/reactTableHelpers';
import { dateTimeFormatter, yesNoFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import {
  AssignedGrowthStrategistFilter,
  BillingAgreementFilter,
  BillingPlanFilter,
  ProjectLifeCycleFilter,
  ProjectSpecialSubTypeFilter,
  ProjectSpecialTypeFilter,
  YesNoFilter,
} from '../../../../../fe_common/client/services/reactTableHelpers/filters';
import {
  assignedGrowthStrategistFilterMethod,
  billingAgreementFilterMethod,
  companyBilledFilterMethod,
  emptyValueFilterMethod,
  getMarketerSubmittedMediaSpend,
  hasDebtsFilterMethod,
  marketerMonthlyMediaSpendFilterMethod,
  projectLifeCycleFilterMethod,
  projectSpecialSubTypeFilterMethod,
  projectSpecialTypeFilterMethod,
  yesNoFilterMethod,
  yesNoUndefinedFilterMethod,
} from '../../../../../fe_common/client/services/reactTableHelpers/filterMethods';

const excludedValues = [ProjectLifeCycleStatus.ABANDONED, ProjectLifeCycleStatus.DISCOVERY];

const BILLING_CYCLE_TABLE_COLUMNS: Column[] = [
  {
    id: 'company',
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value }) => companyLinkFormatter(value),
    minWidth: 200,
    // fixed: 'left',
  },
  {
    id: 'project',
    Header: 'Project',
    accessor: 'project',
    Cell: ({ value }) => projectLinkFormatter(value),
    minWidth: 200,
    // fixed: 'left',
  },
  {
    id: 'projectId',
    accessor: 'projectId',
    show: false,
  },
  {
    id: 'projectLifecycleStatus',
    Header: 'Project Lifecycle Status',
    accessor: 'project.projectLifeCycleStatus',
    // eslint-disable-next-line react/display-name
    Filter: (filterProps) => <ProjectLifeCycleFilter excludedValues={excludedValues} {...filterProps} />,
    filterMethod: projectLifeCycleFilterMethod,
  },
  {
    id: 'specialType',
    Header: 'Project Special Type',
    accessor: 'project.specialType',
    // eslint-disable-next-line react/display-name
    Filter: (filterProps) => <ProjectSpecialTypeFilter {...filterProps} />,
    filterMethod: projectSpecialTypeFilterMethod,
  },
  {
    id: 'specialSubType',
    Header: 'Project Special SubType',
    accessor: 'project.specialSubType',
    // eslint-disable-next-line react/display-name
    Filter: (filterProps) => <ProjectSpecialSubTypeFilter {...filterProps} />,
    filterMethod: projectSpecialSubTypeFilterMethod,
  },
  {
    id: 'mainGrowthStrategistId',
    accessor: 'project.mainGrowthStrategistId',
    show: false,
  },
  {
    id: 'mainGrowthStrategist',
    Header: 'Growth Strategist',
    accessor: 'project.mainGrowthStrategist',
    // eslint-disable-next-line react/display-name
    Filter: (filterProps) => <AssignedGrowthStrategistFilter {...filterProps} />,
    Cell: ({ value }) => value?.name || '',
    filterMethod: assignedGrowthStrategistFilterMethod,
    minWidth: 150,
  },
  {
    id: 'cycleId',
    Header: 'Cycle Id',
    accessor: 'cycle.id',
  },
  {
    id: 'readyForFinalBilling',
    Header: 'Ready for final billing',
    accessor: 'cycle.isReadyForFinalBilling',
    Filter: YesNoFilter,
    filterMethod: yesNoUndefinedFilterMethod,
    Cell: ({ value }) => emphasizeWithColor(yesNoFormatter(value), 'No'),
    minWidth: 150,
  },
  {
    id: 'cycleBilled',
    Header: 'Cycle Billed',
    accessor: 'cycle.phase',
    Cell: ({ value }) => emphasizeWithColor(yesNoFormatter(getCyclePhaseValue(value)), 'No'),
    minWidth: 120,
    Filter: YesNoFilter,
    filterMethod: companyBilledFilterMethod,
  },
  {
    id: 'currency',
    Header: 'Currency',
    accessor: 'project.fundAccount.currency',
  },
  {
    id: 'estimatedTotalCost',
    Header: 'Estimated Total Cost',
    accessor: 'cycle.estimatedTotalCost',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 160,
  },
  {
    id: 'actualTotalCost',
    Header: 'Actual Total Cost',
    accessor: 'cycle.actualTotalCost',
    Cell: (cellInfo: CellInfo): React.ReactNode => {
      const { value, row } = cellInfo;
      const { estimatedTotalCost = 0 } = row;
      return emphasizeWithColor(
        formatFloatCurrency(value),
        formatFloatCurrency(estimatedTotalCost),
        notEqualComparison,
        colors.yellow_lighter,
      );
    },
    minWidth: 160,
  },

  {
    id: 'nextMonthEstimatedTotalCost',
    Header: 'Next Month Estimated Total Cost',
    accessor: 'nextCycle.estimatedTotalCost',
    Cell: (cellInfo: CellInfo): React.ReactNode => {
      const { value, row } = cellInfo;
      const { estimatedTotalCost = 0 } = row;
      return emphasizeWithColor(
        formatFloatCurrency(value),
        formatFloatCurrency(estimatedTotalCost),
        notEqualComparison,
        colors.yellow_lighter,
      );
    },
    minWidth: 160,
  },

  {
    id: 'projectCycleBalance',
    Header: 'Project Cycle Balance',
    accessor: 'cycle.fundAccount.balance',
    Cell: (cellInfo: CellInfo): React.ReactNode => {
      const { value, row } = cellInfo;
      const { estimatedTotalCost = 0 } = row;
      return emphasizeWithColor(
        formatFloatCurrency(value),
        formatFloatCurrency(estimatedTotalCost),
        notEqualComparison,
        colors.yellow_lighter,
      );
    },
    minWidth: 160,
  },
  {
    id: 'projectBalance',
    Header: 'Project Balance',
    accessor: 'project.fundAccount.balance',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 160,
  },
  {
    id: 'companyBalance',
    Header: 'Company Balance',
    accessor: 'company.fundAccount.balance',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 160,
  },
  {
    id: 'debts',
    Header: 'Debts',
    accessor: 'company.fundAccount.totalDebt',
    Cell: ({ value }) => emphasizeWithColor(formatFloatCurrency(value), formatFloatCurrency(0), notEqualComparison),
    minWidth: 120,
    Filter: YesNoFilter,
    filterMethod: hasDebtsFilterMethod,
  },
  {
    id: 'billingType',
    Header: 'Manual / Automatic Billing',
    accessor: 'company.fundAccount',
    Cell: ({ value }) => getBillingTypes(value),
    minWidth: 210,
    Filter: BillingAgreementFilter,
    filterMethod: billingAgreementFilterMethod,
  },
  {
    id: 'problemsReadingAdSpend',
    Header: 'Problems Reading Ad Accounts',
    accessor: 'problemsReadingAdSpend',
    Cell: ({ value }) => emphasizeWithColor(yesNoFormatter(value), 'Yes'),
    minWidth: 160,
    Filter: YesNoFilter,
    filterMethod: yesNoFilterMethod,
  },

  {
    id: 'serviceType',
    Header: 'Service Types',
    accessor: 'cycle',
    Cell: ({ value }) => getWrappedServicesData(value),
    minWidth: 120,
  },

  {
    id: 'services',
    Header: 'Services',
    accessor: 'cycle',
    Cell: ({ value }) => getListOfServicesFromCycle(value).join(', '),
    minWidth: 120,
  },

  {
    id: 'billingConfigurationDiscounts',
    Header: 'Discounts',
    accessor: 'cycle.billingConfiguration',
    Cell: ({ value }) => getBillingConfigurationDiscounts(value),
    minWidth: 250,
  },
  {
    id: 'billingPlan',
    Header: 'PPC Billing Plan',
    accessor: 'cycle.billingConfiguration.planName',
    Cell: ({ value }) => getBillingPlan(value),
    minWidth: 200,
    Filter: BillingPlanFilter,
    filterMethod: emptyValueFilterMethod,
  },
  {
    id: 'billingModelTerms',
    Header: 'PPC Billing Plan Details',
    accessor: 'cycle.billingConfiguration',
    Cell: ({ value }) => getBillingModelTerms(value),
    minWidth: 120,
  },
  {
    id: 'estimatedMediaBudget',
    Header: 'Estimated PPC Media Budget',
    accessor: 'cycle.estimatedMediaBudget',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 120,
  },
  {
    id: 'actualMediaSpend',
    Header: 'Actual PPC Media Spend',
    accessor: 'cycle.actualMediaSpend',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 120,
  },

  {
    id: 'marketer',
    Header: 'Marketer',
    accessor: 'participant.marketer',
    Cell: ({ value }) => marketerLinkFormatter(value),
    minWidth: 200,
  },
  {
    id: 'marketerCountry',
    Header: 'Marketer Country',
    accessor: 'participant.marketer.marketerProfile.contactDetails.postalAddress.country',
    // @ts-ignore
    Cell: ({ value }) => COUNTRIES_LABELS_LOOKUP[value] || value || '',
    minWidth: 120,
  },
  {
    id: 'actualMaypleFee',
    Header: "Actual Mayple's Cut",
    accessor: 'cycle.actualMaypleFee',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 120,
  },
  {
    id: 'actualMarketerFee',
    Header: 'Actual Marketer Cut (no tax)',
    accessor: 'cycle.actualMarketerFee',
    Cell: ({ value }) => formatFloatCurrency(value),
    minWidth: 120,
  },
  {
    id: 'marketerMonthlyMediaSpend',
    Header: 'Marketer submitted media spend report',
    accessor: 'marketerMonthlyMediaSpend',
    Cell: ({ value }) => emphasizeWithColor(yesNoFormatter(getMarketerSubmittedMediaSpend(value)), 'No'),
    Filter: YesNoFilter,
    filterMethod: marketerMonthlyMediaSpendFilterMethod,
    minWidth: 120,
  },
  {
    id: 'totalMediaSpendReported',
    Header: 'Total media spend reported by the expert',
    accessor: 'marketerMonthlyMediaSpend.totalMediaSpend',
    Cell: ({ value }) => (value != null ? formatFloatCurrency(value) : value),
    minWidth: 120,
  },
  {
    id: 'automaticRenewalEnabled',
    Header: 'Automatic Renewal Enabled',
    accessor: 'project.automaticRenewal',
    Cell: ({ value }) => yesNoFormatter(value),
    Filter: YesNoFilter,
    filterMethod: yesNoFilterMethod,
  },
  {
    id: 'projectCreatedDate',
    Header: 'Project Created Date',
    accessor: 'project.created',
    Cell: ({ value }) => dateTimeFormatter(value),
    minWidth: 120,
  },
  {
    id: 'projectStartDate',
    Header: 'Project Start Date',
    accessor: 'project.fundedAt',
    Cell: ({ value }) => dateTimeFormatter(value),
    minWidth: 120,
  },
  {
    id: 'cycleStartDate',
    Header: 'Cycle Start Date',
    accessor: 'cycle.startDate',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
  {
    id: 'cycleEndDate',
    Header: 'Cycle End Date',
    accessor: 'cycle.endDate',
    Cell: ({ value }) => dateTimeFormatter(value),
  },

  {
    id: 'marketerFirstSubmitAt',
    Header: 'HC submitted date (by the expert)',
    accessor: 'project.existingMarketingEvaluation.marketerFirstSubmitAt',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
  {
    id: 'meetingDueDate',
    Header: 'HC Review meeting date',
    accessor: 'project.existingMarketingEvaluation.meetingDueDate',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
  {
    id: 'approvedByGS',
    Header: 'HC approved by GS',
    accessor: 'project.existingMarketingEvaluation.approvedByGS',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
  {
    id: 'approvedByCompany',
    Header: 'HC approved by client',
    accessor: 'project.existingMarketingEvaluation.approvedByCompany',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
  {
    id: 'companyWantsToContinueToAdditionalPackages',
    Header: 'Continue to implementation',
    accessor: 'project.existingMarketingEvaluation.companyWantsToContinueToAdditionalPackages',
  },
  {
    id: 'finishedAt',
    Header: 'Finish date',
    accessor: 'project.finishedAt',
    Cell: ({ value }) => dateTimeFormatter(value),
  },
];

export default BILLING_CYCLE_TABLE_COLUMNS;
