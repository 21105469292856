import React, { FC, useMemo } from 'react';
import { UserType } from '@mayple/types';
import { RowRenderProps, SortingRule } from 'react-table-6';
import { AdminAccessibleUsersPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleUsersPagedQuery';

import { USER_TYPE_LABELS, USER_TYPE_LABELS_LOOKUP } from './consts';
import { userLinkFormatter } from '../../logic/linkFormatters';

import QueryTable, {
  EqualsFilter,
  EnumFilter,
  ContainsFilter,
} from '../../../fe_common/client/components/molecules/QueryTable';

const initialSorted: SortingRule[] = [
  {
    id: 'id',
    desc: true,
  },
];

const UsersTable: FC = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Filter: EqualsFilter({ key: 'id' }),
      },
      {
        Header: 'Name',
        accessor: 'displayName', // String-based value accessors!
        minWidth: 200,
        Cell: (data: RowRenderProps) => userLinkFormatter(data.original),
        Filter: ContainsFilter({ key: 'displayName' }),
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        minWidth: 200,
        Filter: ContainsFilter({ key: 'emailAddress' }),
      },
      {
        Header: 'User Type',
        accessor: 'userType',
        Cell: ({ value }: { value: UserType }) => USER_TYPE_LABELS_LOOKUP[value] || 'Unknown user type',
        Filter: EnumFilter({ key: 'userType', items: USER_TYPE_LABELS }),
      },

      // TODO: This can be enabled after we will solve performance issue when querying for companies and marketers
      /* {
       Header:       'Companies',
       accessor:     'companies',
       Cell:         ({ value }) => {
       return (value || []).map((company) => {
       return (<div key={company.uuid}>{companyLinkFormatter(company)}</div>);
       });
       },
       filterMethod: filterByAnotherField('flatCompanies'),
       }, {
       Header:       'Marketers',
       accessor:     'marketers',
       Cell:         ({ value }) => {
       return (value || []).map((marketer) => {
       return (<div key={marketer.uuid}>{marketerLinkFormatter(marketer)}</div>);
       });
       },
       filterMethod: filterByAnotherField('flatMarketers'),
       }, {
       show: false,
       accessor:     'flatCompanies',
       }, {
       show: false,
       accessor:     'flatMarketers',
       }, */
    ],
    [],
  );

  return (
    <QueryTable
      queryClass={AdminAccessibleUsersPagedQuery}
      entityKey="usersPaged"
      columns={columns}
      initialSorted={initialSorted}
    />
  );
};

export default UsersTable;
