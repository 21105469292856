import React, { FC } from 'react';
import { Marketer } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import DivestMarketerFunds from '../components/DivestMarketerFunds';
import MarketerPayoutDetails from '../components/MarketerPayoutDetails';
import FundAccountDetails from '../../../components/cpanel/components/molecules/FundAccountDetails';
import MarketersPayoutReview from '../../../components/cpanel/components/organisms/Marketer/MarketersPayoutReview';

import Section from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

export type MarketerFundsTabProps = {
  marketer: Marketer;
  classes?: Record<string, string>;
};

const MarketerFundsTab: FC<MarketerFundsTabProps> = (props) => {
  const { marketer } = props;
  const classes = useStyles(props);
  const { id: marketerId, fundAccount } = marketer;

  return (
    <div className={classes.root}>
      <Section title="Marketer Fund Account" noBorder noTopMargin>
        <SectionContent>
          <DivestMarketerFunds marketer={marketer} />
        </SectionContent>
        <FundAccountDetails fundAccount={fundAccount} />
      </Section>

      <Section title="Marketer Payout Details">
        <SectionContent>
          <MarketerPayoutDetails marketerFundAccount={fundAccount} />
        </SectionContent>
      </Section>

      <MarketersPayoutReview marketerId={marketerId} />
    </div>
  );
};

export default MarketerFundsTab;
