import { CreateCompanyUserInvitationTokenMutation } from 'growl-graphql/dist/mutations/CreateCompanyUserInvitationTokenMutation';
import { CreateMarketerUserInvitationTokenMutation } from 'growl-graphql/dist/mutations/CreateMarketerUserInvitationTokenMutation';
import { CreateInternalTeamUserInvitationTokenMutation } from 'growl-graphql/dist/mutations/CreateInternalTeamUserInvitationTokenMutation';

import { ApplicationAccount, CreateEntityUserInvitationTokenParams } from './types';
import { ENTITY_TYPE, ENTITY_TYPES } from '../../../../../app/consts';

export const createEntityUserInvitationTokenMap: Record<ApplicationAccount, CreateEntityUserInvitationTokenParams> = {
  [ENTITY_TYPE.COMPANY]: {
    query: CreateCompanyUserInvitationTokenMutation,
    entityIdKey: 'companyId',
    asEntityParam: 'asCompany',
  },
  [ENTITY_TYPE.MARKETER]: {
    query: CreateMarketerUserInvitationTokenMutation,
    entityIdKey: 'marketerId',
    asEntityParam: 'asMarketer',
  },
  [ENTITY_TYPE.INTERNAL_TEAM]: {
    query: CreateInternalTeamUserInvitationTokenMutation,
    entityIdKey: 'internalTeamId',
    asEntityParam: 'asInternalTeam',
  },
};

export const getCreateEntityUserInvitationTokenParams = (
  entityType: ApplicationAccount,
): CreateEntityUserInvitationTokenParams => {
  if (!entityType || !ENTITY_TYPES.includes(entityType)) {
    throw new Error('Invalid parameter value was supplied for entityType.');
  }

  return createEntityUserInvitationTokenMap[entityType];
};
