import { AccountType, CronofyAvailabilityProfiles } from '@mayple/types';

import {
  CronofyAvailabilityOptionsStyles,
  CronofyAvailabilityViewerMode,
  CronofyAvailabilityViewerSlotSelection,
  CronofyMeetingDuration,
  Day,
  Slot,
  AvailabilityViewerCallbackObject,
  ValidTime,
  WeeklyPeriodDay,
} from '../types/cronofyTypes';
import { Account } from '../types';

export type AvailabilityViewerAccountsGroup = {
  accounts?: Account[];
  accountsGroupSelectionType: AccountType;
};

export interface AvailabilityViewerProps {
  mainAccountsGroup: AvailabilityViewerAccountsGroup;
  initOptions?: AvailabilityViewerOptions;
  defaultFilter?: AvailabilityViewerFilterData;
  showFilter?: boolean;
  selectedSlot?: Slot | undefined;
}

export interface AvailabilityViewerConfig {
  startTime?: ValidTime;
  endTime?: ValidTime;
  interval?: CronofyMeetingDuration;
  weekStartDay?: Day;
  defaultWeeklyPeriods?: WeeklyPeriodDay[];
  mode?: CronofyAvailabilityViewerMode;
  maxSelectionCount?: number | boolean;
  allowExpansion?: boolean;
  boundsControl?: boolean;
  slotSelection?: CronofyAvailabilityViewerSlotSelection;
  timeZone?: string;
  managedAvailability?: boolean;
}

export interface AvailabilityViewerOptions {
  accounts: Account[];
  elementToken?: string;
  elementTargetId?: string;
  config?: AvailabilityViewerConfig;
  demo?: boolean;
  allowExpansion?: boolean;
  cssClassNamePrefix?: string;
  styles?: CronofyAvailabilityOptionsStyles;
  callback?: (callbackObject: AvailabilityViewerCallbackObject) => void;
}

export enum AVAILABILITY_MANAGEMENT {
  VIEW_ALL = 'VIEW_ALL',
  USE_MANAGED_AVAILABILITY = 'USE_MANAGED_AVAILABILITY',
}

export type AvailabilityViewerFilterData = {
  accounts?: Account[] | null | undefined;
  selectedAccounts?: string[] | null | undefined;
  timezone?: string | null;
  enableEntitySearch?: boolean;
  availabilityManagement?: AVAILABILITY_MANAGEMENT;
  availabilityProfiles?: CronofyAvailabilityProfiles[];
  onlyShowAvailability?: boolean;
};
