import { MarketingPlanStatus, Participant, SharedFile, SharedFileCategory } from '@mayple/types';
import moment from 'moment-timezone';
import { TFunction } from 'i18next';

import { MarketingPlanStatusExtended, ParticipantStateData } from './types';

export const getTimeLeftForMarketingPlanDueDate = (
  marketingPlanDueDate?: string | null,
  noValueMessage = '3d'
): string => {
  if (!marketingPlanDueDate) {
    return noValueMessage;
  }

  const daysLeft = moment.utc(marketingPlanDueDate).diff(moment.utc(), 'days', true);
  return `${Math.round(daysLeft)}d`;
};

export const formatMeetingDate = (meetingDate: string | null, noValueMessage = ''): string => {
  if (!meetingDate) {
    return noValueMessage;
  }

  return moment.utc(meetingDate).tz(moment.tz.guess()).format('h:mm a (z) MMM D');
};

export const getMarketingPlanDueDateFormatted = (
  marketingPlanDueDate: string | null,
  noValueMessage = "Due date wasn't set yet"
): string => formatMeetingDate(marketingPlanDueDate, noValueMessage);

export const getMarketingPlanMeetingDateFormatted = (
  marketingPlanMeetingDate: string | null,
  noValueMessage = "Marketing plan meeting wasn't set yet"
): string => formatMeetingDate(marketingPlanMeetingDate, noValueMessage);

export const getStateDataFromParticipant = (participant: Participant, t: TFunction): ParticipantStateData => {
  const { marketingPlanDueDate, marketingPlanMeetingDate, marketingPlanStatus } = participant;

  const timeLeftForMarketingPlanDueDate = getTimeLeftForMarketingPlanDueDate(marketingPlanDueDate, t('daysLeft'));
  const marketingPlanDueDateFormatted = getMarketingPlanDueDateFormatted(marketingPlanDueDate, t('errorDueDateNotSet'));
  const marketingPlanMeetingDateFormatted = getMarketingPlanMeetingDateFormatted(
    marketingPlanMeetingDate,
    t('errorMarketingPlanNotSet')
  );
  const marketingPlanStatusExtended =
    marketingPlanStatus === MarketingPlanStatus.MISSING_MARKETING_PLAN && !marketingPlanMeetingDate
      ? MarketingPlanStatusExtended.MARKETING_PLAN_MEETING_NOT_SET
      : marketingPlanStatus;

  const marketingPlanFile = (participant?.sharedFiles || ([] as SharedFile[])).filter((f: SharedFile) => {
    const { category = SharedFileCategory.NONE } = f;
    return category === SharedFileCategory.MARKETING_PLAN;
  });

  return {
    participantId: participant?.id,
    marketingPlanDueDate: new Date(marketingPlanDueDate),
    timeLeftForMarketingPlanDueDate,
    marketingPlanMeetingDate,
    marketingPlanMeetingDateFormatted,
    marketingPlanStatus: marketingPlanStatusExtended,
    marketingPlanDueDateFormatted,
    marketingPlanFile,
  };
};
