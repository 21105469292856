import React, { FC } from 'react';
import { FilterByOperator, Project } from '@mayple/types';
import { AdminAccessibleProjectsQuery } from 'growl-graphql/dist/queries/AdminAccessibleProjectsQuery';

import ProjectsTable from './ProjectsTable';
import NeedAuditProjectsTable from './NeedAuditProjectsTable';

import useQueryPagination from '../../../fe_common/client/hooks/useQueryPagination';

// import ProjectsTableWithServerPaging from './ProjectsTableWithServerPaging';

const ProjectsPage: FC = () => {
  const { data, loading } = useQueryPagination(AdminAccessibleProjectsQuery, 'projects', {
    pageSize: 1000,
    filter: {
      by: [{ key: 'projectLifeCycleStatus', value: 'ABANDONED', operator: FilterByOperator.NOT_EQUALS }],
    },
  });

  return (
    <>
      <ProjectsTable data={data as Project[]} loading={loading} />
      {/* <ProjectsTableWithServerPaging /> */}
      <NeedAuditProjectsTable />
    </>
  );
};

export default ProjectsPage;
