import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';

// MUI transitions wrapper
export function Transitions(props) {
  const {
    timeout, // time it takes transition to start
    duration, // the duration which the transition is running.
    direction, // direction in case there is such an option.
    type: Transition, // MUI transition type
    children,
  } = props;
  const [render, setRerender] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setRerender(true), timeout);
    // Clear at unmount
    return () => {
      clearTimeout(id);
    };
  });

  return (
    <Transition in={render} timeout={duration} direction={direction}>
      {children}
    </Transition>
  );
}

Transitions.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.any,
  timeout: PropTypes.number,
  duration: PropTypes.number,
  direction: PropTypes.string,
};

Transitions.defaultProps = {
  type: Grow,
  timeout: 0,
  duration: 200,
  direction: undefined,
};
