import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import AllowManualPaymentAgreementChargingSwitch from './AllowManualPaymentAgreementChargingSwitch';
import EnableProjectCycleInitialBillingSwitch from './EnableProjectCycleInitialBillingSwitch';
import ProjectInvoicePaymentTermSelect from './ProjectInvoicePaymentTermSelect';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '2rem',
    },
    bottomSpace: {
      marginBottom: '2rem',
    },
  }),
);

type ProjectBillingConfigurationFormProps = {
  projectId: number;
  classes?: Record<string, string>;
};

const ProjectBillingConfigurationForm: React.FC<ProjectBillingConfigurationFormProps> = (props) => {
  const { projectId } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <AllowManualPaymentAgreementChargingSwitch projectId={projectId} />
      <EnableProjectCycleInitialBillingSwitch projectId={projectId} />
      <ProjectInvoicePaymentTermSelect projectId={projectId} />
    </div>
  );
};

export default ProjectBillingConfigurationForm;
