/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MATCHING_SIMULATOR_WEIGHTS_KEY } from './consts';
import { MatchingSimulatorWeights } from '../../types';

import { exportDataToCsv } from '../../../../../../../fe_common/client/services/ExportToCSV';
import { numericFormatter } from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';
import { getFromLocalStorage } from '../../../../../../../fe_common/client/services/utils';
import { CSVColumn } from '../../../../../../../fe_common/client/services/ExportToCSV/types';

export const mapToWeightTableData = (data: any): { fileName: string; projectId: number }[] =>
  Object.entries(data || {}).map(([fileName, fileContent]) => ({
    fileName,
    // @ts-ignore
    projectId: fileContent?.projectId,
  }));

export const getCSVColumns = (data: any): CSVColumn[] => {
  if (!data || Object.keys(data).length === 0) {
    return [];
  }

  const [weights] = Object.values(data || {}) || [];
  if (!weights) {
    return [];
  }

  // @ts-ignore
  const weightKeys = Object.keys(weights);

  return weightKeys.map((key) => ({
    id: key,
    header: key,
    accessor: key,
    formatter: numericFormatter,
  }));
};

export const getCSVItems = (data: any): unknown[] => {
  if (!data || Object.keys(data).length === 0) {
    return [];
  }

  const weightsData = Object.values(data || {}) || [];
  if (!weightsData.length) {
    return [];
  }

  return weightsData;
};

export const handleExportToCSVOnClick = (): void => {
  const data = getFromLocalStorage(MATCHING_SIMULATOR_WEIGHTS_KEY) || {};

  const columns = getCSVColumns(data);
  const items = getCSVItems(data);

  exportDataToCsv(columns, items, 'weights');
};

export const mapToMatchingSimulatorWeights = (matchingSimulatorWeights: any): MatchingSimulatorWeights => ({
  industryCategoryWeight: matchingSimulatorWeights?.INDUSTRY_CATEGORY_WEIGHT || 0,
  industrySubCategoryWeight: matchingSimulatorWeights?.INDUSTRY_SUB_CATEGORY_WEIGHT || 0,
  campaignTypeWeight: matchingSimulatorWeights?.CAMPAIGN_TYPE_WEIGHT || 0,
  targetLocationWeight: matchingSimulatorWeights?.LOCATIONS_WEIGHT || 0,
  languageWeight: matchingSimulatorWeights?.LANGUAGE_WEIGHT || 0,
  mediaBudgetWeight: matchingSimulatorWeights?.BUDGET_RANGE_WEIGHT || 0,
  successStoriesIndustryWeight: matchingSimulatorWeights?.SUCCESS_STORY_INDUSTRY_CATEGORY_WEIGHT || 0,
  successStoriesSubIndustryWeight: matchingSimulatorWeights?.SUCCESS_STORY_INDUSTRY_SUB_CATEGORY_WEIGHT || 0,
  locationAffinityWeight: matchingSimulatorWeights?.LOCATION_AFFINITY_WEIGHT || 0,
  historicalPerformanceWeight: matchingSimulatorWeights?.HISTORICAL_PERFORMANCE_WEIGHT || 0,
  levelOfServiceWeight: matchingSimulatorWeights?.LEVEL_OF_SERVICE_WEIGHT || 0,
  marketingServiceExpertiseWeight: matchingSimulatorWeights?.MARKETING_SERVICE_EXPERTISE_WEIGHT || 0,
});
