import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import classNames from 'classnames';
import { Calendar, Components, momentLocalizer, SlotInfo, SlotPropGetter } from 'react-big-calendar';

import {
  addSlots,
  DEFAULT_STEP,
  getEventsAsSlots,
  getMinMaxDatesByMode,
  getScrollToTime,
  getSelectionAction,
  getSelectionBoundaries,
  hasEventWithLessThanMinimumSlotDuration,
  mapAllSlotsToEventsSlots,
  mapInitialEventsToAllSlots,
  subtractSlots,
  validateMeetingBoundaries,
} from './logic';
import { AvailabilitySlot, AvailableMeetingSlotsSelectorProps, SelectionAction, SelectionMode } from './types';
import { AvailableMeetingSlotSelectorContextProvider } from './AvailableMeetingSlotSelectorContext';

import AvailabilityViewerContextProvider from '../AvailabilityViewer/AvailabilityViewerProvider/AvailabilityViewerContext';
import useTimezonePicker from '../AvailabilityViewer/AvailabilityViewerFilter/hooks/useTimezonePicker';
import TimezoneSelect from '../../../inputs/TimezoneSelect';
import withProvider from '../../../common/withProvider';
import CalloutMessageCollapse from '../../../common/CalloutMessageCollapse';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import './bigCalendar-override.scss';

import useStyles from './style';

const customComponents: Components = {
  // day: {
  //   // @ts-ignore
  //   event: CustomEventWrapper,
  // },
  // // @ts-ignore
  // eventWrapper: CustomEventWrapper,
  // timeSlotWrapper: CustomTimeSlotWrapper,
  // @ts-ignore
  // dayColumnWrapper: CustomDayColumnWrapper,
};

const AvailableMeetingSlotsSelector: React.FC<AvailableMeetingSlotsSelectorProps> = (props) => {
  const classes = useStyles();

  const {
    mode = SelectionMode.availability,
    step = DEFAULT_STEP,
    requiredDurationInMinutes,
    initialEvents = [],
    initialSelectionBoundaries,
    timezone,
    showTimezoneSelector = false,
    onEventsChange,
    onHasError,
  } = props;

  const [timeZone, onTimezoneChangeHandler] = useTimezonePicker(timezone);
  const [events, setEvents] = useState<AvailabilitySlot[]>([]);
  const [allSlots, setAllSlots] = useState<Set<number>>(() => mapInitialEventsToAllSlots(initialEvents, step));
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  // console.log('initialEvents', initialEvents);

  const { getNow, selectionBoundaries, localizer, scrollToTime, min, max } = useMemo(() => {
    moment.tz.setDefault(timeZone);

    const { minDate, maxDate } = getMinMaxDatesByMode(mode, timeZone);

    const selectionBoundariesTemp = getSelectionBoundaries(mode, {
      timezone: timeZone,
      initialSelectionBoundaries,
      minDate,
      maxDate,
    });

    return {
      getNow: () => moment().toDate(),
      localizer: momentLocalizer(moment),
      scrollToTime: getScrollToTime(mode, minDate, timeZone, selectionBoundariesTemp),
      selectionBoundaries: selectionBoundariesTemp,
      min: minDate,
      max: maxDate,
    };
  }, [mode, timeZone, initialSelectionBoundaries]);

  const selectionBoundariesAsSlots = useMemo(
    () => getEventsAsSlots(selectionBoundaries, step),
    [selectionBoundaries, step],
  );

  const selectionBoundariesInclusiveAsSlots = useMemo(
    () => getEventsAsSlots(selectionBoundaries, step, true),
    [selectionBoundaries, step],
  );

  const customSlotPropGetter: SlotPropGetter = useCallback(
    (date) => ({
      className: classNames({
        [classes.available]: date && selectionBoundariesAsSlots?.has(date.getTime()),
        [classes.unavailable]: date && !selectionBoundariesAsSlots?.has(date.getTime()),
        [classes.unavailable0]: date && date.getMinutes() === 0,
        [classes.unavailable15]: date && date.getMinutes() === 15,
        [classes.unavailable30]: date && date.getMinutes() === 30,
        [classes.unavailable45]: date && date.getMinutes() === 45,
      }),
    }),
    [
      classes.available,
      classes.unavailable,
      classes.unavailable0,
      classes.unavailable15,
      classes.unavailable30,
      classes.unavailable45,
      selectionBoundariesAsSlots,
    ],
  );

  useEffect(
    () => () => {
      moment.tz.setDefault(); // reset to browser TZ on unmount
    },
    [],
  );

  const onSelectSlotHandler = useCallback(
    (slotInfo: SlotInfo): boolean => {
      if ((mode === SelectionMode.availability || mode === SelectionMode.slots) && slotInfo.action === 'select') {
        // Check boundaries - verify selected time is overlapping the background events
        if (!validateMeetingBoundaries(selectionBoundariesInclusiveAsSlots, slotInfo)) {
          return false;
        }

        // Update the slots
        setAllSlots((prevState) => {
          // Need to check if we need to add or subtract selection
          const selectionAction = getSelectionAction(prevState, slotInfo, events);

          switch (selectionAction) {
            case SelectionAction.ADD:
              return addSlots(prevState, slotInfo);
            case SelectionAction.SUBTRACT:
              return subtractSlots(prevState, slotInfo);
            default:
              return prevState;
          }
        });
      }

      return true;
    },
    [mode, selectionBoundariesInclusiveAsSlots, events],
  );

  useEffect(() => {
    const newEvents = mapAllSlotsToEventsSlots(allSlots, step, timeZone);
    // console.log('newEvents', newEvents);

    // Check for minimum duration slot
    if (hasEventWithLessThanMinimumSlotDuration(newEvents, requiredDurationInMinutes)) {
      setHasError(true);
      setMessage(
        `Availability slot duration is too short. Minimum slot duration is ${requiredDurationInMinutes} minutes.`,
      );
    } else {
      setHasError(false);
      setMessage('');
    }

    setEvents(newEvents);
  }, [allSlots, requiredDurationInMinutes, step, timeZone]);

  useEffect(() => {
    onEventsChange?.(events);
  }, [events, onEventsChange]);

  useEffect(() => {
    onHasError?.(hasError);
  }, [hasError, onHasError]);

  return (
    <div className={classes.root}>
      {showTimezoneSelector && (
        <TimezoneSelect
          label="Timezone"
          helperText="Please select a timezone from the list"
          onChange={onTimezoneChangeHandler}
          defaultTimezone={timeZone}
          classes={{ root: classes.timezoneSelectorRoot }}
        />
      )}
      <AvailableMeetingSlotSelectorContextProvider
        value={{ timezone: timeZone, minDate: min, maxDate: max, eventBoundariesSlots: selectionBoundariesAsSlots }}
      >
        <Calendar
          localizer={localizer}
          getNow={getNow}
          defaultDate={scrollToTime}
          components={customComponents}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="work_week"
          views={['work_week']}
          selectable
          step={step}
          onSelectSlot={onSelectSlotHandler}
          scrollToTime={scrollToTime}
          style={{ height: 500 }}
          slotPropGetter={customSlotPropGetter}
        />
      </AvailableMeetingSlotSelectorContextProvider>
      {hasError && (
        <CalloutMessageCollapse
          classes={{ root: classes.errorMessage }}
          timeToShow={5}
          type="error"
          message={message}
          onMessageDisappear={() => {
            setMessage('');
            setHasError(false);
          }}
        />
      )}
    </div>
  );
};

// @ts-ignore
export default withProvider(AvailabilityViewerContextProvider)(AvailableMeetingSlotsSelector);
