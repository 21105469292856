import { makeStyles } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(
  createStyles({
    root: {
      '& .MuiFormGroup-root': {
        flexDirection: 'unset',
      },
      '& label': {
        marginRight: '1.6875rem',
      },
    },
    modalContainer: {
      // height: '65.4rem',
      // width: '43.2rem',
    },
    dialogTitleContainer: {
      padding: '1rem 1.5rem 0 1.5rem',
    },
    // dialogSubtitleContainer: {
    //   padding: '0 1.5rem',
    //   marginBottom: '1.25rem',
    //   color: colorPalette.gray[80],
    //   fontSize: '0.875rem',
    // },
    dialogFooter: {
      padding: '0 1.5rem 1.5rem 1.5rem',
    },
  })
);

export default useStyles;
