import React, { useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Button, { ButtonProps } from '../../../../../../fe_common/client/components/inputs/Button';
import useMarkProjectCyclesAsReadyForFinalBilling from './useMarkProjectCyclesAsReadyForFinalBilling';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface MarkProjectCyclesAsReadyForFinalBillingButton extends Omit<ButtonProps, 'label'> {
  cycleIds: string[] | undefined;
  onSuccess?: () => Promise<any>;
}

const MarkProjectCyclesAsReadyForFinalBillingButton: React.FC<MarkProjectCyclesAsReadyForFinalBillingButton> = (
  props,
) => {
  const classes = useStyles(props);
  const { cycleIds, onSuccess, disabled, ...rest } = props;
  const label = 'Mark as ready for final billing';

  const { markProjectCyclesAsReadyForFinalBilling, saving } = useMarkProjectCyclesAsReadyForFinalBilling();

  const onClickHandler = useCallback(async () => {
    await markProjectCyclesAsReadyForFinalBilling(cycleIds, onSuccess);
  }, [cycleIds, markProjectCyclesAsReadyForFinalBilling, onSuccess]);

  return (
    <div className={classes.root}>
      <Button
        label={label}
        color="primary"
        variant="contained"
        disabled={disabled || saving}
        loading={saving}
        onClick={onClickHandler}
        {...rest}
      />
    </div>
  );
};

export default MarkProjectCyclesAsReadyForFinalBillingButton;
