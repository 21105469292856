import { Country, KpiType } from '@mayple/types';
import { Mark } from '@material-ui/core/Slider';

import { MatchingSimulatorData, MatchingSimulatorWeights } from './types';

export const wizardFormName = 'ProjectMatchingSimulatorWizardForm';

export const MAX_SLIDER_VALUE = 200;
export const SLIDER_STEP_VALUE = 25;

export const defaultInitialValues: Partial<MatchingSimulatorData> = {
  estimatedMediaBudget: 10000,
  targetKPI: KpiType.CPL,
  locations: [],
  productBusinessModel: null,
  testCompanyCountry: Country.US,
  skills: [],
  languages: [],
  preferLocalMarketers: false,
  preferMarketerInTargettedLocations: true,
};

export const defaultWeights: MatchingSimulatorWeights = {
  // parameters weights: https://mayple.slite.com/app/docs/i_JNRCVmX8
  industryCategoryWeight: 80,
  industrySubCategoryWeight: 40,
  campaignTypeWeight: 0,
  targetLocationWeight: 90,
  languageWeight: 0,
  mediaBudgetWeight: 40,
  successStoriesIndustryWeight: 100,
  successStoriesSubIndustryWeight: 60,
  locationAffinityWeight: 90,
  historicalPerformanceWeight: 0,
  levelOfServiceWeight: 0,
  marketingServiceExpertiseWeight: 160,
};

export const getSliderMarks = (maxValue: number, step: number): Mark[] => {
  const sliderMarks = [] as Mark[];

  for (let index = 0; index <= maxValue; index += step) {
    sliderMarks.push({
      value: index,
      label: index.toString(),
    });
  }

  return sliderMarks;
};
