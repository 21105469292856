import { useMemo } from 'react';
import { MarketerFeePlanName, PpcBillingModelPlans } from '@mayple/types';

import { CycleStepData, CycleStepProps } from './types';
import projectBillingHelpers from '../../../../../pages/ProjectBillingPage/helpers';

import { useSharedEnumerationsQuery } from '../../../../../../fe_common/client/hooks';
import { SharedEnumerationsKeys } from '../../../../../../fe_common/client/hooks/useSharedEnumerationsQuery';

const useCycleStep = (props: CycleStepProps): CycleStepData => {
  const { formValues, project, cycle, suffix } = props;

  const { enums, loading } = useSharedEnumerationsQuery({ enumsKey: SharedEnumerationsKeys.ppcBillingModelPlans });

  const glossary = useMemo(() => projectBillingHelpers.getCycleGlossary(cycle, project), [cycle, project]);

  const billingPlanPairs = useMemo(
    () => projectBillingHelpers.createBillingPlanPairsFromSharedEnums(enums as PpcBillingModelPlans),
    [enums],
  );

  const billingConfigurationPlanName = formValues[`billingConfigurationPlanName${suffix}`];
  const marketerFeeConfigurationPlanName = formValues[`marketerFeeConfigurationPlanName${suffix}`];
  const feePartPercentage = formValues[`feePartPercentage${suffix}`];
  const minimumFeePart = formValues[`minimumFeePart${suffix}`];
  const marketerFixedFee = formValues[`marketerFixedFee${suffix}`];
  const PPCCostPerExtraChannel = formValues[`PPCCostPerExtraChannel${suffix}`];
  const PPCIncludedChannelCount = formValues[`PPCIncludedChannelCount${suffix}`];
  const showMarketerFixedInput =
    marketerFeeConfigurationPlanName === MarketerFeePlanName.fixedToMarketer ||
    marketerFeeConfigurationPlanName === MarketerFeePlanName.fixedToMayple;
  const maximumFeePart = formValues[`maximumFeePart${suffix}`];

  const showMaximumMarketerFeeWarning = maximumFeePart <= 0;

  return {
    loading,
    glossary,
    billingPlanPairs,
    billingConfigurationPlanName,
    feePartPercentage,
    minimumFeePart,
    marketerFixedFee,
    PPCCostPerExtraChannel,
    PPCIncludedChannelCount,
    showMarketerFixedInput,
    showMaximumMarketerFeeWarning,
    marketerFeeConfigurationPlanName,
  };
};
export default useCycleStep;
