import React, { useMemo } from 'react';
import { CurrencyType, ProjectCycle, ProjectCyclePhase } from '@mayple/types';

import ProjectCycleSnapshot from '../../../../../components/cpanel/components/organisms/Project/ProjectCycle/ProjectCycleSnapshot';

import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import SectionContent from '../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import Section from '../../../../../../fe_common/client/components/atoms/Section';

interface FinalizedCyclesSectionProps {
  projectId: number;
  cycles: ProjectCycle[];
  currency: CurrencyType;
}

const FinalizedCyclesSection: React.FC<FinalizedCyclesSectionProps> = (props) => {
  const { projectId, cycles, currency } = props;

  const previousCycles = useMemo(() => {
    const previousCyclesTemp = cycles.filter(
      ({ phase }) =>
        // Return only FINALIZED and ELAPSED cycles
        phase === ProjectCyclePhase.FINALIZED || phase === ProjectCyclePhase.ELAPSED,
    );

    return previousCyclesTemp.reverse().filter(({ phase }) => phase !== ProjectCyclePhase.ELAPSED);
  }, [cycles]);

  return (
    <Section title="Finalized Cycles">
      {previousCycles.length === 0 && (
        <SectionContent>
          <CalloutMessage type="info" message="No finalized cycles for this project." />
        </SectionContent>
      )}
      {previousCycles.length > 0 &&
        previousCycles.map((cycle) => (
          <ProjectCycleSnapshot
            key={`precious-cycle-${cycle.id}-for-project-${projectId}`}
            cycle={cycle}
            currency={currency}
            projectId={projectId}
            showLinkToCyclePage
          />
        ))}
    </Section>
  );
};

export default FinalizedCyclesSection;
