import React, { FC } from 'react';
import { Project } from '@mayple/types';

import { getMarketerLocationPreference, getProjectBriefProperties } from './logic';
import ProjectBriefEditDialog from './ProjectBriefEditDialog';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { PropertiesList } from '../../../../../../../fe_common/client/components/display';
import { Button } from '../../../../../../../fe_common/client/components/inputs';
import { useDialog } from '../../../../../../../fe_common/client/hooks';

import useStyles from './style';
import { PropertiesMetaType } from '../../../../../../../fe_common/client/components/display/PropertiesList';

export const getBooleanAnswer = (param: boolean): React.ReactNode => (
  <strong className={param ? 'green-text' : 'red-text'}>{param ? 'Yes' : 'No'}</strong>
);

type ProjectBriefTargetingProps = {
  project: Project | null | undefined;
  onSuccess?: () => Promise<void>;
  classes?: Record<string, string>;
};

const ProjectBriefTargeting: FC<ProjectBriefTargetingProps> = (props) => {
  const classes = useStyles(props);
  const { project, onSuccess } = props;

  const projectBriefProperties: PropertiesMetaType[] = getProjectBriefProperties();
  const marketerLocationPreference = getMarketerLocationPreference(project);
  const { open, closeDialog, openDialog } = useDialog(false);

  const data = {
    ...project,
    marketerLocationPreference,
  };

  return (
    <Section
      title="Project info"
      headerAction={<Button color="primary" label="edit project info" onClick={openDialog} />}
      subTitle="Targeting and matching properties"
      classes={{ root: classes.root }}
      noBorder
    >
      <SectionContent>
        {!project && <div> Could not get project data. </div>}
        {project && (
          <PropertiesList
            id="projectBriefData"
            data={data}
            propertiesMeta={projectBriefProperties}
            classes={{
              propertyLabel: classes.propertyLabel,
              propertyValue: classes.propertyValue,
            }}
          />
        )}
      </SectionContent>
      {project && <ProjectBriefEditDialog project={project} open={open} onClose={closeDialog} onSuccess={onSuccess} />}
    </Section>
  );
};

export default ProjectBriefTargeting;
