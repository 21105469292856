import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 32,
      marginBottom: 32,
    },
    results: {},
    message: {
      textAlign: 'center',
      marginTop: 48,
      marginBottom: 48,
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      minHeight: 400,
      height: '100%',
      width: '100%',
    },
    bottomPager: { marginTop: 32 },
  }),
);

export default useStyles;
