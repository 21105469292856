import React, { useReducer, useContext } from 'react';
import { FeedLegend } from '@mayple/types';

import { FeedEventTraits, FeedMemberType } from './types';
import { FeedDispatch, feedReducer } from './feedReducer';

export type SelectedFeedMember = {
  feedMemberType: FeedMemberType;
  feedMemberUuid: string | null;
};

export interface IFeedContext {
  filestackApiKey: string;
  newPostPlaceholder: string;
  newCommentPlaceholder: string;
  topics: any;
  enableComments: boolean;
  projectId: number | null;
  marketerId: number | null;
  isEditable: boolean;
  selectedFeedMember: null | SelectedFeedMember;
  deleteDialogOpen: boolean;
  editPostDialogOpen: boolean;
  canPostAsPuppet: boolean;
  disableAnalyticsEvents: boolean;
  puppetUsers: [];
  legend: FeedLegend;
  eventHandlers: {
    onCreatePost: () => void;
    onCreatePostComment: () => void;
    onDeletePost: () => void;
    onDeleteComment: () => void;
    onPostReaction: (traits: Record<string, any>) => void;
    onError: (errorMessage: string) => void;
  };
  traits: FeedEventTraits;
}

const FeedContext = React.createContext<IFeedContext | undefined>(undefined);
const FeedDispatchContext = React.createContext<FeedDispatch | undefined>(undefined);

type FeedProviderProps = { value: IFeedContext; children: React.ReactNode };

const FeedProvider: React.FC<FeedProviderProps> = ({ value, children }) => {
  const [state, dispatch] = useReducer(feedReducer, value);

  return (
    <FeedContext.Provider value={state}>
      <FeedDispatchContext.Provider value={dispatch}>{children}</FeedDispatchContext.Provider>
    </FeedContext.Provider>
  );
};

function useFeedState(): IFeedContext {
  const context = useContext(FeedContext);
  if (context === undefined) {
    throw new Error('useFeedState must be used within a FeedProvider');
  }
  return context;
}

function useFeedDispatch(): FeedDispatch {
  const context = useContext(FeedDispatchContext);
  if (context === undefined) {
    throw new Error('useFeedDispatch must be used within a FeedProvider');
  }
  return context;
}

export { FeedProvider, useFeedState, useFeedDispatch };
