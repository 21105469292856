import React from 'react';
import { Column, RowRenderProps, SortingRule } from 'react-table-6';

import LinkToMarketer from '../../../atoms/Links/LinkToMarketer';

import { BoolFilter, ContainsFilter } from '../../../../../../../fe_common/client/components/molecules/QueryTable';
import ExternalLink from '../../../../../../../fe_common/client/components/display/ExternalLink';

export const initialSort: SortingRule[] = [
  {
    id: 'id',
    desc: true,
  },
];

export const columns: Column[] = [
  {
    id: 'id', // Required because our accessor is not a string
    Header: 'ID',
    accessor: (d) => d.id, // Custom value accessors!
    Filter: ContainsFilter({ key: 'id' }),
  },
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    // eslint-disable-next-line react/display-name
    Cell: (d: RowRenderProps) => <LinkToMarketer marketerId={d.original.id}>{d.value}</LinkToMarketer>,
    Filter: ContainsFilter({ key: 'name' }),
    minWidth: 200,
  },
  {
    id: 'websiteAddress',
    Header: 'Website',
    accessor: 'marketerProfile.contactDetails.websiteAddress',
    // eslint-disable-next-line react/display-name
    Cell: (d: RowRenderProps) => <ExternalLink href={d.value}>{d.value}</ExternalLink>,
    sortable: false,
    filterable: false,
  },
  {
    id: 'isVetted',
    Header: 'Is Vetted',
    accessor: 'isVetted',
    Cell: (d: RowRenderProps) => (d.original?.isVetted ? 'Yes' : 'No'),
    sortable: true,
    filterable: true,
    Filter: BoolFilter({ key: 'isVetted' }),
  },
];
