import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { handleFilterToggle } from './logic';
import FeedPostTopic from '../FeedPostTopic';
import { FilterType } from './types';

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginRight: 8,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginRight: 16,
      marginBottom: 16,
      padding: '20px 8px',
      borderRadius: 24,
    },
  },
}));

function TopicsFilter({ topics, selectedTopics, onFilterChange }) {
  const classes = useStyles();

  const handleToggle = (value) => handleFilterToggle(value, selectedTopics, onFilterChange, FilterType.TOPICS);

  return (
    <div className={classes.root}>
      {topics.map((topic) => {
        const isSelected = selectedTopics.includes(topic);

        return (
          <FeedPostTopic
            key={`TopicsFilter-FeedPostTopic-${topic}`}
            topic={topic}
            handleToggle={handleToggle}
            isSelected={isSelected}
            className={classes.chip}
          />
        );
      })}
    </div>
  );
}

TopicsFilter.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.string),
  selectedTopics: PropTypes.arrayOf(PropTypes.string),
  onFilterChange: PropTypes.func,
};

TopicsFilter.defaultProps = {
  topics: [],
  selectedTopics: [],
  onFilterChange: () => {},
};

export default TopicsFilter;
