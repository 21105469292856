import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForeverTwoTone';
import withStyles from '@material-ui/core/styles/withStyles';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { RemoveUserFromCompanyMutation } from 'growl-graphql/dist/mutations/RemoveUserFromCompanyMutation';

import { colors } from '../../../../../fe_common/client/app/theme';
import { setEntityOperationResponseNotification } from '../../../../../fe_common/client/services/notification';
import LinkToUser from '../../../../components/cpanel/components/atoms/Links/LinkToUser';

const styles = () => ({
  root: {},
  listItemRoot: {},
});

const CompanyUsersList = ({
  users = [],
  companyId,
  readOnly,
  removeUserFromCompanyMutation,
  onRemoveUser,
  classes,
}) => {
  const handleRemoveUser = async (user = {}) => {
    const { id: userId, displayName } = user;

    const ans = prompt(`In order to remove ${displayName} User from this Company Users please Enter this User-ID:`);

    if (ans === userId.toString()) {
      try {
        const removeVars = {
          companyId,
          userId,
        };

        const result = await removeUserFromCompanyMutation.mutate(removeVars);
        setEntityOperationResponseNotification(result);
        onRemoveUser();
      } catch (err) {
        alert(`Error has encountered in removeUserFromCompanyMutation - ${err.msg}`);
      }
    } else {
      alert('You failed to confirm the disabled User-ID. Aborting');
    }
  };

  return (
    <List classes={{ root: classes.root }}>
      {users.map((user) => {
        const { id: userId, displayName, emailAddress } = user;
        return (
          <ListItem key={`${userId}-user-key-item`} classes={{ root: classes.listItemRoot }}>
            <ListItemText
              primary={
                <LinkToUser userId={userId}>
                  {displayName} ({userId})
                </LinkToUser>
              }
              secondary={emailAddress}
            />
            {!readOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    handleRemoveUser(user).then(() => {
                      onRemoveUser(user);
                    });
                  }}
                >
                  <DeleteForever fontSize="large" htmlColor={colors.secondary} />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

CompanyUsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  companyId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  onRemoveUser: PropTypes.func,
  removeUserFromCompanyMutation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

CompanyUsersList.defaultProps = {
  onRemoveUser: () => {},
  users: [],
  readOnly: false,
};

export default compose(withStyles(styles), withMutation(RemoveUserFromCompanyMutation))(CompanyUsersList);
