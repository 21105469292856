import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';

interface PagerProps {
  totalItems: number;
  page: number;
  pageSize: number;
  onNextClick: () => void;
  onPrevClick: () => void;
}

const defaultOnClick = () => {
  /* do nothing */
};

const Pager: FC<PagerProps> = (props) => {
  const { totalItems = 0, page = 1, pageSize = 10, onNextClick = defaultOnClick, onPrevClick = defaultOnClick } = props;

  const lastPage = Math.ceil(totalItems / pageSize);
  return (
    <div>
      <TextDisplay variant="body1" bold inline>{`Total Found: ${totalItems}`}</TextDisplay>
      <IconButton onClick={onPrevClick} disabled={page === 1}>
        <ChevronLeft />
      </IconButton>
      <TextDisplay variant="body1" bold inline>{`Page: ${page} / ${lastPage}`}</TextDisplay>
      <IconButton onClick={onNextClick} disabled={page === lastPage}>
        <ChevronRight />
      </IconButton>
    </div>
  );
};

export default Pager;
