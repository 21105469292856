import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { validateRequired } from '../../../../services/validation';
import { DatePicker } from '../../Registration/DatePicker';

export function WizardDatePickerField(props) {
  // console.log('WizardDatePickerField props', props);

  const { name, label, disabled, isRequired, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  return (
    <div>
      <Field component={DatePicker} name={name} label={label} disabled={disabled} validate={validator} {...rest} />
    </div>
  );
}

WizardDatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

WizardDatePickerField.defaultProps = {
  label: '',
  step: 1,
  disabled: false,
  isRequired: false,
};
