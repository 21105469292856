import React, { useCallback } from 'react';
import moment from 'moment/moment';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { ExtendProjectOpportunitiesMutation } from 'growl-graphql/dist/mutations/admin/ExtendProjectOpportunitiesMutation';

import { ProjectOpportunityConfirmationProps } from '../types';
import ExtendProjectOpportunityWizardSteps from './ExtendProjectOpportunityWizardSteps';

import { useMutation } from '../../../../../../fe_common/client/hooks';
import ConfirmDialog from '../../../../../../fe_common/client/components/dialogs/ConfirmDialog';
import { WizardForm } from '../../../../../../fe_common/client/components/organisms/WizardForm';

export const wizardFormExtendOpportunityName = 'extend-opportunities';

const ProjectOpportunityConfirmation: React.FC<ProjectOpportunityConfirmationProps> = (props) => {
  const { isOpen, onCloseDialog, selectedOpportunities, onUpdateOpportunities } = props;

  const defaultDueDateTime = moment().add(4, 'hours');

  const dispatch = useDispatch();

  const { mutate: extendOpportunity } = useMutation(ExtendProjectOpportunitiesMutation);

  const initialValues = {
    opportunitiesUuid: selectedOpportunities,
    dueDateTime: defaultDueDateTime,
  };

  const saveExtendedOpportunities = useCallback(
    async (formValues: Record<string, any>): Promise<any> => {
      const { opportunitiesUuid, dueDateTime } = formValues;

      const variables = {
        opportunitiesUuid,
        dueDateTime,
      };

      return extendOpportunity({ variables });
    },
    [extendOpportunity],
  );

  const handleOnSubmitExtendOpportunity = async (formValues: Record<string, any>) => {
    await saveExtendedOpportunities(formValues);
    onUpdateOpportunities();
  };

  const handleOnConfirmExtendOpportunity = async () => {
    await dispatch(submit(wizardFormExtendOpportunityName));
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isOpen && (
        <ConfirmDialog
          open={isOpen}
          onCancel={onCloseDialog}
          onConfirm={handleOnConfirmExtendOpportunity}
          content={
            <WizardForm
              // @ts-ignore
              steps={ExtendProjectOpportunityWizardSteps}
              form={wizardFormExtendOpportunityName}
              initialValues={initialValues}
              onSubmit={handleOnSubmitExtendOpportunity}
              destroyOnUnmount
              watchAllValues
            />
          }
          title="Extend Opportunities"
        />
      )}
    </>
  );
};

export default ProjectOpportunityConfirmation;
