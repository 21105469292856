import React from 'react';
import { AccountType } from '@mayple/types';

import { TRANSLATION_NS } from './constants';
import { AvailabilityViewerOptions, AvailabilityViewerProps } from './types';

import AvailabilityViewerInitializer from './AvailabilityViewerInitializer';
import AvailabilityViewerFilter from './AvailabilityViewerFilter';
import AvailabilityViewerContextProvider from './AvailabilityViewerProvider/AvailabilityViewerContext';
import availabilityViewerEn from './translations/en/availabilityViewer.json';
import useI18nLazyBundleLoader from '../../../../hooks/useI18nLazyBundleLoader';

import useStyles from './style';

const AvailabilityViewer: React.FC<AvailabilityViewerProps> = (props) => {
  const classes = useStyles();

  useI18nLazyBundleLoader(TRANSLATION_NS, availabilityViewerEn);

  const {
    mainAccountsGroup,
    initOptions = {} as AvailabilityViewerOptions,
    defaultFilter,
    showFilter = true,
    selectedSlot,
  } = props;

  const showAvailabilityProfiles = mainAccountsGroup?.accountsGroupSelectionType === AccountType.INTERNAL_TEAM;

  return (
    <AvailabilityViewerContextProvider
      value={{
        mainAccountsGroup,
        filterData: defaultFilter,
        initOptions,
        selectedSlot,
      }}
    >
      <div className={classes.container}>
        <div className={classes.availabilityViewer}>
          <AvailabilityViewerInitializer />
        </div>
        {showFilter && (
          <div className={classes.filterOptions}>
            <AvailabilityViewerFilter showAvailabilityProfiles={showAvailabilityProfiles} />
          </div>
        )}
      </div>
    </AvailabilityViewerContextProvider>
  );
};

export default AvailabilityViewer;
