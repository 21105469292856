import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Step } from 'react-albus';

import { WizardStepField } from '../../../../../../fe_common/client/components/molecules/WizardStepField';
import { WizardNavigationButtons } from '../../../../../../fe_common/client/components/molecules/WizardNavigationButtons';

export function WizardSteps(props) {
  const { invalid, submit, submitting, onCancel, showCancelButton } = props;

  return (
    <Steps>
      <Step
        id="marketerUpdateStep"
        render={(step) => (
          <div>
            <WizardStepField
              type={WizardStepField.types.CHECKBOX}
              name="active"
              inputLabel="Is the payment agreement still active?"
            />
            <WizardStepField
              type={WizardStepField.types.TEXT}
              name="details"
              wizardFieldLabel="Describe the payment agreement details"
              isMultiLine
              rows={2}
              required
              minLength={10}
            />
            <WizardNavigationButtons
              step={step}
              submit={submit}
              invalid={invalid}
              submitting={submitting}
              showSkipButton={showCancelButton}
              skipButtonLabel="Cancel"
              onSkipButtonClick={onCancel}
            />
          </div>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

WizardSteps.defaultProps = {
  onCancel: () => {},
  showCancelButton: false,
};
