import { AdminAccessibleMarketersPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleMarketersPagedQuery';
import { AdminAccessibleCompaniesQuery } from 'growl-graphql/dist/queries/AdminAccessibleCompaniesQuery';
import { AdminAccessibleUsersQuery } from 'growl-graphql/dist/queries/AdminAccessibleUsersQuery';
import { AdminAccessibleInternalTeamsQuery } from 'growl-graphql/dist/queries/AdminAccessibleInternalTeamsQuery';

import { ENTITY_TYPE } from '../../../app/types';

export type SearchParam = { query: any; dataKey: string; getAllEntities: boolean };
export type EntitySearchParams = Record<ENTITY_TYPE, SearchParam>;

const entitySearchParamsMap: EntitySearchParams = {
  [ENTITY_TYPE.MARKETER]: {
    query: AdminAccessibleMarketersPagedQuery,
    dataKey: 'marketersPaged',
    getAllEntities: false,
  },
  [ENTITY_TYPE.COMPANY]: {
    query: AdminAccessibleCompaniesQuery,
    dataKey: 'companies',
    getAllEntities: true,
  },
  [ENTITY_TYPE.INTERNAL_TEAM]: {
    query: AdminAccessibleInternalTeamsQuery,
    dataKey: 'internalTeams',
    getAllEntities: true,
  },
  [ENTITY_TYPE.USER]: {
    query: AdminAccessibleUsersQuery,
    dataKey: 'users',
    getAllEntities: true,
  },
};

export const getEntitySearchParams = (entityType: ENTITY_TYPE): SearchParam | null => {
  if (Object.getOwnPropertyNames(entitySearchParamsMap).includes(entityType)) {
    return entitySearchParamsMap[entityType];
  }
  return null;
};

export const getFilteredResults = (
  results: any[] = [],
  filterBy: ((e: any) => boolean) | undefined = undefined,
): any[] => {
  if (!filterBy || typeof filterBy !== 'function') {
    return results;
  }

  return results.filter(filterBy);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getOptionLabel = (o: any): string => o.label || '';
