import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketingAccountsDetails from '../../../molecules/MarketingAccountsDetails';
import { getTitleByType, MARKETING_ACCOUNT_TYPE } from '../../../molecules/MarketingAccountsDetails/logic';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';

const styles = {
  sectionRoot: {
    paddingTop: 0,
    marginTop: 0,
  },
  sectionContent: {},
  sectionTitle: {},
  sectionHeader: {},
};

const ProjectMarketingLiveAccountsDetails = (props) => {
  const { project, classes } = props;
  const title = getTitleByType(MARKETING_ACCOUNT_TYPE.LIVE);

  return (
    <Section
      title={title}
      noBorder
      classes={{
        root: classes.sectionRoot,
        sectionTitle: classes.sectionTitle,
        sectionHeader: classes.sectionHeader,
      }}
    >
      <SectionContent classes={{ root: classes.sectionContent }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <MarketingAccountsDetails project={project} marketingAccountType={MARKETING_ACCOUNT_TYPE.LIVE} />
          </Grid>
        </Grid>
      </SectionContent>
    </Section>
  );
};

ProjectMarketingLiveAccountsDetails.propTypes = {
  project: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

ProjectMarketingLiveAccountsDetails.defaultProps = {};

export default withStyles(styles)(ProjectMarketingLiveAccountsDetails);
