import LinkToUserSignUp from './LinkToUserSignUp';
import { getCrackleUrl } from '../../../../../../app/utils';

import { yesNoFormatter } from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';

export const getProjectLeadProperties = (classes) => {
  const yesNoToRedGreen = (value) => (value ? classes.green : classes.red);

  return [
    {
      label: 'User signed up',
      path: 'userSignedUp',
      formatter: yesNoFormatter,
      customClass: yesNoToRedGreen,
    },
    {
      label: 'Link to user sign up',
      path: 'linkToUserSignUp',
      customComponent: LinkToUserSignUp,
      customClass: classes.copyLink,
    },
  ];
};

const getUserSignUpLink = (token) => `${getCrackleUrl()}?token=${token}`;

export function collectDataFromProjectLead(projectLead) {
  if (!projectLead) {
    return null;
  }

  const { userActionToken } = projectLead;
  const { state: userActionTokenState, token } = userActionToken;
  const userSignedUp = userActionTokenState === 'USED';
  const linkToUserSignUp = !userSignedUp ? getUserSignUpLink(token) : null;

  return {
    ...projectLead,
    userSignedUp,
    linkToUserSignUp,
  };
}
