import { GridSize } from '@material-ui/core/Grid/Grid';

import { CardsInRow } from './types';
import { Layout } from '../SelectionCard/types';

export const getFinalGrid = (cardsInRow: CardsInRow): boolean | GridSize => {
  switch (cardsInRow) {
    case CardsInRow.all:
      return false;
    case CardsInRow.four:
      return 3;
    case CardsInRow.two:
      return 6;
    case CardsInRow.three:
    default:
      return 4;
  }
};

export const getCardLayout = (layout: Layout | undefined, bigCard: boolean, leftCard: boolean): Layout => {
  if (layout) {
    return layout;
  }

  let cardLayout: Layout = 'vertical';

  if (bigCard) {
    cardLayout = 'vertical';
  }
  if (leftCard) {
    cardLayout = 'horizontal';
  }

  return cardLayout;
};
