import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { PackageContext } from './logic';
import PackageSection from './common/PackageSection';
import Section from '../../atoms/Section';
import { TextDisplay } from '../../display';
import { useMuiTabState } from '../../../hooks';

import useStyles, { useCommonStyles } from './style';

type PackageAboutSectionProps = {
  classes?: Record<string, string>;
};

const PackageAboutSection: FC<PackageAboutSectionProps> = (props) => {
  const classes = useStyles(props);

  const commonClasses = useCommonStyles();

  const { data } = useContext(PackageContext);
  const { about, showTabs, possibleTabs, defaultTab } = useMemo(() => {
    const aboutData = data?.marketingServiceTypeInfo?.about || [];
    const defaultTabToSelect = aboutData?.[0]?.title || '';
    // Create tabs from the list of about section
    // @ts-ignore
    const tabs = aboutData.reduce((t, { title }) => ({ ...t, [title]: title }), {});

    return {
      about: aboutData,
      showTabs: aboutData.length > 1,
      possibleTabs: tabs,
      defaultTab: defaultTabToSelect,
    };
  }, [data]);

  const [tab, setTab] = useMuiTabState<typeof possibleTabs>(defaultTab, possibleTabs);

  if (!about || about.length === 0) {
    return null;
  }

  // @ts-ignore
  const sections = (about.find(({ title }) => title === tab) || {}).contents || [];

  const lastIndex = sections.length - 1;

  return (
    <Section
      noBorder
      classes={{
        root: classes.section,
        sectionHeader: classes.sectionHeader,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextDisplay className={commonClasses.sectionTitle} variant="h4" bold>
            About {data?.marketingServiceTypeInfo?.name || data.flavor}
          </TextDisplay>
        </Grid>
        {showTabs && (
          <Grid item xs={12} style={{ marginBottom: 72 }}>
            <Tabs value={tab} onChange={setTab} variant="scrollable">
              {/* @ts-ignore */}
              {Object.values(possibleTabs).map((t: string) => (
                <Tab key={`PackageAboutSection-${t}-tabs`} value={t} label={t} />
              ))}
            </Tabs>
          </Grid>
        )}
        <Grid item xs={12}>
          {/* @ts-ignore */}
          {sections.map((section, i: number) => (
            <PackageSection
              key={`PackageAboutSection-PackageSection-${i}`}
              section={section}
              reverse={Boolean(i % 2)}
              classes={{
                root: classNames({ [commonClasses.noBottomMargin]: i === lastIndex }),
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Section>
  );
};

export default PackageAboutSection;
