import React, { FC } from 'react';
import { InternalTeam } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ENTITY_TYPE } from '../../../../app/consts';
import GenerateInviteUserToken from '../../../../components/cpanel/components/molecules/GenerateInviteUserToken';

import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import EntityConnectedUsersList from '../../../../components/cpanel/components/molecules/EntityConnectedUsersList';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface InternalTeamUsersProps {
  internalTeam: InternalTeam;
  onChange: () => void;
  showInviteUserLink?: boolean;
}

const InternalTeamUsers: FC<InternalTeamUsersProps> = (props) => {
  const classes = useStyles();
  const { internalTeam, onChange, showInviteUserLink = false } = props;

  const subTitle =
    'Generate a unique link to invite a new user to join this internal account account. After signing ' +
    'up with this link, the user will get full access to the internal account and its projects. If the user ' +
    'has already signed up, but is not connected to any other company or marketer or internal account ' +
    'account, the link can still be used to allow him to join the internal account. However, if the user ' +
    'is already connected to another company or marketer or internal account, then the link will not work.';

  return (
    <div className={classes.root}>
      <Section title="Users" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
        <SectionContent>
          <EntityConnectedUsersList entityType={ENTITY_TYPE.INTERNAL_TEAM} entity={internalTeam} onChange={onChange} />
        </SectionContent>
      </Section>
      {showInviteUserLink && (
        <Section title="Invite user" subTitle={subTitle} layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}>
          <SectionContent>
            <GenerateInviteUserToken entityType={ENTITY_TYPE.INTERNAL_TEAM} entityId={internalTeam.id} />
          </SectionContent>
        </Section>
      )}
    </div>
  );
};

export default InternalTeamUsers;
