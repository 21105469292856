import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ReportExternalDebtForm from './ReportExternalDebtForm';
import useControlledAccordion from '../../../../hooks/useControlledAccordion';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

function ReportExternalDebt({ company, onSuccess }) {
  const { expandAccordion, accordionOnChangeHandler, onSuccessHandler } = useControlledAccordion(onSuccess);

  return (
    <Section
      title="Report External Debt"
      subTitle={
        'Report a debt a company has to Mayple, that was not created internally in the Mayple system. ' +
        'This sort of debt might have been created due to an incorrect calculation, ' +
        'an attempt to charge a company for a process that was not handled via the Mayple system, etc. ' +
        'Debts must then later be repaid by the company using existing credit or using their payment ' +
        'agreements (like credit card or PayPal account).'
      }
      layout={SECTION_LAYOUT.TWO_COLUMNS}
    >
      <Accordion expanded={expandAccordion} onChange={accordionOnChangeHandler}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay>Click here to report an external debt</TextDisplay>
        </AccordionSummary>
        <AccordionDetails>
          <ReportExternalDebtForm company={company} onSuccess={onSuccessHandler} />
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}

ReportExternalDebt.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

ReportExternalDebt.defaultProps = {
  userType: 'NORMAL_USER',
};

export default ReportExternalDebt;
