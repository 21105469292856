import React, { useEffect, useState } from 'react';
import { Company, InternalTeam, Marketer, User } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import { shouldShowUserCompany, shouldShowUserInternalTeam, shouldShowUserMarketer } from './logic';
import UserConnectedEntity from '../UserConnectedEntity';
import { ENTITY_TYPE } from '../../../../app/consts';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import Section, { SECTION_LAYOUT, SectionContent } from '../../../../../fe_common/client/components/atoms/Section';

interface UserConnectedEntitiesProps {
  user: User;
  onChange: () => void;
}

const UserConnectedEntities: React.FC<UserConnectedEntitiesProps> = (props) => {
  const { user = {} as User, onChange } = props;

  const marketers = (user?.marketers || []) as Marketer[];
  const companies = (user?.companies || []) as Company[];
  const internalTeams = (user?.internalTeams || []) as InternalTeam[];

  const [connectedToMarketer, setConnectedToMarketer] = useState(false);

  const [connectedToCompany, setConnectedToCompany] = useState(false);

  const [connectedToInternalTeam, setConnectedToInternalTeam] = useState(false);

  const [showUserMarketers, setShowUserMarketers] = useState(() =>
    shouldShowUserMarketer(connectedToMarketer, connectedToCompany, connectedToInternalTeam),
  );

  const [showUserCompany, setShowUserCompany] = useState(() =>
    shouldShowUserCompany(connectedToCompany, connectedToMarketer, connectedToInternalTeam),
  );

  const [showUserInternalTeam, setShowUserInternalTeam] = useState(() =>
    shouldShowUserInternalTeam(connectedToInternalTeam, connectedToMarketer, connectedToCompany),
  );

  useEffect(() => {
    setConnectedToMarketer(marketers?.length > 0);
    setConnectedToCompany(companies?.length > 0);
    setConnectedToInternalTeam(internalTeams?.length > 0);
  }, [companies?.length, internalTeams?.length, marketers?.length]);

  useEffect(() => {
    setShowUserMarketers(shouldShowUserMarketer(connectedToMarketer, connectedToCompany, connectedToInternalTeam));
    setShowUserCompany(shouldShowUserCompany(connectedToCompany, connectedToMarketer, connectedToInternalTeam));
    setShowUserInternalTeam(
      shouldShowUserInternalTeam(connectedToInternalTeam, connectedToMarketer, connectedToCompany),
    );
  }, [connectedToMarketer, connectedToCompany, connectedToInternalTeam]);

  return (
    <Grid container spacing={3}>
      {!connectedToMarketer && !connectedToCompany && !connectedToInternalTeam && (
        <Grid item xs={12}>
          <CalloutMessage
            message={
              'This user is not connected to any account. ' +
              'You can connect it either to a Company, Marketer or Internal account.'
            }
          />
        </Grid>
      )}
      {showUserMarketers && (
        <Grid item xs={12} sm={6}>
          <Section
            title={connectedToMarketer ? 'Member of Marketer' : 'Connect to Marketer'}
            layout={SECTION_LAYOUT.ONE_COLUMN}
            noTopMargin
            noBorder
          >
            <SectionContent>
              <UserConnectedEntity
                user={user}
                entityType={ENTITY_TYPE.MARKETER}
                onChange={onChange}
                showConnectUserToEntity={!connectedToMarketer}
              />
            </SectionContent>
          </Section>
        </Grid>
      )}
      {showUserCompany && (
        <Grid item xs={12} sm={6}>
          <Section
            title={connectedToCompany ? 'Member of Company' : 'Connect to Company'}
            layout={SECTION_LAYOUT.ONE_COLUMN}
            noTopMargin
            noBorder
          >
            <SectionContent>
              <UserConnectedEntity
                user={user}
                entityType={ENTITY_TYPE.COMPANY}
                onChange={onChange}
                showConnectUserToEntity={!connectedToCompany}
              />
            </SectionContent>
          </Section>
        </Grid>
      )}
      {showUserInternalTeam && (
        <Grid item xs={12} sm={6}>
          <Section
            title={connectedToInternalTeam ? 'Member of Internal account' : 'Connect to Internal account'}
            layout={SECTION_LAYOUT.ONE_COLUMN}
            noTopMargin
            noBorder
          >
            <SectionContent>
              <UserConnectedEntity
                user={user}
                entityType={ENTITY_TYPE.INTERNAL_TEAM}
                onChange={onChange}
                showConnectUserToEntity={!connectedToInternalTeam}
              />
            </SectionContent>
          </Section>
        </Grid>
      )}
    </Grid>
  );
};

export default UserConnectedEntities;
