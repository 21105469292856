import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Project } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import { DisableProjectMutation } from 'growl-graphql/dist/mutations/DisableProjectMutation';

import ShowByProjectStatus from '../../components/ShowByProjectStatus';
import ProjectFlowConfiguration from '../../components/ProjectFlowConfiguration';
import ProjectBriefTargeting from '../../../../components/cpanel/components/organisms/Project/ProjectBriefTargeting';
import ProjectBriefRequests from '../../../../components/cpanel/components/organisms/Project/ProjectBriefRequests';
import DisableEntityControl from '../../../../containers/shared/DisableEntityControl';
import ProjectLeadDetails from '../../../../components/cpanel/components/organisms/Project/ProjectLeadDetails';
import ProjectInfo from '../../../../components/cpanel/components/organisms/Project/ProjectInfo';
import ProjectDeepBriefPreloadedAnswers from '../../components/ProjectDeepBriefPreloadedAnswers';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import { useMutation } from '../../../../../fe_common/client/hooks';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface ProjectBasicDetailsTabProps {
  project: Project;
  refetchProjectData: () => Promise<void>;
  classes?: Record<string, string>;
}

const ProjectBasicDetailsTab: FC<ProjectBasicDetailsTabProps> = (props) => {
  const classes = useStyles(props);
  const { project, refetchProjectData } = props;
  const { id: projectId, name: projectName, projectLifeCycleStatus } = project;

  const { mutate: disableProjectMutation } = useMutation(DisableProjectMutation);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={6}>
        <ProjectFlowConfiguration project={project} />
        <ProjectBriefTargeting project={project} onSuccess={refetchProjectData} />
        <ProjectBriefRequests project={project} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProjectInfo project={project} />
        <ProjectDeepBriefPreloadedAnswers data={project?.projectDeepBriefPreloadedAnswers} />
      </Grid>
      <Grid item xs={12}>
        <ShowByProjectStatus status={projectLifeCycleStatus} showOnDiscovery>
          <ProjectLeadDetails project={project} />
        </ShowByProjectStatus>
        <Section>
          <DisableEntityControl
            mutation={disableProjectMutation}
            variables={{ projectId }}
            entity={project}
            title="Disable Project..."
            buttonLabel="Disable Project"
            warningText={`You are about to disable marketer ${projectName} with ID: ${projectId}`}
          />
        </Section>
      </Grid>
    </Grid>
  );
};

export default ProjectBasicDetailsTab;
