/* eslint-disable class-methods-use-this */
import { SetOutbrainAuditAssessmentByCompanyForProjectMutation } from 'growl-graphql/dist/mutations/tasks/Outbrain/SetOutbrainAuditAssessmentByCompanyForProjectMutation';
import { SkipOutbrainAuditAssessmentByCompanyForProjectMutation } from 'growl-graphql/dist/mutations/tasks/Outbrain/SkipOutbrainAuditAssessmentByCompanyForProjectMutation';
import {
  OutbrainAuditAssessmentByCompany,
  OutbrainAuditAssessmentByCompanyInput,
  OutbrainAuditAssessmentByCompanyParameterKey,
} from '@mayple/types';

import IAssessment from './IAssessment';
import { FormValues } from '../types';
import { outbrainAuditAssessmentByCompanyParameterKeys } from '../consts';
import { AssessmentSubTask } from '../../../types';

interface SetOutbrainAuditAssessmentByCompanyVariables extends Record<string, any> {
  projectId: number;
  marketerId: number;
  outbrainAuditAssessmentByCompanyInput: OutbrainAuditAssessmentByCompanyInput;
}

class OutbrainAuditAssessmentByCompanyAssessmentConcrete implements IAssessment {
  skipMutation = SkipOutbrainAuditAssessmentByCompanyForProjectMutation;

  skipMutationResponseKey = 'skipOutbrainAuditAssessmentByCompanyForProject';

  saveMutation = SetOutbrainAuditAssessmentByCompanyForProjectMutation;

  saveMutationResponseKey = 'setOutbrainAuditAssessmentByCompanyForProject';

  getAssessmentVotes(): string[] {
    return outbrainAuditAssessmentByCompanyParameterKeys;
  }

  getFormValuesFromSubTask(
    subTask: AssessmentSubTask,
    votes: Record<string, any>,
    assessmentComment: string | null | undefined
  ): FormValues {
    const postProjectMatchOfferMarketerAssessment = subTask as unknown as OutbrainAuditAssessmentByCompany;
    return {
      projectId: postProjectMatchOfferMarketerAssessment?.projectId,
      marketerId: postProjectMatchOfferMarketerAssessment?.marketerId,
      votes,
      assessmentComment,
    };
  }

  getSubmitFormVariables(formValues: FormValues): SetOutbrainAuditAssessmentByCompanyVariables {
    const marketerAssessmentParameters: Array<{
      key: OutbrainAuditAssessmentByCompanyParameterKey;
      value: number;
    }> = Object.entries(formValues?.votes || ({} as OutbrainAuditAssessmentByCompanyParameterKey))
      .map(([key, value]) => ({
        key: key as OutbrainAuditAssessmentByCompanyParameterKey,
        value,
      }))
      .filter((item) => item.value);

    return {
      projectId: formValues?.projectId || 0,
      marketerId: formValues?.marketerId || 0,
      outbrainAuditAssessmentByCompanyInput: {
        marketerAssessmentParameters,
        marketerAssessmentComment: formValues?.assessmentComment,
      },
    };
  }

  getSkipFormVariables(formValues: FormValues): Record<string, any> {
    return {
      projectId: formValues?.projectId || 0,
      marketerId: formValues?.marketerId || 0,
    };
  }
}

export default OutbrainAuditAssessmentByCompanyAssessmentConcrete;
