import React, { ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { Variant } from '@material-ui/core/styles/createTypography';

import { TextDisplay } from '../../display';

import useStyles from './style';

const avatarSizeValidKeys = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
type AvatarSize = typeof avatarSizeValidKeys[number];

export interface EntityAvatarProps {
  avatarImageUrl?: string;
  primary?: string | ReactElement;
  secondary?: string | ReactElement;
  primaryTextType?: Variant;
  secondaryTextType?: Variant;
  size?: AvatarSize;
  loading?: boolean;
  onClick?: () => void;
  classes?: Record<string, string>;
}

const EntityAvatar: React.FC<EntityAvatarProps> = (props) => {
  const {
    avatarImageUrl,
    primary,
    secondary,
    size = 'md',
    loading = false,
    onClick,
    primaryTextType,
    secondaryTextType,
  } = props;

  const classes = useStyles(props);

  const entityAvatarExtraPropsMapper: Record<AvatarSize, string[]> = useMemo(
    () => ({
      xs: ['body1', 'body2', classes.avatarXs],
      sm: ['body1', 'body2', classes.avatarSm],
      md: ['body1', 'body2', classes.avatarMd],
      lg: ['h6', 'body2', classes.avatarLg],
      xl: ['h6', 'body2', classes.avatarXl],
    }),
    [classes.avatarXs, classes.avatarSm, classes.avatarMd, classes.avatarLg, classes.avatarXl]
  );

  const [primaryTextTypographyType, secondaryTextTypographyType, avatarSizeClassName] =
    entityAvatarExtraPropsMapper[size as AvatarSize];

  if (loading) {
    return (
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="circle" className={classNames(classes.avatar, avatarSizeClassName)} />
        </ListItemAvatar>
        <ListItemText>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton height={14} width="80%" />
        </ListItemText>
      </ListItem>
    );
  }

  return (
    <ListItem
      component="div"
      className={classNames(classes.root, {
        [classes.withLink]: typeof onClick === 'function',
      })}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar src={avatarImageUrl} className={classNames(classes.avatar, avatarSizeClassName)} />
      </ListItemAvatar>
      <ListItemText
        primary={
          !!primary && (
            <TextDisplay
              variant={primaryTextType || (primaryTextTypographyType as Variant)}
              className={classes.primary}
            >
              {primary}
            </TextDisplay>
          )
        }
        secondary={
          !!secondary && (
            <TextDisplay
              variant={secondaryTextType || (secondaryTextTypographyType as Variant)}
              className={classes.secondary}
            >
              {secondary}
            </TextDisplay>
          )
        }
        disableTypography
      />
    </ListItem>
  );
};

export default EntityAvatar;
