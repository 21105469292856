import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Remove from '@material-ui/icons/Remove';
import Plus from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { TextDisplay } from '../../display';
import ListItemNode from '../../molecules/ListItemTreeView/ListItemNode';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 30,
  },
  parentNode: {
    cursor: 'pointer',
  },
}));

/**
 * ListItemTreeView is a tree-structure contains ListItemNode for each node and
 * iterates over the childNodes prop in order to retrieve the child nodes.
 * @property node - consists from label and data props, label is the level headline description,
 * while data consists from id, name, displayImageUrl and linkUrl.
 * @property childNodes - additional nodes that rendring as children to the next tree level.
 * @returns ListItemTreeView rendering component.
 */
export function ListItemTreeView(props) {
  const classes = useStyles(props);
  const [collapsed, setCollapsed] = useState(false);

  const {
    node: { label, showItemsCount },
  } = props;
  let {
    node: { data },
    childNodes,
  } = props;

  if (!data) {
    data = [];
  }

  if (!childNodes) {
    childNodes = [];
  }

  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  let itemsCountText = '';
  if (childNodes && data) {
    itemsCountText = `[${data.length + childNodes.length} items]`;
  }

  return (
    <List className={classes.root}>
      <Grid container onClick={handleClick} spacing={1} className={classes.parentNode}>
        <Grid item>{collapsed ? <Remove color="secondary" /> : <Plus color="primary" />}</Grid>
        <Grid item>
          <TextDisplay variant="subtitle1" bold>
            {label} {showItemsCount && itemsCountText}
          </TextDisplay>
        </Grid>
      </Grid>
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        {data.map((nodeData) => (
          <ListItemNode key={`custom-tree-view-${label}-${nodeData.id}-${nodeData.name}`} data={nodeData} />
        ))}
        {childNodes.map((childNode) => (
          <ListItemTreeView
            key={`custom-tree-view-${childNode.node.id}`}
            node={childNode.node}
            childNodes={childNode.childNodes}
          />
        ))}
      </Collapse>
    </List>
  );
}

ListItemTreeView.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    showItemsCount: PropTypes.bool,
  }).isRequired,
  childNodes: PropTypes.arrayOf(PropTypes.object),
};

ListItemTreeView.defaultProps = {
  childNodes: [],
  showItemsCount: true,
};
