import React from 'react';
import PropTypes from 'prop-types';
import { svgs } from '../../../../../fe_common/client/app/svg';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { formatDate } from '../../../../app/utils';
import { useStyles } from './style';

const CreditCardPaymentAgreement = (props) => {
  const classes = useStyles();

  const { currentActiveAgreement, onDeactivatePaymentAgreement, isLoading } = props;

  const { active, valid, cardType, expirationMonth, expirationYear, created, id, cardLastFourDigits, billingInfo } =
    currentActiveAgreement;

  const { address1, address2, city, country, firstName, lastName, zip } = billingInfo || {};

  const billingInfoItems = [`${firstName} ${lastName}`, address1, address2, `${city}, ${zip}`, country];

  return (
    <div className={classes.root}>
      <img src={svgs.credit_card} alt="" />
      <div className={classes.columnContainer}>
        <div className={classes.leftColumn}>
          CC Agreement ID: {id}
          <br />
          Status: {active ? 'Active' : 'Inactive'}
          <br />
          Valid: {valid ? 'Yes' : 'No'}
          <br />
          Card type: {cardType}
          <br />
          Last 4 digits: {cardLastFourDigits}
          <br />
          Expires: {expirationMonth}/{expirationYear}
          <br />
          Added: {formatDate(created)}
          <br />
        </div>

        <div className={classes.rightColumn}>
          <h2 className={classes.rightColumnTitle}>Billing info:</h2>
          {billingInfoItems.map((item) => (
            <div className={classes.billingInfoItem}>{item}</div>
          ))}
        </div>
      </div>

      {active && (
        <div className={classes.actions}>
          <Button
            label="Deactivate credit card"
            color="secondary"
            variant="contained"
            disabled={isLoading}
            onClick={onDeactivatePaymentAgreement}
          />
        </div>
      )}
    </div>
  );
};

CreditCardPaymentAgreement.propTypes = {
  currentActiveAgreement: PropTypes.object.isRequired,
  onDeactivatePaymentAgreement: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CreditCardPaymentAgreement;
