import React, { useCallback } from 'react';
import { ProjectInvoicePaymentTerm } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { LabelValueProps } from '../../../../../fe_common/client/app/types';
import Select from '../../../../../fe_common/client/components/atoms/Registration/Select';
import useProjectData from '../../../../../fe_common/client/hooks/project/useProjectData';
import useUpdateProject from '../../../../hooks/useUpdateProject';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface ProjectInvoicePaymentTermSelectProps {
  projectId: number;
  classes?: Record<string, string>;
}

const INVOICE_PAYMENT_TERM_PAIRS: LabelValueProps[] = [
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_DUE_IMMEDIATELY,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_DUE_IMMEDIATELY,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_10,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_10,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_15,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_15,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_30,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_30,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_45,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_45,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_60,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_60,
  },
  {
    label: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_90,
    value: ProjectInvoicePaymentTerm.PAYMENT_TERM_NET_90,
  },
];

const ProjectInvoicePaymentTermSelect: React.FC<ProjectInvoicePaymentTermSelectProps> = (props) => {
  const classes = useStyles(props);
  const { projectId } = props;

  const { project, refetch } = useProjectData(projectId);

  const value = project?.invoicePaymentTerm;

  const { updateProject } = useUpdateProject();

  const onSelectHandler = useCallback(
    async (newValue: ProjectInvoicePaymentTerm) => {
      await updateProject(projectId, { invoicePaymentTerm: newValue }, refetch);
    },
    [projectId, refetch, updateProject],
  );

  const invoicePaymentTermHelperText =
    'Decides the invoice payment term for debt that is created for this project. ' +
    'The payment term (for example, Net30, Net60 etc) decides when debt is considered "overdue", ' +
    'which is then noted in reports used by the Mayple teams. So, for example, if the invoice payment term for ' +
    'a project is Net30 and debt is created on the 5th of March, then for the first 30 days this debt will be treated ' +
    'as "on-time" and "regular", but after 30 days it will be treated as "overdue", as the company should have paid it by then. ' +
    'In simple terms, this decides the debt due date.';

  return (
    <div className={classes.root}>
      <Select
        name="invoicePaymentTerm"
        label="Invoice Payment Term"
        helperText={invoicePaymentTermHelperText}
        enums={INVOICE_PAYMENT_TERM_PAIRS}
        value={value}
        onSelect={onSelectHandler}
        hideEmptyValue
      />
    </div>
  );
};

export default ProjectInvoicePaymentTermSelect;
