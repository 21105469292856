import React from 'react';
import { CurrencyType, MediaDocument, MediaType, PackagePresentation, ProjectPackageState } from '@mayple/types';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import EditIcon from '@material-ui/icons/Edit';

import { ProjectPackageCardProps, projectPackageStateLabelsLookup } from './types';
import useProjectPackageCardData from './useProjectPackageCardData';
import { getHeroImageUrl } from './logic';
import ProjectPackageOverrideDialog from '../ProjectPackageOverrideDialog';
import ReportProjectPackageAsCompletedReasonDialog from '../ReportProjectPackageAsCompletedReasonDialog';
import useProjectPackageOverrideDialog from '../ProjectPackageOverrideDialog/useProjectPackageOverrideDialog';
import ReportProjectPackageDelayReasonDialog from '../ReportProjectPackageDelayReasonDialog';
import useProjectPackageDelayReasonTranslations from '../ReportProjectPackageDelayReasonDialog/useProjectPackageDelayReasonTranslations';

import { Button } from '../../inputs';
import { Card, SkeletonContainer } from '../../display';
import LabelValue from '../../display/LabelValue';
import RichTextViewer from '../../common/RichTextViewer';
import MultipleActionsButton from '../../inputs/MultipleActionsButton';
import { formatDateString, formatPercentageString, safeExtractCurrencyValue } from '../../../services/utils';

import useStyles from './style';

const ProjectPackageCard: React.FC<ProjectPackageCardProps> = (props) => {
  const classes = useStyles(props);
  const {
    projectPackage,
    loading = false,
    showDescription = false,
    showPrices = false,
    enableEdit = false,
    onCardClick,
    onSuccessfulSave,
  } = props;

  const { projectPackageDelayReasonLabelsLookup } = useProjectPackageDelayReasonTranslations();

  // console.log('projectPackage', projectPackage);

  const {
    actions,
    finalDeliverablesBlueprint,
    isMarkAsCompletedDialogOpen,
    closeMarkAsCompletedDialog,
    isReportPackageDelayDialogOpen,
    closeReportPackageDelayDialog,
  } = useProjectPackageCardData(props);

  const { open: isOpen, openDialog, closeDialog } = useProjectPackageOverrideDialog();

  const { package: packageTemplate } = projectPackage;
  const { presentation } = packageTemplate;
  const { example } = presentation || ({} as PackagePresentation);
  const name = presentation?.name || packageTemplate?.title;
  const description: MediaDocument = presentation?.description || {
    mediaType: MediaType.TEXT_PLAIN,
    contents: packageTemplate?.description,
  };

  const heroImage = getHeroImageUrl(example);
  const projectPackageState = projectPackage?.state;
  const isDraft = projectPackageState === ProjectPackageState.PACKAGE_DRAFT;
  const startedDate = projectPackage?.startedDate;
  const canceledDate = projectPackage?.canceledDate;
  const completedDate = projectPackage?.completedDate;
  const completionMessage = projectPackage?.completionMessage;
  const delayReportedDate = projectPackage?.delayReportedDate;
  const delayReason = projectPackage?.delayReason;
  const delayReasonMessage = projectPackage?.delayReasonMessage;
  const unitCost = safeExtractCurrencyValue(finalDeliverablesBlueprint?.unitCost, CurrencyType.USD, false);
  const discountPercentage = formatPercentageString((finalDeliverablesBlueprint?.discountPercentage ?? 0) / 100);
  const unitAmount = finalDeliverablesBlueprint?.unitAmount ?? 0;

  return (
    <SkeletonContainer loading={loading} width="100%" height={300}>
      <Card classes={{ root: classNames(classes.root, { [classes.draftCard]: isDraft }) }}>
        <CardActionArea onClick={onCardClick}>
          <Card.Header
            classes={{ root: classes.cardHeader, content: classes.cardHeaderContent, title: classes.cardHeaderTitle }}
            title={name || undefined}
          />
          <CardMedia classes={{ root: classes.cardMedia }} component="img" title={name} height="140" src={heroImage} />
          <Card.Content>
            <div className={classes.indicators}>
              <Chip
                size="medium"
                classes={{
                  root: classNames(classes.chip, {
                    [classes.chipPending]: projectPackageState === ProjectPackageState.PACKAGE_PENDING,
                    [classes.chipStarted]: projectPackageState === ProjectPackageState.PACKAGE_STARTED,
                    [classes.chipCanceled]: projectPackageState === ProjectPackageState.PACKAGE_CANCELED,
                    [classes.chipCompleted]: projectPackageState === ProjectPackageState.PACKAGE_COMPLETED,
                  }),
                }}
                label={projectPackageStateLabelsLookup[projectPackageState || ProjectPackageState.PACKAGE_PENDING]}
              />
              {!!delayReportedDate && (
                <Chip size="medium" className={classNames(classes.chip, classes.delay)} label="Delayed" />
              )}
              {/* {isDraft && <div className={classNames(classes.draft, classes.strip)}>Draft</div>} */}
            </div>
            {showDescription && <RichTextViewer value={description} />}
            {showPrices && <LabelValue label="Cost" value={unitCost} />}
            {showPrices && discountPercentage != null && (
              <LabelValue label="Discount Percentage" value={discountPercentage} />
            )}
            {showPrices && unitAmount != null && <LabelValue label="Number of units" value={unitAmount} />}
            {!!startedDate && <LabelValue label="Started Date" value={formatDateString(startedDate)} />}
            {!!canceledDate && <LabelValue label="Canceled Date" value={formatDateString(canceledDate)} />}
            {!!delayReportedDate && (
              <LabelValue label="Delay reported Date" value={formatDateString(delayReportedDate)} />
            )}
            {!!delayReason && (
              <LabelValue label="Delay Reason" value={projectPackageDelayReasonLabelsLookup[delayReason]} />
            )}
            {!!delayReportedDate && <LabelValue label="Delay Reason Message" value={delayReasonMessage} />}
            {!!completedDate && <LabelValue label="Completed Date" value={formatDateString(completedDate)} />}
            {!!completionMessage && <LabelValue label="Completion Message" value={completionMessage} />}
          </Card.Content>
        </CardActionArea>
        {enableEdit && (
          <Card.Actions classes={{ root: classes.cardActions }}>
            <div className={classes.buttons}>
              {enableEdit && (
                <div>
                  <Button
                    label="Edit"
                    startIcon={<EditIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={openDialog}
                  />
                </div>
              )}
              {enableEdit && <MultipleActionsButton color="primary" size="small" actions={actions} />}
            </div>
          </Card.Actions>
        )}
      </Card>
      {isOpen && projectPackage && (
        <ProjectPackageOverrideDialog
          isOpen={isOpen}
          onClose={closeDialog}
          projectPackage={projectPackage}
          onSuccessfulSave={onSuccessfulSave}
        />
      )}
      {isMarkAsCompletedDialogOpen && (
        <ReportProjectPackageAsCompletedReasonDialog
          isOpen={isMarkAsCompletedDialogOpen}
          onClose={closeMarkAsCompletedDialog}
          projectPackageUuid={projectPackage?.uuid}
          onSuccessfulSave={onSuccessfulSave}
        />
      )}
      {isReportPackageDelayDialogOpen && (
        <ReportProjectPackageDelayReasonDialog
          isOpen={isReportPackageDelayDialogOpen}
          onClose={closeReportPackageDelayDialog}
          projectPackageUuid={projectPackage?.uuid}
          onSuccessfulSave={onSuccessfulSave}
        />
      )}
    </SkeletonContainer>
  );
};

export default ProjectPackageCard;
