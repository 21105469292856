import React, { FC } from 'react';
import { AccountType, Marketer, MarketerType } from '@mayple/types';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';

import { getCrackleUrl } from '../../../../app/utils';
import LinkToMarketer from '../../../../components/cpanel/components/atoms/Links/LinkToMarketer';

import { svgs } from '../../../../../fe_common/client/app/svg';
import { MARKETER_TYPE_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { useDialog } from '../../../../../fe_common/client/hooks';
import CopyHtmlElementToClipboardButton from '../../../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';
import AvailabilityViewerButton from '../../../../../fe_common/client/components/integrations/calendars/AvailabilityViewer/components/AvailabilityViewerButton';
import AvailabilityViewerDialog from '../../../../../fe_common/client/components/integrations/calendars/AvailabilityViewer/AvailabilityViewerDialog';
import { MarketerTypeLabelsLookup } from '../../../../../fe_common/client/app/types';

import useStyles from './style';

interface MarketerDetailsHeaderProps {
  marketer: Marketer;
  titlePrefix?: string;
  readOnly?: boolean;
}

/**
 * Basic details viewer on marketer profile
 * @param props
 * @constructor
 */
const MarketerDetailsHeader: FC<MarketerDetailsHeaderProps> = (props) => {
  const { marketer, titlePrefix = '', readOnly = false } = props;
  const classes = useStyles();

  const {
    open: isAvailabilityDialogOpen,
    openDialog: openAvailabilityDialog,
    closeDialog: closeAvailabilityDialog,
  } = useDialog(false);

  const { id: marketerId, name, displayImageUrl, description, isVetted } = marketer;

  const marketerType: MarketerType | undefined = marketer.marketerProfile?.marketerType;

  const marketerTypeLabelsLookup = MARKETER_TYPE_LABELS_LOOKUP as MarketerTypeLabelsLookup;

  const expertHeroImageStyle = {
    backgroundImage: `url('${displayImageUrl}')`,
  };

  const account = {
    id: marketerId,
    accountType: AccountType.MARKETER,
  };

  const title = `${marketer.name} (${marketerId}) Availability`;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerTop}>
          {isVetted && (
            <div className={classes.vetted}>
              <img src={svgs.checkBadgeInv} height={24} width={24} alt="" className={classes.vettedIcon} />
              <TextDisplay bold className={classes.vettedText}>
                Vetted by Mayple
              </TextDisplay>
            </div>
          )}
          <div className={classes.marketerType}>
            <img className={classes.marketerTypeIcon} src={svgs[marketerType as string]} alt="" />
            {marketerType && (
              <TextDisplay className={classes.marketerTypeText} inline>
                {marketerTypeLabelsLookup[marketerType]}
              </TextDisplay>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <AvailabilityViewerButton
              accountId={marketerId}
              accountType={AccountType.MARKETER}
              onClick={openAvailabilityDialog}
              classes={{ root: classes.buttonSpacer }}
            />
            <CopyHtmlElementToClipboardButton
              className={classes.buttonSpacer}
              querySelector="#marketer-header"
              label="Copy to Slack"
              size="medium"
            />
            {!readOnly && (
              <Button
                color="primary"
                variant="contained"
                label="Edit marketer"
                link={`/marketers/${marketerId}/update`}
                size="medium"
              />
            )}
          </div>
        </div>

        <div
          className={classNames(classes.expertHeroHeader, {
            [classes.digitalAgencyHeader]: marketerType === MarketerType.DIGITAL_AGENCY,
            [classes.freelancerHeader]: marketerType === MarketerType.FREELANCER,
          })}
        >
          <div className={classes.expertHeroImageContainer}>
            <div
              className={classNames(classes.expertHeroImage, {
                [classes.expertHeroImageAgency]: marketerType === MarketerType.DIGITAL_AGENCY,
                [classes.expertHeroImageFreelancer]: marketerType === MarketerType.FREELANCER,
              })}
              style={expertHeroImageStyle}
            />
          </div>
          <div className={classes.expertHeroDetailsContainer}>
            <div id="marketer-header">
              <TextDisplay variant="h4" bold gutterBottom className={classes.expertName}>
                <LinkToMarketer marketerId={marketerId} openInNewTab>
                  {titlePrefix}
                  {name} (ID: {marketerId})
                </LinkToMarketer>
              </TextDisplay>
              <TextDisplay variant="caption" gutterBottom>
                <Link
                  href={`${getCrackleUrl()}?actAsMarketerId=${marketerId}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Impersonate {name}
                </Link>
              </TextDisplay>
            </div>
            <TextDisplay className={classes.description} variant="body1">
              {description}
            </TextDisplay>
          </div>
        </div>
      </div>
      <AvailabilityViewerDialog
        mainAccount={account}
        selectionAccountType={AccountType.INTERNAL_TEAM}
        title={title}
        isOpen={isAvailabilityDialogOpen}
        onClose={closeAvailabilityDialog}
      />
    </>
  );
};

export default MarketerDetailsHeader;
