import { useCallback } from 'react';
import { Project, ProjectCycle } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';
import { ProjectCyclesQuery } from 'growl-graphql/dist/queries/project/ProjectCyclesQuery';

interface ProjectDetailsPageData {
  loadingFullProject: boolean;
  loadingCycles: boolean;
  error: ApolloError | undefined;
  project: Project;
  cycles: ProjectCycle[];
  refetchData: () => Promise<any>;
}

const useProjectDetailsPageData = (projectId: number): ProjectDetailsPageData => {
  const {
    loading: loadingFullProject,
    error: errorFullProject,
    data: dataFullProject,
    refetch: refetchFullProject,
  } = useQuery(FullProjectByIdQuery.query, {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: false,
  });

  const {
    data: dataCycles,
    loading: loadingCycles,
    refetch: refetchCycles,
  } = useQuery(ProjectCyclesQuery.query, {
    variables: { projectId, includeBillingSnapshots: true },
    skip: !projectId || !dataFullProject,
    notifyOnNetworkStatusChange: true,
  });

  const refetch = useCallback(
    async () => Promise.all([refetchFullProject(), refetchCycles()]),
    [refetchFullProject, refetchCycles],
  );

  return {
    loadingFullProject,
    loadingCycles,
    error: errorFullProject,
    project: dataFullProject?.project || ({} as Project),
    cycles: dataCycles?.project?.cycles || ([] as ProjectCycle[]),
    refetchData: refetch,
  };
};

export default useProjectDetailsPageData;
