import React, { FC, useState } from 'react';
import { Company } from '@mayple/types';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import useCompanyData from './useCompanyData';
import { CompanyDetailsTabs } from './types';
import { companyTabs } from './logic';
import CompanyDetailsHeader from './components/CompanyDetailsHeader';
import CompanyFundsTab from './tabs/CompanyFundsTab';
import CompanyBasicDetailsTab from './tabs/CompanyBasicDetailsTab';
import CompanyIntegrationsTab from './tabs/CompanyIntegrationsTab';
import CompanyUsersTab from './tabs/CompanyUsersTab';
import { CompanyIdParams } from '../../components/Routes/types';

import { tryParseInt } from '../../../fe_common/client/services/utils';
import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import TabContent from '../../../fe_common/client/components/atoms/TabContent';

import useStyles from './style';

const CompanyDetailsPage: FC = () => {
  const classes = useStyles();
  const { companyId: companyIdFromUrl } = useParams<CompanyIdParams>();
  const companyId: number | null = tryParseInt(companyIdFromUrl, null);

  const { loading, error, company = {} as Company, refetch } = useCompanyData(companyId);

  const [selectedTab, setSelectedTab] = useState<CompanyDetailsTabs>(() => {
    let defaultTab = CompanyDetailsTabs.basicDetails;
    const hash = window.location.hash.replace('#', '');

    if (CompanyDetailsTabs[hash as CompanyDetailsTabs]) {
      defaultTab = hash as CompanyDetailsTabs;
    }

    return defaultTab;
  });

  const handleTabChange = (_event: React.ChangeEvent<Record<string, unknown>>, newSelectedTab: any) => {
    window.location.hash = newSelectedTab;
    setSelectedTab(newSelectedTab as CompanyDetailsTabs);
  };

  if (error) {
    return <QueryErrorMessage error={error} message="Error loading company data" />;
  }

  if (loading) {
    return <PageLoadingPlaceholder />;
  }

  if (!company) {
    return <CalloutMessage message={`company with id ${companyIdFromUrl} not found`} type="info" />;
  }

  // console.log('company', company);

  return (
    <div className={classes.root}>
      <CompanyDetailsHeader company={company} titlePrefix="Company " />

      <Divider className={classes.divider} />

      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {companyTabs.map(({ label, value }) => (
          <Tab key={`CompanyDetailsPage-tabs-${label}`} label={label} value={value} />
        ))}
      </Tabs>

      {selectedTab === CompanyDetailsTabs.basicDetails && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <CompanyBasicDetailsTab company={company} />
        </TabContent>
      )}

      {selectedTab === CompanyDetailsTabs.funds && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <CompanyFundsTab company={company} refetchCompanyData={refetch} />
        </TabContent>
      )}

      {selectedTab === CompanyDetailsTabs.integrations && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <CompanyIntegrationsTab companyId={companyId} />
        </TabContent>
      )}

      {selectedTab === CompanyDetailsTabs.users && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <CompanyUsersTab company={company} />
        </TabContent>
      )}
    </div>
  );
};

export default CompanyDetailsPage;
