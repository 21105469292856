import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    editButton: {
      position: 'absolute',
      top: '-24px',
      right: 0,
      left: 'auto',
      opacity: 0,
    },
    viewContainer: {
      position: 'relative',
      minHeight: '50px',
      padding: 8,
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#DEE2E3',
        transition: 'all 0.2s ease-out',
      },
      '&:hover $editButton': {
        opacity: 1,
      },
    },
  }),
);

export default useStyles;
