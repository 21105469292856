import { useQuery } from '@apollo/react-hooks';
import { ProjectBillingReviewItem, ProjectSpecialSubType, ProjectSpecialType } from '@mayple/types';
import { ProjectBillingReviewQuery } from 'growl-graphql/dist/queries/ProjectBillingReviewQuery';
import { ApolloError, ApolloQueryResult } from 'apollo-client';

import { BillingCycleFormData } from './types';
import { valuesOnly } from '../../../../../fe_common/client/services/utils';

export const getYearMonthFromDate = (date: Date): { year: number; month: number } => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
});

type ProjectBillingReviewVariables = {
  month: number;
  year: number;
  projectSpecialTypes?: ProjectSpecialType[] | null | undefined;
  projectSpecialSubTypes?: ProjectSpecialSubType[] | null | undefined;
};

export type ProjectBillingReviewData = {
  items: ProjectBillingReviewItem[];
  month: number | null | undefined;
  year: number | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: ProjectBillingReviewVariables | undefined) => Promise<ApolloQueryResult<any>>;
};

const getProjectBillingReviewVariables = (
  billingCycleDate: Date,
  projectSpecialTypes: ProjectSpecialType[] | null | undefined,
  projectSpecialSubTypes: ProjectSpecialSubType[] | null | undefined,
): ProjectBillingReviewVariables => {
  const { year, month } = getYearMonthFromDate(billingCycleDate);

  const variables: ProjectBillingReviewVariables = {
    month,
    year,
  };

  if (projectSpecialTypes && projectSpecialTypes.length > 0) {
    variables.projectSpecialTypes = projectSpecialTypes;
  }

  if (projectSpecialSubTypes && projectSpecialSubTypes.length > 0) {
    variables.projectSpecialSubTypes = projectSpecialSubTypes;
  }

  return variables;
};

const useProjectBillingReviewQuery = (
  filter: BillingCycleFormData | null,
  isReady = false,
): ProjectBillingReviewData => {
  const billingCycleDate = filter?.billingCycleDate ?? new Date();
  const projectSpecialTypes = valuesOnly(filter?.projectSpecialTypes);
  const projectSpecialSubTypes = valuesOnly(filter?.projectSpecialSubTypes);

  const variables = getProjectBillingReviewVariables(billingCycleDate, projectSpecialTypes, projectSpecialSubTypes);

  const { data, loading, error, refetch } = useQuery(ProjectBillingReviewQuery.query, {
    variables,
    errorPolicy: 'all',
    skip: !filter || !isReady,
  });

  return {
    items: data?.projectBillingReview?.items || [],
    month: data?.projectBillingReview?.month,
    year: data?.projectBillingReview?.year,
    loading,
    error,
    refetch,
  };
};

export default useProjectBillingReviewQuery;
