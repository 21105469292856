import React from 'react';
import Container from '@material-ui/core/Container';

import { MarketerAssessmentDetailsProps } from './types';
import MarketerAssessmentByCompanyDetails from './MarketerAssessmentByCompanyDetails';
// import MarketerAssessmentPostMatching from './MarketerAssessmentPostMatching';

const MarketerAssessmentDetails: React.FC<MarketerAssessmentDetailsProps> = (props) => {
  const { projectId } = props;

  return (
    <Container maxWidth="md">
      <MarketerAssessmentByCompanyDetails projectId={projectId} />
      {/* <MarketerAssessmentPostMatching projectId={projectId} /> */}
    </Container>
  );
};

export default MarketerAssessmentDetails;
