import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    dialogPaper: {},
    dialogTitleRoot: {
      padding: 0,
    },
    dialogContent: {
      padding: '1rem',
      '&:first-child': {
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        padding: '1rem 2rem',
      },
    },
    dialogActions: {},
    fullScreen: {
      '& $dialogContent': {
        marginTop: 56,
        [theme.breakpoints.up('sm')]: {
          marginTop: 64,
        },
      },
    },
    '&.MuiDialog-paperFullScreen': {
      '& $dialogContent': {
        paddingTop: 56,
        [theme.breakpoints.up('sm')]: {
          paddingTop: 64,
        },
      },
    },
    toolbarRoot: {},
    titlesWrapper: {},
    title: {},
    subtitle: {},
  })
);

export default useStyles;
