import { useContext } from 'react';

import { IActingAccountContext, ActingAccountContext } from '../context/AccountContext';

const useActingAccount = (): IActingAccountContext => {
  const context = useContext(ActingAccountContext);

  if (context === undefined) {
    throw new Error('useActingAccount must be used within a ActingAccountProvider');
  }

  return context;
};

export default useActingAccount;
