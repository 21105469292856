import { CurrentWorkStatusType, MarketerGraduationPlan, MarketerType } from '@mayple/types';

import {
  CURRENT_WORK_STATUS_LABELS_LOOKUP,
  MARKETER_GRADUATION_PLAN_LABELS_LOOKUP,
} from '../../../../../fe_common/client/app/enums';
import { yesNoFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import { PropertiesMetaType } from '../../../../../fe_common/client/components/display/PropertiesList';
import { externalLinkFormatter } from '../../../../logic/linkFormatters';

const currentWorkStatusLabelsLookup = CURRENT_WORK_STATUS_LABELS_LOOKUP as Record<CurrentWorkStatusType, string>;
const marketerGraduationPlanLabelsLookup = MARKETER_GRADUATION_PLAN_LABELS_LOOKUP as Record<
  MarketerGraduationPlan,
  string
>;

const getEntityExtraDetailsProperties = (marketerType = MarketerType.FREELANCER): PropertiesMetaType[] => [
  {
    label: 'Number of employees',
    path: 'marketerProfile.numberOfEmployees',
    group: 'Other',
    defaultValue: 'N/A',
    hidden: marketerType === MarketerType.FREELANCER,
  },
  {
    label: 'Marketer Graduation Plan',
    path: 'graduationPlan',
    formatter: (value: MarketerGraduationPlan | null | undefined) =>
      value != null ? marketerGraduationPlanLabelsLookup[value] : 'N/A',
    group: 'Other',
    defaultValue: 'N/A',
  },
  {
    label: 'Years of experience',
    path: 'marketerProfile.yearFirstStartedDigitalMarketing',
    formatter: (value: any) => (Number.isInteger(value) ? (new Date().getFullYear() - value).toString() : 'N/A'),
    group: 'Other',
    defaultValue: 'N/A',
  },
  {
    label: 'Current Work Status',
    path: 'marketerProfile.currentWorkStatus',
    formatter: (value: CurrentWorkStatusType | null | undefined) =>
      value != null ? currentWorkStatusLabelsLookup[value] : 'N/A',
    group: 'Other',
    defaultValue: 'N/A',
    hidden: marketerType === MarketerType.DIGITAL_AGENCY,
  },
  {
    label: 'Available for projects',
    path: 'matchingSettings.availableForProjects',
    formatter: yesNoFormatter,
    group: 'Matching settings',
    defaultValue: 'N/A',
  },
  {
    label: 'Matching enabled',
    path: 'matchingSettings.enableMatching',
    formatter: yesNoFormatter,
    group: 'Matching settings',
    defaultValue: 'N/A',
  },
  {
    label: 'Concurrent Projects Limit (set by Expert)',
    path: 'marketerCapabilities.maximumConcurrentProjects',
    formatter: (value: any) => (Number.isInteger(value) ? value.toString() : 'N/A'),
    group: 'Matching settings',
    defaultValue: 'N/A',
  },
  {
    label: 'Maximum Concurrent Projects Limit (set by Admin)',
    path: 'matchingSettings.maximumConcurrentProjectsLimit',
    formatter: (value: any) => (Number.isInteger(value) ? value.toString() : 'N/A'),
    group: 'Matching settings',
    defaultValue: 'N/A',
  },
  {
    label: 'Marketer Profile can be shared',
    path: 'profileSettings.isShareable',
    formatter: yesNoFormatter,
    group: 'Profile Settings',
    defaultValue: 'N/A',
  },
  {
    label: 'Link to Public Marketer Profile',
    path: 'externalProfileLink',
    formatter: externalLinkFormatter,
    group: 'Profile Settings',
  },
];

export default getEntityExtraDetailsProperties;
