import React, { FC, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import { MarketingServiceType, AdditionalPackageProfile, AdditionalServicePackagesProfile } from '@mayple/types';

import { defaultPackage } from './logic';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { safeExtractCurrencyValue } from '../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';

interface PackageSelectorProps {
  open: boolean;
  packages: AdditionalServicePackagesProfile[];
  onSelect: (x: Record<string, any>, y: MarketingServiceType) => void;
  onClose: () => void;
}

const PackageSelector: FC<PackageSelectorProps> = (props) => {
  const { packages, open, onSelect, onClose } = props;
  const [selectedService, setSelectedService] = useState<MarketingServiceType | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<AdditionalPackageProfile | null>(null);
  const [flavors, setFlavors] = useState<AdditionalPackageProfile[]>([]);

  useEffect(() => {
    if (flavors?.length === 1) {
      setSelectedPackage(flavors[0]);
    }
  }, [flavors]);

  const onServiceSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selected = (e.target?.value as MarketingServiceType) || '';
    setFlavors(() => {
      if (!selected) {
        return [];
      }

      return (
        ((packages.find(({ serviceType }) => serviceType === selected) || {}).packages as AdditionalPackageProfile[]) ||
        ([] as AdditionalPackageProfile[])
      );
    });

    setSelectedService(selected);
    setSelectedPackage(null);
  };
  const onFlavorSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    // Due to following warning we use e.persist():
    // This synthetic event is reused for performance reasons. If you're seeing this, you're accessing the property
    // `target` on a released/nullified synthetic event. This is set to null. If you must keep the original synthetic
    // event around, use event.persist(). See https://fb.me/react-event-pooling for more information.
    e.persist();
    setSelectedPackage(() => flavors.find((flavor) => flavor?.flavor === e.target?.value) || null);
  };

  const shouldSelectPackage = !selectedPackage && flavors.length > 0;
  const shouldSelectService = !selectedService;
  const disableButton: boolean = shouldSelectService || shouldSelectPackage;

  const onPackageSelect = () => {
    if (disableButton || !selectedService) {
      alert('Something went wrong. Please restart the selection flow');
      return;
    }
    onSelect(selectedPackage || defaultPackage, selectedService);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Select service</DialogTitle>
      <DialogContent>
        <div>
          <FormControl style={{ minWidth: 300, margin: '16px 0' }}>
            <Select value={selectedService} native variant="outlined" onChange={onServiceSelect}>
              <option label="Please select" value="" />
              {packages.map(({ serviceType, title }) => (
                <option key={`PackageSelector-${serviceType}`} value={serviceType || 'N/A'}>
                  {/* @ts-ignore */}
                  {title || serviceType}
                </option>
              ))}
            </Select>
          </FormControl>

          {!!selectedService && flavors.length === 0 && (
            <CalloutMessage message="This Service does not include pre-defined package" />
          )}

          {flavors.length > 0 && (
            <FormControl style={{ minWidth: 300, margin: '16px 0' }}>
              <Select value={selectedPackage?.flavor} native variant="outlined" onChange={onFlavorSelect}>
                <option label="Select package" value="" />
                {flavors.map((additionalPackageProfile) => (
                  <option
                    key={`PackageSelector-flavor-${additionalPackageProfile?.flavor}`}
                    value={additionalPackageProfile?.flavor || ''}
                  >
                    {additionalPackageProfile?.name} {additionalPackageProfile?.isDefault && '(default)'}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {!!selectedPackage && (
            <div>
              <Card>
                <CardContent>
                  <TextDisplay bold>{selectedPackage.name || selectedPackage.flavor}</TextDisplay>
                  <TextDisplay>
                    Default cost {safeExtractCurrencyValue(selectedPackage.cost, selectedPackage.currency)}
                  </TextDisplay>
                  <TextDisplay variant="body2">
                    Includes: {selectedPackage.variableFactorTitle} {selectedPackage.variableFactorCount}{' '}
                    {selectedPackage.variableFactorUnit}
                  </TextDisplay>
                </CardContent>
                <CardActions>
                  <Button size="small" label="View all features (COMING SOON)" disabled color="primary" />
                </CardActions>
              </Card>
            </div>
          )}
        </div>
        <DialogActions>
          <Button label="Cancel" color="primary" onClick={onClose} />
          <Button
            label="Select package"
            variant="contained"
            color="primary"
            disabled={disableButton}
            onClick={onPackageSelect}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PackageSelector;
