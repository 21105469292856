import React, { FC, useMemo } from 'react';
import { Country, Project } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import EditableComponent from '../../../molecules/EditableComponent';
import { TARGETED_COUNTRIES_LABELS_LOOKUP, TARGETED_COUNTRIES_PAIRS } from '../../../../app/enums';
import ChipList from '../../../atoms/ChipList';
import { WizardStepField } from '../../../molecules/WizardStepField';
import getEnumsInitialValues from '../../../molecules/EditableComponent/logic';
import { UseMutation } from '../../../../hooks/useMutation';
import { ProjectUpdateQueryVariables } from '../../types';
import { LabelValueProps } from '../../../../app/types';
import { valuesOnly } from '../../../../services/utils';
import { EditableComponentField } from '../../../molecules/EditableComponent/types';

type ProjectTargetLocationsEditorProps = {
  project: Project;
  mutation: UseMutation;
  enableEdit?: boolean;
};

enum WWCountry {
  WW = 'WW',
}

const ExtendedCountry = { ...Country, ...WWCountry };
type ExtendedCountry = Country | WWCountry; // typeof ExtendedCountry;

const ProjectTargetLocationsEditor: FC<ProjectTargetLocationsEditorProps> = (props) => {
  // console.log('ProjectTargetLocationsEditor props', props);
  const { project, mutation, enableEdit = false } = props;
  const { locations, id: projectId } = project;

  const locationsWithWW: ExtendedCountry[] = useMemo(() => [...(project?.locations || [])], [project?.locations]);

  if (project.worldwideAudienceTargetting) {
    locationsWithWW.push(WWCountry.WW);
  }

  const initialLocationsValue = useMemo(() => {
    const init = getEnumsInitialValues(locations, TARGETED_COUNTRIES_PAIRS);
    // Reason that we send to initial values the same reference and not just an object with same values, is
    // because MUI Autocomplete component compares references for values selected and values in the options list
    if (project.worldwideAudienceTargetting) {
      init.push(TARGETED_COUNTRIES_PAIRS.find(({ value }) => value === 'WW'));
    }

    return init;
  }, [locations, project]);

  const view = useMemo(
    () => (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <ChipList items={locationsWithWW} labelsLookup={TARGETED_COUNTRIES_LABELS_LOOKUP} />
          </Grid>
        </Grid>
      </div>
    ),
    [locationsWithWW],
  );

  const fields: EditableComponentField[] = [
    {
      name: 'locations',
      type: WizardStepField.types.AUTOCOMPLETE, // special multi select for countries
      fieldProps: {
        enums: TARGETED_COUNTRIES_PAIRS,
        isMulti: true,
      },
    },
  ];

  const handleValuesBeforeSave = (values: {
    projectId: number | undefined;
    locations: LabelValueProps<ExtendedCountry>[] | undefined;
  }) => {
    const finalLocations: LabelValueProps<ExtendedCountry>[] = (values?.locations || []).filter(
      ({ value }: { value: ExtendedCountry }) => value !== WWCountry.WW,
    );

    const worldwideAudienceTargetting = (values?.locations || []).some(({ value }) => value === WWCountry.WW);

    const variables: ProjectUpdateQueryVariables = {
      projectId: values?.projectId,
      projectUpdate: {
        locations: valuesOnly(finalLocations) as Country[],
        worldwideAudienceTargetting,
      },
    };

    return variables;
  };

  return (
    <EditableComponent
      view={view}
      fields={fields}
      enableEdit={enableEdit}
      mutation={mutation}
      handleValuesBeforeSave={handleValuesBeforeSave}
      initialValues={{ locations: initialLocationsValue, projectId }}
    />
  );
};

export default ProjectTargetLocationsEditor;
