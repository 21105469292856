import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import Checkbox, { CheckboxProps } from '../../Registration/Checkbox';

type WizardCheckboxInputProps = WrappedFieldProps & CheckboxProps;

const WizardCheckboxInput: React.FC<WizardCheckboxInputProps> = (props) => {
  const { input, meta, name, helperText, ...rest } = props;
  const { error, touched, invalid } = meta;

  const hasError = touched && invalid;
  const errorMessage = hasError && error ? error : undefined;

  return (
    <Checkbox
      name={input?.name || name}
      value={input?.value}
      onChange={input?.onChange}
      checked={input?.value === true}
      error={hasError}
      helperText={errorMessage || helperText}
      {...rest}
    />
  );
};

export default WizardCheckboxInput;
