import React, { useMemo } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';

import { EntitySelectionFilterProps } from './types';
import useEntitySelectionFilterLogic from './useEntitySelectionFilterLogic';
import { TRANSLATION_NS } from '../../../constants';
import EntitySearchAutoComplete from '../../../../../../Search/EntitiySearchAutoComplete';
import getEntityTypeFromAccountType from '../../../../../../../hooks/entities';

import useStyles from './style';

const EntitySelectionFilter: React.FC<EntitySelectionFilterProps> = (props) => {
  const classes = useStyles(props);
  const { accountType } = props;

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'AvailabilityViewerFilter' });

  const { enableEntitySearch, onEntitySelected, onEnableEntitySearchChange, errorMessage } =
    useEntitySelectionFilterLogic(props);

  const entityType = useMemo(() => getEntityTypeFromAccountType(accountType), [accountType]);

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox checked={enableEntitySearch} onChange={onEnableEntitySearchChange} name="enableEntitySearch" />
        }
        label={t('enableAccountTypeSelection', { accountType: t(accountType) })}
      />
      <EntitySearchAutoComplete
        entityType={entityType}
        onSelect={onEntitySelected}
        disabled={!enableEntitySearch}
        helperText={errorMessage}
      />
    </div>
  );
};

export default EntitySelectionFilter;
