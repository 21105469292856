/* eslint-disable function-paren-newline */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeedLegend, Organization, Person } from '@mayple/types';
import { useTimeout } from '@react-hook/timeout';
import { useInView } from 'react-intersection-observer';
import { reportEvent } from '../../logic/events';
import { FeedEventTraits } from './types';
import { useFeedState } from './FeedContext';

export const getAuthorOrganization = (legend: FeedLegend, authorUuid: string): Organization | undefined => {
  const author = legend.persons.find((person: Person) => authorUuid === person.uuid);

  if (!author) {
    return undefined;
  }

  return (legend.organizations || []).find(
    (organization: Organization) => author.organizationUuid === organization.uuid
  );
};

export const useFeedEvents = (
  disableAnalyticsEvents = false
): {
  onPostReactionHandler: (traits: Record<string, any>) => void;
  onPostViewed: (traits: Record<string, any>) => void;
  onCommentViewed: (traits: Record<string, any>) => void;
} => {
  const onPostReactionHandler = useCallback(
    (traits: Record<string, any>) => {
      const { reactionState, authorAccountType } = traits;
      const action = reactionState ? 'Reacted' : 'Unreacted';

      if (disableAnalyticsEvents) {
        return;
      }

      reportEvent('Project.InsightsFeed.Post', action, authorAccountType, traits);
    },
    [disableAnalyticsEvents]
  );

  const onPostViewed = useCallback(
    (traits: Record<string, any>) => {
      const { authorAccountType } = traits;

      if (disableAnalyticsEvents) {
        return;
      }
      reportEvent('Project.InsightsFeed.Post', 'Viewed', authorAccountType, traits);
    },
    [disableAnalyticsEvents]
  );

  const onCommentViewed = useCallback(
    (traits: Record<string, any>) => {
      const { authorAccountType } = traits;

      if (disableAnalyticsEvents) {
        return;
      }
      reportEvent('Project.InsightsFeed.Comment', 'Viewed', authorAccountType, traits);
    },
    [disableAnalyticsEvents]
  );

  return {
    onPostReactionHandler,
    onPostViewed,
    onCommentViewed,
  };
};

export const useFeedTraits = (authorUuid: string): Partial<FeedEventTraits> => {
  const { legend, traits: basicTraits } = useFeedState();

  const authorOrganization = useMemo(() => getAuthorOrganization(legend, authorUuid), [authorUuid, legend]);

  return {
    ...basicTraits,
    authorAccountName: authorOrganization?.name,
    authorAccountId: authorOrganization?.companyId || authorOrganization?.marketerId,
    authorAccountType: authorOrganization?.type,
  };
};

/**
 * Hook for triggering any callback function when element is visible on the screen/viewport for x ms
 * @param callback - function - The function that will be called when the timeout was reached
 * @param delayTime - number - Time to wait (in ms) before triggering the callback
 * @param threshold - number (0-1) - The portion of the element that appears on the screen in order to trigger the
 *   intersection-observer
 * @param triggerOnce - boolean - should the callback be executed only once per Element
 */
export const useDelayedEventTriggerForElementInView = (
  callback: () => void,
  delayTime = 0,
  threshold = 0,
  triggerOnce = false
): {
  ref: (node?: Element | null | undefined) => void;
} => {
  const [triggered, setTriggered] = useState(false); // whether the callback was triggered (used by triggerOnce)
  const [isRunning, setIsRunning] = useState(false); // help determine if the timeout has started
  const [timedOut, startTimeout, resetTimeout] = useTimeout(delayTime);

  // ref - is the reference to be sent to the element we want to track Intersection
  // inView - indicated whether the element is visible in the viewport
  const [ref, inView] = useInView({
    threshold,
  });

  useEffect(() => {
    // If the element is in view and the timeout is NOT running - we should start the timeout
    if (inView && !isRunning) {
      setIsRunning(true);
      startTimeout();
    }

    // If the element is NOT in view and the timeout is running - we should reset the timeout
    if (!inView && isRunning) {
      setIsRunning(false);
      resetTimeout();
    }

    // if timeout was reached - we want to mark the isRunning as false and reset the timeout
    // We want to do this even if the element is in view or not
    // this was done to prevent triggering the callback twice (something to do with the component rendering)
    if (timedOut) {
      setIsRunning(false);
      resetTimeout();
    }

    // if the element is in view and timeout was reached, there is no timeout running, and the event is marked as not
    // triggered - we are calling the callback
    if (inView && timedOut && !isRunning && !triggered) {
      callback();
      // only if the event should be triggered once - we are setting it to "triggered"
      // otherwise - triggered will remain false - and the callback can be triggered as many times the element meets
      // the conditions
      if (triggerOnce) {
        setTriggered(true);
      }
    }
  }, [inView, isRunning, callback, triggered, resetTimeout, startTimeout, timedOut, triggerOnce]);

  return {
    ref,
  };
};
