import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';

import { DEFAULT_NUMBER_OF_COLUMNS } from './consts';
import { MultiplePackagesSelectorProps, SelectedPackagesKey } from './types';
import {
  checkIsSelected,
  getOfferingClassname,
  getMaxSelectionRulesOverridePerChapter,
  selectedByOffering,
  sortByChapterAndPrice,
} from './logic';
import useMultiplePackagesSelectorData from './useMultiplePackagesSelectorData';
import EmptyCellPlaceholder from './EmptyCellPlaceholder';
import AdditionalPackageCard from '../AdditionalPackageCard';

import { SkeletonContainer, TextDisplay } from '../../../display';
import { WizardNavigationButtons } from '../../../navigation';
import { useDeviceInfo } from '../../../../hooks';

import useStyles from './style';

const MultiplePackagesSelector: React.FC<MultiplePackagesSelectorProps> = (props) => {
  const classes = useStyles(props);
  const {
    loading = false,
    hideCost = false,
    hideDescription = false,
    numOfColumns = DEFAULT_NUMBER_OF_COLUMNS,
    maxSelectedPackages = -1,
    maxSelectedPackagesFromChapter = -1,
    maxSelectionRulesOverride,
    onCreateNewProject,
    packagesSortOverride,
    errorMessage,
  } = props;

  const history = useHistory();
  const { isMobile } = useDeviceInfo();

  const {
    loading: loadingPackagesData,
    selectedPackages,
    extendedPackages,
    onSelectHandler,
    onCreateNewProjectClickHandler,
  } = useMultiplePackagesSelectorData(props);

  // eslint-disable-next-line no-nested-ternary
  const maxNumOfColumns = isMobile ? 1 : 5;
  const finalNumberOfColumns = Math.min(numOfColumns, maxNumOfColumns) + (isMobile ? 0 : 1);

  let prevChapter: string | undefined | null;
  let shouldAddChapter = false;
  let numOfCells = 0;
  let addDivider = false;
  let groupColorClassName = classes.group0;
  let invertedGroupColorClassName = classes.group1;

  return (
    <div className={classes.root}>
      <SkeletonContainer
        className={classes.packageSkeleton}
        loading={loadingPackagesData}
        width={900}
        height={523}
        animation="wave"
      >
        <FormControl className={classes.multiplePackagesSelectorFormControl}>
          {!!errorMessage && <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>}
          <div className={classes.additionalPackageCardContainer}>
            {(extendedPackages || []).sort(packagesSortOverride ?? sortByChapterAndPrice).map((extendedPackage) => {
              const { serviceType, flavor, offering, offeringWeight } = extendedPackage;
              const offeringClassName = getOfferingClassname(classes, offeringWeight);

              if (offering !== prevChapter) {
                if (prevChapter) {
                  addDivider = true;
                }
                // save the new offering into prevChapter
                prevChapter = offering;
                shouldAddChapter = true;
                groupColorClassName = groupColorClassName === classes.group0 ? classes.group1 : classes.group0;
                invertedGroupColorClassName = groupColorClassName === classes.group0 ? classes.group1 : classes.group0;
              } else {
                shouldAddChapter = false;
                addDivider = false;
              }

              let isTitleColumn = numOfCells % finalNumberOfColumns === 0;
              let numOfEmptyCellsToAdd = 0;

              if (shouldAddChapter && offering && !isTitleColumn) {
                // we need to fill in the gaps till the end. and then check if this is a isTitleColumn column again
                // we should save the number of empty cell to add
                numOfEmptyCellsToAdd = finalNumberOfColumns - (numOfCells % finalNumberOfColumns);

                // adding the number of empty cell to the numOfCells
                numOfCells += numOfEmptyCellsToAdd;

                // validate that this is now a title column
                isTitleColumn = numOfCells % finalNumberOfColumns === 0;
              }

              // now we check if we are at
              if (shouldAddChapter && offering && isTitleColumn) {
                // As we are adding a title cell
                numOfCells += 1;
              }

              // if this is a new line, but title haven't changes - then we only need to add one empty cell
              if (isTitleColumn && !shouldAddChapter) {
                numOfCells += 1;
              }

              // and finally we need to add a normal cell
              numOfCells += 1;

              const currPackage: SelectedPackagesKey = {
                serviceType,
                flavor: flavor ?? '',
                offering,
              };

              const isSelected = checkIsSelected(selectedPackages, currPackage);

              const limitedByMaxSelectedPackages =
                maxSelectedPackages > -1 && selectedPackages.length >= maxSelectedPackages && !isSelected;

              const finalMaxSelectedPackagesFromChapter = getMaxSelectionRulesOverridePerChapter(
                maxSelectionRulesOverride,
                extendedPackage,
                maxSelectedPackagesFromChapter
              );

              const limitedByMaxSelectedPackagesFromChapter =
                finalMaxSelectedPackagesFromChapter > -1 &&
                selectedByOffering(selectedPackages, currPackage) >= finalMaxSelectedPackagesFromChapter &&
                !isSelected;

              const isDisabled = limitedByMaxSelectedPackages || limitedByMaxSelectedPackagesFromChapter;

              return (
                <React.Fragment key={`extendedPackage-${offering}-${flavor}`}>
                  {numOfEmptyCellsToAdd > 0 && (
                    <EmptyCellPlaceholder
                      name={offering || ''}
                      numberOfEmptyCells={numOfEmptyCellsToAdd}
                      classes={{
                        root: invertedGroupColorClassName,
                      }}
                    />
                  )}
                  {addDivider && (
                    <div className={classes.divider}>
                      <Divider />
                    </div>
                  )}
                  {isTitleColumn && !shouldAddChapter && <div className={groupColorClassName} />}
                  {isTitleColumn && shouldAddChapter && offering && (
                    <div className={classNames(groupColorClassName, classes.labelCell)}>
                      <TextDisplay key={`extendedPackage-title-cell-${offering}`} variant="h6" bold>
                        {offering}
                      </TextDisplay>
                    </div>
                  )}
                  <AdditionalPackageCard
                    key={`additionalPackage-${extendedPackage.serviceType}-${extendedPackage.flavor}`}
                    additionalPackage={extendedPackage}
                    isSelected={isSelected}
                    onSelect={onSelectHandler}
                    hideDescription={hideDescription}
                    hideCost={hideCost}
                    disabled={isDisabled}
                    showLearnMore
                    classes={{
                      root: groupColorClassName,
                      nonPpcCard: classNames(classes.offeringBorder, offeringClassName),
                    }}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </FormControl>
      </SkeletonContainer>
      {!!onCreateNewProject && (
        <div className={classes.actionsContainer}>
          <WizardNavigationButtons
            step={{}}
            prevButton={{
              variant: 'outlined',
              size: 'large',
              label: 'Back',
              color: 'primary',
              disabled: loading || loadingPackagesData,
              onClick: () => history.goBack(),
            }}
            nextButton={{
              color: 'primary',
              variant: 'contained',
              size: 'large',
              label: 'Continue',
              loading: loading || loadingPackagesData,
              disabled: !selectedPackages?.length || loading,
              onClick: onCreateNewProjectClickHandler,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MultiplePackagesSelector;
