import { stringArrayFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import { PropertiesMetaType } from '../../../../../fe_common/client/components/display/PropertiesList';

const getProjectDeepBriefPreloadedAnswersProperties = (classes: Record<string, string>): PropertiesMetaType[] => [
  {
    label: 'What are your marketing challenges?',
    path: 'whatAreYourChallenges',
    defaultValue: 'N/A',
    customClass: classes.alignLeft,
  },
  {
    label: 'Mailchimp Admin Email',
    path: 'mailchimpAdminEmail',
    defaultValue: 'N/A',
  },
  {
    label: 'User title',
    path: 'userTitle',
    defaultValue: 'N/A',
  },
  {
    label: 'What do you hope to accomplish with Mailchimp in the next 3 months?',
    path: 'accomplishWithMailchimp3Months',
    defaultValue: 'N/A',
  },
  {
    label: 'Of these options, which 3 are most important to your business?',
    path: 'mostImportantGoals',
    formatter: stringArrayFormatter,
    defaultValue: 'N/A',
  },
  {
    label: 'If you chose Integrations above, please select all that apply.',
    path: 'integrations',
    formatter: stringArrayFormatter,
    defaultValue: 'N/A',
  },
  {
    label: 'Should we include anyone else in your health check conversation?',
    path: 'additionalContacts',
    formatter: stringArrayFormatter,
    defaultValue: 'N/A',
  },
];
export default getProjectDeepBriefPreloadedAnswersProperties;
