import { useCallback, useMemo } from 'react';
import { CronofyAvailabilityProfiles, CronofyElementToken } from '@mayple/types';

import { Constants, defaultStyles } from '../constants';
import { AvailabilityRulesInitializerProps } from '../index';
import {
  AvailabilityRuleCallbackObject,
  CallbackEventType,
  CallbackEventTypes,
  CronofyAvailabilityRulesOptions,
  CronofyMeetingDuration,
  KeyboardSupport,
  ValidTime,
} from '../../types/cronofyTypes';

const useAvailabilityRulesConfiguration = (
  props: AvailabilityRulesInitializerProps,
  elementToken: CronofyElementToken,
  availabilityProfile?: CronofyAvailabilityProfiles
): CronofyAvailabilityRulesOptions | null => {
  const { initOptions: options, onAvailabilityRuleSaved, onAvailabilityRuleEdited, onAvailabilityRuleNotFound } = props;

  const defaultCallback = useCallback(
    (callbackObject: AvailabilityRuleCallbackObject) => {
      const actionType: CallbackEventType = callbackObject.notification.type;

      switch (actionType) {
        case CallbackEventTypes.availabilityRuleEdited:
          if (onAvailabilityRuleEdited) {
            onAvailabilityRuleEdited(callbackObject);
          }
          break;
        case CallbackEventTypes.availabilityRuleSaved:
          if (onAvailabilityRuleSaved) {
            onAvailabilityRuleSaved(callbackObject);
          }
          break;
        case CallbackEventTypes.availabilityRuleNotFound:
          if (onAvailabilityRuleNotFound) {
            onAvailabilityRuleNotFound(callbackObject);
          }
          break;
        default:
          break;
      }
    },
    [onAvailabilityRuleEdited, onAvailabilityRuleNotFound, onAvailabilityRuleSaved]
  );

  const cronofyAvailabilityRulesOptions = useMemo(() => {
    const token = elementToken?.token || '';
    const isDemo = options?.demo === true;

    // at this point we need to validate we have a valid token and that we are not in demo mode
    if (!elementToken && !isDemo) {
      return null;
    }

    const elementTargetId = options?.elementTargetId || Constants.DEFAULT_ELEMENT_TARGET_ID;
    const startTime = (options?.config?.startTime || Constants.DEFAULT_WORKING_DAY_START_TIME) as ValidTime;
    const endTime = (options?.config?.endTime || Constants.DEFAULT_WORKING_DAY_END_TIME) as ValidTime;
    const duration = (options?.config?.duration || Constants.DEFAULT_MEETING_DURATION) as CronofyMeetingDuration;
    const timeZone = options?.config?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const styles = options?.styles || defaultStyles;
    const weekStartDay = options?.config?.weekStartDay || Constants.DEFAULT_WEEK_START_DAY;
    const keyboardSupport = options?.config?.enableKeyboardSupport
      ? (Constants.DEFAULT_KEYBOARD_SUPPORT_BASIC as KeyboardSupport)
      : undefined;
    const callback = options?.callback || defaultCallback;

    return {
      element_token: token,
      target_id: elementTargetId,
      availability_rule_id: availabilityProfile || Constants.DEFAULT_AVAILABILITY_RULE_ID,
      tzid: timeZone,
      config: {
        week_start_day: weekStartDay,
        start_time: startTime,
        end_time: endTime,
        duration,
        keyboard_support: keyboardSupport,
      },
      styles,
      demo: isDemo,
      callback,
    };
  }, [
    elementToken,
    options?.demo,
    options?.elementTargetId,
    options?.config?.startTime,
    options?.config?.endTime,
    options?.config?.duration,
    options?.config?.timeZone,
    options?.config?.weekStartDay,
    options?.config?.enableKeyboardSupport,
    options?.styles,
    options?.callback,
    defaultCallback,
    availabilityProfile,
  ]);

  return cronofyAvailabilityRulesOptions;
};

export default useAvailabilityRulesConfiguration;
