import {
  Country,
  CurrencyType,
  Industry,
  IndustryInput,
  MarketerMatchingWeightsInput,
  MediaType,
  ProjectCreateInput,
  TestMarketerMatchesInput,
} from '@mayple/types';
import { v4 as uuidV4 } from 'uuid';

import { defaultInitialValues, defaultWeights } from './consts';
import { MatchingSimulatorData, MatchingSimulatorForm } from './types';

import {
  COUNTRIES_PAIRS,
  INDUSTRIES_PAIRS,
  LANGUAGES_PAIRS,
  MARKETING_SKILLS_PAIRS_WITH_ICONS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
} from '../../../../../fe_common/client/app/enums';
import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { valuesOnly } from '../../../../../fe_common/client/services/utils';
import { IndustryLabelValueProps, LabelValueProps, RemoteWork } from '../../../../../fe_common/client/app/types';
import { convertToLabelValue, fromArrayToLabelValueArray } from '../../../../../fe_common/client/logic/formHelpers';

const getMarketerLocation = (
  preferLocalMarketers?: boolean,
  preferMarketerInTargettedLocations?: boolean,
): RemoteWork => {
  if (preferLocalMarketers) {
    return RemoteWork.DOMESTIC;
  }
  if (preferMarketerInTargettedLocations) {
    return RemoteWork.ON_TARGET;
  }
  return RemoteWork.NO_PREFERENCE;
};

export const getIndustryExpertise = (industries?: Industry[] | null): IndustryLabelValueProps | undefined => {
  if (!industries) {
    return undefined;
  }

  const [industry] = industries;
  if (!industry) {
    return undefined;
  }

  return convertToLabelValue(
    industry.industrySubCategory as string,
    INDUSTRIES_PAIRS as LabelValueProps[],
  ) as IndustryLabelValueProps;
};

export const mapMatchingSimulatorDataToMatchingSimulatorForm = (
  values: Partial<MatchingSimulatorData>,
): Partial<MatchingSimulatorForm> => {
  const {
    estimatedMediaBudget,
    testCompanyCountry,
    skills,
    locations,
    languages,
    targetKPI,
    productBusinessModel,
    preferLocalMarketers,
    preferMarketerInTargettedLocations,
    industry,
    requestedProjectSpecialType,
  } = values;

  return {
    targetKPI,
    locations: fromArrayToLabelValueArray(locations, COUNTRIES_PAIRS as LabelValueProps[]),
    testCompanyCountry: convertToLabelValue(testCompanyCountry || Country.US, COUNTRIES_PAIRS as LabelValueProps[]),
    skills: fromArrayToLabelValueArray(skills, MARKETING_SKILLS_PAIRS_WITH_ICONS as LabelValueProps[]),
    languages: fromArrayToLabelValueArray(languages, LANGUAGES_PAIRS as LabelValueProps[]),
    estimatedMediaBudget: estimatedMediaBudget || 0,
    productBusinessModel: fromArrayToLabelValueArray(
      productBusinessModel,
      PRODUCT_BUSINESS_MODEL_PAIRS as LabelValueProps[],
    ),
    preferLocalMarketers: getMarketerLocation(preferLocalMarketers, preferMarketerInTargettedLocations),
    industryExpertise: getIndustryExpertise(industry),
    requestedProjectSpecialType,
  };
};

export const getInitialValues = (
  initialValues?: Partial<MatchingSimulatorData> | null,
): Partial<MatchingSimulatorForm> => {
  if (initialValues) {
    return {
      ...mapMatchingSimulatorDataToMatchingSimulatorForm(initialValues),
      ...defaultWeights,
    };
  }

  return {
    ...mapMatchingSimulatorDataToMatchingSimulatorForm(defaultInitialValues),
    ...defaultWeights,
  };
};

export const mapFormValuesToTestMarketerMatchesInput = (
  formValues: MatchingSimulatorForm,
): TestMarketerMatchesInput => {
  const {
    testCompanyCountry,
    languages,
    locations,
    industryExpertise,
    productBusinessModel,
    targetKPI,
    estimatedMediaBudget,
    preferLocalMarketers,
    skills,
    industryCategoryWeight,
    industrySubCategoryWeight,
    campaignTypeWeight,
    targetLocationWeight,
    languageWeight,
    mediaBudgetWeight,
    successStoriesIndustryWeight,
    successStoriesSubIndustryWeight,
    locationAffinityWeight,
    historicalPerformanceWeight,
    levelOfServiceWeight,
    marketingServiceExpertiseWeight,
    requestedProjectSpecialType,
  } = formValues;

  // Create correct data structure
  const requestedServices = (skills || []).map(({ value }) => ({
    uuid: uuidV4().replace(/-/g, ''),
    serviceType: value,
    description: {
      mediaType: MediaType.TEXT_PLAIN,
      contents: '',
    },
  }));

  const finalLocations: Country[] = [];
  let worldwideAudienceTargetting = false;
  (locations || ([] as LabelValueProps[])).forEach((item: LabelValueProps) => {
    if (item.value === 'WW') {
      worldwideAudienceTargetting = true;
    } else {
      finalLocations.push(item.value);
    }
  });

  const industryInput: IndustryInput = {
    industryCategory: industryExpertise?.industry,
    industrySubCategory: industryExpertise?.value,
  };

  try {
    const projectCreate: ProjectCreateInput = {
      estimatedMediaBudget,
      preferLocalMarketers: preferLocalMarketers === RemoteWork.DOMESTIC,
      preferMarketerInTargettedLocations: preferLocalMarketers === RemoteWork.ON_TARGET,
      requestedServices,
      worldwideAudienceTargetting,
      targetUrls: ['https://www.mayple.com'],
      productBusinessModel: valuesOnly(productBusinessModel),
      languages: valuesOnly(languages),
      locations: finalLocations,
      industry: [industryInput],
      currency: CurrencyType.USD,
      projectDefinition: {
        primaryObjectives: {
          targetKPI,
        },
      },
      requestedProjectSpecialType,
    };

    const dynamicMatchingWeights: MarketerMatchingWeightsInput = {
      INDUSTRY_CATEGORY_WEIGHT: industryCategoryWeight,
      INDUSTRY_SUB_CATEGORY_WEIGHT: industrySubCategoryWeight,
      CAMPAIGN_TYPE_WEIGHT: campaignTypeWeight,
      LOCATIONS_WEIGHT: targetLocationWeight,
      LANGUAGE_WEIGHT: languageWeight,
      BUDGET_RANGE_WEIGHT: mediaBudgetWeight,
      SUCCESS_STORY_INDUSTRY_CATEGORY_WEIGHT: successStoriesIndustryWeight,
      SUCCESS_STORY_INDUSTRY_SUB_CATEGORY_WEIGHT: successStoriesSubIndustryWeight,
      LOCATION_AFFINITY_WEIGHT: locationAffinityWeight,
      HISTORICAL_PERFORMANCE_WEIGHT: historicalPerformanceWeight,
      LEVEL_OF_SERVICE_WEIGHT: levelOfServiceWeight,
      MARKETING_SERVICE_EXPERTISE_WEIGHT: marketingServiceExpertiseWeight,
    };

    return {
      testProject: projectCreate,
      testWeights: dynamicMatchingWeights,
      testCompanyCountry: testCompanyCountry?.value || Country.US,
    };
  } catch (e) {
    clientLogger.error(e);
  }

  return {
    testProject: {} as ProjectCreateInput,
    testWeights: undefined,
    testCompanyCountry: Country.US,
  };
};
