import React, { FC } from 'react';
import { Project } from '@mayple/types';

import { WizardSteps } from './steps';
import { useFormSubmit } from './logic';
import LightBox from '../../../../../../../../fe_common/client/components/dialogs/LightBox';
import { WizardForm } from '../../../../../../../../fe_common/client/components/organisms/WizardForm';
import CalloutMessage from '../../../../../../../../fe_common/client/components/common/CalloutMessage';

interface ProjectBriefEditDialogProps {
  open: boolean;
  project: Project;
  onClose: () => void;
  onSuccess?: () => Promise<void>;
}

/**
 * Component for admins to edit project fields in 1 form.
 * @param props
 * @constructor
 */
const ProjectBriefEditDialog: FC<ProjectBriefEditDialogProps> = (props) => {
  const { open, onClose, onSuccess, project } = props;
  const { id: projectId } = project;
  const { initialValues, onSubmit, loading, error } = useFormSubmit(project);

  const handleFormSubmit = async (
    formValues: Record<string, any>,
    _dispatch: () => void,
    formProps: Record<string, any>,
  ) => {
    const success = await onSubmit(formValues);

    if (success) {
      onSuccess?.();
      onClose();
      formProps.destroy();
    }
  };

  return (
    <LightBox loading={loading} fullWidth maxWidth="sm" title="Edit project info" open={open} onClose={onClose}>
      <WizardForm
        form={`ProjectBriefEditDialog-${projectId}`}
        // @ts-ignore
        watchAllValues
        // @ts-ignore
        initialValues={initialValues}
        // @ts-ignore
        steps={WizardSteps}
        // @ts-ignore
        onSubmit={handleFormSubmit}
        // @ts-ignore
        onCancel={onClose}
        // @ts-ignore
        loading={loading}
      />
      {error && <CalloutMessage margin message={error?.message} type="error" />}
    </LightBox>
  );
};

export default ProjectBriefEditDialog;
