import React, { ChangeEvent, FC } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import GridOn from '@material-ui/icons/GridOn';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    tableColumns: {
      justifyContent: 'flex-end',
    },
  }),
);

interface GridLayoutSelectorProps {
  gridCols: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  classes?: Record<string, string>;
}

const GridLayoutSelector: FC<GridLayoutSelectorProps> = (props) => {
  const { gridCols, onChange } = props;
  const classes = useStyles(props);

  return (
    <RadioGroup row value={gridCols} name="tableColumns" onChange={onChange} classes={{ root: classes.tableColumns }}>
      <FormControlLabel
        label=""
        value="cols"
        control={<Radio icon={<GridOn htmlColor="#aaa" />} checkedIcon={<GridOn color="primary" />} />}
      />
      <FormControlLabel
        label=""
        value="rows"
        control={
          <Radio icon={<FormatAlignJustify htmlColor="#aaa" />} checkedIcon={<FormatAlignJustify color="primary" />} />
        }
      />
    </RadioGroup>
  );
};

export default GridLayoutSelector;
