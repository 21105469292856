import React, { FC } from 'react';
import { User } from '@mayple/types';

import { userLinkFormatter } from '../../../../logic/linkFormatters';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import CopyHtmlElementToClipboardButton from '../../../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';

import useStyles from './styles';
import HubspotContactLink from '../HubspotContactLink';
import EditUserDetailsButton from '../EditUserDetails/UserDetailsEditButton';

export type UserDetailsHeaderProps = {
  user: User;
};

const UserDetailsHeader: FC<UserDetailsHeaderProps> = (props) => {
  const { user } = props;
  const classes = useStyles();

  const { displayImageUrl } = user;

  const heroImageStyle = {
    backgroundImage: `url('${displayImageUrl}')`,
  };

  return (
    <div className={classes.root}>
      <div className={classes.heroHeader}>
        <div className={classes.heroImageContainer}>
          <div className={classes.heroImage} style={heroImageStyle} />
        </div>
        <div className={classes.heroDetailsContainer}>
          <div id="user-header">
            <TextDisplay variant="h4" bold gutterBottom className={classes.userName}>
              {userLinkFormatter(user)}
            </TextDisplay>
            <HubspotContactLink hubspotContactId={user?.hubspotContactId} />
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <CopyHtmlElementToClipboardButton
          className={classes.copyToSlackButton}
          querySelector="#user-header"
          label="Copy to Slack"
          size="small"
        />
        <EditUserDetailsButton userId={user?.id} />
      </div>
    </div>
  );
};

export default UserDetailsHeader;
