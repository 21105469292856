import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto 36px',
      tableLayout: 'fixed',
    },
    cycleRootNoNextCycle: {
      maxWidth: 840,
    },
    cycleHeader: {
      textAlign: 'right',
      width: '36%',
    },
    cycleHeaderNoNextCycle: {
      width: '60%',
    },
    cellExpand: {
      width: '48px',
      textAlign: 'left',
      paddingRight: 0,
      paddingLeft: 0,
    },
    cellLabel: {
      width: 'auto',
      textAlign: 'left',
    },
    cellCurrExtra: {
      width: '18%',
      textAlign: 'right',
    },
    cellCurrValue: {
      width: '18%',
      textAlign: 'right',
    },
    cellNextValue: {
      width: '18%',
      textAlign: 'right',
    },
    cellNextExtra: {
      width: '18%',
      textAlign: 'left',
    },
    bold: {
      fontWeight: 'bold !important',
    },
    showBullet: {
      '& > span': {
        display: 'flex',
      },
      '& > span:before': {
        content: '"\\2022"',
        display: 'inline-block',
        marginRight: 8,
        textIndent: 8,
      },
    },
    smallerFont: {
      fontSize: '1.1em',
    },
    totalRow: {
      backgroundColor: colors.white_darkest,
      '& td': {
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
      },
    },
    lastRowInGroup: {
      '& td': {
        borderBottom: '1px solid black',
      },
    },
    extendedRow: {
      backgroundColor: colors.blackOpacity(0.03),
    },
    labelTooltip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    labelTooltipReverse: {
      flexDirection: 'row-reverse',
    },
    labelTooltipIcon: {
      marginLeft: 8,
      cursor: 'pointer',
    },
    alignRight: {
      textAlign: 'right',
    },
    pending: { color: colors.yellow_dark },
    ongoing: { color: colors.green_darkest },
    elapsed: { color: colors.blue },
    finalized: { color: colors.red_light },
    canceled: { color: colors.red },
  }),
);

export const getDefaultClasses = (classes) => ({
  cellLabel: '',
  cellCurrExtra: classes.smallerFont,
  cellCurrValue: '',
  cellNextValue: '',
  cellNextExtra: classes.smallerFont,
  row: '',
});

export default useStyles;
