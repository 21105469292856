import { ProjectSponsorPayment } from '@mayple/types';

import { CSVColumn } from '../../../fe_common/client/services/ExportToCSV/types';
import { exportDataToCsv } from '../../../fe_common/client/services/ExportToCSV';

const MAILCHIMP_PAY_IN_REPORT_CSV_COLUMNS: CSVColumn[] = [
  { header: 'Project Sponsor Payment Id To Invoice', id: 'projectSponsorPaymentIdToInvoice', accessor: 'id' },
  { header: 'Payment Amount', id: 'paymentAmount', accessor: 'amount' },
  { header: 'Payment Date', id: 'paymentDate', accessor: 'created' },
  { header: 'Project Id', id: 'projectId', accessor: 'projectId' },
  { header: 'Project Name', id: 'projectName', accessor: 'project.name' },
  { header: 'Cycle Id', id: 'cycleId', accessor: 'cycleId' },
  { header: 'Cycle Start Date', id: 'cycleStartDate', accessor: 'cycle.startDate' },
  { header: 'Company Id', id: 'companyId', accessor: 'project.company.id' },
  { header: 'Company Name', id: 'companyName', accessor: 'project.company.name' },
  { header: 'Marketer Id', id: 'marketerId', accessor: 'project.participants[0].marketer.id' },
  { header: 'Marketer Name', id: 'marketerName', accessor: 'project.participants[0].marketer.name' },
  { header: 'Project Start Date', id: 'projectStartDate', accessor: 'project.startedAt' },
  { header: 'Payment Name', id: 'paymentName', accessor: 'name' },
  { header: 'Payment Description', id: 'paymentDescription', accessor: 'description' },
  // No need for the columns below at the moment
  /*
  { header: 'Payment Id', id: 'paymentId', accessor: 'paymentId' },
  { header: 'Applied Packages', id: 'appliedPackages', accessor: 'appliedPackages' },
  {
    header: 'Has Manually Configured Services Or Deliverables',
    id: 'hasManuallyConfiguredServicesOrDeliverables',
    accessor: 'cycle.hasManuallyConfiguredServicesOrDeliverables',
  },
  */
];

const createCsvData = (payments: ProjectSponsorPayment[]): void =>
  exportDataToCsv(MAILCHIMP_PAY_IN_REPORT_CSV_COLUMNS, payments);

export const getIdListFromPayments = (payments: ProjectSponsorPayment[]): number[] =>
  (payments || []).map((payment) => payment.id);

export default createCsvData;
