import { useState } from 'react';

import { AssessmentWizardFormContent } from '../types';
import { MarketerAssessmentWizardData } from '../components/AssessmentWizard/types';

const useMarketerAssessmentWizardData = (): MarketerAssessmentWizardData => {
  const [dialogContent, setDialogContent] = useState<AssessmentWizardFormContent>(
    AssessmentWizardFormContent.SHOW_FORM,
  );

  return {
    wizardFormContentContent: dialogContent,
    setWizardFormContentContent: setDialogContent,
  };
};

export default useMarketerAssessmentWizardData;
