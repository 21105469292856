import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 700,
    },
    viewerUrlContainer: {
      flexGrow: 1,
      maxWidth: '90%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '77%',
      },
    },
    itemsListContainer: {
      height: 190,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  })
);

export default useStyles;
