import React, { useState, useEffect, useMemo, useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import FeedPosts from '../FeedPosts';
import FeedPostEditor from '../FeedPostEditor';
import DeleteFeedMemberDialog from '../DeleteFeedMemeberDialog';

import { FEED_MEMBERS } from '../types';
import { createFeedLegendTable } from '../logic';
import { useFeedState } from '../FeedContext';
import usePuppetUsers from '../usePuppetUsers';
import { titleCase } from '../../../services/utils';
import PageLoadingPlaceholder from '../../atoms/PageLoadingPlaceholder';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface FeedViewerProps {
  feedUuid: string;
  feed: any;
  legend: any;
  filter?: any;
}

const FeedViewer: React.FC<FeedViewerProps> = (props) => {
  const { feedUuid, feed, legend, filter = {} } = props;

  const classes = useStyles();

  const [legendHash, setLegendHash] = useState({});
  const [initialized, setInitialized] = useState(false);
  const viewerPersonUuid = feed?.viewerPersonUuid || null;
  const { isEditable, newPostPlaceholder, deleteDialogOpen, canPostAsPuppet, selectedFeedMember, eventHandlers } =
    useFeedState();

  const { onCreatePost, onCreatePostComment, onDeletePost, onDeleteComment, onError } = eventHandlers;

  // Calling the puppet user here (because this is the highest point inside the Context provider that we can set it)
  usePuppetUsers(canPostAsPuppet);

  const topicsPre: any[] = useMemo(() => feed?.topics || [], [feed]);

  const topics = useMemo(() => topicsPre.map(({ title }) => ({ label: titleCase(title), value: title })), [topicsPre]);

  useEffect(() => {
    setLegendHash(createFeedLegendTable(legend));
  }, [legend]);

  useEffect(() => {
    setInitialized(true);
  }, [legendHash]);

  const selectedFeedMemberType = selectedFeedMember?.feedMemberType;
  const onDeleteFeedMemberSuccess = useMemo(() => {
    if (!selectedFeedMemberType) {
      return () => {
        /* Do Nothing */
      };
    }
    return selectedFeedMemberType === FEED_MEMBERS.POST ? onDeletePost : onDeleteComment;
  }, [selectedFeedMemberType, onDeletePost, onDeleteComment]);

  const onDeleteFeedMemberFail = useCallback(
    (errorMessage: string) => {
      // Call external Error Handler
      onError(errorMessage);
    },
    [onError]
  );

  // console.log('feed', feed);
  // console.log('legend', legend);
  // console.log('legendHash', legendHash);
  // console.log('filter', filter);

  if (!initialized) {
    return <PageLoadingPlaceholder />;
  }

  return (
    <div id={feedUuid} className={classes.root}>
      {isEditable && (
        <FeedPostEditor
          feedUuid={feedUuid}
          authorUuid={viewerPersonUuid}
          legend={legendHash}
          placeholder={newPostPlaceholder}
          topics={topics}
          onCreatePost={onCreatePost}
        />
      )}
      <FeedPosts feed={feed} legend={legendHash} filter={filter} onCreatePostComment={onCreatePostComment} />
      {deleteDialogOpen && (
        <DeleteFeedMemberDialog
          open={deleteDialogOpen}
          onDeleteFeedMemberSuccess={onDeleteFeedMemberSuccess}
          onDeleteFeedMemberFail={onDeleteFeedMemberFail}
        />
      )}
    </div>
  );
};

export default FeedViewer;
