/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import { CardWithIcon } from '../CardWithIcon';

export class RadioCardsWithSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sliders: {},
    };

    this.renderCards = this.renderCards.bind(this);
    this.renderSliders = this.renderSliders.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnDragStop = this.handleOnDragStop.bind(this);
    this.createSVGComponent = this.createSVGComponent.bind(this);
    this.handleOnSliderChange = this.handleOnSliderChange.bind(this);
  }

  componentDidMount() {
    const {
      enums,
      input: { value: reduxValue },
    } = this.props;
    // console.log('reduxValue', reduxValue);
    const { sliders } = this.state;
    enums.forEach(({ value }) => {
      const channel = reduxValue[value];
      if (!channel) {
        sliders[value] = {
          selected: false,
          value: 0,
        };
      } else {
        sliders[value] = {
          selected: channel.selected,
          value: channel.value,
        };
      }
    });
    this.setState({ sliders });
  }

  handleOnClick(channel) {
    const { value } = channel;
    const { sliders } = this.state;
    const { input } = this.props;
    const { onChange } = input;
    if (!sliders[value].selected) {
      sliders[value].selected = true;
      sliders[value].value = 5;
      // const slidersCopy = Object.assign({}, sliders);
      const slidersCopy = {
        ...sliders,
      };

      onChange(slidersCopy);
    } else {
      sliders[value].selected = false;
      sliders[value].value = 0;
      const slidersCopy = {
        ...sliders,
      };

      onChange(slidersCopy);
    }
    this.setState({ sliders });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOnSliderChange(e, value, channelName) {
    // console.log('e', channelName);
    // this.setState({ currentChannelValue: value });
    const { sliders } = this.state;
    sliders[channelName].value = value;
    this.setState({ sliders });
  }

  handleOnDragStop() {
    const { sliders } = this.state;
    const { input } = this.props;
    const slidersCopy = { ...sliders };
    input.onChange(slidersCopy);
    this.setState({ sliders: slidersCopy });
  }

  createSVGComponent(Icon, iconStyle) {
    if (!Icon) {
      return null;
    }

    if (isFunction(Icon)) {
      return <Icon style={iconStyle} />;
    } else if (isString(Icon)) {
      return <img src={Icon} style={iconStyle} alt="" />;
    } else {
      return null;
    }
  }

  renderCards() {
    const { enums, input } = this.props;
    const { sliders } = this.state;
    // console.log('sliders in renderCards', sliders);

    return (
      <Grid container>
        {enums.map((channel) => {
          if (sliders[channel.value]) {
            return (
              <CardWithIcon
                key={`${input.name}-${channel.value}`}
                md={3}
                label={channel.label}
                checked={sliders[channel.value].selected}
                onClick={() => {
                  this.handleOnClick(channel);
                }}
                svgIcon={channel.svgIcon}
              />
            );
          } else {
            return null;
          }
        })}
      </Grid>
    );
  }

  renderSliders() {
    // console.log('sliders props', this.props);
    const { sliders } = this.state;
    const { enums, minSliderValue, maxSliderValue, sliderStep } = this.props;

    // console.log('sliders values in renderSliders', Object.values(sliders));
    return enums.map(({ value, svgIcon }) => {
      if (sliders[value]) {
        const currentChannel = sliders[value];
        if (currentChannel.selected) {
          return (
            <Grid
              container
              justifyContent="center"
              style={{ textAlign: 'center', userSelect: 'none' }}
              key={`slider-for-${value}`}
            >
              <Grid item xs={1}>
                {this.createSVGComponent(svgIcon)}
              </Grid>
              <Grid item xs={10}>
                <Slider
                  min={minSliderValue}
                  max={maxSliderValue}
                  step={sliderStep}
                  onChange={(e, sliderValue) => {
                    this.handleOnSliderChange(e, sliderValue, value);
                  }}
                  onDragStop={this.handleOnDragStop}
                  value={currentChannel.value}
                  sliderStyle={{ marginBottom: 12, marginTop: 12 }}
                />
              </Grid>
              <Grid item xs={1}>
                {/* {currentChannelValue} */}
                {currentChannel.value}
              </Grid>
            </Grid>
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  }

  render() {
    // console.log('radio cards props', this.props);
    // console.log('radio cards state', this.state);
    return (
      <div>
        {this.renderCards()}
        {this.renderSliders()}
      </div>
    );
  }
}

RadioCardsWithSlider.propTypes = {
  enums: PropTypes.arrayOf(PropTypes.object).isRequired,
  input: PropTypes.object,
  minSliderValue: PropTypes.number,
  maxSliderValue: PropTypes.number,
  sliderStep: PropTypes.number,
};

RadioCardsWithSlider.defaultProps = {
  input: {
    value: {},
    onChange: () => {},
  },
  // iconStyle:      {
  //   width:    '6vh',
  //   minWidth: 40,
  // height: 40,
  // padding: 15,
  // },
  minSliderValue: 1,
  maxSliderValue: 10,
  sliderStep: 1,
};
