import React, { FC } from 'react';
import { change as reduxFormChange } from 'redux-form';
import { FormAction } from 'redux-form/lib/actions';
import { connect } from 'react-redux';

import useMatchingSimulatorData from './useMatchingSimulatorData';
import ProjectMatchingSimulatorWizard from '../../containers/project/wizards/ProjectMatchingSimulatorWizard';
import MatchingWeightsManagementPanel from '../../containers/project/wizards/ProjectMatchingSimulatorWizard/components/MatchingWeightsManagementPanel';
import { wizardFormName } from '../../containers/project/wizards/ProjectMatchingSimulatorWizard/consts';

import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';
import PageHeader from '../../../fe_common/client/components/molecules/PageHeader';

import useStyles from './style';

interface MatchingSimulatorPageProps {
  change: (form: string, field: string, value: any, touch?: boolean, persistentSubmitErrors?: boolean) => FormAction;
}

const MatchingSimulatorPage: FC<MatchingSimulatorPageProps> = (props) => {
  const classes = useStyles();
  const { change } = props;
  const { initialized, initialValues } = useMatchingSimulatorData();

  if (!initialized) {
    return <PageLoadingPlaceholder />;
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title="Matching Simulator"
        subTitle="Enter project data to simulate marketer matches after first being created"
      >
        <div className={classes.pageActions}>
          <MatchingWeightsManagementPanel
            onLoad={(newWeights) => {
              Object.entries(newWeights).forEach(([field, value]) => {
                change(wizardFormName, field, value);
              });
            }}
          />
        </div>
      </PageHeader>
      <ProjectMatchingSimulatorWizard initialValues={initialValues} />
    </div>
  );
};

const mapDispatchToProps = {
  change: reduxFormChange,
};

export default connect(null, mapDispatchToProps)(MatchingSimulatorPage);
