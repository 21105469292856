import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withMutation, LoanFundsToCompanyMutation } from 'growl-graphql';

import { WizardSteps } from './steps';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { RefreshSpinner } from '../../../../../fe_common/client/components/atoms/RefreshSpinner';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

class LoanFundsToCompanyWizardDisconnected extends React.Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  async handleFormSubmit(formValuesSubmitted, dispatch, props) {
    const { loanFundsToCompanyMutation, companyId, onSuccess } = this.props;

    const { amount, description } = formValuesSubmitted;
    const queryVariables = {
      debtOperation: {
        currency: 'USD',
        amount,
        description,
      },
      companyId,
    };

    try {
      // Allow throwing of errors out of the mutate
      await loanFundsToCompanyMutation.mutate(queryVariables, true);
      props.destroy();
      onSuccess();
    } catch (err) {
      alert(err);
    }
  }

  render() {
    const { loading: loadingMutation } = this.props.loanFundsToCompanyMutation;
    const { companyId } = this.props;

    const initialValues = {
      enableLoanFundsToCompany: false,
    };

    if (loadingMutation) {
      return <RefreshSpinner />;
    }

    if (!companyId) {
      return <CalloutMessage message="Something went wrong. No payment company ID found" type="error" />;
    }

    const wizardFormName = `LoanFundsToCompanyWizard-${companyId}`;

    return (
      <Grid container>
        <Grid item xs={12}>
          <WizardForm
            form={wizardFormName}
            watchAllValues
            initialValues={initialValues}
            steps={WizardSteps}
            onSubmit={this.handleFormSubmit}
          />
        </Grid>
      </Grid>
    );
  }
}

LoanFundsToCompanyWizardDisconnected.propTypes = {
  loanFundsToCompanyMutation: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};

LoanFundsToCompanyWizardDisconnected.defaultProps = {
  onSuccess: () => {},
};

const enhance = compose(withMutation(LoanFundsToCompanyMutation));

export const LoanFundsToCompanyWizard = enhance(LoanFundsToCompanyWizardDisconnected);
