import React from 'react';
import { GlobalEntitySearchResult } from '@mayple/types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { GlobalEntitySearchQuery } from 'growl-graphql/dist/queries/GlobalEntitySearchQuery';

// import * as sampleData from './searchResults.json';
import { sortByEntityType } from './logic';
import SearchResult from './SearchResult';
import LoadingPlaceholder from '../../../fe_common/client/components/atoms/LoadingPlaceholder';
import QueryErrorMessage from '../../../fe_common/client/components/common/QueryErrorMessage';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '2rem',
      marginBottom: '3rem',
    },
  }),
);

export type SearchResultsPageParams = {
  searchTerm: string;
};

const SearchResultsPage: React.FC = () => {
  const classes = useStyles();
  const { searchTerm } = useParams<SearchResultsPageParams>();

  const variables = {
    valueToSearch: searchTerm,
  };

  const { loading, data, error } = useQuery(GlobalEntitySearchQuery.query, { variables, skip: !searchTerm });

  const results = (data?.globalEntitySearch as GlobalEntitySearchResult[]) || ([] as GlobalEntitySearchResult[]);
  const resultsLength = results?.length || 0;
  const hasResults = resultsLength > 0;

  return (
    <div className={classes.root}>
      {loading && <LoadingPlaceholder />}
      {error && <QueryErrorMessage error={error} message="Error getting search results..." />}
      {!hasResults && (
        <CalloutMessage title="No results found." message="Try and search for a different term." type="info" margin />
      )}
      {hasResults && (
        <CalloutMessage message={`${resultsLength} results were found for ${searchTerm}`} type="info" margin />
      )}
      {hasResults &&
        results.sort(sortByEntityType).map((result) => {
          const { entityUuid } = result;

          return (
            <div key={`search-result-${entityUuid}`}>
              <SearchResult searchResult={result} searchTerm={searchTerm} />
            </div>
          );
        })}
    </div>
  );
};

export default SearchResultsPage;
