import React from 'react';

import { AssessmentFormProps } from './types';
import AssessmentForm from './components/AssessmentForm';
import MailchimpAuditAssessmentForm from './components/MailchimpAuditAssessmentForm';

const getFormComponentBySubTaskType = (
  typeName:
    | 'PostProjectMatchOfferMarketerAssessment'
    | 'MonthlyMarketerAssessmentByGrowthStrategist'
    | 'MonthlyMarketerAssessmentByCompany'
    | 'MailchimpAuditAssessmentByCompany'
    | 'OutbrainAuditAssessmentByCompany'
    | undefined,
): React.FC<AssessmentFormProps> => {
  switch (typeName) {
    case 'MailchimpAuditAssessmentByCompany':
      return MailchimpAuditAssessmentForm;
    default:
      return AssessmentForm;
  }
};

export default getFormComponentBySubTaskType;
