import { UserType } from '@mayple/types';
import { EnumKeysAsStrings, LabelValueProps } from '../../../fe_common/client/app/types';
import { pairsToLabelLookup } from '../../../fe_common/client/app/enums';

export const USER_TYPE_LABELS: LabelValueProps[] = [
  { value: UserType.NORMAL_USER, label: 'Normal User' },
  { value: UserType.SUPPORT_USER, label: 'Support User' },
  { value: UserType.ADMIN_USER, label: 'Admin User' },
];

export type UserTypeKeys = EnumKeysAsStrings<typeof UserType>;
export type UserTypeLabelsLookup = {
  [key in UserTypeKeys]: string;
};

export const USER_TYPE_LABELS_LOOKUP = pairsToLabelLookup(USER_TYPE_LABELS) as UserTypeLabelsLookup;
