import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Step } from 'react-albus';
import Grid from '@material-ui/core/Grid';

import { getSliderMarks, MAX_SLIDER_VALUE, SLIDER_STEP_VALUE } from './consts';

import {
  INDUSTRIES_PAIRS,
  LANGUAGES_PAIRS,
  MARKETING_SKILLS_PAIRS_WITH_ICONS,
  TARGET_KPI_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  TARGETED_COUNTRIES_PAIRS,
  REMOTE_WORK_PAIRS,
  COUNTRIES_PAIRS,
  PROJECT_SPECIAL_TYPE,
} from '../../../../../fe_common/client/app/enums';
import { Transitions } from '../../../../../fe_common/client/components/molecules/Transitions';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { WizardNavigationButtons } from '../../../../../fe_common/client/components/molecules/WizardNavigationButtons';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

const WizardSteps = (props) => {
  const { useDynamicWeights, setUseDynamicWeights, invalid, submit, submitting } = props;

  const sliderMarks = getSliderMarks(MAX_SLIDER_VALUE, SLIDER_STEP_VALUE);

  return (
    <Steps>
      <Step
        id="projectSimulatorStepOne"
        render={(step) => (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Marketing skills"
                  name="skills"
                  enums={MARKETING_SKILLS_PAIRS_WITH_ICONS}
                  type={WizardStepField.types.AUTOCOMPLETE}
                  isMulti
                  required
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Marketing skills weight:"
                      name="marketingServiceExpertiseWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Business model"
                  enums={PRODUCT_BUSINESS_MODEL_PAIRS}
                  type={WizardStepField.types.AUTOCOMPLETE}
                  name="productBusinessModel"
                  required={false}
                  isMulti
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Main industry"
                  enums={INDUSTRIES_PAIRS}
                  required
                  type={WizardStepField.types.AUTOCOMPLETE}
                  name="industryExpertise"
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Industry category weight:"
                      name="industryCategoryWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Industry sub category weight:"
                      name="industrySubCategoryWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Target KPI"
                  enums={TARGET_KPI_PAIRS}
                  type={WizardStepField.types.SELECT}
                  name="targetKPI"
                  required
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Target KPI weight:"
                      name="campaignTypeWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Company's country"
                  name="testCompanyCountry"
                  type={WizardStepField.types.AUTOCOMPLETE}
                  enums={COUNTRIES_PAIRS}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Target locations"
                  name="locations"
                  floatingLabelText="Select Countries"
                  type={WizardStepField.types.AUTOCOMPLETE}
                  enums={TARGETED_COUNTRIES_PAIRS}
                  required
                  isMulti
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Target location weight:"
                      name="targetLocationWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Marketer location"
                  type={WizardStepField.types.SELECT}
                  enums={REMOTE_WORK_PAIRS}
                  name="preferLocalMarketers"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Target languages"
                  name="languages"
                  enums={LANGUAGES_PAIRS}
                  type={WizardStepField.types.AUTOCOMPLETE}
                  isMulti
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Target language weight:"
                      name="languageWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Monthly media budget"
                  type={WizardStepField.types.NUMBER}
                  name="estimatedMediaBudget"
                  required
                  minimumValue={0}
                  maximumValue={1000000}
                  hintText="$500"
                  precision={0}
                />
                {useDynamicWeights && (
                  <Transitions duration={400}>
                    <WizardStepField
                      label="Media budget weight:"
                      name="mediaBudgetWeight"
                      type={WizardStepField.types.SLIDER}
                      maxSliderValue={MAX_SLIDER_VALUE}
                      marks={sliderMarks}
                    />
                  </Transitions>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <WizardStepField
                  label="Project Special Type"
                  type={WizardStepField.types.SELECT}
                  name="requestedProjectSpecialType"
                  enums={PROJECT_SPECIAL_TYPE}
                  required
                />
              </Grid>

              {useDynamicWeights && (
                <>
                  <Grid item xs={12} style={{ margin: 25 }}>
                    <TextDisplay variant="h6" bold>
                      Additional weights:
                    </TextDisplay>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Transitions duration={400}>
                      <WizardStepField
                        label="Success stories industry weight:"
                        name="successStoriesIndustryWeight"
                        type={WizardStepField.types.SLIDER}
                        maxSliderValue={MAX_SLIDER_VALUE}
                        marks={sliderMarks}
                      />
                    </Transitions>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Transitions duration={400}>
                      <WizardStepField
                        label="Success stories sub-industry weight:"
                        name="successStoriesSubIndustryWeight"
                        type={WizardStepField.types.SLIDER}
                        maxSliderValue={MAX_SLIDER_VALUE}
                        marks={sliderMarks}
                      />
                    </Transitions>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Transitions duration={400}>
                      <WizardStepField
                        label="Location affinity weight:"
                        name="locationAffinityWeight"
                        type={WizardStepField.types.SLIDER}
                        maxSliderValue={MAX_SLIDER_VALUE}
                        marks={sliderMarks}
                      />
                    </Transitions>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Transitions duration={400}>
                      <WizardStepField
                        label="Historical performance weight:"
                        name="historicalPerformanceWeight"
                        type={WizardStepField.types.SLIDER}
                        maxSliderValue={MAX_SLIDER_VALUE}
                        marks={sliderMarks}
                      />
                    </Transitions>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Transitions duration={400}>
                      <WizardStepField
                        label="Level of service weight:"
                        name="levelOfServiceWeight"
                        type={WizardStepField.types.SLIDER}
                        maxSliderValue={MAX_SLIDER_VALUE}
                        marks={sliderMarks}
                      />
                    </Transitions>
                  </Grid>
                </>
              )}
              <Grid container item xs={12} justifyContent="space-around">
                <Grid item>
                  <WizardNavigationButtons
                    step={step}
                    submit={submit}
                    invalid={invalid}
                    submitting={submitting}
                    disableNext={invalid}
                    finishButtonLabel="Get matches"
                  />
                </Grid>
                <Grid item>
                  <WizardNavigationButtons
                    step={step}
                    submit={() => {
                      setUseDynamicWeights(!useDynamicWeights);
                    }}
                    invalid={false}
                    submitting={false}
                    finishButtonLabel={`${useDynamicWeights ? 'Hide' : 'Show'} dynamic weights`}
                  />
                </Grid>
                <Grid item>
                  <WizardNavigationButtons
                    step={step}
                    invalid={false}
                    submitting={false}
                    submit={() => {
                      props.reset();
                    }}
                    finishButtonLabel="Reset"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      />
    </Steps>
  );
};

WizardSteps.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  userInput: PropTypes.any,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  useDynamicWeights: PropTypes.bool.isRequired,
  setUseDynamicWeights: PropTypes.func.isRequired,
};

WizardSteps.defaultProps = {
  userInput: {},
};

export default WizardSteps;
