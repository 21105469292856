import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FeedPost from '../FeedPost';
import { TextDisplay } from '../../display';
import { getFilteredPosts, sortByDateKey } from '../logic';
import { colors } from '../../../app/theme';
import { getFilterCount } from '../FeedFilter/logic';
import FeedPostDemo from '../FeedPostDemo';

function FeedPosts({ feed, legend, filter, onCreatePostComment }) {
  const posts = useMemo(() => feed?.posts || [], [feed]);
  const viewerPersonUuid = feed?.viewerPersonUuid || null;
  const filterCount = getFilterCount(filter);
  const filteredSortedPosts = useMemo(() => sortByDateKey(getFilteredPosts(posts, filter)), [posts, filter]);

  const refineFilterMessage = 'Please refine your filter to get results';

  return (
    <>
      {filteredSortedPosts.map((post) => (
        <FeedPost
          viewerPersonUuid={viewerPersonUuid}
          legend={legend}
          post={post}
          key={`${post?.published}-${post.authorUuid}`}
          onCreatePostComment={onCreatePostComment}
          enableComments
        />
      ))}
      {filteredSortedPosts.length === 0 && filterCount === 0 && <FeedPostDemo legend={legend} />}
      {filteredSortedPosts.length === 0 && filterCount > 0 && (
        <TextDisplay variant="h5" color={colors.black_light}>
          {refineFilterMessage}
        </TextDisplay>
      )}
    </>
  );
}

FeedPosts.propTypes = {
  legend: PropTypes.object.isRequired,
  feed: PropTypes.object,
  filter: PropTypes.object,
  onCreatePostComment: PropTypes.func.isRequired,
};

FeedPosts.defaultProps = {
  feed: {},
  filter: {},
};
export default FeedPosts;
