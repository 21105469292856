import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketerVettingProfile from '../components/MarketerVettingProfile';
import DefaultIntegrations from '../components/DefaultIntegrations';
import IntegrationsDetails from '../../../components/cpanel/IntegrationsDetails';

import Section from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';

const styles = () => ({
  root: {},
});

const MarketerIntegrationsTab = (props) => {
  const { marketer, classes } = props;

  return (
    <div className={classes.root}>
      <Section title="Default integrations" noBorder noTopMargin>
        <SectionContent>
          <DefaultIntegrations marketer={marketer} />
        </SectionContent>
      </Section>

      <Section title="Integrations">
        <IntegrationsDetails integrations={marketer.integrations} />
      </Section>

      <Section title="Marketer vetting profile" subTitle="Get vetting access to marketer Facebook business managers">
        <SectionContent>
          <MarketerVettingProfile marketer={marketer} />
        </SectionContent>
      </Section>
    </div>
  );
};

MarketerIntegrationsTab.propTypes = {
  marketer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketerIntegrationsTab);
