import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { validateRequired } from '../../../../services/validation';
import RichTextEditor from '../../../common/RichTextEditor';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardRichTextField(props) {
  // console.log('WizardRichTextField props', props);
  const { name, isRequired, ...rest } = props;
  const validator = isRequired ? [validateRequired] : [];

  return <Field name={name} component={RichTextEditor} validate={validator} {...rest} />;
}

WizardRichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

WizardRichTextField.defaultProps = {
  isRequired: false,
};
