/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = (props) => (
  <div className="container">
    <h1>Error, Page not found !</h1>
    <h5>url - {props.location.pathname} - does not exist !</h5>
    <h5>
      <Link to="/">Go back to homepage</Link>
    </h5>
  </div>
);

export default Page404;
