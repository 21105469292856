export function shouldShowUserMarketer(
  connectedToMarketer: boolean,
  connectedToCompany: boolean,
  connectedToInternalTeam: boolean,
): boolean {
  return (
    (connectedToMarketer && !connectedToCompany && !connectedToInternalTeam) ||
    (!connectedToMarketer && !connectedToCompany && !connectedToInternalTeam)
  );
}

export function shouldShowUserCompany(
  connectedToCompany: boolean,
  connectedToMarketer: boolean,
  connectedToInternalTeam: boolean,
): boolean {
  return (
    (connectedToCompany && !connectedToMarketer && !connectedToInternalTeam) ||
    (!connectedToMarketer && !connectedToCompany && !connectedToInternalTeam)
  );
}

export function shouldShowUserInternalTeam(
  connectedToInternalTeam: boolean,
  connectedToMarketer: boolean,
  connectedToCompany: boolean,
): boolean {
  return (
    (connectedToInternalTeam && !connectedToMarketer && !connectedToCompany) ||
    (!connectedToInternalTeam && !connectedToMarketer && !connectedToCompany)
  );
}
