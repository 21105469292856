import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    propertyLabel: {
      flexBasis: 'auto',
    },
    propertyValue: {
      fontWeight: 400,
    },
  }),
);

export default useStyles;
