import React, { useCallback } from 'react';

import { NotesEditorProps } from '../types';
import useEntityNoteData from '../hooks/useEntityNoteData';
import useEntityNoteMutation from '../hooks/useEntityNoteMutation';

import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';

import useStyles from './style';

const NotesEditor: React.FC<NotesEditorProps> = (props) => {
  const classes = useStyles();
  const { entityId, entityType, formName, onSuccessfulSave } = props;

  const { entityName, note, loading } = useEntityNoteData(entityId, entityType);
  const { mutate, error, variables, dataKey } = useEntityNoteMutation(entityId, entityType);

  const wizardFormName = formName || `${entityType}-note-editor`;

  const handleOnSubmit = useCallback(
    async (formValues: any) => {
      if (variables && dataKey && variables[dataKey]) {
        variables[dataKey].note.contents = formValues?.note;
      }

      const result = await mutate({ variables });

      if (error) {
        return;
      }

      if (result) {
        onSuccessfulSave?.();
      }
    },
    [dataKey, error, mutate, onSuccessfulSave, variables],
  );

  if (loading) {
    return null;
  }

  const initialValues = {
    note: note?.contents,
  };

  // @ts-ignore
  return (
    <div className={classes.root}>
      <CalloutMessage message="Notes can be used in opportunities sent to experts." type="warning" margin />
      <TextDisplay variant="h5" gutterBottom>
        {entityType} {entityName} ({entityId})
      </TextDisplay>

      {!loading && (
        <WizardForm
          form={wizardFormName}
          // @ts-ignore
          // eslint-disable-next-line react/no-unstable-nested-components
          steps={(): FC => (
            <WizardStepField
              type={WizardStepField.types.RICH_TEXT}
              name="note"
              // @ts-ignore
              classes={{ richTextRoot: classes.richTextRoot }}
              placeholder={`Write down your notes for this ${entityType} - ${entityName}`}
              required
            />
          )}
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          error={error}
          destroyOnUnmount
        />
      )}
    </div>
  );
};

export default NotesEditor;
