import React, { FC } from 'react';

import CompaniesTable from './CompaniesTable';
import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';

import useStyles from './style';

const CompaniesPage: FC = () => {
  const classes = useStyles();
  return (
    <Section title="Companies" noBorder classes={{ root: classes.sectionRoot }}>
      <SectionContent>
        <CompaniesTable />
      </SectionContent>
    </Section>
  );
};

export default CompaniesPage;
