import React from 'react';
import Link from '@material-ui/core/Link';
import {
  dateTimeFormatter,
  yesNoFormatter,
} from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';

const PROPS_GROUPS = {
  DATES: 'Lifecycle',
  GENERAL: 'Team and accounts',
};

const dateTimePropertyFormatter = (value, defaultValue) => dateTimeFormatter(value) || defaultValue;

const externalLinkFormatter = (value, defaultValue) => {
  if (!value && !defaultValue) {
    return null;
  }

  return (
    <Link href={value || defaultValue} rel="noopener noreferrer" target="_blank">
      {value || defaultValue}
    </Link>
  );
};

export const getProjectProperties = (classes) => {
  const yesNoToRedGreen = (value) => (value ? classes.green : classes.red);

  return [
    {
      label: 'Creation Date',
      path: 'created',
      formatter: dateTimePropertyFormatter,
      defaultValue: 'N/A',
      group: PROPS_GROUPS.DATES,
    },
    {
      label: 'First payment date',
      path: 'fundedAt',
      formatter: dateTimePropertyFormatter,
      defaultValue: 'N/A',
      group: PROPS_GROUPS.DATES,
    },
    {
      label: 'Delayed launch date',
      path: 'launchDate',
      formatter: dateTimePropertyFormatter,
      defaultValue: 'N/A',
      group: PROPS_GROUPS.DATES,
    },
    {
      label: 'Start Date',
      path: 'startedAt',
      formatter: dateTimePropertyFormatter,
      defaultValue: 'N/A',
      group: PROPS_GROUPS.DATES,
    },
    {
      label: 'Finish Date',
      path: 'finishedAt',
      formatter: dateTimePropertyFormatter,
      defaultValue: 'N/A',
      group: PROPS_GROUPS.DATES,
    },
    {
      label: 'Main Growth Strategist',
      path: 'mainGrowthStrategist',
      formatter: (value) => (value != null ? `${value?.name} (${value?.id})` : 'N/A'),
      group: PROPS_GROUPS.GENERAL,
    },
    {
      label: 'View Package Details in App',
      path: 'linkToProject',
      formatter: externalLinkFormatter,
      group: PROPS_GROUPS.GENERAL,
    },
    {
      label: 'Connected FB History accounts',
      path: 'connectedFBHistory',
      formatter: yesNoFormatter,
      customClass: yesNoToRedGreen,
      group: PROPS_GROUPS.GENERAL,
    },
    {
      label: 'Connected Google Ads History accounts',
      path: 'connectedGAHistory',
      formatter: yesNoFormatter,
      customClass: yesNoToRedGreen,
      group: PROPS_GROUPS.GENERAL,
    },
  ];
};
