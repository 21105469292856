import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AssessmentWizardFormContent, AssessmentThanksMessageProps } from '../../types';

import { svgs } from '../../../../../app/svg';
import { TRANSLATION_NS } from '../../../../../app/consts';
import { TextDisplay } from '../../../../display';
import { Button } from '../../../../inputs';
import EntityAvatar from '../../../../atoms/EntityAvatar';

import useStyles from './style';

const AssessmentThanksMessage: React.FC<AssessmentThanksMessageProps> = (props) => {
  const classes = useStyles();
  const { onClick, setDialogContent, currentStep, totalSteps } = props;

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'AssessmentDialog.AssessmentThanksMessage',
  });

  useEffect(() => {
    if (currentStep === totalSteps) {
      setDialogContent(AssessmentWizardFormContent.SHOW_THANKS_MESSAGE);
    }
  }, [currentStep, setDialogContent, totalSteps]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <EntityAvatar
          classes={{
            root: classes.entityAvatarRoot,
            avatar: classes.entityAvatarAvatar,
            primary: classes.entityAvatarPrimary,
          }}
          size="xl"
          avatarImageUrl={svgs.mayple_logo}
        />
        <TextDisplay variant="h5" className={classes.headline}>
          {t('headline', 'Thank you!')}
        </TextDisplay>
        <TextDisplay variant="body2" className={classes.subHeadline}>
          {t('subHeadline')}
        </TextDisplay>
      </div>
      <div className={classes.formActions}>
        <Button
          label={t('doneAction')}
          onClick={onClick}
          color="primary"
          variant="contained"
          className={classes.wideButton}
        />
      </div>
    </div>
  );
};

export default AssessmentThanksMessage;
