import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useMarketersPayoutReviewStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      paddingTop: 32,
      marginTop: 32,
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const useTableStyles = makeStyles(() =>
  createStyles({
    root: {},
    exportToCSVButton: {
      marginRight: 16,
    },
    tableContainer: {
      width: '100%',
      overflowX: 'auto',
    },
  }),
);

export const useRowStyles = makeStyles(() =>
  createStyles({
    root: {},
    tableCellRoot: {
      padding: '4px 24px 4px 24px',
    },
    fullRowCheckBoxCell: {
      verticalAlign: 'middle',
    },
    fullRowCell: {
      verticalAlign: 'middle',
      paddingTop: 12,
    },
    alignRight: {
      textAlign: 'right',
    },
    bold: {
      fontWeight: 'bold',
    },
  }),
);

export const useTableCellStyles = makeStyles(() =>
  createStyles({
    tableCellRoot: {
      padding: '4px 24px 4px 24px',
    },
    alignRight: {
      textAlign: 'right',
    },
  }),
);

export const useToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    actions: {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
    },
    title: {},
  }),
);
