import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AdditionalPackageProfile, AdditionalServicePackagesProfile } from '@mayple/types';
import { NonPPCPackagesQuery } from 'growl-graphql/dist/queries/admin/NonPPCPackagesQuery';
import { ApolloError, ApolloQueryResult, OperationVariables } from 'apollo-client';

interface ReturnType {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: OperationVariables | undefined) => Promise<ApolloQueryResult<any>>;
  packages: AdditionalServicePackagesProfile[];
}

const filterPackageByPackageNameContainsDeprecated = (currentPackage: AdditionalPackageProfile | null) =>
  !currentPackage?.name?.includes('deprecated');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterPackageByDeprecatedProperty = (currentPackage: AdditionalPackageProfile | null) =>
  !currentPackage?.deprecated;

const useNonPPCPackagesData = (definedPackagesOnly = false): ReturnType => {
  const { data, loading, error, refetch } = useQuery(NonPPCPackagesQuery.query);
  const packages: AdditionalServicePackagesProfile[] = useMemo(() => {
    const p: AdditionalServicePackagesProfile[] = data?.nonPPCPackages || [];

    let filteredPackages = p.filter(({ serviceType }) => Boolean(serviceType));

    if (definedPackagesOnly) {
      filteredPackages = filteredPackages.filter(
        ({ packages: packagesProfiles }) => (packagesProfiles || []).length > 0
      );
    }

    // Current sputter convention is we add the keyword 'deprecated' to the name, but this is not 100% safe
    filteredPackages = filteredPackages.map((currentPackageService) => {
      const nonDeprecatedPackages = (currentPackageService.packages || [])
        .filter(filterPackageByPackageNameContainsDeprecated)
        .filter(filterPackageByDeprecatedProperty);

      return {
        ...currentPackageService,
        packages: nonDeprecatedPackages,
      };
    });

    return filteredPackages;
  }, [data?.nonPPCPackages, definedPackagesOnly]);

  return {
    packages,
    loading,
    error,
    refetch,
  };
};

export default useNonPPCPackagesData;
