import makeStyles from '@material-ui/core/styles/makeStyles';

import { colorPalette, colors } from '../../../../../fe_common/client/app/theme';

export default makeStyles(() => ({
  green: {
    color: colorPalette.green[60],
  },
  yellow: {
    color: colorPalette.yellow[60],
  },
  red: {
    color: colorPalette.error[60],
  },
  nameField: {
    marginBottom: 16,
  },
  root: {
    width: '100%',
  },
  link: {
    color: colors.blue,
  },
  divider: {
    margin: 0,
  },
}));
