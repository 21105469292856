import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import ScoreButtons, { ScoreButtonsProps } from '../../ScoreButtons';

type ScoreButtonsInputProps = WrappedFieldProps & ScoreButtonsProps;

const ScoreButtonsInput: React.FC<ScoreButtonsInputProps> = (props) => {
  const { input, meta, name, helperText, value, ...rest } = props;
  const { error, touched, invalid } = meta;

  const hasError = touched && invalid;
  const errorMessage = hasError && error ? error : undefined;

  return (
    <ScoreButtons
      name={input?.name || name}
      value={input?.value}
      onChange={input?.onChange}
      error={hasError}
      helperText={errorMessage || helperText}
      {...rest}
    />
  );
};

export default ScoreButtonsInput;
