/* eslint-disable camelcase */

/* Cronofy Types */

// Subtypes
import { CronofyBuffer } from '@mayple/types';

type Hours =
  | '00'
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23';

type Minutes = '00' | '05' | '10' | '15' | '20' | '25' | '30' | '35' | '40' | '45' | '50' | '55';

export type ValidTime = `${Hours}:${Minutes}`;

export enum WeekDays {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export type Day =
  | WeekDays.sunday
  | WeekDays.monday
  | WeekDays.tuesday
  | WeekDays.wednesday
  | WeekDays.thursday
  | WeekDays.friday
  | WeekDays.saturday;

export enum CallbackEventTypes {
  availabilityRuleSaved = 'availability_rule_saved',
  availabilityRuleEdited = 'availability_rule_edited',
  availabilityRuleNotFound = 'availability_rule_not_found',
  slotSelected = 'slot_selected',
}

export type CallbackEventType =
  | CallbackEventTypes.availabilityRuleEdited
  | CallbackEventTypes.availabilityRuleNotFound
  | CallbackEventTypes.availabilityRuleSaved
  | CallbackEventTypes.slotSelected;

export type AvailabilityRulesHandler = (callbackObject?: AvailabilityRuleCallbackObject) => void;

export type CronofyMeetingDuration = 15 | 30 | 60;

export interface WeeklyPeriodDay {
  day: Day;
  startTime: ValidTime;
  endTime: ValidTime;
}

export interface AvailabilityRule {
  availability_rule_id: string;
  tzid: string;
  weekly_periods?: WeeklyPeriodDay[];
  calendar_ids: string[];
}

type Locale = 'de' | 'en' | 'es' | 'fr' | 'fr-CA' | 'it' | 'ja' | 'nl' | 'ru' | 'sv';
type LogLevel = 'info' | 'warn' | 'error' | 'none';

interface CallbackNotification {
  type: CallbackEventType;
  message?: string;
}

export interface AvailabilityViewerCallbackNotification extends CallbackNotification {
  slot: {
    available: boolean;
    start: string;
    end: string;
    participants: CronofyMember[];
  };
}

export interface CallbackObject {
  notification: CallbackNotification | AvailabilityViewerCallbackNotification;
}

export interface AvailabilityRuleCallbackObject extends CallbackObject {
  availability_rule?: AvailabilityRule;
}

export interface AvailabilityViewerCallbackObject extends CallbackObject {
  notification: AvailabilityViewerCallbackNotification;
}

export type KeyboardSupport = 'basic' | undefined;

export interface CronofyMember {
  sub: string;
  managed_availability?: boolean;
  only_managed_events?: boolean;
  ignore_tentative?: boolean;
  availability_rule_ids?: string[] | null;
  calendar_ids?: string[] | null;
}

export type CronofyParticipantRequired = 'all' | number;

export interface CronofyParticipant {
  required: CronofyParticipantRequired;
  members: CronofyMember[];
}

export interface CronofyDuration {
  minutes: number;
}

export interface CronofyQueryPeriod {
  start: string;
  end: string;
}

export interface CronofyAvailabilityQuery {
  response_format?: 'periods' | 'slots' | 'overlapping_slots';
  participants: CronofyParticipant[];
  query_periods: CronofyQueryPeriod[];
  required_duration?: CronofyDuration;
  start_interval?: CronofyDuration;
  buffer?: CronofyBuffer | null;
}

interface CronofyAvailabilityRulesOptionsConfig {
  start_time?: ValidTime;
  end_time?: ValidTime;
  duration?: CronofyMeetingDuration;
  logs?: LogLevel;
  week_start_day?: WeekDays;
  default_weekly_periods?: WeeklyPeriodDay[];
  auto_create_rules?: boolean;
  keyboard_support?: KeyboardSupport;
}

export interface CronofyAvailabilityOptionsStyles {
  colors?: {
    hairline?: string;
    primary?: string;
    available?: string;
    availableHover?: string;
    availableActive?: string;
    unavailable?: string;
    unavailableHover?: string;
    unavailableActive?: string;
  };
  prefix?: string;
}

export interface CronofyAvailabilityRulesOptions {
  element_token: string;
  target_id: string;
  availability_rule_id?: string;
  tzid?: string;
  data_center?: string;
  config?: CronofyAvailabilityRulesOptionsConfig;
  locale?: Locale;
  callback?: (callbackObject: AvailabilityRuleCallbackObject) => void;
  translations?: Record<string, any>;
  demo?: boolean;
  styles?: CronofyAvailabilityOptionsStyles;
}

export enum CronofyAvailabilityViewerMode {
  confirm = 'confirm',
  no_confirm = 'no_confirm',
  multi_select = 'multi_select',
  free_select = 'free_select',
}

export enum CronofyAvailabilityViewerSlotSelection {
  available = 'available',
  unrestricted = 'unrestricted',
}

interface CronofyAvailabilityViewerOptionsConfig {
  start_time?: ValidTime;
  end_time?: ValidTime;
  interval?: CronofyMeetingDuration;
  mode?: CronofyAvailabilityViewerMode;
  max_selection_count?: number | boolean;
  bounds_control?: boolean;
  allow_expansion?: boolean;
  slot_selection?: CronofyAvailabilityViewerSlotSelection;
  logs?: LogLevel;
  week_start_day?: WeekDays;
}

export interface CronofyAvailabilityViewerOptions {
  element_token: string | undefined;
  target_id?: string | undefined;
  callback?: (callbackObject: AvailabilityViewerCallbackObject) => void;
  availability_query: CronofyAvailabilityQuery;
  config?: CronofyAvailabilityViewerOptionsConfig;
  data_center?: string;
  locale?: Locale;
  translations?: Record<string, any>;
  demo?: boolean;
  styles?: CronofyAvailabilityOptionsStyles;
  tzid?: string;
}

export interface CronofyAvailabilityViewer {
  update: (options: CronofyAvailabilityViewerOptions) => void;
  refresh: () => void;
  navigation: {
    next: () => void;
    prev: () => void;
  };
}

export interface Slot {
  end: string;
  participants: CronofyMember[];
  start: string;
}
