import React from 'react';
import { FilterRender } from 'react-table-6';
import { ProjectStage } from '@mayple/types';

import { stageTitle } from '../../../logic/projectStage';

export const ProjectStageFilter: FilterRender = (props) => {
  const { filter, onChange } = props;

  return (
    <select
      onChange={(event) => {
        // @ts-ignore
        onChange(event.target.value);
      }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">All Stages</option>
      {Object.values(ProjectStage).map((v) => (
        <option key={v} value={v}>
          {stageTitle[v]}
        </option>
      ))}
    </select>
  );
};

export default ProjectStageFilter;
