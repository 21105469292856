import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import { getCycleDates } from '../ProjectCycleDetailsNew/logic';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

import { getClassByCyclePhase, projectCycleDetailsHeaderStyles } from './style';

function ProjectCycleDetailsHeader({ project, cycle, classes }) {
  const { company, companyId, id: projectId, name: projectName } = project;

  if (!cycle) {
    return <TextDisplay color="error">Error getting project cycle data</TextDisplay>;
  }

  const { id: cycleId, phase: cyclePhase } = cycle;

  const cycleDates = getCycleDates(cycle);
  const cycleHeadTitle = `Cycle: ${cycleDates} | (Cycle ID: ${cycleId}) - `;

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <TextDisplay variant="h4">Project Cycle Details Page</TextDisplay>
      </Grid>
      <Grid item xs={12}>
        <TextDisplay variant="h6" inline>
          {cycleHeadTitle}
        </TextDisplay>
        <TextDisplay variant="h6" inline className={getClassByCyclePhase(cyclePhase, classes)}>
          &nbsp;{cyclePhase}
        </TextDisplay>
      </Grid>
      <Grid item xs={12}>
        <TextDisplay variant="subtitle1">
          <Link to={`/projects/${projectId}`}>
            {projectName} (ID: {projectId})
          </Link>
        </TextDisplay>
      </Grid>
      <Grid item xs={12}>
        <TextDisplay variant="subtitle1" gutterBottom>
          Owning Company:{' '}
          <Link to={`/companies/${companyId}`}>
            {company.name} (ID: {companyId})
          </Link>
        </TextDisplay>
      </Grid>
    </Grid>
  );
}

ProjectCycleDetailsHeader.propTypes = {
  project: PropTypes.object.isRequired,
  cycle: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(projectCycleDetailsHeaderStyles)(ProjectCycleDetailsHeader);
