import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  withQuery,
  withMutation,
  ProjectMarketerMatchesQuery,
  CreateProjectMarketerMatchRelevanceReportMutation,
} from 'growl-graphql';

import MarketerListItem from './MarketerListItem';
import ShowByProjectStatus from './ShowByProjectStatus';
import { handleClientError } from '../../../../fe_common/client/services/logger';
import { setNotification } from '../../../../fe_common/client/services/notification';
import { MatchVotePanel } from '../../../components/cpanel/components/molecules/MatchVotePanel';
import ShareHistoryButton from '../../../components/cpanel/components/molecules/ShareHistoryButton';

import { RefreshSpinner } from '../../../../fe_common/client/components/atoms';
import Section from '../../../../fe_common/client/components/atoms/Section';
import { colors } from '../../../../fe_common/client/app/theme';
import { yesNoFormatter } from '../../../../fe_common/client/services/ExportToCSV/formatters';
import { getFromLocalStorage, setToLocalStorage, tryParseInt } from '../../../../fe_common/client/services/utils';
import { Button } from '../../../../fe_common/client/components/inputs';

const styles = {
  root: {},
  marketerListRoot: {
    listStyleType: 'decimal !important',
    listStylePosition: 'outside',
    marginLeft: 35,
  },
  marketerListItemRoot: {
    listStyleType: 'inherit !important',
    borderBottom: '1px solid',
    borderBottomColor: colors.blackOpacity(0.05),
  },
  marketerListItemSecondaryAction: {},
  showMoreButtonContainer: {
    width: '100%',
    textAlign: 'center',
    padding: '8px 0',
  },
};

class ProjectMatchesWrapped extends Component {
  constructor(props) {
    super(props);

    const projectId = tryParseInt(props.match?.params?.projectId, 0);
    const sessionVotes = getFromLocalStorage(`sessionVotes-project-${projectId}`) || {};

    this.state = {
      loadingData: true,
      projectId,
      project: null,
      sessionVotes,
      numOfMatchesToShow: 3,
    };
  }

  componentDidMount() {
    this.initData();
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { projectMarketerMatchesQuery } = this.props;
    const { projectMarketerMatchesQuery: prevProjectMarketerMatchesQuery } = prevProps;

    if (JSON.stringify(projectMarketerMatchesQuery) !== JSON.stringify(prevProjectMarketerMatchesQuery)) {
      this.initData();
    }
  }

  setNumOfMatchesToShow = (numOfMatchesToShow) => {
    this.setState({ numOfMatchesToShow });
  };

  increaseNumOfMatchesToShow = (increaseBy = 3) => {
    const { numOfMatchesToShow } = this.state;
    const newNumOfMatchesToShow = numOfMatchesToShow + increaseBy;
    this.setNumOfMatchesToShow(newNumOfMatchesToShow);
  };

  initData = () => {
    const { projectMarketerMatchesQuery } = this.props;
    const { loading, error, project } = projectMarketerMatchesQuery;

    if (error) {
      return;
    }

    if (loading || !project) {
      setTimeout(this.initData, 200);
      return;
    }

    this.setState({
      project,
      loadingData: false,
    });
  };

  handleOnVote = async (marketerId, projectId, reason, vote) => {
    try {
      const { sessionVotes } = this.state;

      const newSessionVotes = {
        ...sessionVotes,
        [marketerId]: vote,
      };
      const vars = {
        marketerId,
        projectId,
        reason,
        vote,
      };
      const { createProjectMarketerMatchRelevanceReportMutation } = this.props;

      const response = await createProjectMarketerMatchRelevanceReportMutation.mutate(vars, true);

      if (response) {
        setNotification(response.result, response.success ? 'success' : 'error');
      }

      // Save the votes in local storage. to prevent multiple voting after refresh.
      setToLocalStorage(`sessionVotes-project-${projectId}`, JSON.stringify(newSessionVotes));

      this.setState({ sessionVotes: newSessionVotes });
    } catch (e) {
      handleClientError(e);
      alert(e);
    }
  };

  renderProjectMatches = (project, projectMatches) => {
    const { projectId, numOfMatchesToShow } = this.state;
    const { createProjectMarketerMatchRelevanceReportMutation, classes } = this.props;

    const { projectLifeCycleStatus } = project;
    const { marketerMatches } = projectMatches;

    return (
      <div>
        <List classes={{ root: classes.marketerListRoot }}>
          {marketerMatches.slice(0, numOfMatchesToShow).map((match) => {
            const { marketerId, uuid } = match;

            return (
              <MarketerListItem
                key={uuid}
                marketer={match}
                marketerProperties={[
                  {
                    marketerProperty: 'marketerType',
                    propertyLabel: 'Type: ',
                  },
                  {
                    marketerProperty: 'availableForProjects',
                    propertyLabel: 'Available ? ',
                    propertyFormatter: yesNoFormatter,
                  },
                ]}
                // eslint-disable-next-line react/no-unstable-nested-components
                secondary={(() => {
                  if (this.state.sessionVotes[marketerId]) {
                    return <span>You voted: {this.state.sessionVotes[marketerId]}</span>;
                  }
                  return (
                    <ShowByProjectStatus status={projectLifeCycleStatus} showOnDiscovery>
                      <>
                        How was the match position? <br />
                        <MatchVotePanel
                          marketerId={marketerId}
                          projectId={projectId}
                          onVote={this.handleOnVote}
                          loading={createProjectMarketerMatchRelevanceReportMutation.loading}
                        />
                      </>
                    </ShowByProjectStatus>
                  );
                })()}
                customActionButton={
                  <ShareHistoryButton
                    withIcon
                    withTooltip
                    withLabel={false}
                    projectId={projectId}
                    marketerId={marketerId}
                  />
                }
                classes={{
                  root: classes.marketerListItemRoot,
                  container: classes.marketerListItemRoot,
                  secondaryActionRoot: classes.marketerListItemSecondaryAction,
                }}
              />
            );
          })}
        </List>
        {numOfMatchesToShow < 9 && (
          <div className={classes.showMoreButtonContainer}>
            <Button
              label="Show More..."
              color="primary"
              variant="outlined"
              onClick={() => {
                this.increaseNumOfMatchesToShow(3);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  // This is bad code, but it was necessary to be quick and dirty.
  render() {
    const { projectMarketerMatchesQuery, classes } = this.props;
    const { loading: loadingMatched } = projectMarketerMatchesQuery;
    const { loadingData, project } = this.state;
    const isLoading = loadingData || loadingMatched;

    const { project: projectMatches = {} } = projectMarketerMatchesQuery;

    return (
      <Section title="Matched Marketers" subTitle="Best match first" classes={{ root: classes.root }}>
        <Paper elevation={1} square>
          {isLoading && <RefreshSpinner />}
          {!isLoading && project && this.renderProjectMatches(project, projectMatches)}
          {!project && !isLoading && <div>Error getting project data</div>}
        </Paper>
      </Section>
    );
  }
}

ProjectMatchesWrapped.propTypes = {
  match: PropTypes.object.isRequired,
  projectMarketerMatchesQuery: PropTypes.object.isRequired,
  createProjectMarketerMatchRelevanceReportMutation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

ProjectMatchesWrapped.defaultProps = {};

const ProjectMatches = compose(
  withStyles(styles),
  withRouter,
  withQuery(ProjectMarketerMatchesQuery, {
    options: (props) => ({
      variables: { projectId: props.match?.params?.projectId },
      skip: !props.match?.params?.projectId,
    }),
  }),
  withMutation(CreateProjectMarketerMatchRelevanceReportMutation),
)(ProjectMatchesWrapped);

export default ProjectMatches;
