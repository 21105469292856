import React, { FC } from 'react';

import { formatDate } from '../../../../app/utils';
import { svgs } from '../../../../../fe_common/client/app/svg';
import { Button } from '../../../../../fe_common/client/components/inputs';

import { useStyles } from './style';

export interface PayPalPaymentAgreementProps {
  currentActiveAgreement: any;
  onDeactivatePaymentAgreement: () => void;
  isLoading: boolean;
}

const PayPalPaymentAgreement: FC<PayPalPaymentAgreementProps> = (props) => {
  const classes = useStyles();

  const { currentActiveAgreement, onDeactivatePaymentAgreement, isLoading } = props;

  const { active, valid, created, id, payerFirstName, payerLastName, payerId, payerEmailAddress } =
    currentActiveAgreement;

  return (
    <div className={classes.root}>
      <img src={svgs.paypalWithText} alt="" />
      <div>
        Payer Email: {payerEmailAddress} <br />
        Payer Name: {payerFirstName} {payerLastName} <br />
        Payer ID: {payerId} <br />
        Status: {active ? 'Active' : 'Inactive'}
        <br />
        Valid: {valid ? 'Yes' : 'No'}
        <br />
        Added: {formatDate(created)}
        <br />
        PayPal Agreement ID: {id}
        <br />
      </div>
      {active && (
        <div className={classes.actions}>
          <Button
            label="Deactivate paypal agreement"
            color="secondary"
            variant="contained"
            disabled={isLoading}
            onClick={onDeactivatePaymentAgreement}
          />
        </div>
      )}
    </div>
  );
};

export default PayPalPaymentAgreement;
