import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(
  createStyles({
    root: {},
    closeBtn: {
      marginTop: -12,
      marginRight: -16,
    },
  })
);

export default useStyles;
