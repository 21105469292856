import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: colors.white,
      display: 'flex',
    },
    progress: {},
  })
);

interface LoadingPlaceholderProps {
  classes?: Record<string, any>;
}

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item>
        <CircularProgress className={classes.progress} size={80} />
      </Grid>
    </Grid>
  );
};

export default LoadingPlaceholder;
