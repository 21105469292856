import {
  MarketerAssessmentByGrowthStrategistParameterKey,
  MarketerAssessmentByCompanyParameterKey,
  PostProjectMatchOfferMarketerAssessmentParameterKey,
  OutbrainAuditAssessmentByCompanyParameterKey,
} from '@mayple/types';

export const marketerAssessmentByCompanyParameterKeys: string[] = Object.keys(MarketerAssessmentByCompanyParameterKey);

export const marketerAssessmentByGrowthStrategistParameterKeys: string[] = Object.keys(
  MarketerAssessmentByGrowthStrategistParameterKey
);

export const postProjectMatchOfferMarketerAssessmentParameterKeys: string[] = Object.keys(
  PostProjectMatchOfferMarketerAssessmentParameterKey
);

export const outbrainAuditAssessmentByCompanyParameterKeys: string[] = Object.keys(
  OutbrainAuditAssessmentByCompanyParameterKey
);
