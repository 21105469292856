import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
    },
    inlineItem: {
      paddingLeft: '0.4rem',
    },
  }),
);

export default useStyles;
