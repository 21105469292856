import React from 'react';

import { getMarketingPlanProgressHeaders } from '../logic';
import { useMarketingPlanState } from '../../MarketingPlanProvider';
import SectionHeader from '../../../display/SectionHeader/SectionHeader';

const MarketingPlanProgressHeader: React.FC = () => {
  const {
    marketingPlanStatus,
    marketingPlanDueDate,
    marketingPlanDueDateFormatted,
    timeLeftForMarketingPlanDueDate,
    companyName,
  } = useMarketingPlanState();

  const { title, subtitle, avatarUrl } = getMarketingPlanProgressHeaders(marketingPlanStatus, {
    marketingPlanDueDate,
    marketingPlanDueDateFormatted,
    timeLeftForMarketingPlanDueDate,
    companyName,
  });

  return <SectionHeader title={title} subtitle={subtitle} avatarSize={90} avatarUrl={avatarUrl} />;
};

export default MarketingPlanProgressHeader;
