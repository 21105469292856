import { useMemo } from 'react';
import { Project } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { CompanyProjectsBasicQuery } from 'growl-graphql/dist/queries/company/CompanyProjectsBasicQuery';

type CompanyProjectsBasic = {
  companyProjects: Project[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: { companyId: number }) => Promise<ApolloQueryResult<any>>;
};

const useCompanyProjectsBasic = (companyId: number): CompanyProjectsBasic => {
  const { data, loading, error, refetch } = useQuery(CompanyProjectsBasicQuery.query, {
    variables: { companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const companyProjects = useMemo(() => {
    if (loading) {
      return [];
    }

    return data?.company?.projects || [];
  }, [data?.company?.projects, loading]);

  return {
    companyProjects,
    loading,
    error,
    refetch,
  };
};

export default useCompanyProjectsBasic;
