import React, { FC } from 'react';
import { AccountType, InternalTeam } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { internalTeamLinkFormatter } from '../../../../logic/linkFormatters';
import InternalTeamEditorDialog from '../../../InternalTeamsPage/components/InternalTeamEditorDialog';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { colors } from '../../../../../fe_common/client/app/theme';
import { Button } from '../../../../../fe_common/client/components/inputs';
import useDialog from '../../../../../fe_common/client/hooks/useDialog';
import AvailabilityViewerDialog from '../../../../../fe_common/client/components/integrations/calendars/AvailabilityViewer/AvailabilityViewerDialog';
import AvailabilityViewerButton from '../../../../../fe_common/client/components/integrations/calendars/AvailabilityViewer/components/AvailabilityViewerButton';
import CopyHtmlElementToClipboardButton from '../../../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';

import useDetailsHeaderStyles from './styles';

export interface InternalTeamDetailsHeaderProps {
  internalTeam: InternalTeam;
  refetchInternalTeam?: () => void;
}

const InternalTeamDetailsHeader: FC<InternalTeamDetailsHeaderProps> = (props) => {
  const theme = useTheme();
  const classes = useDetailsHeaderStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    internalTeam = {} as InternalTeam,
    refetchInternalTeam = () => {
      /* */
    },
  } = props;

  const { description, name: internalTeamName, id: internalTeamId } = internalTeam;

  const { open, openDialog, closeDialog } = useDialog();

  const {
    open: isAvailabilityDialogOpen,
    openDialog: openAvailabilityDialog,
    closeDialog: closeAvailabilityDialog,
  } = useDialog(false);

  const account = {
    id: internalTeamId,
    accountType: AccountType.INTERNAL_TEAM,
  };

  const title = `${internalTeamName} (${internalTeamId}) Availability`;

  return (
    <div className={classes.root}>
      <Grid container spacing={isMobile ? 3 : 4} className={classes.heroHeader}>
        <div className={classes.headerActions}>
          <AvailabilityViewerButton
            accountId={internalTeamId}
            accountType={AccountType.INTERNAL_TEAM}
            onClick={openAvailabilityDialog}
            classes={{ root: classes.buttonSpacer }}
          />
          <CopyHtmlElementToClipboardButton
            className={classes.copyToSlackButton}
            querySelector="#user-header"
            label="Copy to Slack"
            size="small"
          />
        </div>
        <Grid item className={classes.heroImageContainer}>
          <div className={classes.heroImage} />
        </Grid>
        <Grid item className={classes.heroDetailsContainer}>
          <div id="user-header">
            <TextDisplay variant="h4" bold gutterBottom className={classes.userName}>
              {internalTeamLinkFormatter(internalTeam)}
            </TextDisplay>
          </div>
          <TextDisplay className={classes.description} variant="body1" colorOverride={colors.black_light}>
            {description}
          </TextDisplay>
          <Button label="Edit Internal Account" onClick={openDialog} color="primary" variant="outlined" />
        </Grid>
      </Grid>
      {open && (
        <InternalTeamEditorDialog
          internalTeam={internalTeam}
          isOpen={open}
          onClose={closeDialog}
          onSuccess={refetchInternalTeam}
        />
      )}
      <AvailabilityViewerDialog
        mainAccount={account}
        selectionAccountType={AccountType.MARKETER}
        title={title}
        isOpen={isAvailabilityDialogOpen}
        onClose={closeAvailabilityDialog}
      />
    </div>
  );
};

export default InternalTeamDetailsHeader;
