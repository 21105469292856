import React from 'react';
import { ProjectLifeCycleStatus } from '@mayple/types';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { UpdateProjectCycleWithOperationResultMutation } from 'growl-graphql/dist/mutations/UpdateProjectCycleWithOperationResultMutation';
import { TriggerProjectCycleFinalBillingMutation } from 'growl-graphql/dist/mutations/TriggerProjectCycleFinalBillingMutation';

import { getElapsedCycleWarningsBeforeBilling } from './logic';
import PauseDurations from './PauseDurations';
import CyclePauseDurationsType from './PauseDurations/consts';
import CyclesSideBySide from '../../../components/cpanel/components/organisms/Project/ProjectCycleDetailsNew/CyclesSideBySide';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import useMutation from '../../../../fe_common/client/hooks/useMutation';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';
import FundAccountDetails from '../../../components/cpanel/components/molecules/FundAccountDetails';
import { Button } from '../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../fe_common/client/components/display';

const useStyles = makeStyles(() => ({
  root: {},
  calloutMessageRoot: {
    marginBottom: 24,
  },
  reviewButton: {
    marginBottom: 24,
  },
  divider: {
    marginTop: 40,
    marginBottom: 40,
  },
}));

function ProjectElapsedCycleReview(props) {
  const { project, elapsedCycles, currency, projectLifeCycleStatus, refetchProjectData } = props;

  const { id: projectId } = project;
  const classes = useStyles();
  const { mutate: updateCycle, loading: loadingUpdateCycle } = useMutation(
    UpdateProjectCycleWithOperationResultMutation,
  );
  const { mutate: triggerBilling, loading: loadingFinalBilling } = useMutation(TriggerProjectCycleFinalBillingMutation);

  const handleSwitchOnChange = async (elapsedCycle) => {
    const { id, isReadyForFinalBilling } = elapsedCycle;
    await updateCycle({
      variables: {
        projectCycleId: id,
        projectCycleUpdate: {
          isReadyForFinalBilling: !isReadyForFinalBilling,
        },
      },
    });

    refetchProjectData?.();
  };

  const handleTriggerBilling = async (cycleId) => {
    const ans = window.confirm(
      `You are about to trigger an immediate billing for project ${projectId}. ` +
        'The PPC ad spend of a project might take up to 48-hours to read properly. ' +
        'If you choose to bill now, make sure the PPC ad spend is correctly configured.',
    );

    if (!ans) {
      return;
    }

    await triggerBilling({ variables: { cycleId } });
    refetchProjectData();
  };

  return (
    <SectionContent>
      {elapsedCycles.length === 0 && (
        <CalloutMessage
          type="info"
          classes={{ root: classes.calloutMessageRoot }}
          message="No elapsed cycles for this project."
        />
      )}
      {elapsedCycles.length > 0 &&
        elapsedCycles.map((cycle, index) => {
          const { id, endDate, isReadyForFinalBilling, actualMediaSpendDivision } = cycle;

          const warnings = getElapsedCycleWarningsBeforeBilling(project, cycle);
          const HOUR = 1000 * 3600;
          const PPC_WARNING_HOURS = 48;
          const endDateUtc = new Date(endDate).getTime();
          const currentDateUtc = new Date().getTime();
          const hoursDiffFromCycleEnd = (currentDateUtc - endDateUtc) / HOUR;
          const havePpcServices = (actualMediaSpendDivision || []).length > 0;
          const showPpcWarning = hoursDiffFromCycleEnd > PPC_WARNING_HOURS && havePpcServices;

          const errorMessage =
            `Ad spend might take up to ${PPC_WARNING_HOURS} hours to update. ` +
            'If you perform billing before that, you risk billing based on an imprecise PPC spend.';
          const warningMessage =
            'Final billing is blocked by review, click the button to review the cycle and confirm it.';
          const allGoodMessage = 'Final billing confirmed and will occur at midnight (UTC) after the 5th of the month.';

          return (
            <div key={`${endDate}-elapsedCycle-key`}>
              {!showPpcWarning && (
                <CalloutMessage type="error" message={errorMessage} classes={{ root: classes.calloutMessageRoot }} />
              )}
              {!isReadyForFinalBilling && (
                <CalloutMessage
                  type="warning"
                  message={warningMessage}
                  classes={{ root: classes.calloutMessageRoot }}
                />
              )}
              {isReadyForFinalBilling && (
                <CalloutMessage
                  type="success"
                  message={allGoodMessage}
                  classes={{ root: classes.calloutMessageRoot }}
                />
              )}
              {warnings &&
                warnings.map((warningMsg) => (
                  <CalloutMessage
                    key={warningMsg.replace(/\s/g, '')}
                    type="error"
                    message={warningMsg}
                    classes={{ root: classes.calloutMessageRoot }}
                  />
                ))}
              <div>
                <Button
                  label="Review Cycle Prior to Final Billing..."
                  color="primary"
                  variant="contained"
                  link={`/projects/${projectId}/billing/${id}${window.location.hash || ''}`}
                  disabled={isReadyForFinalBilling}
                  className={classes.reviewButton}
                />
              </div>
              <FormControlLabel
                disabled={loadingUpdateCycle}
                control={
                  <Switch
                    checked={isReadyForFinalBilling}
                    onChange={() => {
                      handleSwitchOnChange(cycle);
                    }}
                  />
                }
                label={isReadyForFinalBilling ? 'Confirmed for Final Billing ✅' : 'Not Confirmed for Final billing ❌'}
              />
              {isReadyForFinalBilling && (
                <div>
                  <Button
                    label="PERFORM FINAL BILLING NOW"
                    color="secondary"
                    variant="contained"
                    disabled={!isReadyForFinalBilling || loadingFinalBilling}
                    className={classes.reviewButton}
                    onClick={() => {
                      handleTriggerBilling(cycle.id);
                    }}
                  />
                  <TextDisplay variant="caption">
                    The PPC ad spend of a project might take up to 48-hours to read properly. If you choose to bill now,
                    make sure the PPC ad spend is correctly configured.
                  </TextDisplay>
                </div>
              )}
              <CyclesSideBySide
                projectId={projectId}
                currentCycle={cycle}
                nextCycle={null}
                currency={currency}
                showActual
                projectLifeCycleStatus={projectLifeCycleStatus}
              />
              <PauseDurations
                name="ppcDurations"
                title="PPC pause durations"
                durations={cycle.ppcPauseDurations}
                durationType={CyclePauseDurationsType.PPC_DURATIONS}
                isServicePaused={cycle.isPPCPaused}
                projectId={projectId}
              />
              <PauseDurations
                name="nonPpcDurations"
                title="Non-PPC pause durations"
                durations={cycle.nonPPCPauseDurations}
                durationType={CyclePauseDurationsType.NON_PPC_DURATIONS}
                isServicePaused={cycle.isNonPPCPaused}
                projectId={projectId}
              />
              <FundAccountDetails fundAccount={cycle.fundAccount} title="Cycle Fund Account" showDebt={false} />
              {index < elapsedCycles.length - 1 && <Divider className={classes.divider} />}
            </div>
          );
        })}
    </SectionContent>
  );
}

ProjectElapsedCycleReview.propTypes = {
  project: PropTypes.object.isRequired,
  refetchProjectData: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  projectLifeCycleStatus: PropTypes.oneOf(Object.values(ProjectLifeCycleStatus)).isRequired,
  elapsedCycles: PropTypes.arrayOf(PropTypes.object),
};

ProjectElapsedCycleReview.defaultProps = {
  elapsedCycles: [],
};

export default ProjectElapsedCycleReview;
