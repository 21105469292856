import { CreditCardPaymentAgreement, ManualPaymentAgreement, PaypalOpenPaymentAgreement } from '@mayple/types';
import { CompanyDetailsTabs } from './types';

import { PAYMENT_METHODS } from '../../../fe_common/client/app/consts';

export const companyTabs = [
  {
    value: CompanyDetailsTabs.basicDetails,
    label: 'Basic Details',
  },
  {
    value: CompanyDetailsTabs.funds,
    label: 'Funds',
  },
  {
    value: CompanyDetailsTabs.integrations,
    label: 'Integrations',
  },
  {
    value: CompanyDetailsTabs.users,
    label: 'Users',
  },
];

export type PaymentAgreement = CreditCardPaymentAgreement & PaypalOpenPaymentAgreement & ManualPaymentAgreement;

export const getCurrentPaymentMethod = (currentActiveAgreement: PaymentAgreement): string | null => {
  const {
    payerEmailAddress, // That means it is a PayPal payment agreement
    cardLastFourDigits, // That means it is a Credit Card payment agreement
  } = currentActiveAgreement || {};

  if (cardLastFourDigits) {
    return PAYMENT_METHODS.CREDIT_CARD;
  }
  if (payerEmailAddress) {
    return PAYMENT_METHODS.PAYPAL;
  }

  return null;
};
