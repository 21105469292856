import { AVAILABILITY_MANAGEMENT } from './types';
import {
  CronofyAvailabilityOptionsStyles,
  CronofyAvailabilityViewerMode,
  CronofyAvailabilityViewerSlotSelection,
  WeekDays,
} from '../types/cronofyTypes';

import { colorPalette, colors } from '../../../../app/theme';

export const TRANSLATION_NS = 'availabilityViewer';

export const Constants = {
  DEFAULT_ELEMENT_TARGET_ID: 'cronofy-availability-viewer',
  DEFAULT_WORKING_DAY_START_TIME: '08:00',
  DEFAULT_WORKING_DAY_END_TIME: '20:00',
  DEFAULT_INTERVAL: 30,
  DEFAULT_CSS_CLASSNAME_PREFIX: 'availability-viewer',
  DEFAULT_WEEK_START_DAY: WeekDays.monday,
  DEFAULT_MODE: CronofyAvailabilityViewerMode.no_confirm,
  DEFAULT_BOUNDS_CONTROL: false,
  DEFAULT_ALLOW_EXPANSION: true,
  DEFAULT_MAX_SELECTION_COUNT: false,
  DEFAULT_SLOT_SELECTION: CronofyAvailabilityViewerSlotSelection.unrestricted,
  DEFAULT_MANAGED_AVAILABILITY: true,
};

export const defaultStyles: CronofyAvailabilityOptionsStyles = {
  colors: {
    available: colorPalette.green['20'],
    unavailable: colors.white,
  },
  prefix: Constants.DEFAULT_CSS_CLASSNAME_PREFIX,
};

export const availabilityManagementOptions = [
  {
    label: 'Use Availability rules',
    value: AVAILABILITY_MANAGEMENT.USE_MANAGED_AVAILABILITY,
  },
  {
    label: 'Ignore Availability rules',
    value: AVAILABILITY_MANAGEMENT.VIEW_ALL,
  },
];
