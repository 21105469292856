import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Step } from 'react-albus';

import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { WizardNavigationButtons } from '../../../../../fe_common/client/components/molecules/WizardNavigationButtons';

export function WizardSteps(props) {
  const { invalid, submit, submitting, formValues } = props;

  return (
    <Steps>
      <Step
        id="marketerUpdateStep"
        render={(step) => (
          <div>
            <WizardStepField
              type={WizardStepField.types.NUMBER}
              name="amount"
              label="Loan amount"
              required
              minimumValue={0}
              maximumValue={1000000}
            />
            <WizardStepField
              type={WizardStepField.types.TEXT}
              name="description"
              label="Describe the loan details"
              required
              minLength={10}
            />
            <WizardStepField
              type={WizardStepField.types.CHECKBOX}
              name="enableLoanFundsToCompany"
              inputLabel="I approve this loan creation"
            />
            <WizardNavigationButtons
              step={step}
              submit={submit}
              invalid={invalid}
              submitting={submitting}
              disableNext={invalid || !formValues.enableLoanFundsToCompany}
            />
          </div>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  formValues: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};
