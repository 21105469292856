import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerTop: {
      marginTop: '1rem',
      marginBottom: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
      flexWrap: 'wrap-reverse',
      flexDirection: 'row',
      [theme.breakpoints.up('md')]: {
        marginTop: 16,
        flexDirection: 'row',
      },
    },
    marketerType: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '0.5rem',
    },
    marketerTypeText: {
      color: colors.black_light,
    },
    marketerTypeIcon: {},
    vetted: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '0.5rem',
    },
    vettedText: {
      color: colors.green_darker,
    },
    vettedIcon: {
      color: colors.green_darker,
    },
    spacer: {
      flexGrow: 0,
      flexShrink: 1,
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
      },
    },
    actions: {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexGrow: 1,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        width: 'auto',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },

    expertHeroHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        gap: '1rem',
      },
    },
    expertHeroImageContainer: {
      minWidth: '100%',
      minHeight: 'auto',
      [theme.breakpoints.up('sm')]: {
        minWidth: 116,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 213,
      },

      // minWidth: 213,
      // [theme.breakpoints.down('sm')]: {
      //   minWidth: 116,
      // },
      // [theme.breakpoints.down('xs')]: {
      // },
    },
    expertHeroImage: {
      display: 'block',
      width: '100%',
      paddingTop: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      // [theme.breakpoints.down('sm')]: {},
      // [theme.breakpoints.down('xs')]: {
      //   height: 'auto',
      //   width: '100%',
      //   maxHeight: 384,
      //   maxWidth: 384,
      //   minWidth: 'unset',
      // },
    },
    expertHeroImageFreelancer: {
      boxShadow: '0px 2px 16px rgba(36, 24, 21, 0.19)',
      borderRadius: 16,
    },
    expertHeroImageAgency: {
      backgroundSize: 'contain',
    },
    expertHeroDetailsContainer: {
      flexGrow: 1,
    },
    expertName: {
      marginTop: 16,
    },

    digitalAgencyHeader: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
      },
    },
    freelancerHeader: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {},
    },
    description: { marginTop: 40, color: colors.black_light },
    nameStatusContainer: { width: '100%' },
    buttonSpacer: {
      marginRight: 8,
    },
  }),
);

export default useStyles;
