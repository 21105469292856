import { FlowConfiguration, MediaDocument, MediaType, Project } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { ProjectByIdNotesQuery } from 'growl-graphql/dist/queries/ProjectByIdNotesQuery';

import { asBool } from '../../services/utils';

export interface NotesData {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: { projectId: number }) => Promise<ApolloQueryResult<Project>>;
  companyNote: MediaDocument | null | undefined;
  projectNote: MediaDocument | null | undefined;
  notes: MediaDocument;
}

export const getNotesDataIgnoreConfiguration = (
  project: Partial<Project> | null | undefined
): { companyNote: MediaDocument | null | undefined; projectNote: MediaDocument | null | undefined } => {
  const companyNote = project?.company?.note;
  const projectNote = project?.note;

  return {
    companyNote,
    projectNote,
  };
};

export const getNotesData = (project: Project | null | undefined): MediaDocument => {
  const { opportunityIncludeCompanyNote, opportunityIncludeProjectNote } =
    project?.flowConfiguration || ({} as FlowConfiguration);

  const { companyNote, projectNote } = getNotesDataIgnoreConfiguration(project);

  const companyNoteValue = asBool(opportunityIncludeCompanyNote?.value) ? companyNote?.contents || '' : '';
  const projectNoteValue = asBool(opportunityIncludeProjectNote?.value) ? projectNote?.contents || '' : '';

  const contents = ''.concat(companyNoteValue, projectNoteValue).trim();

  return {
    mediaType: MediaType.TEXT_HTML,
    contents,
  };
};

// for admin use
const useNotesData = (
  projectId: number | null | undefined,
  options: QueryHookOptions = { notifyOnNetworkStatusChange: true }
): NotesData => {
  const mergedOptions = {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
    ...options,
  };

  const { loading, error, data, refetch } = useQuery(ProjectByIdNotesQuery.query, mergedOptions);

  const { companyNote, projectNote } = getNotesDataIgnoreConfiguration(data?.project);
  const companyNoteValue = companyNote?.contents || '';
  const projectNoteValue = projectNote?.contents || '';

  const contents = ''.concat(companyNoteValue, projectNoteValue).trim();

  const notes = {
    mediaType: MediaType.TEXT_HTML,
    contents,
  };

  return {
    loading,
    error,
    refetch,
    companyNote,
    projectNote,
    notes,
  };
};

export default useNotesData;
