import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      gap: '0.5rem',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      flexGrow: 0,
      flexShrink: 1,
    },
  }),
);

export default useStyles;
