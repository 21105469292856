import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { withMutation, RejectProjectLeadMutation, ApproveProjectLeadMutation } from 'growl-graphql';
import ReactTable from 'react-table-6';

import { RefreshSpinner } from '../../../fe_common/client/components/atoms';
import LoadingSpinnerNonBlocking from '../../components/cpanel/components/atoms/LoadingSpinnerNonBlocking';
import { clientLogger, handleClientError } from '../../../fe_common/client/services/logger';
import { setEntityOperationResponseNotification } from '../../../fe_common/client/services/notification';
import { filterCaseInsensitiveForReactTable } from '../../../fe_common/client/services/reactTableHelpers/filterMethods';
import { prependHttp } from '../../../fe_common/client/services/utils';
import { Button } from '../../../fe_common/client/components/inputs';

class ProjectLeadsItem extends Component {
  columns = [
    {
      Header: 'Company Name',
      accessor: (d) => d.companyCreate.name,
      id: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (d) => <Link to={`/project_leads/${d.original.id}`}>{d.value || 'N/A'}</Link>,
    },
    {
      Header: 'Company Website',
      accessor: (d) => get(d, 'companyCreate.companyProfile.contactDetails.websiteAddress', '#') || '#',
      id: 'websiteAddress',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (d) => (
        <a target="_blank" rel="noreferrer noopener" href={prependHttp(d.value)}>
          {d.value}
        </a>
      ),
    },
    {
      Header: 'Email',
      accessor: (d) => get(d, 'companyCreate.companyProfile.contactDetails.emailAddress', 'No email') || 'No email',
      id: 'emailAddress',
    },
    {
      Header: 'Phone Number',
      accessor: (d) => get(d, 'companyCreate.companyProfile.contactDetails.phoneNumber', 'No phone') || 'No phone',
      id: 'phoneNumber',
    },
    {
      Header: 'Reject/Approve',
      accessor: (d) => d.id,
      id: 'rejectLead',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (d) => {
        if (d.original.isProjectCreated) {
          return (
            <span className="green-text">
              Approved! <Link to={`/projects/${d.original.projectId}`}>Project {d.original.projectId}</Link>
            </span>
          );
        }
        return (
          <React.Fragment>
            <Button
              onClick={() => {
                this.handleRejectProjectLead(d.value);
              }}
              label="REJECT"
              color="secondary"
              variant="contained"
              size="small"
            />
            <Button
              onClick={() => {
                this.handleApproveProjectLead(d.value);
              }}
              label="APPROVE"
              variant="contained"
              color="primary"
              size="small"
              style={{ background: 'green', marginLeft: 4 }}
            />
          </React.Fragment>
        );
      },
    },
  ];

  handleApproveProjectLead = async (projectLeadId) => {
    // eslint-disable-next-line no-restricted-globals
    const ans = confirm(`Are you sure you want to approve lead creation ${projectLeadId} ?`);
    if (ans) {
      try {
        const { approveProjectLeadMutation, refetch } = this.props;
        const vars = { projectLeadId };

        const response = await approveProjectLeadMutation.mutate(vars);
        clientLogger.debug(`approveProjectLeadMutation response:\n\n${JSON.stringify(response, null, 2)}`);
        const { approveProjectLead } = response;

        setEntityOperationResponseNotification(approveProjectLead);

        if (approveProjectLead.success) {
          refetch();
        } else {
          throw new Error(approveProjectLead.result);
        }
      } catch (e) {
        handleClientError(e, 'Unable to approve lead');
      }
    }
  };

  handleRejectProjectLead = async (projectLeadId) => {
    // eslint-disable-next-line no-restricted-globals
    const ans = confirm(`Are you sure you want to reject lead ${projectLeadId} ?`);
    if (ans) {
      try {
        const { rejectProjectLeadMutation, refetch } = this.props;
        const vars = { projectLeadId };

        const response = await rejectProjectLeadMutation.mutate(vars);
        clientLogger.debug(`rejectProjectLeadMutation response:\n\n${JSON.stringify(response, null, 2)}`);
        const { rejectProjectLead } = response;

        setEntityOperationResponseNotification(rejectProjectLead);

        if (rejectProjectLead.success) {
          refetch();
        } else {
          throw new Error(rejectProjectLead.result);
        }
      } catch (e) {
        handleClientError(e, 'Unable to reject lead');
      }
    }
  };

  render() {
    const { data, rejectProjectLeadMutation, approveProjectLeadMutation, loading } = this.props;
    const { loading: loadingRejectLead } = rejectProjectLeadMutation;
    const { loading: loadingApproveLead } = approveProjectLeadMutation;

    if (loadingRejectLead || loadingApproveLead) {
      return <RefreshSpinner />;
    }

    if (!data) {
      return <div>Error. Could not get project leads data, please contact support.</div>;
    }

    return (
      <Grid container direction="column" spacing={5}>
        <Grid item>
          <div className="table-wrap">
            <h3>Project Leads Table</h3>
            <p>Leads that has finished the flow, but did not sign up.</p>
            <Card>
              <ReactTable
                loading={loading}
                LoadingComponent={LoadingSpinnerNonBlocking}
                data={data} // show new projects first
                columns={this.columns}
                defaultPageSize={10}
                filterable
                defaultFilterMethod={filterCaseInsensitiveForReactTable}
              />
            </Card>
          </div>
        </Grid>
      </Grid>
    );
  }
}

ProjectLeadsItem.propTypes = {
  data: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  rejectProjectLeadMutation: PropTypes.object.isRequired,
  approveProjectLeadMutation: PropTypes.object.isRequired,
};

const ProjectLeadsTable = compose(
  withMutation(RejectProjectLeadMutation),
  withMutation(ApproveProjectLeadMutation),
)(ProjectLeadsItem);

export default ProjectLeadsTable;
