import React from 'react';
import { GlobalEntitySearchResult } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import EntityType from '../../app/types';
import { formatResult, getDescriptionOutOfContext } from './logic';
import LinkToCompany from '../../components/cpanel/components/atoms/Links/LinkToCompany';
import LinkToMarketer from '../../components/cpanel/components/atoms/Links/LinkToMarketer';
import LinkToProject from '../../components/cpanel/components/atoms/Links/LinkToProject';
import LinkToProjectLead from '../../components/cpanel/components/atoms/Links/LinkToProjectLead';
import LinkToUser from '../../components/cpanel/components/atoms/Links/LinkToUser';
import LinkToInternalTeam from '../../components/cpanel/components/atoms/Links/LinkToInternalTeam';

import { TextDisplay } from '../../../fe_common/client/components/display';
import { colors } from '../../../fe_common/client/app/theme';

interface SearchResultProps {
  searchResult: GlobalEntitySearchResult;
  searchTerm: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: '2rem',
    },
    description: {
      color: colors.black_light,
    },
  }),
);

const SearchResult: React.FC<SearchResultProps> = (props) => {
  const classes = useStyles();

  const { searchResult, searchTerm } = props;
  const { entityType, entityId, context } = searchResult;

  const description = getDescriptionOutOfContext(context, searchTerm);

  return (
    <div className={classes.root}>
      {entityType === EntityType.Company && (
        <LinkToCompany companyId={entityId}>{formatResult(searchResult)}</LinkToCompany>
      )}
      {entityType === EntityType.Marketer && (
        <LinkToMarketer marketerId={entityId}>{formatResult(searchResult)}</LinkToMarketer>
      )}
      {entityType === EntityType.Project && (
        <LinkToProject projectId={entityId}>{formatResult(searchResult)}</LinkToProject>
      )}
      {entityType === EntityType.ProjectLead && (
        <LinkToProjectLead projectLeadId={entityId}>{formatResult(searchResult)}</LinkToProjectLead>
      )}
      {entityType === EntityType.User && <LinkToUser userId={entityId}>{formatResult(searchResult)}</LinkToUser>}
      {entityType === EntityType.InternalTeam && (
        <LinkToInternalTeam internalTeamId={entityId}>{formatResult(searchResult)}</LinkToInternalTeam>
      )}
      <TextDisplay variant="caption" className={classes.description}>
        {/* eslint-disable-next-line react/no-danger, jam3/no-sanitizer-with-danger */}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </TextDisplay>
    </div>
  );
};

export default SearchResult;
