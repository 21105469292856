/* eslint-disable react/display-name */
import React from 'react';
import { Project } from '@mayple/types';
import moment from 'moment';
import keyBy from 'lodash/keyBy';
import { Column, RowRenderProps } from 'react-table-6';

import { emphasizeWithColor } from '../../../fe_common/client/services/reactTableHelpers';
import LinkToMarketer from '../../components/cpanel/components/atoms/Links/LinkToMarketer';
import LinkToProject from '../../components/cpanel/components/atoms/Links/LinkToProject';
import LinkToCompany from '../../components/cpanel/components/atoms/Links/LinkToCompany';

import { LabelValueProps } from '../../../fe_common/client/app/types';
import { titleCase } from '../../../fe_common/client/services/utils';
import { yesNoFormatter } from '../../../fe_common/client/services/ExportToCSV/formatters';
import { ContainsFilter, EnumFilter } from '../../../fe_common/client/components/molecules/QueryTable';

export function getIsValidTopic(latestFeedPostByTopic: any = {}): boolean {
  const { datetime, topic } = latestFeedPostByTopic;

  if (['MONTHLY_UPDATE', 'WEEKLY_UPDATE'].includes(topic)) {
    if (!datetime) {
      return false;
    }

    const days = moment.utc().diff(moment.utc(datetime), 'days');

    const MONTHLY_UPDATE_THRESHOLD = 30;
    const WEEKLY_UPDATE_THRESHOLD = 7;

    if (topic === 'MONTHLY_UPDATE' && days > MONTHLY_UPDATE_THRESHOLD) {
      return false;
    }
    if (topic === 'WEEKLY_UPDATE' && days > WEEKLY_UPDATE_THRESHOLD) {
      return false;
    }
  }

  return true;
}

function getLatestPostByTopic(topic: string, feedLatestPostDateByTopic: any) {
  // @ts-ignore
  return feedLatestPostDateByTopic.find(({ topic: t }) => t === topic);
}

// @ts-ignore
const TopicDateRenderer = ({ latestFeedPostByTopic }) => {
  if (!latestFeedPostByTopic) {
    return null;
  }

  const { topic, datetime } = latestFeedPostByTopic;

  if (datetime) {
    return <span>{moment.utc().diff(moment.utc(datetime), 'days')}</span>;
  }

  return <span style={{ color: 'red' }}>No posts for {titleCase(topic)}</span>;
};

export const getColumns = (topic: string, growthStrategistsLabelValue: LabelValueProps[]): Column[] => {
  const growthStrategistsLookup = keyBy(growthStrategistsLabelValue, 'value');

  return [
    {
      id: 'project',
      Header: 'Project',
      accessor: (d: Project) => d.name,
      Cell: (d: RowRenderProps) => (
        <LinkToProject projectId={d.original.id}>
          {d.value} ({d.original.id})
        </LinkToProject>
      ),
      Filter: ContainsFilter({ key: 'name' }),
      sortable: false,
    },
    {
      id: 'company',
      Header: 'Company',
      accessor: (d: Project) => d.company.name,
      Cell: (d: RowRenderProps) => (
        <LinkToCompany companyId={d.original.companyId}>
          {d.value} ({d.original.companyId})
        </LinkToCompany>
      ),
      filterable: false,
      sortable: false,
    },
    {
      id: 'marketer',
      Header: 'Marketer',
      accessor: (d: Project) => d?.participants?.[0]?.marketer?.name,
      Cell: (d: RowRenderProps) => (
        <LinkToMarketer marketerId={d.original.participants[0].marketerId}>
          {d.value} ({d.original.participants[0].marketerId})
        </LinkToMarketer>
      ),
      filterable: false,
      sortable: false,
    },
    {
      id: 'mainGrowthStrategistId',
      Header: 'Assigned Growth Strategist',
      accessor: 'mainGrowthStrategistId',
      Filter: EnumFilter({ key: 'mainGrowthStrategistId', items: growthStrategistsLabelValue }),
      Cell: (d: RowRenderProps) => {
        const { value } = d;
        return growthStrategistsLookup?.[value]?.label || 'Unknown';
      },
      sortable: false,
    },
    {
      id: 'daysSinceLastPost',
      Header: 'Days since last post',
      accessor: 'participants[0].feedStatistics',
      Cell: (d: RowRenderProps) => {
        const { feedLatestPostDateByTopic } = d.value;
        const latestFeedPostByTopic = getLatestPostByTopic(topic, feedLatestPostDateByTopic);
        return <TopicDateRenderer latestFeedPostByTopic={latestFeedPostByTopic} />;
      },
      filterable: false,
      sortable: false,
    },
    {
      id: 'valid',
      Header: 'Valid',
      accessor: 'participants[0].feedStatistics',
      Cell: (d: RowRenderProps) => {
        const { feedLatestPostDateByTopic } = d.value;
        const latestFeedPostByTopic = getLatestPostByTopic(topic, feedLatestPostDateByTopic);
        const isValid = getIsValidTopic(latestFeedPostByTopic);
        return emphasizeWithColor(yesNoFormatter(isValid), 'No');
      },
      filterable: false,
      sortable: false,
      width: 75,
    },
  ];
};
