import React, { FC } from 'react';
import { MarketerCapabilities } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import capabilities from './consts';

import { colors } from '../../../app/theme';
import { svgs } from '../../../app/svg';
import { TextDisplay } from '../../display';
import ExpertProfileInfoCard from '../ExpertProfileInfoCard';

interface ExpertProfileCapabilitiesProps {
  expert: MarketerCapabilities;
}

const ExpertProfileCapabilities: FC<ExpertProfileCapabilitiesProps> = (props) => {
  const { expert } = props;
  const marketerCapabilities = (expert || {}) as Record<string, any>;

  return (
    <Grid container spacing={4}>
      {capabilities.map(({ key, title, labels, getLabel }) => {
        if (
          Object.prototype.hasOwnProperty.call(marketerCapabilities, key) &&
          (marketerCapabilities[key] || []).length > 0
        ) {
          return (
            <Grid item xs={12} md={6} key={`${key}-capabilities-item-key-expert-profile`}>
              <ExpertProfileInfoCard>
                <Grid container wrap="nowrap" spacing={1} style={{ marginBottom: 16 }}>
                  <img src={svgs[key]} alt="" style={{ marginRight: 8 }} />
                  <TextDisplay colorOverride={colors.black_light} bold>
                    {title}
                  </TextDisplay>
                </Grid>
                <div>
                  {marketerCapabilities[key].map((item: string, i: number) => (
                    <Chip
                      key={`${key}-${i}-expert-capability-chip`}
                      label={getLabel ? getLabel(item) : labels[item]}
                      style={{ marginRight: 8, marginBottom: 8, backgroundColor: colors.white_darker }}
                    />
                  ))}
                </div>
              </ExpertProfileInfoCard>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default ExpertProfileCapabilities;
