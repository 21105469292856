const texts = {
  dialogTitle: 'Connect your calendar',
  dialogSubtitle: 'This will let Mayple and your Mayple clients schedule calls with you',
  calloutTitle: 'Privacy notice',
  calloutSubtitle:
    'Mayple’s system and your Mayple clients will only have access to your calendar availability ' +
    'and won’t be able to see or modify your existing meetings',
  instructions: [
    'Click “connect calendar” - This will open a new tab',
    'Select the calendar you want to connect',
    'Approve access',
    'Come back to this tab and set your work hours',
  ],
};

export default texts;
