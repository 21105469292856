import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import classNames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import withStyles from '@material-ui/core/styles/withStyles';

import { TextDisplay } from '../../../../fe_common/client/components/display';
import { Button } from '../../../../fe_common/client/components/inputs';

const styles = () => ({
  root: {
    padding: 16,
    border: '2px solid red',
  },
  title: {
    paddingRight: 16,
  },
  redText: {
    color: 'red',
  },
  errorIcon: {
    paddingRight: '0 !important',
  },
});

class DisableEntityControl extends Component {
  state = {
    enableDisableButton: false,
    didDisable: false,
  };

  setEnableDisableButton = (enableDisableButton) => {
    this.setState({ enableDisableButton });
  };

  setDidDisable = (didDisable) => {
    this.setState({ didDisable });
  };

  onDisableEntity = async () => {
    const { mutation, variables, entity } = this.props;

    const hasBalance = entity?.fundAccount?.balance > 0;

    if (hasBalance) {
      alert(`
      Existing balance of ${entity?.fundAccount?.balance} found!.
      please handle the funds first and try again when balance is 0.
      `);
      return;
    }

    const entityId = entity.id;

    // Verify admin is aware of entity ID
    const ans1 = window.prompt(`Please confirm the entity ID you are about to disable: (${entityId})`);

    const abortFunc = () => {
      alert('You failed to confirm the disabled entity ID. Aborting');
      this.setEnableDisableButton(false);
    };

    if (ans1 !== entityId.toString()) {
      abortFunc();
      return;
    }
    // Confirm admin is aware
    const ans2 = window.prompt('Again, Please confirm the entity ID you are about to disable');

    if (ans2 !== entityId.toString()) {
      abortFunc();
      return;
    }

    let response;
    if (mutation.mutate && typeof mutation.mutate === 'function') {
      response = await mutation.mutate(variables);
    } else {
      response = await mutation({ variables });
    }

    if (response) {
      window.alert(`Successfully disabled entity ID ${entityId}`);
      this.setDidDisable(true);
    } else {
      window.alert('Something went wrong, operation failed.');
      this.setDidDisable(false);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { title, buttonLabel, warningText, mutation, classes, entity, redirectTo, savingData = false } = this.props;

    const { enableDisableButton, didDisable } = this.state;

    if (didDisable) {
      return <Redirect to={redirectTo} />;
    }

    // console.log('entity: ', entity);

    const hasBalance = entity?.fundAccount?.balance > 0;

    return (
      <Accordion className={classes.root} square elevation={1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay variant="h4" className={classNames(classes.redText, classes.title)} inline>
            {title}
          </TextDisplay>
          <ErrorIcon htmlColor="red" fontWeight="600" fontSize="large" className={classes.errorIcon} />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <hr />
            <TextDisplay variant="h5" className={classes.redText}>
              Read first!
            </TextDisplay>
            <TextDisplay variant="h6" className={classes.redText}>
              {warningText}
            </TextDisplay>
            <TextDisplay variant="h6" className={classes.redText}>
              Be careful. This action is <b>irreversible</b>.
              <br />
              This means that you will not be able to query that entity, nor its child entities which will be disabled
              as well.
            </TextDisplay>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableDisableButton}
                    onChange={this.handleChange('enableDisableButton')}
                    value="checkedA"
                    disabled={hasBalance}
                  />
                }
                label="I want to disable this entity"
              />
            </FormGroup>
            {hasBalance && (
              <TextDisplay variant="caption" className={classes.redText}>
                Found existing balance of {entity?.fundAccount?.balance}. Please handle the funds first and try again
                when balance is 0
              </TextDisplay>
            )}
            <Button
              label={buttonLabel}
              onClick={this.onDisableEntity}
              variant="contained"
              color="secondary"
              disabled={!enableDisableButton || mutation.loading || savingData}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

DisableEntityControl.propTypes = {
  mutation: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  variables: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  warningText: PropTypes.string,
  redirectTo: PropTypes.string,
  savingData: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

DisableEntityControl.defaultProps = {
  title: 'Disable Entity',
  buttonLabel: 'Disable Entity',
  warningText: 'You are about to disable this entity',
  redirectTo: '/',
  savingData: false,
};

export default withStyles(styles)(DisableEntityControl);
