import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { getIndustryLabel, normalizeUrl, toNiceEnumsList } from '../../../services/utils';
import { TARGETED_COUNTRIES_LABELS_LOOKUP } from '../../../app/enums';
import { svgs } from '../../../app/svg';
import { TextDisplay } from '../../display';
import { colors } from '../../../app/theme';

/**
 * To render the project creation flow answers, not really part of the deep brief.
 * @param project
 * @param isPrintMode
 * @param onPanelChange
 * @param closePanel
 * @param classes
 * @returns {*}
 */
function BriefReportBasicDetails({ project, isPrintMode, onPanelChange, closePanel = false, classes }) {
  const { locations = [], industry = [], targetUrls = [], worldwideAudienceTargetting } = project;

  const finalLocations = [...locations];
  if (worldwideAudienceTargetting) {
    finalLocations.push('WW');
  }

  const countriesList = toNiceEnumsList(finalLocations, TARGETED_COUNTRIES_LABELS_LOOKUP);

  let expanded = isPrintMode ? true : undefined;
  // override default Accordion panel expanded state
  if (closePanel === true) {
    expanded = false;
  }

  return (
    <Accordion className={classes.panelRoot} defaultExpanded expanded={expanded} onChange={onPanelChange('basicBrief')}>
      <AccordionSummary expandIcon={isPrintMode ? null : <ExpandMoreIcon />} className={classes.panelSummary}>
        <ListItem style={{ padding: 0 }}>
          <Avatar src={svgs.basicBrief} style={{ borderRadius: 0 }} />
          <ListItemText primary="Basic brief" secondary="Preliminary questions about business's product/service." />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} className={classes.basicBriefItem}>
            <TextDisplay variant="overline" colorOverride={colors.black_light} bold>
              INDUSTRY
            </TextDisplay>
            <TextDisplay variant="h5" bold>
              {getIndustryLabel(industry)}
            </TextDisplay>
          </Grid>

          <Grid item xs={12} className={classes.basicBriefItem}>
            <TextDisplay variant="overline" colorOverride={colors.black_light} bold>
              TARGET LOCATIONS
            </TextDisplay>
            <TextDisplay variant="h5" bold>
              {countriesList}
            </TextDisplay>
          </Grid>

          {!!targetUrls[0] && (
            <Grid item xs={12} className={classes.basicBriefItem}>
              <TextDisplay variant="overline" colorOverride={colors.black_light} bold>
                TARGET WEBSITE
              </TextDisplay>
              <TextDisplay variant="subtitle1">
                <a target="_blank" rel="noopener noreferrer" href={normalizeUrl(targetUrls[0])}>
                  {targetUrls[0]}
                </a>
              </TextDisplay>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

BriefReportBasicDetails.propTypes = {
  project: PropTypes.object.isRequired,
  isPrintMode: PropTypes.bool.isRequired,
  onPanelChange: PropTypes.func.isRequired,
  closePanel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

BriefReportBasicDetails.defaultProps = {
  closePanel: false,
};

export default BriefReportBasicDetails;
