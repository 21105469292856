import React, { FC } from 'react';
import { Company, FundAccount } from '@mayple/types';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useQuery } from '@apollo/react-hooks';
import { CompanyFundAccountQuery } from 'growl-graphql/dist/queries/company/CompanyFundAccountQuery';

import CompanyDebtRepayFromBalance from '../components/CompanyDebtRepayFromBalance';
import ActivePaymentAgreement from '../components/ActivePaymentAgreement';
import CompanyDebtRepayFromPaymentAgreement from '../components/CompanyDebtRepayFromPaymentAgreement';
import ReportRefundToCompany from '../components/ReportRefundToCompany';
import ReportCashPayment from '../components/ReportCashPayment';
import { ManualPaymentAgreementView, ManualPaymentAgreementEditor } from '../components/ManualPaymentAgreement';
import ReportWireTransfer from '../components/ReportWireTransfer';
import GrantFunds from '../components/GrantFunds';
import DivestFunds from '../components/DivestFunds';
import GiveLoan from '../components/GiveLoan';
import ReportExternalDebt from '../components/ReportExternalDebt';
import FundAccountDetails from '../../../components/cpanel/components/molecules/FundAccountDetails';
import BillingDocumentsTable from '../../../components/cpanel/components/molecules/BillingDocumentsTable';
import CompanyInvoice from '../components/CompanyInvoice';
import CompanyBillingMechanism from '../components/CompanyBillingMechanism';
import { config } from '../../../config';

import { Environment } from '../../../../fe_common/client/app/types';
import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import { RefreshSpinner } from '../../../../fe_common/client/components/atoms';

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

interface CompanyFundsTabProps {
  company: Company;
  refetchCompanyData: () => void;
}

const CompanyFundsTab: FC<CompanyFundsTabProps> = (props) => {
  const classes = useStyles();
  const { company: companyInitial, refetchCompanyData } = props;
  const { id: companyId } = companyInitial;
  const {
    data,
    loading,
    refetch: refetchFundAccount,
  } = useQuery(CompanyFundAccountQuery.query, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
  });
  const fundAccount = data?.company?.fundAccount || ({} as FundAccount);
  const { manualPaymentAgreement } = fundAccount;

  if (loading) {
    return <RefreshSpinner linear />;
  }

  const company = {
    ...companyInitial,
    fundAccount,
  };

  // @ts-ignore
  const { useStripe = false } = company;

  const refetch = () => {
    refetchFundAccount();
    refetchCompanyData();
  };

  return (
    <div>
      <Section title="Fund Account" noBorder noTopMargin>
        <FundAccountDetails fundAccount={company.fundAccount} title="Account Movements" />
      </Section>

      <Section
        title="Report Refund To Company"
        subTitle="Report a refund that was performed, returning a payment to the company. Such refunds typically
        happen via BlueSnap, PayPal, or by wire transfer."
        layout={SECTION_LAYOUT.TWO_COLUMNS}
      >
        <SectionContent>
          <ReportRefundToCompany company={company} onSuccess={refetch} />
        </SectionContent>
      </Section>
      {config.env !== Environment.PORKY && (
        <Section
          title="Billing mechanism"
          subTitle="Marks the company to use the new billing system"
          layout={SECTION_LAYOUT.TWO_COLUMNS}
        >
          <SectionContent>
            <CompanyBillingMechanism useStripe={useStripe} companyId={companyId} onChange={refetch} loading={loading} />
          </SectionContent>
        </Section>
      )}
      {!useStripe && (
        <>
          <Section
            title="Active Payment Agreement"
            subTitle={
              'Payment agreements are an agreement between Mayple and the company to use payment methods, ' +
              'like PayPal or credit cards, to pay for the services purchased from Mayple and its marketers.'
            }
            layout={SECTION_LAYOUT.TWO_COLUMNS}
          >
            <SectionContent>
              <ActivePaymentAgreement refetch={refetch} companyId={companyId} fundAccount={fundAccount} />
            </SectionContent>
          </Section>

          <Section
            title="Invoice Details"
            subTitle="The invoice address of the company"
            layout={SECTION_LAYOUT.TWO_COLUMNS}
          >
            <SectionContent>
              <CompanyInvoice invoicingDetails={company?.fundAccount?.invoicingDetails} />
            </SectionContent>
          </Section>

          <Section
            title="Manual Payment Agreement"
            subTitle={
              'Manual payment agreements are an agreement between Mayple and the company, for Mayple to give the ' +
              'company credit to pay for the services purchased from Mayple and its marketers. When a manual ' +
              'payment agreement is used, new Debt is created which should be paid by the company later on.'
            }
            layout={SECTION_LAYOUT.TWO_COLUMNS}
          >
            <SectionContent>
              <ManualPaymentAgreementView manualPaymentAgreement={manualPaymentAgreement || {}} />
              <ManualPaymentAgreementEditor company={company} />
            </SectionContent>
          </Section>
        </>
      )}

      <Section
        title="Repay Debts"
        subTitle={
          'Debt is basically money (or funds) that the company owes Mayple. Debt can be repaid directly, ' +
          'if the company has a sufficient balance to do so. If not, it can be repaid by using payment ' +
          'agreement (naturally, except for manual payment agreements).'
        }
        layout={SECTION_LAYOUT.TWO_COLUMNS}
      >
        <SectionContent>
          <CompanyDebtRepayFromBalance fundAccount={fundAccount || {}} companyId={companyId} onSuccess={refetch} />
          <Divider className={classes.divider} />
          <CompanyDebtRepayFromPaymentAgreement companyId={companyId} fundAccount={fundAccount} onSuccess={refetch} />
        </SectionContent>
      </Section>

      <ReportCashPayment company={company} onSuccess={refetch} />

      <ReportWireTransfer company={company} onSuccess={refetch} />

      <GrantFunds company={company} onSuccess={refetch} />

      <DivestFunds company={company} onSuccess={refetch} />

      <ReportExternalDebt company={company} onSuccess={refetch} />

      <GiveLoan company={company} onSuccess={refetch} />

      <Section
        title="Billing Breakdown Documents"
        layout={SECTION_LAYOUT.ONE_COLUMN}
        subTitle={
          'These documents have a breakdown of billing events, such as charges to payment agreements, ' +
          'debt creation and repayment etc.'
        }
      >
        <SectionContent>
          <BillingDocumentsTable billingBreakdownDocuments={company.billingBreakdownDocuments} />
        </SectionContent>
      </Section>
    </div>
  );
};

export default CompanyFundsTab;
