import React, { FC } from 'react';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { FeedPostReactionsIconsProps } from './types';
import usePostReactions from './usePostReactions';
import TextDisplay from '../../../display/TextDisplay';

import useStyles from './style';

const FeedPostReactionsIcons: FC<FeedPostReactionsIconsProps> = (props) => {
  const { reactions: feedReactions, viewerPersonUuid } = props;
  const classes = useStyles();

  const reactions = usePostReactions(feedReactions, viewerPersonUuid);

  if (!Array.isArray(reactions) || reactions.length === 0) {
    return null;
  }

  return (
    <>
      {reactions.map(({ iconClass, count, icon }, index) => {
        const avatarClassName = classNames({
          [classes.likeAvatar]: iconClass === 'likeAvatar',
        });
        return (
          <Grid container alignItems="center" key={`reaction-icon-${index}`}>
            <Avatar className={avatarClassName}>
              {icon &&
                React.createElement(icon as any, {
                  className: avatarClassName,
                })}
            </Avatar>
            <TextDisplay>{count}</TextDisplay>
          </Grid>
        );
      })}
    </>
  );
};

export default FeedPostReactionsIcons;
