import React, { FC } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactFilestack from 'filestack-react';

import { FileUploadDialogProps, FileUploadDialogTabs } from './types';
import { useFileUpload } from './logic';
import { Button } from '../index';
import { Dialog } from '../../display';
import TabContent from '../../atoms/TabContent';
import CalloutMessageCollapse from '../../common/CalloutMessageCollapse';
import { handleClientError } from '../../../services/logger';

import useStyles from './style';

const FileUploadDialog: FC<FileUploadDialogProps> = (props) => {
  const {
    filestackApiKey,
    loading,
    tabs = [FileUploadDialogTabs.GoogleSlides, FileUploadDialogTabs.FileStack],
    callout,
    error,
    clearError,
    ...rest
  } = props;

  const classes = useStyles(props);

  const {
    t,
    tab,
    setTab,
    googleSlideInputError,
    slideUrl,
    onSlideUrlChange,
    onUploadGoogleSlide,
    onFilestackFileUpload,
    filestackOptions,
  } = useFileUpload(props);

  return (
    <Dialog containerMaxWidth="sm" fullWidth {...rest}>
      {tabs && Array.isArray(tabs) && tabs.length > 1 && (
        <Tabs value={tab} onChange={setTab} variant="scrollable" disabled={loading}>
          <Tab disabled={loading} value={FileUploadDialogTabs.GoogleSlides} label={t('GoogleSlidesTab')} />
          <Tab disabled={loading} value={FileUploadDialogTabs.FileStack} label={t('FileStackTab')} />
        </Tabs>
      )}

      {callout || null}

      {tab === FileUploadDialogTabs.GoogleSlides && (
        <TabContent>
          <TextField
            label={t('GoogleSlidesInputLabel', 'Paste a Google Slides link')}
            variant="outlined"
            fullWidth
            autoFocus
            error={!!googleSlideInputError}
            helperText={googleSlideInputError || ''}
            value={slideUrl}
            onChange={onSlideUrlChange}
            disabled={loading}
          />

          <Button
            label={t('GoogleSlidesButton', 'UPLOAD LINK')}
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || !slideUrl || (!!googleSlideInputError && tab === FileUploadDialogTabs.GoogleSlides)}
            onClick={onUploadGoogleSlide}
            className={classes.uploadBtn}
            loading={loading}
          />
        </TabContent>
      )}

      {tab === FileUploadDialogTabs.FileStack && (
        <TabContent>
          <ReactFilestack
            apikey={filestackApiKey}
            options={filestackOptions}
            onSuccess={onFilestackFileUpload}
            onError={(err: Error) => {
              handleClientError(err, 'Failed uploading image to Filestack.');
            }}
            render={({ onPick }: Record<string, any>) => (
              <Button
                startIcon={<CloudUploadIcon />}
                label={t('FileStackButton')}
                onClick={onPick}
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                loading={loading}
              />
            )}
          />
        </TabContent>
      )}

      {!!error && (
        <CalloutMessageCollapse title={t('errorTitle')} message={error} type="error" onMessageDisappear={clearError} />
      )}
    </Dialog>
  );
};

export default FileUploadDialog;
