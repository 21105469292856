import React, { FC } from 'react';
import { IntegrationType, Marketer } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { TextDisplay } from '../../../../fe_common/client/components/display';
import ListItemNode from '../../../../fe_common/client/components/molecules/ListItemTreeView/ListItemNode';
import { svgs } from '../../../../fe_common/client/app/svg';

const useStyles = makeStyles(() => ({
  root: {},
  listItemNodeRoot: {
    marginLeft: 0,
  },
}));

interface DefaultIntegrationsProps {
  marketer: Marketer;
}

/**
 * Component will render the default integrations for the type DefaultIntegrations
 * Both for regular and manual integration
 * @param marketer
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultIntegrations: FC<DefaultIntegrationsProps> = ({ marketer }) => {
  const classes = useStyles();
  const { defaultIntegrations: di } = marketer;

  const { name: gaName, email: gaEmail } = marketer?.marketerProfile?.googleAnalyticsUserInfo || {};

  const [businessManager] = React.useState(() => {
    const integrationType = di?.facebookMarketingIntegration?.integrationType;

    if (integrationType === IntegrationType.FACEBOOK) {
      const bmId = di?.facebookMarketingIntegration?.defaultSettings?.facebookBusinessManagerId;
      const bms = di?.facebookMarketingIntegration?.facebookIntegrationUser?.administratedBusinessManagers || [];

      // @ts-ignore
      return bms.find(({ id }) => id === bmId) || null;
    }

    if (integrationType === IntegrationType.FACEBOOK_MANUAL) {
      const bmId = di?.facebookMarketingIntegration?.externalEntityId;

      return {
        name: 'Manually integrated business manager',
        displayImageUrl: svgs.facebookAds,
        id: bmId,
        uuid: bmId,
        linkUrl: '',
      };
    }

    return null;
  });
  const [managerAccount] = React.useState(() => {
    const integrationType = di?.googleAdWordsIntegration?.integrationType;

    if (integrationType === IntegrationType.GOOGLE_ADWORDS) {
      const maId = di?.googleAdWordsIntegration?.defaultSettings?.googleAdWordsManagerAccountId;
      const mas = di?.googleAdWordsIntegration?.googleAdWordsIntegrationUser?.administratedManagerAccounts || [];

      // @ts-ignore
      return mas.find(({ id }) => id === maId) || null;
    }

    if (integrationType === IntegrationType.GOOGLE_ADWORDS_MANUAL) {
      const maId = di?.googleAdWordsIntegration?.externalEntityId;

      return {
        name: 'Manually integrated manager account',
        displayImageUrl: svgs.googleAds,
        id: maId,
        uuid: maId,
        linkUrl: '',
      };
    }

    return null;
  });

  return (
    <List classes={{ root: classes.root }}>
      {businessManager && <ListItemNode data={businessManager} classes={{ root: classes.listItemNodeRoot }} />}
      {!businessManager && (
        <ListItem>
          <TextDisplay bold color="error">
            No default Facebook integration set.
          </TextDisplay>
        </ListItem>
      )}
      {managerAccount && <ListItemNode data={managerAccount} classes={{ root: classes.listItemNodeRoot }} />}
      {!managerAccount && (
        <ListItem>
          <TextDisplay bold color="error">
            No default GoogleAds integration set.
          </TextDisplay>
        </ListItem>
      )}
      {gaEmail && (
        <ListItem>
          <TextDisplay>
            Google Analytics user selected: {gaName} - Email: {gaEmail}
          </TextDisplay>
        </ListItem>
      )}
      {!gaEmail && (
        <ListItem>
          <TextDisplay bold color="error">
            No default Google Analytics user info set.
          </TextDisplay>
        </ListItem>
      )}
    </List>
  );
};

export default DefaultIntegrations;
