import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 24,
      marginTop: 0,
      marginBottom: 24,
    },
    propertyLabel: {
      flexBasis: 'auto',
    },
    propertyValue: {
      fontWeight: 400,
    },
    green: {
      color: colors.green_darkest,
    },
    red: {
      color: colors.red,
    },
  }),
);

export default useStyles;
