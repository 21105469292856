import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import CopyToClipboardButton from '../../../../../../../fe_common/client/components/atoms/CopyToClipboardButton';

const LinkToUserSignUp = (props) => {
  const { value, className } = props;

  return (
    <TextField
      variant="outlined"
      value={value}
      fullWidth
      className={className}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboardButton text={value} />
          </InputAdornment>
        ),
      }}
    />
  );
};

LinkToUserSignUp.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default LinkToUserSignUp;
