import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import { styleConstants } from '../../../app/style';

export function WizardDivider(props) {
  const { style } = props;

  return <Divider style={style} />;
}

WizardDivider.propTypes = {
  style: PropTypes.object,
};

WizardDivider.defaultProps = {
  style: {
    marginTop: styleConstants.standardPadding,
    marginBottom: styleConstants.standardPadding,
    backgroundColor: '#C2C2CB',
  },
};
