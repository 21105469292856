import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { WrappedFieldInputProps } from 'redux-form';
import {
  AdditionalService,
  AdditionalServicePackagesProfile,
  MarketingServiceType,
  NonPpcMarketingServiceType,
} from '@mayple/types';
import { v4 as uuidV4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { NonPPCPackagesQuery } from 'growl-graphql/dist/queries/admin/NonPPCPackagesQuery';

import { tryParseInt } from '../../../../../fe_common/client/services/utils';
import useDialog from '../../../../../fe_common/client/hooks/useDialog';

export const defaultPackage = {
  cost: 500,
  description: '',
  recurring: true,
  currency: 'USD',
  flavor: '',
  variableFactorTitle: '',
  variableFactorUnit: '',
  variableFactorCount: 0,
};

const useAdditionalServices = (input: WrappedFieldInputProps): Record<string, any> => {
  const { data } = useQuery(NonPPCPackagesQuery.query);
  const { open, openDialog, closeDialog } = useDialog(false);
  const packages: AdditionalServicePackagesProfile[] = useMemo(() => {
    const p: AdditionalServicePackagesProfile[] = data?.nonPPCPackages || [];
    return p.filter(({ serviceType }) => Boolean(serviceType));
  }, [data]);

  const onPackageSelect = useCallback(
    (newPackage: Partial<AdditionalService>, serviceType: MarketingServiceType) => {
      const { value: services, onChange } = input;
      const newServices = cloneDeep(services);
      const uuid = uuidV4().replace(/-/g, '');

      newServices[uuid] = {
        uuid,
        serviceType,
        ...newPackage,
        description: newPackage.description?.contents || '',
      };

      onChange(newServices);
      closeDialog();
    },
    [closeDialog, input],
  );

  const onServiceNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: services, onChange } = input;
      const { value, name: uuid } = e.target;
      const newServices = cloneDeep(services);

      newServices[uuid].alternativeName = value;

      onChange(newServices);
    },
    [input],
  );

  const onServiceCostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: services, onChange } = input;
      const { value, name: uuid } = e.target;
      const newServices = cloneDeep(services);

      if (+value > 1000000) {
        newServices[uuid].cost = 1000000;
      } else {
        newServices[uuid].cost = tryParseInt(value, '');
      }

      onChange(newServices);
    },
    [input],
  );

  const onServiceRecurringChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: services, onChange } = input;
      const { checked, name: uuid } = e.target;
      const newServices = cloneDeep(services);

      newServices[uuid].recurring = checked;

      onChange(newServices);
    },
    [input],
  );

  const onGeneralTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: services, onChange } = input;
      const { value, name } = e.target;
      const newServices = cloneDeep(services);
      const fieldKeys = name.split('|');
      const uuid = fieldKeys[0];
      const key = fieldKeys[1];

      newServices[uuid][key] = value;

      onChange(newServices);
    },
    [input],
  );

  const onDescriptionChange = useCallback(
    (value: string, uuid: string) => {
      const { value: services, onChange } = input;
      const newServices = cloneDeep(services);

      newServices[uuid].description = value;

      onChange(newServices);
    },
    [input],
  );

  const onDeleteService = useCallback(
    (uuid: string) => {
      const { value: services, onChange } = input;
      const newServices = cloneDeep(services);

      delete newServices[uuid];

      onChange(newServices);
    },
    [input],
  );

  return {
    selectorDialogOpen: open,
    packages,
    closeDialog,
    onCreateNewService: openDialog,
    onPackageSelect,
    onServiceNameChange,
    onServiceCostChange,
    onServiceRecurringChange,
    onGeneralTextChange,
    onDescriptionChange,
    onDeleteService,
  };
};

export default useAdditionalServices;

/**
 * Validate the entire input
 * @param value
 * @returns {boolean}
 */
export const validateAdditionalServices = (
  value: Record<string, AdditionalService>,
): Record<string, string> | boolean => {
  const errors: Record<string, string> = {};

  if (!value || Object.values(value).length === 0) {
    return false;
  }

  Object.values(value).forEach((service) => {
    const { cost, uuid } = service;

    if (!cost || cost < 0) {
      errors[uuid] = 'Please provide a price';
    }

    // if (!description) {
    //   errors[uuid] =
    //     `Please provide a detailed description for
    // ${MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP[serviceType]}`; }
  });

  return Object.keys(errors).length === 0 ? false : errors;
};

interface ServiceMetadata {
  isDefined: boolean;
  isDeprecated: boolean;
  packageName: string;
}

export const getServiceMetadata = (
  serviceType: NonPpcMarketingServiceType,
  flavor: string,
  packagesList: AdditionalServicePackagesProfile[],
): ServiceMetadata => {
  const defaultMetadata = {
    isDefined: false,
    isDeprecated: false,
    packageName: flavor,
  };

  if (!serviceType || !packagesList) {
    return defaultMetadata;
  }
  // Find the service in the defined packages list
  const service: AdditionalServicePackagesProfile | null =
    packagesList.find(({ serviceType: packageServiceType }) => packageServiceType === serviceType) || null;

  if (!service) {
    return defaultMetadata;
  }
  // Defined service have at least 1 package
  const isDefined = service?.packages?.length > 0;
  const packageName = (service?.packages?.find((p) => flavor === p?.flavor) || {}).name;
  // If did not found the package name, it means we have a deprecated flavor used.
  const isDeprecated = isDefined && !packageName;

  return {
    isDefined,
    isDeprecated,
    packageName: packageName || flavor,
  };
};
