import { useMemo } from 'react';
import { ExecutionResult } from 'graphql';
import { useQuery } from '@apollo/react-hooks';
import { EntityOperationResponse } from '@mayple/types';
import { IsCurrentProjectStageCanBeSkippedQuery } from 'growl-graphql/dist/queries/projectStage/IsCurrentProjectStageCanBeSkippedQuery';
import { SkipToNextProjectStageMutation } from 'growl-graphql/dist/mutations/projectStage/SkipToNextProjectStageMutation';

import { useMutation } from '../../../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../../../fe_common/client/services/logger';

type SkipToNextProjectStageExecutionResult = ExecutionResult<{
  skipToNextProjectStage: EntityOperationResponse;
}>;

type ProjectStageData = {
  isCurrentProjectStageCanBeSkipped: boolean;
  skipToNextProjectStageHandler: (onSuccess: () => Promise<void>) => Promise<void>;
};

const useProjectStageData = (projectId: number | null | undefined): ProjectStageData => {
  const variables = { projectId };

  const { data, loading, error, refetch } = useQuery(IsCurrentProjectStageCanBeSkippedQuery.query, {
    variables,
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  const { mutate: skipToNextProjectStage } = useMutation(SkipToNextProjectStageMutation, {
    mutationOptions: {
      refetchQueries: ['isCurrentProjectStageCanBeSkipped'],
    },
  });

  const isCurrentProjectStageCanBeSkipped = useMemo(
    () => (!loading && !error && data?.isCurrentProjectStageCanBeSkipped) || false,
    [data?.isCurrentProjectStageCanBeSkipped, error, loading],
  );

  const skipToNextProjectStageHandler = async (onSuccess: () => Promise<void>) => {
    try {
      const result: SkipToNextProjectStageExecutionResult = await skipToNextProjectStage({ variables });

      if (result?.data?.skipToNextProjectStage?.success) {
        await refetch?.();
        await onSuccess?.();
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(result?.data?.skipToNextProjectStage?.result);
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to move project to next stage.');
    }
  };

  return {
    isCurrentProjectStageCanBeSkipped,
    skipToNextProjectStageHandler,
  };
};

export default useProjectStageData;
