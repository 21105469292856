import React, { useState } from 'react';
import { ExecutionResult } from 'graphql';
import { EntityOperationResponse } from '@mayple/types';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { ReportMailchimpHealthCheckProjectScopeChangeMutation } from 'growl-graphql/dist/mutations/admin/ReportMailchimpHealthCheckProjectScopeChangeMutation';

import { mailchimpHealthCheckScopeChangeOptions, saveErrorMessage } from './consts';
import { ProjectHealthCheckStopReasonFormProps } from './types';

import { useMutation } from '../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../fe_common/client/services/logger';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import QueryErrorMessage from '../../../../../fe_common/client/components/common/QueryErrorMessage';
import CalloutMessageCollapse from '../../../../../fe_common/client/components/common/CalloutMessageCollapse';

import { useFormStyles } from './style';

const ProjectHealthCheckStopReasonForm: React.FC<ProjectHealthCheckStopReasonFormProps> = (props) => {
  const classes = useFormStyles();
  const { projectId, onCancel, onSuccess } = props;

  const formName = 'projectHealthCheckStopReasonForm';

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    mutate: reportMailchimpHealthCheckProjectScopeChange,
    loading,
    error,
  } = useMutation(ReportMailchimpHealthCheckProjectScopeChangeMutation);

  const onSubmitHandler: FormSubmitHandler = async (formValues) => {
    try {
      const variables = { projectId, ...formValues };
      const result: ExecutionResult<{ reportMailchimpHealthCheckProjectScopeChange: EntityOperationResponse }> =
        await reportMailchimpHealthCheckProjectScopeChange({ variables });

      if (result?.data?.reportMailchimpHealthCheckProjectScopeChange?.success) {
        setErrorMessage(undefined);
        onSuccess?.();
      } else {
        setErrorMessage(saveErrorMessage);
        // noinspection ExceptionCaughtLocallyJS
        throw new Error();
      }
    } catch (e) {
      handleClientError(e, `Error while trying to update packages for project ${projectId}`);
    }
  };

  return (
    <div className={classes.root}>
      <TextDisplay gutterBottom>
        The system have detected that you are trying to stop a Health Check project with packages that are not marked as
        Completed or Canceled, and will handle it for you.
      </TextDisplay>
      <TextDisplay>Please select the reason which suites most:</TextDisplay>

      {error && <QueryErrorMessage error={error} message={saveErrorMessage} />}
      {errorMessage && <CalloutMessageCollapse type="error" title="Error" message={errorMessage} timeToShow={4} />}
      <WizardForm
        form={formName}
        onSubmit={onSubmitHandler}
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        steps={(formProps) => {
          const { submit } = formProps;
          return (
            <>
              <div className={classes.formContainer}>
                <WizardStepField
                  type={WizardStepField.types.RADIO}
                  name="scopeChangeType"
                  // @ts-ignore
                  isRequired
                  label="Stop Reason"
                  color="primary"
                  layout="vertical"
                  enums={mailchimpHealthCheckScopeChangeOptions}
                  autoFocus
                />
              </div>

              <div className={classes.buttonContainer}>
                <Button onClick={onCancel} label="Cancel" />
                <Button
                  type="submit"
                  label="Submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                  disabled={loading}
                  onClick={submit}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default ProjectHealthCheckStopReasonForm;
