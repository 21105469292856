import React from 'react';
import PropTypes from 'prop-types';
import ReactFilestack from 'filestack-react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Button } from '../../../inputs';
import { TextDisplay } from '../../../display';
import { clientLogger, handleClientError } from '../../../../services/logger';
import { FILE_STACK_DEFAULT_SOURCES } from '../../../../app/consts';
import FilesList from '../../../molecules/FilesList';

const useStyles = makeStyles(() => ({
  root: {
    flex: '0 1 auto',
    textAlign: 'initial',
  },
  fileUploadButton: {
    margin: 0,
    padding: 8,
  },
  maxFileSizeLabel: {
    marginLeft: 18,
  },
}));

const defaultAcceptedMimeTypes = [
  '.pdf',
  '.csv',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  'image/*',
  'image/svg+xml',
  'video/*',
  'text/plain',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats*',
  'application/vnd.ms*',
  'application/msword',
  'application/vnd.oasis.opendocument*',
  'application/postscript',
  'application/rtf',
  'application/zip',
  'application/x-rar-compressed',
];

function FilesUpload(props) {
  // console.log('FilesUpload props', props);

  const classes = useStyles(props);

  const files = props.input?.value || [];

  const {
    input,
    icon,
    accept,
    tooltipText,
    optionalText,
    maxSize,
    apikey,
    buttonProps,
    onSuccess,
    hideMaxFileSize,
    showFilesUploadedList,
    fileOnDeleteHandler,
  } = props;

  const { onChange } = input;

  const options = {
    accept,
    fromSources: FILE_STACK_DEFAULT_SOURCES,
    lang: 'en',
    maxFiles: 1,
    minFiles: 1,
    maxSize,
  };

  const onSuccessHandler = (result) => {
    clientLogger.debug(`FilePicker upload response:\n\n${JSON.stringify(result, null, 2)}`);
    const { filesUploaded } = result;
    const newFiles = [...files, ...filesUploaded];

    if (onChange) {
      // updating outer state
      onChange(newFiles);
    }

    // Returning only the list of uploaded files to onSuccess
    onSuccess(filesUploaded);
  };

  const onErrorHandler = (err) => {
    // we need to do more than that
    handleClientError(err, 'Failed uploading file to Filestack.');
  };

  return (
    <div className={classes.root}>
      <ReactFilestack
        apikey={apikey}
        options={options}
        onSuccess={onSuccessHandler}
        onError={onErrorHandler}
        render={({ onPick }) => (
          <div>
            <Tooltip title={tooltipText} placement="top">
              <div>
                <Button
                  startIcon={icon}
                  label={optionalText}
                  onClick={onPick}
                  className={classes.fileUploadButton}
                  {...buttonProps}
                />
              </div>
            </Tooltip>
            {!hideMaxFileSize && (
              <TextDisplay variant="caption" className={classes.maxFileSizeLabel}>
                Max {Math.round(maxSize / 1000000)} MB.
              </TextDisplay>
            )}
            {showFilesUploadedList && (
              <FilesList files={files} showFileSize fileOnDelete={fileOnDeleteHandler} allowDownload={false} />
            )}
          </div>
        )}
      />
    </div>
  );
}

FilesUpload.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.object,
  apikey: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSuccess: PropTypes.func,
  maxSize: PropTypes.number,
  icon: PropTypes.node,
  tooltipText: PropTypes.string,
  optionalText: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
  buttonProps: PropTypes.object,
  hideMaxFileSize: PropTypes.bool,
  showFilesUploadedList: PropTypes.bool,
  fileOnDeleteHandler: PropTypes.func,
};

FilesUpload.defaultProps = {
  input: {
    name: '',
    value: [],
    onChange: () => {},
  },
  meta: {},
  label: '',
  disabled: false,
  isRequired: false,
  icon: <CloudUploadIcon />,
  tooltipText: 'Upload File / Select File from Cloud',
  optionalText: '',
  onSuccess: () => {},
  maxSize: 5 * 1024 * 1024, // 5 MB
  accept: defaultAcceptedMimeTypes,
  buttonProps: {},
  hideMaxFileSize: false,
  showFilesUploadedList: false,
  fileOnDeleteHandler: () => {},
};

export default FilesUpload;
