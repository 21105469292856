import React from 'react';
import { FilterRender } from 'react-table-6';
import { ProjectSpecialType } from '@mayple/types';

export const ProjectSpecialTypeFilter: FilterRender = (props) => {
  const { filter, onChange } = props;

  const projectSpecialType = Object.entries(ProjectSpecialType);

  return (
    <select
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      {projectSpecialType.map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default ProjectSpecialTypeFilter;
