import React, { FC } from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

type SwitchFieldProps = {
  checked: boolean;
  name?: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  error?: boolean;
  classes?: Record<string, string>;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

const SwitchField: FC<SwitchFieldProps> = (props) => {
  const { checked, name, label, helperText, onChange, disabled = false, error = false } = props;

  const classes = useStyles(props);

  return (
    <FormGroup classes={{ root: classes.root }}>
      <FormControlLabel
        checked={checked}
        disabled={disabled}
        control={<Switch name={name} color="primary" onChange={onChange} />}
        label={label}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormGroup>
  );
};

export default SwitchField;
