import { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { UpdateProjectAutomaticConditionalStoppingMutation } from 'growl-graphql/dist/mutations/project/UpdateProjectAutomaticConditionalStoppingMutation';
import { ProjectStopConditionsQuery } from 'growl-graphql/dist/queries/project/ProjectStopConditionsQuery';

import { ProjectStopConditionsData } from './types';

import { useMutation } from '../../../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../../../fe_common/client/services/logger';

const useProjectStopConditions = (projectId: number | null | undefined): ProjectStopConditionsData => {
  const { data, loading, error, refetch } = useQuery(ProjectStopConditionsQuery.query, {
    variables: { projectId },
    skip: !projectId,
  });

  const {
    mutate: updateProjectAutomaticConditionalStopping,
    loading: saving,
    error: errorSaving,
  } = useMutation(UpdateProjectAutomaticConditionalStoppingMutation);

  const onAllowAutomaticConditionalStoppingChangeHandler = useCallback(async () => {
    const newValue = !data?.project?.allowAutomaticConditionalStopping;

    const variables = {
      projectId,
      allowAutomaticConditionalStopping: newValue,
    };

    try {
      await updateProjectAutomaticConditionalStopping({ variables });
      await refetch();
    } catch (e) {
      handleClientError(e, 'Error while trying to update ProjectAutomaticConditionalStopping');
    }
  }, [data?.project?.allowAutomaticConditionalStopping, projectId, refetch, updateProjectAutomaticConditionalStopping]);

  return {
    loading,
    saving,
    error: error || errorSaving,
    project: data?.project,
    refetch,
    onAllowAutomaticConditionalStoppingChangeHandler,
  };
};

export default useProjectStopConditions;
