import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Step, Steps } from 'react-albus';

import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { muiIcons } from '../../../../../fe_common/client/app/icons';
import { ConfirmDialog } from '../../../../../fe_common/client/components/dialogs/ConfirmDialog';

export function WizardSteps(props) {
  const {
    invalid,
    submit,
    submitting,
    currency,
    confirmMessage,
    onShowConfirmation,
    showConfirmationDialog,
    closeConfirmationDialog,
  } = props;

  return (
    <Steps>
      <Step
        id="marketerUpdateStep"
        render={() => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <WizardStepField
                  type={WizardStepField.types.CURRENCY}
                  name="cashAmountPaid"
                  wizardFieldLabel="Cash amount that was paid"
                  required
                  minimumValue={0}
                  maximumValue={100000}
                  hideCurrencySelect={!!currency}
                  currency={currency}
                />
              </Grid>

              <Grid item xs={12}>
                <WizardStepField
                  type={WizardStepField.types.CURRENCY}
                  name="verificationAmount"
                  wizardFieldLabel="Enter cash amount again to verify"
                  helperText="Enter a whole number in the account currency"
                  required
                  minimumValue={0}
                  maximumValue={100000}
                  hideCurrencySelect={!!currency}
                  currency={currency}
                />
              </Grid>

              <Grid item xs={12}>
                <WizardStepField
                  type={WizardStepField.types.TEXT}
                  name="paymentLocation"
                  wizardFieldLabel="Where (in what location) did you get paid with cash?"
                  helperText="Examples: Mayple office, Avatrade office, etc"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <WizardStepField
                  type={WizardStepField.types.DATE_PICKER}
                  name="paymentDate"
                  wizardFieldLabel="When did you get paid with cash?"
                  helperText="Include time if possible. Example: Jan 25th, 2018 at 13:00, 2017-12-11 09:30am, etc"
                  views={['date', 'month', 'year']}
                  openTo="date"
                  dateFormat="LLL"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <WizardStepField
                  type={WizardStepField.types.TEXT}
                  name="description"
                  wizardFieldLabel="Please enter description for the cash payment reason"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  label="Report Cash Payment"
                  type="submit"
                  startIcon={muiIcons.atm}
                  color="secondary"
                  variant="contained"
                  onClick={onShowConfirmation}
                  disabled={invalid || submitting}
                  style={{ float: 'right' }}
                />
              </Grid>
            </Grid>
            {showConfirmationDialog && (
              <ConfirmDialog
                open={showConfirmationDialog}
                onCancel={closeConfirmationDialog}
                onConfirm={submit}
                content={confirmMessage}
                title="Report cash payment"
              />
            )}
          </>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  invalid: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  confirmMessage: PropTypes.node.isRequired,
  onShowConfirmation: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  closeConfirmationDialog: PropTypes.func.isRequired,
};
