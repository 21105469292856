import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import DivestFundsForm from './DivestFundsForm';
import useControlledAccordion from '../../../../hooks/useControlledAccordion';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

function DivestFunds({ company, onSuccess }) {
  const { expandAccordion, accordionOnChangeHandler, onSuccessHandler } = useControlledAccordion(onSuccess);

  return (
    <Section
      title="Divest Funds"
      subTitle={
        'Divest existing funds from a company. These funds are taken away from the company, ' +
        'perhaps to undo a promotion or an incorrectly calculated payment. ' +
        'These funds are no longer available to the company.'
      }
      layout={SECTION_LAYOUT.TWO_COLUMNS}
    >
      <Accordion expanded={expandAccordion} onChange={accordionOnChangeHandler}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay>Click here to divest funds</TextDisplay>
        </AccordionSummary>
        <AccordionDetails>
          <DivestFundsForm company={company} onSuccess={onSuccessHandler} />
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}

DivestFunds.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

DivestFunds.defaultProps = {
  userType: 'NORMAL_USER',
};

export default DivestFunds;
