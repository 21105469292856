import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    approveMarketingPlanButton: {
      display: 'block',
      marginTop: 32,
      marginBottom: 32,
    },
  })
);

export default useStyles;
