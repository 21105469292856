import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { getMarketerLocationPreference, getProjectBriefProperties } from './logic';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { PropertiesList } from '../../../../../../../fe_common/client/components/display';

import { styles } from './style';

export function getBooleanAnswer(param) {
  return <strong className={param ? 'green-text' : 'red-text'}>{param ? 'Yes' : 'No'}</strong>;
}

const ProjectBriefRequests = (props) => {
  const { project = {}, isProjectLead, classes } = props;

  const projectBriefProperties = getProjectBriefProperties(isProjectLead, classes);
  const marketerLocationPreference = getMarketerLocationPreference(project);

  const data = {
    ...project,
    marketerLocationPreference,
  };

  return (
    <Section
      title="Project Requests"
      subTitle="Requests filled in by the customer in the welcome brief"
      noBorder
      classes={{ root: classes.root }}
    >
      <SectionContent>
        {!project && <div> Could not get project data. </div>}
        {project && (
          <PropertiesList
            id="projectBriefData"
            data={data}
            propertiesMeta={projectBriefProperties}
            classes={{
              propertyLabel: classes.propertyLabel,
              propertyValue: classes.propertyValue,
            }}
          />
        )}
      </SectionContent>
    </Section>
  );
};

ProjectBriefRequests.propTypes = {
  project: PropTypes.object.isRequired,
  isProjectLead: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

ProjectBriefRequests.defaultProps = {
  isProjectLead: false,
};

export default withStyles(styles)(ProjectBriefRequests);
