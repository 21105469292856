import { useQuery } from '@apollo/react-hooks';
import { Project } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';

export interface FullProjectData {
  loading: boolean;
  error: ApolloError | undefined;
  project: Project;
  refetch: (variables?: { projectId: number }) => Promise<ApolloQueryResult<any>>;
}

const useFullProjectData = (projectId: number | null | undefined): FullProjectData => {
  const { loading, error, data, refetch } = useQuery(FullProjectByIdQuery.query, {
    variables: { projectId },
    skip: !projectId,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error,
    project: data?.project || ({} as Project),
    refetch,
  };
};

export default useFullProjectData;
