import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketingAccountStatusIcon from './MarketingAccountStatusIcon';
import { getMarketingAccountsByType, getNoAccountsTextByType, MARKETING_ACCOUNT_TYPE } from './logic';

import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import CopyToClipboardButton from '../../../../../../fe_common/client/components/atoms/CopyToClipboardButton';
import ExternalLink from '../../../../../../fe_common/client/components/display/ExternalLink';

const styles = {
  root: {},
};

const MarketingAccountsDetails = ({ project, marketingAccountType, classes }) => {
  const noAccountsText = getNoAccountsTextByType(marketingAccountType);
  const marketingAccounts = getMarketingAccountsByType(marketingAccountType, project);
  const {
    FbAccountId: fbAccountId,
    GaAccountId: gaAccountId,
    FbAccountAccessSettings,
    GaAccountAccessSettings,
  } = marketingAccounts;

  const fbAccountStatus = FbAccountAccessSettings?.lifecycleStatus || '';
  const fbAdAccountText = FbAccountAccessSettings?.adAccountText || '';
  const fbErrorString = FbAccountAccessSettings?.errorString || '';

  const gaAccountStatus = GaAccountAccessSettings?.lifecycleStatus || '';
  const gaAdAccountText = GaAccountAccessSettings?.adAccountText || '';
  const gaErrorString = GaAccountAccessSettings?.errorString || '';

  return (
    <div className={classes.root}>
      {!gaAccountId && !fbAccountId && (
        <TextDisplay variant="subtitle1">
          <strong>{noAccountsText}</strong>
        </TextDisplay>
      )}
      <TextDisplay variant="subtitle1">
        {fbAccountStatus !== '' && <MarketingAccountStatusIcon status={fbAccountStatus} />}
        {fbAccountStatus !== '' && `Facebook status: ${fbAccountStatus}. `}
        {!fbAccountId && `${fbAdAccountText} `}
        {!!fbAccountId && (
          <>
            Facebook ad account id:{' '}
            <ExternalLink href={`https://wwww.facebook.com/adsmanager/manage/campaigns?${fbAccountId}`}>
              <strong>{fbAccountId}</strong>
            </ExternalLink>
          </>
        )}
        {!!fbAccountId && <CopyToClipboardButton text={fbAccountId} />}
        {fbErrorString !== '' && <span className={classes.errorMessage}> - {fbErrorString}</span>}
      </TextDisplay>

      <TextDisplay variant="subtitle1">
        {gaAccountStatus !== '' && <MarketingAccountStatusIcon status={gaAccountStatus} />}
        {gaAccountStatus !== '' && `Google Ads status: ${gaAccountStatus}. `}
        {!gaAccountId && `${gaAdAccountText} `}
        {!!gaAccountId && (
          <>
            Google Ads client account id:{' '}
            <ExternalLink href={`https://adwords.google.com/aw/overview?__e=${gaAccountId}`}>
              <strong>{gaAccountId}</strong>
            </ExternalLink>
          </>
        )}
        {!!gaAccountId && <CopyToClipboardButton text={gaAccountId} />}
        {gaErrorString !== '' && (
          <TextDisplay inline color="error">
            {' '}
            - {gaErrorString}
          </TextDisplay>
        )}
      </TextDisplay>
    </div>
  );
};

MarketingAccountsDetails.propTypes = {
  project: PropTypes.object.isRequired,
  marketingAccountType: PropTypes.oneOf(Object.keys(MARKETING_ACCOUNT_TYPE)).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketingAccountsDetails);
