import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import {
  validateMinimumSelectedValues,
  validateMaximumSelectedValues,
  validateNonEmptyArray,
} from '../../../../services/validation';
import EntityAvatarCheckboxes from '../../EntityAvatarCheckboxes';
import { EntityAvatarProps } from '../../EntityAvatar';

interface WizardEntityAvatarCheckboxFieldProps {
  name: string;
  data: any;
  dataToEntityMapper: (dataRow: unknown) => Partial<EntityAvatarProps>;
  isRequired: boolean;
  emptyArrayMessage: string;
  minimumSelectedValues: number;
  maximumSelectedValues: number;
}

const WizardEntityAvatarCheckboxField: React.FC<WizardEntityAvatarCheckboxFieldProps> = (props) => {
  const {
    name,
    data,
    dataToEntityMapper,
    isRequired = false,
    emptyArrayMessage,
    minimumSelectedValues = -1,
    maximumSelectedValues = -1,
    ...rest
  } = props;

  const validator = useMemo(() => {
    const validations = [];
    if (isRequired) {
      validations.push(validateNonEmptyArray(emptyArrayMessage));
    }

    if (minimumSelectedValues > -1) {
      validations.push((value: any) => validateMinimumSelectedValues(value, minimumSelectedValues));
    }

    if (maximumSelectedValues > -1) {
      validations.push((value: any) => validateMaximumSelectedValues(value, maximumSelectedValues));
    }

    return validations;
  }, [isRequired, minimumSelectedValues, maximumSelectedValues, emptyArrayMessage]);

  return (
    <Field
      component={EntityAvatarCheckboxes}
      name={name}
      data={data}
      dataToEntityMapper={dataToEntityMapper}
      validate={validator}
      {...rest}
    />
  );
};

export default WizardEntityAvatarCheckboxField;
