import React from 'react';
import PropTypes from 'prop-types';
import TextFieldMUI from '@material-ui/core/TextField';

function TextField({ label, input, meta: { touched, invalid, error }, ...rest }) {
  return <TextFieldMUI
    label={label}
    placeholder={label}
    error={touched && invalid}
    {...input}
    {...rest}
    helperText={(touched && error) || rest.helperText || ''}
  />
}

TextField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default TextField;
