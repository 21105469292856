import React from 'react';
import { FundAccount, Project, ProjectCycle, ProjectLifeCycleStatus } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CyclesSideBySide from './CyclesSideBySide';

interface ProjectCycleDetailsNewProps {
  project: Project;
  cycles: ProjectCycle[];
  showActual: boolean;
  fundAccount?: FundAccount;
}

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProjectCycleDetailsNew: React.FC<ProjectCycleDetailsNewProps> = ({
  cycles,
  project,
  showActual,
  fundAccount,
}) => {
  const classes = useStyles();

  const {
    currentCycle: currentCycleTemp,
    nextCycle: nextCycleTemp,
    currency,
    projectLifeCycleStatus,
    id: projectId,
    automaticRenewal,
  } = project;

  const nextCycleId = automaticRenewal ? nextCycleTemp?.id || null : null;
  const currentCycleId = currentCycleTemp?.id || null;

  const currentCycle = (cycles || []).find(({ id }) => id === currentCycleId);
  const nextCycle = (cycles || []).find(({ id }) => {
    if (projectLifeCycleStatus === ProjectLifeCycleStatus.FINISHED) {
      return false;
    }

    return id === nextCycleId;
  });

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <CyclesSideBySide
          projectId={projectId}
          // @ts-ignore
          currentCycle={currentCycle}
          nextCycle={nextCycle}
          currency={currency}
          showActual={showActual}
          projectLifeCycleStatus={projectLifeCycleStatus}
          fundAccount={fundAccount}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectCycleDetailsNew;
