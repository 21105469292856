import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import withStyles from '@material-ui/core/styles/withStyles';

import { defaultFormatter } from './logic';
import CollapsibleTable from './CollapsibleTable';

import { collapsibleRowStyles } from './style';

function CollapsibleRow({ tableName, columns, collapsedColumns, row, classes }) {
  const [open, setOpen] = React.useState(false);

  const onClickOpenRowHandler = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow classes={{ root: classes.tableRow }}>
        <TableCell classes={{ root: classes.tableCell }}>
          <IconButton aria-label="expand row" size="small" onClick={onClickOpenRowHandler}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(({ field, formatter = defaultFormatter, className }) => {
          let extraClassNames = className;
          if (typeof className === 'function') {
            extraClassNames = className(row[field], row);
          }

          return (
            <TableCell
              key={`SimpleDataGrid-${tableName}-cell-${row.id}-${field}`}
              className={extraClassNames}
              classes={{ root: classes.tableCell }}
            >
              {formatter(row[field], row)}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <CollapsibleTable
            columns={collapsedColumns}
            rows={[row]}
            open={open}
            classes={{
              root: classes.collapsibleTableRoot,
              tableContainer: classes.collapsibleTableContainer,
              tableHead: classes.collapsibleTableHead,
              tableHeadRow: classes.collapsibleTableHeadRow,
              tableHeadCell: classes.collapsibleTableHeadCell,
              tableBody: classes.collapsibleTableBody,
              tableRow: classes.collapsibleTableRow,
              tableEmptyRow: classes.collapsibleTableEmptyRow,
              tableCell: classes.collapsibleTableCell,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleRow.propTypes = {
  tableName: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  collapsedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(collapsibleRowStyles)(CollapsibleRow);
