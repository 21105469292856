/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import ReactFilestack from 'filestack-react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { TextIcon } from '../../TextIcon';
import { iconCodes } from '../../../../app/icons';
import { handleClientError } from '../../../../services/logger';
import { ReduxFormValue } from '../../ReduxFormValue';
import { styleConstants } from '../../../../app/style';
import { Button } from '../../../inputs';
import { svgs } from '../../../../app/svg';
import { colors } from '../../../../app/theme';
import { FILE_STACK_DEFAULT_SOURCES } from '../../../../app/consts';

export function WizardImagePickerInput(props) {
  // console.log('WizardImagePickerInput props', props);

  const { name, dispatch, form, largePicker, currentValue, apikey } = props;

  return (
    <div style={{ flex: '0 1 auto', padding: styleConstants.halfPadding, textAlign: 'initial' }}>
      {!largePicker && <ReduxFormValue form={form} valueName={name} isAvatar avatarSize={64} />}
      <ReactFilestack
        apikey={apikey}
        options={{
          accept: 'image/*',
          fromSources: FILE_STACK_DEFAULT_SOURCES,
          lang: 'en',
          maxFiles: 1,
          minFiles: 1,
          maxSize: 10 * 1024 * 1024,
        }}
        onSuccess={(result) => {
          // console.log('Filepicker upload complete:', JSON.stringify(result));
          dispatch(change(form, name, result.filesUploaded[0].url));
        }}
        onError={(err) => {
          handleClientError(err, 'Failed uploading image to Filestack.');
        }}
        render={({ onPick }) => {
          if (largePicker) {
            const url = currentValue || svgs.cloud_download_blue;
            const pickerStyle = {
              border: `1px dashed ${colors.primary}`,
              borderRadius: 8,
              width: 300,
              height: 300,
              marginBottom: 16,
              position: 'relative',
              textAlign: 'center',
              background: `url(${url}) no-repeat`,
              backgroundSize: currentValue ? 'cover' : '',
              backgroundPosition: 'center',
            };
            return (
              <div style={pickerStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPick}
                  label={currentValue ? 'RETAKE' : 'CLICK TO UPLOAD'}
                  style={{ bottom: -278 }}
                />
              </div>
            );
          } else {
            return (
              <Tooltip title="Upload Image / Select Image from Cloud">
                <IconButton onClick={onPick}>
                  <TextIcon icon={iconCodes.upload} primary />
                </IconButton>
              </Tooltip>
            );
          }
        }}
      />
    </div>
  );
}

WizardImagePickerInput.propTypes = {
  name: PropTypes.string.isRequired,
  apikey: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  largePicker: PropTypes.bool,
  // isRequired: PropTypes.bool,
};

WizardImagePickerInput.defaultProps = {
  isRequired: false,
  largePicker: false,
  currentValue: '',
};
