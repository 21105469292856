import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CopyHtmlElementToClipboardButton from '../../../../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface ProjectHeaderCopyToSlackProps {
  projectId: number;
  classes?: Record<string, string>;
}

const ProjectHeaderCopyToSlack: FC<ProjectHeaderCopyToSlackProps> = (props) => {
  const classes = useStyles(props);

  return (
    <CopyHtmlElementToClipboardButton
      className={classes.root}
      querySelector="#project-header"
      label="Copy to Slack"
      size="small"
    />
  );
};

export default ProjectHeaderCopyToSlack;
