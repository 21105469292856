import React, { useCallback, useState } from 'react';
import { FilterBy, FilterByOperator, MarketerMatchOverride, ProjectUpdateInput } from '@mayple/types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import {
  getMarketerMatchOverrideCopy,
  getUserConfirmationToOverrideMarketer,
  validateMarketerMatchesOverride,
} from '../logic';
import { AddMarketerMatchOverrideFormProps } from '../types';

import { ENTITY_TYPE } from '../../../../../app/consts';

import EntitySearchAutoComplete, {
  AutoCompleteOptionType,
} from '../../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import { clientLogger } from '../../../../../../fe_common/client/services/logger';
import { EntityOperationResponseExecutionResult } from '../../../../../../fe_common/client/app/types';

import useStyles from '../style';

const AddMarketerMatchOverrideForm: React.FC<AddMarketerMatchOverrideFormProps> = (props) => {
  const classes = useStyles(props);

  const { loading, viewer, project, updateProjectMutate, onAddMarketerMatchOverride } = props;
  const { id: projectId } = project;

  const [overrideMarketerId, setOverrideMarketerId] = useState<number | string>('');
  const [overridePosition, setOverridePosition] = useState<string | number>('');
  const [overrideReason, setOverrideReason] = useState<string>('');

  const { emailAddress } = viewer;
  const shouldDisable = +overrideMarketerId < 1 || +overridePosition < 1 || +overridePosition > 20 || !overrideReason;

  const queryFilterBy: FilterBy[] = [
    {
      key: 'isVetted',
      value: '1',
      operator: FilterByOperator.EQUALS,
    },
  ];

  const onOverridePositionChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string | number = event.target.value || '';
    setOverridePosition(newValue);
  }, []);

  const onOverrideReasonChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string | number = event.target.value || '';
    setOverrideReason(newValue);
  }, []);

  const onMarketerSelectedHandler: (newValue: AutoCompleteOptionType | null) => void = (selectedMarketer) => {
    if (selectedMarketer?.value) {
      setOverrideMarketerId(selectedMarketer.value);
    } else {
      setOverrideMarketerId('');
    }
  };

  const handleOverride = async (): Promise<void> => {
    const marketerMatchesOverrideOriginal = project?.marketerMatchesOverride || ([] as MarketerMatchOverride[]);

    if (!getUserConfirmationToOverrideMarketer(projectId, overrideMarketerId, overridePosition)) {
      return;
    }

    if (!validateMarketerMatchesOverride(marketerMatchesOverrideOriginal, overrideMarketerId, overridePosition)) {
      return;
    }

    // get a clean copy of marketerMatchesOverride
    const marketerMatchesOverride: MarketerMatchOverride[] = getMarketerMatchOverrideCopy(
      marketerMatchesOverrideOriginal,
    );

    if (overrideMarketerId && overridePosition && emailAddress) {
      // And add the new match override
      marketerMatchesOverride.push({
        marketerId: overrideMarketerId as number,
        position: overridePosition as number,
        overriddenBy: emailAddress,
        overrideReason,
      });

      const variables: { projectId: number; projectUpdate: ProjectUpdateInput } = {
        projectId,
        projectUpdate: {
          marketerMatchesOverride,
        },
      };

      try {
        const response: EntityOperationResponseExecutionResult<'updateProjectWithOperationResult'> =
          await updateProjectMutate({
            variables,
          });

        clientLogger.debug(`Response from server:\n\n${JSON.stringify(response, null, 2)}`);
      } catch (e) {
        clientLogger.error(e);
      } finally {
        setOverrideMarketerId('');
        setOverridePosition('');
        setOverrideReason('');

        await onAddMarketerMatchOverride();
      }
    }
  };

  return (
    <div>
      Override a marketer match position Minimum position: 1 <br />
      Maximum position: 20 <br />
      Current editor: {emailAddress}
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <EntitySearchAutoComplete
            entityType={ENTITY_TYPE.MARKETER}
            helperText="search for a marketer you want to select for the client to review and approve"
            onSelect={onMarketerSelectedHandler}
            queryFilterBy={queryFilterBy}
            filterBy={({ isVetted }) => isVetted}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Marketer position*"
            value={overridePosition}
            onChange={onOverridePositionChangeHandler}
            margin="normal"
            variant="outlined"
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <p>
            Please fill a detailed reason. why did you override ? what was wrong with the matching algorithm ? We need
            these details to investigate what did not work properly and to improve the algorithm.
          </p>
          <TextField
            label="Override reason*"
            value={overrideReason}
            onChange={onOverrideReasonChangeHandler}
            margin="normal"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Button
        label="Override"
        variant="contained"
        color="primary"
        onClick={handleOverride}
        disabled={shouldDisable || loading}
      />
    </div>
  );
};

export default AddMarketerMatchOverrideForm;
