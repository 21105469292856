import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { PhoneNumberInput2 } from '../../Registration/PhoneNumberInput2';
import { validateRequired, validatePhoneNumber } from '../../../../services/validation';

export function WizardPhoneNumberInput2(props) {
  const { name, placeholder, isRequired, ...rest } = props;

  const validator = isRequired ? [validateRequired, validatePhoneNumber] : [validatePhoneNumber];

  return (
    <div>
      <Field
        name={name}
        component={PhoneNumberInput2}
        isRequired={isRequired}
        validate={validator}
        placeholder={placeholder}
        withRef
        {...rest}
      />
    </div>
  );
}

WizardPhoneNumberInput2.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
};

WizardPhoneNumberInput2.defaultProps = {
  isRequired: false,
  placeholder: '',
};
