import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
// @ts-ignore
import ReactFilestack from 'filestack-react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { TextIcon } from '../../TextIcon';
import { iconCodes } from '../../../../app/icons';
import { handleClientError } from '../../../../services/logger';
import { Button } from '../../../inputs';
import { svgs } from '../../../../app/svg';
import { FILE_STACK_DEFAULT_SOURCES } from '../../../../app/consts';

import useStyles from './style';

const defaultFileSize = 10 * 1024 * 1024; // 10MB

interface ImagePickerProps extends WrappedFieldProps {
  apikey: string;
  size: 'small' | 'large';
  maxFileSize: number;
}

const ImagePicker: FC<ImagePickerProps> = (props) => {
  // console.log('ImagePicker props', props);
  const classes = useStyles(props);
  const { input, size = 'large', apikey } = props;

  const { value, onChange } = input;

  const largePicker = size === 'large';

  return (
    <div className={classes.root}>
      {!largePicker && <Avatar src={value} className={classes.smallAvatar} />}

      <ReactFilestack
        apikey={apikey}
        options={{
          accept: 'image/*',
          fromSources: FILE_STACK_DEFAULT_SOURCES,
          lang: 'en',
          maxFiles: 1,
          minFiles: 1,
          maxSize: defaultFileSize,
        }}
        onSuccess={(result: { filesUploaded: Array<{ url: string }> }) => {
          // console.log('Filepicker upload complete:', JSON.stringify(result));
          onChange(result?.filesUploaded[0].url);
        }}
        onError={(err: Error) => {
          handleClientError(err, 'Failed uploading image to Filestack.');
        }}
        render={(pickerProps: { onPick: () => void }) => {
          const { onPick } = pickerProps;
          if (largePicker) {
            const url = value || svgs.cloud_download_blue;
            const label = value ? 'RETAKE' : 'CLICK TO UPLOAD';
            const pickerStyle = {
              background: `url(${url}) no-repeat`,
              backgroundSize: value ? 'cover' : '',
            };

            return (
              <div className={classes.picker} style={pickerStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPick}
                  label={label}
                  className={classes.pickerButton}
                />
              </div>
            );
          }
          return (
            <Tooltip title="Upload Image / Select Image from Cloud">
              <IconButton onClick={onPick}>
                <TextIcon icon={iconCodes.upload} />
              </IconButton>
            </Tooltip>
          );
        }}
      />
    </div>
  );
};

export default ImagePicker;
