import React, { ReactNode } from 'react';

import SimpleIcon, { SimpleIconType } from '../SimpleIcon';
import { TextDisplay } from '../../display';
import { svgs } from '../../../app/svg';

import useStyles from './style';

export interface EmptyStateProps {
  primary: string | ReactNode;
  secondary?: string | ReactNode;
  icon?: SimpleIconType;
  classes?: Record<string, string>;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const classes = useStyles();
  const { primary, secondary, icon = svgs.ArchiveFolder } = props;

  return (
    <div className={classes.root}>
      {icon !== null && (
        <div className={classes.iconContainer}>
          <SimpleIcon icon={icon} className={classes.icon} />
        </div>
      )}

      <div className={classes.primaryContainer}>
        {typeof primary === 'string' ? (
          <TextDisplay variant="h5" className={classes.primary}>
            {primary}
          </TextDisplay>
        ) : (
          primary
        )}
      </div>

      {secondary != null && (
        <div className={classes.secondaryContainer}>
          {typeof secondary === 'string' ? (
            <TextDisplay variant="body1" className={classes.secondary}>
              {secondary}
            </TextDisplay>
          ) : (
            secondary
          )}
        </div>
      )}
    </div>
  );
};

export default EmptyState;
