import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import MarketerUsers from '../components/MarketerUsers';
import GenerateInviteUserToken from '../../../components/cpanel/components/molecules/GenerateInviteUserToken';
import { ENTITY_TYPE } from '../../../app/consts';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';

const styles = () => ({
  root: {},
});

const MarketerUsersTab = ({ marketer, classes }) => (
  <div className={classes.root}>
    <Section title="Marketer Users" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
      <SectionContent>
        <MarketerUsers marketer={marketer} />
      </SectionContent>
    </Section>
    <Section
      title="Invite user"
      subTitle="Generate a unique link to invite a new user to join the Marketer Account"
      layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS}
    >
      <SectionContent>
        <GenerateInviteUserToken entityType={ENTITY_TYPE.MARKETER} entityId={marketer.id} />
      </SectionContent>
    </Section>
  </div>
);

MarketerUsersTab.propTypes = {
  marketer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketerUsersTab);
