import moment from 'moment';
import {
  dateTimeUTCFormatter,
  defaultStringFormatter,
  numericFormatter,
} from '../../../../../../../fe_common/client/services/ExportToCSV/formatters';
import { CSVColumn } from '../../../../../../../fe_common/client/services/ExportToCSV/types';

export const referenceCodeFormatter = (value: string): string => {
  const date = new Date();
  const trimmedDate = date.toISOString().replace(/(T.*|[-])/g, '');
  return defaultStringFormatter(`${value}${trimmedDate}`);
};

export const invoiceSubjectFormatter = (value: string): string => {
  const date = moment(value).isValid() ? moment(value) : moment().subtract(1, 'month');
  const cycleDate = date.format('MMMM YYYY');

  return defaultStringFormatter(`Payment for ${cycleDate}`);
};

export const invoiceDescriptionFormatter = (): string =>
  defaultStringFormatter('Marketing Services - Mayple MarketPlace');

export const MARKETERS_PAYOUT_REVIEW_CSV_COLUMNS: CSVColumn[] = [
  {
    id: 'marketerId',
    header: 'Marketer Id',
    accessor: 'marketerId',
    formatter: numericFormatter,
  },
  {
    id: 'marketerName',
    header: 'Marketer Name',
    accessor: 'marketerName',
  },
  {
    id: 'preferredPayoutType',
    header: 'Preferred Payout Type',
    accessor: 'preferredPayoutType',
  },
  {
    id: 'projectId',
    header: 'Project Id',
    accessor: 'projectId',
    formatter: numericFormatter,
  },
  {
    id: 'projectName',
    header: 'Project',
    accessor: 'projectName',
  },
  {
    id: 'cycleId',
    header: 'Cycle ID',
    accessor: 'cycleId',
    formatter: numericFormatter,
  },
  {
    id: 'cycleDate',
    header: 'Cycle Date',
    accessor: 'cycleDate',
    // @ts-ignore
    formatter: dateTimeUTCFormatter,
  },
  {
    id: 'amount',
    header: 'Amount',
    accessor: 'amount',
    formatter: numericFormatter,
  },
  {
    id: 'totalMarketerFee',
    header: 'Total marketer fee',
    accessor: 'balance',
    formatter: numericFormatter,
  },
];

export const MARKETERS_PAYOUT_REVIEW_TIPALTI_CSV_COLUMNS: CSVColumn[] = [
  {
    id: 'marketerName',
    header: 'Marketer Name',
    accessor: 'marketerName',
  },
  {
    id: 'marketerId',
    header: 'Idap',
    accessor: 'marketerId',
    formatter: numericFormatter,
  },
  {
    id: 'refCode',
    header: 'BillRefCode',
    accessor: 'marketerId',
    formatter: referenceCodeFormatter,
  },
  {
    id: 'invoiceNumber',
    header: 'InvoiceNumber',
    accessor: 'marketerId',
    formatter: referenceCodeFormatter,
  },
  {
    id: 'invoiceSubject',
    header: 'InvoiceSubject',
    accessor: 'cycleDate',
    formatter: invoiceSubjectFormatter,
  },
  // InvoiceDescription
  {
    id: 'invoiceDescription',
    header: 'InvoiceDescription',
    formatter: invoiceDescriptionFormatter,
  },
  // InvoiceDate
  {
    id: 'invoiceDate',
    header: 'InvoiceDate',
    formatter: () => defaultStringFormatter(moment().format('MM/DD/YYYY')),
  },
  // LineDescription - Concatenation of: "Project ID""Project Name"
  {
    id: 'lineDescription',
    header: 'LineDescription',
    accessor: 'lineDescription',
  },
  // AccountID
  {
    id: 'projectId',
    header: 'AccountID',
    accessor: 'projectId',
    formatter: numericFormatter,
  },
  // Currency
  {
    id: 'currency',
    header: 'Currency',
    accessor: 'currency',
  },
  // LineAmount
  {
    id: 'amount',
    header: 'LineAmount',
    accessor: 'amount',
    formatter: numericFormatter,
  },
  // TotalAmount
  {
    id: 'totalMarketerFee',
    header: 'TotalAmount',
    accessor: 'balance',
    formatter: numericFormatter,
  },
  // PaymentCurrency
  {
    id: 'paymentCurrency',
    header: 'PaymentCurrency',
    accessor: 'country',
    formatter: (value) => (value === 'IL' ? 'ILS' : 'USD'),
  },
];
