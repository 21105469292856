import { PauseProjectPPCServicesMutation } from 'growl-graphql/dist/mutations/PauseProjectPPCServicesMutation';
import { ResumeProjectPPCServicesMutation } from 'growl-graphql/dist/mutations/ResumeProjectPPCServicesMutation';
import { PauseProjectNonPPCServicesMutation } from 'growl-graphql/dist/mutations/PauseProjectNonPPCServicesMutation';
import { ResumeProjectNonPPCServicesMutation } from 'growl-graphql/dist/mutations/ResumeProjectNonPPCServicesMutation';

import CyclePauseDurationsType from './consts';

export const getUserComment = () => window.prompt('Please supply a reason to pause the project services');

export const getMutationClass = (isServicePaused, durationType) => {
  if (durationType === CyclePauseDurationsType.PPC_DURATIONS) {
    return isServicePaused ? ResumeProjectPPCServicesMutation : PauseProjectPPCServicesMutation;
  }

  return isServicePaused ? ResumeProjectNonPPCServicesMutation : PauseProjectNonPPCServicesMutation;
};

export const getMutationVars = (projectId, isServicePaused) => {
  let comment;

  // User is about to pause project cycle
  if (!isServicePaused) {
    // Now we are asking the user to supply a comment
    comment = getUserComment();
    if (!comment) {
      throw new Error('You must supply a comment for this operation.');
    }
  }

  const vars = {
    variables: {
      projectId,
    },
  };

  if (comment) {
    vars.variables.comment = comment;
  }

  return vars;
};
