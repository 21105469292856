import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 16,
      padding: '16px 12px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '100%',
        borderRadius: 0,
      },
    },
    divider: {
      marginTop: 24,
      marginBottom: 24,
    },
    actionsContainer: {
      marginTop: 32,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        marginBottom: 16,
        textAlign: 'center',
      },
    },
  })
);

export default useStyles;
