import { MediaType } from '@mayple/types';
import { UpdateProjectNewMutation } from 'growl-graphql/dist/mutations/UpdateProjectNewMutation';
import { UpdateCompanyMutation } from 'growl-graphql/dist/mutations/UpdateCompanyMutation';

import { EntityNotesMutation, NoteMutation, NotesEntityType } from '../types';
import EntityType from '../../../../app/types';
import useMutation from '../../../../../fe_common/client/hooks/useMutation';

const getNoteMutation = (entityId: number, entityType: NotesEntityType): NoteMutation | null => {
  // @ts-ignore
  const noteMutation: Record<Partial<EntityType>, NoteMutation> = {
    [EntityType.Project]: {
      mutationClass: UpdateProjectNewMutation,
      dataKey: 'projectUpdate',
      variables: {
        projectId: entityId,
        projectUpdate: {
          note: {
            contents: '',
            mediaType: MediaType.TEXT_HTML,
          },
        },
      },
    },
    [EntityType.Company]: {
      mutationClass: UpdateCompanyMutation,
      dataKey: 'companyUpdate',
      variables: {
        companyId: entityId,
        companyUpdate: {
          note: {
            contents: '',
            mediaType: MediaType.TEXT_HTML,
          },
        },
      },
    },
  };

  // @ts-ignore
  return noteMutation[entityType] ?? null;
};

const useEntityNoteMutation = (
  entityId: number,
  entityType: EntityType.Project | EntityType.Company,
): EntityNotesMutation => {
  const noteMutation = getNoteMutation(entityId, entityType);
  const { mutationClass, variables, dataKey } = noteMutation || {};

  const { mutate, loading, error } = useMutation(mutationClass);

  return {
    mutate,
    loading,
    error,
    variables,
    dataKey,
  };
};

export default useEntityNoteMutation;
