import { PackageKeyInput } from '@mayple/types';

import useProjectPackageSelectionDialog from './components/ProjectPackagesSelectionDialog/useProjectPackageSelectionDialog';

import useProjectAppliedPackages from '../../../../../fe_common/client/hooks/project/useProjectAppliedPackages';

const CHAPTER_MAILCHIMP = 'Mailchimp';
const CATEGORY_EMAIL_MARKETING = 'Email Marketing';

export type ProjectPackagesData = {
  addPackageDialogIsOpen: boolean;
  openAddPackageDialog: () => void;
  closeAddPackageDialog: () => void;
  onAddPackageHandler: (packageKey: PackageKeyInput | null | undefined) => Promise<void>;
  packageKey: PackageKeyInput;
};

const useProjectPackagesData = (projectId: number | null | undefined): ProjectPackagesData => {
  const packageKey: PackageKeyInput = {
    category: CATEGORY_EMAIL_MARKETING,
    chapter: CHAPTER_MAILCHIMP,
    offering: '',
    variant: '',
    version: '',
  };

  const { refetch: refetchProjectAppliedPackages } = useProjectAppliedPackages(projectId);

  const { addPackageDialogIsOpen, openAddPackageDialog, closeAddPackageDialog, onAddPackageHandler } =
    useProjectPackageSelectionDialog(projectId, refetchProjectAppliedPackages);

  return {
    addPackageDialogIsOpen,
    openAddPackageDialog,
    closeAddPackageDialog,
    onAddPackageHandler,
    packageKey,
  };
};

export default useProjectPackagesData;
