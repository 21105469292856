import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';
import { useQuery } from '@apollo/react-hooks';
import { FullInternalTeamByIdQuery } from 'growl-graphql/dist/queries/FullInternalTeamById';

import { INTERNAL_TEAM_DETAILS_TABS, internalTeamDetailsTabs } from './logic';
import InternalTeamDetailsHeader from './components/InternalTeamDetailsHeader';
import InternalTeamBasicDetailsTab from './tabs/InternalTeamBasicDetailsTab';
import InternalTeamUsersTab from './tabs/InternalTeamUsersTab';
import InternalTeamProjectsTab from './tabs/InternalTeamProjectsTab';
import { InternalTeamIdParams } from '../../components/Routes/types';

import { useMuiTabState } from '../../../fe_common/client/hooks';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import TabContent from '../../../fe_common/client/components/atoms/TabContent';
import PageLoadingPlaceholder from '../../../fe_common/client/components/atoms/PageLoadingPlaceholder';

import useStyles from './styles';

// shows full details of single internal account
const InternalTeamDetailsPage: FC = () => {
  const { internalTeamId } = useParams<InternalTeamIdParams>();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useMuiTabState(
    INTERNAL_TEAM_DETAILS_TABS.basicDetails,
    INTERNAL_TEAM_DETAILS_TABS,
  );

  const { data, loading, refetch, error } = useQuery(FullInternalTeamByIdQuery.query, {
    variables: {
      internalTeamId,
    },
    skip: !internalTeamId,
  });

  // if the data still not returned show loading symbol
  if (loading) {
    // loading symbol
    return <PageLoadingPlaceholder />;
  }

  if (error) {
    return <CalloutMessage type="error" message={`Error getting internal account data. ${error}`} />;
  }

  const { internalTeam } = data;

  // if no data gets returned from the DB
  if (!internalTeam) {
    return <CalloutMessage type="info" message={`internal account with id ${internalTeamId} not found`} />;
  }

  return (
    <div className={classes.root}>
      <InternalTeamDetailsHeader internalTeam={internalTeam} refetchInternalTeam={refetch} />

      <Divider className={classes.divider} />

      <Tabs value={selectedTab} onChange={setSelectedTab} variant="scrollable" scrollButtons="auto">
        {internalTeamDetailsTabs.map(({ label, value }) => (
          <Tab key={`InternalTeamDetailsPage-tabs-${label}`} label={label} value={value} />
        ))}
      </Tabs>

      {selectedTab === INTERNAL_TEAM_DETAILS_TABS.basicDetails && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <InternalTeamBasicDetailsTab internalTeam={internalTeam} />
        </TabContent>
      )}

      {selectedTab === INTERNAL_TEAM_DETAILS_TABS.users && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <InternalTeamUsersTab internalTeam={internalTeam} onChange={refetch} />
        </TabContent>
      )}

      {selectedTab === INTERNAL_TEAM_DETAILS_TABS.projects && (
        <TabContent classes={{ root: classes.tabContentRoot }}>
          <InternalTeamProjectsTab internalTeam={internalTeam} onChange={refetch} />
        </TabContent>
      )}
    </div>
  );
};

export default InternalTeamDetailsPage;
