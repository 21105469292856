import React, { useEffect, useState } from 'react';
import { Project } from '@mayple/types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { getBriefConnectedProjects } from './logic';
import useCompanyProjectsBasic from '../../../../../hooks/useCompanyProjectsBasic';
import CompanyProjects from '../../../../../pages/CompanyDetailsPage/components/CompanyProjects/index';

import { RefreshSpinner } from '../../../../../../fe_common/client/components/atoms';
import { getGradientColor } from '../../../../../../fe_common/client/app/theme';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    accordionSummary: {
      width: '100%',
      justifyContent: 'space-between',
    },
    summarySubtitle: {
      color: getGradientColor('gray', 80),
    },
    accordionDetailsRoot: {
      width: '100%',
    },
    companyProjectsRoot: {
      width: '100%',
    },
  }),
);

interface ProjectBriefConnectedProjectsProps {
  project: Project;
}

const ProjectBriefConnectedProjects: React.FC<ProjectBriefConnectedProjectsProps> = (props) => {
  const classes = useStyles();

  const { project } = props;
  const { companyId } = project;
  const { companyProjects, loading, refetch } = useCompanyProjectsBasic(companyId);
  const [initialized, setInitialized] = useState(false);
  const [briefConnectedProjects, setBriefConnectedProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (!loading) {
      setBriefConnectedProjects(getBriefConnectedProjects(project, companyProjects));
      setInitialized(true);
    }
  }, [loading, companyProjects, project]);

  const hasConnectedProjects = briefConnectedProjects?.length > 0;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.accordionSummary }}>
        <div>
          {!loading && initialized && !hasConnectedProjects && <LinkOffIcon />}
          {!loading && initialized && hasConnectedProjects && <LinkIcon />}{' '}
          <TextDisplay variant="h5" inline>
            Connected projects{' '}
          </TextDisplay>{' '}
          <TextDisplay variant="h5" inline>
            ({briefConnectedProjects?.length})
          </TextDisplay>
          <TextDisplay variant="body1">
            Connected Briefs act as one brief, all changes made in one affect the others.
          </TextDisplay>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        {loading && !initialized && <RefreshSpinner />}
        {!loading && initialized && <CompanyProjects projects={briefConnectedProjects} refetch={refetch} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectBriefConnectedProjects;
