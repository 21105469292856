import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    richTextRoot: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& .ql-container': {
        minHeight: '20rem',
      },
      '& .ql-toolbar': {
        borderTop: '0 none',
      },
      '& .ql-editor': {
        borderTop: '1px solid #ccc',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  }),
);
export default useStyles;
