import React, { FC } from 'react';
import ReactTable from 'react-table-6';

import { ProjectsTableProps } from './types';
import columns from './logic';
import LoadingSpinnerNonBlocking from '../../../components/cpanel/components/atoms/LoadingSpinnerNonBlocking';

import Section from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';
import { filterCaseInsensitiveForReactTable } from '../../../../fe_common/client/services/reactTableHelpers/filterMethods';

import useStyles from './style';

const ProjectsTable: FC<ProjectsTableProps> = (props) => {
  const classes = useStyles(props);
  const { data: allProjects, loading, title = 'Projects' } = props;

  if (!allProjects && !loading) {
    return <CalloutMessage type="error" message="Error getting projects list" />;
  }

  return (
    <Section title={title} gutterTopSize={2} noBorder classes={{ root: classes.sectionRoot }}>
      <SectionContent classes={{ root: classes.sectionContentRoot }}>
        <ReactTable
          className={classes.reactTable}
          loading={loading}
          LoadingComponent={LoadingSpinnerNonBlocking}
          data={allProjects || []} // show new projects first
          columns={columns}
          defaultPageSize={10}
          filterable
          defaultFilterMethod={filterCaseInsensitiveForReactTable}
        />
      </SectionContent>
    </Section>
  );
};

export default ProjectsTable;
