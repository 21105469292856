import React, { FC } from 'react';
import moment from 'moment';

import { OpportunitiesCandidatesForm, WizardStepsProps } from './types';

import { WizardStepField } from '../../../../../../fe_common/client/components/molecules/WizardStepField';
import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const SendProjectOpportunityWizardSteps: FC<WizardStepsProps> = (props) => {
  const minDate = moment().add(4, 'hours');
  const maxDate = moment().add(1, 'month');

  const { formValues } = props;
  const { companyNote, projectNote } = formValues as OpportunitiesCandidatesForm;

  const hasCompanyNote = companyNote?.contents?.length > 0;
  const hasProjectNote = projectNote?.contents?.length > 0;

  return (
    <div>
      <div>
        <TextDisplay gutterBottom variant="subtitle2">
          Please select the expiration date of the selected opportunities
          <br />
          (default is 24 hours)
        </TextDisplay>
        <br />
        <WizardStepField
          type={WizardStepField.types.DATE_TIME_PICKER}
          // @ts-ignore
          label="Date to expire"
          name="dueDateTime"
          required
          minDate={minDate}
          maxDate={maxDate}
          views={['date']}
        />
      </div>
      {hasCompanyNote && (
        <>
          <TextDisplay variant="caption" gutterBottom>
            Company Notes
          </TextDisplay>
          <RichTextViewer value={companyNote} />
          <WizardStepField
            type={WizardStepField.types.CHECKBOX}
            // @ts-ignore
            inputLabel="Include Company Notes?"
            name="includeCompanyNote"
          />
        </>
      )}
      {hasProjectNote && (
        <>
          <TextDisplay variant="caption" gutterBottom>
            Project Notes
          </TextDisplay>
          <RichTextViewer value={projectNote} />
          <WizardStepField
            type={WizardStepField.types.CHECKBOX}
            // @ts-ignore
            inputLabel="Include Project Notes?"
            name="includeProjectNote"
          />
        </>
      )}
    </div>
  );
};

export default SendProjectOpportunityWizardSteps;
