import React from 'react';
import { useTranslation } from 'react-i18next';
import MobileStepper from '@material-ui/core/MobileStepper';
import TextField from '@material-ui/core/TextField';

import useAssessmentFormData from './useAssessmentFormData';
import assessmentQuestionsDefinitions from './assessmentQuestionsDefinitions';
import { AssessmentFormProps } from '../../types';

import { useDeviceInfo } from '../../../../../hooks';
import { TRANSLATION_NS } from '../../../../../app/consts';
import StarsVote from '../../../../inputs/StarsVote';
import EntityAvatar from '../../../../atoms/EntityAvatar';
import { TextDisplay, TitleWithTooltip } from '../../../../display';
import { Button, RadioGroup } from '../../../../inputs';
import CalloutMessage from '../../../../common/CalloutMessage';
import { WizardStepField } from '../../../../molecules/WizardStepField';

import useStyles from './styles';
import ScoreButtons from '../../../../atoms/ScoreButtons';

const AssessmentForm: React.FC<AssessmentFormProps> = (props) => {
  const classes = useStyles(props);

  const { name, subTask, totalSteps = 0, currentStep = 1, subHeadline, hideAssessmentComment = false } = props;
  const { uuid: subTaskUuid } = subTask;
  const { isMobile } = useDeviceInfo();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'AssessmentDialog.MarketerAssessmentForm',
  });

  const {
    displayImageUrl,
    marketerName,
    getOnChangeStarVoteHandler,
    getOnChangeTextVoteHandler,
    getOnChangeScoreVoteHandler,
    onSubmitButtonClickHandler,
    onSkipButtonClickHandler,
    assessmentVotes,
    votes,
    assessmentComment,
    onAssessmentCommentChangeHandler,
    errorMessage,
  } = useAssessmentFormData(props);

  return (
    <div className={classes.root}>
      {totalSteps > 1 && (
        <MobileStepper
          steps={totalSteps}
          color="primary"
          position="static"
          activeStep={currentStep - 1}
          backButton={null}
          nextButton={null}
          classes={{
            root: classes.mobileStepper,
          }}
        />
      )}
      <div className={classes.formHeader}>
        <EntityAvatar
          classes={{
            root: classes.entityAvatarRoot,
            avatar: classes.entityAvatarAvatar,
            primary: classes.entityAvatarPrimary,
          }}
          size={isMobile ? 'md' : 'xl'}
          avatarImageUrl={displayImageUrl}
          primaryTextType="overline"
          primary={marketerName}
        />
        <TextDisplay variant="h5" className={classes.headline}>
          {t('headline', { marketerName })}
        </TextDisplay>
        {!!subHeadline && (
          <TextDisplay variant="body2" className={classes.subHeadline}>
            {t('subHeadline')}
          </TextDisplay>
        )}
      </div>
      <form name={name} className={classes.formBody}>
        {assessmentVotes.map((key) => {
          const { component, props: componentProps } = assessmentQuestionsDefinitions[key] || {};

          switch (component) {
            case WizardStepField.types.SCORE_BUTTONS:
              return (
                <div key={`assessmentVote-${key}-${subTaskUuid}`} className={classes.question}>
                  <TitleWithTooltip title={t(`Labels.${key}`)} tooltip={t(`Tooltips.${key}`) || false} />
                  <ScoreButtons
                    {...componentProps}
                    name={`assessmentVote-${key}-${subTaskUuid}`}
                    value={votes[key] as string | number}
                    minimumValue={1}
                    maximumValue={10}
                    minimumValueCaption={t('poor')}
                    maximumValueCaption={t('outstanding')}
                    onChange={getOnChangeScoreVoteHandler(key)}
                  />
                </div>
              );

            case WizardStepField.types.RADIO:
              return (
                <div key={`assessmentVote-${key}-${subTaskUuid}`} className={classes.question}>
                  <TitleWithTooltip title={t(`Labels.${key}`)} tooltip={t(`Tooltips.${key}`) || false} />
                  <RadioGroup
                    options={[]}
                    {...componentProps}
                    name={`assessmentVote-${key}-${subTaskUuid}`}
                    value={votes[key] as string | undefined}
                    onChange={getOnChangeTextVoteHandler(key)}
                    color="primary"
                  />
                </div>
              );

            case WizardStepField.types.TEXT:
              return (
                <div key={`assessmentVote-${key}-${subTaskUuid}`} className={classes.question}>
                  <TitleWithTooltip title={t(`Labels.${key}`)} tooltip={t(`Tooltips.${key}`) || false} />
                  <TextField
                    {...componentProps}
                    name={`assessmentVote-${key}-${subTaskUuid}`}
                    onChange={getOnChangeTextVoteHandler(key)}
                    value={votes[key]}
                    className={classes.assessmentComment}
                  />
                </div>
              );

            default:
              return (
                <StarsVote
                  key={`assessmentVote-${key}-${subTaskUuid}`}
                  name={`assessmentVote-${key}-${subTaskUuid}`}
                  title={t(`Labels.${key}`)}
                  tooltip={t(`Tooltips.${key}`)}
                  onChange={getOnChangeStarVoteHandler(key)}
                  value={votes[key] as number | undefined}
                  size={isMobile ? 'small' : 'large'}
                  showRatingLabel
                  classes={{ root: classes.question }}
                />
              );
          }
        })}
        {!hideAssessmentComment && (
          <TextField
            className={classes.assessmentComment}
            label="Assessment comment"
            value={assessmentComment}
            onChange={onAssessmentCommentChangeHandler}
            margin="normal"
            variant="outlined"
            type="text"
            multiline
            minRows={3}
          />
        )}
      </form>
      {!!errorMessage && <CalloutMessage type="error" message={errorMessage} margin />}
      <div className={classes.formActions}>
        <Button
          label={totalSteps > 1 ? t('nextButton', 'next') : t('submitButton', 'submit')}
          color="primary"
          variant="contained"
          className={classes.nextButton}
          onClick={onSubmitButtonClickHandler}
        />
        <Button
          label={t('skipButton', 'skip')}
          color="primary"
          variant="outlined"
          className={classes.skipButton}
          onClick={onSkipButtonClickHandler}
        />
      </div>
    </div>
  );
};

export default AssessmentForm;
