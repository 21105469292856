import moment from 'moment';
import get from 'lodash/get';
import { ParticipantInsightsFeedQuery } from 'growl-graphql/dist/queries/ParticipantInsightsFeedQuery';

import { handleClientError } from '../../services/logger';
import { arrayOfObjectsToHashTable } from '../../services/utils';

/**
 *
 * @param legend
 * @returns {{}}
 */
export function createFeedLegendTable(legend) {
  const table = {};

  try {
    const { persons, organizations } = legend;
    // Convert to hash table
    table.persons = arrayOfObjectsToHashTable({ array: persons, key: 'uuid' });
    table.organizations = arrayOfObjectsToHashTable({ array: organizations, key: 'uuid' });
    // Set for each person his organization details
    Object.keys(table.persons).forEach((key) => {
      if (table.organizations[table?.persons?.[key]?.organizationUuid]) {
        table.persons[key].organization = {
          ...table.organizations[table.persons[key].organizationUuid],
        };
      }
    });
  } catch (e) {
    handleClientError(e);
  }

  return table;
}

/**
 *
 * @param authorUuid
 * @param legend
 */
export function getAuthorDetails(authorUuid, legend) {
  let author;

  try {
    if (legend.persons[authorUuid]) {
      author = {
        ...legend.persons[authorUuid],
      };
    } else {
      author = {
        name: 'Unknown author',
        uuid: Math.random().toString(36),
      };
    }
  } catch (e) {
    author = {
      name: 'Unknown author',
      uuid: Math.random().toString(36),
    };
  }

  return author;
}

export function formatCommentDate(date) {
  if (date) {
    return moment(date).format('MMM DD hh:mm');
  }
  return '';
}

const isMatchedPost = (filterBy, value) => {
  if (filterBy.length > 0) {
    return filterBy.includes(value);
  }
  return true;
};

const filterToPostMapper = [
  { filterKey: 'topics', postAccessor: 'topic' },
  { filterKey: 'teamMembers', postAccessor: 'authorUuid' },
];

const filterMatchReducer = (value, accumulator) => value && accumulator;

export const getFilteredPosts = (posts, filter) => {
  const filteredPosts = posts.filter((post) => {
    // console.log('post', post);

    // loop over the mapper, and call isMatchedPost with the filter values, and post value
    // to search for a match for each filter type
    const matches = filterToPostMapper.map(({ filterKey, postAccessor }) =>
      isMatchedPost(get(filter, filterKey, []), get(post, postAccessor))
    );

    // console.log('matches', matches);

    // use a reducer to sum all the filter match results
    // only a result the would return true for all values will be returned
    const match = matches.reduce(filterMatchReducer, true);

    // console.log('match', match);

    return match;
  });

  return filteredPosts;
};

export const sortByDateKey = (posts, sortBy = 'lastActivityDate', order = 'desc') =>
  posts.sort((a, b) => {
    const dateA = a[sortBy];
    const dateB = b[sortBy];
    const utcA = new Date(dateA).getTime();
    const utcB = new Date(dateB).getTime();
    if (order === 'desc') {
      return utcA < utcB ? 1 : -1;
    } else {
      return utcA > utcB ? 1 : -1;
    }
  });

export const mapAttachedFiles = (uploadedFiles) => {
  if (!Array.isArray(uploadedFiles)) {
    return null;
  }

  return uploadedFiles.map((file) => {
    const { filename, mimetype, size, url } = file;

    return {
      name: filename,
      description: '',
      mimetype,
      size,
      url,
      uploaderContext: JSON.stringify(file),
    };
  });
};

export const getInsightsFeedParticipantFromCache = (cache, projectId, marketerId) => {
  try {
    const query = cache.readQuery({
      query: ParticipantInsightsFeedQuery.query,
      variables: {
        projectId,
        marketerId,
      },
    });

    return query?.participant || null;
  } catch (e) {
    return null;
  }
};

export const writeInsightsFeedParticipantToCache = (cache, projectId, marketerId, participant) => {
  cache.writeQuery({
    query: ParticipantInsightsFeedQuery.query,
    variables: { projectId, marketerId },
    data: { participant },
  });
};
