/* eslint-disable prefer-template */
import get from 'lodash/get';

import { defaultStringFormatter } from './formatters';

export const mapRowData = (columns, item) =>
  columns.map(({ accessor, formatter = defaultStringFormatter }) => formatter(get(item, accessor, '')));

/**
 * This function is forcing the browser to create a download link to CSV
 * It creates an <a /> tag with a link to the file, and simulate a click on it to activate D/L
 * @param csvContent - the content of the csv file
 * @param fileName - the name of the file to download
 */
const forceDownloadCSV = (csvContent, fileName) => {
  const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file named "fileName.csv".
};

const mapDataToRows = (columns, data) => data.map((item) => mapRowData(columns, item));

export const exportDataToCsv = (columns, data, fileName = 'export') => {
  if (!data) {
    throw new Error('No data to export...');
  }

  const rows = mapDataToRows(columns, data);
  const columnsHeaders = columns.map(({ header }) => header);

  const csvContent = columnsHeaders.join(',') + '\n' + rows.map((e) => e.join(',')).join('\n');

  forceDownloadCSV(csvContent, fileName);
};

const defaultFilter = () => true;

const getFilteredData = (data, columns, dataTableFilter) => {
  if (!dataTableFilter || dataTableFilter.length === 0) {
    return data;
  }

  let filteredData = [];

  // looping on all the filters
  dataTableFilter.forEach((filter) => {
    // Getting the column out of the columns data
    const column = columns.find(({ id }) => id === filter.id);
    const { id, filterMethod = defaultFilter, accessor = filter.id } = column || {};

    filteredData = data.filter((dataRow) => {
      // since the rowData structure may differ from the table row data
      // I'm creating a small data object from the field filter
      const thinDataRow = {
        [id]: get(dataRow, accessor),
      };
      // using the filter method to determine if this row should be filtered
      return filterMethod(filter, thinDataRow);
    });
  });

  return filteredData;
};

const defaultSortMethod = (valA, valB, orderDesc = false) => {
  // force null and undefined to the bottom
  let a = valA === null || valA === undefined ? '' : valA;
  let b = valB === null || valB === undefined ? '' : valB;
  // force any string values to lowercase
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;

  const orderDirection = orderDesc ? -1 : 1;

  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1 * orderDirection;
  }
  if (a < b) {
    return -1 * orderDirection;
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
  return 0;
};

export const getSortedData = (data, columns, dataTableSort) => {
  if (!dataTableSort.length) {
    return data;
  }

  return data.sort((a, b) => {
    for (let i = 0; i < dataTableSort.length; i++) {
      const { id, desc } = dataTableSort[i];

      const { accessor } = columns.find((column) => column.id === id) || {};
      const sortResult = defaultSortMethod(get(a, accessor, ''), get(b, accessor, ''), desc);

      // if the values match each other, continue to next value in the sorting array
      if (sortResult !== 0) {
        return sortResult;
      }
    }

    // all values match - returning 0
    return 0;
  });
};

export const getFilteredSortedData = (data, columns, dataTableFilter, dataTableSort) => {
  let filteredSortedData = data;

  filteredSortedData = getFilteredData(filteredSortedData, columns, dataTableFilter);
  filteredSortedData = getSortedData(filteredSortedData, columns, dataTableSort);

  return filteredSortedData;
};
