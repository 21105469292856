import { useCallback } from 'react';
import { Account } from '../../../types';
import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../../AvailabilityViewerProvider/availabilityViewerReducer';
import { EntitySelectionFilterValue } from '../components/EntitySelectionFilter/types';

const useEntitySelectionFilter = (): [
  EntitySelectionFilterValue,
  (account: Account | null, enableEntitySearch: boolean) => void
] => {
  const { filterData } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const entitySelectionFilterValue = {
    enableEntitySearch: filterData?.enableEntitySearch || false,
    selectedAccount: filterData?.account || null,
  };

  const onEntitySelectionFilterChange = useCallback(
    (newAccount: Account | null, enableEntitySearch: boolean) => {
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          enableEntitySearch,
          account: newAccount,
        },
      };
      dispatch(action);
    },
    [dispatch]
  );

  return [entitySelectionFilterValue, onEntitySelectionFilterChange];
};

export default useEntitySelectionFilter;
