import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: colors.whiteOpacity(0.9),
      alignItems: 'center',
      justifyContent: 'center',
      wordBreak: 'keep-all',
      whiteSpace: 'pre',
      zIndex: 10,
    },
    message: {
      color: colors.primary,
    },
  })
);

export default useStyles;
