import { MailchimpHealthCheckScopeChangeType } from '@mayple/types';

import { LabelValueProps } from '../../../../../fe_common/client/app/types';

export const saveErrorMessage = 'Error while trying to report Mailchimp Health Check project scope change.';

export const mailchimpHealthCheckScopeChangeOptions: LabelValueProps<MailchimpHealthCheckScopeChangeType>[] = [
  {
    value: MailchimpHealthCheckScopeChangeType.MAILCHIMP_HEALTH_CHECK_ALL_CANCELED,
    label: 'Cancel Project - will cancel all the non completed packages',
  },
  {
    value: MailchimpHealthCheckScopeChangeType.MAILCHIMP_HEALTH_CHECK_ONLY_KICK_OFF_MEETING,
    label: 'Project being stopped after KO meeting - will pay the expert 150$ for the KO meeting.',
  },
  // {
  //   value: 'ExpertReplacementBeforeReviewMeeting',
  //   label:
  //     'Project being stopped before review meeting, HC review was filled, but no review meeting. will pay the expert 400$.',
  //   isDisabled: true,
  // },
];
