/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { Project, ProjectLifeCycleStatus } from '@mayple/types';
import ReactTable, { Column, RowRenderProps } from 'react-table-6';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { ProjectsRequiringMarketingAuditQuery } from 'growl-graphql/dist/queries/ProjectsRequiringMarketingAuditQuery';

import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';
import useQueryPagination from '../../../fe_common/client/hooks/useQueryPagination';
import { ProjectLifeCycleFilter } from '../../../fe_common/client/services/reactTableHelpers/filters';
import {
  filterCaseInsensitiveForReactTable,
  projectLifeCycleFilterMethod,
} from '../../../fe_common/client/services/reactTableHelpers/filterMethods';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      paddingTop: 0,
      paddingBottom: 36,
      marginTop: 36,
    },
  }),
);

const NeedAuditProjectsTable: FC = () => {
  const classes = useStyles();

  const excludedValues = [ProjectLifeCycleStatus.ABANDONED, ProjectLifeCycleStatus.FINISHED];

  const { data, loading } = useQueryPagination(ProjectsRequiringMarketingAuditQuery, 'projectsRequiringMarketingAudit');

  const columns: Column[] = [
    {
      Header: 'Name',
      accessor: (d) => d.name,
      id: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (d: RowRenderProps) => <Link to={`/projects/${d.original.id}`}>{d.value}</Link>,
    },
    {
      Header: 'Status',
      accessor: 'projectLifeCycleStatus',
      // eslint-disable-next-line react/no-unstable-nested-components
      Filter: (filterProps) => <ProjectLifeCycleFilter excludedValues={excludedValues} {...filterProps} />,
      filterMethod: projectLifeCycleFilterMethod,
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Company',
      accessor: (d) => d.company.name,
      id: 'company',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (d: RowRenderProps) => <Link to={`/companies/${d.original.company.id}`}>{d.original.company.name}</Link>,
    },
  ];

  const needAuditProjectList: Project[] = [...(data as Project[])]
    .filter((project) => !excludedValues.includes(project.projectLifeCycleStatus))
    .reverse();

  return (
    <Section title="Need Audit Projects" noBorder classes={{ root: classes.sectionRoot }}>
      <SectionContent>
        <ReactTable
          loading={loading}
          data={needAuditProjectList} // show old projects first
          columns={columns}
          defaultPageSize={10}
          filterable
          defaultFilterMethod={filterCaseInsensitiveForReactTable}
        />
      </SectionContent>
    </Section>
  );
};

export default NeedAuditProjectsTable;
