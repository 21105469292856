import React, { MouseEventHandler, useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { MultipleActionsButtonProps } from './types';
import { Button } from '../index';

import useStyles from './style';

const MultipleActionsButton: React.FC<MultipleActionsButtonProps> = (props) => {
  const classes = useStyles();
  const { label, onClick, name, actions, hideMenuOnClick = true, ...restOfButtonProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      onClick?.(event);
    },
    [onClick]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className={classes.root}>
      {label && (
        <Button
          label={label}
          onClick={handleClick}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          {...restOfButtonProps}
        />
      )}
      {!label && (
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id={`simple-menu-${name}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={undefined}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.menuRoot,
        }}
      >
        {actions.map((action) => {
          const { id, onClick: onActionClick, label: actionLabel, hidden = false, disabled = false } = action;
          const onActionClickHandler: MouseEventHandler<HTMLLIElement> = (event) => {
            onActionClick?.(event);
            if (hideMenuOnClick) {
              handleClose();
            }
          };

          return (
            !hidden && (
              <MenuItem key={id} onClick={onActionClickHandler} disabled={disabled}>
                {actionLabel}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
};

export default MultipleActionsButton;
