import React, { FC } from 'react';
import { PpcBillingModelPlanName, MarketerFeePlanName } from '@mayple/types';
import { Field } from 'redux-form';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

import useCycleStep from './useCycleStep';
import { CycleStepProps } from './types';
import { checkIsFixedPlan } from './logic';
import {
  MediaBudgetDivisionField,
  validateMediaBudgetDivision,
} from '../../../../../components/cpanel/project/MediaBudgetDivisionField';
import DeliverablesField, { validateDeliverables } from '../../../../../components/cpanel/project/DeliverableField';
import AdditionalServicesField, {
  validateAdditionalServices,
} from '../../../../../components/cpanel/project/AdditionalServicesField';
import projectBillingHelpers from '../../../../../pages/ProjectBillingPage/helpers';

import { MARKETER_FEE_PLAN_PAIRS } from '../../../../../../fe_common/client/app/enums';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { WizardStepField } from '../../../../../../fe_common/client/components/molecules/WizardStepField';
import { safeExtractCurrencyValue } from '../../../../../../fe_common/client/services/utils';
import { RefreshSpinner } from '../../../../../../fe_common/client/components/atoms';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';
import { colors } from '../../../../../../fe_common/client/app/theme';

const CycleStep: FC<CycleStepProps> = (props) => {
  // console.log('CycleStep props', props);
  const {
    project,
    isNextCycle,
    allowDiscountEdit,
    enableSetupServiceEdit,
    enableDeliverablesEdit,
    enablePpcBillingPlanEdit,
    enableNonPpcServicesEdit,
    suffix,
    mediaBudgetDivisionFieldAmountKey,
    showNonPpcRules,
  } = props;

  const {
    loading,
    glossary,
    billingPlanPairs,
    billingConfigurationPlanName,
    feePartPercentage,
    minimumFeePart,
    marketerFixedFee,
    PPCCostPerExtraChannel,
    PPCIncludedChannelCount,
    showMarketerFixedInput,
    showMaximumMarketerFeeWarning,
    marketerFeeConfigurationPlanName,
  } = useCycleStep(props);

  const isFixedPlan = checkIsFixedPlan(billingConfigurationPlanName);

  return (
    <div
      className="p-8"
      style={{ backgroundColor: isNextCycle ? '#e8938b1a' : '#8bc3ea1a' }}
      id={isNextCycle ? 'next-cycle-container' : 'current-cycle-container'}
    >
      <TextDisplay variant="h5" gutterBottom bold>
        {glossary.header}
      </TextDisplay>
      <TextDisplay gutterBottom>{glossary.context}</TextDisplay>
      <Divider />

      {enablePpcBillingPlanEdit && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>PPC Services</AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextDisplay bold variant="h6" gutterBottom>
                      {glossary.ppcDivisionHeader}
                    </TextDisplay>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={MediaBudgetDivisionField}
                      name={`mediaBudget${suffix}`}
                      validate={[validateMediaBudgetDivision]}
                      project={project}
                      getTitle={() => {
                        /* do nothing */
                      }}
                      label={glossary.mediaDivisionLabel}
                      amountKey={mediaBudgetDivisionFieldAmountKey}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <TextDisplay bold variant="h6" gutterBottom>
                      Settings for PPC services
                    </TextDisplay>
                    {!enablePpcBillingPlanEdit && (
                      <TextDisplay color="error" gutterBottom>
                        Cannot edit the PPC related billing configuration.
                      </TextDisplay>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {!loading && (
                        <Grid item xs={12} md={6}>
                          <WizardStepField
                            name={`billingConfigurationPlanName${suffix}`}
                            type={WizardStepField.types.SELECT}
                            // @ts-ignore
                            label="PPC Billing Plan"
                            enums={billingPlanPairs}
                          />
                          <WizardStepField
                            name={`PPCIncludedChannelCount${suffix}`}
                            type={WizardStepField.types.NUMBER}
                            // @ts-ignore
                            label="Included Channels In PPC Package"
                            minimumValue={0}
                          />
                          <WizardStepField
                            name={`PPCCostPerExtraChannel${suffix}`}
                            type={WizardStepField.types.NUMBER}
                            // @ts-ignore
                            label="Cost Per Extra PPC Channel"
                            minimumValue={0}
                          />
                          {!isFixedPlan && (
                            <WizardStepField
                              name={`minimumFee${suffix}`}
                              type={WizardStepField.types.NUMBER}
                              // @ts-ignore
                              label="Minimum PPC fee"
                            />
                          )}
                          {isFixedPlan && (
                            <WizardStepField
                              name={`fixedPrice${suffix}`}
                              type={WizardStepField.types.NUMBER}
                              // @ts-ignore
                              label="Fixed PPC Fee"
                              minimumValue={0}
                              maximumValue={1000000}
                            />
                          )}
                          {billingConfigurationPlanName === PpcBillingModelPlanName.meteredPlan20180101 && (
                            <WizardStepField
                              name={`meteredPriceMultiplier${suffix}`}
                              type={WizardStepField.types.NUMBER}
                              // @ts-ignore
                              label="Metered PPC Fee Multiplier"
                              minimumValue={1}
                              maximumValue={100}
                              inputAdornment="%"
                              fullWidth
                            />
                          )}
                        </Grid>
                      )}
                      {loading && (
                        <Grid item xs={12} md={6}>
                          <RefreshSpinner linear />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <div>
                          {projectBillingHelpers.getBillingPlanHint(billingConfigurationPlanName, billingPlanPairs)}
                        </div>
                        <p>
                          For every base PPC cost, an additional of{' '}
                          {safeExtractCurrencyValue(PPCCostPerExtraChannel || 0)} will be added for each media channel
                          added over the included {PPCIncludedChannelCount || 0} channels in this cycle.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <WizardStepField
                          name={`forcePPCBillingEvenWithZeroSpend${suffix}`}
                          type={WizardStepField.types.CHECKBOX}
                          // @ts-ignore
                          inputLabel="Bill for PPC services even if total media spend is $0?"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextDisplay variant="caption">
                          If this is checked, then even a spend of $0 will incur a fee for the PPC service. If it isn't
                          checked, then a spend of $0 will be viewed as if the PPC service was not executed, hence there
                          will be no PPC service fee.
                        </TextDisplay>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <WizardStepField
                          name={`PPCDiscount${suffix}`}
                          type={WizardStepField.types.NUMBER}
                          // @ts-ignore
                          label="PPC Discount % (0-100)"
                          minimumValue={0}
                          maximumValue={100}
                          inputAdornment="%"
                        />
                      </Grid>
                      {allowDiscountEdit && enablePpcBillingPlanEdit && (
                        <Grid item xs={12} md={6} lg={3}>
                          <WizardStepField
                            name={`PPCDiscountRecurring${suffix}`}
                            type={WizardStepField.types.CHECKBOX}
                            // @ts-ignore
                            inputLabel="Is PPC Discount Recurring?"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}

      {enableNonPpcServicesEdit && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Non-PPC Services</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {showNonPpcRules && (
                  <Grid item xs={12}>
                    <CalloutMessage
                      message={
                        <span>
                          A service that continues to the next monthly cycle will automatically be included in the next
                          monthly cycle. Modifying it will also automatically modify it in the next monthly cycle.{' '}
                          <br />
                          You cannot directly modify a service in the next monthly cycle that was created in this way.
                        </span>
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Field
                    component={AdditionalServicesField}
                    name={`additionalServices${suffix}`}
                    validate={[validateAdditionalServices]}
                    project={project}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextDisplay bold variant="h6" gutterBottom>
                    Settings for all non-PPC services
                  </TextDisplay>
                </Grid>
                <Grid item xs={12} md={4}>
                  <WizardStepField
                    name={`nonPPCDiscount${suffix}`}
                    type={WizardStepField.types.NUMBER}
                    // @ts-ignore
                    label="Non-PPC Discount % (0-100)"
                    minimumValue={0}
                    maximumValue={100}
                    inputAdornment="%"
                  />
                </Grid>
                {allowDiscountEdit && (
                  <Grid item xs={12} md={4}>
                    <WizardStepField
                      name={`nonPPCDiscountRecurring${suffix}`}
                      type={WizardStepField.types.CHECKBOX}
                      // @ts-ignore
                      inputLabel="Is Non-PPC Discount Recurring?"
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Divider />
        </>
      )}

      {enableDeliverablesEdit && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Deliverables</AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextDisplay bold variant="h6" gutterBottom>
                      Deliverable Details
                    </TextDisplay>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={DeliverablesField}
                      name={`deliverables${suffix}`}
                      validate={[validateDeliverables]}
                      project={project}
                    />
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}

      {enableSetupServiceEdit && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>One-Time/Setup Simple Service</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CalloutMessage
                    message="This service is charged in a simple, straightforward manner - it is not prorated. That is,
                  it is billed exactly by its cost. Actual active days in the monthly cycle are not taken into account."
                  />
                </Grid>

                <Grid item xs={12}>
                  <WizardStepField
                    name={`setupServiceCost${suffix}`}
                    type={WizardStepField.types.NUMBER}
                    // @ts-ignore
                    label="Cost (optional)"
                    minimumValue={0}
                    maximumValue={1000000}
                    inputAdornment="$"
                  />
                </Grid>

                {enableSetupServiceEdit && (
                  <Grid item xs={12}>
                    <WizardStepField
                      name={`setupServiceDescription${suffix}`}
                      type={WizardStepField.types.TEXT}
                      // @ts-ignore
                      label="Description (optional)"
                      minLength={10}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextDisplay bold variant="h6" gutterBottom>
                    Settings
                  </TextDisplay>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <WizardStepField
                    name={`setupServiceDiscount${suffix}`}
                    type={WizardStepField.types.NUMBER}
                    // @ts-ignore
                    label="Discount % (0-100)"
                    minimumValue={0}
                    maximumValue={100}
                    inputAdornment="%"
                  />
                </Grid>
                {allowDiscountEdit && (
                  <Grid item xs={12} md={6} lg={4}>
                    <WizardStepField
                      name={`setupServiceDiscountRecurring${suffix}`}
                      type={WizardStepField.types.CHECKBOX}
                      // @ts-ignore
                      inputLabel="Is Discount Recurring?"
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Marketer cut</AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <TextDisplay bold variant="h6">
                Marketer cut configuration
              </TextDisplay>
              <TextDisplay variant="subtitle2" gutterBottom>
                Marketer cut configuration affects the split of the cycle total cost between marketer and Mayple.
              </TextDisplay>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <WizardStepField
                    name={`marketerFeeConfigurationPlanName${suffix}`}
                    type={WizardStepField.types.SELECT}
                    // @ts-ignore
                    label="Marketer cut plan"
                    enums={MARKETER_FEE_PLAN_PAIRS}
                  />
                  {marketerFeeConfigurationPlanName === MarketerFeePlanName.percentage && (
                    <>
                      <WizardStepField
                        name={`feePartPercentage${suffix}`}
                        type={WizardStepField.types.NUMBER}
                        // @ts-ignore
                        label="Marketer cut % (0-100)"
                        minimumValue={0}
                        maximumValue={100}
                        inputAdornment="%"
                      />
                      <WizardStepField
                        name={`minimumFeePart${suffix}`}
                        type={WizardStepField.types.NUMBER}
                        // @ts-ignore
                        label="Minimum marketer cut"
                        minimumValue={0}
                        maximumValue={1000000}
                      />
                      <WizardStepField
                        name={`maximumFeePart${suffix}`}
                        type={WizardStepField.types.NUMBER}
                        // @ts-ignore
                        label="Maximum marketer cut"
                        minimumValue={0}
                        maximumValue={1000000}
                      />
                      {showMaximumMarketerFeeWarning && (
                        <CalloutMessage
                          margin
                          type="warning"
                          message="Setting Maximum Marketer cut to 0 - means the marketer won't get any fee."
                        />
                      )}
                    </>
                  )}
                  {showMarketerFixedInput && (
                    <WizardStepField
                      name={`marketerFixedFee${suffix}`}
                      type={WizardStepField.types.NUMBER}
                      // @ts-ignore
                      label="Fixed cut"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={7}>
                  <div
                    style={{
                      padding: '1rem',
                      background: 'lightyellow',
                      borderRadius: '1rem',
                      boxShadow: `0px 2px 5px ${colors.blackOpacity(0.1)}`,
                    }}
                  >
                    {projectBillingHelpers.getMarketerFeePlanHint(
                      marketerFeeConfigurationPlanName,
                      feePartPercentage,
                      minimumFeePart,
                      marketerFixedFee,
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Divider />
    </div>
  );
};

export default CycleStep;
