import { Package, PackageKey } from '@mayple/types';

const getPackageKey = (p: Package | null | undefined): PackageKey | null => {
  if (!p) {
    return null;
  }

  const { category, chapter, offering, variant, version } = p || {};

  return {
    category,
    chapter,
    offering,
    variant,
    version,
  };
};

export default getPackageKey;
