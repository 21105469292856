import React from 'react';
import { FilterRender } from 'react-table-6';

const YesNoFilter: FilterRender = (props) => {
  const { filter, onChange } = props;
  return (
    <select
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
};

export default YesNoFilter;
