import React, { FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { SectionHeaderProps } from './types';
import { TextDisplay } from '../index';
import { TextDisplayProps } from '../TextDisplay';

import useStyles from './style';

const SectionHeader: FC<SectionHeaderProps> = (props) => {
  const { title, subtitle, avatarUrl, titleProps, subtitleProps } = props;

  const classes = useStyles(props);

  const finalTitleProps: TextDisplayProps = {
    variant: 'h3',
    bold: true,
    gutterBottom: true,
    ...titleProps,
  };

  const finalSubtitleProps: TextDisplayProps = {
    variant: 'h6',
    ...subtitleProps,
  };

  return (
    <ListItem className={classes.root}>
      {avatarUrl && (
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar src={avatarUrl} className={classes.avatar} />
        </ListItemAvatar>
      )}
      <ListItemText
        disableTypography
        primary={
          <TextDisplay {...finalTitleProps} className={classes.primary}>
            {title}
          </TextDisplay>
        }
        secondary={
          subtitle && (
            <TextDisplay {...finalSubtitleProps} className={classes.secondary} color="textSecondary">
              {subtitle}
            </TextDisplay>
          )
        }
      />
    </ListItem>
  );
};

export default SectionHeader;
