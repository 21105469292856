import React, { FC } from 'react';
import { Marketer, MarketerType } from '@mayple/types';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { DisableMarketerMutation } from 'growl-graphql/dist/mutations/DisableMarketerMutation';
import DisableEntityControl from '../../../containers/shared/DisableEntityControl';

import MarketerExtraDetails from '../components/MarketerExtraDetails';
import EntityContactDetails from '../../../components/cpanel/components/molecules/EntityContactDetails';
import SuccessStoriesPanels from '../../../components/cpanel/components/molecules/SuccessStoriesPanels';
import EmploymentHistoryPanels from '../../../components/cpanel/components/molecules/EmploymentHistoryPanels';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import ExpertProfileCapabilities from '../../../../fe_common/client/components/organisms/ExpertProfileCapabilities';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import { useMutation } from '../../../../fe_common/client/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      paddingTop: 24,
      marginTop: 24,
    },
  }),
);

interface MarketerBasicDetailsTabProps {
  marketer: Marketer;
  classes?: Record<string, string>;
}

const MarketerBasicDetailsTab: FC<MarketerBasicDetailsTabProps> = (props) => {
  const { marketer } = props;

  const classes = useStyles();
  const { id: marketerId, name: marketerName } = marketer as Marketer;

  const { mutate: disableMarketerMutation, loading: savingData } = useMutation(DisableMarketerMutation);

  return (
    <div className={classes.root}>
      <Section title="Contact details" layout={SECTION_LAYOUT.ONE_THIRD_TWO_THIRDS} noBorder noTopMargin>
        <SectionContent>
          <EntityContactDetails contactDetails={marketer?.marketerProfile?.contactDetails} />
          <MarketerExtraDetails marketer={marketer} />
        </SectionContent>
      </Section>

      {marketer?.marketerCapabilities && (
        <Section title="Marketer Capabilities">
          <ExpertProfileCapabilities expert={marketer?.marketerCapabilities} />
        </Section>
      )}

      {marketer?.marketerProfile?.successStories && (
        <Section title="Success stories" classes={{ root: classes.sectionRoot }}>
          <SuccessStoriesPanels successStories={marketer?.marketerProfile?.successStories} />
        </Section>
      )}

      {marketer?.marketerProfile?.employmentHistory &&
        marketer?.marketerProfile?.marketerType === MarketerType.FREELANCER && (
          <Section title="Employment history" classes={{ root: classes.sectionRoot }}>
            <EmploymentHistoryPanels employmentHistory={marketer?.marketerProfile?.employmentHistory} />
          </Section>
        )}

      <Section>
        <DisableEntityControl
          mutation={disableMarketerMutation}
          variables={{ marketerId }}
          entity={marketer}
          title="Disable Marketer"
          buttonLabel="Disable Marketer"
          warningText={`You are about to disable marketer ${marketerName} with ID: ${marketerId}`}
          savingData={savingData}
        />
      </Section>
    </div>
  );
};

export default MarketerBasicDetailsTab;
