/* eslint-disable react/display-name */
import React from 'react';
import { ProjectStage } from '@mayple/types';
import { Column, RowRenderProps } from 'react-table-6';
import { Link } from 'react-router-dom';

import LinkToCompany from '../../../components/cpanel/components/atoms/Links/LinkToCompany';

import {
  ProjectLifeCycleFilter,
  ProjectSpecialSubTypeFilter,
  ProjectSpecialTypeFilter,
  ProjectStageFilter,
} from '../../../../fe_common/client/services/reactTableHelpers/filters';
import {
  projectLifeCycleFilterMethod,
  projectSpecialSubTypeFilterMethod,
  projectSpecialTypeFilterMethod,
} from '../../../../fe_common/client/services/reactTableHelpers/filterMethods';
import { stageTitle } from '../../../../fe_common/client/logic/projectStage';

const columns: Column[] = [
  {
    Header: 'Name',
    accessor: (d) => d.name,
    id: 'name',
    Cell: (d: RowRenderProps) => <Link to={`/projects/${d.original.id}`}>{d.value}</Link>,
  },
  {
    id: 'projectLifecycleStatus',
    Header: 'Status',
    accessor: 'projectLifeCycleStatus',
    Filter: ProjectLifeCycleFilter,
    filterMethod: projectLifeCycleFilterMethod,
  },
  {
    Header: 'Stage',
    accessor: 'stage',
    id: 'stage',
    Filter: ProjectStageFilter,
    filterMethod: projectLifeCycleFilterMethod,
    Cell: (d: RowRenderProps) => <span>{stageTitle[(d?.original?.stage as ProjectStage) ?? '']}</span>,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Company',
    accessor: (d) => d?.company?.name,
    id: 'company',
    Cell: (d: RowRenderProps) => (
      <LinkToCompany companyId={d?.original?.company?.id}>{d?.original?.company?.name ?? ''}</LinkToCompany>
    ),
  },
  {
    Header: 'Project Type',
    accessor: 'specialType',
    id: 'specialType',
    Filter: ProjectSpecialTypeFilter,
    filterMethod: projectSpecialTypeFilterMethod,
  },
  {
    Header: 'Sub Type',
    accessor: 'specialSubType',
    id: 'specialSubType',
    Filter: ProjectSpecialSubTypeFilter,
    filterMethod: projectSpecialSubTypeFilterMethod,
  },
];

export default columns;
