import React, { useCallback } from 'react';
import { EntityOperationResponse } from '@mayple/types';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { ExecutionResult } from 'graphql';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import CardContent from '@material-ui/core/CardContent';
import { ApproveProjectLeadMutation } from 'growl-graphql/dist/mutations/ApproveProjectLeadMutation';

import { TextDisplay } from '../../../../fe_common/client/components/display';
import { Button } from '../../../../fe_common/client/components/inputs';
import { useMutation } from '../../../../fe_common/client/hooks';
import { clientLogger, handleClientError } from '../../../../fe_common/client/services/logger';
import { setEntityOperationResponseNotification } from '../../../../fe_common/client/services/notification';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface ApproveProjectLeadCardProps {
  projectLeadId: number;
  onSuccess?: () => void;
}

const ApproveProjectLeadCard: React.FC<ApproveProjectLeadCardProps> = (props) => {
  const classes = useStyles(props);
  const { projectLeadId, onSuccess } = props;

  const { loading: savingApproveProjectLead, mutate: approveProjectLead } = useMutation(ApproveProjectLeadMutation);

  const handleApproveProjectLead = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    const ans = confirm(`Are you sure you want to approve this lead ID(${projectLeadId}) ?`);

    if (ans) {
      try {
        const variables = { projectLeadId };

        const response: ExecutionResult<{ approveProjectLead: EntityOperationResponse }> = await approveProjectLead({
          variables,
        });

        clientLogger.debug(`approveProjectLeadMutation response:\n\n${JSON.stringify(response, null, 2)}`);

        setEntityOperationResponseNotification(response?.data?.approveProjectLead);

        if (!response?.data?.approveProjectLead?.success) {
          throw new Error(response?.data?.approveProjectLead?.result);
        }

        onSuccess?.();
      } catch (e) {
        handleClientError(e, 'Unable to approve lead');
      }
    }
  }, [approveProjectLead, onSuccess, projectLeadId]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <TextDisplay variant="h6">Approve lead</TextDisplay>
        <p>
          Approving a lead means that a company and a project will be created. The Mayple marketer entity could create a
          package for the lead, even before he registered. Once the lead will receive his email with the package details
          he could finish the sign up and have his package ready for payment.
        </p>
        <div>
          <Button
            onClick={handleApproveProjectLead}
            label="APPROVE"
            color="primary"
            variant="contained"
            loading={savingApproveProjectLead}
          />
        </div>
        <p>
          Whats next ? You should now see the project and company entity in the <Link to="/projects">projects</Link>{' '}
          page.
        </p>
      </CardContent>
    </Card>
  );
};

export default ApproveProjectLeadCard;
