import React, { useCallback, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useAllTimezones from './useAllTimezones';
import { TimezoneOption, TimezoneSelectProps } from './types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

export const getTimezoneOption = (
  allTimeZones: TimezoneOption[],
  timezone: string | undefined
): TimezoneOption | null => allTimeZones.find((tz) => tz.value === timezone) || null;

const TimezoneSelect: React.FC<TimezoneSelectProps> = (props) => {
  const { value, showTimezoneOffset = false, defaultTimezone, onChange } = props;

  const classes = useStyles(props);

  const allTimeZones = useAllTimezones(showTimezoneOffset);

  const [timezone, setTimezone] = useState<TimezoneOption | null>(
    () => getTimezoneOption(allTimeZones, defaultTimezone) || value || null
  );

  const onChangeHandler = useCallback(
    (_event: any, newTimezone: TimezoneOption | null) => {
      setTimezone(newTimezone);
      onChange?.(newTimezone?.value || null);
    },
    [onChange]
  );

  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label="Timezone" variant="outlined" />}
      options={allTimeZones}
      getOptionLabel={(option: TimezoneOption) => option.label}
      getOptionSelected={(option: TimezoneOption, selectedValue: TimezoneOption) =>
        option.value === selectedValue.value
      }
      value={timezone}
      onChange={onChangeHandler}
      className={classes.root}
    />
  );
};

export default TimezoneSelect;
