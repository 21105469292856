import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

import AutocompleteField from '../../atoms/Registration/AutocompleteField';
import { Button } from '../../inputs';
import CalloutMessage from '../CalloutMessage';
import categoriesEditor from './logic';

const useStyles = makeStyles({
  dialogContent: {
    overflow: 'visible',
    minHeight: 321,
    minWidth: 500,
    marginTop: 8,
  },
  root: { overflow: 'visible' },
  btnContainer: { margin: '24px 0' },
});

function CategoriesEditor(props) {
  // console.log('CategoriesEditor props', props);
  const classes = useStyles();
  const {
    enums,
    input,
    labels,
    maxItems,
    dialogTitle,
    buttonLabel,
    categoryName,
    categoryPlaceholder,
    subCategoryPlaceholder,
    selectMultiSubCategories,
  } = props;

  const items = get(props, 'input.value') || [];
  const error = get(props, 'meta.error');
  const touched = get(props, 'meta.touched');

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [categoryToEdit, setCategoryToEdit] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(selectMultiSubCategories ? [] : null);

  const resetSubCategories = () => {
    setSelectedSubCategory(selectMultiSubCategories ? [] : null);
  };

  const handleOnCategoryChange = (selectedCat) => {
    setSelectedCategory(selectedCat);
  };

  const handleSave = () => {
    categoriesEditor.onSave(items, selectMultiSubCategories, categoryToEdit, selectedSubCategory, input);

    setSelectedCategory(null);
    resetSubCategories();
    setCategoryToEdit(null);
    setDialogOpen(false);
  };

  const handleDelete = (toDelete) => {
    categoriesEditor.onDelete(toDelete, items, input);

    setSelectedCategory(null);
    resetSubCategories();
    setCategoryToEdit(null);
    setDialogOpen(false);
  };

  const handleEdit = (subCategoriesSelected, category) => {
    const { subCategoryToEdit, categoryToEditItem } = categoriesEditor.onEdit(
      subCategoriesSelected,
      category,
      selectMultiSubCategories,
      items,
      enums,
      labels
    );

    setSelectedCategory(categoryToEditItem);
    setSelectedSubCategory(subCategoryToEdit);
    setCategoryToEdit(subCategoryToEdit);
    setDialogOpen(true);
  };

  const itemsToRender = categoriesEditor.groupCategories(items, categoryName);
  const isMaxedOut = itemsToRender.length >= maxItems && maxItems != null;

  return (
    <div>
      {itemsToRender &&
        itemsToRender.length > 0 &&
        itemsToRender.map(({ value, category }) => (
          <List key={`CategoriesEditor-key-r-${value}-${category}`}>
            <ListItem>
              <ListItemText primary={labels[category]} secondary={categoriesEditor.createLabelFromGrouped(value)} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    handleEdit(value, category);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}

      <div className={classes.btnContainer}>
        <Button
          label={buttonLabel}
          variant="outlined"
          onClick={() => {
            setDialogOpen(true);
          }}
          disabled={isMaxedOut}
        />
      </div>

      {!!error && touched && <CalloutMessage type="error" message={error} />}

      {isMaxedOut && <CalloutMessage type="info" message={`You can’t add more than ${maxItems}`} />}

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        PaperProps={{ classes: { root: classes.root } }}
      >
        <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            <AutocompleteField
              label={categoryPlaceholder}
              autoFocus
              enums={enums}
              input={{
                value: selectedCategory,
                onChange: handleOnCategoryChange,
              }}
            />
            {!!selectedCategory && (
              <div style={{ marginTop: 24 }}>
                <AutocompleteField
                  label={subCategoryPlaceholder}
                  autoFocus
                  enums={selectedCategory.options}
                  input={{
                    value: selectedSubCategory,
                    onChange: setSelectedSubCategory,
                  }}
                  isMulti={selectMultiSubCategories}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {!!categoryToEdit && (
            <Button
              label="Delete"
              color="primary"
              onClick={() => {
                handleDelete(selectedSubCategory);
              }}
              disabled={!selectedSubCategory}
            />
          )}
          <Button
            label="Cancel"
            color="primary"
            onClick={() => {
              setDialogOpen(false);
              setCategoryToEdit(null);
              setSelectedCategory(null);
              resetSubCategories();
            }}
          />
          <Button
            label="Save"
            variant="contained"
            color="primary"
            disabled={!selectedSubCategory || !selectedCategory}
            onClick={handleSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

CategoriesEditor.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  enums: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  meta: PropTypes.object,
  dialogTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  categoryPlaceholder: PropTypes.string,
  subCategoryPlaceholder: PropTypes.string,
  categoryName: PropTypes.string,
  maxItems: PropTypes.number,
  selectMultiSubCategories: PropTypes.bool,
};

CategoriesEditor.defaultProps = {
  selectMultiSubCategories: false,
  maxItems: null,
  meta: null,
  dialogTitle: 'Add new option',
  buttonLabel: 'Add new option',
  categoryPlaceholder: 'Category',
  subCategoryPlaceholder: 'Subcategory',
  categoryName: 'categoryName',
};

export default CategoriesEditor;
