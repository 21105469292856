/* eslint-disable class-methods-use-this,no-underscore-dangle */
import { ReactNode } from 'react';
import {
  PpcBillingModelPlanName,
  MarketerFeePlanName,
  ProjectLifeCycleStatus,
  ProjectCyclePhase,
  MediaType,
  ProjectCycleOperationMode,
  Project,
  MarketerFeeConfiguration,
  Deliverable,
  BillingConfiguration,
  ProjectCycleUpdate,
  ProjectCyclePauseDuration,
  ProjectCycle,
  AdditionalService,
  MediaBudgetDivision,
  MediaSpendDivision,
} from '@mayple/types';
import omit from 'lodash/omit';
import diff from 'variable-diff';
import { FormErrors, SubmissionError } from 'redux-form';
import moment from 'moment';

import { handleClientError, clientLogger } from '../../../fe_common/client/services/logger';
import { arrayOfObjectsToHashTable, tryParseInt } from '../../../fe_common/client/services/utils';
import { toFloat } from '../../app/utils';
import {
  setEntityOperationResponseNotification,
  setNotification,
} from '../../../fe_common/client/services/notification';
import { validateInteger, validateIntegerBetween, validateRequired } from '../../services/validation';
import { SharedValuesInput, UpdateCyclePauseDurationsErrors } from './types';

/*
 * Contains project billing page related logic.
 * */
class ProjectBilling {
  _getCycleSuffix(isNextCycle: boolean): string {
    return isNextCycle ? 'NextCycle' : '';
  }

  _getProjectCycleId(project: Project | null | undefined, isNextCycle: boolean): number | null | undefined {
    if (isNextCycle) {
      return project?.nextCycle?.id;
    }

    return project?.currentCycle?.id;
  }

  _getIsOperatedAutomaticallyByProjectPackages(project: Project | null | undefined) {
    return project?.projectCycleOperationMode === ProjectCycleOperationMode.OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES;
  }

  _getAdditionalServiceForUpdate(additionalServices: Array<Record<string, any>>): Array<Record<string, any>> {
    // console.log('_getAdditionalServiceForUpdate additionalServices', additionalServices);

    return (Object.values(additionalServices) ?? []).map((service) => {
      const { description } = service;

      return {
        ...omit(service, [
          'marketingServiceTypeInfo',
          'packageKey',
          'packageCollections',
          'name',
          'deprecated',
          'isDefault',
          '__typename',
        ]),
        cost: Math.round(service?.cost),
        description: {
          contents: description,
          mediaType: MediaType.TEXT_HTML,
        },
        flavor: service.flavor || '',
        variableFactorTitle: service.variableFactorTitle || '',
        variableFactorUnit: service.variableFactorUnit || '',
        variableFactorCount: tryParseInt(service.variableFactorCount, 0),
      };
    });
  }

  _getDeliverableForUpdate(deliverables: Array<Deliverable>) {
    return (Object.values(deliverables) ?? []).map((deliverable) => ({
      ...omit(deliverable, ['marketingServiceTypeInfo', 'name', 'isDefault', '__typename']),
      serviceType: deliverable.serviceType,
      unitType: deliverable.unitType || '',
      unitDescription: deliverable.unitDescription,
      unitCost: Math.round(deliverable.unitCost),
      unitAmount: tryParseInt(deliverable.unitAmount, 0),
      discountPercentage: tryParseInt(deliverable.discountPercentage, 0),
      fulfillmentBehavior: deliverable.fulfillmentBehavior,
    }));
  }

  /**
   * Compares the initial values, and the form values.
   * Returns true if it should run the update func.
   * ******************* Make sure to update the keys when removing or adding fields. **********************************
   * @param formValues
   * @param keys
   * @param suffix
   * @returns {boolean}
   * @private
   */
  _shouldUpdate(formValues: Record<string, any>, keys: Set<string>, suffix = ''): boolean {
    let shouldUpdate = false;
    // Only update if changes made.
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      if (
        JSON.stringify(formValues[`${key}${suffix}`]) !== JSON.stringify(formValues.initialValues[`${key}${suffix}`])
      ) {
        shouldUpdate = true;
        break;
      }
    }

    return shouldUpdate;
  }

  _getMarketerFeeConfig(formValues: Record<string, any>, suffix: string): MarketerFeeConfiguration {
    const { initialValues } = formValues;
    const feePartPercentage = formValues[`feePartPercentage${suffix}`];
    const minimumFeePart = formValues[`minimumFeePart${suffix}`];
    const maximumFeePart = formValues[`maximumFeePart${suffix}`];
    const marketerFeeConfigurationPlanName = formValues[`marketerFeeConfigurationPlanName${suffix}`];
    const marketerFixedFee = formValues[`marketerFixedFee${suffix}`];

    const marketerFeeConfiguration: Partial<MarketerFeeConfiguration> = {
      planName: marketerFeeConfigurationPlanName,
    };

    /* ADDITIONAL MARKETER FEE RELATED FIELDS */

    if (marketerFeeConfigurationPlanName === MarketerFeePlanName.percentage) {
      // Set the percentage fields
      marketerFeeConfiguration.feePartPercentage = toFloat(
        feePartPercentage / 100,
        initialValues[`feePartPercentage${suffix}`],
      );
      marketerFeeConfiguration.minimumFeePart = toFloat(minimumFeePart, initialValues[`minimumFeePart${suffix}`]);
      marketerFeeConfiguration.maximumFeePart = toFloat(maximumFeePart, initialValues[`maximumFeePart${suffix}`]);
      // And reset the rest
      marketerFeeConfiguration.fixedFee = 0;
    }

    if (
      marketerFeeConfigurationPlanName === MarketerFeePlanName.fixedToMayple ||
      marketerFeeConfigurationPlanName === MarketerFeePlanName.fixedToMarketer
    ) {
      // Set the fixed fee
      marketerFeeConfiguration.fixedFee = toFloat(marketerFixedFee);
      // And reset the rest
      marketerFeeConfiguration.feePartPercentage = 0;
      marketerFeeConfiguration.minimumFeePart = 0;
      marketerFeeConfiguration.maximumFeePart = 0;
    }

    return marketerFeeConfiguration as MarketerFeeConfiguration;
  }

  /**
   * This function might change the values of discounts configuration based on different inputs and project status.
   * Based on https://perfpie.atlassian.net/browse/DEV-4683
   * @param formValues
   * @param isNextCycle
   * @param project
   * @returns {{PPCDiscount: *, setupServiceDiscount: *, setupServiceDiscountRecurring: boolean, PPCDiscountRecurring:
   *   boolean, nonPPCDiscountRecurring: boolean, nonPPCDiscount: *}}
   * @private
   */
  _getCycleDiscountsConfig(formValues: Record<string, any>, isNextCycle: boolean, project: Project | null | undefined) {
    const suffix = this._getCycleSuffix(isNextCycle);
    const PPCDiscount = formValues[`PPCDiscount${suffix}`];
    const nonPPCDiscount = formValues[`nonPPCDiscount${suffix}`];
    const setupServiceDiscount = formValues[`setupServiceDiscount${suffix}`];
    const forcePPCBillingEvenWithZeroSpend = formValues[`forcePPCBillingEvenWithZeroSpend${suffix}`];
    let PPCDiscountRecurring = formValues[`PPCDiscountRecurring${suffix}`];
    let nonPPCDiscountRecurring = formValues[`nonPPCDiscountRecurring${suffix}`];
    let setupServiceDiscountRecurring = formValues[`setupServiceDiscountRecurring${suffix}`];

    const { isDiscovery, isOnboarding, isLive } = this.getProjectLifeCycles(project);

    if (isLive && !isNextCycle) {
      /*
       * For current cycles only in live projects, set the recurring flag to false.
       * It has no effect in live, because the new generated cycle will be copied from next cycle values.
       * */
      PPCDiscountRecurring = false;
      setupServiceDiscountRecurring = false;
      nonPPCDiscountRecurring = false;
    } else if (isDiscovery || isOnboarding || isLive) {
      // If the discounts are 0, always send false for recurring flags
      if (PPCDiscount === 0) {
        PPCDiscountRecurring = false;
      }
      if (setupServiceDiscount === 0) {
        setupServiceDiscountRecurring = false;
      }
      if (nonPPCDiscount === 0) {
        nonPPCDiscountRecurring = false;
      }
    }

    return {
      PPCDiscount,
      nonPPCDiscount,
      setupServiceDiscount,
      PPCDiscountRecurring,
      nonPPCDiscountRecurring,
      setupServiceDiscountRecurring,
      forcePPCBillingEvenWithZeroSpend,
    };
  }

  /**
   * This will update the admin only values related to the billing config.
   * @param formValues
   * @param project
   * @param mutate
   * @param isNextCycle
   * @returns {Promise<void>}
   * @private
   */
  async _updateOngoingProjectCycleBillingConfig(
    formValues: Record<string, any>,
    project: Project | null | undefined,
    mutate: any,
    isNextCycle = false,
  ) {
    const isOperatedAutomaticallyByProjectPackages = this._getIsOperatedAutomaticallyByProjectPackages(project);
    const projectCycleId = this._getProjectCycleId(project, isNextCycle);

    const suffix = this._getCycleSuffix(isNextCycle);

    const billingKeys = new Set([
      'meteredPriceMultiplier',
      'feePartPercentage',
      'fixedPrice',
      'minimumFee',
      'PPCDiscount',
      'nonPPCDiscount',
      'setupServiceDiscount',
      'minimumFeePart',
      'maximumFeePart',
      'billingConfigurationPlanName',
      'marketerFeeConfigurationPlanName',
      'marketerFixedFee',
      'PPCDiscountRecurring',
      'nonPPCDiscountRecurring',
      'setupServiceDiscountRecurring',
      'forcePPCBillingEvenWithZeroSpend',
      'PPCIncludedChannelCount',
      'PPCCostPerExtraChannel',
    ]);

    const shouldUpdate = this._shouldUpdate(formValues, billingKeys, suffix);

    if (!shouldUpdate) {
      // setNotification(`Skipping update for ${isNextCycle ? 'next' : 'current'} cycle billing due to no changes`);
      return true;
    }

    const { initialValues } = formValues;
    const meteredPriceMultiplier = formValues[`meteredPriceMultiplier${suffix}`];
    const fixedPrice = formValues[`fixedPrice${suffix}`];
    const minimumFee = formValues[`minimumFee${suffix}`];
    const billingConfigurationPlanName = formValues[`billingConfigurationPlanName${suffix}`];
    const PPCIncludedChannelCount = formValues[`PPCIncludedChannelCount${suffix}`];
    const PPCCostPerExtraChannel = formValues[`PPCCostPerExtraChannel${suffix}`];
    const {
      PPCDiscount,
      nonPPCDiscount,
      setupServiceDiscount,
      PPCDiscountRecurring,
      nonPPCDiscountRecurring,
      setupServiceDiscountRecurring,
      forcePPCBillingEvenWithZeroSpend,
    } = this._getCycleDiscountsConfig(formValues, isNextCycle, project);

    const projectCycleUpdate: {
      billingConfiguration: BillingConfiguration;
      marketerFeeConfiguration: MarketerFeeConfiguration;
    } = {
      billingConfiguration: {
        PPCDiscount,
        nonPPCDiscount,
        setupServiceDiscount,
        PPCDiscountRecurring,
        nonPPCDiscountRecurring,
        setupServiceDiscountRecurring,
        forcePPCBillingEvenWithZeroSpend,
        PPCIncludedChannelCount,
        PPCCostPerExtraChannel,
        planName: billingConfigurationPlanName,
        meteredPriceMultiplier: toFloat(meteredPriceMultiplier / 100, initialValues[`meteredPriceMultiplier${suffix}`]),
        minimumFee: toFloat(minimumFee, initialValues[`minimumFee${suffix}`]),
      },
      marketerFeeConfiguration: this._getMarketerFeeConfig(formValues, suffix),
    };

    /* ADDITIONAL CYCLE BILLING RELATED FIELDS */
    if (billingConfigurationPlanName === PpcBillingModelPlanName.fixedPlan) {
      projectCycleUpdate.billingConfiguration.fixedPrice = toFloat(fixedPrice, initialValues[`fixedPrice${suffix}`]);
      projectCycleUpdate.billingConfiguration.minimumFee = 0;
    } else {
      projectCycleUpdate.billingConfiguration.fixedPrice = 0;
    }

    // if OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES then remove billingConfiguration
    if (isOperatedAutomaticallyByProjectPackages && projectCycleUpdate.billingConfiguration) {
      // @ts-ignore
      delete projectCycleUpdate.billingConfiguration;
    }

    /* END */

    const updateVars = {
      projectId: project?.id,
      projectCycleId,
      projectCycleUpdate,
    };

    clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateVars, null, 2)}`);

    try {
      const response = await mutate(updateVars);
      // clientLogger.debug(`Response :\n\n${JSON.stringify(response, null, 2)}`);

      if (response.id) {
        setNotification(
          // eslint-disable-next-line max-len
          `Successfully updated configuration for ${isNextCycle ? 'next' : 'current'} cycle ID ${response.id}`,
          'success',
        );
      }
    } catch (e) {
      handleClientError(e);
      setNotification(`Failed updating ${isNextCycle ? 'next' : 'current'} cycle`, 'error');

      return false;
    }

    return true;
  }

  /**
   * This will update the admin only values related to the billing config.
   * @param formValues
   * @param project
   * @param mutate
   * @param projectCycleId
   * @param isNextCycle {Boolean}
   * @returns {Promise<void>}
   * @private
   */
  async _updateCycleBillingConfig(
    formValues: Record<string, any>,
    project: Project,
    mutate: any,
    projectCycleId: number,
    isNextCycle = false,
  ) {
    const suffix = this._getCycleSuffix(isNextCycle);
    const isOperatedAutomaticallyByProjectPackages = this._getIsOperatedAutomaticallyByProjectPackages(project);

    const billingKeys = new Set([
      'meteredPriceMultiplier',
      'feePartPercentage',
      'fixedPrice',
      'minimumFee',
      'PPCDiscount',
      'nonPPCDiscount',
      'setupServiceDiscount',
      'minimumFeePart',
      'maximumFeePart',
      'billingConfigurationPlanName',
      'marketerFeeConfigurationPlanName',
      'marketerFixedFee',
      'PPCDiscountRecurring',
      'nonPPCDiscountRecurring',
      'setupServiceDiscountRecurring',
      'forcePPCBillingEvenWithZeroSpend',
      'PPCIncludedChannelCount',
      'PPCCostPerExtraChannel',
    ]);

    const shouldUpdate = this._shouldUpdate(formValues, billingKeys, suffix);

    if (!shouldUpdate) {
      // setNotification(`Skipping update for cycle ${projectCycleId} billing due to no changes`);
      return true;
    }

    const { initialValues } = formValues;
    const meteredPriceMultiplier = formValues[`meteredPriceMultiplier${suffix}`];
    const fixedPrice = formValues[`fixedPrice${suffix}`];
    const minimumFee = formValues[`minimumFee${suffix}`];
    const billingConfigurationPlanName = formValues[`billingConfigurationPlanName${suffix}`];
    const PPCIncludedChannelCount = formValues[`PPCIncludedChannelCount${suffix}`];
    const PPCCostPerExtraChannel = formValues[`PPCCostPerExtraChannel${suffix}`];
    const {
      PPCDiscount,
      nonPPCDiscount,
      setupServiceDiscount,
      PPCDiscountRecurring,
      nonPPCDiscountRecurring,
      setupServiceDiscountRecurring,
      forcePPCBillingEvenWithZeroSpend,
    } = this._getCycleDiscountsConfig(formValues, false, project);

    const projectCycleUpdate: {
      billingConfiguration: BillingConfiguration;
      marketerFeeConfiguration: MarketerFeeConfiguration;
    } = {
      billingConfiguration: {
        PPCDiscount,
        nonPPCDiscount,
        setupServiceDiscount,
        PPCDiscountRecurring,
        nonPPCDiscountRecurring,
        setupServiceDiscountRecurring,
        forcePPCBillingEvenWithZeroSpend,
        PPCIncludedChannelCount,
        PPCCostPerExtraChannel,
        planName: billingConfigurationPlanName,
        meteredPriceMultiplier: toFloat(meteredPriceMultiplier / 100, initialValues[`meteredPriceMultiplier${suffix}`]),
        minimumFee: toFloat(minimumFee, initialValues[`minimumFee${suffix}`]),
      },
      marketerFeeConfiguration: this._getMarketerFeeConfig(formValues, suffix),
    };

    /* ADDITIONAL CYCLE BILLING RELATED FIELDS */

    if (billingConfigurationPlanName === PpcBillingModelPlanName.fixedPlan) {
      projectCycleUpdate.billingConfiguration.fixedPrice = toFloat(fixedPrice, initialValues[`fixedPrice${suffix}`]);
      projectCycleUpdate.billingConfiguration.minimumFee = 0;
    } else {
      projectCycleUpdate.billingConfiguration.fixedPrice = 0;
    }

    // if OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES then remove billingConfiguration
    if (isOperatedAutomaticallyByProjectPackages && projectCycleUpdate.billingConfiguration) {
      // @ts-ignore
      delete projectCycleUpdate.billingConfiguration;
    }

    /* END */

    const updateVars = {
      projectId: project?.id,
      projectCycleUpdate,
      projectCycleId,
    };

    clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateVars, null, 2)}`);

    try {
      const response = await mutate({ variables: updateVars });
      clientLogger.debug(`Response :\n\n${JSON.stringify(response, null, 2)}`);

      if (response.data.updateProjectCycle.id) {
        setNotification(
          // eslint-disable-next-line max-len
          `Updated configuration for cycle ID ${projectCycleId}`,
          'success',
        );
      }
    } catch (e) {
      handleClientError(e);
      setNotification('Failed updating cycle', 'error');

      return false;
    }

    return true;
  }

  async _updateElapsedCycleServicesAndDeliverables(
    formValues: Record<string, any>,
    cycleId: number,
    projectId: number,
    updateProjectCycle: any,
    updateProjectCycleAdditionalServices: any,
    isFirstCycle: boolean,
  ): Promise<boolean> {
    // console.log('_updateElapsedCycleServicesAndDeliverables(formValues=', formValues, ')');
    const cycleBudgetKeys = new Set(['mediaBudget', 'startDate']);
    const cycleSetupServiceKeys = new Set(['setupServiceCost', 'setupServiceDescription']);
    const cycleDeliverablesKeys = new Set(['deliverables']);
    const cycleServicesKeys = new Set(['additionalServices']);
    const shouldUpdateBudget = this._shouldUpdate(formValues, cycleBudgetKeys);
    const shouldUpdateSetupService = this._shouldUpdate(formValues, cycleSetupServiceKeys);
    const shouldUpdateDeliverables = this._shouldUpdate(formValues, cycleDeliverablesKeys);
    const shouldUpdateServices = this._shouldUpdate(formValues, cycleServicesKeys);
    let succeeded = true;
    let combinedSuccess = true;

    if (shouldUpdateBudget) {
      try {
        const { mediaBudget, startDate } = formValues;

        const { mediaBudgetDivision } = mediaBudget;

        const projectCycleUpdate: ProjectCycleUpdate = {
          actualMediaSpendDivision: mediaBudgetDivision ? Object.values(mediaBudgetDivision) : [],
        };

        const updateBudgetVars = {
          projectId,
          projectCycleId: cycleId,
          projectCycleUpdate,
        };

        if (isFirstCycle) {
          updateBudgetVars.projectCycleUpdate.startDate = new Date(startDate).toISOString();
        }

        clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

        const updateBudgetResponse = await updateProjectCycle({ variables: updateBudgetVars });

        if (updateBudgetResponse.data.updateProjectCycleWithOperationResult.success) {
          succeeded = true;
          setNotification(`Updated cycle ${cycleId} actual media spend / start date`, 'success');
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating cycle ${cycleId} actual media spend / start date`, 'error');
        succeeded = false;
      }
    } else {
      // setNotification(`Skipping update for cycle ${cycleId} budget due to no changes`);
      succeeded = true;
    }
    combinedSuccess = combinedSuccess && succeeded;

    if (shouldUpdateSetupService) {
      try {
        const { setupServiceCost, setupServiceDescription } = formValues;

        const updateBudgetVars = {
          projectId,
          projectCycleId: cycleId,
          projectCycleUpdate: {
            setupService: {
              cost: setupServiceCost || 0,
              currency: 'USD',
              description: {
                contents: setupServiceDescription || '',
                mediaType: 'TEXT_PLAIN',
              },
            },
          },
        };

        clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

        const updateBudgetResponse = await updateProjectCycle({ variables: updateBudgetVars });

        if (updateBudgetResponse.data.updateProjectCycle.id) {
          succeeded = true;
          setNotification(`Updated cycle ${cycleId} setup service`, 'success');
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating cycle ${cycleId} setup service`, 'error');
        succeeded = false;
      }
    } else {
      // setNotification(`Skipping update for cycle ${cycleId} budget due to no changes`);
      succeeded = true;
    }
    combinedSuccess = combinedSuccess && succeeded;

    if (shouldUpdateDeliverables) {
      try {
        const { deliverables } = formValues;

        const updateBudgetVars = {
          projectId,
          projectCycleId: cycleId,
          projectCycleUpdate: {
            actualDeliverables: deliverables ? Object.values(deliverables) : [],
          },
        };

        clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

        const updateBudgetResponse = await updateProjectCycle({ variables: updateBudgetVars });

        if (updateBudgetResponse.data.updateProjectCycle.id) {
          succeeded = true;
          setNotification(`Updated cycle ${cycleId} actual deliverables`, 'success');
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating cycle ${cycleId} actual deliverables`, 'error');
        succeeded = false;
      }
    } else {
      // setNotification(`Skipping update for cycle ${cycleId} budget due to no changes`);
      succeeded = true;
    }
    combinedSuccess = combinedSuccess && succeeded;

    if (shouldUpdateServices) {
      try {
        const { additionalServices: additionalServicesPre } = formValues;
        const additionalServicesTemp = this._getAdditionalServiceForUpdate(additionalServicesPre);
        const additionalServices = (additionalServicesTemp ?? []).map((s) => ({
          ...s,
          recurring: false,
        }));
        const updateServicesVars = {
          additionalServices,
          cycleId,
        };

        clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateServicesVars, null, 2)}`);

        const response = await updateProjectCycleAdditionalServices({ variables: updateServicesVars });
        if (response) {
          setEntityOperationResponseNotification(response.data.updateProjectCycleAdditionalServices);
          succeeded = succeeded && response.data.updateProjectCycleAdditionalServices.success;
        } else {
          succeeded = false;
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating cycle ${cycleId} additional services`, 'error');
        succeeded = false;
      }
    } else {
      // setNotification(`Skipping update for cycle ${cycleId} non-PPC services due to no changes`);
    }
    combinedSuccess = combinedSuccess && succeeded;

    return combinedSuccess;
  }

  validatePausedDurations(
    durations: ProjectCyclePauseDuration[],
    cycle: ProjectCycle | null | undefined,
  ): string | ReactNode | undefined {
    if (Array.isArray(durations) && durations.length > 0) {
      const editedCycleMonth = moment(cycle?.startDate).month();
      // First check if all the pairs are in the same month
      const areAllDurationsInSameMonth = durations.every(
        ({ startDate, endDate }) => startDate.month() === editedCycleMonth && endDate.month() === editedCycleMonth,
      );

      if (!areAllDurationsInSameMonth) {
        return `Start and end dates must be with same month of cycle (${moment(cycle?.startDate).format('MMMM')})!`;
      }

      // Convert the array to a tuple structure for easier iteration
      const datesTuples = (durations ?? []).map(({ startDate, endDate }) => [startDate.unix(), endDate.unix()]);

      for (let i = 0; i < datesTuples.length; i += 1) {
        const [startUnix, endUnix] = datesTuples[i];
        // First check if the start date is smaller than the end date
        if (startUnix >= endUnix) {
          return 'Start date cannot be after / same as end date!';
        }
        // Second, check if any date tuple overlap with another date tuple
        for (let j = 0; j < datesTuples.length; j += 1) {
          if (j !== i) {
            const [otherStartDateUnix, otherEndDateUnix] = datesTuples[j];
            const isStartDateOverlap = startUnix >= otherStartDateUnix && startUnix <= otherEndDateUnix;
            const isEndDateOverlap = endUnix >= otherStartDateUnix && endUnix <= otherEndDateUnix;

            if (isStartDateOverlap || isEndDateOverlap) {
              return 'Dates must not overlap!';
            }
          }
        }
      }
      return null;
    }
    return null;
  }

  async _updateCyclePauseDurations(
    formValues: Record<string, any>,
    mutate: any,
    cycle: ProjectCycle | null | undefined,
  ): Promise<boolean> {
    let nonPPCPauseDurations = (formValues?.nonPPCPauseDurations ?? []) as ProjectCyclePauseDuration[];
    let ppcPauseDurations = (formValues?.ppcPauseDurations ?? []) as ProjectCyclePauseDuration[];
    let succeeded = true;

    const errors: FormErrors<UpdateCyclePauseDurationsErrors> = {
      // @ts-ignore
      nonPPCPauseDurations: this.validatePausedDurations(nonPPCPauseDurations, cycle),
      // @ts-ignore
      ppcPauseDurations: this.validatePausedDurations(ppcPauseDurations, cycle),
    };

    if (errors.nonPPCPauseDurations || errors.ppcPauseDurations) {
      throw new SubmissionError(errors);
    }

    try {
      nonPPCPauseDurations = (nonPPCPauseDurations ?? []).map(({ comment, endDate, startDate }) => ({
        comment,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }));
      ppcPauseDurations = (ppcPauseDurations ?? []).map(({ comment, endDate, startDate }) => ({
        comment,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }));

      const variables = {
        cycleId: cycle?.id,
        ppcPauseDurations,
        nonPPCPauseDurations,
      };
      clientLogger.debug(`_updateCyclePauseDurations submitted:\n\n${JSON.stringify(variables, null, 2)}`);

      const response = await mutate({ variables });

      succeeded = response.data.updateProjectCyclePauseDurations.success;
      setEntityOperationResponseNotification(response.data.updateProjectCyclePauseDurations);
    } catch (e) {
      handleClientError(e);
      setNotification('Failed updating cycle pause durations', 'error');
      succeeded = false;
    }

    return succeeded;
  }

  async _updateOngoingProjectCycleServicesAndDeliverables(
    formValues: Record<string, any>,
    projectId: number | undefined,
    cycleId: number | null | undefined,
    mutateMediaBudget: any,
    mutateAdditionalServices: any,
    isNextCycle: boolean,
  ): Promise<boolean> {
    // console.log('_updateOngoingProjectCycleServicesAndDeliverables(formValues=', formValues, ')');
    const suffix = this._getCycleSuffix(isNextCycle);
    const cycleBudgetKeys = new Set([`mediaBudget${suffix}`]);
    const cycleSetupServiceKeys = new Set([`setupServiceCost${suffix}`, `setupServiceDescription${suffix}`]);
    const cycleDeliverablesKeys = new Set([`deliverables${suffix}`]);
    const cycleServicesKeys = new Set([`additionalServices${suffix}`]);
    const shouldUpdateBudget = this._shouldUpdate(formValues, cycleBudgetKeys);
    const shouldUpdateSetupService = this._shouldUpdate(formValues, cycleSetupServiceKeys);
    const shouldUpdateDeliverables = this._shouldUpdate(formValues, cycleDeliverablesKeys);
    const shouldUpdateServices = this._shouldUpdate(formValues, cycleServicesKeys);

    let succeeded = true;
    let combinedSuccess = true;

    if (shouldUpdateBudget) {
      const mediaBudget = formValues[`mediaBudget${suffix}`];

      const { mediaBudgetDivision, totalMediaBudget } = mediaBudget;

      const updateBudgetVars = {
        projectId,
        projectCycleId: cycleId,
        projectCycleUpdate: {
          estimatedMediaBudget: tryParseInt(totalMediaBudget),
          estimatedMediaBudgetDivision: mediaBudgetDivision ? Object.values(mediaBudgetDivision) : [],
        },
      };

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

      try {
        const response = await mutateMediaBudget(updateBudgetVars);
        if (response) {
          setEntityOperationResponseNotification(response.updateProjectCycleWithOperationResult);
          succeeded = succeeded && response.updateProjectCycleWithOperationResult.success;
        } else {
          succeeded = false;
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating ${isNextCycle ? 'next' : 'current'} cycle estimated media budget`, 'error');
        succeeded = false;
      }
    } else {
      succeeded = true;
    }

    combinedSuccess = combinedSuccess && succeeded;

    if (shouldUpdateSetupService) {
      const setupServiceCost = formValues[`setupServiceCost${suffix}`];
      const setupServiceDescription = formValues[`setupServiceDescription${suffix}`];

      const updateBudgetVars = {
        projectId,
        projectCycleId: cycleId,
        projectCycleUpdate: {
          setupService: {
            cost: setupServiceCost || 0,
            currency: 'USD',
            description: {
              contents: setupServiceDescription || '',
              mediaType: 'TEXT_PLAIN',
            },
          },
        },
      };

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

      try {
        const response = await mutateMediaBudget(updateBudgetVars);
        if (response) {
          setEntityOperationResponseNotification(response.updateProjectCycleWithOperationResult);
          succeeded = succeeded && response.updateProjectCycleWithOperationResult.success;
        } else {
          succeeded = false;
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating ${isNextCycle ? 'next' : 'current'} cycle setup service`, 'error');
        succeeded = false;
      }
    } else {
      succeeded = true;
    }
    combinedSuccess = combinedSuccess && succeeded;

    if (shouldUpdateDeliverables) {
      const deliverables = formValues[`deliverables${suffix}`];

      const updateBudgetVars = {
        projectId,
        projectCycleId: cycleId,
        projectCycleUpdate: {
          estimatedDeliverables: deliverables ? Object.values(deliverables) : [],
        },
      };

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateBudgetVars, null, 2)}`);

      try {
        const response = await mutateMediaBudget(updateBudgetVars);
        if (response) {
          setEntityOperationResponseNotification(response.updateProjectCycleWithOperationResult);
          succeeded = succeeded && response.updateProjectCycleWithOperationResult.success;
        } else {
          succeeded = false;
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating ${isNextCycle ? 'next' : 'current'} cycle estimated deliverables`, 'error');
        succeeded = false;
      }
    } else {
      succeeded = true;
    }
    combinedSuccess = combinedSuccess && succeeded;

    //
    if (shouldUpdateServices) {
      const additionalServicesPre = formValues[`additionalServices${suffix}`];
      const additionalServices = this._getAdditionalServiceForUpdate(additionalServicesPre);
      const updateServicesVars = {
        additionalServices,
        cycleId,
      };

      clientLogger.debug(`You submitted:\n\n${JSON.stringify(updateServicesVars, null, 2)}`);

      try {
        const response = await mutateAdditionalServices(updateServicesVars);

        if (response) {
          setEntityOperationResponseNotification(response.updateProjectCycleAdditionalServices);
          succeeded = succeeded && response.updateProjectCycleAdditionalServices.success;
        } else {
          succeeded = false;
        }
      } catch (e) {
        handleClientError(e);
        setNotification(`Failed updating ${isNextCycle ? 'next' : 'current'} cycle services`, 'error');
        succeeded = false;
      }
    } else {
      // setNotification(`Skipping update for ${suffix} cycle non-PPC services due to no changes`);
    }
    combinedSuccess = combinedSuccess && succeeded;

    return combinedSuccess;
  }

  _getCycleBillingConfigInitialValues(cycle: ProjectCycle | null | undefined, isNextCycle = false) {
    const suffix = this._getCycleSuffix(isNextCycle);

    const estimatedMediaBudget = cycle?.estimatedMediaBudget;
    const billingConfiguration = cycle?.billingConfiguration || ({} as BillingConfiguration);
    const marketerFeeConfiguration = cycle?.marketerFeeConfiguration || ({} as MarketerFeeConfiguration);

    const {
      feePartPercentage,
      minimumFeePart,
      maximumFeePart,
      planName: marketerFeeConfigurationPlanName,
      fixedFee: marketerFixedFee,
    } = marketerFeeConfiguration;

    const {
      minimumFee,
      fixedPrice,
      planName: billingConfigurationPlanName,
      PPCDiscount,
      nonPPCDiscount,
      setupServiceDiscount,
      meteredPriceMultiplier,
      PPCDiscountRecurring,
      nonPPCDiscountRecurring,
      setupServiceDiscountRecurring,
      forcePPCBillingEvenWithZeroSpend,
      PPCCostPerExtraChannel,
      PPCIncludedChannelCount,
    } = billingConfiguration;

    // cycle values
    return {
      [`meteredPriceMultiplier${suffix}`]: +(meteredPriceMultiplier || 0.15) * 100,
      [`feePartPercentage${suffix}`]: feePartPercentage * 100,
      [`fixedPrice${suffix}`]: fixedPrice || 0,
      [`minimumFee${suffix}`]: minimumFee || 0,
      [`PPCDiscount${suffix}`]: PPCDiscount || 0,
      [`PPCCostPerExtraChannel${suffix}`]: PPCCostPerExtraChannel || 0,
      [`PPCIncludedChannelCount${suffix}`]: PPCIncludedChannelCount || 0,
      [`nonPPCDiscount${suffix}`]: nonPPCDiscount || 0,
      [`setupServiceDiscount${suffix}`]: setupServiceDiscount || 0,
      [`estimatedMediaBudget${suffix}`]: estimatedMediaBudget,
      [`minimumFeePart${suffix}`]: minimumFeePart,
      [`maximumFeePart${suffix}`]: maximumFeePart,
      [`billingConfigurationPlanName${suffix}`]: billingConfigurationPlanName,
      [`marketerFeeConfigurationPlanName${suffix}`]: marketerFeeConfigurationPlanName,
      [`marketerFixedFee${suffix}`]: marketerFixedFee,
      [`PPCDiscountRecurring${suffix}`]: PPCDiscountRecurring,
      [`nonPPCDiscountRecurring${suffix}`]: nonPPCDiscountRecurring,
      [`setupServiceDiscountRecurring${suffix}`]: setupServiceDiscountRecurring,
      [`forcePPCBillingEvenWithZeroSpend${suffix}`]: forcePPCBillingEvenWithZeroSpend || false,
    };
  }

  _getSharedValues(sharedValuesInput: SharedValuesInput) {
    const { mediaBudgetDivision, additionalServices, deliverables } = sharedValuesInput;
    // console.log('_getSharedValues(additionalServices=', additionalServices, ', deliverables=', deliverables, ')');
    // filter out the __typename key
    const omitTypeNameAndServiceTypeInfo = (
      service:
        | Partial<AdditionalService>
        | Partial<MediaBudgetDivision>
        | Partial<MediaSpendDivision>
        | Partial<Deliverable>,
    ) => omit(service, ['__typename', 'temporaryCacheKey']);

    const createServiceStructure = (service: AdditionalService) => ({
      ...service,
      description: service.description.contents,
    });

    const createDeliverableStructure = (deliverable: Deliverable) => ({
      ...deliverable,
      unitDescription: omit(deliverable.unitDescription, ['__typename']),
    });

    // @ts-ignore
    const mediaBudgetDivisionTemp = (mediaBudgetDivision || []).map(omitTypeNameAndServiceTypeInfo);
    // @ts-ignore
    const deliverablesTemp = (deliverables ?? []).map(omitTypeNameAndServiceTypeInfo).map(createDeliverableStructure);

    const additionalServicesTemp = (additionalServices ?? [])
      .map(omitTypeNameAndServiceTypeInfo)
      // @ts-ignore
      .map(createServiceStructure);

    return {
      additionalServices: arrayOfObjectsToHashTable({
        // @ts-ignore
        array: additionalServicesTemp,
        key: 'uuid',
      }),
      mediaBudgetDivision: arrayOfObjectsToHashTable({
        // @ts-ignore
        array: mediaBudgetDivisionTemp,
        key: 'skillType',
      }),
      deliverables: arrayOfObjectsToHashTable({
        // @ts-ignore
        array: deliverablesTemp,
        key: 'uuid',
      }),
    };
  }

  _getServicesAndDeliverablesValuesForOngoingProject(project: Project | null | undefined) {
    // NOTE: argh this is a terrible function
    const currentCycle = project?.currentCycle;
    const nextCycle = project?.nextCycle;

    // console.log('_getServicesAndDeliverablesValuesForOngoingProject(currentCycle=', currentCycle, ')');

    const { isDiscovery, isOnboarding, isLive } = this.getProjectLifeCycles(project);

    // Default values
    let totalMediaBudget = 0;
    let setupServiceCost = 0;
    let setupServiceDescription = '';
    let additionalServices = {};
    let mediaBudgetDivision = {};
    let deliverables = {};

    if (isOnboarding || isLive || isDiscovery) {
      const {
        additionalServices: additionalServicesCurrentCycle,
        estimatedDeliverables: estimatedDeliverablesCurrentCycle,
        estimatedMediaBudgetDivision: estimatedMediaBudgetDivisionCurrentCycle,
        estimatedMediaBudget: estimatedMediaBudgetCurrentCycle,
        setupService: setupServiceCurrentCycle,
      } = currentCycle as ProjectCycle;
      // console.log('currentCycle', currentCycle);

      setupServiceCost = setupServiceCurrentCycle?.cost || 0;
      setupServiceDescription = setupServiceCurrentCycle.description.contents || '';
      totalMediaBudget = estimatedMediaBudgetCurrentCycle;

      ({ additionalServices, mediaBudgetDivision, deliverables } = this._getSharedValues({
        additionalServices: additionalServicesCurrentCycle,
        mediaBudgetDivision: estimatedMediaBudgetDivisionCurrentCycle,
        deliverables: estimatedDeliverablesCurrentCycle,
      }));
    }

    const initialValues: Record<string, any> = {
      additionalServices,
      setupServiceCost,
      setupServiceDescription,
      mediaBudget: {
        totalMediaBudget,
        mediaBudgetDivision,
      },
      deliverables,
    };

    // Add the next cycle initial values
    if (isLive) {
      const {
        additionalServices: additionalServicesNextCycleTemp,
        estimatedDeliverables: estimatedDeliverablesNextCycleTemp,
        estimatedMediaBudgetDivision: estimatedMediaBudgetDivisionNextCycleTemp,
        estimatedMediaBudget: estimatedMediaBudgetNextCycleTemp,
        setupService: setupServiceNextCycleTemp,
      } = nextCycle || {};

      const setupServiceCostNextCycle = setupServiceNextCycleTemp?.cost || 0;
      const setupServiceDescriptionNextCycle = setupServiceNextCycleTemp?.description?.contents || '';
      // console.log('nextCycle: ', nextCycle);

      const {
        additionalServices: additionalServicesNextCycle,
        mediaBudgetDivision: estimatedMediaBudgetDivisionNextCycle,
        deliverables: estimatedDeliverablesNextCycle,
      } = this._getSharedValues({
        additionalServices: additionalServicesNextCycleTemp,
        mediaBudgetDivision: estimatedMediaBudgetDivisionNextCycleTemp,
        deliverables: estimatedDeliverablesNextCycleTemp,
      });

      // TODO: Use an update of the object instead of this
      initialValues.mediaBudgetNextCycle = {
        totalMediaBudget: estimatedMediaBudgetNextCycleTemp,
        mediaBudgetDivision: estimatedMediaBudgetDivisionNextCycle,
      };

      initialValues.setupServiceCostNextCycle = setupServiceCostNextCycle;
      initialValues.setupServiceDescriptionNextCycle = setupServiceDescriptionNextCycle;
      initialValues.additionalServicesNextCycle = additionalServicesNextCycle;
      initialValues.deliverablesNextCycle = estimatedDeliverablesNextCycle;
    }

    // console.log('initialValues', initialValues);

    return initialValues;
  }

  /**
   * Handler function for redux form wizard submit
   * Specific handler for ongoing project with status of discovery, onboarding or live
   * @param formValuesSubmitted
   * @param project
   * @param hasOpenOpportunities
   * @param updateProjectCycleWithOperationResultMutation
   * @param updateProjectCycleAdditionalServicesMutation
   * @returns {Promise<boolean>}
   */
  async onSubmitForOngoingProject(
    formValuesSubmitted: Record<string, any>,
    project: Project | null | undefined,
    hasOpenOpportunities: boolean,
    updateProjectCycleWithOperationResultMutation: any,
    updateProjectCycleAdditionalServicesMutation: any,
  ): Promise<boolean> {
    // console.log('formValuesSubmitted', formValuesSubmitted);
    const iv = formValuesSubmitted.initialValues;
    const nv = JSON.parse(JSON.stringify(formValuesSubmitted));
    delete nv.initialValues;

    if (hasOpenOpportunities) {
      const ans = window.confirm(
        `You are making changes to a project that has open opportunities.\nAre you sure you want to continue with the change?`,
      );
      if (!ans) {
        return false;
      }
    }

    const ans = window.confirm(`Confirm changes made:\n${diff(iv, nv).text || 'N/A'}`);
    if (!ans) {
      return false;
    }

    const projectId = project?.id;
    const currentCycle = project?.currentCycle;
    const nextCycle = project?.nextCycle;

    const { isLive, isOnboarding, isDiscovery } = this.getProjectLifeCycles(project);
    let _updateProjectCycleBillingConfigSucceededNextCycle = true;
    let _updateProjectCycleServicesSucceeded = true;
    let _updateProjectCycleServicesSucceededNextCycle = true;
    /* First, update the project cycle billing configurations */

    clientLogger.debug(`Updating project (${projectId}) current cycle (${currentCycle?.id})`);

    const _updateProjectCycleBillingConfigSucceeded = await this._updateOngoingProjectCycleBillingConfig(
      formValuesSubmitted,
      project,
      updateProjectCycleWithOperationResultMutation.mutate,
    );

    if (isLive) {
      clientLogger.debug(`Updating project (${projectId}) next cycle (${nextCycle?.id})`);

      _updateProjectCycleBillingConfigSucceededNextCycle = await this._updateOngoingProjectCycleBillingConfig(
        formValuesSubmitted,
        project,
        updateProjectCycleWithOperationResultMutation.mutate,
        true,
      );
    }

    /* Project cycle billing configurations update end */

    /* Then update the services and media budget in the cycle */

    // In discovery, onboarding and live, we only need to update the project cycles
    if (isOnboarding || isLive || isDiscovery) {
      _updateProjectCycleServicesSucceeded = await this._updateOngoingProjectCycleServicesAndDeliverables(
        formValuesSubmitted,
        projectId,
        currentCycle?.id,
        updateProjectCycleWithOperationResultMutation.mutate,
        updateProjectCycleAdditionalServicesMutation.mutate,
        false,
      );
    }

    if (isLive) {
      _updateProjectCycleServicesSucceededNextCycle = await this._updateOngoingProjectCycleServicesAndDeliverables(
        formValuesSubmitted,
        projectId,
        nextCycle?.id,
        updateProjectCycleWithOperationResultMutation.mutate,
        updateProjectCycleAdditionalServicesMutation.mutate,
        true,
      );
    }

    /* services and media budget update end */

    // Return true if all mutations succeeded
    return (
      _updateProjectCycleBillingConfigSucceeded &&
      _updateProjectCycleBillingConfigSucceededNextCycle &&
      _updateProjectCycleServicesSucceeded &&
      _updateProjectCycleServicesSucceededNextCycle
    );
  }

  async onSubmitForElapsedCycle(
    formValuesSubmitted: Record<string, any>,
    project: Project,
    cycles: ProjectCycle[],
    cycleId: number,
    updateProjectCycle: any,
    updateProjectCycleAdditionalServices: any,
    updateProjectCyclePauseDurations: any,
    isFirstCycle: boolean,
  ) {
    const cycle = cycles.find(({ id }) => id === cycleId);

    if (cycle?.phase !== ProjectCyclePhase.ELAPSED) {
      throw new Error(`Cannot submit for cycle with phase ${cycle?.phase}`);
    }

    const _updateProjectCycleServicesSucceeded = await this._updateElapsedCycleServicesAndDeliverables(
      formValuesSubmitted,
      cycleId,
      project.id,
      updateProjectCycle,
      updateProjectCycleAdditionalServices,
      isFirstCycle,
    );

    const _updateCycleBillingConfigSucceeded = await this._updateCycleBillingConfig(
      formValuesSubmitted,
      project,
      updateProjectCycle,
      cycleId,
    );

    const _updateCyclePauseDurationsSucceeded = await this._updateCyclePauseDurations(
      formValuesSubmitted,
      updateProjectCyclePauseDurations,
      cycle,
    );

    return (
      _updateProjectCycleServicesSucceeded && _updateCycleBillingConfigSucceeded && _updateCyclePauseDurationsSucceeded
    );
  }

  completeMissingEstimatedDeliverablesInActualDeliverables(
    estimatedDeliverables: Array<Deliverable>,
    actualDeliverables: Array<Deliverable> | null | undefined,
  ) {
    // Create a new array with zeroed unitAmount for each estimatedDeliverable
    const zeroedEstimatedDeliverables = (estimatedDeliverables ?? []).map((deliverable) => ({
      ...deliverable,
      unitAmount: 0,
    }));

    // Create a new Set to store UUIDs of actualDeliverables
    const actualDeliverableUUIDs = new Set((actualDeliverables ?? []).map((deliverable) => deliverable.uuid));

    // Filter out zeroedEstimatedDeliverables that already exist in actualDeliverables
    const filteredZeroedEstimatedDeliverables = zeroedEstimatedDeliverables.filter(
      (deliverable) => !actualDeliverableUUIDs.has(deliverable.uuid),
    );

    // console.log(
    //   'estimatedDeliverables', estimatedDeliverables,
    //   'actualDeliverables', actualDeliverables,
    //   'result', [...actualDeliverables, ...filteredZeroedEstimatedDeliverables],
    // );

    // Return the merged array
    return [...(actualDeliverables ?? []), ...filteredZeroedEstimatedDeliverables];
  }

  /**
   * Sets the initial values for redux form wizard
   * @param cycles
   * @param cycleId
   * @returns {{additionalServices: {}, initialValues: any, targetKPI: null, mediaBudget: {mediaBudgetDivision: {},
   *   setupServiceCost: number, setupServiceDescription: string, totalMediaBudget: number}}}
   */
  getInitialValuesForElapsedCycle = (cycles: Array<ProjectCycle>, cycleId: number) => {
    // console.log('getInitialValuesForElapsedCycle(cycles=', cycles, ')');
    const cycle = cycles.find(({ id }) => id === cycleId);

    if (cycle?.phase !== ProjectCyclePhase.ELAPSED) {
      throw new Error(`Cannot initialize cycle with phase ${cycle?.phase}`);
    }
    // console.log('cycle:', cycle);

    const elapsedCycleBillingConfigValues = this._getCycleBillingConfigInitialValues(cycle);
    const {
      additionalServices: additionalServicesElapsedCycle,
      actualDeliverables,
      estimatedDeliverables,
      actualMediaSpendDivision: mediaBudgetDivisionElapsedCycle,
      actualMediaSpend: actualMediaBudgetElapsedCycle,
      nonPPCPauseDurations,
      ppcPauseDurations,
      startDate,
      setupService,
    } = cycle;

    const deliverablesElapsedCycle = this.completeMissingEstimatedDeliverablesInActualDeliverables(
      estimatedDeliverables,
      actualDeliverables,
    );

    const totalMediaBudget = actualMediaBudgetElapsedCycle;

    const { additionalServices, mediaBudgetDivision, deliverables } = this._getSharedValues({
      additionalServices: additionalServicesElapsedCycle,
      mediaBudgetDivision: mediaBudgetDivisionElapsedCycle,
      deliverables: deliverablesElapsedCycle,
    });

    const ppcPauseDurationsInit = (ppcPauseDurations ?? []).map(({ comment, startDate: sd, endDate }) => ({
      comment,
      startDate: moment(sd),
      endDate: moment(endDate),
    }));

    const nonPPCPauseDurationsInit = (nonPPCPauseDurations ?? []).map(({ comment, startDate: sd, endDate }) => ({
      comment,
      startDate: moment(sd),
      endDate: moment(endDate),
    }));

    const setupServiceCost = setupService?.cost || 0;
    const setupServiceDescription = setupService.description.contents || '';

    const values = {
      ...elapsedCycleBillingConfigValues,
      deliverables,
      additionalServices,
      mediaBudget: {
        totalMediaBudget,
        mediaBudgetDivision,
      },
      setupServiceCost,
      setupServiceDescription,
      ppcPauseDurations: ppcPauseDurationsInit,
      nonPPCPauseDurations: nonPPCPauseDurationsInit,
      startDate: new Date(startDate).toISOString(),
    };
    const copy = JSON.parse(JSON.stringify(values));

    // console.log('initialValues', values);

    return {
      ...values,
      initialValues: copy,
    };
  };

  /**
   * Sets the initial values for redux form wizard
   * @param project
   * @returns {{additionalServices: {}, initialValues: any, targetKPI: null, mediaBudget: {mediaBudgetDivision: {},
   *   setupServiceCost: number, setupServiceDescription: string, totalMediaBudget: number}}}
   */
  getInitialValuesForOngoingProject = (project: Project | null | undefined) => {
    const currentCycle = project?.currentCycle;
    const nextCycle = project?.nextCycle;

    // console.log('project', JSON.stringify(project, null, 2));

    const { isLive } = this.getProjectLifeCycles(project);
    const shouldInitNextCycle = isLive;
    const currentCycleBillingConfigValues = this._getCycleBillingConfigInitialValues(currentCycle);
    let nextCycleBillingConfigValues = {};

    if (shouldInitNextCycle) {
      nextCycleBillingConfigValues = this._getCycleBillingConfigInitialValues(nextCycle, true);
    }

    const servicesAndDeliverablesValues = this._getServicesAndDeliverablesValuesForOngoingProject(project);
    const values = {
      // Current cycle values
      ...currentCycleBillingConfigValues,
      // Next cycle values
      ...nextCycleBillingConfigValues,
      // Services values
      ...servicesAndDeliverablesValues,
    };
    const copy = JSON.parse(JSON.stringify(values));

    // Initialize values
    // noinspection UnnecessaryLocalVariableJS
    const initialValues = {
      ...values,
      initialValues: copy,
    };

    // eslint-disable-next-line
    // console.log('getInitialValues initialValues', JSON.stringify(initialValues, null, 2));

    return initialValues;
  };

  validateBillingConfig = (values: Record<string, any>) => {
    // console.log('values on sync validate', values);
    const errors: Record<string, any> = {};
    const {
      PPCDiscount,
      nonPPCDiscount,
      setupServiceDiscount,
      // estimatedMediaBudget,
      // mediaBudgetDivision,
      minimumFeePart,
      maximumFeePart,
      feePartPercentage,
      fixedPrice,
      minimumFee,
      billingConfigurationPlanName,
      marketerFeeConfigurationPlanName,
    } = values;

    //
    errors.billingConfigurationPlanName = validateRequired(billingConfigurationPlanName);
    //
    errors.marketerFeeConfigurationPlanName = validateRequired(marketerFeeConfigurationPlanName);
    //
    errors.minimumFee = validateInteger(minimumFee) || validateIntegerBetween(0, 1000000)(minimumFee);
    //
    errors.fixedPrice = validateInteger(fixedPrice) || validateIntegerBetween(0, 1000000)(fixedPrice);
    //
    errors.minimumFeePart = validateInteger(minimumFeePart) || validateIntegerBetween(0, 1000000)(minimumFeePart);
    //
    errors.maximumFeePart =
      validateInteger(maximumFeePart) || validateIntegerBetween(minimumFeePart, 1000000)(maximumFeePart);
    //
    errors.feePartPercentage = validateInteger(feePartPercentage) || validateIntegerBetween(0, 100)(feePartPercentage);
    //
    errors.PPCDiscount = validateInteger(PPCDiscount) || validateIntegerBetween(0, 100)(PPCDiscount);
    //
    errors.nonPPCDiscount = validateInteger(nonPPCDiscount) || validateIntegerBetween(0, 100)(nonPPCDiscount);
    //
    errors.setupServiceDiscount =
      validateInteger(setupServiceDiscount) || validateIntegerBetween(0, 100)(setupServiceDiscount);
    // console.log('errors', errors);
    return errors;
  };

  /**
   *
   * @param project
   * @returns {{isLive: boolean, isDiscovery: boolean, isOnboarding: boolean}}
   */
  getProjectLifeCycles(project: Project | null | undefined) {
    const projectLifeCycleStatus = project?.projectLifeCycleStatus;

    return {
      isDiscovery: projectLifeCycleStatus === ProjectLifeCycleStatus.DISCOVERY,
      isOnboarding: projectLifeCycleStatus === ProjectLifeCycleStatus.ONBOARDING,
      isLive: projectLifeCycleStatus === ProjectLifeCycleStatus.LIVE,
      isFinished: projectLifeCycleStatus === ProjectLifeCycleStatus.FINISHED,
    };
  }
}

// Create a singleton
const projectBilling = new ProjectBilling();

export default projectBilling;
