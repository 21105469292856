import React, { FC } from 'react';
import { CurrencyType, Marketer, MarketerType } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import SuccessStoriesPanels from '../../../../components/cpanel/components/molecules/SuccessStoriesPanels';
import EmploymentHistoryPanels from '../../../../components/cpanel/components/molecules/EmploymentHistoryPanels';

import {
  AD_SPEND_PAIRS,
  COUNTRIES_PAIRS,
  CLIENT_STAGE_EXPERTISE,
  INDUSTRIES_LABELS_LOOKUP,
  INDUSTRIES_GROUPS,
  LANGUAGES_PAIRS,
  MARKETER_TYPE_PAIRS,
  MARKETING_SKILLS_PAIRS_WITH_ICONS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  PRODUCT_COST_RANGE,
  TARGET_KPI_PAIRS,
  CURRENT_WORK_STATUS,
  PROJECT_SPECIAL_TYPE,
  MARKETER_GRADUATION_PLAN,
} from '../../../../../fe_common/client/app/enums';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { safeExtractCurrencyValue } from '../../../../../fe_common/client/services/utils';
import { WizardDivider } from '../../../../../fe_common/client/components/molecules/WizardDivider';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';

import useStyles from './styles';

type WizardStepsProps = {
  userInput: Record<string, any>;
  initialValues: Record<string, any>;
  marketer: Marketer;
  submit: () => void;
  submitting: boolean;
  invalid: boolean;
};

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const { invalid, submit, submitting, initialValues, marketer, userInput = {} } = props;

  const classes = useStyles(props);

  const { marketerIndustryTopCategories, years } = initialValues;
  const successStories = marketer?.marketerProfile?.successStories || [];
  const employmentHistory = marketer?.marketerProfile?.employmentHistory || [];
  const availableForProjects = marketer?.matchingSettings?.availableForProjects || false;
  const minimumProjectFeeAmount = marketer?.matchingSettings?.minimumProjectFeeAmount || 0;
  const minimumProjectFeeCurrency = marketer?.matchingSettings?.minimumProjectFeeCurrency || CurrencyType.USD;

  const { areYouSure = false } = userInput;
  const marketerType = userInput.marketerType || marketer?.marketerProfile?.marketerType;

  // console.log('marketerType', marketerType);
  // console.log('userInput', userInput);

  return (
    <div>
      <Section title="General info" classes={{ root: classes.sectionRoot }}>
        <SectionContent>
          <Grid container spacing={4} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <WizardStepField
                type={WizardStepField.types.SELECT}
                name="marketerType"
                // @ts-ignore
                label="Marketer type"
                enums={MARKETER_TYPE_PAIRS}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WizardStepField
                type={WizardStepField.types.URL}
                name="introVideoUrl"
                // @ts-ignore
                label="Marketer intro video URL"
                hintText="e.g. https://mayple.wistia.com/medias/1m4jibve3a"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WizardStepField
                name="marketerMinimumProjectFeeAmount"
                type={WizardStepField.types.NUMBER}
                required
                // @ts-ignore
                label="Minimum monthly fee per project*"
                minimumValue={1}
                maximumValue={1000000}
                precision={0}
                inputAdornment="$"
              />
            </Grid>
            {marketerType === MarketerType.DIGITAL_AGENCY && (
              <Grid item xs={12} sm={6}>
                <WizardStepField
                  name="numberOfEmployees"
                  type={WizardStepField.types.NUMBER}
                  required
                  // @ts-ignore
                  label="Number Of Employees"
                  minimumValue={0}
                  maximumValue={1000000}
                  precision={0}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <WizardStepField
                name="graduationPlan"
                type={WizardStepField.types.SELECT}
                // @ts-ignore
                label="Marketer Graduation Plan"
                enums={MARKETER_GRADUATION_PLAN}
              />
            </Grid>
          </Grid>
        </SectionContent>
      </Section>

      <Section title="Experience" classes={{ root: classes.sectionRoot }}>
        <SectionContent>
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE_RADIO_BUTTONS}
            name="marketerMarketingSkills"
            wizardFieldLabel="Marketing skills"
            // @ts-ignore
            label="Marketing skills"
            enums={MARKETING_SKILLS_PAIRS_WITH_ICONS}
            isMulti
          />
          {marketerIndustryTopCategories !== '' && (
            <Grid container>
              <Grid item xs={2}>
                <TextDisplay inline>Previous selected industries: </TextDisplay>
              </Grid>
              <Grid item xs={10}>
                <TextDisplay id="marketerIndustryTopCategories" inline>
                  {marketerIndustryTopCategories}
                </TextDisplay>
              </Grid>
            </Grid>
          )}
          <WizardStepField
            name="marketerIndustryExpertise"
            wizardFieldLabel="Main industry experienced in"
            type={WizardStepField.types.CATEGORIES_INPUT}
            required
            // @ts-ignore
            enums={INDUSTRIES_GROUPS}
            labels={INDUSTRIES_LABELS_LOOKUP}
            selectMultiSubCategories
            categoryName="industry"
            dialogTitle="Add new industry"
            buttonLabel="Add new industry"
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="marketerCountryExpertise"
            required
            // @ts-ignore
            label="Has experience in countries:"
            enums={COUNTRIES_PAIRS}
            isMulti
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="marketerLanguageExpertise"
            required
            // @ts-ignore
            label="Has experience in languages:"
            enums={LANGUAGES_PAIRS}
            isMulti
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="marketerTargetKPIExpertise"
            required
            // @ts-ignore
            label="Has experience in target KPIs:"
            isMulti
            enums={TARGET_KPI_PAIRS}
            autoFocus={false}
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="marketerProductBusinessModelExpertise"
            required
            // @ts-ignore
            label="Has experience in product business models:"
            isMulti
            enums={PRODUCT_BUSINESS_MODEL_PAIRS}
            autoFocus={false}
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="marketerMonthlyBudgetRangeAmount"
            // @ts-ignore
            label="Monthly average ad spend"
            isMulti
            enums={AD_SPEND_PAIRS}
          />
          <WizardStepField
            type={WizardStepField.types.NUMBER}
            name="totalExternalProjectsCompleted"
            required
            // @ts-ignore
            label="Total external projects completed (outside of Mayple)"
            minimumValue={0}
          />

          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="productCostRange"
            // @ts-ignore
            label="Product costs range"
            enums={PRODUCT_COST_RANGE}
            isMulti
          />
          <WizardStepField
            type={WizardStepField.types.AUTOCOMPLETE}
            name="clientStageExpertise"
            // @ts-ignore
            label="Client stage expertise"
            enums={CLIENT_STAGE_EXPERTISE}
            isMulti
          />
          <WizardStepField
            name="yearFirstStartedDigitalMarketing"
            type={WizardStepField.types.SELECT}
            // @ts-ignore
            enums={years}
            placeholder="What year did the expert started his career in digital marketing?"
            label="What year did the expert started his career in digital marketing?"
          />

          {marketerType === MarketerType.FREELANCER && (
            <WizardStepField
              type={WizardStepField.types.AUTOCOMPLETE}
              name="currentWorkStatus"
              // @ts-ignore
              label="Current Status"
              enums={CURRENT_WORK_STATUS}
            />
          )}
        </SectionContent>
      </Section>

      <Section title="Matching Configuration" classes={{ root: classes.sectionRoot }}>
        <TextDisplay>
          Marketer has set himself to {availableForProjects ? 'Available ✅' : 'Not Available ❗'}
        </TextDisplay>
        <TextDisplay>
          Minimum project fee amount:{' '}
          {safeExtractCurrencyValue(minimumProjectFeeAmount, minimumProjectFeeCurrency, false)}
        </TextDisplay>
        <WizardStepField
          type={WizardStepField.types.CHECKBOX}
          name="enableMatching"
          // @ts-ignore
          inputLabel="Enable matching for this marketer"
        />
        <WizardStepField
          type={WizardStepField.types.AUTOCOMPLETE}
          name="matchableForProjectSpecialTypes"
          // @ts-ignore
          label="Can be matched in the following project types"
          enums={PROJECT_SPECIAL_TYPE}
          isMulti
        />
        <WizardStepField
          type={WizardStepField.types.NUMBER}
          name="maximumConcurrentProjectsLimit"
          // @ts-ignore
          label="Maximum Concurrent Projects Limit (0-120)"
          minimumValue={0}
          maximumValue={120}
        />
      </Section>

      <Section title="Profile Configuration" classes={{ root: classes.sectionRoot }}>
        <WizardStepField
          name="isShareable"
          type={WizardStepField.types.SWITCH}
          // @ts-ignore
          label="Can this marketer profile be publicly shared?"
        />
      </Section>

      <Section title="Success stories" classes={{ root: classes.sectionRoot }}>
        <SuccessStoriesPanels successStories={successStories} />
      </Section>

      <Section title="Employment history" classes={{ root: classes.sectionRoot }}>
        <EmploymentHistoryPanels employmentHistory={employmentHistory} />
      </Section>

      <Section title="Score" classes={{ root: classes.sectionRoot }}>
        <SectionContent>
          <WizardStepField
            type={WizardStepField.types.NUMBER}
            name="levelOfService"
            // @ts-ignore
            label="Level of service (LOS) score (1-10)"
            minimumValue={1}
            maximumValue={10}
            margin="normal"
          />
          <WizardStepField
            type={WizardStepField.types.NUMBER}
            name="interviewScore"
            // @ts-ignore
            label="Interview score (1-10)"
            minimumValue={1}
            maximumValue={10}
            margin="normal"
          />
          <WizardStepField
            type={WizardStepField.types.NUMBER}
            name="marketerHistoricalPerformance"
            // @ts-ignore
            label="Total historical performance (1-10)"
            minimumValue={1}
            maximumValue={10}
            margin="normal"
          />
        </SectionContent>
      </Section>

      <Section title="Vetting" classes={{ root: classes.sectionRoot }}>
        <SectionContent>
          <WizardStepField
            type={WizardStepField.types.CHECKBOX}
            name="isVetted"
            // @ts-ignore
            inputLabel="Is the marketer vetted?"
          />
          <WizardStepField
            type={WizardStepField.types.TEXT}
            name="vettedBy"
            // @ts-ignore
            label="Vetting done by:"
          />
          <WizardStepField
            type={WizardStepField.types.TEXT}
            name="vetterComment"
            // @ts-ignore
            label="Vetting general comment:"
          />
        </SectionContent>
      </Section>

      <Section title="Verify edit" classes={{ root: classes.sectionRoot }}>
        <SectionContent>
          <WizardStepField
            type={WizardStepField.types.CHECKBOX}
            name="areYouSure"
            // @ts-ignore
            inputLabel="I have checked all the information before updating"
          />
          <WizardDivider />
          <div className={classes.actionsContainer}>
            <div>{(invalid || !areYouSure) && <div>Make sure you fill all required details!</div>}</div>
            <div>
              <Button
                label="Save Changes"
                variant="contained"
                color="primary"
                disabled={invalid || !areYouSure || submitting}
                loading={submitting}
                onClick={submit}
              />
            </div>
          </div>
        </SectionContent>
      </Section>
    </div>
  );
};

export default WizardSteps;
