import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { CreateFeedPostMutation } from 'growl-graphql/dist/mutations/CreateFeedPostMutation';
import { UpdateFeedPostMutation } from 'growl-graphql/dist/mutations/UpdateFeedPostMutation';

import WizardSteps from './steps';
import {
  getInitialValues,
  getMutationVariable,
  handleOnFailure,
  handleOnSuccess,
  isValidPost,
  POST_EDITOR_MODES,
} from './logic';
import FeedAuthorAvatar from '../FeedAuthorAvatar';
import { getAuthorDetails } from '../logic';
import { WizardForm } from '../../organisms/WizardForm';
import useMutation from '../../../hooks/useMutation';
import { clientLogger } from '../../../services/logger';
import LoadingOverlay from '../../atoms/LoadingOverlay';

import { useStyles } from './style';

function FeedPostEditor({ feedUuid, authorUuid, legend, topics, placeholder, onCreatePost, onUpdatePost, postData }) {
  const classes = useStyles();

  const [wizardFormName] = useState(() => `insightsFeedPost-${(Math.random() * 1000000).toFixed(0)}`);

  const [editorMode, setEditorMode] = useState(() =>
    postData !== null ? POST_EDITOR_MODES.ACTIVE : POST_EDITOR_MODES.IDLE,
  );

  const { mutate: createFeedPostMutation, loading: savingMessage } = useMutation(CreateFeedPostMutation);
  const { mutate: updateFeedPostMutation } = useMutation(UpdateFeedPostMutation);

  const initialValues = getInitialValues(feedUuid, postData);

  // console.log('legend', legend);

  const author = useMemo(() => getAuthorDetails(authorUuid, legend), [authorUuid, legend]);

  const onInputClickHandler = () => {
    setEditorMode(POST_EDITOR_MODES.IDLE_CLICKED);
    setTimeout(() => {
      setEditorMode(POST_EDITOR_MODES.ACTIVE);
    }, 100);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnSubmit = async (formValues, dispatch, formProps) => {
    // console.log('formValues', formValues);
    // console.log('formProps', formProps);

    if (!isValidPost(formValues, dispatch, wizardFormName)) {
      return;
    }

    const { postUuid } = formValues;
    const variables = getMutationVariable(formValues, feedUuid);
    const mutate = postUuid ? updateFeedPostMutation : createFeedPostMutation;

    try {
      const result = await mutate({ variables });
      // console.log('result', result);

      // If updating existing post
      if (postUuid && result?.data?.updateFeedPost) {
        // close the editor and return to idle
        setEditorMode(POST_EDITOR_MODES.IDLE);

        // clear redux form values
        formProps.reset();

        // call parent scope onUpdatePost
        onUpdatePost();
      } else if (result?.data?.createFeedPost) {
        // Create new post
        // call parent scope onCreatePost
        onCreatePost();

        // close the editor and return to idle
        setEditorMode(POST_EDITOR_MODES.IDLE);

        // clear redux form values
        formProps.reset();
      }
    } catch (e) {
      clientLogger(e);
    }
  };

  const editorModeIsActive = editorMode === POST_EDITOR_MODES.ACTIVE;

  return (
    <Paper classes={{ root: classes.root }}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classNames(classes.postEditorContainer, {
          [classes.postEditorContainerActive]: editorModeIsActive,
          [classes.postEditorContainerIdle]: !editorModeIsActive,
        })}
      >
        <Grid
          item
          className={classNames(classes.avatarContainer, {
            [classes.avatarContainerOnActiveMode]: editorModeIsActive,
          })}
        >
          <FeedAuthorAvatar className={classes.avatar} author={author} />
        </Grid>
        <Grid item className={classes.formContainer}>
          <Collapse in={!editorModeIsActive}>
            <InputBase
              placeholder={placeholder}
              className={classNames(classes.input, {
                [classes.inputIdle]: editorMode === POST_EDITOR_MODES.IDLE,
                [classes.inputIdleClicked]: editorMode === POST_EDITOR_MODES.IDLE_CLICKED,
              })}
              fullWidth
              readOnly
              onClick={onInputClickHandler}
            />
          </Collapse>
          <Collapse in={editorMode === POST_EDITOR_MODES.ACTIVE}>
            <div>
              <WizardForm
                form={wizardFormName}
                steps={WizardSteps}
                initialValues={initialValues}
                watchAllValues
                onSubmit={handleOnSubmit}
                onSuccess={handleOnSuccess}
                onFailure={handleOnFailure}
                destroyOnUnmount
                topics={topics}
                placeholder={placeholder}
                loading={savingMessage}
              />
            </div>
          </Collapse>
          {savingMessage && <LoadingOverlay message="posting..." />}
        </Grid>
      </Grid>
    </Paper>
  );
}

FeedPostEditor.propTypes = {
  legend: PropTypes.object.isRequired,
  feedUuid: PropTypes.string.isRequired,
  authorUuid: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onCreatePost: PropTypes.func.isRequired,
  onUpdatePost: PropTypes.func,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  postData: PropTypes.object,
};

FeedPostEditor.defaultProps = {
  postData: null,
  placeholder: '',
  onUpdatePost: () => {},
};

export default FeedPostEditor;
