import React, { FC, useState } from 'react';
import { Company, InternalTeam, Marketer, User } from '@mayple/types';
import List from '@material-ui/core/List';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import EntityListItem from '../EntityListItem';
import useEntityUsersOperations, {
  connectUserToEntity,
  disconnectUserFromEntity,
  ValidOperationsEntities,
} from '../../../../../hooks/useEntityUsersOperation';
import { AddUserDialog } from '../../atoms/AddUserDialog';
import { ENTITY_TYPE } from '../../../../../app/consts';

import useDialog from '../../../../../../fe_common/client/hooks/useDialog';
import { Button } from '../../../../../../fe_common/client/components/inputs';
import { setNotification } from '../../../../../../fe_common/client/services/notification';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import PromptDialog from '../../../../../../fe_common/client/components/molecules/PromptDialog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface EntityConnectedUsersListProps {
  entity: Partial<Company | Marketer | InternalTeam>;
  entityType: ENTITY_TYPE;
  readOnly?: boolean;
  onChange: () => void;
  classes?: Record<string, string>;
}

type SelectedUser = {
  id: number;
  emailAddress: string;
};

const defaultSelectedUser = { id: 0, emailAddress: '' };

const EntityConnectedUsersList: FC<EntityConnectedUsersListProps> = (props) => {
  const classes = useStyles(props);

  const { open: isAddUserDialogOpen, openDialog: openAddUserDialog, closeDialog: closeAddUserDialog } = useDialog();

  const {
    open: showDisconnectUserPrompt,
    openDialog: openDisconnectUserPrompt,
    closeDialog: closeDisconnectUserPrompt,
  } = useDialog();

  const [selectedUser, setSelectedUser] = useState<SelectedUser>(defaultSelectedUser);

  const {
    entity = {},
    entityType,
    readOnly = false,
    onChange = () => {
      /* Do nothing */
    },
  } = props;

  const { id: entityId = 0, name: entityName = '' } = entity;
  const users = (entity?.users || []) as User[];
  const hasUsers = users.length > 0;

  const { addUserToEntity, removeUserFromEntity } = useEntityUsersOperations(entityType as ValidOperationsEntities);

  const clearSelectedUser = () => {
    setSelectedUser(defaultSelectedUser);
  };

  const setUserEmail = (newValue: string) => {
    setSelectedUser((prevState) => ({
      ...prevState,
      emailAddress: newValue,
    }));
  };

  const setUserId = (newValue: number) => {
    setSelectedUser((prevState) => ({
      ...prevState,
      id: newValue,
    }));
  };

  const handleAddUser = async () => {
    if (!selectedUser) {
      setNotification(`No user was selected. Can't assign user to ${entityType}`);
    }
    const { id: userId } = selectedUser;

    await connectUserToEntity(addUserToEntity, entityType as ValidOperationsEntities, entityId, userId);

    onChange();
    clearSelectedUser();
    closeAddUserDialog();
  };

  const disconnectUserFromEntityHandler = (user: User) => {
    setSelectedUser({
      emailAddress: user.emailAddress || '',
      id: user.id,
    });
    openDisconnectUserPrompt();
  };

  const onConfirmDisconnectUserFromEntity = async (value: string) => {
    closeDisconnectUserPrompt();

    if (value.toString() === selectedUser.id.toString()) {
      await disconnectUserFromEntity(
        removeUserFromEntity,
        entityType as ValidOperationsEntities,
        entityId,
        selectedUser.id,
      );
      onChange();
    } else {
      setNotification(`Value entered does not match ${entityType} ID`, 'warning');
    }
    clearSelectedUser();
  };

  const onCancelDisconnectUserFromEntity = () => {
    closeDisconnectUserPrompt();
    clearSelectedUser();
  };

  return (
    <div className={classes.root}>
      {!hasUsers && <CalloutMessage type="info" message="No users listed." margin />}

      {hasUsers && (
        <List className={classes.root}>
          {users.map((user: Partial<User>) => {
            const { id } = user;
            return (
              <EntityListItem
                key={`entity-list-item-${entityType}-${id}`}
                entityType={ENTITY_TYPE.USER}
                entity={user as User}
                entityProperties={[
                  {
                    entityProperty: 'emailAddress',
                  },
                ]}
                action={{
                  icon: DeleteForeverIcon,
                  handler: disconnectUserFromEntityHandler,
                }}
              />
            );
          })}
        </List>
      )}

      {!readOnly && (
        <Button label="Add User to Account" onClick={openAddUserDialog} color="primary" variant="outlined" />
      )}

      {!readOnly && isAddUserDialogOpen && (
        <AddUserDialog
          userName={entityName}
          open={isAddUserDialogOpen}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserEmail(event.target.value);
          }}
          emailAddress={selectedUser.emailAddress || ''}
          userIdSetter={setUserId}
          handleAddUser={handleAddUser}
          handleClose={closeAddUserDialog}
        />
      )}
      {showDisconnectUserPrompt && (
        <PromptDialog
          isOpen={showDisconnectUserPrompt}
          title={`Disconnect user ${selectedUser.emailAddress} From ${entityType} (${entityId})`}
          message={`Are you sure you want to continue?\n\n
            Please confirm by entering the user id (${selectedUser.id}) in the text box below and clicking confirm`}
          onConfirm={onConfirmDisconnectUserFromEntity}
          onCancel={onCancelDisconnectUserFromEntity}
        />
      )}
    </div>
  );
};

export default EntityConnectedUsersList;
