import React, { FC, useCallback } from 'react';
import { FeedComment, FeedLegend } from '@mayple/types';
import Grid from '@material-ui/core/Grid';

import FeedPostFiles from './FeedPostFiles';
import FeedContextMenuButton from './FeedContextMenuButton';
import { FEED_MEMBERS } from '../types';
import { useFeedState } from '../FeedContext';
import { formatCommentDate, getAuthorDetails } from '../logic';
import FeedAuthorAvatar from '../FeedAuthorAvatar';
import { useDelayedEventTriggerForElementInView, useFeedEvents } from '../events';

import { TextDisplay } from '../../display';
import RichTextViewer from '../../common/RichTextViewer';

import { useFeedPostCommentStyles } from './styles';

interface FeedPostCommentProps {
  comment: FeedComment;
  legend: FeedLegend;
  postUuid: string;
  classes?: Record<string, string>;
}

type Author = {
  name: string;
  uuid: string;
};

const FeedPostComment: FC<FeedPostCommentProps> = (props) => {
  const { comment, postUuid, legend } = props;
  const { isEditable } = useFeedState();
  const classes = useFeedPostCommentStyles(props);

  const { uuid: commentUuid, authorUuid, content, updated, attachedFiles, permissions } = comment;

  const date = formatCommentDate(updated);
  const author = getAuthorDetails(authorUuid, legend) as Author;
  const { name } = author;

  const { onCommentViewed } = useFeedEvents();

  const onPostViewedCallBack = useCallback(() => {
    onCommentViewed({ postUuid, commentUuid });
  }, [onCommentViewed, postUuid, commentUuid]);

  const { ref } = useDelayedEventTriggerForElementInView(onPostViewedCallBack, 2000, 0.8, true);

  return (
    <Grid ref={ref} container key={`FeedPostComments-${authorUuid}`} className={classes.commentContainer} wrap="nowrap">
      <Grid item className={classes.commentAvatarContainer}>
        <FeedAuthorAvatar author={author} size="small" />
      </Grid>
      <Grid item className={classes.commentContentsContainer}>
        <TextDisplay variant="subtitle1" bold inline className={classes.commentAuthor}>
          {name}
        </TextDisplay>
        <TextDisplay variant="subtitle2" inline>
          {date}
        </TextDisplay>
        <RichTextViewer value={content} />
        <FeedPostFiles classes={classes} files={attachedFiles || []} />
      </Grid>
      {isEditable && (
        <FeedContextMenuButton
          feedMemberUuid={commentUuid}
          permissions={permissions}
          feedMemberType={FEED_MEMBERS.COMMENT}
          classes={{ root: classes.commentContextMenuButton }}
        />
      )}
    </Grid>
  );
};

export default FeedPostComment;
