import React, { useMemo } from 'react';
import { ProjectCycle } from '@mayple/types';

import BillingDocumentsTable from '../../../../../components/cpanel/components/molecules/BillingDocumentsTable';

import SectionContent from '../../../../../../fe_common/client/components/atoms/Section/SectionContent';
import Section, { SECTION_LAYOUT } from '../../../../../../fe_common/client/components/atoms/Section';
import { getProjectCyclesBillingBreakdownDocuments } from '../../../../../components/cpanel/components/molecules/BillingDocumentsTable/logic';

interface BillingBreakdownDocumentsSectionProps {
  cycles: ProjectCycle[];
}

const BillingBreakdownDocumentsSection: React.FC<BillingBreakdownDocumentsSectionProps> = (props) => {
  const { cycles } = props;
  const billingBreakdownDocuments = useMemo(() => getProjectCyclesBillingBreakdownDocuments(cycles), [cycles]);

  return (
    <Section
      title="Billing Breakdown Documents"
      layout={SECTION_LAYOUT.ONE_COLUMN}
      subTitle={
        'These documents have a breakdown of billing events, such as charges to payment agreements, ' +
        'project cycle billing, debt creation and repayment etc.'
      }
    >
      <SectionContent>
        <BillingDocumentsTable billingBreakdownDocuments={billingBreakdownDocuments} withCycleData />
      </SectionContent>
    </Section>
  );
};

export default BillingBreakdownDocumentsSection;
