import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    container: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    pageHeaderRoot: {
      marginTop: 0,
    },
  }),
);

export default useStyles;
