import React, { FC } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

export type LabelValueWithIconProps = {
  label: string;
  value?: string | null | undefined;
  icon?: React.FC | React.ComponentClass;
  showIcon?: boolean | null;
};

const LabelValueWithIcon: FC<LabelValueWithIconProps> = (props) => {
  const { label, value = null, icon: Icon = RefreshIcon, showIcon = false } = props;

  return (
    <div>
      <strong>{label}</strong>&nbsp;
      <span>{value}</span>
      {showIcon && <Icon />}
    </div>
  );
};

export default LabelValueWithIcon;
