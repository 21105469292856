import { ReactNode } from 'react';
import { Column, RowRenderProps } from 'react-table-6';

import ChipListCell from './reactTableHelpers';
import { internalTeamLinkFormatter } from '../../logic/linkFormatters';

import { images } from '../../../fe_common/client/app/images';

import {
  InternalTeamTypeFilter,
  ProjectSpecialTypeFilter,
} from '../../../fe_common/client/services/reactTableHelpers/filters';
import {
  defaultEnumArrayValueFilterMethod,
  defaultEnumSingleValueFilterMethod,
} from '../../../fe_common/client/services/reactTableHelpers/filterMethods';

const columns: Column<Record<string, any>>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: (d: RowRenderProps): ReactNode => internalTeamLinkFormatter(d.original),
  },
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'internalTeamType',
    Filter: InternalTeamTypeFilter,
    filterMethod: defaultEnumSingleValueFilterMethod,
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: 'description',
  },
  {
    id: 'workingGroup',
    Header: 'Working Group',
    accessor: 'projectAutomaticAssignmentSettings.workingGroupOnProjectTypes',
    Filter: ProjectSpecialTypeFilter,
    filterMethod: defaultEnumArrayValueFilterMethod,
    Cell: ChipListCell,
  },
];

export const getRandomLogoImage = (): string =>
  // @ts-ignore
  images[`maypleAvatar0${Math.ceil(Math.random() * 6)}`];

export default columns;
