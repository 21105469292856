import React, { FC } from 'react';
import LockIcon from '@material-ui/icons/Lock';

import texts from './texts';

import { TextDisplay } from '../../../../../display';
import CalloutMessage from '../../../../../common/CalloutMessage';

import useStyles from './style';

const ConnectCalendarExplanation: FC = () => {
  const classes = useStyles();

  return (
    <div>
      <TextDisplay variant="h5" gutterBottom>
        {texts.dialogTitle}
      </TextDisplay>
      <TextDisplay variant="subtitle2" color="textSecondary" className={classes.dialogSubtitle}>
        {texts.dialogSubtitle}
      </TextDisplay>
      <TextDisplay variant="overline" color="textSecondary">
        INSTRUCTIONS:
      </TextDisplay>
      <ul className={classes.ul}>
        {texts.instructions.map((item, index) => (
          <li key={`ConnectCalendarExplanationDialog-instructions-item-${index}`}>
            <TextDisplay variant="body1" color="textSecondary" gutterBottom>
              {item}
            </TextDisplay>
          </li>
        ))}
      </ul>
      <CalloutMessage type="success" title={texts.calloutTitle} message={texts.calloutSubtitle} icon={<LockIcon />} />
    </div>
  );
};

export default ConnectCalendarExplanation;
