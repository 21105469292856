import React from 'react';

export enum WizardStepFieldTypes {
  AUTOCOMPLETE = 'autoComplete',
  AUTOCOMPLETE_RADIO_BUTTONS = 'autoCompleteRadioButtons',
  AVAILABILITY_SELECTION = 'availabilitySelection',
  CATEGORIES_INPUT = 'categoriesInput',
  CHECKBOX = 'checkbox',
  CHECKBOX_CARD = 'checkboxCard',
  CURRENCY = 'currency',
  CURRENCY_RANGE = 'currencyRange',
  CUSTOM = 'custom',
  DATE_PICKER = 'datePicker',
  DATE_TIME_PICKER = 'dateTimePicker',
  EMAIL = 'email',
  ENTITY_AVATAR_CHECKBOX = 'entityAvatarCheckbox',
  FILE_UPLOAD = 'fileUpload',
  FILE_UPLOAD_2 = 'fileUpload2',
  IMAGE_PICKER = 'imagePicker',
  IMAGE_PICKER_2 = 'imagePicker2',
  MULTI_AUTOCOMPLETE = 'multiAutoComplete',
  MULTI_CHECKBOX = 'multiCheckbox',
  MULTI_SELECT = 'multiSelect',
  MULTI_SELECT_AUTOCOMPLETE = 'multiSelectAutoComplete',
  NUMBER = 'number',
  NUMERIC_RANGE = 'numericRange',
  PASSWORD = 'password',
  PHONE2 = 'phone2',
  PHONE = 'phone',
  RADIO = 'radio',
  RADIO_CARDS = 'radioCards',
  RADIO_CARDS_WITH_SLIDER = 'radioCardsWithSlider',
  RICH_TEXT = 'richText',
  SCORE_BUTTONS = 'scoreButtons',
  SELECT = 'select',
  SLIDER = 'slider',
  STARS_VOTE = 'starsVote',
  SWITCH = 'switch',
  TEXT = 'text',
  TEXT_LIST = 'textList',
  URL = 'url',
  URL_LIST = 'urlList',
  MULTIPLE_PACKAGES_SELECTOR = 'multiplePackagesSelector',
}

export type WizardFieldProps = {
  name: string;
  type: WizardStepFieldTypes;
  id?: string;
  customComponent?: React.ReactNode;
  wizardFieldLabel?: React.ReactNode | string;
  wizardFieldTooltip?: string;
  style?: Record<string, any>;
  styleConstants?: Record<string, any>;
  required?: boolean;
  vertical?: boolean;
  labelProps?: Record<string, any>;
  valueProps?: Record<string, any>;
  fieldProps?: Record<string, any>;
  classesOverride?: Record<string, string>;
};
