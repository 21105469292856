import { useEffect, useState } from 'react';
import { Integration } from '@mayple/types';
import { ApolloError } from 'apollo-client';

import useActingAccount from '../useActingAccount';

const useActingAccountIntegrations = (): {
  actingAccountIntegrations: Integration[] | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<any>;
} => {
  const [actingAccountIntegrations, setActingAccountIntegrations] = useState<Integration[] | null>(null);
  // TODO: remove this actingAccount and use explicit entity
  const { actingAccount, loading, error, refetch } = useActingAccount();

  useEffect(() => {
    if (error) {
      setActingAccountIntegrations(null);
    }
    if (!loading && !error && actingAccount) {
      setActingAccountIntegrations(actingAccount?.integrations || null);
    }
  }, [actingAccount, error, loading]);

  return {
    actingAccountIntegrations,
    loading,
    error,
    refetch,
  };
};

export default useActingAccountIntegrations;
