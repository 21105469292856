import React, { FC, useMemo } from 'react';
import { FundAccount } from '@mayple/types';

import { getPayoutDetailsProperties } from './logic';

import PropertiesList from '../../../../../fe_common/client/components/display/PropertiesList';

import useStyles from './style';

export type MarketerPayoutDetailsProps = {
  marketerFundAccount: FundAccount | null | undefined;
};

const MarketerPayoutDetails: FC<MarketerPayoutDetailsProps> = (props) => {
  const { marketerFundAccount } = props;
  const classes = useStyles();

  const { payoutDetails, preferredPayoutType } = marketerFundAccount || ({} as FundAccount);
  const payoutDetailsProperties = useMemo(
    () => getPayoutDetailsProperties(preferredPayoutType, payoutDetails),
    [preferredPayoutType, payoutDetails],
  );

  const data = {
    preferredPayoutType,
    ...payoutDetails,
  };

  return (
    <PropertiesList
      id="payoutDetails"
      data={data}
      propertiesMeta={payoutDetailsProperties}
      classes={{
        propertyLabel: classes.propertyLabel,
        propertyValue: classes.propertyValue,
      }}
    />
  );
};

export default MarketerPayoutDetails;
