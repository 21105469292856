import React, { FC, useMemo } from 'react';
import { FeedLegend } from '@mayple/types';
import isEmpty from 'lodash/isEmpty';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';

import { FeedFilterProps, FeedFilterType, FilterType } from './types';
import FeedSingleFilter from './FeedSingleFilter';
import TeamMembersFilter from './TeamMembersFilter';
import TopicsFilter from './TopicsFilter';
import FeedFilterHeader from './FeedFilterHeader';
import { getFilterCount, getFilterSelectedValues, getTeamMembers, getTopicsFromPosts } from './logic';
import { Button } from '../../inputs';

import useStyles from './style';

const FeedFilter: FC<FeedFilterProps> = (props) => {
  const {
    feed,
    filter = {} as FeedFilterType,
    legend = {} as FeedLegend,
    onSetFilter,
    showFilterHeader = false,
  } = props;

  const classes = useStyles(props);
  const { posts } = feed;

  const filterCount = getFilterCount(filter);
  const disableClearAll = isEmpty(filter) || filterCount === 0;

  const teamMembers = useMemo(() => getTeamMembers(legend, posts), [legend, posts]);

  const filteredTopics = useMemo(() => getTopicsFromPosts(posts), [posts]);

  const selectedMembers = getFilterSelectedValues(filter, FilterType.TEAM_MEMBERS);
  const selectedTopics = getFilterSelectedValues(filter, FilterType.TOPICS);

  const handleClearFilter = () => {
    onSetFilter({});
  };

  const handleOnFilterChange = (filterType: FilterType, value: string[]) => {
    const newFilter = { ...filter };
    newFilter[filterType] = value;

    onSetFilter(newFilter);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {showFilterHeader && <FeedFilterHeader filter={filter} />}
      <FeedSingleFilter title="Team Members">
        <TeamMembersFilter
          teamMembers={teamMembers}
          selectedMembers={selectedMembers}
          onFilterChange={handleOnFilterChange}
        />
      </FeedSingleFilter>
      <Divider className={classes.divider} />
      <FeedSingleFilter title="Topics">
        <TopicsFilter topics={filteredTopics} selectedTopics={selectedTopics} onFilterChange={handleOnFilterChange} />
      </FeedSingleFilter>
      <Hidden smDown>
        <div className={classes.actionsContainer}>
          <Button size="large" label="clear all" onClick={handleClearFilter} disabled={disableClearAll} />
        </div>
      </Hidden>
    </Paper>
  );
};

export default FeedFilter;
