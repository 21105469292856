import React, { FC, useMemo } from 'react';
import { RowRenderProps, SortingRule } from 'react-table-6';
import { AdminAccessibleCompaniesPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleCompaniesPagedQuery';

import { companyLinkFormatter } from '../../logic/linkFormatters';

import QueryTable, { BoolFilter, ContainsFilter } from '../../../fe_common/client/components/molecules/QueryTable';

const initialSorted: SortingRule[] = [
  {
    id: 'id',
    desc: true,
  },
];

const CompaniesTable: FC = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 100,
        Filter: ContainsFilter({ key: 'id' }),
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        Cell: (d: RowRenderProps) => companyLinkFormatter(d.original),
        Filter: ContainsFilter({ key: 'name' }),
      },
      {
        Header: 'Approved',
        accessor: 'approved',
        Cell: (d: RowRenderProps) => (d.original?.approved ? 'Yes' : 'No'),
        Filter: BoolFilter({ key: 'approved' }),
      },
    ],
    [],
  );

  return (
    <QueryTable
      queryClass={AdminAccessibleCompaniesPagedQuery}
      entityKey="companiesPaged"
      columns={columns}
      initialSorted={initialSorted}
    />
  );
};

export default CompaniesTable;
