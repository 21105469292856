import React, { FC } from 'react';
import { submit, destroy } from 'redux-form';
import { useDispatch } from 'react-redux';

import Button, { ButtonProps } from '../../inputs/Button';

interface RemoteSubmitButtonProps extends ButtonProps {
  form: string;
  onBeforeSubmit?: () => void;
  formIsInvalid?: boolean;
  destroyFormDataAfterSubmit?: boolean;
}

const defaultDoNothing = () => {
  /* Do nothing */
};

/**
 * A very useful button that can be used to submit (and destroy if needed) a redux form
 * This is for buttons that are not inside the form itself and need to reference it from
 * another place in the page
 */
const RemoteSubmitButton: FC<RemoteSubmitButtonProps> = (props) => {
  const {
    form,
    formIsInvalid = false,
    onBeforeSubmit = false,
    destroyFormDataAfterSubmit = false,
    onClick = defaultDoNothing,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onBeforeSubmit) {
      onBeforeSubmit();
    }

    if (formIsInvalid) {
      onClick(event);
    } else {
      await dispatch(submit(form));
      onClick(event);
    }

    if (destroyFormDataAfterSubmit) {
      await dispatch(destroy(form));
    }
  };

  return <Button onClick={onClickHandler} {...rest} />;
};

export default RemoteSubmitButton;
