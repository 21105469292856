import { getInsightsFeedParticipantFromCache, writeInsightsFeedParticipantToCache } from '../logic';

export const COMMENT_EDITOR_MODES = {
  IDLE: 'IDLE',
  IDLE_CLICKED: 'IDLE_CLICKED',
  ACTIVE: 'ACTIVE',
};

export const getMutationOptions = (postUuid, projectId, marketerId) => ({
  update(cache, { data }) {
    const participant = getInsightsFeedParticipantFromCache(cache, projectId, marketerId);
    const newComment = data?.createFeedPostComment;

    if (!participant || !newComment) {
      return;
    }

    const posts = participant?.insightsFeedMaterializedView?.posts || [];
    const postIndex = posts.findIndex(({ uuid }) => uuid === postUuid);

    participant.insightsFeedMaterializedView.posts[postIndex].comments.push(newComment);

    writeInsightsFeedParticipantToCache(cache, projectId, marketerId, participant);
  },
});
