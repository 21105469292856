/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MUISelect from '@material-ui/core/Select';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';

export type SelectOptions = {
  value: string;
  label: string;
  isDeprecated?: boolean;
  disabled?: boolean;
};

interface SelectProps extends Partial<WrappedFieldProps> {
  name?: string;
  label?: string;
  value?: any;
  onSelect?: (newValue: any) => void;
  disabled?: boolean;
  enums: SelectOptions[];
  helperText?: string;
  showError?: boolean;
  hideEmptyValue?: boolean;
  className?: string;
}

/**
 * MUI select component that uses the native device options selector
 */
export const Select: FC<SelectProps> = (props) => {
  const {
    name,
    label: fieldLabel = 'Please select',
    value: controlledValue,
    enums,
    onSelect = () => {
      /* Do Nothing */
    },
    helperText = '',
    showError = true,
    disabled = false,
    className = '',
    // if used as redux field
    input = {} as WrappedFieldInputProps,
    meta = {} as WrappedFieldMetaProps,
    hideEmptyValue = false,
  } = props;

  // console.log('Select props:', props);
  const { value, onChange } = input;

  const hasError = meta?.error && meta?.error !== '';

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as any;
    onSelect?.(newValue);
    if (onChange && typeof onChange === 'function') {
      onChange(newValue, value || controlledValue);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <InputLabel htmlFor="outlined-age-native-simple">{fieldLabel}</InputLabel>
      <MUISelect
        name={name}
        native
        value={value || controlledValue || ''}
        onChange={handleChange}
        error={showError && hasError && meta?.touched}
        input={<OutlinedInput name="outlined" labelWidth={fieldLabel.length * 8.2} id="outlined-age-native-simple" />}
        classes={{ root: className }}
      >
        {!hideEmptyValue && <option value="" />}
        {enums
          .filter(({ isDeprecated }) => !isDeprecated)
          .map(({ label, value: optionValue, disabled: optionDisabled }) => (
            <option key={`${optionValue}-native-s`} label={label} disabled={optionDisabled} value={optionValue}>
              {label}
            </option>
          ))}
      </MUISelect>
      {hasError && showError && meta?.touched && <FormHelperText error={hasError}>{meta?.error}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
