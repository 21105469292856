export interface MarketersPageProps {
  classes?: Record<string, string>;
}

export enum MarketerPageTabs {
  marketersTable,
  advancedMarketerSearch,
  multipleMarketersAvailability,
  marketersPayoutReview,
}
