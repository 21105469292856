import React, { useEffect, useState } from 'react';
import {
  MarketerMatch,
  NoSelectionOption,
  OpportunityState,
  Project,
  ProjectMarketerOpportunity,
  SuccessStory,
} from '@mayple/types';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import List from '@material-ui/core/List';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import MarketerListItem from './MarketerListItem';
import { withAssignedGSVerification } from '../../../logic/growthStrategist';
import SuccessStoriesPanels from '../../../components/cpanel/components/molecules/SuccessStoriesPanels';

import { formatMeetingDate } from '../../../../fe_common/client/components/onboarding/MarketingPlanProvider/logic';
import { TextDisplay } from '../../../../fe_common/client/components/display';

const useStyles = makeStyles(() => ({
  root: {},
  optyCtn: {
    marginBottom: 8,
    paddingBottom: 16,
    borderBottom: '1px solid #aeaeae',
  },
  accordionDetails: {
    width: '100%',
  },
  clientSlotsMessage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
    lineHeight: '2rem',
  },
}));

interface AppliedOpportunitiesMarketersProps {
  opportunities?: ProjectMarketerOpportunity[];
  marketerMatches: MarketerMatch[];
  onSelectOpportunity: (o: ProjectMarketerOpportunity) => void;
  project: Partial<Project> | null | undefined;
}

const appliedOpportunities = [
  OpportunityState.REVIEWED,
  OpportunityState.APPLIED,
  OpportunityState.SELECTED,
  OpportunityState.REJECTED,
  OpportunityState.REPLACED,
];

/**
 * Display a list of marketers who applied to the project opportunity
 * Allowing the user to click on the Marketer and select it
 *
 * @param props
 * @constructor
 */
const AppliedOpportunitiesMarketers: React.FC<AppliedOpportunitiesMarketersProps> = (props) => {
  const classes = useStyles();
  const { opportunities = [], marketerMatches, onSelectOpportunity, project } = props;
  const [marketerMatchesHash, setMarketerMatchesHash] = useState<Dictionary<MarketerMatch>>({});

  useEffect(() => {
    setMarketerMatchesHash(keyBy(marketerMatches, 'marketerId'));
  }, [marketerMatches]);

  return (
    <>
      <TextDisplay variant="h6" bold>
        Sent opportunities
      </TextDisplay>
      <TextDisplay variant="subtitle2" gutterBottom>
        You can set the selected marketer for this project from the list of marketers that applied for this project.
        <br />
        You can do that only if <strong>none</strong> of the opportunities are in OFFERED status.
      </TextDisplay>
      <List className={classes.root}>
        {opportunities.map((opportunity: ProjectMarketerOpportunity) => {
          const {
            marketerId,
            opportunityState,
            introduceYourself,
            selectedSuccessStories,
            uuid,
            selectedSlots,
            freeSlots,
          } = opportunity;

          const marketer = {
            ...marketerMatchesHash[marketerId],
            ...opportunity?.marketer,
            opportunityState,
          };

          const handleConnectMarketerToProject = () => {
            onSelectOpportunity(opportunity);
          };
          const wrappedHandleConnectMarketerToProject = withAssignedGSVerification(
            project,
            handleConnectMarketerToProject,
          );

          const actionProps =
            opportunityState === OpportunityState.APPLIED
              ? {
                  icon: AddOutlinedIcon,
                  label: 'Select marketer for client approval',
                  handler: wrappedHandleConnectMarketerToProject,
                }
              : null;

          return (
            <div key={`AppliedOpportunitiesMarketers-MarketerListItem-${uuid}`} className={classes.optyCtn}>
              <MarketerListItem
                marketer={marketer}
                marketerProperties={[
                  {
                    marketerProperty: 'opportunityState',
                    propertyLabel: 'Opportunity Status: ',
                  },
                ]}
                action={actionProps}
              />
              {appliedOpportunities.includes(opportunityState) && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>Application details</AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.accordionDetails}>
                      <TextDisplay>
                        <strong>Introduction:</strong> {introduceYourself}
                      </TextDisplay>
                      <Divider />
                      <TextDisplay>
                        <strong>Selected Availability:</strong>
                      </TextDisplay>
                      {!!selectedSlots?.slots?.length &&
                        selectedSlots?.slots.map(({ startDate, endDate }, index) => (
                          <TextDisplay key={`selected-slots-${marketerId}-${index}`}>
                            {formatMeetingDate(startDate)} - {formatMeetingDate(endDate)}
                          </TextDisplay>
                        ))}
                      {selectedSlots?.noSelectionOption === NoSelectionOption.I_CANT_MAKE_ANY_OF_THE_ABOVE_TIMES && (
                        <TextDisplay>The Expert couldn't find any available slots.</TextDisplay>
                      )}
                      {!selectedSlots?.noSelectionOption && !selectedSlots?.slots?.length && (
                        <TextDisplay>N/A</TextDisplay>
                      )}
                      {!!freeSlots?.slots?.length && (
                        <TextDisplay className={classes.clientSlotsMessage}>
                          <DoneOutlineIcon color="primary" />
                          The client have selected {freeSlots?.slots?.length} slots
                        </TextDisplay>
                      )}
                      {!freeSlots?.slots?.length && (
                        <TextDisplay className={classes.clientSlotsMessage}>
                          <HighlightOffIcon color="secondary" />
                          The client didn't select any slot.
                        </TextDisplay>
                      )}
                      <Divider />
                      <TextDisplay bold>Selected success stories:</TextDisplay>
                      <SuccessStoriesPanels successStories={(selectedSuccessStories || []) as SuccessStory[]} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          );
        })}
      </List>
    </>
  );
};

export default AppliedOpportunitiesMarketers;
