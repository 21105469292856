import { Integration, IntegrationStatus, IntegrationType } from '@mayple/types';

import useActingAccountIntegrations from '../../../../../hooks/useActingAccountIntegrations';

export const filterCronofyIntegration = (integration: Integration): boolean => {
  const { integrationStatus, integrationType } = integration;

  return integrationStatus === IntegrationStatus.ACTIVE && integrationType === IntegrationType.CRONOFY;
};

export const getCronofyIntegration = (integrations: Integration[] | null | undefined): Integration | null => {
  const [cronofyIntegration] = (integrations || []).filter(filterCronofyIntegration);
  return cronofyIntegration;
};

const useCronofyIntegration = (): {
  cronofyIntegration: Integration | null;
  refetch: () => Promise<any>;
} => {
  const { actingAccountIntegrations: integrations, refetch } = useActingAccountIntegrations();

  const cronofyIntegration = getCronofyIntegration(integrations);

  return {
    cronofyIntegration,
    refetch,
  };
};

export default useCronofyIntegration;
