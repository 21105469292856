import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, getGradientColor } from '../../../app/theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: 40,
  },
  iconContainer: {
    width: 88,
    height: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white_darker,
    borderRadius: '50%',
    marginBottom: 32,
  },
  icon: {
    color: colors.cherry,
    '& svg': {
      width: '48px !important',
      height: '48px !important',
    },
    '& g path': {
      stroke: colors.cherry,
    },
  },
  primaryContainer: {
    marginBottom: 8,
  },
  primary: {
    fontWeight: 'bold',
    color: getGradientColor('gray', 80),
    textAlign: 'center',
  },
  secondaryContainer: {},
  secondary: {
    textAlign: 'center',
  },
}));

export default useStyles;
