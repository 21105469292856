import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ProjectCyclePhase } from '@mayple/types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FullProjectByIdQuery } from 'growl-graphql/dist/queries/FullProjectByIdQuery';
import { ProjectCyclesQuery } from 'growl-graphql/dist/queries/project/ProjectCyclesQuery';
import { UpdateProjectCycleAdditionalServicesMutation } from 'growl-graphql/dist/mutations/UpdateProjectCycleAdditionalServicesMutation';
import { UpdateProjectCycleWithOperationResultMutation } from 'growl-graphql/dist/mutations/UpdateProjectCycleWithOperationResultMutation';
import { UpdateProjectCyclePauseDurationsMutation } from 'growl-graphql/dist/mutations/UpdateProjectCyclePauseDurationsMutation';
import { Link } from 'react-router-dom';

import ProjectCycleFinalizationEditWizard from '../../containers/project/wizards/ProjectCycleFinalizationEditWizard';

import { tryParseInt } from '../../../fe_common/client/services/utils';
import { RefreshSpinner } from '../../../fe_common/client/components/atoms';
import { Button } from '../../../fe_common/client/components/inputs';

const ProjectCycleFinalizationPage = ({ match }) => {
  const projectId = tryParseInt(match.params.projectId);
  const cycleId = tryParseInt(match.params.cycleId);

  const updateProjectCyclePauseDurationsMutation = useMutation(UpdateProjectCyclePauseDurationsMutation.query);
  const updateProjectCycleAdditionalServicesMutation = useMutation(UpdateProjectCycleAdditionalServicesMutation.query);
  const updateProjectCycleMutation = useMutation(UpdateProjectCycleWithOperationResultMutation.query);
  const {
    data,
    loading,
    error,
    refetch: refetchProjectData,
  } = useQuery(FullProjectByIdQuery.query, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const {
    data: projectCyclesData,
    loading: projectCyclesLoading,
    error: projectCyclesError,
    refetch: refetchProjectCyclesData,
  } = useQuery(ProjectCyclesQuery.query, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const refetchAll = useCallback(async () => {
    await refetchProjectData();
    await refetchProjectCyclesData();
  }, [refetchProjectCyclesData, refetchProjectData]);

  if (loading || projectCyclesLoading) {
    return <RefreshSpinner linear />;
  }

  if (error || projectCyclesError) {
    return <div>{JSON.stringify(error || projectCyclesError)}</div>;
  }

  const { project } = data;
  const cycles = projectCyclesData?.project?.cycles || [];
  const elapsedCycle = cycles.find(({ id }) => id === cycleId);
  const { phase, isReadyForFinalBilling } = elapsedCycle;

  if (phase !== ProjectCyclePhase.ELAPSED) {
    return (
      <h1>
        You cannot edit a cycle at phase: <strong>{phase}</strong> for finalization!
      </h1>
    );
  }
  //
  if (isReadyForFinalBilling) {
    return <h1>You cannot edit a cycle that is already confirmed for billing!</h1>;
  }

  return (
    <div className="p-8">
      <Link to={`/projects/${projectId}${window.location.hash || ''}`}>
        <Button label="BACK TO PROJECT PAGE" color="primary" />
      </Link>
      <ProjectCycleFinalizationEditWizard
        loading={loading}
        project={project}
        cycles={cycles}
        cycleId={cycleId}
        updateProjectCyclePauseDurationsMutation={updateProjectCyclePauseDurationsMutation}
        updateProjectCycleAdditionalServicesMutation={updateProjectCycleAdditionalServicesMutation}
        updateProjectCycleMutation={updateProjectCycleMutation}
        onSuccessfulUpdate={refetchAll}
      />
    </div>
  );
};

ProjectCycleFinalizationPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ProjectCycleFinalizationPage;
