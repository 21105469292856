import { useQuery } from '@apollo/react-hooks';
import { ProjectSponsorPaymentsReportQuery } from 'growl-graphql/dist/queries/sponsor/ProjectSponsorPaymentsReportQuery';

import { PayInReportData } from './types';

const MC_SPONSOR_ID = 1;

const usePayInReportData = (generateReport = false): PayInReportData => {
  const variables = { projectSponsorId: MC_SPONSOR_ID, excludeInvoicedPayments: true };
  const { data, loading, error, refetch } = useQuery(ProjectSponsorPaymentsReportQuery.query, {
    variables,
    skip: !generateReport,
  });

  return {
    loading,
    error,
    refetch,
    payments: data?.projectSponsor?.payments || [],
  };
};
export default usePayInReportData;
