import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    summaryDeckFileContainer: {},
    fileListContainer: {},
    fileListRoot: {},
    actionsContainer: {
      display: 'flex',
      gap: '2rem',
    },
  }),
);

export default useStyles;
