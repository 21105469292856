import { colors } from '../../../app/theme';

export const styles = () => ({
  container: {},
  root: {},
  tableContainer: {},
  tableHead: {},
  tableHeadRow: {
    textTransform: 'uppercase',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  tableBody: {},
  tableRow: {},
  tableEmptyRow: {},
  tableCell: {},

  collapsibleTableRoot: {},
  collapsibleTableContainer: {},
  collapsibleTableHead: {},
  collapsibleTableHeadRow: {
    textTransform: 'uppercase',
  },
  collapsibleTableHeadCell: {
    fontWeight: 'bold',
  },
  collapsibleTableBody: {},
  collapsibleTableRow: {},
  collapsibleTableEmptyRow: {},
  collapsibleTableCell: {},
});

export const collapsibleTableStyles = () => ({
  root: {},
  tableContainer: {},
  tableHead: {},
  tableHeadRow: {
    textTransform: 'uppercase',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    color: colors.black_light,
    borderBottom: 0,
    padding: 8,
  },
  tableBody: {},
  tableRow: {},
  tableEmptyRow: {},
  tableCell: {},
});

export const rowStyles = () => ({
  tableRow: {},
  tableCell: {},
});

export const collapsibleRowStyles = () => ({
  tableRow: {},
  tableCell: {},
  collapsibleTableRoot: {},
  collapsibleTableContainer: {},
  collapsibleTableHead: {},
  collapsibleTableHeadRow: {},
  collapsibleTableHeadCell: {},
  collapsibleTableBody: {},
  collapsibleTableRow: {},
  collapsibleTableEmptyRow: {},
  collapsibleTableCell: {},
});
