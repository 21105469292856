import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult, OperationVariables } from 'apollo-client';
import { AssessmentResponse, FilterRequest, PaginationRequest } from '@mayple/types';
import { ProjectAssessmentResponsesQuery } from 'growl-graphql/dist/queries/assessment/ProjectAssessmentResponsesQuery';

export type ProjectAssessmentResponseVariables = {
  projectId: number | null | undefined;
  pagination?: PaginationRequest | undefined;
  filter?: FilterRequest | undefined;
};

export type ProjectAssessmentResponseData = {
  projectAssessments: AssessmentResponse[] | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: OperationVariables | ProjectAssessmentResponseVariables | undefined,
  ) => Promise<ApolloQueryResult<any>>;
};

const useProjectAssessmentResponse = (
  variables: ProjectAssessmentResponseVariables,
  queryOptions?: QueryHookOptions,
): ProjectAssessmentResponseData => {
  const { data, loading, error, refetch } = useQuery(ProjectAssessmentResponsesQuery.query, {
    variables,
    skip: !variables?.projectId,
    ...queryOptions,
  });

  return {
    projectAssessments: data?.project?.assessmentResponses,
    loading,
    error,
    refetch,
  };
};

export default useProjectAssessmentResponse;
