import React from 'react';
import { ProjectAutomaticConditionalStoppingProps } from './types';
import useProjectStopConditions from './useProjectStopConditions';

import SwitchField from '../../../../../../../fe_common/client/components/atoms/SwitchField';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';

import useStyles from './style';
import ProjectStopConditions from './ProjectStopConditions';

const ProjectAutomaticConditionalStopping: React.FC<ProjectAutomaticConditionalStoppingProps> = (props) => {
  const classes = useStyles();
  const { projectId } = props;

  const { loading, saving, project, onAllowAutomaticConditionalStoppingChangeHandler } =
    useProjectStopConditions(projectId);

  const allowAutomaticConditionalStopping = project?.allowAutomaticConditionalStopping || false;
  const disableAllowAutomaticConditionalStopping = loading || saving;
  const labelAllowAutomaticConditionalStopping = allowAutomaticConditionalStopping ? 'Enabled' : 'Disabled';

  return (
    <div className={classes.root}>
      <TextDisplay variant="h6" gutterBottom>
        Automatic Conditional Stopping
      </TextDisplay>
      <SwitchField
        name="allowAutomaticConditionalStopping"
        checked={allowAutomaticConditionalStopping}
        disabled={disableAllowAutomaticConditionalStopping}
        label={labelAllowAutomaticConditionalStopping}
        onChange={onAllowAutomaticConditionalStoppingChangeHandler}
        helperText="Enable/disable the automatic stopping of projects based on conditions. If this option is enabled - the project will automatically be stopped at 1pm UTC of the day after all stopping conditions are met."
      />
      <ProjectStopConditions stopConditions={project?.stopConditions} />
    </div>
  );
};

export default ProjectAutomaticConditionalStopping;
