import React, { FC, useMemo } from 'react';
import { AdditionalService } from '@mayple/types';
import classNames from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { PackageContext } from './logic';
import PackageExamples from './PackageExamples';
import PackageAboutSection from './PackageAboutSection';
import PackageWhatExpertsDo from './PackageWhatExpertsDo';
import PackageWhyChooseThisPackage from './PackageWhyChooseThisPackage';
import PackageOverview from './PackageOverview';
import PackageContactUs from './PackageContactUs';
import Section from '../../atoms/Section';

import useStyles from './style';

interface PackageContentProps {
  service: AdditionalService | null;
  onBookPackageClick?: () => void;
  showChatLink?: boolean;
  noCTA?: boolean;
}

const PackageContent: FC<PackageContentProps> = (props) => {
  const { service, onBookPackageClick, showChatLink = true, noCTA = false } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const context = useMemo(
    () => ({
      data: service || {},
      isSmDown,
      showChatLink,
    }),
    [isSmDown, service, showChatLink],
  );

  if (!service) {
    return null;
  }

  const hasWhatExpertsDoSection = (service?.marketingServiceTypeInfo?.whatExpertsDo || []).length > 0;
  const hasPackageWhyChooseThisPackage = (service?.marketingServiceTypeInfo?.whyChooseThis || []).length > 0;

  return (
    <div>
      <PackageContext.Provider value={context}>
        <Section
          noBorder
          classes={{
            root: classes.section,
            sectionHeader: classes.sectionHeader,
          }}
        >
          <div className={classNames(classes.smallSection)}>
            <PackageExamples />
          </div>
          <div className={classNames(classes.smallSection)}>
            <PackageOverview onBookPackageClick={onBookPackageClick} noCTA={noCTA} />
          </div>
        </Section>
        <PackageAboutSection />
        {hasWhatExpertsDoSection && (
          <div className={classes.whatExpertsDo}>
            <Section
              noBorder
              classes={{
                root: classNames(classes.section),
                sectionHeader: classes.sectionHeader,
              }}
            >
              <PackageWhatExpertsDo />
            </Section>
          </div>
        )}
        {hasPackageWhyChooseThisPackage && (
          <div className={classes.whyChooseThisPackage}>
            <Section
              noBorder
              classes={{
                root: classNames(classes.section),
                sectionHeader: classes.sectionHeader,
              }}
            >
              <PackageWhyChooseThisPackage />
            </Section>
          </div>
        )}
        <div className={classes.contactUsContainer}>
          <PackageContactUs />
        </div>
      </PackageContext.Provider>
    </div>
  );
};

export default PackageContent;
