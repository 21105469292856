import { colors } from '../../../../../../../fe_common/client/app/theme';

export const cycleStatusStyles = () => ({
  pending: { color: colors.yellow_dark },
  ongoing: { color: colors.green_darkest },
  elapsed: { color: colors.blue },
  finalized: { color: colors.red_light },
  canceled: { color: colors.red },
});

export const getClassByCyclePhase = (phase, classes) => {
  const mapper = {
    PENDING: classes.pending,
    ONGOING: classes.ongoing,
    ELAPSED: classes.elapsed,
    FINALIZED: classes.finalized,
    CANCELED: classes.canceled,
  };

  return mapper[phase] || '';
};

export const styles = (theme) => ({
  root: {
    paddingBottom: 32,
  },
  infoIcon: {
    height: 18,
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
  },
  toolTipTrigger: {
    cursor: 'pointer',
  },
  htmlTooltip: {
    backgroundColor: colors.white,
    color: colors.blackOpacity(0.87),
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    maxWidth: '500px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
    },
  },
});

export const projectCycleDetailsHeaderStyles = () => ({
  root: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  ...cycleStatusStyles(),
});
