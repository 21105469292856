import React, { useCallback, useMemo } from 'react';
import {
  ProjectExistingMarketingEvaluationReportDetails,
  ProjectExistingMarketingEvaluationReportV1Details,
  ProjectExistingMarketingEvaluationReportV2Details,
  ProjectExistingMarketingEvaluationReportV3Details,
  ProjectExistingMarketingEvaluationState,
  ProjectSpecialSubType,
  SharedFile,
  SharedFileCategory,
} from '@mayple/types';
import { ApproveExistingMarketingEvaluationReportByCompanyMutation } from 'growl-graphql/dist/mutations/company/ApproveExistingMarketingEvaluationReportByCompanyMutation';
import { ApproveExistingMarketingEvaluationReportByGrowthStrategistMutation } from 'growl-graphql/dist/mutations/project/ExistingMarketingEvaluation/ApproveExistingMarketingEvaluationReportByGrowthStrategistMutation';
import { SetCommentsOnExistingMarketingEvaluationByGrowthStrategistMutation } from 'growl-graphql/dist/mutations/project/ExistingMarketingEvaluation/SetCommentsOnExistingMarketingEvaluationByGrowthStrategistMutation';

import {
  MarketerProjectMarketingEvaluationAuditReportData,
  MarketerProjectMarketingEvaluationAuditReportProps,
} from './types';

import useMutation from '../../../../../fe_common/client/hooks/useMutation';
import useProjectScheduledMeetings from '../../../../../fe_common/client/hooks/project/useProjectScheduledMeetings';
import useProjectExistingMarketingEvaluation from '../../../../../fe_common/client/hooks/project/useProjectExistingMarketingEvaluation';
import { useHealthCheckMeetingType } from '../../../../../fe_common/client/logic/meetings';
import ExistingMarketingEvaluationReportV1Details, {
  ExistingMarketingEvaluationReportV1DetailsProps,
} from './ExistingMarketingEvaluationReportV1Details';
import ExistingMarketingEvaluationReportV2Details, {
  ExistingMarketingEvaluationReportV2DetailsProps,
} from './ExistingMarketingEvaluationReportV2Details';
import ExistingMarketingEvaluationReportV3Details, {
  ExistingMarketingEvaluationReportV3DetailsProps,
} from './ExistingMarketingEvaluationReportV3Details';

const getReportDetails = (
  projectSpecialSubType: ProjectSpecialSubType | undefined,
  marketerReport: ProjectExistingMarketingEvaluationReportDetails | null | undefined,
):
  | ProjectExistingMarketingEvaluationReportV1Details
  | ProjectExistingMarketingEvaluationReportV2Details
  | ProjectExistingMarketingEvaluationReportV3Details
  | null
  | undefined => {
  if (projectSpecialSubType === ProjectSpecialSubType.OUTBRAIN_HEALTH_CHECK) {
    return marketerReport?.reportOutbrainV1;
  }

  return projectSpecialSubType === ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK
    ? marketerReport?.reportV1
    : marketerReport?.reportV2;
};

interface MarketerProjectMarketingEvaluationAuditReportDetails extends Record<string, any> {
  Component:
    | React.FC<ExistingMarketingEvaluationReportV1DetailsProps>
    | React.FC<ExistingMarketingEvaluationReportV2DetailsProps>
    | React.FC<ExistingMarketingEvaluationReportV3DetailsProps>
    | null
    | undefined;
  translationKeyPrefix: string;
}

const getMarketerProjectMarketingEvaluationAuditReportDetails = (
  projectSpecialSubType: ProjectSpecialSubType | null | undefined,
): MarketerProjectMarketingEvaluationAuditReportDetails => {
  // @ts-ignore
  const mapper: Record<ProjectSpecialSubType, MarketerProjectMarketingEvaluationAuditReportDetails> = {
    [ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK]: {
      Component: ExistingMarketingEvaluationReportV1Details,
      translationKeyPrefix: 'ExistingMarketingEvaluationReportV1Details',
    },
    [ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2]: {
      Component: ExistingMarketingEvaluationReportV2Details,
      translationKeyPrefix: 'ExistingMarketingEvaluationReportV2Details',
    },
    [ProjectSpecialSubType.OUTBRAIN_HEALTH_CHECK]: {
      Component: ExistingMarketingEvaluationReportV3Details,
      translationKeyPrefix: 'ExistingMarketingEvaluationReportV3Details',
    },
  };

  if (!projectSpecialSubType) {
    return {
      Component: null,
      translationKeyPrefix: '',
    };
  }

  if (projectSpecialSubType && !mapper[projectSpecialSubType]) {
    throw new Error(`Missing required definition for property "projectSpecialSubType" ${projectSpecialSubType}`);
  }

  return mapper[projectSpecialSubType];
};

const useMarketerProjectMarketingEvaluationAuditReportData = (
  props: MarketerProjectMarketingEvaluationAuditReportProps,
): MarketerProjectMarketingEvaluationAuditReportData => {
  const { projectId, participant, isMailchimpProject = true } = props;

  const { mutate: approveEvaluationByCompany } = useMutation(ApproveExistingMarketingEvaluationReportByCompanyMutation);

  const { mutate: approveEvaluation, loading: loadingApproveEvaluation } = useMutation(
    ApproveExistingMarketingEvaluationReportByGrowthStrategistMutation,
  );

  const { mutate: setCommentsOnExistingMarketingEvaluationByGrowthStrategist, loading: saving } = useMutation(
    SetCommentsOnExistingMarketingEvaluationByGrowthStrategistMutation,
  );

  const {
    project: projectWithEvaluation,
    refetch: refetchEvaluation,
    loading: loadingEvaluation,
  } = useProjectExistingMarketingEvaluation(projectId, {
    skip: !isMailchimpProject,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const projectSpecialSubType = projectWithEvaluation?.specialSubType;
  const { kickOffMeetingType, reviewMeetingType } = useHealthCheckMeetingType(projectSpecialSubType);

  const { Component, translationKeyPrefix = 'ExistingMarketingEvaluationReportV1Details' } = useMemo(
    () => getMarketerProjectMarketingEvaluationAuditReportDetails(projectSpecialSubType),
    [projectSpecialSubType],
  );

  const onApproveClick = useCallback(async () => {
    await approveEvaluation({ variables: { projectId } });
    await refetchEvaluation();
  }, [approveEvaluation, projectId, refetchEvaluation]);

  const onSetCommentsByGSClick = useCallback(async () => {
    await setCommentsOnExistingMarketingEvaluationByGrowthStrategist({ variables: { projectId } });
    await refetchEvaluation();
  }, [projectId, refetchEvaluation, setCommentsOnExistingMarketingEvaluationByGrowthStrategist]);

  const onApproveExistingMarketingEvaluationReportByCompanyClickHandler = useCallback(async () => {
    const result = window.confirm(
      'Are you sure you want to approve marketing evaluation on behalf of the client?\n' +
        'This should be done by the client, click yes only if you wish to bypass ',
    );

    if (!result) {
      return;
    }

    await approveEvaluationByCompany({ variables: { projectId } });
    await refetchEvaluation();
  }, [approveEvaluationByCompany, projectId, refetchEvaluation]);

  const { meetings: evaluationKickOffMeetings } = useProjectScheduledMeetings(projectId, kickOffMeetingType);
  const { meetings: evaluationReviewMeetings } = useProjectScheduledMeetings(projectId, reviewMeetingType);

  const evaluationData = useMemo(() => {
    if (!isMailchimpProject) {
      return null;
    }

    if (projectWithEvaluation?.existingMarketingEvaluation) {
      const { state, marketerDueDate, marketerReport } = projectWithEvaluation.existingMarketingEvaluation;
      const reportDetails = getReportDetails(projectSpecialSubType, marketerReport);

      const sharedFiles = participant?.sharedFiles || ([] as SharedFile[]);

      const evaluationFiles =
        sharedFiles.filter(
          ({ category }) => category === SharedFileCategory.EXISTING_MARKETING_EVALUATION_REPORT_FILE,
        ) || [];

      const [healthCheckFile] = evaluationFiles;
      const isWaitingForGSApproval = state === ProjectExistingMarketingEvaluationState.EVALUATION_REVIEWED_BY_MARKETER;
      const isWaitingForCompanyApproval = state === ProjectExistingMarketingEvaluationState.EVALUATION_APPROVED_BY_GS;
      const isApprovedByGS = [
        ProjectExistingMarketingEvaluationState.EVALUATION_APPROVED_BY_GS,
        ProjectExistingMarketingEvaluationState.EVALUATION_DONE,
      ].includes(state);
      const isApprovedByCompany = state === ProjectExistingMarketingEvaluationState.EVALUATION_DONE;

      const evaluationReviewMeeting = evaluationReviewMeetings?.[0];
      const evaluationKickOffMeeting = evaluationKickOffMeetings?.[0];

      return {
        state,
        marketerDueDate,
        reportDetails,
        evaluationFiles,
        healthCheckFile,
        isWaitingForGSApproval,
        isWaitingForCompanyApproval,
        isApprovedByGS,
        isApprovedByCompany,
        evaluationReviewMeeting,
        evaluationKickOffMeeting,
        onApproveExistingMarketingEvaluationReportByCompanyClickHandler,
      };
    }

    return null;
  }, [
    evaluationKickOffMeetings,
    evaluationReviewMeetings,
    isMailchimpProject,
    onApproveExistingMarketingEvaluationReportByCompanyClickHandler,
    participant?.sharedFiles,
    projectSpecialSubType,
    projectWithEvaluation?.existingMarketingEvaluation,
  ]);

  return {
    Component,
    translationKeyPrefix,
    loadingEvaluation,
    loadingApproveEvaluation,
    saving,
    evaluationData,
    onApproveClick,
    onSetCommentsByGSClick,
    projectSpecialSubType,
    onApproveExistingMarketingEvaluationReportByCompanyClickHandler,
  };
};

export default useMarketerProjectMarketingEvaluationAuditReportData;
