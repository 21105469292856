import React, { FC } from 'react';

import { TextDisplay } from '../index';
import { LabelValueProps } from '../../../app/types';

const LabelValue: FC<LabelValueProps> = (props) => {
  const { label, value } = props;

  return (
    <div>
      <TextDisplay inline bold>
        {label}:
      </TextDisplay>{' '}
      <TextDisplay inline>{(value ?? '').toString()}</TextDisplay>
    </div>
  );
};

export default LabelValue;
