import { colors } from '../../../../../../../fe_common/client/app/theme';

export const styles = () => ({
  root: {
    paddingTop: 0,
    marginTop: 0,
  },
  propertyLabel: {
    flexBasis: 'auto',
  },
  propertyValue: {
    fontWeight: 400,
  },
  green: {
    color: colors.green_darkest,
  },
  red: {
    color: colors.red,
  },
});
