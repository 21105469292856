import { Project, ProjectLifeCycleStatus } from '@mayple/types';

interface ProjectLifeCycleStatusState {
  isLive?: boolean;
  isOnboarding?: boolean;
  isDiscovery?: boolean;
  isFinished?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function getProjectLifeCycleStatus(project: Project | null | undefined): ProjectLifeCycleStatusState {
  if (!project) {
    return {};
  }

  return {
    isLive: project.projectLifeCycleStatus === ProjectLifeCycleStatus.LIVE,
    isOnboarding: project.projectLifeCycleStatus === ProjectLifeCycleStatus.ONBOARDING,
    isDiscovery: project.projectLifeCycleStatus === ProjectLifeCycleStatus.DISCOVERY,
    isFinished: project.projectLifeCycleStatus === ProjectLifeCycleStatus.FINISHED,
  };
}
