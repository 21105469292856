import React, { FC } from 'react';
import { InternalTeam } from '@mayple/types';

import Section, { SECTION_LAYOUT } from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import InternalTeamProjects from '../components/InternalTeamProjects';

interface InternalTeamProjectsTabProps {
  internalTeam: InternalTeam;
  onChange: () => void;
}

const InternalTeamProjectsTab: FC<InternalTeamProjectsTabProps> = (props) => {
  const { internalTeam, onChange } = props;

  return (
    <Section layout={SECTION_LAYOUT.ONE_COLUMN} noTopMargin noBorder>
      <SectionContent>
        <InternalTeamProjects internalTeam={internalTeam} onChange={onChange} />
      </SectionContent>
    </Section>
  );
};

export default InternalTeamProjectsTab;
