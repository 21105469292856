import { ParsedFormInBackendFormat, QuestionInBackendFormat, ProjectStopReasonsFormValues } from './types';
import { ProjectStopReasonsQuestionIds } from './consts';

// eslint-disable-next-line import/prefer-default-export
export const parseFormValuesToBackendFormat = (
  formValues: ProjectStopReasonsFormValues,
  projectId: number,
  isStopProjectImmediately: boolean
): ParsedFormInBackendFormat => {
  const formFormatted: ParsedFormInBackendFormat = {
    projectId,
    projectFinishReasonDescription: formValues.description,
    // automaticRenewal: isStopProjectImmediately,
    projectFinishAnswers: [],
  };

  // eslint-disable-next-line consistent-return
  Object.entries(formValues).forEach(([question, answer]) => {
    if (question === 'description') {
      return false;
    }

    const questionFormatted: QuestionInBackendFormat = {
      questionId: ProjectStopReasonsQuestionIds[question as keyof typeof ProjectStopReasonsQuestionIds],
      values: [{ value: answer as string }],
    };

    formFormatted.projectFinishAnswers.push(questionFormatted);
  });

  if (!isStopProjectImmediately) {
    formFormatted.automaticRenewal = false;
  }

  return formFormatted;
};
