import React, { FC } from 'react';
import { InvoicingDetails } from '@mayple/types';
import { Divider } from '@material-ui/core';

import Constants from './consts';
import checkIfHasInvoiceDetails from './logic';

import { COUNTRIES_LABELS_LOOKUP } from '../../../../../fe_common/client/app/enums';
import { CountriesLabelsLookup } from '../../../../../fe_common/client/app/types';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';

export interface CompanyInvoiceProps {
  invoicingDetails: InvoicingDetails | undefined;
}

const CompanyInvoice: FC<CompanyInvoiceProps> = (props) => {
  const classes = useStyles(props);
  const { invoicingDetails } = props;

  const hasInvoiceDetails = checkIfHasInvoiceDetails(invoicingDetails);
  if (!hasInvoiceDetails) {
    return <CalloutMessage message="Missing invoice details information" />;
  }

  const {
    companyName,
    email,
    firstName,
    lastName,
    phone,
    address1,
    address2,
    country,
    state,
    city,
    zip,
    personalIdentificationNumber,
  } = invoicingDetails || ({} as InvoicingDetails);

  return (
    <div className={classes.root}>
      {(firstName || lastName) && (
        <TextDisplay>
          {firstName} {lastName}
        </TextDisplay>
      )}
      <TextDisplay bold>{companyName}</TextDisplay>
      <div style={{ marginTop: 12 }}>
        <TextDisplay gutterBottom>{address1}</TextDisplay>
        {address2 && <TextDisplay gutterBottom>{address2}</TextDisplay>}
        <TextDisplay gutterBottom>
          {city}, {state !== 'NONE' && state} {zip}
        </TextDisplay>
        {country && (
          <TextDisplay gutterBottom>{(COUNTRIES_LABELS_LOOKUP as CountriesLabelsLookup)[country]}</TextDisplay>
        )}
        <Divider className={classes.divider} />
        <TextDisplay gutterBottom>
          {Constants.PHONE}: {phone}
        </TextDisplay>
        <TextDisplay gutterBottom>
          {Constants.EMAIL}: {email}
        </TextDisplay>
        <TextDisplay gutterBottom>
          {Constants.PERSONAL_ID}: {personalIdentificationNumber}
        </TextDisplay>
      </div>
    </div>
  );
};

export default CompanyInvoice;
