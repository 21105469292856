import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import HotIcon from '@material-ui/icons/Whatshot';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import HotEnums from './HotEnums';
import { defaultCategoriesFilterOption } from '../../../../logic/filters';

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    borderRadius: 0,
  },
}));
const hotIconStyle = { color: '#FF6A00' };

export const AUTO_COMPLETE_ACTIONS = {
  VALUE_ADDED: 'VALUE_ADDED',
  VALUES_CLEARED: 'VALUES_CLEARED',
  VALUE_DELETED: 'VALUE_DELETED',
};

// TODO: add render options with icons https://material-ui.com/components/autocomplete/#customizations
const renderOptionWithIcon = (option) => {
  const { label, icon, svgIcon } = option;

  return (
    <React.Fragment>
      <Avatar src={icon || svgIcon} alt={label} style={{ width: '1.2rem', height: '1.2rem' }} />
      <span>{label}</span>
    </React.Fragment>
  );
};

const renderOptionsMapper = {
  default: undefined,
  withIcon: renderOptionWithIcon,
};

function AutocompleteField(props) {
  const classes = useStyles(props);

  const {
    label,
    enums,
    hotEnums,
    hotEnumsIcon,
    placeholder,
    helperText,
    isMulti,
    addActionMeta,
    autoFocus,
    disableCloseOnSelect,
    getOptionSelected,
    getOptionLabel,
    input,
    meta,
    disabled,
    optionViewType,
  } = props;

  const [grouped] = React.useState(() => !!(enums && enums.length > 0 && enums[0].category));
  const enumsFinal = useMemo(() => enums.filter(({ deprecated }) => !deprecated), [enums]);

  const { value, onChange } = input;

  // Option to add
  const calcActionMeta = React.useCallback((oldValues, newValues) => {
    const actionMeta = {};

    if (newValues.length > oldValues.length) {
      const newValueAdded = differenceBy(newValues, oldValues, 'value');

      actionMeta.action = AUTO_COMPLETE_ACTIONS.VALUE_ADDED;
      [actionMeta.data] = newValueAdded;
    } else if (newValues.length === 0) {
      actionMeta.action = AUTO_COMPLETE_ACTIONS.VALUES_CLEARED;
    } else if (newValues.length < oldValues.length) {
      const oldValueDeleted = differenceBy(oldValues, newValues, 'value');

      actionMeta.action = AUTO_COMPLETE_ACTIONS.VALUE_DELETED;
      [actionMeta.data] = oldValueDeleted;
    }

    return actionMeta;
  }, []);

  const onInputChange = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (e, newValue) => {
      if (isMulti) {
        let actionMeta = {};

        if (addActionMeta) {
          actionMeta = calcActionMeta(value, newValue);
        }

        onChange([...newValue], actionMeta);
      }

      onChange(newValue);
    },
    [value, onChange, isMulti, addActionMeta, calcActionMeta],
  );

  const onHotEnumClick = React.useCallback(
    (newValue) => {
      let actionMeta = {};
      const newValues = [...value, newValue];

      if (addActionMeta) {
        actionMeta = calcActionMeta(value, newValues);
      }

      onChange([...value, newValue], actionMeta);
    },
    [value, onChange, calcActionMeta, addActionMeta],
  );

  const errorMsg = meta ? meta.error : null;
  const touched = meta ? meta.touched : null;
  // isMulti mode requires we send minimum of empty array. null will crash
  const safeValue = value || (isMulti ? [] : null);

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{
          paper: classes.paper,
        }}
        disabled={disabled}
        groupBy={grouped ? (option) => option.categoryLabel : undefined}
        onChange={onInputChange}
        value={safeValue}
        filterOptions={grouped ? defaultCategoriesFilterOption : undefined}
        multiple={isMulti}
        options={enumsFinal}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        disableCloseOnSelect={disableCloseOnSelect}
        renderOption={renderOptionsMapper[optionViewType]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={isMulti && value && value.length > 0 ? '' : placeholder}
            error={touched && !!errorMsg}
            helperText={errorMsg && touched ? errorMsg : helperText}
            autoFocus={autoFocus}
          />
        )}
        getOptionSelected={getOptionSelected}
      />
      {isMulti && (
        <HotEnums
          optionsToFilter={input.value}
          options={hotEnums}
          defaultIcon={hotEnumsIcon}
          onClick={onHotEnumClick}
        />
      )}
    </div>
  );
}

AutocompleteField.propTypes = {
  enums: PropTypes.arrayOf(PropTypes.object).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  // maxSelections:        PropTypes.number,
  isMulti: PropTypes.bool,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
  addActionMeta: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  hotEnumsIcon: PropTypes.node,
  hotEnums: PropTypes.arrayOf(PropTypes.object),
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  optionViewType: PropTypes.oneOf(['default', 'withIcon']),
  disabled: PropTypes.bool,
};

AutocompleteField.defaultProps = {
  label: '',
  placeholder: 'Please select',
  helperText: '',
  // maxSelections:        0,
  initialValue: null,
  isMulti: false,
  getOptionSelected: (o, v) =>
    // https://material-ui.com/api/autocomplete/#autocomplete-api
    // Use the value to determine if an option is selected
    // Our default enums structure is { label: string, value: string }
    o.value === v.value,
  getOptionLabel: (o) => o.label || '',
  addActionMeta: false,
  autoFocus: false,
  disableCloseOnSelect: false,
  hotEnums: null,
  meta: null,
  optionViewType: 'default',
  hotEnumsIcon: <HotIcon style={hotIconStyle} />,
  disabled: false,
};

export default AutocompleteField;
