import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0.5rem 0.5rem',
      width: '100%',
      alignItems: 'center',
    },
    titles: {
      flexGrow: 1,
      paddingInlineStart: '1rem',
    },
    closeButton: {
      flexGrow: 0,
      flexShrink: 1,
      alignSelf: 'flex-end',
    },
  })
);

export default useStyles;
