import React, { FC, useRef } from 'react';
import { Project } from '@mayple/types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import ReactToPrint from 'react-to-print';

import BriefReport from './BriefReport';
import { colors } from '../../../app/theme';
import { TextDisplay } from '../../display';

import useStyles from './style';

type BriefReportDialogProps = {
  project: Project | undefined;
  onClose: () => void;
  open: boolean;
  classes?: Record<string, string>;
};

/**
 *
 * A shared component that view that answers for project.deepBrief.
 * Can be used by both company and marketer sides.
 *
 */
const BriefReportDialog: FC<BriefReportDialogProps> = (props) => {
  const { open, project = {} as Project, onClose } = props;
  const classes = useStyles(props);

  const componentRef = useRef<HTMLDivElement | null>(null);

  const handleExportToPdf = () => {
    /* do nothing */
  };

  // console.log('BriefReportDialog props', props);
  // console.log('BriefReportDialog project', project);

  return (
    <Dialog open={open} fullScreen classes={{ paper: classes.root }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <AppBar
          position="fixed"
          color="default"
          style={{
            background: colors.white,
          }}
        >
          <Toolbar>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <TextDisplay variant="h6" bold>
              Brief summary
            </TextDisplay>
            <ReactToPrint
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <IconButton style={{ position: 'fixed', right: 12 }}>
                  <CloudDownload />
                </IconButton>
              )}
              content={() => componentRef.current}
              onBeforePrint={handleExportToPdf}
            />
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <BriefReport project={project} classes={classes} />
        {/* @ts-ignore */}
        <BriefReport ref={componentRef} project={project} isPrintMode classes={classes} />
      </DialogContent>
    </Dialog>
  );
};

export default BriefReportDialog;
