enum EntityType {
  Company = 'Company',
  Marketer = 'Marketer',
  InternalTeam = 'InternalTeam',
  Project = 'Project',
  ProjectLead = 'ProjectLead',
  User = 'User',
}

export default EntityType;
