import React, { FC } from 'react';
import Link from '@material-ui/core/Link';

import { LinkToProjectProps } from './types';
import { getProjectPagePath } from '../../../../../pages/consts';

const LinkToProject: FC<LinkToProjectProps> = (props) => {
  const { projectId, openInNewTab = false, children } = props;
  const target = openInNewTab ? '_blank' : '_self';
  return (
    <Link href={getProjectPagePath(projectId)} target={target}>
      {children}
    </Link>
  );
};

export default LinkToProject;
