import React, { FC } from 'react';
import { Integration } from '@mayple/types';

import { getIntegrationDetailsItem } from './logic';

import { TextDisplay } from '../../../../fe_common/client/components/display';

interface IntegrationsDetailsProps {
  integrations: Integration[];
}

const IntegrationsDetails: FC<IntegrationsDetailsProps> = (props) => {
  const { integrations } = props;

  if (!integrations || integrations.length === 0) {
    return <TextDisplay>No integrations found.</TextDisplay>;
  }

  return (
    <div>
      {integrations.map((integration) => {
        const integrationItemDetails = getIntegrationDetailsItem(integration);
        if (!integrationItemDetails) {
          return null;
        }

        const { component, avatar } = integrationItemDetails;

        return React.createElement(component, {
          key: integration.uuid,
          integration,
          avatar,
        });
      })}
    </div>
  );
};

export default IntegrationsDetails;
