import React, { FC } from 'react';

import useCronofyAvailabilityRulesConfiguration from './hooks/useCronofyAvailabilityRulesConfiguration';
import { Constants } from './constants';

import { CronofyAvailabilityRulesOptions } from '../types/cronofyTypes';

export interface CronofyAvailabilityRulesProps {
  options: CronofyAvailabilityRulesOptions;
}

const CronofyAvailabilityRules: FC<CronofyAvailabilityRulesProps> = ({ options }) => {
  const targetId = options.target_id || Constants.WIDGET_TARGET_ID;

  useCronofyAvailabilityRulesConfiguration(options);

  return targetId ? <div id={targetId} /> : null;
};

export default CronofyAvailabilityRules;
