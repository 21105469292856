import React, { FC, useCallback, useMemo } from 'react';
import { ParticipantOnboardingStatus } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { MarketerParticipantQuery } from 'growl-graphql/dist/queries/MarketerParticipantQuery';
import { UpdateParticipantOnboardingStatusMutation } from 'growl-graphql/dist/mutations/UpdateParticipantOnboardingStatusMutation';

import SwitchField from '../../../../../../fe_common/client/components/atoms/SwitchField';
import { isOnboardingStepDone } from '../../../../../../fe_common/client/logic/participantOnboarding';
import { useMutation } from '../../../../../../fe_common/client/hooks';

type UpdateProjectKickOffMeetingFinishedSwitchProps = {
  projectId: number;
  marketerId: number;
};

const UpdateProjectKickOffMeetingFinishedSwitch: FC<UpdateProjectKickOffMeetingFinishedSwitchProps> = (props) => {
  const { projectId, marketerId } = props;

  const { loading, refetch, data } = useQuery(MarketerParticipantQuery.query, {
    variables: {
      projectId,
      marketerId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { loading: saving, mutate } = useMutation(UpdateParticipantOnboardingStatusMutation);

  const participant = data?.participant;
  const participantId = data?.participant?.id;
  const { participantOnboardingStatus = ParticipantOnboardingStatus.STARTED_ONBOARDING } = participant || {};

  const onKickOffChangeHandler = useCallback(async () => {
    const result = window.confirm('Are you sure you want to move project to expert matching stage?');

    if (!result) {
      return;
    }

    const variables = {
      projectId,
      participantId,
      participantOnboardingStatusUpdate: {
        participantOnboardingStatus: ParticipantOnboardingStatus.FINISHED_KICK_OFF_MEETING,
      },
    };

    await mutate({ variables });

    await refetch();
  }, [mutate, participantId, projectId, refetch]);

  const isStepDone = useMemo(
    () => isOnboardingStepDone(ParticipantOnboardingStatus.FINISHED_KICK_OFF_MEETING, participantOnboardingStatus),
    [participantOnboardingStatus],
  );

  // don't render anything if there is no participant
  if (!participantId) {
    return null;
  }

  return (
    <SwitchField
      checked={isStepDone}
      disabled={isStepDone || loading || saving}
      onChange={onKickOffChangeHandler}
      label="Move project to expert matching stage"
    />
  );
};

export default UpdateProjectKickOffMeetingFinishedSwitch;
