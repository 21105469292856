import React, { FC } from 'react';
import Link from '@material-ui/core/Link';

import { LinkToUserProps } from './types';

const LinkToUser: FC<LinkToUserProps> = (props) => {
  const { userId, openInNewTab = false, children } = props;
  const target = openInNewTab ? '_blank' : '_self';
  return (
    <Link href={`/users/${userId}`} target={target}>
      {children}
    </Link>
  );
};

export default LinkToUser;
