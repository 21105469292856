import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    divider: {
      marginTop: 32,
      marginBottom: 32,
    },
    selectProjectContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '2rem',
      alignItems: 'center',
      '& :first-child': {
        flexGrow: 1,
      },
    },
    connectProjectButton: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
