import React from 'react';
import { Dispatch } from 'redux';
import { ProjectSpecialType } from '@mayple/types';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { CreateInternalTeamMutation } from 'growl-graphql/dist/mutations/CreateInternalTeamMutation';
import { UpdateInternalTeamMutation } from 'growl-graphql/dist/mutations/UpdateInternalTeamMutation';

import {
  InternalTeamEditorDialogProps,
  InternalTeamEditorMode,
  InternalTeamEditorModes,
  InternalTeamFormData,
} from './types';
import WizardSteps from './steps';
import getMutationVariables from './logic';
import { getRandomLogoImage } from '../../logic';

import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import RemoteSubmitButton from '../../../../../fe_common/client/components/atoms/RemoteSubmitButton';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { PROJECT_SPECIAL_TYPE } from '../../../../../fe_common/client/app/enums';
import { fromArrayToLabelValueArray } from '../../../../../fe_common/client/logic/formHelpers';

import useStyles from './style';

const InternalTeamEditorDialog: React.FC<InternalTeamEditorDialogProps> = (props) => {
  const classes = useStyles();
  const { mutate: createInternalTeam } = useMutation(CreateInternalTeamMutation);
  const { mutate: updateInternalTeam } = useMutation(UpdateInternalTeamMutation);

  const {
    isOpen = false,
    internalTeam,
    onClose,
    onSuccess = () => {
      /* do nothing */
    },
  } = props;

  const mode = internalTeam != null ? InternalTeamEditorModes.Edit : InternalTeamEditorModes.Create;

  const formName = 'internal-team-editor';

  const initialValues: InternalTeamFormData = {
    internalTeamId: internalTeam?.id || null,
    name: internalTeam?.name || '',
    description: internalTeam?.description || '',
    internalTeamType: internalTeam?.internalTeamType,
    displayImageUrl: internalTeam?.displayImageUrl || getRandomLogoImage(),
    meetYouExpertCalendlyLink: internalTeam?.meetYouExpertCalendlyLink || '',
    maypleSlackMemberId: internalTeam?.maypleSlackMemberId || '',
    maypleSlackAllowTag: internalTeam?.maypleSlackAllowTag || true,
    workingGroupOnProjectTypes: fromArrayToLabelValueArray(
      (internalTeam?.projectAutomaticAssignmentSettings?.workingGroupOnProjectTypes as ProjectSpecialType[]) ||
        ([] as ProjectSpecialType[]),
      PROJECT_SPECIAL_TYPE,
    ),
  };

  const dialogTitle = `${mode} account`;
  const submitButtonLabel = mode === InternalTeamEditorModes.Edit ? 'Update' : 'Save';

  const getMutationFunction = (internalTeamEditorMode: InternalTeamEditorMode) =>
    internalTeamEditorMode === InternalTeamEditorModes.Edit ? updateInternalTeam : createInternalTeam;

  const handleOnSubmitForm = async (
    formValues: Record<string, any>,
    _dispatch: Dispatch,
    formProps: DecoratedFormProps,
  ): Promise<void> => {
    const variables = getMutationVariables(mode, formValues);
    const mutate = getMutationFunction(mode);

    await mutate({ variables });

    if (formProps && typeof formProps.destroy === 'function') {
      formProps.destroy();
    }

    onSuccess();
    onClose();
  };

  return (
    <Dialog fullScreen className={classes.root} open={isOpen} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <TextDisplay variant="h5" bold>
          {dialogTitle}
        </TextDisplay>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <WizardForm
          form={formName}
          // @ts-ignore
          steps={WizardSteps}
          onSubmit={handleOnSubmitForm}
          watchAllValues
          initialValues={initialValues}
          destroyOnUnmount
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <RemoteSubmitButton form={formName} color="primary" variant="contained" label={submitButtonLabel} />
        <Button color="primary" variant="outlined" label="cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default InternalTeamEditorDialog;
