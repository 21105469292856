import React, { FC, useCallback } from 'react';
import { Marketer, Project, SharedGoogleAnalyticsView, AccountType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ProjectByIdSharedAssetsQuery } from 'growl-graphql/dist/queries/project/ProjectByIdSharedAssetsQuery';
import { MarketerByIdQuery } from 'growl-graphql/dist/queries/MarketerByIdQuery';
import { ShareGoogleAnalyticsViewsMutation } from 'growl-graphql/dist/mutations/admin/ShareGoogleAnalyticsViewsMutation';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { RefreshSpinner } from '../../../../../../../fe_common/client/components/atoms';
import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import Section from '../../../../../../../fe_common/client/components/atoms/Section';
import useMainGrowthStrategist from '../../../../../../../fe_common/client/hooks/internalTeam/useMainGrowthStrategist';
import { Button } from '../../../../../../../fe_common/client/components/inputs';
import { useMutation } from '../../../../../../../fe_common/client/hooks';

interface ProjectSharedGoogleAnalyticsViewsProps {
  project: Project;
}

const ProjectSharedGoogleAnalyticsViews: FC<ProjectSharedGoogleAnalyticsViewsProps> = (props) => {
  const { project } = props;
  const { id: projectId } = project;
  const marketerId = project?.participants?.[0]?.marketerId;
  const { mutate: shareViews, loading: loadingShareViews, error } = useMutation(ShareGoogleAnalyticsViewsMutation);
  const {
    data: projectAssetsData,
    loading: loadingProject,
    refetch: refetchProject,
  } = useQuery(ProjectByIdSharedAssetsQuery.query, { variables: { projectId } });
  const { data: marketerData, loading: loadingMarketer } = useQuery(MarketerByIdQuery.query, {
    variables: { marketerId },
    skip: !marketerId,
  });

  const marketer = marketerData?.marketer as Marketer;

  const { name: gaName, email: gaEmail } = marketer?.marketerProfile?.googleAnalyticsUserInfo || {};

  const mainGrowthStrategist = useMainGrowthStrategist(project);

  const onShare = useCallback(
    async (shareWithAssignedAccount: AccountType) => {
      if (!projectId) {
        alert('Something went wrong reading project details. Contact support.');
        return;
      }

      const variables = { projectId, shareWithAssignedAccount };

      await shareViews({ variables });
      await refetchProject();
    },
    [projectId, refetchProject, shareViews],
  );

  if (loadingProject) {
    return <RefreshSpinner linear />;
  }

  const views: SharedGoogleAnalyticsView[] = projectAssetsData?.project?.sharedGoogleAnalyticsViews || [];
  const shareToInternalAccount = () => {
    onShare(AccountType.INTERNAL_TEAM);
  };
  const shareToExpert = () => {
    onShare(AccountType.MARKETER);
  };

  return (
    <div>
      <Section title="Google Analytics Views">
        {views.length === 0 && (
          <TextDisplay variant="subtitle1" bold>
            No views were selected to be shared by the company yet.
          </TextDisplay>
        )}
        {views.length > 0 && (
          <Card>
            <CardContent>
              <TextDisplay variant="h6">
                Views selected by the company to be shared with the growth strategist:
              </TextDisplay>
              <List>
                {views.map((view) => {
                  const { id, name, accountName, propertyName, sharingResult } = view;
                  return (
                    <ListItem key={`${id}-shared-view-ProjectSharedGoogleAnalyticsViews`}>
                      <ListItemText
                        primary={`${name} (Account: ${accountName} - Property: ${propertyName})`}
                        secondary={
                          <ul>
                            {(sharingResult || []).map(({ success, result, userId, userEmail }) => (
                              <li key={`${userEmail}-${userId}`}>
                                Sharing with email {userEmail} has {success ? 'succeeded!' : 'failed!'} <br />
                                Result: {result}
                              </li>
                            ))}
                          </ul>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
            <CardActions>
              {!mainGrowthStrategist && (
                <TextDisplay>
                  Please assign a Growth Strategist to this project in order to share the views access.
                </TextDisplay>
              )}
              {mainGrowthStrategist && !loadingShareViews && (
                <>
                  <Button label="Share views to GS" variant="outlined" onClick={shareToInternalAccount} />
                  <TextDisplay variant="caption" color="textSecondary">
                    Will share to GS <strong>{mainGrowthStrategist.name}</strong> (ID: {mainGrowthStrategist.id})
                  </TextDisplay>
                  <br />
                </>
              )}
              {!!gaEmail && !loadingShareViews && !loadingMarketer && (
                <>
                  <Button label="Share views to Expert" variant="outlined" onClick={shareToExpert} />
                  <TextDisplay variant="caption" color="textSecondary">
                    Will share to assigned Expert {marketer.name} to Google Analytics user {gaName} ({gaEmail})
                  </TextDisplay>
                </>
              )}
            </CardActions>
          </Card>
        )}
        {error && <TextDisplay color="error">{JSON.stringify(error)}</TextDisplay>}
      </Section>
    </div>
  );
};

export default ProjectSharedGoogleAnalyticsViews;
