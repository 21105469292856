import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import AccountMovementsTable from './AccountsMovmentsTable';
import FundAccountDebts from './FundAccountDebts';
import { getSortedDebts, mergeWithdrawalsAndDeposits } from './logic';

import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import { safeExtractCurrencyValue } from '../../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const styles = () => ({
  root: {
    width: '100%',
  },
  AccordionSummaryContent: {
    display: 'block',
  },
  accountBalance: {
    fontWeight: 'bold',
  },
  accountDebt: {
    fontWeight: 'bold',
  },
  red: {
    color: 'red',
  },
  green: {
    color: 'green',
  },
});

/**
 * Renders a basic details on fundAccount object
 * @param props
 * @returns {*}
 * @constructor
 */
const FundAccountDetails = (props) => {
  const { fundAccount, title, subTitle, showBalance, showDebt, showAccountMovements, classes } = props;

  const {
    balance = 0,
    totalDebt = 0,
    currency: accountCurrency = 'USD',
    withdrawals = [],
    debts = [],
    deposits = [],
  } = fundAccount || {};
  const hasRefund = React.useMemo(
    () => (withdrawals || []).some(({ withdrawalTarget }) => withdrawalTarget === 'REFUND'),
    [withdrawals],
  );
  // console.log('fundAccount', fundAccount);

  const sortedDebts = getSortedDebts(debts);
  const withdrawalsAndDeposits = mergeWithdrawalsAndDeposits(withdrawals, deposits);

  return (
    <Accordion className={classes.root}>
      <AccordionSummary classes={{ content: classes.AccordionSummaryContent }} expandIcon={<ExpandMoreIcon />}>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item>
            {subTitle !== '' && <TextDisplay variant="subtitle1">{subTitle}</TextDisplay>}
            <TextDisplay variant="h5" bold>
              {title}
            </TextDisplay>
            {!fundAccount && <CalloutMessage message="No fund account details found." />}
            {fundAccount && showBalance && (
              <TextDisplay variant="body1">
                <span>Account balance: </span>
                <span className={classNames(classes.accountBalance, { [classes.green]: balance > 0 })}>
                  {safeExtractCurrencyValue(balance, accountCurrency)}
                </span>
              </TextDisplay>
            )}
            {fundAccount && showDebt && (
              <TextDisplay variant="body1">
                <span>Account debt: </span>
                <span className={classNames(classes.accountDebt, { [classes.red]: totalDebt > 0 })}>
                  {safeExtractCurrencyValue(totalDebt, accountCurrency)}
                </span>
              </TextDisplay>
            )}
          </Grid>
          <Grid item>
            {hasRefund && (
              <div>
                <LocalAtmIcon /> <RotateLeftIcon />
                <TextDisplay color="primary" variant="caption">
                  Received refund
                </TextDisplay>
              </div>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          {fundAccount && showDebt && (
            <Grid item xs={12}>
              <FundAccountDebts debts={sortedDebts} />
            </Grid>
          )}
          {fundAccount && showAccountMovements && (
            <Grid item xs={12}>
              <AccountMovementsTable withdrawalsAndDeposits={withdrawalsAndDeposits} />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

FundAccountDetails.propTypes = {
  fundAccount: PropTypes.object,
  showBalance: PropTypes.bool,
  showDebt: PropTypes.bool,
  showAccountMovements: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

FundAccountDetails.defaultProps = {
  fundAccount: null,
  title: 'Fund account details',
  subTitle: '',
  showBalance: true,
  showDebt: true,
  showAccountMovements: true,
};

export default withStyles(styles)(FundAccountDetails);
