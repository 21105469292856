import React, { useCallback } from 'react';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import isNumber from 'lodash/isNumber';
import {
  CurrencyType,
  IndustryCategory,
  IndustrySubCategory,
  Marketer,
  MarketerCapabilities,
  MarketerProfile,
  MarketerUpdate,
  MatchingSettings,
  ProfileSettings,
  ProjectSpecialType,
  User,
  VettingData,
} from '@mayple/types';
import { UpdateMarketerMutation } from 'growl-graphql/dist/mutations/UpdateMarketerMutation';

import { MarketerUpdateFormData, UpdateMarketerData } from './types';

import {
  AD_SPEND_PAIRS,
  CLIENT_STAGE_EXPERTISE,
  COUNTRIES_PAIRS,
  CURRENT_WORK_STATUS,
  INDUSTRIES_LABELS_LOOKUP,
  LANGUAGES_PAIRS,
  PRODUCT_BUSINESS_MODEL_PAIRS,
  PRODUCT_COST_RANGE,
  PROJECT_SPECIAL_TYPE,
  TARGET_KPI_PAIRS,
} from '../../../../../fe_common/client/app/enums';
import getEnumsInitialValues from '../../../../../fe_common/client/components/molecules/EditableComponent/logic';
import { valuesOnly } from '../../../../../fe_common/client/services/utils';
import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { LabelValueProps } from '../../../../../fe_common/client/app/types';

const getYearsData = (): LabelValueProps<number>[] => {
  const currentYear = new Date().getFullYear();

  const years = [];
  for (let year = currentYear; year >= 1990; year--) {
    years.push({
      label: year.toString(),
      value: year,
    });
  }
  return years;
};

const useUpdateMarketer = (
  marketerId: number,
  setRedirectTo: React.Dispatch<React.SetStateAction<string>>,
): UpdateMarketerData => {
  const { mutate: updateMarketerMutation } = useMutation(UpdateMarketerMutation);

  const getInitialValues = useCallback((marketer: Marketer, viewer: User) => {
    const {
      marketerProfile,
      profileSettings,
      marketerCapabilities,
      matchingSettings,
      vettingData,
      isVetted,
      introVideoUrl,
      graduationPlan,
    } = marketer;
    const { emailAddress } = viewer;

    const { isShareable } = profileSettings || ({} as ProfileSettings);

    const {
      marketerType,
      totalExternalProjectsCompleted,
      currentWorkStatus,
      numberOfEmployees,
      yearFirstStartedDigitalMarketing,
    } = marketerProfile || ({} as MarketerProfile);

    const { vettedBy, vetterComment, levelOfService, interviewScore, marketerHistoricalPerformance } =
      vettingData || ({} as VettingData);

    const {
      countryExpertise,
      industryExpertise: marketerIndustryExpertise,
      languageExpertise,
      marketingSkills,
      monthlyBudgetRangeAmount,
      productBusinessModelExpertise,
      targetKPIExpertise,
      productCostRange,
      clientStageExpertise,
    } = marketerCapabilities || ({} as MarketerCapabilities);

    const {
      availableForProjects,
      minimumProjectFeeAmount,
      enableMatching,
      matchableForProjectSpecialTypes,
      maximumConcurrentProjectsLimit,
    } = matchingSettings || ({} as MatchingSettings);

    const marketerLanguageExpertise = getEnumsInitialValues(languageExpertise, LANGUAGES_PAIRS);
    const marketerCountryExpertise = getEnumsInitialValues(countryExpertise, COUNTRIES_PAIRS);
    const marketerTargetKPIExpertise = getEnumsInitialValues(targetKPIExpertise, TARGET_KPI_PAIRS);
    const marketerMonthlyBudgetRangeAmount = getEnumsInitialValues(monthlyBudgetRangeAmount, AD_SPEND_PAIRS);
    const marketerProductBusinessModelExpertise = getEnumsInitialValues(
      productBusinessModelExpertise,
      PRODUCT_BUSINESS_MODEL_PAIRS,
    );
    const marketerMarketingSkills = marketingSkills.map(({ skillType, skillLevel, yearsOfExperience = 0 }) => ({
      skillType,
      skillLevel,
      yearsOfExperience,
    }));

    const marketerProductCostRange = getEnumsInitialValues(productCostRange, PRODUCT_COST_RANGE);
    const marketerClientStageExpertise = getEnumsInitialValues(clientStageExpertise, CLIENT_STAGE_EXPERTISE);
    const marketerCurrentWorkStatus = getEnumsInitialValues(currentWorkStatus, CURRENT_WORK_STATUS);
    const marketerMatchableForProjectSpecialTypes = getEnumsInitialValues(
      matchableForProjectSpecialTypes || [ProjectSpecialType.NO_SPECIAL_TYPE],
      PROJECT_SPECIAL_TYPE,
    );

    const years = getYearsData();

    const initialIndustry: ({
      industry: IndustryCategory;
      label: string;
      category: IndustryCategory;
      value: IndustrySubCategory;
    } | null)[] = marketerIndustryExpertise
      .map((item) => {
        const { industrySubCategory: value, industryCategory: category } = item;
        // @ts-ignore
        return value ? { value, category, industry: category, label: INDUSTRIES_LABELS_LOOKUP[value] } : null;
      })
      .filter(Boolean);

    const topLevelIndustries = Array.from(
      new Set(
        marketerIndustryExpertise
          .map((item) => {
            const { industrySubCategory, industryCategory } = item;
            if (!industrySubCategory && industryCategory) {
              // @ts-ignore
              return INDUSTRIES_LABELS_LOOKUP[industryCategory];
            }
            return null;
          })
          .filter(Boolean),
      ),
    ).join(', ');

    const initialValues: Partial<MarketerUpdateFormData> = {
      isVetted,
      introVideoUrl: introVideoUrl || '',
      vettedBy: vettedBy || emailAddress,
      vetterComment,
      marketerType,
      marketerCountryExpertise,
      marketerLanguageExpertise,
      graduationPlan,
      marketerIndustryExpertise: initialIndustry,
      marketerIndustryTopCategories: topLevelIndustries,
      marketerTargetKPIExpertise,
      marketerProductBusinessModelExpertise,
      marketerMonthlyBudgetRangeAmount,
      marketerMarketingSkills,
      totalExternalProjectsCompleted: totalExternalProjectsCompleted || 1,
      levelOfService: levelOfService || 1,
      interviewScore: interviewScore || 1,
      marketerHistoricalPerformance: marketerHistoricalPerformance || 1,
      availableForProjects,
      enableMatching,
      marketerMinimumProjectFeeAmount: minimumProjectFeeAmount,
      maximumConcurrentProjectsLimit,
      numberOfEmployees: numberOfEmployees || 1,
      productCostRange: marketerProductCostRange,
      clientStageExpertise: marketerClientStageExpertise,
      currentWorkStatus: marketerCurrentWorkStatus,
      matchableForProjectSpecialTypes: marketerMatchableForProjectSpecialTypes,
      yearFirstStartedDigitalMarketing,
      years,
      isShareable,
    };

    return initialValues;
  }, []);

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleFormSubmit: FormSubmitHandler<MarketerUpdateFormData> = async (formValuesSubmitted, _dispatch, props) => {
    const {
      enableMatching,
      isVetted,
      vettedBy,
      vetterComment,
      levelOfService,
      interviewScore,
      introVideoUrl,
      marketerType,
      graduationPlan,
      marketerHistoricalPerformance,
      marketerMinimumProjectFeeAmount: minimumProjectFeeAmount,
      maximumConcurrentProjectsLimit,
      marketerMonthlyBudgetRangeAmount,
      marketerMarketingSkills,
      marketerCountryExpertise,
      marketerIndustryExpertise,
      marketerLanguageExpertise,
      marketerTargetKPIExpertise,
      marketerProductBusinessModelExpertise,
      totalExternalProjectsCompleted,
      currentWorkStatus,
      numberOfEmployees,
      productCostRange,
      clientStageExpertise,
      yearFirstStartedDigitalMarketing,
      matchableForProjectSpecialTypes,
      isShareable,
    } = formValuesSubmitted;

    const industryInput = (marketerIndustryExpertise || []).map((item) => ({
      industryCategory: item.industry,
      industrySubCategory: item.value,
    }));

    const variables: { marketerId: number; marketerUpdate: MarketerUpdate } = {
      marketerId,
      marketerUpdate: {
        isVetted,
        introVideoUrl: introVideoUrl || '',
        graduationPlan,
        marketerProfile: {
          marketerType,
          totalExternalProjectsCompleted,
          currentWorkStatus: currentWorkStatus?.value || null,
          numberOfEmployees: numberOfEmployees || 1,
          yearFirstStartedDigitalMarketing: yearFirstStartedDigitalMarketing || null,
        },
        profileSettings: {
          isShareable,
        },
        matchingSettings: {
          enableMatching,
          minimumProjectFeeAmount,
          minimumProjectFeeCurrency: CurrencyType.USD,
          matchableForProjectSpecialTypes: valuesOnly(matchableForProjectSpecialTypes),
          maximumConcurrentProjectsLimit: isNumber(maximumConcurrentProjectsLimit)
            ? maximumConcurrentProjectsLimit
            : undefined,
        },
        vettingData: {
          vettedBy,
          vetterComment,
          marketerHistoricalPerformance,
          levelOfService,
          interviewScore,
        },
        marketerCapabilities: {
          marketingSkills: marketerMarketingSkills,
          monthlyBudgetRangeAmount: valuesOnly(marketerMonthlyBudgetRangeAmount),
          languageExpertise: valuesOnly(marketerLanguageExpertise),
          industryExpertise: industryInput,
          countryExpertise: valuesOnly(marketerCountryExpertise),
          productBusinessModelExpertise: valuesOnly(marketerProductBusinessModelExpertise),
          targetKPIExpertise: valuesOnly(marketerTargetKPIExpertise),
          productCostRange: valuesOnly(productCostRange),
          clientStageExpertise: valuesOnly(clientStageExpertise),
        },
      },
    };

    try {
      // Allow throwing of errors out of the mutation
      await updateMarketerMutation({ variables });
      props?.destroy?.();

      setRedirectTo(`/marketers/${marketerId}`);
    } catch (err) {
      clientLogger(err);
    }
  };

  return {
    getInitialValues,
    handleFormSubmit,
  };
};

export default useUpdateMarketer;
