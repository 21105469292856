import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import { config } from '../../../../../../config';

import { TextDisplay } from '../../../../../../../fe_common/client/components/display';
import ExternalLink from '../../../../../../../fe_common/client/components/display/ExternalLink';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    inlineItem: {},
  }),
);

interface HubspotDealLinkProps {
  hubspotDealId: string | null | undefined;
  classes?: Record<string, string>;
}

const getHubspotDealLink = (hubspotAccountId: number | string, hubspotDealId: number | string) =>
  `https://app.hubspot.com/contacts/${hubspotAccountId}/deal/${hubspotDealId}`;

const HubspotDealLink: React.FC<HubspotDealLinkProps> = (props) => {
  const classes = useStyles(props);

  const { hubspotDealId } = props;

  if (!hubspotDealId) {
    return null;
  }

  // const linkToHubspotDeal = 'https://app.hubspot.com/contacts/4292856/deal/9826414662';
  const linkToHubspotDeal = getHubspotDealLink(config.hubspotPortalId, hubspotDealId);

  return (
    <div className={classes.root}>
      <TextDisplay variant="subtitle1" inline className={classes.inlineItem}>
        {'Hubspot Deal: '}
      </TextDisplay>
      <ExternalLink href={linkToHubspotDeal}>
        {linkToHubspotDeal}
        <Tooltip title="Link to Hubspot deal" placement="top">
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </ExternalLink>
    </div>
  );
};

export default HubspotDealLink;
