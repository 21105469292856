import React, { FC } from 'react';
import { Participant, ParticipantOnboardingStatus, Project, ProjectSpecialType } from '@mayple/types';

import useHasAssignedMarketer from './useHasAssignedMarketer';
import ProjectOnboardingStatus from '../../components/ProjectOnboardingStatus';
import MarketerProjectMarketingEvaluationAuditReport from '../../components/MarketerProjectMarketingEvaluationAuditReport';
import ProjectMarketingEvaluationImplementationDetails from '../../components/ProjectMarketingEvaluationImplementationDetails';
import UpdateProjectKickOffMeetingFinishedSwitch from '../../../../components/cpanel/components/molecules/UpdateProjectKickOffMeetingFinishedSwitch';
import { config } from '../../../../config';

import Section from '../../../../../fe_common/client/components/atoms/Section';
import useHasAssignedInternalTeams from '../../../../../fe_common/client/hooks/internalTeam/useHasAssignedInternalTeams';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import { isOnboardingStepDone } from '../../../../../fe_common/client/logic/participantOnboarding';
import MarketingPlanProgress from '../../../../../fe_common/client/components/onboarding/MarketingPlanProgress';
import { MarketingPlanProvider } from '../../../../../fe_common/client/components/onboarding/MarketingPlanProvider';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import useProjectFlowConfiguration from '../../../../../fe_common/client/hooks/project/useProjectFlowConfiguration';

import useStyles from './style';

interface ProjectOnBoardingTabProps {
  project: Project;
}

const ProjectOnBoardingTab: FC<ProjectOnBoardingTabProps> = (props) => {
  const classes = useStyles(props);

  const { project } = props;
  const { id: projectId, participants, flowConfiguration } = project;
  const [participant] = (participants || []) as Participant[];
  const { marketerId = 0, participantOnboardingStatus } = participant;

  const { getProjectFlowConfigurationByKey } = useProjectFlowConfiguration(projectId);
  const skipExistingMarketingEvaluationStage = !!getProjectFlowConfigurationByKey(
    'skipExistingMarketingEvaluationStage',
  )?.value;

  const skipExistingMarketingOptimizationImplementationStage = !!getProjectFlowConfigurationByKey(
    'skipExistingMarketingOptimizationImplementationStage',
  )?.value;

  const skipProjectDataEnrichmentStage = !!project?.flowConfiguration?.skipProjectDataEnrichmentStage?.value || false;
  const skipMarketingPlan = flowConfiguration?.skipMarketingPlan?.value || false;

  const { hasAssignedGS } = useHasAssignedInternalTeams(project);
  const hasAssignedMarketer = useHasAssignedMarketer(project);
  const initialValues = {
    filestackApiKey: config.filestackApiKey,
    projectId,
    marketerId,
  };

  return (
    <div className={classes.root}>
      {project?.specialType === ProjectSpecialType.NO_SPECIAL_TYPE && (
        <ProjectOnboardingStatus status={participantOnboardingStatus} />
      )}

      {!skipProjectDataEnrichmentStage &&
        isOnboardingStepDone(ParticipantOnboardingStatus.STARTED_ONBOARDING, participantOnboardingStatus) && (
          <Section title="Kick off" classes={{ root: classes.sectionRoot }}>
            <SectionContent>
              <UpdateProjectKickOffMeetingFinishedSwitch marketerId={marketerId} projectId={projectId} />
            </SectionContent>
          </Section>
        )}

      {!skipExistingMarketingEvaluationStage && hasAssignedMarketer && (
        <MarketerProjectMarketingEvaluationAuditReport
          projectId={projectId}
          participant={participant}
          hasAssignedGS={hasAssignedGS}
          noBorder={skipProjectDataEnrichmentStage}
          classes={{ root: classes.sectionRoot }}
        />
      )}

      {!skipExistingMarketingOptimizationImplementationStage && (
        <ProjectMarketingEvaluationImplementationDetails
          projectId={project.id}
          participant={participant}
          noBorder={skipExistingMarketingEvaluationStage && skipProjectDataEnrichmentStage}
          noTopMargin={skipExistingMarketingEvaluationStage && skipProjectDataEnrichmentStage}
        />
      )}

      {isOnboardingStepDone(ParticipantOnboardingStatus.FINISHED_KICK_OFF_MEETING, participantOnboardingStatus) &&
        hasAssignedGS &&
        hasAssignedMarketer &&
        !skipMarketingPlan && (
          <Section title="Marketing Plan" classes={{ root: classes.sectionRoot }}>
            <SectionContent>
              {skipMarketingPlan && <CalloutMessage message="This project does not require marketing plan" />}
              {!skipMarketingPlan && (
                <MarketingPlanProvider initialValues={initialValues}>
                  <MarketingPlanProgress />
                </MarketingPlanProvider>
              )}
            </SectionContent>
          </Section>
        )}
    </div>
  );
};

export default ProjectOnBoardingTab;
