import {
  AD_SPEND_LABELS_LOOKUP,
  CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP,
  COUNTRIES_LABELS_LOOKUP,
  INDUSTRIES_LABELS_LOOKUP,
  LANGUAGES_LABELS_LOOKUP,
  PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  PRODUCT_COST_RANGE_LABELS_LOOKUP,
  TARGET_KPI_WITH_ICONS_MARKETER_LABELS_LOOKUP,
} from '../../../app/enums';

import { getIndustryLabel } from '../../../services/utils';

type CapabilityType = {
  key: string;
  title: string;
  labels: Record<string, string> | any;
  getLabel?: (value: any) => string;
};

const capabilities: CapabilityType[] = [
  {
    key: 'languageExpertise',
    title: 'Languages',
    labels: LANGUAGES_LABELS_LOOKUP,
  },
  {
    key: 'countryExpertise',
    title: 'Target location',
    labels: COUNTRIES_LABELS_LOOKUP,
  },
  {
    key: 'targetKPIExpertise',
    title: 'Campaign types',
    labels: TARGET_KPI_WITH_ICONS_MARKETER_LABELS_LOOKUP,
  },
  {
    key: 'industryExpertise',
    title: 'Industry experience',
    labels: INDUSTRIES_LABELS_LOOKUP,
    getLabel: getIndustryLabel,
  },
  {
    key: 'productBusinessModelExpertise',
    title: 'Client types',
    labels: PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP,
  },
  {
    key: 'monthlyBudgetRangeAmount',
    title: 'Budgets',
    labels: AD_SPEND_LABELS_LOOKUP,
  },
  {
    key: 'productCostRange',
    title: 'Product Costs Range',
    labels: PRODUCT_COST_RANGE_LABELS_LOOKUP,
  },
  {
    key: 'clientStageExpertise',
    title: 'Client Stage Expertise',
    labels: CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP,
  },
];

export default capabilities;
