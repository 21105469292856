import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import AutoCompleteRadioButtons from '../../Registration/AutoCompleteRadioButtons';
import { validateRequired, validateNonEmptyArray } from '../../../../services/validation';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardMultiAutoCompleteRadioButtonsField(props) {
  const { name, floatingLabelText, isRequired, enums, isMulti, placeholder, autoFocus, ...rest } = props;

  // console.log('WizardMultiAutoCompleteRadioButtonsField props', props);
  // field required?
  const validator = isRequired ? [validateRequired] : [];

  if (isMulti && isRequired) {
    validator.push(validateNonEmptyArray());
  }

  return (
    <div>
      <Field
        component={AutoCompleteRadioButtons}
        name={name}
        enums={enums}
        isMulti={isMulti}
        placeholder={placeholder}
        floatingLabelText={floatingLabelText}
        validate={validator}
        autoFocus={autoFocus}
        {...rest}
      />
    </div>
  );
}

WizardMultiAutoCompleteRadioButtonsField.propTypes = {
  name: PropTypes.string.isRequired,
  enums: PropTypes.arrayOf(PropTypes.object).isRequired,
  floatingLabelText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

WizardMultiAutoCompleteRadioButtonsField.defaultProps = {
  floatingLabelText: '',
  placeholder: '',
  isRequired: false,
  isMulti: false,
  autoFocus: false,
};
