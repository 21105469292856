/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import moment from 'moment';
import {
  BillingConfiguration,
  FundAccount,
  MarketingServiceType,
  MediaBudgetDivision,
  PpcBillingModelPlanName,
  ProjectCycle,
  ProjectCyclePhase,
  SetupService,
} from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import LabelValueWithIcon from './LabelValueWithIcon';
import { renderAdditionalServicesInfo } from '../../../../components/cpanel/components/organisms/Project/ProjectCycle/logic';

import { TextDisplay } from '../../../../../fe_common/client/components/display';
import {
  MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS,
  MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS,
} from '../../../../../fe_common/client/app/enums';
import { formatFloatCurrency, formatPercentageString } from '../../../../../fe_common/client/services/utils';

const marketingSkillsLabelsLookup = MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS as Record<MarketingServiceType, string>;
const marketingChannelsLabelsLookupWithIcons = MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS as Record<
  MarketingServiceType | `${MarketingServiceType}-svgIcon`,
  string
>;

export const getBillingTypes = (fundAccount: FundAccount | null | undefined): string => {
  const { creditCardPaymentAgreements, manualPaymentAgreement, paypalOpenPaymentAgreements } =
    fundAccount || ({} as FundAccount);

  const hasManualPaymentAgreement = manualPaymentAgreement?.active || false;

  const hasCreditCardPaymentAgreements =
    !!creditCardPaymentAgreements?.length && creditCardPaymentAgreements?.some(({ valid, active }) => valid || active);

  const hasPaypalOpenPaymentAgreements =
    !!paypalOpenPaymentAgreements?.length && paypalOpenPaymentAgreements?.some(({ valid, active }) => valid || active);

  const billingTypes = [];

  if (hasManualPaymentAgreement) {
    billingTypes.push('Manual');
  }

  if (hasCreditCardPaymentAgreements) {
    billingTypes.push('Credit Card');
  }

  if (hasPaypalOpenPaymentAgreements) {
    billingTypes.push('PayPal');
  }

  return billingTypes.length > 0 ? billingTypes.join(', ') : 'None';
};

export const getBillingPlan = (planName: string): string | React.ReactNode =>
  planName !== '' ? planName : <TextDisplay style={{ backgroundColor: 'lightPink' }} />;

export const getServicesData = (cycle: ProjectCycle): string[] => {
  const { additionalServices, setupService, estimatedMediaBudgetDivision } = cycle;

  const services = [];

  if (additionalServices.length > 0) {
    services.push('Non-PPC');
  }
  if ((estimatedMediaBudgetDivision ?? []).length) {
    services.push('PPC');
  }
  if (setupService && setupService.cost > 0) {
    services.push('Setup');
  }

  return services;
  // return (services.length > 0) ? services.join(', ') : 'None';
};

const getTotalMediaBudget = (values: MediaBudgetDivision[]) =>
  values.reduce((total, { budgetForSkillType }) => total + budgetForSkillType, 0);

export const renderMediaBudgetDivisionInfo = (mediaBudgetDivision: MediaBudgetDivision[]): React.ReactNode => {
  // No Non-PPC Services - no need to render
  if (mediaBudgetDivision.length === 0) {
    return null;
  }

  const totalCost = getTotalMediaBudget(mediaBudgetDivision);

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        <>
          <h6 color="inherit">
            <strong>PPC Services Details</strong>
          </h6>
          <Grid container>
            {mediaBudgetDivision.map(({ skillType, budgetForSkillType }) => {
              // const descriptionContent = description.contents;
              const marketingChannelDescription = marketingChannelsLabelsLookupWithIcons?.[skillType];
              // @ts-ignore
              const marketingChannelImage = marketingChannelsLabelsLookupWithIcons?.[`${skillType}-svgIcon`];

              return (
                <React.Fragment key={`marketing-channel-tooltip-${marketingChannelDescription}-${skillType}`}>
                  <Grid item xs={1}>
                    <img height={16} src={marketingChannelImage} alt={marketingChannelDescription} />
                  </Grid>
                  <Grid item xs={6}>
                    {marketingChannelDescription}
                  </Grid>
                  <Grid item xs={5} alignContent="flex-end">
                    {formatFloatCurrency(budgetForSkillType)}
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <hr />
            </Grid>
            <React.Fragment key="marketing-channel-total">
              <Grid item xs={1} />
              <Grid item xs={4}>
                <strong>Total:</strong>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={3} alignContent="flex-end">
                <strong>{formatFloatCurrency(totalCost)}</strong>
              </Grid>
            </React.Fragment>
          </Grid>
        </>
      }
    >
      <InfoIcon color="primary" />
    </Tooltip>
  );
};

export const renderSetupServiceInfo = (setupService: SetupService): React.ReactNode => {
  const {
    cost,
    description: { contents: setupServiceDescription },
  } = setupService;
  const totalCost = cost;

  // No Non-PPC Services - no need to render
  if (cost === 0) {
    return null;
  }

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        <>
          <h6 color="inherit">
            <strong>One-Time/Setup Simple Service Details</strong>
          </h6>
          <Grid container>
            <Grid item xs={1}>
              {/* <img height={16} src={marketingChannelImage} alt="" /> */}
            </Grid>
            <Grid item xs={6}>
              {setupServiceDescription}
            </Grid>
            <Grid item xs={5} alignContent="flex-end">
              {formatFloatCurrency(cost)}
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>
            <React.Fragment key="marketing-channel-total">
              <Grid item xs={1} />
              <Grid item xs={4}>
                <strong>Total:</strong>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={3} alignContent="flex-end">
                <strong>{formatFloatCurrency(totalCost)}</strong>
              </Grid>
            </React.Fragment>
          </Grid>
        </>
      }
    >
      <InfoIcon color="primary" />
    </Tooltip>
  );
};

export const getWrappedServicesData = (cycle: ProjectCycle): React.ReactNode => {
  const { additionalServices, estimatedMediaBudgetDivision, setupService } = cycle;
  const services = getServicesData(cycle);
  let additionalServicesInfo;
  let mediaBudgetDivisionInfo;
  let setupServiceInfo;

  if (services.includes('Non-PPC')) {
    additionalServicesInfo = renderAdditionalServicesInfo(additionalServices, {});
  }
  if (services.includes('PPC')) {
    mediaBudgetDivisionInfo = renderMediaBudgetDivisionInfo(estimatedMediaBudgetDivision ?? []);
  }
  if (services.includes('Setup')) {
    setupServiceInfo = renderSetupServiceInfo(setupService);
  }

  if (services.length === 0) {
    return 'None';
  }

  return (
    <>
      {additionalServicesInfo !== undefined && <div>Non-PPC {additionalServicesInfo}</div>}
      {mediaBudgetDivisionInfo !== undefined && <div>PPC {mediaBudgetDivisionInfo}</div>}
      {setupServiceInfo !== undefined && <div>Setup {setupServiceInfo}</div>}
    </>
  );
};

export const getListOfServicesFromCycle = (cycle: ProjectCycle): string[] => {
  const { additionalServices, estimatedMediaBudgetDivision } = cycle;
  const servicesList: string[] = [];

  additionalServices.forEach((service) => servicesList.push(marketingSkillsLabelsLookup[service?.serviceType]));
  estimatedMediaBudgetDivision?.forEach((service) =>
    servicesList.push(marketingSkillsLabelsLookup[service?.skillType]),
  );

  return servicesList ?? [];
};

export const getBillingModelTerms = (
  billingConfiguration: BillingConfiguration,
  currency = '$',
  asObject = false,
): null | string | { billingModelName: string; billingModelValue: string; minimumFee: string } => {
  if (!billingConfiguration) {
    return null;
  }

  const { planName = '', fixedPrice, meteredPriceMultiplier, minimumFee } = billingConfiguration;

  const billingModelTerms = [];
  let billingModelName = '';
  let billingModelValue = '';

  if (planName === PpcBillingModelPlanName.fixedPlan && fixedPrice) {
    billingModelName = 'Fixed';
    billingModelValue = formatFloatCurrency(fixedPrice, currency);
  } else if (planName === PpcBillingModelPlanName.meteredPlan20180101 && meteredPriceMultiplier) {
    billingModelName = 'Metered';
    billingModelValue = formatPercentageString(meteredPriceMultiplier);
  }

  if (billingModelName && billingModelValue) {
    billingModelTerms.push(`${billingModelName}: ${billingModelValue}`);
  }

  if (minimumFee && minimumFee > 0) {
    billingModelTerms.push(`Minimum fee: ${formatFloatCurrency(minimumFee, currency)}`);
  }

  if (asObject) {
    return {
      billingModelName,
      billingModelValue,
      minimumFee: formatFloatCurrency(minimumFee ?? 0, currency),
    };
  }
  return billingModelTerms.length > 0 ? billingModelTerms.join(', ') : '';
};

export const getCyclePhaseValue = (phase: ProjectCyclePhase): boolean => phase === ProjectCyclePhase.FINALIZED;

export const getBillingConfigurationDiscounts = (billingConfiguration: BillingConfiguration): React.ReactNode => {
  const {
    PPCDiscount,
    PPCDiscountRecurring,
    nonPPCDiscount,
    nonPPCDiscountRecurring,
    setupServiceDiscount,
    setupServiceDiscountRecurring,
  } = billingConfiguration;

  return (
    <>
      {PPCDiscount !== 0 && (
        <LabelValueWithIcon
          label="PPC Discount"
          value={formatPercentageString(PPCDiscount! / 100)}
          showIcon={PPCDiscountRecurring}
        />
      )}
      {nonPPCDiscount !== 0 && (
        <LabelValueWithIcon
          label="Non-PPC Discount"
          value={formatPercentageString(nonPPCDiscount! / 100)}
          showIcon={nonPPCDiscountRecurring}
        />
      )}
      {setupServiceDiscount !== 0 && (
        <LabelValueWithIcon
          label="One-Time/Setup Simple Service Discount"
          value={formatPercentageString(setupServiceDiscount! / 100)}
          showIcon={setupServiceDiscountRecurring}
        />
      )}
    </>
  );
};

export const getPreviousMonthDate = (): Date => moment().subtract(1, 'months').toDate();
