import React, { FC } from 'react';
import { Field } from 'redux-form';

import WizardCheckboxInput from './WizardCheckboxInput';
import { validateRequired, validateTrue } from '../../../../services/validation';

interface WizardCheckboxFieldProps {
  name: string;
  isRequired?: boolean;
  validationErrorMessage?: string;
}

const WizardCheckboxField: FC<WizardCheckboxFieldProps> = (props) => {
  const { name, isRequired, validationErrorMessage = 'Required', ...rest } = props;

  const validator = isRequired ? [validateRequired, validateTrue(validationErrorMessage)] : [];

  return <Field name={name} component={WizardCheckboxInput} validate={validator} {...rest} />;
};

export default WizardCheckboxField;
