import React, { FC } from 'react';
import { Answer } from '@mayple/types';

import { AnswerProps } from './types';
import { TextDisplay } from '../../../display';

const NumberAnswer: FC<AnswerProps> = (props) => {
  const { answer = {} as Answer } = props;
  const { values = [] } = answer;

  const [value = {}] = values;
  const { value: amount } = value;

  return <TextDisplay variant="subtitle1">{amount}</TextDisplay>;
};

export default NumberAnswer;
