import React from 'react';
import { Steps, Step } from 'react-albus';

import { WizardStepField } from '../../../../../../fe_common/client/components/molecules/WizardStepField';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const WithdrawProjectOpportunityWizardSteps: React.FC = () => (
  <Steps>
    <Step
      id="withdrawOpportunitiesConfirmation"
      render={() => (
        <div>
          <TextDisplay gutterBottom>Are you sure you want to withdraw selected opportunities?</TextDisplay>
          <WizardStepField
            type={WizardStepField.types.TEXT}
            // @ts-ignore
            label="Reason"
            name="resolutionReason"
            minLength={5}
            required
          />
        </div>
      )}
    />
  </Steps>
);

export default WithdrawProjectOpportunityWizardSteps;
