import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../app/theme';

const shadowColor = colors.blackOpacity(0.04);
const shadowColor2 = colors.blackOpacity(0.02);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      boxShadow:
        `0px 0px 1px ${shadowColor}, 0px 4px 8px ${shadowColor}, 0px 16px 24px ${shadowColor}, ` +
        `0px 24px 32px ${shadowColor}, 0px -4px 32px ${shadowColor2}`,
    },
    cardHeader: {
      position: 'absolute',
      outline: 'white',
      height: 140,
      verticalAlign: 'middle',
      width: '100%',
      fontWeight: 'bold',
    },
    cardHeaderTitle: {
      fontWeight: 'bold',
      color: colors.black,
      position: 'relative',
      filter: 'drop-shadow(2px 2px 1px white) drop-shadow(-2px -2px 1px white)',
      zIndex: 1,
      opacity: 1,
    },
    cardHeaderContent: {
      '&:before': {
        display: 'block',
        content: '""',
        width: '100%',
        height: 140,
        position: 'absolute',
        inset: '0 0 0 0',
        backgroundColor: colors.white,
        opacity: 0.6,
        zIndex: 0,
      },
    },
    cardMedia: {
      // opacity: 0.2,
    },
    ribbon: {
      fontWeight: 'bold',
      fontSize: '1rem',
      position: 'absolute',
      clipPath: `inset(0 -100%)`,
    },
    ribbonRight: {
      inset: '0 0 auto auto',
      transformOrigin: '0 0',
      transform: 'translate(29.3%) rotate(45deg)',
    },
    ribbonLeft: {
      inset: '0 auto auto 0',
      transformOrigin: '100% 0',
      transform: 'translate(-29.3%) rotate(-45deg)',
    },
    strip: {
      width: '100%',
      padding: '1rem 0.5rem',
      fontWeight: 'bold',
      fontSize: '1rem',
      marginTop: '-1rem',
      marginBottom: '1rem',
    },
    delay: {
      color: getGradientColor('yellow', 90),
      borderColor: getGradientColor('yellow', 90),
      backgroundColor: getGradientColor('yellow', 40),
    },
    draftCard: {
      border: '2px gray dashed',
    },
    draft: {
      color: colors.black,
      backgroundColor: getGradientColor('gray', 30),
      boxShadow: `-20px 0 0 0 ${getGradientColor('gray', 30)}, 20px 0 0 0 ${getGradientColor('gray', 30)}`,
    },
    chip: {
      border: '1px solid',
      color: colors.black,
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '31px',
      justifyContent: 'flex-start',
      padding: '18px 8px',
      letterSpacing: '0.04em',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    chipPending: {},
    chipCompleted: {
      color: getGradientColor('green', 90),
      borderColor: getGradientColor('green', 90),
      backgroundColor: colors.green_light,
    },
    chipStarted: {
      backgroundColor: colors.yellow_light,
    },
    chipCanceled: {
      color: colors.cherry_darker,
      backgroundColor: colors.red_lighter,
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '0.5rem',
    },
    indicators: {
      marginBottom: '1rem',
    },
  })
);

export default useStyles;
