/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Phone, { isValidPhoneNumber, formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { TextDisplay } from '../../../display';

export class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);

    const phoneNumber = get(props, 'input.value') || ''; // get international value from redux form
    const { country } = parsePhoneNumber(phoneNumber) || {}; // initialize correct country code

    this.state = {
      showInvalidWarn: false,
      phone: formatPhoneNumber(get(props, 'input.value') || '', 'National'), // initialize value
      country: country || '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.renderInvalidWarning = this.renderInvalidWarning.bind(this);
    this.renderRequired = this.renderRequired.bind(this);
  }

  handleOnChange(phone) {
    if (isValidPhoneNumber(phone)) {
      const { input } = this.props;

      input.onChange(phone);

      this.setState({
        showInvalidWarn: false,
        phone,
      });
    } else {
      this.setState({
        showInvalidWarn: true,
        phone,
      });
    }
  }

  handleOnBlur(e) {
    const { input } = this.props;
    input.onBlur(e);

    if (isValidPhoneNumber(e.target.value)) {
      // format the number internationally, with country codes
      input.onChange(e.target.value);
    } else {
      // if the user have invalid phone input. delete that from state. dont send to the server garbage
      input.onChange('');
    }
  }

  // will render a red line warning the user on invalid phone numbers
  // and will show the formatted value for valid ones.
  renderInvalidWarning() {
    if (this.state.showInvalidWarn && this.state.phone !== '') {
      return (
        <TextDisplay colorOverride="#FF0033" variant="caption">
          Invalid phone number
        </TextDisplay>
      );
    } else {
      return null;
    }
  }

  renderRequired(isRequired) {
    if (isRequired) {
      // const { meta = {} } = this.props;
      const { phone } = this.state;
      // const { pristine = true } = meta;
      if (!phone) {
        return <TextDisplay variant="caption">Required *</TextDisplay>;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render() {
    const { placeholder, country: countryFromProps, isRequired } = this.props;

    const { country: countryFromState } = this.state;

    const country = countryFromState || countryFromProps;

    return (
      <div style={{ marginTop: 34 }}>
        <Phone
          country={country || 'US'}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          placeholder={placeholder}
          style={{ background: 'none' }}
          autoComplete="nope"
          value={this.state.phone}
        />
        {this.renderInvalidWarning()}
        {this.renderRequired(isRequired)}
      </div>
    );
  }
}

PhoneNumberInput.propTypes = {
  input: PropTypes.object,
  // meta:        PropTypes.object,
  placeholder: PropTypes.string,
  country: PropTypes.string,
  isRequired: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  isRequired: false,
  placeholder: '',
  country: 'GB',
  // meta:        {},
  input: {
    value: null,
    onChange: () => {},
  },
};
