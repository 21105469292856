import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withMutation } from 'growl-graphql/dist/hoc/withMutation';
import { AddUserToCompanyMutation } from 'growl-graphql/dist/mutations/AddUserToCompanyMutation';

import CompanyUsersList from './CompanyUsersList';
import { AddUserDialog } from '../../../../components/cpanel/components/atoms/AddUserDialog';

import { Button } from '../../../../../fe_common/client/components/inputs';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { setEntityOperationResponseNotification } from '../../../../../fe_common/client/services/notification';

const CompanyUsers = (props) => {
  const { company, addUserToCompanyMutation, readOnly } = props;

  const { id: companyId, name: companyName, users = [] } = company;
  const hasUsers = users.length > 0;

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState(0);

  const openAddUsersDialog = () => {
    setShowAddUserDialog(true);
  };

  const closeAddUsersDialog = () => {
    setShowAddUserDialog(false);
  };

  const handleAddUser = async () => {
    try {
      const addVars = {
        companyId,
        userId,
      };
      const result = await addUserToCompanyMutation.mutate(addVars);
      setEntityOperationResponseNotification(result);
      setUserEmail('');
      setUserId(0);
      closeAddUsersDialog();
    } catch (err) {
      alert(`Error has encountered in addUserToCompanyMutation - ${err.msg}`);
    }
  };

  const onRemoveUser = () => {
    setUserId(0);
    setUserEmail('');
  };

  return (
    <div>
      {!hasUsers && <CalloutMessage type="info" message="No users listed." margin />}

      {hasUsers && (
        <CompanyUsersList users={users} companyId={companyId} readOnly={readOnly} onRemoveUser={onRemoveUser} />
      )}

      {!readOnly && (
        <Button label="Add User to Account" onClick={openAddUsersDialog} color="primary" variant="outlined" />
      )}

      {!readOnly && showAddUserDialog && (
        <AddUserDialog
          userName={companyName}
          open={showAddUserDialog}
          onChange={(event) => {
            setUserEmail(event.target.value);
          }}
          emailAddress={userEmail}
          userIdSetter={setUserId}
          handleAddUser={handleAddUser}
          handleClose={closeAddUsersDialog}
        />
      )}
    </div>
  );
};

CompanyUsers.propTypes = {
  company: PropTypes.object.isRequired,
  addUserToCompanyMutation: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

CompanyUsers.defaultProps = {
  readOnly: false,
};

export default compose(withMutation(AddUserToCompanyMutation))(CompanyUsers);
