import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextDisplay } from '../../../../../display';
import { TRANSLATION_NS } from '../../../consts';
import { useMarketingPlanState } from '../../../../MarketingPlanProvider';

const MarketingPlanMeetingAndApprovalStep: React.FC = () => {
  const { marketingPlanMeetingDate, marketingPlanMeetingDateFormatted } = useMarketingPlanState();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper.MarketingPlanMeetingAndApprovalStep',
  });

  return (
    <TextDisplay variant="body1">
      {t('marketingPlanMeetingAndApproval', {
        replace: {
          marketingPlanMeetingDate: marketingPlanMeetingDate != null ? `(${marketingPlanMeetingDateFormatted})` : '',
        },
      })}
    </TextDisplay>
  );
};

export default MarketingPlanMeetingAndApprovalStep;
