import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import useControlledAccordion from '../../../../hooks/useControlledAccordion';
import { LoanFundsToCompanyWizard } from '../../../../containers/company/wizards/LoanFundsToCompanyWizard';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';

function GiveLoan({ company, onSuccess }) {
  const { expandAccordion, accordionOnChangeHandler, onSuccessHandler } = useControlledAccordion(onSuccess);

  return (
    <Section
      title="Give Loan"
      subTitle={
        'Gives a company funds as credit, which can be used immediately for new or existing projects, ' +
        "and in return creates corresponding debt which must be repaid later, using the company's " +
        'existing credit or using their payment agreements (like credit card or PayPal account).'
      }
      layout={SECTION_LAYOUT.TWO_COLUMNS}
    >
      <Accordion expanded={expandAccordion} onChange={accordionOnChangeHandler}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextDisplay>Click here to give loan</TextDisplay>
        </AccordionSummary>
        <AccordionDetails>
          <LoanFundsToCompanyWizard companyId={company.id} onSuccess={onSuccessHandler} />
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}

GiveLoan.propTypes = {
  company: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

GiveLoan.defaultProps = {
  userType: 'NORMAL_USER',
};

export default GiveLoan;
