import React, { FC } from 'react';
import { Company } from '@mayple/types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import EntityType from '../../../../app/types';
import { getCrackleUrl } from '../../../../app/utils';
import NotesButton from '../../../../components/cpanel/Notes/NotesButton';
import LinkToCompany from '../../../../components/cpanel/components/atoms/Links/LinkToCompany';

import { useDeviceInfo } from '../../../../../fe_common/client/hooks';
import { colors } from '../../../../../fe_common/client/app/theme';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import CopyHtmlElementToClipboardButton from '../../../../../fe_common/client/components/molecules/CopyHtmlElementToClipboardButton';

import useStyles from './style';

interface CompanyDetailsHeaderProps {
  company: Company;
  titlePrefix?: string;
}

/**
 * Basic details viewer on marketer profile
 * @param props
 * @returns {*}
 * @constructor
 */
const CompanyDetailsHeader: FC<CompanyDetailsHeaderProps> = (props) => {
  const classes = useStyles(props);
  const { company, titlePrefix = '' } = props;

  const { isMobile } = useDeviceInfo();

  const { id: companyId, name: companyName, displayImageUrl, description } = company;

  const expertHeroImageStyle = {
    backgroundImage: `url('${displayImageUrl}')`,
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={isMobile ? 3 : 4} className={classNames(classes.heroHeader, {})}>
        <Grid item className={classes.heroImageContainer}>
          <div className={classNames(classes.heroImage, {})} style={expertHeroImageStyle} />
        </Grid>
        <Grid item className={classes.heroDetailsContainer}>
          <div className={classes.headerActions}>
            <NotesButton entityId={companyId} entityType={EntityType.Company} />
            <CopyHtmlElementToClipboardButton
              className={classes.copyButtonRoot}
              querySelector="#company-header"
              label="Copy to Slack"
              size="small"
            />
          </div>
          <div>
            <TextDisplay variant="h4" bold gutterBottom className={classes.companyName} id="company-header">
              <LinkToCompany companyId={companyId} openInNewTab>
                {titlePrefix}
                {companyName} (ID: {companyId})
              </LinkToCompany>
            </TextDisplay>
            <TextDisplay variant="caption" gutterBottom>
              <Link href={`${getCrackleUrl()}?actAsCompanyId=${companyId}`} rel="noopener noreferrer" target="_blank">
                Impersonate {companyName}
              </Link>
            </TextDisplay>
          </div>
          <TextDisplay variant="body1" className={classes.description} colorOverride={colors.black_light}>
            {description}
          </TextDisplay>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyDetailsHeader;
