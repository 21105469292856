import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {
      marginTop: 72,
      paddingTop: 72,
    },
  }),
);

export default useStyles;
