import React, { FC } from 'react';
import StepWizard from 'react-step-wizard';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';

import Step1 from './Step1';

import useStyles from './style';

interface WizardStepsProps extends DecoratedFormProps {
  formValues: any;
  loading: boolean;
}

const WizardSteps: FC<WizardStepsProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <StepWizard>
        <Step1 stepName="mailchimpAuditAssessment-step1" {...props} />
      </StepWizard>
    </div>
  );
};

export default WizardSteps;
