import React, { FC } from 'react';
import { Project } from '@mayple/types';

import DeclineOrApproveMatchedExpertButton from '../DeclineOrApproveMatchedExpertButton';
import useExpertMatchingData from '../../../../../fe_common/client/logic/project/stage/onboarding/useExpertMatchingData';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { RefreshSpinner } from '../../../../../fe_common/client/components/atoms';
import { ExpertMatchingStageData } from '../../../../../fe_common/client/logic/project/stage/onboarding/useExpertMatchingData/types';
import QueryErrorMessage from '../../../../../fe_common/client/components/common/QueryErrorMessage';

interface SelectedMatchesProps {
  project: Project | null | undefined;
  refetchProject: () => void;
}

const SelectedMatches: FC<SelectedMatchesProps> = (props) => {
  const { project, refetchProject } = props;
  const { selectedMatches, loading, error } = useExpertMatchingData(project);

  return (
    <div>
      <TextDisplay variant="h6" gutterBottom color="textSecondary">
        Experts waiting for client approval:
      </TextDisplay>
      {loading && <RefreshSpinner size={24} />}
      <TextDisplay variant="caption" gutterBottom color="textSecondary">
        * Client will be able to schedule meetings with all selected experts, but can approve only 1 to be assigned
      </TextDisplay>
      {error && (
        <QueryErrorMessage error={error} message="Something went wrong. We can't show selected matches here..." />
      )}
      {!error &&
        selectedMatches.map((s: ExpertMatchingStageData) => (
          <DeclineOrApproveMatchedExpertButton
            key={`DeclineMatchedExpertButton-${s.marketerId}-key`}
            matchedExpert={s}
            project={project}
            onDecline={refetchProject}
            onApprove={refetchProject}
          />
        ))}
    </div>
  );
};

export default SelectedMatches;
