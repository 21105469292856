import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Button } from '../../../fe_common/client/components/inputs';

const SearchInput = ({ initialValues, onChange, loading }) => {
  const [values, setValues] = React.useState(initialValues);

  const handleSliderChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };
  const handleCheckboxChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.checked,
    });
  };

  const { days, positions, marketersPerPosition, includeMatchesOverrides } = values;

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item xs={12}>
        <p>
          Projects created in last <b>{days}</b> days (1-90)
        </p>
        <input disabled={loading} type="range" name="days" min="1" max="90" onChange={handleSliderChange('days')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          Calculate <b>{positions}</b> positions (1-9)
        </p>
        <input
          disabled={loading}
          type="range"
          name="positions"
          min="1"
          max="9"
          onChange={handleSliderChange('positions')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          Give <b>{marketersPerPosition}</b> marketers per position (1-10)
        </p>
        <input
          disabled={loading}
          type="range"
          name="marketersPerPosition"
          min="1"
          max="10"
          onChange={handleSliderChange('marketersPerPosition')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          disabled={loading}
          control={
            <Checkbox
              checked={includeMatchesOverrides}
              onChange={handleCheckboxChange('includeMatchesOverrides')}
              color="primary"
            />
          }
          label="Include matches overrides in report (Not recommended)"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          label={loading ? 'Generating...' : 'Generate report'}
          onClick={() => {
            onChange(values);
          }}
          disabled={loading}
          color="primary"
          variant="contained"
        />
      </Grid>
    </Grid>
  );
};

SearchInput.propTypes = {
  initialValues: PropTypes.shape({
    days: PropTypes.string,
    positions: PropTypes.string,
    marketersPerPosition: PropTypes.string,
    includeMatchesOverrides: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SearchInput;
