import React from 'react';

import { AvailabilityViewerDialogProps } from './types';
import AvailabilityViewer from '../index';
import { Dialog } from '../../../../display';

import useStyles from './style';

const AvailabilityViewerDialog: React.FC<AvailabilityViewerDialogProps> = (props) => {
  const { title, isOpen, onClose, mainAccount, initOptions, showFilter, defaultFilter, selectionAccountType } = props;

  const availabilityViewerProps = {
    mainAccount,
    initOptions,
    showFilter,
    defaultFilter,
    selectionAccountType,
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{ dialogContent: classes.dialogContent }}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      fullScreen
    >
      <AvailabilityViewer {...availabilityViewerProps} />
    </Dialog>
  );
};

export default AvailabilityViewerDialog;
