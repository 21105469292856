import React, { useCallback, useMemo } from 'react';
import { TaskType } from '@mayple/types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Pagination from '@material-ui/lab/Pagination';

import useTasksListData from './useTasksListData';
import usePagination from './usePagination';
import getTaskListItemData from './logic';
import AssessmentDialog from '../AssessmentDialog';
import { AssessmentSubTask } from '../AssessmentDialog/types';

import { SkeletonContainer, TextDisplay } from '../../display';
import QueryErrorMessage from '../../common/QueryErrorMessage';
import CalloutMessage from '../../common/CalloutMessage';
import EntityAvatar from '../../atoms/EntityAvatar';
import { useDialog } from '../../../hooks';

import useStyles from './style';

const TasksList: React.FC = () => {
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialog();

  const { loading, error, subTasks, selectedSubTask, setSelectedSubTask, refetch } = useTasksListData([
    TaskType.PostProjectMatchOfferMarketerAssessment,
  ]);

  const { page, totalItems, pageSize, numOfPages, setPageHandler } = usePagination(subTasks);

  // const onOpenAllTasksClickHandler = useCallback(() => {
  //   setSelectedSubTask(null);
  //   openDialog();
  // }, [openDialog, setSelectedSubTask]);

  const getTaskClickHandler = useCallback(
    (newSelectedTask: AssessmentSubTask) => () => {
      setSelectedSubTask(newSelectedTask);
      openDialog();
    },
    [openDialog, setSelectedSubTask]
  );

  const itemsFilter = useCallback(
    (_subTask: any, index: number) => index >= (page - 1) * pageSize && index < page * pageSize,
    [page, pageSize]
  );

  const onCloseDialogHandler = useCallback(async () => {
    await refetch();
    closeDialog();
  }, [closeDialog, refetch]);

  const finalTaskList = useMemo(() => (subTasks || []).filter(itemsFilter), [itemsFilter, subTasks]);

  return (
    <div className={classes.root}>
      <SkeletonContainer loading={loading} variant="rect" height={300}>
        {!loading && error && <QueryErrorMessage error={error} message="Error while trying to get tasks" />}
        {!loading && !error && subTasks?.length === 0 && (
          <CalloutMessage title="Hoorah! 🎉" message="You don't have any open taks to handle at the moment." />
        )}
        {!loading && !error && subTasks?.length > 0 && (
          <>
            <div className={classes.paginatedListHeader}>
              <div>
                <TextDisplay bold>Total tasks: {totalItems}</TextDisplay>
              </div>
              {/*
            <Button
              label="Open All Tasks"
              onClick={onOpenAllTasksClickHandler}
              variant="outlined"
              color="primary"
              size="small"
            />
            */}
              <Pagination
                page={page}
                count={numOfPages}
                variant="outlined"
                color="primary"
                // @ts-ignore
                onChange={setPageHandler}
              />
            </div>
            <List>
              {finalTaskList.map((subTask) => {
                const { primaryText, secondaryText, marketer } = getTaskListItemData(subTask);

                return (
                  <ListItem key={subTask.uuid}>
                    <EntityAvatar
                      avatarImageUrl={marketer?.displayImageUrl || ''}
                      primary={primaryText}
                      secondary={secondaryText}
                      classes={{
                        secondary: classes.secondary,
                      }}
                      onClick={getTaskClickHandler(subTask)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {open && (
          <AssessmentDialog
            isOpen={open}
            onClose={onCloseDialogHandler}
            subTasks={selectedSubTask ? [selectedSubTask] : subTasks}
          />
        )}
      </SkeletonContainer>
    </div>
  );
};

export default TasksList;
