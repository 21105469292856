import { ProjectCreationReason } from '@mayple/types';

export interface OptionsType {
  deepBrief: boolean;
  projectSettings: boolean;
  packages: boolean;
  assignedGS: boolean;
  assignedMC: boolean;
  isUpSale: boolean;
}

export const getOptions = (reason: ProjectCreationReason): OptionsType => {
  const options: OptionsType = {
    deepBrief: true,
    projectSettings: true,
    packages: true,
    assignedGS: true,
    assignedMC: true,
    isUpSale: false,
  };

  if (reason === ProjectCreationReason.UP_SALE) {
    options.packages = false;
    options.assignedMC = false;
    options.isUpSale = true;
  } else if (reason === ProjectCreationReason.EXPERT_REPLACEMENT) {
    options.assignedMC = false;
    options.isUpSale = true;
  }

  return options;
};

export const optionLabels: Record<keyof OptionsType, string> = {
  deepBrief: 'Deep Brief (will be connected to current project)',
  projectSettings: 'Project settings (KPI, Industry, Targeting etc.)',
  packages: 'Packages and billing (from the last cycle)',
  assignedGS: 'Assigned GS',
  assignedMC: 'Assigned sales',
  isUpSale: 'Mark as Up sale',
};
