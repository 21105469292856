export enum MarketerDetailsTabs {
  basicDetails = 'basicDetails',
  projects = 'projects',
  funds = 'funds',
  integrations = 'integrations',
  users = 'users',
}

export const marketerTabs = [
  {
    value: MarketerDetailsTabs.basicDetails,
    label: 'Basic Details',
  },
  {
    value: MarketerDetailsTabs.projects,
    label: 'Projects',
  },
  {
    value: MarketerDetailsTabs.funds,
    label: 'Funds',
  },
  {
    value: MarketerDetailsTabs.integrations,
    label: 'Integrations',
  },
  {
    value: MarketerDetailsTabs.users,
    label: 'Users',
  },
];
