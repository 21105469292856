import React, { FC, useCallback, useMemo } from 'react';
import { CronofyAvailabilityProfiles } from '@mayple/types';

import ProjectsTable from '../ProjectsPage/ProjectsTable';
import { ROUTES_REDIRECT } from '../../app/consts';
import QuickNavigate from './components/QuickNavigate';

import Section from '../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../fe_common/client/components/atoms/Section/SectionContent';
import useActingAccount from '../../../fe_common/client/hooks/useActingAccount';
import CalloutMessage from '../../../fe_common/client/components/common/CalloutMessage';
import CronofyIntegrationManager from '../../../fe_common/client/components/integrations/calendars/CronofyIntegrationManager';
import TasksList from '../../../fe_common/client/components/tasks/TasksList';

import useStyles from './style';

const HomePage: FC = () => {
  const classes = useStyles();

  const {
    actingAccount: actingInternalTeam,
    loading: actingInternalTeamLoading,
    error: actingInternalTeamError,
    refetch: refetchActingInternalTeam,
  } = useActingAccount();

  const projects = useMemo(() => actingInternalTeam?.projects || [], [actingInternalTeam?.projects]);

  const redirectUri = `${window.location.origin}${ROUTES_REDIRECT.CRONOFY_AUTH}`;

  const cronofyIntegrationOnSuccessHandler = useCallback(async () => {
    await refetchActingInternalTeam();
  }, [refetchActingInternalTeam]);

  return (
    <div className={classes.root}>
      {!!actingInternalTeamError && !actingInternalTeamLoading && (
        <CalloutMessage type="error" message={actingInternalTeamError.message} />
      )}

      <Section noBorder noTopMargin title="Quick navigate" classes={{ sectionHeader: classes.sectionHeader }}>
        <QuickNavigate />
      </Section>

      <Section noBorder gutterTopSize={2} title="Open Tasks" classes={{ sectionHeader: classes.sectionHeader }}>
        <TasksList />
      </Section>

      <ProjectsTable
        data={projects}
        loading={actingInternalTeamLoading}
        title="Your Projects"
        classes={{ sectionContentRoot: classes.sectionContent }}
      />

      <Section gutterTopSize={2} noBorder title="Your Integations">
        <SectionContent>
          <CronofyIntegrationManager
            redirectUri={redirectUri}
            onAddIntegrationSuccess={cronofyIntegrationOnSuccessHandler}
            onRevokeSuccess={cronofyIntegrationOnSuccessHandler}
            availabilityRulesProfiles={[CronofyAvailabilityProfiles.work_hours, CronofyAvailabilityProfiles.mailchimp]}
          />
        </SectionContent>
      </Section>
    </div>
  );
};

export default HomePage;
