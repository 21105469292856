import React from 'react';

import { ProjectHealthCheckStopReasonDialogProps } from './types';
import ProjectHealthCheckStopReasonForm from './ProjectHealthCheckStopReasonForm';

import { Dialog } from '../../../../../fe_common/client/components/display';

import { useDialogStyles } from './style';

const ProjectHealthCheckStopReasonDialog: React.FC<ProjectHealthCheckStopReasonDialogProps> = (props) => {
  const classes = useDialogStyles(props);

  const { projectId, isOpen, onClose, onSuccess } = props;
  const onSuccessHandler = () => {
    onSuccess?.();
    onClose?.();
  };

  return (
    <Dialog
      title="Before you stop the project"
      isOpen={isOpen}
      onClose={onClose}
      containerMaxWidth="md"
      fullWidth
      classes={classes}
    >
      <ProjectHealthCheckStopReasonForm projectId={projectId} onSuccess={onSuccessHandler} onCancel={onClose} />
    </Dialog>
  );
};

export default ProjectHealthCheckStopReasonDialog;
