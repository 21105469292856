import React, { FC } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import useStyles from './style';

interface LightBoxProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  loading?: boolean;
  actions?: any[];
}

const LightBox: FC<LightBoxProps> = (props) => {
  const { open, onClose, title, actions, loading = false, children, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Dialog open={open} {...rest}>
      {loading && <LinearProgress />}
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{title}</Grid>
          <Grid item>
            <Tooltip title="Close" placement="left">
              <IconButton
                type="button"
                aria-label="Close"
                color="default"
                onClick={onClose}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && actions.length > 0 && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default LightBox;
