import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { Project } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProjectByIdQuery } from 'growl-graphql/dist/queries/ProjectByIdQuery';

export type ProjectData = {
  loading: boolean;
  error: ApolloError | undefined;
  project: Project | undefined;
  refetch: (variables?: { projectId: number }) => Promise<ApolloQueryResult<Project>>;
};

export type ProjectDataQueryFlags = {
  withWebsiteBasedInsights?: boolean;
};

const useProjectData = (
  projectId: number | null | undefined,
  options: QueryHookOptions = { notifyOnNetworkStatusChange: true },
  queryFlags: ProjectDataQueryFlags = {},
): ProjectData => {
  const mergedOptions = {
    variables: { projectId, ...queryFlags },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
    ...options,
  };

  const { loading, error, data, refetch } = useQuery(ProjectByIdQuery.query, mergedOptions);

  return {
    loading,
    error,
    project: data?.project,
    refetch,
  };
};

export default useProjectData;
