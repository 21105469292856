import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import { GlobalMarketerMatchesStatisticsQuery } from 'growl-graphql/dist/queries/GlobalMarketerMatchesStatisticsQuery';

import ResultItem from './ResultItem';
import { TextDisplay } from '../../../fe_common/client/components/display';
import { RefreshSpinner } from '../../../fe_common/client/components/atoms';

const ResultsContainer = ({ marketerMatchesStatisticsInput, setLoading }) => {
  const originalQuery = useQuery(GlobalMarketerMatchesStatisticsQuery.query, {
    variables: {
      marketerMatchesStatisticsInput,
    },
  });

  const { loading, error, data } = originalQuery;

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const globalMarketerMatchesStatistics = data?.globalMarketerMatchesStatistics;

  if (error) {
    return <div>Error: {JSON.stringify(error)}</div>;
  }

  if (loading) {
    return <RefreshSpinner />;
  }

  return (
    <div>
      {globalMarketerMatchesStatistics.map((item) => (
        <div key={`${item.position}-globalMarketerMatchesStatistics-position`} className="m-b-16">
          <TextDisplay variant="h5" gutterBottom color="primary">
            Position: {item.position}
          </TextDisplay>
          <Grid container spacing={2}>
            {item.marketerMatchesStatisticsList.map((statisticItem, index) => (
              <Grid
                key={`${statisticItem.marketer.uuid}-marketerMatchesStatisticsList-${item.position}`}
                item
                xs={12}
                md={4}
                lg={2}
              >
                <ResultItem
                  marketerMatchStatistic={statisticItem}
                  topCount={item.marketerMatchesStatisticsList[0].count}
                  index={index}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

ResultsContainer.propTypes = {
  marketerMatchesStatisticsInput: PropTypes.shape({
    days: PropTypes.string,
    positions: PropTypes.string,
    marketersPerPosition: PropTypes.string,
    includeMatchesOverrides: PropTypes.bool,
  }).isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default ResultsContainer;
