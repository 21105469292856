import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    pageHeaderRoot: {
      display: 'flex',
      marginTop: '2.5rem',
      marginBottom: '1.5rem',
      alignItems: 'center',
      gap: '1rem',
      width: '100%',
    },
    pageHeaderLeftSide: {
      flexGrow: 1,
    },
    pageHeaderRightSide: {
      textAlign: 'end',
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        flexGrow: 1,
      },
    },
    title: {},
    subtitle: {},
  })
);

export default useStyles;
