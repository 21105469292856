import { useCallback, useEffect, useState } from 'react';
import { Marketer, EntityOperationResponse, MailchimpAuditAssessmentByCompany } from '@mayple/types';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { useTranslation } from 'react-i18next';

import { FormValues, MailchimpAuditAssessmentFormData } from './types';
import getSetMailchimpAuditAssessmentByCompanyForProjectVariables from './logic';
import useMailchimpAuditAssessmentMutations from './useMailchimpAuditAssessmentMutations';
import { AssessmentFormProps, AssessmentWizardFormContent } from '../../types';

import { TRANSLATION_NS } from '../../../../../app/consts';
import { handleClientError } from '../../../../../services/logger';

const useMailchimpAuditAssessmentFormData = (props: AssessmentFormProps): MailchimpAuditAssessmentFormData => {
  const { subTask, onSubmitForm, onSkipForm, currentStep = 1, totalSteps = 0, nextStep, setDialogContent } = props;
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'AssessmentDialog.MailchimpAuditAssessmentForm' });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { marketer, marketerId } = subTask as MailchimpAuditAssessmentByCompany;
  const { name: marketerName = '', displayImageUrl = '' } = marketer || ({} as Marketer);

  const {
    savingAssessment,
    setMailchimpAuditAssessmentByCompanyForProject,
    skippingAssessment,
    skipMailchimpAuditAssessmentByCompanyForProject,
  } = useMailchimpAuditAssessmentMutations();

  const saveAssessmentData = useCallback(
    async (formValues: FormValues): Promise<EntityOperationResponse> => {
      const variables = getSetMailchimpAuditAssessmentByCompanyForProjectVariables(formValues);

      const result = await setMailchimpAuditAssessmentByCompanyForProject({ variables });
      return result?.data?.setMailchimpAuditAssessmentByCompanyForProject;
    },
    [setMailchimpAuditAssessmentByCompanyForProject]
  );

  const formSubmitHandler: FormSubmitHandler<FormValues> = useCallback(
    async (formValues) => {
      if (!subTask) {
        return;
      }

      try {
        const result = await saveAssessmentData(formValues);

        if (!result?.success) {
          handleClientError(null, t('submitFormErrorMessage'));
          setErrorMessage(t('submitFormErrorMessage', 'There was a problem submitting your assessment data.'));
          return;
        }
        onSubmitForm?.();
        if (currentStep < totalSteps) {
          nextStep?.();
        }
      } catch (e) {
        handleClientError(null, t('submitFormErrorMessage'));
        setErrorMessage(t('submitFormErrorMessage', 'There was a problem submitting your assessment data.'));
      }
    },
    [currentStep, nextStep, onSubmitForm, saveAssessmentData, subTask, t, totalSteps]
  );

  const skipFormHandler = useCallback(async (): Promise<EntityOperationResponse> => {
    const variables: {
      projectId: number;
      marketerId: number;
    } = {
      projectId: subTask?.projectId || 0,
      marketerId: subTask?.marketerId || 0,
    };

    const result = await skipMailchimpAuditAssessmentByCompanyForProject({ variables });
    return result?.data?.skipMailchimpAuditAssessmentByCompanyForProject;
  }, [skipMailchimpAuditAssessmentByCompanyForProject, subTask?.marketerId, subTask?.projectId]);

  const onSkipButtonClickHandler = useCallback(async () => {
    if (!subTask) {
      return;
    }

    try {
      const result = await skipFormHandler();
      if (!result?.success) {
        handleClientError(null, t('skipFormErrorMessage'));
        setErrorMessage(t('submitFormErrorMessage', 'There was a problem skipping your assessment data.'));
        return;
      }
      onSkipForm?.();
    } catch (e) {
      handleClientError(null, t('skipFormErrorMessage'));
      setErrorMessage(t('submitFormErrorMessage', 'There was a problem skipping your assessment data.'));
    }
  }, [subTask, skipFormHandler, onSkipForm, t]);

  useEffect(() => {
    if (currentStep < totalSteps) {
      setDialogContent(AssessmentWizardFormContent.SHOW_FORM);
    }
  }, [currentStep, setDialogContent, totalSteps]);

  return {
    marketerId,
    marketerName,
    displayImageUrl,
    formSubmitHandler,
    saving: savingAssessment || skippingAssessment,
    onSkipButtonClickHandler,
    errorMessage,
  };
};

export default useMailchimpAuditAssessmentFormData;
