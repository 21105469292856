import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colorPalette } from '../../../../../fe_common/client/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    listRoot: {
      whiteSpace: 'nowrap',
      padding: 0,
      border: `2px solid ${colorPalette.gray[40]}`,
      borderRight: `1px solid ${colorPalette.gray[40]}`,
      alignSelf: 'stretch',
      borderRadius: '4px 0 0 4px',
      backgroundColor: colorPalette.gray[20],
    },
    listItemRoot: {
      minWidth: 170,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
    },
    listItemTextRoot: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginTop: 0,
      marginBottom: 0,
    },
    listItemIconRoot: {
      justifyContent: 'center',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderLeft: '0 none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderColor: `${colorPalette.gray[40]}!important`,
      },
    },
  }),
);

export default useStyles;
