import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  root: { verticalAlign: 'middle', marginRight: 8 },
  error: { color: 'red' },
  pending: { color: 'yellow' },
  synced: { color: 'green' },
});

const MarketingAccountStatusIcon = ({ status, classes }) => (
  <Brightness1Icon
    className={classNames(classes.root, {
      [classes.error]: status === 'ERROR',
      [classes.synced]: status === 'SYNCED',
      [classes.pending]: status === 'PENDING' || status === 'UPDATED',
    })}
  />
);

MarketingAccountStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketingAccountStatusIcon);
