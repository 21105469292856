import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

import SimpleDataGrid from './index';

import { collapsibleTableStyles } from './style';

function CollapsibleTable({ name, columns, rows, open, classes }) {
  return <Collapse in={open} timeout="auto" unmountOnExit>
    <Box margin={1}>
      <SimpleDataGrid name={`collapsedTable-${name}-${rows.id}`} columns={columns} rows={rows} classes={classes} />
    </Box>
  </Collapse>
}

CollapsibleTable.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      formatter: PropTypes.func,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

CollapsibleTable.defaultProps = {
  rows: [],
  name: '',
};

export default withStyles(collapsibleTableStyles)(CollapsibleTable);
