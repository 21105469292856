import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

import ProjectMarketingHistoryAccountsDetails from '../ProjectMarketingHistoryAccountsDetails';
import ProjectMarketingLiveAccountsDetails from '../ProjectMarketingLiveAccountsDetails';

import Section from '../../../../../../../fe_common/client/components/atoms/Section';

const styles = () => ({
  root: {},
  sectionTitle: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
  },
  sectionHeader: {
    marginBottom: 16,
  },
  divider: {
    margin: '32px auto',
  },
});

const ProjectMarketingAccountDetails = (props) => {
  const { project, showHistoryAccounts, showLiveAccounts, noTopMargin, noBorder, classes } = props;

  return (
    <Section title="Marketing Accounts" noBorder={noBorder} noTopMargin={noTopMargin}>
      {showLiveAccounts && (
        <ProjectMarketingLiveAccountsDetails
          project={project}
          classes={{
            sectionTitle: classes.sectionTitle,
            sectionHeader: classes.sectionHeader,
          }}
        />
      )}
      {showLiveAccounts && showHistoryAccounts && <Divider className={classes.divider} />}
      {showHistoryAccounts && (
        <ProjectMarketingHistoryAccountsDetails
          project={project}
          classes={{
            sectionTitle: classes.sectionTitle,
            sectionHeader: classes.sectionHeader,
          }}
        />
      )}
    </Section>
  );
};

ProjectMarketingAccountDetails.propTypes = {
  project: PropTypes.object.isRequired,
  showHistoryAccounts: PropTypes.bool,
  showLiveAccounts: PropTypes.bool,
  noBorder: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

ProjectMarketingAccountDetails.defaultProps = {
  showHistoryAccounts: false,
  showLiveAccounts: false,
  noBorder: false,
  noTopMargin: false,
};

export default withStyles(styles)(ProjectMarketingAccountDetails);
