import React from 'react';
import { AccountType } from '@mayple/types';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

import useReviewByGrowthStrategistStep from './logic';
import { TRANSLATION_NS } from '../../../consts';
import { TextDisplay } from '../../../../../display';
import { Button } from '../../../../../inputs';

import useStyles from './style';

const ReviewByGrowthStrategistStep: React.FC = () => {
  const classes = useStyles();

  const {
    accountType,
    marketingPlanApproved,
    marketingPlanUploaded,
    mainGrowthStrategistName,
    projectInMarketingPlanStage,
    onApproveMarketingPlanClickHandler,
  } = useReviewByGrowthStrategistStep();

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper.ReviewByGrowthStrategistStep',
  });

  const tooltipTitle = !projectInMarketingPlanStage
    ? 'To approve the marketing plan the project should be on Marketing Plan stage.'
    : '';

  return (
    <>
      <TextDisplay variant="body1">{t('stepContent', { replace: { mainGrowthStrategistName } })}</TextDisplay>
      {accountType && accountType === AccountType.INTERNAL_TEAM && (
        <Tooltip title={tooltipTitle} placement="top-start">
          <span>
            <Button
              label={t('approvePlan')}
              color="primary"
              variant="contained"
              onClick={onApproveMarketingPlanClickHandler}
              className={classes.approveMarketingPlanButton}
              disabled={marketingPlanApproved || !marketingPlanUploaded || !projectInMarketingPlanStage}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default ReviewByGrowthStrategistStep;
