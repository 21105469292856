import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';

import useStyles from './styles';

interface ReadMoreLinkProps {
  onChange: (isOpen: boolean) => void;
  isOpen?: boolean;
}

const ReadMoreLink: React.FC<ReadMoreLinkProps> = (props) => {
  const classes = useStyles(props);
  const { isOpen = false, onChange } = props;

  // is the content expanded
  const [readMoreOpen, setReadMoreOpen] = useState(isOpen);

  const readMoreLabel = readMoreOpen ? 'read less' : 'read more...';

  const onChangeHandler = useCallback(() => {
    setReadMoreOpen(!readMoreOpen);
    onChange(!readMoreOpen);
  }, [onChange, readMoreOpen]);

  return (
    <div
      className={classNames(classes.readLinkContainer, {
        [classes.readMoreLinkContainer]: !readMoreOpen,
        [classes.readLessLinkContainer]: readMoreOpen,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link component="button" variant="body2" onClick={onChangeHandler}>
        {readMoreLabel}
      </Link>
    </div>
  );
};

export default ReadMoreLink;
