import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { debounce } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import {
  validateRequired,
  validateRegex,
  validateMinLength,
  validateMaxLength,
  validateEmail,
  validateIntegerGreaterThanOrEqualTo,
  validateIntegerLessThanOrEqualTo,
  validateFloatBetween,
  validateEquality,
  validateRequiredWithMessage,
} from '../../../../services/validation';
import { colors, getGradientColor } from '../../../../app/theme';
import TextField from '../../Registration/TextField';
import { TextDisplay } from '../../../display';

/**
 * Removes white space from email field on blur, as it makes it invalid email
 * @param dispatch
 * @param change
 * @param name
 * @param e
 */
export function onEmailInputBlur(dispatch, change, name, e) {
  const finalValue = typeof e.target.value === 'string' ? e.target.value.trim() : e.target.value;
  if (!finalValue) {
    return;
  }

  // Only trim when it's a valid email
  if (!validateEmail(finalValue)) {
    e.preventDefault();
    dispatch(change(name, finalValue));
  }
}

const styles = createStyles({
  inputAdornmentRoot: {
    position: 'absolute',
    bottom: '17px',
    right: '3px',
    display: 'inline-block',
  },
  inputAdornmentText: {
    color: getGradientColor('gray', 80),
  },
});

class WizardTextFieldWrapped extends React.Component {
  constructor(props) {
    super(props);

    // console.log('WizardTextFieldWrapped props', props);

    const {
      fieldType,
      maxValue,
      minValue,
      range,
      isRequired,
      isEmail,
      minLength,
      maxLength,
      regex,
      extraValidation,
      regexErrorMessage,
    } = props;

    // field required?
    let validator = [];

    if (isRequired) {
      if (minLength > 0) {
        validator.push(validateRequiredWithMessage(`Min ${minLength} characters required`));
      } else {
        validator.push(validateRequired);
      }
    }

    if (fieldType === 'number') {
      // console.log('WizardTextFieldWrapped fieldType === \'number\'');
      if (maxValue !== 0) {
        validator.push(validateIntegerLessThanOrEqualTo(maxValue));
      }
      if (minValue !== 0) {
        validator.push(validateIntegerGreaterThanOrEqualTo(minValue));
      }
      if (range) {
        validator.push(validateFloatBetween(range.min, range.max));
      }
    }

    // min length of text field: default 0
    if (minLength > 0) {
      validator.push(validateMinLength(minLength));
    }
    // min length of text field: default 0
    if (maxLength > 0) {
      validator.push(validateMaxLength(maxLength));
    }

    // is email field?
    if (isEmail) {
      validator.push(validateEmail);
    }

    if (regex) {
      validator.push(validateRegex(regex, regexErrorMessage));
    }

    if (extraValidation && extraValidation?.length > 0) {
      validator = validator.concat(extraValidation);
    }

    this.state = {
      validator,
      textAreaHelper: '',
    };
  }

  setRemainingCharacters = debounce((event) => {
    const { value = '' } = event.target;
    const { isMultiLine, maxLength } = this.props;

    if (isMultiLine && maxLength) {
      const numOfChars = (typeof value === 'string' && value.length) ?? 0;
      const textAreaHelper = `${numOfChars}/${maxLength}`;
      this.setState({ textAreaHelper });
    }
  }, 100);

  render() {
    const {
      name,
      hintText,
      isMultiLine,
      fieldType,
      minValue,
      maxValue,
      maxLength,
      rows,
      maxRows,
      onBlur,
      parse,
      fullWidth,
      label,
      floatingLabelText,
      autoComplete,
      autoFocus,
      helperText,
      inputAdornment,
      adornmentPosition,
      variant,
      onEnterPress,
      equalsTo,
      showRemainingCharacters,
      classes,
    } = this.props;

    const { validator: originalValidator, textAreaHelper } = this.state;

    const validator = [...originalValidator];
    if (equalsTo) {
      validator.push((value) => validateEquality(value, equalsTo));
    }

    let adornment = null;
    let adornmentInputProps = {};
    if (inputAdornment && !isMultiLine && adornmentPosition !== 'end') {
      adornment = (
        <InputAdornment style={{ color: colors.black }} position={adornmentPosition}>
          <TextDisplay color="default">{inputAdornment}</TextDisplay>
        </InputAdornment>
      );

      adornmentInputProps = adornmentPosition === 'start' ? { startAdornment: adornment } : { endAdornment: adornment };
    }

    if (isMultiLine && maxLength) {
      adornment = (
        <InputAdornment position="end" classes={{ root: classes.inputAdornmentRoot }}>
          <TextDisplay variant="caption" className={classes.inputAdornmentText}>
            {textAreaHelper}
          </TextDisplay>
        </InputAdornment>
      );

      adornmentInputProps = { endAdornment: adornment };
    }

    let extraProps = {};
    if (showRemainingCharacters) {
      extraProps = { onChange: this.setRemainingCharacters };
    }

    return (
      <div>
        <Field
          component={TextField}
          type={fieldType}
          name={name}
          placeholder={hintText}
          fullWidth={fullWidth}
          label={floatingLabelText || label}
          multiline={isMultiLine}
          minRows={rows}
          maxRows={maxRows}
          validate={validator}
          onBlur={onBlur}
          parse={parse}
          autoComplete={autoComplete}
          autoFocus={autoFocus} // only 1 input should get this prop per step
          helperText={helperText}
          InputProps={{
            ...adornmentInputProps,
          }}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          inputProps={{
            min: minValue,
            max: maxValue,
            maxLength,
          }}
          variant={variant}
          onKeyUp={(e) => {
            e.preventDefault();
            if (onEnterPress && (e.key === 'Enter' || e.keyCode === 13)) {
              onEnterPress(e);
            }
          }}
          {...extraProps}
        />
      </div>
    );
  }
}

WizardTextFieldWrapped.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  label: PropTypes.string,
  floatingLabelText: PropTypes.string,
  regexErrorMessage: PropTypes.string,
  fieldType: PropTypes.string,
  autoComplete: PropTypes.string,
  inputAdornment: PropTypes.string,
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  maxRows: PropTypes.number,
  rows: PropTypes.number,
  range: PropTypes.object,
  isEmail: PropTypes.bool,
  isMultiLine: PropTypes.bool,
  isRequired: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showRemainingCharacters: PropTypes.bool,
  onBlur: PropTypes.func,
  onEnterPress: PropTypes.func,
  parse: PropTypes.any,
  regex: PropTypes.any,
  helperText: PropTypes.any,
  equalsTo: PropTypes.string,
  extraValidation: PropTypes.arrayOf(PropTypes.func),
};

WizardTextFieldWrapped.defaultProps = {
  label: '',
  floatingLabelText: '',
  hintText: '',
  regexErrorMessage: '',
  inputAdornment: '',
  adornmentPosition: 'start',
  fieldType: 'text',
  variant: 'outlined',
  autoComplete: 'nope',
  isMultiLine: false,
  isEmail: false,
  autoFocus: false,
  isRequired: true,
  fullWidth: true,
  regex: null,
  range: null,
  helperText: null,
  minLength: 0,
  maxLength: -1,
  minValue: 0,
  maxValue: 0,
  rows: 1,
  maxRows: 10,
  onBlur: () => {},
  onEnterPress: () => {},
  parse: undefined,
  equalsTo: '',
  extraValidation: [],
  showRemainingCharacters: false,
};

export const WizardTextField = withStyles(styles)(WizardTextFieldWrapped);
