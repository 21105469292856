import React, { useMemo, useState, useEffect, FC, useCallback } from 'react';
import { FeedPost, FeedReaction, ReactionType } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';
import { ReactToFeedPostMutation } from 'growl-graphql/dist/mutations/ReactToFeedPostMutation';

import useMutation from '../../../hooks/useMutation';
import { Button } from '../../inputs';
import { useFeedState } from '../FeedContext';
import FeedPostReactionsIcons from './FeedPostReactionsIcons';
import getReactToFeedPostMutationOptions from './logic';
import { useFeedTraits } from '../events';

import { useFeedPostReactionsStyles } from './styles';

interface FeedPostReactionsProps {
  post: FeedPost;
  viewerPersonUuid: string;
  classes?: Record<string, string>;
}

/**
 * Based on:
 * https://www.notion.so/Insights-9eadfe7e7d9944518a079fa2480b9665#7b4dba4b3d4e461886e06dd3812d06ae
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FeedPostReactions: FC<FeedPostReactionsProps> = (props) => {
  const { viewerPersonUuid, post } = props;
  const classes = useFeedPostReactionsStyles(props);
  const reactions = useMemo(() => (post?.reactions || []) as FeedReaction[], [post]);
  const { uuid: postUuid, authorUuid, topic } = post;
  // In the future: we will extract this to a component FeedPostReaction, that will handle the rendering of the
  // different reaction types
  // For now we only show likes. So no need for separating types of reactions!

  const { projectId, marketerId, isEditable, eventHandlers } = useFeedState();
  const { onPostReaction } = eventHandlers;
  const [disable, setDisable] = useState(false);
  const traits = useFeedTraits(authorUuid);

  const didReactToPost = useMemo(
    () => reactions.some(({ personUuid }) => personUuid === viewerPersonUuid),
    [reactions, viewerPersonUuid]
  );

  const { mutate, loading } = useMutation(ReactToFeedPostMutation, {
    mutationOptions: getReactToFeedPostMutationOptions(postUuid, projectId, marketerId, viewerPersonUuid),
  });

  useEffect(() => {
    setDisable(loading);
  }, [loading]);
  //
  const reactToFeedPost = useCallback(async () => {
    setDisable(true);
    const reactionType = ReactionType.LIKE;
    const reactionState = !didReactToPost;

    const variables = {
      reaction: {
        reactionType,
        reactionState,
      },
      postUuid,
    };

    await mutate({ variables });

    onPostReaction({
      ...traits,
      reactionState,
      reactionType,
      postUuid,
      topic,
    });
  }, [didReactToPost, mutate, onPostReaction, postUuid, topic, traits]);

  return (
    <Grid container alignItems="center" spacing={2} className={classes.reactionsRoot}>
      <Grid item>
        <FeedPostReactionsIcons viewerPersonUuid={viewerPersonUuid} reactions={reactions} />
      </Grid>
      {isEditable && (
        <Grid item>
          <Button
            className={classes.likeButton}
            variant={!didReactToPost ? 'outlined' : 'text'}
            color={didReactToPost ? 'primary' : 'default'}
            label={didReactToPost ? 'UNLIKE' : 'LIKE'}
            startIcon={<ThumbUpIcon />}
            onClick={reactToFeedPost}
            disabled={loading || disable}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FeedPostReactions;
