import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Step } from 'react-albus';
import { Field } from 'redux-form';
import keyBy from 'lodash/keyBy';
import omitDeep from 'omit-deep-lodash';
import Divider from '@material-ui/core/Divider';

import MarketerMonthlyMediaSpend from './MarketerMonthlyMediaSpend';
import CycleStep from '../ProjectBillingEditWizard/CycleStep';

import MultiDateRangesPicker from '../../../../components/cpanel/components/molecules/MultiDateRangesPicker';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { WizardStepField } from '../../../../../fe_common/client/components/molecules/WizardStepField';
import { dateTimeUTCFormatter } from '../../../../../fe_common/client/services/ExportToCSV/formatters';
import { getMonthStartDate } from '../../../../../fe_common/client/services/utils';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';

export function WizardSteps(props) {
  const { invalid, submit, submitting, errors, cycleId, cycle, isFirstCycle, dispatch, change, formValues } = props;
  const { startDate, endDate, estimatedMediaBudgetDivision } = cycle;
  const { minDate, maxDate } = React.useMemo(
    () => ({
      minDate: getMonthStartDate(endDate),
      maxDate: dateTimeUTCFormatter(endDate),
    }),
    [endDate],
  );
  // console.log('formValues', formValues);
  // console.log('props', props);

  const hasPPCService = estimatedMediaBudgetDivision?.length > 0;

  // Collect all the spend values reported by the marketer and copy them over the existing channels values
  const handleCopySpend = React.useCallback(
    (newDivision) => {
      const { mediaBudget } = formValues;
      const { mediaBudgetDivision } = mediaBudget;
      const marketerMediaBudgetDivision = omitDeep(keyBy(newDivision, 'skillType'), '__typename', 'tableData');

      const newMediaBudgetDivision = {
        ...mediaBudgetDivision,
        ...marketerMediaBudgetDivision,
      };

      const newTotalBudget = Object.values(newMediaBudgetDivision).reduce(
        (acc, { spendForSkillType }) => acc + spendForSkillType,
        0,
      );

      const newMediaBudget = {
        mediaBudgetDivision: newMediaBudgetDivision,
        totalMediaBudget: newTotalBudget,
      };

      dispatch(change('mediaBudget', newMediaBudget));
    },
    [change, dispatch, formValues],
  );

  return (
    <Steps>
      <Step
        id="projectSimulatorStepOne"
        render={() => (
          <div>
            {hasPPCService && <MarketerMonthlyMediaSpend onCopySpend={handleCopySpend} cycleId={cycleId} />}
            {!hasPPCService && (
              <CalloutMessage
                margin
                message="No PPC service for this cycle, hence no spend update from the Expert is required."
                type="info"
              />
            )}
            <CycleStep
              {...props}
              isNextCycle={false}
              allowDiscountEdit={false}
              suffix=""
              enableDeliverablesEdit
              enableSetupServiceEdit
              enablePpcBillingPlanEdit
              enableNonPpcServicesEdit
              cycleId={cycleId}
              cycle={cycle}
              showNonPpcRules={false}
              mediaBudgetDivisionFieldAmountKey="spendForSkillType"
            />
            <TextDisplay variant="h5" bold gutterBottom>
              Dates
            </TextDisplay>
            {isFirstCycle && (
              <WizardStepField
                wizardFieldLabel="Set cycle start date"
                name="startDate"
                type={WizardStepField.types.DATE_PICKER}
                minDate={minDate}
                maxDate={maxDate}
                views={['date']}
              />
            )}

            <TextDisplay>Original start date: {dateTimeUTCFormatter(startDate)}</TextDisplay>
            <TextDisplay>Original end date: {dateTimeUTCFormatter(endDate)}</TextDisplay>
            <Field
              name="ppcPauseDurations"
              component={MultiDateRangesPicker}
              minDate={startDate}
              maxDate={endDate}
              title="PPC Paused Durations"
            />
            <Field
              name="nonPPCPauseDurations"
              component={MultiDateRangesPicker}
              minDate={startDate}
              maxDate={endDate}
              title="Non-PPC Paused Durations"
            />
            <TextDisplay variant="caption">
              <strong>Notice: </strong> The dates are inclusive. meaning that both the start date, end date and all the
              dates between, will be considered as paused and will not be charged.
            </TextDisplay>
            {errors && errors.length > 0 && (
              <ul>
                {errors.map((errMsg) => (
                  <li key={errMsg.replace(/\s/g, '')}>
                    <font color="red">{errMsg}</font>
                  </li>
                ))}
              </ul>
            )}
            {invalid && <CalloutMessage type="error" message="Invalid values. Please check for errors." />}

            <Divider />

            <Button
              label="Update configuration"
              disabled={invalid || submitting}
              onClick={submit}
              color="primary"
              variant="contained"
            />

            <br />
            <br />
            <br />
          </div>
        )}
      />
    </Steps>
  );
}

WizardSteps.propTypes = {
  formValues: PropTypes.object.isRequired,
  cycle: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  cycleId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  enableNextCycleEdit: PropTypes.bool.isRequired,
  isFirstCycle: PropTypes.bool.isRequired,
  errors: PropTypes.array,
};
WizardSteps.defaultProps = {
  errors: null,
};
