import { useEffect } from 'react';
import { parse } from 'query-string';

import { CronofyIntegrationResult } from '../types';
import { CRONOFY_EVENT_SOURCE_NAME } from '../consts';

/**
 * This hook should be called in the page that Cronofy redirects to.
 * It is contains a logic that sends back the code and the state passed to Cronofy Authentication
 */
const useCronofyAuthenticationResult = (): void => {
  // call this only once when the page loads
  // We should add here some error handling in case the window was closed in the middle
  useEffect(() => {
    const queryParams = parse(window.location.search);
    const { state, code } = queryParams;

    // console.log('code', code);
    // console.log('state', JSON.parse(state as string));

    const payload: CronofyIntegrationResult = {
      code: (code || '') as string,
    };

    if (state != null && state !== '') {
      payload.state = JSON.parse(state as string);
    }

    const message = {
      source: CRONOFY_EVENT_SOURCE_NAME,
      payload,
    };

    // send a message to the opener
    if (window.opener) {
      window.opener.postMessage(message, '*');
    }
    const timeoutHandle = window.setTimeout(window.close, 0);
    return () => {
      window.clearTimeout(timeoutHandle);
    };
  }, []);
};

export default useCronofyAuthenticationResult;
