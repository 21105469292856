import React, { useCallback, useMemo, useState } from 'react';
import { Project } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { ConnectProjectDeepBriefToAnotherProjectMutation } from 'growl-graphql/dist/mutations/ConnectProjectDeepBriefToAnotherProjectMutation';

import { getCompanyProjectsList } from './logic';
import useCompanyProjectsBasic from '../../../../../hooks/useCompanyProjectsBasic';

import { useMutation } from '../../../../../../fe_common/client/hooks';
import CalloutMessage from '../../../../../../fe_common/client/components/common/CalloutMessage';
import ConfirmDialog from '../../../../../../fe_common/client/components/dialogs/ConfirmDialog';
import Select from '../../../../../../fe_common/client/components/atoms/Registration/Select';
import Checkbox from '../../../../../../fe_common/client/components/atoms/Registration/Checkbox';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    calloutMessageRoot: { marginTop: 16 },
    dialogActions: { padding: 16 },
  }),
);

interface ProjectBriefCloneAndConnectDialogProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

const ProjectBriefCloneAndConnectDialog: React.FC<ProjectBriefCloneAndConnectDialogProps> = (props) => {
  const classes = useStyles();
  const { open, onClose, project } = props;
  const { id: projectId, companyId } = project;

  const [targetProjectId, setTargetProjectId] = useState<string>();
  const [confirmClone, setConfirmClone] = useState<boolean>(false);
  const { companyProjects, loading, refetch } = useCompanyProjectsBasic(companyId);
  const [saving, setSaving] = useState(false);

  const companyProjectsList = useMemo(
    () => getCompanyProjectsList(companyProjects, [projectId]),
    [companyProjects, projectId],
  );

  const { mutate: connectProjectDeepBriefToAnotherProject } = useMutation(
    ConnectProjectDeepBriefToAnotherProjectMutation,
  );

  const onSelectCompanyHandler = useCallback((newValue: string) => {
    setTargetProjectId(newValue);
  }, []);

  const handleOnConfirmCloneClick = useCallback((newValue) => {
    setConfirmClone(newValue);
  }, []);

  const handleOnConfirm = useCallback(async () => {
    setSaving(true);
    const variables = {
      projectId,
      targetProjectId,
    };

    await connectProjectDeepBriefToAnotherProject({ variables });
    await refetch();
    setSaving(false);
    onClose();
  }, [connectProjectDeepBriefToAnotherProject, onClose, projectId, refetch, targetProjectId]);

  return (
    <ConfirmDialog
      open={open}
      onCancel={onClose}
      title="Clone the data from this brief and connect it to another project"
      onConfirm={handleOnConfirm}
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Clone and connect"
      confirmButtonDisabled={(!!targetProjectId && !confirmClone) || saving}
      classes={{
        root: classes.root,
        dialogActions: classes.dialogActions,
      }}
      content={
        <>
          <TextDisplay gutterBottom>
            <strong>YOU CAN’T UNDO THIS STEP:</strong>
            <br />
            By Clicking “Clone and Connect” you will copy the data from this brief and permanently overwrite the data in
            the target brief. These briefs will then be permanently connected and any changes you make in one will also
            appear in the other.
          </TextDisplay>
          <Select
            enums={companyProjectsList || []}
            value={targetProjectId}
            onSelect={onSelectCompanyHandler}
            disabled={loading}
          />
          {loading && <TextDisplay variant="caption">loading projects...</TextDisplay>}
          {!!targetProjectId && (
            <>
              <CalloutMessage
                type="warning"
                message={
                  `All the data in project (ID: ${targetProjectId}) will be permanently deleted. ` +
                  'THIS ACTION CAN’T BE UNDONE!'
                }
                classes={{ root: classes.calloutMessageRoot }}
              />
              <Checkbox
                name="confirm-clone"
                checked={confirmClone}
                onChange={handleOnConfirmCloneClick}
                inputLabel="I understand that these briefs will be permanently connected"
              />
            </>
          )}
        </>
      }
    />
  );
};

export default ProjectBriefCloneAndConnectDialog;
