import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { useCronofyIntegrationManager, useCronofyIntegration, useCronofyIntegrationRevoke } from './hooks';
import { CronofyIntegrationManagerProps } from './types';
import { useCalendarIntegrationText } from './texts';

import AvailabilityDialog from './components/AvailabilityDialog';
import ConnectCalendarExplanationDialog from './components/ConnectCalendarExplanationDialog';
import { RefreshSpinner } from '../../../atoms';
import { Button } from '../../../inputs';
import { svgs } from '../../../../app/svg';
import CalloutMessageCollapse from '../../../common/CalloutMessageCollapse';
import ConfirmDialog from '../../../dialogs/ConfirmDialog';

import useStyles from './styles';
import { TextDisplay } from '../../../display';

const CronofyIntegrationManager: React.FC<CronofyIntegrationManagerProps> = (props) => {
  const classes = useStyles(props);

  const {
    redirectUri,
    state,
    onAddIntegrationSuccess,
    onRevokeSuccess,
    onError,
    availabilityRulesProfiles,
    showImpersonationWarning,
  } = props;

  const { cronofyIntegration, refetch: refetchCronofyIntegration } = useCronofyIntegration();

  const hasActiveCalendarIntegration = cronofyIntegration != null;

  const {
    title,
    primary,
    secondary,
    connectCalendar,
    editAvailability,
    providerIcon,
    confirmDialogContent,
    confirmDialogTitleDisconnectMessage,
  } = useCalendarIntegrationText(cronofyIntegration);

  const {
    onCalendarAuthorizationClick,
    onConnectIntegrationClick,
    isExplanationDialogOpen,
    onExplanationDialogClose,
    isAvailabilityDialogOpen,
    onAvailabilityDialogClose,
    onEditAvailabilityClick,
    clearErrorMessage,
    disabled,
    isLoading,
    error,
  } = useCronofyIntegrationManager({
    redirectUri,
    cronofyIntegration,
    refetchCronofyIntegration,
    onSuccess: onAddIntegrationSuccess,
    state,
    onError,
  });

  const {
    isRevokeIntegrationConfirmDialogOpen,
    onRevokeIntegrationClick,
    onRevokeIntegrationConfirm,
    onRevokeIntegrationConfirmDialogClose,
  } = useCronofyIntegrationRevoke(cronofyIntegration, refetchCronofyIntegration, onRevokeSuccess);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.cronofyLogo}>
          <img src={svgs.cronofy} width={40} height={40} alt="" />
        </div>
        <div className={classes.title}>
          <TextDisplay variant="body1">{title}</TextDisplay>
        </div>
        {hasActiveCalendarIntegration && (
          <>
            <div className={classes.content}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={svgs[providerIcon]} />
                </ListItemAvatar>
                <ListItemText
                  primary={primary}
                  secondary={secondary}
                  classes={{
                    primary: classes.listItemTextPrimary,
                    secondary: classes.listItemTextSecondary,
                  }}
                />
              </ListItem>
            </div>
            <div className={classes.replaceButton}>
              <IconButton onClick={onRevokeIntegrationClick}>
                <DeleteIcon />
              </IconButton>
            </div>
          </>
        )}
        <div className={classes.actionButton}>
          {!hasActiveCalendarIntegration && (
            <Button
              label={connectCalendar}
              onClick={onConnectIntegrationClick}
              disabled={disabled}
              variant="contained"
              color="secondary"
              startIcon={isLoading ? <RefreshSpinner size={24} /> : null}
            />
          )}
          {hasActiveCalendarIntegration && (
            <Button
              label={editAvailability}
              onClick={onEditAvailabilityClick}
              disabled={disabled}
              color="primary"
              variant="outlined"
            />
          )}
        </div>
      </div>

      {error !== undefined && (
        <div>
          <CalloutMessageCollapse type="error" message={error} timeToShow={7} onMessageDisappear={clearErrorMessage} />
        </div>
      )}

      <ConfirmDialog
        open={isRevokeIntegrationConfirmDialogOpen}
        onConfirm={onRevokeIntegrationConfirm}
        onCancel={onRevokeIntegrationConfirmDialogClose}
        title={confirmDialogTitleDisconnectMessage}
        content={confirmDialogContent}
      />

      <ConnectCalendarExplanationDialog
        open={isExplanationDialogOpen}
        onClose={onExplanationDialogClose}
        onCalendarAuthorizationClick={onCalendarAuthorizationClick}
      />

      <AvailabilityDialog
        isOpen={isAvailabilityDialogOpen}
        onCloseClick={onAvailabilityDialogClose}
        availabilityRulesProfiles={availabilityRulesProfiles}
        showImpersonationWarning={showImpersonationWarning}
      />
    </>
  );
};

export default CronofyIntegrationManager;
