import React, { FC } from 'react';
import { ReferralContact } from '@mayple/types';

import TextDisplay from '../../../../../../fe_common/client/components/display/TextDisplay';

type ReferralContactsListProps = {
  referralContacts: Array<ReferralContact>;
};

/**
 * Shared component to list the referral contacts type
 *
 * @param props
 * @constructor
 */
const ReferralContactsList: FC<ReferralContactsListProps> = (props) => {
  const { referralContacts } = props;

  if (!referralContacts || referralContacts.length === 0) {
    return null;
  }

  return (
    <ul>
      {referralContacts.map((contact, index) => {
        const { firstName, lastName, emailAddress, phoneNumber, contactAuthorized, jobTitle } = contact;

        if (firstName || lastName) {
          return (
            <li key={`ReferralContactsList-${index}-${emailAddress || ''}`}>
              <TextDisplay bold>
                {firstName} {lastName} - {jobTitle || 'Job title N/A'}
              </TextDisplay>
              <TextDisplay>
                Email: <a href={`mailto:${emailAddress}`}>{emailAddress || 'N/A'}</a>
              </TextDisplay>
              <TextDisplay>
                Phone: <a href={`tel:${phoneNumber}`}>{phoneNumber || 'N/A'}</a>
              </TextDisplay>
              <div>
                <TextDisplay inline>Authorized for contact: </TextDisplay>
                <TextDisplay inline colorOverride={contactAuthorized ? 'green' : 'red'}>
                  {contactAuthorized ? 'Yes' : 'No'}
                </TextDisplay>
              </div>
            </li>
          );
        }

        return (
          <li key="ReferralContactsList-no-results">
            <TextDisplay>Missing contact details information.</TextDisplay>
          </li>
        );
      })}
    </ul>
  );
};

export default ReferralContactsList;
