import { WrappedFieldInputProps } from 'redux-form';
import React, { useCallback, useEffect, useState } from 'react';

import { UrlListProps } from './types';

import { normalizeUrl } from '../../../../services/utils';
import { validateUrl } from '../../../../services/validation';

interface UrlListData {
  onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  items: string[];
  url: string;
  showError: boolean;
  onEnterPress: (e: React.KeyboardEvent) => void;
  handleOnUrlAdd: (e: React.KeyboardEvent | React.MouseEvent) => void;
  handleOnDeleteUrl: (deletedUrl: string) => void;
}

const useUrlListData = (props: UrlListProps): UrlListData => {
  const { input = {} as WrappedFieldInputProps } = props;

  const { value = [], onChange } = input as WrappedFieldInputProps;

  // get the values from redux form if there is any
  const initialItems = value || [];
  const [items, setItems] = useState<string[]>(initialItems);
  const [url, setUrl] = useState('');
  const [showError, setShowError] = useState(false);

  const onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);

    if (newUrl.length < 1 || !validateUrl(newUrl)) {
      setShowError(false);
    }
  }, []);

  const handleOnUrlAdd = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent): void => {
      e.preventDefault();
      let newShowError = false;
      if (validateUrl(url)) {
        newShowError = true;
      }

      setShowError(newShowError);

      if (newShowError || url.length < 1) {
        return;
      }

      if (items.includes(normalizeUrl(url))) {
        // don't allow duplicates of url
        setUrl('');
        return;
      }

      const newItems = [...items, normalizeUrl(url)];
      setUrl('');
      setItems(newItems);
      // onChange?.(items); // add updated list to redux form
    },
    [items, url]
  );

  // remove url from list, and update redux form
  const handleOnDeleteUrl = useCallback(
    (deletedUrl: string) => {
      const newItems: string[] = items.filter((item) => deletedUrl !== item);
      setItems(newItems);
      // onChange?.(newItems);
    },
    [items]
  );

  // we cant put form inside a form (in this case, WizardForm) so use enter press
  const onEnterPress = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleOnUrlAdd(e);
    }
  };

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange?.(items); // add updated list to redux form
    }
  }, [onChange, items]);

  return {
    onChangeHandler,
    items,
    url,
    showError,
    onEnterPress,
    handleOnUrlAdd,
    handleOnDeleteUrl,
  };
};

export default useUrlListData;
