import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { SearchOption } from './consts';
import { tryParseInt } from '../../../../../fe_common/client/services/utils';

const getQuickSearchSelectedIndexAction = (value: number) => ({
  type: 'quickSearchSelectedIndex',
  payload: value,
});

type UseQuickNavigateReturnType = {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickClearValue: () => void;
  handleClickClearValueMouseDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOnEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickListItem: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuItemClick: (event: React.MouseEvent<HTMLElement>, index: number) => void;
  handleClose: () => void;
  setTextInputRef: (element: HTMLInputElement) => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  error: string;
  selectedIndex: number;
  value: string;
};

const useQuickNavigate = (quickSearchOptions: SearchOption[]): UseQuickNavigateReturnType => {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const qsSelectedIndex = useSelector(
    (state) =>
      // @ts-ignore
      state?.app?.quickSearchSelectedIndex || 0,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(qsSelectedIndex);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleClickClearValue = () => {
    setValue('');
  };

  const handleClickClearValueMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleQuickSearch = () => {
    const id = tryParseInt(value, 0);

    if (id) {
      history.push(`/${quickSearchOptions[selectedIndex].value}/${id}`);
    } else {
      setError(`please enter a valid id for ${quickSearchOptions[selectedIndex].label}`);
    }
  };

  const handleOnEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // enter key pressed
      event.preventDefault();
      handleQuickSearch();
    }
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const inputFocus = () => {
    window.setTimeout(() => {
      if (inputRef) {
        // eslint-disable-next-line no-unused-expressions
        inputRef.current?.focus();
      }
    }, 0);
  };

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    dispatch(getQuickSearchSelectedIndexAction(index));
    setAnchorEl(null);
    inputFocus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setTextInputRef = (element: HTMLInputElement) => {
    // eslint-disable-next-line no-param-reassign
    inputRef.current = element;
  };

  return {
    onChangeHandler,
    handleClickClearValue,
    handleClickClearValueMouseDown,
    handleOnEnterPress,
    handleClickListItem,
    handleMenuItemClick,
    handleClose,
    setTextInputRef,
    anchorEl,
    error,
    selectedIndex,
    value,
  };
};

export default useQuickNavigate;
