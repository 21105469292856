import { useCallback } from 'react';

import { AssessmentDialogProps, MarketerAssessmentDialogData } from '../types';

const useMarketerAssessmentDialogData = (props: AssessmentDialogProps): MarketerAssessmentDialogData => {
  const { onClose, showThanksMessage } = props;

  const onCloseDialogHandler = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const onAssessmentDoneClickHandler = useCallback(() => {
    onCloseDialogHandler();
  }, [onCloseDialogHandler]);

  const submitFormHandler = useCallback(() => {
    if (!showThanksMessage) {
      onCloseDialogHandler();
    }
  }, [onCloseDialogHandler, showThanksMessage]);

  const skipFormHandler = () => {
    onCloseDialogHandler();
  };

  return {
    submitFormHandler,
    skipFormHandler,
    onAssessmentDoneClickHandler,
    onCloseDialogHandler,
  };
};

export default useMarketerAssessmentDialogData;
