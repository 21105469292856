import { ProjectLifeCycleStatus } from '@mayple/types';

export enum PROJECT_DETAILS_TABS {
  basicDetails = 'basicDetails',
  matchingAndOpportunities = 'matchingAndOpportunities',
  meetings = 'meetings',
  discovery = 'discovery',
  onboarding = 'onboarding',
  accounts = 'accounts',
  servicesAndBilling = 'servicesAndBilling',
  internalTeams = 'internalTeams',
  reports = 'reports',
  insights = 'insights',
  assessment = 'assessment',
}

type TabDefinition = {
  value: string;
  label: string;
  hideOnStatus: Array<ProjectLifeCycleStatus>;
};

export const tabsDefinitions: TabDefinition[] = [
  {
    value: PROJECT_DETAILS_TABS.basicDetails,
    label: 'Basic Details',
    hideOnStatus: [],
  },
  {
    value: PROJECT_DETAILS_TABS.matchingAndOpportunities,
    label: 'Matching & Opportunities',
    hideOnStatus: [],
  },
  {
    value: PROJECT_DETAILS_TABS.internalTeams,
    label: 'Internal Accounts',
    hideOnStatus: [],
  },
  {
    value: PROJECT_DETAILS_TABS.reports,
    label: 'Reports',
    hideOnStatus: [],
  },
  {
    value: PROJECT_DETAILS_TABS.accounts,
    label: 'Accounts',
    hideOnStatus: [ProjectLifeCycleStatus.ABANDONED],
  },
  {
    value: PROJECT_DETAILS_TABS.discovery,
    label: 'Services And Billing',
    hideOnStatus: [
      ProjectLifeCycleStatus.ONBOARDING,
      ProjectLifeCycleStatus.LIVE,
      ProjectLifeCycleStatus.FINISHED,
      ProjectLifeCycleStatus.ABANDONED,
    ],
  },
  {
    value: PROJECT_DETAILS_TABS.onboarding,
    label: 'Onboarding',
    hideOnStatus: [ProjectLifeCycleStatus.DISCOVERY, ProjectLifeCycleStatus.ABANDONED],
  },
  {
    value: PROJECT_DETAILS_TABS.meetings,
    label: 'Meetings',
    hideOnStatus: [ProjectLifeCycleStatus.ABANDONED],
  },
  {
    value: PROJECT_DETAILS_TABS.assessment,
    label: 'Assessment',
    hideOnStatus: [ProjectLifeCycleStatus.DISCOVERY, ProjectLifeCycleStatus.ABANDONED],
  },
  {
    value: PROJECT_DETAILS_TABS.servicesAndBilling,
    label: 'Services And Billing',
    hideOnStatus: [ProjectLifeCycleStatus.DISCOVERY, ProjectLifeCycleStatus.ABANDONED],
  },
  {
    value: PROJECT_DETAILS_TABS.insights,
    label: 'Insights',
    hideOnStatus: [ProjectLifeCycleStatus.DISCOVERY, ProjectLifeCycleStatus.ABANDONED],
  },
];
