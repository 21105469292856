import React, { FC, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import { PackageContext } from './logic';
import { TextDisplay } from '../../display';
import SimpleIcon from '../../atoms/SimpleIcon';

import { useCommonStyles, usePlatformsStyles } from './style';

const PackagePlatforms: FC = () => {
  const classes = usePlatformsStyles();
  const commonClasses = useCommonStyles();
  const { data } = useContext(PackageContext);
  const platforms = data?.marketingServiceTypeInfo?.platforms || [];
  //
  if (!platforms || platforms.length < 1) {
    return null;
  }

  return (
    <div className={classNames(classes.root, commonClasses.lightDiv)}>
      <TextDisplay variant="body1" className={classNames(commonClasses.darkText, classes.recommendedPlatformsTitle)}>
        Recommended platforms
      </TextDisplay>
      <div className={classes.iconsContainer}>
        {platforms.map(
          // @ts-ignore
          ({ title, icon }) => (
            <Tooltip placement="top" title={title} key={`PackagePlatforms-${title}-supported-platform-icon-key`}>
              <div>
                <SimpleIcon icon={icon?.url || title} />
              </div>
            </Tooltip>
          )
        )}
      </div>
    </div>
  );
};

export default PackagePlatforms;
