import { MarkProjectCyclesAsReadyForFinalBillingMutation } from 'growl-graphql/dist/mutations/admin/MarkProjectCyclesAsReadyForFinalBillingMutation';

import { useMutation } from '../../../../../../fe_common/client/hooks';
import { handleClientError } from '../../../../../../fe_common/client/services/logger';
import { EntityOperationResponseExecutionResult } from '../../../../../../fe_common/client/app/types';

const cleanCycleIds = (cycleIds: string[] | undefined) => cycleIds?.map((item) => item.replace('select-', ''));

const useMarkProjectCyclesAsReadyForFinalBilling = (): {
  markProjectCyclesAsReadyForFinalBilling: (
    cycleIds: string[] | undefined,
    onSuccess?: () => Promise<any>,
  ) => Promise<void>;
  saving: boolean;
} => {
  const { mutate, loading: saving } = useMutation(MarkProjectCyclesAsReadyForFinalBillingMutation);

  const markProjectCyclesAsReadyForFinalBilling = async (
    cycleIds: string[] | undefined,
    onSuccess?: () => Promise<any>,
  ) => {
    try {
      const variables = { idList: cleanCycleIds(cycleIds) };
      const result: EntityOperationResponseExecutionResult<'markProjectCyclesAsReadyForFinalBilling'> = await mutate({
        variables,
      });
      if (result?.data?.markProjectCyclesAsReadyForFinalBilling?.success) {
        onSuccess?.();
      }
    } catch (e) {
      handleClientError(e, 'Error while trying to mark cycles as ready for final billing.');
    }
  };

  return {
    markProjectCyclesAsReadyForFinalBilling,
    saving,
  };
};

export default useMarkProjectCyclesAsReadyForFinalBilling;
