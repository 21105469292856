import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    formWrapper: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
      marginTop: '3rem',
      marginBottom: '3rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
    richTextRoot: {
      '& .ql-container': {
        minHeight: '10em',
      },
      '& .ql-editor': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    preserveLineBreak: {
      whiteSpace: 'pre-wrap',
    },
  })
);

export default useStyles;
