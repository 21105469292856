import React, { FC, useState } from 'react';
import { TestMarketerMatchesInput } from '@mayple/types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import WizardSteps from './steps';
import { wizardFormName } from './consts';
import { getInitialValues, mapFormValuesToTestMarketerMatchesInput } from './logic';
import { MatchingSimulatorForm, ProjectMatchingSimulatorWizardProps } from './types';
import MatchedMarketersResults from './components/MatchedMarketersResults';

import { clientLogger } from '../../../../../fe_common/client/services/logger';
import { Transitions } from '../../../../../fe_common/client/components/molecules/Transitions';
import { WizardForm } from '../../../../../fe_common/client/components/organisms/WizardForm';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

/**
 * Wizard to simulate what matches will be received based on project data
 */
const ProjectMatchingSimulatorWizard: FC<ProjectMatchingSimulatorWizardProps> = (props) => {
  const { initialValues } = props;
  const finalInitialValues = getInitialValues(initialValues);

  const [useDynamicWeights, setUseDynamicWeights] = useState(false);
  const [testMarketerMatchesInput, setTestMarketerMatchesInput] = useState<TestMarketerMatchesInput | null>(null);

  const handleFormSubmit = (formValuesSubmitted: MatchingSimulatorForm): void => {
    const newTestMarketerMatchesInput = mapFormValuesToTestMarketerMatchesInput(formValuesSubmitted);
    const { testProject, testWeights } = newTestMarketerMatchesInput;

    clientLogger.debug(`You submitted:\n\n${JSON.stringify(testProject, null, 2)}`);

    if (useDynamicWeights) {
      clientLogger.debug(`You submitted:\n\n${JSON.stringify(testWeights, null, 2)}`);
    }

    setTestMarketerMatchesInput(newTestMarketerMatchesInput);
  };

  const renderMatches = () => {
    if (!testMarketerMatchesInput?.testProject) {
      return (
        <Transitions duration={400}>
          <TextDisplay variant="h5" bold italic align="center" style={{ margin: 50 }}>
            Enter project data to see results
          </TextDisplay>
        </Transitions>
      );
    }

    return (
      <Transitions duration={400}>
        <div className="m-b-16" style={{ marginTop: 50 }}>
          <MatchedMarketersResults testMarketerMatchesInput={testMarketerMatchesInput} />
        </div>
      </Transitions>
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <WizardForm
                form={wizardFormName}
                useDynamicWeights={useDynamicWeights}
                setUseDynamicWeights={setUseDynamicWeights}
                initialValues={finalInitialValues}
                steps={WizardSteps}
                // @ts-ignore
                onSubmit={handleFormSubmit}
                destroyOnUnmount
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div>{renderMatches()}</div>
    </div>
  );
};

export default ProjectMatchingSimulatorWizard;
