import React, { FC } from 'react';
import { Answer } from '@mayple/types';

import { AnswerProps } from './types';
import { TextDisplay } from '../../../display';

const NumericRangeAnswer: FC<AnswerProps> = (props) => {
  const { answer = {} as Answer } = props;
  const { values = [] } = answer;

  const [value = {}] = values;
  const { minimum, maximum } = value;

  return (
    <TextDisplay variant="subtitle1">
      {minimum} - {maximum}
    </TextDisplay>
  );
};

export default NumericRangeAnswer;
