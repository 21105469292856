import React, { FC } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';

import { ActionButtonProps, EntityListItemProps } from './types';
import { getEntityExtraData, getEntityTypeProps } from './logic';

import useStyles from './style';

const EntityListItem: FC<EntityListItemProps> = (props) => {
  const classes = useStyles(props);

  const {
    entityProperties = [],
    entityType,
    entity,
    secondary,
    action,
    customActionButton,
    withSelection = false,
    isChecked = false,
    handleToggleSelection,
  } = props;

  const hasAction = action !== null; // here we check if action was passed
  const { icon: Icon = AddCircleIcon, label = '', handler } = action || ({} as ActionButtonProps); // default action values

  const { linkFormatter, entityIdKey = 'id', entityNameKey = 'name' } = getEntityTypeProps(entityType);
  const entityLink = linkFormatter(entity, [entityIdKey, entityNameKey]);

  const { displayImageUrl } = entity;

  return (
    <ListItem classes={{ root: classes.root, container: classes.container }}>
      {withSelection && (
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            onClick={() => {
              handleToggleSelection?.(entity);
            }}
          />
        </ListItemIcon>
      )}
      <ListItemAvatar>
        <Avatar src={displayImageUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={entityLink}
        secondary={
          <>
            {getEntityExtraData(entity, entityProperties, entityIdKey, classes)}
            {secondary}
          </>
        }
      />
      {customActionButton !== null && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          {customActionButton}
        </ListItemSecondaryAction>
      )}
      {hasAction && !customActionButton && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
          <Tooltip title={label} placement="left">
            <IconButton
              onClick={() => {
                handler?.(entity);
              }}
            >
              <Icon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default EntityListItem;
