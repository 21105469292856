import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    searchContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    entitySearchRoot: {
      flexGrow: 1,
    },
    actionButton: {},
    connectedInternalTeams: {
      width: '100%',
      marginTop: '2rem',
    },
  }),
);

export default useStyles;
