import { ReactionType } from '@mayple/types';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { PostReactionIcon } from './types';

// eslint-disable-next-line import/prefer-default-export
export const POST_REACTIONS: PostReactionIcon[] = [
  {
    icon: ThumbUpIcon,
    label: 'LIKE',
    reactionType: ReactionType.LIKE,
    iconClass: 'likeAvatar',
  },
  // {
  //   icon: ThumbUpIcon,
  //   label: 'LOVE',
  //   reactionType: ReactionType.LOVE,
  //   iconClass: 'loveAvatar',
  // },
  // {
  //   icon:      '',
  //   label:     'HAHA',
  //   type:     FEED_REACTION.HAHA,
  //   iconClass: 'hahaAvatar',
  // },
  // {
  //   icon:      '',
  //   label:     'WOW',
  //   type:     FEED_REACTION.WOW,
  //   iconClass: 'wowAvatar',
  // },
  // {
  //   icon:      '',
  //   label:     'SAD',
  //   type:     FEED_REACTION.SAD,
  //   iconClass: 'sadAvatar',
  // },
  // {
  //   icon:      '',
  //   label:     'ANGRY',
  //   type:     FEED_REACTION.ANGRY,
  //   iconClass: 'angryAvatar',
  // },
];
