import { useCallback, useMemo } from 'react';
import { Participant, SharedFile, SharedFileCategory, SharedFileCreate } from '@mayple/types';
import { CreateParticipantSharedFileMutation } from 'growl-graphql/dist/mutations/CreateParticipantSharedFileMutation';
import { ReplaceParticipantSharedFileMutation } from 'growl-graphql/dist/mutations/ReplaceParticipantSharedFileMutation';

import { useMutation } from '../../fe_common/client/hooks';
import { handleClientError } from '../../fe_common/client/services/logger';

interface UploadParticipantSharedFileProps {
  participant: Participant | null | undefined;
  sharedFileCategory: SharedFileCategory;
  onSuccess?: () => void;
}
interface UploadParticipantSharedFileData {
  loading: boolean;
  onFileUpload: (s: SharedFileCreate) => Promise<void>;
}

export const getExistingSharedFile = (
  participant: Participant | null | undefined,
  sharedFileCategory: SharedFileCategory,
): SharedFile | undefined =>
  (participant?.sharedFiles || ([] as SharedFile[])).find(({ category }) => category === sharedFileCategory);

const useUploadParticipantSharedFile = (props: UploadParticipantSharedFileProps): UploadParticipantSharedFileData => {
  const { participant, sharedFileCategory, onSuccess } = props;

  const participantId = participant?.id;

  const { mutate: createFile, loading: createFileLoading } = useMutation(CreateParticipantSharedFileMutation, {
    mutationOptions: {
      notifyOnNetworkStatusChange: true,
    },
  });

  const { mutate: replaceFile, loading: replaceFileLoading } = useMutation(ReplaceParticipantSharedFileMutation, {
    mutationOptions: {
      notifyOnNetworkStatusChange: true,
    },
  });

  const existingFile = useMemo(
    () => getExistingSharedFile(participant, sharedFileCategory),
    [participant, sharedFileCategory],
  );

  const onFileUpload = useCallback(
    async (sharedFileCreate: SharedFileCreate) => {
      if (!participantId) {
        throw new Error('missing participant Id');
      }

      try {
        const variables: { participantId: number; sharedFileCreate: SharedFileCreate; sharedFileId?: number } = {
          participantId,
          sharedFileCreate,
        };

        if (existingFile) {
          variables.sharedFileId = existingFile.id;
          await replaceFile({ variables });
        } else {
          await createFile({ variables });
        }

        await onSuccess?.();
      } catch (e) {
        handleClientError(e);
      }
    },
    [createFile, existingFile, onSuccess, participantId, replaceFile],
  );

  return {
    loading: createFileLoading || replaceFileLoading,
    onFileUpload,
  };
};

export default useUploadParticipantSharedFile;
