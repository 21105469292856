import React, { useMemo } from 'react';
import { BillingBreakdownDocument } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { getBillingBreakdownDocumentsTableColumns } from './logic';
import DataTable from '../../../../../../fe_common/client/components/molecules/DataTable';
import useDataTableRef from '../../../../../../fe_common/client/components/molecules/DataTable/useDataTableRef';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

export interface ExtendedBillingBreakdownDocument extends BillingBreakdownDocument {
  startDate: string;
}

interface BillingDocumentsTableProps {
  billingBreakdownDocuments: ExtendedBillingBreakdownDocument[] | BillingBreakdownDocument[];
  withCycleData?: boolean;
  classes?: Record<string, string>;
}

const BillingDocumentsTable: React.FC<BillingDocumentsTableProps> = (props) => {
  const { billingBreakdownDocuments, withCycleData = false } = props;

  const classes = useStyles(props);
  const { tableRef } = useDataTableRef();
  const billingBreakdownDocumentsTableOptions = {
    selection: false,
    sorting: true,
    paging: true,
    pageSize: 10,
    emptyRowsWhenPaging: false,
    pageSizeOptions: [10, 20, 50],
    tableLayout: 'fixed',
  };

  const billingBreakdownDocumentsTableColumns = useMemo(
    () => getBillingBreakdownDocumentsTableColumns(withCycleData),
    [withCycleData],
  );

  return (
    <div className={classes.root}>
      <DataTable
        tableRef={tableRef}
        title={`${billingBreakdownDocuments.length} Billing breakdown documents`}
        data={billingBreakdownDocuments}
        columns={billingBreakdownDocumentsTableColumns}
        // @ts-ignore
        options={billingBreakdownDocumentsTableOptions}
        emptyStateProps={{
          primary: 'No Billing breakdown documents here yet',
          secondary: '',
        }}
      />
    </div>
  );
};

export default BillingDocumentsTable;
