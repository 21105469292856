import React, { FC, ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import useStyles from './style';

interface SimpleExpansionPanelProps {
  summary: ReactNode;
  details: ReactNode;
  classes?: Record<string, string>;
}

const SimpleExpansionPanel: FC<SimpleExpansionPanelProps> = (props) => {
  const classes = useStyles(props);

  const [answerExpended, setAnswerExpended] = useState(false);

  const { summary, details } = props;

  const onClickHandler = useCallback(() => {
    setAnswerExpended(!answerExpended);
  }, [answerExpended]);

  return (
    <Accordion classes={{ root: classes.root }}>
      <AccordionSummary
        className={classNames(classes.accordionSummary, {
          [classes.answerExpended]: answerExpended,
        })}
        expandIcon={
          answerExpended ? <RemoveIcon className={classes.removeIcon} /> : <AddIcon className={classes.addIcon} />
        }
        onClick={onClickHandler}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default SimpleExpansionPanel;
