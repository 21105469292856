import {
  Project,
  ProjectStage,
  FlowConfiguration,
  StageConfigurationValue,
  FlowConfigurationInput,
} from '@mayple/types';
import React, { useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';
import { SetProjectFlowConfigurationMutation } from 'growl-graphql/dist/mutations/admin/SetProjectFlowConfigurationMutation';

import {
  FlowConfigurationData,
  FlowConfigurationMutationData,
  StageConfigurationKey,
  StageConfigurationSwitch,
} from './types';

import { titleCase } from '../../../../../fe_common/client/services/utils';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { stageTitle } from '../../../../../fe_common/client/logic/projectStage';

const labels: Record<StageConfigurationKey, string> = {
  skipProjectDataEnrichmentStage: 'Skip Project Data Enrichment Stage',
  skipOpportunityStage: 'Skip Opportunity Stage',
  automaticOpportunities: 'Automatic opportunities dispatch',
  opportunityIncludeCompanyNote: 'Include company notes in sent opportunities',
  opportunityIncludeProjectNote: 'Include project notes in sent opportunities',
  meetYourExpertWithoutSale: 'Meet the expert meeting without Mayple',
  numberOfOfferedMarketersForMarketerApproval: 'Number of marketers to approve',
  allowDelayedProjectLaunch: 'Allow Delayed Project Launch',
  skipSetupStage: 'Skip Setup Stage',
  skipExistingMarketingEvaluationStage: 'Skip Existing Marketing Evaluation Stage',
  skipHealthCheckKickOffMeeting: 'Skip Health Check kick-off Meeting',
  isHealthCheckContinueDecisionRequired: 'Should we ask the client to continue at the end of Health Check',
  skipExistingMarketingOptimizationImplementationStage: 'Skip Existing Marketing Optimization Implementation Stage',
  skipUploadProcessAtImplementationStage: 'Skip Upload Process at Implementation Stage',
  skipPostImplementationMeeting: 'Skip Post Implementation Meeting',
  marketingPlanWithoutGS: 'Marketing plan meeting without Mayple',
  skipMarketingPlan: 'Skip the marketing plan upload and approval process',
  skipMarketingPlanMeeting: 'Skip the full marketing plan stage',
};

const tooltips: Record<StageConfigurationKey, string> = {
  skipProjectDataEnrichmentStage: 'Skip Project Data Enrichment Stage',
  skipOpportunityStage: 'skipOpportunityStage',
  automaticOpportunities:
    'This will automatically send the top 3 expert matches opportunities when the "Expert Matching" stage will begin',
  opportunityIncludeCompanyNote: 'Include company notes in sent opportunities',
  opportunityIncludeProjectNote: 'Include project notes in sent opportunities',
  meetYourExpertWithoutSale: 'Without MC/GS present',
  numberOfOfferedMarketersForMarketerApproval: 'Number of marketers to approve',
  allowDelayedProjectLaunch:
    'Allow Delayed Project Launch. User can set a starting date of the project, and launch it later.',
  skipSetupStage: 'skipSetupStage',
  skipExistingMarketingEvaluationStage: 'Skip Existing Marketing Evaluation Stage',
  skipHealthCheckKickOffMeeting: 'Skip Health Check kick-off Meeting',
  isHealthCheckContinueDecisionRequired: 'Should we ask the client to continue at the end of Health Check',
  skipExistingMarketingOptimizationImplementationStage: 'Skip Existing Marketing Optimization Implementation Stage',
  skipUploadProcessAtImplementationStage: 'Skip Upload Process at Implementation Stage',
  skipPostImplementationMeeting: 'Skip Post Implementation Meeting',
  marketingPlanWithoutGS: 'Without GS present',
  skipMarketingPlan: 'No MP file is needed, keep the meeting',
  skipMarketingPlanMeeting: 'no meeting will be scheduled and no plan will be uploaded and approved.',
};

const getSwitchLabelAndTooltip = (key: StageConfigurationKey, useBeforeStage: ProjectStage) => {
  const label = labels[key] || titleCase(key || '') || 'N/A';
  const tooltip = `${tooltips[key] || 'Tooltip N/A'} (Editable before ${stageTitle[useBeforeStage]})`;

  return { label, tooltip };
};

const filterFlowConfiguration: (a: [string, StageConfigurationValue | 'FlowConfiguration' | undefined]) => boolean = ([
  key,
]) => key !== 'numberOfOfferedMarketersForMarketerApproval' && key !== '__typename';

const sortFlowConfiguration: (
  a: [string, StageConfigurationValue | 'FlowConfiguration' | undefined],
  b: [string, StageConfigurationValue | 'FlowConfiguration' | undefined],
) => number = ([, dataA], [, dataB]) =>
  (dataA as StageConfigurationValue)?.category >= (dataB as StageConfigurationValue)?.category ? 1 : -1;

/**
 * Create dynamic switches based on the Project.FlowConfiguration type
 */
const getSwitchesFromConfiguration = (
  flowConfiguration: FlowConfiguration,
  currentStage: ProjectStage,
): StageConfigurationSwitch[] => {
  if (!flowConfiguration) {
    return [];
  }

  try {
    const entries = Object.entries(flowConfiguration || {})
      .filter(filterFlowConfiguration)
      .sort(sortFlowConfiguration);

    // List the stages based on order of enum (Should be always order by the project flow)
    const stages = Object.values(ProjectStage);
    const indexOfCurrentStage = stages.indexOf(currentStage);
    const switches: StageConfigurationSwitch[] = [];

    // Create the switches objects
    entries.forEach((entry) => {
      const [key, currentConfig] = entry;
      // Ignore the__typename key or non StageFlowValue types
      if (key === '__typename' || typeof currentConfig === 'string' || !currentConfig) {
        return;
      }

      const { useBeforeStage, value, category, hidden } = currentConfig;
      const indexOfLastStageToUse = stages.indexOf(useBeforeStage);
      // Switch is editable only before a certain stage
      const editable = indexOfCurrentStage < indexOfLastStageToUse;
      // @ts-ignore - This key will always be the keyof FlowConfiguration
      const { tooltip, label } = getSwitchLabelAndTooltip(key, useBeforeStage);

      switches.push({ key, editable, category, value, label, useBeforeStage, tooltip, hidden });
    });

    return switches;
  } catch (e) {
    return [];
  }
};

export const useProjectFlowConfigurationMutation = (): FlowConfigurationMutationData => {
  const {
    mutate: setProjectFlowConfiguration,
    loading,
    error,
  } = useMutation(SetProjectFlowConfigurationMutation, {
    successMessage: '',
  });

  return {
    setProjectFlowConfiguration,
    loading,
    error,
  };
};

export const saveProjectFlowConfiguration = async (
  setProjectFlowConfiguration: (options?: any) => Promise<any>,
  projectId: number,
  flowConfiguration: FlowConfiguration,
): Promise<any> => {
  const variables = { projectId, flowConfiguration };
  return setProjectFlowConfiguration({ variables });
};

// export const enrichFlowConfiguration = (
//   flowConfiguration: FlowConfiguration,
//   key: StageConfigurationKey,
//   useBeforeStage: ProjectStage,
//   value = false
// ): FlowConfiguration => ({
//   ...flowConfiguration,
//   [key]: {
//     value: value ? 1 : 0,
//     useBeforeStage,
//     category: ProjectStage.OPPORTUNITIES,
//   },
// });

export const cleanFlowConfiguration = (flowConfiguration: FlowConfiguration): FlowConfigurationInput => {
  const nonFlowConfigurationInputKeys = ['allowDelayedProjectLaunch'];

  const newFlowConfiguration = omitDeep(
    {
      ...flowConfiguration,
    },
    '__typename',
    'hidden',
    ...nonFlowConfigurationInputKeys,
  );

  return newFlowConfiguration as FlowConfigurationInput;
};

const useFlowConfigurationData = (project: Project): FlowConfigurationData => {
  const { flowConfiguration, stage, id: projectId } = project;
  const switches = useMemo(() => getSwitchesFromConfiguration(flowConfiguration, stage), [flowConfiguration, stage]);
  const { setProjectFlowConfiguration, loading } = useProjectFlowConfigurationMutation();

  const onSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const key: keyof FlowConfigurationInput = event.target.name as keyof FlowConfigurationInput;
    const value: boolean = event.target.checked;

    const flowConfigurationToUpdate = cleanFlowConfiguration(flowConfiguration);
    (flowConfigurationToUpdate[key] as StageConfigurationValue).value = value ? 1 : 0;

    return saveProjectFlowConfiguration(
      setProjectFlowConfiguration,
      projectId,
      flowConfigurationToUpdate as FlowConfiguration,
    );
  };

  return { switches, onSwitchChange, loading };
};

export default useFlowConfigurationData;
