import React from 'react';
import { OpportunityState } from '@mayple/types';
import { Action, Column } from 'material-table';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import LinkToMarketer from '../../../../../components/cpanel/components/atoms/Links/LinkToMarketer';
import ChipList from '../../../../../../fe_common/client/components/atoms/ChipList';
import { dateTimeFormatter } from '../../../../../../fe_common/client/components/molecules/DataTable/logic';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

export const opportunityStateFormatter = (rowData: Record<string, any>): React.ReactElement => {
  const state = rowData.opportunityState;
  return (
    <TextDisplay>
      {state} {state === OpportunityState.APPLIED ? '*' : ''}
    </TextDisplay>
  );
};

export const marketerLinkFormatter = (rowData: Record<string, any>): React.ReactElement => (
  <LinkToMarketer marketerId={rowData.marketerId}>{rowData?.marketer?.name}</LinkToMarketer>
);

export function chipListColumnFormatter(
  fieldName: string,
  labelsLookup: Record<string, any>,
  maxToShow = 0,
  classes: Record<string, any> = {},
) {
  // eslint-disable-next-line react/display-name
  return (rowData: Record<string, any>): React.ReactElement => {
    const items = rowData[fieldName].map(({ serviceType }: { serviceType: string }) => serviceType);

    return (
      <ChipList
        items={items}
        labelsLookup={labelsLookup}
        maxToShow={maxToShow}
        classes={{ root: classes.chipListRoot }}
      />
    );
  };
}

export const getProjectOpportunitiesTableColumns = (): Column<Record<string, unknown>>[] => [
  { title: 'OpportunityUuid', field: 'uuid', hidden: true },
  {
    title: 'Marketer',
    field: 'marketer',
    render: marketerLinkFormatter,
  },
  { title: 'Status', field: 'opportunityState', render: opportunityStateFormatter },
  {
    title: 'Sent',
    field: 'offeredDateTime',
    render: dateTimeFormatter('offeredDateTime'),
    defaultSort: 'desc',
  },
  {
    title: 'Expiration Date',
    field: 'expirationDateTime',
    render: dateTimeFormatter('expirationDateTime'),
  },
  {
    title: 'Responded',
    field: 'marketerResolutionDateTime',
    render: dateTimeFormatter('marketerResolutionDateTime'),
  },
];

type TableActions = Action<Record<string, any>>[];

export const getProjectOpportunitiesTableActions = (
  handleWithdrawOpportunity: (event: any, rowData: Record<string, any> | Record<string, any>[]) => void,
  handleExtendOpportunity: (event: any, rowData: Record<string, any> | Record<string, any>[]) => void,
): TableActions => [
  // @ts-ignore
  (rowsData: Record<string, any> | Record<string, any>[]) => {
    // rowsData - is an array of all selected rows,
    // so I'm using a filter to check if the user selected any "WITHDRAWN" opportunity
    // In that case - the button should be disabled
    const disabled =
      rowsData.filter(
        ({ opportunityState }: { opportunityState: OpportunityState }) =>
          opportunityState === OpportunityState.WITHDRAWN,
      ).length > 0;

    return {
      icon: TimerOffIcon,
      position: 'toolbarOnSelect',
      tooltip: 'Withdraw Opportunity',
      onClick: (event: any, rd: Record<string, any> | Record<string, any>[]) => {
        handleWithdrawOpportunity(event, rd);
      },
      disabled,
    };
  },
  // @ts-ignore
  (rowsData: Record<string, any> | Record<string, any>[]) => {
    // rowsData - is an array of all selected rows
    // so I'm using a filter to check if the user selected any opportunity  that is not "OFFERED" or "EXPIRED"
    // In that case - the button should be disabled
    const disabled =
      rowsData.filter(
        ({ opportunityState }: { opportunityState: OpportunityState }) =>
          opportunityState !== OpportunityState.OFFERED && opportunityState !== OpportunityState.EXPIRED,
      ).length > 0;

    return {
      icon: AvTimerIcon,
      position: 'toolbarOnSelect',
      tooltip: 'Extend Opportunity',
      onClick: (event: any, rd: Record<string, any> | Record<string, any>[]) => {
        handleExtendOpportunity(event, rd);
      },
      disabled,
    };
  },
];
