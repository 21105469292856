import { PayoutType } from '@mayple/types';

import { PAYOUT_METHOD_LABELS } from '../../../../../fe_common/client/app/enums';

export const getPayoutDetailsProperties = (preferredPayoutType, payoutDetails) => {
  if (preferredPayoutType === PayoutType.PAYPAL && !payoutDetails?.paypal?.emailAddress) {
    return [
      {
        label: 'Preferred Payout Type',
        path: 'noPreferredPayoutType',
        formatter: (value) => value || 'N/A',
      },
    ];
  }

  switch (preferredPayoutType) {
    case PayoutType.PAYPAL:
      return [
        {
          label: 'Preferred Payout Type',
          path: 'preferredPayoutType',
          formatter: (value) => PAYOUT_METHOD_LABELS[value],
        },
        {
          label: 'Email Address',
          path: 'paypal.emailAddress',
        },
        {
          label: 'Payment Reference Message',
          path: 'paypal.paymentReferenceMessage',
        },
      ];
    case PayoutType.WIRE_TRANSFER:
      return [
        {
          label: 'Preferred Payout Type',
          path: 'preferredPayoutType',
          formatter: (value) => PAYOUT_METHOD_LABELS[value],
        },
        {
          label: 'Bank Name',
          path: 'wireTransfer.destinationBank.bankName',
          group: 'Destination Bank',
        },
        {
          label: 'Account Number',
          path: 'wireTransfer.destinationBank.accountNumber',
          group: 'Destination Bank',
        },
        {
          label: 'Branch Name',
          path: 'wireTransfer.destinationBank.branchName',
          group: 'Destination Bank',
        },
        {
          label: 'Swift BIC',
          path: 'wireTransfer.destinationBank.swiftBIC',
          group: 'Destination Bank',
        },
        {
          label: 'IBAN',
          path: 'wireTransfer.destinationBank.IBAN',
          group: 'Destination Bank',
        },
        {
          label: 'Sort Code Or ABA',
          path: 'wireTransfer.destinationBank.sortCodeOrABA',
          group: 'Destination Bank',
        },
        {
          label: 'Payment Reference Message',
          path: 'wireTransfer.paymentReferenceMessage',
        },
        {
          label: 'Additional Instructions',
          path: 'wireTransfer.additionalInstructions',
        },
      ];
    default:
      return [];
  }
};
