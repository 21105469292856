import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker as Picker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

import { colors } from '../../../../app/theme';
import { TextDisplay } from '../../../display';

function DateRangePicker(props) {
  // console.log('DateRangePicker props', props);

  const {
    openTo,
    views,
    fromLabel,
    toLabel,
    helperText,
    disableFuture,
    minStartDate,
    minEndDate,
    maxStartDate,
    maxEndDate,
    dateFormat,
    input,
    meta,
  } = props;

  const showError = !!meta.error && meta.touched;
  const { value, onChange } = input;
  const { startDate, endDate } = value;

  const handleStartDateChange = (sDate) => {
    const toUpdate = {
      endDate,
      startDate: sDate,
    };

    onChange(toUpdate);
  };
  const handleEndDateChange = (eDate) => {
    const toUpdate = {
      startDate,
      endDate: eDate,
    };

    onChange(toUpdate);
  };

  const minStartD = useMemo(
    () => (minStartDate ? moment.utc(minStartDate).format('YYYY-MM-DD') : undefined),
    [minStartDate]
  );
  const maxStartD = useMemo(
    () => (maxStartDate ? moment.utc(maxStartDate).format('YYYY-MM-DD') : undefined),
    [maxStartDate]
  );
  const minEndD = useMemo(() => (minEndDate ? moment.utc(minEndDate).format('YYYY-MM-DD') : undefined), [minEndDate]);
  const maxEndD = useMemo(() => (maxEndDate ? moment.utc(maxEndDate).format('YYYY-MM-DD') : undefined), [maxEndDate]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Picker
          minDate={minStartD}
          maxDate={maxStartD}
          inputVariant="outlined"
          views={views}
          label={fromLabel}
          format={dateFormat}
          value={startDate}
          helperText={helperText}
          onChange={handleStartDateChange}
          disableFuture={disableFuture}
          openTo={openTo}
        />
      </Grid>
      <Grid item>
        <Picker
          minDate={minEndD}
          maxDate={maxEndD}
          inputVariant="outlined"
          views={views}
          label={toLabel}
          format={dateFormat}
          value={endDate}
          helperText={helperText}
          onChange={handleEndDateChange}
          disableFuture={disableFuture}
          openTo={openTo}
        />
      </Grid>
      {showError && (
        <TextDisplay variant="caption" colorOverride={colors.error}>
          {meta.error}
        </TextDisplay>
      )}
    </Grid>
  );
}

DateRangePicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.object,
  views: PropTypes.arrayOf(PropTypes.oneOf(['date', 'month', 'year'])),
  openTo: PropTypes.oneOf(['date', 'month', 'year']),
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
  helperText: PropTypes.string,
  dateFormat: PropTypes.string,
  disableFuture: PropTypes.bool,
  minStartDate: PropTypes.any,
  minEndDate: PropTypes.any,
  maxStartDate: PropTypes.any,
  maxEndDate: PropTypes.any,
};

DateRangePicker.defaultProps = {
  fromLabel: 'Start date',
  toLabel: 'End date',
  helperText: '',
  views: ['month', 'date'],
  openTo: 'date',
  required: false,
  disableFuture: false,
  minStartDate: null,
  minEndDate: null,
  maxStartDate: null,
  maxEndDate: null,
  meta: {},
  dateFormat: null,
  input: {
    value: null,
    onChange: () => {},
  },
};

export default DateRangePicker;
