import React, { FC } from 'react';
import { Project, ProjectCycle } from '@mayple/types';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';

import { getProjectBillingPagePath } from '../../consts';
import ProjectBillingConfiguration from '../components/ProjectBillingConfiguration';
import ProjectBillingPointBehaviorEditor from '../components/ProjectBillingPointBehaviorEditor';
import ProjectCycleDetailsNew from '../../../components/cpanel/components/organisms/Project/ProjectCycleDetailsNew';

import Section from '../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../fe_common/client/components/atoms/Section/SectionContent';
import { Button } from '../../../../fe_common/client/components/inputs';
import CalloutMessage from '../../../../fe_common/client/components/common/CalloutMessage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    sectionRoot: {},
    sectionRootNoPaddingNoMargin: {
      paddingTop: 0,
      marginTop: 0,
    },
  }),
);

interface ProjectDiscoveryTabProps {
  project: Project;
  cycles: ProjectCycle[] | null | undefined;
  classes?: Record<string, string>;
}

const ProjectDiscoveryTab: FC<ProjectDiscoveryTabProps> = (props) => {
  const { project, cycles } = props;
  const classes = useStyles(props);

  const projectId = project?.id;

  if (!cycles) {
    return <CalloutMessage type="error" title="Error" message="Error getting project cycles data" />;
  }

  const { pathname, hash } = window.location;
  const returnTo = `${pathname}${hash}`;
  const projectBillingPagePath = getProjectBillingPagePath(projectId, returnTo);

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Section
            title="Monthly Cycles"
            noBorder
            classes={{
              root: classNames(classes.sectionRoot, classes.sectionRootNoPaddingNoMargin),
            }}
          >
            <SectionContent>
              <ProjectCycleDetailsNew cycles={cycles} project={project} showActual={false} />
            </SectionContent>
          </Section>
        </Grid>
        <Grid item xs={12}>
          <Button
            label="Edit project services and billing"
            href={projectBillingPagePath}
            variant="contained"
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <ProjectBillingConfiguration projectId={projectId} />
        </Grid>
        <Grid item xs={12}>
          <ProjectBillingPointBehaviorEditor project={project} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectDiscoveryTab;
