import React, { FC } from 'react';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';

import { TableCol } from './types';
import TableCell from './TableCell';

import { useRowStyles } from './styles';

export type MarketersPayoutReviewTableHeadProps = {
  cols: TableCol[];
  numSelected: number;
  rowCount: number;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: Record<string, string>;
};

const MarketersPayoutReviewTableHead: FC<MarketersPayoutReviewTableHeadProps> = (props) => {
  const classes = useRowStyles(props);
  const { cols, numSelected, rowCount, handleSelectAllClick } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        {cols.map(({ id, label }) => (
          <TableCell className={classes.bold} key={id}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default MarketersPayoutReviewTableHead;
