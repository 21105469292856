import React from 'react';
import { CurrencyType, MediaType, PackagePresentation } from '@mayple/types';

import { getDiscountedCost } from '../../../services/utils/number';
import { PPC_SERVICE_TYPE } from '../../../app/consts';
import { generateUuid } from '../../../services/utils';

interface PackageContextType {
  data: Record<string, any>;
  isSmDown: boolean;
  showChatLink: boolean;
}

export const PackageContext = React.createContext<PackageContextType>({
  data: {},
  isSmDown: false,
  showChatLink: true,
});

// export const getPackageIcon = (iconCode: string): SimpleIconType => {
//
//   // @ts-ignore // TODO REMOVE THIS AFTER TYPING IS ADDED
//   return svgs[iconCode] || streamlineIcons[iconCode] || iconCodes[iconCode] || null;
//
// };

export const getPackagePrice = (
  cost: number,
  discount: number
): {
  costBeforeDiscount: number;
  costAfterDiscount: number;
} => {
  const finalCost = Math.round(cost);

  if (!Number.isInteger(finalCost) || !Number.isInteger(discount) || discount < 0 || discount > 100) {
    return {
      costBeforeDiscount: 0,
      costAfterDiscount: 0,
    };
  }

  return {
    costBeforeDiscount: finalCost,
    costAfterDiscount: getDiscountedCost(finalCost, discount),
  };
};

export const createFakePpcService = (
  planName: string,
  cost: number,
  estimatedMediaBudget: number,
  discount: number,
  estimatedMediaBudgetDivision: Record<string, any>[],
  marketingServiceTypeInfo: PackagePresentation | null
): Record<string, any> => ({
  planName,
  cost,
  currency: CurrencyType.USD,
  uuid: generateUuid(),
  description: marketingServiceTypeInfo?.description || {
    contents:
      'The best solution to grow your business online, with a dedicated marketing expert, ' +
      'and unbiased marketing supervision.',
    mediaType: MediaType.TEXT_PLAIN,
  },
  recurring: true,
  serviceType: PPC_SERVICE_TYPE,
  discount,
  flavor: 'Essential',
  variableFactorTitle: 'media channels',
  variableFactorUnit: '',
  variableFactorCount: Math.max(estimatedMediaBudgetDivision.length, 2),
  marketingServiceTypeInfo,
  estimatedMediaBudget,
});
