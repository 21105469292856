import { ProjectStage, StageConfigurationValue } from '@mayple/types';

export const checkIfConfigurationEditableInCurrentStage = (
  stageConfigurationValue: StageConfigurationValue | undefined | null,
  currentStage: ProjectStage | undefined | null,
): boolean => {
  if (!stageConfigurationValue || !currentStage) {
    return false;
  }

  const stages = Object.values(ProjectStage);
  const indexOfCurrentStage = stages.indexOf(currentStage);
  const { useBeforeStage } = stageConfigurationValue || ({} as StageConfigurationValue);

  const indexOfLastStageToUse = stages.indexOf(useBeforeStage);

  // Configuration is editable only before a certain stage
  return indexOfCurrentStage < indexOfLastStageToUse;
};

export default checkIfConfigurationEditableInCurrentStage;
