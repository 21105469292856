import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { getGradientColor } from '../../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    entityAvatarRoot: {
      flexDirection: 'column',
    },
    entityAvatarAvatar: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    entityAvatarPrimary: {
      color: getGradientColor('gray', 80),
    },
    headline: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '0.5rem',
    },
    subHeadline: {
      color: getGradientColor('gray', 60),
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    formActions: {
      marginTop: '2rem',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '1rem',
    },
    wideButton: {
      paddingRight: '3rem',
      paddingLeft: '3rem',
    },
  })
);

export default useStyles;
