import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(
  createStyles({
    root: {
      paddingTop: 24,
      paddingBottom: 48,
    },
  }),
);

export default useStyles;
